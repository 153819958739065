import React, { useMemo } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections, { SectionData } from "../tables/TableWithSections";
import { formatValue, percentage } from "../utils/formatters";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["revenue"];
}

const ModelTeamResultsProfitAndLoss: React.FC<Props> = ({ data }) => {
  const sections: SectionData[] = useMemo(
    () => [
      {
        name: "Interest Income",
        fields: [
          "interestIncome.interestOnCash",
          "interestIncome.HOME_LOANS",
          "interestIncome.BUSINESS_LOANS",
          "interestIncome.CREDIT_CARDS",
          "interestIncome.INSTITUTIONAL_BANKING",
          "interestIncome.loanBookPremiumAmortisation",
          "interestIncome.total",
        ],
        totalFields: ["interestIncome.total"],
        values: data,
      },
      {
        name: "Interest Expenses",
        fields: [
          "interestExpenses.DEPOSITS",
          "interestExpenses.wholesaleFunding",
          "interestExpenses.total",
        ],
        totalFields: ["interestExpenses.total"],
        values: data,
        formatter: (val: number) => formatValue(-1 * val),
      },
      {
        fields: ["netInterestIncome"],
        totalFields: ["netInterestIncome"],
        values: data,
      },
      {
        name: "Other Banking Income",
        fields: [
          "otherBankingIncome.HOME_LOANS",
          "otherBankingIncome.BUSINESS_LOANS",
          "otherBankingIncome.DEPOSITS",
          "otherBankingIncome.brokerCommissions",
          "otherBankingIncome.CREDIT_CARDS.creditCardFees",
          "otherBankingIncome.CREDIT_CARDS.creditCardInterchangeIncome",
          "otherBankingIncome.CREDIT_CARDS.creditCardRewardCosts",
          "otherBankingIncome.INSTITUTIONAL_BANKING",
          "otherBankingIncome.OFFSHORE_BANK",
          "otherBankingIncome.total",
        ],
        totalFields: ["otherBankingIncome.total"],
        values: data,
        formatter: (val: number, field: string) =>
          [
            "otherBankingIncome.brokerCommissions",
            "otherBankingIncome.CREDIT_CARDS.creditCardRewardCosts",
          ].includes(field)
            ? formatValue(-1 * val)
            : null,
      },
      {
        name: "Funds Management Income",
        fields: ["fundsManagementIncome.WEALTH_MANAGEMENT"],
        values: data,
      },
      {
        fields: ["totalIncome"],
        totalFields: ["totalIncome"],
        values: data,
      },
      {
        name: "Investment Experience",
        fields: [
          "investmentExperience.gainLossOnSaleLoanBook",
          "investmentExperience.dividends",
          "investmentExperience.total",
        ],
        totalFields: ["investmentExperience.total"],
        values: data,
      },
      {
        name: "Operating Costs",
        fields: [
          "operatingCosts.HOME_LOANS",
          "operatingCosts.BUSINESS_LOANS",
          "operatingCosts.DEPOSITS",
          "operatingCosts.CREDIT_CARDS",
          "operatingCosts.WEALTH_MANAGEMENT",
          "operatingCosts.INSTITUTIONAL_BANKING",
          "operatingCosts.remediationCosts",
          "operatingCosts.total",
        ],
        totalFields: ["operatingCosts.total"],
        values: data,
        formatter: (val: number) => formatValue(-1 * val),
      },
      {
        name: "Loan Impairment Expense",
        fields: [
          "lie.HOME_LOANS",
          "lie.BUSINESS_LOANS",
          "lie.CREDIT_CARDS",
          "lie.INSTITUTIONAL_BANKING",
          "lie.total",
        ],
        totalFields: ["lie.total"],
        values: data,
        formatter: (val: number) => formatValue(-1 * val),
      },
      {
        fields: [
          "projectExpenses",
          "netProfitBeforeTax",
          "profitAndLossTaxationExpense",
        ],
        totalFields: ["totalIncome"],
        values: data,
        fieldFormatter: (field: string) =>
          field === "profitAndLossTaxationExpense" ? "Taxation Expense" : null,
      },
      {
        isTotalsSection: true,
        fields: ["netProfitAfterTax", "proxyNetProfitBeforeTax"],
        values: data,
      },
      {
        name: "Financial Metrics",
        fields: [
          "financialMetrics.averageEquity",
          "financialMetrics.returnOfAverageEquity",
          "financialMetrics.netInterestMargin",
          "financialMetrics.otherBusinessIncome",
          "financialMetrics.costToIncome",
          "financialMetrics.loanImpairmentExpenseDivGLAA",
          "financialMetrics.costOfFunds",
          "financialMetrics.riskWeightedAssetsDivLendingAssets",
        ],
        values: data,
        fieldFormatter: (field: string) => {
          if (field === "financialMetrics.loanImpairmentExpenseDivGLAA") {
            return "Loan Impairment Expense (LIE) / GLAA";
          }
          if (field === "financialMetrics.riskWeightedAssetsDivLendingAssets") {
            return "Risk Weighted Assets (RWA) / Lending Assets";
          }
          return null;
        },
        formatter: (val: number, field: string) => {
          if (
            [
              "financialMetrics.returnOfAverageEquity",
              "financialMetrics.netInterestMargin",
              "financialMetrics.otherBusinessIncome",
              "financialMetrics.costToIncome",
              "financialMetrics.loanImpairmentExpenseDivGLAA",
              "financialMetrics.costOfFunds",
              "financialMetrics.riskWeightedAssetsDivLendingAssets",
            ].includes(field)
          ) {
            return percentage(val);
          }
          return formatValue(val, 0);
        },
      },
    ],
    [data],
  );

  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <TableWithSections
          data={sections}
          formatter={(
            val: number,
            section: string | undefined,
            field: string,
          ) =>
            ["cashTaxationExpense", "profitAndLossTaxationExpense"].includes(
              field,
            )
              ? formatValue(val * -1)
              : formatValue(val)
          }
        />
      </VerticalGroup>
    </>
  );
};

export default ModelTeamResultsProfitAndLoss;
