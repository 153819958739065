import React from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";
import { startCase } from "../../../../services/utils";
import pluckResultsByType from "../utils/pluckResultsByType";
import { formatValue } from "../utils/formatters";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["nps"];
}

const fields = [
  "openingScore",
  "pricingImpact",
  "project1Impact",
  "project2Impact",
  "project3Impact",
  "project4Impact",
  "project5Impact",
  "project6Impact",
  "project7Impact",
  "project8Impact",
  "project9Impact",
  "project10Impact",
  "project11Impact",
  "project12Impact",
  "project13Impact",
  "project14Impact",
  "project15Impact",
  "project16Impact",
  "project17Impact",
  "project18Impact",
  "excoImpact",
  "nonFinancialImpact",
  "customerComplaintsImpact",
];
const types = [
  "HOME_LOANS",
  "BUSINESS_LOANS",
  "DEPOSITS",
  "CREDIT_CARDS",
  "WEALTH_MANAGEMENT",
  "INSTITUTIONAL_BANKING",
  "OFFSHORE_BANK",
];

const ModelTeamResultsNPS: React.FC<Props> = ({ data }) => (
  <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
    {types.map((type) => {
      const values = pluckResultsByType(data, type);
      return (
        <TableWithSections
          key={type}
          header={startCase(String(type))}
          formatter={(val: number) => formatValue(val, 2, 2)}
          data={[
            {
              fields,
              values,
            },
            {
              isTotalsSection: true,
              fields: ["closingScore"],
              values,
            },
          ]}
        />
      );
    })}
    <TableWithSections
      header="Total Bank"
      formatter={(val: number) => formatValue(val, 2, 2)}
      data={[
        {
          fields: [
            ...types,
            "bankNpsScore",
            "averageNonFinancialImpact",
            "changeInNPS",
          ],
          totalFields: [
            "bankNpsScore",
            "averageNonFinancialImpact",
            "changeInNPS",
          ],
          values: pluckResultsByType(data, "totalScore"),
        },
      ]}
    />
  </VerticalGroup>
);

export default ModelTeamResultsNPS;
