import React, { useCallback, useEffect } from "react";
import API from "../../../../services/api";
import useAPIRequest from "../../../../hooks/useAPIRequest";
import ClientListTable from "./ClientListTable";
import { shortDate } from "../../../../lib/date";
import Container from "../../../atoms/page/Page";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import Banner from "../../../atoms/banner/Banner";

function ClientList() {
  const callback = useCallback(() => {
    return API.getClients();
  }, []);

  const [{ inProgress, completed, data, error }, doAPIRequest] = useAPIRequest<
    API.ClientResponse[]
  >(callback, { data: [] });

  const handleExport = useCallback(() => {
    const date = new Date();
    API.exportClientList(
      `Banksim - Client List - ${shortDate(date.toString())}`,
    );
  }, []);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  const handleRefreshClients = useCallback(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  return (
    <Container>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {completed && data && (
        <ClientListTable
          clients={data}
          handleExport={handleExport}
          refreshClients={handleRefreshClients}
        />
      )}
    </Container>
  );
}

export default ClientList;
