import React from "react";
import classNames from "classnames";
import { Draggable, Droppable } from "react-beautiful-dnd";

import { DragAndDropItem } from "./DraggableItemsPool";
import Text from "../../atoms/text/Text";
import Icon from "../../atoms/icon/Icon";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";

interface Props {
  item?: DragAndDropItem;
  readOnly?: boolean;
  index: number;
}

export const DROPPABLE_AREA_PREFIX = "droppable-zone-";

const DroppableArea: React.FC<Props> = ({ item, index, readOnly }) => {
  if (readOnly && item) {
    return (
      <div
        className={classNames("drop-area", `drop-area-color-${index}`, {
          isDraggingOver: false,
          hasItem: true,
        })}
      >
        <VerticalGroup
          className={classNames("drop-area-item", `drop-area-color-${index}`)}
          full
          wide
        >
          {item.view}
        </VerticalGroup>
      </div>
    );
  }

  return (
    <Droppable droppableId={`${DROPPABLE_AREA_PREFIX}${index}`}>
      {(provided, snapshot) => {
        return (
          <div
            className={classNames("drop-area", `drop-area-color-${index}`, {
              isDraggingOver: snapshot.isDraggingOver,
              hasItem:
                !!item &&
                (!snapshot.isDraggingOver ||
                  snapshot.draggingOverWith === item.id),
            })}
            ref={provided.innerRef}
          >
            {(!item || snapshot.isDraggingOver) && (
              <div>
                <Text size="sm">Drag and Drop</Text>
                <Text size="sm">Metric here</Text>
              </div>
            )}

            {item &&
              (!snapshot.isDraggingOver ||
                snapshot.draggingOverWith === item.id) && (
                <Draggable draggableId={item.id} index={index}>
                  {(provided) => (
                    <VerticalGroup
                      className={classNames(
                        "drop-area-item",
                        `drop-area-color-${index}`,
                      )}
                      full
                      wide
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <div
                        className={classNames("item-draggable")}
                        {...provided.dragHandleProps}
                      >
                        <Icon type="draggable" colour="white" />
                      </div>
                      {item.view}
                    </VerticalGroup>
                  )}
                </Draggable>
              )}
          </div>
        );
      }}
    </Droppable>
  );
};

export default DroppableArea;
