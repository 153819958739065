import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";

import API from "../../../services/api";
import useAPIRequest from "../../../hooks/useAPIRequest";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import Container from "../../atoms/page/Page";
import Banner from "../../atoms/banner/Banner";
import SimulationCreate from "./SimulationCreate";
import useQuery from "../../../hooks/useQuery";

export const Breadcrumb = () => {
  const query = useQuery();
  const stepTitle = query.get("stepTitle");
  return <span>Edit Simulation - {stepTitle}</span>;
};

export const PageHeader = () => {
  const query = useQuery();
  const header = query.get("pageHeader");
  return <span>Edit Simulation - {header}</span>;
};

function EditSimulation() {
  const { simulationId } = useParams<{ simulationId: string }>();

  const callback = useCallback(() => {
    return API.getSimulation(simulationId);
  }, [simulationId]);

  const [{ inProgress, data, error }, doApiRequest] = useAPIRequest(
    callback,
    null,
  );

  useEffect(() => {
    doApiRequest();
  }, [doApiRequest]);

  return data ? (
    <SimulationCreate simulation={data} />
  ) : (
    <Container>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
    </Container>
  );
}

export default EditSimulation;
