import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import API from "../../../../services/api";
import Container from "../../../atoms/page/Page";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import Banner from "../../../atoms/banner/Banner";
import TabletConfigTable from "./TabletConfigurationTable/TabletConfigTable";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Ribbon from "../../../atoms/ribbon/Ribbon";
import ConfigSettings from "./Configuration/ConfigSettings";
import { useMemoRequest } from "../../../../hooks/useMemoRequest";
import { reducer } from "./context/state";
import {
  TabletConfigContext,
  useTabletConfigContextValue,
} from "./context/context";
import { unique } from "../../../../lib/array";

function TabletConfig() {
  const [tab, setTab] = useState("Configuration");

  const [state, dispatch] = useReducer(reducer, {
    data: null,
    selectedEventId: null,
    eventData: {},
    tabletDataPerEvent: {},
  });

  const callback = useCallback(async () => {
    const tablets = await API.getTablets();
    dispatch({
      type: "UpdateData",
      payload: tablets,
    });
    return tablets;
  }, []);

  const { inProgress, error, refresh } = useMemoRequest(callback);

  // We want to clear the selected event id when we switch to the tablets tab
  useEffect(() => {
    if (tab === "Tablets" && state.selectedEventId) {
      dispatch({ type: "UpdateSelectedEventId", payload: null });
    }
  }, [state.selectedEventId, tab]);

  useEffect(() => {
    async function fetchData(eventId: string) {
      try {
        const fullEventData: API.EventResponse =
          state.eventData[eventId] ?? (await API.getEvent(eventId));
        dispatch({
          type: "UpdateEventData",
          payload: fullEventData,
        });
      } catch (e) {
        if (e.status === 404 && state.data) {
          dispatch({
            type: "UpdateData",
            payload: {
              teams: state.data.teams.filter((t) => t.eventId !== eventId),
              tablets: state.data.tablets.filter((t) => t.eventId !== eventId),
            },
          });
        }
      }
    }
    if (
      state.data &&
      Object.keys(state.tabletDataPerEvent).length === 0 &&
      state.data.teams.length > 0
    ) {
      const uniqueEventIds = unique(state.data.teams.map((t) => t.eventId));
      for (const eventId of uniqueEventIds) {
        fetchData(eventId);
      }
    }
  }, [state.data, state.eventData, state.tabletDataPerEvent]);

  const resetConfigurations = useCallback(async () => {
    dispatch({ type: "Reset" });
    refresh();
  }, [refresh]);
  const tabletContext = useTabletConfigContextValue(state, dispatch);

  const allLoaded = useMemo(() => {
    return (
      !inProgress &&
      state.data &&
      state.data.teams.every((t) => state.tabletDataPerEvent[t.eventId])
    );
  }, [inProgress, state.data, state.tabletDataPerEvent]);

  return (
    <Container>
      {(inProgress || (!error && !allLoaded)) && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      <InlineGroup spaceBetweenElements={2} className="h-16 mb-2" block>
        <Ribbon
          first
          active={tab === "Configuration"}
          title={"Configurations"}
          onClick={() => setTab("Configuration")}
        ></Ribbon>
        <Ribbon
          last
          active={tab === "Tablets"}
          title={"Tablets"}
          onClick={() => setTab("Tablets")}
        ></Ribbon>
      </InlineGroup>
      {allLoaded && (
        <TabletConfigContext.Provider value={tabletContext}>
          {tab === "Tablets" && state.data && (
            <TabletConfigTable refresh={refresh} tabletData={state.data} />
          )}
          {tab === "Configuration" && (
            <ConfigSettings
              refresh={refresh}
              onClearConfigurations={resetConfigurations}
            />
          )}
        </TabletConfigContext.Provider>
      )}
    </Container>
  );
}

export default TabletConfig;
