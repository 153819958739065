import { doDelete, download, get, put } from "../lib/request";

const GAME_API_HOST = process.env.REACT_APP_GAME_API_URL?.includes("localhost")
  ? process.env.REACT_APP_GAME_API_URL.replace(
      "localhost",
      process.env.REACT_APP_LOCAL_IP ?? "localhost",
    )
  : process.env.REACT_APP_GAME_API_URL;

class GameAPI {
  token: string;

  constructor() {
    this.token = "";
  }

  headers = () => ({
    Authorization: `Bearer ${this.token}`,
  });

  setToken(token: string) {
    this.token = token;
  }

  getSummarySetup = async (eventId: string, roundId: number) => {
    const response = await get<any>({
      url: `${GAME_API_HOST}/api/admin/games/${eventId}/summary/rounds/${roundId}`,
      headers: this.headers(),
    });
    return response.data;
  };

  getFinancialsSetup = async (eventId: string, roundId: number) => {
    const response = await get<any>({
      url: `${GAME_API_HOST}/api/admin/games/${eventId}/financials/rounds/${roundId}`,
      headers: this.headers(),
    });
    return response.data;
  };

  getOpexSetup = async (eventId: string, roundId: number) => {
    const response = await get<any>({
      url: `${GAME_API_HOST}/api/admin/games/${eventId}/opex/rounds/${roundId}`,
      headers: this.headers(),
    });
    return response.data;
  };

  getTreasurySetup = async (eventId: string, roundId: number) => {
    const response = await get<any>({
      url: `${GAME_API_HOST}/api/admin/games/${eventId}/treasury/rounds/${roundId}`,
      headers: this.headers(),
    });
    return response.data;
  };

  getPeerInsightsSetup = async (eventId: string, roundId: number) => {
    const response = await get<any>({
      url: `${GAME_API_HOST}/api/admin/games/${eventId}/peer-insights/rounds/${roundId}`,
      headers: this.headers(),
    });
    return response.data;
  };

  getBusinessSetup = async (eventId: string, roundId: number) => {
    const response = await get<any>({
      url: `${GAME_API_HOST}/api/admin/games/${eventId}/business/rounds/${roundId}`,
      headers: this.headers(),
    });
    return response.data;
  };

  getFinancialSummarySetup = async (eventId: string, roundId: number) => {
    const response = await get<any>({
      url: `${GAME_API_HOST}/api/admin/games/${eventId}/financials/rounds/${roundId}/summary`,
      headers: this.headers(),
    });
    return response.data;
  };

  downloadAssessment = async (event: API.EventResponse) => {
    return download(
      `${GAME_API_HOST}/api/games/${event.id}/assessment/report`,
      { headers: this.headers(), responseType: "arraybuffer" },
      "text/csv",
      `${event.client}-${event.name}${event.streamName ? `- ${event.streamName}` : ""}-${event.date}-${event.id}.csv`,
    );
  };

  broadcastMessage = async (eventId: string, params: API.BroadcastMessage) => {
    return put({
      url: `${GAME_API_HOST}/api/admin/games/${eventId}/broadcast`,
      headers: this.headers(),
      body: params,
    });
  };

  deleteBroadcast = async (eventId: string) => {
    return doDelete({
      url: `${GAME_API_HOST}/api/admin/games/${eventId}/broadcast`,
      headers: this.headers(),
    });
  };

  getStatus = async () => {
    const response = await get<GameAPI.APIStatus>({
      url: `${GAME_API_HOST}/api/status`,
      headers: this.headers(),
    });
    return response.data;
  };
}

export default new GameAPI();
