import React, { useCallback, useContext, useEffect } from "react";

import API from "../../../../../../services/api";
import Banner from "../../../../../atoms/banner/Banner";
import Form from "../../../../../atoms/form/Form";
import { EventContext } from "../../context";
import useForm from "../../../../../../hooks/useForm";
import SelectBranding from "./SelectBranding";
import Card from "../../../../../atoms/card/Card";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import EventOptions from "../../components/EventOptions";
import StepContainer from "../../components/StepContainer";
import { getEventUpdatePayload } from "../../utils";

const Branding: React.FC = () => {
  const { config, updateConfig, assignOnSubmitFunction, updateFormStatus } =
    useContext(EventContext);
  const [
    {
      formData: { brandingId },
      error,
      inProgress,
      formUpdated,
    },
    { handleSubmit, setFieldRaw },
  ] = useForm(
    config?.branding
      ? {
          brandingId: config.brandingId,
        }
      : {
          brandingId: null,
        },
  );

  const onSubmit = useCallback(async () => {
    if (config?.id) {
      const payload = getEventUpdatePayload(config, {
        brandingId,
      });
      const response = await API.editEvent(config.id, payload);
      updateConfig(response);
      return response;
    }
  }, [brandingId, config, updateConfig]);

  const validate = useCallback(() => {
    return [];
  }, []);

  const onBrandingSelected = useCallback(
    (branding: API.BrandingResponse) => {
      setFieldRaw("brandingId", branding.id);
    },
    [setFieldRaw],
  );

  const onBrandingCleared = useCallback(() => {
    setFieldRaw("brandingId", null);
  }, [setFieldRaw]);

  useEffect(() => {
    assignOnSubmitFunction(handleSubmit(onSubmit, validate));
  }, [assignOnSubmitFunction, handleSubmit, onSubmit, validate]);

  useEffect(() => {
    updateFormStatus({
      inProgress: inProgress,
      formUpdated: formUpdated,
    });
  }, [updateFormStatus, inProgress, formUpdated]);

  return (
    <StepContainer>
      <Banner type="error" active={!!error} message={error?.message} />
      <InlineGroup spread verticalCenter block>
        <h2>Branding</h2>
        <EventOptions />
      </InlineGroup>
      <Form wide>
        <Card wide padding={16}>
          <SelectBranding
            clientId={config?.clientId}
            brandingId={brandingId}
            onBrandingSelected={onBrandingSelected}
            onUnselectBranding={onBrandingCleared}
          />
        </Card>
      </Form>
    </StepContainer>
  );
};

export default Branding;
