import React from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";
import { formatValue, percentage } from "../utils/formatters";
import pluckResultsByType from "../utils/pluckResultsByType";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["nonFinancialCalculations"];
}

const divisionsWithoutSalary = [
  "IT_OPERATIONS",
  "FRONT_LINE",
  "RISK_COMPLIANCE",
  "HR_TRAINING",
];

const divisionFields = divisionsWithoutSalary.concat("SALARY_GROWTH");

const divisionFomatter = (field: string): string | null => {
  if (field === "IT_OPERATIONS") {
    return "IT & Operations";
  }
  if (field === "RISK_COMPLIANCE") {
    return "Risk & Compliance";
  }
  if (field === "HR_TRAINING") {
    return "HR & Training";
  }
  if (field === "salaryGrowthRealTerms") {
    return "Salary Growth (Real Terms)";
  }
  return null;
};

const ModelTeamResultsNonFinancialCalculations: React.FC<Props> = ({
  data,
}) => {
  const workforceCalculations = pluckResultsByType(
    data,
    "workforceCalculations",
  );
  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <TableWithSections
          header=""
          data={[
            {
              name: "Raw Slider Values",
              fields: divisionFields.concat("salaryGrowthRealTerms"),
              values: pluckResultsByType(data, "rawSliderValues"),
              fieldFormatter: divisionFomatter,
            },
            {
              name: "Natural Log (Raw Slider Values)",
              fields: divisionFields,
              values: pluckResultsByType(data, "naturalLogRawSliderValues"),
              fieldFormatter: divisionFomatter,
              formatter: (val: number) => formatValue(val, 2, 2),
            },
            {
              name: "Salary & Wages Index (current decisions)",
              fields: ["openingBalance", "change", "closingBalance"],
              totalFields: ["closingBalance"],
              values: pluckResultsByType(
                pluckResultsByType(data, "salaryAndWagesIndex"),
                "currentDecisions",
              ),
              formatter: (val: number) => formatValue(val, 2, 2),
            },
            {
              name: "Salary & Wages Index (without decisions)",
              fields: ["openingBalance", "change", "closingBalance"],
              totalFields: ["closingBalance"],
              values: pluckResultsByType(
                pluckResultsByType(data, "salaryAndWagesIndex"),
                "withoutDecisions",
              ),
              formatter: (val: number) => formatValue(val, 2, 2),
            },
            {
              name: "Effective Productivity",
              fields: divisionFields.concat("salaryGrowthRealTerms"),
              values: pluckResultsByType(data, "effectiveProductivity"),
              fieldFormatter: divisionFomatter,
              formatter: (val: number) => formatValue(val, 2, 2),
            },
            {
              name: "Natural Log (Incl Productivity)",
              fields: divisionFields,
              values: pluckResultsByType(data, "naturalLogInclProductivity"),
              fieldFormatter: divisionFomatter,
              formatter: (val: number) => formatValue(val, 2, 2),
            },
            {
              name: "Productivity Level",
              fields: divisionFields,
              values: pluckResultsByType(
                pluckResultsByType(data, "naturalLogInclProductivity"),
                "productivityLevel",
              ),
              fieldFormatter: divisionFomatter,
              formatter: (val: number) => percentage(val),
            },
            {
              name: "Cost Calculations",
              fields: [
                "totalOperatingPeopleCosts",
                "totalOperatingPeopleCostsBeforePayRise",
                "defaultCostPerDivision.IT_OPERATIONS",
                "defaultCostPerDivision.FRONT_LINE",
                "defaultCostPerDivision.RISK_COMPLIANCE",
                "defaultCostPerDivision.HR_TRAINING",
                "defaultCostPerDivision.SALARY_GROWTH",
                "total",
                "variance",
              ],
              values: pluckResultsByType(data, "costCalculations"),
              fieldFormatter: (field: string) => {
                if (field === "totalOperatingPeopleCostsBeforePayRise") {
                  return "Total Operating People Costs (Before Pay Rises)";
                }
                if (field === "total") {
                  return "Total Operating People Costs";
                }
                return divisionFomatter(field);
              },
            },
          ]}
        />

        <TableWithSections
          header="Workforce Calculations"
          data={[
            {
              name: "Cost per FTE Before Pay Rises",
              fields: divisionsWithoutSalary,
              values: pluckResultsByType(
                workforceCalculations,
                "costPerFTEBeforePayRises",
              ),
              fieldFormatter: divisionFomatter,
            },
            {
              name: "Notional FTE Head Count (Default)",
              fields: divisionsWithoutSalary.concat("total"),
              totalFields: ["total"],
              values: pluckResultsByType(
                workforceCalculations,
                "notionalFTEHeadCount",
              ),
              fieldFormatter: (field: string) => {
                if (field === "total") {
                  return "Total Notional FTE Head Count (Opening)";
                }
                return divisionFomatter(field);
              },
            },
            {
              name: "Extra FTE Required",
              fields: divisionsWithoutSalary.concat("total"),
              totalFields: ["total"],
              values: pluckResultsByType(
                workforceCalculations,
                "extraFTERequired",
              ),
              fieldFormatter: (field: string) => {
                if (field === "total") {
                  return "Total Extra FTE Required";
                }
                return divisionFomatter(field);
              },
            },
            {
              name: "Cost Per New FTE (With CPI Pay Rise This Year)",
              fields: divisionsWithoutSalary,
              values: pluckResultsByType(
                workforceCalculations,
                "costPerNewFTEWithCPIPayRiseThisYear",
              ),
              fieldFormatter: divisionFomatter,
            },
            {
              name: "Cost Per New FTE (With Full Pay Rise This Year)",
              fields: divisionsWithoutSalary,
              values: pluckResultsByType(
                workforceCalculations,
                "costPerNewFTEWithFullPayRiseThisYear",
              ),
              fieldFormatter: divisionFomatter,
            },
            {
              name: "Incremental Cost (New People + CPI)",
              fields: divisionsWithoutSalary.concat("total"),
              totalFields: ["total"],
              values: pluckResultsByType(
                pluckResultsByType(
                  workforceCalculations,
                  "operatingPeopleCosts",
                ),
                "incrementCostNewPeopleAndCPI",
              ),
              fieldFormatter: divisionFomatter,
            },
            {
              name: "Incremental Cost (New People + Full Pay Rise)",
              fields: divisionsWithoutSalary.concat("total"),
              totalFields: ["total"],
              values: pluckResultsByType(
                pluckResultsByType(
                  workforceCalculations,
                  "operatingPeopleCosts",
                ),
                "incrementCostNewPeopleAndFullPayRise",
              ),
              fieldFormatter: divisionFomatter,
            },
            {
              name: "Total Pay Increase (Salaries Only)",
              fields: [
                "existingStaffPayIncreaseAboveCPIThisRound",
                "newStaffPayIncreaseAboveCPIThisRound",
                "existingStaffPayIncreaseCPIThisRound",
                "totalStaffPayIncreaseCPIThisRound",
                "totalNewOperatingPeopleCostsFullPay",
                "totalNewOperatingPeopleCostsCPIIndex",
                "trueCostAboveCPIIndex",
                "trueCostThisYearsChange",
                "changeInCostUIOnly",
                "peopleCostChangeDueToNonFinancials",
              ],
              totalFields: ["totalStaffPayIncreaseCPIThisRound"],
              values: pluckResultsByType(
                workforceCalculations,
                "totalPayIncreaseSalariesOnly",
              ),
              fieldFormatter: (field: string) => {
                if (field === "existingStaffPayIncreaseAboveCPIThisRound")
                  return "Existing Staff - Pay Increase (Above CPI This Round)";
                else if (field === "newStaffPayIncreaseAboveCPIThisRound")
                  return "New Staff - Pay Increase (Above CPI This Round)";
                else if (field === "existingStaffPayIncreaseCPIThisRound")
                  return "Existing Staff - Pay Increase (CPI This Round)";
                else if (field === "totalNewOperatingPeopleCostsFullPay")
                  return "Total New Operating People Costs (Full Pay)";
                else if (field === "totalNewOperatingPeopleCostsCPIIndex")
                  return "Total New Operating People Costs (CPI Index)";
                else if (field === "trueCostAboveCPIIndex")
                  return "True Cost - Above CPI Index";
                else if (field === "trueCostThisYearsChange")
                  return "True Cost - This Years Change";
                else if (field === "changeInCostUIOnly")
                  return "Change In Cost (UI Only)";
                else if (field === "peopleCostChangeDueToNonFinancials")
                  return "People Cost Change Due To Non-Financials (%)";
                return null;
              },
              formatter: (val: number, field: string) => {
                if (field === "peopleCostChangeDueToNonFinancials") {
                  return percentage(val);
                }
                return null;
              },
            },
          ]}
        />
      </VerticalGroup>
    </>
  );
};

export default ModelTeamResultsNonFinancialCalculations;
