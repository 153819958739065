const DOMAIN_REGEX_SOURCE =
  /[a-zA-Z0-9](-?[a-zA-Z0-9])*(\.[a-zA-Z](-?[a-zA-Z0-9])*)+/.source;
const EMAIL_REGEX_SOURCE =
  /[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@/.source;
const EMAIL_REGEX = new RegExp(
  "^" + EMAIL_REGEX_SOURCE + DOMAIN_REGEX_SOURCE + "$",
);

export const isEmailValid = (value: string): boolean => {
  if (typeof value !== "string") {
    return false;
  }
  if (value.length > 254) {
    return false;
  }
  if (!EMAIL_REGEX.test(value)) {
    return false;
  }

  // Further checking of some things regex can't handle
  const parts = value.split("@");
  if (parts[0].length > 64) {
    return false;
  }

  const longPartOfDomain = parts[1].split(".").some(function (part) {
    return part.length > 63;
  });
  if (longPartOfDomain) {
    return false;
  }

  return true;
};
