import React from "react";
import Container from "../../atoms/page/Page";

import ErrorSection from "./ErrorSection";

function NotFound() {
  return (
    <Container className="error-page" noOverflow>
      <ErrorSection
        message="We're sorry the page you requested could not be found"
        secondaryMessage={
          <span>
            Please go back to the dashboard or{" "}
            <a href="mailto: tech@excelerateconsulting.co"> contact support</a>
          </span>
        }
      />
    </Container>
  );
}

export default NotFound;
