import React from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";
import pluckResultsByType from "../utils/pluckResultsByType";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["otherCosts"];
}

const ModelTeamResultsOtherCosts: React.FC<Props> = ({ data }) => (
  <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
    <VerticalGroup spaceBetweenElements={2} wide>
      <TableWithSections
        header="Total Remediation Costs"
        data={[
          {
            fields: [
              "operationalRiskEvent1Fines",
              "regulatorActionsFinesOperationalsImpact",
              "executiveDecisionImpacts",
              "projectShieldRemediation",
              "dealRoomNeoBank",
              "totalRemediationCostsCurrentYear",
              "totalRemediationCostsCumulative",
            ],
            totalFields: [
              "totalRemediationCostsCurrentYear",
              "totalRemediationCostsCumulative",
            ],
            values: pluckResultsByType(data, "totalRemediationCosts"),
          },
        ]}
      />
    </VerticalGroup>
  </VerticalGroup>
);

export default ModelTeamResultsOtherCosts;
