import React from "react";
import classNames from "classnames";

import "./Scrollable.scss";

interface Props {
  className?: string;
  children: React.ReactNode;
  wide?: boolean;
}

function Scrollable(props: Props) {
  const { children, className, wide } = props;
  return (
    <div className={classNames("scrollable", { wide }, className)}>
      {children}
    </div>
  );
}

export default Scrollable;
