import React, { useRef, useImperativeHandle } from "react";
import ReactToPrint from "react-to-print";
import "./Printable.scss";

interface PrintableProps {
  children: React.ReactNode;
  orientation?: "portrait" | "landscape";
  visibleOnScreen?: boolean; // New prop
}

const Printable = React.forwardRef((props: PrintableProps, ref) => {
  const { children, orientation = "portrait", visibleOnScreen = false } = props;
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintTriggerRef = useRef<ReactToPrint>(null);

  useImperativeHandle(
    ref,
    () => ({
      handlePrint: () => {
        if (reactToPrintTriggerRef.current) {
          reactToPrintTriggerRef.current.handlePrint();
        }
      },
    }),
    [],
  );

  const contentClassName = `printable-content ${orientation} ${
    visibleOnScreen ? "visible-on-screen" : "hidden-on-screen"
  }`;

  return (
    <>
      <ReactToPrint
        content={() => contentRef.current}
        ref={reactToPrintTriggerRef}
      />
      <div className={contentClassName} ref={contentRef}>
        <style type="text/css" media="print">
          {`@page { size: ${orientation}; }`}
        </style>
        {children}
      </div>
    </>
  );
});

Printable.displayName = "Printable";

export default Printable;
