import React, { useCallback, useMemo } from "react";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import Card from "../../../atoms/card/Card";
import { CharacteristicItem } from "../components/CharcteristicItem";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import { useResultsContext } from "../context/context";
import EditableReportingHeading from "../components/EditableReportingHeading";
export const wellRunMaxClicks = 10;
const NUMBER_OF_ITEMS_PER_SLIDE = 5;
interface Props {}

const RoundResultsWellRunBanks: React.FC<Props> = () => {
  const characteristicItems = useMemo(
    () => [
      <CharacteristicItem
        key="pricing1"
        icon="pricing"
        color="primaryDark"
        heading="PRICING"
        bulletPoints={[
          "Price-competitive during times of high growth but appreciate differentiating through price alone does not lead to sustainable competitive advantages.",
        ]}
      />,
      <CharacteristicItem
        key="customerFocus"
        icon="customerFocus"
        color="secondaryBlue"
        heading="CUSTOMER FOCUS"
        bulletPoints={[
          "Customer satisfaction matters and reduces long-term customer attrition. The long-term compounding impact on growth is significant without loss of interest margin.",
        ]}
      />,
      <CharacteristicItem
        key="managingMargins"
        icon="margins"
        color="yellow"
        heading="MANAGING MARGINS"
        bulletPoints={[
          "Good margin discipline through minimising the cost of funds in addition to sustainable pricing.",
          "Raise long-term wholesale funding when it is cheap and attract sticky low-cost deposits.",
        ]}
      />,
      <CharacteristicItem
        key="managingFunding"
        icon="funding"
        color="green"
        heading="ADEQUATELY MANAGE FUNDING"
        bulletPoints={[
          "Adequately fund growth, using the right mix of funding sources giving consideration to current market conditions.",
          "A stable deposit base reduces the need for wholesale funding which can have volatile costs.",
          "Spreading wholesale funding maturities is important to reduce exposure to volatile market conditions.",
        ]}
      />,
      <CharacteristicItem
        key="managingLiquidity"
        icon="liquidity"
        color="lightgreen"
        heading="MANAGE LIQUIDITY APPROPRIATELY "
        bulletPoints={[
          "Hold sufficient cash balances for safety, but not so much that excesses reduce margins.Cash earns a low interest rate relative to lending it to customers.",
        ]}
      />,

      <CharacteristicItem
        key="shareholderReturns"
        icon="shareholderReturns"
        color="lime"
        heading="SHAREHOLDER RETURNS"
        bulletPoints={[
          "Steady asset growth reduces the demand for shareholder capital (compared to aggressive growth)",
          "Better margins usually result in stronger dividends",
          "Long-term competitive advantages (high customer sat, employee engagement, technology etc) increase share prices",
          "Avoid events which significantly reduce earnings (and therefore dividends) such as high losses, fines, and thin margins.",
        ]}
      />,
      <CharacteristicItem
        key="investments2"
        icon="investments"
        color="lightpurple"
        heading="INVESTMENT PRIORITISATION"
        bulletPoints={[
          "Be clear on why certain investments are funded, deferred or denied and invest early in initiatives that provide an edge over competitors and deliver long-term benefits. Actually quantify cost vs benefit.",
        ]}
      />,
      <CharacteristicItem
        key="costDiscipline"
        icon="costDiscipline"
        color="purple"
        heading="COST DISCIPLINE"
        bulletPoints={[
          "Pursue improvements in processes and reduce complexity to drive better operational and cost outcomes, but without excessive spending.",
        ]}
      />,
      <CharacteristicItem
        key="risk"
        icon="risk"
        color="darkgrey"
        heading="MANAGE RISK “THROUGH THE CYCLE"
        bulletPoints={[
          "Carefully consider how lending losses change through an economic cycle. Loose lending practices can increase potential lending losses which are challenging to offset even with increased interest rates.",
        ]}
      />,
      <CharacteristicItem
        key="operationalRisk"
        icon="operationalRisk"
        color="orange"
        heading="OPERATIONAL RISK"
        bulletPoints={[
          "Address the threat of operational risk failures proactively and promptly as significant failures can have a wide range of negative impacts.",
        ]}
      />,
    ],
    [],
  );

  const context = useResultsContext();
  const show = useMemo(() => {
    return context.noAnimations ? true : false;
  }, [context.noAnimations]);

  const visibleItems = useMemo(() => {
    const startIndex =
      context.clicks >= NUMBER_OF_ITEMS_PER_SLIDE
        ? NUMBER_OF_ITEMS_PER_SLIDE
        : 0;
    return characteristicItems
      .slice(startIndex, startIndex + NUMBER_OF_ITEMS_PER_SLIDE)
      .map((_, index) => index <= context.clicks - startIndex);
  }, [context.clicks, characteristicItems]);

  const onNext = useCallback(() => {
    if (context.clicks < wellRunMaxClicks - 1) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context]);

  return (
    <RoundResultsContainer onNext={onNext}>
      <Card onClick={() => context.addClick()} fullHeight wide sharp>
        <VerticalGroup
          spaceBetweenElements={4}
          center
          style={{ height: "90%" }}
        >
          <EditableReportingHeading defaultHeading="Characteristics Of Well Run Banks" />
          <VerticalGroup
            spaceBetweenElements={3}
            center
            className="width-90-percent"
            full
          >
            {characteristicItems
              .slice(context.clicks >= 5 ? 5 : 0, context.clicks >= 5 ? 10 : 5)
              .map((item, index) => (
                <React.Fragment key={item.key}>
                  {React.cloneElement(item, {
                    visible: show || visibleItems[index],
                    animationClass:
                      show || visibleItems[index]
                        ? `characteristic-item-${index + 1}`
                        : "",
                  })}
                </React.Fragment>
              ))}
          </VerticalGroup>
        </VerticalGroup>
      </Card>
    </RoundResultsContainer>
  );
};

export default RoundResultsWellRunBanks;
