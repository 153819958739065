import React, { useCallback } from "react";
import ChallengeModal from "../../../organisms/challenge-modal/ChallengeModal";

import useAPIRequest from "../../../../hooks/useAPIRequest";
import API from "../../../../services/api";
import { LayoutResponse, TemplateType } from "../types";

interface Props {
  type: TemplateType;
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  templateId: string;
  layout: LayoutResponse;
}

function DeleteTemplateLayout({
  type,
  isOpen,
  onComplete,
  onClose,
  templateId,
  layout,
}: Props) {
  const callback = useCallback(
    () => API.deleteReportingTemplateLayout(type, templateId, layout.id),
    [templateId, layout, type],
  );

  const [{ inProgress, error }, doAPIRequest] = useAPIRequest(callback, null);

  const handleConfirm = () => {
    doAPIRequest(onComplete);
  };

  return (
    <ChallengeModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      challengeQuestion="This action is irreversible. Please type the word DELETE below to delete this slide."
      challengeAnswer="DELETE"
      challengeAnswerWarning="Please type the word DELETE in CAPS to delete the slide."
      title="Delete slide?"
      confirmTitle="Delete"
      error={error && error.message}
      warning
    />
  );
}

export default DeleteTemplateLayout;
