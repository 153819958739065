import React, { useCallback, useMemo, useState, useEffect } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import API from "../../../../../../services/api";
import useForm from "../../../../../../hooks/useForm";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import ConfirmModal from "../../../../../organisms/confirm-modal/ConfirmModal";
import { formatPersonName } from "../../../../../../lib/formatters";
import Banner from "../../../../../atoms/banner/Banner";
import Form, { FormRequiredText } from "../../../../../atoms/form/Form";
import Input from "../../../../../atoms/form/input/Input";
import Dropdown from "../../../../../atoms/form/input/Dropdown";
import Toggle from "../../../../../atoms/toggle/Toggle";
import DisplayField from "../../../../../atoms/form/display-field/DisplayField";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import Button from "../../../../../atoms/button/Button";
import IconButton from "../../../../../molecules/iconbutton/IconButton";
import openNewTab from "../../../../../../lib/openNewTab";
import Text from "../../../../../atoms/text/Text";
import SendEmailModal from "./SendEmailModal";
import useIsMobile from "../../../../../../hooks/useIsMobile";

import { isEmailValid } from "../../../../../../lib/validateEmail";

import LoadingSpinner from "../../../../../atoms/loadingspinner/LoadingSpinner";
import NameSuggestionModal from "./NameSuggestionModal";
import InlineError from "../../../../../atoms/inlineerror/InlineError";

interface Props {
  participant?: API.ParticipantResponse;
  event: API.EventResponse;
  onAfterSubmit?: (dismissPopup: boolean) => void;
  onCancel?: () => void;
  onNextParticipant?: () => void;
  onPrevParticipant?: () => void;
  deleteParticipant?: (participant: API.ParticipantResponse) => void;
  onWelcomeEmailSent?: () => void;
}
interface Option {
  value: number;
  label: string;
}

const createOptions = (num: number): Option[] => {
  const options: Option[] = [];
  for (let i = 1; i <= num; i++) {
    options.push({ value: i, label: i.toString() });
  }
  return options;
};

function ParticipantForm({
  participant,
  event,
  onAfterSubmit,
  onCancel,
  onNextParticipant,
  onPrevParticipant,
  deleteParticipant,
  onWelcomeEmailSent,
}: Props) {
  const [modalOpen, setModalOpen] = useState(false);
  const { id: eventId } = event;
  const participantLoginLink = useMemo(() => {
    if (participant != null) {
      return `${process.env.REACT_APP_GAME_URL}?eventId=${encodeURIComponent(
        eventId,
      )}&participantId=${encodeURIComponent(
        participant.id,
      )}&email=${encodeURIComponent(participant.email)}`;
    }
    return "";
  }, [eventId, participant]);

  const password = participant?.id.split("-")[0];
  const [copied, setCopied] = useState(false);
  const [copiedLogin, setCopiedLogin] = useState(false);
  const [navigateStepCallback, setNavigateStepCallback] = useState<
    (() => void) | undefined
  >(undefined);
  const [isSendEmailModalOpen, setSendEmailModalOpen] =
    useState<boolean>(false);
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);
  const [nameSuggestions, setNameSuggestions] = useState<
    { firstName: string; lastName: string }[]
  >([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isNameSuggestionModalOpen, setIsNameSuggestionModalOpen] =
    useState(false);
  const [isGuessingName, setIsGuessingName] = useState(false);
  const [hasAttemptedGuess, setHasAttemptedGuess] = useState(false);
  const teamOptions = useMemo(() => {
    const numOfTeams = event.simulation?.playersSetup?.teams ?? 100;
    return createOptions(numOfTeams);
  }, [event.simulation?.playersSetup?.teams]);
  const isMobile = useIsMobile();
  const isTablet = useIsMobile(700);
  const [
    {
      formData: { id, email, firstName, lastName, team, isCeo },
      inProgress,
      fieldErrors,
      error,
      formUpdated,
    },
    {
      setField,
      setFieldSimple,
      setDropdownField,
      handleSubmit,
      resetFormData,
      setError,
      setFieldRaw,
    },
  ] = useForm(
    participant || {
      id: "",
      email: "",
      firstName: "",
      lastName: "",
      team: 1,
      isCeo: false,
    },
  );
  const handleOpenSendEmailModal = useCallback(() => {
    setSendEmailModalOpen(true);
  }, []);

  const handleCloseSendEmailModal = useCallback(() => {
    setSendEmailModalOpen(false);
    onWelcomeEmailSent?.();
  }, [onWelcomeEmailSent]);
  useEffect(() => {
    participant && resetFormData(participant);
  }, [participant, resetFormData]);

  const callback = useCallback(
    ({ transferCeo }: { transferCeo?: boolean } = {}) => {
      return async () => {
        const payload = {
          email: email.trim(),
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          team,
          isCeo,
        };
        if (participant) {
          await API.updateParticipant(
            eventId,
            participant.id,
            payload,
            transferCeo,
            sendWelcomeEmail,
          );
        } else {
          await API.addParticipant(
            eventId,
            payload,
            transferCeo,
            sendWelcomeEmail,
          );
        }
      };
    },
    [
      email,
      firstName,
      lastName,
      team,
      isCeo,
      participant,
      eventId,
      sendWelcomeEmail,
    ],
  );
  const validate = useCallback(() => {
    const errors = [];
    if (!email) {
      errors.push({ field: "email", message: "Enter an email" });
    }
    if (!firstName) {
      errors.push({ field: "firstName", message: "Enter a first name" });
    }
    if (!lastName) {
      errors.push({ field: "lastName", message: "Enter a last name" });
    }
    if (email && !isEmailValid(email)) {
      errors.push({ field: "email", message: "Enter a valid email" });
    }
    return errors;
  }, [email, firstName, lastName]);

  useEffect(() => {
    if (email && !isEmailValid(email)) {
      setError("email", "Enter a valid email");
    }
  }, [email, setError]);

  const existingCeo = useMemo(() => {
    return event.participants.find((p) => p.isCeo && p.team === team);
  }, [event.participants, team]);

  const onSubmit = useCallback(
    async (dismissPopup = true) => {
      if (existingCeo && isCeo && existingCeo.id !== participant?.id) {
        setModalOpen(true);
        return false;
      }
      const success = await handleSubmit(callback(), validate)();
      if (success !== false) {
        onAfterSubmit && onAfterSubmit(dismissPopup);
        return true;
      }
      return false;
    },
    [
      existingCeo,
      isCeo,
      participant?.id,
      handleSubmit,
      callback,
      validate,
      onAfterSubmit,
    ],
  );

  const onSubmitNext = useCallback(async () => {
    if (formUpdated) {
      const success = await onSubmit(false);
      if (!success) {
        setNavigateStepCallback(() => onNextParticipant);
        return;
      }
    }
    onNextParticipant && onNextParticipant();
  }, [formUpdated, onNextParticipant, onSubmit, setNavigateStepCallback]);

  const onSubmitPrev = useCallback(async () => {
    if (formUpdated) {
      const success = await onSubmit(false);
      if (!success) {
        setNavigateStepCallback(() => onPrevParticipant);
        return;
      }
    }
    onPrevParticipant && onPrevParticipant();
  }, [formUpdated, onPrevParticipant, onSubmit]);

  const guessName = useCallback(async () => {
    if (!email || !eventId) return;

    setIsGuessingName(true);
    setHasAttemptedGuess(true);

    const suggestions = await API.guessParticipantName(eventId, email);
    setNameSuggestions(suggestions);

    if (suggestions.length > 0) {
      if (isMobile) {
        setIsNameSuggestionModalOpen(true);
      } else {
        setShowSuggestions(true);
      }
    } else {
      setShowSuggestions(false);
    }

    setIsGuessingName(false);
  }, [email, eventId, isMobile]);

  const selectSuggestion = (suggestion: {
    firstName: string;
    lastName: string;
  }) => {
    setFieldRaw("firstName", suggestion.firstName);
    setFieldRaw("lastName", suggestion.lastName);
    setShowSuggestions(false);
  };

  return (
    <VerticalGroup spread full>
      {isMobile && (
        <NameSuggestionModal
          isOpen={isNameSuggestionModalOpen}
          onClose={() => setIsNameSuggestionModalOpen(false)}
          suggestions={nameSuggestions}
          onSelect={selectSuggestion}
        />
      )}
      {existingCeo && modalOpen && (
        <ConfirmModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          onCancel={() => setModalOpen(false)}
          onConfirm={async () => {
            const success = await handleSubmit(
              callback({ transferCeo: true }),
              validate,
            )();
            if (success !== false) {
              setModalOpen(false);
              onAfterSubmit && onAfterSubmit(false);
              navigateStepCallback && navigateStepCallback();
            }
          }}
          confirmInProgress={inProgress}
          title="A CEO for this team already exists"
          description={`If you proceed this will transfer the CEO status from ${formatPersonName(
            existingCeo!,
          )} to ${formatPersonName({
            firstName,
            lastName,
          })}, do you want to continue?`}
          confirmTitle="Continue"
          error={error && error.message}
          warning
        />
      )}
      {isSendEmailModalOpen && participant && (
        <SendEmailModal
          onComplete={handleCloseSendEmailModal}
          participantCount={1}
          isOpen={isSendEmailModalOpen}
          onClose={handleCloseSendEmailModal}
          eventId={eventId}
          participants={[participant]}
        />
      )}
      <VerticalGroup wide>
        <InlineGroup
          block
          fullHeight
          right={!isTablet}
          spaceBetweenElements={4}
        >
          {participant && !emailChanged && (
            <IconButton
              data-test="send-emails"
              icon="email"
              text="Send Welcome Email"
              onClick={handleOpenSendEmailModal}
            />
          )}
          {participant && !isMobile && (
            <IconButton
              data-test={`login-participant-${participant.id}`}
              icon="export"
              text="Login As Participant"
              onClick={() => openNewTab(participantLoginLink)}
            />
          )}
          {participant && !isMobile && (
            <CopyToClipboard
              text={participantLoginLink}
              onCopy={() => setCopiedLogin(true)}
            >
              <IconButton icon="copy" text="Copy Login Link" />
            </CopyToClipboard>
          )}
          {participant && (
            <IconButton
              danger
              data-test={`delete-participant-${participant.id}`}
              icon="trash"
              text="Delete"
              onClick={(event) => {
                event.stopPropagation();
                deleteParticipant && deleteParticipant(participant);
                // Call onCancel to close the Form modal and allow the delete modal to show
                onCancel && onCancel();
              }}
            />
          )}
        </InlineGroup>
        <Banner type="error" active={!!error} message={error?.message} />
        {copied && (
          <Banner type="success" active={!!copied} message="Password copied" />
        )}
        {copiedLogin && (
          <Banner
            type="success"
            active={!!copiedLogin}
            message="Login link copied"
          />
        )}
        <Form noValidate id="participant-add-form">
          <div className="pt-4">
            <FormRequiredText />
          </div>
          <InlineGroup spaceBetweenElements={4}>
            <Input
              isVerticalLayout={isTablet}
              autoFocus
              data-test="email"
              type="email"
              label="Email *"
              value={email}
              onChange={(value) => {
                setField("email")(value);
                setEmailChanged(true);
              }}
              error={fieldErrors.email}
            />
            <VerticalGroup spaceBetweenElements={1}>
              {!isTablet && (
                <Button onClick={guessName} disabled={!email || isGuessingName}>
                  {isGuessingName ? (
                    <InlineGroup spaceBetweenElements={2}>
                      <Text>Guessing...</Text>
                      <LoadingSpinner size={16} />
                    </InlineGroup>
                  ) : (
                    "Guess Name"
                  )}
                </Button>
              )}
              {hasAttemptedGuess &&
                nameSuggestions.length === 0 &&
                !isGuessingName && (
                  <InlineError
                    active={true}
                    message="No name suggestions found"
                    block
                  />
                )}
            </VerticalGroup>
          </InlineGroup>
          {!isMobile && showSuggestions && nameSuggestions.length > 1 && (
            <Dropdown
              label="Name Suggestions"
              selectProps={{
                options: nameSuggestions.map((suggestion, index) => ({
                  value: index,
                  label: `${suggestion.firstName} ${suggestion.lastName}`,
                })),
                onChange: (option: any) =>
                  selectSuggestion(nameSuggestions[option.value]),
              }}
            />
          )}
          {isTablet && (
            <Button onClick={guessName} disabled={!email || isGuessingName}>
              {isGuessingName ? (
                <InlineGroup spaceBetweenElements={2}>
                  <Text>Guessing...</Text>
                  <LoadingSpinner size={16} />
                </InlineGroup>
              ) : (
                "Guess Name"
              )}
            </Button>
          )}
          <Input
            data-test="firstName"
            type="text"
            label="First Name *"
            value={firstName}
            onChange={setField("firstName")}
            error={fieldErrors.firstName}
            isVerticalLayout={isTablet}
          />

          <Input
            data-test="lastName"
            type="text"
            label="Last Name *"
            value={lastName}
            onChange={setField("lastName")}
            error={fieldErrors.lastName}
            isVerticalLayout={isTablet}
          />
          <Dropdown
            label="Team #"
            isVerticalLayout={isTablet}
            selectProps={{
              options: teamOptions,
              defaultValue: teamOptions.find((o) => o.value === team),
              onChange: setDropdownField("team"),
              value: teamOptions.find((o) => o.value === team),
            }}
          />
          <Toggle
            label="CEO"
            helpTitle="What is a CEO?"
            helpDescription="As CEO, there will be certain decisions which can only be entered into your simulation interface"
            checked={isCeo}
            onUpdate={setFieldSimple("isCeo")}
            block
          />
          <Input
            data-test="SendWelcomeEmail"
            type="checkbox"
            label="Send Welcome Email"
            checked={sendWelcomeEmail}
            onChange={(event) => setSendWelcomeEmail(event.target.checked)}
          />
          {password && (
            <InlineGroup>
              <DisplayField
                isVerticalLayout={isTablet}
                data-test="participantPassword"
                label="Password"
                value={
                  <>
                    <InlineGroup spread>
                      <Text size="sm">{password || ""}</Text>
                      <CopyToClipboard
                        text={password || ""}
                        onCopy={() => setCopied(true)}
                      >
                        <IconButton icon="copy" text="Copy" />
                      </CopyToClipboard>
                    </InlineGroup>
                  </>
                }
              />
            </InlineGroup>
          )}
          {!!participant && (
            <DisplayField
              isVerticalLayout={isTablet}
              label="Participant ID"
              value={id}
              data-test="participantId"
            />
          )}
        </Form>
      </VerticalGroup>
      <InlineGroup block spread fullHeight style={{ marginTop: "10px" }}>
        {!isTablet && (
          <Button
            data-test="cancel"
            form="participant-add-form"
            wide
            light
            inProgress={inProgress}
            onClick={onCancel}
          >
            Cancel
          </Button>
        )}
        <InlineGroup spread spaceBetweenElements={4}>
          {participant && (
            <>
              <Button
                data-test="save-prev"
                form="participant-add-form"
                wide
                inProgress={inProgress}
                onClick={onSubmitPrev}
              >
                Save & Prev
              </Button>
              <Button
                data-test="save-next"
                form="participant-add-form"
                wide
                inProgress={inProgress}
                onClick={onSubmitNext}
              >
                Save & Next
              </Button>
            </>
          )}
          {!isTablet && (
            <Button
              data-test="save"
              form="participant-add-form"
              wide
              disabled={Object.values(fieldErrors).filter(Boolean).length > 0}
              inProgress={inProgress}
              onClick={() => onSubmit()}
            >
              Save
            </Button>
          )}
        </InlineGroup>
      </InlineGroup>
      {isTablet && (
        <InlineGroup className="pt-5" block spread>
          <Button
            data-test="cancel"
            form="participant-add-form"
            wide
            light
            inProgress={inProgress}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            data-test="save"
            form="participant-add-form"
            wide
            disabled={Object.values(fieldErrors).filter(Boolean).length > 0}
            inProgress={inProgress}
            onClick={() => onSubmit()}
          >
            Save
          </Button>
        </InlineGroup>
      )}
    </VerticalGroup>
  );
}

export default ParticipantForm;
