// QuoteSection.tsx

import React from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import ResultsText from "../../results-text/ResultsText";

interface QuoteSectionProps {
  quote: string;
  author?: string;
}

export const QuoteSection: React.FC<QuoteSectionProps> = ({
  quote,
  author,
}) => (
  <VerticalGroup center wide className="poor-risk-container">
    <ResultsText bold italic center size="lg">
      {quote}
    </ResultsText>
    {author && <ResultsText size="lg">{author}</ResultsText>}
  </VerticalGroup>
);

export default QuoteSection;
