import React from "react";
import ParticipantTable, {
  ReportingParticipant,
} from "../components/ParticipantTable";

interface Props {
  participants: ReportingParticipant[];
  brandingLogo: string;
}

const IntroCheckTeam: React.FC<Props> = ({ participants, brandingLogo }) => {
  return (
    <ParticipantTable
      defaultTitle="Check You Are In The Correct Team"
      participants={participants}
      brandingLogo={brandingLogo}
    />
  );
};

export default IntroCheckTeam;
