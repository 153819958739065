import { useState, useEffect } from "react";

const useIsMonitor = (customBreakpoint?: number): boolean => {
  const [isMonitor, setIsMonitor] = useState(false);

  useEffect(() => {
    const checkIsMonitor = () => {
      const monitorBreakpoint = customBreakpoint || 1920;
      setIsMonitor(window.innerWidth >= monitorBreakpoint);
    };

    checkIsMonitor();

    window.addEventListener("resize", checkIsMonitor);

    return () => {
      window.removeEventListener("resize", checkIsMonitor);
    };
  }, [customBreakpoint]);

  return isMonitor;
};

export default useIsMonitor;
