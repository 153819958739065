import React, { useState, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";

import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import Button from "../../../../atoms/button/Button";
import Form from "../../../../atoms/form/Form";
import KeyValueInput from "./KeyValueInput";

interface Props {
  metadata?: API.SimulationResponse["metadata"];
  setFieldRaw: (field: string, value: any) => void;
}

const PropertyViewer: React.FC<Props> = ({ metadata, setFieldRaw }) => {
  const [formState, setFormState] = useState<
    Record<string, { key?: string; value?: string | number }>
  >(() => {
    const state: Record<string, { key: string; value: string | number }> = {};
    for (const [key, value] of Object.entries(metadata ?? {})) {
      if (key !== undefined && value !== undefined) {
        state[uuidv4()] = { key, value } as {
          key: string;
          value: string | number;
        };
      }
    }
    return state;
  });

  const onMetadataChange = useCallback(
    (id, key, value) => {
      setFormState((state) => {
        const updatedState: Record<
          string,
          { key?: string; value?: string | number }
        > = {
          ...state,
          [id]: { key, value },
        };
        const updatedMetadata = Object.values(updatedState).reduce<any>(
          (acc, meta) => {
            const { key, value } = meta;
            if (key !== undefined && value !== undefined) {
              acc[key] = value;
            }
            return acc;
          },
          {},
        );
        setFieldRaw("metadata", updatedMetadata);

        return updatedState;
      });
    },
    [setFieldRaw],
  );

  const addNewLine = useCallback(() => {
    const newId = uuidv4();
    setFormState((state) => ({
      ...state,
      [newId]: { key: undefined, value: undefined },
    }));
  }, []);

  const removeLine = useCallback((id: string) => {
    setFormState((state) => {
      delete state[id];
      return { ...state };
    });
  }, []);

  return (
    <Form id="simulation-metadata-form">
      <VerticalGroup wide>
        {Object.entries(formState).map(([id, metadata]) => {
          return (
            <KeyValueInput
              id={id}
              key={id}
              keyName={metadata.key}
              value={metadata.value}
              onChange={onMetadataChange}
              onRemove={removeLine}
            />
          );
        })}
        <Button data-test="add-new-line" className="mt-1" onClick={addNewLine}>
          Add
        </Button>
      </VerticalGroup>
    </Form>
  );
};

export default PropertyViewer;
