import React, { useCallback } from "react";
import { Link } from "react-router-dom";

import Input from "../../atoms/form/input/Input";
import InlineError from "../../atoms/inlineerror/InlineError";
import useQuery from "../../../hooks/useQuery";
import useForm from "../../../hooks/useForm";
import API from "../../../services/api";
import Button from "../../atoms/button/Button";
import PublicPageContainer from "./PublicPageContainer";
import Form from "../../atoms/form/Form";
import Text from "../../atoms/text/Text";

const fieldNameMapper = {
  password: "Your password",
};

function ResetPassword() {
  const query = useQuery();
  const [
    {
      formData: { token, userId, password, confirmPassword },
      inProgress,
      fieldErrors,
      error,
      completed,
    },
    { setField, handleSubmit },
  ] = useForm(
    {
      token: query.get("token"),
      userId: query.get("userId"),
      password: "",
      confirmPassword: "",
    },
    { fieldNameMapper },
  );

  const invalidPage = !token || !userId;

  const callback = useCallback(() => {
    return API.resetPassword(userId!, { token: token!, password });
  }, [token, userId, password]);

  const validate = useCallback(() => {
    const errors = [];
    if (!password) {
      errors.push({ field: "password", message: "Enter a password" });
    }
    if (!confirmPassword) {
      errors.push({
        field: "confirmPassword",
        message: "Confirm your password",
      });
    }

    if (password && confirmPassword && password !== confirmPassword) {
      errors.push({ field: "password", message: "Passwords don't match" });
      errors.push({
        field: "confirmPassword",
        message: "Passwords don't match",
      });
    }

    return errors;
  }, [password, confirmPassword]);

  const renderError = () => {
    return (
      <Text colour="white" data-test="text-summary">
        Invalid url
      </Text>
    );
  };

  const renderSuccess = () => {
    return (
      <Text colour="white" data-test="text-summary">
        Password successfully changed, click{" "}
        <Link to="/" data-test="login-link">
          here
        </Link>{" "}
        to login
      </Text>
    );
  };

  const renderForm = () => {
    return (
      <>
        <Text colour="white" data-test="text-summary">
          Reset your password
        </Text>
        <Form
          className="mt-6"
          onSubmit={handleSubmit(callback, validate)}
          center
          narrow
        >
          <Input
            autoFocus
            data-test="password"
            type="password"
            value={password}
            onChange={setField("password")}
            error={fieldErrors.password}
            placeholder="Password"
            large
          />
          <Input
            data-test="confirm-password"
            type="password"
            value={confirmPassword}
            onChange={setField("confirmPassword")}
            error={fieldErrors.confirmPassword}
            placeholder="Confirm Password"
            large
          />
          <InlineError active={!!error} message={error?.message} block />
          <Button
            data-test="reset-password-button"
            type="submit"
            inProgress={inProgress}
            disabled={inProgress}
            large
            block
          >
            Reset
          </Button>
        </Form>
      </>
    );
  };

  return (
    <PublicPageContainer>
      {completed && !error
        ? renderSuccess()
        : invalidPage
          ? renderError()
          : renderForm()}
    </PublicPageContainer>
  );
}

export default ResetPassword;
