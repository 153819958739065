import React, { forwardRef, useMemo } from "react";
import Table, { Td, Th, Tr } from "../../../../atoms/table/Table";
import { EventMapping } from "../utils/mapInitialTabletMappingForEvent";
import Printable from "../../../../atoms/printable/Printable";
import "./PrintTabletsPage.scss";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import PrintPreviewGameDetails from "../../../game/PrintPreviewGameDetails";
import Text from "../../../../atoms/text/Text";
import Image from "../../../../atoms/image/Image";

interface PrintTabletsPageProps {
  tabletData: EventMapping | null;
  event: API.EventResponse;
}

const PrintTabletsPage = forwardRef(
  ({ tabletData, event }: PrintTabletsPageProps, ref) => {
    const participantDetails = event.participants;
    const tabletsData = useMemo(() => {
      if (!tabletData) return [];

      return tabletData.tablets
        .filter((tablet) => {
          return participantDetails.some((p) => p.id === tablet.participantId);
        })
        .map((tablet) => {
          const participant = participantDetails.find(
            (p) => p.id === tablet.participantId,
          );
          const teamColour = participant?.team
            ? tabletData.teamColours[participant.team - 1]
            : "";

          return {
            tabletId: tablet.tabletId,
            tabletColour: tablet.colour,
            teamColour,
          };
        });
    }, [participantDetails, tabletData]);

    const renderTableHeader = () => (
      <thead className="tablets-header">
        <tr>
          <th colSpan={6}>
            <VerticalGroup spaceBetweenElements={2} center>
              <Text center bold size="lg">
                Tablets Check List
              </Text>
              <InlineGroup block stretch verticalCenter spread>
                <VerticalGroup className="game-details">
                  <PrintPreviewGameDetails
                    event={event}
                    tabletCount={tabletsData.length}
                  />
                </VerticalGroup>

                <Image
                  height={36}
                  src={event.branding?.brandingImage?.imageUrl}
                  alt="branding image"
                />
              </InlineGroup>
            </VerticalGroup>
          </th>
        </tr>
        <Tr>
          <Th>Count</Th>
          <Th>Tablet ID</Th>
          <Th>Tablet Colour</Th>
          <Th>Team Colour</Th>
          <Th>Charged</Th>
          <Th>Cleaned</Th>
          <Th>Packed</Th>
        </Tr>
      </thead>
    );

    return (
      <Printable ref={ref} orientation="portrait">
        <VerticalGroup wide spread center className="tablets-print-page">
          <Table className="tablets-table">
            {renderTableHeader()}
            <tbody>
              {tabletsData.map((tablet, index) => (
                <Tr key={tablet.tabletId}>
                  <Td>{index + 1}</Td>
                  <Td>{tablet.tabletId}</Td>
                  <Td>
                    <Text>{tablet.tabletColour}</Text>
                  </Td>
                  <Td>
                    <Text>{tablet.teamColour}</Text>
                  </Td>
                  <Td>
                    <input type="checkbox" />
                  </Td>
                  <Td>
                    <input type="checkbox" />
                  </Td>
                  <Td>
                    <input type="checkbox" />
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        </VerticalGroup>
      </Printable>
    );
  },
);

PrintTabletsPage.displayName = "PrintTabletsPage";

export default PrintTabletsPage;
