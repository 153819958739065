import React, { useState, useEffect, useCallback } from "react";

import Input from "../../atoms/form/input/Input";
import InlineError from "../../atoms/inlineerror/InlineError";
import { useUserActions } from "../../../context/userContext";
import useAPIRequest from "../../../hooks/useAPIRequest";
import API from "../../../services/api";
import Button from "../../atoms/button/Button";
import PublicPageContainer from "./PublicPageContainer";
import Form from "../../atoms/form/Form";
import { Link, useLocation } from "react-router-dom";
import Text from "../../atoms/text/Text";

interface Location {
  email?: string | null;
}

function Login() {
  const location = useLocation<Location>();

  const [email, setEmail] = useState(location?.state?.email ?? "");
  const [password, setPassword] = useState("");

  const { setToken } = useUserActions();

  function handleEmailChange(e: any) {
    setEmail(e.target.value);
  }

  function handlePasswordChange(e: any) {
    setPassword(e.target.value);
  }

  const callback = useCallback(() => {
    return API.login(email, password);
  }, [email, password]);

  const [{ inProgress, data, error }, doAPIRequest] =
    useAPIRequest<API.LoginResponse>(callback, null);

  const handleLogin = (e: any) => {
    e.preventDefault();
    doAPIRequest();
  };

  useEffect(() => {
    if (data && data.token) {
      setToken(data.token);
    }
  }, [data, setToken]);

  const canLogin = !inProgress && !!email.length && !!password.length;

  return (
    <PublicPageContainer>
      <Form className="mt-6" onSubmit={handleLogin} center narrow>
        <Input
          autoFocus
          data-test="email"
          type="text"
          value={email}
          onChange={handleEmailChange}
          placeholder="Email"
          large
          allowLastPass
        />
        <Input
          data-test="password"
          type="password"
          value={password}
          onChange={handlePasswordChange}
          placeholder="Password"
          large
          allowLastPass
        />
        <InlineError active={!!error} message={error?.message} block />
        <Button
          data-test="loginButton"
          type="submit"
          inProgress={inProgress}
          disabled={!canLogin}
          large
          block
        >
          Login
        </Button>
        <Link
          to={{ pathname: "/forgot-password", state: { email } }}
          data-test="forgot-password-link"
        >
          <Text>Forgot your password?</Text>
        </Link>
      </Form>
    </PublicPageContainer>
  );
}

export default Login;
