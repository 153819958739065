import React from "react";
import NewWSFIssued from "./NewWSFIssued";
import TermOutstanding from "./TermOutstanding";
import Repayments from "./Repayments";
import OutstandingWholesaleFunding from "./OutstandingWholesaleFunding";
import OutstandingWholesaleFundingByTenor from "./OutstandingWholesaleFundingByTenor";
import AverageWholesaleFunding from "./AverageWholesaleFunding";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["wholesaleFundingMovements"];
  fields: Array<{ field: string; name: string }>;
}

const showOutstanding = false;

const WholesaleFundingMovements: React.FC<Props> = ({
  fields,
  data,
  configuration,
}) => {
  return (
    <>
      <NewWSFIssued configuration={configuration} data={data} fields={fields} />
      {showOutstanding && (
        <TermOutstanding
          configuration={configuration}
          data={data}
          fields={fields}
        />
      )}
      <Repayments configuration={configuration} data={data} fields={fields} />
      <OutstandingWholesaleFunding
        configuration={configuration}
        data={data}
        fields={fields}
      />
      <OutstandingWholesaleFundingByTenor
        configuration={configuration}
        data={data}
        fields={fields}
      />
      <AverageWholesaleFunding
        configuration={configuration}
        data={data}
        fields={fields}
      />
    </>
  );
};

export default WholesaleFundingMovements;
