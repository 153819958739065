import React from "react";
import Modal from "../../../atoms/modal/Modal";
import ModalBody from "../../../molecules/modal-body/ModalBody";
import ResultsFontMutiplierDropdown from "./ResultsFontMultiplierDropdown";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Button from "../../../atoms/button/Button";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const RoundResultsFontMultiplierModal = ({ isOpen, onClose }: Props) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalBody
        type="standard"
        title="Font Size"
        description="Update the font size"
      >
        {" "}
        <VerticalGroup spaceBetweenElements={4} className="mt-2">
          <ResultsFontMutiplierDropdown />
          <InlineGroup spread reverse>
            <Button
              data-test="modal-confimation-confirm"
              onClick={onClose}
              large
            >
              Close
            </Button>
          </InlineGroup>
        </VerticalGroup>
      </ModalBody>
    </Modal>
  );
};

export default RoundResultsFontMultiplierModal;
