import React, { useState, useEffect, useCallback } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Icon from "../../../atoms/icon/Icon";
import "./ResultsInfoCard.scss";
import { ThemeColours } from "../../../../types/theme";
import classNames from "classnames";
import ResultsText from "../results-text/ResultsText";
import { useResultsContext } from "../context/context";

interface ResultsInfoCardProps {
  themeColour?: ThemeColours;
  headerText: string;
  BadgeText: string;
  infoPoints: string[];
  animateBadge?: boolean;
  initialTimeInMinutes?: number;
}

const ResultsInfoCard: React.FC<ResultsInfoCardProps> = ({
  themeColour = "blue",
  headerText,
  BadgeText,
  infoPoints,
  animateBadge = false,
  initialTimeInMinutes = 10,
}) => {
  const context = useResultsContext();
  const [showSecondBadge, setShowSecondBadge] = useState(false);
  const [hideFirstBadge, setHideFirstBadge] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [timeLeft, setTimeLeft] = useState(initialTimeInMinutes * 60);

  const formatTime = useCallback((seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  }, []);

  const startTimer = useCallback(() => {
    setShowTimer(true);
  }, []);

  const handleFirstBadgeAnimationEnd = useCallback(() => {
    setHideFirstBadge(true);
    setShowSecondBadge(true);
  }, []);

  const handleSecondBadgeAnimationEnd = useCallback(() => {
    startTimer();
  }, [startTimer]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (showTimer && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [showTimer, timeLeft]);

  return (
    <div className="info-container-wrapper">
      <Icon
        colour={themeColour}
        stroke={themeColour}
        type="infoContainer"
        dynamicSize={{
          min: "250px",
          preferred: "33vw",
          max: "2100px",
        }}
        className="info-icon"
      />
      <div className="bank-info-content">
        <VerticalGroup full wide className="content-wrapper">
          <InlineGroup
            spaceBetweenElements={4}
            verticalCenter
            style={{ height: "45%", paddingLeft: "1rem" }}
            block
            className="header-container"
          >
            <div
              className={classNames("badge-wrapper", { hide: hideFirstBadge })}
            >
              <InlineGroup
                className={classNames("circle-badge", {
                  animate: !context.noAnimations && animateBadge,
                })}
                bgColour={themeColour}
                onAnimationEnd={handleFirstBadgeAnimationEnd}
              >
                <ResultsText colour="white" bold>
                  {BadgeText}
                </ResultsText>
              </InlineGroup>
            </div>
            <div className="header-wrapper">
              <ResultsText colour={themeColour} bold>
                {headerText}
              </ResultsText>
            </div>
          </InlineGroup>

          <ul className="requirements-list">
            <VerticalGroup spaceBetweenElements={5}>
              {infoPoints.map((point, index) => (
                <li key={`info-point-${index}`} className="info-point-item">
                  <ResultsText left>{point}</ResultsText>
                </li>
              ))}
            </VerticalGroup>
          </ul>

          {!context.noAnimations && showSecondBadge && (
            <div className="second-badge-wrapper">
              <InlineGroup
                className="circle-badge"
                bgColour={themeColour}
                onAnimationEnd={handleSecondBadgeAnimationEnd}
              >
                <ResultsText
                  colour="white"
                  bold
                  className={classNames("timer-text", {
                    show: showTimer,
                  })}
                >
                  {formatTime(timeLeft)}
                </ResultsText>
              </InlineGroup>
            </div>
          )}
        </VerticalGroup>
      </div>
    </div>
  );
};

export default ResultsInfoCard;
