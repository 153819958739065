import React, { useCallback } from "react";
import Card from "../../../atoms/card/Card";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import EditableReportingHeading from "../components/EditableReportingHeading";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Icon from "../../../atoms/icon/Icon";
import "./IntroRegulatoryCapitalCreditRating.scss";
import ReportingSubHeading from "../components/ReportingSubHeading";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import ResultsText from "../results-text/ResultsText";
import { useResultsContext } from "../context/context";
import classNames from "classnames";

export const regulatoryCapitalCreditRatingMaxClicks = 4;
const CREDIT_RATINGS = ["AAA", "AA", "A", "BBB", "BB", "B"];

const IntroRegulatoryCapitalCreditRating = () => {
  const context = useResultsContext();

  const onNext = useCallback(() => {
    if (context.clicks < regulatoryCapitalCreditRatingMaxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context]);

  const showSubHeading = context.noAnimations || context.clicks >= 1;
  const showAllContent = context.noAnimations || context.clicks >= 2;
  const animateScale = context.noAnimations || context.clicks >= 3;
  const animatePointer = context.noAnimations || context.clicks >= 4;

  return (
    <RoundResultsContainer onNext={onNext}>
      <Card wide fullHeight className="capital-rating-slide">
        <VerticalGroup wide center full spread>
          <EditableReportingHeading defaultHeading="Capital is a regulatory requirement" />
          <InlineGroup
            center
            className={classNames("capital-subheading", {
              show: showSubHeading,
            })}
          >
            <ReportingSubHeading subHeading="Banks are required to hold minimum capital levels to protect those who trust the bank with their savings (Depositors)" />
          </InlineGroup>

          <InlineGroup
            className={classNames("capital-rating-container", {
              show: showAllContent,
            })}
            verticalCenter
            center
            spread
            fullHeight
          >
            <VerticalGroup wide center full>
              <div
                className={classNames("scale-animation", {
                  animate: animateScale,
                })}
              >
                <Icon type="strengthScales" fullSize />
              </div>
              <div className="bottom-icon">
                <Icon type="strengthScalesBottom" fullSize />
              </div>
            </VerticalGroup>

            <InlineGroup className="rating-container">
              <VerticalGroup
                className="rating-heading-container"
                spread
                full
                center
              >
                <ResultsText center bold colour="primaryDark">
                  CREDIT RATING
                </ResultsText>

                {CREDIT_RATINGS.map((rating) => (
                  <InlineGroup
                    key={rating}
                    className="rating-item"
                    center
                    block
                    bgColour="blue"
                  >
                    <ResultsText>{rating}</ResultsText>
                  </InlineGroup>
                ))}
              </VerticalGroup>

              <div className="pointer-container">
                <div
                  className={classNames("pointer-animation", {
                    animate: animatePointer,
                  })}
                >
                  <Icon
                    className=""
                    type="pointer"
                    dynamicSize={{
                      min: "50px",
                      preferred: "7vw",
                      max: "900px",
                    }}
                  />
                </div>
              </div>
            </InlineGroup>
          </InlineGroup>
        </VerticalGroup>
      </Card>
    </RoundResultsContainer>
  );
};

export default IntroRegulatoryCapitalCreditRating;
