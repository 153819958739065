import classNames from "classnames";
import React from "react";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import Ribbon from "../../atoms/ribbon/Ribbon";

import "./GameProgressBar.scss";

interface Props {
  className?: string;
  screen: "timer" | "bonus" | "close";
  setSelectedScreen: React.Dispatch<
    React.SetStateAction<"timer" | "bonus" | "close" | null>
  >;
  hideBonusTime?: boolean;
}

function GameProgressBar({
  className,
  screen,
  setSelectedScreen,
  hideBonusTime,
}: Props) {
  return (
    <InlineGroup
      className={classNames("game-progress-bar", className)}
      verticalCenter
    >
      <Ribbon
        compact
        first
        active={screen === "timer"}
        title="Timer Control"
        icon="timer"
        onClick={() => setSelectedScreen("timer")}
      />
      {!hideBonusTime && (
        <Ribbon
          compact
          active={screen === "bonus"}
          title="Bonus Time"
          icon="gift"
          className="ml-2"
          onClick={() => setSelectedScreen("bonus")}
        />
      )}
      <Ribbon
        compact
        last
        active={screen === "close"}
        title="Round Results"
        icon="report"
        className="ml-2"
        onClick={() => setSelectedScreen("close")}
      />
    </InlineGroup>
  );
}

export default GameProgressBar;
