import React, { useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import Page from "../../../atoms/page/Page";
import { QuestionForm } from "./QuestionForm";
import API from "../../../../services/api";

const AssessmentAddQuestion: React.FC = () => {
  const { assessmentId, groupId } = useParams<{
    assessmentId: string;
    groupId: string;
  }>();
  const history = useHistory();

  const onSave = useCallback(
    async (
      groupId: string,
      payload: API.AssessmentQuestionRequest,
    ): Promise<API.AssessmentQuestionResponse> => {
      return API.createAssessmentQuesiton(assessmentId, groupId, payload);
    },
    [assessmentId],
  );

  const onComplete = useCallback(() => {
    history.push(`/assessments/${assessmentId}/view?groupId=${groupId}`);
  }, [assessmentId, history, groupId]);

  return (
    <Page>
      <QuestionForm
        mode="create"
        assessmentId={assessmentId}
        groupId={groupId}
        onSave={onSave}
        onComplete={onComplete}
        onCancel={onComplete}
      />
    </Page>
  );
};

export default AssessmentAddQuestion;
