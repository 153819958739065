import React, { useMemo } from "react";
import classNames from "classnames";

import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import "./Stepper.scss";
import { PrivateProps as StepProps } from "./Step";

interface Props {
  children: React.ReactNode;
  direction: "horizontal" | "vertical";
}

function Stepper({ children, direction }: Props) {
  // Assigning index and current index to each step
  const childrenArray = useMemo(
    () => React.Children.toArray(children),
    [children],
  );
  const steps = useMemo(() => {
    return childrenArray.map((step, index) => {
      if (React.isValidElement(step)) {
        return React.cloneElement<StepProps>(step, {
          index,
          lastIndex: childrenArray.length - 1,
          direction,
          ...step.props,
        });
      }
      // This means that if any child is not a valid React.Element, it will be ignored
      return null;
    });
  }, [childrenArray, direction]);

  const stepperClass = classNames(
    "stepper",
    { horizontal: direction === "horizontal" },
    { vertical: direction === "vertical" },
  );

  return (
    <div className={stepperClass}>
      {direction === "horizontal" && (
        <InlineGroup spaceBetweenElements={2} className="h-16" block>
          {steps}
        </InlineGroup>
      )}
      {direction === "vertical" && <VerticalGroup wide>{steps}</VerticalGroup>}
    </div>
  );
}

export { useStepperContextValue, StepperContext } from "./context";
export default Stepper;
