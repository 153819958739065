import axios, { AxiosHeaderValue, AxiosRequestConfig } from "axios";
import APIError from "../errors/APIError";
import { saveAs } from "file-saver";

interface GetParams {
  url: string;
  headers?: { [header: string]: string };
}

interface PostParams extends GetParams {
  body?: any;
}

interface HttpResponse<data> {
  status: number;
  headers?: { [header: string]: AxiosHeaderValue | undefined };
  data: data;
}

const doRequest = async <data>(
  params: AxiosRequestConfig,
): Promise<HttpResponse<data>> => {
  try {
    const { status, headers, data } = await axios.request<data>(params);
    return { status, headers, data };
  } catch (e) {
    if (e.message === "Network Error") {
      throw new Error(
        "You have lost connection to our servers. Please check your internet connection and then refresh this webpage.",
      );
    }
    if (e.response) {
      if (e.response.status === 401) {
        throw new APIError({
          status: 401,
          message: e.response.data.message,
          type: "unauthenticated",
        });
      }
      // ascension-api and ascension-admin-api has two different ways of returning errors, will need to update once that is patched
      const message =
        (e.response.data &&
          (e.response.data.message ||
            (e.response.data.error && e.response.data.error.message))) ||
        e.message;
      throw new APIError({
        status: e.response.status,
        message: message,
        errors: e.response.data && e.response.data.errors,
      });
    }
    throw e;
  }
};

export const get = async <data>(
  params: GetParams,
): Promise<HttpResponse<data>> => {
  return doRequest<data>({
    method: "get",
    url: params.url,
    headers: params.headers || {},
  });
};

export const post = async <data>(
  params: PostParams,
): Promise<HttpResponse<data>> => {
  return doRequest<data>({
    method: "post",
    url: params.url,
    headers: params.headers || {},
    data: params.body || {},
  });
};

export const doDelete = async <data>(
  params: GetParams,
): Promise<HttpResponse<data>> => {
  return doRequest<data>({
    method: "delete",
    url: params.url,
    headers: params.headers || {},
  });
};

export const put = async <data>(
  params: PostParams,
): Promise<HttpResponse<data>> => {
  return doRequest<data>({
    method: "put",
    url: params.url,
    headers: params.headers || {},
    data: params.body || {},
  });
};

export const patch = async <data>(
  params: PostParams,
): Promise<HttpResponse<data>> => {
  return doRequest<data>({
    method: "patch",
    url: params.url,
    headers: params.headers || {},
    data: params.body || {},
  });
};

export const download = async (
  url: string,
  options: { [field: string]: any },
  type: string,
  filename: string,
) => {
  const response = await axios.get<ArrayBuffer>(url, options);
  const blob = new Blob([response.data], { type: type });
  saveAs(blob, filename);
};
