import React, { useRef, useCallback } from "react";
import { useEffect } from "react";
import Input from "../../../../../atoms/form/input/Input";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import IconButton from "../../../../../molecules/iconbutton/IconButton";

export type OnChangeParam = { label?: string; rating?: number; index: number };

export const NA_INDEX = -1;

interface BaseProps {
  label: string;
  onChange: (value: OnChangeParam) => void;
}

interface Props extends BaseProps {
  index: number;
  rating?: number;
  error?: string;
  isNew: boolean;
  onDelete: (index: number) => void;
  onAppendNewRating: (index: number) => void;
}

const parseRating = (rating: string) => {
  const clean = rating.replace(/[^\d]/g, "");
  const number = Number.parseInt(clean, 10);

  if (isNaN(number)) return 0;
  return number;
};

export const NARatingField: React.FC<BaseProps> = ({ label, onChange }) => {
  const onChangeLabel = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const label = e.target.value;
      onChange({ label, index: NA_INDEX });
    },
    [onChange],
  );

  return (
    <InlineGroup block spaceBetweenElements={2}>
      <Input type="text" value={label} onChange={onChangeLabel} />
    </InlineGroup>
  );
};

export const RatingField: React.FC<Props> = ({
  index,
  label,
  rating = 0,
  isNew,
  error,
  onChange,
  onAppendNewRating,
  onDelete,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const onChangeLabel = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const label = e.target.value;
      onChange({ label, index });
    },
    [onChange, index],
  );

  const onChangeRating = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const rating = e.target.value;
      onChange({ rating: parseRating(rating), index });
    },
    [onChange, index],
  );

  const onPlusClick = useCallback(() => {
    onAppendNewRating(index);
  }, [index, onAppendNewRating]);

  const onDeleteClick = useCallback(() => {
    onDelete(index);
  }, [index, onDelete]);

  useEffect(() => {
    if (inputRef.current && isNew) {
      inputRef.current.focus();
    }
  }, [isNew]);

  const ratingValue = isNaN(rating) ? "" : rating;

  return (
    <InlineGroup block spaceBetweenElements={2}>
      <Input
        ref={inputRef}
        type="text"
        value={label}
        onChange={onChangeLabel}
        autoFocus={isNew ? true : false}
        error={error}
      />
      <Input type="text" small value={ratingValue} onChange={onChangeRating} />
      <IconButton
        mini
        noTab
        noUi
        light={false}
        wide={false}
        icon="roundPlus"
        onClick={onPlusClick}
      />
      <IconButton
        mini
        noTab
        noUi
        light={false}
        wide={false}
        icon="roundMinus"
        onClick={onDeleteClick}
      />
    </InlineGroup>
  );
};
