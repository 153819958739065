import React, { useCallback, useState } from "react";
import Container from "../../../../atoms/page/Page";
import ImportEventsListDropdown from "../../../../organisms/event-dropdown/ImportEventsListDropDown";
import GameDetails from "../../../game/GameDetails";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import Button from "../../../../atoms/button/Button";
import useAPIRequest from "../../../../../hooks/useAPIRequest";
import Banner from "../../../../atoms/banner/Banner";
import AdminAPI from "../../../../../services/api";

function ImportEvent() {
  const [dismissError, setDismissError] = useState(false);
  const [dismissMessage, setDismissMessage] = useState(false);
  const [selectedEventData, setSelectedEventData] = useState<
    API.EventResponseSummary | undefined
  >(undefined);

  const callback = useCallback(() => {
    return AdminAPI.importEvent(selectedEventData?.id ?? "");
  }, [selectedEventData]);

  const [{ completed, inProgress, error }, doAPIRequest] = useAPIRequest(
    callback,
    null,
  );

  const handleImport = useCallback(async () => {
    setDismissError(false);
    setDismissMessage(false);
    doAPIRequest();
  }, [doAPIRequest]);

  return (
    <Container noOverflow>
      {error && (
        <Banner
          type="error"
          message={error.message}
          active={!dismissError}
          onClose={() => setDismissError(true)}
        />
      )}
      {completed && (
        <Banner
          type="success"
          message="Event imported"
          active={!dismissMessage}
          onClose={() => setDismissMessage(true)}
        />
      )}
      <h2>Import Event</h2>
      <VerticalGroup spaceBetweenElements={2}>
        <ImportEventsListDropdown
          selectedEventId={selectedEventData?.id}
          onEventSelected={setSelectedEventData}
        />
        {selectedEventData && <GameDetails event={selectedEventData} />}
        <Button onClick={handleImport} inProgress={inProgress}>
          Import
        </Button>
      </VerticalGroup>
    </Container>
  );
}

export default ImportEvent;
