import React, { useCallback, useState } from "react";

import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import Input from "../../../../atoms/form/input/Input";

interface Props {
  metadata?: API.SimulationResponse["metadata"];
  setFieldRaw: (field: string, value: any) => void;
  setError: (field: string, message: string | undefined) => void;
}

const JsonViewer: React.FC<Props> = ({ metadata, setFieldRaw, setError }) => {
  const [value, setValue] = useState(() => {
    return JSON.stringify(metadata, null, 2);
  });

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        setValue(event.target.value);
        setFieldRaw("metadata", JSON.parse(event.target.value));
      } catch (err) {
        setError("metadata", err.message);
      }
    },
    [setError, setFieldRaw],
  );

  return (
    <VerticalGroup spaceBetweenElements={2} className="editabletext">
      <InlineGroup spaceBetweenElements={2}>
        <Input block type={"textbox"} value={value} onChange={onChange} />
      </InlineGroup>
    </VerticalGroup>
  );
};

export default JsonViewer;
