import React, { useCallback } from "react";
import IntegerDropdown from "../integer-dropdown/IntegerDropdown";
import {
  formatPercentage,
  wsfConvertDivide,
} from "../../pages/administration/configuration/economicScenarioBuilder/calculations/utils";

interface Params {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  step: number;
  midsize?: boolean;
  small?: boolean;
  menuPlacement?: "bottom" | "top";
  label?: string;
  direction?: "desc" | "asc";
  dp?: number;
}

const PercentageDropdown = ({
  value,
  onChange,
  min = 0,
  max = 100,
  step,
  midsize,
  menuPlacement,
  label,
  direction,
  small,
  dp = 4,
}: Params) => {
  const smallish = !midsize && !small;
  const onValueChange = useCallback(
    (option: { value: number }) => {
      onChange(option.value * 10000);
    },
    [onChange],
  );

  return (
    <IntegerDropdown
      value={Number(wsfConvertDivide(value / 100, dp))}
      decimalPlaces={dp}
      onChange={onValueChange}
      min={min}
      max={max}
      step={step}
      smallish={smallish}
      small={small}
      midsize={midsize}
      isCreatable
      labelFormatter={(val) => formatPercentage(val, dp)}
      menuPlacement={menuPlacement}
      label={label}
      direction={direction}
    />
  );
};

export default PercentageDropdown;
