export const mockData = [
  {
    name: "commonwealth bank",
    value: 2900000000,
    fill: "#FFE8A4",
    logo: "commbank",
    displayValue: "$2.9Bn",
  },
  {
    name: "westpac",
    value: 2500000000,
    fill: "#FF6251",
    logo: "westpac",
    displayValue: "$2.5Bn",
  },
  {
    name: "nab",
    value: 3900000000,
    fill: "#181818",
    logo: "nab",
    displayValue: "$3.0Bn",
  },
  {
    name: "anz",
    value: 2100000000,
    fill: "#00A2EE",
    logo: "anz",
    displayValue: "$2.1Bn",
  },
];

export const mockDataPageTwo = [
  {
    name: "commbank",
    value: 500000000,
    fill: "#FFE8A4",
    logo: "commbank",
    displayValue: "$500m",
  },
  {
    name: "westpac",
    value: 1000000000,
    fill: "#FF6251",
    logo: "westpac",
    displayValue: "$1Bn",
  },
  {
    name: "nab",
    value: 500000000,
    fill: "#181818",
    logo: "nab",
    displayValue: "$500m",
  },
  {
    name: "anz",
    value: 750000000,
    fill: "#00A2EE",
    logo: "anz",
    displayValue: "$750m",
  },
  {
    name: "macbank",
    value: 500000000,
    fill: "#ffffff",
    logo: "macbank",
    displayValue: "$500m",
    border: true,
  },
];

export const mockDataPageTwoChange = [
  {
    name: "commbank",
    value: 500000000,
    fill: "transparent",
    logo: "commbank",
    displayValue: "",
    topLabel: "30 Sept 22...",
    additionalLogo: "tick",
  },
  {
    name: "westpac",
    value: 500000000,
    fill: "#FF6251",
    logo: "westpac",
    displayValue: "$500m",
    topLabel: "19 July 24...",
    additionalLogo: "tick",
  },
  {
    name: "nab",
    value: 500000000,
    fill: "transparent",
    logo: "nab",
    displayValue: "",
    topLabel: "13 March 24...",
    additionalLogo: "tick",
  },
  {
    name: "anz",
    value: 750000000,
    fill: "#00A2EE",
    logo: "anz",
    displayValue: "$750m",
  },
  {
    name: "macbank",
    value: 500000000,
    fill: "#ffffff",
    logo: "macbank",
    displayValue: "$500m",
    border: true,
  },
];
