import React from "react";

export interface ModelState {
  config?: ModelAPI.ConfigurationResponse;
  selectedRound: number;
  selectedTeam: number;
  gameStatus?: API.GameStatus;
}

/**
 * Action
 */
interface UpdateConfig {
  type: "UpdateConfig";
  payload: ModelAPI.ConfigurationResponse;
}

interface UpdateSelectedRound {
  type: "UpdateSelectedRound";
  payload: number;
}

interface UpdateGameStatus {
  type: "UpdateGameStatus";
  payload: API.GameStatus;
}

interface UpdateSelectedTeam {
  type: "UpdateSelectedTeam";
  payload: number;
}

export type Actions =
  | UpdateConfig
  | UpdateSelectedRound
  | UpdateGameStatus
  | UpdateSelectedTeam;

/**
 * Reducer
 */

export const reducer: React.Reducer<ModelState, Actions> = (state, action) => {
  switch (action.type) {
    case "UpdateConfig":
      return {
        ...state,
        config: action.payload,
      };
    case "UpdateSelectedRound":
      return {
        ...state,
        selectedRound: action.payload,
      };
    case "UpdateGameStatus":
      return {
        ...state,
        gameStatus: action.payload,
      };
    case "UpdateSelectedTeam":
      return {
        ...state,
        selectedTeam: action.payload,
      };
    default:
      return state;
  }
};
