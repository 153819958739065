import React, { useCallback } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./Datepicker.scss";
import Input from "../form/input/Input";
import InlineGroup from "../inlinegroup/InlineGroup";

interface Props {
  isVerticalLayout?: boolean;
  label?: string;
  date: Date | null;
  onChange: (value: Date | null) => void;
  autoFocus?: boolean;
  error?: string;
  withTime?: boolean;
  disabled?: boolean;
}

function Datepicker({
  isVerticalLayout,
  date,
  onChange,
  autoFocus,
  error,
  label,
  withTime = false,
  disabled = false,
}: Props) {
  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(new Date(e.target.value));
    },
    [onChange],
  );
  return (
    <InlineGroup spread>
      <DatePicker
        disabled={disabled}
        autoFocus={autoFocus}
        showTimeSelect={withTime}
        selected={date}
        onChange={onChange}
        dateFormat={withTime ? "EEE d MMMM yyyy h:mm aa" : "d MMMM yyyy"}
        timeIntervals={15}
        customInput={
          <Input
            isVerticalLayout={isVerticalLayout}
            data-lpignore="true"
            data-test="date"
            type="text"
            label={label}
            value={date?.toString()}
            onChange={onInputChange}
            error={error}
          />
        }
      />
    </InlineGroup>
  );
}

export default Datepicker;
