import React, { Component, ErrorInfo, useState } from "react";
import { Redirect } from "react-router-dom";

import APIError from "../../../errors/APIError";
import Container from "../../atoms/page/Page";
import ErrorSection from "./ErrorSection";

interface Props {
  children: React.ReactNode;
  logout: Context.UserContext["logout"];
}

interface State {
  hasError: boolean;
  error: Error | APIError | null;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error: error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log(error, errorInfo);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (prevState.hasError !== this.state.hasError) {
      if (
        this.state.error instanceof APIError &&
        this.state.error.type === "unauthenticated"
      ) {
        this.props.logout();
      }
    }
  }

  render() {
    const { hasError, error } = this.state;
    if (hasError && error) {
      if (error instanceof APIError && error.type === "unauthenticated") {
        return <Redirect to="/" />;
      }
      return (
        <Container className="error-page full-vh" noOverflow>
          <ErrorSection
            message="Something went wrong"
            secondaryMessage={
              <>
                If this problem persists, please contact our{" "}
                <a href="mailto: tech@excelerateconsulting.co"> Support Team</a>
              </>
            }
          />
        </Container>
      );
    }
    return this.props.children;
  }
}

export function useErrorHandler(err?: Error | APIError | null) {
  const [error, setError] = useState(err);
  if (err) throw err;
  if (error) throw error;
  return [setError];
}

export default ErrorBoundary;
