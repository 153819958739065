import React, { useEffect, useMemo, useState } from "react";

import Button from "../../../../atoms/button/Button";
import SimpleModal from "../../../../organisms/standard-modal/SimpleModal";
import Toggle from "../../../../atoms/toggle/Toggle";
import API from "../../../../../services/api";
import Text from "../../../../atoms/text/Text";
import Container from "../../../../atoms/page/Page";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import AssignTabletColourDropdown from "../Configuration/assign-tablet-colour-dropdown/AssignTabletColourDropdown";
import { getUniqueTabletColors } from "../utils/mapInitialTabletMappingForEvent";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  tablet?: API.TabletDetailResponse | null;
  refresh: () => void;
  tabletData: API.TabletMappingResponse;
}
function EditTabletModal({
  isOpen,
  onClose,
  tablet,
  refresh,
  tabletData,
}: Props) {
  const [isAvailable, setIsAvailable] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [tabletColor, setTabletColor] = useState<API.TabletColour>("red");
  const allTabletColors = useMemo(
    () => getUniqueTabletColors(tabletData.tablets),
    [tabletData.tablets],
  );

  useEffect(() => {
    if (tablet) {
      setIsAvailable(tablet.available);
      setTabletColor(tablet.colour as API.TabletColour);
    }
  }, [tablet]);

  const handleToggleChange = (checked: boolean) => {
    setIsAvailable(checked);
  };

  const handleColorSelect = (color: string) => {
    const tabletColour = color as API.TabletColour;
    setTabletColor(tabletColour);
  };

  const handleSaveSettings = async () => {
    if (tablet) {
      setIsUpdating(true);

      await updateTabletAvailability(
        tablet.tabletId,
        isAvailable,
        tablet.participantId || "",
        tabletColor,
      );
      refresh();
      onClose();
      setIsUpdating(false);
    }
  };

  const updateTabletAvailability = async (
    tabletId: number,
    available: boolean,
    participantId: string,
    colour?: API.TabletColour,
  ) => {
    const tabletUpdateDetails: API.TabletUpdateDetails = {
      tabletId,
      available,
      colour,
    };
    if (participantId) {
      tabletUpdateDetails.participantId = participantId;
    }

    const tabletUpdateRequest: API.TabletUpdateRequest = {
      tablets: [tabletUpdateDetails],
      teams: [],
    };

    await API.updateTabletMappings(tabletUpdateRequest);
  };
  return (
    <SimpleModal size="medium" isOpen={isOpen} onClose={onClose}>
      <VerticalGroup wide spaceBetweenElements={2}>
        <Text bold size="lg">
          Edit Tablet
        </Text>
        <Container fit>
          <VerticalGroup wide spaceBetweenElements={2}>
            <Toggle
              block
              label="Tablet Available"
              checked={isAvailable}
              onUpdate={handleToggleChange}
              helpDescription="Toggle to make tablet available or unavailable"
              helpTitle="Tablet Available"
            />
            <AssignTabletColourDropdown
              availableTabletColors={allTabletColors}
              onColorSelect={handleColorSelect}
              editselectedColor={tabletColor || ""}
            />
          </VerticalGroup>
        </Container>
        <InlineGroup block right>
          <Button onClick={handleSaveSettings} disabled={isUpdating}>
            Save Settings
          </Button>
        </InlineGroup>
      </VerticalGroup>
    </SimpleModal>
  );
}

export default EditTabletModal;
