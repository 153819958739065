import React from "react";
import classNames from "classnames";
import { Draggable, Droppable } from "react-beautiful-dnd";

export interface DragAndDropItem {
  view: React.ReactNode;
  id: string;
}

interface Props {
  items: DragAndDropItem[];
}

export const DRAGGABLE_ITEMS_POOL_ID = "draggable-items-pool";

const DraggableItemsPool: React.FC<Props> = ({ items }) => {
  return (
    <Droppable droppableId={DRAGGABLE_ITEMS_POOL_ID}>
      {(provided, snapshot) => {
        return (
          <div
            className={classNames("draggable-container")}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {snapshot.draggingOverWith && !snapshot.draggingFromThisWith && (
              <div>drop here to remove the metric</div>
            )}
            {((snapshot.draggingOverWith && snapshot.draggingFromThisWith) ||
              !snapshot.isDraggingOver) &&
              items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={classNames("draggable-item", {
                        isDragging: snapshot.isDragging,
                      })}
                      style={provided.draggableProps.style}
                    >
                      {item.view}
                    </div>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
};

export default DraggableItemsPool;
