import React from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import { deals } from "../constants";
import TableWithSections from "../tables/TableWithSections";
import { formatLossMultiplier, percentage } from "../utils/formatters";
import pluckResultsByType from "../utils/pluckResultsByType";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["companyValuation"];
}

const ModelTeamResultsCompanyValuation: React.FC<Props> = ({ data }) => {
  const terminalValueMultipleData = pluckResultsByType(
    data,
    "terminalValueMultiple",
  );
  const terminalValueData = pluckResultsByType(data, "terminalValue");
  const dealData = pluckResultsByType(data, "dealCashflows");
  const coreBusinessData = pluckResultsByType(data, "coreBusinessCashflows");
  const mAndABusinessCashflowsData = pluckResultsByType(
    data,
    "mAndABusinessCashflows",
  );
  const bankValuationData = pluckResultsByType(data, "bankValuation");
  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <TableWithSections
          header="Terminal Value Multiple"
          data={[
            {
              name: "",
              fields: [
                "spotROE",
                "averageROE",
                "playerRound",
                "completedGameRound",
              ],
              values: terminalValueMultipleData,
              formatter: (val: number, field: string) => {
                if (["playerRound", "completedGameRound"].includes(field)) {
                  const boolVal = val as unknown as boolean;
                  if (boolVal === true) return "True";
                  if (boolVal === false) return "False";
                  return "-";
                }
                return percentage(val);
              },
            },
            {
              name: "Variance",
              fields: ["variance", "positiveIncrements", "negativeIncrements"],
              values: pluckResultsByType(terminalValueMultipleData, "variance"),
              formatter: (val: number, field: string) => {
                if (field === "variance") return percentage(val);
                return null;
              },
            },
            {
              name: "Terminal Value Multiple - Bank",
              fields: ["openingBalance", "addMovement", "closingBalance"],
              values: pluckResultsByType(
                terminalValueMultipleData,
                "terminalValueMultipleBank",
              ),
              formatter: (val: number) => {
                return formatLossMultiplier(val);
              },
            },
            {
              name: "Terminal Value Multiple - WM",
              fields: ["openingBalance", "addMovement", "closingBalance"],
              values: pluckResultsByType(
                terminalValueMultipleData,
                "terminalValueMultipleWM",
              ),
              formatter: (val: number) => {
                return formatLossMultiplier(val);
              },
            },
          ]}
        />
        <TableWithSections
          header="Terminal Value"
          data={[
            {
              name: "Terminal Value - Domestic Business",
              fields: [
                "normalisedCapitalLevel",
                "investmentEquity",
                "closingBookValueExcludingMAndA",
                "wealthManagementEquityBookValue",
                "closingBookValueExcludingWM",
                "terminalValueBankingOnly",
                "impliedPEMultiple",
                "wealthManagementHistoricalNPAT",
                "terminalValueWMOnly",
              ],
              values: pluckResultsByType(
                terminalValueData,
                "terminalValueDomesticBusiness",
              ),
              formatter: (val: number, field: string) => {
                if (field === "impliedPEMultiple")
                  return formatLossMultiplier(val);
                return null;
              },
              fieldFormatter: (field: string) => {
                if (field === "closingBookValueExcludingMAndA") {
                  return "Closing Book Value (Excl M&A Deals)";
                }
                if (field === "closingBookValueExcludingWM") {
                  return "Closing Book Value (Excl WM)";
                }
                if (field === "impliedPEMultiple") {
                  return "Implied P/E Multiple";
                }
                if (field === "terminalValueBankingOnly") {
                  return "Terminal Value (Banking Only)";
                }
                return null;
              },
            },
            {
              fields: [
                "totalCoreBusinessTerminalValue",
                "terminalValueInvestments",
              ],
              isTotalsSection: true,
              values: terminalValueData,
            },
          ]}
        />
        <TableWithSections
          header="Deal (M&A) Cashflows"
          data={[
            {
              name: "Investment (M&A) Dividends",
              fields: deals.concat("buy-now-cry-later").concat("total"),
              totalFields: ["total"],
              values: pluckResultsByType(dealData, "investmentDividends"),
              fieldFormatter: (field: string) => {
                if (field === "total") {
                  return "Total Investment (M&A) Cashflows";
                }

                return null;
              },
            },
          ]}
        />
        <TableWithSections
          header="Core Business Cashflows"
          data={[
            {
              name: "Shareholder Cashflows - Assumed To Occur @ Start of Year",
              fields: [
                "shareBuybacks",
                "specialDividends",
                "coreOrdinaryShareIssues",
                "shareholderCashflowsExclTerminal",
              ],
              totalFields: ["shareholderCashflowsExclTerminal"],
              values: pluckResultsByType(
                coreBusinessData,
                "shareholderCashflowsStartOfYear",
              ),
              fieldFormatter: (field: string) => {
                if (field === "shareholderCashflowsExclTerminal") {
                  return "Shareholder Cashflows (Excl Terminal)";
                }

                return null;
              },
            },
            {
              name: "Shareholder Cashflows - Assumed To Occur @ End of Year",
              fields: [
                "coreDividendsOnly",
                "drpShareIssues",
                "shareholderCashflowsExclTerminal",
              ],
              totalFields: ["shareholderCashflowsExclTerminal"],
              values: pluckResultsByType(
                coreBusinessData,
                "shareholderCashflowsEndOfYear",
              ),
              fieldFormatter: (field: string) => {
                if (field === "shareholderCashflowsExclTerminal") {
                  return "Shareholder Cashflows (Excl Terminal)";
                }

                return null;
              },
            },
          ]}
        />
        <TableWithSections
          header="M & A Business Cashflows"
          data={[
            {
              name: "M&A Cashflows - Start of Year",
              fields: [
                "offshore-bank",
                "regional-bank",
                "home-loan-broker",
                "payments-partner",
                "neobank",
                "home-loan-book",
                "buy-now-cry-later",
                "mAndACashflowsExclTerminal",
              ],
              totalFields: ["mAndACashflowsExclTerminal"],
              values: pluckResultsByType(
                mAndABusinessCashflowsData,
                "mAndACashflowsStartOfYear",
              ),
              fieldFormatter: (field: string) => {
                if (field === "mAndACashflowsExclTerminal") {
                  return "M&A Cashflows (Excl Terminal)";
                }

                return null;
              },
            },
            {
              name: "M&A Cashflows - End of Year",
              fields: [
                "offshore-bank",
                "regional-bank",
                "home-loan-broker",
                "payments-partner",
                "neobank",
                "home-loan-book",
                "buy-now-cry-later",
                "mAndACashflowsInclTerminal",
              ],
              totalFields: ["mAndACashflowsInclTerminal"],
              values: pluckResultsByType(
                mAndABusinessCashflowsData,
                "mAndACashflowsEndOfYear",
              ),
              fieldFormatter: (field: string) => {
                if (field === "mAndACashflowsInclTerminal") {
                  return "M&A Cashflows (Incl Terminal)";
                }

                return null;
              },
            },
          ]}
        />
        <TableWithSections
          header="Bank Valuation"
          data={[
            {
              fields: ["discountRateEachPeriod"],
              values: bankValuationData,
              formatter: (val: number) => percentage(val),
            },
            {
              name: "Valuation Cashflows - Core Business",
              fields: [
                "terminalValue",
                "valuationCashFlowsExclTerminalStartOfYear",
                "valuationCashFlowsIclTerminalEndOfYear",
              ],
              values: pluckResultsByType(
                pluckResultsByType(bankValuationData, "valuationCashflows"),
                "coreBusiness",
              ),
              fieldFormatter: (field: string) => {
                if (field === "valuationCashFlowsExclTerminalStartOfYear") {
                  return "Valuation Cashflows (Excl Terminal) - Start of Year";
                }
                if (field === "valuationCashFlowsIclTerminalEndOfYear") {
                  return "Valuation Cashflows (Inc Terminal) - End of Year";
                }

                return null;
              },
            },
            {
              name: "Valuation Cashflows - Bank Valuation - Start of Year",
              fields: [
                "npvCashflowsStartOfYear",
                "npvCashflowsEndOfYear",
                "coreBusiness",
                "offshore-bank",
                "regional-bank",
                "home-loan-broker",
                "payments-partner",
                "neobank",
                "home-loan-book",
                "buy-now-cry-later",
                "bankValuationStartOfYear",
              ],
              totalFields: ["bankValuationStartOfYear"],
              values: pluckResultsByType(
                pluckResultsByType(bankValuationData, "valuationCashflows"),
                "bankValuationStartOfYear",
              ),
              fieldFormatter: (field: string) => {
                if (field === "npvCashflowsStartOfYear") {
                  return "NPV Cashflows - Start of The Year";
                }
                if (field === "npvCashflowsEndOfYear") {
                  return "NPV Cashflows - End of The Year";
                }
                if (field === "bankValuationStartOfYear") {
                  return "Bank Valuation - Start of Each Year";
                }

                return null;
              },
            },
            {
              name: "Valuation Cashflows - Bank Valuation - End of Year",
              fields: [
                "npvCashflowsStartOfYear",
                "npvCashflowsEndOfYear",
                "terminalValue",
                "coreBusiness",
                "offshore-bank",
                "regional-bank",
                "home-loan-broker",
                "payments-partner",
                "neobank",
                "home-loan-book",
                "buy-now-cry-later",
                "bankValuationEndOfYear",
              ],
              totalFields: ["bankValuationEndOfYear"],
              values: pluckResultsByType(
                pluckResultsByType(bankValuationData, "valuationCashflows"),
                "bankValuationEndOfYear",
              ),
              fieldFormatter: (field: string) => {
                if (field === "npvCashflowsStartOfYear") {
                  return "NPV Cashflows - Start of The Year";
                }
                if (field === "npvCashflowsEndOfYear") {
                  return "NPV Cashflows - End of The Year";
                }
                if (field === "bankValuationEndOfYear") {
                  return "Bank Valuation - End of Each Year";
                }

                return null;
              },
            },
            {
              isTotalsSection: true,
              fields: [
                "recordedCompanyValuationsEndOfYearInitial",
                "recordedCompanyValuationsEndOfYear",
                "companyValuesStartOfYearUsed",
                "companyValuesEndOfYearUsed",
              ],
              values: bankValuationData,
            },
          ]}
        />
      </VerticalGroup>
    </>
  );
};

export default ModelTeamResultsCompanyValuation;
