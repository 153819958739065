import React, { useMemo } from "react";

import Dropdown from "../../atoms/form/input/Dropdown";
import { useIntroTemplateList } from "../../../hooks/useIntroTemplate";

interface SelectValue {
  id: string;
  value: string;
  label: string;
  template: API.IntroTemplateResponse;
}

interface IntroTemplateDropdownProps {
  introTemplateId?: string;
  clientId?: string;
  onTemplateSelected?: (template: API.IntroTemplateResponse) => void;
  error?: string;
}

const IntroTemplateDropdown: React.FC<IntroTemplateDropdownProps> = ({
  introTemplateId,
  clientId,
  onTemplateSelected,
  error,
}) => {
  const { data, inProgress } = useIntroTemplateList(
    clientId ? [clientId] : [],
    clientId ? true : false,
  );

  const options: SelectValue[] = useMemo(() => {
    return data
      ? data.map((template) => ({
          id: template.id,
          value: template.id,
          label: template.name,
          template,
        }))
      : [];
  }, [data]);

  const currentSelected = useMemo(() => {
    if (introTemplateId) {
      return options.find((option) => option.id === introTemplateId);
    }
    return null;
  }, [introTemplateId, options]);

  return (
    <Dropdown
      isVerticalLayout
      error={error}
      selectProps={{
        isLoading: inProgress,
        onChange: (option: SelectValue) =>
          onTemplateSelected && onTemplateSelected(option.template),
        value: currentSelected,
        options,
        noOptionsMessage: () => "No Templates",
        placeholder: !data
          ? "Loading Templates..."
          : data.length === 0
            ? "No Templates"
            : "Select a template",
      }}
    />
  );
};

export default IntroTemplateDropdown;
