import React, { useCallback } from "react";
import { useParams } from "react-router";
import { useMemoRequest } from "../../../hooks/useMemoRequest";
import modelApi from "../../../services/modelApi";
import Banner from "../../atoms/banner/Banner";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import RoundResultsPage from "./RoundResultsPage";

const onlyThesePages: API.ReportingLayoutType[] | null = [];

const RoundResults: React.FC = () => {
  const { roundNumber, eventId } = useParams<{
    roundNumber: string;
    eventId: string;
  }>();

  const getReportingResults = useCallback(() => {
    return modelApi.getReportingResults(eventId, parseInt(roundNumber));
  }, [eventId, roundNumber]);

  const { inProgress, data, error } =
    useMemoRequest<ModelAPI.Reporting.ReportingResults>(getReportingResults);

  return (
    <>
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {inProgress && <LoadingSpinner />}
      {data && (
        <div style={{ height: "100vh" }}>
          <RoundResultsPage
            data={data}
            roundNumber={parseInt(roundNumber)}
            onlyThesePages={onlyThesePages}
            hidePagination
            allowPresentationMode
            noAudio
          />
        </div>
      )}
    </>
  );
};

export default RoundResults;
