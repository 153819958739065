import React, { useCallback, useMemo, useRef, useState } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import { useResultsContext } from "../context/context";
import { useKeypress } from "../../../../hooks/useKeypress";
import Clickable from "../../../atoms/clickable/Clickable";
import Icon from "../../../atoms/icon/Icon";
import RoundResultsPresenterSettingModal from "./RoundResultsPresenterSettingModal";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import "./RoundsResultsContainer.scss";
import HoverableIcon from "../../../atoms/icon/HoverableIcon";
import { sendRemoteAction } from "../../../../lib/remoteAction";
import SimpleModal from "../../../organisms/standard-modal/SimpleModal";
import SpeakerNotes from "./SpeakerNotes";
import Text from "../../../atoms/text/Text";
import Container from "../../../atoms/page/Page";
import classNames from "classnames";

interface Props {
  onNext?: () => void;
  withoutPadding?: boolean;
  allowOverFlow?: boolean;
  fullHeight?: boolean;
}

const RoundResultsContainer: React.FC<Props> = ({
  children,
  withoutPadding,
  onNext,
  allowOverFlow,
  fullHeight,
}) => {
  const context = useResultsContext();
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditingSpeakerNotes, setIsEditingSpeakerNotes] = useState(false);
  const speakerNotesRef = useRef<React.ElementRef<typeof SpeakerNotes>>(null);

  const onBackClick = useCallback(() => {
    if (context.pageIndex > 0) {
      context.goPreviousPage();
    }
  }, [context]);

  const onNextClick = useCallback(() => {
    if (onNext) {
      return onNext();
    }
    if (context.pageIndex < context.pages.length - 1) {
      context.goNextPage();
    }
  }, [context, onNext]);

  const onCloseClick = useCallback(() => {
    sendRemoteAction({ name: "closePublishedResults" });
  }, []);

  const onEscClick = useCallback(() => {
    context.exitPresentationMode();
  }, [context]);

  const handleShowSettings = useCallback(() => {
    setSettingsOpen(true);
  }, []);

  const keymap: Record<string, () => void> = useMemo(() => {
    if (context.ignoreKeyPress || isEditingSpeakerNotes) {
      return {} as Record<string, () => void>;
    }
    return {
      Escape: onEscClick,
      ArrowDown: onNextClick,
      ArrowRight: onNextClick,
      PageDown: onNextClick,
      Enter: onNextClick,
      Space: onNextClick,
      KeyN: onNextClick,
      ArrowUp: onBackClick,
      ArrowLeft: onBackClick,
      PageUp: onBackClick,
      Backspace: onBackClick,
      KeyP: onBackClick,
    };
  }, [
    context.ignoreKeyPress,
    onNextClick,
    onBackClick,
    onEscClick,
    isEditingSpeakerNotes,
  ]);

  useKeypress({ keymap });

  const currentPage = useMemo(() => {
    return context.pages[context.pageIndex];
  }, [context.pageIndex, context.pages]);

  const openModal = useCallback((editing) => {
    setIsEditingSpeakerNotes(editing);
    setModalOpen(true);
  }, []);

  const handleSpeakerNotesSave = useCallback(
    (layoutId: string) => (notes: string) => {
      context.onLayoutUpdate?.(layoutId, {
        speakerNotes: notes,
      });
      setModalOpen(false);
    },
    [context],
  );

  return (
    <>
      {context.showRemoteCloseButton && (
        <Clickable className="close-results-button" onClick={onCloseClick}>
          <InlineGroup verticalCenter center className="button-container">
            <Icon size={10} type="close" />
          </InlineGroup>
        </Clickable>
      )}
      <VerticalGroup
        wide
        id="round-results-container"
        className={classNames({
          "full-height": fullHeight,
          "pr-10": !withoutPadding,
          "pl-10": !withoutPadding,
          "allow-overflow": allowOverFlow,
        })}
      >
        <InlineGroup fullHeight stretch block>
          {children}
        </InlineGroup>
        {context.onLayoutUpdate && currentPage && (
          <Clickable
            style={{
              position: "absolute",
              top: "5px",
              right: "160px",
              zIndex: 1,
            }}
            onClick={openModal}
          >
            <InlineGroup
              center
              verticalCenter
              style={{
                backgroundColor: "black",
                borderRadius: "100%",
                opacity: 0.3,
                width: "60px",
                height: "60px",
              }}
            >
              <Icon colour="white" size={8} type={"notes"} />
            </InlineGroup>
          </Clickable>
        )}
        {context.onLayoutUpdate && currentPage && (
          <Clickable
            style={{
              position: "absolute",
              top: "5px",
              right: "80px",
              zIndex: 1,
            }}
            onClick={(event) => {
              event.stopPropagation();
              context.onLayoutUpdate?.(currentPage.id, {
                overrideDisabled: !currentPage.overrideDisabled,
              });
            }}
          >
            <InlineGroup
              center
              verticalCenter
              style={{
                backgroundColor: "black",
                borderRadius: "100%",
                opacity: 0.3,
                width: "60px",
                height: "60px",
              }}
            >
              <HoverableIcon
                size={10}
                fill={currentPage.overrideDisabled ? "red" : "white"}
                hoverType={currentPage.overrideDisabled ? "eye" : "hidden"}
                type={currentPage.overrideDisabled ? "hidden" : "eye"}
              />
            </InlineGroup>
          </Clickable>
        )}
        {!context.hidePagination &&
          !context.allowPresentationMode &&
          context.pageIndex > 0 && (
            <Clickable className="back-button" onClick={onBackClick}>
              <InlineGroup verticalCenter center className="button-container">
                <Icon size={10} type={"leftChevron"} />
              </InlineGroup>
            </Clickable>
          )}
        {!context.hidePagination &&
          !context.allowPresentationMode &&
          context.pageIndex < context.pages.length - 1 && (
            <>
              <Clickable className="next-button" onClick={onNextClick}>
                <InlineGroup verticalCenter center className="button-container">
                  <Icon size={10} type={"rightChevron"} />
                </InlineGroup>
              </Clickable>
            </>
          )}
      </VerticalGroup>
      {settingsOpen && (
        <RoundResultsPresenterSettingModal
          isOpen={settingsOpen}
          onClose={() => setSettingsOpen(false)}
        />
      )}
      {context.allowPresentationMode && (
        <div className="settings-container">
          <Clickable onClick={handleShowSettings}>
            <Icon type="settings" colour="white" size={8} />
          </Clickable>
        </div>
      )}
      {context.showFullScreenButton && (
        <div className="full-screen-container">
          <Clickable onClick={() => context.enterPresentationMode()}>
            <Icon type="fullScreen" colour="white" size={8} />
          </Clickable>
        </div>
      )}
      <SimpleModal
        showCloseButton
        isOpen={modalOpen}
        size={"medium"}
        onClose={() => setModalOpen(false)}
      >
        <Container fit>
          <VerticalGroup className="pb-6" wide full spaceBetweenElements={2}>
            <Text size="lg" bold>
              Speaker Notes
            </Text>
            <SpeakerNotes
              ref={speakerNotesRef}
              initialValue={currentPage.speakerNotes || ""}
              onSave={handleSpeakerNotesSave(currentPage.id)}
              placeHolderColour="primaryDark"
            />
          </VerticalGroup>
        </Container>
      </SimpleModal>
    </>
  );
};

export default RoundResultsContainer;
