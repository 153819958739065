import React from "react";
import classNames from "classnames";

import "./Form.scss";

import Text from "../text/Text";
interface Props extends React.FormHTMLAttributes<HTMLFormElement> {
  className?: string;
  center?: boolean;
  children: React.ReactNode;
  narrow?: boolean;
  wide?: boolean;
}

function Form({ className, center, narrow, wide, children, ...rest }: Props) {
  return (
    <form
      className={classNames("form", { center, narrow, wide }, className)}
      {...rest}
    >
      {children}
    </form>
  );
}

export const FormRequiredText = () => (
  <Text size="sm">All fields marked with * are required.</Text>
);

export default Form;
