import React, { useEffect, useState } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Icon from "../../../atoms/icon/Icon";
import Badge from "../../../atoms/badge/Badge";
import { ThemeSpacing } from "../../../../types/theme";

interface Props {
  type: "tablet" | "virtual";
  showPlayer1: boolean;
  showPlayer2: boolean;
}

const CeoTransferCircle: React.FC<Props> = ({
  type,
  showPlayer1,
  showPlayer2,
}) => {
  const [size, setSize] = useState({ width: 300, height: 300 });
  const [lightGreenIconPos, setLightGreenIconPos] = useState({
    top: 150,
    left: -30,
  });
  const [greenIconPos, setGreenIconPos] = useState({ top: 420, left: 60 });
  const [yellowIconPos, setYellowIconPos] = useState({ top: 420, right: 60 });
  const [blueIconPos, setBlueIconPos] = useState({ top: 150, right: -30 });
  const [darkegreyIconPos, setDarkgreyIconPos] = useState({ top: -50 });
  const [badgeSize, setBadgeSize] = useState(10);
  const [tabletBadgeSize, setTabletBadgeSize] = useState(10);

  const closestThemeSpacing = (value: number): ThemeSpacing => {
    const allowedSizes: ThemeSpacing[] = [
      0, 1, 2, 3, 4, 5, 6, 8, 10, 12, 16, 20, 24, 32, 40, 48, 56, 64, 72, 80,
      88,
    ];
    return allowedSizes.reduce((prev, curr) =>
      Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev,
    );
  };

  useEffect(() => {
    const updateSize = () => {
      let newSize = { width: 600, height: 600 };
      let newLightGreenPos = { top: 130, left: -60 };
      let newGreenPos = { top: 420, left: 80 };
      let newYellowPos = { top: 420, right: 350 };
      let newBluePos = { top: 150, right: -30 };
      let newDarkgreyPos = { top: -50 };
      let newBadgeSize = 20;
      let newTabletBadgeSize = 15;

      if (window.innerWidth > 2520) {
        newSize = { width: 1300, height: 1300 };
        newLightGreenPos = { top: 380, left: -60 };
        newGreenPos = { top: 950, left: 180 };
        newYellowPos = { top: 940, right: 800 };
        newBluePos = { top: 380, right: -60 };
        newDarkgreyPos = { top: -90 };
        newBadgeSize = 24;
        newTabletBadgeSize = 29;
      } else if (window.innerWidth <= 1000) {
        newSize = { width: 300, height: 300 };
        newLightGreenPos = { top: 80, left: -15 };
        newGreenPos = { top: 210, left: 50 };
        newYellowPos = { top: 210, right: 175 };
        newBluePos = { top: 80, right: -15 };
        newDarkgreyPos = { top: -20 };
        newBadgeSize = 8;
        newTabletBadgeSize = 8;
      } else if (window.innerWidth > 1000 && window.innerWidth < 1600) {
        newSize = { width: 450, height: 450 };
        newLightGreenPos = { top: 120, left: -30 };
        newGreenPos = { top: 320, left: 270 };
        newYellowPos = { top: 320, right: 60 };
        newBluePos = { top: 120, right: -30 };
        newDarkgreyPos = { top: -50 };
        newBadgeSize = 10;
        newTabletBadgeSize = 9;
      }

      setSize(newSize);
      setLightGreenIconPos(newLightGreenPos);
      setGreenIconPos(newGreenPos);
      setYellowIconPos(newYellowPos);
      setBlueIconPos(newBluePos);
      setDarkgreyIconPos(newDarkgreyPos);
      setBadgeSize(closestThemeSpacing(newBadgeSize));
      setTabletBadgeSize(closestThemeSpacing(newTabletBadgeSize));
    };

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return (
    <InlineGroup
      center
      verticalCenter
      block
      fullHeight
      style={{ height: size.height, width: size.width }}
    >
      <InlineGroup
        center
        verticalCenter
        block
        fullHeight
        style={{ position: "relative", height: size.height, width: size.width }}
      >
        <Icon
          type="circleWithArrows"
          style={{ height: size.height, width: size.width }}
        />
        <VerticalGroup
          style={{ position: "absolute", top: darkegreyIconPos.top }}
        >
          <Icon
            type={showPlayer1 ? "playerWithHighlight" : "player"}
            colour="darkgrey"
            dynamicSize={{ min: "70px", preferred: "8vw", max: "300px" }}
            skipSizing
          />
          {type === "tablet" && showPlayer1 && (
            <Icon
              type="tablet"
              colour="danger"
              size={closestThemeSpacing(tabletBadgeSize)}
              style={{ position: "absolute", top: "-25%", left: "-15%" }}
            />
          )}
          {type === "virtual" && showPlayer1 && (
            <Badge
              colour="danger"
              size={closestThemeSpacing(tabletBadgeSize)}
              value="CEO"
              floating
              style={{ top: "10%", left: "25%" }}
            />
          )}
        </VerticalGroup>
        <VerticalGroup
          style={{
            position: "absolute",
            top: blueIconPos.top,
            right: blueIconPos.right,
          }}
        >
          <Icon
            type={showPlayer2 ? "playerWithHighlight" : "player"}
            colour="blue"
            dynamicSize={{ min: "70px", preferred: "8vw", max: "300px" }}
            skipSizing
          />
          {type === "tablet" && showPlayer2 && (
            <Icon
              type="tablet"
              colour="danger"
              size={closestThemeSpacing(tabletBadgeSize)}
              style={{ position: "absolute", top: "-25%", right: "-15%" }}
            />
          )}
          {type === "virtual" && showPlayer2 && (
            <Badge
              colour="danger"
              size={closestThemeSpacing(badgeSize)}
              value="CEO"
              floating
              style={{ top: "10%", left: "25%" }}
            />
          )}
        </VerticalGroup>
        <InlineGroup
          style={{
            position: "absolute",
            top: lightGreenIconPos.top,
            left: lightGreenIconPos.left,
          }}
        >
          <Icon
            type="player"
            colour="lightgreen"
            dynamicSize={{ min: "70px", preferred: "8vw", max: "300px" }}
            skipSizing
          />
        </InlineGroup>
        <InlineGroup
          style={{
            position: "absolute",
            top: greenIconPos.top,
            left: greenIconPos.left,
          }}
        >
          <Icon
            type="player"
            colour="green"
            dynamicSize={{ min: "70px", preferred: "8vw", max: "300px" }}
            skipSizing
          />
        </InlineGroup>
        <InlineGroup
          style={{
            position: "absolute",
            top: yellowIconPos.top,
            left: yellowIconPos.right,
          }}
        >
          <Icon
            type="player"
            colour="yellow"
            dynamicSize={{ min: "70px", preferred: "8vw", max: "300px" }}
            skipSizing
          />
        </InlineGroup>
      </InlineGroup>
    </InlineGroup>
  );
};

export default CeoTransferCircle;
