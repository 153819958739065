import React from "react";
import "./Grid.scss";
import classNames from "classnames";

interface Props {
  columns?: number;
  wide?: boolean;
  children: React.ReactNode;
}

const Grid: React.FC<Props> = ({ children, columns, wide }) => {
  return (
    <div
      className={classNames("grid", {
        "columns-2": columns === 2 || columns === undefined,
        "columns-3": columns === 3,
        "columns-4": columns === 4,
        "width-100-percent": wide,
      })}
    >
      {children}
    </div>
  );
};

export default Grid;
