import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import {
  useAssessmentInEvent,
  useCurrentEvent,
} from "../../../../../context/CurrentEventContext";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import Container from "../../../../atoms/page/Page";
import Text from "../../../../atoms/text/Text";

import "./EventAssessmentContainer.scss";

interface Props {}

export const EventAssessmentContainer: React.FC<Props> = ({ children }) => {
  const route = useRouteMatch();
  const { event } = useCurrentEvent();
  const { assessment } = useAssessmentInEvent();

  if (!event) return null;
  if (!assessment) return null;

  const baseurl = `/events/${event.id}/view/assessments/${assessment.id}`;

  const summaryUrl = `${baseurl}/summary`;
  const responsesUrl = `${baseurl}/responses`;

  return (
    <Container relative>
      <InlineGroup
        verticalCenter
        spaceBetweenElements={8}
        block
        className="event-assessment-header"
      >
        <Link to={summaryUrl}>
          <Text
            size="lg"
            colour={route.url.indexOf(summaryUrl) >= 0 ? "primaryDark" : "blue"}
          >
            Summary
          </Text>
        </Link>
        <Link to={responsesUrl}>
          <Text
            size="lg"
            colour={
              route.url.indexOf(responsesUrl) >= 0 ? "primaryDark" : "blue"
            }
          >
            Responses
          </Text>
        </Link>
      </InlineGroup>
      {children}
    </Container>
  );
};
