import React, { useState, useEffect, useCallback } from "react";
import { FieldErrors, Setters, State } from "../../../../../../hooks/useForm";
import Input from "../../../../../atoms/form/input/Input";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import Toggle from "../../../../../atoms/toggle/Toggle";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import { WrittenResponseField } from "../FormFields/WrittenResponseField";

interface Props {
  question: State["formData"];
  setNumberField: Setters["setNumberField"];
  setFieldRaw: Setters["setFieldRaw"];
  setFieldSimple: Setters["setFieldSimple"];
  fieldErrors?: FieldErrors;
}

export const DEFAULT_WRITTEN_RESPONSE: API.WrittenResponseFieldRequest = {
  type: "written_response",
  config: {
    type: "written_response",
    label: "",
    hasCharacterLimit: false,
  },
};

export const WrittenResponseFormDetails: React.FC<Props> = ({
  question,
  setFieldRaw,
  setFieldSimple,
  fieldErrors,
  setNumberField,
}) => {
  const [field, setResponseField] = useState<API.WrittenResponseFieldRequest>(
    () => {
      if (question.fields[0]) {
        return question.fields[0];
      }
      return {
        ...DEFAULT_WRITTEN_RESPONSE,
      };
    },
  );

  useEffect(() => {
    setFieldRaw("fields", [field]);
  }, [setFieldRaw, field]);

  const handleAssignPointsToggle = useCallback(
    (value: boolean) => {
      setFieldSimple("assignMarks")(value);
      setFieldSimple("marks")(0);
    },
    [setFieldSimple],
  );

  return (
    <VerticalGroup spaceBetweenElements={4}>
      <InlineGroup verticalCenter>
        <Toggle
          reverse
          label="Assign points"
          checked={question.assignMarks}
          onUpdate={handleAssignPointsToggle}
        />
        {question.assignMarks && (
          <Input
            className="ml-2"
            type="text"
            small
            reverse
            label="Points"
            value={question.marks}
            onChange={setNumberField("marks")}
          />
        )}
      </InlineGroup>
      <WrittenResponseField
        onChange={setResponseField}
        writtenResponseField={field}
        index={0}
        fieldErrors={fieldErrors}
      />
    </VerticalGroup>
  );
};
