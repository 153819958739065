import React from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";
import { formatValue, percentage } from "../utils/formatters";
import pluckResultsByType from "../utils/pluckResultsByType";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["shareCapital"];
}

const ModelTeamResultsShareCapital: React.FC<Props> = ({ data }) => {
  const sharePriceDiscountData = pluckResultsByType(data, "sharePriceDiscount");
  const ethicalCapitalRequiredData = pluckResultsByType(
    data,
    "ethicalCapitalRequired",
  );
  const dividendsPaidDataa = pluckResultsByType(data, "dividendsPaid");
  const shareCapitalMovementsData = pluckResultsByType(
    data,
    "shareCapitalMovements",
  );
  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <TableWithSections
          header="Share Capital Movement"
          data={[
            {
              name: "",
              fields: ["movementExcludingForced", "raisedRegulator"],
              values: data,
              fieldFormatter: (field: string) => {
                if (field === "movementExcludingForced") {
                  return "Capital Movement Excluding Forced";
                }
                if (field === "raisedRegulator") {
                  return "Regulator Raised Capital";
                }
                return null;
              },
            },
            {
              name: "Share Issue Bands - Limits The Amount of Share Capital Which Can Be Issued",
              fields: ["openingShareCapital"],
              values: pluckResultsByType(
                sharePriceDiscountData,
                "shareIssueBands",
              ),
            },
            {
              name: "Max Change When Regulator Forces Capital Raise (To Allow Their Forced Capital Raise To Happen)",
              fields: [
                "capitalRaisedByRegulator",
                "shareCapitalRaisedByPlayer",
                "maxMovement",
                "minOfPlayerAmountAndBracket5",
                // "dividendsReinvested",
                "maxChange",
                "maximumChangeInShareCapital",
              ],
              values: pluckResultsByType(
                sharePriceDiscountData,
                "shareIssueBands",
              ),
              fieldFormatter: (field: string) => {
                if (field === "minOfPlayerAmountAndBracket5") {
                  return "Player Raise (Capped)";
                }
                return null;
              },
            },
            {
              name: "Share Capital Issued (Excl M&A Deals)",
              fields: [
                "shareCapitalRaisedAttemptedBeforeCap",
                "shareCapitalRaisedCapped",
              ],
              values: pluckResultsByType(
                sharePriceDiscountData,
                "shareCapitalIssuedExcludingMandADeals",
              ),
            },
          ]}
        />
        <TableWithSections
          header="Dividends Paid / DRP"
          data={[
            {
              name: "Dividends Paid",
              fields: [
                "groupNPAT",
                "investmentNPAT",
                "coreNPAT",
                "groupRetainedEarningsBeforeDividendsPaid",
                "maximumDividendAmountPayable",
                "coreDividendsPaid",
                "investmentDividendsPaid",
                "totalDividendsPaid",
              ],
              totalFields: ["totalDividendsPaid"],
              values: pluckResultsByType(dividendsPaidDataa, "dividendsPaid"),
            },
            {
              name: "Dividend Reinvestment Plan",
              fields: ["dividendReinvestmentPercentage", "dividendsReinvested"],
              values: pluckResultsByType(
                dividendsPaidDataa,
                "dividendReinvestmentPlan",
              ),
              formatter: (value: number, field: string) => {
                return field === "dividendReinvestmentPercentage"
                  ? percentage(value)
                  : null;
              },
            },
          ]}
        />
        <TableWithSections
          header="Ethical Capital Required"
          data={[
            {
              fields: [
                "coalMiningLoans",
                "riskWeighting",
                "capitalRatio",
                "capitalRaised",
              ],
              values: ethicalCapitalRequiredData,
              formatter: (value: number, field: string) => {
                return ["riskWeighting", "capitalRatio"].includes(field)
                  ? percentage(value)
                  : null;
              },
            },
          ]}
        />
        <TableWithSections
          header="Share Capital Movements"
          data={[
            {
              name: "Share Capital Issued",
              fields: [
                "manualAndForced",
                "investments",
                "drpShareCapitalIssued",
                "totalShareholderFundsRaised",
              ],
              totalFields: ["totalShareholderFundsRaised"],
              values: pluckResultsByType(
                shareCapitalMovementsData,
                "shareCapitalIssued",
              ),
            },
            {
              name: "Share Capital Bought Back",
              fields: [
                "shareCapitalBuyback",
                "maximumAmountOfBuyback",
                "shareCapitalBuybackCapped",
                "shareholderFundsAvailable",
                "totalShareholderFundsBoughtBack",
                "specialDividend",
                "otherAdjustments",
                "totalSpecialDividend",
              ],
              totalFields: [
                "totalShareholderFundsBoughtBack",
                "totalSpecialDividend",
              ],
              values: pluckResultsByType(
                shareCapitalMovementsData,
                "shareCapitalBoughtBack",
              ),
              formatter: (val: number, field: string) => {
                if (field === "maximumAmountOfBuyback") {
                  return formatValue(-1 * val);
                }
                return null;
              },
              fieldFormatter: (field: string) => {
                if (field === "shareCapitalBuyback") {
                  return "Shareholder Capital Buyback - Raw";
                }
                return null;
              },
            },
          ]}
        />
      </VerticalGroup>
    </>
  );
};

export default ModelTeamResultsShareCapital;
