export const projects = [
  "project-offshore",
  "project-right-size",
  "project-harvest",
  "project-mcfinsey",
  "project-switch",
  "project-lightspeed",
  "project-digital",
  "project-genius",
  "project-nitro",
  "project-big-data",
  "project-care-bear",
  "project-tighten",
  "project-fix-it",
  "project-roadshow",
  "project-sustain",
  "project-shield",
  "project-simple",
  "project-fusion",
];

export const excoDecisions = [
  "employee-costs-10%",
  "credit-cards",
  "data-privacy",
  "bonus-season",
  "coal-miner",
  "credit-cards-&-bushfires",
  "smart-atms-take-a-break",
  "lmi-insurance",
  "seek-&-you-shall-find",
  "loan-break-cost",
  "credit-cards-washed-away",
  "sleepy-advanced-atms",
  "buy-now-cry-later",
  "bigger-faster",
];

export const deals = [
  "offshore-bank",
  "regional-bank",
  "home-loan-broker",
  "payments-partner",
  "neobank",
  "home-loan-book",
];
export const rounds = 14;
