import { useState, useEffect, RefObject } from "react";

interface CalculateHeightOptions {
  subtractPixels?: number;
  minHeight?: number;
}

const useCalculateHeight = (
  ref: RefObject<HTMLElement>,
  options: CalculateHeightOptions = {},
): string => {
  const [height, setHeight] = useState<string>("auto");

  useEffect(() => {
    const calculateHeight = () => {
      if (ref.current) {
        const containerHeight = ref.current.clientHeight;
        const windowHeight = window.innerHeight;
        const availableHeight = windowHeight - (windowHeight - containerHeight);
        const calculatedHeight = Math.max(
          availableHeight - (options.subtractPixels || 0),
          options.minHeight || 0,
        );
        setHeight(`${calculatedHeight}px`);
      }
    };

    calculateHeight();
    window.addEventListener("resize", calculateHeight);

    return () => window.removeEventListener("resize", calculateHeight);
  }, [ref, options.subtractPixels, options.minHeight]);

  return height;
};

export default useCalculateHeight;
