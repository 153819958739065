import React, { useCallback } from "react";
import ConfirmModal from "../../organisms/confirm-modal/ConfirmModal";

import useAPIRequest from "../../../hooks/useAPIRequest";
import API from "../../../services/api";
import { getTeamName } from "./utils/getTeamName";

interface Props {
  gameId: string;
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  team: API.GameConnection;
}

function DisconnectCEODevice({
  gameId,
  isOpen,
  onClose,
  onComplete,
  team,
}: Props) {
  const ceoDevice = team.devices.find((d) => d.isMaster);

  const callback = useCallback(() => {
    if (ceoDevice) {
      return API.removeDevice(gameId, ceoDevice.deviceId);
    }
    return Promise.resolve({});
  }, [ceoDevice, gameId]);

  const [{ inProgress, error }, doAPIRequest] = useAPIRequest(callback, null);

  const handleConfirm = useCallback(() => {
    doAPIRequest(onComplete);
  }, [doAPIRequest, onComplete]);

  if (!ceoDevice) {
    return (
      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onClose}
        title="Disconnect the CEO device?"
        description="This team does not have a CEO device to disconnect"
        confirmTitle="Ok"
      />
    );
  }

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      title="Disconnect the CEO device?"
      description={
        <>
          Are you sure you want to disconnect the following device from{" "}
          <b>{getTeamName(team)}</b>: <br />
          Device ({ceoDevice.deviceId})<br />
        </>
      }
      confirmTitle="Disconnect"
      error={error && error.message}
      warning
    />
  );
}

export default DisconnectCEODevice;
