import React, { useCallback, useState } from "react";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import Ribbon from "../../atoms/ribbon/Ribbon";
import AssessmentGroup from "./Group/AssessmentGroup";

import "./assessments.scss";

interface Props {
  groups: API.AssessmentGroupResponse[];
  assessmentId: string;
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
}

function AssessmentRibbon({
  groups: initialGroups,
  assessmentId,
  selectedIndex,
  setSelectedIndex,
}: Props) {
  const [groups, setGroups] = useState(initialGroups);
  const handleGroupQuestionsChange = useCallback(
    (updatedQuestions: API.AssessmentQuestionResponse[]) => {
      setGroups((prevGroups) => {
        const updatedGroup = {
          ...prevGroups[selectedIndex],
          questions: updatedQuestions,
        };
        return [
          ...prevGroups.slice(0, selectedIndex),
          updatedGroup,
          ...prevGroups.slice(selectedIndex + 1),
        ];
      });
    },
    [selectedIndex],
  );
  return (
    <>
      <InlineGroup
        className={"h-16 mt-2 assessment-ribbon"}
        spaceBetweenElements={2}
        block
      >
        {groups.map((group, index) => {
          return (
            <>
              <Ribbon
                first={index === 0}
                last={index === groups.length - 1}
                active={index === selectedIndex}
                title={`Round ${group.round}`}
                description={`${group.name}`}
                onClick={() => setSelectedIndex(index)}
              />
            </>
          );
        })}
      </InlineGroup>
      <AssessmentGroup
        group={groups[selectedIndex]}
        assessmentId={assessmentId}
        onQuestionsChange={handleGroupQuestionsChange}
      />
    </>
  );
}

export default AssessmentRibbon;
