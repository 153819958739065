import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import Dropdown from "../../atoms/form/input/Dropdown";
import Card from "../../atoms/card/Card";
import Text from "../../atoms/text/Text";
import Table, { Th, Tr } from "../../atoms/table/Table";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import IconButton from "../../molecules/iconbutton/IconButton";
import Container from "../../atoms/page/Page";
import Clickable from "../../atoms/clickable/Clickable";
import Icon from "../../atoms/icon/Icon";
import useReportingResults from "../../../hooks/useReportingResults";
import useIsMobile from "../../../hooks/useIsMobile";
import Button from "../../atoms/button/Button";
import debounce from "lodash.debounce";
import { DebouncedFunc } from "lodash";

interface TeamData {
  adjustments: number[];
  results: number[];
}
interface Update {
  type: keyof ModelAPI.FacilitatorAdjustmentsResponse;
  teamId: number;
  value: number;
}

interface CombinedAdjustments {
  [key: string]: {
    [teamId: number]: TeamData;
  };
}

type Adjustment = {
  type: keyof ModelAPI.FacilitatorAdjustmentsResponse;
  teamId: number;
  value: number;
};

interface Props {
  eventId: string;
  selectedRound: number;
  configuration: ModelAPI.ConfigurationResponse;
  adjustmentData: ModelAPI.FacilitatorAdjustmentsResponse;
  resultData: ModelAPI.RoundResultsResponse[];
  onChangesMade: (hasChanges: boolean) => void;
  onUpdate: (adjustments: Adjustment[]) => Promise<void>;
}

const percentageMetrics: { [key: string]: boolean } = {
  capitalRatio: true,
  communityTrust: true,
  employeeEngagement: true,
  lie: true,
  shareholderReturns: true,
};

const allowedMetrics = [
  { key: "capitalRatio", value: "capitalRatio", label: "Capital Ratio" },
  { key: "communityTrust", value: "communityTrust", label: "Community Trust" },
  { key: "complaints", value: "complaints", label: "Complaints" },
  {
    key: "employeeEngagement",
    value: "employeeEngagement",
    label: "Employee Engagement",
  },
  { key: "lie", value: "lie", label: "LIE" },
  { key: "nps", value: "nps", label: "NPS" },
  {
    key: "regulatorActions",
    value: "regulatorActions",
    label: "Regulator Actions",
  },
  { key: "riskIncidents", value: "riskIncidents", label: "Risk Incidents" },
  {
    key: "shareholderReturns",
    value: "shareholderReturns",
    label: "Total Shareholder Returns",
  },
];

const metricRankingOrder = {
  nps: "highest",
  tsr: "highest",
  shareholderReturns: "highest",
  employeeEngagement: "highest",
  complaints: "lowest",
  riskIncidents: "lowest",
  capitalRatio: "highest",
  lie: "lowest",
  regulatorActions: "lowest",
  communityTrust: "highest",
};

type DebouncedUpdateFunction = DebouncedFunc<
  (newAdjustments: CombinedAdjustments, metric: string) => void
>;

const GameModelAdjustmentsContent: React.FC<Props> = ({
  configuration,
  // teams,
  adjustmentData,
  resultData,
  onUpdate,
  eventId,
  selectedRound,
  onChangesMade,
}) => {
  const { data } = useReportingResults(eventId, selectedRound);

  const isMobile = useIsMobile();

  const [localAdjustments, setLocalAdjustments] = useState<CombinedAdjustments>(
    {},
  );
  const [changedMetrics, setChangedMetrics] = useState<Set<string>>(new Set());
  const adjustmentRefs = useRef<{
    [key: string]: { [teamId: number]: number };
  }>({});
  const debouncedUpdateRef = useRef<DebouncedUpdateFunction | null>(null);

  const { currentRound, numberOfHistoricRounds } = configuration;
  const currentRoundIndex = numberOfHistoricRounds + currentRound - 1;
  const [selectedMetric, setSelectedMetric] = useState<
    keyof ModelAPI.FacilitatorAdjustmentsResponse
  >(allowedMetrics[0].key as keyof ModelAPI.FacilitatorAdjustmentsResponse);

  useEffect(() => {
    debouncedUpdateRef.current = debounce(
      (newAdjustments: CombinedAdjustments, metric: string) => {
        setLocalAdjustments(newAdjustments);
        setChangedMetrics((prev) => new Set(prev).add(metric));
        onChangesMade(true);
      },
      100,
    );

    return () => {
      if (debouncedUpdateRef.current) {
        debouncedUpdateRef.current.cancel();
      }
    };
  }, [onChangesMade]);

  const teamPositions = useMemo(() => {
    if (!selectedMetric || !localAdjustments[selectedMetric]) return {};

    const teamValues = (data?.teams ?? []).map((team) => ({
      teamId: team.teamId,
      value:
        localAdjustments[selectedMetric][team.teamId]?.results[
          currentRoundIndex
        ] || 0,
    }));

    const sortedTeams = teamValues.sort((a, b) =>
      metricRankingOrder[selectedMetric as keyof typeof metricRankingOrder] ===
      "highest"
        ? b.value - a.value
        : a.value - b.value,
    );

    const newPositions: { [key: number]: number } = {};
    let currentRank = 1;
    let previousValue: number | null = null;
    let teamsAtCurrentRank = 0;

    sortedTeams.forEach((team) => {
      if (previousValue !== null && team.value !== previousValue) {
        currentRank += teamsAtCurrentRank;
        teamsAtCurrentRank = 0;
      }
      newPositions[team.teamId] = currentRank;
      previousValue = team.value;
      teamsAtCurrentRank++;
    });

    return newPositions;
  }, [selectedMetric, localAdjustments, data?.teams, currentRoundIndex]);

  useEffect(() => {
    if (!data || !data.teams) return;
    const initialAdjustments: CombinedAdjustments = {};
    allowedMetrics.forEach((metric) => {
      initialAdjustments[metric.key] = {};
      data.teams.forEach((team) => {
        const adjustmentMetric = getAdjustmentMetric(metric.key);
        const adjustments =
          adjustmentData[
            adjustmentMetric as keyof ModelAPI.FacilitatorAdjustmentsResponse
          ]?.[team.teamId] || [];
        const results =
          resultData
            .find((data) => data.teamId === team.teamId)
            ?.years.map(
              (year) => year[metric.key as keyof typeof year] as number,
            ) || [];
        initialAdjustments[metric.key][team.teamId] = { adjustments, results };
      });
    });
    setLocalAdjustments(initialAdjustments);
  }, [adjustmentData, resultData, data]);

  const handleAdjustment = useCallback(
    (teamId: number, increment: boolean) => {
      if (selectedMetric && debouncedUpdateRef.current) {
        const newAdjustments = { ...localAdjustments };
        const teamData = newAdjustments[selectedMetric][teamId];
        if (!adjustmentRefs.current[selectedMetric]) {
          adjustmentRefs.current[selectedMetric] = {};
        }
        if (adjustmentRefs.current[selectedMetric][teamId] === undefined) {
          adjustmentRefs.current[selectedMetric][teamId] =
            teamData.results[currentRoundIndex];
        }
        const currentValue = adjustmentRefs.current[selectedMetric][teamId];
        const isPercentage = percentageMetrics[selectedMetric];

        let newValue: number;
        if (isPercentage) {
          newValue = increment
            ? (currentValue * 10000 + 1) / 10000
            : (currentValue * 10000 - 1) / 10000;
        } else {
          newValue = currentValue + (increment ? 1 : -1);
        }
        adjustmentRefs.current[selectedMetric][teamId] = newValue;
        teamData.results[currentRoundIndex] = newValue;
        teamData.adjustments[currentRoundIndex] += increment ? 1 : -1;

        debouncedUpdateRef.current(newAdjustments, selectedMetric);
      }
    },
    [currentRoundIndex, localAdjustments, selectedMetric],
  );

  const incrementAdjustment = useCallback(
    (teamId: number) => () => {
      handleAdjustment(teamId, true);
    },
    [handleAdjustment],
  );

  const decrementAdjustment = useCallback(
    (teamId: number) => () => {
      handleAdjustment(teamId, false);
    },
    [handleAdjustment],
  );

  const handleSaveLocalAdjustment = useCallback(async () => {
    const updates: Update[] = [];

    changedMetrics.forEach((metric) => {
      const adjustmentMetric = getAdjustmentMetric(metric);
      (data?.teams ?? []).forEach((team) => {
        const teamData = localAdjustments[metric][team.teamId];
        if (teamData && !team.isCpuTeam) {
          const value = teamData.adjustments[currentRoundIndex];
          updates.push({
            type: adjustmentMetric as keyof ModelAPI.FacilitatorAdjustmentsResponse,
            teamId: team.teamId,
            value: value,
          });
        }
      });
    });
    try {
      if (updates.length > 0) {
        await onUpdate(updates);
      }
      onChangesMade(false);
      setChangedMetrics(new Set());
    } catch (error) {
      console.error(
        "[ERROR] Failed to save adjustments, recalculate, or fetch results:",
        error,
      );
    }
  }, [
    changedMetrics,
    data?.teams,
    localAdjustments,
    currentRoundIndex,
    onChangesMade,
    onUpdate,
  ]);

  const rows = useMemo(() => {
    let firstCpuTeam = true;
    return (data?.teams ?? []).map((team) => {
      const teamData = localAdjustments[selectedMetric]?.[team.teamId];
      const resultValue = teamData
        ? formatMetricValue(selectedMetric, teamData.results[currentRoundIndex])
        : "-";
      if (team.teamId === 1) {
        console.log(
          teamData?.results?.[currentRoundIndex],
          (teamData?.results?.[currentRoundIndex] * 100).toFixed(2) + "%",
        );
      }
      const adjustmentValue = team.isCpuTeam
        ? "-"
        : teamData
          ? formatAdjustmentValue(
              selectedMetric,
              teamData.adjustments[currentRoundIndex],
            )
          : "-";
      const position = teamPositions[team.teamId] || "-";
      const shouldRenderSeparator = team.isCpuTeam && firstCpuTeam;
      if (shouldRenderSeparator) {
        firstCpuTeam = false;
      }
      return {
        isCpu: team.isCpuTeam,
        teamName: team.teamName,
        resultValue,
        adjustmentValue,
        position,
        teamId: team.teamId,
        shouldRenderSeparator,
      };
    });
  }, [
    currentRoundIndex,
    data?.teams,
    localAdjustments,
    selectedMetric,
    teamPositions,
  ]);

  return (
    <VerticalGroup spaceBetweenElements={4} wide>
      <Text size="xl" bold>{`Round ${selectedRound} Adjustments`}</Text>
      <Container fit>
        <VerticalGroup spaceBetweenElements={4} wide>
          <InlineGroup spread block>
            <Dropdown
              block={isMobile}
              selectProps={{
                options: allowedMetrics,

                onChange: (option: any) => setSelectedMetric(option.value),
                value: allowedMetrics.find(
                  (option) => option.value === selectedMetric,
                ),
              }}
            />
            {!isMobile && (
              <IconButton
                icon="disk"
                text="Calculate Model"
                onClick={handleSaveLocalAdjustment}
              />
            )}
          </InlineGroup>
          <Card wide>
            <Table className="w-full table no-stripe no-header-bg">
              <thead>
                <Tr>
                  <Th left style={{ paddingLeft: 0 }}>
                    <Text bold size="sm">
                      Teams
                    </Text>
                  </Th>
                  <Th center>
                    <Text bold size="sm">
                      Value
                    </Text>
                  </Th>
                  <Th>
                    <Text bold size="sm">
                      Change
                    </Text>
                  </Th>
                  <Th center>
                    <Text bold size="sm">
                      Position
                    </Text>
                  </Th>
                </Tr>
              </thead>
              <tbody>
                {rows.map((row, index) => {
                  return (
                    <React.Fragment key={index}>
                      {row.shouldRenderSeparator && (
                        <tr>
                          <td
                            colSpan={4}
                            style={{
                              borderBottom: "1px solid #ccc",
                              padding: "0",
                              height: "10px",
                            }}
                          ></td>
                        </tr>
                      )}
                      <Tr>
                        <td style={{ paddingLeft: 0 }}>
                          <Text allowOverflow>{row.teamName}</Text>
                        </td>
                        <td>
                          {row.isCpu ? (
                            <InlineGroup center verticalCenter fullHeight>
                              <Text
                                center
                                style={{ width: isMobile ? "50%" : "25%" }}
                                size="sm"
                              >
                                {row.resultValue}
                              </Text>
                            </InlineGroup>
                          ) : (
                            <InlineGroup
                              fullHeight
                              verticalCenter
                              center
                              spaceBetweenElements={2}
                            >
                              <Clickable
                                onClick={decrementAdjustment(row.teamId)}
                              >
                                <Icon
                                  noMargin
                                  type="roundMinus"
                                  colour="secondaryBlue"
                                />
                              </Clickable>

                              <Text
                                center
                                style={{ width: isMobile ? "50%" : "25%" }}
                                size="sm"
                              >
                                {row.resultValue}
                              </Text>
                              <Clickable
                                onClick={incrementAdjustment(row.teamId)}
                              >
                                <Icon
                                  noMargin
                                  type="roundPlus"
                                  colour="secondaryBlue"
                                />
                              </Clickable>
                            </InlineGroup>
                          )}
                        </td>
                        <td>
                          <Text size="sm">
                            <InlineGroup verticalCenter center={isMobile}>
                              <div>{row.adjustmentValue}</div>
                            </InlineGroup>
                          </Text>
                        </td>
                        <td>
                          <Text center size="sm">
                            {row.position}
                          </Text>
                        </td>
                      </Tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </Table>
          </Card>
          {isMobile && (
            <Button large block wide onClick={handleSaveLocalAdjustment}>
              Save
            </Button>
          )}
        </VerticalGroup>
      </Container>
    </VerticalGroup>
  );
};

export default GameModelAdjustmentsContent;

const formatMetricValue = (metric: string, value: number | string): string => {
  if (typeof value === "string") return value;

  if (percentageMetrics[metric]) {
    return (value * 100).toFixed(2) + "%";
  }

  if (
    ["complaints", "regulatorActions", "riskIncidents", "nps"].includes(metric)
  ) {
    return Math.round(value).toString();
  }
  return value.toFixed(2);
};

const formatAdjustmentValue = (
  metric: string,
  value: number | string,
): string => {
  if (typeof value === "string") return value;

  if (percentageMetrics[metric]) {
    return (value / 100).toFixed(2) + "%";
  }

  if (
    ["complaints", "regulatorActions", "riskIncidents", "nps"].includes(metric)
  ) {
    return Math.round(value).toString();
  }
  return value.toFixed(2);
};

const getAdjustmentMetric = (metric: string) =>
  metric === "shareholderReturns" ? "tsr" : metric;
