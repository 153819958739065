import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
  useRef,
} from "react";
import { useGroups } from "../../../../../../hooks/useGroup";
import Dropdown from "../../../../../atoms/form/input/Dropdown";

interface Props {
  assessmentId: string;
  groupId?: string;
  onChange?: (groupId: string) => void;
  value?: API.AssessmentGroupResponse;
  error?: string;
}

export const GroupSelect: React.FC<Props> = ({
  error,
  groupId,
  assessmentId,
  value,
  onChange,
}) => {
  const { data: groups, inProgress } = useGroups(assessmentId);
  const initialGroupId = useRef(groupId);
  const [selectedGroup, setGroup] = useState(value || null);

  const options = useMemo(() => {
    return (
      groups?.map((group) => ({
        label: `Round ${group.round} - ${group.name}`,
        value: group,
      })) ?? []
    );
  }, [groups]);

  useEffect(() => {
    if (groups && groups.length && initialGroupId.current) {
      const group = groups.find((g) => g.id === initialGroupId.current);
      if (group) setGroup(group);
    }
  }, [groups]);

  const selectedOption = useMemo(() => {
    return options.find((option) => option.value === selectedGroup);
  }, [options, selectedGroup]);

  useEffect(() => {
    onChange && selectedGroup && onChange(selectedGroup.id);
  }, [onChange, selectedGroup]);

  const onOptionChange = useCallback((newValue) => {
    setGroup(newValue.value);
  }, []);

  if (inProgress) {
    return null;
  }

  return (
    <Dropdown
      label="Group *"
      selectProps={{
        value: selectedOption,
        options,
        onChange: onOptionChange,
      }}
      error={error}
    />
  );
};
