import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useOpenEvents } from "../../../hooks/useOpenEvents";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import Text from "../../atoms/text/Text";
import "./OpenEventSidebar.scss";
import Clickable from "../../atoms/clickable/Clickable";
import { shortDate } from "../../../lib/date";
import Avatar from "../../atoms/avatar/Avatar";
import Toggle from "../../atoms/toggle/Toggle";

interface Props {
  eventId?: string;
  onChange: (gameId: string) => void;
}

export const formatGameInfo = (event: API.EventResponseSummary) => {
  let endBit = "";
  if (event.streamName) {
    endBit = ` - ${event.streamName}`;
  }
  return `${shortDate(event.date)}${endBit}`;
};

function OpenEventSidebar({ eventId, onChange }: Props) {
  const { inProgress, data } = useOpenEvents();
  const { data: loadTestData } = useOpenEvents({ isLoadTest: true });
  const [showLoadTest, setShowLoadTest] = useState(false);
  const events = useMemo(() => {
    if (showLoadTest) {
      return loadTestData ? loadTestData.filter((e) => e.isGameSetup) : [];
    }
    return data ? data.filter((e) => e.isGameSetup) : [];
  }, [data, loadTestData, showLoadTest]);

  const event = useMemo(() => {
    if (!eventId) {
      return null;
    }
    return (
      data?.find((e) => e.id === eventId) ||
      loadTestData?.find((e) => e.id === eventId)
    );
  }, [data, eventId, loadTestData]);

  useEffect(() => {
    if (event && event.isLoadTest && !showLoadTest) {
      setShowLoadTest(true);
    }
  }, [event, showLoadTest]);

  const onToggleUpdate = useCallback((checked: boolean) => {
    setShowLoadTest(checked);
  }, []);

  return (
    <div className="openeventsidebar">
      <VerticalGroup
        className={classNames("events-container", {
          "full-height": inProgress || !data,
        })}
        wide
      >
        <InlineGroup className="pl-2 pr-4 pt-4 pb-2">
          <Text colour="green" size="lg" center>
            Games
          </Text>
        </InlineGroup>
        {process.env.REACT_APP_SHOW_LOAD_TESTS === "true" &&
          loadTestData &&
          loadTestData.length > 0 && (
            <InlineGroup className="pl-2 pr-4 pb-2" verticalCenter spread block>
              <Text colour="white">Load Test:</Text>
              <Toggle checked={showLoadTest} onUpdate={onToggleUpdate} />
            </InlineGroup>
          )}
        {(inProgress || !data) && (
          <div className="spinner-container">
            <LoadingSpinner />
          </div>
        )}
        {!inProgress && !!data && (
          <VerticalGroup spaceBetweenElements={1} wide>
            {events.map((e) => (
              <Clickable
                onClick={() => onChange(e.id)}
                className={classNames("openeventssidebarevent", {
                  active: e.id === eventId,
                })}
                key={e.id}
              >
                <VerticalGroup spread wide spaceBetweenElements={2}>
                  <InlineGroup verticalCenter center>
                    <Text size="sm" medium>
                      {e.name}
                    </Text>
                  </InlineGroup>
                  <InlineGroup verticalCenter spread block>
                    <Text size="xs" medium>
                      {formatGameInfo(e)}
                    </Text>
                    <Avatar
                      size={5}
                      id={e.id}
                      src={e.branding?.brandingImage?.imageUrl}
                      alt={e.client}
                      defaultText={e.client[0]}
                    />
                  </InlineGroup>
                </VerticalGroup>
              </Clickable>
            ))}
          </VerticalGroup>
        )}
      </VerticalGroup>
    </div>
  );
}

export default OpenEventSidebar;
