import React, { ChangeEvent, useCallback } from "react";
import { Redirect } from "react-router-dom";
import Button from "../../../atoms/button/Button";
import Input from "../../../atoms/form/input/Input";
import useForm from "../../../../hooks/useForm";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import DisplayField from "../../../atoms/form/display-field/DisplayField";
import Form, { FormRequiredText } from "../../../atoms/form/Form";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Dropdown from "../../../atoms/form/input/Dropdown";
import Banner from "../../../atoms/banner/Banner";
import ClientDropdown from "../../../organisms/client-dropdown/ClientDropdown";
import { formatUserType } from "./lib/formatters";
import { useCurrentUser } from "../../../../context/userContext";
import InformationPopup from "../../../organisms/information-popup/InformationPopup";
import Text from "../../../atoms/text/Text";
import Image from "../../../atoms/image/Image";
import UploadFile from "../../../organisms/upload-file/upload-file";
import useObjectURL from "use-object-url";
interface Props {
  user?: API.UserResponse;
  onSave: (data: API.UserRequest, image?: File) => Promise<API.UserResponse>;
  onCancel: () => void;
}

export const ACCOUNT_TYPES = [
  "superadmin",
  "admin",
  "standard",
  "facilitator",
].map((type) => ({ value: type, label: formatUserType(type as API.Role) }));

function UserForm({ user, onSave, onCancel }: Props) {
  const currentUser = useCurrentUser();
  const [
    {
      formData: {
        email,
        firstName,
        lastName,
        type,
        state,
        clientIds,
        bio,
        dotPoints,
        image,
      },
      inProgress,
      fieldErrors,
      error,
      data,
      completed,
    },
    {
      setField,
      setDropdownField,
      setMultiSelectField,
      handleSubmit,
      setFieldSimple,
      setFieldRaw,
    },
  ] = useForm(
    user
      ? {
          ...user,
          dotPoints: user.dotPoints || [],
        }
      : {
          email: "",
          firstName: "",
          lastName: "",
          type: "standard",
          state: "active",
          clientIds: [],
          dotPoints: [],
          bio: "",
          image: null,
        },
  );

  const callback = useCallback(() => {
    const payload = {
      email: email.trim(),
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      type,
      state,
      clientIds,
      bio: bio,
      dotPoints: dotPoints,
    };

    return onSave(payload, image);
  }, [
    onSave,
    email,
    firstName,
    lastName,
    type,
    state,
    clientIds,
    bio,
    dotPoints,
    image,
  ]);

  const objectURL = useObjectURL(image);
  const handleDotPointChange = useCallback(
    (value: string, index: number) => {
      const updatedDotPoints = [...dotPoints];
      updatedDotPoints[index] = value;
      setFieldSimple("dotPoints")(updatedDotPoints);
    },
    [dotPoints, setFieldSimple],
  );

  const handleAddDotPoint = useCallback(() => {
    const updatedDotPoints = [...dotPoints, ""];
    setFieldSimple("dotPoints")(updatedDotPoints);
  }, [dotPoints, setFieldSimple]);

  const handleRemoveDotPoint = useCallback(
    (index: number) => {
      const updatedDotPoints = dotPoints.filter(
        (_: string, i: number) => i !== index,
      );
      setFieldSimple("dotPoints")(updatedDotPoints);
    },
    [dotPoints, setFieldSimple],
  );

  const validate = useCallback(() => {
    const errors = [];
    if (!email) {
      errors.push({ field: "email", message: "Enter an email" });
    }
    if (!firstName) {
      errors.push({ field: "firstName", message: "Enter a first name" });
    }
    if (!lastName) {
      errors.push({ field: "lastName", message: "Enter a last name" });
    }
    if (!["superadmin", "facilitator"].includes(type) && !clientIds.length) {
      errors.push({
        field: "clientIds",
        message: "Please select at least one client",
      });
    }
    const emptyHighlights = dotPoints.some((point: string) => !point.trim());
    if (dotPoints.length > 0 && emptyHighlights) {
      errors.push({
        field: "dotPoints",
        message: "Please fill in all career highlights or remove empty ones",
      });
    }

    return errors;
  }, [email, firstName, lastName, clientIds, type, dotPoints]);
  const handleLogo = useCallback(
    async (file) => {
      setFieldRaw("image", file);
    },
    [setFieldRaw],
  );
  return (
    <VerticalGroup spread wide full>
      <VerticalGroup className="pb-4" spread wide>
        <Banner type="error" active={!!error} message={error?.message} />
        {!!completed && !error && (
          <Redirect to={`/administration/users/${data.id}/view`} />
        )}
        <Form id="user-add-form" onSubmit={handleSubmit(callback, validate)}>
          <VerticalGroup wide spaceBetweenElements={4}>
            <FormRequiredText />
            <Input
              autoFocus
              data-test="email"
              type="text"
              label="Email *"
              value={email}
              onChange={setField("email")}
              error={fieldErrors.email}
            />
            <Input
              data-test="firstName"
              type="text"
              label="First Name *"
              value={firstName}
              onChange={setField("firstName")}
              error={fieldErrors.firstName}
            />
            <Input
              data-test="lastName"
              type="text"
              label="Last Name *"
              value={lastName}
              onChange={setField("lastName")}
              error={fieldErrors.lastName}
            />
            <InlineGroup verticalCenter spaceBetweenElements={2}>
              <Dropdown
                label="Account Type"
                selectProps={{
                  options:
                    currentUser?.type === "superadmin"
                      ? ACCOUNT_TYPES
                      : ACCOUNT_TYPES.filter(
                          (type) => type.value !== "superadmin",
                        ),
                  defaultValue: ACCOUNT_TYPES.find((p) => p.value === type),
                  onChange: setDropdownField("type"),
                }}
              />
              <InformationPopup
                large
                shadow
                title={"Account Type Access"}
                body={
                  <VerticalGroup>
                    <VerticalGroup>
                      <Text bold>{"Super Admin:"}</Text>

                      <Text>
                        {
                          "Events: View, create, modify, and delete all events. Export event data and participant lists."
                        }
                      </Text>

                      <Text>
                        {
                          "Simulations: Full control to create, view, edit, delete, and duplicate all simulations. "
                        }
                      </Text>
                      <Text>
                        {
                          "Branding: Complete management of branding, including image uploads. "
                        }
                      </Text>
                      <Text>
                        {
                          "User Management: Unrestricted access to all user-related functions. "
                        }
                      </Text>
                      <Text>
                        {
                          "Assessments: Full access to create, view, edit, and delete all assessment components. "
                        }
                      </Text>
                      <Text>
                        {"Audit Logs: Access to view all audit logs "}
                      </Text>
                      <Text>
                        {
                          "Clients: Full control to create, view, edit, delete clients, and manage client logos. "
                        }
                      </Text>
                      <Text>
                        {
                          "Economic Scenarios: Complete management of economic scenarios, including creation, editing, and deletion.  "
                        }
                      </Text>

                      <Text>
                        {
                          "Reporting Templates: Full access to create, view, edit, delete, and duplicate reporting templates. Upload images and videos.  "
                        }
                      </Text>

                      <Text>
                        {
                          "System Status: Access to view API connectivity status. "
                        }
                      </Text>
                      <Text>
                        {
                          "Tablet Management: Complete control over tablet mappings, configurations, and management "
                        }
                      </Text>
                    </VerticalGroup>
                    <VerticalGroup>
                      <Text bold>{"Admin:"}</Text>
                      <Text>
                        {
                          "Access limited to resources assigned to their clients."
                        }
                      </Text>
                      <Text>
                        {
                          "Events: View, create, and modify events assigned to their clients. Export data for assigned events. "
                        }
                      </Text>

                      <Text>
                        {
                          "Simulations: Create, view, edit, delete, and duplicate simulations . "
                        }
                      </Text>
                      <Text>
                        {
                          "Branding: View and update branding (excluding image uploads). "
                        }
                      </Text>

                      <Text>
                        {
                          "User Management: Create and view users, limited deletion rights. "
                        }
                      </Text>
                      <Text>
                        {
                          "Assessments: Access to create, view, edit, and delete assessments. "
                        }
                      </Text>
                      <Text>{"Clients: View client information. "}</Text>
                      <Text>
                        {
                          "Reporting Templates: Create, view, edit, delete, and duplicate reporting templates. "
                        }
                      </Text>
                    </VerticalGroup>
                    <VerticalGroup>
                      <Text bold>{"Standard:"}</Text>
                      <Text>
                        {
                          "Access limited to resources assigned to their clients."
                        }
                      </Text>
                      <Text>
                        {"Events: View and participate in assigned events. "}
                      </Text>
                      <Text>
                        {
                          "Simulations: Access, interact with, create, view, edit, delete, and duplicate simulations .  "
                        }
                      </Text>

                      <Text>
                        {
                          "Branding: View branding relevant to their assignments. "
                        }
                      </Text>
                      <Text>
                        {
                          "User Management: Manage own account information only. "
                        }
                      </Text>
                      <Text>
                        {
                          "Assessments: Access to create, view, edit, and delete assessments . "
                        }
                      </Text>
                      <Text>{"Clients: View client information  "}</Text>
                      <Text>
                        {
                          "Reporting Templates: Create, view, edit, delete, and duplicate reporting templates . "
                        }
                      </Text>
                    </VerticalGroup>
                  </VerticalGroup>
                }
              />
            </InlineGroup>
            {!["superadmin", "facilitator"].includes(type) && (
              <ClientDropdown
                label="Select Clients *"
                isMulti
                clientIds={clientIds}
                onChange={setMultiSelectField("clientIds")}
                error={fieldErrors.clientIds}
              />
            )}
            {!!user && <DisplayField label="User ID" value={user.id} />}
            <Text size="xl" bold>
              Facilitator Details
            </Text>
            <VerticalGroup wide spaceBetweenElements={4}>
              <DisplayField
                compact
                label="Photo"
                value={
                  <Image
                    className="br-2"
                    shadow
                    heightSize={32}
                    src={objectURL || user?.imageUrl}
                    alt="User Photo"
                  />
                }
              />
              <UploadFile
                buttonText="Upload Photo"
                label="Upload new photo"
                handleFileUpload={handleLogo}
                title="Select photo"
                description="Should be at least 400x400 px"
                allowedType="image"
                errorMsg={fieldErrors.imageMd5}
              />
              <Input
                block
                data-test="bio"
                label="Bio"
                value={bio || ""}
                onChange={setField("bio")}
                error={fieldErrors.bio}
                type="textbox"
              />
              <VerticalGroup spaceBetweenElements={2} spread wide>
                {dotPoints.map((point: string, index: number) => (
                  <InlineGroup
                    spaceBetweenElements={2}
                    block
                    verticalCenter
                    key={index}
                  >
                    <Input
                      block
                      label={index === 0 ? "Career Highlights" : "\u00A0"}
                      type="text"
                      value={point}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleDotPointChange(e.target.value, index)
                      }
                      placeholder="Enter career highlight"
                    />
                    <Button danger onClick={() => handleRemoveDotPoint(index)}>
                      Remove
                    </Button>
                  </InlineGroup>
                ))}

                <InlineGroup verticalCenter block>
                  <Input
                    hidden
                    label={
                      dotPoints.length > 0 ? "\u00A0" : "Career Highlights"
                    }
                  />
                  <Button block wide onClick={handleAddDotPoint}>
                    Add Career Highlight
                  </Button>
                </InlineGroup>

                {fieldErrors.dotPoints && (
                  <Text color="error">{fieldErrors.dotPoints}</Text>
                )}
              </VerticalGroup>
            </VerticalGroup>
          </VerticalGroup>
        </Form>
      </VerticalGroup>
      <InlineGroup block spread>
        <Button data-test="cancel" light wide onClick={onCancel}>
          Cancel
        </Button>
        <Button
          data-test="save"
          type="submit"
          form="user-add-form"
          wide
          inProgress={inProgress}
        >
          Save
        </Button>
      </InlineGroup>
    </VerticalGroup>
  );
}

export default UserForm;
