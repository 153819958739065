const pluckResultsByType = <K extends string, T extends Record<K, any>[]>(
  data: T,
  type: K,
) => {
  const results = [];

  for (const year of data) {
    results.push(year[type]);
  }

  return results;
};

export default pluckResultsByType;
