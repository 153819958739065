import React, { useCallback, useState, ReactElement } from "react";
import className from "classnames";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import Text from "../../atoms/text/Text";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import Clickable from "../../atoms/clickable/Clickable";
import "./accordion.scss";
import Icon from "../../atoms/icon/Icon";

type AccordionState = "collapse" | "expand";

interface AccordionProps {
  title: string;
  wide?: boolean;
  defaultState: "collapse" | "expand";
  actions?: ReactElement;
}

export const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  wide,
  defaultState,
  actions = null,
}) => {
  const [currentState, setCurrentState] =
    useState<AccordionState>(defaultState);

  const onToggle = useCallback(() => {
    setCurrentState((state) => (state === "collapse" ? "expand" : "collapse"));
  }, [setCurrentState]);

  return (
    <VerticalGroup wide={wide}>
      <Clickable className="accordion-header" onClick={onToggle}>
        <InlineGroup block spread verticalCenter>
          <Text size="lg">{title}</Text>
          <InlineGroup spaceBetweenElements={8}>
            <InlineGroup>
              {actions}
              <Icon
                type="arrow"
                fill="none"
                className={className("arrow-icon", {
                  isCollapsed: currentState === "collapse",
                })}
              />
            </InlineGroup>
          </InlineGroup>
        </InlineGroup>
      </Clickable>

      <div
        className={className("accordion-content", {
          isCollapsed: currentState === "collapse",
          wide,
        })}
      >
        {children}
      </div>
    </VerticalGroup>
  );
};
