import React, { useCallback, useEffect, useRef, useState } from "react";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import Banner from "../../atoms/banner/Banner";
import ModelAPI from "../../../services/modelApi";
import GameModelAdjustmentsContent from "./GameModelAdjustmentsContent";

interface Props {
  eventId: string;
  configuration: ModelAPI.ConfigurationResponse;
  selectedRound: number;
  onRecalculate: () => void;
  onChangesMade: (hasChanges: boolean) => void;
  handleCancelClose: () => void;
}

const FacilitatorModelAdjustments: React.FC<Props> = ({
  eventId,
  selectedRound,
  configuration,
  onRecalculate,
  onChangesMade,
  handleCancelClose,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const adjustmentsRef = useRef<ModelAPI.FacilitatorAdjustmentsResponse | null>(
    null,
  );

  const resultsRef = useRef<ModelAPI.RoundResultsResponse[] | null>(null);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      const [adjustments, results] = await Promise.all([
        ModelAPI.getAdjustments(eventId),
        ModelAPI.getModelTeamResultsAll(eventId, selectedRound),
      ]);
      adjustmentsRef.current = adjustments;
      resultsRef.current = results;
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  }, [eventId, selectedRound]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleUpdate = useCallback(
    async (
      adjustments: Array<{
        type: keyof ModelAPI.FacilitatorAdjustmentsResponse;
        teamId: number;
        value: number;
      }>,
    ) => {
      try {
        const apiAdjustments = adjustments.map((adj) => {
          return {
            teamId: adj.teamId,
            type: adj.type,
            value: adj.value,
          };
        });

        await ModelAPI.updateMultipleAdjustments(
          eventId,
          selectedRound,
          apiAdjustments,
        );

        const { currentRound, numberOfHistoricRounds } = configuration;
        const currentRoundIndex = numberOfHistoricRounds + currentRound - 1;

        if (adjustmentsRef.current) {
          adjustments.forEach((adj) => {
            if (adjustmentsRef.current) {
              adjustmentsRef.current[adj.type][adj.teamId][currentRoundIndex] =
                adj.value;
            }
          });
        }

        const updatedResults = await ModelAPI.getModelTeamResultsAll(
          eventId,
          selectedRound,
        );
        resultsRef.current = updatedResults;
        onChangesMade(false);
        handleCancelClose();

        onRecalculate();
      } catch (err) {
        console.error("Error updating adjustments:", err);
      }
    },
    [
      eventId,
      configuration,
      selectedRound,
      onRecalculate,
      onChangesMade,
      handleCancelClose,
    ],
  );
  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <Banner
        type="error"
        active
        message={error.message || "An error occurred"}
      />
    );
  }

  if (!adjustmentsRef.current || !resultsRef.current) {
    return null;
  }

  return (
    <GameModelAdjustmentsContent
      eventId={eventId}
      selectedRound={selectedRound}
      configuration={configuration}
      adjustmentData={adjustmentsRef.current}
      resultData={resultsRef.current}
      onUpdate={handleUpdate}
      onChangesMade={onChangesMade}
    />
  );
};

export default FacilitatorModelAdjustments;
