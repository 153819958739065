import classNames from "classnames";
import React from "react";
import { useResultsContext } from "../context/context";
import "./ResultsTable.scss";

interface Props {
  id?: string;
  fadeIn?: boolean;
  key?: number;
  noborder?: boolean;
  className?: string;
  softGridLines?: boolean;
  alternateColourRows?: boolean;
  normalFontWeight?: boolean;
}

const ResultsTable: React.FC<Props> = ({
  children,
  fadeIn,
  id,
  key,
  noborder,
  className,
  softGridLines,
  alternateColourRows = false,
  normalFontWeight = false,
}) => {
  const context = useResultsContext();
  return (
    <table
      key={"tableRender" + key}
      id={id}
      className={classNames("results-table", className, {
        "fade-in": !context.noAnimations && fadeIn,
        "results-table-no-animations": context.noAnimations,
        noborder: noborder,
        "light-grids": softGridLines,
        "alternate-colour-rows": alternateColourRows,
        "normal-weight": normalFontWeight,
      })}
    >
      {children}
    </table>
  );
};

export default ResultsTable;
