import React from "react";
import {
  ComposedChart,
  XAxis,
  YAxis,
  Area,
  ResponsiveContainer,
  Legend,
  Line,
  LabelList,
  CartesianGrid,
} from "recharts";

import Theme from "../../../../styles/_theme.module.scss";
import { useResultsContext } from "../context/context";
import "./ResultsComposedChart.scss";
import useIsHighResolution from "../../../../hooks/useIsHightResolution";
import useChartStyles from "../../../../hooks/useChartStyles";

interface Props {
  data: object[];
  formatter?: (val: number) => string | number;
  marginChangeLabel: any;
  yAxisFormatter?: (val: number) => string | number;
  maxYAxisvalue: number;
  minYAxisvalue: number;
  numberOfTicks: number;
}

const ResultsComposedChart: React.FC<Props> = ({
  data,
  formatter,
  marginChangeLabel,
  yAxisFormatter,
  maxYAxisvalue,
  minYAxisvalue,
  numberOfTicks,
}) => {
  const context = useResultsContext();
  const isHighResolution = useIsHighResolution();
  const chartStyles = useChartStyles();

  return (
    <ResponsiveContainer width="100%" height="100%" debounce={1}>
      <ComposedChart
        data={data}
        margin={{
          top: 20,
          right: isHighResolution ? 50 : 30,
          left: isHighResolution ? 50 : 0,
          bottom: 10,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="name"
          padding={{ left: 75, right: 75 }}
          tickLine={false}
          tick={{
            style: {
              fontSize: "clamp(0.15rem, calc(0.15rem + 0.80vw), 2rem)",
            },
          }}
        />
        <YAxis
          tick={{
            style: {
              fontSize: "clamp(0.15rem, calc(0.15rem + 0.80vw), 2rem)",
            },
          }}
          axisLine={false}
          tickLine={false}
          minTickGap={0}
          tickMargin={0}
          tickCount={numberOfTicks + 1}
          tickFormatter={
            yAxisFormatter
              ? (tick: any) => yAxisFormatter(tick)
              : formatter
                ? (tick) => formatter(tick)
                : (tick) => tick
          }
          domain={[minYAxisvalue, maxYAxisvalue]}
        />
        <Area
          dataKey="net"
          fill={"#32CD32"}
          stroke="#32CD32"
          fillOpacity={0.3}
          strokeWidth={chartStyles.strokeWidth}
          dot={{ r: chartStyles.circleRadius }}
          legendType="square"
          name="Net Interest Margin"
          animationDuration={context.noAnimations ? 0 : 1000}
        />
        <Line
          dataKey="rate"
          stroke={"black"}
          strokeWidth={chartStyles.strokeWidth}
          dot={{ r: chartStyles.circleRadius }}
          name="Interest Earned"
          animationDuration={context.noAnimations ? 0 : 1000}
        >
          <LabelList
            className="label-list-common"
            dataKey="rate"
            position="bottom"
            offset={10}
            fontWeight="bold"
            formatter={
              formatter ? (tick: any) => formatter(tick) : (tick: any) => tick
            }
          />
        </Line>
        <Line
          dataKey="midpoint"
          dot={false}
          strokeWidth={0}
          legendType="none"
          animationDuration={context.noAnimations ? 0 : 1000}
        >
          <LabelList
            className="label-list-margin"
            dataKey="margin"
            position="top"
            fontSize={22}
            fontWeight="bold"
            fill="black"
            formatter={
              formatter ? (tick: any) => formatter(tick) : (tick: any) => tick
            }
          />
          <LabelList
            className="label-list-margin-change"
            dataKey="marginChange"
            position="bottom"
            fontSize={16}
            fontWeight="bold"
            content={marginChangeLabel}
          />
        </Line>
        <Line
          dataKey="cost"
          stroke={Theme.orange}
          strokeWidth={chartStyles.strokeWidth}
          dot={{ r: chartStyles.circleRadius }}
          name="Cost of Funds"
          animationDuration={context.noAnimations ? 0 : 1000}
        >
          <LabelList
            className="label-list-common"
            dataKey="cost"
            position="top"
            offset={10}
            fontSize={12}
            fontWeight="bold"
            formatter={
              formatter ? (tick: any) => formatter(tick) : (tick: any) => tick
            }
          />
        </Line>
        <Legend
          iconSize={isHighResolution ? 40 : 20}
          wrapperStyle={{
            bottom: 10,
            left: 0,
            right: 0,
            fontSize: "clamp(0.15rem, calc(0.15rem + 0.80vw), 3rem)",
          }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ResultsComposedChart;
