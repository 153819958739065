import React, { useCallback } from "react";

import { useResultsContext } from "../context/context";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import RoundResultsExecutiveDecisionsTableOne from "./RoundResultsExecutiveDecisionsTableOne";
import RoundResultsExecutiveDecisionsTableTwo from "./RoundResultsExecutiveDecisionsTableTwo";
import { TeamsWithColours } from "../RoundResultsPage";

interface Props {
  teams: TeamsWithColours[];
  executiveDecisions: ModelAPI.Reporting.ExcoDecision;
}

export const maxClicks = 2;

const RoundResultsExecutiveDecisionsTable: React.FC<Props> = (props) => {
  const context = useResultsContext();

  const onNext = useCallback(() => {
    if (context.clicks === maxClicks) {
      context.goNextPage();
    } else {
      context.addClick();
    }
  }, [context]);

  return (
    <RoundResultsContainer onNext={onNext}>
      {context.clicks === 0 && (
        <RoundResultsExecutiveDecisionsTableOne {...props} />
      )}
      {context.clicks > 0 && (
        <RoundResultsExecutiveDecisionsTableTwo
          {...props}
          showDescription={context.clicks > 1}
        />
      )}
    </RoundResultsContainer>
  );
};

export default RoundResultsExecutiveDecisionsTable;
