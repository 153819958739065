import React, { useMemo } from "react";
import classNames from "classnames";
import { v4 as uuid } from "uuid";
import InlineGroup from "../../inlinegroup/InlineGroup";
import Input, { InputStatus } from "./Input";
import { ThemeColours } from "../../../../types/theme";

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  value?: string;
  checked?: boolean;
  name: string;
  inline?: boolean;
  large?: boolean;
  disabled?: boolean;
  colour?: ThemeColours;
  status?: InputStatus;
  isLabelHtml?: boolean;
}

const Radio: React.FC<Props> = ({
  name,
  label,
  inline = false,
  onChange,
  status,
  large,
  isLabelHtml = false,
  ...props
}) => {
  const id = useMemo(() => uuid(), []);

  return (
    <InlineGroup
      verticalCenter
      spaceBetweenElements={inline ? 0 : 2}
      center={inline}
      className={classNames("input-width", { inline, large })}
    >
      <Input
        data-test={props.value}
        id={id}
        type="radio"
        name={name}
        onChange={onChange}
        label={label}
        labelFor={id}
        status={status}
        reverse
        containerBlock
        right
        isLabelHtml={isLabelHtml}
        {...props}
      />
    </InlineGroup>
  );
};

export default Radio;
