export const fakeRiskIncidentsClosing = {
  risk_incidents_below: 150,
  capital: 10000,
  data: [
    {
      teamId: 1,
      incident: 1000,
    },
    {
      teamId: 3,
      incident: 3000,
    },
    {
      teamId: 4,
      incident: 2000,
    },
    {
      teamId: 6,
      incident: 500,
    },
    {
      teamId: 2,
      incident: 1500,
    },
    {
      teamId: 5,
      incident: 1200,
    },
    {
      teamId: 7,
      incident: 800,
    },
  ],
};
