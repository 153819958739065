import React, { ReactNode } from "react";
import classNames from "classnames";
import Modal from "../../atoms/modal/Modal";

import "./StandardModal.scss";
interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description?: string | ReactNode;
  children: React.ReactNode;
  className?: string;
  width?: string;
}

function StandardModal({
  isOpen,
  onClose,
  title,
  description,
  children,
  className,
  width,
}: Props) {
  return (
    <Modal width={width} isOpen={isOpen} onRequestClose={onClose}>
      <div
        className={classNames("standard-modal-body", className, {
          withMinWidth: !width,
        })}
        data-test="standard-modal"
      >
        <div className="title">{title}</div>
        <div className="description">{description}</div>
        {children}
      </div>
    </Modal>
  );
}

export default StandardModal;
