import React, { useCallback } from "react";
import ConfirmModal from "../../organisms/confirm-modal/ConfirmModal";

import useAPIRequest from "../../../hooks/useAPIRequest";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => Promise<void>;
  round: number;
  totalRounds: number;
  publishResults?: boolean;
}

function CloseRoundModal({
  isOpen,
  onClose,
  onComplete,
  totalRounds,
  publishResults,
  round,
}: Props) {
  const [{ inProgress, error }, doAPIRequest] = useAPIRequest<void>(
    onComplete,
    null,
  );

  const handleConfirm = useCallback(() => {
    doAPIRequest(onComplete);
  }, [doAPIRequest, onComplete]);

  const nextRound = round === totalRounds ? "Wrap up" : `Round ${round + 1}`;

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      title={`Confirm Close Round ${round}`}
      description={
        <>
          Closing the round will finalise all decision-making and no further
          changes will be possible to this round. <br />{" "}
          {publishResults
            ? `Results slides will be published to the participant devices allowing them to open them and view them.`
            : ""}{" "}
          <br />
          Press ok to move to {nextRound}
        </>
      }
      confirmTitle="Ok"
      error={error && error.message}
      warning
    />
  );
}

export default CloseRoundModal;
