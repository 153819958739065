import React from "react";

import Card from "../../../atoms/card/Card";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Image from "../../../atoms/image/Image";
import ArrowHeader from "../components/ArrowHeader";

import Theme from "../../../../styles/_theme.module.scss";

import "../RoundResultsTablePage.scss";
import { useResultsContext } from "../context/context";
import classNames from "classnames";
import { TeamsWithColours } from "../RoundResultsPage";
import ResultsText from "../results-text/ResultsText";
import DynamicResultsText from "../results-text/DynamicResultsText";
import EditableReportingHeading from "../components/EditableReportingHeading";
import ReportingSubHeading from "../components/ReportingSubHeading";

interface Props {
  teams: TeamsWithColours[];
  executiveDecisions: ModelAPI.Reporting.ExcoDecision;
}

const getDotColour = (idx: number) => {
  switch (idx) {
    case 0:
      return Theme.yellow;
    case 1:
      return Theme.green;
    case 2:
      return Theme.blue;
    default:
      return "blue";
  }
};

const RoundResultsExecutiveDecisionsTableOne: React.FC<Props> = ({
  executiveDecisions,
}) => {
  const context = useResultsContext();

  if (!executiveDecisions) {
    return null;
  }

  return (
    <VerticalGroup full center wide>
      <EditableReportingHeading defaultHeading="Risk Forum" />
      <ReportingSubHeading subHeading="The right answer is sometimes unclear" />
      <InlineGroup fullHeight>
        <Card
          wide
          sharp
          padding={0}
          className={classNames("executive-decisions-card", {
            animate: !context.noAnimations,
          })}
        >
          <InlineGroup spread fullHeight>
            <VerticalGroup wide>
              <VerticalGroup className="mt-4" spread full>
                <VerticalGroup>
                  <ArrowHeader
                    text="Background Information"
                    colour="primaryDark"
                  />
                  <DynamicResultsText
                    size="sm"
                    className="pl-6 pr-2 pt-3 pb-3"
                    isHtml
                  >
                    {executiveDecisions.description}
                  </DynamicResultsText>
                  <ArrowHeader text="Options" colour="blue" />
                </VerticalGroup>
                <VerticalGroup full className="mt-4">
                  {executiveDecisions.options?.map((option, i) => (
                    <InlineGroup key={i} className="pl-6 pr-2" fullHeight>
                      <VerticalGroup center>
                        <div
                          className="options-dot mr-4"
                          style={{ backgroundColor: getDotColour(i) }}
                        ></div>
                        {i >= 2 ? (
                          <span className="dot-separator mr-4 mt-1" />
                        ) : (
                          <span className="dot-separator mr-4 mt-1 mb-1" />
                        )}
                      </VerticalGroup>
                      <VerticalGroup>
                        <InlineGroup block verticalCenter>
                          <ResultsText size="md" bold>
                            {option.name}
                          </ResultsText>
                        </InlineGroup>
                        <ResultsText size="sm" className="pb-2" isHtml>
                          {option.description}
                        </ResultsText>
                      </VerticalGroup>
                    </InlineGroup>
                  ))}
                </VerticalGroup>
              </VerticalGroup>
            </VerticalGroup>
            <InlineGroup
              center
              verticalCenter
              className="decisions-table-image-wrapper m-6"
              style={{ width: "75%" }}
            >
              <Image
                src={executiveDecisions.imageUrl}
                alt="Table Image"
                cover
                width="100%"
                height="100%"
              ></Image>
              <ResultsText
                size="sm"
                bold
                className="decisions-table-image-overlay"
              >
                {executiveDecisions.name}
              </ResultsText>
            </InlineGroup>
          </InlineGroup>
        </Card>
      </InlineGroup>
    </VerticalGroup>
  );
};

export default RoundResultsExecutiveDecisionsTableOne;
