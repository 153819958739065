import React, { ReactNode } from "react";

interface AvoidPageBreakProps {
  children: ReactNode;
}

const AvoidPageBreak: React.FC<AvoidPageBreakProps> = ({ children }) => {
  return <div className="avoid-page-break">{children}</div>;
};

export default AvoidPageBreak;
