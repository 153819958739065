import React from "react";
import ResultsText from "../../results-text/ResultsText";
import ResultsBarChart from "../../charts/ResultsBarChart";
import { thousandSeparator, round } from "../../../model/utils/formatters";
import commbank from "../../../../../assets/results/commbank.png";
import westpac from "../../../../../assets/results/westpac.png";
import nab from "../../../../../assets/results/nab.png";
import anz from "../../../../../assets/results/anz.png";
import macbank from "../../../../../assets/results/macbank.png";
import tick from "../../../../../assets/results/tick.png";
import Image from "../../../../atoms/image/Image";

const logoMap: { [key: string]: React.ReactNode } = {
  commbank: (
    <Image
      style={{
        width: "100%",
        height: "100%",
        objectFit: "contain",
      }}
      src={commbank}
      alt="CommBank"
    />
  ),
  westpac: (
    <Image
      style={{
        width: "100%",
        height: "100%",
        objectFit: "contain",
      }}
      src={westpac}
      alt="Westpac"
    />
  ),
  nab: (
    <Image
      style={{
        width: "100%",
        height: "100%",
        objectFit: "contain",
      }}
      src={nab}
      alt="NAB"
    />
  ),
  anz: (
    <Image
      style={{
        width: "100%",
        height: "100%",
        objectFit: "contain",
      }}
      src={anz}
      alt="ANZ"
    />
  ),
  macbank: (
    <Image
      style={{
        width: "100%",
        height: "100%",
        objectFit: "contain",
      }}
      src={macbank}
      alt="Macquarie Bank"
    />
  ),
  tick: (
    <Image
      style={{
        width: "100%",
        height: "100%",
        objectFit: "contain",
      }}
      src={tick}
      alt="Tick"
    />
  ),
};

export interface ChartDataItem {
  name: string;
  value: number;
  fill: string;
  logo: string;
  displayValue: string;
  border?: boolean;
  additionalLogo?: string;
  formatter: () => string;
  topLabel: React.ReactNode;
}

interface YAxisData {
  minValue: number;
  maxValue: number;
  numberOfTicks: number;
}

interface ChartSectionProps {
  data: ChartDataItem[];
  yAxisData: YAxisData;
  title: string;
  barSize?: number;
}

const ChartSection: React.FC<ChartSectionProps> = ({
  data,
  yAxisData,
  title,
  barSize = 230,
}) => {
  const processedData = data.map((item) => ({
    ...item,
    logo: logoMap[item.logo],
    additionalLogo: item.additionalLogo
      ? logoMap[item.additionalLogo]
      : undefined,
  }));

  return (
    <div style={{ height: "70%", width: "90%" }}>
      <div className="arrow-container">
        <div className="arrow arrow-left"></div>
        <span className="arrow-text">
          <ResultsText size="lg" bold>
            {title}
          </ResultsText>
        </span>
        <div className="arrow arrow-right"></div>
      </div>
      <ResultsBarChart
        data={processedData}
        formatter={(val) => thousandSeparator(round(val, 0))}
        minYAxisvalue={yAxisData.minValue}
        maxYAxisvalue={yAxisData.maxValue}
        numberOfTicks={yAxisData.numberOfTicks}
        shouldRenderLines={true}
        hideAxes={true}
        showInnerLabels={true}
        barSize={barSize}
        showBottomLabels={false}
        showTopLabels={true}
      />
    </div>
  );
};
export default ChartSection;
