import React from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";
import { percentage } from "../utils/formatters";
import pluckResultsByType from "../utils/pluckResultsByType";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["nimSculpting"];
}

const ModelTeamResultsNimSculpting: React.FC<Props> = ({ data }) => (
  <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
    <TableWithSections
      header="Net Interest Margin Sculpting"
      data={[
        {
          name: "Target Net Interest Margins",
          fields: [
            "HOME_LOANS",
            "BUSINESS_LOANS",
            "DEPOSITS",
            "CREDIT_CARDS",
            "INSTITUTIONAL_BANKING",
            "WEALTH_MANAGEMENT",
            "OFFSHORE_BANK",
          ],
          values: pluckResultsByType(data, "targetNims"),
          formatter: (val: number) => percentage(val, 2),
        },
        {
          name: "Actual Net Interest Margins",
          fields: [
            "HOME_LOANS",
            "BUSINESS_LOANS",
            "DEPOSITS",
            "CREDIT_CARDS",
            "INSTITUTIONAL_BANKING",
            "WEALTH_MANAGEMENT",
            "OFFSHORE_BANK",
          ],
          values: pluckResultsByType(data, "actualNims"),
          formatter: (val: number) => percentage(val, 2),
        },
        {
          name: "Variance",
          fields: [
            "HOME_LOANS",
            "BUSINESS_LOANS",
            "DEPOSITS",
            "CREDIT_CARDS",
            "INSTITUTIONAL_BANKING",
            "WEALTH_MANAGEMENT",
            "OFFSHORE_BANK",
          ],
          values: pluckResultsByType(data, "variance"),
        },
      ]}
    />
  </VerticalGroup>
);

export default ModelTeamResultsNimSculpting;
