import React from "react";
import IntegerDropdown from "../../../../organisms/integer-dropdown/IntegerDropdown";
import { thousandSeparator } from "../../../model/utils/formatters";

interface Props {
  label: string;
  value: number;
  min?: number;
  max?: number;
  step?: number;
  onChange: (selected: any) => void;
}

const AmountDropdown: React.FC<Props> = (props) => {
  const {
    label,
    value,
    onChange,
    min = 0,
    max = 1000000,
    step = 100000,
  } = props;
  return (
    <IntegerDropdown
      label={label}
      value={value}
      onChange={onChange}
      min={min}
      max={max}
      step={step}
      midsize
      labelFormatter={thousandSeparator}
      isCreatable
    />
  );
};

export default AmountDropdown;
