import { useCallback, useEffect, useMemo } from "react";
import API from "../services/api";
import useAPIRequest from "./useAPIRequest";

export const useIntroLayoutConfig = () => {
  const callback = useCallback(() => {
    return API.getIntroLayoutConfig();
  }, []);

  const [{ inProgress, data, error }, doAPIRequest] = useAPIRequest<
    API.BaseIntroTemplateLayoutResponse[]
  >(callback, null);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  return useMemo(
    () => ({
      inProgress,
      data,
      error,
      refresh: doAPIRequest,
    }),
    [inProgress, data, error, doAPIRequest],
  );
};
