import React, { useState, useEffect } from "react";
import SimpleModal from "../../../../organisms/standard-modal/SimpleModal";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import Container from "../../../../atoms/page/Page";
import Text from "../../../../atoms/text/Text";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import Button from "../../../../atoms/button/Button";

const MIN_COLUMNS = 1;
const MAX_COLUMNS = 4;

interface EditRowModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (columns: number) => void;
  initialColumns: number;
}

const EditRowModal: React.FC<EditRowModalProps> = ({
  isOpen,
  onClose,
  onSave,
  initialColumns,
}) => {
  const [columns, setColumns] = useState(initialColumns);

  useEffect(() => {
    setColumns(initialColumns);
  }, [initialColumns]);

  return (
    <SimpleModal
      className="add-section-container"
      showCloseButton
      isOpen={isOpen}
      onClose={onClose}
      size="small"
    >
      <VerticalGroup spaceBetweenElements={2}>
        <Container fit>
          <VerticalGroup spaceBetweenElements={2}>
            <Text size="lg" bold>
              Edit Row
            </Text>
            <InlineGroup verticalCenter block spread>
              <Text>Adjust number of columns:</Text>
              <InlineGroup verticalCenter spaceBetweenElements={3}>
                <Button
                  rounded
                  mini
                  onClick={() => setColumns(Math.max(MIN_COLUMNS, columns - 1))}
                  disabled={columns <= MIN_COLUMNS}
                >
                  -
                </Button>
                <Text>{columns}</Text>
                <Button
                  rounded
                  mini
                  onClick={() => setColumns(Math.min(MAX_COLUMNS, columns + 1))}
                  disabled={columns >= MAX_COLUMNS}
                >
                  +
                </Button>
              </InlineGroup>
            </InlineGroup>
          </VerticalGroup>
        </Container>
        <InlineGroup block right>
          <Button onClick={() => onSave(columns)}>Save</Button>
        </InlineGroup>
      </VerticalGroup>
    </SimpleModal>
  );
};

export default EditRowModal;
