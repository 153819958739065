import React from "react";

import Card from "../../../../atoms/card/Card";
import DisplayField from "../../../../atoms/form/display-field/DisplayField";
import { getLabelFromValue as getStrategyLabel } from "./CpuStrategyDropdown";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import Clickable from "../../../../atoms/clickable/Clickable";
import Icon from "../../../../atoms/icon/Icon";
import AvoidPageBreak from "../../../../atoms/printable/components/AvoidPageBreak";

interface Props {
  data: API.SimulationResponse["playersSetup"];
  onClickEdit?: () => void;
}

const Display: React.FC<Props> = ({ data, onClickEdit }) => {
  return data ? (
    <Card wide>
      <AvoidPageBreak>
        <InlineGroup verticalCenter>
          <h4>Player Settings</h4>
          {onClickEdit ? (
            <Clickable
              data-test={"edit-simulation-players-step"}
              onClick={onClickEdit}
            >
              <Icon
                type="edit"
                tip={{
                  content: "Edit Players",
                  id: "edit-players",
                }}
              />
            </Clickable>
          ) : null}
        </InlineGroup>
        <DisplayField label="Number of human teams" value={data.teams} />
        <DisplayField
          label="Number of computer teams"
          value={data.cpuStrategies.length}
        />
        {data.cpuStrategies.length > 0 && <h4>Computer Teams Strategies</h4>}
        {data.cpuStrategies.map((strategy, index) => (
          <DisplayField
            key={index}
            label={`Team ${index + 1}`}
            value={getStrategyLabel(strategy)}
          />
        ))}
      </AvoidPageBreak>
    </Card>
  ) : null;
};

export default Display;
