import React, { useCallback, useMemo, useRef } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import { TeamsWithColours } from "../RoundResultsPage";
import { percentage, reportingPercentage } from "../../model/utils/formatters";
import { getYAxisDataV2 } from "../charts/yaxis";
import ChartCard from "../components/ChartCard";
import { convertRoundDataToPercentages } from "../charts/convertRoundDataToPercentages";
import {
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import TeamPositions from "../positions/TeamPositions";
import { useResultsContext } from "../context/context";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import useChartStyles from "../../../../hooks/useChartStyles";
import useIsHighResolution from "../../../../hooks/useIsHightResolution";
import useLineChartRendering from "../charts/useLineChartRendering";
import createCustomTooltip from "../components/CustomTooltip";

interface Props {
  pastRound1: boolean;
  teams: TeamsWithColours[];
  creditRating: ModelAPI.Reporting.CreditRating;
}

const createTopLabel = (val: any) => {
  const TopLabel = (props: any) => {
    const { x, y, width } = props.viewBox;
    return (
      <g>
        <text
          x={x + 20}
          y={y - 10}
          fill="#000"
          textAnchor="middle"
          fontWeight="bold"
          fontSize="clamp(0.35rem, calc(0.35rem + 0.60vw), 2rem)"
        >
          {val}
        </text>
        <text
          x={x + width - 20}
          y={y - 10}
          fill="#000"
          textAnchor="middle"
          fontWeight="bold"
          fontSize="clamp(0.35rem, calc(0.35rem + 0.60vw), 2rem)"
        >
          {val}
        </text>
      </g>
    );
  };
  return TopLabel;
};

const RoundResultsCreditRating: React.FC<Props> = ({
  pastRound1,
  teams,
  creditRating,
}) => {
  const data = useMemo(
    () => convertRoundDataToPercentages(creditRating.data),
    [creditRating.data],
  );
  const chartStyles = useChartStyles();
  const isHighResolution = useIsHighResolution();
  const { minValue, maxValue, numberOfTicks, yAxisDp } = getYAxisDataV2({
    isPercentage: true,
    padding: 0,
    pastRound1,
    data: [
      {
        name: "fakeMin",
        value: Number(percentage(creditRating.creditRatings.B, 2, true, true)),
      },
      {
        name: "fakeMax",
        value: Number(
          percentage(
            creditRating.creditRatings.AAA +
              (creditRating.creditRatings.AAA - creditRating.creditRatings.AA),
            2,
            true,
            true,
          ),
        ),
      },
      // @ts-expect-error it's okay
    ].concat(data),
  });

  const { teamPositions } = creditRating;

  const lines = teams.map((t) => ({
    name: t.teamName,
    key: `team${t.teamId}`,
    colour: t.colour,
  }));

  const referenceLines = useMemo(() => {
    const ratings: Array<
      keyof ModelAPI.Reporting.CreditRating["creditRatings"]
    > = ["AAA", "AA", "A", "BBB", "BB", "B"];
    return ratings.map((r) => ({
      label: r,
      y: (creditRating.creditRatings[r] ?? 0) * 100,
    }));
  }, [creditRating.creditRatings]);

  const areas = useMemo(() => {
    return [
      {
        colour: "#91C8B6",
        upper: maxValue,
        lower: creditRating.creditRatings.AAA * 100,
      },
      {
        colour: "#BBDDD2",
        upper: creditRating.creditRatings.AAA * 100,
        lower: creditRating.creditRatings.AA * 100,
      },
      {
        colour: "#CAE5DD",
        upper: creditRating.creditRatings.AA * 100,
        lower: creditRating.creditRatings.A * 100,
      },
      {
        colour: "#DFEFEB",
        upper: creditRating.creditRatings.A * 100,
        lower: creditRating.creditRatings.BBB * 100,
      },
      {
        colour: "#E5B8BC",
        upper: creditRating.creditRatings.BBB * 100,
        lower: creditRating.creditRatings.BB * 100,
      },
      {
        colour: "#CD6C72",
        upper: creditRating.creditRatings.BB * 100,
        lower: creditRating.creditRatings.B * 100,
      },
    ];
  }, [
    creditRating.creditRatings.A,
    creditRating.creditRatings.AA,
    creditRating.creditRatings.AAA,
    creditRating.creditRatings.B,
    creditRating.creditRatings.BB,
    creditRating.creditRatings.BBB,
    maxValue,
  ]);

  const context = useResultsContext();

  const maxClicks = useMemo(
    () => (context.allowPresentationMode || context.readSyncState ? 1 : 0),
    [context.allowPresentationMode, context.readSyncState],
  );

  const onNext = useCallback(() => {
    if (context.clicks < maxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context, maxClicks]);

  const legendFontSize =
    teams.length > 9
      ? "clamp(0.20rem, calc(0.20rem + 0.70vw), 2rem)"
      : "clamp(0.30rem, calc(0.30rem + 0.90vw), 2rem)";

  const shouldRenderLines = useMemo(
    () => context.clicks === maxClicks,
    [context.clicks, maxClicks],
  );

  const chartData = useMemo(() => {
    return data.map((d) => ({
      ...d,
      bArea: [
        creditRating.creditRatings.BB * 100,
        creditRating.creditRatings.B * 100,
      ],
    }));
  }, [creditRating.creditRatings.B, creditRating.creditRatings.BB, data]);

  const chartRef = useRef<any>(null);
  const [
    renderStartTime,
    tooltipVisible,
    setAnimationsCompleted,
    animationCountRef,
  ] = useLineChartRendering({
    // @ts-expect-error it's okay
    data: chartData,
    chartRef,
    shouldRenderLines,
    lines,
  });
  return (
    <RoundResultsContainer onNext={onNext}>
      <VerticalGroup full center wide>
        <ChartCard heading="Credit Rating">
          <ResponsiveContainer width="100%" height="100%" debounce={1}>
            <ComposedChart
              ref={chartRef}
              data={chartData}
              margin={{
                top: 20,
                right: isHighResolution ? 50 : 30,
                left: isHighResolution ? 50 : 0,
                bottom: 10,
              }}
            >
              <CartesianGrid vertical={false} />
              <ReferenceLine y={0} stroke="#000" />
              {referenceLines.map((r) => (
                <ReferenceLine
                  key={r.y}
                  y={r.y}
                  stroke="#000"
                  strokeDasharray="3 3"
                >
                  <Label
                    position="top"
                    content={createTopLabel(r.label)}
                    offset={10}
                  />
                </ReferenceLine>
              ))}
              <XAxis
                dataKey="name"
                tickLine={false}
                padding={{
                  left: isHighResolution ? 150 : 75,
                  right: isHighResolution ? 150 : 75,
                }}
                tick={{
                  style: {
                    fontSize: "clamp(0.15rem, calc(0.15rem + 0.80vw), 2rem)",
                  },
                }}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                minTickGap={0}
                tickMargin={0}
                tickCount={numberOfTicks + 1}
                tickFormatter={(tick) => reportingPercentage(tick, yAxisDp)}
                domain={[minValue, maxValue]}
                tick={{
                  style: {
                    fontSize: "clamp(0.15rem, calc(0.15rem + 0.80vw), 2rem)",
                  },
                }}
              />
              <Tooltip
                isAnimationActive={false}
                active={tooltipVisible}
                formatter={(tick: any) => reportingPercentage(tick)}
                content={createCustomTooltip("highest", data)}
              />
              <Legend
                wrapperStyle={{
                  bottom: 7,
                  left: 0,
                  right: 0,
                  fontSize: legendFontSize,
                }}
              />
              {shouldRenderLines &&
                lines.map((l, idx) => (
                  <Line
                    key={`line-item-${idx}`}
                    name={l.name}
                    type="natural"
                    dataKey={l.key}
                    stroke={l.colour}
                    strokeWidth={chartStyles.strokeWidth}
                    dot={{ r: chartStyles.circleRadius }}
                    animationDuration={context.noAnimations ? 0 : 1000}
                    isAnimationActive={context.noAnimations ? false : true}
                    animationBegin={
                      context.noAnimations
                        ? 0
                        : (renderStartTime ? Date.now() - renderStartTime : 0) +
                          idx * 1000
                    }
                    onAnimationEnd={() => {
                      animationCountRef.current += 1;
                      if (animationCountRef.current === lines.length) {
                        setAnimationsCompleted(true);
                      }
                    }}
                  />
                ))}
              {areas.map((a, i) => (
                <ReferenceArea
                  key={i}
                  y1={a.upper}
                  y2={a.lower}
                  fill={a.colour}
                  stroke={a.colour}
                  fillOpacity={0.3}
                  strokeWidth={0}
                />
              ))}
            </ComposedChart>
          </ResponsiveContainer>
          {!context.hideTeamPositions &&
            teamPositions &&
            teamPositions.length > 0 && (
              <TeamPositions
                teams={teams}
                teamPositions={teamPositions}
                shouldRender={shouldRenderLines}
              />
            )}
        </ChartCard>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsCreditRating;
