import { useCallback } from "react";

export const useNoPropagation = (fn: () => void) =>
  useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      fn();
    },
    [fn],
  );
