import React, { useCallback } from "react";
import ConfirmModal from "../../../../../organisms/confirm-modal/ConfirmModal";

import useAPIRequest from "../../../../../../hooks/useAPIRequest";
import API from "../../../../../../services/api";
import { formatPersonName } from "../../../../../../lib/formatters";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import Text from "../../../../../atoms/text/Text";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  eventId: string;
  participants: API.ParticipantResponse[];
  allParticipants?: API.ParticipantResponse[];
}
const participantsToIds = (participants: API.ParticipantResponse[]) => {
  const ids = [];
  for (const p of participants) {
    ids.push(p.id);
  }
  return ids;
};

function DeleteParticipant({
  isOpen,
  onClose,
  onComplete,
  participants,
  allParticipants,
  eventId,
}: Props) {
  const callback = useCallback(() => {
    if (participants.length) {
      let transferCeo = false;
      if (participants.length === 1 && participants[0].isCeo) {
        transferCeo = true;
      } else if (participants.length > 1) {
        const ceos = participants.filter((p) => p.isCeo);
        if (ceos.length > 0) {
          transferCeo = true;
        }
      }

      return API.deleteParticipants(
        eventId,
        participantsToIds(participants),
        transferCeo,
      );
    } else {
      return API.deleteAllParticipants(eventId);
    }
  }, [eventId, participants]);

  const [{ inProgress, error }, doAPIRequest, clearError] = useAPIRequest(
    callback,
    null,
  );

  const handleConfirm = useCallback(() => {
    if (participants.length > 0) {
      doAPIRequest(onComplete);
    } else {
      onClose();
    }
  }, [doAPIRequest, onComplete, participants, onClose]);

  const handleClose = useCallback(() => {
    clearError();
    onClose();
  }, [clearError, onClose]);

  let message = "";
  let noParticipants = false;

  if (participants.length === 0) {
    message =
      "No participants were selected. Please select a participant to delete";
    noParticipants = true;
  } else if (participants.length === 1) {
    message = `Are you sure you want to delete ${formatPersonName(participants[0])}?`;
  } else {
    message = `Are you sure you want to delete ${participants.length} participants?`;
  }

  if (participants.length === 1) {
    const participant = participants[0];
    const nextCeo = (allParticipants ?? []).find(
      (p) => p.team === participant.team && p.id !== participant.id,
    );

    const team = participant.team;
    if (participant.isCeo) {
      return (
        <ConfirmModal
          isOpen={isOpen}
          onClose={handleClose}
          onConfirm={handleConfirm}
          confirmInProgress={inProgress}
          onCancel={onClose}
          title="Deleting the CEO"
          description={`You are about to delete the CEO of team ${team}. ${nextCeo ? `We will automatically transfer CEO to ${nextCeo.firstName} ${nextCeo.lastName}` : "This team will no longer have a CEO"}. Are you sure you want to continue?`}
          confirmTitle={"Delete"}
          error={error && error.message}
          disabled={inProgress}
          warning
        />
      );
    }
  }

  if (
    participants.length > 1 &&
    participants.length !== allParticipants?.length
  ) {
    const participantsNotBeingDeleted = (allParticipants ?? []).filter(
      (p) => !participants.find((p2) => p2.id === p.id),
    );
    const ceosBeingDeleted = participants.filter((p) => p.isCeo);
    if (ceosBeingDeleted.length > 0) {
      const ceoAndNextCeoPair = ceosBeingDeleted.map((p) => {
        const nextCeo = participantsNotBeingDeleted.find(
          (p2) => p2.team === p.team,
        );
        return { ceo: p, nextCeo };
      });

      const description = (
        <VerticalGroup spaceBetweenElements={2}>
          <Text>
            You are about to delete the CEO&apos;s of the following teams:
          </Text>
          <VerticalGroup>
            {ceoAndNextCeoPair.map((pair, i) => {
              return (
                <Text key={i}>
                  {pair.ceo.firstName} {pair.ceo.lastName} of Team{" "}
                  {pair.ceo.team}.{" "}
                  {pair.nextCeo
                    ? `We will automatically transfer CEO to ${pair.nextCeo.firstName} ${pair.nextCeo.lastName}`
                    : "This team will no longer have a CEO"}
                </Text>
              );
            })}
          </VerticalGroup>
          <Text>Are you sure you want to continue?</Text>
        </VerticalGroup>
      );

      return (
        <ConfirmModal
          isOpen={isOpen}
          onClose={handleClose}
          onConfirm={handleConfirm}
          confirmInProgress={inProgress}
          onCancel={onClose}
          title="Deleting CEO's"
          description={description}
          confirmTitle={"Delete"}
          error={error && error.message}
          disabled={inProgress}
          warning
        />
      );
    }
  }

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={handleClose}
      onCancel={noParticipants ? undefined : onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      title={`${message}`}
      confirmTitle={noParticipants ? "Ok" : "Delete"}
      error={error && error.message}
      disabled={inProgress}
      warning={noParticipants ? false : true}
    />
  );
}

export default DeleteParticipant;
