import React from "react";
import Icon, { IconType } from "../../../atoms/icon/Icon";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import ResultsText from "../results-text/ResultsText";
import { ResponsiveSize } from "../../../atoms/image/Image";
import EditableReportingHeading from "../components/EditableReportingHeading";

import { ThemeColours } from "../../../../types/theme"; // Import ThemeColours type
import Card from "../../../atoms/card/Card";
import useIsMobile from "../../../../hooks/useIsMobile";
import { DotAndText } from "../components/DotAndText";

interface Props {
  simConfig: ModelAPI.Reporting.ReportingResults["simConfig"];
}

interface TextAndIconBlockProps {
  heading: string;
  points: Array<string | undefined>;
  iconType: IconType;
  dynamicSize: ResponsiveSize;
  colors: ThemeColours[];
}

const TextAndIconBlock: React.FC<TextAndIconBlockProps> = ({
  heading,
  points,
  iconType,
  dynamicSize,
  colors,
}) => {
  const isMobile = useIsMobile();
  const definedPoints: string[] = points.filter(Boolean) as string[];
  return (
    <InlineGroup spaceBetweenElements={4} fullHeight>
      <InlineGroup>
        <Icon type={iconType} dynamicSize={dynamicSize} noMargin></Icon>
      </InlineGroup>
      <VerticalGroup spaceBetweenElements={6}>
        <ResultsText bold size="xl">
          {heading}
        </ResultsText>
        <VerticalGroup full>
          {definedPoints.map((p, index) => (
            <InlineGroup key={p} fullHeight block>
              <DotAndText
                text={p}
                dotColour={colors[index]}
                dotSize={"clamp(0.10rem, calc(0.15rem + 0.60vw), 2rem)"}
                isLast={index === definedPoints.length - 1}
                marginTop={isMobile ? "-0.3rem" : "-1rem"}
                noLines
              />
            </InlineGroup>
          ))}
        </VerticalGroup>
      </VerticalGroup>
    </InlineGroup>
  );
};

const RoundResultsReversion: React.FC<Props> = ({ simConfig }) => (
  <RoundResultsContainer>
    <Card fullHeight wide sharp>
      <VerticalGroup full wide center spaceBetweenElements={16}>
        <VerticalGroup center>
          <EditableReportingHeading defaultHeading="Simulation Final Round" />
          <ResultsText size="xl" bold center colour="blue">
            What happens to our banks after this round?
          </ResultsText>
        </VerticalGroup>
        <VerticalGroup spread style={{ height: "90%" }}>
          <VerticalGroup style={{ height: "50%" }}>
            <TextAndIconBlock
              dynamicSize={{ min: "70px", preferred: "7vw", max: "800px" }}
              iconType="calender"
              heading='Banks forecast for 5 more years (to prevent "gaming" and capture long-term impacts)'
              points={[
                "All pricing decisions revert to the mean",
                "Treasury managed optimally (A credit rating maintained)",
                `Secondary levers reset to beginning settings (excl branch networks${simConfig.institutionalBankingEnabled ? " and client specialists" : ""})`,
              ]}
              colors={["blue", "yellow", "green", "lightgreen", "purple"]}
            />
          </VerticalGroup>
          <VerticalGroup style={{ height: "50%" }}>
            <TextAndIconBlock
              dynamicSize={{ min: "70px", preferred: "7vw", max: "800px" }}
              iconType="climb"
              heading="How do I differentiate my bank?"
              points={[
                "Points of presence (branch networks)",
                simConfig.institutionalBankingEnabled
                  ? "Client specialists"
                  : undefined,
                "Change initiatives selected",
                "Non-financial transformation progress",
                simConfig.dealRoomEnabled ? "Deals done" : undefined,
              ]}
              colors={["blue", "yellow", "green", "lightgreen", "purple"]}
            />
          </VerticalGroup>
        </VerticalGroup>
      </VerticalGroup>
    </Card>
  </RoundResultsContainer>
);

export default RoundResultsReversion;
