import React, { useMemo, useCallback } from "react";

import getTeamIdsAsFields from "../utils/getTeamIdsAsFields";
import getTeamName from "../utils/getTeamName";
import { percentage } from "../utils/formatters";

import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import ModelAdjustmentsSection from "./ModelAdjustmentSection";

interface Props {
  eventId: string;
  configuration: ModelAPI.ConfigurationResponse;
  selectedRound: number;
  teams: API.GameConnection[];
  adjustmentData: ModelAPI.FacilitatorAdjustmentsResponse;
  resultData: ModelAPI.RoundResultsResponse[];
  onAdjustmentMade?: () => void;
  onUpdate: (
    type: keyof ModelAPI.FacilitatorAdjustmentsResponse,
    teamId: number,
    year: number,
    value: number,
  ) => Promise<void> | void;
  containerComponent?: React.ComponentType<any>;
}

const ModelAdjustmentsContent: React.FC<Props> = ({
  configuration,
  teams,
  adjustmentData,
  resultData,
  onUpdate,
  containerComponent: Container = React.Fragment,
  onAdjustmentMade,
}) => {
  const { numberOfTeams } = configuration;
  adjustmentData;
  const fields = useMemo(
    () => getTeamIdsAsFields(numberOfTeams),
    [numberOfTeams],
  );

  const teamNameFieldFormatter = useCallback(
    (field: string) => getTeamName(parseInt(field), numberOfTeams, teams),
    [numberOfTeams, teams],
  );
  const percentageFormatter = useCallback(
    (value: any) => percentage(value),
    [],
  );
  const handleUpdate = useCallback(
    async (
      type: keyof ModelAPI.FacilitatorAdjustmentsResponse,
      teamId: number,
      year: number,
      value: number,
    ) => {
      await onUpdate(type, teamId, year, value);
      if (onAdjustmentMade) {
        onAdjustmentMade();
      }
    },
    [onUpdate, onAdjustmentMade],
  );

  const content = (
    <VerticalGroup spaceBetweenElements={4} wide>
      <ModelAdjustmentsSection
        title={"NPS"}
        configuration={configuration}
        numberOfTeams={numberOfTeams}
        adjustmentData={adjustmentData}
        adjustmentField={"nps"}
        resultData={resultData}
        resultField={"nps"}
        fields={fields}
        fieldFormatter={teamNameFieldFormatter}
        onUpdate={handleUpdate}
        onAdjustmentMade={onAdjustmentMade}
      />
      <ModelAdjustmentsSection
        title={"Total Shareholder Return"}
        configuration={configuration}
        numberOfTeams={numberOfTeams}
        adjustmentData={adjustmentData}
        adjustmentField={"tsr"}
        resultData={resultData}
        resultField={"shareholderReturns"}
        fields={fields}
        fieldFormatter={teamNameFieldFormatter}
        formatter={percentageFormatter}
        onUpdate={handleUpdate}
        onAdjustmentMade={onAdjustmentMade}
      />
      <ModelAdjustmentsSection
        title={"Employee Engagement"}
        configuration={configuration}
        numberOfTeams={numberOfTeams}
        adjustmentData={adjustmentData}
        adjustmentField={"employeeEngagement"}
        resultData={resultData}
        resultField={"employeeEngagement"}
        fields={fields}
        fieldFormatter={teamNameFieldFormatter}
        formatter={percentageFormatter}
        onUpdate={handleUpdate}
        onAdjustmentMade={onAdjustmentMade}
      />
      <ModelAdjustmentsSection
        title={"Complaints"}
        configuration={configuration}
        numberOfTeams={numberOfTeams}
        adjustmentData={adjustmentData}
        adjustmentField={"complaints"}
        resultData={resultData}
        resultField={"complaints"}
        fields={fields}
        fieldFormatter={teamNameFieldFormatter}
        onUpdate={handleUpdate}
        onAdjustmentMade={onAdjustmentMade}
      />
      <ModelAdjustmentsSection
        title={"Risk Incidents"}
        configuration={configuration}
        numberOfTeams={numberOfTeams}
        adjustmentData={adjustmentData}
        adjustmentField={"riskIncidents"}
        resultData={resultData}
        resultField={"riskIncidents"}
        fields={fields}
        fieldFormatter={teamNameFieldFormatter}
        onUpdate={handleUpdate}
        onAdjustmentMade={onAdjustmentMade}
      />
      <ModelAdjustmentsSection
        title={"Capital Ratio"}
        configuration={configuration}
        numberOfTeams={numberOfTeams}
        adjustmentData={adjustmentData}
        adjustmentField={"capitalRatio"}
        resultData={resultData}
        resultField={"capitalRatio"}
        fields={fields}
        fieldFormatter={teamNameFieldFormatter}
        formatter={percentageFormatter}
        onUpdate={handleUpdate}
        onAdjustmentMade={onAdjustmentMade}
      />
      <ModelAdjustmentsSection
        title={"Loan Impairment Expenses"}
        configuration={configuration}
        numberOfTeams={numberOfTeams}
        adjustmentData={adjustmentData}
        adjustmentField={"lie"}
        resultData={resultData}
        resultField={"lie"}
        fields={fields}
        fieldFormatter={teamNameFieldFormatter}
        formatter={percentageFormatter}
        onUpdate={handleUpdate}
        onAdjustmentMade={onAdjustmentMade}
      />
      <ModelAdjustmentsSection
        title={"Regulator Actions"}
        configuration={configuration}
        numberOfTeams={numberOfTeams}
        adjustmentData={adjustmentData}
        adjustmentField={"regulatorActions"}
        resultData={resultData}
        resultField={"regulatorActions"}
        fields={fields}
        fieldFormatter={teamNameFieldFormatter}
        onUpdate={handleUpdate}
        onAdjustmentMade={onAdjustmentMade}
      />
      <ModelAdjustmentsSection
        title={"Community Trust"}
        configuration={configuration}
        numberOfTeams={numberOfTeams}
        adjustmentData={adjustmentData}
        adjustmentField={"communityTrust"}
        resultData={resultData}
        resultField={"communityTrust"}
        fields={fields}
        fieldFormatter={teamNameFieldFormatter}
        formatter={percentageFormatter}
        onUpdate={handleUpdate}
        onAdjustmentMade={onAdjustmentMade}
      />
    </VerticalGroup>
  );

  return <Container>{content}</Container>;
};

export default ModelAdjustmentsContent;
