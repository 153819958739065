import React from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import Group from "./Group";
import { FieldsOf } from "./types";
import GloInput, { DataProps } from "./GloInput";

type DataType = API.GlobalModelVariablesResponse["balanceSheet"];

interface Props {
  data: DataType;
  onChange: <T extends keyof DataType>(
    key: T,
    field?: undefined | FieldsOf<DataType, T>,
  ) => (selected: any) => void;
}

const assets: Array<DataProps<DataType, "assets">> = [
  { label: "Home Loans", field: "homeLoans", type: "amount" },
  { label: "Business Lending", field: "businessLoans", type: "amount" },
  { label: "Wealth Management", field: "wealthManagement", type: "amount" },
  {
    label: "Institutional Lending",
    field: "institutionalLending",
    type: "amount",
  },
  { label: "Other Fixed Assets", field: "otherFixedAssets", type: "amount" },
];

const offBalanceSheet: Array<DataProps<DataType, "offBalanceSheet">> = [
  {
    label: "Funds Under Management",
    field: "fundsUnderManagement",
    type: "amount",
  },
  { label: "Credit Card Spend", field: "creditCardSpend", type: "amount" },
  { label: "Offshore Bank - IEA", field: "offshoreBank", type: "amount" },
];

const BalanceSheet: React.FC<Props> = ({ data, onChange }) => {
  return (
    <VerticalGroup wide spaceBetweenElements={3} className="p-2">
      <GloInput
        label="Rounded To Nearest"
        value={data.roundedToNearest}
        onChange={onChange("roundedToNearest")}
        min={0}
        max={1000}
        step={100}
        type="amount"
      />
      <Group title="Assets">
        {assets.map(({ field, ...rest }) => (
          <GloInput
            key={field}
            value={data.assets[field]}
            onChange={onChange("assets", field)}
            {...rest}
          />
        ))}
      </Group>
      <Group title="Off Balance Sheet">
        {offBalanceSheet.map(({ field, ...rest }) => (
          <GloInput
            key={field}
            value={data.offBalanceSheet[field]}
            onChange={onChange("offBalanceSheet", field)}
            {...rest}
          />
        ))}
      </Group>
    </VerticalGroup>
  );
};
export default BalanceSheet;
