import React from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";
import { percentage } from "../utils/formatters";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["nonProductBalanceSheet"];
}

const ModelTeamResultsNonProductBalanceSheet: React.FC<Props> = ({ data }) => (
  <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
    <VerticalGroup spaceBetweenElements={2} wide>
      <TableWithSections
        header="Assets"
        data={[
          {
            fields: ["averageGrowthInLendingAssets"],
            values: data,
            formatter: (value: number) => percentage(value),
          },
          {
            name: "Fixed Assets (Deposits)",
            fields: [
              "fixedAssets.DEPOSITS.openingBalance",
              "fixedAssets.DEPOSITS.assetIncreases",
              "fixedAssets.DEPOSITS.assetDecreases",
              "fixedAssets.DEPOSITS.closingBalance",
              "fixedAssets.DEPOSITS.averageBalance",
            ],
            totalFields: [
              "fixedAssets.DEPOSITS.closingBalance",
              "fixedAssets.DEPOSITS.averageBalance",
            ],
            values: data,
          },
        ]}
      />
    </VerticalGroup>
  </VerticalGroup>
);

export default ModelTeamResultsNonProductBalanceSheet;
