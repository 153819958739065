import React, { useEffect } from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { $generateHtmlFromNodes } from "@lexical/html";
import { $getRoot, $insertNodes } from "lexical";
import ToolbarPlugin from "./plugins/richTextToolbar/Toolbarplugin";
import "./Editor.scss";

import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import parseHtmlToLexicalNodes from "./htmlParser/htmlParser";
import { ColoredTextNode } from "./plugins/customNode/CustomColourNode";
import ExampleTheme from "./exampleTheme";
import DOMPurify from "dompurify";

const editorConfig = (value: string) => {
  const nodes = [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    AutoLinkNode,
    LinkNode,
    ColoredTextNode,
  ];

  return {
    theme: ExampleTheme,
    onError(error: any) {
      throw error;
    },
    nodes,
    namespace: "MyEditor",
    editorState: (editor: any) => {
      if (value) {
        const parser = new DOMParser();
        const dom = parser.parseFromString(value, "text/html");
        const rootElement = dom.body;
        const nodes = parseHtmlToLexicalNodes(editor, rootElement.innerHTML);
        $getRoot().select();
        $insertNodes(nodes);
      }
    },
  };
};

type MyOnChangePluginProps = {
  onChange: (editorState: { text: string; html: string }) => void;
};

const MyOnChangePlugin: React.FC<MyOnChangePluginProps> = ({ onChange }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      const textContent = editorState.read(() => {
        return $getRoot().getTextContent();
      });
      const htmlContent = editorState.read(() => {
        return $generateHtmlFromNodes(editor);
      });
      const sanitizedHtml = DOMPurify.sanitize(htmlContent);
      onChange({ text: textContent, html: sanitizedHtml });
    });
  }, [editor, onChange]);

  return null;
};

interface RichTextEditorProps {
  value: string;
  onChange: (value: { text: string; html: string }) => void;
  richTextInputHeight?: string;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  value,
  onChange,
  richTextInputHeight,
}) => {
  return (
    <LexicalComposer initialConfig={editorConfig(value)}>
      <div className="editor-container">
        <ToolbarPlugin />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={
              <div style={{ height: richTextInputHeight }}>
                <ContentEditable className="editor-input" />
              </div>
            }
            placeholder={null}
            ErrorBoundary={LexicalErrorBoundary}
          />
        </div>
        <HistoryPlugin />
        <AutoFocusPlugin />
        <MyOnChangePlugin onChange={onChange} />
      </div>
    </LexicalComposer>
  );
};

export default RichTextEditor;
