import { BROKER_LOANS_MARKET_SHARE, LOSS_RATES_BY_MARKET } from "../data";
import { PreviewSettings } from "../types";
import { bpsConvertMultiply, convertToPercentage } from "./utils";

interface Lie {
  HOME_LOANS: {
    proprietaryLoans: number[];
    brokerLoans: number[];
    portfolio: number[];
  };
  BUSINESS_LOANS: {
    good: number[];
    medium: number[];
    poor: number[];
    portfolio: number[];
  };
  CREDIT_CARDS: number[];
  INSTITUTIONAL_BANKING: number[];
  OFFSHORE_BANK: number[];
}
interface GrowthAndLosses {
  grossGrowthRates: Record<API.Products, number[]>;
  lie: Lie;
}

interface Params {
  previewSettings: PreviewSettings;
  netRates: Record<API.Products, number[]>;
  lossMultipliers: {
    HOME_LOANS: {
      proprietaryLoans: number[];
      brokerLoans: number[];
    };
    BUSINESS_LOANS: {
      goodQuality: number[];
      mediumQuality: number[];
      poorQuality: number[];
    };
    CREDIT_CARDS: number[];
    OFFSHORE_BANK: number[];
    INSTITUTIONAL_BANKING: number[];
  };
}

export const calculateGrossRates = (params: Params): GrowthAndLosses => {
  const lossRate = LOSS_RATES_BY_MARKET[params.previewSettings.country];
  const netRates = {
    HOME_LOANS: params.netRates.HOME_LOANS.map((val) => val / 100),
    BUSINESS_LOANS: params.netRates.BUSINESS_LOANS.map((val) => val / 100),
    DEPOSITS: params.netRates.DEPOSITS.map((val) => val / 100),
    CREDIT_CARDS: params.netRates.CREDIT_CARDS.map((val) => val / 100),
    WEALTH_MANAGEMENT: params.netRates.WEALTH_MANAGEMENT.map(
      (val) => val / 100,
    ),
    OFFSHORE_BANK: params.netRates.OFFSHORE_BANK.map((val) => val / 100),
    INSTITUTIONAL_BANKING: params.netRates.INSTITUTIONAL_BANKING.map(
      (val) => val / 100,
    ),
  };

  const lossMultipliers = {
    HOME_LOANS: {
      proprietaryLoans: params.lossMultipliers.HOME_LOANS.proprietaryLoans.map(
        (val) => val / 100,
      ),
      brokerLoans: params.lossMultipliers.HOME_LOANS.brokerLoans.map(
        (val) => val / 100,
      ),
    },
    BUSINESS_LOANS: {
      goodQuality: params.lossMultipliers.BUSINESS_LOANS.goodQuality.map(
        (val) => val / 100,
      ),
      mediumQuality: params.lossMultipliers.BUSINESS_LOANS.mediumQuality.map(
        (val) => val / 100,
      ),
      poorQuality: params.lossMultipliers.BUSINESS_LOANS.poorQuality.map(
        (val) => val / 100,
      ),
    },
    CREDIT_CARDS: params.lossMultipliers.CREDIT_CARDS.map((val) => val / 100),
    OFFSHORE_BANK: params.lossMultipliers.OFFSHORE_BANK.map((val) => val / 100),
    INSTITUTIONAL_BANKING: params.lossMultipliers.INSTITUTIONAL_BANKING.map(
      (val) => val / 100,
    ),
  };

  const homeLoansPropLie = lossMultipliers.HOME_LOANS.proprietaryLoans.map(
    (multiplier) =>
      bpsConvertMultiply(multiplier * lossRate.HOME_LOANS.proprietaryLoans),
  );
  const homeLoansBrokerLie = lossMultipliers.HOME_LOANS.brokerLoans.map(
    (multiplier) =>
      bpsConvertMultiply(multiplier * lossRate.HOME_LOANS.brokerLoans),
  );

  const businessLoansGoodQualityLie =
    lossMultipliers.BUSINESS_LOANS.goodQuality.map((multiplier) =>
      bpsConvertMultiply(multiplier * lossRate.BUSINESS_LOANS.goodQuality),
    );
  const businessLoansMediumQualityLie =
    lossMultipliers.BUSINESS_LOANS.mediumQuality.map((multiplier) =>
      bpsConvertMultiply(multiplier * lossRate.BUSINESS_LOANS.mediumQuality),
    );
  const businessLoansPoorQualityLie =
    lossMultipliers.BUSINESS_LOANS.poorQuality.map((multiplier) =>
      bpsConvertMultiply(multiplier * lossRate.BUSINESS_LOANS.poorQuality),
    );

  const LIE: Lie = {
    HOME_LOANS: {
      proprietaryLoans: homeLoansPropLie,
      brokerLoans: homeLoansBrokerLie,
      portfolio: homeLoansPropLie.map((val, index) => {
        const brokerVal = homeLoansBrokerLie[index];
        return Math.round(
          brokerVal * BROKER_LOANS_MARKET_SHARE +
            val * (1 - BROKER_LOANS_MARKET_SHARE),
        );
      }),
    },
    BUSINESS_LOANS: {
      good: businessLoansGoodQualityLie,
      medium: businessLoansMediumQualityLie,
      poor: businessLoansPoorQualityLie,
      portfolio: businessLoansGoodQualityLie.map((val, index) => {
        const mediumVal = businessLoansMediumQualityLie[index];
        const poorVal = businessLoansPoorQualityLie[index];
        return Math.round(val * 0.4 + mediumVal * 0.4 + poorVal * 0.2);
      }),
    },
    CREDIT_CARDS: lossMultipliers.CREDIT_CARDS.map((multiplier) =>
      bpsConvertMultiply(multiplier * lossRate.CREDIT_CARDS),
    ),
    INSTITUTIONAL_BANKING: lossMultipliers.INSTITUTIONAL_BANKING.map(
      (multiplier) =>
        bpsConvertMultiply(multiplier * lossRate.INSTITUTIONAL_BANKING),
    ),
    OFFSHORE_BANK: lossMultipliers.OFFSHORE_BANK.map((multiplier) =>
      bpsConvertMultiply(multiplier * lossRate.OFFSHORE_BANK),
    ),
  };

  const grossHomeLoanRates = LIE.HOME_LOANS.portfolio.map((val, index) => {
    const netRate = netRates.HOME_LOANS[index];
    if (netRate == null) {
      return 0;
    }
    return val / 100 + netRate;
  });

  const grossBusinessLoanRates = LIE.BUSINESS_LOANS.portfolio.map(
    (val, index) => {
      const netRate = netRates.BUSINESS_LOANS[index];
      if (netRate == null) {
        return 0;
      }
      return val / 100 + netRate;
    },
  );

  const grossDepositRates = netRates.DEPOSITS.map((val) => {
    if (!val) {
      return 0;
    }
    return val;
  });

  const grossCreditCardRates = LIE.CREDIT_CARDS.map((val, index) => {
    const netRate = netRates.CREDIT_CARDS[index];
    if (netRate == null) {
      return 0;
    }
    return val / 100 + netRate;
  });

  const grossInstitutionalBankingRates = LIE.INSTITUTIONAL_BANKING.map(
    (val, index) => {
      const netRate = netRates.INSTITUTIONAL_BANKING[index];
      if (netRate == null) {
        return 0;
      }
      return val / 100 + netRate;
    },
  );

  const grossOffshoreBankRates = LIE.OFFSHORE_BANK.map((val, index) => {
    const netRate = netRates.OFFSHORE_BANK[index];
    if (netRate == null) {
      return 0;
    }
    return val / 100 + netRate;
  });

  return {
    grossGrowthRates: {
      HOME_LOANS: grossHomeLoanRates.map(convertToPercentage),
      BUSINESS_LOANS: grossBusinessLoanRates.map(convertToPercentage),
      DEPOSITS: grossDepositRates.map(convertToPercentage),
      CREDIT_CARDS: grossCreditCardRates.map(convertToPercentage),
      WEALTH_MANAGEMENT: params.netRates.WEALTH_MANAGEMENT,
      OFFSHORE_BANK: grossOffshoreBankRates.map(convertToPercentage),
      INSTITUTIONAL_BANKING:
        grossInstitutionalBankingRates.map(convertToPercentage),
    },
    lie: LIE,
  };
};
