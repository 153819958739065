import React, { useMemo, useState } from "react";

import Text from "../../../../../atoms/text/Text";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import IconButton from "../../../../../molecules/iconbutton/IconButton";
import Button from "../../../../../atoms/button/Button";
import { Link } from "react-router-dom";
import { EventReportingTemplatePreviewModal } from "./EventReportingTemplatePreviewModal";
import ReportingTemplateDropdown from "../../../../../organisms/reporting-template-dropdown/ReportingTemplateDropdown";
import RoleRequired from "../../../../../molecules/role-required/RoleRequired";

interface SelectReportingTemplateProps {
  reportingTemplate?: API.ReportingTemplateResponse;
  clientId?: string;
  simulationId?: string;
  brandingId?: string;
  eventId: string;
  maxRounds?: number;
  readOnly?: boolean;
  onTemplateSelected?: (simulation: API.ReportingTemplateResponse) => void;
  onUnselectTemplate?: () => void;
  error?: string;
}

const SelectReportingTemplate: React.FC<SelectReportingTemplateProps> = ({
  reportingTemplate,
  clientId,
  simulationId,
  brandingId,
  eventId,
  maxRounds,
  readOnly,
  onTemplateSelected,
  onUnselectTemplate,
  error,
}) => {
  const [showSettingsPreviewModal, setShowSettingsPreviewModal] =
    useState<boolean>(false);

  const status = useMemo(() => {
    if (readOnly) {
      if (reportingTemplate) {
        return "readOnlyWithTemplate";
      }
      return "readOnlyWithoutTemplate";
    }

    return "default";
  }, [reportingTemplate, readOnly]);

  const content = useMemo(() => {
    switch (status) {
      case "readOnlyWithTemplate":
        return (
          <>
            <InlineGroup spaceBetweenElements={2}>
              <Text size="sm">Template Selected: </Text>
              <Text size="sm" bold>
                {reportingTemplate?.name}
              </Text>
            </InlineGroup>
          </>
        );
      case "readOnlyWithoutTemplate":
        return (
          <InlineGroup spaceBetweenElements={2}>
            <Text size="sm" colour="danger" bold>
              No Template Selected
            </Text>
          </InlineGroup>
        );
      case "default":
        return (
          <InlineGroup spaceBetweenElements={4}>
            <ReportingTemplateDropdown
              reportingTemplateId={reportingTemplate?.id}
              onTemplateSelected={onTemplateSelected}
              clientId={clientId}
              error={error}
            />
            <RoleRequired roles={["superadmin", "standard", "admin"]}>
              <IconButton
                color="blue"
                mini
                noUi
                light={false}
                data-test={`delete-template-${reportingTemplate?.id}`}
                icon="trash"
                onClick={onUnselectTemplate}
              />
            </RoleRequired>
            {reportingTemplate && (
              <Button
                outline
                data-test="view-template-settings-button"
                onClick={() => setShowSettingsPreviewModal(true)}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Preview&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Button>
            )}
            {reportingTemplate && (
              <Button outline>
                <Link to={`/reporting-templates/${reportingTemplate.id}/view`}>
                  Edit Settings
                </Link>
              </Button>
            )}
          </InlineGroup>
        );
      default:
        return null;
    }
  }, [
    status,
    reportingTemplate,
    onTemplateSelected,
    clientId,
    error,
    onUnselectTemplate,
  ]);

  return (
    <>
      <VerticalGroup spaceBetweenElements={4}>
        <Text size="sm" bold>
          Reporting Template
        </Text>
        {content}
        {reportingTemplate && simulationId && brandingId && (
          <EventReportingTemplatePreviewModal
            reportingTemplateId={reportingTemplate.id}
            simulationId={simulationId}
            brandingId={brandingId}
            eventId={eventId}
            isOpen={showSettingsPreviewModal}
            maxRounds={maxRounds}
            onClose={() => setShowSettingsPreviewModal(false)}
          />
        )}
      </VerticalGroup>
    </>
  );
};

export default SelectReportingTemplate;
