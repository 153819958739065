import React, { useState } from "react";
import Dropdown from "../../atoms/form/input/Dropdown";
import { StylesConfig } from "react-select";
import { rounds } from "../../pages/model/constants";

interface FastEditingDropDownProps {
  onChange: (selectedValue: {
    value: string;
    label: string;
    round?: string;
  }) => void;
  label?: string;
  error?: string;
  placeholder?: string;
  colour?: string;
  small?: boolean;
  midsize?: boolean;
  currentRound: number;
}

const FastEditingDropDown: React.FC<FastEditingDropDownProps> = ({
  onChange,
  label,
  error,
  placeholder,
  colour,
  small,
  midsize,
  currentRound,
}) => {
  const [showSubmenu, setShowSubmenu] = useState<boolean>(false);
  const [mainDropdownVisible, setMainDropdownVisible] = useState<boolean>(true);
  const [selectedOption, setSelectedOption] = useState<{
    value: string;
    label: string;
  } | null>(null);

  const options = [
    {
      value: "copy_from",
      label: "Copy From",
    },
    {
      value: "paste_forward",
      label: "Paste Forward",
    },
  ];

  const getLabel = (index: number): string => {
    let label = "";
    if (index < 3) {
      label = `Historical ${index + 1}`;
    } else if (index >= 3 && index < 9) {
      label = `Round ${index - 2}`;
    } else if (index >= 9) {
      label = `Reversion ${index - 8}`;
    }

    if (index === currentRound - 1) {
      label += "  (current)";
    }

    return label;
  };

  const roundOptions = Array.from({ length: rounds }, (_, index) => ({
    value: `round_${index}`,
    label: getLabel(index),
    isDisabled: index === currentRound - 1,
  }));

  const customStyles: StylesConfig<{
    value: string;
    label: string;
    isDisabled: boolean;
  }> = {
    option: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? "grey" : provided.color,
    }),
  };

  const handleSelect = (option: { value: string; label: string }) => {
    setSelectedOption(option);
    if (option.value === "copy_from") {
      setShowSubmenu(true);
      setMainDropdownVisible(false);
      setSelectedOption(null);
    } else {
      setShowSubmenu(false);
      setMainDropdownVisible(true);
      setSelectedOption(null);
      onChange(option);
    }
  };

  const handleRoundSelect = (option: { value: string; label: string }) => {
    setShowSubmenu(false);
    setMainDropdownVisible(true);
    onChange({ value: "copy_from", label: "Copy From", round: option.value });
  };

  return (
    <div>
      {mainDropdownVisible && (
        <Dropdown
          label={label}
          error={error}
          colour={colour}
          small={small}
          midsize={midsize}
          selectProps={{
            options: options,
            onChange: handleSelect,
            placeholder: placeholder || "Select a method",
            value: selectedOption,
          }}
        />
      )}
      {showSubmenu && (
        <Dropdown
          alwaysOpen={true}
          midsize={midsize}
          selectProps={{
            styles: customStyles,
            options: roundOptions,
            onChange: handleRoundSelect,
            autoFocus: true,
            value: selectedOption,
          }}
        />
      )}
    </div>
  );
};

export default FastEditingDropDown;
