import React from "react";

import EnumDropdown, {
  getValueFunc,
} from "../../../../organisms/enum-dropdown/EnumDropdown";

interface Props {
  onChange: (selected: { value: string; label: string }) => void;
  value?: string;
  error?: string;
  label?: string;
  name?: string;
}

export const options = [
  {
    value: "automatic",
    label: "Automatic",
  },
  {
    value: "manage_amount_only",
    label: "Manage Amount Only",
  },
  {
    value: "manage_amount_and_duration",
    label: "Manage Amount & Duration",
  },
];

export const getLabelFromValue = getValueFunc(options);

function TermFundingDropdown({ onChange, value, error, label, name }: Props) {
  return (
    <EnumDropdown
      onChange={onChange}
      options={options}
      value={value}
      error={error}
      label={label}
      name={name ?? "term-funding-dropdown"}
    />
  );
}

export default TermFundingDropdown;
