import React from "react";
import { LabelProps } from "recharts";
import { TeamsWithColours } from "../RoundResultsLayout";

export interface CustomLabelProps extends Omit<LabelProps, "value"> {
  value: { teamId: number };
}

export interface BarChartData {
  [key: string]: string | number | null | React.FC<CustomLabelProps>;
  key: string;
  topLabel: React.FC<CustomLabelProps>;
  fill: string | null;
  name: string;
  raw?: any;
}

export const createLegend = (teams: TeamsWithColours[]) => {
  const Legend: React.FC<CustomLabelProps> = (props) => {
    const { x, y, width, height, value, name } = props;
    if (value == null) {
      return null;
    }
    const valueToUse = value.teamId;
    const xToUse = Number(x) ?? 0;
    const yToUse = Number(y) ?? 0;
    const widthToUse = Number(width) ?? 0;
    const heightToUse = Number(height) ?? 0;
    const team = teams.find((t) => t.teamId === valueToUse);
    const smallScreen = window.matchMedia("(max-width: 820px)");
    const mediumScreen = window.matchMedia("(max-width: 2000px)");

    let yOffset;
    if (smallScreen.matches) {
      yOffset = 20;
    } else if (mediumScreen.matches) {
      yOffset = 25;
    } else {
      yOffset = 50;
    }
    const legendFontSize =
      teams.length > 9
        ? "clamp(0.20rem, calc(0.20rem + 0.80vw), 2rem)"
        : "clamp(0.25rem, calc(0.25rem + 1.2vw), 2rem)";
    return (
      <g>
        <text
          x={xToUse + widthToUse / 2}
          y={yToUse + heightToUse + yOffset}
          fill={team?.colour ?? "black"}
          textAnchor="middle"
          fontWeight="bold"
          fontSize={legendFontSize}
        >
          {name}
        </text>
      </g>
    );
  };
  return Legend;
};
