import React, { useState, useCallback } from "react";
import { useImperativeHandle } from "react";
import useForm from "../../../hooks/useForm";
import Button from "../button/Button";
import Clickable from "../clickable/Clickable";
import Input from "../form/input/Input";
import InlineGroup from "../inlinegroup/InlineGroup";
import VerticalGroup from "../verticalgroup/VerticalGroup";
import { Option } from "../form/input/Textbox";
import "./EditableText.scss";
import { ThemeSpacing } from "../../../types/theme";
import Text, { Props as TextProps } from "../../../components/atoms/text/Text";

interface Props extends Omit<TextProps, "children"> {
  "data-test"?: string;
  value: string | number;
  type?: "text" | "textbox" | "richText";
  onChange: (value: string) => void;
  formatter?: (value: string) => string;
  alignment?: "horizontal" | "vertical";
  clickableStyle?: React.CSSProperties;
  enableRightClickMenu?: boolean;
  dropdownOptions?: Option[];
  lineHeight?: ThemeSpacing;
  richTextInputHeight?: string;
  small?: boolean;
}

export interface Handles {
  setIsEditing: (is: boolean) => void;
  reset: () => void;
}

const defaultFormatter = (str: string) => str;

const EditableText: React.ForwardRefRenderFunction<Handles, Props> = (
  {
    value,
    onChange,
    type = "textbox",
    alignment = "vertical",
    formatter = defaultFormatter,
    clickableStyle,
    enableRightClickMenu,
    dropdownOptions,
    lineHeight,
    richTextInputHeight,
    small = false,

    ...rest
  },
  ref,
) => {
  const [isEditing, setIsEditing] = useState(false);
  const [
    {
      formData: { updatedValue },
    },
    { setField, setFieldRaw },
  ] = useForm({ updatedValue: null });

  useImperativeHandle(ref, () => ({
    setIsEditing,
    reset: () => setFieldRaw("updatedValue", null),
  }));

  const handleCancel = useCallback(() => {
    setFieldRaw("updatedValue", null);
    setIsEditing(false);
  }, [setFieldRaw]);

  const handleSave = useCallback(() => {
    if (updatedValue !== null) {
      onChange(updatedValue);
    }
    setIsEditing(false);
  }, [onChange, updatedValue]);

  if (!isEditing) {
    return (
      <Clickable
        onClick={() => setIsEditing(true)}
        withHover
        style={clickableStyle}
      >
        {type === "richText" ? (
          <div style={{ width: "100%" }}>
            <Text size="2xl" isHtml {...rest} lineHeight={lineHeight}>
              {formatter(updatedValue ?? value)}
            </Text>
          </div>
        ) : (
          <Text isHtml {...rest} lineHeight={lineHeight}>
            {formatter(updatedValue ?? value)}
          </Text>
        )}
      </Clickable>
    );
  }

  const content = (
    <>
      <InlineGroup block spaceBetweenElements={2}>
        <Input
          dropdownOptions={dropdownOptions}
          block
          small={small}
          type={type}
          value={updatedValue ?? value}
          onChange={setField("updatedValue")}
          enableRightClickMenu={enableRightClickMenu}
          lineHeight={lineHeight}
          richTextInputHeight={richTextInputHeight}
        />
      </InlineGroup>
      <InlineGroup spaceBetweenElements={2}>
        <Button shadow onClick={handleSave}>
          Save
        </Button>
        <Button
          shadow
          light
          onClick={handleCancel}
          style={{ backgroundColor: "white" }}
        >
          Cancel
        </Button>
      </InlineGroup>
    </>
  );

  return alignment === "vertical" ? (
    <VerticalGroup spaceBetweenElements={2} className="editabletext">
      {content}
    </VerticalGroup>
  ) : (
    <InlineGroup spaceBetweenElements={2} className="editabletext">
      {content}
    </InlineGroup>
  );
};

export default React.forwardRef(EditableText);
