import React, { useState, useCallback } from "react";
import ConfirmModal from "../../../organisms/confirm-modal/ConfirmModal";
import Button from "../../button/Button";

interface CancelButtonProps {
  /**
   * Optional confirm modal props to enable the confirm
   * modal when cancel button is pressed.
   */
  confirmModalProps?: {
    /**
     * Whether the confirm modal should be shown when cancel
     * button is pressed.
     * In our use case, it should be set to `true` when the form
     * is updated.
     */
    showModal: boolean;
    /**
     * Showing inProgress status of confirm button
     */
    inProgress: boolean;
    /**
     * Showing any error information
     */
    error?: Error;
    /**
     * Confirm Modal title/heading
     */
    title?: string;
    /**
     * Confirm Modal description
     */
    description?: string;
    /**
     * Confirm Button text
     */
    confirmButtonText?: string;
    /**
     * Callback function when confirm button is pressed
     * If not provided, it would default to callback assigned
     * to onClick property
     */
    onConfirm?: () => void;
    /**
     * Callback function when discard button is pressed
     * If not provided, discard button won't show
     */
    onDiscard?: () => void;
  };
  /**
   * This callback function will be trigger when cancel button
   * is pressed and modal doesn't get in the way
   */
  onClick: () => void;
}

/**
 * Generic form Cancel button,
 * must be used with `useForm`
 */
const CancelButton: React.FC<CancelButtonProps> = ({
  confirmModalProps,
  onClick,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const {
    inProgress = false,
    error,
    showModal = false,
    title = "Discard Changes",
    description = "Are you sure you want to close without saving your changes?",
    confirmButtonText = "Confirm",
    onConfirm = onClick,
    onDiscard,
  } = confirmModalProps ?? {};

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const handleOnCancel = useCallback(() => {
    if (showModal) setModalOpen(true);
    else onClick();
  }, [onClick, showModal]);

  const handleOnConfirm = useCallback(() => {
    onConfirm();
    closeModal();
  }, [closeModal, onConfirm]);

  return (
    <>
      <ConfirmModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onCancel={closeModal}
        onConfirm={handleOnConfirm}
        onDiscard={onDiscard}
        confirmInProgress={inProgress}
        title={title}
        description={description}
        confirmTitle={confirmButtonText}
        error={error && error.message}
        warning
      />
      <Button data-test="cancel" light wide onClick={handleOnCancel}>
        Cancel
      </Button>
    </>
  );
};

export default CancelButton;
