import useQuery from "../../../../hooks/useQuery";

const expandIfSec = (
  querySection: string | null,
  section: string,
): "expand" | "collapse" => {
  if (querySection === section) {
    return "expand";
  }
  return "collapse";
};

const openIfTable = (queryTable: string | null, table: string): boolean => {
  return queryTable === table;
};

function useModelQuery() {
  const query = useQuery();

  const tab = query.get("tab");
  const section = query.get("section");
  const team = query.get("team");
  const table = query.get("table");

  const expandIfSection = (sec: string) => expandIfSec(section, sec);
  const openIfTableFunc = (_table: string) => openIfTable(table, _table);

  return {
    tab,
    section,
    team,
    table,
    expandIfSection,
    openIfTable: openIfTableFunc,
  };
}

export default useModelQuery;
