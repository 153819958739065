import React, { useState, useEffect } from "react";
import SimpleModal from "../../../organisms/standard-modal/SimpleModal";
import Container from "../../../atoms/page/Page";
import Text from "../../../atoms/text/Text";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Button from "../../../atoms/button/Button";
import Dropdown from "../../../atoms/form/input/Dropdown";
import NumberInput from "../../../atoms/form/input/NumberInput";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import PercentageDropdown from "../../../organisms/percentage-dropdown/PercentageDropdown";

interface EditMediaModalProps {
  isOpen: boolean;
  onClose: () => void;
  width: number;
  height: number;
  unit: "px" | "%";
  alignment: "left" | "center" | "right";
  onSave: (
    width: number,
    height: number,
    unit: "px" | "%",
    alignment: "left" | "center" | "right",
  ) => void;
  mediaType: "image" | "video";
}

const MAX_PERCENTAGE = 100;
const MAX_PIXELS = 800; // You can adjust this value as needed

const EditMediaModal: React.FC<EditMediaModalProps> = ({
  isOpen,
  onClose,
  width: initialWidth,
  height: initialHeight,
  unit: initialUnit,
  alignment: initialAlignment,
  onSave,
  mediaType,
}) => {
  const [width, setWidth] = useState(initialWidth);
  const [height, setHeight] = useState(initialHeight);
  const [unit, setUnit] = useState<"px" | "%">(initialUnit);
  const [alignment, setAlignment] = useState<"left" | "center" | "right">(
    initialAlignment,
  );

  useEffect(() => {
    setWidth(initialWidth);
    setHeight(initialHeight);
    setUnit(initialUnit);
    setAlignment(initialAlignment);
  }, [initialWidth, initialHeight, initialUnit, initialAlignment]);

  const handleSave = () => {
    onSave(width, height, unit, alignment);
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  const unitOptions = [
    { value: "px", label: "Pixels (px)" },
    { value: "%", label: "Percentage (%)" },
  ];

  const alignmentOptions = [
    { value: "left", label: "Left" },
    { value: "center", label: "Center" },
    { value: "right", label: "Right" },
  ];

  const convertValue = (
    value: number,
    fromUnit: "px" | "%",
    toUnit: "px" | "%",
  ): number => {
    if (fromUnit === toUnit) return value;
    if (fromUnit === "px" && toUnit === "%") {
      return Math.min((value / MAX_PIXELS) * 100, MAX_PERCENTAGE);
    }
    if (fromUnit === "%" && toUnit === "px") {
      return Math.min((value / 100) * MAX_PIXELS, MAX_PIXELS);
    }
    return value;
  };

  const handleUnitChange = (selectedOption: { value: "px" | "%" }) => {
    const newUnit = selectedOption.value;
    setWidth(convertValue(width, unit, newUnit));
    setHeight(convertValue(height, unit, newUnit));
    setUnit(newUnit);
  };

  const handleWidthChange = (newValue: number) => {
    setWidth(unit === "%" ? newValue / 10000 : newValue);
  };

  const handleHeightChange = (newValue: number) => {
    setHeight(unit === "%" ? newValue / 10000 : newValue);
  };

  return (
    <SimpleModal
      className="add-section-container"
      showCloseButton
      isOpen={isOpen}
      onClose={onClose}
      size="medium"
    >
      <VerticalGroup spaceBetweenElements={2} wide>
        <Container fit>
          <VerticalGroup wide spaceBetweenElements={4}>
            <Text size="lg" bold>
              Edit {mediaType === "image" ? "Image" : "Video"}
            </Text>
            <VerticalGroup wide spaceBetweenElements={4}>
              <Dropdown
                small
                label="Alignment"
                selectProps={{
                  options: alignmentOptions,
                  value: alignmentOptions.find(
                    (option) => option.value === alignment,
                  ),
                  onChange: (selectedOption: {
                    value: "left" | "center" | "right";
                  }) => setAlignment(selectedOption.value),
                }}
              />
              <Dropdown
                small
                label="Unit"
                selectProps={{
                  options: unitOptions,
                  value: unitOptions.find((option) => option.value === unit),
                  onChange: handleUnitChange,
                }}
              />
              {unit === "px" ? (
                <NumberInput
                  block
                  label="Width"
                  type="number"
                  value={Math.round(width)}
                  onChange={(e) => handleWidthChange(Number(e.target.value))}
                  min={1}
                  max={MAX_PIXELS}
                />
              ) : (
                <PercentageDropdown
                  label="Width"
                  value={width * 10000}
                  onChange={handleWidthChange}
                  min={0}
                  max={MAX_PERCENTAGE}
                  step={0.1}
                  dp={1}
                />
              )}
              {unit === "px" ? (
                <NumberInput
                  block
                  label="Height"
                  type="number"
                  value={Math.round(height)}
                  onChange={(e) => handleHeightChange(Number(e.target.value))}
                  min={1}
                  max={MAX_PIXELS}
                />
              ) : (
                <PercentageDropdown
                  label="Height"
                  value={height * 10000}
                  onChange={handleHeightChange}
                  min={1}
                  max={MAX_PERCENTAGE}
                  step={0.1}
                  dp={1}
                />
              )}
            </VerticalGroup>
          </VerticalGroup>
        </Container>
        <InlineGroup block spread>
          <Button outline onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </InlineGroup>
      </VerticalGroup>
    </SimpleModal>
  );
};

export default EditMediaModal;
