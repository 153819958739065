import React from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["customerNumbers"];
}

const ModelTeamResultsCustomerNUmbers: React.FC<Props> = ({ data }) => {
  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <TableWithSections
          header="Customer Numbers"
          data={[
            {
              fields: [
                "HOME_LOANS",
                "BUSINESS_LOANS",
                "DEPOSITS",
                "CREDIT_CARDS",
                "WEALTH_MANAGEMENT",
                "INSTITUTIONAL_BANKING",
                "OFFSHORE_BANK",
                "total",
              ],
              values: data,
              totalFields: ["total"],
            },
          ]}
        />
      </VerticalGroup>
    </>
  );
};

export default ModelTeamResultsCustomerNUmbers;
