import React from "react";
import classNames from "classnames";
import "./Card.scss";
import { ThemeColours, ThemeSpacing } from "../../../types/theme";

interface CardProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
  flex?: boolean;
  error?: boolean;
  wide?: boolean;
  borderColour?: ThemeColours;
  sharp?: boolean;
  transparent?: boolean;
  padding?: ThemeSpacing;
  fullHeight?: boolean;
  hideCard?: boolean;
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  (
    {
      className,
      children,
      error,
      flex,
      wide,
      borderColour,
      sharp,
      transparent,
      padding = 4,
      fullHeight,
      hideCard = false,
      ...rest
    },
    ref,
  ) => {
    Card.displayName = "Card";
    const borderColourClass = borderColour
      ? `borderColor-${borderColour}`
      : null;

    return (
      <div
        ref={ref}
        className={classNames(
          {
            [`card p-${padding}`]: !hideCard,
            flex,
            error,
            wide,
            sharp,
            transparent,
            "full-height": fullHeight,
          },
          !hideCard && borderColourClass,
          className,
        )}
        {...rest}
      >
        {children}
      </div>
    );
  },
);

export default Card;
