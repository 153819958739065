import React, { useCallback, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import Text from "../../../../atoms/text/Text";
import Toggle from "../../../../atoms/toggle/Toggle";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import Image from "../../../../atoms/image/Image";
import { Accordion } from "../../../../organisms/accordion/accordion";
import Card from "../../../../atoms/card/Card";
import EditableText, {
  Handles as EditableTextHandles,
} from "../../../../atoms/text/EditableText";
import "./projects.scss";
import Clickable from "../../../../atoms/clickable/Clickable";
import Icon from "../../../../atoms/icon/Icon";
import Dropdown from "../../../../atoms/form/input/Dropdown";

const Impact: React.FC<{
  projectId: string;
  impact: API.SpecialProjectImpact;
  impactTypeName?: API.SpecialProjectImpactTypeName[];
  onUpdateField?: (
    projectId: string,
    impactId: string,
    data: Partial<API.SpecialProjectImpact>,
  ) => Promise<void>;
}> = ({ projectId, impact, impactTypeName, onUpdateField }) => {
  const { name } =
    impactTypeName?.find((typeName) => typeName.type === impact.type) ?? impact;
  const [value, setValue] = useState(impact.value);
  const updateField = useCallback(
    (field: keyof API.SpecialProjectImpact) => (value: string | number) => {
      if (onUpdateField) {
        onUpdateField(projectId, impact.id.toString(), { [field]: value });
      }
    },
    [onUpdateField, projectId, impact.id],
  );

  const updateFieldValue = useCallback(
    (value: number) => {
      setValue((oldValue) => {
        if (oldValue === value) {
          updateField("value")(0);
          return 0;
        } else {
          updateField("value")(value);
          return value;
        }
      });
    },
    [updateField],
  );

  const impactValues = useMemo(() => {
    const negItems = new Array(5).fill(false);
    const posItems = new Array(5).fill(false);

    if (value > 0) {
      for (let i = 0; i < value; i++) {
        posItems[i] = true;
      }
    } else if (value < 0) {
      for (let i = negItems.length + value; i < negItems.length; i++) {
        negItems[i] = true;
      }
    }

    return (
      <InlineGroup spaceBetweenElements={1} verticalCenter>
        {negItems.map((item, idx) => (
          <Clickable
            key={idx}
            onClick={() => updateFieldValue(idx - negItems.length)}
          >
            <div
              className={classNames("impact-value", `neg-${5 - idx}`, {
                selected: item,
              })}
            />
          </Clickable>
        ))}
        <Text>|</Text>
        {posItems.map((item, idx) => (
          <Clickable key={idx} onClick={() => updateFieldValue(idx + 1)}>
            <div
              className={classNames("impact-value", `pos-${idx + 1}`, {
                selected: item,
              })}
            />
          </Clickable>
        ))}
      </InlineGroup>
    );
  }, [updateFieldValue, value]);

  const nameRef = useRef<EditableTextHandles>(null);

  return (
    <VerticalGroup center>
      <InlineGroup spaceBetweenElements={2} verticalCenter>
        <EditableText
          ref={nameRef}
          size="sm"
          type="text"
          bold
          value={name}
          onChange={updateField("name")}
        />
        <Clickable onClick={() => nameRef.current?.setIsEditing(true)}>
          <Icon noMargin type="edit" tip={{ content: "Edit Name" }} />
        </Clickable>
      </InlineGroup>
      {impactValues}
    </VerticalGroup>
  );
};

interface ProjectItemProps {
  project: API.SpecialProject;
  impactTypeName?: API.SpecialProjectImpactTypeName[];
  hideImpactType?: string[];
  selected: boolean;
  onChange?: (project: API.SpecialProject, selected: boolean) => void;
  onUpdateContent?: (
    projectId: string,
    data: Partial<API.SpecialProject>,
  ) => Promise<void>;
  onUpdateImpact?: (
    projectId: string,
    impactId: string,
    data: Partial<API.SpecialProjectImpact>,
  ) => Promise<void>;
  operationalRiskEnabled: boolean;
  onoperationalRiskEnabled?: (e: boolean) => void;
  onOperationRiskChange?: (selected: any) => void;
  numberOfRounds?: number;
  selectedOperationalRiskRound?: any;
  riskRoundsOptions?: any;
}

const ProjectItem: React.FC<ProjectItemProps> = ({
  project,
  selected,
  onChange,
  onUpdateContent,
  onUpdateImpact,
  impactTypeName,
  operationalRiskEnabled,
  onoperationalRiskEnabled,
  onOperationRiskChange,
  numberOfRounds,
  selectedOperationalRiskRound,
  riskRoundsOptions,
  hideImpactType = [],
}) => {
  const onProjectItemChange = useCallback(
    (value) => {
      if (!onChange) {
        return;
      }
      onChange(project, value);
    },
    [onChange, project],
  );

  const updateField = useCallback(
    (field: keyof API.SpecialProject) => (value: string | number) => {
      if (onUpdateContent) {
        onUpdateContent(project.id.toString(), {
          [field]: value,
        });
      }
    },
    [project.id, onUpdateContent],
  );

  const nameRef = useRef<EditableTextHandles>(null);
  const descriptionRef = useRef<EditableTextHandles>(null);
  const upfrontCostRef = useRef<EditableTextHandles>(null);
  const ongoingCostRef = useRef<EditableTextHandles>(null);

  return (
    <Card className="project-item mt-4 p-6" sharp wide>
      <VerticalGroup spaceBetweenElements={6}>
        <InlineGroup verticalCenter spread block>
          <InlineGroup spaceBetweenElements={2} verticalCenter>
            <EditableText
              ref={nameRef}
              type="text"
              size="sm"
              bold
              value={project.name}
              onChange={updateField("name")}
            />
            <Clickable onClick={() => nameRef.current?.setIsEditing(true)}>
              <Icon noMargin type="edit" tip={{ content: "Edit Name" }} />
            </Clickable>
          </InlineGroup>

          <VerticalGroup full spread spaceBetweenElements={2}>
            {onChange && (
              <InlineGroup block right>
                <Toggle
                  checked={selected}
                  onUpdate={onProjectItemChange}
                  data-test={`special-project-toggle-${project.type}`}
                />
              </InlineGroup>
            )}
          </VerticalGroup>
        </InlineGroup>

        {selected &&
          project.type === "project-fix-it" &&
          onoperationalRiskEnabled != null && (
            <InlineGroup block spaceBetweenElements={4} right>
              <Toggle
                label="Royal Commission Event"
                checked={operationalRiskEnabled}
                onUpdate={onoperationalRiskEnabled}
                data-test="enable-operational-risk"
                helpTitle="Royal Commission Event"
                helpDescription="This is an event where the Banking Regulator/ Government conducts a market-wide investigation of all banks. Any banks which have not proactively addressed operational risk incur penalties."
              />

              {operationalRiskEnabled &&
                numberOfRounds &&
                onOperationRiskChange != null && (
                  <InlineGroup verticalCenter spaceBetweenElements={2} right>
                    <Text>Occurs in round</Text>
                    <Dropdown
                      tiny
                      data-test={"operational-risk-round-dropdown"}
                      selectProps={{
                        value: selectedOperationalRiskRound,
                        options: riskRoundsOptions,
                        onChange: onOperationRiskChange,
                        classNamePrefix: "operational-risk-round-dropdown",
                      }}
                    />
                  </InlineGroup>
                )}
            </InlineGroup>
          )}
        <InlineGroup block spaceBetweenElements={6}>
          <Image size={56} cover src={project.imageUrl} alt={project.name} />
          <VerticalGroup spaceBetweenElements={2}>
            <InlineGroup spaceBetweenElements={2} verticalCenter>
              <Text size="sm" bold>
                Upfront Cost
              </Text>
              <Clickable
                onClick={() => upfrontCostRef.current?.setIsEditing(true)}
              >
                <Icon
                  noMargin
                  type="edit"
                  tip={{ content: "Edit Upfront Cost" }}
                />
              </Clickable>
            </InlineGroup>
            <EditableText
              ref={upfrontCostRef}
              size="sm"
              alignment="horizontal"
              type="text"
              value={String(project.upfrontCost)}
              onChange={(val: string) =>
                updateField("upfrontCost")(parseInt(String(val).trim()))
              }
            />
            <InlineGroup spaceBetweenElements={2} verticalCenter>
              <Text size="sm" bold>
                Ongoing Cost
              </Text>
              <Clickable
                onClick={() => ongoingCostRef.current?.setIsEditing(true)}
              >
                <Icon
                  noMargin
                  type="edit"
                  tip={{ content: "Edit Ongoing Cost" }}
                />
              </Clickable>
            </InlineGroup>
            <EditableText
              ref={ongoingCostRef}
              size="sm"
              alignment="horizontal"
              type="text"
              value={String(project.ongoingCost)}
              onChange={(val: string) =>
                updateField("ongoingCost")(parseInt(String(val).trim()))
              }
            />
            <InlineGroup spaceBetweenElements={2} verticalCenter>
              <Text size="sm" bold>
                Description
              </Text>
              <Clickable
                onClick={() => descriptionRef.current?.setIsEditing(true)}
              >
                <Icon
                  noMargin
                  type="edit"
                  tip={{ content: "Edit Description" }}
                />
              </Clickable>
            </InlineGroup>
            <EditableText
              ref={descriptionRef}
              size="sm"
              value={
                project.description.length
                  ? project.description
                  : "No description given"
              }
              onChange={updateField("description")}
            />
          </VerticalGroup>
          <VerticalGroup spaceBetweenElements={2}>
            {project.impacts.map((impact) =>
              hideImpactType.includes(impact.type) ? null : (
                <Impact
                  projectId={project.id.toString()}
                  impact={impact}
                  key={impact.id}
                  onUpdateField={onUpdateImpact}
                  impactTypeName={impactTypeName}
                />
              ),
            )}
          </VerticalGroup>
        </InlineGroup>
      </VerticalGroup>
    </Card>
  );
};

interface SpecialProjectsSelectProps {
  selected?: API.ProjectType[];
  onChange?: (newSelected: API.ProjectType[]) => void;
  onUpdateContent?: (
    projectId: string,
    data: Partial<API.SpecialProject>,
  ) => Promise<void>;
  onUpdateImpact?: (
    projectId: string,
    impactId: string,
    data: Partial<API.SpecialProjectImpact>,
  ) => Promise<void>;
  projects: API.SpecialProjectsResponse;
  impactTypeName?: API.SpecialProjectImpactTypeName[];
  hideImpactType?: string[];
  operationalRiskEnabled?: boolean;
  onoperationalRiskEnabled?: (e: boolean) => void;
  onOperationRiskChange?: (selected: any) => void;
  numberOfRounds?: number;
  operationalRiskRound?: number;
  selectedOperationalRiskRound?: any;
  riskRoundsOptions?: any;
}

const SpecialProjectsSelect: React.FC<SpecialProjectsSelectProps> = ({
  selected,
  onChange,
  projects,
  onUpdateContent,
  onUpdateImpact,
  impactTypeName,
  hideImpactType,
  onoperationalRiskEnabled,
  operationalRiskEnabled,
  onOperationRiskChange,
  numberOfRounds,
  selectedOperationalRiskRound,
  riskRoundsOptions,
}) => {
  const onProjectSelectChange = useCallback(
    (project: API.SpecialProject, isSelected: boolean) => {
      if (!selected || !onChange) {
        return;
      }
      const items = new Set(selected);

      if (isSelected) {
        items.add(project.type);
      } else {
        items.delete(project.type);
      }

      onChange(Array.from(items));
    },
    [onChange, selected],
  );

  const groups = useMemo(() => {
    if (!projects) return [];

    const groupSet = new Set<string>();

    for (const project of projects) {
      groupSet.add(project.group);
    }
    return Array.from(groupSet);
  }, [projects]);

  return (
    <VerticalGroup
      className="special-projects-select"
      wide
      spaceBetweenElements={6}
      flexAuto
    >
      {projects &&
        groups.length &&
        groups.map((group) => {
          return (
            <Accordion key={group} title={group} defaultState="expand">
              {projects
                .filter((p) => p.group === group)
                .map((project) => {
                  return (
                    <ProjectItem
                      key={project.name}
                      project={project}
                      selected={!!selected?.includes(project.type)}
                      onChange={onChange ? onProjectSelectChange : undefined}
                      onUpdateContent={onUpdateContent}
                      onUpdateImpact={onUpdateImpact}
                      impactTypeName={impactTypeName}
                      hideImpactType={hideImpactType}
                      onoperationalRiskEnabled={onoperationalRiskEnabled}
                      operationalRiskEnabled={!!operationalRiskEnabled}
                      onOperationRiskChange={onOperationRiskChange}
                      numberOfRounds={numberOfRounds}
                      selectedOperationalRiskRound={
                        selectedOperationalRiskRound
                      }
                      riskRoundsOptions={riskRoundsOptions}
                    />
                  );
                })}
            </Accordion>
          );
        })}
    </VerticalGroup>
  );
};

export default SpecialProjectsSelect;
