import React from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";
import useModelQuery from "../utils/useModelQuery";
import { formatValue } from "../utils/formatters";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["balanceSheet"];
}

const ModelTeamResultsBalanceSheet: React.FC<Props> = ({ data }) => {
  const { openIfTable } = useModelQuery();

  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <VerticalGroup spaceBetweenElements={2} wide>
          <TableWithSections
            header="Assets"
            data={[
              {
                name: "Cash",
                fields: [
                  "assets.cash.openingBalance",
                  "assets.cash.netCashflow",
                  "assets.cash.closingBalance",
                  "assets.cash.averageBalance",
                ],
                totalFields: [
                  "assets.cash.closingBalance",
                  "assets.cash.averageBalance",
                ],
                values: data,
              },
              {
                name: "Lending Assets",
                fields: [
                  "assets.lendingAssets.HOME_LOANS",
                  "assets.lendingAssets.homeLoanPremiumOnAcquisition",
                  "assets.lendingAssets.BUSINESS_LOANS",
                  "assets.lendingAssets.CREDIT_CARDS",
                  "assets.lendingAssets.INSTITUTIONAL_BANKING",
                  "assets.lendingAssets.total",
                ],
                totalFields: ["assets.lendingAssets.total"],
                values: data,
              },
              {
                name: "Other Assets",
                fields: [
                  "assets.otherAssets.WEALTH_MANAGEMENT",
                  "assets.otherAssets.OFFSHORE_BANK",
                  "assets.otherAssets.otherFixedAssets",
                  "assets.otherAssets.investments",
                  "assets.otherAssets.total",
                ],
                totalFields: ["assets.otherAssets.total"],
                values: data,
              },
              {
                isTotalsSection: true,
                fields: ["assets.total"],
                fieldFormatter: () => "Total Assets",
                values: data,
              },
            ]}
          />
          <TableWithSections
            header="Liabilities"
            data={[
              {
                fields: [
                  "liabilities.DEPOSITS",
                  "liabilities.wholesaleFunding",
                  "liabilities.total",
                ],
                totalFields: ["liabilities.total"],
                fieldFormatter: (field: string) =>
                  field === "liabilities.total" ? "Total Liabilities" : null,
                values: data,
              },
              {
                isTotalsSection: true,
                fields: ["assets.net"],
                values: data,
                fieldFormatter: () => "Net Assets",
              },
            ]}
          />
          <TableWithSections
            header="Equity"
            openOnInit={openIfTable("balanceSheetEquity")}
            data={[
              {
                name: "Retained Earnings",
                fields: [
                  "equity.retainedEarnings.openingBalance",
                  "equity.retainedEarnings.npat",
                  "equity.retainedEarnings.dividendsPaid",
                  "equity.retainedEarnings.specialDividendsPaid",
                  "equity.retainedEarnings.closingBalance",
                ],
                totalFields: ["equity.retainedEarnings.closingBalance"],
                values: data,
                formatter: (val: number, field: string) =>
                  field === "equity.retainedEarnings.dividendsPaid" ||
                  field === "equity.retainedEarnings.specialDividendsPaid"
                    ? formatValue(-1 * val)
                    : null,
              },
              {
                name: "Shareholders Funds",
                fields: [
                  "equity.shareholdersFunds.openingBalance",
                  "equity.shareholdersFunds.shareIssues",
                  "equity.shareholdersFunds.shareBuybacks",
                  "equity.shareholdersFunds.closingBalance",
                ],
                totalFields: ["equity.shareholdersFunds.closingBalance"],
                values: data,
              },
              {
                isTotalsSection: true,
                fields: [
                  "equity.averageBalance",
                  "equity.total",
                  "equity.variance",
                ],
                fieldFormatter: (field: string) =>
                  field === "equity.total" ? "Total Equity" : null,
                values: data,
              },
            ]}
          />
        </VerticalGroup>
      </VerticalGroup>
    </>
  );
};

export default ModelTeamResultsBalanceSheet;
