import React from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import ContentStretcher from "../../reporting-templates/components/ContentStretcher";

interface Props {
  paddingLeft?: number;
  paddingRight?: number;
  availableWidth: number;
  availableHeight: number;
  previewWidth: number;
  previewHeight: number;
  heightOffset?: number;
}

const RENDERED_DIMENSIONS = { width: 1806, height: 850 };

const RoundResultsPresenterSlidePreview: React.FC<Props> = (props) => {
  const {
    paddingLeft = 0,
    paddingRight = 0,
    heightOffset = 0,
    availableHeight,
    availableWidth,
    previewWidth,
    previewHeight,
    children,
  } = props;
  return (
    <VerticalGroup
      style={{
        flex: 1,
        height: availableHeight,
        width: availableWidth,
        paddingLeft,
        paddingRight,
      }}
      spread
      center
    >
      <ContentStretcher
        style={{
          background: "#e1e1e1",
          width: previewWidth - (paddingLeft + paddingRight),
          height: previewHeight - heightOffset,
          minHeight: previewHeight - heightOffset,
        }}
        contentWidth={RENDERED_DIMENSIONS.width}
        contentHeight={RENDERED_DIMENSIONS.height}
      >
        <div
          style={{
            width: RENDERED_DIMENSIONS.width,
            height: RENDERED_DIMENSIONS.height,
            minHeight: RENDERED_DIMENSIONS.height,
            position: "absolute",
          }}
        >
          {children}
        </div>
      </ContentStretcher>
    </VerticalGroup>
  );
};

export default RoundResultsPresenterSlidePreview;
