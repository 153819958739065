import React, { useCallback, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";

import Text from "../../../../../atoms/text/Text";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import Dropdown from "../../../../../atoms/form/input/Dropdown";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import IconButton from "../../../../../molecules/iconbutton/IconButton";
import Button from "../../../../../atoms/button/Button";
import useAssessments from "../../../../../../hooks/useAssessments";
import AssessmentPreviewModal from "./AssessmentPreviewModal";
import gameApi from "../../../../../../services/gameApi";
import FacilitatorHidden from "../../../../../molecules/facilitator-hidden/FacilitatorHidden";
import FacilitatorOnly from "../../../../../molecules/facilitator-only/FacilitatorOnly";
import Input from "../../../../../atoms/form/input/Input";
import { Noop } from "../../../../../../constants/functions";

interface SelectValue {
  id: string;
  value: string;
  label: string;
  assessment: API.AssessmentResponse;
}

interface SelectAssessmentProps {
  event: API.EventResponse;
  assessmentId?: string;
  clientId?: string;
  state?: string;
  onAssessmentSelected?: (assessment: API.AssessmentResponse) => void;
  onUnselectAssessment?: () => void;
}

const SelectAssessment: React.FC<SelectAssessmentProps> = ({
  event,
  assessmentId,
  clientId,
  onAssessmentSelected,
  onUnselectAssessment,
}) => {
  const { eventId } = useParams<{ eventId: string }>();
  const [showSettingsPreviewModal, setShowSettingsPreviewModal] =
    useState<boolean>(false);
  const { data } = useAssessments(clientId ? [clientId] : []);

  const options: SelectValue[] = useMemo(() => {
    return data
      ? data.map((assessment) => ({
          id: assessment.id,
          value: assessment.id,
          label: assessment.name,
          assessment,
        }))
      : [];
  }, [data]);

  const currentSelected = useMemo(() => {
    if (assessmentId) {
      return options.find((option) => option.id === assessmentId);
    }
    return null;
  }, [assessmentId, options]);

  const handleExport = useCallback(() => {
    gameApi.downloadAssessment(event);
  }, [event]);

  return (
    <>
      <VerticalGroup spaceBetweenElements={4}>
        <Text size="sm" bold>
          Assessments
        </Text>
        <InlineGroup spaceBetweenElements={4}>
          <FacilitatorHidden>
            <Dropdown
              selectProps={{
                isDisabled: !clientId,
                onChange: (option: SelectValue) =>
                  onAssessmentSelected &&
                  onAssessmentSelected(option.assessment),
                value: currentSelected,
                options,
                noOptionsMessage: () => "No Published Assessment",
              }}
            />
          </FacilitatorHidden>
          <FacilitatorOnly>
            <Input
              isVerticalLayout
              type="text"
              value={event.assessment?.name}
              disabled
              onChange={Noop}
            />
          </FacilitatorOnly>
          <FacilitatorHidden>
            <IconButton
              color="blue"
              mini
              noUi
              light={false}
              data-test={`delete-assessment-${currentSelected?.id}`}
              icon="trash"
              onClick={onUnselectAssessment}
            />
          </FacilitatorHidden>
          {assessmentId && (
            <Button
              outline
              data-test="view-simulation-settings-button"
              onClick={() => setShowSettingsPreviewModal(true)}
            >
              View Assessment
            </Button>
          )}
          {assessmentId && (
            <Button outline>
              <Link to={`/events/${eventId}/view/assessments/${assessmentId}`}>
                Mark assessments
              </Link>
            </Button>
          )}
          {assessmentId && (
            <Button outline onClick={handleExport}>
              Download Answers
            </Button>
          )}
        </InlineGroup>
        {assessmentId && (
          <AssessmentPreviewModal
            assessmentId={assessmentId}
            isOpen={showSettingsPreviewModal}
            onClose={() => setShowSettingsPreviewModal(false)}
          />
        )}
      </VerticalGroup>
    </>
  );
};

export default SelectAssessment;
