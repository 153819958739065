import React from "react";

import Checkbox, { Props as CheckboxProps } from "../form/input/Checkbox";
import InlineGroup from "../inlinegroup/InlineGroup";
import LoadingSpinner from "../loadingspinner/LoadingSpinner";
import Text from "../text/Text";

import "./FancyCheckbox.scss";

export interface Props extends CheckboxProps {
  inProgress?: boolean;
}

function FancyCheckbox({ inProgress, ...rest }: Props) {
  if (inProgress) {
    return (
      <InlineGroup
        verticalCenter
        spaceBetweenElements={3}
        style={{ marginTop: 2, marginBottom: 2 }}
      >
        <LoadingSpinner />
        <Text>{rest.label}</Text>
      </InlineGroup>
    );
  }
  return <Checkbox {...rest} inputClassName="fancy-checkbox" tabIndex={-1} />;
}

export default FancyCheckbox;
