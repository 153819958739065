import React, { useMemo } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";
import getTeamIdsAsFields from "../utils/getTeamIdsAsFields";
import getTeamName from "../utils/getTeamName";
import pluckResultsByType from "../utils/pluckResultsByType";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.DecisionsResponse["dealroom"];
  teams: API.GameConnection[];
}

const Dealroom: React.FC<Props> = ({ configuration, data, teams }) => {
  const { numberOfTeams, numberOfCpuTeams } = configuration;
  const totalNumberOfTeams = numberOfTeams + numberOfCpuTeams;
  const fields = useMemo(
    () => getTeamIdsAsFields(totalNumberOfTeams),
    [totalNumberOfTeams],
  );

  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <TableWithSections
          header="Dealroom"
          data={[
            {
              fields,
              values: pluckResultsByType(data, "dealroom"),
            },
          ]}
          fieldFormatter={(field: string) =>
            getTeamName(parseInt(field), numberOfTeams, teams)
          }
        />
      </VerticalGroup>
    </>
  );
};

export default Dealroom;
