// IntroWhenYouStart.tsx
import React, { useCallback, useMemo } from "react";
import Card from "../../../atoms/card/Card";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import EditableReportingHeading from "../components/EditableReportingHeading";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Icon from "../../../atoms/icon/Icon";
import "./IntroWhenYouStart.scss";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import ResultsText from "../results-text/ResultsText";
import { useResultsContext } from "../context/context";
import classNames from "classnames";

export const whenYouStartMaxClicks = 3;
interface Props {
  dealRoomEnabled: boolean;
  excoEnabled: boolean;
}

const IntroWhenYouStart = ({ dealRoomEnabled, excoEnabled }: Props) => {
  const context = useResultsContext();

  const onNext = useCallback(() => {
    if (context.clicks < whenYouStartMaxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context]);
  const showCeoSecondGroup = context.noAnimations || context.clicks >= 1;
  const showSyncingSection = context.noAnimations || context.clicks >= 2;
  const showPricingSection = context.noAnimations || context.clicks >= 3;

  const getSubmissionText = useMemo(() => {
    if (!dealRoomEnabled && !excoEnabled) {
      return "Submit bank name and strategy.";
    } else if (dealRoomEnabled && !excoEnabled) {
      return "Submit bank name, strategy, and deal room bids.";
    } else if (!dealRoomEnabled && excoEnabled) {
      return "Submit bank name, strategy, and executive decisions.";
    } else {
      return "Submit bank name, strategy, deal room bids, and executive decisions.";
    }
  }, [dealRoomEnabled, excoEnabled]);

  return (
    <RoundResultsContainer onNext={onNext}>
      <Card wide fullHeight>
        <VerticalGroup wide center full spread>
          <EditableReportingHeading defaultHeading="When you start..." />
          <div className="timeline-container">
            <VerticalGroup className="timeline-items">
              <InlineGroup className="timeline-item">
                <div className="timeline-icon">
                  <Icon
                    type="ceo"
                    dynamicSize={{
                      min: "50px",
                      preferred: "8vw",
                      max: "900px",
                    }}
                  />
                </div>
                <div className="timeline-content">
                  <ResultsText left bold size="lg">
                    There is 1 CEO per team
                  </ResultsText>
                  <ul className="ceo-info-first-group">
                    <li>
                      <ResultsText left>Welcome to the CEOs....</ResultsText>
                    </li>
                    <li>
                      <ResultsText>{getSubmissionText}</ResultsText>
                    </li>
                  </ul>
                  <ResultsText
                    left
                    className={classNames("ceo-info-second-group-heading", {
                      show: showCeoSecondGroup,
                    })}
                  >
                    Exercise your leadership skills:
                  </ResultsText>
                  <ul
                    className={classNames("ceo-info-second-group", {
                      show: showCeoSecondGroup,
                    })}
                  >
                    <li>
                      <ResultsText left>
                        CEO can choose how to organise and run your banks
                      </ResultsText>
                    </li>
                    <li>
                      <ResultsText left>
                        Remember to influence, listen and challenge respectfully
                      </ResultsText>
                    </li>
                  </ul>
                </div>
              </InlineGroup>

              <div
                className={classNames("timeline-item-syncing", {
                  show: showSyncingSection,
                })}
              >
                <div className="timeline-icon">
                  <Icon
                    type="syncing"
                    dynamicSize={{
                      min: "50px",
                      preferred: "8vw",
                      max: "900px",
                    }}
                  />
                </div>
                <VerticalGroup verticalCenter className="timeline-content">
                  <ResultsText left bold size="lg">
                    Syncing Across Team Member Interfaces
                  </ResultsText>
                  <ResultsText left>
                    Within teams, your decisions synchronize every few seconds
                    across interfaces
                  </ResultsText>
                </VerticalGroup>
              </div>

              <div
                className={classNames("timeline-item-pricing", {
                  show: showPricingSection,
                })}
              >
                <div className="timeline-icon">
                  <Icon
                    type="growthFunding"
                    dynamicSize={{
                      min: "50px",
                      preferred: "8vw",
                      max: "900px",
                    }}
                  />
                </div>
                <VerticalGroup verticalCenter className="timeline-content">
                  <ResultsText left size="lg" bold>
                    Pricing Changes
                  </ResultsText>
                  <ResultsText left>
                    Pricing changes are for existing & new customers
                  </ResultsText>
                </VerticalGroup>
              </div>
              <div className="timeline-line"></div>
            </VerticalGroup>
          </div>
        </VerticalGroup>
      </Card>
    </RoundResultsContainer>
  );
};

export default IntroWhenYouStart;
