import React from "react";
import { fullDate } from "../../../lib/date";

import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import Text from "../../atoms/text/Text";
import { Accordion } from "../../organisms/accordion/accordion";
import Label from "../../atoms/form/label/Label";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";

interface Props {
  event: API.EventResponse | API.EventResponseSummary;
}

function MobileGameDetails({ event }: Props) {
  return (
    <Accordion
      title={`${event.client} - ${fullDate(event.date)}`}
      defaultState="collapse"
      wide
    >
      {" "}
      <VerticalGroup bgColour="white">
        {" "}
        <InlineGroup verticalCenter className="p-1">
          <Label noShrink>
            <Text size="md" bold>
              Event Name
            </Text>
          </Label>
          <Text size="md">{event.name}</Text>
        </InlineGroup>
        <InlineGroup verticalCenter className="p-1">
          <Label noShrink>
            <Text size="md" bold>
              Stream
            </Text>
          </Label>
          <Text size="md">{event.streamName ?? "-"}</Text>
        </InlineGroup>
      </VerticalGroup>
    </Accordion>
  );
}

export default MobileGameDetails;
