import React, { useCallback, useRef } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Card from "../../../atoms/card/Card";
import "../RoundResultsTablePage.scss";
import { useResultsContext } from "../context/context";
import EditableText from "../../../atoms/text/EditableText";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import ParseHtml from "../components/ParseHtmlIntoResultsTest";
import Text from "../../../atoms/text/Text";
import useCalculateHeight from "../../../../hooks/useCalculateHeight";
interface Props {
  data: API.ReportingLayoutCustomTextConfig;
}

const RoundResultsCustomText: React.FC<Props> = ({ data }) => {
  const context = useResultsContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const editorHeight = useCalculateHeight(containerRef, {
    subtractPixels: 130,
    minHeight: 300,
  });

  const onChange = useCallback(
    (val: API.ReportingLayoutCustomTextConfig) => {
      if (context.onCustomTemplateEdit) {
        context.onCustomTemplateEdit({
          config: val,
        });
      }
    },
    [context],
  );

  return (
    <RoundResultsContainer>
      <VerticalGroup full center wide>
        {context.allowCustomTemplateEdits ? (
          <EditableText
            type="text"
            value={data.heading}
            size="6xl"
            bold
            colour="primaryDark"
            className="mb-2"
            alignment="horizontal"
            onChange={(val: string) => {
              onChange({
                ...data,
                heading: val,
              });
            }}
          />
        ) : (
          <Text size="6xl" bold colour="primaryDark" className="mb-2">
            {data.heading}
          </Text>
        )}
        <InlineGroup fullHeight block>
          <Card ref={containerRef} wide sharp padding={4}>
            <InlineGroup fullHeight block>
              {context.allowCustomTemplateEdits ? (
                <div style={{ width: "100%" }}>
                  <EditableText
                    richTextInputHeight={editorHeight}
                    type="richText"
                    value={data.body}
                    clickableStyle={{
                      width: "100%",
                    }}
                    onChange={(val: string) => {
                      onChange({
                        ...data,
                        body: val,
                      });
                    }}
                  />
                </div>
              ) : (
                <div style={{ width: "100%" }}>
                  <ParseHtml html={data.body} />
                </div>
              )}
            </InlineGroup>
          </Card>
        </InlineGroup>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsCustomText;
