import React, { useCallback } from "react";
import api from "../../../../services/api";
import Layouts from "./layouts/Layouts";
import { BaseTemplateLayoutType } from "../../reporting-templates/types";
import { useGenericPreviewReportingData } from "../../../../hooks/usePreviewReportingData";
import { useReportingLayoutConfig } from "../../../../hooks/useReportingLayoutConfig";

function ReportingLayouts() {
  const { data, inProgress, error, refresh } = useReportingLayoutConfig();

  const patchLayouts = useCallback((data: BaseTemplateLayoutType[]) => {
    api.patchBaseReportingLayouts(
      data as API.BaseReportingTemplateLayoutResponse[],
    );
  }, []);

  const updateConfig = useCallback(
    (layoutId: string, data: API.ReportingTemplateLayoutUpdateRequest) => {
      return api.updateReportingLayoutConfig(layoutId, data);
    },
    [],
  );

  const { data: previewData } = useGenericPreviewReportingData({ roundId: 3 });

  return (
    <Layouts
      templateType="reporting"
      inProgress={inProgress}
      error={error}
      apiLayouts={data as API.IntroTemplateLayoutResponse[]}
      refresh={refresh}
      updateConfig={updateConfig}
      patchLayouts={patchLayouts}
      data={previewData}
    />
  );
}

export default ReportingLayouts;
