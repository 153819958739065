import React from "react";

import EnumDropdown, {
  getValueFunc,
} from "../../../../organisms/enum-dropdown/EnumDropdown";

interface Props {
  onChange: (selected: { value: string; label: string }) => void;
  value?: string;
  error?: string;
  label?: string;
  name?: string;
}

export const options = [
  "AUD",
  "NZD",
  "USD",
  "GBP",
  "EUR",
  "IDR",
  "JPY",
  "RMB",
  "SGD",
].map((currency) => ({
  value: currency,
  label: currency,
}));

export const getLabelFromValue = getValueFunc(options);

function CurrencyDropdown({ onChange, value, error, label, name }: Props) {
  return (
    <EnumDropdown
      onChange={onChange}
      options={options}
      value={value}
      error={error}
      label={label}
      name={name ?? "currency-dropdown"}
    />
  );
}

export default CurrencyDropdown;
