import React, { useCallback } from "react";
import ConfirmModal from "../../../../organisms/confirm-modal/ConfirmModal";

import useAPIRequest from "../../../../../hooks/useAPIRequest";
import API from "../../../../../services/api";
import ChallengeModal from "../../../../organisms/challenge-modal/ChallengeModal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  event: API.EventResponseSummary;
}

function DeleteEvent({ isOpen, onClose, onComplete, event }: Props) {
  const callback = useCallback(() => {
    if (!event.closed) {
      return API.closeAndDeleteEvent(event.id);
    }
    return API.deleteEvent(event.id);
  }, [event]);

  const [{ inProgress, error }, doAPIRequest] = useAPIRequest(callback, null);

  const handleConfirm = useCallback(() => {
    doAPIRequest(onComplete);
  }, [doAPIRequest, onComplete]);

  if (!event.closed) {
    return (
      <ChallengeModal
        isOpen={isOpen}
        onClose={onClose}
        onCancel={onClose}
        onConfirm={handleConfirm}
        confirmInProgress={inProgress}
        title="Delete this event?"
        challengeQuestion={
          <>
            To delete the following event: <br />
            <b>{event.name}</b>
            <br />
            Please type the word DELETE below to permanently delete this event.
          </>
        }
        challengeAnswer="DELETE"
        challengeAnswerWarning="Please type the word DELETE in CAPS to delete the event."
        confirmTitle="Delete"
        error={error && error.message}
        warning
      />
    );
  }

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      title="Delete this event?"
      description={
        <>
          Are you sure you want to delete the following event: <br />
          <b>{event.name}</b>
          <br />
          This cannot be reverted.
        </>
      }
      confirmTitle="Delete"
      error={error && error.message}
      warning
    />
  );
}

export default DeleteEvent;
