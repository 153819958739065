import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  MouseEvent,
} from "react";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import { parseTimeLeftFromSeconds } from "../../../../lib/date";
import API from "../../../../services/api";
import { useParams } from "react-router-dom";
import { MINIMUM_SECONDS_LEFT } from "../GameController";
import Icon from "../../../atoms/icon/Icon";

import Theme from "../../../../styles/_theme.module.scss";
import "./TeamTimer.scss";
import Clickable from "../../../atoms/clickable/Clickable";
import { formatTeamName } from "../utils/getTeamName";
import DisconnectCEODevice from "../DisconnectCEODevice";
import EditableText, { Handles } from "../../../atoms/text/EditableText";
import ErrorModal from "../../../organisms/standard-modal/ErrorModal";
import useIsMobile from "../../../../hooks/useIsMobile";
import Button from "../../../atoms/button/Button";
import Text from "../../../atoms/text/Text";

const DEFAULT_BONUS_TIME = 120;

interface UIState {
  disconnectModalOpen: boolean;
  teamSelected: API.GameConnection | null;
}

interface Props {
  round: number;
  team: API.GameConnection;
  timer: API.GameTeamBonusTimer;
  setError: (err: string | null) => void;
  update: () => void;
  event: API.EventResponse;
  handleLoginClick: (teamId: number) => void;
}

function TeamTimer({
  round,
  team,
  timer,
  setError,
  update,
  handleLoginClick,
}: Props) {
  const { eventId } = useParams<{ eventId: string }>();
  const [timeToAdd, setTimeToAdd] = useState(
    timer.timeRemaining === 0 ? DEFAULT_BONUS_TIME : 0,
  );
  const [time, setTime] = useState(timer.timeRemaining);
  const timerActive = !!timer.bonusFinishTime && timer.timeRemaining > 0;
  const [active, setActive] = useState(timerActive);
  const nameEditorRef = useRef<Handles>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [originalTeamName, setOriginalTeamName] = useState(team.name);
  const [isErrorModalOpen, setErrorModalOpen] = useState(false);
  const isMobile = useIsMobile();
  const [{ disconnectModalOpen, teamSelected }, setUiState] = useState<UIState>(
    { disconnectModalOpen: false, teamSelected: null },
  );

  const onDisconnectDeviceComplete = useCallback(() => {
    setUiState((prevState) => ({
      ...prevState,
      disconnectModalOpen: false,
      teamSelected: null,
    }));
  }, []);

  const onEditTeamIconClicked = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    if (nameEditorRef.current) {
      nameEditorRef.current.setIsEditing(true);
    }
  }, []);

  const onTeamNameUpdated = useCallback(
    (newTeamName: string) => {
      API.updateTeamInfo(eventId, team.teamId, {
        name: newTeamName,
        strategy: team.strategy || "",
      })
        .then(() => {
          setOriginalTeamName(newTeamName);
        })
        .catch((error) => {
          setErrorModalOpen(true);
          nameEditorRef.current?.reset();
          nameEditorRef.current?.setIsEditing(false);

          if (error.response && error.response.data) {
            console.log("not sure");
          } else {
            setErrorMessage(`${error}`);
          }
        });
    },
    [eventId, team.strategy, team.teamId],
  );

  useEffect(() => {
    if (!active && timerActive) {
      setActive(true);
      setTimeToAdd(0);
    }
    if (active) {
      setTime(timer.timeRemaining);
    }
  }, [active, timer, team, timer.timeRemaining, timerActive]);

  const updateTime = useCallback(
    (newTime: number) => {
      if (active && newTime <= 0) {
        setActive(false);
        setTimeToAdd(DEFAULT_BONUS_TIME);
      }
      if (newTime <= 0) {
        if (time !== 0) {
          setTime(0);
        }
        return;
      }
      setTime(newTime);
    },
    [active, time],
  );

  useEffect(() => {
    let countdown: NodeJS.Timeout;
    if (active) {
      countdown = setInterval(() => {
        updateTime(time - 1);
      }, 1000);
    }
    return () => {
      clearInterval(countdown);
    };
  }, [active, time, updateTime]);

  const handleTimeChange = async (seconds: number) => {
    try {
      if (!active) {
        const newTimeToAdd = timeToAdd + seconds;
        const newTime = time + newTimeToAdd;
        if (newTime <= 0) return;
        setTimeToAdd(newTimeToAdd);
      } else {
        if (time <= MINIMUM_SECONDS_LEFT && seconds < 0) return;
        if (time + seconds < MINIMUM_SECONDS_LEFT) {
          await API.updateTeamBonusTimer(
            eventId,
            round,
            team.teamId,
            -(time - MINIMUM_SECONDS_LEFT),
          );
          updateTime(MINIMUM_SECONDS_LEFT);
        } else {
          await API.updateTeamBonusTimer(eventId, round, team.teamId, seconds);
          updateTime(time + seconds);
        }
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const clearBonusTime = useCallback(async () => {
    try {
      await API.clearTeamBonusTimer(eventId, round, team.teamId);
      setTime(0);
      setTimeToAdd(DEFAULT_BONUS_TIME);
    } catch (err) {
      setError(err.message);
    }
  }, [eventId, round, team, setError]);

  const startBonusTime = useCallback(async () => {
    await API.updateTeamBonusTimer(eventId, round, team.teamId, timeToAdd);
    setTimeToAdd(0);
  }, [eventId, round, team, timeToAdd]);

  const handlePlay = useCallback(async () => {
    try {
      if (active) {
        await clearBonusTime();
      } else {
        await startBonusTime();
      }
      setActive(!active);
      update();
    } catch (err) {
      setError(err.message);
    }
  }, [active, clearBonusTime, startBonusTime, update, setError]);

  const displayTime = time + timeToAdd;

  const teamHasMaster = useMemo(() => {
    return team.devices.some((d) => d.isMaster);
  }, [team]);
  return (
    <InlineGroup
      spaceBetweenElements={isMobile ? 4 : 0}
      verticalCenter
      block
      evenWidthChildren
      key={`team-${team}`}
    >
      {disconnectModalOpen && !!teamSelected && (
        <DisconnectCEODevice
          gameId={eventId}
          isOpen={disconnectModalOpen}
          onClose={() =>
            setUiState((prevState) => ({
              ...prevState,
              disconnectModalOpen: false,
              teamSelected: null,
            }))
          }
          onComplete={onDisconnectDeviceComplete}
          team={teamSelected}
        />
      )}
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => {
          setErrorModalOpen(false);
        }}
        title="Error updating team name"
        description={
          errorMessage
            ? errorMessage
            : "Could not update the team name. Please try again."
        }
      />
      <InlineGroup verticalCenter spaceBetweenElements={2}>
        <Clickable
          disabled={!teamHasMaster}
          centered
          onClick={(event) => {
            event.stopPropagation();
            setUiState({ disconnectModalOpen: true, teamSelected: team });
          }}
        >
          <Icon
            type="disconnect"
            colour={teamHasMaster ? "danger" : "grey1"}
            noMargin
            tip={{
              content: (
                <>
                  <p>This will disconnect the CEO from the simulation</p>
                </>
              ),
              id: `${team}-disconnect`,
            }}
          />
        </Clickable>
        <EditableText
          size="sm"
          type="text"
          ref={nameEditorRef}
          value={originalTeamName}
          formatter={formatTeamName(team)}
          onChange={onTeamNameUpdated}
        />
        <Clickable centered onClick={onEditTeamIconClicked}>
          <Icon
            type="edit"
            noMargin
            tip={{
              content: (
                <>
                  <p>Edit team name</p>
                </>
              ),
              id: `${team}-edit-team-name`,
            }}
          />
        </Clickable>
      </InlineGroup>
      <InlineGroup
        verticalCenter
        className="team-timer"
        spaceBetweenElements={2}
      >
        <Clickable onClick={() => handleTimeChange(-60)}>
          <Icon noMargin type="roundMinus" colour="secondaryBlue" />
        </Clickable>
        <div
          className="time"
          style={{
            color:
              displayTime <= MINIMUM_SECONDS_LEFT
                ? Theme.red
                : displayTime <= 60
                  ? Theme.yellow
                  : Theme.blue,
          }}
        >
          {parseTimeLeftFromSeconds(displayTime).time}
        </div>
        <Clickable onClick={() => handleTimeChange(60)}>
          <Icon noMargin type="roundPlus" colour="secondaryBlue" />
        </Clickable>
      </InlineGroup>

      <InlineGroup evenWidthChildren verticalCenter>
        <Clickable onClick={handlePlay}>
          {active ? (
            <Icon noMargin type="roundStop" colour="warning" />
          ) : (
            <Icon noMargin type="roundPlay" colour="green" />
          )}
        </Clickable>
        <Button outline onClick={() => handleLoginClick(team.teamId)}>
          <Text>Login</Text>
        </Button>
        {!isMobile && (
          <>
            <Icon
              noMargin
              type="status"
              colour={team.submitted ? "lightgreen" : "grey1"}
              tip={{
                content: (
                  <>
                    <p>
                      The &quot;Decisions&quot; status indicates whether <br />
                      this team has submitted decisions.
                    </p>
                  </>
                ),
                id: `${team}-status`,
              }}
            />
          </>
        )}
      </InlineGroup>
    </InlineGroup>
  );
}

export default TeamTimer;
