import React from "react";
import Card from "../../../../../atoms/card/Card";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import NewsPreviewIframe from "./NewsPreviewIframe";
import { PreviewSettings } from "../types";
import Button from "../../../../../atoms/button/Button";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import EconomicScenarioRoundDropdown from "../../../../../organisms/economicScenarioRoundDropdown/EconomicScenarioRoundDropdown";
import EconomySettingDropDown from "../components/EconomySettingDropDown";
import "./NewsPreview.scss";
import EnumDropdown from "../../../../../organisms/enum-dropdown/EnumDropdown";
import ResultsPreview from "./ResultsPreview";
import useDimensions from "../../../../../../hooks/useDimensions";

interface Props {
  scenarioId?: string;
  roundId: number;
  previewSettings: PreviewSettings;
  setNewsPreviewRound: (round: number) => void;
  newsPreviewRound: number;
  handleEditEconomyClick: () => void;
  handleEditBullAndBearClick: () => void;
  handleEditNewsConfigClick: () => void;
  handleEditSettingsClick: () => void;
  selectedScenario: API.EconomicScenarioResponse | null;
  scenarios: API.EconomicScenarioResponse[];
  handleUpdate: (scenario: {
    value: string;
    label: string;
    description?: string;
  }) => void;
  previewMode: string;
  setPreviewMode: (mode: string) => void;
}

const RENDERED_DIMENSIONS = { width: 1600, height: 600 };
const containerPadding = 0;
const innerContainerPadding = 0;
const headingHeight = 50;

const NewsPreview = ({
  scenarioId,
  roundId,
  previewSettings,
  newsPreviewRound,
  handleEditEconomyClick,
  selectedScenario,
  handleEditBullAndBearClick,
  handleEditSettingsClick,
  setNewsPreviewRound,
  handleEditNewsConfigClick,
  scenarios,
  handleUpdate,
  previewMode,
  setPreviewMode,
}: Props) => {
  const [{ availableHeight }, setContainerRef] = useDimensions(
    headingHeight,
    containerPadding,
    innerContainerPadding,
    RENDERED_DIMENSIONS,
  );

  if (!scenarioId) {
    return (
      <VerticalGroup>
        <Card>Select a scenario first</Card>
      </VerticalGroup>
    );
  }

  return (
    <VerticalGroup spaceBetweenElements={3} wide full>
      <InlineGroup stretch block evenWidthChildren>
        <InlineGroup block reverse>
          <EconomySettingDropDown
            scenariosData={scenarios}
            onChange={handleUpdate}
            value={selectedScenario?.id}
            small
          />
        </InlineGroup>
        <InlineGroup stretch block reverse>
          <InlineGroup spaceBetweenElements={2} className="buttons-container">
            <EnumDropdown
              onChange={(selected) => {
                setPreviewMode(selected.value);
              }}
              options={[
                { label: "News", value: "news" },
                { label: "Results", value: "results" },
              ]}
              value={previewMode}
              tiny
            />
            <Button
              block
              disabled={!selectedScenario}
              onClick={handleEditNewsConfigClick}
            >
              Edit Headings & Icons
            </Button>
            <Button
              block
              disabled={!selectedScenario}
              onClick={handleEditEconomyClick}
            >
              Edit Primary Text Block
            </Button>
            <Button
              block
              disabled={!selectedScenario}
              onClick={handleEditBullAndBearClick}
            >
              Edit Secondary Text Block
            </Button>
            <Button
              block
              disabled={!selectedScenario}
              onClick={handleEditSettingsClick}
            >
              Edit Settings
            </Button>
            <EconomicScenarioRoundDropdown
              showPlayableOnly
              onChange={setNewsPreviewRound}
              currentRound={newsPreviewRound}
              smallish
            />
          </InlineGroup>
        </InlineGroup>
      </InlineGroup>
      <Card wide fullHeight allowFullScreen ref={setContainerRef}>
        {previewMode === "news" ? (
          <NewsPreviewIframe
            scenarioId={scenarioId}
            roundId={roundId}
            previewSettings={previewSettings}
            maxContainerHeight={availableHeight}
          />
        ) : null}
        {previewMode === "results" ? (
          <ResultsPreview
            scenarioId={scenarioId}
            roundId={roundId}
            previewSettings={previewSettings}
          />
        ) : null}
      </Card>
    </VerticalGroup>
  );
};

export default NewsPreview;
