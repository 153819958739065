import React from "react";
import Icon from "../../../atoms/icon/Icon";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import EditableReportingHeading from "../components/EditableReportingHeading";

interface Props {}

const RoundResultsLetsGo: React.FC<Props> = () => {
  return (
    <RoundResultsContainer>
      <VerticalGroup wide full center>
        <EditableReportingHeading defaultHeading="Let's Go!" />
        <InlineGroup center>
          <VerticalGroup full wide center>
            <Icon style={{ width: "95%", height: "95%" }} type="lets_go" />
          </VerticalGroup>
        </InlineGroup>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsLetsGo;
