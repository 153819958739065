import React, { useState, useEffect } from "react";
import Text from "../../../atoms/text/Text";
import {
  addSecondsToDate,
  formatRoundedMinutes,
  formatTime,
} from "../../../../lib/date";

interface RemainingTimeEstimateProps {
  timeRemaining: number;
}

const RemainingTimeEstimate: React.FC<RemainingTimeEstimateProps> = ({
  timeRemaining,
}) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Text size="lg" colour="white">
      Remaining Time Estimate: {formatRoundedMinutes(timeRemaining)} (Estimate
      Finish: {formatTime(addSecondsToDate(timeRemaining, currentDate))})
    </Text>
  );
};

export default RemainingTimeEstimate;
