import React, { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Setters, State, FieldErrors } from "../../../../../../hooks/useForm";
import Input from "../../../../../atoms/form/input/Input";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";

interface Props {
  question: State["formData"];
  setFieldRaw: Setters["setFieldRaw"];
  setFieldError: Setters["setFieldError"];
  fieldErrors: FieldErrors;
}

export const DEFAULT_WRITTEN_TEXT: API.WrittenTextFieldRequest = {
  type: "written_text",
  config: {
    type: "written_text",
    text: "",
  },
};

export const WrittenTextDetails: React.FC<Props> = ({
  question,
  setFieldRaw,
  setFieldError,
  fieldErrors,
}) => {
  const [field] = useState<API.WrittenTextFieldRequest>(
    question.fields[0] || { ...DEFAULT_WRITTEN_TEXT },
  );
  const [text, setText] = useState(() => {
    return field.config.text;
  });

  const onChange = useCallback((e) => {
    setText(e.target.value);
  }, []);

  useEffect(() => {
    setFieldRaw("fields", [
      {
        ...field,
        config: {
          ...field.config,
          text,
        },
      },
    ]);
  }, [setFieldRaw, text, field]);

  return (
    <VerticalGroup>
      <Input
        containerBlock
        type="textbox"
        value={text}
        label="Text *"
        onFieldInvalid={setFieldError("fields.text")}
        error={fieldErrors["fields.text"]}
        onChange={onChange}
      />
    </VerticalGroup>
  );
};
