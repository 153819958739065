import React from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import Group from "./Group";
import { FieldsOf } from "./types";
import GloInput, { DataProps } from "./GloInput";
import EnumDropdown from "../../../../organisms/enum-dropdown/EnumDropdown";

type DataType = API.GlobalModelVariablesResponse["otherMarketVariables"];

interface Props {
  data: DataType;
  onChange: <T extends keyof DataType>(
    key: T,
    field?: undefined | FieldsOf<DataType, T>,
  ) => (selected: any) => void;
}

const homeLoans: Array<DataProps<DataType, "homeLoans">> = [
  {
    label: "Broker Loans Market Share",
    field: "brokerLoansMarketShare",
    type: "percentage",
  },
  {
    label: "Starting Broker Support Costs",
    field: "startingBrokerSupportCosts",
    type: "bps",
  },
  {
    label: "Broker Commission Costs",
    field: "brokerCommissionCosts",
    type: "bps",
  },
  {
    label: "Broker Sensitivity to Prop Pricing",
    field: "brokerSensitivityToPropPricing",
    type: "percentage",
  },
  {
    label: "Home Loans OBI (% of Avg Balance)",
    field: "homeLoansObi",
    type: "bps",
  },
];

const deposits: Array<DataProps<DataType, "deposits">> = [
  {
    label: "Starting # Branches",
    field: "startingNumberOfBranches",
    type: "integer",
  },
  {
    label: "Transaction: Total Deposit Ratio",
    field: "totalDepositRatio",
    type: "percentage",
  },
  {
    label: "Each Branch Change Price",
    field: "eachBranchChangePrice",
    type: "bps",
  },
  {
    label: "Annual Branch Lease Cost",
    field: "annualBranchLeaseCost",
    type: "multiplier",
    multiplier: " ($m p.a)",
  },
  {
    label: "Branch Fitout Cost",
    field: "branchFitoutCost",
    type: "multiplier",
    multiplier: " ($m p.a)",
  },
  {
    label: "Deposits OBI (% of Tran Balance)",
    field: "depositsObi",
    type: "bps",
  },
];

const creditCards: Array<DataProps<DataType, "creditCards">> = [
  {
    label: "Reward Points Per $1 Spend",
    field: "rewardPointsPerDollarSpend",
    type: "integer",
  },
  {
    label: "Interchange Revenue Per $1",
    field: "interchangeRevenuePerDollar",
    type: "bps",
  },
  { label: "Value Per Point", field: "valuePerPoint", type: "bps" },
  {
    label: "CC Spend/Balance Ratio",
    field: "ccSpendBalanceRatio",
    type: "multiplier",
  },
  { label: "CC Revolve Rate", field: "ccRevolveRate", type: "percentage" },
  {
    label: "CC Headline Interest Rate",
    field: "ccHeadlineInterestRate",
    type: "percentage",
  },
];

const institutionalLending: Array<DataProps<DataType, "institutionalBanking">> =
  [
    {
      label: "Institutional Client Specialists",
      field: "institutionalClientSpecialists",
      type: "integer",
    },
    {
      label: "1 Client Specialist = Price",
      field: "clientSpecialistPrice",
      type: "bps",
    },
    {
      label: "Client Specialist Cost p.a",
      field: "clientSpecialistCost",
      type: "amount",
    },
    {
      label: "Institutional OBI - Assets %",
      field: "institutionalObi",
      type: "bps",
    },
  ];

const offshoreBank: Array<DataProps<DataType, "offshoreBank">> = [
  {
    label: "O.B - Default Investment ",
    field: "defaultInvestment",
    type: "amount",
  },
  {
    label: "O.B - Inv Pricing Impact Per 1m",
    field: "investmentPricingImpactPer1m",
    type: "bps",
  },
  { label: "Cost Of Funds", field: "costOfFunds", type: "percentage" },
  {
    label: "Intitial Acqusition Premium ($m)",
    field: "initialAcquisitionPremium",
    type: "integer",
  },
  {
    label: "Capital / IEA Ratio",
    field: "capitalIEARatio",
    type: "percentage",
  },
];

const OtherMarketVariables: React.FC<Props> = ({ data, onChange }) => {
  return (
    <VerticalGroup wide spaceBetweenElements={3} className="p-2">
      <Group title="Home Loans">
        {homeLoans.map(({ field, ...rest }) => (
          <GloInput
            key={field}
            value={data.homeLoans[field]}
            onChange={onChange("homeLoans", field)}
            {...rest}
          />
        ))}
      </Group>
      <Group title="Business Loans">
        <GloInput
          label="Business Loans OBI (% of Avg Balance)"
          value={data.businessLoans.businessLoansObi}
          onChange={onChange("businessLoans", "businessLoansObi")}
          type="bps"
        />
      </Group>
      <Group title="Deposits">
        {deposits.map(({ field, ...rest }) => (
          <GloInput
            key={field}
            value={data.deposits[field]}
            onChange={onChange("deposits", field)}
            {...rest}
          />
        ))}
      </Group>
      <Group title="Credit Cards">
        {creditCards.map(({ field, ...rest }) => (
          <GloInput
            key={field}
            value={data.creditCards[field]}
            onChange={onChange("creditCards", field)}
            {...rest}
          />
        ))}
      </Group>
      <Group title="Institutional Lending">
        {institutionalLending.map(({ field, ...rest }) => (
          <GloInput
            key={field}
            value={data.institutionalBanking[field]}
            onChange={onChange("institutionalBanking", field)}
            {...rest}
          />
        ))}
      </Group>
      <Group title="Wealth Management">
        <EnumDropdown
          label="WM Service Level - Default"
          onChange={(selected) =>
            onChange("wealthManagement", "serviceLevel")(selected.value)
          }
          options={[
            { label: "Low", value: "low" },
            { label: "Medium", value: "medium" },
            { label: "High", value: "lohighw" },
          ]}
          value={data.wealthManagement.serviceLevel}
          midsize
        />
        <GloInput
          value={data.wealthManagement.bsAssetsFixedPortion}
          onChange={onChange("wealthManagement", "bsAssetsFixedPortion")}
          type="percentage"
          label="WM BS Assets - Fixed Portion"
        />
      </Group>
      <Group title="Offshore Bank">
        {offshoreBank.map(({ field, ...rest }) => (
          <GloInput
            key={field}
            value={data.offshoreBank[field]}
            onChange={onChange("offshoreBank", field)}
            {...rest}
          />
        ))}
      </Group>
    </VerticalGroup>
  );
};
export default OtherMarketVariables;
