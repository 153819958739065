import React from "react";

import EnumDropdown, {
  getValueFunc,
} from "../../../../organisms/enum-dropdown/EnumDropdown";

interface Props {
  onChange: (selected: { value: string; label: string }) => void;
  value?: string;
  error?: string;
  label?: string;
  name?: string;
}

export const options = [
  {
    value: "default",
    label: "Default Decisions",
  },
  {
    value: "averagebank_easy",
    label: "Average Bank - Easy",
  },
  {
    value: "averagebank_medium",
    label: "Average Bank - Medium",
  },
  {
    value: "averagebank_hard",
    label: "Average Bank - Hard",
  },
  {
    value: "bigbank_easy",
    label: "Big Bank - Easy",
  },
  {
    value: "bigbank_medium",
    label: "Big Bank - Medium",
  },
  {
    value: "bigbank_hard",
    label: "Big Bank - Hard",
  },
  {
    value: "smallbank_easy",
    label: "Small Bank - Easy",
  },
  {
    value: "smallbank_medium",
    label: "Small Bank - Medium",
  },
  {
    value: "smallbank_hard",
    label: "Small Bank - Hard",
  },
];

export const getLabelFromValue = getValueFunc(options);

function CpuStrategyDropdown({ onChange, value, error, label, name }: Props) {
  return (
    <EnumDropdown
      onChange={onChange}
      options={options}
      value={value}
      error={error}
      label={label}
      name={name ?? "cpu-strategy-dropdown"}
    />
  );
}

export default CpuStrategyDropdown;
