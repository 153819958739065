import React, { useRef, useCallback, useMemo } from "react";
import classNames from "classnames";

import Text from "../../atoms/text/Text";
import Icon, { IconType } from "../../atoms/icon/Icon";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import EditableText, {
  Handles as EditableTextHandles,
} from "../../atoms/text/EditableText";
import Clickable from "../../atoms/clickable/Clickable";
import { Noop } from "../../../constants/functions";

interface Props {
  label: string;
  icon?: IconType;
  footerView?: React.ReactNode;
  onUpdateField?: (field: string) => (value: string) => void;
}

const SelectedView: React.FC<Props> = ({
  label,
  icon,
  footerView,
  onUpdateField,
}) => {
  const nameRef = useRef<EditableTextHandles>(null);

  const updateNameField = useCallback(
    (value: string) => {
      return onUpdateField && onUpdateField("name")(value);
    },
    [onUpdateField],
  );

  const labelComponent = useMemo(() => {
    return onUpdateField ? (
      <InlineGroup spaceBetweenElements={2} verticalCenter>
        <EditableText
          type="text"
          className={"item-label"}
          ref={nameRef}
          bold
          size="xl"
          value={label}
          onChange={updateNameField ?? Noop}
        />
        <Clickable onClick={() => nameRef.current?.setIsEditing(true)}>
          <Icon
            noMargin
            type="edit"
            tip={{ content: "Edit Name", id: "edit-name" }}
          />
        </Clickable>
      </InlineGroup>
    ) : (
      <Text className={"item-label"} size="xl" bold>
        {label}
      </Text>
    );
  }, [label, onUpdateField, updateNameField]);

  return (
    <>
      <InlineGroup
        verticalCenter
        center
        className={classNames("drop-area-header")}
      >
        <InlineGroup verticalCenter>
          {icon && <Icon size={16} type={icon} colour="white" />}
          {labelComponent}
        </InlineGroup>
      </InlineGroup>
      <InlineGroup center verticalCenter stretch block>
        {footerView}
      </InlineGroup>
    </>
  );
};

export default SelectedView;
