import classNames from "classnames";
import React, { CSSProperties } from "react";
import Clickable from "../../atoms/clickable/Clickable";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";

import "./ClickableBox.scss";

interface Props {
  onClick: () => void;
  active: boolean;
  style?: CSSProperties;
}

const ClickableBox: React.FC<Props> = ({
  children,
  onClick,
  active,
  style,
}) => {
  return (
    <Clickable onClick={onClick} as="div">
      <VerticalGroup
        spaceBetweenElements={2}
        className={classNames("clickable-box", { active })}
        style={style}
      >
        {children}
      </VerticalGroup>
    </Clickable>
  );
};

export default ClickableBox;
