import React from "react";
import { useCallback } from "react";
import { useMemoRequest } from "../../../../hooks/useMemoRequest";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import ModelAPI from "../../../../services/modelApi";
import { Accordion } from "../../../organisms/accordion/accordion";
import NPS from "./NPS";
import ProductBalances from "./ProductBalances";
import Taxation from "./Taxation";
import NonProductBalanceSheet from "./NonProductBalanceSheet";
import CustomerNumbers from "./CustomerNumbers";
import Revenue from "./Revenue";
import DivisionalCosts from "./DivisionalCosts";
import CustomerAttrition from "./CustomerAttrition";
import TransferPricing from "./TransferPricing";
import DivisionalProfitAndLoss from "./DivisionalProfitAndLoss";
import NimSculpting from "./NimSculpting";
import NonFinancialCalculations from "./NonFinancialCalculations";
import NonFinancialMetrics from "./NonFinancialMetrics";
import InterestOnCash from "./InterestOnCash";
import ModelPageContainer from "../ModelPageContainer";
import useModelQuery from "../utils/useModelQuery";
import SpecialProjects from "./SpecialProjects";
import ExcoDecisions from "./ExcoDecisions";
import DealCalculations from "./DealCalculations";
import OtherCosts from "./OtherCosts";

interface Props {
  eventId: string;
  configuration: ModelAPI.ConfigurationResponse;
  selectedTeam: number;
  selectedRound: number;
}

const ModelTeamResults: React.FC<Props> = ({
  eventId,
  configuration,
  selectedTeam,
  selectedRound,
}) => {
  const getTeamResults = useCallback(() => {
    return ModelAPI.getModelTeamResults(eventId, selectedRound, selectedTeam);
  }, [eventId, selectedRound, selectedTeam]);

  const { inProgress, data, error, refresh } =
    useMemoRequest<ModelAPI.TeamResultsResponse>(getTeamResults);

  const { expandIfSection } = useModelQuery();

  return (
    <ModelPageContainer
      eventId={eventId}
      selectedRound={selectedRound}
      data={data}
      refresh={refresh}
      error={error}
      inProgress={inProgress}
    >
      {data && (
        <VerticalGroup spaceBetweenElements={2} wide>
          <Accordion title="Special Projects" defaultState="collapse" wide>
            <SpecialProjects
              configuration={configuration}
              data={data.specialProjects}
            />
          </Accordion>
          <Accordion title="Deal Calculations" defaultState="collapse" wide>
            <DealCalculations
              configuration={configuration}
              data={data.dealCalculations}
            />
          </Accordion>
          <Accordion title="Executive Decisions" defaultState="collapse" wide>
            <ExcoDecisions
              configuration={configuration}
              data={data.excoDecisions}
            />
          </Accordion>
          <Accordion title="NPS" defaultState="collapse" wide>
            <NPS configuration={configuration} data={data.nps} />
          </Accordion>
          <Accordion title="Customer Attrition" defaultState="collapse" wide>
            <CustomerAttrition
              configuration={configuration}
              data={data.customerAttrition}
            />
          </Accordion>
          <Accordion title="Taxation" defaultState="collapse" wide>
            <Taxation configuration={configuration} data={data.taxation} />
          </Accordion>
          <Accordion title="Product Balances" defaultState="collapse" wide>
            <ProductBalances
              configuration={configuration}
              data={data.productBalances}
            />
          </Accordion>
          <Accordion
            title="Non-Product Balance Sheet"
            defaultState="collapse"
            wide
          >
            <NonProductBalanceSheet
              configuration={configuration}
              data={data.nonProductBalanceSheet}
            />
          </Accordion>
          <Accordion title="Customer Numbers" defaultState="collapse" wide>
            <CustomerNumbers
              configuration={configuration}
              data={data.customerNumbers}
            />
          </Accordion>
          <Accordion title="Revenue" defaultState="collapse" wide>
            <Revenue configuration={configuration} data={data.revenue} />
          </Accordion>
          <Accordion title="Divisional Costs" defaultState="collapse" wide>
            <DivisionalCosts
              configuration={configuration}
              data={data.divisionalCosts}
            />
          </Accordion>
          <Accordion title="Other Costs" defaultState="collapse" wide>
            <OtherCosts configuration={configuration} data={data.otherCosts} />
          </Accordion>
          <Accordion title="Interest On Cash" defaultState="collapse" wide>
            <InterestOnCash
              configuration={configuration}
              data={data.interestOnCash}
            />
          </Accordion>
          <Accordion
            title="Transfer Pricing"
            defaultState={expandIfSection("transferPricing")}
            wide
          >
            <TransferPricing
              configuration={configuration}
              data={data.transferPricing}
            />
          </Accordion>
          <Accordion title="Divisional P&L" defaultState="collapse" wide>
            <DivisionalProfitAndLoss
              configuration={configuration}
              data={data.divisionalProfitAndLoss}
            />
          </Accordion>
          <Accordion
            title="Net Interest Margin Sculpting"
            defaultState="collapse"
            wide
          >
            <NimSculpting
              configuration={configuration}
              data={data.nimSculpting}
            />
          </Accordion>
          <Accordion
            title="Non-Financial Calculations"
            defaultState="collapse"
            wide
          >
            <NonFinancialCalculations
              configuration={configuration}
              data={data.nonFinancialCalculations}
            />
          </Accordion>
          <Accordion title="Non-Financial Metrics" defaultState="collapse" wide>
            <NonFinancialMetrics
              configuration={configuration}
              data={data.nonFinancialMetrics}
            />
          </Accordion>
        </VerticalGroup>
      )}
    </ModelPageContainer>
  );
};

export default ModelTeamResults;
