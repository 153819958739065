import { useCallback, useEffect, useMemo } from "react";
import API from "../services/api";
import useAPIRequest from "./useAPIRequest";

const MAX_AGE = 500; // ms
const BRANDING_CACHE = new Map<string, Promise<API.BrandingResponse>>();

export const useBranding = (brandingId: string) => {
  const callback = useCallback(() => {
    let cachedPromise = BRANDING_CACHE.get(brandingId);
    if (cachedPromise) {
      return cachedPromise;
    }

    cachedPromise = API.getBranding(brandingId);

    BRANDING_CACHE.set(brandingId, cachedPromise);
    setTimeout(() => {
      BRANDING_CACHE.delete(brandingId);
    }, MAX_AGE);

    return cachedPromise;
  }, [brandingId]);

  const [{ inProgress, data, error }, doAPIRequest] =
    useAPIRequest<API.BrandingResponse>(callback, null);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  return useMemo(
    () => ({
      inProgress,
      data,
      error,
      refresh: doAPIRequest,
    }),
    [inProgress, data, error, doAPIRequest],
  );
};

const useBrandings = () => {
  const callback = useCallback(() => {
    return API.getBrandings();
  }, []);

  const [{ inProgress, completed, data, error }, doAPIRequest] = useAPIRequest<
    API.BrandingResponse[]
  >(callback, { data: [] });

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  const refresh = useCallback(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  return useMemo(
    () => ({
      inProgress,
      completed,
      data,
      error,
      refresh,
    }),
    [inProgress, completed, data, error, refresh],
  );
};

export default useBrandings;
