import React from "react";
import VirtualMeetingIntro from "../components/VirtualMeetingIntro";
import meetMute from "../../../../assets/results/googlemeetOne.png";
import meetStart from "../../../../assets/results/googlemeetstart.png";
import meetChat from "../../../../assets/results/googlemeetTwo.png";
import meetHandChat from "../../../../assets/results/googlemeetThree.png";
import meetVideo from "../../../../assets/results/googlemeetFour.png";

const IntroGoogleMeet = () => {
  const steps = [
    {
      text: "Please minimize background noise by using mute.",
      imageOverride: meetMute,
    },
    {
      text: "Feel free to use the chat function for questions / comments.",
      imageOverride: meetChat,
    },
    {
      text: "Raise your hand if you need our assistance.",
      imageOverride: meetHandChat,
    },
    {
      text: "We'd love to see you on camera, however, turn off cameras during videos.",
      imageOverride: meetVideo,
    },
  ];

  return (
    <VirtualMeetingIntro
      defaultHeading="Google Meet Logistics"
      image={meetStart}
      steps={steps}
    />
  );
};

export default IntroGoogleMeet;
