import React from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../../tables/TableWithSections";
import pluckResultsByType from "../../utils/pluckResultsByType";
import {
  formatLossMultiplier,
  formatValue,
  percentage,
} from "../../utils/formatters";
import { startCase } from "lodash";
import useModelQuery from "../../utils/useModelQuery";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["wholesaleFundingCosts"];
  fields: Array<{ field: string; name: string }>;
}

const fieldMap: { [key: string]: string } = {
  centralBankFacility: "CBF",
  wsf1: "1 Year",
  wsf2: "2 Year",
  wsf3: "3 Year",
  wsf4: "4 Year",
  wsf5: "5 Year",
};

const formatter = (
  val: number,
  section: string | undefined,
  field: string,
): string | null => {
  if (field === "referenceRate") {
    return percentage(val);
  }
  if (field === "weightedAverageLargeDebtIssuePremium") {
    return percentage(val, 2);
  }
  return formatValue(val, 0);
};

const LargeDebtIssuePremiums: React.FC<Props> = ({ data }) => {
  const values = pluckResultsByType(data, "largeDebtIssuePremiums");
  const { openIfTable } = useModelQuery();
  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <TableWithSections
          hasRoundZero
          header="Large Debt Issue Premiums"
          openOnInit={openIfTable("largeDebtIssuePremiums")}
          data={[
            {
              name: "Wholesale Funding Appetite",
              fields: [
                "lendingAssetGrowth.HOME_LOANS.propietaryHomeLoans",
                "lendingAssetGrowth.HOME_LOANS.brokerHomeLoans",
                "lendingAssetGrowth.BUSINESS_LOANS",
                "lendingAssetGrowth.CREDIT_CARDS",
                "lendingAssetGrowth.INSTITUTIONAL_BANKING",
                "lendingAssetGrowth.total",
                "marketGrowth",
                "annualExpectedIssue",
              ],
              totalFields: [
                "lendingAssetGrowth.total",
                "marketGrowth",
                "annualExpectedIssue",
              ],
              values: pluckResultsByType(values, "wholesaleFundingAppetite"),
              fieldFormatter: (field: string) => fieldMap[field],
              formatter: (val: number, field: string) =>
                field === "marketGrowth"
                  ? percentage(val)
                  : formatValue(val, 0),
            },
            {
              name: "Market Expectations",
              fields: ["band1", "band2", "band3", "band4", "band5", "band6"],
              values: pluckResultsByType(values, "marketExpectations"),
              fieldFormatter: (field: string) =>
                `${startCase(field)} (${formatLossMultiplier(values[0].marketExpectations.largeIssueAmountFrom[field])})`,
            },
            {
              name: "Allocated Player Issued Amount Into Banks",
              fields: [
                "playerWholesaleFundingRaised",
                "band1",
                "band2",
                "band3",
                "band4",
                "band5",
                "band6",
                "band0",
                "total",
                "variance",
                "weightedAverageLargeDebtIssuePremium",
              ],
              totalFields: [
                "total",
                "variance",
                "weightedAverageLargeDebtIssuePremium",
              ],
              values: pluckResultsByType(
                values,
                "allocatedPlayerIssuedAmountIntoBands",
              ),
              fieldFormatter: (field: string) => {
                if (field.startsWith("band")) {
                  const bandConfig =
                    values[0].allocatedPlayerIssuedAmountIntoBands.bandConfig[
                      field
                    ];
                  return `${startCase(field)} (${percentage(bandConfig.incrementalPremium)} - ${formatLossMultiplier(bandConfig.largeIssueAmountFrom)})`;
                }
                return null;
              },
            },
          ]}
          formatter={formatter}
        />
      </VerticalGroup>
    </>
  );
};

export default LargeDebtIssuePremiums;
