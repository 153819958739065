import React from "react";
import RoundResultsWholesaleFundingCostsAllRatings from "./RoundResultsWholesaleFundingCostsAllRatings";
import RoundResultsWholesaleFundingCostsARatings from "./RoundResultsWholesaleFundingCostsARatings";

interface Props {
  pastRound1: boolean;
  wholesaleFundingCostsAllRatings: ModelAPI.Reporting.DataAndPositions | null;
  wholesaleFundingCostsARatings: ModelAPI.Reporting.DataAndPositions | null;
}

const RoundResultsWholesaleFundingCosts: React.FC<Props> = ({
  pastRound1,
  wholesaleFundingCostsAllRatings,
  wholesaleFundingCostsARatings,
}) => {
  if (
    wholesaleFundingCostsAllRatings != null &&
    wholesaleFundingCostsAllRatings.data.length > 0
  ) {
    return (
      <RoundResultsWholesaleFundingCostsAllRatings
        pastRound1={pastRound1}
        wholesaleFundingCostsAllRatings={wholesaleFundingCostsAllRatings}
      />
    );
  } else if (
    wholesaleFundingCostsARatings != null &&
    wholesaleFundingCostsARatings.data.length > 0
  ) {
    return (
      <RoundResultsWholesaleFundingCostsARatings
        pastRound1={pastRound1}
        wholesaleFundingCostsARatings={wholesaleFundingCostsARatings}
      />
    );
  }
  return null;
};

export default RoundResultsWholesaleFundingCosts;
