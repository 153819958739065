import React from "react";

export type SimulationStepTitle =
  | "General"
  | "Players"
  | "Economy And Other"
  | "Market"
  | "Businesses"
  | "Deal Room"
  | "Executive Decisions"
  | "Summary Screen"
  | "Financial Metrics"
  | "Special Projects"
  | "Resources"
  | "Labels"
  | "Help"
  | "Custom Settings"
  | "Preview";
interface StepMeta {
  title: SimulationStepTitle;
  content: React.ReactNode;
  /**
   * The key in the state.config that this step stores with
   */
  key: string;
  roleRequired?: API.Role[];
}

export interface SimulationState {
  steps: StepMeta[];
  config?: API.SimulationResponse;
  formStatus: {
    inProgress: boolean;
    formUpdated: boolean;
  };
  /**
   * The function which will submit the form. If it returns false,
   * that means a form error
   */
  onSubmit: () => Promise<false | void>;
  isJumpStepModalOpen: boolean;
  jumpStepModalConfirmAction?: () => void;
}

/**
 * Action
 */
interface UpdateConfig {
  type: "UpdateConfig";
  payload: API.SimulationResponse;
}
interface AssignOnSubmitFunction {
  type: "AssignOnSubmitFunction";
  payload: () => Promise<false | void>;
}
interface UpdateFormStatus {
  type: "UpdateFormStatus";
  payload: SimulationState["formStatus"];
}

interface SetJumpStepModalStatus {
  type: "SetJumpStepModalStatus";
  payload: {
    isJumpStepModalOpen: boolean;
    jumpStepModalConfirmAction?: () => void;
  };
}

export type Actions =
  | UpdateConfig
  | AssignOnSubmitFunction
  | UpdateFormStatus
  | SetJumpStepModalStatus;

/**
 * Reducer
 */

export const reducer: React.Reducer<SimulationState, Actions> = (
  state,
  action,
) => {
  switch (action.type) {
    case "UpdateConfig":
      return {
        ...state,
        config: action.payload,
      };
    case "AssignOnSubmitFunction":
      return {
        ...state,
        onSubmit: action.payload,
      };
    case "UpdateFormStatus":
      return {
        ...state,
        formStatus: action.payload,
      };
    case "SetJumpStepModalStatus":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
