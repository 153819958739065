import React from "react";
import ResultsText, { ResultsTextProps } from "./ResultsText";
import { useResultsContext } from "../context/context";

const DynamicResultsText: React.FC<ResultsTextProps> = (props) => {
  const context = useResultsContext();
  return (
    <ResultsText
      {...props}
      sizeMultiplier={context.ignoreFontMultiplier ? 1 : context.fontMultiplier}
    />
  );
};

export default DynamicResultsText;
