import React, { useMemo, useCallback } from "react";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Image from "../../../atoms/image/Image";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import { useResultsContext } from "../context/context";
import image1 from "../../../../assets/results/ceoChangeVirtual1.gif";
import image2 from "../../../../assets/results/ceoChangeVirtual2.gif";
import image3 from "../../../../assets/results/ceoChangeVirtual3.gif";
import CeoTransferCircle from "../components/CeoTransferCircle";
import DynamicResultsText from "../results-text/DynamicResultsText";
import EditableReportingHeading from "../components/EditableReportingHeading";
import ReportingSubHeading from "../components/ReportingSubHeading";

interface Props {}

const content: Array<{ text: string[]; image: string | null }> = [
  {
    text: [
      "Each round, a new CEO will take ultimate accountability for the decisions being made.",
      "Upon entering the interface, the CEO will see the following option:",
    ],
    image: image1,
  },
  {
    text: [
      "You will be given the option to transfer the CEO role to a different person.",
    ],
    image: image2,
  },
  {
    text: [
      "You will be given the option to transfer the CEO role to a different person.",
    ],
    image: image3,
  },
  { text: ["You have successfully transferred CEO!"], image: null },
];

export const maxClicks = 3;

const RoundResultsCeoTransferVirtual: React.FC<Props> = () => {
  const context = useResultsContext();

  const currentContent = useMemo(() => {
    return content[Math.min(context.clicks, content.length - 1)];
  }, [context.clicks]);

  const onNext = useCallback(() => {
    if (context.clicks === maxClicks) {
      context.goNextPage();
    } else {
      context.addClick();
    }
  }, [context]);

  return (
    <RoundResultsContainer onNext={onNext}>
      <VerticalGroup full center wide verticalCenter spaceBetweenElements={4}>
        <VerticalGroup spaceBetweenElements={2} center wide>
          <EditableReportingHeading defaultHeading="Changing CEO's" />
          <ReportingSubHeading subHeading="How to change CEO" />
        </VerticalGroup>
        <InlineGroup
          block
          spaceBetweenElements={2}
          fullHeight
          evenWidthChildren
          verticalCenter
        >
          <CeoTransferCircle
            type="virtual"
            showPlayer1={context.clicks <= 2}
            showPlayer2={context.clicks > 2}
          />
          <VerticalGroup spaceBetweenElements={4} wide full className="pt-24">
            <VerticalGroup spaceBetweenElements={4}>
              {currentContent.text.map((t, i) => (
                <DynamicResultsText key={i} size="lg">
                  {t}
                </DynamicResultsText>
              ))}
            </VerticalGroup>
            {currentContent.image && (
              <Image
                dynamicSize={{ min: "400px", preferred: "45vw", max: "1800px" }}
                src={currentContent.image}
                alt="CEO Image"
                width="100%"
              />
            )}
          </VerticalGroup>
        </InlineGroup>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsCeoTransferVirtual;
