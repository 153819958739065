import React, { useState, useEffect } from "react";
import Text from "../../../atoms/text/Text";
import { formatTime } from "../../../../lib/date";

const CurrentTimeDisplay: React.FC = () => {
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Text colour="white" size="lg">
      Currently: {formatTime(currentDate)}
    </Text>
  );
};

export default CurrentTimeDisplay;
