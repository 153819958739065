import React, { useState, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";

import IconButton from "../../../../molecules/iconbutton/IconButton";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import RoleRequired from "../../../../molecules/role-required/RoleRequired";
import ToggleEvent from "./ToggleEvent";
import DeleteEvent from "./DeleteEvent";
import { EventContext } from "../context";
import { Noop } from "../../../../../constants/functions";
import FacilitatorHidden from "../../../../molecules/facilitator-hidden/FacilitatorHidden";

const EventOptionsContext = React.createContext<{
  data: API.EventResponse | null;
  refresh: () => void;
}>({ data: null, refresh: Noop });
EventOptionsContext.displayName = "EventOptionsContext";

interface Props {
  showDangerous?: boolean;
}

const EventOptions: React.FC<Props> = ({ showDangerous }) => {
  const {
    config: data,
    refreshConfig: refresh,
    formMode,
  } = useContext(EventContext);
  const history = useHistory();

  const [showEventModal, setShowEventModal] = useState<boolean>(false);
  const [showDeleteEventModal, setShowDeleteEventModal] =
    useState<boolean>(false);

  const onEventToggleComplete = useCallback(() => {
    setShowEventModal(false);
    refresh();
  }, [refresh]);

  const onEventDeleteComplete = useCallback(() => {
    setShowDeleteEventModal(false);
    history.push("/events");
  }, [history]);

  if (!data) {
    return null;
  }

  const { id: eventId } = data;

  if (formMode === "clone") {
    return null;
  }

  return (
    <>
      <InlineGroup spread spaceBetweenElements={2}>
        {showDangerous && (
          <>
            <FacilitatorHidden>
              <IconButton
                danger
                data-test={`delete-${eventId}`}
                icon="trash"
                text="Delete"
                onClick={() => setShowDeleteEventModal(true)}
              />
            </FacilitatorHidden>
            <FacilitatorHidden>
              <IconButton
                data-test={`clone-event-${eventId}`}
                icon="clone"
                text="Clone Event"
                linkTo={`/events/${eventId}/clone`}
              />
            </FacilitatorHidden>
            <IconButton
              data-test={`toggle-${eventId}`}
              icon={data.closed ? "lockOpen" : "lockClosed"}
              text={data.closed ? "Open Event" : "Close Event"}
              onClick={() => setShowEventModal(true)}
            />
          </>
        )}

        <RoleRequired roles={["superadmin"]}>
          <IconButton
            data-test={`model-event-${eventId}`}
            icon="preview"
            text="Debug Model"
            linkTo={`/events/${eventId}/debug`}
          />
        </RoleRequired>
      </InlineGroup>
      <ToggleEvent
        isOpen={showEventModal}
        onClose={() => setShowEventModal(false)}
        onComplete={onEventToggleComplete}
        event={data}
      />
      <DeleteEvent
        isOpen={showDeleteEventModal}
        onClose={() => setShowDeleteEventModal(false)}
        onComplete={onEventDeleteComplete}
        event={data}
      />
    </>
  );
};

export default EventOptions;
