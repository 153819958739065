import React from "react";
import Checkbox from "../../../../../atoms/form/input/Checkbox";
import { Noop } from "../../../../../../constants/functions";

interface Props {
  question: API.AssessmentQuestionResponse;
  field: API.MultipleChoiceFieldResponse;
  answer?: API.AssessmentAnswerResponse["answers"][0]["answer"];
  isAnswerMode?: boolean;
}

export const MultipleChoiceFieldPreview: React.FC<Props> = ({
  field,
  question,
  answer,
  isAnswerMode = false,
}) => {
  const isAnswerMatchThisField = answer?.some(
    ({ fieldId }) => fieldId === field.id,
  );
  const isWrongAnswer =
    isAnswerMatchThisField && isAnswerMode && !field.config.markCorrect;

  return (
    <Checkbox
      name={question.id}
      isLabelHtml
      label={
        !isAnswerMode
          ? field.config.label
          : `${field.config.label} ${field.config.mark ?? -1 > 0 ? `(${field.config.mark} points)` : ""}`
      }
      onChange={Noop}
      disabled={(answer?.length ?? 0) > 0 || isAnswerMode}
      checked={isAnswerMatchThisField}
      colour={
        isAnswerMode
          ? field.config.markCorrect
            ? "green"
            : isWrongAnswer
              ? "danger"
              : "primaryDark"
          : "primaryDark"
      }
      status={!isAnswerMode ? "normal" : isWrongAnswer ? "wrong" : "correct"}
    />
  );
};
