import React, { useMemo } from "react";
import ReactTooltip from "react-tooltip";

import PrivateRouter from "./Router";
import PublicRouter from "./RouterPublic";

import userContext, { useUserContext } from "./context/userContext";
import "./App.scss";

function App() {
  const [state, setToken, logout] = useUserContext();

  const contextValue = useMemo(
    () => ({
      user: state.user,
      setToken,
      logout,
    }),
    [state, setToken, logout],
  );

  if (state.loading) {
    return null;
  }

  return (
    <>
      <userContext.Provider value={contextValue}>
        {state.user ? <PrivateRouter /> : <PublicRouter />}
      </userContext.Provider>
      <ReactTooltip
        effect="solid"
        textColor="black"
        backgroundColor="white"
        borderColor="black"
        border
      />
    </>
  );
}

export default App;
