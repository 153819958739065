import React from "react";

import Card from "../../../../atoms/card/Card";
import DisplayField from "../../../../atoms/form/display-field/DisplayField";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import Clickable from "../../../../atoms/clickable/Clickable";
import Icon from "../../../../atoms/icon/Icon";
import Text from "../../../../atoms/text/Text";
import { useExcoConfig } from "../../../../../hooks/useExcoConfig";
import AvoidPageBreak from "../../../../atoms/printable/components/AvoidPageBreak";

interface Props {
  data?: API.SimulationResponse;
  onClickEdit?: () => void;
}

const Display: React.FC<Props> = ({ data, onClickEdit }) => {
  const { data: decisions } = useExcoConfig();
  return data?.excoDecisionSetup && decisions ? (
    <Card wide>
      <AvoidPageBreak>
        <InlineGroup verticalCenter>
          <h4>Executive Team Decision Settings</h4>
          {onClickEdit ? (
            <Clickable
              data-test={"edit-simulation-exco-decision-step"}
              onClick={onClickEdit}
            >
              <Icon
                type="edit"
                tip={{
                  content: "Edit Executive Team Decisions",
                  id: "edit-exco-decision",
                }}
              />
            </Clickable>
          ) : null}
        </InlineGroup>
        {!data.excoDecisionSetup.enabled && <Text size="sm">Disabled</Text>}
        {data.excoDecisionSetup.enabled &&
          data.excoDecisionSetup.excoDecisions.map(
            (decision: string, i: number) => {
              if (i >= data.generalSetup.rounds) return null;
              return (
                <DisplayField
                  key={decision}
                  label={`Round #${i + 1}`}
                  value={
                    decisions.find((d) => d.type === decision)?.name ?? "None"
                  }
                />
              );
            },
          )}
      </AvoidPageBreak>
    </Card>
  ) : null;
};

export default Display;
