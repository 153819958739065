import React, { useCallback, useState } from "react";
import SimpleModal from "../../../../../organisms/standard-modal/SimpleModal";
import Container from "../../../../../atoms/page/Page";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import Text from "../../../../../atoms/text/Text";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import InformationPopup from "../../../../../organisms/information-popup/InformationPopup";
import { EditingTextBody } from "./InfoPopUpText";
import { PreviewSettings } from "../types";
import CountryDropdown from "../../../../simulations/steps/region/CountryDropdown";
import { presets } from "../../../../simulations/steps/region";
import CentralBankDropdown from "../../../../simulations/steps/region/CentralBankDropdown";
import CurrencyDropdown from "../../../../simulations/steps/region/CurrencyDropdown";
import ForthProductDropDown from "../../../../simulations/steps/region/ForthProductDropDown";
import ReferenceRateDropdown from "../../../../simulations/steps/region/ReferenceRateDropdown";
import RegulatorDropdown from "../../../../simulations/steps/region/RegulatorDropdown";
import Button from "../../../../../atoms/button/Button";
interface Props {
  isOpen: boolean;
  onClose: () => void;
  data: PreviewSettings;
  handleSettingsChange: (updatedSettings: PreviewSettings) => void;
}

export const EditSettingsModal = ({
  isOpen,
  onClose,
  data,
  handleSettingsChange,
}: Props) => {
  const [previewSettings, setPreviewSettings] = useState<PreviewSettings>(data);

  const handleCountryChange = useCallback(
    (selectedValue: { value: API.Country; label: string }) => {
      const preset = presets[selectedValue.value];
      const updatedSettings: PreviewSettings = {
        ...previewSettings,
        referenceRate: preset.referenceRate,
        centralBank: preset.centralBank,
        regulator: preset.regulator,
        currency: preset.currency,
        country: selectedValue.value,
      };

      setPreviewSettings(updatedSettings);
    },
    [previewSettings],
  );

  const handleFieldChange = useCallback(
    (field: keyof PreviewSettings) => (dat: { value: any }) => {
      setPreviewSettings({
        ...previewSettings,
        [field]: dat.value,
      });
    },
    [previewSettings],
  );

  const handleSaveClick = useCallback(() => {
    handleSettingsChange(previewSettings);
    onClose();
  }, [handleSettingsChange, onClose, previewSettings]);

  return (
    <SimpleModal isOpen={isOpen} size="medium" onClose={onClose}>
      <Container fit className="simulation-edit">
        <VerticalGroup spaceBetweenElements={4}>
          <VerticalGroup spaceBetweenElements={4}>
            <InlineGroup verticalCenter spaceBetweenElements={2}>
              <Text size="xl" bold>
                Edit Preview Settings
              </Text>
              <InformationPopup title={"Editing Tip"} body={EditingTextBody} />
            </InlineGroup>
            <VerticalGroup spaceBetweenElements={2}>
              <VerticalGroup verticalCenter spaceBetweenElements={2}>
                <Text>Country</Text>
                <CountryDropdown
                  onChange={handleCountryChange}
                  value={previewSettings.country}
                />
              </VerticalGroup>
              <InlineGroup spaceBetweenElements={6}>
                <VerticalGroup spaceBetweenElements={2}>
                  <Text>Currency</Text>
                  <CurrencyDropdown
                    onChange={handleFieldChange("currency")}
                    value={previewSettings.currency}
                  />
                </VerticalGroup>
                <VerticalGroup spaceBetweenElements={2}>
                  <Text>Central Bank</Text>
                  <CentralBankDropdown
                    onChange={handleFieldChange("centralBank")}
                    value={previewSettings.centralBank}
                  />
                </VerticalGroup>
              </InlineGroup>
              <InlineGroup spaceBetweenElements={6}>
                <VerticalGroup spaceBetweenElements={2}>
                  <Text>Regulator</Text>
                  <RegulatorDropdown
                    onChange={handleFieldChange("regulator")}
                    value={previewSettings.regulator}
                  />
                </VerticalGroup>
                <VerticalGroup spaceBetweenElements={2}>
                  <Text>Reference Rate</Text>
                  <ReferenceRateDropdown
                    onChange={handleFieldChange("referenceRate")}
                    value={previewSettings.referenceRate}
                  />
                </VerticalGroup>
              </InlineGroup>
              <InlineGroup>
                <VerticalGroup spaceBetweenElements={2}>
                  <Text>4th Product</Text>
                  <ForthProductDropDown
                    onChange={handleFieldChange("product")}
                    value={previewSettings.product}
                  />
                </VerticalGroup>
              </InlineGroup>
            </VerticalGroup>
          </VerticalGroup>
          <InlineGroup block right>
            <Button onClick={handleSaveClick}>Save</Button>
          </InlineGroup>
        </VerticalGroup>
      </Container>
    </SimpleModal>
  );
};

export default EditSettingsModal;
