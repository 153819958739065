import { useCallback, useEffect, useMemo } from "react";
import API from "../services/api";
import useAPIRequest, { useAPIRequestRunner } from "./useAPIRequest";

const MAX_AGE = 500; // ms
const TEMPLATE_CACHE = new Map<
  string,
  Promise<API.ReportingTemplateResponse>
>();

export const useReportingTemplate = (reportingTemplateId: string) => {
  const callback = useCallback(() => {
    let cachedPromise = TEMPLATE_CACHE.get(reportingTemplateId);
    if (cachedPromise) {
      return cachedPromise;
    }

    cachedPromise = API.getReportingTemplate(reportingTemplateId);

    TEMPLATE_CACHE.set(reportingTemplateId, cachedPromise);
    setTimeout(() => {
      TEMPLATE_CACHE.delete(reportingTemplateId);
    }, MAX_AGE);

    return cachedPromise;
  }, [reportingTemplateId]);

  const [{ inProgress, data, error }, doAPIRequest] =
    useAPIRequest<API.ReportingTemplateResponse>(callback, null);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  return useMemo(
    () => ({
      inProgress,
      data,
      error,
      refresh: doAPIRequest,
    }),
    [inProgress, data, error, doAPIRequest],
  );
};

export const useReportingTemplateList = (
  clientIds: string[],
  isClientIdLimited = false,
) => {
  const {
    state: { inProgress, data, error },
    run,
  } = useAPIRequestRunner<API.ReportingTemplateResponse[]>();

  const doApiRequest = useCallback(() => {
    run(API.getReportingTemplates(clientIds));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...clientIds, run]);

  useEffect(() => {
    if (isClientIdLimited && clientIds.length > 0) {
      doApiRequest();
    } else if (isClientIdLimited === false) {
      doApiRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...clientIds, doApiRequest, isClientIdLimited, run]);

  return useMemo(
    () => ({
      inProgress,
      data,
      error,
      refresh: doApiRequest,
    }),
    [inProgress, data, error, doApiRequest],
  );
};

export default useReportingTemplate;
