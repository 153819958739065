import React, { useCallback } from "react";
import Container from "../../../atoms/page/Page";
import SimpleModal from "../../../organisms/standard-modal/SimpleModal";
import useDimensions from "../../../../hooks/useDimensions";
import { RENDERED_DIMENSIONS } from "./ReportingPagePreviewTile";
import useIsMobile from "../../../../hooks/useIsMobile";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  headingHeight?: number;
  children(response: {
    previewHeight: number;
    previewWidth: number;
    availableHeight: number;
    availableWidth: number;
  }): React.ReactNode;
}

const containerPadding = 1;
const innerContainerPadding = 1;
const ReportingTemplatePreviewModal: React.FC<Props> = ({
  isOpen,
  onClose,
  headingHeight = 0,
  children,
}) => {
  const isMobile = useIsMobile();
  const [
    { previewHeight, previewWidth, availableHeight, availableWidth },
    setContainerRef,
  ] = useDimensions(
    headingHeight,
    containerPadding,
    innerContainerPadding,
    RENDERED_DIMENSIONS,
  );

  const containerRef = useCallback(
    (node: HTMLElement | null) => {
      if (node !== null) {
        setContainerRef(node);
      }
    },
    [setContainerRef],
  );

  return (
    <SimpleModal
      showMobileCloseButton
      noPadding={isMobile}
      size="scale"
      isOpen={isOpen}
      showCloseButton
      onClose={onClose}
    >
      {isMobile ? (
        <div ref={containerRef}>
          {children({
            previewHeight,
            previewWidth,
            availableHeight,
            availableWidth,
          })}
        </div>
      ) : (
        <Container
          noPadding
          ref={containerRef}
          className="reporting-template-preview"
        >
          {children({
            previewHeight,
            previewWidth,
            availableHeight,
            availableWidth,
          })}
        </Container>
      )}
    </SimpleModal>
  );
};

export default ReportingTemplatePreviewModal;
