import React, { useCallback, useEffect, useState } from "react";
import API from "../../../../services/api";
import useAPIRequest from "../../../../hooks/useAPIRequest";
import UserListTable from "./UserListTable";
import { shortDate } from "../../../../lib/date";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import Banner from "../../../atoms/banner/Banner";
import Container from "../../../atoms/page/Page";
import StickyBar from "../../../organisms/sticky-bar/StickyBar";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import IconButton from "../../../molecules/iconbutton/IconButton";
import Input from "../../../atoms/form/input/Input";
import Dropdown from "../../../atoms/form/input/Dropdown";
import Text from "../../../atoms/text/Text";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import ClientDropdown from "../../../organisms/client-dropdown/ClientDropdown";
import Icon from "../../../atoms/icon/Icon";
import Theme from "../../../../styles/_theme.module.scss";

const userTypes = [
  { label: "Superadmin", value: "superadmin" },
  { label: "Admin", value: "admin" },
  { label: "Standard", value: "standard" },
  { label: "Facilitator", value: "facilitator" },
];

export const STICKY_BAR_HEIGHT = 32;

function UserList() {
  const [filter, setFilter] = useState<{
    type?: string[] | undefined;
    clients?: string[] | undefined;
    status?: "active";
  }>({});
  const [search, setSearch] = useState("");
  const [searchedUsers, setSearchedUsers] = useState<API.UserResponse[] | null>(
    null,
  );

  const callback = useCallback(() => {
    return API.getUsers(filter);
  }, [filter]);

  const [{ inProgress, data, completed, error }, doAPIRequest] = useAPIRequest(
    callback,
    { data: [] },
  );

  useEffect(() => {
    const debounce = setTimeout(() => {
      if (!data || search === "") {
        return setSearchedUsers(null);
      }
      setSearchedUsers(
        data.filter(
          (d) =>
            d.email.toLowerCase().includes(search.toLowerCase()) ||
            `${d.firstName} ${d.lastName}`
              .toLowerCase()
              .includes(search.toLowerCase()),
        ),
      );
    }, 400);
    return () => clearTimeout(debounce);
  }, [search, data]);

  const handleExport = useCallback(() => {
    const date = new Date();
    API.exportUserList(`Banksim - User List - ${shortDate(date.toString())}`);
  }, []);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  const handleRefreshUsers = useCallback(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  return (
    <Container>
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      <StickyBar height={STICKY_BAR_HEIGHT} spaceBetweenElements={2}>
        <VerticalGroup wide>
          <InlineGroup spaceBetweenElements={2}>
            <IconButton
              data-test="add-user"
              linkTo="/administration/users/add"
              icon="addUser"
              text="Add User"
            />
            <IconButton
              onClick={handleExport}
              icon="export"
              text="Export List"
            />
          </InlineGroup>
          <InlineGroup spaceBetweenElements={2} className="mt-2" block>
            <Input
              data-test="user-search"
              type="text"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              block
            />
          </InlineGroup>
          <InlineGroup verticalCenter className="mt-2">
            <Icon height={16} width={16} type="filter" colour="blue" />
            <ClientDropdown
              noBorder
              isMulti
              placeholder="All Clients"
              colour={Theme.blue}
              onChange={(clients) =>
                setFilter((s) => ({
                  ...s,
                  clients: clients?.map(
                    (c: { label: string; value: string }) => c.value,
                  ),
                }))
              }
            />
            <Dropdown
              noBorder
              isMulti
              colour={Theme.blue}
              selectProps={{
                options: userTypes,
                placeholder: "All Types",
                onChange: (types: { label: string; value: string }[] | null) =>
                  setFilter((s) => ({
                    ...s,
                    type: types?.map((t) => t.value),
                  })),
              }}
            />
            <Input
              type="checkbox"
              name="active"
              checked={!!filter.status}
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  status: e.target.checked ? "active" : undefined,
                }))
              }
            />
            <Text size="sm" className="ml-2">
              Hide deactivated accounts
            </Text>
          </InlineGroup>
        </VerticalGroup>
      </StickyBar>
      {inProgress && <LoadingSpinner />}
      {completed && data && (
        <UserListTable
          users={searchedUsers || data}
          refreshUsers={handleRefreshUsers}
        />
      )}
    </Container>
  );
}

export default UserList;
