import React, { useCallback, useEffect, useState } from "react";
import useForm, { FormError } from "../../../../../hooks/useForm";
import { useSimulationContext } from "../../context";
import FinancialSummaryForm from "./FinancialSummaryForm";
import API from "../../../../../services/api";
import ErrorModal from "../../../../organisms/standard-modal/ErrorModal";
import Text from "../../../../atoms/text/Text";
import { pluralise } from "../../../../../lib/pluralise";

const FinancialSummary = () => {
  const { config, updateConfig, assignOnSubmitFunction, updateFormStatus } =
    useSimulationContext();
  const [validationError, setValidationError] = useState<{
    title: string;
    message: string;
  } | null>(null);
  const [numberOfMetrics, setNumberOfMetrics] = useState(
    config?.financialSummaryMetrics?.length || 6,
  );

  const [
    {
      formData: { financialSummaryMetrics },
      inProgress,
      formUpdated,
    },
    { handleSubmit, setFieldRaw },
  ] = useForm({
    financialSummaryMetrics: config?.financialSummaryMetrics ?? [],
  });

  const onSubmit = useCallback(async () => {
    if (config) {
      const payload: API.SimulationUpdateRequest = {
        financialSummaryMetrics,
      };
      const response = await API.editSimulation(config.id, payload);
      updateConfig(response);
      return response;
    }
  }, [config, updateConfig, financialSummaryMetrics]);

  const onMetricFieldUpdate = useCallback(
    (metricConfigId: string) => (field: string) => async (value: string) => {
      if (config) {
        const payload: API.SimulationUpdateRequest = {
          financialSummaryMetricConfig: (
            config.financialSummaryMetricConfig ?? []
          ).map((metricConfig) => {
            if (metricConfig.id === metricConfigId) {
              return {
                ...metricConfig,
                [field]: value,
              };
            }
            return metricConfig;
          }),
        };
        const response = await API.editSimulation(config.id, payload);
        updateConfig(response);
        return response;
      }
    },
    [config, updateConfig],
  );

  const validate = useCallback(() => {
    const errors: FormError[] = [];
    if (financialSummaryMetrics.length !== numberOfMetrics) {
      const metricsRemaining = numberOfMetrics - financialSummaryMetrics.length;
      const errorMessage = `${metricsRemaining} ${pluralise("metric", metricsRemaining)} still to be selected. Please select all ${numberOfMetrics} metrics`;
      errors.push({
        field: "financialSummaryMetrics",
        message: errorMessage,
      });
      setValidationError({ title: "Missing Metrics", message: errorMessage });
    }

    return errors;
  }, [financialSummaryMetrics, numberOfMetrics]);

  const onSummaryChange = useCallback(
    (metrics: API.SimulationUpdateRequest["financialSummaryMetrics"]) => {
      setFieldRaw("financialSummaryMetrics", metrics);
    },
    [setFieldRaw],
  );

  useEffect(() => {
    assignOnSubmitFunction(handleSubmit(onSubmit, validate));
  }, [assignOnSubmitFunction, handleSubmit, onSubmit, validate]);

  useEffect(() => {
    updateFormStatus({
      inProgress: inProgress,
      formUpdated: formUpdated,
    });
  }, [updateFormStatus, inProgress, formUpdated]);

  return (
    <div className="step-container">
      <FinancialSummaryForm
        defaultSelected={config?.financialSummaryMetrics}
        onSummaryChange={onSummaryChange}
        numberOfMetrics={numberOfMetrics}
        onNumberOfMetricsChange={setNumberOfMetrics}
        onMetricFieldUpdate={onMetricFieldUpdate}
        financialSummaryMetricConfig={config?.financialSummaryMetricConfig}
      />
      {validationError && (
        <ErrorModal
          isOpen={validationError != null}
          onClose={() => {
            setValidationError(null);
          }}
          title={validationError?.title}
          description={<Text>{validationError?.message}</Text>}
        ></ErrorModal>
      )}
    </div>
  );
};

export { default as FinancialScreenDisplay } from "./Display";
export default FinancialSummary;
