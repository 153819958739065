import React from "react";
import Dropdown from "../../atoms/form/input/Dropdown";

export const teamTabletColourOptions: API.TabletColour[] = [
  "red",
  "white",
  "yellow",
  "pink",
  "purple",
  "orange",
  "black",
  "blue",
  "green",
  "tan",
  "purple+pink",
  "black+blue",
  "grey+green",
  "green+pink",
];

interface TeamTabletColourDropdownProps {
  label: string;
  defaultColourId: string;
  onColourChange: (colourId: API.TabletColour) => void;
  omitColours?: API.TabletColour[];
}

const TeamTabletColourDropdown: React.FC<TeamTabletColourDropdownProps> = ({
  label,
  defaultColourId,
  onColourChange,
  omitColours,
}) => {
  const options = teamTabletColourOptions
    .filter((colour) => {
      if (omitColours) {
        return !omitColours.includes(colour);
      }
      return true;
    })
    .map((colour) => ({
      label: colour,
      id: colour,
    }));

  return (
    <Dropdown
      label={label}
      selectProps={{
        options: options,
        value: options.find((option) => option.id === defaultColourId),
        onChange: (option: any) => onColourChange(option.id),
      }}
    />
  );
};

export default TeamTabletColourDropdown;
