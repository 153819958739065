import React from "react";

import EnumDropdown, {
  getValueFunc,
} from "../../../../organisms/enum-dropdown/EnumDropdown";

interface Props {
  onChange: (selected: { value: string; label: string }) => void;
  value?: string;
  error?: string;
  label?: string;
  name?: string;
}

export const options = [
  "APRA",
  "RBNZ",
  "Fed",
  "PRA",
  "EBA",
  "OJK",
  "FSA",
  "CBIRC",
  "MAS",
].map((currency) => ({
  value: currency,
  label: currency,
}));

export const getLabelFromValue = getValueFunc(options);

function RegulatorDropdown({ onChange, value, error, label, name }: Props) {
  return (
    <EnumDropdown
      onChange={onChange}
      options={options}
      value={value}
      error={error}
      label={label}
      name={name ?? "regulator-dropdown"}
    />
  );
}

export default RegulatorDropdown;
