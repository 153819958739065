import React from "react";
import { useAssessmentInEvent } from "../../../../context/CurrentEventContext";

export const EventAssessmentBreadcrumb: React.FC = () => {
  const { assessment } = useAssessmentInEvent();

  if (!assessment) {
    return <>...</>;
  }

  return <>Assessment - {assessment.name}</>;
};
