import React, { useCallback, useState, useEffect } from "react";
import {
  parseTimeLeftFromSeconds,
  differenceISOTime,
  fullDateAndTime,
} from "../../../lib/date";
import API from "../../../services/api";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import Button from "../../atoms/button/Button";
import Icon from "../../atoms/icon/Icon";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import Text from "../../atoms/text/Text";
import TimerProgress from "./timer/TimerProgress";
import GameConnections from "./GameConnections";
import CircleIcon from "../../atoms/icon/CircleIcon";
import Card from "../../atoms/card/Card";
import ScheduledRoundForm from "./ScheduledRoundForm";
import "./timer/Timer.scss";
import useIsMobile from "../../../hooks/useIsMobile";
import classnames from "classnames";
import { Accordion } from "../../organisms/accordion/accordion";
import IconButton from "../../molecules/iconbutton/IconButton";

interface Props {
  game: API.GameStatus;
  event: API.EventResponse;
  round: number;
  setError: (err: string | null) => void;
  update: () => void;
  setScreen: (value: "timer" | "bonus" | "close") => void;
  handleLoginClick: (teamId: number) => void;
  handleBroadcastClick: () => void;
  handleSendWelcomeEmailsClick: () => void;
}

function ScheduledRoundTimerScreen({
  game,
  round,
  event,
  setError,
  update,
  setScreen,
  handleLoginClick,
  handleBroadcastClick,
  handleSendWelcomeEmailsClick,
}: Props) {
  const isMobile = useIsMobile();
  const inProgress = game.status === "inprogress" || game.status === "wrapup";
  const [shouldShowEditModal, setShouldShowEditModal] = useState(false);
  const [time, setTime] = useState(game.timeRemaining);
  const [active, setActive] = useState(
    inProgress && time > 0 && !game.pausedTime,
  );

  const onEditClicked = useCallback(() => {
    setShouldShowEditModal(true);
  }, [setShouldShowEditModal]);

  useEffect(() => {
    setTime(game.timeRemaining);
    const inProgress = game.status === "inprogress" || game.status === "wrapup";
    setActive(inProgress && !game.pausedTime);
  }, [game]);

  useEffect(() => {
    const countdown: NodeJS.Timeout = setInterval(async () => {
      setTime((time) => time - 1);
    }, 1000);
    if (game.timeRemaining < 0) {
      setScreen("close");
    }
    return () => {
      clearInterval(countdown);
    };
  }, [active, game, setScreen]);

  const resumeGame = useCallback(async () => {
    await API.unlockAllTeams(game.gameId, round);
  }, [game.gameId, round]);

  const pauseGame = useCallback(async () => {
    await API.lockAllTeams(game.gameId, round);
  }, [game.gameId, round]);

  const handlePlay = useCallback(
    async (roundHasStarted) => {
      setError(null);
      if (!roundHasStarted) {
        await API.updateScheduledRound(game.gameId, round, {
          startTime: new Date().toISOString(),
        });
      } else if (active) {
        await pauseGame();
      } else if (inProgress) {
        await resumeGame();
      }
      setActive(!active);
      update();
    },
    [
      active,
      inProgress,
      pauseGame,
      resumeGame,
      setActive,
      setError,
      update,
      game.gameId,
      round,
    ],
  );

  const onSaveSchedule = useCallback(
    async ({
      startTime,
      finishTime,
    }: {
      startTime?: string;
      finishTime: string;
    }) => {
      await API.updateScheduledRound(game.gameId, round, {
        startTime,
        finishTime,
      });
      setShouldShowEditModal(false);
    },
    [game.gameId, round, setShouldShowEditModal],
  );

  const roundHasStarted =
    game.roundTimers[round] &&
    game.roundTimers[round].startTime! < new Date().toISOString();
  const timeLeftInSeconds = roundHasStarted
    ? time
    : differenceISOTime(game.roundTimers[round].startTime!, null);
  const maxTimerValue = roundHasStarted
    ? differenceISOTime(
        game.roundTimers[round].finishTime!,
        game.roundTimers[round].startTime!,
      )
    : 1;
  const timeLeft = parseTimeLeftFromSeconds(timeLeftInSeconds, true);
  return (
    <InlineGroup
      spaceBetweenElements={isMobile ? 0 : 2}
      block
      className={isMobile ? "mobile-layout" : ""}
    >
      <VerticalGroup
        className={classnames("game-timer", {
          "width-90-percent": isMobile,
          "width-40-percent": !isMobile,
        })}
        spaceBetweenElements={2}
        center
      >
        <div
          style={{
            width: isMobile ? "100%" : "60%",
          }}
        >
          <TimerProgress
            value={roundHasStarted ? timeLeftInSeconds : 0}
            maxValue={maxTimerValue}
          >
            <VerticalGroup center>
              {timeLeft.displayType === "M" && (
                <Text
                  size="4xl"
                  colour={
                    timeLeftInSeconds <= 60
                      ? "danger"
                      : timeLeftInSeconds <= 300
                        ? "secondaryYellow"
                        : "secondaryBlue"
                  }
                  mono
                  medium
                >
                  {timeLeft.time.toUpperCase()}
                </Text>
              )}
              {timeLeft.displayType === "H" && (
                <Text
                  size={isMobile ? "4xl" : "2xl"}
                  colour="green"
                  mono
                  medium
                  respectNewLine
                  center
                >
                  {timeLeft.time.toUpperCase()}
                </Text>
              )}
              {timeLeft.displayType === "D" && (
                <Text
                  size={isMobile ? "4xl" : "2xl"}
                  colour="green"
                  mono
                  medium
                >
                  {timeLeft.time.toUpperCase()}
                </Text>
              )}
              <Text size="xl" mono medium>
                {roundHasStarted
                  ? timeLeftInSeconds <= 300
                    ? "FINAL 5 MINS"
                    : "REMAINING"
                  : "UNTIL START"}
              </Text>
            </VerticalGroup>
          </TimerProgress>
        </div>
        <InlineGroup center verticalCenter>
          <Button
            colour={active ? "danger" : "green"}
            className={active ? "game-stop" : "game-play"}
            onClick={() => handlePlay(roundHasStarted)}
          >
            <Icon
              type={active ? "square" : "play"}
              colour="white"
              className={`timer-icon${active ? "" : "-play"}`}
            />
          </Button>
        </InlineGroup>
        {!isMobile && (
          <IconButton
            block
            icon="broadcast"
            onClick={handleBroadcastClick}
            text="Broadcast"
          />
        )}
        {!isMobile && round <= 1 && (
          <IconButton
            block
            wide={false}
            data-test="send-emails"
            icon="email"
            text="Send Welcome Emails"
            onClick={handleSendWelcomeEmailsClick}
          />
        )}
      </VerticalGroup>

      <VerticalGroup
        full
        spaceBetweenElements={2}
        className={classnames("game-timer", {
          "width-100-percent": isMobile,
          "width-60-percent": !isMobile,
        })}
      >
        <Card wide>
          <InlineGroup spread>
            <InlineGroup spaceBetweenElements={6} stretch>
              <CircleIcon
                type="schedule"
                colour="blue"
                circleSize={16}
                size={10}
              />
              <InlineGroup stretch>
                <VerticalGroup spaceBetweenElements={2}>
                  <Text size="sm" bold>
                    Round Schedule
                  </Text>
                  <Text size="sm">
                    Start Time:{" "}
                    {fullDateAndTime(game.roundTimers[round].startTime!)}
                  </Text>
                  <Text size="sm">
                    Finish Time:{" "}
                    {fullDateAndTime(game.roundTimers[round].finishTime!)}
                  </Text>
                  <Button
                    link
                    onClick={onEditClicked}
                    style={{ height: "auto" }}
                  >
                    Edit Schedule
                  </Button>
                </VerticalGroup>
              </InlineGroup>
            </InlineGroup>
          </InlineGroup>
        </Card>
        {isMobile ? (
          <Accordion wide title="Teams" defaultState="collapse">
            <GameConnections
              teams={game.teams}
              gameId={game.gameId}
              event={event}
              handleLoginClick={handleLoginClick}
            />
          </Accordion>
        ) : (
          <GameConnections
            teams={game.teams}
            gameId={game.gameId}
            event={event}
            handleLoginClick={handleLoginClick}
          />
        )}
      </VerticalGroup>
      <ScheduledRoundForm
        isOpen={shouldShowEditModal}
        onClose={() => setShouldShowEditModal(false)}
        data={game.roundTimers[round]}
        onSave={onSaveSchedule}
        onCancel={() => setShouldShowEditModal(false)}
      />
    </InlineGroup>
  );
}

export default ScheduledRoundTimerScreen;
