import React, { useCallback, useMemo } from "react";
import Dropdown from "../../atoms/form/input/Dropdown";

interface PresentResultsRndDropdownProps {
  onChange: (round: number) => void;
  maxRound: number;
  currentRound: number;
}

const PresentResultsRndDropdown: React.FC<PresentResultsRndDropdownProps> = ({
  onChange,
  currentRound,
  maxRound,
}) => {
  const getLabel = useCallback((index: number): string => {
    if (index === 0) {
      return "Intro";
    }
    return `Rnd ${index}`;
  }, []);

  const roundOptions = useMemo(
    () =>
      Array.from({ length: maxRound + 1 }, (_, index) => {
        const optionLabel = getLabel(index);
        return {
          value: index,
          label: optionLabel,
          formattedLabel: optionLabel,
        };
      }).filter(Boolean),
    [getLabel, maxRound],
  );

  const currentRoundOption = useMemo(
    () => roundOptions.find((option) => option?.value === currentRound),
    [currentRound, roundOptions],
  );

  const handleSelect = useCallback(
    (option: { value: string; label: string }) => {
      onChange(parseInt(option.value));
    },
    [onChange],
  );

  return (
    <Dropdown
      small
      menuPlacement="top"
      selectProps={{
        options: roundOptions,
        onChange: handleSelect,
        classNamePrefix: "round-dropdown",
        value: currentRoundOption,
      }}
    />
  );
};

export default PresentResultsRndDropdown;
