import React from "react";
import Text from "../../../../atoms/text/Text";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";

interface PrintFinancialSummaryMetricsProps {
  data: API.SimulationResponse["financialSummaryMetrics"];
  config?: API.SimulationResponse["financialSummaryMetricConfig"];
}

const PrintFinancialSummaryMetrics: React.FC<
  PrintFinancialSummaryMetricsProps
> = ({ data, config }) => {
  return (
    <VerticalGroup spaceBetweenElements={4} className="page-break-inside-avoid">
      <h4>Financial Summary Metrics</h4>
      <VerticalGroup spaceBetweenElements={4}>
        {(data ?? []).map((item) => {
          const matchedConfig = config?.find((i) => i.type === item.type);

          if (matchedConfig) {
            return <Text key={item.type}>{matchedConfig.name}</Text>;
          }
          return null;
        })}
      </VerticalGroup>
    </VerticalGroup>
  );
};

export default PrintFinancialSummaryMetrics;
