import React from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";
import { percentage } from "../utils/formatters";
import pluckResultsByType from "../utils/pluckResultsByType";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["totalShareholderReturn"];
}

const ModelTeamResultsTotalShareholderReturn: React.FC<Props> = ({ data }) => {
  const valuationCashflowsData = pluckResultsByType(data, "valuationCashflows");
  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <TableWithSections
          hasRoundZero
          header="Valuation Cashflows (EOY)"
          data={[
            {
              name: "",
              fields: [
                "futureCashflowsInclTerminalValue",
                "historicalCashflowsStartOfYear",
                "historicalCashflowsEndOfYear",
                "initialInvestment",
                "shareholderCashflows",
                "shareholderReturn",
                "storedShareholderReturns",
              ],
              values: valuationCashflowsData,
              totalFields: ["shareholderCashflows", "storedShareholderReturns"],
              formatter: (val: number, field: string) => {
                if (
                  ["shareholderReturn", "storedShareholderReturns"].includes(
                    field,
                  )
                ) {
                  return percentage(val);
                }
                return null;
              },
              fieldFormatter: (field: string) => {
                if (field === "futureCashflowsInclTerminalValue") {
                  return "Future Cashflows (incl. Terminal Value)";
                }
                return null;
              },
            },
          ]}
        />
      </VerticalGroup>
    </>
  );
};

export default ModelTeamResultsTotalShareholderReturn;
