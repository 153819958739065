import React, { forwardRef } from "react";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import "./PrintParticipantsAttendancePage.scss";
import Table, { Td, Th, Tr } from "../../../../../atoms/table/Table";
import Text from "../../../../../atoms/text/Text";
import { sortParticipants } from "../../../../../../lib/participantSort";
import Image from "../../../../../atoms/image/Image";
import { ThemeColours } from "../../../../../../types/theme";
import Printable from "../../../../../atoms/printable/Printable";
import PrintPreviewGameDetails from "../../../../game/PrintPreviewGameDetails";

interface Props {
  event: API.EventResponse;
  participants: API.ParticipantResponse[];
  id?: string;
}
interface ParticipantRowProps {
  participant: API.ParticipantResponse;
  bold: boolean;
  showSignColumn?: boolean;
  colour?: ThemeColours;
  isLastMember?: boolean;
}
export const ParticipantRow: React.FC<ParticipantRowProps> = ({
  participant,
  bold,
  showSignColumn,
  colour,
  isLastMember,
}) => {
  const rowClass = isLastMember ? "last-team-member" : "";
  return (
    <Tr colour={colour} className={rowClass}>
      <Td>
        <Text bold={bold}>{participant.team}</Text>
      </Td>
      <Td>
        <Text bold={bold}>{participant.firstName}</Text>
      </Td>
      <Td>
        <Text bold={bold}>{participant.lastName}</Text>
      </Td>
      {showSignColumn && (
        <Td className="sign-attendance">
          <Text bold={bold}></Text>
        </Td>
      )}
    </Tr>
  );
};
const PrintParticipantsAttendancePage = forwardRef<HTMLDivElement, Props>(
  ({ participants, event }, ref) => {
    const filterParticipants = (participants: API.ParticipantResponse[]) => {
      return participants.filter(
        (p) =>
          !(
            p.lastName.toLowerCase().includes("tablet") &&
            p.firstName.toLowerCase().includes("player")
          ),
      );
    };
    const filteredParticipants = filterParticipants(participants);
    const sortedParticipantsByTeam = sortParticipants(filteredParticipants);
    const renderTableHeader = () => (
      <thead className="attendance-header">
        <tr>
          <th colSpan={4}>
            <VerticalGroup center>
              <Text center bold size="lg">
                Attendance List
              </Text>
              <InlineGroup block stretch verticalCenter spread>
                <VerticalGroup>
                  <PrintPreviewGameDetails
                    participantsCount={filteredParticipants.length}
                    event={event}
                  />
                </VerticalGroup>
                <Image
                  height={36}
                  src={event.branding?.brandingImage?.imageUrl}
                  alt="branding image"
                />
              </InlineGroup>
            </VerticalGroup>
          </th>
        </tr>

        <tr>
          <Th width={20}>Team</Th>
          <Th width={48}>First Name</Th>
          <Th width={48}>Last Name</Th>
          <Th>Signature</Th>
        </tr>
      </thead>
    );

    return (
      <Printable ref={ref} orientation="portrait">
        <VerticalGroup
          wide
          spread
          center
          className="attendance-page"
          spaceBetweenElements={1}
        >
          <InlineGroup block spaceBetweenElements={16}>
            <VerticalGroup wide>
              <Table className="attendance-table ">
                {renderTableHeader()}

                <tbody>
                  {sortedParticipantsByTeam.map((participant, index, array) => {
                    const isLastMember =
                      index === array.length - 1 ||
                      array[index + 1].team !== participant.team;
                    return (
                      <ParticipantRow
                        key={participant.id}
                        participant={participant}
                        bold={participant.isCeo}
                        showSignColumn
                        isLastMember={isLastMember}
                      />
                    );
                  })}
                </tbody>
              </Table>
            </VerticalGroup>
          </InlineGroup>
          <InlineGroup block spread></InlineGroup>
        </VerticalGroup>
      </Printable>
    );
  },
);
PrintParticipantsAttendancePage.displayName = "PrintParticipantsAttendancePage";
export default PrintParticipantsAttendancePage;
