import React, { useMemo } from "react";
import { DragAndDropItem, Dropzone } from "../../../../organisms/draganddrop";
import { summaryMetricItems } from "./data";
import SummaryStandard from "./SummarySelectedView";
import { DragDropContext } from "react-beautiful-dnd";
import { Noop } from "../../../../../constants/functions";
interface Props {
  data: API.SimulationResponse["summaryMetrics"];
  config: API.SimulationResponse["summaryMetricConfig"];
}

export const SummaryMetricsReadOnly: React.FC<Props> = ({ data, config }) => {
  const selectedItems: Array<DragAndDropItem | undefined> = useMemo(() => {
    return data.map((item) => {
      const matchedItem = summaryMetricItems.find((i) => i.type === item.type);
      const matchedConfig = config?.find((i) => i.type === item.type);

      return matchedItem && matchedConfig
        ? {
            id: item.type,
            view: (
              <SummaryStandard
                readOnly
                key={item.type}
                item={{
                  ...matchedItem,
                  percentage: item.percentage,
                  label: matchedConfig.name,
                }}
              />
            ),
          }
        : undefined;
    });
  }, [config, data]);

  return (
    <DragDropContext onDragEnd={Noop}>
      <Dropzone items={selectedItems} size={data.length} readOnly />
    </DragDropContext>
  );
};
