interface LossRate {
  HOME_LOANS: {
    proprietaryLoans: number;
    brokerLoans: number;
  };
  BUSINESS_LOANS: {
    goodQuality: number;
    mediumQuality: number;
    poorQuality: number;
  };
  CREDIT_CARDS: number;
  INSTITUTIONAL_BANKING: number;
  OFFSHORE_BANK: number;
}

const defaultLossRate: LossRate = {
  HOME_LOANS: {
    proprietaryLoans: 0.00015,
    brokerLoans: 0.0003,
  },
  BUSINESS_LOANS: {
    goodQuality: 0.0004,
    mediumQuality: 0.0012,
    poorQuality: 0.008,
  },
  CREDIT_CARDS: 0.025,
  INSTITUTIONAL_BANKING: 0.002,
  OFFSHORE_BANK: 0.02,
};

export const LOSS_RATES_BY_MARKET: Record<API.Country, LossRate> = {
  australia: defaultLossRate,
  new_zealand: defaultLossRate,
  usa: defaultLossRate,
  united_kingdom: defaultLossRate,
  europe: defaultLossRate,
  indonesia: defaultLossRate,
  japan: defaultLossRate,
  china: defaultLossRate,
  singapore: defaultLossRate,
  small_market: defaultLossRate,
  medium_market: defaultLossRate,
  big_market: defaultLossRate,
};

export const BROKER_LOANS_MARKET_SHARE = 0.45;
