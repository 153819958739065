import React from "react";
import BrandingListTable from "./BrandingListTable";
import Container from "../../atoms/page/Page";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import Banner from "../../atoms/banner/Banner";
import useBrandings from "../../../hooks/useBrandings";

function BrandingList() {
  const { inProgress, completed, data, error, refresh } = useBrandings();

  return (
    <Container>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {completed && data && (
        <BrandingListTable brandings={data} refreshBrandings={refresh} />
      )}
    </Container>
  );
}

export default BrandingList;
