import ordinal from "ordinal";
import roundTo from "round-to";

const DEFAULT_DP = 2;
export const round = (val: number, precision?: number): number => {
  return roundTo(val, precision ?? DEFAULT_DP);
};

export const roundToFixed = (val: number, precision?: number): string => {
  return round(val, precision).toFixed(precision ?? DEFAULT_DP);
};

export const thousandSeparator = (val: number | string) => {
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const percentage = (
  val: number,
  dp?: number,
  hidePercentage?: boolean,
  showNegativeSign?: boolean,
  dontFormatZero?: boolean,
) => {
  if (val === 0) {
    if (dontFormatZero) {
      return "0";
    }
    return "-";
  }
  const formatted = `${roundToFixed(val * 100, dp ?? 2)}${hidePercentage ? "" : "%"}`;
  if (val < 0 && !showNegativeSign) {
    return `(${formatted.replace("-", "")})`;
  }
  return formatted;
};

export const reportingPercentage = (
  val: number,
  dp?: number,
  hidePercentage?: boolean,
) => {
  return `${roundToFixed(val, dp ?? 2)}${hidePercentage ? "" : "%"}`;
};

export const inMillions = (val: number) => {
  if (val.toString().length >= 6) {
    const num = val / 1000000;
    return `${num.toFixed(2)}m`;
  } else {
    return val;
  }
};

export const inThousands = (val: number) => {
  if (val.toString().length >= 4) {
    const num = val / 1000;
    return `${num.toFixed(1)}k`;
  } else {
    return val;
  }
};

export const formatValue = (val: number, dp?: number, displayDp?: number) => {
  if (val === 0) {
    return "-";
  }
  if (dp !== null) {
    val = round(val, dp);
  }
  const formatted = thousandSeparator(
    displayDp !== null ? val.toFixed(displayDp) : val,
  );
  if (val < 0) {
    return `(${formatted})`.replace("-", "");
  }
  return formatted;
};

export const formatRank = (position: number) => ordinal(position);

export const formatLendingCriteria = (val: number) =>
  val < 0 ? "Loose" : val > 0 ? "Tight" : "Medium";

export const formatServiceLevel = (val: number) =>
  val < 0 ? "Low" : val > 0 ? "High" : "Medium";

export const formatLossMultiplier = (val: number) =>
  `${roundToFixed(val, 2)} x`;

export const formatBps = (val: number, dp?: number, displayDp?: number) => {
  if (val === 0) {
    return "-";
  }
  return `${formatValue(val, dp, displayDp)}bps`;
};

export const percentageAsBps = (val: number, dp?: number) => {
  if (val === 0) {
    return "-";
  }
  const formatted = `${val > 0 ? "+" : ""}${(Number(roundToFixed(val * 100, dp ?? 2)) * 100).toFixed(0)} bps`;
  return formatted;
};
