import React from "react";
import { useParams } from "react-router-dom";
import api from "../../../../../../services/api";
import { ImageWithPreviewModal } from "../../../../../organisms/image-preview-modal/ImageWithPreviewModal";

interface Props {
  field: API.ImageFieldResponse;
}

export const ImageFieldPreview: React.FC<Props> = ({ field }) => {
  const { assessmentId } = useParams<{ assessmentId: string }>();
  if (field.config.imageId) {
    return (
      <div>
        <ImageWithPreviewModal
          src={api.getAssessmentImageUrl(assessmentId, field.config.imageId)}
          alt={field.config.nickName || ""}
        />
      </div>
    );
  } else if (field.config.imageUrl) {
    return (
      <div>
        <ImageWithPreviewModal
          src={`${field.config.imageUrl}`}
          alt={field.config.nickName || ""}
        />
      </div>
    );
  }
  return null;
};
