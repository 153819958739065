import {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useMemo,
} from "react";

import { Actions, ModelState } from "./state";
import { NotImplemented } from "../../../constants/functions";

interface Context {
  config: ModelState["config"];
  selectedRound: ModelState["selectedRound"];
  selectedTeam: ModelState["selectedTeam"];
  gameStatus: ModelState["gameStatus"];
  updateConfig: (payload: ModelAPI.ConfigurationResponse) => void;
  updateSelectedRound: (payload: number) => void;
  updateGameStatus: (paylod: API.GameStatus) => void;
  updateSelectedTeam: (payload: number) => void;
}

export const ModelContext = createContext<Context>({
  config: undefined,
  selectedRound: 0,
  selectedTeam: 1,
  gameStatus: undefined,
  updateConfig: NotImplemented,
  updateSelectedRound: NotImplemented,
  updateGameStatus: NotImplemented,
  updateSelectedTeam: NotImplemented,
});

export const useModelContextValue = (
  state: ModelState,
  dispatch: Dispatch<Actions>,
): Context => {
  const updateConfig = useCallback(
    (payload: ModelAPI.ConfigurationResponse) => {
      dispatch({
        type: "UpdateConfig",
        payload,
      });
    },
    [dispatch],
  );

  const updateSelectedRound = useCallback(
    (payload: number) => {
      dispatch({
        type: "UpdateSelectedRound",
        payload,
      });
    },
    [dispatch],
  );

  const updateGameStatus = useCallback(
    (payload: API.GameStatus) => {
      dispatch({
        type: "UpdateGameStatus",
        payload,
      });
    },
    [dispatch],
  );

  const updateSelectedTeam = useCallback(
    (payload: number) => {
      dispatch({
        type: "UpdateSelectedTeam",
        payload,
      });
    },
    [dispatch],
  );

  const context = useMemo(
    () => ({
      config: state.config,
      updateConfig,
      selectedRound: state.selectedRound,
      updateSelectedRound,
      gameStatus: state.gameStatus,
      updateGameStatus,
      selectedTeam: state.selectedTeam,
      updateSelectedTeam,
    }),
    [
      state.config,
      updateConfig,
      state.selectedRound,
      updateSelectedRound,
      state.gameStatus,
      updateGameStatus,
      state.selectedTeam,
      updateSelectedTeam,
    ],
  );

  return context;
};

export const useModelContext = () => {
  return useContext(ModelContext);
};
