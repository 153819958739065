import React from "react";
import StandardModal from "../standard-modal/StandardModal";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import Button from "../../atoms/button/Button";
import InlineError from "../../atoms/inlineerror/InlineError";
import "./DropModal.scss";
import { FileDropZone } from "../file-dropzone/FileDropZone";

interface Props {
  isOpen: boolean;
  confirmTitle?: string;
  confirmInProgress?: boolean;
  onConfirm: () => void;
  onClose: (completed: boolean) => void;
  onCancel: () => void;
  onDrop: (acceptedFiles: Array<File>) => void;
  dropzoneText?: string;
  dropzonePlaceholderMain?: string;
  dropzonePlaceholderSecondary?: string;
  title: string;
  error: string | null;
  errorActive: boolean;
  accept?: string;
  downloadOnClick?: any;
  downloadMsg?: string;
}

function DropModal({
  isOpen,
  onClose,
  onCancel,
  onDrop,
  title,
  confirmTitle,
  dropzoneText,
  dropzonePlaceholderMain,
  dropzonePlaceholderSecondary,
  onConfirm,
  confirmInProgress,
  error,
  errorActive,
  accept,
  downloadMsg,
  downloadOnClick,
}: Props) {
  return (
    <StandardModal
      isOpen={isOpen}
      onClose={() => onClose(false)}
      title={title}
      className="drop-modal"
    >
      <>
        <Button className="template-link" noUi onClick={downloadOnClick}>
          {downloadMsg}
        </Button>
        <FileDropZone
          onDrop={onDrop}
          accept={accept}
          dropzoneText={dropzoneText}
          dropzonePlaceholderMain={dropzonePlaceholderMain}
          dropzonePlaceholderSecondary={dropzonePlaceholderSecondary}
        />
        {error && (
          <InlineError
            className="upload-error mt-2"
            active={errorActive}
            message={error || ""}
          />
        )}
        <InlineGroup className="mt-4" spread>
          <Button data-test="modal-cancel" outline onClick={onCancel} large>
            Cancel
          </Button>
          <Button
            data-test="modal-confirm"
            onClick={onConfirm}
            inProgress={confirmInProgress}
            large
          >
            {confirmTitle || "Yes"}
          </Button>
        </InlineGroup>
      </>
    </StandardModal>
  );
}

export default DropModal;
