import React, { useCallback, useEffect, useMemo } from "react";
import Button from "../../../atoms/button/Button";
import Input from "../../../atoms/form/input/Input";
import useForm from "../../../../hooks/useForm";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import DisplayField from "../../../atoms/form/display-field/DisplayField";
import Form, { FormRequiredText } from "../../../atoms/form/Form";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Banner from "../../../atoms/banner/Banner";
import CancelButton from "../../../atoms/form/actions/CancelButton";
import IntegerDropdown from "../../../organisms/integer-dropdown/IntegerDropdown";

interface Props {
  assessment: API.AssessmentResponse;
  group?: API.AssessmentGroupResponse;
  onSave: (
    data: API.AssessmentGroupRequest,
  ) => Promise<API.AssessmentGroupResponse | null>;
  onCancel: () => void;
  onComplete: (data: API.AssessmentGroupResponse) => void;
}

const getNextAvailableRound = (assessment: API.AssessmentResponse) => {
  const existingRounds = assessment.groups.reduce<{ [round: string]: boolean }>(
    (res, g) => {
      res[g.round] = true;
      return res;
    },
    {},
  );
  for (let round = 1; round <= 12; round++) {
    if (!existingRounds[round]) {
      return round;
    }
  }
  return 1;
};

const GroupForm: React.FC<Props> = ({
  assessment,
  group,
  onSave,
  onCancel,
  onComplete,
}) => {
  const [
    { formData, inProgress, fieldErrors, error, data, completed },
    { setField, setDropdownField, handleSubmit },
  ] = useForm(
    group
      ? {
          ...group,
        }
      : {
          round: getNextAvailableRound(assessment),
          name: "",
          isModalOpen: false,
        },
  );

  const { round, name } = formData;

  const callback = useCallback(() => {
    const payload = {
      round,
      name,
    };
    return onSave(payload);
  }, [name, round, onSave]);

  const validate = useCallback(() => {
    const errors = [];
    if (!round) {
      errors.push({ field: "round", message: "Select a round" });
    }
    if (!name) {
      errors.push({ field: "name", message: "Enter a name" });
    }
    return errors;
  }, [round, name]);

  const onSubmit = useMemo(() => {
    return handleSubmit(callback, validate);
  }, [callback, handleSubmit, validate]);

  useEffect(() => {
    if (!!completed && !error) {
      onComplete(data);
    }
  }, [completed, error, onComplete, data]);

  return (
    <VerticalGroup full spread>
      <VerticalGroup wide>
        <Banner type="error" active={!!error} message={error?.message} />
        <InlineGroup evenWidthChildren block spaceBetweenElements={10}>
          <Form id="assessment-group-form" onSubmit={onSubmit}>
            <FormRequiredText />
            <IntegerDropdown
              label="Round *"
              value={round}
              min={1}
              max={12}
              onChange={setDropdownField("round")}
              error={fieldErrors.round}
              blocklist={assessment.groups.map((g) => g.round)}
            />
            <Input
              data-test="name"
              type="text"
              label="Group Name *"
              value={name}
              onChange={setField("name")}
              error={fieldErrors.name}
            />
            {!!group && <DisplayField label="Group ID" value={group.id} />}
          </Form>
        </InlineGroup>
      </VerticalGroup>
      <InlineGroup block spread className="mt-4">
        <CancelButton
          confirmModalProps={{
            confirmButtonText: "Save",
            inProgress: formData.inProgress,
            showModal: formData.formUpdated,
            onConfirm: onSubmit,
            onDiscard: onCancel,
          }}
          onClick={onCancel}
        />
        <Button
          data-test="save"
          type="submit"
          form="assessment-group-form"
          wide
          inProgress={inProgress}
        >
          Save
        </Button>
      </InlineGroup>
    </VerticalGroup>
  );
};

export default GroupForm;
