import React, { useCallback, useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import { useHistory } from "react-router-dom";

import useAPIRequest from "../../../../../hooks/useAPIRequest";
import API from "../../../../../services/api";
import Banner from "../../../../atoms/banner/Banner";
import Clickable from "../../../../atoms/clickable/Clickable";
import Icon from "../../../../atoms/icon/Icon";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import LoadingSpinner from "../../../../atoms/loadingspinner/LoadingSpinner";
import Table, { Td, Th, Tr } from "../../../../atoms/table/Table";
import ClientAvatarList from "../../../../molecules/client-avatar-list/ClientAvatarList";
import IconButton from "../../../../molecules/iconbutton/IconButton";
import EmptyList from "../../../../organisms/empty-list/EmptyList";
import SimpleModal from "../../../../organisms/standard-modal/SimpleModal";
import DeleteHelpManuals from "./HelpManualsDelete";
import Tooltip from "../../../../atoms/tooltip/Tooltip";

const STICKY_BAR_HEIGHT = 0;
const TOOLTIP_ID = "help-manual-table-tooltip";

interface HelpManualsState {
  helpManualModalOpen: boolean;
  deleteModalOpen: boolean;
  helpManual: API.HelpManualResponse | null;
}

function HelpManuals() {
  const [
    { helpManualModalOpen, deleteModalOpen, helpManual },
    setHelpManualState,
  ] = useState<HelpManualsState>({
    helpManualModalOpen: false,
    deleteModalOpen: false,
    helpManual: null,
  });
  const history = useHistory();

  const callback = useCallback(() => {
    return API.getHelpManuals();
  }, []);

  const [{ inProgress, data, error }, doAPIRequest] = useAPIRequest(
    callback,
    null,
  );

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  const onManualDeleteComplete = useCallback(() => {
    setHelpManualState((prevState) => ({
      ...prevState,
      deleteModalOpen: false,
      manualSelected: null,
    }));
    doAPIRequest();
  }, [doAPIRequest]);

  const images = helpManual?.images.map((image) => ({
    original: image.imageUrl,
    thumbnail: image.imageUrl,
  }));

  const handleHelpManualDeleteClick = useCallback(
    (helpItem: API.HelpManualResponse) => (event: any) => {
      event.stopPropagation();
      setHelpManualState((prevState) => ({
        ...prevState,
        helpManualModalOpen: false,
        deleteModalOpen: true,
        helpManual: helpItem,
      }));
    },
    [],
  );

  return (
    <>
      <Tooltip id={TOOLTIP_ID} />
      {helpManualModalOpen && (
        <SimpleModal
          size="medium"
          isMinimal
          showCloseButton
          isOpen={helpManualModalOpen}
          onClose={() =>
            setHelpManualState((prevState) => ({
              ...prevState,
              helpManualModalOpen: false,
              deleteModalOpen: false,
            }))
          }
        >
          <ImageGallery showPlayButton={false} items={images ?? []} />
        </SimpleModal>
      )}

      {deleteModalOpen && helpManual && (
        <DeleteHelpManuals
          isOpen={deleteModalOpen}
          onClose={() =>
            setHelpManualState((prevState) => ({
              ...prevState,
              helpManualModalOpen: false,
              deleteModalOpen: false,
            }))
          }
          onComplete={onManualDeleteComplete}
          manual={helpManual}
        />
      )}

      <InlineGroup block spread verticalCenter>
        <h2>Help Manuals</h2>
        <IconButton
          icon="help"
          text="Add Help Manual"
          className="mr-3"
          linkTo="/administration/configuration/helpManuals/add"
        />
      </InlineGroup>
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {inProgress && <LoadingSpinner />}
      {!data?.length && (
        <EmptyList icon="help" message="There are no help manuals yet" />
      )}
      {!!data?.length && (
        <Table stickyHeader stickyTop={STICKY_BAR_HEIGHT} clickable>
          <thead>
            <Tr>
              <Th>Name</Th>
              <Th>Client</Th>
              <Th right>Actions</Th>
            </Tr>
          </thead>
          <tbody>
            {data?.map((helpItem: API.HelpManualResponse) => (
              <Tr
                key={helpItem.id}
                className="help-manuals-row"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  history.push(
                    `/administration/configuration/helpManuals/${helpItem.id}/view`,
                  );
                }}
              >
                <Td>{helpItem.name}</Td>
                <Td>
                  {helpItem.availableToAllClients ? (
                    "Available to all clients"
                  ) : (
                    <ClientAvatarList clients={helpItem.clients} />
                  )}
                </Td>
                <Td className="right">
                  <Icon
                    type="search"
                    tt={{ content: "View Help Manual", id: TOOLTIP_ID }}
                  />
                  <Clickable onClick={handleHelpManualDeleteClick(helpItem)}>
                    <Icon
                      type="trash"
                      tt={{ content: "Delete Help Manual", id: TOOLTIP_ID }}
                    />
                  </Clickable>
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}

export default HelpManuals;
