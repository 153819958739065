import React, { useMemo, useState } from "react";

import Text from "../../../../../atoms/text/Text";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import { useSimulations } from "../../../../../../hooks/useSimulations";
import Dropdown from "../../../../../atoms/form/input/Dropdown";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import IconButton from "../../../../../molecules/iconbutton/IconButton";
import Button from "../../../../../atoms/button/Button";
import SimulationPreviewModal from "./SimulationPreviewModal";
import { Link } from "react-router-dom";
import RoleRequired from "../../../../../molecules/role-required/RoleRequired";
import FacilitatorHidden from "../../../../../molecules/facilitator-hidden/FacilitatorHidden";
import FacilitatorOnly from "../../../../../molecules/facilitator-only/FacilitatorOnly";
import Input from "../../../../../atoms/form/input/Input";
import { Noop } from "../../../../../../constants/functions";

interface SelectValue {
  id: string;
  value: string;
  label: string;
  simulation: API.SimulationResponse;
}

interface SelectSimulationProps {
  simulationId?: string;
  clientId?: string;
  state?: string;
  onSimulationSelected?: (simulation: API.SimulationResponse) => void;
  onUnselectSimulation?: () => void;
  error?: string;
}

const SelectSimulation: React.FC<SelectSimulationProps> = ({
  simulationId,
  clientId,
  state,
  onSimulationSelected,
  onUnselectSimulation,
  error,
}) => {
  const [showSettingsPreviewModal, setShowSettingsPreviewModal] =
    useState<boolean>(false);
  const { inProgress, data } = useSimulations(
    clientId ? [clientId] : [],
    true,
    state,
  );

  const options: SelectValue[] = useMemo(() => {
    return data
      ? data.map((simulation) => ({
          id: simulation.id,
          value: simulation.id,
          label: simulation.generalSetup.name,
          simulation,
        }))
      : [];
  }, [data]);

  const publishedOptions = useMemo(() => {
    return options
      ? options.filter(
          (simulation) => simulation.simulation.state === "complete",
        )
      : [];
  }, [options]);

  const currentSelected = useMemo(() => {
    if (simulationId) {
      return options.find((option) => option.id === simulationId);
    }
    return null;
  }, [simulationId, options]);

  return (
    <>
      <VerticalGroup spaceBetweenElements={4}>
        <Text size="sm" bold>
          Simulation
        </Text>
        <InlineGroup spaceBetweenElements={4}>
          <FacilitatorHidden>
            <Dropdown
              isVerticalLayout
              selectProps={{
                isLoading: inProgress,
                isDisabled: !clientId,
                onChange: (option: SelectValue) =>
                  onSimulationSelected &&
                  onSimulationSelected(option.simulation),
                value: currentSelected,
                options: publishedOptions,
                noOptionsMessage: () => "No Published Simulations",
                placeholder: !data
                  ? "Loading Simulations..."
                  : data.length === 0
                    ? "No Published Simulations"
                    : "Select a Simulation",
              }}
              error={error}
            />
          </FacilitatorHidden>
          <FacilitatorOnly>
            <Input
              isVerticalLayout
              type="text"
              // label="Simulation"
              value={currentSelected?.label}
              disabled
              onChange={Noop}
            />
          </FacilitatorOnly>
          <RoleRequired roles={["superadmin", "admin", "standard"]}>
            <IconButton
              color="blue"
              mini
              noUi
              light={false}
              data-test={`delete-simulation-${currentSelected?.id}`}
              icon="trash"
              onClick={onUnselectSimulation}
            />
          </RoleRequired>
          {currentSelected && (
            <Button
              outline
              data-test="view-simulation-settings-button"
              onClick={() => setShowSettingsPreviewModal(true)}
            >
              View Settings
            </Button>
          )}
          {currentSelected && (
            <RoleRequired roles={["superadmin", "admin", "standard"]}>
              <Button outline>
                <Link to={`/simulations/${currentSelected.simulation.id}/edit`}>
                  Edit Settings
                </Link>
              </Button>
            </RoleRequired>
          )}
        </InlineGroup>
        {simulationId && (
          <SimulationPreviewModal
            simulationId={simulationId}
            isOpen={showSettingsPreviewModal}
            onClose={() => setShowSettingsPreviewModal(false)}
          />
        )}
      </VerticalGroup>
    </>
  );
};

export default SelectSimulation;
