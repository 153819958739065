import React, { useState, useEffect } from "react";
import SimpleModal from "../../../../organisms/standard-modal/SimpleModal";
import Text from "../../../../atoms/text/Text";
import Button from "../../../../atoms/button/Button";
import InlineError from "../../../../atoms/inlineerror/InlineError";
import { useAuthToken } from "../../../../../hooks/useAuthToken";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import Container from "../../../../atoms/page/Page";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";

interface AuthTokenModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AuthTokenModal: React.FC<AuthTokenModalProps> = ({ isOpen, onClose }) => {
  const [secondsLeft, setSecondsLeft] = useState<number>(0);
  const { data, inProgress, error, refresh } = useAuthToken();

  useEffect(() => {
    if (isOpen) {
      refresh();
    }
  }, [isOpen, refresh]);

  useEffect(() => {
    if (data?.validUntil) {
      const validUntil = new Date(data.validUntil);
      const now = new Date();
      const newSecondsLeft = Math.floor(
        (validUntil.getTime() - now.getTime()) / 1000,
      );
      setSecondsLeft(Math.max(0, newSecondsLeft));
    }
  }, [data]);

  useEffect(() => {
    if (!isOpen) return;

    const timer = setInterval(() => {
      setSecondsLeft((prev) => {
        if (prev <= 0) {
          refresh();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [isOpen, refresh]);
  const showLoading = inProgress;
  const showToken = !showLoading && data?.token;

  return (
    <SimpleModal showCloseButton isOpen={isOpen} size="small" onClose={onClose}>
      <VerticalGroup spaceBetweenElements={2}>
        <Text bold size="lg">
          Authentication Token
        </Text>
        <Container fit>
          <InlineError
            active={!!error}
            message={error?.message || "Failed to fetch authentication token"}
            block
          />
          {showLoading ? (
            <Text size="md">Loading token...</Text>
          ) : showToken && data ? (
            <VerticalGroup wide center spaceBetweenElements={2}>
              <InlineGroup center fullHeight block verticalCenter>
                <Text center size="5xl" mono>
                  {data?.token}
                </Text>
              </InlineGroup>

              <InlineGroup center block>
                <Text center size="md">
                  Expires in: {secondsLeft} seconds
                </Text>
              </InlineGroup>
            </VerticalGroup>
          ) : null}
        </Container>
        <InlineGroup block right>
          <Button onClick={onClose}>OK</Button>
        </InlineGroup>
      </VerticalGroup>
    </SimpleModal>
  );
};

export default AuthTokenModal;
