import React from "react";

import EnumDropdown, {
  getValueFunc,
} from "../../../../organisms/enum-dropdown/EnumDropdown";

interface Props {
  onChange: (selected: { value: string; label: string }) => void;
  value?: string;
  error?: string;
  label?: string;
  name?: string;
}

export const options = [
  {
    value: "automatic",
    label: "Automatic",
  },
  {
    value: "set_credit_rating",
    label: "Set Credit Rating",
  },
  {
    value: "full_capital_management",
    label: "Full Capital Management",
  },
];

export const getLabelFromValue = getValueFunc(options);

function CapitalManagementDropdown({
  onChange,
  value,
  error,
  label,
  name,
}: Props) {
  return (
    <EnumDropdown
      onChange={onChange}
      options={options}
      value={value}
      error={error}
      label={label}
      name={name ?? "capital-management-dropdown"}
    />
  );
}

export default CapitalManagementDropdown;
