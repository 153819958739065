import React from "react";
import { useElementSize } from "usehooks-ts";
import { ThemeColours } from "../../../../types/theme";
import Badge from "../../../atoms/badge/Badge";
import Icon, { IconType } from "../../../atoms/icon/Icon";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";

import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import CurlyBrace from "../components/CurlyBrace/CurlyBrace";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import ResultsText from "../results-text/ResultsText";
import EditableReportingHeading from "../components/EditableReportingHeading";
import ReportingSubHeading from "../components/ReportingSubHeading";

interface Props {}

interface ColumnProps {
  heading: string;
  results: number[];
  colour: ThemeColours;
  manType: IconType;
}

const Column: React.FC<ColumnProps> = ({
  heading,
  results,
  colour,
  manType,
}) => {
  const [ref, { width }] = useElementSize();

  return (
    <VerticalGroup spaceBetweenElements={2} center>
      <ResultsText bold size="xl">
        {heading}
      </ResultsText>
      <InlineGroup block center>
        {width && <CurlyBrace width={width - 20} />}
      </InlineGroup>
      <InlineGroup spread ref={ref} fullHeight block center className="pt-12">
        {results.map((r) => (
          <VerticalGroup
            key={r}
            spaceBetweenElements={2}
            center
            spread
            style={{ height: "80%" }}
          >
            <Badge
              value={r}
              colour={colour}
              dynamicSize={{ min: "20px", preferred: "7vw", max: "300px" }}
            />
            <Icon
              type={manType}
              colour={colour}
              dynamicSize={{ min: "30px", preferred: "7.5vw", max: "300px" }}
              skipSizing
            />
          </VerticalGroup>
        ))}
      </InlineGroup>
    </VerticalGroup>
  );
};

const RoundResultsSurvey: React.FC<Props> = () => {
  return (
    <RoundResultsContainer>
      <VerticalGroup full center wide spaceBetweenElements={4}>
        <VerticalGroup spaceBetweenElements={2} center wide>
          <EditableReportingHeading defaultHeading="Survey" />
          <ReportingSubHeading subHeading="We care about our customers (you)!" />
          <ResultsText
            size="xl"
            bold
            italic
            center
            colour="blue"
            className="mb-6"
          >
            Your feedback helps us improve our programs
          </ResultsText>
        </VerticalGroup>
        <InlineGroup
          block
          fullHeight
          style={{ paddingLeft: "5%", paddingRight: "5%" }}
          center
          spaceBetweenElements={4}
        >
          <Column
            heading="Didn't like it"
            results={[1, 2, 3, 4, 5, 6]}
            colour="green"
            manType="depressedman"
          />
          <Column
            heading="So-so"
            results={[7, 8]}
            colour="yellow"
            manType="neutralman"
          />
          <Column
            heading="Recommend it"
            results={[9, 10]}
            colour="blue"
            manType="happyman"
          />
        </InlineGroup>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsSurvey;
