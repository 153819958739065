import React from "react";
import classNames from "classnames";
import Scrollable from "../scrollable/Scrollable";

import "./Page.scss";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  children: React.ReactNode;
  noOverflow?: boolean;
  full?: boolean;
  column?: boolean;
  fit?: boolean;
  relative?: boolean;
  noPadding?: boolean;
}

const Page = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      children,
      noOverflow,
      full,
      column,
      fit,
      relative,
      noPadding,
      ...rest
    },
    ref,
  ) => {
    Page.displayName = "Page";
    if (noOverflow) {
      return (
        <div
          ref={ref}
          className={classNames(
            "page-container",
            {
              "no-overflow": noOverflow,
              full,
              column,
              fit,
              relative,
              "no-padding": noPadding,
            },
            [className],
          )}
          {...rest}
        >
          {children}
        </div>
      );
    }
    return (
      <div
        ref={ref}
        className={classNames(
          "page-container",
          {
            "no-overflow": noOverflow,
            full,
            column,
            fit,
            relative,
            "no-padding": noPadding,
          },
          [className],
        )}
        {...rest}
      >
        <Scrollable>{children}</Scrollable>
      </div>
    );
  },
);

export default Page;
