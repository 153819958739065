import React, { useCallback } from "react";

import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Icon from "../../../atoms/icon/Icon";
import Image from "../../../atoms/image/Image";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Courtroom from "../../../../assets/results/Courtroom.png";
import { thousandSeparator } from "../../model/utils/formatters";
import { TeamsWithColours } from "../RoundResultsPage";

import "../RoundResultsTablePage.scss";
import { useResultsContext } from "../context/context";
import classNames from "classnames";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import ResultsText from "../results-text/ResultsText";
import EditableReportingHeading from "../components/EditableReportingHeading";
import ReportingSubHeading from "../components/ReportingSubHeading";

interface Props {
  teams: TeamsWithColours[];
  data: ModelAPI.Reporting.RoyalCommissionEvent;
  currencyDenomination: string;
}

export const getMaxClicks = (
  data: ModelAPI.Reporting.RoyalCommissionEvent | null,
) => {
  return data && data.teams.length > 0 ? 3 : 1;
};

const RoundResultsRoyalCommission: React.FC<Props> = ({
  teams,
  data,
  currencyDenomination,
}) => {
  const context = useResultsContext();

  const onNext = useCallback(() => {
    const maxClicks = getMaxClicks(data);
    if (context.clicks === maxClicks) {
      context.goNextPage();
    } else {
      context.addClick();
    }
  }, [context, data]);

  return (
    <RoundResultsContainer onNext={onNext}>
      <VerticalGroup full center wide spaceBetweenElements={2}>
        <EditableReportingHeading defaultHeading="Breaking News!" />
        <VerticalGroup full center wide spaceBetweenElements={4}>
          <ReportingSubHeading
            subHeading="Full scale Government investigation of banking practices and
            processes."
          />
          <InlineGroup fullHeight verticalCenter>
            <VerticalGroup
              full
              verticalCenter
              center
              style={{ width: "100%", height: "100%" }}
            >
              <Image
                src={Courtroom}
                alt="Court Room"
                dynamicSize={{ min: "300px", preferred: "40vw", max: "1300px" }}
              ></Image>
            </VerticalGroup>
            <VerticalGroup full spread style={{ width: "120%", height: "90%" }}>
              <ResultsText size="xl" bold className="mb-2">
                Key Findings
              </ResultsText>

              <VerticalGroup
                full
                className="pt-4 pb-4"
                wide
                {...(data.teams.length > 0
                  ? { spread: true }
                  : { spaceBetweenElements: 8 })}
              >
                <InlineGroup verticalCenter>
                  <Icon
                    type="hammer"
                    colour="primaryDark"
                    dynamicSize={{
                      min: "50px",
                      preferred: "5vw",
                      max: "300px",
                    }}
                  ></Icon>
                  <VerticalGroup wide className="ml-2">
                    <ResultsText bold size="md" className="pb-2">
                      Verdict
                    </ResultsText>
                    <ResultsText size="md">
                      Banks found to have breached responsible banking practices
                      and had insufficient key process controls.
                    </ResultsText>
                  </VerticalGroup>
                </InlineGroup>
                <InlineGroup
                  verticalCenter
                  className={classNames({
                    "show-bids": context.clicks >= 1 && !context.noAnimations,
                    "no-show-options": context.clicks < 1,
                  })}
                >
                  <Icon
                    type="dollars"
                    colour="yellow"
                    dynamicSize={{
                      min: "50px",
                      preferred: "5vw",
                      max: "300px",
                    }}
                  ></Icon>
                  <VerticalGroup wide className="ml-2">
                    <ResultsText bold size="md" className="pb-2">
                      Remediation Costs
                    </ResultsText>
                    <ResultsText size="md">
                      Banks are exposed to significant regulatory and consumer
                      lawsuits.
                    </ResultsText>
                    <ResultsText bold size="md">
                      Remediation costs stand at{" "}
                      {thousandSeparator(data.costsPerBank)} (
                      {currencyDenomination}) per bank.
                    </ResultsText>
                  </VerticalGroup>
                </InlineGroup>
                {data.teams.length > 0 ? (
                  <InlineGroup
                    verticalCenter
                    className={classNames({
                      "show-bids": context.clicks >= 2 && !context.noAnimations,
                      "no-show-options": context.clicks <= 1,
                    })}
                  >
                    <Icon
                      type="citizens"
                      colour="blue"
                      dynamicSize={{
                        min: "50px",
                        preferred: "5vw",
                        max: "300px",
                      }}
                    ></Icon>

                    <VerticalGroup wide className="ml-2">
                      <ResultsText bold size="md" className="pb-2">
                        Good Corporate Citizens
                      </ResultsText>
                      <ResultsText size="md">
                        Some banks were found to have undertaken pro-active
                        self-assessment of internal practices and these banks
                        had significantly less issues relating to lending
                        practices. These only incur{" "}
                        {thousandSeparator(data.remediationCosts)} (
                        {currencyDenomination}) in remediation costs and are:
                      </ResultsText>
                      <ResultsText size="md" bold>
                        <div
                          className={classNames({
                            "show-bids":
                              context.clicks >= 3 && !context.noAnimations,
                            "no-show-options": context.clicks <= 2,
                          })}
                        >
                          {data.teams.map((teamId: number, i) => {
                            const teamList = teams.find(
                              (t) => t.teamId === teamId,
                            );

                            return (
                              <span
                                key={i}
                                style={{ color: `${teamList?.colour}` }}
                              >
                                {(i ? ", " : "") + teamList?.teamName}
                              </span>
                            );
                          })}
                        </div>
                      </ResultsText>
                    </VerticalGroup>
                  </InlineGroup>
                ) : null}
              </VerticalGroup>
            </VerticalGroup>
          </InlineGroup>
        </VerticalGroup>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsRoyalCommission;
