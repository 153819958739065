import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import Image from "../../atoms/image/Image";
import API from "../../../services/api";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import Text from "../../atoms/text/Text";
import Icon from "../../atoms/icon/Icon";
import Clickable from "../../atoms/clickable/Clickable";
import "./FacilitatorList.scss";
import { ThemeColours } from "../../../types/theme";
import InlineError from "../../atoms/inlineerror/InlineError";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

interface FacilitatorListProps {
  selectedFacilitatorIds: string[];
  onFacilitatorChange?: (facilitatorIds: string[]) => void;
  error?: string;
  displayOnly?: boolean;
}

const FacilitatorList: React.FC<FacilitatorListProps> = ({
  selectedFacilitatorIds,
  onFacilitatorChange,
  error,
  displayOnly = false,
}) => {
  const [users, setUsers] = useState<API.UserResponse[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showFacilitators, setShowFacilitators] = useState(false);
  const [hoveredFacilitator, setHoveredFacilitator] = useState<string | null>(
    null,
  );
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);
      try {
        const response = await API.getUsers({
          status: "active",
          type: ["facilitator"],
        });
        setUsers(response);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);
  const orderedSelectedUsers = useMemo(() => {
    return selectedFacilitatorIds
      .map((id) => users.find((user) => user.id === id))
      .filter((user): user is API.UserResponse => user !== undefined);
  }, [selectedFacilitatorIds, users]);

  useEffect(() => {
    if (displayOnly) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setShowFacilitators(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [displayOnly]);

  const handleFacilitatorSelection = useCallback(
    (userId: string) => {
      if (displayOnly || !onFacilitatorChange) return;

      if (selectedFacilitatorIds.includes(userId)) {
        const newSelectedIds = selectedFacilitatorIds.filter(
          (id) => id !== userId,
        );
        onFacilitatorChange(newSelectedIds);
      } else {
        const newSelectedIds = [...selectedFacilitatorIds, userId];
        onFacilitatorChange(newSelectedIds);
      }
      setShowFacilitators(false);
    },
    [selectedFacilitatorIds, onFacilitatorChange, displayOnly],
  );

  const handleDragEnd = useCallback(
    (result: any) => {
      if (!result.destination || displayOnly || !onFacilitatorChange) return;

      const newOrder = Array.from(selectedFacilitatorIds);
      const [reorderedItem] = newOrder.splice(result.source.index, 1);
      newOrder.splice(result.destination.index, 0, reorderedItem);

      onFacilitatorChange(newOrder);
    },
    [selectedFacilitatorIds, onFacilitatorChange, displayOnly],
  );

  const getColor = (index: number): ThemeColours => {
    const colors: { selected: ThemeColours[] } = {
      selected: ["blue", "yellow", "green"],
    };
    return colors.selected[index % 3];
  };

  const availableFacilitators = useMemo(() => {
    return users.filter((user) => !selectedFacilitatorIds.includes(user.id));
  }, [users, selectedFacilitatorIds]);

  const renderSelectedFacilitators = () => (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="facilitator-list" direction="horizontal">
        {(provided) => (
          <InlineGroup
            spaceBetweenElements={6}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {orderedSelectedUsers.map((user, index) => (
              <Draggable
                key={user.id}
                draggableId={user.id}
                index={index}
                isDragDisabled={displayOnly}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                      opacity: snapshot.isDragging ? 0.8 : 1,
                    }}
                    className="facilitator-item-wrapper"
                    data-drag-disabled={displayOnly}
                  >
                    <VerticalGroup center spaceBetweenElements={1}>
                      <InlineGroup
                        className="selected-facilitator"
                        border={{
                          style: "solid",
                          color: getColor(index),
                          width: "3px",
                          rounded: "100%",
                        }}
                        onMouseEnter={
                          !displayOnly
                            ? () => setHoveredFacilitator(user.id)
                            : undefined
                        }
                        onMouseLeave={
                          !displayOnly
                            ? () => setHoveredFacilitator(null)
                            : undefined
                        }
                        onClick={
                          !displayOnly
                            ? () => handleFacilitatorSelection(user.id)
                            : undefined
                        }
                      >
                        <Image
                          src={user.imageUrl}
                          alt={`${user.firstName} ${user.lastName}`}
                          circle
                          cover
                          style={{ width: "100%", height: "100%" }}
                        />
                        {!displayOnly && hoveredFacilitator === user.id && (
                          <InlineGroup
                            className="remove-facilitator"
                            block
                            fullHeight
                            center
                            verticalCenter
                          >
                            <Icon type="close" colour="white" size={10} />
                          </InlineGroup>
                        )}
                      </InlineGroup>
                      <VerticalGroup center>
                        <Text size="md">{`${user.firstName} `}</Text>
                        <Text size="md">{`${user.lastName}`}</Text>
                      </VerticalGroup>
                    </VerticalGroup>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </InlineGroup>
        )}
      </Droppable>
    </DragDropContext>
  );

  return (
    <VerticalGroup className="facilitator-list" spaceBetweenElements={3}>
      <Text bold>Facilitators</Text>
      <div className="facilitator-list-container">
        <InlineGroup spaceBetweenElements={3} verticalCenter>
          {orderedSelectedUsers.length > 0 && renderSelectedFacilitators()}

          {!displayOnly && (
            <Clickable
              className="pl-4"
              as="button"
              ref={buttonRef}
              onClick={() => setShowFacilitators(!showFacilitators)}
            >
              <VerticalGroup spaceBetweenElements={1} center>
                <InlineGroup
                  className="add-facilitator-button"
                  border={{
                    style: "dashed",
                    color: "grey1",
                    width: "2px",
                    rounded: "100%",
                  }}
                  verticalCenter
                  center
                >
                  <Icon type="addUser" size={10} colour="blue" />
                </InlineGroup>
                <VerticalGroup center>
                  <Text colour="blue" size="md">
                    Add
                  </Text>
                  <Text colour="blue" size="md">
                    Facilitator
                  </Text>
                </VerticalGroup>
              </VerticalGroup>
            </Clickable>
          )}
        </InlineGroup>

        {!displayOnly && showFacilitators && (
          <div className="facilitator-dropdown" ref={dropdownRef}>
            {isLoading ? (
              <InlineGroup className="p-4">Loading...</InlineGroup>
            ) : availableFacilitators.length === 0 ? (
              <InlineGroup className="p-4">
                {users.length === 0
                  ? "No facilitators available for this client"
                  : "All facilitators have been selected"}
              </InlineGroup>
            ) : (
              <VerticalGroup>
                {availableFacilitators.map((user) => (
                  <InlineGroup
                    verticalCenter
                    block
                    spaceBetweenElements={2}
                    key={user.id}
                    className="dropdown-item"
                    onClick={() => handleFacilitatorSelection(user.id)}
                  >
                    <div className="facilitator-dropdown-image">
                      <Image
                        src={user.imageUrl}
                        alt={`${user.firstName} ${user.lastName}`}
                        circle
                        cover
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                    <VerticalGroup>
                      <Text>{`${user.firstName} ${user.lastName}`}</Text>
                    </VerticalGroup>
                  </InlineGroup>
                ))}
              </VerticalGroup>
            )}
          </div>
        )}
      </div>
      {!displayOnly && <InlineError active={!!error} message={error} block />}
    </VerticalGroup>
  );
};

export default FacilitatorList;
