/**
 * This function will tailor the array to expected size and fill up
 * empty slot with provided value
 */
export const resizeArray = <T>(arr: T[], size: number, fill: T): T[] => {
  return [...arr, ...Array(Math.max(size - arr.length, 0)).fill(fill)].slice(
    0,
    size,
  );
};

export const findDuplicate = <T>(arr: T[]): Set<T> => {
  const allValueSet = new Set<T>();
  const duplicatedValueSet = new Set<T>();

  for (const item of arr) {
    if (allValueSet.has(item)) {
      duplicatedValueSet.add(item);
    } else {
      allValueSet.add(item);
    }
  }

  return duplicatedValueSet;
};
