import React from "react";
import SimpleModal from "../../organisms/standard-modal/SimpleModal";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import Container from "../../atoms/page/Page";
import Radio from "../../atoms/form/input/Radio";
import Text from "../../atoms/text/Text";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import Button from "../../atoms/button/Button";

interface SortParticipantModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedSort: string;
  onSortChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSortSave: () => void;
}

const sortOptions = [
  { label: "First Name", value: "firstName" },
  { label: "Last Name", value: "lastName" },
  { label: "Email", value: "email" },
  { label: "Team", value: "team" },
];

const SortParticipantModal: React.FC<SortParticipantModalProps> = ({
  isOpen,
  onClose,
  selectedSort,
  onSortChange,
  onSortSave,
}) => {
  return (
    <SimpleModal size="scale" isOpen={isOpen} showCloseButton onClose={onClose}>
      <VerticalGroup wide spaceBetweenElements={2}>
        <Container fit>
          <VerticalGroup wide spaceBetweenElements={4}>
            <Text>Sort Participants By:</Text>
            {sortOptions.map((option) => (
              <Radio
                key={option.value}
                name="sortOption"
                label={option.label}
                value={option.value}
                checked={option.value === selectedSort}
                onChange={onSortChange}
              />
            ))}
          </VerticalGroup>
        </Container>
        <InlineGroup block right>
          <Button onClick={onSortSave}>Apply Sort</Button>
        </InlineGroup>
      </VerticalGroup>
    </SimpleModal>
  );
};

export default SortParticipantModal;
