import React from "react";
import ReactTooltip from "react-tooltip";
import useIsMobile from "../../../hooks/useIsMobile";

interface Props {
  id: string;
  children?: React.ReactNode;
}

const Tooltip: React.FC<Props> = ({ id, children }) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return null;
  }

  return (
    <ReactTooltip
      effect="solid"
      id={id}
      textColor="black"
      backgroundColor="white"
      borderColor="black"
      border
    >
      {children}
    </ReactTooltip>
  );
};

export default Tooltip;
