import React from "react";
import ConfirmModal from "../../../../organisms/confirm-modal/ConfirmModal";
import { pluralise } from "../../../../../lib/pluralise";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  participantsWithoutTablets: number;
}

function PublishWarningModal({
  isOpen,
  onClose,
  onConfirm,
  participantsWithoutTablets,
}: Props) {
  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={onConfirm}
      title={`Confirm publish configuration`}
      description={`You have ${participantsWithoutTablets} ${pluralise("participant", participantsWithoutTablets)} without ${pluralise("a tablet", participantsWithoutTablets, "tablets")}. Are you sure you want to publish the configuration?`}
      confirmTitle="Confirm"
    />
  );
}

export default PublishWarningModal;
