import classNames from "classnames";
import React from "react";
import Icon, { IconType } from "../../atoms/icon/Icon";
import Text from "../../atoms/text/Text";
import Clickable from "../clickable/Clickable";

import Theme from "../../../styles/_theme.module.scss";
import "./Ribbon.scss";
import VerticalGroup from "../verticalgroup/VerticalGroup";

interface Props {
  active: boolean;
  title: string;
  description?: string;
  icon?: IconType;
  className?: string;
  first?: boolean;
  last?: boolean;
  onClick?: (e: React.MouseEvent<any>) => void;
  compact?: boolean;
  disabled?: boolean;
}

function GameProgressBarSegment({
  active,
  title,
  description,
  icon,
  className,
  first,
  last,
  onClick,
  compact,
  disabled,
}: Props) {
  return (
    <Clickable
      className={classNames("ribbon", className, {
        active,
        first,
        last,
        compact,
        disabled,
      })}
      onClick={onClick}
    >
      {icon && <Icon type={icon} fill={active ? Theme.white : Theme.blue} />}
      <VerticalGroup>
        <Text size="sm">{title}</Text>
        {description && (
          <Text size="xs" className="mt-1">
            {description}
          </Text>
        )}
      </VerticalGroup>
    </Clickable>
  );
}

export default GameProgressBarSegment;
