import React, { useCallback } from "react";
import ChallengeModal from "../../organisms/challenge-modal/ChallengeModal";

import useAPIRequest from "../../../hooks/useAPIRequest";
import API from "../../../services/api";
import ConfirmModal from "../../organisms/confirm-modal/ConfirmModal";
import { pluralise } from "../../../lib/pluralise";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  simulation: API.SimulationResponse;
}

function DeleteSimulation({ isOpen, onComplete, onClose, simulation }: Props) {
  const callback = useCallback(
    () => API.deleteSimulation(simulation.id),
    [simulation],
  );

  const [{ inProgress, error }, doAPIRequest] = useAPIRequest(callback, null);

  const handleConfirm = () => {
    doAPIRequest(onComplete);
  };

  if (simulation.eventsCount > 0) {
    return (
      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onClose}
        title="Delete events"
        description={`This simulation cannot be deleted as it is being used by ${simulation.eventsCount} ${pluralise("event", simulation.eventsCount)}. Please make sure it is not in use before deleting.`}
        confirmTitle={"Ok"}
      />
    );
  }

  return (
    <ChallengeModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      challengeQuestion="This action is irreversible. Please type the word DELETE below to delete this simulation."
      challengeAnswer="DELETE"
      challengeAnswerWarning="Please type the word DELETE in CAPS to delete the simulation."
      title={`Delete simulation ${simulation.generalSetup.name}?`}
      confirmTitle="Delete"
      error={error && error.message}
      warning
    />
  );
}

export default DeleteSimulation;
