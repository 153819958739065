import React, { useMemo } from "react";
import classNames from "classnames";

import { DragAndDropItem } from "./DraggableItemsPool";
import DroppableArea from "./DroppableArea";
import { resizeArray } from "../../pages/simulations/lib/array";

interface Props {
  items?: Array<DragAndDropItem | undefined>;
  readOnly?: boolean;
  size: number;
  itemsPerPage?: number;
}

const Dropzone: React.FC<Props> = ({
  items,
  size,
  readOnly,
  itemsPerPage = 6,
}) => {
  const pages = useMemo(() => {
    const resizedItems = resizeArray(items ?? [], size, undefined);
    const pages = [];
    for (let i = 0; i < resizedItems.length; i += itemsPerPage) {
      const page = resizedItems.slice(i, i + itemsPerPage);
      pages.push(page);
    }
    return pages;
  }, [items, itemsPerPage, size]);

  return (
    <div className={classNames("drop-zone-container")}>
      {pages.map((page, pageIndex) => (
        <div key={pageIndex} className={classNames("drop-zone-page")}>
          {page.map((item, itemIndex) => {
            const index = itemIndex + pageIndex * itemsPerPage;
            const key = item && item.id ? item.id : index;
            return (
              <DroppableArea
                key={key}
                index={index}
                item={item}
                readOnly={readOnly}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default Dropzone;
