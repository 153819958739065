import React, { useMemo } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections, { SectionData } from "../tables/TableWithSections";
import {
  formatBps,
  formatRank,
  formatValue,
  percentage,
} from "../utils/formatters";
import getTeamIdsAsFields from "../utils/getTeamIdsAsFields";
import getTeamName from "../utils/getTeamName";
import { pick } from "dot-object";
import useModelQuery from "../utils/useModelQuery";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.MarketShareResponse["marketShare"];
  teams: API.GameConnection[];
  prefix: string;
  hideNPS?: boolean;
  primaryAttrition?: string;
  secondaryAttrition?: string;
  totalLoansInTheMarketName?: string;
  headlinePricingType?: "dollars" | "bps";
  secondaryLeversHeading?: string;
  secondaryPricingType?: "bps" | "dollars" | "value";
  tertiaryLeversHeading?: string;
  quaternaryLeversHeading?: string;
  type: string;
}

const transformTeamValuesArray = (
  teamValues: { id: number; value: number }[],
) => {
  return teamValues.reduce<{ [team: string]: number }>((acc, teamValue) => {
    acc[teamValue.id] = teamValue.value;
    return acc;
  }, {});
};

const transformTeamValuesArrayToIndex = (
  teamValues: { id: number; value: number }[],
) => {
  return teamValues.reduce<{ [team: string]: number }>(
    (acc, teamValue, index) => {
      acc[index + 1] = teamValue.value;
      return acc;
    },
    {},
  );
};

const rankFieldFormatter = (fields: string[]) => (field: string) =>
  fields.includes(field) ? `${formatRank(parseInt(field))}` : null;

const MarketCalculations: React.FC<Props> = ({
  configuration,
  data,
  teams,
  prefix,
  hideNPS,
  primaryAttrition,
  secondaryAttrition,
  totalLoansInTheMarketName,
  headlinePricingType = "bps",
  secondaryLeversHeading = "Secondary Levers",
  tertiaryLeversHeading = "Project Impacts",
  quaternaryLeversHeading = "Deal Impacts",
  secondaryPricingType = headlinePricingType,
  type,
}) => {
  const { numberOfTeams, numberOfCpuTeams } = configuration;

  const totalNumberOfTeams = numberOfTeams + numberOfCpuTeams;
  const fields = useMemo(
    () => getTeamIdsAsFields(totalNumberOfTeams),
    [totalNumberOfTeams],
  );

  const { openIfTable } = useModelQuery();

  const sections: SectionData[] = [
    {
      fields: [`${prefix}.marketGrowth`],
      values: data,
      formatter: (val: number) => percentage(val) ?? null,
    },
    {
      name: totalLoansInTheMarketName ?? "Total Loans In Market",
      fields: [
        `${prefix}.totalLoansInMarket.openingBalance`,
        `${prefix}.totalLoansInMarket.annualGrowth`,
        `${prefix}.totalLoansInMarket.secondaryGrowth`,
        `${prefix}.totalLoansInMarket.attrition`,
        `${prefix}.totalLoansInMarket.closingBalance`,
      ],
      totalFields: [`${prefix}.totalLoansInMarket.closingBalance`],
      values: data,
      formatter: (val: number, field: string) => {
        if (field === `${prefix}.totalLoansInMarket.attrition`) {
          return formatValue(val * -1);
        }
        return null;
      },
    },
    {
      name: "Market Share Up For Grabs",
      fields: [
        `${prefix}.marketShareUpForGrabs.marketDeclineEvenlyAllocated`,
        `${prefix}.marketShareUpForGrabs.marketGrowthUseAllocation`,
        `${prefix}.marketShareUpForGrabs.playerAttrition`,
      ]
        .concat(
          secondaryAttrition
            ? `${prefix}.marketShareUpForGrabs.playerAttritionSecondary`
            : [],
        )
        .concat(`${prefix}.marketShareUpForGrabs.total`),
      totalFields: [`${prefix}.marketShareUpForGrabs.total`],
      values: data,
      fieldFormatter: (field: string) => {
        if (field === `${prefix}.marketShareUpForGrabs.playerAttrition`) {
          return primaryAttrition ?? null;
        }
        if (
          field === `${prefix}.marketShareUpForGrabs.playerAttritionSecondary`
        ) {
          return secondaryAttrition ?? null;
        }
        return null;
      },
      formatter: (val: number, field: string) => {
        if (
          field ===
          `${prefix}.marketShareUpForGrabs.marketDeclineEvenlyAllocated`
        ) {
          return formatValue(val * -1);
        }
        return null;
      },
    },
    {
      name: "Headline Pricing",
      fields: fields,
      fieldFormatter: (field: string) =>
        fields.includes(field)
          ? getTeamName(parseInt(field), numberOfTeams, teams)
          : null,
      values: data.map((d) => ({
        ...transformTeamValuesArray(pick(prefix, d).headlinePricing),
      })),
      formatter: (val: number) =>
        headlinePricingType === "bps" ? formatBps(val, 2) : formatValue(val, 2),
    },
    {
      name: secondaryLeversHeading,
      fields: fields,
      fieldFormatter: (field: string) =>
        fields.includes(field)
          ? getTeamName(parseInt(field), numberOfTeams, teams)
          : null,
      values: data.map((d) => ({
        ...transformTeamValuesArray(pick(prefix, d).secondaryLevers),
      })),
      formatter: (val: number) =>
        secondaryPricingType === "bps"
          ? formatBps(val, 2, 2)
          : formatValue(val, 2, 2),
    },
    {
      name: tertiaryLeversHeading,
      fields: fields,
      fieldFormatter: (field: string) =>
        fields.includes(field)
          ? getTeamName(parseInt(field), numberOfTeams, teams)
          : null,
      values: data.map((d) => ({
        ...transformTeamValuesArray(pick(prefix, d).tertiaryLevers),
      })),
      formatter: (val: number) =>
        headlinePricingType === "bps"
          ? formatBps(val, 2, 2)
          : formatValue(val, 2, 2),
    },
    {
      name: quaternaryLeversHeading,
      fields: fields,
      fieldFormatter: (field: string) =>
        fields.includes(field)
          ? getTeamName(parseInt(field), numberOfTeams, teams)
          : null,
      values: data.map((d) => ({
        ...transformTeamValuesArray(pick(prefix, d).quaternaryLevers),
      })),
      formatter: (val: number) =>
        headlinePricingType === "bps"
          ? formatBps(val, 2, 2)
          : formatValue(val, 2, 2),
    },
    {
      name: "Market Share Allocated",
      fields: [...fields, "total", "variance"],
      totalFields: ["total", "variance"],
      fieldFormatter: (field: string) =>
        fields.includes(field)
          ? getTeamName(parseInt(field), numberOfTeams, teams)
          : null,
      values: data.map((d) => ({
        variance: pick(prefix, d).marketShare.allocated.variance,
        total: pick(prefix, d).marketShare.allocated.total,
        ...transformTeamValuesArray(
          pick(prefix, d).marketShare.allocated.values,
        ),
      })),
    },
    {
      name: "Notional Pricing",
      fields: [
        ...fields,
        `${prefix}.notionalPricing.mean`,
        `${prefix}.notionalPricing.standardDeviation`,
        `${prefix}.notionalPricing.lowestPrice`,
        `${prefix}.notionalPricing.highestPrice`,
      ],
      fieldFormatter: (field: string) =>
        fields
          .concat(["mean", "standardDeviation", "lowestPrice", "highestPrice"])
          .includes(field)
          ? getTeamName(parseInt(field), numberOfTeams, teams)
          : null,
      totalFields: [
        `${prefix}.notionalPricing.mean`,
        `${prefix}.notionalPricing.standardDeviation`,
        `${prefix}.notionalPricing.lowestPrice`,
        `${prefix}.notionalPricing.highestPrice`,
      ],
      values: data.map((d) => ({
        ...d,
        ...transformTeamValuesArray(pick(prefix, d).notionalPricing.values),
      })),
      formatter: (val: number) =>
        headlinePricingType === "bps"
          ? formatBps(val, 2, 2)
          : formatValue(val, 2, 2),
    },
    {
      name: "Normal Distribution",
      fields: [...fields, "unallocatedMarketShare"],
      totalFields: ["unallocatedMarketShare"],
      formatter: (val: number) => percentage(val),
      fieldFormatter: (field: string) =>
        fields.includes(field)
          ? `${formatRank(parseInt(field))} (${percentage(data[0].normalDistributionPercentages[parseInt(field) - 1])})`
          : null,
      values: data.map((d) => ({
        unallocatedMarketShare: pick(prefix, d).normalDistribution
          .unallocatedMarketShare,
        ...transformTeamValuesArrayToIndex(
          pick(prefix, d).normalDistribution.normalDistribution,
        ),
      })),
    },
    {
      name: "Market Share Allocations",
      fields: [...fields, "total"],
      totalFields: ["total"],
      formatter: (val: number) => percentage(val),
      fieldFormatter: rankFieldFormatter(fields),
      values: data.map((d) => ({
        total: pick(prefix, d).marketShare.total,
        ...transformTeamValuesArrayToIndex(
          pick(prefix, d).marketShare.allocations,
        ),
      })),
    },
    {
      name: "Equal Market Share",
      fields: [`${prefix}.marketShare.equalShare`],
      formatter: (val: number) => percentage(val),
      fieldFormatter: () => `${totalNumberOfTeams} Teams In Play`,
      values: data,
    },
    {
      name: "Hybrid Market Share Allocation",
      fields: [
        `${prefix}.marketShare.dynamicMarketShareAllocation`,
        `${prefix}.marketShare.equalMarketShareAllocation`,
      ],
      formatter: (val: number) => percentage(val),
      values: data,
    },
    {
      name: "Hybrid Method: Market Share Allocation",
      fields: [...fields, "total"],
      totalFields: ["total"],
      formatter: (val: number) => percentage(val),
      fieldFormatter: rankFieldFormatter(fields),
      values: data.map((d) => ({
        total: pick(prefix, d).marketShare.hybridMarketShareAllocationsTotal,
        ...transformTeamValuesArrayToIndex(
          pick(prefix, d).marketShare.hybridMarketShareAllocations,
        ),
      })),
    },
    {
      name: "Final Market Share Allocations",
      fields: [...fields, "total"],
      totalFields: ["total"],
      formatter: (val: number) => percentage(val),
      fieldFormatter: (field: string) =>
        fields.includes(field)
          ? getTeamName(parseInt(field), numberOfTeams, teams)
          : null,
      values: data.map((d) => ({
        total: pick(prefix, d).marketShare.hybridMarketShareAllocationsTotal,
        ...transformTeamValuesArray(
          pick(prefix, d).marketShare.hybridMarketShareAllocations,
        ),
      })),
    },
  ];

  const npsSection: SectionData[] = hideNPS
    ? []
    : [
        {
          name: "NPS Pricing Impact",
          fields,
          fieldFormatter: (field: string) =>
            fields.includes(field)
              ? getTeamName(parseInt(field), numberOfTeams, teams)
              : null,
          values: data.map((d) =>
            transformTeamValuesArray(pick(prefix, d).npsPricingImpact),
          ),
          formatter: (val: number) => formatValue(val, 2, 2),
        },
      ];

  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <TableWithSections
          data={sections.concat(npsSection)}
          openOnInit={openIfTable(type)}
        />
      </VerticalGroup>
    </>
  );
};

export default MarketCalculations;
