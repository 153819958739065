import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import Icon from "../../atoms/icon/Icon";
import Table from "../../atoms/table/Table";

import EmptyList from "../../organisms/empty-list/EmptyList";
import DeleteSimulation from "./SimulationDelete";
import Clickable from "../../atoms/clickable/Clickable";
import { STICKY_BAR_HEIGHT } from "./SimulationList";
import { formatSimulationState } from "./lib/formatters";
import ClientAvatarList from "../../molecules/client-avatar-list/ClientAvatarList";
import API from "../../../services/api";
import Tooltip from "../../atoms/tooltip/Tooltip";
interface Props {
  simulations: API.SimulationResponse[];
  refreshSimulations: () => void;
}

interface SimulationState {
  deleteModalOpen: boolean;
  simulationSelected: API.SimulationResponse | null;
}

const TOOLTIP_ID = "simulation-table-tooltip";

function SimulationListTable({ simulations, refreshSimulations }: Props) {
  const history = useHistory();
  const [{ deleteModalOpen, simulationSelected }, setSimulationState] =
    useState<SimulationState>({
      deleteModalOpen: false,
      simulationSelected: null,
    });

  const onSimulationDeleteComplete = useCallback(() => {
    setSimulationState((prevState) => ({
      ...prevState,
      deleteModalOpen: false,
      simulationSelected: null,
    }));
    refreshSimulations();
  }, [refreshSimulations]);

  const handleSimulationClick = useCallback(
    (simulation: API.SimulationResponse) => (event: any) => {
      event.stopPropagation();
      event.preventDefault();
      history.push(`/simulations/${simulation.id}/view`);
    },
    [history],
  );

  const handleCloneClick = useCallback(
    (simulation: API.SimulationResponse) => async (event: React.MouseEvent) => {
      event.stopPropagation();
      const newSimulation = await API.cloneSimulation(simulation.id);
      history.push(`/simulations/${newSimulation.id}/edit`);
    },
    [history],
  );

  const handleDeleteClick = useCallback(
    (simulation: API.SimulationResponse) => (event: any) => {
      event.stopPropagation();
      setSimulationState({
        deleteModalOpen: true,
        simulationSelected: simulation,
      });
    },
    [],
  );

  return (
    <>
      <Tooltip id={TOOLTIP_ID} />
      <Tooltip id="clone-simulation">
        <>
          <b>Clone Simulation</b>
          <p>
            Create a new simulation using the details <br /> of this simulation
            as a starting point.
          </p>
        </>
      </Tooltip>
      {deleteModalOpen && !!simulationSelected && (
        <DeleteSimulation
          isOpen={deleteModalOpen}
          onClose={() =>
            setSimulationState((prevState) => ({
              ...prevState,
              brandingModalOpen: false,
              simulationSelected: null,
            }))
          }
          onComplete={onSimulationDeleteComplete}
          simulation={simulationSelected}
        />
      )}
      {!simulations.length && (
        <EmptyList icon="simulations" message="There are no simulations yet" />
      )}
      {!!simulations.length && (
        <Table stickyHeader stickyTop={STICKY_BAR_HEIGHT} clickable>
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Clients</th>
              <th># Events Using</th>
              <th className="right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {simulations.map((simulation) => (
              <tr
                key={simulation.id}
                data-test={simulation.id}
                onClick={handleSimulationClick(simulation)}
              >
                <td>{simulation.generalSetup.name}</td>
                <td>{formatSimulationState(simulation)}</td>
                <td>
                  <ClientAvatarList clients={simulation.clients} />
                </td>
                <td>{simulation.eventsCount}</td>
                <td className="right">
                  <Icon
                    type="search"
                    tt={{ content: "View Simulation", id: TOOLTIP_ID }}
                  />
                  <Clickable
                    data-test={`clone-${simulation.id}`}
                    onClick={handleCloneClick(simulation)}
                  >
                    <Icon
                      type="copy"
                      tt={{
                        content: "Clone Simulation",
                        id: "clone-simulation",
                      }}
                    />
                  </Clickable>
                  <Clickable
                    data-test={`delete-${simulation.id}`}
                    onClick={handleDeleteClick(simulation)}
                  >
                    <Icon
                      type="trash"
                      tt={{ content: "Delete Simulation", id: TOOLTIP_ID }}
                    />
                  </Clickable>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}

export default SimulationListTable;
