interface Step {
  text: string;
  imageOverride?: string;
}

interface VirtualMeetingIntroProps {
  defaultHeading: string;
  image: string;
  steps: Step[];
  className?: string;
}

import React, { useCallback } from "react";
import Card from "../../../atoms/card/Card";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import EditableReportingHeading from "../components/EditableReportingHeading";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Image from "../../../atoms/image/Image";
import ResultsText from "../results-text/ResultsText";
import ReportingSubHeading from "../components/ReportingSubHeading";
import { useResultsContext } from "../context/context";
import classNames from "classnames";
import "./VirtualMeetingIntro.scss";

export const virtualMeetingIntroMaxClicks = 4;

const VirtualMeetingIntro: React.FC<VirtualMeetingIntroProps> = ({
  defaultHeading,
  image,
  steps,
  className,
}) => {
  const context = useResultsContext();
  const maxClicks = steps.length;

  const onNext = useCallback(() => {
    if (context.clicks < maxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context, maxClicks]);

  const getCurrentImage = () => {
    if (context.clicks === 0) return image;
    return steps[context.clicks - 1]?.imageOverride || image;
  };

  const showStepOne = context.noAnimations || context.clicks >= 1;
  const showStepTwo = context.noAnimations || context.clicks >= 2;
  const showStepThree = context.noAnimations || context.clicks >= 3;
  const showStepFour = context.noAnimations || context.clicks >= 4;

  return (
    <RoundResultsContainer onNext={onNext}>
      <Card
        className={classNames("virtual-meeting-slide", className)}
        wide
        fullHeight
      >
        <VerticalGroup wide center full spread>
          <EditableReportingHeading defaultHeading={defaultHeading} />
          <ReportingSubHeading subHeading="Important items for an optimal learning experience" />

          <VerticalGroup
            full
            wide
            center
            verticalCenter
            spaceBetweenElements={10}
          >
            <Image
              className="current-step-image"
              alt="Step visualization"
              src={getCurrentImage()}
              dynamicSize={{
                min: "600px",
                preferred: "90%",
                max: "3500px",
              }}
              contain
            />

            <VerticalGroup spread width="90%" half wide>
              <VerticalGroup
                className={classNames("virtual-meeting-step-content", {
                  show: showStepOne,
                })}
              >
                <ResultsText bold colour="primaryDark" size="lg">
                  {"1.) "} {steps[0].text}
                </ResultsText>
              </VerticalGroup>

              <VerticalGroup>
                <ResultsText
                  className={classNames("virtual-meeting-step-content", {
                    show: showStepTwo,
                  })}
                  bold
                  colour="primaryDark"
                  size="lg"
                >
                  {"2.) "} {steps[1].text}
                </ResultsText>
              </VerticalGroup>

              <VerticalGroup
                className={classNames("virtual-meeting-step-content", {
                  show: showStepThree,
                })}
              >
                <ResultsText bold colour="primaryDark" size="lg">
                  {"3.) "} {steps[2].text}
                </ResultsText>
              </VerticalGroup>

              <VerticalGroup
                className={classNames("virtual-meeting-step-content", {
                  show: showStepFour,
                })}
              >
                <ResultsText bold colour="primaryDark" size="lg">
                  {"4.) "} {steps[3].text}
                </ResultsText>
              </VerticalGroup>
            </VerticalGroup>
          </VerticalGroup>
        </VerticalGroup>
      </Card>
    </RoundResultsContainer>
  );
};

export default VirtualMeetingIntro;
