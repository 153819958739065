import React, { useCallback, useState } from "react";
import { v4 as uuid } from "uuid";
import GameAPI from "../../../services/gameApi";
import BroadcastMessageModal from "./BroadcastMessageModal";
import { useBroadcastMessages } from "../../../hooks/useBroadcastMessages";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  eventId: string;
  broadcastMessage: API.GameStatus["broadcastMessage"];
}

export default function GameControllerBroadcastMessage(props: Props) {
  const { isOpen, onClose, eventId, broadcastMessage } = props;
  const [error, setError] = useState<string | null>(null);
  const { inProgress, data } = useBroadcastMessages();

  const onSave = useCallback(
    async (data: API.BroadcastMessageRequest) => {
      try {
        await GameAPI.broadcastMessage(eventId, {
          id: uuid(),
          title: data.title,
          message: data.message,
          link: data.link,
          linkCTA: data.linkCTA,
        });
      } catch (error) {
        setError(error.message);
      }
    },
    [eventId],
  );

  const removeBroadcastMessage = useCallback(async () => {
    try {
      await GameAPI.deleteBroadcast(props.eventId);
    } catch (error) {
      setError(error.message);
    }
  }, [props.eventId]);

  if (inProgress) {
    return null;
  }

  return (
    <BroadcastMessageModal
      key={broadcastMessage ? broadcastMessage.id : "new"}
      modalTitle="Broadcast Message"
      modalMessage="Broadcast a message to all participants"
      error={error}
      isOpen={isOpen}
      onClose={onClose}
      onSave={onSave}
      onDelete={removeBroadcastMessage}
      broadcastMessage={broadcastMessage}
      preSavedMessages={data}
      confirmButtonText="Send Message"
    />
  );
}
