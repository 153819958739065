import React, { useMemo } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";
import { percentage } from "../utils/formatters";
import pluckResultsByType from "../utils/pluckResultsByType";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["customerAttrition"];
}

const ModelTeamResultsCustomerAttrition: React.FC<Props> = ({ data }) => {
  const sections = useMemo(
    () => [
      {
        fields: [
          "HOME_LOANS.propietaryHomeLoans",
          "HOME_LOANS.brokerHomeLoans",
          "BUSINESS_LOANS",
          "CREDIT_CARDS",
          "WEALTH_MANAGEMENT",
          "INSTITUTIONAL_BANKING",
          "OFFSHORE_BANK",
          "DEPOSITS",
        ],
        values: data,
      },
    ],
    [data],
  );

  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <TableWithSections
          header="Adjustments"
          data={[
            {
              fields: [
                "HOME_LOANS.propietaryHomeLoans",
                "HOME_LOANS.brokerHomeLoans",
                "BUSINESS_LOANS",
                "CREDIT_CARDS",
                "WEALTH_MANAGEMENT",
                "INSTITUTIONAL_BANKING",
                "OFFSHORE_BANK",
                "DEPOSITS",
              ],
              values: pluckResultsByType(data, "adjustments"),
            },
          ]}
          formatter={(val) => percentage(val)}
        />
        <TableWithSections
          header="Final Attrition"
          data={sections}
          formatter={(val) => percentage(val)}
        />
      </VerticalGroup>
    </>
  );
};

export default ModelTeamResultsCustomerAttrition;
