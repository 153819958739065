import React, { useCallback, useEffect, useState } from "react";
import { useSpecialProjects } from "../../../../hooks/useSpecialProjects";
import Banner from "../../../atoms/banner/Banner";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import SpecialProjectsSelect from "../../simulations/steps/special-projects/SpecialProjectsSelect";
import api from "../../../../services/api";
import SimulationsThatNeedManualModificationModal from "./components/SimulationsThatNeedManualModificationModal";

function SpecialProjects() {
  const { data: projects, inProgress, error, refresh } = useSpecialProjects();

  const [projectsData, setProjectsData] = useState<API.SpecialProject[] | null>(
    null,
  );
  useEffect(() => {
    if (projects) {
      setProjectsData(projects);
    }
  }, [projects]);
  const [
    simulationsThatNeedManualModification,
    setSimulationsThatNeedManualModification,
  ] = useState<API.SimulationsThatNeedManualModification[] | null>(null);

  const handleSpecialProjectsUpdate = useCallback(
    async (projectId: string, data: Partial<API.SpecialProject>) => {
      const response = await api.updateProjectConfig(projectId, data);
      setProjectsData((prev) => {
        if (!prev) {
          return prev;
        }
        return prev.map((project) => {
          if (project.id === projectId) {
            return { ...project, ...data };
          }
          return project;
        });
      });
      if (response.simulationsThatNeedManualModification?.length > 0) {
        setSimulationsThatNeedManualModification(
          response.simulationsThatNeedManualModification,
        );
      }
    },
    [],
  );

  const handleSpecialProjectsImpactUpdate = useCallback(
    async (
      projectId: string,
      impactId: string,
      data: Partial<API.SpecialProjectImpact>,
    ) => {
      const response = await api.updateProjectImpactConfig(
        projectId,
        impactId,
        data,
      );
      setProjectsData((prev) => {
        if (!prev) {
          return prev;
        }
        return prev.map((project) => {
          if (project.id === projectId) {
            return {
              ...project,
              impacts: project.impacts.map((impact) => {
                if (impact.id === impactId) {
                  return { ...impact, ...data };
                }
                return impact;
              }),
            };
          }
          return project;
        });
      });
      if (response.simulationsThatNeedManualModification?.length > 0) {
        setSimulationsThatNeedManualModification(
          response.simulationsThatNeedManualModification,
        );
      }
      refresh();
    },
    [refresh],
  );

  return (
    <>
      <h3>Special Project Settings</h3>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {projectsData && (
        <VerticalGroup className="mt-4">
          <SpecialProjectsSelect
            projects={projectsData}
            onUpdateContent={handleSpecialProjectsUpdate}
            onUpdateImpact={handleSpecialProjectsImpactUpdate}
          />
        </VerticalGroup>
      )}
      {simulationsThatNeedManualModification &&
        simulationsThatNeedManualModification.length > 0 && (
          <SimulationsThatNeedManualModificationModal
            simulationsThatNeedManualModification={
              simulationsThatNeedManualModification
            }
            onClose={() => setSimulationsThatNeedManualModification(null)}
            stepTitle="Special Projects"
          />
        )}
    </>
  );
}

export default SpecialProjects;
