import React, { useCallback } from "react";

import API from "../../../../../services/api";
import useAPIRequest from "../../../../../hooks/useAPIRequest";
import ConfirmModal from "../../../../organisms/confirm-modal/ConfirmModal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  tablet: API.TabletDetailResponse;
}

function DeleteTabletModal({ isOpen, onClose, tablet, onConfirm }: Props) {
  const callback = useCallback(() => {
    return API.deleteTablet(tablet.tabletId);
  }, [tablet.tabletId]);

  const [{ inProgress, error }, doAPIRequest] = useAPIRequest(callback, null);

  const handleConfirm = useCallback(() => {
    doAPIRequest(onConfirm);
  }, [doAPIRequest, onConfirm]);

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      title="Delete this tablet?"
      description={
        <>
          Are you sure you want to delete the following tablet: <br />
          <b>{tablet.tabletId}</b>
          <br />
          This cannot be reverted.
        </>
      }
      confirmTitle="Delete"
      error={error && error.message}
      warning
    />
  );
}

export default DeleteTabletModal;
