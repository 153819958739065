export const getDotColour =
  (creditSettings: ModelAPI.Reporting.RoundData[]) =>
  (index: number, team: number): string | undefined => {
    if (!creditSettings?.[index]) {
      return "white";
    }
    const setting: number = creditSettings[index][`team${team}`] as number;
    if (!setting) {
      return "white";
    }
    if (setting > 0) {
      return "black";
    }
    if (setting < 0) {
      return "red";
    }
    return "white";
  };
