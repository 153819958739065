import React from "react";

import Card from "../../../../atoms/card/Card";
import Text from "../../../../atoms/text/Text";
import DisplayField from "../../../../atoms/form/display-field/DisplayField";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import Clickable from "../../../../atoms/clickable/Clickable";
import Icon from "../../../../atoms/icon/Icon";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import { getEnabledDivisions } from "../business";
import AvoidPageBreak from "../../../../atoms/printable/components/AvoidPageBreak";

interface Props {
  data: API.SimulationResponse["businessSetup"];
  onClickEdit?: () => void;
}

const Display: React.FC<Props> = ({ data, onClickEdit }) => {
  return data ? (
    <Card wide>
      <AvoidPageBreak>
        <InlineGroup verticalCenter>
          <h4>Business Settings</h4>
          {onClickEdit ? (
            <Clickable
              data-test={"edit-simulation-business-step"}
              onClick={onClickEdit}
            >
              <Icon
                type="edit"
                tip={{
                  content: "Edit Business",
                  id: "edit-business",
                }}
              />
            </Clickable>
          ) : null}
        </InlineGroup>
        <DisplayField
          stretch
          verticalCenter={false}
          label="List of divisions"
          value={
            <VerticalGroup spaceBetweenElements={3}>
              {getEnabledDivisions(data).map((division) => (
                <Text key={division} size="sm">
                  {division}
                </Text>
              ))}
            </VerticalGroup>
          }
        />
      </AvoidPageBreak>
    </Card>
  ) : null;
};

export default Display;
