import React, { ReactElement } from "react";
import ghost from "../../../assets/common/ghost.svg";
import Button from "../../atoms/button/Button";
import Text from "../../atoms/text/Text";
import Image from "../../atoms/image/Image";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import { Link } from "react-router-dom";

import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";

interface Props {
  message: string | ReactElement;
  secondaryMessage: string | ReactElement;
}

function ErrorSection({ message, secondaryMessage }: Props) {
  return (
    <VerticalGroup center verticalCenter full>
      <Image src={ghost} alt="ghost" heightSize={56} />
      <div className="mt-4">
        <Text colour="darkgrey" size="4xl" bold center className="mt-2">
          Whooops!
        </Text>
        <Text colour="secondaryDark3" size="lg" center className="mt-6">
          {message}
        </Text>
        <Text colour="secondaryDark3" size="lg" center className="mt-2">
          {secondaryMessage}
        </Text>
        <InlineGroup center className="mt-6">
          <Link to="/">
            <Button wide>Back to home</Button>
          </Link>
        </InlineGroup>
      </div>
    </VerticalGroup>
  );
}

export default ErrorSection;
