import React from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";
import { formatValue, percentage } from "../utils/formatters";
import pluckResultsByType from "../utils/pluckResultsByType";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["divisionalProfitAndLoss"];
}

const formatter = (val: number, field: string) => {
  if (
    [
      "interestExpense",
      "interestExpenseSecondary",
      "creditCardRewardCosts",
      "operatingExpenses",
      "lieExpenses",
      "totalExpenses",
      "taxationExpenses",
      "brokerCommissions",
      "otherOperatingExpenses",
    ].includes(field)
  ) {
    return formatValue(-1 * val);
  }
  return null;
};

const ModelTeamResultsDivisionalProfitAndLoss: React.FC<Props> = ({ data }) => {
  const homeLoanData = pluckResultsByType(data, "HOME_LOANS");
  const businessLoanData = pluckResultsByType(data, "BUSINESS_LOANS");
  const depositsData = pluckResultsByType(data, "DEPOSITS");
  const creditCardsData = pluckResultsByType(data, "CREDIT_CARDS");
  const wealthManagementData = pluckResultsByType(data, "WEALTH_MANAGEMENT");
  const offshoreBankData = pluckResultsByType(data, "OFFSHORE_BANK");
  const institutionalBankingData = pluckResultsByType(
    data,
    "INSTITUTIONAL_BANKING",
  );

  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <TableWithSections
          header="Home Loans"
          data={[
            {
              fields: [
                "interestIncome",
                "interestExpense",
                "interestExpenseSecondary",
                "netInterestIncome",
                "brokerCommissions",
                "otherBusinessIncome",
                "totalIncome",
                "operatingExpenses",
                "lieExpenses",
                "totalExpenses",
                "netProfitBeforeTax",
                "taxationExpenses",
                "netProfitAfterTax",
              ],
              totalFields: [
                "netInterestIncome",
                "totalIncome",
                "totalExpenses",
                "netProfitAfterTax",
              ],
              values: homeLoanData,
              formatter,
              fieldFormatter: (field: string) => {
                if (field === "interestExpenseSecondary") {
                  return "Amortisation of Premium";
                }
                return null;
              },
            },
            {
              name: "Financial Metrics",
              fields: [
                "averageEquity",
                "returnOnEquity",
                "netInterestMargin",
                "costToIncome",
                "loanImpairmentExpenses",
              ],
              values: pluckResultsByType(homeLoanData, "financialMetrics"),
              formatter: (val: number, field: string) =>
                field !== "averageEquity" ? percentage(val, 2) : null,
            },
          ]}
        />
        <TableWithSections
          header="Business Loans"
          data={[
            {
              fields: [
                "interestIncome",
                "interestExpense",
                "netInterestIncome",
                "otherBusinessIncome",
                "totalIncome",
                "operatingExpenses",
                "lieExpenses",
                "totalExpenses",
                "netProfitBeforeTax",
                "taxationExpenses",
                "netProfitAfterTax",
              ],
              totalFields: [
                "netInterestIncome",
                "totalIncome",
                "totalExpenses",
                "netProfitAfterTax",
              ],
              values: businessLoanData,
              formatter,
            },
            {
              name: "Financial Metrics",
              fields: [
                "averageEquity",
                "returnOnEquity",
                "netInterestMargin",
                "costToIncome",
                "loanImpairmentExpenses",
              ],
              values: pluckResultsByType(businessLoanData, "financialMetrics"),
              formatter: (val: number, field: string) =>
                field !== "averageEquity" ? percentage(val, 2) : null,
            },
          ]}
        />
        <TableWithSections
          header="Deposits"
          data={[
            {
              fields: [
                "interestIncome",
                "interestExpense",
                "interestExpenseSecondary",
                "netInterestIncome",
                "otherBusinessIncome",
                "totalIncome",
                "operatingExpenses",
                "totalExpenses",
                "netProfitBeforeTax",
                "taxationExpenses",
                "netProfitAfterTax",
              ],
              totalFields: [
                "netInterestIncome",
                "totalIncome",
                "totalExpenses",
                "netProfitAfterTax",
              ],
              values: depositsData,
              formatter,
              fieldFormatter: (field: string) => {
                if (field === "interestExpense") {
                  return "Interest Expense (Customers)";
                }
                if (field === "interestExpenseSecondary") {
                  return "Interest Expense (Fixed Asset Funding)";
                }
                return null;
              },
            },
            {
              name: "Financial Metrics",
              fields: [
                "averageEquity",
                "returnOnEquity",
                "netInterestMargin",
                "costToIncome",
                "loanImpairmentExpenses",
              ],
              values: pluckResultsByType(depositsData, "financialMetrics"),
              formatter: (val: number, field: string) =>
                field !== "averageEquity" ? percentage(val, 2) : null,
            },
          ]}
        />
        <TableWithSections
          header="Credit Cards"
          data={[
            {
              fields: [
                "interestIncome",
                "interestExpense",
                "netInterestIncome",
                "creditCardFees",
                "creditCardInterchangeIncome",
                "creditCardRewardCosts",
                "totalIncome",
                "operatingExpenses",
                "lieExpenses",
                "totalExpenses",
                "netProfitBeforeTax",
                "taxationExpenses",
                "netProfitAfterTax",
              ],
              totalFields: [
                "netInterestIncome",
                "totalIncome",
                "totalExpenses",
                "netProfitAfterTax",
              ],
              values: creditCardsData,
              formatter,
            },
            {
              name: "Financial Metrics",
              fields: [
                "averageEquity",
                "returnOnEquity",
                "netInterestMargin",
                "costToIncome",
                "loanImpairmentExpenses",
              ],
              values: pluckResultsByType(creditCardsData, "financialMetrics"),
              formatter: (val: number, field: string) =>
                field !== "averageEquity" ? percentage(val, 2) : null,
            },
          ]}
        />
        <TableWithSections
          header="Wealth Management"
          data={[
            {
              fields: [
                "managementFeeIncome",
                "totalIncome",
                "otherOperatingExpenses",
                "totalExpenses",
                "netProfitBeforeTax",
                "taxationExpenses",
                "netProfitAfterTax",
              ],
              totalFields: [
                "totalIncome",
                "totalExpenses",
                "netProfitAfterTax",
              ],
              values: wealthManagementData,
              formatter,
            },
            {
              name: "Financial Metrics",
              fields: [
                "averageEquity",
                "returnOnEquity",
                "netManagementFee",
                "costToIncome",
                "loanImpairmentExpenses",
              ],
              values: pluckResultsByType(
                wealthManagementData,
                "financialMetrics",
              ),
              formatter: (val: number, field: string) =>
                field !== "averageEquity" ? percentage(val, 2) : null,
            },
          ]}
        />
        <TableWithSections
          header="Offshore Bank"
          data={[
            {
              fields: [
                "interestIncome",
                "interestExpense",
                "interestExpenseSecondary",
                "netInterestIncome",
                "otherBusinessIncome",
                "totalIncome",
                "operatingExpenses",
                "lieExpenses",
                "totalExpenses",
                "netProfitBeforeTax",
                "taxationExpenses",
                "netProfitAfterTax",
                "additionalCapitalInvestmentsIntoOffshoreBank",
                "capitalReturnedFromOffshoreBank",
              ],
              totalFields: [
                "netInterestIncome",
                "totalIncome",
                "totalExpenses",
                "netProfitAfterTax",
              ],
              values: offshoreBankData,
              formatter,
            },
            {
              name: "Financial Metrics",
              fields: [
                "averageEquity",
                "returnOnEquity",
                "roa",
                "obi",
                "costToIncome",
                "loanImpairmentExpenses",
              ],
              values: pluckResultsByType(offshoreBankData, "financialMetrics"),
              formatter: (val: number, field: string) =>
                field !== "averageEquity" ? percentage(val, 2) : null,
            },
          ]}
        />
        <TableWithSections
          header="Institutional Banking"
          data={[
            {
              fields: [
                "interestIncome",
                "interestExpense",
                "netInterestIncome",
                "otherBusinessIncome",
                "totalIncome",
                "operatingExpenses",
                "lieExpenses",
                "totalExpenses",
                "netProfitBeforeTax",
                "taxationExpenses",
                "netProfitAfterTax",
              ],
              totalFields: [
                "netInterestIncome",
                "totalIncome",
                "totalExpenses",
                "netProfitAfterTax",
              ],
              values: institutionalBankingData,
              formatter,
            },
            {
              name: "Financial Metrics",
              fields: [
                "averageEquity",
                "returnOnEquity",
                "netInterestMargin",
                "costToIncome",
                "loanImpairmentExpenses",
              ],
              values: pluckResultsByType(
                institutionalBankingData,
                "financialMetrics",
              ),
              formatter: (val: number, field: string) =>
                field !== "averageEquity" ? percentage(val, 2) : null,
            },
          ]}
        />
      </VerticalGroup>
    </>
  );
};

export default ModelTeamResultsDivisionalProfitAndLoss;
