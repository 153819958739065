import React from "react";
import { useCallback } from "react";
import { useMemoRequest } from "../../../../hooks/useMemoRequest";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import ModelAPI from "../../../../services/modelApi";
import { Accordion } from "../../../organisms/accordion/accordion";
import ProductPricing from "./ProductPricing";
import ProductPricingSecondary from "./ProductPricingSecondary";
import WholesaleFundingMovements from "./WholesaleFundingMovements";
import ShareholderCapital from "./ShareholderCapital";
import DividendReinvestmentPlan from "./DividendReinvestmentPlan";
import SpecialProjects from "./SpecialProjects";
import ExecutiveDecisions from "./ExecutiveDecisions";
import OperationalCosts from "./OperationalCosts";
import ModelPageContainer from "../ModelPageContainer";
import Dealroom from "./Dealroom";

interface Props {
  eventId: string;
  configuration: ModelAPI.ConfigurationResponse;
  teams: API.GameConnection[];
  selectedRound: number;
}

const ModelDecisions: React.FC<Props> = ({
  eventId,
  configuration,
  teams,
  selectedRound,
}) => {
  const getDecisions = useCallback(() => {
    return ModelAPI.getDecisions(eventId, selectedRound);
  }, [eventId, selectedRound]);

  const { inProgress, data, error, refresh } =
    useMemoRequest<ModelAPI.DecisionsResponse>(getDecisions);

  return (
    <ModelPageContainer
      eventId={eventId}
      selectedRound={selectedRound}
      data={data}
      refresh={refresh}
      error={error}
      inProgress={inProgress}
    >
      {!!data && (
        <VerticalGroup spaceBetweenElements={2} wide>
          <Accordion title="Product Pricing" defaultState="collapse" wide>
            <ProductPricing
              configuration={configuration}
              data={data.productPricing}
              teams={teams}
            />
          </Accordion>
          <Accordion
            title="Product Pricing - Secondary"
            defaultState="collapse"
            wide
          >
            <ProductPricingSecondary
              configuration={configuration}
              data={data.productPricing}
              teams={teams}
            />
          </Accordion>
          <Accordion
            title="Wholesale Funding Movements"
            defaultState="collapse"
            wide
          >
            <WholesaleFundingMovements
              configuration={configuration}
              data={data}
              teams={teams}
            />
          </Accordion>
          <Accordion
            title="Dividend Reinvestment Plan"
            defaultState="collapse"
            wide
          >
            <DividendReinvestmentPlan
              configuration={configuration}
              data={data.dividendReinvestmentPlan}
              teams={teams}
            />
          </Accordion>
          <Accordion
            title="Shareholder Capital Raised / Bought Back"
            defaultState="collapse"
            wide
          >
            <ShareholderCapital
              configuration={configuration}
              data={data.shareholderCapital}
              teams={teams}
            />
          </Accordion>
          <Accordion title="Special Projects" defaultState="collapse" wide>
            <SpecialProjects
              configuration={configuration}
              data={data.specialProjects}
              teams={teams}
            />
          </Accordion>
          <Accordion title="Executive Decisions" defaultState="collapse" wide>
            <ExecutiveDecisions
              configuration={configuration}
              data={data.executiveDecisions}
              teams={teams}
            />
          </Accordion>
          <Accordion title="Operational Costs" defaultState="collapse" wide>
            <OperationalCosts
              configuration={configuration}
              data={data.operationalCosts}
              teams={teams}
            />
          </Accordion>
          <Accordion title="Dealroom" defaultState="collapse" wide>
            <Dealroom
              configuration={configuration}
              data={data.dealroom}
              teams={teams}
            />
          </Accordion>
        </VerticalGroup>
      )}
    </ModelPageContainer>
  );
};

export default ModelDecisions;
