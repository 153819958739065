import React from "react";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import SimpleModal from "./SimpleModal";
import { ProgressBar } from "../../atoms/progressbar/ProgressBar";
import Text from "../../atoms/text/Text";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";

interface Props {
  onClose: () => void;
  message?: string | null;
  percentageDone: number;
}

const ProgressModal: React.FC<Props> = ({
  message,
  percentageDone,
  onClose,
}) => {
  return (
    <SimpleModal isOpen showCloseButton={false} size="small" onClose={onClose}>
      <VerticalGroup spaceBetweenElements={2}>
        <InlineGroup verticalCenter spaceBetweenElements={2}>
          <LoadingSpinner size={18} />
          <Text>{message}</Text>
        </InlineGroup>
        <ProgressBar percentage={percentageDone} />
      </VerticalGroup>
    </SimpleModal>
  );
};

export default ProgressModal;
