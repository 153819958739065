import React, { useCallback } from "react";
import ClientForm from "./ClientForm";
import Container from "../../../atoms/page/Page";
import API from "../../../../services/api";
import { useHistory } from "react-router-dom";

function CreateClient() {
  const onSave = useCallback(async (data: API.ClientRequest, logo?: File) => {
    let client = await API.createClient(data);
    if (logo) {
      client = await API.uploadClientLogo(client.id, logo);
    }
    return client;
  }, []);

  const history = useHistory();

  const onCancel = useCallback(() => {
    history.push("/administration/clients");
  }, [history]);

  return (
    <Container>
      <ClientForm onSave={onSave} onCancel={onCancel} />
    </Container>
  );
}

export default CreateClient;
