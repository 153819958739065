import React from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import Group from "./Group";
import { FieldsOf } from "./types";
import GloInput, { DataProps } from "./GloInput";

type DataType = API.GlobalModelVariablesResponse["creditRatings"];

interface Props {
  data: DataType;
  onChange: <T extends keyof DataType>(
    key: T,
    field?: undefined | FieldsOf<DataType, T>,
  ) => (selected: any) => void;
}

const creditRatings: Array<
  DataProps<API.GlobalModelVariablesResponse, "creditRatings">
> = [
  { label: "AAA", field: "AAA", type: "percentage" },
  { label: "AA", field: "AA", type: "percentage" },
  { label: "A", field: "A", type: "percentage" },
  { label: "BBB", field: "BBB", type: "percentage" },
  { label: "BB", field: "BB", type: "percentage" },
  { label: "B", field: "B", type: "percentage" },
  { label: "Default", field: "default", type: "percentage" },
];

const CreditRatings: React.FC<Props> = ({ data, onChange }) => {
  return (
    <VerticalGroup wide spaceBetweenElements={3} className="p-2">
      <Group title="Credit Rating Bands">
        {creditRatings.map(({ field, ...rest }) => (
          <GloInput
            key={field}
            value={data[field]}
            onChange={onChange(field)}
            {...rest}
          />
        ))}
      </Group>
    </VerticalGroup>
  );
};
export default CreditRatings;
