import React, { useCallback, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import api from "../../../../../services/api";
import Image from "../../../../atoms/image/Image";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import { Td } from "../../../../atoms/table/Table";
import Text from "../../../../atoms/text/Text";
import IconButton from "../../../../molecules/iconbutton/IconButton";
import SimpleModal from "../../../../organisms/standard-modal/SimpleModal";
import { AssessmentCloneQuestion } from "../AssessmentCloneQuestionIcon.tsx";
import { DeleteQuestion } from "./DeleteQuestion";

interface PreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  src: string;
  alt: string;
}

const PreviewModal: React.FC<PreviewModalProps> = ({
  isOpen,
  onClose,
  src,
  alt,
}) => (
  <SimpleModal
    size="large"
    isMinimal
    showCloseButton
    isOpen={isOpen}
    onClose={onClose}
  >
    <Image src={src} alt={alt} />
  </SimpleModal>
);

interface QuestionRowProps {
  question: API.AssessmentQuestionResponse;
  assessmentId: string;
  groupId: string;
  index: number;
  onDelete: (question: API.AssessmentQuestionResponse) => void;
}

export const QUESTION_TYPE_NAME_MAPPING = {
  multiple_choices: "Multiple Choice",
  checkboxes: "Checkboxes",
  image: "Image",
  rating: "Rating",
  written_text: "Written Text",
  written_response: "Written Response",
  short_written_response: "Short Written Response",
};

export const QuestionRow = ({
  index,
  question,
  assessmentId,
  groupId,
  onDelete,
}: QuestionRowProps) => {
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const openDeleteModal = useCallback(() => setDeleteOpen(true), []);
  const closeDeleteModal = useCallback(() => setDeleteOpen(false), []);
  const onComplete = useCallback(() => {
    onDelete(question);
  }, [onDelete, question]);

  return (
    <Draggable draggableId={question.id} index={index}>
      {(provided) => {
        return (
          <tr
            key={question.id}
            ref={provided.innerRef}
            style={{
              ...provided.draggableProps.style,
            }}
            {...provided.draggableProps}
          >
            <Td>
              <InlineGroup verticalCenter>
                <Text isHtml>{question.question}</Text>
                {question.fields.map((q) => {
                  return (
                    <>
                      {q?.type === "image" && (
                        <Image
                          id={q.config.imageId}
                          key={q.config.imageId}
                          src={
                            q.config.imageId
                              ? api.getAssessmentImageUrl(
                                  assessmentId,
                                  q.config.imageId,
                                )
                              : q.config.imageUrl
                          }
                          alt={`${q.config.nickName}`}
                          onClick={() => setShowPreviewModal(true)}
                          className="ml-2"
                          heightSize={8}
                        />
                      )}
                      {q?.type === "image" && q.config.imageId && (
                        <PreviewModal
                          key={q.config.imageId}
                          isOpen={showPreviewModal}
                          onClose={() => setShowPreviewModal(false)}
                          src={
                            q.config.imageId
                              ? api.getAssessmentImageUrl(
                                  assessmentId,
                                  q.config.imageId,
                                )
                              : q.config.imageUrl || ""
                          }
                          alt={`${q.config.nickName}`}
                        />
                      )}
                    </>
                  );
                })}
              </InlineGroup>
            </Td>
            <Td center width={64}>
              {QUESTION_TYPE_NAME_MAPPING[question.type]}
            </Td>
            <Td center width={32}>
              {question.mandatory ? "Yes" : "No"}
            </Td>
            <Td center width={32}>
              {question.marks}
            </Td>
            <Td center width={16}>
              <InlineGroup>
                <IconButton
                  noUi
                  icon="edit"
                  light={false}
                  wide={false}
                  linkTo={`/assessments/${assessmentId}/groups/${groupId}/questions/${question.id}/edit`}
                  color="darkgrey"
                />
                <AssessmentCloneQuestion
                  assessmentId={assessmentId}
                  groupId={groupId}
                  question={question}
                />
                <IconButton
                  noUi
                  icon="trash"
                  light={false}
                  wide={false}
                  color="darkgrey"
                  onClick={openDeleteModal}
                />
                <DeleteQuestion
                  assessmentId={assessmentId}
                  groupId={groupId}
                  question={question}
                  isOpen={isDeleteOpen}
                  onComplete={onComplete}
                  onClose={closeDeleteModal}
                />
                <IconButton
                  icon="draggable"
                  light={false}
                  noUi
                  wide={false}
                  {...provided.dragHandleProps}
                />
              </InlineGroup>
            </Td>
          </tr>
        );
      }}
    </Draggable>
  );
};
