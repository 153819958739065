import React from "react";
import EnumDropdown from "../../../../organisms/enum-dropdown/EnumDropdown";

interface ForthProductDropDownProps {
  onChange: (selected: { value: string; label: string }) => void;
  value?: string;
  error?: string;
  label?: string;
  name?: string;
  small?: boolean;
}

const forthProductDropDownOptions = [
  { value: "CREDIT_CARDS", label: "Credit Cards" },
  { value: "WEALTH_MANAGEMENT", label: "Wealth Management" },
  { value: "INSTITUTIONAL_BANKING", label: "Institutional Banking" },
  { value: "OFFSHORE_BANK", label: "Offshore Bank" },
];

const ForthProductDropDown: React.FC<ForthProductDropDownProps> = ({
  onChange,
  value,
  error,
  label,
  name,
  small,
}) => {
  return (
    <EnumDropdown
      small={small}
      onChange={onChange}
      options={forthProductDropDownOptions}
      value={value}
      error={error}
      label={label}
      name={name ?? "forth-product-dropdown"}
    />
  );
};

export default ForthProductDropDown;
