import React, { useCallback, useMemo } from "react";
import API from "../../../../../services/api";
import SimpleModal from "../../../../organisms/standard-modal/SimpleModal";
import Button from "../../../../atoms/button/Button";
import Container from "../../../../atoms/page/Page";
import AssignTabletColourDropdown from "../Configuration/assign-tablet-colour-dropdown/AssignTabletColourDropdown";
import Toggle from "../../../../atoms/toggle/Toggle";
import Text from "../../../../atoms/text/Text";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import IntegerDropdown from "../../../../organisms/integer-dropdown/IntegerDropdown";
import useForm from "../../../../../hooks/useForm";
import Form from "../../../../atoms/form/Form";
import Banner from "../../../../atoms/banner/Banner";
import { getUniqueTabletColors } from "../utils/mapInitialTabletMappingForEvent";

interface AddTabletProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  tabletData: API.TabletMappingResponse;
}

const getTabletId = (
  tablets: API.TabletDetailResponse[],
): { firstAvailable: number; unavailableIds: number[]; maxId: number } => {
  const ids = tablets.map((tablet) => tablet.tabletId);
  const maxId = Math.max(...ids) + 10;
  const availableIds = [];
  const unavailableIds = [];
  let nextId = 1;
  while (nextId <= maxId + 1) {
    if (!ids.includes(nextId)) {
      availableIds.push(nextId);
    } else {
      unavailableIds.push(nextId);
    }
    nextId++;
  }
  return { firstAvailable: availableIds[0], unavailableIds, maxId };
};

function AddTablet({ isOpen, onClose, onConfirm, tabletData }: AddTabletProps) {
  const { firstAvailable, unavailableIds, maxId } = useMemo(
    () => getTabletId(tabletData.tablets),
    [tabletData.tablets],
  );
  const [
    {
      formData: { tabletId, colour, available },
      fieldErrors,
      inProgress,
      error,
    },
    { handleSubmit, setFieldSimple, setFieldRaw },
  ] = useForm({
    tabletId: firstAvailable,
    colour: "red",
    available: true,
  });

  const validate = useCallback(() => {
    const errors = [];

    if (unavailableIds.includes(tabletId)) {
      errors.push({
        field: "tabletId",
        message: "That tablet ID is already in use",
      });
    }

    return errors;
  }, [tabletId, unavailableIds]);

  const addTabletCallback = useCallback(async () => {
    const payload: API.TabletCreateRequest = {
      tabletId,
      available,
      colour: colour as API.TabletColour,
    };
    await API.addTablet(payload);
    onConfirm?.();
  }, [tabletId, available, colour, onConfirm]);

  const handleTabletChange = useCallback(
    (val: any | null) => {
      if (val) {
        setFieldRaw("tabletId", val.value);
      } else {
        setFieldRaw("tabletId", firstAvailable);
      }
    },
    [firstAvailable, setFieldRaw],
  );

  const allTabletColors = useMemo(
    () => getUniqueTabletColors(tabletData.tablets),
    [tabletData.tablets],
  );

  return (
    <SimpleModal isOpen={isOpen} onClose={onClose} size={"medium"}>
      <VerticalGroup spaceBetweenElements={4}>
        <Banner type="error" active={!!error} message={error?.message} />
        <Text size="xl" bold>
          Add Tablet
        </Text>
        <Container fit>
          <Form
            id="tablet-add-form"
            onSubmit={handleSubmit(addTabletCallback, validate)}
          >
            {error && <div>{error.message}</div>}
            <AssignTabletColourDropdown
              availableTabletColors={allTabletColors}
              onColorSelect={setFieldSimple("colour")}
              editselectedColor={colour}
            />
            <Toggle
              block
              label="Tablet Available"
              checked={available}
              onUpdate={setFieldSimple("available")}
              helpDescription="toggle to make tablet available or unavailable"
              helpTitle="Tablet Available"
            />
            <IntegerDropdown
              helpTitle="Tablet ID"
              helpDescription="Either select a tablet ID from the list of available tablets, or manually enter the number of the tablet you want to assign."
              isCreatable
              label="Tablet ID"
              value={tabletId}
              min={firstAvailable}
              max={maxId}
              onChange={handleTabletChange}
              error={fieldErrors.tabletId}
              blocklist={unavailableIds}
            />
          </Form>
        </Container>
        <InlineGroup block spread>
          <Button data-test="cancel" light wide onClick={onClose}>
            Cancel
          </Button>
          <Button inProgress={inProgress} type="submit" form="tablet-add-form">
            Add Tablet
          </Button>
        </InlineGroup>
      </VerticalGroup>
    </SimpleModal>
  );
}

export default AddTablet;
