import React, { useCallback } from "react";
import { Link, useLocation } from "react-router-dom";

import Input from "../../atoms/form/input/Input";
import InlineError from "../../atoms/inlineerror/InlineError";
import useForm from "../../../hooks/useForm";
import API from "../../../services/api";
import Button from "../../atoms/button/Button";
import PublicPageContainer from "./PublicPageContainer";
import Form from "../../atoms/form/Form";
import Text from "../../atoms/text/Text";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";

interface Location {
  email?: string | null;
}

function ForgotPassword() {
  const location = useLocation<Location>();

  const [
    {
      formData: { email },
      inProgress,
      fieldErrors,
      error,
      completed,
    },
    { setField, handleSubmit },
  ] = useForm({
    email: location?.state?.email ?? "",
  });

  const callback = useCallback(() => {
    return API.sendResetPasswordEmail({
      email,
    });
  }, [email]);

  const validate = useCallback(() => {
    const errors = [];
    if (!email) {
      errors.push({ field: "email", message: "Enter an email" });
    }

    return errors;
  }, [email]);

  const renderSuccess = () => {
    return (
      <Text colour="white" data-test="text-summary">
        Reset password email sent to your registered email, click{" "}
        <Link to="/" data-test="success-login-link">
          here
        </Link>{" "}
        to login
      </Text>
    );
  };

  const renderForm = () => {
    return (
      <>
        <Text colour="white" data-test="text-summary">
          Enter your registered email to request a password reset
        </Text>
        <Form
          className="mt-6"
          onSubmit={handleSubmit(callback, validate)}
          center
          narrow
        >
          <Input
            autoFocus
            data-test="email"
            type="text"
            value={email}
            onChange={setField("email")}
            error={fieldErrors.email}
            placeholder="Enter your email address"
            large
          />
          <InlineError active={!!error} message={error?.message} block />
          <Button
            data-test="send-reset-password-email-button"
            type="submit"
            inProgress={inProgress}
            disabled={inProgress}
            large
            block
          >
            Send
          </Button>
          <InlineGroup>
            <Text colour="white">Remember your password?&nbsp;</Text>
            <Link
              to={{ pathname: "/", state: { email } }}
              data-test="login-link"
            >
              Log in
            </Link>
          </InlineGroup>
        </Form>
      </>
    );
  };

  return (
    <PublicPageContainer>
      {completed && !error ? renderSuccess() : renderForm()}
    </PublicPageContainer>
  );
}

export default ForgotPassword;
