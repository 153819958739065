import React, { useCallback, useState } from "react";

import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";

import { Accordion } from "../../../organisms/accordion/accordion";
import Summary from "./Summary";
import Treasury from "./Treasury";
import Financials from "./Financials";
import Opex from "./Opex";
import PeerInsights from "./PeerInsights";
import Business from "./Business";
import FinancialsSummary from "./FinancialsSummary";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import IconButton from "../../../molecules/iconbutton/IconButton";

interface Props {
  eventId: string;
  configuration: ModelAPI.ConfigurationResponse;
  selectedTeam: number;
  selectedRound: number;
}

const ModelSetupChecks: React.FC<Props> = ({ eventId, selectedRound }) => {
  const [issues, setIssues] = useState({
    opex: 0,
    financials: 0,
    summary: 0,
    treasury: 0,
    peerInsights: 0,
    business: 0,
    cheatSheet: 0,
  });
  const [refetchId, setRefetchId] = useState(0);
  const setIssueForOpex = useCallback((val: number) => {
    setIssues((issues) => ({ ...issues, opex: val }));
  }, []);
  const setIssueForFinancials = useCallback((val: number) => {
    setIssues((issues) => ({ ...issues, financials: val }));
  }, []);
  const setIssueForSummary = useCallback((val: number) => {
    setIssues((issues) => ({ ...issues, summary: val }));
  }, []);
  const setIssueForTreasury = useCallback((val: number) => {
    setIssues((issues) => ({ ...issues, treasury: val }));
  }, []);
  const setIssueForPeerInsights = useCallback((val: number) => {
    setIssues((issues) => ({ ...issues, peerInsights: val }));
  }, []);
  const setIssueForBusiness = useCallback((val: number) => {
    setIssues((issues) => ({ ...issues, business: val }));
  }, []);
  const setIssueForCheatsheet = useCallback((val: number) => {
    setIssues((issues) => ({ ...issues, cheatSheet: val }));
  }, []);
  return (
    <VerticalGroup spaceBetweenElements={2} wide className="mt-4">
      <InlineGroup spaceBetweenElements={2} right block>
        <IconButton
          icon="refresh"
          text="Refresh"
          onClick={() => setRefetchId((r) => r + 1)}
        />
      </InlineGroup>
      <VerticalGroup spaceBetweenElements={4} wide>
        <Accordion
          title={`Financials (${issues.financials} issues)`}
          defaultState="collapse"
          wide
        >
          <Financials
            refreshId={refetchId}
            eventId={eventId}
            selectedRound={selectedRound}
            setIssues={setIssueForFinancials}
          />
        </Accordion>
        <Accordion
          title={`Opex (${issues.opex} issues)`}
          defaultState="collapse"
          wide
        >
          <Opex
            refreshId={refetchId}
            eventId={eventId}
            selectedRound={selectedRound}
            setIssues={setIssueForOpex}
          />
        </Accordion>
        {selectedRound > 0 && (
          <>
            <Accordion
              title={`Summary (${issues.summary} issues)`}
              defaultState="collapse"
              wide
            >
              <Summary
                refreshId={refetchId}
                eventId={eventId}
                selectedRound={selectedRound}
                setIssues={setIssueForSummary}
              />
            </Accordion>
            <Accordion
              title={`Treasury (${issues.treasury} issues)`}
              defaultState="collapse"
              wide
            >
              <Treasury
                refreshId={refetchId}
                eventId={eventId}
                selectedRound={selectedRound}
                setIssues={setIssueForTreasury}
              />
            </Accordion>
            <Accordion
              title={`Peer Insights (${issues.peerInsights} issues)`}
              defaultState="collapse"
              wide
            >
              <PeerInsights
                refreshId={refetchId}
                eventId={eventId}
                selectedRound={selectedRound}
                setIssues={setIssueForPeerInsights}
              />
            </Accordion>
            <Accordion
              title={`Business Decisions (${issues.business} issues)`}
              defaultState="collapse"
              wide
            >
              <Business
                refreshId={refetchId}
                eventId={eventId}
                selectedRound={selectedRound}
                setIssues={setIssueForBusiness}
              />
            </Accordion>
            <Accordion
              title={`Cheat Sheet (${issues.cheatSheet} issues)`}
              defaultState="collapse"
              wide
            >
              <FinancialsSummary
                refreshId={refetchId}
                eventId={eventId}
                selectedRound={selectedRound}
                setIssues={setIssueForCheatsheet}
              />
            </Accordion>
          </>
        )}
      </VerticalGroup>
    </VerticalGroup>
  );
};

export default ModelSetupChecks;
