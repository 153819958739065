import React from "react";

import InlineGroup, {
  Props as IGProps,
} from "../../../atoms/inlinegroup/InlineGroup";

import "./ArrowHeader.scss";
import { ThemeColours } from "../../../../types/theme";
import classNames from "classnames";
import ResultsText from "../results-text/ResultsText";

interface Props extends IGProps {
  text: string;
  colour: ThemeColours;
}

const ArrowHeader: React.FC<Props> = ({ text, colour, ...rest }) => {
  const bgColourClass = colour ? `bg-colour-${colour}` : null;

  return (
    <InlineGroup
      block
      stretch
      spread
      style={{ width: "100%" }}
      className={classNames("arrow-header", bgColourClass)}
      {...rest}
    >
      <ResultsText size="lg" bold colour="white">
        {text}
      </ResultsText>
      <span
        style={{ width: "100%" }}
        className={classNames("arrow-header-point", bgColourClass)}
      ></span>
    </InlineGroup>
  );
};

export default ArrowHeader;
