import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useCallback,
} from "react";
import { ThemeColours, ThemeFontSize } from "../../../../types/theme";
import Text from "../../../atoms/text/Text";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Button from "../../../atoms/button/Button";
import "./SpeakerNotes.scss";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Input from "../../../atoms/form/input/Input";
import classNames from "classnames";

interface SpeakerNotesProps {
  initialValue: string;
  onSave: (value: string) => void;
  fontSize?: ThemeFontSize;
  onEditChange?: (editing: boolean) => void;
  placeHolderColour?: ThemeColours;
}

export interface SpeakerNotesRef {
  handleEditChange: (editing: boolean) => void;
}

const SpeakerNotes = forwardRef<SpeakerNotesRef, SpeakerNotesProps>(
  (
    {
      initialValue,
      onSave,
      fontSize = "xl",
      placeHolderColour = "white",
      onEditChange,
    },
    ref,
  ) => {
    const [value, setValue] = useState(initialValue);
    const [isEditing, setIsEditing] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(false);

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        setShowSaveButton(true);
      },
      [],
    );

    const handleSave = useCallback(() => {
      onSave(value);
      setShowSaveButton(false);
      setIsEditing(false);
    }, [onSave, value]);

    const handleEditChange = useCallback(
      (editing: boolean) => {
        onEditChange && onEditChange(editing);
        setIsEditing(editing);
      },
      [onEditChange],
    );

    useImperativeHandle(
      ref,
      () => ({
        handleEditChange,
      }),
      [handleEditChange],
    );

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <VerticalGroup
        full
        wide
        spaceBetweenElements={2}
        className="speaker-notes"
      >
        <div
          className={classNames("container", {
            "has-value": value,
            "is-editing": isEditing,
          })}
          onClick={() => {
            handleEditChange(true);
            if (value) {
              setShowSaveButton(true);
            }
          }}
        >
          {isEditing ? (
            <Input
              className="scrollable"
              inputClassName="speaker-notes-input"
              // dropdownOptions={dropdownOptions}
              block
              // small={small}
              type="textbox"
              value={value}
              onChange={handleChange}
            />
          ) : (
            <Text
              colour={value ? "primaryDark" : placeHolderColour}
              className="text"
              size={fontSize}
              isHtml
              style={{ opacity: value ? 1 : 0.2 }}
            >
              {value || "Add Speaker Notes"}
            </Text>
          )}
        </div>
        {showSaveButton && (
          <InlineGroup block right>
            <Button border onClick={handleSave}>
              Save
            </Button>
          </InlineGroup>
        )}
      </VerticalGroup>
    );
  },
);

SpeakerNotes.displayName = "SpeakerNotes";

export default SpeakerNotes;
