import React, { useCallback } from "react";
import UserForm from "./UserForm";
import Container from "../../../atoms/page/Page";
import API from "../../../../services/api";
import { useHistory } from "react-router-dom";

function CreateUser() {
  const history = useHistory();
  const onSave = useCallback(async (data: API.UserRequest, image?: File) => {
    let user = await API.createUser(data);
    if (image) {
      user = await API.uploadUserImage(user.id, image);
    }
    return user;
  }, []);

  const onCancel = useCallback(() => {
    history.push("/administration/users");
  }, [history]);

  return (
    <Container>
      <UserForm onSave={onSave} onCancel={onCancel} />
    </Container>
  );
}

export default CreateUser;
