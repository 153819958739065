import React from "react";
import classNames from "classnames";
import Icon from "./Icon";
import "./Icon.scss";
import { ThemeSpacing } from "../../../types/theme";

interface CircleIconProps extends React.ComponentProps<typeof Icon> {
  circleSize: ThemeSpacing;
  circleBorder?: ThemeSpacing;
}

const CircleIcon: React.FC<CircleIconProps> = ({
  circleSize,
  circleBorder,
  ...props
}) => {
  const { colour } = props;
  const sizeClass = circleSize ? `size-${circleSize}` : null;
  const colorClass = colour ? `color-${colour}` : null;
  const circleBorderClass = circleBorder ? `border-size-${circleBorder}` : null;

  return (
    <div
      className={classNames(
        "circle-icon",
        sizeClass,
        colorClass,
        circleBorderClass,
      )}
    >
      <Icon {...props} colour={colour} />
    </div>
  );
};

export default CircleIcon;
