const convertWSFRoundResult = ({
  totalYears,
  rounds,
}: {
  totalYears: number;
  rounds: any[];
}): { [field: string]: number } => {
  let result: { [field: string]: number } = {};
  for (let round = 0; round <= totalYears; round++) {
    const dataForRound = rounds[round];
    result = {
      ...result,
      [`round${round}-CBF`]: dataForRound.centralBankFacility,
      [`round${round}-1Year`]: dataForRound.wsf1,
      [`round${round}-2Year`]: dataForRound.wsf2,
      [`round${round}-3Year`]: dataForRound.wsf3,
      [`round${round}-4Year`]: dataForRound.wsf4,
      [`round${round}-5Year`]: dataForRound.wsf5,
    };
  }
  return result;
};

export default convertWSFRoundResult;
