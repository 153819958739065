import React from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Icon from "../../../atoms/icon/Icon";
import { TeamsWithColours } from "../RoundResultsPage";
import "./TeamPositions.scss";
import { useResultsContext } from "../context/context";
import ResultsText from "../results-text/ResultsText";

interface Props {
  teamPositions: Array<Type>;
  teams: TeamsWithColours[];
  shouldRender?: boolean;
}

interface Type {
  teamId: number;
  position: number;
  shouldRender?: boolean;
}

const TeamPositions: React.FC<Props> = ({
  teamPositions,
  teams,
  shouldRender,
}) => {
  const context = useResultsContext();
  return (
    <InlineGroup
      stretch
      verticalCenter
      spaceBetweenElements={10}
      className={"positions-container "}
    >
      {!shouldRender && (
        <VerticalGroup
          key={`position-placeholder`}
          center
          spaceBetweenElements={2}
          className="icon-item"
          style={{ visibility: "hidden" }}
        >
          <Icon
            type="goldMedal"
            fill={"black"}
            dynamicSize={{ min: "50px", preferred: "5vw", max: "900px" }}
            noMargin
          ></Icon>
          <div style={{ color: "black" }}>
            <ResultsText bold size="lg">
              Placeholder
            </ResultsText>
          </div>
        </VerticalGroup>
      )}
      {shouldRender &&
        teamPositions.map((p, i) => {
          const teamForPosition = teams.find((t) => t.teamId === p.teamId);
          return (
            <VerticalGroup
              key={`position-${i}`}
              center
              spaceBetweenElements={2}
              className="icon-item"
              style={{
                animationDelay: context.noAnimations
                  ? "0s"
                  : `${teams.length + 1 + i}s`,
              }}
            >
              {p.position === 1 && (
                <Icon
                  type="goldMedal"
                  fill={teamForPosition?.colour}
                  dynamicSize={{ min: "50px", preferred: "5vw", max: "900px" }}
                  noMargin
                ></Icon>
              )}
              {p.position === 2 && (
                <Icon
                  type="silverMedal"
                  fill={teamForPosition?.colour}
                  dynamicSize={{ min: "50px", preferred: "5vw", max: "900px" }}
                  noMargin
                ></Icon>
              )}
              {p.position === 3 && (
                <Icon
                  type="bronzeMedal"
                  fill={teamForPosition?.colour}
                  dynamicSize={{ min: "50px", preferred: "5vw", max: "900px" }}
                  noMargin
                ></Icon>
              )}
              <div style={{ color: teamForPosition?.colour }}>
                <ResultsText bold size="lg">
                  {teamForPosition?.teamName}
                </ResultsText>
              </div>
            </VerticalGroup>
          );
        })}
    </InlineGroup>
  );
};

export default TeamPositions;
