import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import Icon from "../../atoms/icon/Icon";
import Table from "../../atoms/table/Table";
import API from "../../../services/api";

import StickyBar from "../../organisms/sticky-bar/StickyBar";
import EmptyList from "../../organisms/empty-list/EmptyList";
import IconButton from "../../molecules/iconbutton/IconButton";
import Clickable from "../../atoms/clickable/Clickable";
import DeleteAssessment from "./AssessmentDelete";
import ClientAvatarList from "../../molecules/client-avatar-list/ClientAvatarList";
import { CLONE_TOOLTIP_ID, CloneAssessment } from "./actions/CloneAssessment";
import Tooltip from "../../atoms/tooltip/Tooltip";

interface Props {
  assessments: API.AssessmentResponse[];
  refreshAssessments: () => void;
}

interface AssessmentState {
  deleteModalOpen: boolean;
  assessmentSelected: API.AssessmentResponse | null;
}

const STICKY_BAR_HEIGHT = 8;
const TOOLTIP_ID = "assessment-table-tooltip";

function AssessmentListTable({ assessments, refreshAssessments }: Props) {
  const history = useHistory();
  const [{ deleteModalOpen, assessmentSelected }, setAssessmentState] =
    useState<AssessmentState>({
      deleteModalOpen: false,
      assessmentSelected: null,
    });

  const onAssessmentDeleteComplete = useCallback(() => {
    setAssessmentState((prevState) => ({
      ...prevState,
      deleteModalOpen: false,
      assessmentSelected: null,
    }));
    refreshAssessments();
  }, [refreshAssessments]);

  const handleAssessmentClick = useCallback(
    (assessment: API.AssessmentResponse) => (event: any) => {
      event.stopPropagation();
      event.preventDefault();
      history.push(`/assessments/${assessment.id}/view`);
    },
    [history],
  );

  const handleDeleteClick = useCallback(
    (assessment: API.AssessmentResponse) => (event: any) => {
      event.stopPropagation();
      setAssessmentState({
        deleteModalOpen: true,
        assessmentSelected: assessment,
      });
    },
    [],
  );

  return (
    <>
      <Tooltip id={TOOLTIP_ID} />
      <Tooltip id={CLONE_TOOLTIP_ID}>
        <>
          <b>Clone Assessment</b>
          <p>
            Create a new assessment using the details <br /> of this assessment
            as a starting point.
          </p>
        </>
      </Tooltip>
      {deleteModalOpen && !!assessmentSelected && (
        <DeleteAssessment
          isOpen={deleteModalOpen}
          onClose={() =>
            setAssessmentState((prevState) => ({
              ...prevState,
              assessmentModalOpen: false,
              assessmentSelected: null,
            }))
          }
          onComplete={onAssessmentDeleteComplete}
          assessment={assessmentSelected}
        />
      )}
      <StickyBar height={STICKY_BAR_HEIGHT}>
        <IconButton
          data-test="add-assessment"
          linkTo="/assessments/add"
          icon="assessments"
          text="Add New Assessment"
        />
      </StickyBar>
      {!assessments.length && (
        <EmptyList icon="assessments" message="There are no assessments yet" />
      )}
      {!!assessments.length && (
        <Table stickyHeader stickyTop={STICKY_BAR_HEIGHT} clickable>
          <thead>
            <tr>
              <th>Assessment Name</th>
              <th>Clients</th>
              <th className="right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {assessments.map((assessment) => (
              <tr
                className="assessmentsTable-row"
                key={assessment.id}
                data-test={assessment.id}
                onClick={handleAssessmentClick(assessment)}
              >
                <td>{assessment.name}</td>
                <td>
                  <ClientAvatarList clients={assessment.clients} />
                </td>
                <td className="right">
                  <CloneAssessment type="icon" assessment={assessment} />
                  <Icon
                    type="search"
                    tt={{ content: "View Assessment", id: TOOLTIP_ID }}
                  />
                  {assessment && (
                    <Clickable
                      data-test={`delete-${assessment.id}`}
                      onClick={handleDeleteClick(assessment)}
                    >
                      <Icon
                        type="trash"
                        tt={{ content: "Delete Assessment", id: TOOLTIP_ID }}
                      />
                    </Clickable>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}

export default AssessmentListTable;
