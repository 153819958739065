import React from "react";

import Card from "../../../../atoms/card/Card";
import HelpManualPreview from "./HelpManualPreview";
import ScreenOnly from "../../../../atoms/printable/components/ScreenOnly";

interface Props {
  data?: API.SimulationResponse["resources"];
}

const Display: React.FC<Props> = ({ data }) => {
  return (
    <Card wide>
      <ScreenOnly>
        <h4>Help Manual</h4>
        {data?.helpManualId ? (
          <HelpManualPreview helpManualId={data.helpManualId} />
        ) : (
          <p>No Help Manual Selected</p>
        )}
      </ScreenOnly>
    </Card>
  );
};

export default Display;
