import React, { useMemo } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";
import getTeamIdsAsFields from "../utils/getTeamIdsAsFields";
import getTeamName from "../utils/getTeamName";
import pluckResultsByType from "../utils/pluckResultsByType";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.DecisionsResponse["shareholderCapital"];
  teams: API.GameConnection[];
}

const ShareholderCapital: React.FC<Props> = ({
  configuration,
  data,
  teams,
}) => {
  const { numberOfTeams, numberOfCpuTeams } = configuration;
  const totalNumberOfTeams = numberOfTeams + numberOfCpuTeams;
  const fields = useMemo(
    () => getTeamIdsAsFields(totalNumberOfTeams),
    [totalNumberOfTeams],
  );

  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <TableWithSections
          header="Managed Capital - Player Selected Rating"
          data={[
            {
              fields,
              values: pluckResultsByType(data, "managedCapitalSelectedRating"),
            },
          ]}
          fieldFormatter={(field: string) =>
            getTeamName(parseInt(field), numberOfTeams, teams)
          }
          formatter={(value: any) => {
            if (value == null) {
              return "-";
            }
            return value;
          }}
        />
        <TableWithSections
          header="Share Capital Management"
          data={[
            {
              fields,
              values: pluckResultsByType(data, "shareCapitalManagement"),
            },
          ]}
          fieldFormatter={(field: string) =>
            getTeamName(parseInt(field), numberOfTeams, teams)
          }
        />
        <TableWithSections
          header="Regulator Mandated Capital Raising"
          data={[
            {
              fields,
              values: pluckResultsByType(
                data,
                "regulatorMandatedCapitalRating",
              ),
            },
          ]}
          fieldFormatter={(field: string) =>
            getTeamName(parseInt(field), numberOfTeams, teams)
          }
        />
      </VerticalGroup>
    </>
  );
};

export default ShareholderCapital;
