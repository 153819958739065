import React, { useCallback } from "react";

import Input from "../../../../atoms/form/input/Input";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import Clickable from "../../../../atoms/clickable/Clickable";
import Icon from "../../../../atoms/icon/Icon";

interface KeyValueInputProps {
  id: string;
  onChange: (
    id: string,
    key: string | undefined,
    value: string | number | undefined,
  ) => void;
  onRemove: (id: string) => void;
  keyName?: string;
  value?: string | number;
}

const KeyValueInput: React.FC<KeyValueInputProps> = ({
  id,
  keyName,
  value,
  onChange,
  onRemove,
}) => {
  const updateKey = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(id, event.target.value.replace(/\s/g, ""), value);
    },
    [id, onChange, value],
  );

  const updateValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(id, keyName?.replace(/\s/g, ""), event.target.value);
    },
    [id, keyName, onChange],
  );

  const deleteField = useCallback(() => {
    onRemove(id);
  }, [id, onRemove]);

  return (
    <InlineGroup>
      <Input
        data-test="key"
        className="ml-1 mt-1"
        isVerticalLayout
        type="text"
        label="Setting Name"
        value={keyName}
        onChange={updateKey}
      />
      <Input
        data-test="value"
        className="ml-1 mt-1"
        isVerticalLayout
        type="text"
        label="Value"
        value={value}
        onChange={updateValue}
      />
      <Clickable
        className="pt-1"
        alignBot
        data-test={`delete-${id}`}
        onClick={(event) => {
          event.stopPropagation();
          deleteField();
        }}
      >
        <Icon
          type="trash"
          tip={{
            content: "Delete",
          }}
        />
      </Clickable>
    </InlineGroup>
  );
};

export default KeyValueInput;
