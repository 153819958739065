import React from "react";
import Dropdown from "../../atoms/form/input/Dropdown";

type SimulationType = "online" | "tablet" | "hybrid";

interface SimulationTypeDropdownProps {
  simulationType: SimulationType;
  setSimulationType: (type: SimulationType) => void;
}

const simulationTypeOptions = [
  { label: "Online", id: "online" },
  { label: "Tablet", id: "tablet" },
  { label: "Hybrid", id: "hybrid" },
];

const SimulationTypeDropdown: React.FC<SimulationTypeDropdownProps> = ({
  simulationType,
  setSimulationType,
}) => {
  return (
    <Dropdown
      label="Simulation Type"
      selectProps={{
        options: simulationTypeOptions,
        value: simulationTypeOptions.find(
          (option) => option.id === simulationType,
        ),
        onChange: (option: { id: SimulationType }) => {
          setSimulationType(option.id);
        },
      }}
    />
  );
};

export default SimulationTypeDropdown;
