import React, { useCallback, useMemo } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import { TeamsWithColours } from "../RoundResultsPage";
import ResultsTeamLineChart from "../charts/ResultsTeamLineChart";
import { reportingPercentage } from "../../model/utils/formatters";
import { getYAxisDataV2 } from "../charts/yaxis";
import ChartCard from "../components/ChartCard";
import { convertRoundDataToPercentages } from "../charts/convertRoundDataToPercentages";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import { useResultsContext } from "../context/context";

interface Props {
  pastRound1: boolean;
  teams: TeamsWithColours[];
  employeeEngagement: ModelAPI.Reporting.DataAndPositions;
}

const RoundResultsEmployeeEngagement: React.FC<Props> = ({
  pastRound1,
  teams,
  employeeEngagement,
}) => {
  const data = useMemo(
    () => convertRoundDataToPercentages(employeeEngagement.data),
    [employeeEngagement.data],
  );

  const { minValue, maxValue, numberOfTicks, yAxisDp } = getYAxisDataV2({
    isPercentage: true,
    padding: 0.9,
    pastRound1,
    data,
  });
  const context = useResultsContext();
  const maxClicks = useMemo(
    () => (context.allowPresentationMode || context.readSyncState ? 1 : 0),
    [context.allowPresentationMode, context.readSyncState],
  );
  const onNext = useCallback(() => {
    if (context.clicks < maxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context, maxClicks]);
  return (
    <RoundResultsContainer onNext={onNext}>
      <VerticalGroup full center wide>
        <ChartCard heading="Employee Engagement">
          <ResultsTeamLineChart
            data={data}
            teams={teams.filter((t) => !t.isCpuTeam)}
            formatter={(val: number) => reportingPercentage(val)}
            yAxisFormatter={(val: number) => reportingPercentage(val, yAxisDp)}
            minYAxisvalue={minValue}
            maxYAxisvalue={maxValue}
            numberOfTicks={numberOfTicks}
            teamPositions={employeeEngagement.teamPositions}
            shouldRenderLines={context.clicks === maxClicks}
          />
        </ChartCard>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsEmployeeEngagement;
