import React from "react";
import { fullDate } from "../../../lib/date";
import Card from "../../atoms/card/Card";
import Label from "../../atoms/form/label/Label";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import Text from "../../atoms/text/Text";

interface Props {
  event: API.EventResponse | API.EventResponseSummary;
  wide?: boolean;
}

function GameDetails({ event, wide }: Props) {
  return (
    <Card wide={wide} flex>
      <InlineGroup verticalCenter className="p-1">
        <Label noShrink>
          <Text size="sm" bold>
            Client
          </Text>
        </Label>
        <Text size="sm">{event.client}</Text>
      </InlineGroup>
      <InlineGroup verticalCenter className="p-1">
        <Label noShrink>
          <Text size="sm" bold>
            Stream
          </Text>
        </Label>
        <Text size="sm">{event.streamName ?? "-"}</Text>
      </InlineGroup>
      <InlineGroup verticalCenter className="p-1">
        <Label noShrink>
          <Text size="sm" bold>
            Event Id
          </Text>
        </Label>
        <Text size="sm">{event.id}</Text>
      </InlineGroup>
      <InlineGroup verticalCenter className="p-1">
        <Label noShrink>
          <Text size="sm" bold>
            Event Date{" "}
          </Text>
        </Label>
        <Text size="sm">{fullDate(event.date)}</Text>
      </InlineGroup>
    </Card>
  );
}

export default GameDetails;
