import React, { useEffect } from "react";
import SimpleModal from "../../organisms/standard-modal/SimpleModal";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import Text from "../../atoms/text/Text";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import SquareIconButton from "../../molecules/square-icon-button/SquareIconButton";
import openNewWindow from "../../../lib/openNewWindow";
import Icon from "../../atoms/icon/Icon";
import ClickableText from "../../atoms/text/ClickableText";
import CopyToClipboard from "react-copy-to-clipboard";
import { Noop } from "../../../constants/functions";

interface Props {
  isOpen: boolean;
  eventId: string;
  onClose: () => void;
}

function RoundResultsOpenPresentationModal({
  isOpen,
  eventId,
  onClose,
}: Props) {
  const [copied, setCopied] = React.useState<string | null>(null);

  useEffect(() => {
    if (copied != null) {
      const timeout = setTimeout(() => {
        setCopied(null);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [copied]);

  return (
    <SimpleModal isOpen={isOpen} showCloseButton size="small" onClose={onClose}>
      <VerticalGroup spaceBetweenElements={8} wide center>
        <InlineGroup verticalCenter spread block>
          <Text size="lg" bold>
            Open Presentation
          </Text>
          <Icon type="leftChevron" noMargin colour="white" />
        </InlineGroup>
        <Text size="md" lineHeight={6}>
          Move this window to the screen that the audience can NOT see.
          <br />
          If you havn&apos;t already, click the button to open the presentation
          in a new window. <br />
          Once open move that window to the screen that the audience can see.
        </Text>
        <InlineGroup spaceBetweenElements={12}>
          <VerticalGroup spaceBetweenElements={2} center>
            <SquareIconButton
              icon="presentation"
              label="Open Presentation &nbsp;"
              onClick={() => {
                openNewWindow(
                  `${window.location.origin}/results/games/${eventId}/presentation-reader`,
                  {
                    left: 50,
                    top: 50,
                    width: 800,
                    height: 600,
                    windowName: "Presentation",
                  },
                );
                onClose();
              }}
            />
            <CopyToClipboard
              text={`${window.location.origin}/results/games/${eventId}/presentation-reader`}
              onCopy={() => setCopied("presentation")}
            >
              <InlineGroup spaceBetweenElements={2} verticalCenter>
                <ClickableText onClick={Noop}>Copy Link</ClickableText>
                {copied === "presentation" && (
                  <InlineGroup spaceBetweenElements={2} verticalCenter>
                    <Icon type="tick" colour="green" />
                    <Text>Link copied</Text>
                  </InlineGroup>
                )}
              </InlineGroup>
            </CopyToClipboard>
          </VerticalGroup>
        </InlineGroup>
      </VerticalGroup>
    </SimpleModal>
  );
}

export default RoundResultsOpenPresentationModal;
