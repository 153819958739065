import React from "react";

interface StepMeta {
  title: string;
  content: React.ReactNode;
  /**
   * The key in the state.config that this step stores with
   */
  key: string;
  roleRequired?: API.Role[];
  disabled?: boolean;
  disabledMessage?: string;
  submitButtonText?: string;
  hideNavigationButtons?: boolean;
}

export interface EventState {
  steps: StepMeta[];
  config?: API.EventResponse;
  formStatus: {
    inProgress: boolean;
    formUpdated: boolean;
    mode?: "create" | "edit" | "clone";
  };
  /**
   * The function which will submit the form. If it returns false,
   * that means a form error
   */
  onSubmitNext: () => Promise<false | void>;
  onSubmitPrevious: () => Promise<false | void>;
  isJumpStepModalOpen: boolean;
  jumpStepModalConfirmAction?: () => void;
}

/**
 * Action
 */
interface UpdateConfig {
  type: "UpdateConfig";
  payload: API.EventResponse;
}

interface AssignOnSubmitFunction {
  type: "AssignOnSubmitFunction";
  payload: () => Promise<false | void>;
}
interface AssignOnSubmitNextFunction {
  type: "AssignOnSubmitNextFunction";
  payload: () => Promise<false | void>;
}
interface AssignOnSubmitPreviousFunction {
  type: "AssignOnSubmitPreviousFunction";
  payload: () => Promise<false | void>;
}
interface UpdateFormStatus {
  type: "UpdateFormStatus";
  payload: EventState["formStatus"];
}

interface SetJumpStepModalStatus {
  type: "SetJumpStepModalStatus";
  payload: {
    isJumpStepModalOpen: boolean;
    jumpStepModalConfirmAction?: () => void;
  };
}

interface UpdateStepMeta {
  type: "UpdateStepMeta";
  payload: {
    key: string;
    data: Partial<StepMeta>;
  };
}

export type Actions =
  | UpdateConfig
  | AssignOnSubmitFunction
  | AssignOnSubmitNextFunction
  | AssignOnSubmitPreviousFunction
  | UpdateFormStatus
  | SetJumpStepModalStatus
  | UpdateStepMeta;

/**
 * Reducer
 */

export const reducer: React.Reducer<EventState, Actions> = (state, action) => {
  switch (action.type) {
    case "UpdateConfig":
      return {
        ...state,
        config: {
          ...state.config,
          ...action.payload,
        },
      };
    case "AssignOnSubmitFunction":
      return {
        ...state,
        onSubmitNext: action.payload,
        onSubmitPrevious: action.payload,
      };
    case "AssignOnSubmitNextFunction":
      return {
        ...state,
        onSubmitNext: action.payload,
      };
    case "AssignOnSubmitPreviousFunction":
      return {
        ...state,
        onSubmitPrevious: action.payload,
      };
    case "UpdateFormStatus":
      return {
        ...state,
        formStatus: {
          ...state.formStatus,
          ...action.payload,
        },
      };
    case "SetJumpStepModalStatus":
      return {
        ...state,
        ...action.payload,
      };
    case "UpdateStepMeta": {
      return {
        ...state,
        steps: state.steps.map((step) => {
          if (step.key === action.payload.key) {
            return {
              ...step,
              ...action.payload.data,
            };
          }
          return step;
        }),
      };
    }
    default:
      return state;
  }
};
