import React, { useCallback, useMemo, useState } from "react";
import Card from "../../../../../atoms/card/Card";
import Text from "../../../../../atoms/text/Text";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import "./GrowthLosses.scss";
import EconomicScenarioLineChart from "../EconomicScenarioCharts/EconomicScenarioLineChart ";
import { calculateGrossRates } from "../calculations/growthAndLosses";
import { PreviewSettings } from "../types";
import {
  convertBpsToPercentage,
  wsfConvertDivide,
} from "../calculations/utils";
import PercentageDropdown from "../../../../../organisms/percentage-dropdown/PercentageDropdown";
import CountryDropdown from "../../../../simulations/steps/region/CountryDropdown";
import ForthProductDropDown from "../../../../simulations/steps/region/ForthProductDropDown";
import EconomicScenarioRoundDropdown from "../../../../../organisms/economicScenarioRoundDropdown/EconomicScenarioRoundDropdown";
import FastEditingDropDown from "../../../../../organisms/FastEditingDropDown/FastEditingDropDown";
import MultiplierDropdown from "../../../../../organisms/multiplier-dropdown/MultiplierDropdown";
import EconomySettingDropDown from "../components/EconomySettingDropDown";

interface Params {
  round: number;
  selectedScenario: API.EconomicScenarioResponse;
  growthAndLosses: API.EconomicScenarioGrowthAndLosses;
  handleScenarioUpdated: (
    updatedScenario: API.EconomicScenarioUpdateRequest,
  ) => void;
  previewSettings: PreviewSettings;
  handlePreviewSettingsChange: (
    key: keyof PreviewSettings,
    value: string,
  ) => void;
  setRound: (round: number) => void;
  handleDropdownChange: (key: keyof PreviewSettings) => (value: string) => void;
  handleRoundChange: (newRound: number) => void;
  handleUpdate: (scenario: {
    value: string;
    label: string;
    description?: string;
  }) => void;
  scenarios: API.EconomicScenarioResponse[];
}
export interface DropdownOption {
  value: string;
  label: string;
}

interface ChartData {
  name: string;
  HOME_LOANS: number;
  BUSINESS_LOANS: number;
  DEPOSITS: number;
  CREDIT_CARDS?: number;
  INSTITUTIONAL_BANKING?: number;
  OFFSHORE_BANK?: number;
  WEALTH_MANAGEMENT?: number;
}

type ChartType = keyof API.EconomicScenarioGrowthAndLosses;
const GrowthLosses = ({
  round,
  growthAndLosses,
  selectedScenario,
  previewSettings,
  handleScenarioUpdated,
  handlePreviewSettingsChange,
  setRound,
  handleDropdownChange,
  handleRoundChange,
  scenarios,
  handleUpdate,
}: Params) => {
  const [selectedProduct, setSelectedProduct] = useState(
    previewSettings.product,
  );
  const handleAndRecalc = useCallback(
    (updatedGrowthAndLosses: API.EconomicScenarioGrowthAndLosses) => {
      handleScenarioUpdated({
        growthAndLosses: selectedScenario.growthAndLosses.map((g) => {
          if (g.country === growthAndLosses.country) {
            return updatedGrowthAndLosses;
          }
          return g;
        }),
      });
    },
    [
      growthAndLosses.country,
      handleScenarioUpdated,
      selectedScenario.growthAndLosses,
    ],
  );

  const handleNetRatesChange = useCallback(
    (productType: API.Products) => (value: number) => {
      const newValueTypeArray = [...growthAndLosses.netRates[productType]];
      newValueTypeArray[round - 1] = value;

      handleAndRecalc({
        ...growthAndLosses,
        netRates: {
          ...growthAndLosses.netRates,
          [productType]: newValueTypeArray,
        },
      });
    },
    [growthAndLosses, handleAndRecalc, round],
  );

  const handleLossMultiplierChange = useCallback(
    (
      productType: keyof API.EconomicScenarioGrowthAndLosses["lossMultipliers"],
      subType?:
        | "proprietaryLoans"
        | "brokerLoans"
        | "goodQuality"
        | "mediumQuality"
        | "poorQuality",
    ) =>
      (value: number) => {
        const numericValue = Math.round(value);
        const lossMultipliers = {
          ...growthAndLosses.lossMultipliers,
        };

        const newValueTypeArray = subType
          ? [
              // @ts-expect-error this works
              ...(growthAndLosses.lossMultipliers[productType][
                subType
              ] as number[]),
            ]
          : [...(growthAndLosses.lossMultipliers[productType] as number[])];
        newValueTypeArray[round - 1] = numericValue;

        if (subType) {
          // @ts-expect-error this works
          lossMultipliers[productType][subType] = newValueTypeArray;
        } else {
          // @ts-expect-error this works
          lossMultipliers[productType] = newValueTypeArray;
        }

        handleAndRecalc({
          ...growthAndLosses,
          lossMultipliers,
        });
      },
    [growthAndLosses, handleAndRecalc, round],
  );

  const calculatedGrossRates = useMemo(() => {
    return calculateGrossRates({
      previewSettings,
      netRates: growthAndLosses.netRates,
      lossMultipliers: growthAndLosses.lossMultipliers,
    });
  }, [
    growthAndLosses.lossMultipliers,
    growthAndLosses.netRates,
    previewSettings,
  ]);

  const productGrowthRateData = useMemo(() => {
    const baseData: ChartData[] = growthAndLosses.netRates.HOME_LOANS.map(
      (hl: number, i: number) => ({
        name: `${i + 1}`,
        HOME_LOANS: hl / 100,
        BUSINESS_LOANS: growthAndLosses.netRates.BUSINESS_LOANS[i] / 100,
        DEPOSITS: growthAndLosses.netRates.DEPOSITS[i] / 100,
      }),
    );

    if (selectedProduct === "CREDIT_CARDS") {
      baseData.forEach((item, i) => {
        item.CREDIT_CARDS = growthAndLosses.netRates.CREDIT_CARDS[i] / 100;
      });
    }
    if (selectedProduct === "INSTITUTIONAL_BANKING") {
      baseData.forEach((item, i) => {
        item.INSTITUTIONAL_BANKING =
          growthAndLosses.netRates.INSTITUTIONAL_BANKING[i] / 100;
      });
    }
    if (selectedProduct === "OFFSHORE_BANK") {
      baseData.forEach((item, i) => {
        item.OFFSHORE_BANK = growthAndLosses.netRates.OFFSHORE_BANK[i] / 100;
      });
    }
    if (selectedProduct === "WEALTH_MANAGEMENT") {
      baseData.forEach((item, i) => {
        item.WEALTH_MANAGEMENT =
          growthAndLosses.netRates.WEALTH_MANAGEMENT[i] / 100;
      });
    }

    return baseData;
  }, [growthAndLosses.netRates, selectedProduct]);

  const homeLoanLieData = useMemo(() => {
    return calculatedGrossRates.lie.HOME_LOANS.proprietaryLoans.map(
      (pl: number, i: number) => ({
        name: `${i + 1}`,
        proprietaryLoans: pl,
        brokerLoans: calculatedGrossRates.lie.HOME_LOANS.brokerLoans[i],
        portfolio: calculatedGrossRates.lie.HOME_LOANS.portfolio[i],
      }),
    );
  }, [
    calculatedGrossRates.lie.HOME_LOANS.brokerLoans,
    calculatedGrossRates.lie.HOME_LOANS.portfolio,
    calculatedGrossRates.lie.HOME_LOANS.proprietaryLoans,
  ]);

  const businessLoanLieData = useMemo(() => {
    return calculatedGrossRates.lie.BUSINESS_LOANS.good.map(
      (bl: number, i: number) => ({
        name: `${i + 1}`,
        good: bl,
        medium: calculatedGrossRates.lie.BUSINESS_LOANS.medium[i],
        poor: calculatedGrossRates.lie.BUSINESS_LOANS.poor[i],
        portfolio: calculatedGrossRates.lie.BUSINESS_LOANS.portfolio[i],
      }),
    );
  }, [
    calculatedGrossRates.lie.BUSINESS_LOANS.good,
    calculatedGrossRates.lie.BUSINESS_LOANS.medium,
    calculatedGrossRates.lie.BUSINESS_LOANS.poor,
    calculatedGrossRates.lie.BUSINESS_LOANS.portfolio,
  ]);

  const creditCardLieData = useMemo(() => {
    return calculatedGrossRates.lie.CREDIT_CARDS.map(
      (cc: number, i: number) => ({
        name: `${i + 1}`,
        value: cc,
      }),
    );
  }, [calculatedGrossRates.lie.CREDIT_CARDS]);

  const institutionalBankingLieData = useMemo(() => {
    return calculatedGrossRates.lie.INSTITUTIONAL_BANKING.map(
      (ib: number, i: number) => ({
        name: `${i + 1}`,
        value: ib,
      }),
    );
  }, [calculatedGrossRates.lie.INSTITUTIONAL_BANKING]);

  const offshoreBankingLieData = useMemo(() => {
    return calculatedGrossRates.lie.OFFSHORE_BANK.map(
      (ob: number, i: number) => ({
        name: `${i + 1}`,
        value: ob,
      }),
    );
  }, [calculatedGrossRates.lie.OFFSHORE_BANK]);

  const handleCountryChange = (selectedCountry: DropdownOption) => {
    handlePreviewSettingsChange("country", selectedCountry.value);
  };

  const handleProductChange = useCallback(
    (selected) => {
      const newProduct = selected.value;
      setSelectedProduct(newProduct);

      handleDropdownChange("product")(newProduct);
    },
    [handleDropdownChange],
  );

  const showProductSpecificControls = useCallback(
    (productType) => {
      return selectedProduct === productType;
    },
    [selectedProduct],
  );
  const handleFastEditChange = useCallback(
    (
      chartType: ChartType,
      productTypeForLosses: keyof API.EconomicScenarioGrowthAndLosses["lossMultipliers"],
    ) =>
      (selectedValue: { value: string; label: string; round?: string }) => {
        const updatedData: API.EconomicScenarioGrowthAndLosses = {
          ...growthAndLosses,
          netRates: { ...growthAndLosses.netRates },
          lossMultipliers: { ...growthAndLosses.lossMultipliers },
        };
        const roundToEdit = selectedValue.round
          ? parseInt(selectedValue.round.replace("round_", ""))
          : round;

        const handleChartEdit = (
          valuesArray: number[],
          selectedValue: { value: string; round?: string },
          roundToEdit: number,
        ): number[] => {
          const newArray = [...valuesArray];
          if (selectedValue.value === "copy_from" && selectedValue.round) {
            newArray[round - 1] = newArray[roundToEdit];
          } else if (selectedValue.value === "paste_forward") {
            for (let i = round; i < newArray.length; i++) {
              newArray[i] = newArray[round - 1];
            }
          }
          return newArray;
        };
        const handleLossMultiplierEdit = (
          lossMultiplier: any,
          selectedValue: { value: string; round?: string },
          roundToEdit: number,
        ): any => {
          const updatedMultiplier = Array.isArray(lossMultiplier)
            ? [...lossMultiplier]
            : { ...lossMultiplier };

          const applyEditToArray = (array: number[]) => {
            if (selectedValue.value === "copy_from" && selectedValue.round) {
              array[round - 1] = array[roundToEdit];
            } else if (selectedValue.value === "paste_forward") {
              for (let i = round; i < array.length; i++) {
                array[i] = array[round - 1];
              }
            }
          };

          if (Array.isArray(updatedMultiplier)) {
            applyEditToArray(updatedMultiplier);
          } else {
            Object.keys(updatedMultiplier).forEach((subKey) => {
              if (Array.isArray(updatedMultiplier[subKey])) {
                applyEditToArray(updatedMultiplier[subKey]);
              }
            });
          }
          return updatedMultiplier;
        };

        if (chartType === "netRates") {
          Object.keys(updatedData.netRates).forEach((key) => {
            const product = key as keyof typeof updatedData.netRates;
            updatedData.netRates[product] = handleChartEdit(
              updatedData.netRates[product],
              selectedValue,
              roundToEdit,
            );
          });
        } else if (chartType === "lossMultipliers") {
          updatedData.lossMultipliers[productTypeForLosses] =
            handleLossMultiplierEdit(
              updatedData.lossMultipliers[productTypeForLosses],
              selectedValue,
              roundToEdit,
            );
        }
        handleAndRecalc(updatedData);
      },
    [growthAndLosses, handleAndRecalc, round],
  );

  return (
    <VerticalGroup wide spaceBetweenElements={3}>
      <InlineGroup stretch block evenWidthChildren>
        <InlineGroup stretch block>
          <EconomySettingDropDown
            scenariosData={scenarios}
            onChange={handleUpdate}
            value={selectedScenario?.id}
            small
          />
        </InlineGroup>
        <InlineGroup stretch block reverse>
          <InlineGroup spaceBetweenElements={3}>
            <ForthProductDropDown
              small
              onChange={handleProductChange}
              value={selectedProduct}
            />
            <CountryDropdown
              small
              onChange={handleCountryChange}
              value={previewSettings.country}
            />
            <EconomicScenarioRoundDropdown
              onChange={setRound}
              currentRound={round}
              small
            />
          </InlineGroup>
        </InlineGroup>
      </InlineGroup>
      <Card wide>
        <InlineGroup stretch spread>
          <VerticalGroup wide spaceBetweenElements={4}>
            <Text bold>Product Growth Rate (%)</Text>
            <div className="grid-wrapper">
              <div className="grid-container">
                <Text>Product</Text>
                <Text>Gross</Text>
                <Text>Net</Text>
                <Text>Home Loans</Text>
                <Text>
                  {convertBpsToPercentage(
                    calculatedGrossRates.grossGrowthRates.HOME_LOANS[round - 1],
                    2,
                  )}
                </Text>
                <PercentageDropdown
                  value={growthAndLosses.netRates.HOME_LOANS[round - 1]}
                  onChange={handleNetRatesChange("HOME_LOANS")}
                  min={-50}
                  max={50}
                  step={1}
                  direction="desc"
                  small
                />
                <Text>Business Loans</Text>
                <Text>
                  {convertBpsToPercentage(
                    calculatedGrossRates.grossGrowthRates.BUSINESS_LOANS[
                      round - 1
                    ],
                    2,
                  )}
                </Text>
                <PercentageDropdown
                  value={growthAndLosses.netRates.BUSINESS_LOANS[round - 1]}
                  onChange={handleNetRatesChange("BUSINESS_LOANS")}
                  min={-50}
                  max={50}
                  step={1}
                  direction="desc"
                  small
                />
                <Text>Deposits</Text>
                <Text>
                  {convertBpsToPercentage(
                    calculatedGrossRates.grossGrowthRates.DEPOSITS[round - 1],
                    2,
                  )}
                </Text>
                <PercentageDropdown
                  value={growthAndLosses.netRates.DEPOSITS[round - 1]}
                  onChange={handleNetRatesChange("DEPOSITS")}
                  min={-50}
                  max={50}
                  step={1}
                  direction="desc"
                  small
                />
                {showProductSpecificControls("WEALTH_MANAGEMENT") && (
                  <>
                    <Text>Wealth Management</Text>
                    <Text>
                      {convertBpsToPercentage(
                        calculatedGrossRates.grossGrowthRates.WEALTH_MANAGEMENT[
                          round - 1
                        ],
                        2,
                      )}
                    </Text>
                    <PercentageDropdown
                      value={
                        growthAndLosses.netRates.WEALTH_MANAGEMENT[round - 1]
                      }
                      onChange={handleNetRatesChange("WEALTH_MANAGEMENT")}
                      min={-50}
                      max={50}
                      step={1}
                      direction="desc"
                      small
                    />
                  </>
                )}
                {showProductSpecificControls("CREDIT_CARDS") && (
                  <>
                    <Text>Credit cards</Text>
                    <Text>
                      {convertBpsToPercentage(
                        calculatedGrossRates.grossGrowthRates.CREDIT_CARDS[
                          round - 1
                        ],
                        2,
                      )}
                    </Text>
                    <PercentageDropdown
                      value={growthAndLosses.netRates.CREDIT_CARDS[round - 1]}
                      onChange={handleNetRatesChange("CREDIT_CARDS")}
                      min={-50}
                      max={50}
                      step={1}
                      direction="desc"
                      small
                    />{" "}
                  </>
                )}
                {showProductSpecificControls("INSTITUTIONAL_BANKING") && (
                  <>
                    <Text>Institutional Lending</Text>
                    <Text>
                      {convertBpsToPercentage(
                        calculatedGrossRates.grossGrowthRates
                          .INSTITUTIONAL_BANKING[round - 1],
                        2,
                      )}
                    </Text>
                    <PercentageDropdown
                      value={
                        growthAndLosses.netRates.INSTITUTIONAL_BANKING[
                          round - 1
                        ]
                      }
                      onChange={handleNetRatesChange("INSTITUTIONAL_BANKING")}
                      min={-50}
                      max={50}
                      step={1}
                      direction="desc"
                      small
                    />
                  </>
                )}
                {showProductSpecificControls("OFFSHORE_BANK") && (
                  <>
                    <Text>Offshore Banking</Text>
                    <Text>
                      {convertBpsToPercentage(
                        calculatedGrossRates.grossGrowthRates.OFFSHORE_BANK[
                          round - 1
                        ],
                        2,
                      )}
                    </Text>
                    <PercentageDropdown
                      value={growthAndLosses.netRates.OFFSHORE_BANK[round - 1]}
                      onChange={handleNetRatesChange("OFFSHORE_BANK")}
                      min={-50}
                      max={50}
                      step={1}
                      direction="desc"
                      small
                    />
                  </>
                )}
              </div>
            </div>

            <VerticalGroup wide spaceBetweenElements={2}>
              <hr />
              <Text>Fast Editing</Text>
              <FastEditingDropDown
                currentRound={round}
                midsize
                onChange={handleFastEditChange("netRates", "HOME_LOANS")}
              />
            </VerticalGroup>
          </VerticalGroup>
          <InlineGroup>
            <EconomicScenarioLineChart
              onRoundChange={handleRoundChange}
              currentRound={round}
              height={400}
              data={productGrowthRateData}
              lineConfigurations={[
                {
                  dataKey: "HOME_LOANS",
                  name: "Home Loans",
                  strokeColor: "#27A4F2",
                  activeDot: true,
                },
                {
                  dataKey: "BUSINESS_LOANS",
                  name: "Business Loans",
                  strokeColor: "#67D0B6",
                },
                {
                  dataKey: "DEPOSITS",
                  name: "Deposits Loans",
                  strokeColor: "#9549D6",
                },
                ...(selectedProduct === "WEALTH_MANAGEMENT"
                  ? [
                      {
                        dataKey: "WEALTH_MANAGEMENT",
                        name: "Wealth Management",
                        strokeColor: "#F9AA3C",
                      },
                    ]
                  : []),
                ...(selectedProduct === "CREDIT_CARDS"
                  ? [
                      {
                        dataKey: "CREDIT_CARDS",
                        name: "Credit cards",
                        strokeColor: "#E876E1",
                      },
                    ]
                  : []),
                ...(selectedProduct === "INSTITUTIONAL_BANKING"
                  ? [
                      {
                        dataKey: "INSTITUTIONAL_BANKING",
                        name: "Institutional Lending",
                        strokeColor: "#FA6A50",
                      },
                    ]
                  : []),
                ...(selectedProduct === "OFFSHORE_BANK"
                  ? [
                      {
                        dataKey: "OFFSHORE_BANK",
                        name: "Offshore Banking",
                        strokeColor: "#06A882",
                      },
                    ]
                  : []),
              ]}
              formatter={wsfConvertDivide}
            />
          </InlineGroup>
        </InlineGroup>
      </Card>
      <Card wide>
        <InlineGroup spread>
          <VerticalGroup wide spaceBetweenElements={3}>
            <VerticalGroup spaceBetweenElements={5} wide>
              <Text center bold>
                Home Loans Losses (Bps)
              </Text>
              <div className="losses-grid-container">
                <InlineGroup center className="loss-multiplier">
                  <Text>Loss Multipliers</Text>
                </InlineGroup>
                <InlineGroup>
                  <Text>Proprietary</Text>
                </InlineGroup>
                <InlineGroup>
                  <MultiplierDropdown
                    value={
                      growthAndLosses.lossMultipliers.HOME_LOANS
                        .proprietaryLoans[round - 1]
                    }
                    onChange={handleLossMultiplierChange(
                      "HOME_LOANS",
                      "proprietaryLoans",
                    )}
                    min={0}
                    max={25}
                    step={1}
                  />
                </InlineGroup>
                <InlineGroup className="grid-spacer">
                  <Text>Broker</Text>
                </InlineGroup>
                <InlineGroup className="grid-spacer">
                  <MultiplierDropdown
                    value={
                      growthAndLosses.lossMultipliers.HOME_LOANS.brokerLoans[
                        round - 1
                      ]
                    }
                    onChange={handleLossMultiplierChange(
                      "HOME_LOANS",
                      "brokerLoans",
                    )}
                    min={0}
                    max={25}
                    step={1}
                  />
                </InlineGroup>
              </div>
              <hr />
            </VerticalGroup>
            <Text>Fast Editing</Text>
            <FastEditingDropDown
              currentRound={round}
              midsize
              onChange={handleFastEditChange("lossMultipliers", "HOME_LOANS")}
            />
          </VerticalGroup>
          <InlineGroup>
            <EconomicScenarioLineChart
              onRoundChange={handleRoundChange}
              currentRound={round}
              data={homeLoanLieData}
              formatter={wsfConvertDivide}
              lineConfigurations={[
                {
                  dataKey: "proprietaryLoans",
                  name: "Proprietary",
                  strokeColor: "#27A4F2",
                  activeDot: true,
                },
                {
                  dataKey: "brokerLoans",
                  name: "Broker",
                  strokeColor: "#67D0B6",
                },
                {
                  dataKey: "portfolio",
                  name: "Portfolio",
                  strokeColor: "#9549D6",
                },
              ]}
            />
          </InlineGroup>
        </InlineGroup>
      </Card>
      <Card wide>
        <InlineGroup spread>
          <VerticalGroup wide spaceBetweenElements={3}>
            <VerticalGroup wide spaceBetweenElements={5}>
              <Text bold>Business Loans Losses (Bps)</Text>
              <div className="losses-grid-container">
                <InlineGroup center className="loss-multiplier">
                  <Text>Loss Multipliers</Text>
                </InlineGroup>

                <Text>Good</Text>

                <MultiplierDropdown
                  value={
                    growthAndLosses.lossMultipliers.BUSINESS_LOANS.goodQuality[
                      round - 1
                    ]
                  }
                  onChange={handleLossMultiplierChange(
                    "BUSINESS_LOANS",
                    "goodQuality",
                  )}
                  min={0}
                  max={25}
                  step={1}
                />

                <InlineGroup className="grid-spacer">
                  <Text>Medium</Text>
                </InlineGroup>
                <InlineGroup className="grid-spacer">
                  <MultiplierDropdown
                    value={
                      growthAndLosses.lossMultipliers.BUSINESS_LOANS
                        .mediumQuality[round - 1]
                    }
                    onChange={handleLossMultiplierChange(
                      "BUSINESS_LOANS",
                      "mediumQuality",
                    )}
                    min={0}
                    max={25}
                    step={1}
                  />
                </InlineGroup>
                <InlineGroup className="grid-spacer">
                  <Text>Poor</Text>
                </InlineGroup>
                <InlineGroup className="grid-spacer">
                  <MultiplierDropdown
                    value={
                      growthAndLosses.lossMultipliers.BUSINESS_LOANS
                        .poorQuality[round - 1]
                    }
                    onChange={handleLossMultiplierChange(
                      "BUSINESS_LOANS",
                      "poorQuality",
                    )}
                    min={0}
                    max={25}
                    step={1}
                  />
                </InlineGroup>
              </div>
              <hr />
            </VerticalGroup>
            <Text>Fast Editing</Text>
            <FastEditingDropDown
              currentRound={round}
              midsize
              onChange={handleFastEditChange(
                "lossMultipliers",
                "BUSINESS_LOANS",
              )}
            />
          </VerticalGroup>

          <InlineGroup>
            <EconomicScenarioLineChart
              onRoundChange={handleRoundChange}
              currentRound={round}
              data={businessLoanLieData}
              formatter={wsfConvertDivide}
              lineConfigurations={[
                { dataKey: "good", name: "Good", strokeColor: "#27A4F2" },
                { dataKey: "medium", name: "Medium", strokeColor: "#67D0B6" },
                { dataKey: "poor", name: "Poor", strokeColor: "#9549D6" },
                {
                  dataKey: "portfolio",
                  name: "Portfolio",
                  strokeColor: "#E876E1",
                },
              ]}
            />
          </InlineGroup>
        </InlineGroup>
      </Card>
      {showProductSpecificControls("CREDIT_CARDS") && (
        <Card wide>
          <InlineGroup>
            <VerticalGroup wide spaceBetweenElements={3}>
              <VerticalGroup wide spaceBetweenElements={5}>
                <Text bold>Credit Cards Losses (Bps)</Text>
                <div className="losses-grid-container">
                  <InlineGroup center className="loss-multiplier">
                    <Text>Loss Multipliers</Text>
                  </InlineGroup>
                  <Text>Credit Cards</Text>
                  <MultiplierDropdown
                    value={
                      growthAndLosses.lossMultipliers.CREDIT_CARDS[round - 1]
                    }
                    onChange={handleLossMultiplierChange("CREDIT_CARDS")}
                    min={0}
                    max={25}
                    step={1}
                  />
                </div>
                <hr />
              </VerticalGroup>
              <Text>Fast Editing</Text>
              <FastEditingDropDown
                currentRound={round}
                midsize
                onChange={handleFastEditChange(
                  "lossMultipliers",
                  "CREDIT_CARDS",
                )}
              />
            </VerticalGroup>
            <EconomicScenarioLineChart
              onRoundChange={handleRoundChange}
              currentRound={round}
              data={creditCardLieData}
              formatter={wsfConvertDivide}
              lineConfigurations={[
                {
                  dataKey: "value",
                  name: "Credit cards",
                  strokeColor: "#E876E1",
                  activeDot: true,
                },
              ]}
            />
          </InlineGroup>
        </Card>
      )}
      {showProductSpecificControls("INSTITUTIONAL_BANKING") && (
        <Card wide>
          <InlineGroup>
            <VerticalGroup wide spaceBetweenElements={3}>
              <VerticalGroup wide spaceBetweenElements={5}>
                <Text bold>Institutional Lending Losses (Bps)</Text>
                <div className="losses-grid-container">
                  <InlineGroup center className="loss-multiplier">
                    <Text>Loss Multipliers</Text>
                  </InlineGroup>
                  <Text>Institutional Lending</Text>
                  <MultiplierDropdown
                    value={
                      growthAndLosses.lossMultipliers.INSTITUTIONAL_BANKING[
                        round - 1
                      ]
                    }
                    onChange={handleLossMultiplierChange(
                      "INSTITUTIONAL_BANKING",
                    )}
                    min={0}
                    max={25}
                    step={1}
                  />
                </div>
                <hr />
              </VerticalGroup>
              <Text>Fast Editing</Text>
              <FastEditingDropDown
                currentRound={round}
                midsize
                onChange={handleFastEditChange(
                  "lossMultipliers",
                  "INSTITUTIONAL_BANKING",
                )}
              />
            </VerticalGroup>
            <EconomicScenarioLineChart
              onRoundChange={handleRoundChange}
              currentRound={round}
              data={institutionalBankingLieData}
              formatter={wsfConvertDivide}
              lineConfigurations={[
                {
                  dataKey: "value",
                  name: "Institutional Banking",
                  strokeColor: "#E876E1",
                  activeDot: true,
                },
              ]}
            />
          </InlineGroup>
        </Card>
      )}
      {showProductSpecificControls("OFFSHORE_BANK") && (
        <Card wide>
          <InlineGroup>
            <VerticalGroup wide spaceBetweenElements={3}>
              <VerticalGroup wide spaceBetweenElements={5}>
                <Text bold>Offshore Bank Losses (Bps)</Text>
                <div className="losses-grid-container">
                  <InlineGroup center className="loss-multiplier">
                    <Text>Loss Multipliers</Text>
                  </InlineGroup>
                  <Text>Offshore Bank</Text>
                  <MultiplierDropdown
                    value={
                      growthAndLosses.lossMultipliers.OFFSHORE_BANK[round - 1]
                    }
                    onChange={handleLossMultiplierChange("OFFSHORE_BANK")}
                    min={0}
                    max={25}
                    step={1}
                  />
                </div>
                <hr />
              </VerticalGroup>
              <VerticalGroup wide spaceBetweenElements={2}>
                <Text>Fast Editing</Text>
                <FastEditingDropDown
                  currentRound={round}
                  midsize
                  onChange={handleFastEditChange(
                    "lossMultipliers",
                    "OFFSHORE_BANK",
                  )}
                />
              </VerticalGroup>
            </VerticalGroup>
            <EconomicScenarioLineChart
              onRoundChange={handleRoundChange}
              currentRound={round}
              data={offshoreBankingLieData}
              formatter={wsfConvertDivide}
              lineConfigurations={[
                {
                  dataKey: "value",
                  name: "Offshore Bank",
                  strokeColor: "#E876E1",
                  activeDot: true,
                },
              ]}
            />
          </InlineGroup>
        </Card>
      )}
    </VerticalGroup>
  );
};

export default GrowthLosses;
