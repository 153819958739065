import React from "react";

import Text from "../../atoms/text/Text";
import Icon from "../../atoms/icon/Icon";

interface Props {
  label: string;
}

const DraggableView: React.FC<Props> = ({ label }) => {
  return (
    <>
      <Text size="sm" colour="white" bold>
        {label}
      </Text>
      <div className="icon-container">
        <Icon type="draggable" colour="white" />
      </div>
    </>
  );
};

export default DraggableView;
