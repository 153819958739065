import React from "react";

import Container from "../../../../../atoms/page/Page";
import SimpleModal from "../../../../../organisms/standard-modal/SimpleModal";
import SimulationPreview from "../../../../../organisms/simulation-preview";
import Text from "../../../../../atoms/text/Text";
interface Props {
  isOpen: boolean;
  onClose: () => void;
  simulationId: string;
}

const SimulationPreviewModal: React.FC<Props> = ({
  simulationId,
  isOpen,
  onClose,
}) => {
  return (
    <SimpleModal size="scale" isOpen={isOpen} showCloseButton onClose={onClose}>
      <Container className="simulation-edit">
        <Text bold size="xl">
          Simulation Preview
        </Text>
        <SimulationPreview simulationId={simulationId} />
      </Container>
    </SimpleModal>
  );
};

export default SimulationPreviewModal;
