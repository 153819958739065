import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import api from "../../../../../services/api";
import Banner from "../../../../atoms/banner/Banner";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import { SimulationContext } from "../../context";
import useForm from "../../../../../hooks/useForm";
import Toggle from "../../../../atoms/toggle/Toggle";
import { resizeArray, findDuplicate } from "../../lib/array";
import ErrorModal from "../../../../organisms/standard-modal/ErrorModal";
import DealRoomSelect from "./DealRoomSelect";
import "./deals.scss";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import Button from "../../../../atoms/button/Button";

const convertDealsToArray = (
  rounds: number,
  dealRooms: { [key in API.DealRoom]: number },
) => {
  if (Object.keys(dealRooms).length === 0) {
    return [];
  }
  const dealRoomsArray = resizeArray<API.DealRoom>([], rounds, "none");
  for (const [deal, round] of Object.entries(dealRooms)) {
    dealRoomsArray[round - 1] = deal as API.DealRoom;
  }
  return dealRoomsArray;
};

const DealRoom: React.FC = () => {
  const [isErrorOpen, setErrorOpen] = useState(false);
  const { config, updateConfig, assignOnSubmitFunction, updateFormStatus } =
    useContext(SimulationContext);
  const [
    {
      formData: { enabled, dealRooms, amlEventEnabled, amlEventRound },
      error,
      inProgress,
      formUpdated,
      fieldErrors,
    },
    { setFieldSimple, handleSubmit, setFieldRaw, setError },
  ] = useForm(
    config?.dealRoomSetup
      ? {
          ...config.dealRoomSetup,
          dealRooms: config.dealRoomSetup.dealRooms.reduce(
            (acc: { [type: string]: number }, deal, index) => {
              if (deal !== "none") {
                acc[deal] = index + 1;
              }
              return acc;
            },
            {},
          ),
        }
      : {
          enabled: false,
          amlEventEnabled: false,
          amlEventRound: 2,
          dealRooms: {},
        },
  );

  const onSubmit = useCallback(async () => {
    if (config) {
      const payload: API.SimulationUpdateRequest = {
        dealRoomSetup: {
          enabled,
          dealRooms: convertDealsToArray(config.generalSetup.rounds, dealRooms),
          amlEventEnabled,
          ...(amlEventEnabled
            ? {
                amlEventRound,
              }
            : {}),
        },
      };

      const response = await api.editSimulation(config.id, payload);

      updateConfig(response);
      return response;
    }
  }, [
    config,
    enabled,
    dealRooms,
    amlEventEnabled,
    amlEventRound,
    updateConfig,
  ]);

  const handleDealRoomContentUpdate = useCallback(
    async (dealId: string, data: Partial<API.DealConfigResponse>) => {
      if (config?.dealRoomConfig) {
        const payload: API.SimulationUpdateRequest = {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          dealRoomConfig: config?.dealRoomConfig.map(({ imageUrl, ...drc }) => {
            if (drc.id !== dealId) {
              return drc;
            }
            return {
              ...drc,
              ...data,
            };
          }),
        };

        const response = await api.editSimulation(config.id, payload);

        updateConfig(response);
      }
    },
    [config, updateConfig],
  );

  const validate = useCallback(
    (newDealRooms?: { [key in API.DealRoom]: number }) => {
      const errors = [];
      const dealRoomsToUse = (newDealRooms ?? dealRooms) as {
        [key in API.DealRoom]: number;
      };
      const roundsArray: number[] = Object.entries(dealRoomsToUse)
        .filter((entry) => entry[0] !== "none")
        .map((entry) => entry[1]);
      const duplicatedValueSet = findDuplicate<number>(roundsArray);

      for (const dealRoomEntry of Object.entries(dealRoomsToUse)) {
        const [dealType, round] = dealRoomEntry;
        if (dealType === "none") {
          errors.push({ field: "dealRooms-none", message: undefined });
          continue;
        }

        if (duplicatedValueSet.has(round)) {
          errors.push({
            field: `dealRooms-${dealType}`,
            message: "Only select 1 deal per round",
          });
        } else {
          errors.push({ field: `dealRooms-${dealType}`, message: undefined });
        }
      }

      if (
        enabled &&
        !Object.keys(dealRoomsToUse).some(
          (d: string) => d != null && d !== "none",
        )
      ) {
        errors.push({
          field: "dealRooms",
          message: "Please select at least one deal or disable the deal room.",
        });
        setErrorOpen(true);
      }

      if (enabled && amlEventEnabled && !amlEventRound) {
        errors.push({
          field: "dealRoomsAmlEventRound",
          message: "Please select a round when enabling the AML Event.",
        });
        setErrorOpen(true);
      } else {
        errors.push({
          field: "dealRoomsAmlEventRound",
          message: undefined,
        });
      }

      return errors;
    },
    [amlEventEnabled, amlEventRound, dealRooms, enabled],
  );

  useEffect(() => {
    assignOnSubmitFunction(handleSubmit(onSubmit, validate, true));
  }, [assignOnSubmitFunction, handleSubmit, onSubmit, validate]);

  useEffect(() => {
    updateFormStatus({
      inProgress: inProgress,
      formUpdated: formUpdated,
    });
  }, [updateFormStatus, inProgress, formUpdated]);

  const updateDealRoom = useCallback(
    (newValue: { [key in API.DealRoom]: number }) => {
      if (!newValue.neobank) {
        setFieldRaw("amlEventEnabled", false);
        setFieldRaw("amlEventRound", null);
      }
      setFieldRaw("dealRooms", newValue);

      const errors = validate(newValue);
      for (const err of errors) {
        setError(err.field, err.message);
      }
    },
    [setError, setFieldRaw, validate],
  );

  const clearAll = useCallback(() => {
    setFieldRaw("dealRooms", {});
  }, [setFieldRaw]);

  const onAMLEventEnabled = useCallback(
    (enabled: boolean) => {
      setFieldRaw("amlEventEnabled", enabled);
      if (enabled) {
        setFieldRaw(
          "amlEventRound",
          Math.min(
            dealRooms["neobank"] + 1,
            config?.generalSetup.rounds ?? dealRooms["neobank"],
          ),
        );
      }
    },
    [setFieldRaw, dealRooms, config?.generalSetup.rounds],
  );

  const onAMLEventRoundChange = useCallback(
    (option) => {
      setFieldRaw("amlEventRound", option.value);
    },
    [setFieldRaw],
  );

  const errorProperties = useMemo(() => {
    return {
      isOpen:
        (!!fieldErrors.dealRooms ||
          !!fieldErrors.dealRoomsNeoBank ||
          !!fieldErrors.dealRoomsAmlEventRound) &&
        isErrorOpen,
      title: fieldErrors.dealRooms ? "No Deals Selected" : "AML Event",
      message:
        fieldErrors.dealRooms ||
        fieldErrors.dealRoomsNeoBank ||
        fieldErrors.dealRoomsAmlEventRound,
    };
  }, [fieldErrors, isErrorOpen]);

  return (
    <VerticalGroup
      wide
      full
      spaceBetweenElements={6}
      className="deals-container"
    >
      <InlineGroup block spread verticalCenter>
        <Banner type="error" active={!!error} message={error?.message} />
        <h3>Deal Room Settings</h3>
      </InlineGroup>
      <InlineGroup block spread verticalCenter>
        <Toggle
          label="Enable Deal Room"
          helpTitle="Deal Room"
          helpDescription="This allows participants to undertake acquisitions of different businesses. ie inorganic growth."
          checked={enabled}
          onUpdate={setFieldSimple("enabled")}
          block
        />
        {enabled && (
          <Button
            wide
            disabled={
              Object.keys(dealRooms).filter((k) => k !== "none").length === 0
            }
            onClick={clearAll}
          >
            Clear All
          </Button>
        )}
      </InlineGroup>
      {enabled && (
        <DealRoomSelect
          onUpdateContent={handleDealRoomContentUpdate}
          deals={config?.dealRoomConfig ?? []}
          fieldErrors={fieldErrors}
          selected={dealRooms}
          onChange={updateDealRoom}
          numberOfRounds={config?.generalSetup.rounds ?? 0}
          amlEventEnabled={amlEventEnabled}
          onAMLEventEnabled={onAMLEventEnabled}
          amlEventRound={amlEventRound}
          onAMLEventRoundChange={onAMLEventRoundChange}
        />
      )}
      {errorProperties.isOpen && (
        <ErrorModal
          isOpen={errorProperties.isOpen}
          onClose={() => {
            setErrorOpen(false);
          }}
          title={errorProperties.title}
          description={errorProperties.message}
        ></ErrorModal>
      )}
    </VerticalGroup>
  );
};

export { default as DealRoomDisplay } from "./Display";
export default DealRoom;
