import React, { useCallback, useContext } from "react";

import { SimulationContext } from "../../context";
import useForm from "../../../../../hooks/useForm";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import api from "../../../../../services/api";
import InfoPopups from "./InfoPopups";

const Labels: React.FC = () => {
  const { config, updateConfig } = useContext(SimulationContext);
  const [
    {
      formData: { helpConfig },
    },
  ] = useForm(
    config
      ? {
          helpConfig: config.helpConfig,
        }
      : {},
  );

  const handleHelpContentUpdate = useCallback(
    async (helpId: string, data: Partial<API.HelpConfigResponse>) => {
      if (config?.helpConfig) {
        const payload: API.SimulationUpdateRequest = {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          helpConfig: config?.helpConfig.map((hc) => {
            if (hc.id !== helpId) {
              return hc;
            }
            return {
              ...hc,
              ...data,
            };
          }),
        };

        const response = await api.editSimulation(config.id, payload);

        updateConfig(response);
      }
    },
    [config, updateConfig],
  );

  return (
    <VerticalGroup full wide style={{ flex: "auto", overflowY: "hidden" }}>
      <InfoPopups
        helpItems={helpConfig}
        onUpdateContent={handleHelpContentUpdate}
      />
    </VerticalGroup>
  );
};

export { default as MetadataDisplay } from "./Display";

export default Labels;
