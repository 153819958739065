import dateFormat from "dateformat";
import { pluralise } from "./pluralise";

export const MILLISECONDS_IN_SECOND = 1000;
export const DAY_SECONDS = 86400;
export const HOUR_SECONDS = 3600;
export const MINUTE_SECONDS = 60;

export const DAY_MILLISECONDS = DAY_SECONDS * MILLISECONDS_IN_SECOND;
export const HOUR_MILLISECONDS = HOUR_SECONDS * MILLISECONDS_IN_SECOND;
export const MINUTE_MILLISECONDS = MINUTE_SECONDS * MILLISECONDS_IN_SECOND;

export const convertToYYYYMMDD = (date: Date) => dateFormat(date, "yyyy-mm-dd");

export const fullDate = (date: string) => dateFormat(date, "d mmmm yyyy");
export const fullDateAndTime = (date: string) =>
  dateFormat(date, "ddd d mmmm yyyy h:MMtt");

export const shortDate = (date: string) => dateFormat(date, "d-mmm-yyyy");

export const parseTimeLeftFromSeconds = (
  seconds: number,
  multipleLines = false,
): { time: string; displayType: string } => {
  if (seconds <= 0) {
    return { time: "00:00", displayType: "M" };
  }
  if (seconds >= DAY_SECONDS) {
    return secondsToDH(seconds);
  } else if (seconds >= HOUR_SECONDS) {
    return multipleLines
      ? secondsToHMMultipleLines(seconds)
      : secondsToHM(seconds);
  } else {
    return secondsToHMS(seconds);
  }
};

const pad = (val: number) => {
  if (val < 10) {
    return `0${val}`;
  }
  return val;
};

const secondsToDH = (s: number) => {
  const days = Math.floor(s / DAY_SECONDS);
  const hours = Math.floor((s - days * DAY_SECONDS) / HOUR_SECONDS);
  const hoursString = hours > 0 ? `, ${hours} ${pluralise("hour", hours)}` : "";
  return {
    time: `${days} ${pluralise("day", days)}${hoursString}`,
    displayType: "D",
  };
};

const secondsToHM = (s: number) => {
  const hours = Math.floor(s / HOUR_SECONDS);
  const minutes = Math.floor((s - hours * HOUR_SECONDS) / MINUTE_SECONDS);
  const minutesString =
    minutes > 0 ? `, ${minutes} ${pluralise("minute", minutes)}` : "";
  return {
    time: `${hours} ${pluralise("hour", hours)}${minutesString}`,
    displayType: "H",
  };
};

const secondsToHMMultipleLines = (s: number) => {
  const hours = Math.floor(s / HOUR_SECONDS);
  const minutes = Math.floor((s - hours * HOUR_SECONDS) / MINUTE_SECONDS);
  const minutesString =
    minutes > 0 ? `${minutes} ${pluralise("minute", minutes)}` : "";
  return {
    time: `${hours} ${pluralise("hour", hours)}
${minutesString}`,
    displayType: "H",
  };
};

const secondsToHMS = (s: number) => {
  const hours = Math.floor(s / HOUR_SECONDS);
  const minutes = Math.floor((s - hours * HOUR_SECONDS) / MINUTE_SECONDS);
  const seconds = Math.floor(s - minutes * MINUTE_SECONDS);
  return {
    time: `${pad(minutes)}:${pad(seconds)}`,
    displayType: "M",
  };
};

const secondsToM = (s: number) => {
  const hours = Math.floor(s / HOUR_SECONDS);
  const minutes = Math.floor((s - hours * HOUR_SECONDS) / MINUTE_SECONDS);
  return {
    time: `${minutes} ${pluralise("minute", minutes)}`,
    displayType: "M",
  };
};

export const differenceISOTime = (s: string, date: string | null) => {
  const startTime = date ? new Date(date).getTime() : new Date().getTime();
  const finishTime = new Date(s).getTime();
  return (finishTime - startTime) / 1000;
};

export const addDaysToDate = (numberOfDays: number, val: Date): Date => {
  const date = new Date(val);
  date.setTime(date.getTime() + numberOfDays * DAY_MILLISECONDS);
  return date;
};

export const addSecondsToDate = (numberOfSeconds: number, val: Date): Date => {
  const date = new Date(val);
  date.setTime(date.getTime() + numberOfSeconds * MILLISECONDS_IN_SECOND);
  return date;
};

export const duration = (startDate: Date, finishDate: Date): string => {
  const seconds = differenceISOTime(
    finishDate.toISOString()!,
    startDate.toISOString(),
  );
  if (seconds <= 0) {
    return "-";
  }
  if (seconds >= DAY_SECONDS) {
    return secondsToDH(seconds).time;
  } else if (seconds >= HOUR_SECONDS) {
    return secondsToHM(seconds).time;
  } else {
    return secondsToM(seconds).time;
  }
};

export const formatAMPM = (date: Date): string => {
  const hours = date.getHours();
  return `${hours === 12 ? 12 : hours % 12}:${pad(date.getMinutes())} ${hours >= 12 ? "PM" : "AM"}`;
};

export const formatTime = (date: Date): string => {
  return `${formatAMPM(date)}`;
};
export const formatRoundedMinutes = (seconds: number): string => {
  const minutes = Math.round(seconds / 60);
  return `${minutes} mins`;
};

export const formatISOStringWithDateFormat = (isoString: string): string => {
  const date = new Date(isoString);
  const time = dateFormat(date, "hh:MMtt");
  const dayMonthYear = dateFormat(date, "dd mmmm yyyy");
  return `${time} on ${dayMonthYear}`;
};
