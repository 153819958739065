import React, { useState, useMemo, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import Image, { Props } from "../../atoms/image/Image";
import { ImageModal } from "./ImageModal";
import classNames from "classnames";
import "./image-preview-modal.scss";

interface ImageWithPreviewModalProps extends Props {
  tip?: string;
}

export const ImageWithPreviewModal: React.FC<ImageWithPreviewModalProps> = ({
  tip = "Click to preview image",
  ...props
}) => {
  // open modal when click on image
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const image = useMemo(() => {
    return (
      <div className="image-preview-modal">
        <Image
          {...props}
          {...(tip ? { "data-tip": tip, "data-place": "right" } : {})}
          className={classNames("image-preview", "image", props.className)}
          onClick={() => setIsOpen(true)}
        />
      </div>
    );
  }, [props, tip]);

  return (
    <>
      {image}
      <ImageModal
        showCloseButton
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        src={props.src}
        alt={props.alt}
      />
    </>
  );
};
