import React, { useCallback, useMemo } from "react";
import EditableText from "../../../atoms/text/EditableText";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";

import { Accordion } from "../../../organisms/accordion/accordion";
import TableWithSections, { CustomCell } from "../tables/TableWithSections";

interface Props {
  title: string;
  configuration: ModelAPI.ConfigurationResponse;
  numberOfTeams: number;
  adjustmentData: ModelAPI.FacilitatorAdjustmentsResponse;
  adjustmentField: keyof ModelAPI.FacilitatorAdjustmentsResponse;
  resultData: ModelAPI.RoundResultsResponse[];
  resultField: keyof ModelAPI.RoundResultsYearResponse;
  fields: string[];
  fieldFormatter: (field: string) => string;
  formatter?: (
    val: number,
    section: string | undefined,
    field: string,
    index: number,
  ) => string | null;
  onUpdate: (
    type: keyof ModelAPI.FacilitatorAdjustmentsResponse,
    teamId: number,
    year: number,
    value: number,
  ) => void;
  onAdjustmentMade?: () => void;
}

const ModelAdjustmentsSection: React.FC<Props> = ({
  title,
  configuration,
  adjustmentData,
  adjustmentField,
  resultData,
  resultField,
  numberOfTeams,
  fields,
  fieldFormatter,
  formatter,
  onUpdate,
  onAdjustmentMade,
}) => {
  const { numberOfHistoricRounds, numberOfRounds, numberOfPostGameRounds } =
    configuration;
  const totalNumberOfRounds =
    numberOfHistoricRounds + numberOfRounds + numberOfPostGameRounds;

  const handleAdjustment = useCallback(
    (team: number, year: number, value: string) => {
      const numValue = Number(value);
      onUpdate(adjustmentField, team, year, numValue);
      if (onAdjustmentMade) {
        onAdjustmentMade();
      }
    },
    [adjustmentField, onUpdate, onAdjustmentMade],
  );

  const adjustmentValues = useMemo(() => {
    const result = [];
    for (let year = 0; year < totalNumberOfRounds; year++) {
      if (
        year >= numberOfHistoricRounds &&
        year < numberOfHistoricRounds + numberOfRounds
      ) {
        const d: { [key: number]: CustomCell } = {};
        for (let team = 1; team <= numberOfTeams; team++) {
          const value = adjustmentData[adjustmentField][team][year] ?? 0;
          d[team] = {
            clickable: true,
            component: (
              <EditableText
                small
                type="text"
                value={value}
                onChange={(value: string) =>
                  handleAdjustment(team, year, value)
                }
                formatter={(value: string) => (value === "" ? "0" : value)}
              />
              // <Input small type="text" value={value} onChange={() => onUpdate(adjustmentField, team, year)} />
            ),
          };
        }
        result.push(d);
      } else {
        const d: { [key: number]: number } = {};
        for (let team = 1; team <= numberOfTeams; team++) {
          d[team] = adjustmentData[adjustmentField][team][year];
        }
        result.push(d);
      }
    }
    return result;
  }, [
    adjustmentData,
    adjustmentField,
    numberOfHistoricRounds,
    numberOfRounds,
    numberOfTeams,
    totalNumberOfRounds,
    handleAdjustment,
  ]);

  const values = useMemo(() => {
    const result = [];
    for (let year = 0; year < totalNumberOfRounds; year++) {
      const d: { [key: number]: number } = {};
      for (let team = 1; team <= numberOfTeams; team++) {
        const yearData = resultData
          .find((d) => d.teamId === team)
          ?.years.find((y) => y.year === year + 1);
        if (yearData && typeof yearData[resultField] === "number") {
          d[team] = yearData[resultField] as number;
        }
      }
      result.push(d);
    }
    return result;
  }, [numberOfTeams, resultData, resultField, totalNumberOfRounds]);

  return (
    <Accordion title={title} defaultState="collapse" wide>
      <VerticalGroup wide className="mt-2" spaceBetweenElements={2}>
        <TableWithSections
          header="Result (w/ adjustments)"
          data={[
            {
              fields,
              values,
            },
          ]}
          fieldFormatter={fieldFormatter}
          formatter={formatter}
        />
        <TableWithSections
          header="Adjustments"
          data={[
            {
              fields,
              values: adjustmentValues,
            },
          ]}
          fieldFormatter={fieldFormatter}
        />
      </VerticalGroup>
    </Accordion>
  );
};

export default ModelAdjustmentsSection;
