import React, { useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import ClientForm from "./ClientForm";
import API from "../../../../services/api";
import useAPIRequest from "../../../../hooks/useAPIRequest";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import Container from "../../../atoms/page/Page";
import Banner from "../../../atoms/banner/Banner";

function EditClient() {
  const { clientId } = useParams<{ clientId: string }>();

  const callback = useCallback(() => {
    return API.getClient(clientId);
  }, [clientId]);

  const [{ inProgress, data, error }, doApiRequest] = useAPIRequest(
    callback,
    null,
  );

  useEffect(() => {
    doApiRequest();
  }, [doApiRequest]);

  const onSave = useCallback(
    async (data: API.ClientRequest, logo?: File) => {
      let client = await API.editClient(clientId, data);
      if (logo) {
        client = await API.uploadClientLogo(clientId, logo);
      }
      return client;
    },
    [clientId],
  );

  const history = useHistory();

  const onCancel = useCallback(() => {
    history.push(`/administration/clients/${clientId}/view`);
  }, [history, clientId]);

  return (
    <Container>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {data && (
        <ClientForm
          client={{ ...data, userIds: data.users?.map((u) => u.id) ?? [] }}
          onSave={onSave}
          onCancel={onCancel}
        />
      )}
    </Container>
  );
}

export default EditClient;
