import React, { useCallback } from "react";

import ChallengeModal from "../../organisms/challenge-modal/ChallengeModal";
import modelApi from "../../../services/modelApi";
import api from "../../../services/api";
import { wait } from "../../../lib/wait";
import { capitalize } from "../../../services/utils";
import ProgressModal from "../../organisms/standard-modal/ProgressModal";

interface Props {
  isOpen: boolean;
  eventId: string;
  onClose: () => void;
  onComplete: () => void;
}

function ResetGameModal({ isOpen, eventId, onClose, onComplete }: Props) {
  const [inProgress, setInProgress] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [statusMessage, setStatusMessage] = React.useState<string | null>(null);
  const [percentageDone, setPercentageDone] = React.useState(0);
  const handleConfirm = useCallback(async () => {
    setInProgress(true);
    setError(null);
    setStatusMessage(null);
    try {
      setStatusMessage("Resetting game");
      await api.resetGame(eventId);
    } catch (e) {
      setError(e.message);
      setInProgress(false);
      return;
    }
    setPercentageDone(15);
    try {
      let iterations = 0;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        await wait(2000);
        const configuration = await modelApi.getModelConfiguration(eventId);
        console.log(configuration.state, configuration.currentRound);

        if (iterations > 100) {
          throw new Error("Timedout calculating deleteing model data");
        }
        iterations++;
      }
    } catch (e) {
      console.log("Data has been deleted");
    }
    setPercentageDone(30);
    try {
      setStatusMessage("Setting up game again");
      await api.setupGame(eventId, { uploadInitialResults: true });
      let iteration = 0;
      await wait(2000);
      // eslint-disable-next-line no-constant-condition
      while (true) {
        iteration++;
        const configuration = await modelApi.getModelConfiguration(eventId);
        setPercentageDone((prev) => Math.min(prev + 5, 70));
        setStatusMessage(
          `${capitalize(configuration.state)} - ${configuration.progressMessage}`,
        );
        if (configuration.state !== "initialising") {
          break;
        }
        if (iteration > 100) {
          throw new Error("Timed out building model");
        }
        await wait(5000);
      }
    } catch (e) {
      setError(e.message);
      setInProgress(false);
      return;
    }
    setPercentageDone(90);
    setError(null);
    setStatusMessage(null);
    setInProgress(false);
    onComplete();
  }, [eventId, onComplete]);

  if (inProgress) {
    return (
      <ProgressModal
        percentageDone={percentageDone}
        message={statusMessage}
        onClose={() => {
          console.log("Closing");
        }}
      />
    );
  }

  return (
    <ChallengeModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      title={`Are you sure you want to reset this game?`}
      confirmingMessage={statusMessage}
      challengeQuestion="This action is irreversible. Please type the word RESET below to reset this game."
      challengeAnswer="RESET"
      challengeAnswerWarning="Please type the word RESET in CAPS to reset this game."
      confirmTitle="Reset"
      error={error}
      warning
    />
  );
}

export default ResetGameModal;
