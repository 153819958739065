import { EventsWithStatus } from "./EventsTable";
import { formatISOStringWithDateFormat } from "../../../../lib/date";

export const mapEventsWithStatus = (
  data: API.EventResponseSummary[] | null,
  gameApiStatus: GameAPI.APIStatus | null,
): EventsWithStatus[] | null => {
  if (!gameApiStatus || !data) {
    return null;
  }

  return data.map((event) => {
    const status = gameApiStatus[event.id] ?? {
      status: "notsetup",
      totalRounds: 0,
      currentRound: 0,
    };
    const gameStarted = status.currentRound > 0;
    if (
      status.status === "closed" &&
      status.currentRound === status.totalRounds
    ) {
      return {
        ...event,
        status: status.status,
        statusText: "Finished",
        statusIcon: "status",
        statusColour: "danger",
      };
    }

    if (!gameStarted) {
      if (event.schedule?.roundType === "scheduled" && status.gameStartTime) {
        return {
          ...event,
          status: status.status,
          statusText: `This event will commence at: ${formatISOStringWithDateFormat(status.gameStartTime)}`,
          statusIcon: "timer",
          statusColour: "secondaryBlue1",
        };
      }
      return {
        ...event,
        status: status.status,
        statusText: "Not commenced",
        statusIcon: "roundStop",
        statusColour: "purple",
      };
    } else if (status.uiStatus === "locked") {
      if (
        event.schedule?.roundType === "scheduled" &&
        status.nextRoundStartTime
      ) {
        return {
          ...event,
          status: status.status,
          statusText: `Resuming at: ${formatISOStringWithDateFormat(
            status.nextRoundStartTime,
          )} `,
          statusIcon: "timer",
          statusColour: "orange",
        };
      }
      return {
        ...event,
        status: status.status,
        statusText: `Rnd ${status.currentRound} locked`,
        statusIcon: "lock",
        statusColour: "orange",
      };
    }
    if (status.status === "notsetup") {
      return {
        ...event,
        status: status.status,
        statusText: "Not setup yet",
        statusIcon: "rocket",
        statusColour: "grey1",
      };
    }
    return {
      ...event,
      status: status.status,
      statusText: `Rnd ${status.currentRound} in progress`,
      statusIcon: "rocket",
      statusColour: "darkGreen",
    };
  });
};
