import React from "react";
import Text from "../../../atoms/text/Text";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Toggle from "../../../atoms/toggle/Toggle";
import Button from "../../../atoms/button/Button";
import ResultsText from "../../results/results-text/ResultsText";
import useIsMobile from "../../../../hooks/useIsMobile";

interface Props {
  heading?: string;
  mode: "fullscreen" | "thumbnails";
  setMode: (val: "fullscreen" | "thumbnails") => void;
  children?: React.ReactNode;
  onRefresh?: () => void;
  showSpeakerNotes?: boolean;
  setShowSpeakerNotes?: (show: boolean) => void;
  showSpeakerNotesToggle?: boolean;
}

const ReportingTemplatePreviewHeader = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      heading,
      mode,
      setMode,
      children,
      onRefresh,
      showSpeakerNotes,
      setShowSpeakerNotes,
      showSpeakerNotesToggle = true,
    },
    ref,
  ) => {
    const isMobile = useIsMobile();
    ReportingTemplatePreviewHeader.displayName =
      "ReportingTemplatePreviewHeader";

    return (
      <>
        {isMobile && (
          <InlineGroup className="p-3">
            <ResultsText bold size="2xl">
              {heading ?? "Results Slides Preview"}
            </ResultsText>
          </InlineGroup>
        )}
        <InlineGroup
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingBottom: isMobile ? "10px" : "",
          }}
          ref={ref}
          verticalCenter
          spread
        >
          {!isMobile && (
            <ResultsText bold size="sm">
              {heading ?? "Results Slides Preview"}
            </ResultsText>
          )}
          <InlineGroup spaceBetweenElements={2} verticalCenter>
            {children}
            {onRefresh && <Button onClick={onRefresh}>Refresh</Button>}
            <InlineGroup spaceBetweenElements={2} verticalCenter>
              {showSpeakerNotesToggle &&
                showSpeakerNotes !== undefined &&
                setShowSpeakerNotes && (
                  <>
                    <Text>Speaker notes</Text>
                    <Toggle
                      checked={showSpeakerNotes}
                      onUpdate={() => setShowSpeakerNotes(!showSpeakerNotes)}
                    />
                  </>
                )}
              <Text>Fullscreen:</Text>
              <Toggle
                checked={mode === "fullscreen"}
                onUpdate={() => {
                  setMode(mode === "fullscreen" ? "thumbnails" : "fullscreen");
                }}
              />
            </InlineGroup>
          </InlineGroup>
        </InlineGroup>
      </>
    );
  },
);

export default ReportingTemplatePreviewHeader;
