import React, { useState, useMemo } from "react";

import Button from "../../../../../atoms/button/Button";
import Text from "../../../../../atoms/text/Text";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import SelectModal from "./SelectModal";
import { AsyncBrandingPreview } from "../../../../../organisms/brandingpreview/BrandingPreview";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";

interface SelectBrandingProps {
  brandingId?: string;
  branding?: API.BrandingResponse;
  clientId?: string;
  readOnly?: boolean;
  onUnselectBranding?: () => void;
  onBrandingSelected?: (branding: API.BrandingResponse) => void;
}

const SelectBranding: React.FC<SelectBrandingProps> = ({
  brandingId,
  branding,
  clientId,
  readOnly,
  onBrandingSelected,
  onUnselectBranding,
}) => {
  const [showSelectionModal, setShowSelectionModal] = useState(false);

  const status = useMemo(() => {
    if (readOnly) {
      if (branding) {
        return "readOnlyWithBranding";
      }
      return "readOnlyWithoutBranding";
    }

    if (!clientId) {
      return "noClientWarning";
    }

    if (brandingId) {
      return "brandingSelected";
    }

    return "pendingSelection";
  }, [branding, brandingId, clientId, readOnly]);

  const content = useMemo(() => {
    switch (status) {
      case "pendingSelection":
        return (
          <VerticalGroup spaceBetweenElements={4}>
            <Text size="lg">Choose branding for the event</Text>
            <Button
              data-test="select-branding-button"
              onClick={() => setShowSelectionModal(true)}
            >
              Select Branding
            </Button>
          </VerticalGroup>
        );
      case "readOnlyWithBranding":
        return (
          <VerticalGroup spaceBetweenElements={4}>
            <Text size="sm">{branding!.gameName} Selected</Text>
          </VerticalGroup>
        );
      case "readOnlyWithoutBranding":
        return (
          <VerticalGroup spaceBetweenElements={4}>
            <Text size="sm" colour="danger" bold>
              No branding selected
            </Text>
          </VerticalGroup>
        );
      case "noClientWarning":
        return (
          <VerticalGroup spaceBetweenElements={4}>
            <Text size="sm">*Please select client first*</Text>
          </VerticalGroup>
        );
      case "brandingSelected":
        return (
          <VerticalGroup spaceBetweenElements={4} center>
            <Text size="sm">Preview</Text>
            <AsyncBrandingPreview
              brandingId={brandingId!}
              maxContainerHeight={425}
            />
            <InlineGroup spaceBetweenElements={4}>
              <Button
                data-test="select-branding-button"
                onClick={() => setShowSelectionModal(true)}
              >
                Change Branding
              </Button>
              <Button
                data-test="remove-branding-button"
                warning
                onClick={onUnselectBranding}
              >
                Remove Branding
              </Button>
            </InlineGroup>
          </VerticalGroup>
        );
      default:
        return null;
    }
  }, [branding, brandingId, onUnselectBranding, status]);

  return (
    <>
      {content}
      <SelectModal
        data-test="select-branding-modal"
        isOpen={showSelectionModal}
        onClose={() => setShowSelectionModal(false)}
        clientId={clientId!}
        onBrandingSelected={(branding) =>
          onBrandingSelected && onBrandingSelected(branding)
        }
      />
    </>
  );
};

export default SelectBranding;
