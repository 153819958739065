import { useCallback, useEffect, useMemo } from "react";
import ModelAPI from "../services/modelApi";
import useAPIRequest from "./useAPIRequest";
import { PreviewSettings } from "../components/pages/administration/configuration/economicScenarioBuilder/types";

export const usePreviewReportingData = ({
  reportingTemplateId,
  introTemplateId,
  simulationId,
  roundId,
  brandingId,
  eventId,
}: {
  reportingTemplateId?: string;
  introTemplateId?: string;
  simulationId: string;
  roundId: number;
  brandingId?: string;
  eventId?: string;
}) => {
  const callback = useCallback(() => {
    return ModelAPI.getPreviewData({
      reportingTemplateId,
      introTemplateId,
      simulationId,
      roundId,
      brandingId,
      eventId,
    });
  }, [
    brandingId,
    reportingTemplateId,
    introTemplateId,
    roundId,
    simulationId,
    eventId,
  ]);

  const [{ inProgress, data, error }, doAPIRequest] =
    useAPIRequest<ModelAPI.Reporting.ReportingResults>(callback, null);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  return useMemo(
    () => ({
      inProgress,
      data,
      error,
      refresh: doAPIRequest,
    }),
    [inProgress, data, error, doAPIRequest],
  );
};

export const useGenericPreviewReportingData = ({
  reportingTemplateId,
  introTemplateId,
  roundId,
  scenarioId,
  previewSettings,
}: {
  reportingTemplateId?: string | undefined | null;
  introTemplateId?: string | undefined | null;
  roundId: number;
  scenarioId?: string;
  previewSettings?: PreviewSettings;
}) => {
  const callback = useCallback(() => {
    if (!roundId && reportingTemplateId) return Promise.resolve(null);
    return ModelAPI.getGenericPreviewData({
      introTemplateId,
      reportingTemplateId,
      roundId,
      scenarioId,
      previewSettings,
    });
  }, [
    introTemplateId,
    reportingTemplateId,
    roundId,
    scenarioId,
    previewSettings,
  ]);

  const [{ inProgress, data, error }, doAPIRequest] =
    useAPIRequest<ModelAPI.Reporting.ReportingResults | null>(callback, null);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  return useMemo(
    () => ({
      inProgress,
      data,
      error,
      refresh: doAPIRequest,
    }),
    [inProgress, data, error, doAPIRequest],
  );
};

export default usePreviewReportingData;
