import React from "react";
import classNames from "classnames";
import Label from "../label/Label";
import InlineError from "../../inlineerror/InlineError";
import InformationPopup from "../../../organisms/information-popup/InformationPopup";
import InlineGroup from "../../inlinegroup/InlineGroup";
import VerticalGroup from "../../verticalgroup/VerticalGroup";
import { ThemeColours } from "../../../../types/theme";

interface InputContainerProps {
  className?: string;
  label?: string;
  labelFor?: string;
  noBorder?: boolean;
  error?: string;
  block?: boolean;
  containerBlock?: boolean;
  large?: boolean;
  small?: boolean;
  reverse?: boolean;
  right?: boolean;
  center?: boolean;
  helpTitle?: string;
  helpDescription?: string;
  "data-test"?: string;
  verticalCenter?: boolean;
  isVerticalLayout?: boolean;
  colour?: ThemeColours;
  tiny?: boolean;
  smallish?: boolean;
  isLabelHtml?: boolean;
  midsize?: boolean;
}

const InputContainer: React.FC<InputContainerProps> = ({
  className,
  noBorder,
  label,
  labelFor,
  error,
  block,
  containerBlock,
  reverse,
  right,
  small,
  children,
  helpTitle,
  helpDescription,
  verticalCenter = true,
  isVerticalLayout = false,
  isLabelHtml = false,
  colour,
  tiny,
  smallish,
  midsize,

  ...props
}) => (
  <div
    className={classNames(
      "input-container",
      {
        "no-border": !!noBorder,
        "no-label": !label,
        small,
        tiny,
        smallish,
        midsize,
        block,
      },
      [className],
    )}
    {...props}
  >
    {isVerticalLayout && (
      <VerticalGroup
        center={false}
        wide
        className={classNames(
          "input-container-child",
          {
            block: block ?? containerBlock,
            reverse,
            right,
            isVerticalLayout,
          },
          [className],
        )}
      >
        {!!label && (
          <Label
            className={classNames("label", { reverse })}
            labelFor={labelFor}
            colour={colour}
            isHtml={isLabelHtml}
          >
            {label}
          </Label>
        )}
        {children}
        {helpTitle && helpDescription && (
          <InformationPopup
            className="ml-2"
            title={helpTitle}
            body={helpDescription}
          />
        )}
      </VerticalGroup>
    )}
    {!isVerticalLayout && (
      <InlineGroup
        verticalCenter={verticalCenter}
        className={classNames(
          "input-container-child",
          { block: block ?? containerBlock, reverse, right },
          [className],
        )}
      >
        {!!label && (
          <Label
            isHtml={isLabelHtml}
            className={classNames("label", { reverse })}
            labelFor={labelFor}
            colour={colour}
          >
            {label}
          </Label>
        )}
        {children}
        {helpTitle && helpDescription && (
          <InformationPopup
            className="ml-2"
            title={helpTitle}
            body={helpDescription}
          />
        )}
      </InlineGroup>
    )}
    {error && (
      <div className="input-error">
        <InlineError active={!!error} message={error} />
      </div>
    )}
  </div>
);

export default InputContainer;
