import React, { useState } from "react";
import {
  useAssessmentInEvent,
  useCurrentEvent,
} from "../../../../context/CurrentEventContext";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Ribbon from "../../../atoms/ribbon/Ribbon";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Text from "../../../atoms/text/Text";
import EmptyList from "../../../organisms/empty-list/EmptyList";
import { EventAssessmentContainer } from "./components/EventAssessmentContainer";
import "./AssessmentSummary.scss";
import { ProgressBar } from "../../../atoms/progressbar/ProgressBar";
import { useParams } from "react-router-dom";
import { useAssessmentResponses } from "../../../../hooks/useAssessments";
import {
  amountOfParticipantStarted,
  answersRemainingToBeMarked,
  calculateAvg,
  calculateMax,
  calculateMedian,
  calculateMin,
  completedParticipantsForRoundCount,
  getQuestionsForRound,
} from "./utils";
import classNames from "classnames";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import InformationPopup from "../../../organisms/information-popup/InformationPopup";

const Placeholder = () => <div></div>;

interface RowProps {
  title: string;
  rate: string;
  help?: {
    title: string;
    body: string;
  };
  percentage?: number;
}

const SummaryRow: React.FC<RowProps> = ({ title, rate, percentage, help }) => {
  return (
    <>
      <Text size="lg">{title}</Text>
      <Text size="lg" bold>
        {rate}
      </Text>
      {help ? (
        <InformationPopup
          className="ml-2"
          title={help.title}
          body={help.body}
        />
      ) : (
        <Placeholder />
      )}
      {typeof percentage !== "undefined" ? (
        <ProgressBar percentage={percentage} />
      ) : (
        <Placeholder />
      )}
    </>
  );
};

export const AssessmentSummary: React.FC = () => {
  const [currentRound, setRound] = useState(0);
  const { event, inProgress: isLoadingEvent } = useCurrentEvent();
  const { assessment } = useAssessmentInEvent();
  const { eventId } = useParams<{ eventId: string }>();
  const { data, inProgress: isLoadingAnswers } =
    useAssessmentResponses(eventId);
  const answers = data || [];

  if (isLoadingAnswers || isLoadingEvent) {
    return <LoadingSpinner />;
  }

  if (!assessment || !event) {
    return (
      <EmptyList icon="assessments" message="The assessment does not exist" />
    );
  }

  const { groups } = assessment;
  const maxRound = Math.max(...groups.map((g) => g.round));
  const rounds = [...Array(maxRound).keys()];

  const remainingToBeMarked = answersRemainingToBeMarked(
    assessment,
    answers,
    currentRound + 1,
  ).length;
  const questionsForRound = getQuestionsForRound(groups, currentRound + 1);

  return (
    <EventAssessmentContainer>
      <VerticalGroup spaceBetweenElements={8}>
        <InlineGroup
          className={"h-16 mt-2 assessment-ribbon"}
          spaceBetweenElements={2}
          block
        >
          {rounds.map((round) => {
            const group = groups.find((g) => g.round - 1 === round);
            return (
              <>
                <Ribbon
                  key={round}
                  first={round === currentRound}
                  last={round === maxRound}
                  active={round === currentRound}
                  title={`Round ${round}`}
                  description={`${group ? group.name : ""}`}
                  onClick={() => setRound(round)}
                />
              </>
            );
          })}
        </InlineGroup>
        {questionsForRound.length === 0 && (
          <VerticalGroup className="pt-16" full center verticalCenter wide>
            <EmptyList
              icon="assessments"
              message="There's no questions for this round"
            />
          </VerticalGroup>
        )}
        {questionsForRound.length > 0 && (
          <>
            <VerticalGroup wide spaceBetweenElements={10}>
              <div className="summary-container">
                <SummaryRow
                  title="Starts"
                  rate={`${amountOfParticipantStarted(answers, currentRound + 1)} / ${event.participantsCount}`}
                  percentage={
                    (amountOfParticipantStarted(answers, currentRound + 1) /
                      event.participantsCount) *
                    100
                  }
                  help={{
                    title: "Starts",
                    body: `Indicates ${amountOfParticipantStarted(answers, currentRound + 1)} of ${event.participantsCount} people have answered at least one question.`,
                  }}
                />
              </div>
              <div className="summary-container">
                <SummaryRow
                  title="Completion Rate"
                  rate={`${((completedParticipantsForRoundCount(assessment, answers, currentRound + 1) / event.participantsCount) * 100).toFixed(0)}%`}
                  percentage={
                    (completedParticipantsForRoundCount(
                      assessment,
                      answers,
                      currentRound + 1,
                    ) /
                      event.participantsCount) *
                    100
                  }
                  help={{
                    title: "Completion Rate",
                    body: "The percentage of people who have completed the assessment/survey and pressed “submit”.",
                  }}
                />
                <SummaryRow
                  title="Average score"
                  rate={`${calculateAvg(assessment, answers, currentRound + 1)}%`}
                  percentage={calculateAvg(
                    assessment,
                    answers,
                    currentRound + 1,
                  )}
                />

                <SummaryRow
                  title="Median"
                  rate={`${calculateMedian(assessment, answers, currentRound + 1)}%`}
                  percentage={calculateMedian(
                    assessment,
                    answers,
                    currentRound + 1,
                  )}
                />
              </div>

              <div className="summary-container">
                <SummaryRow
                  title="Highest score"
                  rate={`${calculateMax(assessment, answers, currentRound + 1)}%`}
                  percentage={calculateMax(
                    assessment,
                    answers,
                    currentRound + 1,
                  )}
                />

                <SummaryRow
                  title="Lowest score"
                  rate={`${calculateMin(assessment, answers, currentRound + 1)}%`}
                  percentage={calculateMin(
                    assessment,
                    answers,
                    currentRound + 1,
                  )}
                />
              </div>
            </VerticalGroup>
          </>
        )}
      </VerticalGroup>
      {questionsForRound.length > 0 && (
        <VerticalGroup
          spaceBetweenElements={2}
          className={classNames("info-box", {
            grey: remainingToBeMarked > 0,
            green: remainingToBeMarked === 0,
          })}
        >
          {remainingToBeMarked === 0 ? (
            <>
              <Text size="lg">Well done!</Text>
              <Text size="lg">All completed assignments have been marked</Text>
            </>
          ) : (
            <>
              <Text size="lg">Responses to mark</Text>
              <Text size="lg">
                {remainingToBeMarked} of student response
                {remainingToBeMarked > 1 ? "s" : ""} which need marking
              </Text>
            </>
          )}
        </VerticalGroup>
      )}
    </EventAssessmentContainer>
  );
};
