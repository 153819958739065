import React, { useMemo } from "react";
import Modal from "react-modal";
import "./Modal.scss";
import useIsMobileOrTablet from "../../../hooks/useIsMobileOrTablet";

const customStyles: Modal.Styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 6,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    border: "none",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
    border: "none",
    padding: 0,
  },
};

interface Props extends Modal.Props {
  children: React.ReactNode;
  overrideStyles?: Modal.Styles;
  width?: string;
}

function _Modal(props: Props) {
  const { children, overrideStyles, width } = props;
  const isMobileOrTablet = useIsMobileOrTablet();

  const styles = useMemo(() => {
    let contentStyles = {
      ...customStyles.content,
      width: width || "auto",
    };

    if (isMobileOrTablet) {
      contentStyles = {
        ...contentStyles,
        maxHeight: "100dvh",
      };
    }

    if (!overrideStyles) {
      return {
        ...customStyles,
        content: contentStyles,
      };
    }

    return {
      overlay: {
        ...customStyles.overlay,
        ...(overrideStyles.overlay ? overrideStyles.overlay : {}),
      },
      content: {
        ...contentStyles,
        ...(overrideStyles.content ? overrideStyles.content : {}),
      },
    };
  }, [overrideStyles, width, isMobileOrTablet]);

  return (
    <Modal style={styles} {...props}>
      {children}
    </Modal>
  );
}

export default _Modal;
