import React from "react";
import Icon, { IconType } from "../../atoms/icon/Icon";
import Text from "../../atoms/text/Text";
import Clickable from "../../atoms/clickable/Clickable";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import "./SquareIconButton.scss";
import classNames from "classnames";
import { ThemeColours } from "../../../types/theme";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";

interface Props {
  className?: string;
  disabled?: boolean;
  icon: IconType;
  label?: string;
  colour?: ThemeColours;
  bgColour?: ThemeColours;
  inProgress?: boolean;
  onClick?: () => void;
  completed?: boolean;
  noBorder?: boolean;
  curved?: boolean;
}

const SquareIconButton: React.FC<Props> = ({
  className,
  disabled,
  icon,
  label,
  colour = "blue",
  bgColour,
  inProgress,
  noBorder,
  onClick,
  completed,
  curved,
}) => {
  const colourClass = colour ? `colour-${colour}` : null;
  const bgColourClass = disabled
    ? "bg-colour-primaryDark3"
    : bgColour
      ? `bg-colour-${bgColour}`
      : null;
  return (
    <Clickable disabled={disabled} onClick={onClick}>
      <InlineGroup
        className={classNames("p-4", colourClass, bgColourClass, className, {
          "square-icon-button": !disabled,
        })}
        border={
          noBorder
            ? undefined
            : {
                style: "solid",
                color: colour,
                width: "0.1rem",
                rounded: "6px",
              }
        }
        style={{ position: "relative" }}
        curved={curved}
      >
        <VerticalGroup wide center>
          {completed != null && !disabled && (
            <Icon
              noMargin
              type="status"
              className="mr-2"
              colour={completed ? "lightgreen" : "grey1"}
              style={{ position: "absolute", top: 5, right: 5 }}
            />
          )}
          {inProgress && <LoadingSpinner size={20} />}
          {!inProgress && (
            <Icon colour={colour} size={10} type={icon} className="icon" />
          )}

          {label && (
            <Text colour={colour} center>
              {label}
            </Text>
          )}
        </VerticalGroup>
      </InlineGroup>
    </Clickable>
  );
};

export default SquareIconButton;
