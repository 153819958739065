import React, { useCallback, useMemo, useState, useEffect } from "react";
import ParticipantsTable, {
  FlexibleColumn,
} from "../events/event/steps/participants/ParticipantsTable";
import { CellProps, SortingRule } from "react-table";
import API from "../../../services/api";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import Checkbox from "../../atoms/form/input/Checkbox";
import DeleteParticipant from "../events/event/steps/participants/DeleteParticipant";
import { ParticipantState } from "../events/event/steps/participants/ParticipantsSection";
import EditParticipantModal from "../events/event/steps/participants/EditParticipantModal";
import ErrorModal from "../../organisms/standard-modal/ErrorModal";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import AddParticipantModal from "../events/event/steps/participants/AddParticipantModal";
import IconButton from "../../molecules/iconbutton/IconButton";
import SortParticipantModal from "./SortParticipantModal";
import Clickable from "../../atoms/clickable/Clickable";
import Icon from "../../atoms/icon/Icon";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import SimpleModal from "../../organisms/standard-modal/SimpleModal";
import { formatISOStringWithDateFormat } from "../../../lib/date";
import Text from "../../atoms/text/Text";
import useIsMobileOrTablet from "../../../hooks/useIsMobileOrTablet";
import useIsMobile from "../../../hooks/useIsMobile";

interface ParticipantsCheckListProps {
  event: API.EventResponse;
  fetchEvent: () => void;
}

const STICKY_BAR_HEIGHT = 1;

const ParticipantsCheckList: React.FC<ParticipantsCheckListProps> = ({
  event,
  fetchEvent,
}) => {
  const [
    {
      deleteParticipantModalOpen,
      participantToDelete,
      editParticipantModalOpen,
      selectedParticipant,
      addParticipantModalOpen,
    },
    setParticipantModalState,
  ] = useState<ParticipantState>({
    deleteParticipantModalOpen: false,
    participantToDelete: null,
    addParticipantModalOpen: false,
    editParticipantModalOpen: false,
    selectedParticipant: null,
  });
  const [selected, setSelected] = useState<API.ParticipantResponse[]>([]);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showUncheckedOnly, setShowUncheckedOnly] = useState(false);
  const [showSortModal, setShowSortModal] = useState(false);
  const [selectedSort, setSelectedSort] = useState<string>("firstName");
  const isMobile = useIsMobile(460);
  const [showEmailSentModal, setShowEmailSentModal] = useState<string | null>(
    null,
  );
  const [loadingAttendance, setLoadingAttendance] = useState<{
    [key: string]: boolean;
  }>({});
  const isMobileOrTablet = useIsMobileOrTablet();
  const handleWelcomeEmailSent = useCallback(() => {
    fetchEvent();
  }, [fetchEvent]);
  const handleSortChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedSort(e.target.value);
    },
    [],
  );

  const handleSortSave = useCallback(() => {
    setSortBy([{ id: selectedSort, desc: false }]);
    setShowSortModal(false);
  }, [selectedSort]);
  const toggleShowUnchecked = useCallback(() => {
    setShowUncheckedOnly((prev) => !prev);
  }, []);

  const [sortBy, setSortBy] = useState<SortingRule<API.ParticipantResponse>[]>([
    { id: "firstName", desc: false },
  ]);

  const mappedParticipants = useMemo(() => {
    return selected.map((p) => ({
      ...p,
      hidden: p.attendanceChecked && showUncheckedOnly,
    }));
  }, [selected, showUncheckedOnly]);
  useEffect(() => {
    setSelected(event.participants);
  }, [event.participants]);

  const handleEditParticipant = useCallback(
    (participant: API.ParticipantResponse) => {
      setParticipantModalState((prevState) => ({
        ...prevState,
        editParticipantModalOpen: true,
        selectedParticipant: participant,
      }));
    },
    [],
  );
  const isSortableField = (
    key: string,
  ): key is "firstName" | "lastName" | "email" | "team" => {
    return (
      key === "firstName" ||
      key === "lastName" ||
      key === "email" ||
      key === "team"
    );
  };

  const sortedParticipants = useMemo(() => {
    const sortField = sortBy[0]?.id || "firstName";
    if (!isSortableField(sortField)) {
      return mappedParticipants;
    }
    return [...mappedParticipants].sort((a, b) => {
      if (sortField === "team") {
        return Number(a[sortField]) - Number(b[sortField]);
      }
      return a[sortField].localeCompare(b[sortField], undefined, {
        sensitivity: "base",
      });
    });
  }, [mappedParticipants, sortBy]);
  const handleSelectedRowsChange = useCallback(
    (selectedRows: API.ParticipantResponse[]) => {
      setSelected(selectedRows);
    },
    [],
  );

  const handleShowError = useCallback((errorMsg: string) => {
    setErrorMsg(errorMsg);
    setShowError(true);
  }, []);

  const handleAttendanceChange = useCallback(
    async (participant: API.ParticipantResponse, checked: boolean) => {
      setLoadingAttendance((prev) => ({ ...prev, [participant.id]: true }));
      const startTime = Date.now();

      setSelected((prevParticipants) =>
        prevParticipants.map((p) =>
          p.id === participant.id ? { ...p, attendanceChecked: checked } : p,
        ),
      );

      const updatePayload = {
        email: participant.email,
        firstName: participant.firstName,
        lastName: participant.lastName,
        team: participant.team,
        isCeo: participant.isCeo,
        attendanceChecked: checked,
      };

      await API.updateParticipant(event.id, participant.id, updatePayload);
      fetchEvent();

      const endTime = Date.now();
      const elapsedTime = endTime - startTime;
      const minimumSpinnerDuration = 2000;

      const remainingTime = Math.max(0, minimumSpinnerDuration - elapsedTime);

      setTimeout(() => {
        setLoadingAttendance((prev) => ({ ...prev, [participant.id]: false }));
      }, remainingTime);
    },
    [event.id, fetchEvent, setSelected],
  );
  const handleDeleteParticipant = useCallback(
    (participant: API.ParticipantResponse) => {
      setParticipantModalState((prevState) => ({
        ...prevState,
        deleteParticipantModalOpen: true,
        participantToDelete: participant,
      }));
    },
    [],
  );
  const deleteParticipant = useCallback(
    (participant: API.ParticipantResponse) =>
      setParticipantModalState((prevState) => ({
        ...prevState,
        deleteParticipantModalOpen: true,
        participantToDelete: participant,
      })),
    [],
  );
  const navigateParticipant = useCallback(
    (step: number) => {
      if (!selectedParticipant) return;

      const currentIndex = event.participants.findIndex(
        (p: API.ParticipantResponse) => p.id === selectedParticipant.id,
      );
      let nextIndex = currentIndex + step;
      if (nextIndex < 0) {
        nextIndex = event.participantsCount - 1;
      } else if (nextIndex >= event.participantsCount) {
        nextIndex = 0;
      }
      setParticipantModalState((prevState) => ({
        ...prevState,
        editParticipantModalOpen: true,
        selectedParticipant: event.participants[nextIndex],
      }));
    },
    [event.participants, event.participantsCount, selectedParticipant],
  );

  const onParticipantDeleteComplete = useCallback(() => {
    setParticipantModalState((prevState) => ({
      ...prevState,
      deleteParticipantModalOpen: false,
      participantToDelete: null,
    }));
    fetchEvent();
  }, [fetchEvent]);

  const handleAddParticipant = useCallback(() => {
    setParticipantModalState((prevState) => ({
      ...prevState,
      addParticipantModalOpen: true,
    }));
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowEmailSentModal(null);
  }, []);

  const caseInsensitiveSort = (rowA: any, rowB: any, columnId: any) => {
    const a = String(rowA.values[columnId]).toLowerCase();
    const b = String(rowB.values[columnId]).toLowerCase();
    return a.localeCompare(b);
  };
  const columns: FlexibleColumn<API.ParticipantResponse>[] = useMemo(
    () => [
      {
        Header: "Team",
        accessor: "team",
      },

      {
        Header: "Participant",
        accessor: (row) => `${row.firstName} ${row.lastName}`,
        sortType: caseInsensitiveSort,
        Cell: ({ row }: { row: { original: API.ParticipantResponse } }) => (
          <div>
            <InlineGroup verticalCenter spaceBetweenElements={2}>
              <div>{`${row.original.firstName} ${row.original.lastName}`}</div>
              {row.original.isCeo && (
                <InlineGroup
                  className="p-1"
                  border={{
                    style: "solid",
                    color: "primaryDark3",
                    width: "1px",
                    rounded: "4px",
                  }}
                >
                  <Text size="sm" bold>
                    CEO
                  </Text>
                </InlineGroup>
              )}
            </InlineGroup>
            <div style={{ fontSize: "0.8em", color: "#666" }}>
              {row.original.email}
            </div>
          </div>
        ),
        show: isMobileOrTablet,
      },
      {
        Header: "First Name",
        accessor: "firstName",
        sortType: caseInsensitiveSort,
        show: !isMobileOrTablet,
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        sortType: caseInsensitiveSort,
        show: !isMobileOrTablet,
      },
      {
        Header: "Email",
        accessor: "email",
        sortType: caseInsensitiveSort,
        show: !isMobileOrTablet,
      },
      {
        Header: "",
        id: "welcomeEmailStatus",
        accessor: (row) => {
          if (row.welcomeEmailSent) {
            return (
              <div
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <Clickable
                  onClick={(event) => {
                    event.stopPropagation();
                    setShowEmailSentModal(row.id);
                  }}
                >
                  <Icon colour="green" size={8} type="emailSent" />
                </Clickable>
                <SimpleModal
                  showMobileCloseButton
                  isOpen={showEmailSentModal === row.id}
                  onClose={handleCloseModal}
                  size="small"
                  showCloseButton
                >
                  <VerticalGroup spaceBetweenElements={2}>
                    <Text color="green" size="2xl" bold>
                      Email Sent
                    </Text>
                    <Text>
                      {row.firstName} {row.lastName} was sent their last welcome
                      email with their login details at:{" "}
                    </Text>
                    <Text bold center size="lg">
                      {row.welcomeEmailSentAt
                        ? formatISOStringWithDateFormat(row.welcomeEmailSentAt)
                        : "Date not available"}
                    </Text>
                  </VerticalGroup>
                </SimpleModal>
              </div>
            );
          } else if (!row.welcomeEmailSent && row.welcomeEmailError) {
            return (
              <Clickable
                onClick={(event) => {
                  event.stopPropagation();
                  handleShowError(row.welcomeEmailError ?? "");
                }}
              >
                <Icon colour="danger" size={10} type="emailError" />
              </Clickable>
            );
          } else {
            return <Icon style={{ opacity: 0.2 }} size={8} type="emailSent" />;
          }
        },
        Cell: ({ value }: CellProps<API.ParticipantResponse, any>) => {
          return <>{value}</>; // Ensure it returns JSX (ReactElement)
        },
      },
      {
        Header: isMobile ? "Attend" : "Attendance",
        accessor: "attendanceChecked",
        sortType: "basic",
        Cell: ({
          value,
          row,
        }: {
          value: any;
          row: { original: API.ParticipantResponse };
        }) => (
          <InlineGroup center>
            {loadingAttendance[row.original.id] ? (
              <LoadingSpinner size={20} />
            ) : (
              <Checkbox
                inputClassName="survey-monkey-checkbox"
                name={`attendance-${row.original.id}`}
                label=""
                checked={value}
                onChange={(e) =>
                  handleAttendanceChange(row.original, e.target.checked)
                }
                onClick={(e) => e.stopPropagation()}
              />
            )}
          </InlineGroup>
        ),
      },
    ],
    [
      handleAttendanceChange,
      isMobileOrTablet,
      handleShowError,
      loadingAttendance,
      showEmailSentModal,
      handleCloseModal,
      isMobile,
    ],
  );

  if (!event) {
    return null;
  }

  return (
    <InlineGroup block>
      <ErrorModal
        isOpen={showError}
        onClose={() => setShowError(false)}
        title="Email Error"
        description={errorMsg}
      />
      <DeleteParticipant
        isOpen={deleteParticipantModalOpen}
        participants={participantToDelete ? [participantToDelete] : selected}
        eventId={event.id}
        onClose={() =>
          setParticipantModalState((prevState) => ({
            ...prevState,
            deleteParticipantModalOpen: false,
            participantToDelete: null,
          }))
        }
        onComplete={onParticipantDeleteComplete}
      />
      {selectedParticipant && (
        <EditParticipantModal
          isOpen={editParticipantModalOpen}
          onClose={() =>
            setParticipantModalState((prevState) => ({
              ...prevState,
              editParticipantModalOpen: false,
              selectedParticipant: null,
            }))
          }
          onComplete={(dismissPopup: boolean) => {
            setParticipantModalState((prevState) => ({
              ...prevState,
              editParticipantModalOpen: !dismissPopup,
              selectedParticipant: null,
            }));
            fetchEvent();
          }}
          event={event}
          participant={selectedParticipant}
          onNextParticipant={() => navigateParticipant(1)}
          onPrevParticipant={() => navigateParticipant(-1)}
          deleteParticipant={deleteParticipant}
          onWelcomeEmailSent={handleWelcomeEmailSent}
        />
      )}
      {addParticipantModalOpen && (
        <AddParticipantModal
          isOpen={addParticipantModalOpen}
          onClose={() =>
            setParticipantModalState((prevState) => ({
              ...prevState,
              addParticipantModalOpen: false,
            }))
          }
          onComplete={() => {
            setParticipantModalState((prevState) => ({
              ...prevState,
              addParticipantModalOpen: false,
            }));
            fetchEvent();
          }}
          event={event}
        />
      )}
      <VerticalGroup wide>
        <InlineGroup spread block right>
          {isMobileOrTablet && (
            <IconButton
              onClick={() => setShowSortModal(true)}
              text={"Sort"}
              icon={"sort"}
            ></IconButton>
          )}
          <IconButton
            onClick={toggleShowUnchecked}
            text={showUncheckedOnly ? "Hiding Checked" : "Showing All"}
            icon={showUncheckedOnly ? "hidden" : "eye"}
          ></IconButton>
          <IconButton
            onClick={handleAddParticipant}
            text="Add Participant"
            icon={"addUser"}
          ></IconButton>
        </InlineGroup>
        <ParticipantsTable
          participants={sortedParticipants}
          event={event}
          onEditParticipant={handleEditParticipant}
          onDeleteParticipant={handleDeleteParticipant}
          onSelectedRowsChange={handleSelectedRowsChange}
          onShowError={handleShowError}
          stickyTop={STICKY_BAR_HEIGHT}
          columns={columns}
          showCheckboxes={false}
          defaultSortBy={sortBy}
        />
      </VerticalGroup>
      <SortParticipantModal
        isOpen={showSortModal}
        onClose={() => setShowSortModal(false)}
        selectedSort={selectedSort}
        onSortChange={handleSortChange}
        onSortSave={handleSortSave}
      />
    </InlineGroup>
  );
};

export default ParticipantsCheckList;
