import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  createContext,
  useContext,
} from "react";
import classNames from "classnames";
import { SketchPicker } from "react-color";
import { useLayer } from "react-laag";

import Card from "../../atoms/card/Card";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import Clickable from "../../atoms/clickable/Clickable";

import "./BrandingColorSection.scss";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import Text from "../../atoms/text/Text";
import HexInput from "../../atoms/form/input/HexInput";
import { Noop } from "../../../constants/functions";

interface IPopupData {
  currentPicker: null | string;
  setCurrentPicker: (popupId: string | null) => void;
}
const Context = createContext<IPopupData>({
  currentPicker: null,
  setCurrentPicker: Noop,
});

interface IColourPopupContainer {}

export const ColourPopupContainer: React.FC<IColourPopupContainer> = ({
  children,
}) => {
  const [currentPicker, setCurrentPicker] = useState<string | null>(null);

  const contextData = useMemo(
    () => ({
      currentPicker,
      setCurrentPicker,
    }),
    [currentPicker],
  );

  return <Context.Provider value={contextData}>{children}</Context.Provider>;
};

const useCurrentColourPicker = () => {
  return useContext(Context);
};

interface ColorButtonProps {
  id: string;
  color: string;
  readOnly?: boolean;
  onChange: (colour: string) => void;
  "data-test"?: string;
}

export const ColourButton: React.FC<ColorButtonProps> = ({
  id,
  color,
  readOnly = false,
  onChange,
  ...props
}) => {
  const { currentPicker, setCurrentPicker } = useCurrentColourPicker();
  const isOpen = currentPicker === id;
  const { triggerProps, renderLayer, layerProps } = useLayer({
    isOpen,
    placement: "bottom-end",
    auto: true,
    snap: true,
  });

  const close = useCallback(() => {
    setCurrentPicker(null);
  }, [setCurrentPicker]);

  const stopPropagation = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }, []);

  useEffect(() => {
    window.addEventListener("click", close, false);
    return () => {
      window.removeEventListener("click", close);
    };
  }, [close]);

  return (
    <div onClick={stopPropagation}>
      <Clickable
        className={classNames("colorButton")}
        style={{ backgroundColor: color }}
        onClick={() => {
          !readOnly && setCurrentPicker(id);
        }}
        disabled={readOnly}
        {...triggerProps}
        {...props}
      />
      {isOpen &&
        renderLayer(
          <div {...layerProps}>
            <SketchPicker
              color={color}
              disableAlpha
              onChange={({ hex }) => onChange(hex)}
              presetColors={[
                "#112540",
                "#405066",
                "#27a4f2",
                "#feb729",
                "#1d252c",
                "#ffffff",
                "#991AD6",
                "#FFD9F8",
                "#F3F4F6",
                "#DA1710",
                "#000000",
                "#B8E986",
                "#D8D9E9",
                "#FF3DDB",
                "#7ED321",
                "#005D92",
              ]}
            />
          </div>,
        )}
    </div>
  );
};

interface ColorRowProps {
  id: string;
  title: string;
  color: string;
  readOnly?: boolean;
  onChange?: (colour: string) => void;
}

export const ColourRow: React.FC<ColorRowProps> = ({
  id,
  title,
  color,
  onChange = Noop,
  readOnly = false,
}) => (
  <InlineGroup verticalCenter spread block spaceBetweenElements={10}>
    <Text size="sm">{title}</Text>
    <InlineGroup verticalCenter>
      <ColourButton
        id={id}
        data-test={`button-${id}`}
        color={color}
        onChange={onChange}
        readOnly={readOnly}
      />
      <HexInput
        className="ml-2"
        type="text"
        color={color}
        onChange={onChange}
        readOnly={readOnly}
        small
        data-test={`input-${id}`}
      />
    </InlineGroup>
  </InlineGroup>
);

interface FillColoursProps {
  title: string;
}

export const ColorSection: React.FC<FillColoursProps> = ({
  title,
  children,
}) => (
  <Card flex>
    <Text size="lg" className="mb-2" bold>
      {title}
    </Text>
    <VerticalGroup spaceBetweenElements={2}>{children}</VerticalGroup>
  </Card>
);
