import { useState, useEffect } from "react";

const useIsHighResolution = (): boolean => {
  const [isHighResolution, setIsHighResolution] = useState(false);

  useEffect(() => {
    const checkIsHighResolution = () => {
      const highResolutionWidth = 3740;
      const highResolutionHeight = 2060;
      setIsHighResolution(
        window.innerWidth >= highResolutionWidth &&
          window.innerHeight >= highResolutionHeight,
      );
    };

    checkIsHighResolution();
    window.addEventListener("resize", checkIsHighResolution);

    return () => {
      window.removeEventListener("resize", checkIsHighResolution);
    };
  }, []);

  return isHighResolution;
};

export default useIsHighResolution;
