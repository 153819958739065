import React from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";
import { percentage } from "../utils/formatters";
import pluckResultsByType from "../utils/pluckResultsByType";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["revenue"];
}

const fieldFormatterMap: { [field: string]: string } = {
  "HOME_LOANS.interest": "Home Loans (Interest)",
  "HOME_LOANS.obi": "Home Loans (OBI)",
  "BUSINESS_LOANS.interest": "Business Lending (Interest)",
  "BUSINESS_LOANS.coalMineInterest": "Business Lending (Coal Mine Interest)",
  "BUSINESS_LOANS.obi": "Business Lending (OBI)",
  "DEPOSITS.interest": "Deposits (Interest)",
  "DEPOSITS.obi": "Deposits (OBI)",
  "CREDIT_CARDS.interest": "Credit Cards (Interest)",
  "CREDIT_CARDS.cardFees": "Credit Cards (Card Fees)",
  "CREDIT_CARDS.interchange": "Credit Cards (Interchange)",
  "CREDIT_CARDS.rewardCosts": "Credit Cards (Reward Costs)",
  "WEALTH_MANAGEMENT.fees": "Wealth Management (Fees)",
  "INSTITUTIONAL_BANKING.interest": "Institutional Banking (Interest)",
  "INSTITUTIONAL_BANKING.obi": "Institutional Banking (OBI)",
  "OFFSHORE_BANK.interestExpense": "Offshore Bank (Interest Expense)",
  "OFFSHORE_BANK.interestIncome": "Offshore Bank (Interest Income)",
};

const ModelTeamResultsRevenue: React.FC<Props> = ({ data }) => {
  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <TableWithSections
          header="Revenue Calculations"
          data={[
            {
              name: "Extra Revenue",
              fields: [
                "HOME_LOANS",
                "BUSINESS_LOANS",
                "DEPOSITS",
                "CREDIT_CARDS",
                "WEALTH_MANAGEMENT",
                "INSTITUTIONAL_BANKING",
                "OFFSHORE_BANK",
              ],
              values: pluckResultsByType(data, "extraRevenue"),
            },
            {
              name: "Credit Cards Interest Rate",
              fields: ["effectiveCreditCardInterestRate"],
              values: pluckResultsByType(data, "creditCardsInterestRate"),
              formatter: (val: number) => percentage(val, 2),
            },
            {
              name: "Business Lending OBI",
              fields: ["effectiveBusinessLoansOBI"],
              values: pluckResultsByType(data, "businessLendingOBI"),
            },
            {
              name: "Primary Revenue / Expense",
              fields: [
                "HOME_LOANS.interest",
                "HOME_LOANS.obi",
                "BUSINESS_LOANS.interest",
                "BUSINESS_LOANS.coalMineInterest",
                "BUSINESS_LOANS.obi",
                "DEPOSITS.interest",
                "DEPOSITS.obi",
                "CREDIT_CARDS.interest",
                "CREDIT_CARDS.cardFees",
                "CREDIT_CARDS.interchange",
                "CREDIT_CARDS.rewardCosts",
                "WEALTH_MANAGEMENT.fees",
                "INSTITUTIONAL_BANKING.interest",
                "INSTITUTIONAL_BANKING.obi",
                "OFFSHORE_BANK.interestExpense",
                "OFFSHORE_BANK.interestIncome",
              ],
              values: pluckResultsByType(data, "primaryRevenueExpense"),
            },
          ]}
          fieldFormatter={(field: string) => fieldFormatterMap[field] ?? null}
        />
      </VerticalGroup>
    </>
  );
};

export default ModelTeamResultsRevenue;
