import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

import Theme from "../../../styles/_theme.module.scss";
import "./LoadingSpinner.scss";

interface Props {
  text?: string;
  size?: number | string;
  height?: number | string;
  width?: number | string;
}

function LoadingSpinner(props: Props) {
  return (
    <div className="spinner-container">
      <ClipLoader size={props.size} color={Theme.secondaryBlue1} />
      {props.text && <div className="mt-1">{props.text}</div>}
    </div>
  );
}

export default LoadingSpinner;
