import React from "react";
import Image from "../../../atoms/image/Image";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import ResultsTable from "../table/ResultsTable";
import Trophy from "../../../../assets/results/award.gif";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import { TeamsWithColours } from "../RoundResultsPage";
import DynamicResultsText from "../results-text/DynamicResultsText";
import EditableReportingHeading from "../components/EditableReportingHeading";
import "./RoundResultsTeamSummary.scss";

interface Props {
  data: TeamsWithColours[];
}

const RoundResultsTeamSummary: React.FC<Props> = ({ data }) => {
  const teamPairs = data
    .filter((t) => !t.isCpuTeam)
    .reduce<Array<Array<TeamsWithColours>>>(function (
      result,
      value,
      index,
      array,
    ) {
      if (index % 2 === 0) result.push(array.slice(index, index + 2));
      return result;
    }, []);
  return (
    <RoundResultsContainer>
      <VerticalGroup full center wide spaceBetweenElements={2}>
        <EditableReportingHeading defaultHeading="Our Teams" />
        <InlineGroup fullHeight>
          <InlineGroup
            spaceBetweenElements={2}
            fullHeight
            block
            className="teams-container"
          >
            <InlineGroup verticalCenter center className="width-25-percent">
              <Image
                src={Trophy}
                alt="Trophy"
                dynamicSize={{ min: "10px", preferred: "20vw", max: "900px" }}
              />
            </InlineGroup>

            <ResultsTable>
              <tbody>
                {teamPairs.map((pair, i) => (
                  <tr key={i}>
                    {pair.map((team, index) => (
                      <td key={index} className="teams-table-cell">
                        <div
                          className="teams-rank-container"
                          style={{ backgroundColor: team.colour }}
                        >
                          <DynamicResultsText colour="white" size="xl">
                            #{i * 2 + index + 1}
                          </DynamicResultsText>
                        </div>
                        <div className="teams-name-container">
                          <DynamicResultsText size="2xl" color={team.colour}>
                            {team.teamName}
                          </DynamicResultsText>
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </ResultsTable>
          </InlineGroup>
        </InlineGroup>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsTeamSummary;
