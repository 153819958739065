import React, { useCallback, useEffect, useMemo } from "react";
import Button from "../../atoms/button/Button";
import Input from "../../atoms/form/input/Input";
import useForm from "../../../hooks/useForm";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import DisplayField from "../../atoms/form/display-field/DisplayField";
import Form, { FormRequiredText } from "../../atoms/form/Form";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import Banner from "../../atoms/banner/Banner";
import ClientDropdown from "../../organisms/client-dropdown/ClientDropdown";
import CancelButton from "../../atoms/form/actions/CancelButton";
import Toggle from "../../atoms/toggle/Toggle";
import { TemplateRequest, TemplateResponse } from "./types";

interface Props {
  template?: TemplateResponse;
  onSave: (data: TemplateRequest) => Promise<TemplateResponse>;
  onCancel: () => void;
  onComplete: (data: TemplateResponse) => void;
  mode: "edit" | "create";
}

function ReportingTemplateForm({
  template,
  onSave,
  onCancel,
  onComplete,
}: Props) {
  const [
    { formData, inProgress, fieldErrors, error, data, completed },
    { setField, setMultiSelectField, handleSubmit, setFieldSimple },
  ] = useForm(
    template
      ? {
          ...template,
        }
      : {
          clientIds: [],
          name: "",
          isModalOpen: false,
          availableToAllClients: true,
        },
  );

  const { clientIds, name, availableToAllClients } = formData;

  const callback = useCallback(() => {
    const payload = {
      name: name.trim(),
      clientIds: availableToAllClients ? [] : clientIds,
      availableToAllClients,
    };
    return onSave(payload);
  }, [availableToAllClients, clientIds, name, onSave]);

  const validate = useCallback(() => {
    const errors = [];
    if (!name) {
      errors.push({ field: "name", message: "Enter a name" });
    }
    if (!clientIds.length && !availableToAllClients) {
      errors.push({ field: "clientIds", message: "Select a client" });
    }
    return errors;
  }, [availableToAllClients, clientIds, name]);

  const onSubmit = useMemo(() => {
    return handleSubmit(callback, validate);
  }, [callback, handleSubmit, validate]);

  useEffect(() => {
    if (!!completed && !error) {
      onComplete(data);
    }
  }, [completed, error, onComplete, data]);

  return (
    <VerticalGroup full spread>
      <VerticalGroup wide>
        <Banner type="error" active={!!error} message={error?.message} />
        <InlineGroup evenWidthChildren block spaceBetweenElements={10}>
          <Form id="template-add-form" onSubmit={onSubmit}>
            <FormRequiredText />
            <Input
              data-test="name"
              type="text"
              label="Template Name *"
              value={name}
              onChange={setField("name")}
              error={fieldErrors.name}
            />
            <InlineGroup verticalCenter>
              <Toggle
                label="Available to all clients"
                helpTitle="Available to all clients"
                helpDescription=""
                checked={!!availableToAllClients}
                onUpdate={setFieldSimple("availableToAllClients")}
                block
              />
            </InlineGroup>
            {!availableToAllClients && (
              <ClientDropdown
                label="Select Clients *"
                isMulti
                clientIds={clientIds}
                onChange={setMultiSelectField("clientIds")}
                error={fieldErrors.clientIds}
              />
            )}
            {!!template && (
              <DisplayField label="Template ID" value={template.id} />
            )}
          </Form>
        </InlineGroup>
      </VerticalGroup>
      <InlineGroup block spread className="mt-4">
        <CancelButton
          confirmModalProps={{
            confirmButtonText: "Save",
            inProgress: formData.inProgress,
            showModal: formData.formUpdated,
            onConfirm: onSubmit,
            onDiscard: onCancel,
          }}
          onClick={onCancel}
        />
        <Button
          data-test="save"
          type="submit"
          form="template-add-form"
          wide
          inProgress={inProgress}
        >
          Save
        </Button>
      </InlineGroup>
    </VerticalGroup>
  );
}

export default ReportingTemplateForm;
