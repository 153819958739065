import React from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections, { SectionData } from "../tables/TableWithSections";
import { startCase } from "../../../../services/utils";
import {
  formatLendingCriteria,
  formatLossMultiplier,
  formatValue,
  percentage,
  round,
} from "../utils/formatters";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["productBalances"];
}

interface ProductTypes {
  name: string;
  types: Array<{
    header?: string;
    type?: string;
    fields?: string[];
    totalSectionFields?: string[];
    fieldFormatter?: (field: string) => string | null;
    formatter?: (val: number, field: string) => string | null;
    sections?: Array<{
      name?: string;
      isTotalSection?: boolean;
      fields: string[];
      totalSectionFields?: string[];
      formatter?: (val: number, field: string) => string | null;
      fieldFormatter?: (field: string) => string | null;
    }>;
  }>;
}

const lieFormatter = (val: number, field: string) => {
  if (field === "lie.lossMultiplier" || field === "lossMultiplier") {
    return formatLossMultiplier(val);
  }
  if (field === "lie.lieRates" || field === "lieRates") {
    return percentage(val);
  }
  return null;
};

const lieSection = {
  name: "Loan Impairment Expense",
  fields: ["lie.lossMultiplier", "lie.lieRates", "lie.lieLendingLosses"],
  formatter: lieFormatter,
};

const projectImpactsBenefitLIEReductionSection = {
  name: "Project Impacts (Benefit LIE Reduction)",
  fields: [
    "projectImpactsBenefitLIEReduction.project-offshore",
    "projectImpactsBenefitLIEReduction.project-right-size",
    "projectImpactsBenefitLIEReduction.project-harvest",
    "projectImpactsBenefitLIEReduction.project-mcfinsey",
    "projectImpactsBenefitLIEReduction.project-switch",
    "projectImpactsBenefitLIEReduction.project-lightspeed",
    "projectImpactsBenefitLIEReduction.project-digital",
    "projectImpactsBenefitLIEReduction.project-genius",
    "projectImpactsBenefitLIEReduction.project-nitro",
    "projectImpactsBenefitLIEReduction.project-big-data",
    "projectImpactsBenefitLIEReduction.project-care-bear",
    "projectImpactsBenefitLIEReduction.project-tighten",
    "projectImpactsBenefitLIEReduction.project-fix-it",
    "projectImpactsBenefitLIEReduction.project-roadshow",
    "projectImpactsBenefitLIEReduction.project-sustain",
    "projectImpactsBenefitLIEReduction.project-shield",
    "projectImpactsBenefitLIEReduction.project-simple",
    "projectImpactsBenefitLIEReduction.project-fusion",
    "projectImpactsBenefitLIEReduction.total",
  ],
  totalSectionFields: ["projectImpactsBenefitLIEReduction.total"],
  formatter: (val: number) => percentage(val),
};

const projectImpactsCumulativeImpactOnLossMultipliersSection = {
  name: "Project Impacts (Cumulative Impact on Loss Multipliers)",
  fields: [
    "projectImpactsCumulativeImpactOnLossMultipliers.project-offshore",
    "projectImpactsCumulativeImpactOnLossMultipliers.project-right-size",
    "projectImpactsCumulativeImpactOnLossMultipliers.project-harvest",
    "projectImpactsCumulativeImpactOnLossMultipliers.project-mcfinsey",
    "projectImpactsCumulativeImpactOnLossMultipliers.project-switch",
    "projectImpactsCumulativeImpactOnLossMultipliers.project-lightspeed",
    "projectImpactsCumulativeImpactOnLossMultipliers.project-digital",
    "projectImpactsCumulativeImpactOnLossMultipliers.project-genius",
    "projectImpactsCumulativeImpactOnLossMultipliers.project-nitro",
    "projectImpactsCumulativeImpactOnLossMultipliers.project-big-data",
    "projectImpactsCumulativeImpactOnLossMultipliers.project-care-bear",
    "projectImpactsCumulativeImpactOnLossMultipliers.project-tighten",
    "projectImpactsCumulativeImpactOnLossMultipliers.project-fix-it",
    "projectImpactsCumulativeImpactOnLossMultipliers.project-roadshow",
    "projectImpactsCumulativeImpactOnLossMultipliers.project-sustain",
    "projectImpactsCumulativeImpactOnLossMultipliers.project-shield",
    "projectImpactsCumulativeImpactOnLossMultipliers.project-simple",
    "projectImpactsCumulativeImpactOnLossMultipliers.project-fusion",
    "projectImpactsCumulativeImpactOnLossMultipliers.total",
  ],
  totalSectionFields: ["projectImpactsCumulativeImpactOnLossMultipliers.total"],
  formatter: (val: number) => percentage(val),
};

const fields = ["openingBalance", "annualGrowth", "attrition"];
const totalSectionFields = ["closingBalance", "averageBalance"];
const types: ProductTypes[] = [
  {
    name: "HOME_LOANS",
    types: [
      {
        type: "propietaryHomeLoans",
        sections: [
          {
            fields: fields.concat("lieAndNetWriteOffs").concat("loanBookSale"),
          },
          { isTotalSection: true, fields: totalSectionFields },
          projectImpactsBenefitLIEReductionSection,
          projectImpactsCumulativeImpactOnLossMultipliersSection,
          lieSection,
        ],
      },
      {
        type: "brokerHomeLoans",
        sections: [
          { fields: fields.concat("lieAndNetWriteOffs") },
          { isTotalSection: true, fields: totalSectionFields },
          projectImpactsBenefitLIEReductionSection,
          projectImpactsCumulativeImpactOnLossMultipliersSection,
          lieSection,
        ],
      },
      {
        type: "total",
        fields: fields.concat("lieAndNetWriteOffs").concat("loanBookSale"),
        totalSectionFields,
      },
      {
        type: "brokerSupportCostsAndCommissions",
        fields: ["averageBalance", "averageAnnualBrokerSupportCosts"],
        totalSectionFields: ["brokerSupportCosts", "brokerCommissionCosts"],
      },
      {
        type: "loanBookPremium",
        fields: ["openingBalance", "premiumAcquired", "amortisation"],
        totalSectionFields: ["closingBalance", "averageBalance"],
      },
    ],
  },
  {
    name: "BUSINESS_LOANS",
    types: [
      {
        type: "total",
        fields: [
          "openingBalance",
          "annualGrowth",
          "annualGrowthSecondary",
          "attrition",
          "lieAndNetWriteOffs",
        ],
        totalSectionFields,
        fieldFormatter: (field: string) => {
          if (field === "annualGrowthSecondary") {
            return "Coal Mine Loans Written";
          }
          return null;
        },
      },
      {
        header: "New Business Mix",
        sections: [
          {
            fields: ["lendingCriteria"],
            formatter: formatLendingCriteria,
          },
          {
            fields: [
              "newBusinessMix.goodQuality",
              "newBusinessMix.mediumQuality",
              "newBusinessMix.poorQuality",
            ],
            formatter: (val: number) => percentage(val, 0),
          },
        ],
      },
      {
        header: "Loan Book - Quality Mix",
        sections: [
          {
            name: "Good Quality",
            fields: [
              "loanBookQualityMix.goodQuality.openingBalance",
              "loanBookQualityMix.goodQuality.annualGrowth",
              "loanBookQualityMix.goodQuality.attrition",
              "loanBookQualityMix.goodQuality.lieAndNetWriteOffs",
              "loanBookQualityMix.goodQuality.closingBalance",
            ],
            totalSectionFields: [
              "loanBookQualityMix.goodQuality.closingBalance",
            ],
            formatter: (val: number, field: string) => {
              if (
                field === "loanBookQualityMix.goodQuality.lieAndNetWriteOffs"
              ) {
                return formatValue(-1 * val, 1, 1);
              }
              if (field === "loanBookQualityMix.goodQuality.attrition") {
                return formatValue(-1 * val);
              }
              return formatValue(val);
            },
            fieldFormatter: (field: string) =>
              field === "loanBookQualityMix.goodQuality.lieAndNetWriteOffs"
                ? "LIE / Gross Write Offs"
                : null,
          },
          {
            name: "Coal Mine Loan",
            fields: [
              "loanBookQualityMix.coalMinerLoan.openingBalance",
              "loanBookQualityMix.coalMinerLoan.closingBalance",
              "loanBookQualityMix.coalMinerLoan.averageBalance",
            ],
          },
          {
            name: "Medium Quality",
            fields: [
              "loanBookQualityMix.mediumQuality.openingBalance",
              "loanBookQualityMix.mediumQuality.annualGrowth",
              "loanBookQualityMix.mediumQuality.attrition",
              "loanBookQualityMix.mediumQuality.lieAndNetWriteOffs",
              "loanBookQualityMix.mediumQuality.closingBalance",
            ],
            totalSectionFields: [
              "loanBookQualityMix.mediumQuality.closingBalance",
            ],
            formatter: (val: number, field: string) => {
              if (
                field === "loanBookQualityMix.mediumQuality.lieAndNetWriteOffs"
              ) {
                return formatValue(-1 * val, 1, 1);
              }
              if (field === "loanBookQualityMix.mediumQuality.attrition") {
                return formatValue(-1 * val);
              }
              return formatValue(val);
            },
            fieldFormatter: (field: string) =>
              field === "loanBookQualityMix.mediumQuality.lieAndNetWriteOffs"
                ? "LIE / Gross Write Offs"
                : null,
          },
          {
            name: "Poor Quality",
            fields: [
              "loanBookQualityMix.poorQuality.openingBalance",
              "loanBookQualityMix.poorQuality.annualGrowth",
              "loanBookQualityMix.poorQuality.attrition",
              "loanBookQualityMix.poorQuality.lieAndNetWriteOffs",
              "loanBookQualityMix.poorQuality.excoDecisionImpacts",
              "loanBookQualityMix.poorQuality.closingBalance",
            ],
            totalSectionFields: [
              "loanBookQualityMix.poorQuality.closingBalance",
            ],
            formatter: (val: number, field: string) => {
              if (
                field === "loanBookQualityMix.poorQuality.lieAndNetWriteOffs"
              ) {
                return formatValue(-1 * val, 1, 1);
              }
              if (field === "loanBookQualityMix.poorQuality.attrition") {
                return formatValue(-1 * val);
              }
              return formatValue(val);
            },
            fieldFormatter: (field: string) =>
              field === "loanBookQualityMix.poorQuality.lieAndNetWriteOffs"
                ? "LIE / Gross Write Offs"
                : null,
          },
          {
            fields: ["loanBookQualityMix.variance"],
          },
        ],
      },
      {
        header: "Loan Impairment Expenses",
        type: "lie",
        sections: [
          {
            name: "Loss Multiplier",
            fields: [
              "lossMultiplier.goodQuality",
              "lossMultiplier.mediumQuality",
              "lossMultiplier.poorQuality",
            ],
            formatter: formatLossMultiplier,
          },
          projectImpactsBenefitLIEReductionSection,
          {
            name: "LIE Rates",
            fields: [
              "lieRates.goodQuality",
              "lieRates.mediumQuality",
              "lieRates.poorQuality",
            ],
            formatter: (val: number) => percentage(val),
          },
          {
            name: "LIE",
            fields: [
              "lie.goodQuality",
              "lie.mediumQuality",
              "lie.poorQuality",
              "lie.coalMineExcoLosses",
              "totalLie",
            ],
            totalSectionFields: ["totalLie"],
            formatter: (val: number) => round(val, 1).toString(),
          },
        ],
      },
    ],
  },
  {
    name: "INSTITUTIONAL_BANKING",
    types: [
      {
        sections: [
          {
            fields: fields.concat("lieAndNetWriteOffs"),
          },
          { isTotalSection: true, fields: totalSectionFields },
          projectImpactsBenefitLIEReductionSection,
          projectImpactsCumulativeImpactOnLossMultipliersSection,
          {
            fields: ["clientSpecialistCosts"],
          },
          lieSection,
        ],
      },
    ],
  },
  {
    name: "CREDIT_CARDS",
    types: [
      {
        type: "annualSpend",
        fields: fields
          .concat("lieAndNetWriteOffs")
          .concat("excoDecisionImpacts"),
        totalSectionFields,
        fieldFormatter: (field: string) =>
          field === "lieAndNetWriteOffs" ? "Spend lost due to losses" : null,
      },
      {
        type: "balances",
        fields: fields.concat("lieAndNetWriteOffs"),
        totalSectionFields,
      },
      {
        type: "interestEarningBalances",
        sections: [
          {
            fields: ["revolveRate", "averageInterestEarningBalances"],
            formatter: (val: number, field: string) =>
              field === "revolveRate" ? percentage(val) : formatValue(val),
          },
          projectImpactsCumulativeImpactOnLossMultipliersSection,
          {
            name: "Loan Impairment Expense",
            fields: [
              "lie.lossMultiplier",
              "lie.lieRates",
              "lie.lieLendingLosses",
              "lie.spendLostDueToLosses",
            ],
            formatter: lieFormatter,
          },
        ],
      },
      {
        type: "customers",
        fields: [
          "annualCustomerSpend",
          "changeInAnnualCustomerSpend",
          "averageCustomers",
          "closingCustomers",
        ],
      },
    ],
  },
  {
    name: "totalAssets",
    types: [
      {
        fields: fields.concat("lieAndNetWriteOffs"),
        totalSectionFields: ["closingBalance"],
      },
      {
        type: "WEALTH_MANAGEMENT",
        fields: [
          "openingBalance",
          "assetIncreases",
          "assetDecreases",
          "closingBalance",
          "averageBalance",
        ],
      },
    ],
  },
  {
    name: "DEPOSITS",
    types: [
      { type: "savingsAccounts", fields, totalSectionFields },
      { type: "transactionAccounts", fields, totalSectionFields },
      { type: "total", fields, totalSectionFields },
    ],
  },
  {
    name: "OFFSHORE_BANK",
    types: [
      { type: "underlyingEquityBookValue", fields, totalSectionFields },
      {
        type: "initialStakebookPremium",
        fields: fields.concat("lieAndNetWriteOffs"),
        totalSectionFields,
      },
      {
        sections: [
          projectImpactsBenefitLIEReductionSection,
          projectImpactsCumulativeImpactOnLossMultipliersSection,
        ],
      },
      {
        type: "lie",
        fields: ["lossMultiplier", "lieRates", "lieLendingLosses"],
        formatter: lieFormatter,
      },
      {
        type: "total",
        fields: ["averageBalance"],
        totalSectionFields: ["closingBalance"],
      },
    ],
  },
  {
    name: "wholesaleFunding",
    types: [
      {
        formatter: (val: number, field: string) =>
          ["repayments"].includes(field) ? formatValue(-1 * val) : null,
        fields: ["openingBalance", "annualGrowth", "repayments"],
        totalSectionFields: ["closingBalance", "averageBalance"],
      },
    ],
  },
  {
    name: "offBalanceSheet",
    types: [
      {
        type: "WEALTH_MANAGEMENT",
        sections: [
          {
            fields: [
              "openingBalance",
              "newFundsManaged",
              "attrition",
              "closingBalance",
              "averageBalance",
            ],
            totalSectionFields: ["closingBalance", "averageBalance"],
          },
          {
            name: "Balances On This Banks Balance Sheet",
            fields: [
              "balancesOnThisBanksBalanceSheet.closingBalance",
              "balancesOnThisBanksBalanceSheet.averageBalance",
            ],
            totalSectionFields: [
              "balancesOnThisBanksBalanceSheet.closingBalance",
              "balancesOnThisBanksBalanceSheet.averageBalance",
            ],
          },
          {
            fields: ["fumGrowth", "servicingCosts"],
            formatter: (val: number, field: string) =>
              field === "fumGrowth" ? percentage(val) : formatValue(val, 1, 1),
          },
        ],
      },
      {
        type: "OFFSHORE_BANK",
        sections: [
          {
            fields: [
              "openingBalance",
              "annualGrowth",
              "attrition",
              "lieAndNetWriteOffs",
              "closingBalance",
              "averageBalance",
            ],
            totalSectionFields: ["closingBalance", "averageBalance"],
          },
        ],
      },
    ],
  },
];

const pluckResultsByType = <K extends string, T extends Record<K, any>[]>(
  data: T,
  type: K,
  subType?: string,
) => {
  const results = [];

  for (const year of data) {
    if (subType) {
      results.push(year[type][subType]);
    } else {
      results.push(year[type]);
    }
  }

  return results;
};

const ModelTeamResultsProductBalances: React.FC<Props> = ({ data }) => (
  <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
    {types.map((type) => (
      <VerticalGroup className="" key={type.name} spaceBetweenElements={2} wide>
        <h3 className="ml-4">{startCase(type.name)}</h3>
        {type.types.map((subType) => {
          const values = pluckResultsByType(data, type.name, subType.type);
          const tableData: SectionData[] = [];
          if (subType.sections?.length) {
            for (const section of subType.sections) {
              tableData.push({
                name: section.name,
                isTotalsSection: section.isTotalSection ?? false,
                fields: section.fields,
                totalFields: section.totalSectionFields,
                values,
                formatter: section.formatter ?? subType.formatter,
                fieldFormatter:
                  section.fieldFormatter ?? subType.fieldFormatter,
              });
            }
          } else if (subType.fields?.length) {
            tableData.push({
              fields: subType.fields,
              values,
              formatter: subType.formatter,
              fieldFormatter: subType.fieldFormatter,
            });

            if (subType.totalSectionFields) {
              tableData.push({
                isTotalsSection: true,
                fields: subType.totalSectionFields,
                values,
              });
            }
          }

          return (
            <TableWithSections
              key={`${type.name} - ${subType.type}`}
              header={subType.header ?? startCase(subType.type ?? "")}
              data={tableData}
              fieldFormatter={(field) =>
                field === "lieAndNetWriteOffs" ? "Lie / Net Write Offs" : null
              }
              formatter={(val, section, field) =>
                ["attrition", "lieAndNetWriteOffs"].includes(field)
                  ? formatValue(-1 * val)
                  : formatValue(val)
              }
            />
          );
        })}
      </VerticalGroup>
    ))}
  </VerticalGroup>
);

export default ModelTeamResultsProductBalances;
