import React, { useCallback, useMemo, useRef, useState } from "react";
import { Accordion } from "../../../../organisms/accordion/accordion";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import Text from "../../../../atoms/text/Text";
import ConfigurationEvents from "./ConfigurationEvents";
import ConfigurationTablets from "./ConfigurationTablets";
import ConfigurationParticipants from "./ConfigurationParticipants";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import AdminAPI from "../../../../../services/api";
import useAPIRequest from "../../../../../hooks/useAPIRequest";
import Banner from "../../../../atoms/banner/Banner";
import { useTabletConfigContext } from "../context/context";
import ClickableText from "../../../../atoms/text/ClickableText";
import IconButton from "../../../../molecules/iconbutton/IconButton";
import ClearConfigForEvent from "../components/ClearConfigForEvent";
import PublishWarningModal from "../components/PublishWarningModal";
import PrintTabletsPage from "./PrintTabletsPage";
import DeleteTabletConfig from "../components/DeleteTabletConfig";
import PrintOnly from "../../../../atoms/printable/components/PrintOnly";

interface ConfigurationProps {
  refresh: () => void;
  onClearConfigurations: () => void;
}

const ConfigSettings = ({
  refresh,
  onClearConfigurations,
}: ConfigurationProps) => {
  const [defaultCeoColour, setDefaultCeoColour] =
    useState<API.TabletColour>("red");
  const [dismissError, setDismissError] = useState(false);
  const [dismissMessage, setDismissMessage] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPublishWarningModal, setShowPublishWarningModal] = useState(false);
  const printTabletsRef = useRef<any>(null);
  const [confirmClearModalOpen, setConfirmClearModalOpen] = useState(false);

  const {
    data,
    selectedEventId,
    eventData,
    tabletDataPerEvent,
    updateSelectedEventId,
    updateTabletsForEvent,
  } = useTabletConfigContext();

  const handleClearConfigurationsClick = useCallback(() => {
    setConfirmClearModalOpen(true);
  }, []);
  const handleConfirmClearModalClose = useCallback(() => {
    setConfirmClearModalOpen(false);
  }, []);

  const handleConfirmClearModalConfirm = useCallback(() => {
    setConfirmClearModalOpen(false);
    onClearConfigurations();
    refresh();
  }, [onClearConfigurations, refresh]);

  const callback = useCallback(async () => {
    if (selectedEventId) {
      await AdminAPI.sendTabletConfigEmail(selectedEventId);
    }
  }, [selectedEventId]);

  const [{ completed, inProgress, error }, doAPIRequest] = useAPIRequest(
    callback,
    null,
  );

  const handleSendEmail = useCallback(async () => {
    setDismissError(false);
    setDismissMessage(false);
    doAPIRequest();
  }, [doAPIRequest]);

  const publishCallback = useCallback(async () => {
    if (selectedEventId == null) return;
    const tabletData = tabletDataPerEvent[selectedEventId];
    if (!tabletData) return;
    const updatedMappings: API.TabletUpdateRequest = {
      tablets: tabletData.tablets.map((tablet) => ({
        tabletId: tablet.tabletId,
        participantId: tablet.participantId,
      })),
      teams: (data?.teams ?? [])
        .filter((t) => t.eventId !== selectedEventId)
        .concat(
          tabletData.teamColours.map((t, i) => ({
            teamId: i + 1,
            eventId: selectedEventId,
            colour: t,
          })),
        ),
    };
    await AdminAPI.updateTabletMappings(updatedMappings);
  }, [data?.teams, selectedEventId, tabletDataPerEvent]);

  const [{ inProgress: publishInProgress, error: publishError }, doPublish] =
    useAPIRequest(publishCallback, null);

  const { selectedEventData, tabletData } = useMemo(() => {
    if (!selectedEventId) return { selectedEventData: null, tabletData: null };
    return {
      selectedEventData: eventData[selectedEventId],
      tabletData: tabletDataPerEvent[selectedEventId],
    };
  }, [eventData, selectedEventId, tabletDataPerEvent]);

  const participantsWithoutTablets = useMemo(() => {
    if (selectedEventId == null) return [];
    const tabletData = tabletDataPerEvent[selectedEventId];
    if (!tabletData) return [];
    const selectedEventData = eventData[selectedEventId];
    if (!selectedEventData) return [];
    return selectedEventData.participants.filter(
      (p: API.ParticipantResponse) =>
        !tabletData.tablets.some((t) => t.participantId === p.id),
    );
  }, [eventData, selectedEventId, tabletDataPerEvent]);

  const handlePublishClick = useCallback(
    (acceptWarning?: boolean) => {
      if (!acceptWarning && participantsWithoutTablets.length > 0) {
        setShowPublishWarningModal(true);
      } else {
        setShowPublishWarningModal(false);
        doPublish(refresh);
      }
    },
    [doPublish, participantsWithoutTablets.length, refresh],
  );

  const handleGenerateClick = useCallback(() => {
    if (!selectedEventId) return;
    const selectedEventData = eventData[selectedEventId];
    if (!selectedEventData) return;
    const tabletData = tabletDataPerEvent[selectedEventId];
    if (!tabletData) return;

    const participantMap = selectedEventData.participants.reduce(
      (acc: Record<string, API.ParticipantResponse>, p) => {
        acc[p.id] = p;
        return acc;
      },
      {},
    );

    const updatedTablets: API.TabletDetailResponse[] = (
      tabletData?.tablets ?? []
    ).map((tablet) => {
      return {
        ...tablet,
        ...(tablet.participantId && participantMap[tablet.participantId]
          ? {
              participantId: null,
              eventId: null,
              email: null,
              teamId: null,
            }
          : {}),
      };
    });

    for (const participant of selectedEventData.participants) {
      if (participant.isCeo) {
        const ceoTabletThatIsUnassigned = updatedTablets.find(
          (tablet) =>
            tablet.colour === defaultCeoColour &&
            tablet.available &&
            tablet.participantId == null,
        );
        if (ceoTabletThatIsUnassigned) {
          ceoTabletThatIsUnassigned.participantId = participant.id;
        }
      } else {
        const teamColour = tabletData.teamColours[participant.team - 1];
        const tabletForColourThatIsUnassigned = updatedTablets.find(
          (tablet) =>
            tablet.colour === teamColour &&
            tablet.available &&
            tablet.participantId == null,
        );
        if (tabletForColourThatIsUnassigned) {
          tabletForColourThatIsUnassigned.participantId = participant.id;
        }
      }
    }

    updateTabletsForEvent(updatedTablets);
  }, [
    defaultCeoColour,
    eventData,
    selectedEventId,
    tabletDataPerEvent,
    updateTabletsForEvent,
  ]);

  if (!selectedEventData) {
    return (
      <VerticalGroup wide spaceBetweenElements={2}>
        <InlineGroup right block>
          <IconButton
            danger
            icon="trash"
            text="Clear Configurations"
            onClick={handleClearConfigurationsClick}
          />
        </InlineGroup>
        <ConfigurationEvents refresh={refresh} />
        <DeleteTabletConfig
          isOpen={confirmClearModalOpen}
          onConfirm={handleConfirmClearModalConfirm}
          onClose={handleConfirmClearModalClose}
        />
      </VerticalGroup>
    );
  }
  const handlePrintClick = () => {
    if (printTabletsRef.current) {
      printTabletsRef.current.handlePrint();
    }
  };

  return (
    <VerticalGroup wide spaceBetweenElements={2}>
      {showPublishWarningModal && (
        <PublishWarningModal
          participantsWithoutTablets={participantsWithoutTablets.length}
          isOpen
          onClose={() => setShowPublishWarningModal(false)}
          onConfirm={() => handlePublishClick(true)}
        />
      )}
      {showDeleteModal && (
        <ClearConfigForEvent
          eventId={selectedEventData.id}
          isOpen
          onClose={() => setShowDeleteModal(false)}
          onConfirm={refresh}
        />
      )}
      {error && (
        <Banner
          type="error"
          message={error.message}
          active={!dismissError}
          onClose={() => setDismissError(true)}
        />
      )}
      {publishError && (
        <Banner
          type="error"
          message={publishError.message}
          active={!dismissError}
          onClose={() => setDismissError(true)}
        />
      )}
      {completed && (
        <Banner
          type="success"
          message="Email sent"
          active={!dismissMessage}
          onClose={() => setDismissMessage(true)}
        />
      )}
      <InlineGroup block spread verticalCenter>
        <InlineGroup spaceBetweenElements={2}>
          <Text bold>
            {selectedEventData.client} - {selectedEventData.name}
          </Text>
          <ClickableText onClick={() => updateSelectedEventId(null)}>
            Change
          </ClickableText>
        </InlineGroup>
        <InlineGroup spaceBetweenElements={4}>
          <IconButton
            danger
            icon="trash"
            text="Delete Configuration"
            onClick={() => setShowDeleteModal(true)}
          />
          <IconButton
            icon="wand"
            onClick={handleGenerateClick}
            text="Generate"
          />
          <IconButton
            icon="paperPlane"
            onClick={() => handlePublishClick()}
            inProgress={publishInProgress}
            text="Publish"
          />

          <IconButton
            icon="printAttendance"
            onClick={handlePrintClick}
            inProgress={inProgress}
            text="Print"
          />
          <IconButton
            icon="email"
            onClick={handleSendEmail}
            inProgress={inProgress}
            text="Send Tablet Config Email"
          />
        </InlineGroup>
      </InlineGroup>
      <VerticalGroup spaceBetweenElements={2} wide>
        <PrintOnly>
          <PrintTabletsPage
            event={selectedEventData}
            tabletData={tabletData}
            ref={printTabletsRef}
          />
        </PrintOnly>

        <Accordion wide title="Tablets" defaultState="expand">
          <ConfigurationTablets
            eventMapping={tabletData}
            defaultCeoColour={defaultCeoColour}
            setDefaultCeoColour={setDefaultCeoColour}
          />
        </Accordion>
        <Accordion wide title="Participants" defaultState="expand">
          {tabletData && (
            <ConfigurationParticipants
              participantDetails={selectedEventData?.participants || []}
              tabletData={tabletData}
            />
          )}
        </Accordion>
      </VerticalGroup>
    </VerticalGroup>
  );
};

export default ConfigSettings;
