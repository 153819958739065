import React, { ReactNode } from "react";
import classNames from "classnames";

import Label from "../label/Label";

import "./DisplayField.scss";
import InlineGroup from "../../inlinegroup/InlineGroup";
import Text from "../../text/Text";
import VerticalGroup from "../../verticalgroup/VerticalGroup";

interface Props {
  className?: string;
  label: string;
  value: string | number | ReactNode;
  stretch?: boolean;
  "data-test"?: string;
  verticalCenter?: boolean;
  nowrap?: boolean;
  compact?: boolean;
  allowOverflow?: boolean;
  isVerticalLayout?: boolean;
}

function DisplayField({
  className,
  label,
  value,
  stretch,
  "data-test": dataTest,
  verticalCenter = true,
  nowrap = true,
  compact = false,
  allowOverflow = false,
  isVerticalLayout = false,
}: Props) {
  const valueIsStringOrNumber =
    typeof value === "string" || typeof value === "number";
  if (isVerticalLayout) {
    return (
      <VerticalGroup
        className={classNames("display-field", className, {
          compact,
        })}
        verticalCenter={verticalCenter}
        spaceBetweenElements={2}
      >
        <Label bold>{label}</Label>
        <div
          className={classNames({
            stretch,
            nowrap,
            "display-field-value": !compact,
          })}
          data-test={dataTest}
        >
          {valueIsStringOrNumber ? (
            <Text size="sm" allowOverflow={allowOverflow}>
              {value}
            </Text>
          ) : (
            value
          )}
        </div>
      </VerticalGroup>
    );
  }
  return (
    <InlineGroup
      className={classNames("display-field", className, {
        compact,
      })}
      verticalCenter={verticalCenter}
    >
      <Label>{label}</Label>
      <div
        className={classNames({
          stretch,
          nowrap,
          "display-field-value": !compact,
        })}
        data-test={dataTest}
      >
        {valueIsStringOrNumber ? (
          <Text size="sm" allowOverflow={allowOverflow}>
            {value}
          </Text>
        ) : (
          value
        )}
      </div>
    </InlineGroup>
  );
}

export default DisplayField;
