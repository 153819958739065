import React, { useCallback, useMemo } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import { TeamsWithColours } from "../RoundResultsPage";
import ResultsBarChart from "../charts/ResultsBarChart";
import { thousandSeparator } from "../../model/utils/formatters";
import ChartCard from "../components/ChartCard";
import { getYAxisDataV2 } from "../charts/yaxis";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import { createLegend } from "../charts/BarChartLegend";
import { useResultsContext } from "../context/context";
import { createBarChartTopLabel } from "../charts/BarChartTopLabel";

interface Props {
  currencyDenomination: string;
  teams: TeamsWithColours[];
  cashBalances: ModelAPI.Reporting.TeamData[];
}

const RoundResultsCashBalances: React.FC<Props> = ({
  currencyDenomination,
  teams,
  cashBalances,
}) => {
  const data = useMemo(
    () =>
      cashBalances.map((teamValue) => {
        const team = teams.find((t) => t.teamId === teamValue.teamId);
        if (team == null) {
          throw new Error("Team should exist");
        }
        return {
          name: team.teamName,
          value: teamValue.value,
          key: `team${teamValue.teamId}`,
          teamId: teamValue.teamId,
          fill: team.colour,
          topLabel: createBarChartTopLabel(thousandSeparator),
          topValueToUse: teamValue.value,
          legend: createLegend(teams),
        };
      }),
    [cashBalances, teams],
  );

  const { minValue, maxValue, numberOfTicks } = useMemo(
    () =>
      getYAxisDataV2({
        pastRound1: true,
        min: 0,
        data: [{ name: "fake0", value: 0 }].concat(
          cashBalances.map((cb) => ({
            name: "fakeName",
            value: cb.value,
          })),
        ),
      }),
    [cashBalances],
  );

  const context = useResultsContext();
  const maxClicks = useMemo(
    () => (context.allowPresentationMode || context.readSyncState ? 1 : 0),
    [context.allowPresentationMode, context.readSyncState],
  );
  const onNext = useCallback(() => {
    if (context.clicks < maxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context, maxClicks]);

  return (
    <RoundResultsContainer onNext={onNext}>
      <VerticalGroup full center wide>
        <ChartCard
          heading="Cash Balances"
          currencyDenomination={currencyDenomination}
        >
          <ResultsBarChart
            data={data}
            formatter={(val: number) => thousandSeparator(val)}
            withoutCpuTeams
            minYAxisvalue={minValue}
            maxYAxisvalue={maxValue}
            numberOfTicks={numberOfTicks}
            shouldRenderLines={context.clicks === maxClicks}
          />
        </ChartCard>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsCashBalances;
