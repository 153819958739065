import { useState, useEffect } from "react";

const useIsMobile = (customBreakpoint?: number): boolean => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      const mobileBreakpoint = customBreakpoint || 850;
      setIsMobile(window.innerWidth <= mobileBreakpoint);
    };

    checkIsMobile();

    window.addEventListener("resize", checkIsMobile);

    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, [customBreakpoint]);

  return isMobile;
};

export default useIsMobile;
