import React, { useMemo } from "react";

import Dropdown from "../../atoms/form/input/Dropdown";
import useScenarios from "../../../hooks/useScenarios";

interface SelectValue {
  id: string;
  value: string;
  label: string;
  scenario: API.EconomicScenarioResponse;
}

interface EconomicScenarioDropdownProps {
  scenarioId?: string;
  onScenarioSelected?: (scenarioId: string) => void;
}

const EconomicScenarioDropdown: React.FC<EconomicScenarioDropdownProps> = ({
  scenarioId,
  onScenarioSelected,
}) => {
  const { data } = useScenarios();

  const options: SelectValue[] = useMemo(() => {
    return data
      ? data.map((scenario) => ({
          id: scenario.id,
          value: scenario.id,
          label: scenario.name,
          scenario,
        }))
      : [];
  }, [data]);

  const currentSelected = useMemo(() => {
    if (scenarioId) {
      return options.find((option) => option.id === scenarioId);
    }
    return null;
  }, [scenarioId, options]);

  return (
    <Dropdown
      selectProps={{
        onChange: (option: SelectValue) =>
          onScenarioSelected && onScenarioSelected(option.value),
        value: currentSelected,
        options,
        noOptionsMessage: () => "No Scenarios",
      }}
    />
  );
};

export default EconomicScenarioDropdown;
