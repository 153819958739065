import React from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import { FieldsOf } from "./types";
import GloInput from "./GloInput";

type DataType = API.GlobalModelVariablesResponse["general"];

interface Props {
  data: DataType;
  onChange: <T extends keyof DataType>(
    key: T,
    field?: undefined | FieldsOf<DataType, T>,
  ) => (selected: any) => void;
}

const General: React.FC<Props> = ({ data, onChange }) => {
  return (
    <VerticalGroup wide spaceBetweenElements={3} className="p-2">
      <GloInput
        label="Corporate Tax Rate"
        value={data.corporateTaxRate}
        onChange={onChange("corporateTaxRate")}
        type="percentage"
      />
    </VerticalGroup>
  );
};
export default General;
