import React, { useCallback } from "react";
import AssessmentForm from "./AssessmentForm";
import API from "../../../services/api";
import Container from "../../atoms/page/Page";
import { useHistory } from "react-router-dom";

function CreateAssessment() {
  const onSave = useCallback((data: API.AssessmentRequest) => {
    return API.createAssessment(data);
  }, []);

  const history = useHistory();

  const onCancel = useCallback(() => {
    history.push("/assessments");
  }, [history]);

  const onComplete = useCallback(
    (data) => {
      history.push(`/assessments/${data.id}/view`);
    },
    [history],
  );

  return (
    <Container>
      <AssessmentForm
        onSave={onSave}
        onCancel={onCancel}
        onComplete={onComplete}
        mode="create"
      />
    </Container>
  );
}

export default CreateAssessment;
