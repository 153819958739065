import React from "react";
import ResultsText from "../results-text/ResultsText";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";

interface ResultsSubHeadingProps {
  subHeading: string;
  styledUnderline?: boolean;
}

const ReportingSubHeading: React.FC<ResultsSubHeadingProps> = ({
  subHeading,
  styledUnderline,
}) => {
  return (
    <InlineGroup center className="mb-2 width-90-percent">
      <ResultsText
        styledUnderline={styledUnderline}
        bold
        size="xl"
        colour="blue"
        center
      >
        {subHeading}
      </ResultsText>
    </InlineGroup>
  );
};

export default ReportingSubHeading;
