import React from "react";
import InterestAndSpread from "./InterestAndSpread";
import SpreadOnly from "./SpreadOnly";
import TotalWholesaleFundingCosts from "./TotalWholesaleFundingCosts";
import LargeDebtIssuePremiums from "./LargeDebtIssuePremiums";
import EffectiveWSFRates from "./EffectiveWSFRates";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["wholesaleFundingCosts"];
  fields: Array<{ field: string; name: string }>;
}

const WholesaleFundingCosts: React.FC<Props> = ({
  fields,
  data,
  configuration,
}) => {
  return (
    <>
      <LargeDebtIssuePremiums
        configuration={configuration}
        data={data}
        fields={fields}
      />
      <InterestAndSpread
        configuration={configuration}
        data={data}
        fields={fields}
      />
      <SpreadOnly configuration={configuration} data={data} fields={fields} />
      <TotalWholesaleFundingCosts
        configuration={configuration}
        data={data}
        fields={fields}
      />
      <EffectiveWSFRates
        configuration={configuration}
        data={data}
        fields={fields}
      />
    </>
  );
};

export default WholesaleFundingCosts;
