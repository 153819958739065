import { LayoutResponse } from "../types";

export const formatDuration = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
};

export const parseDuration = (formattedDuration: string): number => {
  const parts = formattedDuration.split(":");
  if (parts.length === 1) {
    return parseInt(parts[0], 10);
  } else if (parts.length === 2) {
    return parseInt(parts[0], 10) * 60 + parseInt(parts[1], 10);
  }
  return NaN;
};

export const getTotalDurationEstimate = (
  layouts: LayoutResponse[],
  curentRound: number,
  currentIndex: number,
): number => {
  const enabledLayouts = layouts.filter((layout) => layout.enabled);
  return enabledLayouts
    .slice(currentIndex)
    .reduce(
      (sum, layout) =>
        sum +
        (curentRound === 1
          ? layout.durationEstimateFirstRound ?? layout.durationEstimate
          : layout.durationEstimate),
      0,
    );
};
