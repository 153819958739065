import React, { useCallback, useContext, useEffect, useMemo } from "react";

import Banner from "../../../../../atoms/banner/Banner";
import Form from "../../../../../atoms/form/Form";
import { EventContext } from "../../context";
import useForm, { FormError } from "../../../../../../hooks/useForm";
import StepContainer from "../../components/StepContainer";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import EventOptions from "../../components/EventOptions";
import Card from "../../../../../atoms/card/Card";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import InformationPopup from "../../../../../organisms/information-popup/InformationPopup";
import Text from "../../../../../atoms/text/Text";
import RadioGroup from "../../../../../atoms/form/input/RadioGroup";
import IntegerDropdown from "../../../../../organisms/integer-dropdown/IntegerDropdown";
import Button from "../../../../../atoms/button/Button";
import {
  setStateFromSchedule,
  DEFAULT_MINS_ALLOCATED,
  getDefaultDate,
  formatDateForAPI,
} from "./utils";
import RoundSchedulingRow, { RoundSchedule } from "./RoundSchedulingRow";
import DisplayField from "../../../../../atoms/form/display-field/DisplayField";
import { pluralise } from "../../../../../../lib/pluralise";
import { addDaysToDate } from "../../../../../../lib/date";
import API from "../../../../../../services/api";
import { getEventUpdatePayload } from "../../utils";

const Schedule: React.FC = () => {
  const { config, updateConfig, assignOnSubmitFunction, updateFormStatus } =
    useContext(EventContext);

  const [
    { formData, fieldErrors, error, inProgress, formUpdated },
    {
      setRadioField,
      setFieldRaw,
      setDropdownField,
      setError,
      handleSubmit,
      clearFormError,
    },
  ] = useForm(
    config?.schedule && config?.simulation
      ? setStateFromSchedule(config.schedule, config.simulation)
      : {
          facilitatedRoundsEnabled: true,
          scheduledRoundsEnabled: false,
          rounds: config?.simulation?.generalSetup.rounds || 3,
          daysBetweenRounds: 7,
          schedules: new Array(4).fill({
            timeAllocated: DEFAULT_MINS_ALLOCATED,
            startTime: getDefaultDate(),
            finishTime: getDefaultDate(),
          }),
        },
  );

  const {
    facilitatedRoundsEnabled,
    scheduledRoundsEnabled,
    rounds,
    daysBetweenRounds,
    schedules,
  } = formData;

  const validate = useCallback(() => {
    const errors: FormError[] = [];

    if (scheduledRoundsEnabled) {
      for (let i = 0; i < schedules.length; i++) {
        const schedule = schedules[i];
        if (i === 0) {
          if (schedule.startTime < new Date()) {
            errors.push({
              field: `schedule-${i}-startTime`,
              message: "Can't be in the past",
            });
          } else {
            errors.push({
              field: `schedule-${i}-startTime`,
              message: undefined,
            });
          }
        }
        if (i !== 0) {
          const previousRound = schedules[i - 1];
          if (previousRound.finishTime > schedule.startTime) {
            errors.push({
              field: `schedule-${i}-startTime`,
              message: "Can't be before finish time of previous round",
            });
          } else {
            errors.push({
              field: `schedule-${i}-startTime`,
              message: undefined,
            });
          }
        }
        if (schedule.finishTime <= schedule.startTime) {
          errors.push({
            field: `schedule-${i}-finishTime`,
            message: "Finish time must be after start time",
          });
        } else {
          errors.push({
            field: `schedule-${i}-finishTime`,
            message: undefined,
          });
        }
      }
    }
    return errors;
  }, [scheduledRoundsEnabled, schedules]);

  const onSubmit = useCallback(async () => {
    if (config?.id) {
      const payload = getEventUpdatePayload(config, {
        schedule: {
          roundType: facilitatedRoundsEnabled ? "facilitated" : "scheduled",
          schedules: schedules.map((s: RoundSchedule) => ({
            timeAllocated: facilitatedRoundsEnabled ? s.timeAllocated : 0,
            startTime: facilitatedRoundsEnabled
              ? null
              : formatDateForAPI(s.startTime),
            finishTime: facilitatedRoundsEnabled
              ? null
              : formatDateForAPI(s.finishTime),
          })),
        },
      });

      const response = await API.editEvent(config.id, payload);
      updateConfig({ ...config, schedule: response.schedule });
    }
  }, [config, facilitatedRoundsEnabled, schedules, updateConfig]);

  const onRadioChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setRadioField(["facilitatedRoundsEnabled", "scheduledRoundsEnabled"])(
        e.target.value,
      );
      if (e.target.value === "facilitatedRoundsEnabled") {
        clearFormError();
      }
    },
    [clearFormError, setRadioField],
  );

  const radioGroups = useMemo(() => {
    return [
      {
        label: "Facilitated Rounds",
        value: "facilitatedRoundsEnabled",
        checked: facilitatedRoundsEnabled,
      },
      {
        label: "Specific Start/End Dates",
        value: "scheduledRoundsEnabled",
        checked: scheduledRoundsEnabled,
      },
    ];
  }, [facilitatedRoundsEnabled, scheduledRoundsEnabled]);

  useEffect(() => {
    const roundsPlusWrapUp = rounds + 1;
    if (roundsPlusWrapUp !== schedules.length) {
      if (roundsPlusWrapUp > schedules.length) {
        const remaining = roundsPlusWrapUp - schedules.length;
        setFieldRaw(
          "schedules",
          schedules.concat(
            new Array(remaining).fill({
              timeAllocated: DEFAULT_MINS_ALLOCATED,
              startTime: getDefaultDate(),
              finishTime: getDefaultDate(),
            }),
          ),
        );
      } else {
        setFieldRaw("schedules", schedules.slice(0, roundsPlusWrapUp));
      }
    }
    const errors = validate();
    for (const err of errors) {
      setError(err.field, err.message);
    }
  }, [rounds, schedules, setError, setFieldRaw, validate]);

  const generateScheduledRounds = useCallback(() => {
    const startingRound = schedules[0];
    const generatedSchedule = schedules.map((s: RoundSchedule, i: number) => {
      if (i === 0) {
        return s;
      }
      return {
        ...s,
        startTime: addDaysToDate(
          i * daysBetweenRounds,
          startingRound.startTime,
        ),
        finishTime: addDaysToDate(
          i * daysBetweenRounds,
          startingRound.finishTime,
        ),
      };
    });
    setFieldRaw("schedules", generatedSchedule);
    const errors = validate();
    for (const err of errors) {
      setError(err.field, err.message);
    }
  }, [schedules, daysBetweenRounds, setFieldRaw, validate, setError]);

  const updateRoundSchedule = useCallback(
    (index: number) => {
      return (value: RoundSchedule) => {
        const updatedSchedules = schedules.map(
          (s: RoundSchedule, scheduleIndex: number) => {
            if (scheduleIndex === index) {
              return value;
            }
            return s;
          },
        );
        setFieldRaw("schedules", updatedSchedules);
        const errors = validate();
        for (const err of errors) {
          setError(err.field, err.message);
        }
      };
    },
    [schedules, setFieldRaw, setError, validate],
  );

  useEffect(() => {
    assignOnSubmitFunction(handleSubmit(onSubmit, validate));
  }, [assignOnSubmitFunction, handleSubmit, onSubmit, validate]);

  useEffect(() => {
    updateFormStatus({
      inProgress: inProgress,
      formUpdated: formUpdated,
    });
  }, [updateFormStatus, inProgress, formUpdated]);

  return (
    <StepContainer>
      <Banner type="error" active={!!error} message={error?.message} />
      <InlineGroup spread verticalCenter block>
        <h2>Round Schedule</h2>
        <EventOptions />
      </InlineGroup>
      <Form wide>
        <Card wide padding={16}>
          <VerticalGroup wide spaceBetweenElements={4}>
            <InlineGroup spaceBetweenElements={2}>
              <RadioGroup
                name="round-style"
                label="Round Style"
                onChange={onRadioChange}
                fields={radioGroups}
              />
              <InformationPopup
                shadow
                title="Round Style"
                body={
                  <>
                    <p>
                      Facilitated Rounds – These rounds will be manually started
                      by facilitators during an event.{" "}
                    </p>
                    <p>
                      Scheduled Rounds – These rounds will automatically start
                      and stop based on the schedule below.
                    </p>
                  </>
                }
              />
            </InlineGroup>
            {scheduledRoundsEnabled && (
              <InlineGroup spaceBetweenElements={2} verticalCenter>
                <IntegerDropdown
                  label="Repeat every"
                  value={daysBetweenRounds}
                  onChange={setDropdownField("daysBetweenRounds")}
                  min={1}
                  max={31}
                  labelFormatter={(val: number) =>
                    `${val} ${pluralise("day", val)}`
                  }
                />
                <Button wide onClick={generateScheduledRounds}>
                  Create Schedule
                </Button>
                <InformationPopup
                  shadow
                  title="How to create a schedule"
                  body={
                    <>
                      <p>Step 1: Select Round 1 start date &#38; time</p>
                      <p>Step 2: Select Round 1 end date &#38; time</p>
                      <p>Step 3: Select “Repeat every X days”</p>
                      <p>Step 4: Click the “Create Schedule” button</p>
                    </>
                  }
                />
              </InlineGroup>
            )}
            {scheduledRoundsEnabled && (
              <DisplayField
                label=""
                value={
                  <InlineGroup spaceBetweenElements={2}>
                    <div className="input-width">
                      <Text size="sm" className="input-width" bold>
                        Starting Date and Time
                      </Text>
                    </div>
                    <div className="input-width">
                      <Text size="sm" className="input-width" bold>
                        Finishing Date and Time
                      </Text>
                    </div>
                    <div className="input-width">
                      <Text size="sm" className="input-width" bold>
                        Round Duration
                      </Text>
                    </div>
                  </InlineGroup>
                }
              />
            )}
            {schedules.map((schedule: RoundSchedule, i: number) => (
              <RoundSchedulingRow
                key={i}
                label={i < rounds ? `Round ${i + 1}` : "WrapUp Round"}
                schedule={schedule}
                facilitatedRoundsEnabled={facilitatedRoundsEnabled}
                fieldErrors={{
                  startTime: fieldErrors[`schedule-${i}-startTime`],
                  finishTime: fieldErrors[`schedule-${i}-finishTime`],
                }}
                onChange={updateRoundSchedule(i)}
              />
            ))}
          </VerticalGroup>
        </Card>
      </Form>
    </StepContainer>
  );
};

export default Schedule;
