import React, { useRef } from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
} from "recharts";
import createCustomTooltip from "../components/CustomTooltip";
import { useResultsContext } from "../context/context";
import useIsHighResolution from "../../../../hooks/useIsHightResolution";
import useChartStyles from "../../../../hooks/useChartStyles";
import useLineChartRendering from "./useLineChartRendering";

export interface LineData {
  name: string;
  key: string;
  colour: string;
  dot?: any;
}
export interface DataPoint {
  name: string;
  [key: string]: string | number | null;
}
interface Props {
  data: DataPoint[];
  formatter?: (val: number) => string | number;
  toolTipFormatter?: (val: number) => string | number;
  yAxisFormatter?: (val: number) => string | number;
  maxYAxisvalue: number;
  minYAxisvalue: number;
  numberOfTicks: number;
  lines: LineData[];
  tooltipOrdering?: "highest" | "lowest";
  shouldRenderLines?: boolean;
  linesOneByOne?: boolean;
}

const ResultsLineChart: React.FC<Props> = ({
  data,
  lines,
  formatter,
  yAxisFormatter,
  maxYAxisvalue,
  minYAxisvalue,
  numberOfTicks,
  tooltipOrdering = "highest",
  toolTipFormatter,
  shouldRenderLines,
  linesOneByOne = true,
}) => {
  const context = useResultsContext();
  const isHighResolution = useIsHighResolution();
  const chartStyles = useChartStyles();
  const chartRef = useRef<any>(null);
  const [
    renderStartTime,
    tooltipVisible,
    setAnimationsCompleted,
    animationCountRef,
  ] = useLineChartRendering({
    data,
    chartRef,
    shouldRenderLines,
    lines,
  });

  const legendFontSize =
    lines.length > 9
      ? "clamp(0.20rem, calc(0.20rem + 0.70vw), 2rem)"
      : "clamp(0.30rem, calc(0.30rem + 0.90vw), 2rem)";

  return (
    <>
      <ResponsiveContainer width="100%" height="92%" debounce={1}>
        <LineChart
          ref={chartRef}
          data={data}
          margin={{
            top: 20,
            right: isHighResolution ? 50 : 15,
            left: isHighResolution ? 50 : 15,
            bottom: 10,
          }}
        >
          <CartesianGrid vertical={false} />
          <ReferenceLine stroke="#000" />
          <XAxis
            dataKey="name"
            tickLine={false}
            padding={{ left: 75, right: 75 }}
            tick={{
              style: {
                fontSize: "clamp(0.15rem, calc(0.15rem + 0.80vw), 2rem)",
              },
            }}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            minTickGap={0}
            tickMargin={0}
            tickCount={numberOfTicks + 1}
            tickFormatter={
              yAxisFormatter
                ? (tick: any) => yAxisFormatter(tick)
                : formatter
                  ? (tick) => formatter(tick)
                  : (tick) => tick
            }
            domain={[minYAxisvalue, maxYAxisvalue]}
            tick={{
              style: {
                fontSize: "clamp(0.15rem, calc(0.15rem + 0.80vw), 2rem)",
              },
            }}
          />
          <Tooltip
            isAnimationActive={false}
            active={tooltipVisible}
            formatter={
              toolTipFormatter
                ? (tick: any) => toolTipFormatter(tick)
                : formatter
                  ? (tick: any) => formatter(tick)
                  : (tick: any) => tick
            }
            content={createCustomTooltip(tooltipOrdering, data)}
          />
          {!context.hideTeamPositions && (
            <Legend
              wrapperStyle={{
                bottom: 8,
                left: 0,
                right: 0,
                fontSize: legendFontSize,
              }}
            />
          )}
          {shouldRenderLines &&
            lines.map((l, idx) => (
              <Line
                key={`line-item-${idx}`}
                name={l.name}
                type="natural"
                dataKey={l.key}
                stroke={l.colour}
                strokeWidth={chartStyles.strokeWidth}
                dot={l.dot ? l.dot : { r: chartStyles.circleRadius }}
                activeDot={{
                  r: chartStyles.circleRadius,
                  stroke: l.colour,
                  strokeWidth: chartStyles.strokeWidth,
                  fill: "transparent",
                }}
                isAnimationActive={context.noAnimations ? false : true}
                animationBegin={
                  context.noAnimations
                    ? 0
                    : linesOneByOne
                      ? (renderStartTime ? Date.now() - renderStartTime : 0) +
                        idx * 1000
                      : renderStartTime
                        ? Date.now() - renderStartTime
                        : 0
                }
                onAnimationEnd={() => {
                  animationCountRef.current += 1;
                  if (animationCountRef.current === lines.length) {
                    setAnimationsCompleted(true);
                  }
                }}
              />
            ))}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default ResultsLineChart;
