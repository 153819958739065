import React, { useState } from "react";
import Checkbox from "../../../../../atoms/form/input/Checkbox";
import Input from "../../../../../atoms/form/input/Input";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import Text from "../../../../../atoms/text/Text";
import Radio from "../../../../../atoms/form/input/Radio";

interface Props {
  type: API.QuestionType;
  question: API.AssessmentQuestionResponse;
  field: API.WrittenResponseFieldResponse;
  answer?: API.AssessmentAnswerResponse["answers"][0]["answer"];
  isAnswerMode?: boolean;
}

export const OtherOptionField: React.FC<Props> = ({
  type,
  question,
  field,
  answer,
  isAnswerMode = false,
}) => {
  const [isSelected, setSelected] = useState(false);
  const answerItem = typeof answer !== "undefined" ? answer[0] : undefined;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(e.target.checked);
  };

  return (
    <>
      {type === "multiple_choices" && (
        <Checkbox
          name={question.id}
          label={"Other"}
          onChange={handleChange}
          {...(answerItem ? { checked: answerItem.fieldId === field.id } : {})}
          {...(isAnswerMode ? { disabled: true } : {})}
        />
      )}
      {type === "checkboxes" && (
        <Radio
          name={question.id}
          label={"Other"}
          onChange={handleChange}
          {...(answerItem ? { checked: answerItem.fieldId === field.id } : {})}
          {...(isAnswerMode ? { disabled: true } : {})}
        />
      )}
      <VerticalGroup spaceBetweenElements={1} wide>
        <Input
          block
          containerBlock
          {...(answerItem
            ? { value: answerItem.value, disabled: true }
            : {
                disabled: !isSelected,
              })}
        />
        {field.config.hasCharacterLimit && (
          <Text size="xs" colour="secondaryDark4">
            Character limit {field.config.characterLimit}
          </Text>
        )}
      </VerticalGroup>
    </>
  );
};
