import React from "react";

import teamsLogistics from "../../../../assets/results/teamsOne.png";
import stepTwoImage from "../../../../assets/results/teamsTwo.png";
import stepThreeImage from "../../../../assets/results//teamsThree.png";
import stepFourImage from "../../../../assets/results//teamsFour.png";
import teamsStart from "../../../../assets/results/teamsStart.png";
import VirtualMeetingIntro from "../components/VirtualMeetingIntro";

const IntroMicrosoftTeams = () => {
  const steps = [
    {
      text: "Please minimize background noise by using mute.",
      imageOverride: teamsLogistics,
    },
    {
      text: "Feel free to use the chat function for questions / comments.",
      imageOverride: stepTwoImage,
    },
    {
      text: "Raise your hand if you need our assistance.",
      imageOverride: stepThreeImage,
    },
    {
      text: "We'd love to see you on camera, however, turn off cameras during videos.",
      imageOverride: stepFourImage,
    },
  ];

  return (
    <VirtualMeetingIntro
      defaultHeading="Microsoft Teams Logistics"
      image={teamsStart}
      steps={steps}
    />
  );
};

export default IntroMicrosoftTeams;
