import React from "react";
import SimpleModal from "../../../../../organisms/standard-modal/SimpleModal";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import Button from "../../../../../atoms/button/Button";
import Text from "../../../../../atoms/text/Text";
import Container from "../../../../../atoms/page/Page";
import useIsMobileOrTablet from "../../../../../../hooks/useIsMobileOrTablet";

export interface NameSuggestion {
  firstName: string;
  lastName: string;
}

interface NameSuggestionModalProps {
  isOpen: boolean;
  onClose: () => void;
  suggestions: NameSuggestion[];
  onSelect: (suggestion: NameSuggestion) => void;
}

const NameSuggestionModal: React.FC<NameSuggestionModalProps> = ({
  isOpen,
  onClose,
  suggestions,
  onSelect,
}) => {
  const isMobileOrTablet = useIsMobileOrTablet();
  return (
    <SimpleModal
      isOpen={isOpen}
      onClose={onClose}
      size={isMobileOrTablet ? "scale" : "medium"}
    >
      <Container fit>
        <VerticalGroup center spaceBetweenElements={8}>
          <Text bold size="4xl">
            Name Suggestions
          </Text>
          {suggestions.map((suggestion, index) => (
            <Button
              block
              wide
              large
              style={{ paddingTop: "3rem", paddingBottom: "3rem" }}
              key={index}
              onClick={() => {
                onSelect(suggestion);
                onClose();
              }}
            >
              <Text size="4xl">{`${suggestion.firstName} ${suggestion.lastName}`}</Text>
            </Button>
          ))}
        </VerticalGroup>
      </Container>
    </SimpleModal>
  );
};

export default NameSuggestionModal;
