import React from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import ClickableText from "../../../../atoms/text/ClickableText";
import openNewTab from "../../../../../lib/openNewTab";
import ConfirmModal from "../../../../organisms/confirm-modal/ConfirmModal";
import { SimulationStepTitle } from "../../../simulations/state";

interface Props {
  simulationsThatNeedManualModification: API.SimulationsThatNeedManualModification[];
  onClose: () => void;
  stepTitle: SimulationStepTitle;
}

const SimulationsThatNeedManualModificationModal: React.FC<Props> = ({
  simulationsThatNeedManualModification,
  onClose,
  stepTitle,
}) => {
  return (
    <ConfirmModal
      isOpen
      onClose={onClose}
      onConfirm={onClose}
      onCancel={onClose}
      confirmTitle="OK"
      title={"Simulations That Need Manual Modification"}
      description={
        <VerticalGroup spaceBetweenElements={4}>
          The following simulations could not be updated as the text has
          diverged from the base config.
          <br />
          Please manually update the text in the following simulations
          <VerticalGroup className="mt-4">
            {simulationsThatNeedManualModification.map((simulation, index) => (
              <ClickableText
                key={index}
                onClick={() => {
                  openNewTab(
                    `/simulations/${simulation.id}/edit?stepTitle=${stepTitle}`,
                  );
                }}
              >
                {simulation.name}
              </ClickableText>
            ))}
          </VerticalGroup>
        </VerticalGroup>
      }
    />
  );
};

export default SimulationsThatNeedManualModificationModal;
