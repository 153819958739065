import React from "react";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import { FileDropZone } from "../../../../organisms/file-dropzone/FileDropZone";
import InlineError from "../../../../atoms/inlineerror/InlineError";
import Video from "../../../../atoms/video/Video";
import { useResultsContext } from "../../context/context";

interface VideoSectionProps {
  item: API.ReportingLayoutCustomSlideVideoOption;
  index: number;
  handleVideoUpload: (files: File[], index: number, itemIndex: number) => void;
  isUploading: boolean;
  uploadError: string | null;
  getAlignment: (
    item: API.ReportingLayoutCustomSlideVideoOption,
  ) => "left" | "center" | "right";
}

const VideoSection: React.FC<VideoSectionProps> = ({
  item,
  index,
  handleVideoUpload,
  isUploading,
  uploadError,
  getAlignment,
}) => {
  const context = useResultsContext();

  return (
    <InlineGroup fullHeight block>
      {item.videoUrl ? (
        <InlineGroup
          fullHeight
          block
          center={getAlignment(item) === "center"}
          right={getAlignment(item) === "right"}
        >
          <Video
            width={item.width || 600}
            height={item.height || 500}
            controls
            src={item.videoUrl}
          />
        </InlineGroup>
      ) : (
        context.allowCustomTemplateEdits && (
          <VerticalGroup wide full>
            <FileDropZone
              required={false}
              className="width-100-percent"
              onDrop={(files) => handleVideoUpload(files, index, -1)}
              accept="video/*"
              dropzonePlaceholderMain="Drag your video here"
              dropzonePlaceholderSecondary="or click to upload from your computer"
              isLoading={isUploading}
            />
            {uploadError && <InlineError active message={uploadError} />}
          </VerticalGroup>
        )
      )}
      {!item.videoId && !item.videoUrl && !context.allowCustomTemplateEdits && (
        <div> Add your custom video</div>
      )}
    </InlineGroup>
  );
};

export default VideoSection;
