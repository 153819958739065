import React, { useCallback, useMemo, useState } from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import { Accordion } from "../../../../organisms/accordion/accordion";
import { useMemoRequest } from "../../../../../hooks/useMemoRequest";
import AdminAPI from "../../../../../services/api";
import LoadingSpinner from "../../../../atoms/loadingspinner/LoadingSpinner";
import Banner from "../../../../atoms/banner/Banner";
import Text from "../../../../atoms/text/Text";
import CountryDropdown from "../../../simulations/steps/region/CountryDropdown";
import OtherMarketVariables from "./OtherMarketVariables";
import BalanceSheet from "./BalanceSheet";
import { FieldsOf } from "./types";
import CapitalTreatment from "./CapitalTreatment";
import Pricing from "./Pricing";
import General from "./General";
import CreditRatings from "./CreditRatings";
import CostsAndLosses from "./CostsAndLosses";

const GlobalModelVariables: React.FC = () => {
  const [country, setCountry] = useState<API.Country>("australia");
  const callback = useCallback(() => {
    return AdminAPI.getGlobalModelVariables();
  }, []);

  const { data, inProgress, error, refresh } = useMemoRequest(callback);

  const handleCountryChange = useCallback(
    (selected: { value: API.Country }) => {
      setCountry(selected.value);
    },
    [],
  );

  const selectedData = useMemo(() => {
    return data?.find((d) => d.country === country);
  }, [country, data]);

  type DataType = Omit<API.GlobalModelVariablesResponse, "id" | "country">;

  const handleChange = useCallback(
    <K extends keyof DataType>(keyField: K) =>
      <T extends keyof DataType[K]>(
        key: T,
        field?: undefined | FieldsOf<DataType[K], T>,
      ) =>
      async (value: number) => {
        if (!data) {
          return null;
        }
        const payload = [...data].map((d) => {
          if (d.country === selectedData?.country) {
            const dataToChange = d[keyField];
            const prevVal = dataToChange[key];
            const newVal =
              typeof prevVal === "object" && field
                ? {
                    ...(prevVal as any),
                    [field]: value,
                  }
                : value;

            return {
              ...d,
              [keyField]: {
                ...d[keyField],
                [key]: newVal,
              },
            };
          } else {
            return d;
          }
        });

        await AdminAPI.updateGlobalModelVariables(payload);
        refresh();
      },
    [data, selectedData?.country, refresh],
  );

  const handleChangeNested = useCallback(
    <K extends keyof DataType>(keyField: K) =>
      <T extends keyof DataType[K]>(
        key: T,
        subKey: FieldsOf<DataType[K], T>,
        field: FieldsOf<DataType[K][T], FieldsOf<DataType[K], T>>,
      ) =>
      async (value: number) => {
        if (!data) {
          return null;
        }
        const payload = [...data].map((d) => {
          if (d.country === selectedData?.country) {
            const dataToChange = d[keyField];
            const prevVal = dataToChange[key][subKey];
            const newVal =
              typeof prevVal === "object" && field
                ? {
                    ...(prevVal as any),
                    [field]: value,
                  }
                : value;

            return {
              ...d,
              [keyField]: {
                ...d[keyField],
                [key]: {
                  ...d[keyField][key],
                  [subKey]: newVal,
                },
              },
            };
          } else {
            return d;
          }
        });

        await AdminAPI.updateGlobalModelVariables(payload);
        refresh();
      },
    [data, refresh, selectedData?.country],
  );

  return (
    <VerticalGroup full className="py-2" wide>
      {inProgress && !selectedData && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {selectedData && (
        <VerticalGroup wide spaceBetweenElements={6} flexAuto>
          <VerticalGroup spaceBetweenElements={2}>
            <Text size="sm" bold>
              Financials And Metrics based on:
            </Text>
            <CountryDropdown onChange={handleCountryChange} value={country} />
          </VerticalGroup>
          <Accordion title="Balance Sheet" defaultState="collapse" wide>
            <BalanceSheet
              data={selectedData.balanceSheet}
              onChange={handleChange("balanceSheet")}
            />
          </Accordion>
          <Accordion title="Capital Treatment" defaultState="collapse" wide>
            <CapitalTreatment
              data={selectedData.capitalTreatment}
              onChange={handleChange("capitalTreatment")}
            />
          </Accordion>
          <Accordion title="Pricing" defaultState="collapse" wide>
            <Pricing
              data={selectedData.pricing}
              onChange={handleChange("pricing")}
            />
          </Accordion>
          <Accordion
            title="Other Market Variables"
            defaultState="collapse"
            wide
          >
            <OtherMarketVariables
              data={selectedData.otherMarketVariables}
              onChange={handleChange("otherMarketVariables")}
            />
          </Accordion>
          <Accordion title="General" defaultState="collapse" wide>
            <General
              data={selectedData.general}
              onChange={handleChange("general")}
            />
          </Accordion>
          <Accordion title="Credit Ratings" defaultState="collapse" wide>
            <CreditRatings
              data={selectedData.creditRatings}
              onChange={handleChange("creditRatings")}
            />
          </Accordion>
          <Accordion title="Costs And Losses" defaultState="collapse" wide>
            <CostsAndLosses
              data={selectedData.costsAndLosses}
              onChange={handleChange("costsAndLosses")}
              onChangeNested={handleChangeNested("costsAndLosses")}
            />
          </Accordion>
        </VerticalGroup>
      )}
    </VerticalGroup>
  );
};
export default GlobalModelVariables;
