import React, { useMemo } from "react";
import EnumDropdown from "../../../../../organisms/enum-dropdown/EnumDropdown";

interface Props {
  onChange: (selected: { value: string; label: string }) => void;
  value?: string;
  error?: string;
  label?: string;
  name?: string;
  scenariosData: API.EconomicScenarioResponse[] | null;
  small?: boolean;
}

const EconomySettingDropDown: React.FC<Props> = ({
  onChange,
  value,
  error,
  label,
  name,
  scenariosData,
  small,
}) => {
  const options = useMemo(() => {
    return (scenariosData ?? []).map((scenario) => ({
      value: scenario.id,
      label: scenario.name,
      description: scenario.description,
    }));
  }, [scenariosData]);

  return (
    <EnumDropdown
      small={small}
      onChange={onChange}
      options={options}
      value={value}
      error={error}
      label={label}
      name={name ?? "economy-settings-dropdown"}
    />
  );
};

export default EconomySettingDropDown;
