import React from "react";
import { useMemo } from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import { Field } from "./Fields/Fields";
import { OtherOptionField } from "./Fields/OtherOptionFIeld";
import { QuestionPreviewContainer } from "./QuestionPreviewContainer";

interface Props {
  group: API.AssessmentGroupResponse;
  question: API.AssessmentQuestionResponse;
  index: number;
  disableQuestionIndex?: boolean;
  answer?: API.AssessmentAnswerResponse["answers"][0]["answer"];
  isAnswerMode?: boolean;
}

export const CheckboxQuestionPreview: React.FC<Props> = ({
  index,
  group,
  question,
  disableQuestionIndex,
  answer,
  isAnswerMode = false,
}) => {
  const lastField = question.fields[question.fields.length - 1];
  const hasOtherOption = lastField.config.type === "written_response";

  const fieldsToRender = useMemo(() => {
    if (hasOtherOption) {
      return question.fields.slice(0, question.fields.length - 1);
    }
    return question.fields;
  }, [hasOtherOption, question.fields]);

  return (
    <QuestionPreviewContainer
      index={index}
      question={question}
      group={group}
      disableQuestionIndex={disableQuestionIndex}
      isAnswerMode={isAnswerMode}
    >
      <VerticalGroup spaceBetweenElements={2} wide>
        {fieldsToRender.map((field) => {
          return (
            <Field
              key={field.id}
              field={field}
              question={question}
              answer={answer}
              isAnswerMode={isAnswerMode}
            />
          );
        })}

        {hasOtherOption && lastField.type === "written_response" && (
          <OtherOptionField
            type="checkboxes"
            question={question}
            field={lastField}
            isAnswerMode={isAnswerMode}
          />
        )}
      </VerticalGroup>
    </QuestionPreviewContainer>
  );
};
