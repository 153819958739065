import React, { useCallback, useContext, useEffect } from "react";

import API from "../../../../../services/api";
import Banner from "../../../../atoms/banner/Banner";
import Form from "../../../../atoms/form/Form";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import { SimulationContext } from "../../context";
import useForm from "../../../../../hooks/useForm";
import TermFundingDropdown from "./TermFundingDropdown";
import CapitalManagementDropdown from "./CapitalManagementDropdown";
import Toggle from "../../../../atoms/toggle/Toggle";
import Input from "../../../../atoms/form/input/Input";
import EconomicScenarioDropdown from "../../../../organisms/economic-scenario-dropdown/EconomicScenarioDropdown";

const fieldNameMapper = {
  npsOpeningValue: "Opening value for NPS",
  complaintsOpeningValue: "Opening value for Complaints",
  riskIncidentsOpeningValue: "Opening value for Risk Incidents",
  employeeEngagementOpeningValue: "Opening value for Employee Engagement",
  communityTrustOpeningValue: "Opening value for Community Trust",
};

const Module: React.FC = () => {
  const { config, updateConfig, assignOnSubmitFunction, updateFormStatus } =
    useContext(SimulationContext);
  const [
    { formData, error, inProgress, formUpdated, fieldErrors },
    { setDropdownField, setFieldSimple, handleSubmit, setField },
  ] = useForm(
    config?.moduleSetup
      ? {
          ...config.moduleSetup,
        }
      : {
          fkScenarioId: "",
          termFunding: "automatic",
          capitalManagement: "automatic",
          operatingCostsEnabled: false,
          npsOpeningValue: 0,
          complaintsOpeningValue: 700,
          riskIncidentsOpeningValue: 750,
          employeeEngagementOpeningValue: 50,
          communityTrustOpeningValue: 50,
        },
    {
      fieldNameMapper,
      errorPathPrefix: "moduleSetup.",
    },
  );

  const {
    fkScenarioId,
    economy,
    termFunding,
    capitalManagement,
    operatingCostsEnabled,
    npsOpeningValue,
    complaintsOpeningValue,
    riskIncidentsOpeningValue,
    employeeEngagementOpeningValue,
    communityTrustOpeningValue,
  } = formData;

  const onSubmit = useCallback(async () => {
    if (config) {
      const payload: API.SimulationUpdateRequest = {
        moduleSetup: {
          fkScenarioId,
          economy,
          termFunding,
          capitalManagement,
          operatingCostsEnabled,
          npsOpeningValue: parseInt(npsOpeningValue),
          complaintsOpeningValue: parseInt(complaintsOpeningValue),
          riskIncidentsOpeningValue: parseInt(riskIncidentsOpeningValue),
          employeeEngagementOpeningValue: parseInt(
            employeeEngagementOpeningValue,
          ),
          communityTrustOpeningValue: parseInt(communityTrustOpeningValue),
        },
      };

      const response = await API.editSimulation(config.id, payload);

      updateConfig(response);
      return response;
    }
  }, [
    config,
    fkScenarioId,
    economy,
    termFunding,
    capitalManagement,
    operatingCostsEnabled,
    npsOpeningValue,
    complaintsOpeningValue,
    riskIncidentsOpeningValue,
    employeeEngagementOpeningValue,
    communityTrustOpeningValue,
    updateConfig,
  ]);

  useEffect(() => {
    assignOnSubmitFunction(handleSubmit(onSubmit));
  }, [assignOnSubmitFunction, handleSubmit, onSubmit]);

  useEffect(() => {
    updateFormStatus({
      inProgress: inProgress,
      formUpdated: formUpdated,
    });
  }, [updateFormStatus, inProgress, formUpdated]);

  return (
    <div className="step-container">
      <VerticalGroup spread full>
        <VerticalGroup wide>
          <Banner type="error" active={!!error} message={error?.message} />
          <Form id="simulation-add-form">
            <h3>Treasury Settings</h3>
            <TermFundingDropdown
              label="Term Funding"
              onChange={setDropdownField("termFunding")}
              value={termFunding}
            />
            <CapitalManagementDropdown
              label="Capital Management"
              onChange={setDropdownField("capitalManagement")}
              value={capitalManagement}
            />
            <h3>Other Settings</h3>
            <Toggle
              label="Operating Costs"
              helpTitle="Operating Costs"
              helpDescription="This allows participants to adjust investment and resourcing in 4 functional divisions. This will impact financial and non-financial outcomes. e.g Increased Risk & Compliance FTE to reduce unresolved risk incidents."
              checked={operatingCostsEnabled}
              onUpdate={setFieldSimple("operatingCostsEnabled")}
              block
            />
            <h3>Opening Values</h3>
            <Input
              autoFocus
              data-test="npsOpeningValue"
              type="text"
              label="NPS"
              value={npsOpeningValue}
              onChange={setField("npsOpeningValue")}
              error={fieldErrors.npsOpeningValue}
            />
            <Input
              autoFocus
              data-test="complaintsOpeningValue"
              type="text"
              label="Complaints"
              value={complaintsOpeningValue}
              onChange={setField("complaintsOpeningValue")}
              error={fieldErrors.complaintsOpeningValue}
            />
            <Input
              autoFocus
              data-test="riskIncidentsOpeningValue"
              type="text"
              label="Risk Incidents"
              value={riskIncidentsOpeningValue}
              onChange={setField("riskIncidentsOpeningValue")}
              error={fieldErrors.riskIncidentsOpeningValue}
            />
            <Input
              autoFocus
              data-test="employeeEngagementOpeningValue"
              type="text"
              label="Employee Engagement (%)"
              value={employeeEngagementOpeningValue}
              onChange={setField("employeeEngagementOpeningValue")}
              error={fieldErrors.employeeEngagementOpeningValue}
            />
            <Input
              autoFocus
              data-test="communityTrustOpeningValue"
              type="text"
              label="Community Trust (%)"
              value={communityTrustOpeningValue}
              onChange={setField("communityTrustOpeningValue")}
              error={fieldErrors.communityTrustOpeningValue}
            />
            <h3>Economy Settings</h3>
            <EconomicScenarioDropdown
              scenarioId={fkScenarioId}
              onScenarioSelected={setFieldSimple("fkScenarioId")}
            />
          </Form>
        </VerticalGroup>
      </VerticalGroup>
    </div>
  );
};

export { default as ModuleDisplay } from "./Display";
export default Module;
