import React from "react";

import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import Banner from "../../atoms/banner/Banner";
import { useSimulation } from "../../../hooks/useSimulation";
import SimulationPreviewWithData from "./SimulationPreviewWithData";

interface Props {
  simulationId: string;
}

const SimulationPreview: React.FC<Props> = ({ simulationId }) => {
  const { inProgress, error, data } = useSimulation(simulationId);

  return (
    <>
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {inProgress && <LoadingSpinner />}
      {!!data && !inProgress && <SimulationPreviewWithData simulation={data} />}
    </>
  );
};

export default SimulationPreview;
