import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import ReportingPagePreviewTile, {
  Props as TileProps,
} from "./ReportingPagePreviewTile";

interface Props extends TileProps {}

function SortableReportingPagePreviewTile(props: Props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.layout.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div id={props.layout.id} style={style} ref={setNodeRef}>
      <ReportingPagePreviewTile
        {...props}
        dndDragProps={{ ref: setActivatorNodeRef, attributes, listeners }}
      />
    </div>
  );
}

export default SortableReportingPagePreviewTile;
