import React from "react";
import classNames from "classnames";

import "./Image.scss";

import placeholder from "../../../assets/common/placeholder.png";
import { ThemeSpacing } from "../../../types/theme";
export interface ResponsiveSize {
  min: string;
  preferred: string;
  max: string;
}

export interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  className?: string;
  size?: ThemeSpacing;
  alt: string;
  shadow?: boolean;
  src: string | undefined;
  circle?: boolean;
  widthSize?: ThemeSpacing;
  heightSize?: ThemeSpacing;
  cover?: boolean;
  contain?: boolean;
  fill?: boolean;
  scaleDown?: boolean;
  dynamicSize?: ResponsiveSize;
  maxWidth?: string;
  maxHeight?: string;
}

function Image({
  size,
  src,
  className,
  alt,
  circle,
  shadow,
  widthSize,
  heightSize,
  cover,
  contain,
  fill,
  scaleDown,
  dynamicSize,
  maxWidth,
  maxHeight,
  ...rest
}: Props) {
  const sizeClass = size ? `size-${size}` : null;
  const widthClass = widthSize ? `width-${widthSize}` : null;
  const heightClass = heightSize ? `height-${heightSize}` : null;
  const maxWidthStyle = maxWidth ? { maxWidth } : {};
  const maxHeightStyle = maxHeight ? { maxHeight } : {};
  const responsiveStyle = dynamicSize
    ? {
        width: `clamp(${dynamicSize.min}, ${dynamicSize.preferred}, ${dynamicSize.max})`,
      }
    : {};
  return (
    <img
      className={classNames(
        "image",
        { shadow, circle, cover, contain, fill, "scale-down": scaleDown },
        sizeClass,
        widthClass,
        heightClass,
        className,
      )}
      alt={alt}
      src={src ?? placeholder}
      style={{ ...responsiveStyle, ...maxWidthStyle, ...maxHeightStyle }}
      {...rest}
    />
  );
}

export default Image;
