import React from "react";
// @ts-expect-error cant be bothered adding a declaration
import { DefaultTooltipContent } from "recharts/lib/component/DefaultTooltipContent";
import { NameType } from "recharts/types/component/DefaultTooltipContent";
import { ContentType } from "recharts/types/component/Tooltip";

const createCustomTooltip = (ordering: "highest" | "lowest", data?: any[]) => {
  const CustomTooltip: ContentType<any, NameType> = (props: any) => {
    // we don't need to check payload[0] as there's a better prop for this purpose
    if (!props.active) {
      // I think returning null works based on this: http://recharts.org/en-US/examples/CustomContentOfTooltip
      return null;
    }
    // mutating props directly is against react's conventions
    // so we create a new payload with the name and value fields set to what we want
    const newPayload =
      props.payload && props.payload.length > 0
        ? [
            ...props.payload.sort((a: any, b: any) => {
              if (ordering === "highest") {
                return b.value - a.value;
              } else {
                return a.value - b.value;
              }
            }),
          ]
        : [];

    let isLastRound = false;

    if (data && data.length > 0) {
      isLastRound = props.label === data[data.length - 1].name;
    }

    return (
      <div
        style={{
          fontSize: "clamp(0.5rem, 0.9vw, 2rem)",
          position: "relative",
          right: isLastRound ? "4em" : "0",
        }}
      >
        <DefaultTooltipContent
          {...props}
          payload={newPayload}
          itemStyle={{
            marginBottom: "4px",
          }}
          contentStyle={{
            padding: "25px",
            borderRadius: "4px",
            boxShadow:
              "0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)",
          }}
          labelStyle={{
            marginBottom: "15px",
            paddingBottom: "10px",
            borderBottom: "1px solid #ccc",
            fontWeight: "bold",
          }}
        />
      </div>
    );
  };

  return CustomTooltip;
};

export default createCustomTooltip;
