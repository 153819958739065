import React, { useCallback, useMemo } from "react";
import { Redirect } from "react-router-dom";
import Button from "../../atoms/button/Button";
import Input from "../../atoms/form/input/Input";
import useForm from "../../../hooks/useForm";
import API from "../../../services/api";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import DisplayField from "../../atoms/form/display-field/DisplayField";
import Form, { FormRequiredText } from "../../atoms/form/Form";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import Banner from "../../atoms/banner/Banner";
import ClientDropdown from "../../organisms/client-dropdown/ClientDropdown";
import UploadFile from "../../organisms/upload-file/upload-file";
import Image from "../../atoms/image/Image";
import {
  ColorSection,
  ColourPopupContainer,
  ColourRow,
} from "./BrandingColorSection";
import { BrandingPreviewWrapped } from "../../organisms/brandingpreview/BrandingPreview";
import CancelButton from "../../atoms/form/actions/CancelButton";

interface Props {
  branding?: API.BrandingResponse;
  onSave: (data: API.BrandingRequest) => Promise<API.BrandingResponse>;
  onCancel: () => void;
}

function BrandingForm({ branding, onSave, onCancel }: Props) {
  const [
    { formData, inProgress, fieldErrors, error, data, completed, formUpdated },
    {
      setField,
      setMultiSelectField,
      handleSubmit,
      setFieldRaw,
      setFieldSimple,
    },
  ] = useForm(
    branding
      ? {
          ...branding,
        }
      : {
          clientIds: [],
          clientName: "",
          gameName: "",
          insideAppText: "",
          headingBackgroundColour: "#1E242B",
          mainBackgroundColour: "#F7F7F7",
          buttonBackgroundColour: "#38A9E4",
          headingTextColour: "#FFFFFF",
          mainTextColour: "#1E242B",
          buttonTextColour: "#FFFFFF",
          brandingImageId: null,
          brandingImageUrl: null,
          isModalOpen: false,
        },
  );

  const {
    clientIds,
    clientName,
    gameName,
    insideAppText,
    headingBackgroundColour,
    mainBackgroundColour,
    buttonBackgroundColour,
    headingTextColour,
    mainTextColour,
    buttonTextColour,
    brandingImageId,
    brandingImageUrl,
  } = formData;

  const callback = useCallback(() => {
    const payload = {
      clientIds,
      clientName: clientName.trim(),
      gameName: gameName.trim(),
      insideAppText: insideAppText.trim(),
      headingBackgroundColour,
      mainBackgroundColour,
      buttonBackgroundColour,
      headingTextColour,
      mainTextColour,
      buttonTextColour,
      brandingImageId,
    };
    return onSave(payload);
  }, [
    clientIds,
    clientName,
    gameName,
    insideAppText,
    headingBackgroundColour,
    mainBackgroundColour,
    buttonBackgroundColour,
    headingTextColour,
    mainTextColour,
    buttonTextColour,
    brandingImageId,
    onSave,
  ]);

  const validate = useCallback(() => {
    const errors = [];
    if (!clientIds.length) {
      errors.push({ field: "clientIds", message: "Select a client" });
    }
    if (!clientName) {
      errors.push({ field: "clientName", message: "Enter a client name" });
    }
    if (!gameName) {
      errors.push({ field: "gameName", message: "Enter a game name" });
    }
    if (!insideAppText) {
      errors.push({
        field: "insideAppText",
        message: "Enter the inside app text",
      });
    }
    if (!brandingImageId) {
      errors.push({
        field: "brandingImageId",
        message: "Please upload a logo",
      });
    }
    return errors;
  }, [clientIds, clientName, gameName, insideAppText, brandingImageId]);

  const handleLogo = useCallback(
    async (file) => {
      const response = await API.uploadBrandingImage(file!);
      setFieldRaw("brandingImageId", response.id);
      setFieldRaw("brandingImageUrl", response.imageUrl);
    },
    [setFieldRaw],
  );

  const onSubmit = useMemo(() => {
    return handleSubmit(callback, validate);
  }, [callback, handleSubmit, validate]);

  return (
    <VerticalGroup full spread>
      <VerticalGroup wide>
        <Banner type="error" active={!!error} message={error?.message} />
        {!!completed && !error && (
          <Redirect to={`/brandings/${data.id}/view`} />
        )}

        <InlineGroup evenWidthChildren block spaceBetweenElements={10}>
          <Form id="branding-add-form" onSubmit={onSubmit}>
            <FormRequiredText />
            <ClientDropdown
              label="Select Clients *"
              isMulti
              clientIds={clientIds}
              onChange={setMultiSelectField("clientIds")}
              error={fieldErrors.clientIds}
            />
            <Input
              data-test="clientName"
              type="text"
              label="Client Name *"
              value={clientName}
              onChange={setField("clientName")}
              error={fieldErrors.clientName}
            />
            <Input
              data-test="gameName"
              type="text"
              label="Game Name *"
              value={gameName}
              onChange={setField("gameName")}
              error={fieldErrors.gameName}
            />
            <Input
              data-test="insideAppText"
              type="text"
              label="In App Text *"
              value={insideAppText}
              onChange={setField("insideAppText")}
              error={fieldErrors.insideAppText}
              helpTitle="Inside App Text"
              helpDescription="This text appears in the top left-hand corner of the main simulation interface during game play. This is usually your client name, limit to 12 characters."
            />
            <DisplayField
              stretch
              label="Logo"
              value={
                <Image
                  className="br-2"
                  shadow
                  heightSize={20}
                  src={brandingImageUrl || branding?.brandingImage?.imageUrl}
                  alt="Simulation logo"
                />
              }
            />
            <UploadFile
              buttonText="Upload logo"
              label="Upload new logo"
              handleFileUpload={handleLogo}
              title="Select simulation logo"
              description="Should be at least 400x400 px"
              allowedType="image"
              errorMsg={fieldErrors.brandingImageId}
            />
            {!!branding && (
              <DisplayField label="Branding ID" value={branding.id} />
            )}
          </Form>
          <VerticalGroup>
            <h2>Preview: Participant Login Page</h2>
            <BrandingPreviewWrapped
              buttonBackgroundColour={buttonBackgroundColour}
              buttonTextColour={buttonTextColour}
              clientName={clientName}
              gameName={gameName}
              headingBackgroundColour={headingBackgroundColour}
              headingTextColour={headingTextColour}
              mainBackgroundColour={mainBackgroundColour}
              mainTextColour={mainTextColour}
              logoUrl={
                brandingImageUrl || branding?.brandingImage?.imageUrl || ""
              }
              maxContainerHeight={460}
            />
          </VerticalGroup>
        </InlineGroup>

        <VerticalGroup wide>
          <h2>Design Settings</h2>
          <InlineGroup block spread spaceBetweenElements={10}>
            <ColourPopupContainer>
              <ColorSection title="Fill Colours">
                <ColourRow
                  id="fill-heading-bg"
                  title="Heading & footer background"
                  color={headingBackgroundColour}
                  onChange={setFieldSimple("headingBackgroundColour")}
                />
                <ColourRow
                  id="fill-main-bg"
                  title="Main background"
                  color={mainBackgroundColour}
                  onChange={setFieldSimple("mainBackgroundColour")}
                />
                <ColourRow
                  id="fill-login-btn"
                  title="Login button"
                  color={buttonBackgroundColour}
                  onChange={setFieldSimple("buttonBackgroundColour")}
                />
              </ColorSection>
              <ColorSection title="Text Colours">
                <ColourRow
                  id="text-heading"
                  title="Heading & footer text"
                  color={headingTextColour}
                  onChange={setFieldSimple("headingTextColour")}
                />
                <ColourRow
                  id="text-main"
                  title="Main text"
                  color={mainTextColour}
                  onChange={setFieldSimple("mainTextColour")}
                />
                <ColourRow
                  id="text-login-btn"
                  title="Login button"
                  color={buttonTextColour}
                  onChange={setFieldSimple("buttonTextColour")}
                />
              </ColorSection>
            </ColourPopupContainer>
          </InlineGroup>
        </VerticalGroup>
      </VerticalGroup>
      <InlineGroup block spread className="mt-4">
        <CancelButton
          confirmModalProps={{
            confirmButtonText: "Save",
            inProgress: inProgress,
            showModal: formUpdated,
            onConfirm: onSubmit,
            onDiscard: onCancel,
          }}
          onClick={onCancel}
        />
        <Button
          data-test="save"
          type="submit"
          form="branding-add-form"
          wide
          inProgress={inProgress}
        >
          Save
        </Button>
      </InlineGroup>
    </VerticalGroup>
  );
}

export default BrandingForm;
