import React, { forwardRef } from "react";
import classNames from "classnames";

import "./Clickable.scss";

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  centered?: boolean;
  withHover?: boolean;
  alignBot?: boolean;
  as?: "div" | "button";
}

const Clickable = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { children, centered, alignBot, className, withHover, as, ...rest } =
    props;

  if (as === "div") {
    return (
      <div
        // @ts-expect-error  its fine
        ref={ref}
        // @ts-expect-error  its fine
        onClick={rest.onClick}
        className={classNames(
          "clickable",
          { centered, withHover, alignBot },
          className,
        )}
      >
        {children}
      </div>
    );
  }

  return (
    <button
      ref={ref}
      className={classNames(
        "clickable",
        { centered, withHover, alignBot },
        className,
      )}
      type="button"
      {...rest}
    >
      {children}
    </button>
  );
});

Clickable.displayName = "Clickable";

export default Clickable;
