import React from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import Group from "./Group";
import { FieldsOf } from "./types";
import Toggle from "../../../../atoms/toggle/Toggle";
import GloInput, { DataProps } from "./GloInput";

type DataType = API.GlobalModelVariablesResponse["capitalTreatment"];

interface Props {
  data: DataType;
  onChange: <T extends keyof DataType>(
    key: T,
    field?: undefined | FieldsOf<DataType, T>,
  ) => (selected: any) => void;
}

const financialRatios: Array<DataProps<DataType, "financialRatios">> = [
  {
    label: "Cash % of Deposits",
    field: "cashPercentOfDeposits",
    type: "percentage",
    step: 10,
  },
  {
    label: "Retained Earning Mix",
    field: "retainedEarningsMix",
    type: "percentage",
    step: 10,
  },
  {
    label: "Common Equity Tier 1 Ratio",
    field: "commonEquityTier1Ratio",
    type: "percentage",
    step: 10,
  },
  { label: "Deposits Mix", field: "depositsMix", type: "percentage", step: 10 },
];

const assetRw: Array<DataProps<DataType, "assetRiskWeightings">> = [
  {
    label: "Cash / Liquid Assets",
    field: "cash",
    type: "percentage",
    step: 10,
  },
  { label: "Home Loans", field: "homeLoans", type: "percentage", step: 10 },
  {
    label: "Business Lending",
    field: "businessLoans",
    type: "percentage",
    step: 10,
  },
  {
    label: "Wealth Management",
    field: "wealthManagement",
    type: "percentage",
    step: 10,
  },
  { label: "Credit Cards", field: "creditCards", type: "percentage", step: 10 },
  {
    label: "Institutional Lending",
    field: "institutionalLending",
    type: "percentage",
    step: 10,
  },
  {
    label: "Offshore Bank",
    field: "offshoreBank",
    type: "percentage",
    step: 10,
  },
  {
    label: "Other Fixed Assets",
    field: "otherFixedAssets",
    type: "percentage",
    step: 10,
  },
  { label: "Investments", field: "investments", type: "percentage", step: 10 },
];

const regulatoryCapitalDeductions: Array<{
  label: string;
  field: keyof DataType["regulatoryCapitalDeductions"];
}> = [
  { label: "Cash / Liquid Assets", field: "cashLiquidAssets" },
  { label: "Home Loans", field: "homeLoans" },
  { label: "Business Lending", field: "businessLoans" },
  { label: "Wealth Management", field: "wealthManagement" },
  { label: "Credit Cards", field: "creditCards" },
  { label: "Institutional Lending", field: "institutionalLending" },
  { label: "Offshore Bank", field: "offshoreBank" },
  { label: "Other Fixed Assets", field: "otherFixedAssets" },
  { label: "Investments", field: "investments" },
];

const CapitalTreatment: React.FC<Props> = ({ data, onChange }) => {
  return (
    <VerticalGroup wide spaceBetweenElements={3} className="p-2">
      <Group title="Financial Ratios">
        {financialRatios.map(({ field, ...rest }) => (
          <GloInput
            key={field}
            value={data.financialRatios[field]}
            onChange={onChange("financialRatios", field)}
            {...rest}
          />
        ))}
      </Group>
      <Group title="Asset Risk Weightings">
        {assetRw.map(({ field, ...rest }) => (
          <GloInput
            key={field}
            value={data.assetRiskWeightings[field]}
            onChange={onChange("assetRiskWeightings", field)}
            {...rest}
          />
        ))}
      </Group>
      <Group title="Regulatory Capital Deductions">
        {regulatoryCapitalDeductions.map(({ label, field }) => (
          <Toggle
            key={field}
            label={label}
            checked={data.regulatoryCapitalDeductions[field]}
            onUpdate={onChange("regulatoryCapitalDeductions", field)}
          />
        ))}
      </Group>
      <Group title="Operational Risk">
        <GloInput
          label="Operational Risk (% of Credit Risk)"
          value={data.operationalRisk}
          onChange={onChange("operationalRisk")}
          step={10}
          type="percentage"
        />
      </Group>
    </VerticalGroup>
  );
};
export default CapitalTreatment;
