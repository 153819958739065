import React, { useCallback, useState, useEffect, useMemo } from "react";

import API from "../../../../../services/api";
import { useSimulationContext } from "../../context";
import useForm from "../../../../../hooks/useForm";
import BusinessLabels from "./BusinessLabels";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import Ribbon from "../../../../atoms/ribbon/Ribbon";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import api from "../../../../../services/api";
import LabelsByType from "./LabelsByType";

type Tab = "business" | API.LabelTypes;

const IS_DND_ENABLED = true;

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  if (value === null || value === undefined) return false;
  return true;
}

const Labels: React.FC = () => {
  const { config, updateConfig, updateFormStatus, assignOnSubmitFunction } =
    useSimulationContext();
  const [
    {
      formData: { businessConfig, labelConfig, mainMenuOrder },
      inProgress,
      formUpdated,
    },
    { setFieldRaw, handleSubmit },
  ] = useForm(
    config
      ? {
          businessConfig: config.businessConfig,
          labelConfig: config.labelConfig,
          mainMenuOrder: config.mainMenuOrder,
        }
      : {},
  );

  const [tab, setTab] = useState<Tab>("business");

  const handleBusinessContentUpdate = useCallback(
    async (businessId: string, data: Partial<API.BusinessConfigResponse>) => {
      if (config?.businessConfig) {
        const payload: API.SimulationUpdateRequest = {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          businessConfig: config?.businessConfig.map((bc) => {
            if (bc.id !== businessId) {
              return bc;
            }
            return {
              ...bc,
              ...data,
            };
          }),
        };

        const response = await api.editSimulation(config.id, payload);

        updateConfig(response);
      }
    },
    [config, updateConfig],
  );

  const handleLabelContentUpdate = useCallback(
    async (labelId: string, data: Partial<API.LabelConfigResponse>) => {
      if (config?.labelConfig) {
        const payload: API.SimulationUpdateRequest = {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          labelConfig: config?.labelConfig.map((lc) => {
            if (lc.id !== labelId) {
              return lc;
            }
            return {
              ...lc,
              ...data,
            };
          }),
        };

        const response = await api.editSimulation(config.id, payload);

        updateConfig(response);
      }
    },
    [config, updateConfig],
  );

  const onMainMenuItemOrderChanged = useCallback(
    (items: API.LabelConfigResponse[]) => {
      setFieldRaw(
        "mainMenuOrder",
        items.map(
          (item, index): API.MainMenuOrder => ({
            labelConfigId: item.id,
            sort: index,
          }),
        ),
      );
    },
    [setFieldRaw],
  );

  const sortByArray = useMemo(() => {
    const clone = Array.from(mainMenuOrder as API.MainMenuOrder[]);
    clone.sort((a, b) => a.sort - b.sort);
    return clone
      .map((order) => {
        return (labelConfig as API.LabelConfigResponse[]).find(
          (label) => label.id === order.labelConfigId,
        )?.name;
      })
      .filter(notEmpty);
  }, [mainMenuOrder, labelConfig]);

  const onSubmit = useCallback(async () => {
    if (config && config.id) {
      const payload: API.SimulationUpdateRequest = {
        mainMenuOrder,
      };

      const response = await API.editSimulation(config.id, payload);
      updateConfig(response);
      return response;
    }
  }, [config, mainMenuOrder, updateConfig]);

  useEffect(() => {
    assignOnSubmitFunction(handleSubmit(onSubmit));
  }, [assignOnSubmitFunction, handleSubmit, onSubmit]);

  useEffect(() => {
    updateFormStatus({
      inProgress: inProgress,
      formUpdated: formUpdated,
    });
  }, [updateFormStatus, inProgress, formUpdated]);

  return (
    <VerticalGroup full wide style={{ flex: "auto", overflowY: "hidden" }}>
      <InlineGroup spaceBetweenElements={2} className="h-16" block>
        <Ribbon
          first
          active={tab === "business"}
          title="Business"
          onClick={() => setTab("business")}
          className="h-16"
        />
        <Ribbon
          active={tab === "main_menu"}
          title="Main Menu"
          onClick={() => setTab("main_menu")}
          className="h-16"
        />
        <Ribbon
          last
          active={tab === "team"}
          title="Team"
          onClick={() => setTab("team")}
          className="h-16"
        />
      </InlineGroup>
      {tab === "business" && (
        <BusinessLabels
          businesses={businessConfig}
          onUpdateContent={handleBusinessContentUpdate}
        />
      )}
      {tab === "main_menu" && (
        <LabelsByType
          isDNDEnabled={IS_DND_ENABLED}
          onUpdateContent={handleLabelContentUpdate}
          onItemsOrderChanged={onMainMenuItemOrderChanged}
          type={tab}
          items={labelConfig}
          sortBy={sortByArray}
        />
      )}
      {tab === "team" && (
        <LabelsByType
          onUpdateContent={handleLabelContentUpdate}
          type={tab}
          items={labelConfig}
        />
      )}
    </VerticalGroup>
  );
};

export { default as MetadataDisplay } from "./Display";

export default Labels;
