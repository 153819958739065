import React from "react";
import Card from "../../../atoms/card/Card";
import RoundResultsContainer from "../components/RoundsResultsContainer";

import EditableReportingHeading from "../components/EditableReportingHeading";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Icon from "../../../atoms/icon/Icon";
import ResultsText from "../results-text/ResultsText";
import "./IntroConfuciusSlide.scss";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";

const IntroConfuciusSlide = () => {
  return (
    <RoundResultsContainer>
      <Card wide fullHeight>
        <VerticalGroup wide center full spread>
          <EditableReportingHeading defaultHeading="Banking Simulation" />
          <InlineGroup
            block
            fullHeight
            center
            verticalCenter
            className="quote-container"
          >
            <InlineGroup
              block
              fullHeight
              center
              verticalCenter
              className="icon-wrapper"
            >
              <Icon
                type="confucius"
                dynamicSize={{
                  min: "100px",
                  preferred: "40vw",
                  max: "1400px",
                }}
              />
            </InlineGroup>
            <VerticalGroup center verticalCenter className="quotes-overlay">
              <ResultsText size="lg">I hear and I forget</ResultsText>
              <ResultsText size="lg">I see and I remember</ResultsText>
              <ResultsText size="xl" bold>
                I do and I understand
              </ResultsText>
              <ResultsText right className="pt-8" italic size="lg" bold>
                Confucius 450 BC
              </ResultsText>
            </VerticalGroup>
          </InlineGroup>
        </VerticalGroup>
      </Card>
    </RoundResultsContainer>
  );
};

export default IntroConfuciusSlide;
