import React, { useMemo } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";
import { percentage } from "../utils/formatters";
import getTeamIdsAsFields from "../utils/getTeamIdsAsFields";
import getTeamName from "../utils/getTeamName";
import { pick } from "dot-object";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.MarketShareResponse["marketShare"];
  teams: API.GameConnection[];
}

const transformTeamValuesArray = (
  teamValues: { id: number; value: number }[],
) => {
  return teamValues.reduce<{ [team: string]: number }>((acc, teamValue) => {
    acc[teamValue.id] = teamValue.value;
    return acc;
  }, {});
};

const ProjectImpacts: React.FC<Props> = ({ configuration, data, teams }) => {
  const { numberOfTeams, numberOfCpuTeams } = configuration;

  const totalNumberOfTeams = numberOfTeams + numberOfCpuTeams;
  const fields = useMemo(
    () => getTeamIdsAsFields(totalNumberOfTeams),
    [totalNumberOfTeams],
  );

  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <TableWithSections
          data={[
            {
              name: "Front Line Workforce Productivity",
              fields: fields,
              fieldFormatter: (field: string) =>
                fields.includes(field)
                  ? getTeamName(parseInt(field), numberOfTeams, teams)
                  : null,
              values: data.map((d) => ({
                ...transformTeamValuesArray(
                  pick("projectImpacts.frontLineWorkforceProductivity", d),
                ),
              })),
              formatter: (val: number) => percentage(val),
            },
            {
              name: "Front Line Workforce Productivity Impact",
              fields: fields,
              fieldFormatter: (field: string) =>
                fields.includes(field)
                  ? getTeamName(parseInt(field), numberOfTeams, teams)
                  : null,
              values: data.map((d) => ({
                ...transformTeamValuesArray(
                  pick(
                    "projectImpacts.frontLineWorkforceProductivityImpact",
                    d,
                  ),
                ),
              })),
              formatter: (val: number) => percentage(val),
            },
            {
              name: "Community Trust Relative To Baseline",
              fields: fields,
              fieldFormatter: (field: string) =>
                fields.includes(field)
                  ? getTeamName(parseInt(field), numberOfTeams, teams)
                  : null,
              values: data.map((d) => ({
                ...transformTeamValuesArray(
                  pick("projectImpacts.communityTrustRelativeToBaseline", d),
                ),
              })),
              formatter: (val: number) => percentage(val),
            },
          ]}
        />
      </VerticalGroup>
    </>
  );
};

export default ProjectImpacts;
