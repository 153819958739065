import React, { useRef, useEffect, useState } from "react";
import { LabelProps } from "recharts";
import { TeamsWithColours } from "../RoundResultsPage";
export interface CustomLabelProps extends Omit<LabelProps, "value"> {
  value: { middleLabelValue?: number };
}

export interface BarChartData {
  [key: string]: string | number | null | React.FC<CustomLabelProps>;
  key: string;
  topLabel: React.FC<CustomLabelProps>;
  fill: string | null;
  name: string;
  raw?: any;
}

interface ResponsiveRectangleProps {
  x: number;
  y: number;
  width: number;
  height: number;
}

const ResponsiveRectangle: React.FC<ResponsiveRectangleProps> = ({
  x,
  y,
  width,
  height,
}) => {
  const borderRadius = 8;
  const borderColor = "grey";
  const borderWidth = 1;

  return (
    <rect
      x={x}
      y={y - height / 2}
      width={width}
      height={height}
      fill="#fff"
      rx={borderRadius}
      ry={borderRadius}
      stroke={borderColor}
      strokeWidth={borderWidth}
    />
  );
};

export const createBarChartMiddleLabel = (
  labelFormatter: (val: number) => string,
  teams: TeamsWithColours[],
) => {
  const BarChartTopLabel: React.FC<CustomLabelProps> = ({
    x,
    y,
    width,
    height,
    value,
  }) => {
    const [textDimensions, setTextDimensions] = useState<{
      width: number;
      height: number;
    }>({ width: 0, height: 0 });
    const textRef = useRef<SVGTextElement | null>(null);

    useEffect(() => {
      if (textRef.current) {
        const bbox = textRef.current.getBBox();
        setTextDimensions({ width: bbox.width, height: bbox.height });
      }
    }, [value]);

    if (value == null) {
      return null;
    }

    const valueToUse = value.middleLabelValue ?? 0;
    const xToUse = Number(x) ?? 0;
    const yToUse = Number(y) ?? 0;
    const widthToUse = Number(width) ?? 0;
    const heightToUse = Number(height) ?? 0;

    const paddingVertical = 8;
    const paddingHorizontal =
      teams.length <= 6 ? 14 : Math.max(4, 14 - (teams.length - 6) * 2);
    const rectangleWidth = textDimensions.width + paddingHorizontal * 2;
    const rectangleHeight =
      Math.max(textDimensions.height, 20) + paddingVertical * 2;

    return (
      <g>
        <ResponsiveRectangle
          x={xToUse + (widthToUse - rectangleWidth) / 2}
          y={yToUse + heightToUse / 2}
          width={rectangleWidth}
          height={rectangleHeight}
        />
        <text
          ref={textRef}
          x={xToUse + widthToUse / 2}
          y={yToUse + heightToUse / 2}
          fill="#000"
          textAnchor="middle"
          dominantBaseline="central"
          fontWeight="bold"
          fontSize="clamp(0.25rem, calc(0.25rem + 1.2vw), 4rem)"
        >
          {labelFormatter(valueToUse)}
        </text>
      </g>
    );
  };

  return BarChartTopLabel;
};
