import React from "react";

import EnumDropdown, {
  getValueFunc,
} from "../../../../organisms/enum-dropdown/EnumDropdown";

interface Props {
  onChange: (selected: { value: string; label: string }) => void;
  value?: string;
  error?: string;
  label?: string;
  name?: string;
}

export const options = [
  "BBSW",
  "NZD_BBSW",
  "Fed_Funds_Rate",
  "LIBOR",
  "EURIBOR",
  "JIBOR",
  "TIBOR",
  "SHIBOR",
  "SIBOR",
].map((currency) => {
  if (currency === "EURIBOR") {
    return {
      value: "EUIRBOR",
      label: "EURIBOR",
    };
  }
  return {
    value: currency,
    label: currency.replace(/_/g, " "),
  };
});

export const getLabelFromValue = getValueFunc(options);

function ReferenceRateDropdown({ onChange, value, error, label, name }: Props) {
  return (
    <EnumDropdown
      onChange={onChange}
      options={options}
      value={value}
      error={error}
      label={label}
      name={name ?? "reference-drate-dropdown"}
    />
  );
}

export default ReferenceRateDropdown;
