import React from "react";
import classNames from "classnames";

import LoadingSpinner from "../loadingspinner/LoadingSpinner";
import "./Button.scss";
import { ThemeColours } from "../../../types/theme";

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
  outline?: boolean;
  danger?: boolean;
  noUi?: boolean;
  light?: boolean;
  wide?: boolean;
  inProgress?: boolean;
  block?: boolean;
  warning?: boolean;
  large?: boolean;
  bold?: boolean;
  colour?: ThemeColours;
  mini?: boolean;
  noMinWidth?: boolean;
  link?: boolean;
  shadow?: boolean;
  border?: boolean;
  rounded?: boolean;
  smallFont?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  Button.displayName = "Button";
  const {
    children,
    className,
    outline,
    danger,
    noUi,
    bold,
    light,
    wide,
    block,
    inProgress,
    warning,
    large,
    colour,
    mini,
    link,
    shadow,
    border,
    rounded,
    smallFont,
    ...rest
  } = props;
  const colourClass = colour ? `colour-${colour}` : null;
  return (
    <button
      className={classNames(
        "button",
        {
          outline,
          danger,
          noUi,
          bold,
          light,
          wide,
          block,
          warning,
          large,
          mini,
          link,
          shadow,
          border,
          rounded,
          smallFont,
        },
        colourClass,
        [className],
      )}
      ref={ref}
      type="button"
      disabled={rest.disabled || !!inProgress}
      {...rest}
    >
      {inProgress && <LoadingSpinner size={20} />}
      {!inProgress && children}
    </button>
  );
});

export default Button;
