import React, { useCallback } from "react";
import useAPIRequest from "../../../../../hooks/useAPIRequest";
import API from "../../../../../services/api";
import ConfirmModal from "../../../../organisms/confirm-modal/ConfirmModal";
import { useTabletConfigContext } from "../context/context";

interface ClearConfigForEventProps {
  isOpen: boolean;
  eventId: string;
  onClose: () => void;
  onConfirm?: () => void;
}

function ClearConfigForEvent({
  isOpen,
  eventId,
  onClose,
  onConfirm,
}: ClearConfigForEventProps) {
  const { data, eventData, clearEvent } = useTabletConfigContext();

  const event = eventData[eventId];

  const callback = useCallback(async () => {
    if (!data) return;
    const updatedMappings: API.TabletUpdateRequest = {
      teams: data.teams.filter((t) => t.eventId !== eventId),
      tablets: data.tablets.map((tablet) => ({
        tabletId: tablet.tabletId,
        participantId: tablet.eventId === eventId ? null : tablet.participantId,
      })),
    };
    await API.updateTabletMappings(updatedMappings);
    clearEvent(eventId);
  }, [data, eventId, clearEvent]);

  const [{ inProgress, error }, doAPIRequest] = useAPIRequest(callback, null);

  const handleConfirm = useCallback(() => {
    doAPIRequest(onConfirm);
  }, [doAPIRequest, onConfirm]);

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      title="Delete Tablet Configuration?"
      description={
        <>
          Are you sure you want to wipe tablet configuration for {event?.name}
          <br /> <strong> This action cannot be reverted.</strong>
        </>
      }
      confirmTitle="Delete"
      error={error && error.message}
      warning
    />
  );
}

export default ClearConfigForEvent;
