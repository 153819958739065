import React, { useCallback, useEffect, useMemo } from "react";

import useAPIRequest from "../../../hooks/useAPIRequest";
import { formatPersonName } from "../../../lib/formatters";
import API from "../../../services/api";

import Text from "../../atoms/text/Text";
import Dropdown from "../../atoms/form/input/Dropdown";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import { formatUserType } from "../../pages/administration/users/lib/formatters";

interface Props {
  userId?: string;
  userIds?: string[];
  onChange: (selectedValue: { value: string; label: string }) => void;
  error?: string;
  label?: string;
  isMulti?: boolean;
  types?: Array<API.Role>;
  noBorder?: boolean;
  placeholder?: string;
  colour?: string;
}

function UserDropdown({
  userId,
  userIds,
  onChange,
  error,
  label,
  isMulti,
  types,
  noBorder,
  placeholder,
  colour,
}: Props) {
  const callback = useCallback(() => {
    return API.getUsers({ type: types ?? [] });
  }, [types]);

  const [{ inProgress, data }, doAPIRequest] = useAPIRequest<
    API.UserResponse[]
  >(callback, null);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  const options = useMemo(() => {
    return data
      ? data.map((user) => ({
          id: user.id,
          value: user.id,
          label: formatPersonName(user),
          formattedLabel: (
            <InlineGroup spread>
              <Text>{formatPersonName(user)}</Text>
              <Text>{formatUserType(user.type)}</Text>
            </InlineGroup>
          ),
        }))
      : [];
  }, [data]);

  const defaultValue = useMemo(() => {
    const value = null;
    if (isMulti) {
      if (userIds) {
        return options.filter((user) => userIds.includes(user.value));
      }
    } else {
      if (userId) {
        return options.find((user) => user.value === userId);
      }
    }
    return value;
  }, [userId, userIds, options, isMulti]);

  if (inProgress || !data) {
    return (
      <Dropdown
        key="loading-user-dropdown"
        isMulti={isMulti}
        data-test="user-dropdown-loading"
        label={label}
        error={error}
        noBorder={noBorder}
        colour={colour}
        selectProps={{
          isLoading: true,
          options: [],
          defaultValue: null,
          onChange: null,
          placeholder: "Loading users...",
        }}
      />
    );
  }

  return (
    <Dropdown
      data-test="user-dropdown"
      isMulti={isMulti}
      label={label}
      error={error}
      noBorder={noBorder}
      colour={colour}
      selectProps={{
        options: options,
        defaultValue: defaultValue,
        onChange: onChange,
        placeholder: placeholder,
        classNamePrefix: "user-dropdown",
      }}
    />
  );
}

export default UserDropdown;
