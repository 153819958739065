import React, { useMemo, useCallback } from "react";
import classNames from "classnames";

import SimpleModal from "../../../../../organisms/standard-modal/SimpleModal";
import LoadingSpinner from "../../../../../atoms/loadingspinner/LoadingSpinner";
import ErrorModal from "../../../../../organisms/standard-modal/ErrorModal";
import useBrandings from "../../../../../../hooks/useBrandings";
import BrandingSelectOption from "./BrandingSelectionOption";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";

interface SelectModalProps {
  isOpen: boolean;
  onClose: () => void;
  clientId: string;
  onBrandingSelected: (branding: API.BrandingResponse) => void;
  "data-test"?: string;
}

const SelectModal: React.FC<SelectModalProps> = ({
  clientId,
  isOpen,
  onClose,
  onBrandingSelected,
  ...props
}) => {
  const { data, inProgress } = useBrandings();
  const onBranding = useCallback(
    (branding: API.BrandingResponse) => {
      onBrandingSelected(branding);
      onClose();
    },
    [onBrandingSelected, onClose],
  );

  const selectedData = useMemo(() => {
    if (!data) return [];
    return data?.filter((branding) => {
      return branding.clientIds.includes(clientId);
    });
  }, [data, clientId]);

  const size = "medium";

  if (!inProgress && selectedData.length === 0) {
    return (
      <ErrorModal
        isOpen={isOpen}
        onClose={onClose}
        title="No Branding Found"
        description="The selected client has not created any branding themes yet."
        {...props}
      />
    );
  }

  return (
    <SimpleModal
      size={size}
      isOpen={isOpen}
      showCloseButton
      onClose={onClose}
      isScrollable={true}
      {...props}
    >
      {inProgress && <LoadingSpinner />}
      {selectedData && (
        <VerticalGroup
          spaceBetweenElements={4}
          className={classNames("branding-select-container", `size-${size}`)}
        >
          {selectedData.map((branding) => (
            <VerticalGroup wide key={branding.id}>
              <BrandingSelectOption
                key={branding.id}
                data-test={`branding-option-${branding.id}`}
                branding={branding}
                onBrandingSelected={onBranding}
              />{" "}
              <hr />
            </VerticalGroup>
          ))}
        </VerticalGroup>
      )}
    </SimpleModal>
  );
};

export default SelectModal;
