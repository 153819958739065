import React, { useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import API from "../../../../services/api";
import useAPIRequest from "../../../../hooks/useAPIRequest";
import Container from "../../../atoms/page/Page";
import Image from "../../../atoms/image/Image";
import Input from "../../../atoms/form/input/Input";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import ClientToggle from "./ClientToggle";
import ClientDelete from "./ClientDelete";
import ActivateClient from "./ActivateClient";
import DisplayField from "../../../atoms/form/display-field/DisplayField";
import Form from "../../../atoms/form/Form";
import Text from "../../../atoms/text/Text";
import Banner from "../../../atoms/banner/Banner";
import IconButton from "../../../molecules/iconbutton/IconButton";
import ListView from "../../../organisms/list-view/ListView";
import { formatPersonName } from "../../../../lib/formatters";
import { formatUserType } from "../users/lib/formatters";

interface ClientState {
  clientToggleModalOpen: boolean;
  deleteModalOpen: boolean;
}

function ClientDetails() {
  const { clientId } = useParams<{ clientId: string }>();
  const history = useHistory();

  const callback = useCallback(() => {
    return API.getClient(clientId);
  }, [clientId]);

  const [{ inProgress, data, error }, doAPIRequest] =
    useAPIRequest<API.ClientResponse>(callback, null);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  const [{ clientToggleModalOpen, deleteModalOpen }, setClientState] =
    useState<ClientState>({
      clientToggleModalOpen: false,
      deleteModalOpen: false,
    });

  const onClientToggleComplete = useCallback(() => {
    setClientState((prevState) => ({
      ...prevState,
      clientToggleModalOpen: false,
    }));
    history.push("/administration/clients");
  }, [history]);

  const onClientDeleteComplete = useCallback(() => {
    setClientState((prevState) => ({ ...prevState, deleteModalOpen: false }));
    history.push("/administration/clients");
  }, [history]);

  return (
    <>
      {clientToggleModalOpen && !!data && (
        <ClientToggle
          isOpen={clientToggleModalOpen}
          onClose={() =>
            setClientState((prevState) => ({
              ...prevState,
              clientToggleModalOpen: false,
            }))
          }
          onComplete={onClientToggleComplete}
          client={data}
        />
      )}
      {deleteModalOpen && !!data && (
        <ClientDelete
          isOpen={deleteModalOpen}
          onClose={() =>
            setClientState((prevState) => ({
              ...prevState,
              deleteModalOpen: false,
            }))
          }
          onComplete={onClientDeleteComplete}
          client={data}
        />
      )}

      <Container className="client-edit">
        {error && (
          <Banner type="error" active={!!error} message={error?.message} />
        )}
        <InlineGroup spread verticalCenter>
          <h2>Client Details</h2>
          {!!data && !inProgress && (
            <InlineGroup spread spaceBetweenElements={2}>
              <IconButton
                data-test={`edit-client-${clientId}`}
                icon="edit"
                text="Edit Client"
                linkTo={`/administration/clients/${clientId}/edit`}
              />
              {data.state === "active" ? (
                <IconButton
                  data-test={`toggle-client-state-${data.id}`}
                  icon="ban"
                  text="Deactivate Client"
                  onClick={() =>
                    setClientState((prevState) => ({
                      ...prevState,
                      clientToggleModalOpen: true,
                    }))
                  }
                />
              ) : (
                <ActivateClient client={data} />
              )}
              <IconButton
                danger
                data-test={`delete-client-${data.id}`}
                icon="trash"
                text="Delete"
                onClick={() =>
                  setClientState((prevState) => ({
                    ...prevState,
                    deleteModalOpen: true,
                  }))
                }
              />
            </InlineGroup>
          )}
        </InlineGroup>
        {inProgress && <LoadingSpinner />}
        {!!data && !inProgress && (
          <>
            <Form>
              <Input
                data-test="name"
                type="text"
                value={data.name}
                label="Client Name"
                readOnly
              />
              <DisplayField
                stretch
                label="Logo"
                value={
                  <Image
                    className="br-2"
                    shadow
                    heightSize={20}
                    src={data.logoUrl}
                    alt="Client logo"
                  />
                }
              />
              {!!data.users?.length && (
                <DisplayField
                  stretch
                  label="Users with access"
                  value={
                    <ListView height={56}>
                      {data.users.map((user) => (
                        <InlineGroup
                          key={user.id}
                          verticalCenter
                          data-test={`user-${user.id}`}
                          spread
                        >
                          <Text size="sm">{formatPersonName(user)}</Text>
                          <Text size="sm">{formatUserType(user.type)}</Text>
                        </InlineGroup>
                      ))}
                    </ListView>
                  }
                />
              )}
              <DisplayField label="Client ID" value={data.id} />
            </Form>
          </>
        )}
      </Container>
    </>
  );
}

export default ClientDetails;
