import React from "react";
import { fullDate } from "../../../lib/date";
import Label from "../../atoms/form/label/Label";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import Text from "../../atoms/text/Text";

interface Props {
  event: API.EventResponse | API.EventResponseSummary;
  tabletCount?: number;
  participantsCount?: number;
}

function PrintPreviewGameDetails({
  event,
  tabletCount,
  participantsCount,
}: Props) {
  return (
    <>
      <InlineGroup verticalCenter>
        <Label noShrink>
          <Text size="sm" bold>
            Client
          </Text>
        </Label>
        <Text size="sm">{event.client}</Text>
      </InlineGroup>
      <InlineGroup verticalCenter>
        <Label noShrink>
          <Text size="sm" bold>
            Event Name
          </Text>
        </Label>
        <Text size="sm">{event.name}</Text>
      </InlineGroup>
      <InlineGroup verticalCenter>
        <Label noShrink>
          <Text size="sm" bold>
            Event Date
          </Text>
        </Label>
        <Text size="sm">{fullDate(event.date)}</Text>
      </InlineGroup>
      {tabletCount !== undefined && (
        <InlineGroup verticalCenter>
          <Label noShrink>
            <Text size="sm" bold>
              Total Tablets
            </Text>
          </Label>
          <Text size="sm">{tabletCount}</Text>
        </InlineGroup>
      )}
      {participantsCount !== undefined && (
        <InlineGroup verticalCenter>
          <Label noShrink>
            <Text size="sm" bold>
              Total Participants
            </Text>
          </Label>
          <Text size="sm">{participantsCount}</Text>
        </InlineGroup>
      )}
    </>
  );
}

export default PrintPreviewGameDetails;
