import React, { useCallback } from "react";
import ConfirmModal from "../../organisms/confirm-modal/ConfirmModal";

import useAPIRequest from "../../../hooks/useAPIRequest";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => Promise<void>;
}

function StartGameModal({ isOpen, onClose, onComplete }: Props) {
  const [{ inProgress, error }, doAPIRequest] = useAPIRequest<void>(
    onComplete,
    null,
  );

  const handleConfirm = useCallback(() => {
    doAPIRequest(onComplete);
  }, [doAPIRequest, onComplete]);

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      title={`Confirm Start Game`}
      description={
        <>
          Starting the game will open up the simulation for all participants.{" "}
          <br /> <br />
          Press ok to start
        </>
      }
      confirmTitle="Ok"
      error={error && error.message}
      warning
    />
  );
}

export default StartGameModal;
