import React from "react";
import { Link } from "react-router-dom";
import Container from "../atoms/page/Page";
import "./Dashboard.scss";
import Theme from "../../styles/_theme.module.scss";
import Icon, { IconType } from "../atoms/icon/Icon";

interface DashboardElement {
  icon: IconType;
  classname: string;
  title: string;
  description: string;
  link: string;
  width?: number;
  height?: number;
}

const mapDashboardElems: DashboardElement[] = [
  {
    icon: "branding",
    classname: "branding",
    title: "Branding",
    description:
      "Create and edit some of the colours used in your simulation events so it aligns to your branding",
    link: "/brandings",
  },
  {
    icon: "assessments",
    classname: "assessments",
    title: "Assessments",
    description:
      "Create and edit surveys or assessments which participants can complete during each simulation round",
    link: "/assessments",
  },
  {
    icon: "simulations",
    classname: "simulations",
    title: "Simulations",
    description:
      "Configure and edit the content and complexity of the simulations your participants will experience",
    link: "/simulations",
  },
  {
    icon: "events",
    classname: "events",
    title: "Events",
    description:
      "Create and edit a simulation event. Invite participants, select the branding, assessments, and simulation for each event",
    link: "/events",
  },
  {
    icon: "gameController",
    classname: "game-controller",
    title: "Game Controller",
    description:
      "Start the simulation. Control when each round begins and ends",
    link: "/games",
    width: 10,
    height: 8,
  },
  {
    icon: "settings",
    classname: "settings",
    title: "Settings",
    description: "Access your profile settings",
    link: "/",
  },
];

function Dashboard() {
  return (
    <Container>
      <div className="dashboard-container">
        {mapDashboardElems.map((item) => (
          <div
            key={item.classname}
            className={`dashboard-card ${item.classname}`}
          >
            <Link data-test={`dashboard-to-${item.classname}`} to={item.link}>
              <div className="circle-icon">
                <div className="large-icon-container">
                  <Icon
                    width={item.width ?? undefined}
                    height={item.height ?? undefined}
                    type={item.icon}
                    fill={Theme.blue}
                    className="large-icon"
                  />
                  <Icon
                    width={item.width ?? undefined}
                    height={item.height ?? undefined}
                    type={item.icon}
                    fill={"white"}
                    className="large-icon"
                  />
                </div>
              </div>
            </Link>
            <div className="dashboard-title">{item.title}</div>
            <div className="dashboard-description">{item.description}</div>
          </div>
        ))}
      </div>
    </Container>
  );
}

export default Dashboard;
