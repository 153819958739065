export const formatSimulationState = (
  simulation: API.SimulationResponse,
): string => {
  switch (simulation.state) {
    case "draft":
      return "Draft";
    case "complete":
      return "Published";
  }
};
