import React, { useCallback } from "react";
import IntegerDropdown from "../integer-dropdown/IntegerDropdown";
import {
  formatBps,
  wsfConvertDivide,
} from "../../pages/administration/configuration/economicScenarioBuilder/calculations/utils";

interface Params {
  value: number;
  onChange: (value: number) => void;
  min: number;
  max: number;
  step: number;
  midsize?: boolean;
  label?: string;
  dp?: number;
}

const BpsDropdown = ({
  value,
  onChange,
  min,
  max,
  step,
  midsize,
  label,
  dp = 4,
}: Params) => {
  const onValueChange = useCallback(
    (option: { value: number }) => {
      onChange(option.value * 10000);
    },
    [onChange],
  );

  return (
    <IntegerDropdown
      value={Number(wsfConvertDivide(value / 100))}
      decimalPlaces={dp}
      onChange={onValueChange}
      min={min}
      max={max}
      step={step}
      small
      isCreatable
      labelFormatter={(val) => formatBps(val, dp)}
      midsize={midsize}
      label={label}
    />
  );
};

export default BpsDropdown;
