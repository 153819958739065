import React from "react";
import { GenericQuestionPreview } from "./GenericQuestionPreview";
import { RatingQuestionPreview } from "./RatingQuestionPreview";

import "./QuestionPreview.scss";
import { CheckboxQuestionPreview } from "./ChecckboxQuestionPreview";
import { MultpleChoiceQuestionPreview } from "./MultipleChoiceQuestionPreview";

interface Props {
  group: API.AssessmentGroupResponse;
  question: API.AssessmentQuestionResponse;
  index: number;
  disableQuestionIndex?: boolean;
  answer?: API.AssessmentAnswerResponse["answers"][0]["answer"];
  isAnswerMode?: boolean;
}

const PREVIEWS: Record<API.QuestionType, React.ComponentType<Props>> = {
  multiple_choices: MultpleChoiceQuestionPreview,
  written_response: GenericQuestionPreview,
  short_written_response: GenericQuestionPreview,
  checkboxes: CheckboxQuestionPreview,
  image: GenericQuestionPreview,
  rating: RatingQuestionPreview,
  written_text: GenericQuestionPreview,
};

export const QuestionPreview: React.FC<Props> = ({
  group,
  question,
  index,
  answer,
  isAnswerMode = false,
  disableQuestionIndex,
}) => {
  const QuestionPreviewComponent = PREVIEWS[question.type];
  return (
    <QuestionPreviewComponent
      group={group}
      question={question}
      index={index}
      answer={answer}
      disableQuestionIndex={disableQuestionIndex}
      isAnswerMode={isAnswerMode}
    />
  );
};
