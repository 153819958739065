import React from "react";
import classNames from "classnames";

import "./InlineError.scss";

interface Props {
  active: boolean;
  message?: string;
  style?: any;
  className?: string;
  block?: boolean;
}

function InlineError(props: Props) {
  return (
    <div
      className={classNames(
        "inline-error-wrapper",
        { hidden: !props.active, block: props.block },
        props.className,
      )}
    >
      <div data-test="error" className="error" style={props.style}>
        {props.message}
      </div>
    </div>
  );
}

export default InlineError;
