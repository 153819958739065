import React, { useMemo } from "react";
import { useCallback } from "react";
import { useMemoRequest } from "../../../../hooks/useMemoRequest";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import ModelAPI from "../../../../services/modelApi";
import { Accordion } from "../../../organisms/accordion/accordion";

import WholesaleFundingMovements from "./WholesaleFundingMovements/WholesaleFundingMovements";
import WholesaleFundingCosts from "./WholesaleFundingCosts/WholesaleFundingCosts";
import WholesaleFundingCalculations from "./WholesaleFundingCalculations/WholesaleFundingCalculations";
import RegulatoryCapital from "./RegulatoryCapital/RegulatoryCapital";
import ShareCapital from "./ShareCapital";
import ModelPageContainer from "../ModelPageContainer";
import useModelQuery from "../utils/useModelQuery";

interface Props {
  eventId: string;
  configuration: ModelAPI.ConfigurationResponse;
  selectedTeam: number;
  selectedRound: number;
}

const ModelTeamResults: React.FC<Props> = ({
  eventId,
  configuration,
  selectedTeam,
  selectedRound,
}) => {
  const { numberOfHistoricRounds, numberOfRounds, numberOfPostGameRounds } =
    configuration;
  const getTeamResults = useCallback(() => {
    return ModelAPI.getModelTeamResults(eventId, selectedRound, selectedTeam);
  }, [eventId, selectedRound, selectedTeam]);

  const { inProgress, data, error, refresh } =
    useMemoRequest<ModelAPI.TeamResultsResponse>(getTeamResults);

  const matrixFields = useMemo(() => {
    const fields: Array<{ field: string; name: string }> = [];
    const totalYears =
      numberOfHistoricRounds + numberOfRounds + numberOfPostGameRounds;
    for (let year = 0; year <= totalYears; year++) {
      fields.push({ field: `round${year}-CBF`, name: `Year ${year} - CBF` });
      fields.push({
        field: `round${year}-1Year`,
        name: `Year ${year} - 1 Yr Term`,
      });
      fields.push({
        field: `round${year}-2Year`,
        name: `Year ${year} - 2 Yr Term`,
      });
      fields.push({
        field: `round${year}-3Year`,
        name: `Year ${year} - 3 Yr Term`,
      });
      fields.push({
        field: `round${year}-4Year`,
        name: `Year ${year} - 4 Yr Term`,
      });
      fields.push({
        field: `round${year}-5Year`,
        name: `Year ${year} - 5 Yr Term`,
      });
    }
    return fields;
  }, [numberOfHistoricRounds, numberOfPostGameRounds, numberOfRounds]);

  const { expandIfSection } = useModelQuery();

  return (
    <ModelPageContainer
      eventId={eventId}
      selectedRound={selectedRound}
      data={data}
      refresh={refresh}
      error={error}
      inProgress={inProgress}
    >
      {!!data && (
        <VerticalGroup spaceBetweenElements={2} wide>
          <Accordion
            title="Wholesale Funding Movements"
            defaultState={expandIfSection("wsfMovements")}
            wide
          >
            <WholesaleFundingMovements
              configuration={configuration}
              data={data.wholesaleFundingMovements}
              fields={matrixFields}
            />
          </Accordion>
          <Accordion
            title="Wholesale Funding Costs"
            defaultState={expandIfSection("wsfCosts")}
            wide
          >
            <WholesaleFundingCosts
              configuration={configuration}
              data={data.wholesaleFundingCosts}
              fields={matrixFields}
            />
          </Accordion>
          <Accordion
            title="Wholesale Funding Calculations"
            defaultState={expandIfSection("wsfCalculations")}
            wide
          >
            <WholesaleFundingCalculations
              configuration={configuration}
              data={data.wholesaleFundingCalculations}
              fields={matrixFields}
            />
          </Accordion>
          <Accordion
            title="Regulatory Capital"
            defaultState={expandIfSection("regulatoryCapital")}
            wide
          >
            <RegulatoryCapital
              configuration={configuration}
              data={data.regulatoryCapital}
            />
          </Accordion>
          <Accordion
            title="Share Capital"
            defaultState={expandIfSection("shareCapital")}
            wide
          >
            <ShareCapital
              configuration={configuration}
              data={data.shareCapital}
            />
          </Accordion>
        </VerticalGroup>
      )}
    </ModelPageContainer>
  );
};

export default ModelTeamResults;
