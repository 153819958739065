import React from "react";

import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Image from "../../../atoms/image/Image";
import "./ResultsHeader.scss";
import ResultsText from "../results-text/ResultsText";
import useIsMobile from "../../../../hooks/useIsMobile";

interface Props {
  round: number;
  logo?: string;
  gameName?: string;
}

const ResultsHeader: React.FC<Props> = ({ logo, gameName, round }) => {
  const isMobile = useIsMobile();
  const heading = round === 0 ? "INTRODUCTION" : `ROUND ${round} SUMMARY`;
  return (
    <>
      <InlineGroup
        block
        className={
          isMobile ? "results-header-top-mobile" : "results-header-top"
        }
      ></InlineGroup>
      <InlineGroup block verticalCenter className="results-header-bottom">
        <div className="results-summary-header">
          <InlineGroup
            className={
              isMobile
                ? "results-summary-block-mobile"
                : "results-summary-block"
            }
            center
            verticalCenter
          >
            <ResultsText size={isMobile ? "sm" : "lg"} colour="white">
              {heading}
            </ResultsText>
          </InlineGroup>
        </div>
        <div className="results-logo-header">
          <InlineGroup
            verticalCenter
            spaceBetweenElements={2}
            style={{ float: "right" }}
            className="pr-2"
          >
            <Image
              dynamicSize={{ min: "20px", preferred: "1.5vw", max: "60px" }}
              src={logo}
              alt="default logo"
            ></Image>
            <ResultsText size="sm" className="w-fit" bold>
              {gameName}
            </ResultsText>
          </InlineGroup>
        </div>
      </InlineGroup>
    </>
  );
};

export default ResultsHeader;
