import React, { useCallback, useMemo } from "react";
import Text from "../../../../../atoms/text/Text";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import SquareIconButton from "../../../../../molecules/square-icon-button/SquareIconButton";
import Clickable from "../../../../../atoms/clickable/Clickable";
import Icon from "../../../../../atoms/icon/Icon";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import SimpleModal from "../../../../../organisms/standard-modal/SimpleModal";

interface Props {
  isOpen: boolean;
  onBuild: (uploadInitialResults: boolean) => void;
  onClose: () => void;
  inProgress?: boolean;
}

function BuildSimulationModal({ isOpen, onBuild, onClose, inProgress }: Props) {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleBuild = useCallback(
    ({ useAws }: { useAws: boolean }) =>
      () => {
        return onBuild(useAws);
      },
    [onBuild],
  );

  const steps = useMemo(
    () => [
      {
        id: "1",
        content: (
          <>
            <Text size="lg" bold center>
              Which model are you using to build the simulation?
            </Text>
            <InlineGroup spaceBetweenElements={16}>
              <div className="width-50-percent">
                <SquareIconButton
                  icon="excel"
                  label={"Excel"}
                  onClick={() => setActiveStep(1)}
                />
              </div>
              <div className="width-50-percent">
                <SquareIconButton
                  icon="aws"
                  label="AWS"
                  onClick={() => setActiveStep(2)}
                />
              </div>
            </InlineGroup>
          </>
        ),
      },
      {
        id: "2",
        content: (
          <>
            <InlineGroup verticalCenter spread block>
              <Clickable onClick={() => setActiveStep(0)}>
                <Icon type="leftChevron" noMargin />
              </Clickable>
              <Text size="lg" bold>
                Excel Model
              </Text>
              <Icon type="leftChevron" noMargin colour="white" />
            </InlineGroup>

            <Text size="md" lineHeight={6}>
              When using the Excel Model you use excel to do the following
              <ul>
                <li>Build the simulation</li>
                <li>Calculate Results</li>
                <li>Close Round</li>
              </ul>
              You can control the rounds by using the Game Controller
              <br />
              The AWS model will still be initialised but no data will be used
              in the Participants
            </Text>
            <InlineGroup spaceBetweenElements={16}>
              <SquareIconButton
                icon="build"
                label="Build"
                inProgress={inProgress}
                onClick={handleBuild({ useAws: false })}
              />
            </InlineGroup>
          </>
        ),
      },
      {
        id: "3",
        content: (
          <>
            <InlineGroup verticalCenter spread block>
              <Clickable onClick={() => setActiveStep(0)}>
                <Icon type="leftChevron" noMargin />
              </Clickable>
              <Text size="lg" bold>
                AWS Model
              </Text>
              <Icon type="leftChevron" noMargin colour="white" />
            </InlineGroup>

            <Text size="md" lineHeight={6}>
              When using the AWS Model everything is done using the Game
              Controller
              <br />
              You can control the rounds by using the Game Controller
              <br />
              All data shown to the Participants will use the AWS Model
            </Text>
            <InlineGroup spaceBetweenElements={16}>
              <SquareIconButton
                icon="build"
                label="Build"
                bgColour="yellow"
                colour="white"
                curved
                inProgress={inProgress}
                onClick={handleBuild({ useAws: true })}
              />
            </InlineGroup>
          </>
        ),
      },
    ],
    [handleBuild, inProgress],
  );

  return (
    <SimpleModal isOpen={isOpen} showCloseButton size="small" onClose={onClose}>
      <VerticalGroup spaceBetweenElements={8} wide center>
        {steps[activeStep].content}
      </VerticalGroup>
    </SimpleModal>
  );
}

export default BuildSimulationModal;
