import React, { useCallback } from "react";
import Card from "../../../atoms/card/Card";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import EditableReportingHeading from "../components/EditableReportingHeading";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import ResultsInfoCard from "../components/ResultsInfoCard";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import ResultsText from "../results-text/ResultsText";
import "./IntroGetToKnowYourBank.scss";
import { useResultsContext } from "../context/context";
import classNames from "classnames";
import { ThemeColours } from "../../../../types/theme";

interface NumberBadgeProps {
  number: number;
  text: string | null;
  badgeColour?: ThemeColours;
}

export const NumberBadge: React.FC<NumberBadgeProps> = ({
  number,
  text,
  badgeColour = "green",
}) => (
  <InlineGroup spaceBetweenElements={4} verticalCenter>
    <div className="question-badge-wrapper">
      <InlineGroup bgColour={badgeColour} className="question-circle-badge">
        <ResultsText colour="white" bold>
          {number}
        </ResultsText>
      </InlineGroup>
    </div>
    <ResultsText>{text}</ResultsText>
  </InlineGroup>
);

const questions = [
  "Which product is forecast to grow the fastest in the next 12 months?",
  "What is your bank's most recent NPAT (Net Profit After Tax) in $?",
  "What is your largest lending product in terms of balances?",
  "Which funding source provides the majority of your funding?",
  "How much does new 5-year wholesale funding cost your bank (%)?",
  "How much cash did your bank hold on the balance sheet last year ($)?",
  "What was the cost of deposits last year (%)?",
];

export const getToKnowYourBankMaxClicks = 2;

const IntroGetToKnowYourBank = () => {
  const context = useResultsContext();

  const onNext = useCallback(() => {
    if (context.clicks < getToKnowYourBankMaxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context]);

  const showQuestions = context.noAnimations || context.clicks >= 1;
  const hideInfoPointsStartAnimation = context.clicks >= 2;

  const infoPoints = hideInfoPointsStartAnimation
    ? []
    : [
        "Explore the interface",
        "Answer the following questions",
        "Prizes for correct answers",
      ];
  return (
    <RoundResultsContainer onNext={onNext}>
      <Card className="know-your-bank-slide" wide fullHeight>
        <VerticalGroup wide full spaceBetweenElements={4}>
          <InlineGroup block center>
            <EditableReportingHeading defaultHeading="Get To Know Your Bank" />
          </InlineGroup>
          <InlineGroup center width="55%">
            <ResultsText bold size="lg" colour="primaryDark">
              10 minutes for Simulation Exploration Time
            </ResultsText>
          </InlineGroup>
          <VerticalGroup wide full spread>
            <InlineGroup>
              <InlineGroup>
                <ResultsInfoCard
                  animateBadge={hideInfoPointsStartAnimation}
                  themeColour="green"
                  BadgeText="10m"
                  headerText="Simulation Interface Exploration"
                  infoPoints={infoPoints}
                />
              </InlineGroup>
              <VerticalGroup
                className={classNames("know-your-bank-questions", {
                  show: showQuestions,
                })}
                style={{ height: "95%" }}
                spread
              >
                {questions.map((question, index) => (
                  <NumberBadge key={index} number={index + 1} text={question} />
                ))}
              </VerticalGroup>
            </InlineGroup>
          </VerticalGroup>
        </VerticalGroup>
      </Card>
    </RoundResultsContainer>
  );
};

export default IntroGetToKnowYourBank;
