import React from "react";
import {
  CircularProgressbarWithChildren as Progress,
  buildStyles,
} from "react-circular-progressbar";

import Theme from "../../../../styles/_theme.module.scss";

import "react-circular-progressbar/dist/styles.css";
import Gradient from "./GradientProps";
import "./TimerProgress.scss";

interface Props {
  value: number;
  maxValue: number;
}

const TimerProgress: React.FC<Props> = ({ value, maxValue, children }) => {
  return (
    <>
      <Gradient
        endColor={Theme.blue}
        startColor="#50C4CD"
        idCSS="timergradient-normal"
        rotation={0}
      />
      <Gradient
        startColor={Theme.yellow}
        endColor="#FA6A50"
        idCSS="timergradient-warning"
        rotation={0}
      />
      <Gradient
        endColor={Theme.red}
        startColor="#8e263d"
        idCSS="timergradient-danger"
        rotation={0}
      />
      <Progress
        className={value <= 60 ? "danger" : value <= 300 ? "warning" : "normal"}
        value={value}
        maxValue={maxValue}
        strokeWidth={8}
        styles={buildStyles({
          strokeLinecap: "butt",
        })}
      >
        {children}
      </Progress>
    </>
  );
};

export default TimerProgress;
