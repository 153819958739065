import React from "react";
import VerticalGroup from "../../verticalgroup/VerticalGroup";
import InputContainer from "./InputContainer";
import Radio from "./Radio";

interface Props {
  label: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fields: Array<{
    label: string;
    value: string;
    checked: boolean;
  }>;
}

const RadioGroup: React.FC<Props> = ({ name, label, fields, onChange }) => {
  return (
    <InputContainer label={label} verticalCenter={false}>
      <VerticalGroup spaceBetweenElements={4}>
        {fields.map(({ label, value, checked }, i) => (
          <Radio
            key={i}
            onChange={onChange}
            name={name}
            label={label}
            value={value}
            checked={checked}
          />
        ))}
      </VerticalGroup>
    </InputContainer>
  );
};

export default RadioGroup;
