import { round } from "../../../../model/utils/formatters";

export const convertToPercentage = (val: number) => round(val * 100, 2);
export const wsfConvertDivide = (val: number, dp = 2) =>
  (val / 100).toFixed(dp);
export const wsfConvertDivide2 = (val: number, dp = 4) =>
  (val / 10000).toFixed(dp);
export const wsfConvertDividePercentage = (val: number) =>
  `${wsfConvertDivide(val)}%`;
export const wsfConvertDivideBps = (val: number) =>
  `${wsfConvertDivide(val)}bps`;
export const wsfConvertMultiply = (val: number) => (val * 100).toFixed(2);

export const bpsConvertMultiply = (val: number) => 10000 * val;
export const bpsConvertDivide = (val: number, dp?: number) => {
  const value = val / 10000;
  if (dp != null) {
    return round(value, dp);
  }
  return value;
};

export const formatToRating = (val: number) => `${(val / 100).toFixed(2)}x`;

export const convertBpsToPercentage = (val: number, dp?: number) => {
  return `${bpsConvertDivide(val, dp).toFixed(dp)}%`;
};

export const formatBps = (val: number, dp = 2) => `${val.toFixed(dp)} bps`;
export const formatToMultiplier = (val: number, multiplier = "x", dp = 2) =>
  `${val.toFixed(dp)}${multiplier}`;
export const formatPercentage = (val: number, dp = 2) => `${val.toFixed(dp)}%`;
