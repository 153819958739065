import { useCallback, useEffect, useMemo } from "react";
import API from "../services/api";
import useAPIRequest from "./useAPIRequest";

const MAX_AGE = 500; // ms
const CACHE = new Map<string, Promise<API.AssessmentGroupResponse>>();

export const useGroup = (assessmentId: string, groupId: string) => {
  const callback = useCallback(() => {
    let cachedPromise = CACHE.get(assessmentId);
    if (cachedPromise) {
      return cachedPromise;
    }

    cachedPromise = API.getAssessmentGroup(assessmentId, groupId);

    CACHE.set(groupId, cachedPromise);
    setTimeout(() => {
      CACHE.delete(groupId);
    }, MAX_AGE);

    return cachedPromise;
  }, [groupId, assessmentId]);

  const [{ inProgress, data, error }, doAPIRequest] =
    useAPIRequest<API.AssessmentGroupResponse>(callback, null);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  return useMemo(
    () => ({
      inProgress,
      data,
      error,
      refresh: doAPIRequest,
    }),
    [inProgress, data, error, doAPIRequest],
  );
};

export const useGroups = (assessmentId: string) => {
  const callback = useCallback(() => {
    return API.getAssessmentGroups(assessmentId);
  }, [assessmentId]);

  const [{ inProgress, data, error }, doAPIRequest] = useAPIRequest<
    API.AssessmentGroupResponse[]
  >(callback, null);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  return useMemo(
    () => ({
      inProgress,
      data,
      error,
      refresh: doAPIRequest,
    }),
    [inProgress, data, error, doAPIRequest],
  );
};
