import React, { useCallback, useEffect, useState } from "react";
import { useMemoRequest } from "../../../../hooks/useMemoRequest";
import api from "../../../../services/api";
import Banner from "../../../atoms/banner/Banner";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import { SelectedView, Dropzone } from "../../../organisms/draganddrop";
import { summaryMetricItems } from "../../simulations/steps/summary/data";
import { DragDropContext } from "react-beautiful-dnd";
import { Noop } from "../../../../constants/functions";
import SimulationsThatNeedManualModificationModal from "./components/SimulationsThatNeedManualModificationModal";

function SummaryMetrics() {
  const [
    simulationsThatNeedManualModification,
    setSimulationsThatNeedManualModification,
  ] = useState<API.SimulationsThatNeedManualModification[] | null>(null);

  const getSummaryMetricConfig = useCallback(async () => {
    return await api.getSummaryMetricConfig();
  }, []);

  const {
    data: summaryMetricConfig,
    inProgress,
    error,
  } = useMemoRequest(getSummaryMetricConfig);

  const [summaryMetricConfigData, setSummaryMetricConfigData] = useState<
    API.SummaryMetricConfig[] | null
  >(null);
  useEffect(() => {
    if (summaryMetricConfig) {
      setSummaryMetricConfigData(summaryMetricConfig);
    }
  }, [summaryMetricConfig]);

  const handleSummaryMetricConfigUpdate = useCallback(
    async (metricConfigId: string, data: Partial<API.SummaryMetricConfig>) => {
      const response = await api.updateSummaryMetricConfig(
        metricConfigId,
        data,
      );
      setSummaryMetricConfigData((prev) => {
        if (!prev) {
          return prev;
        }
        return prev.map((metricConfig) => {
          if (metricConfig.id === metricConfigId) {
            return { ...metricConfig, ...data };
          }
          return metricConfig;
        });
      });
      if (response.simulationsThatNeedManualModification.length > 0) {
        setSimulationsThatNeedManualModification(
          response.simulationsThatNeedManualModification,
        );
      }
    },
    [],
  );

  const updateField = useCallback(
    (metricConfigId: string) => (field: string) => async (value: string) => {
      await handleSummaryMetricConfigUpdate(metricConfigId, {
        [field]: value,
      });
    },
    [handleSummaryMetricConfigUpdate],
  );

  const items = (summaryMetricConfigData ?? []).map((metricConfig) => {
    const item = summaryMetricItems.find(
      (item) => item.type === metricConfig.type,
    );

    return {
      id: metricConfig.type,
      view: (
        <SelectedView
          label={metricConfig.name}
          icon={item?.icon}
          onUpdateField={updateField(metricConfig.id)}
        />
      ),
    };
  });

  return (
    <>
      <h3>Summary Metric Settings</h3>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {summaryMetricConfigData && (
        <DragDropContext onDragEnd={Noop}>
          <Dropzone
            items={items}
            size={items.length}
            itemsPerPage={items.length}
            readOnly
          />
        </DragDropContext>
      )}
      {simulationsThatNeedManualModification &&
        simulationsThatNeedManualModification.length > 0 && (
          <SimulationsThatNeedManualModificationModal
            simulationsThatNeedManualModification={
              simulationsThatNeedManualModification
            }
            onClose={() => setSimulationsThatNeedManualModification(null)}
            stepTitle="Summary Screen"
          />
        )}
    </>
  );
}

export default SummaryMetrics;
