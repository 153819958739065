import React from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";
import { percentage } from "../utils/formatters";
import pluckResultsByType from "../utils/pluckResultsByType";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["divisionalCosts"];
}

const ModelTeamResultsDivisionalCosts: React.FC<Props> = ({ data }) => (
  <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
    <VerticalGroup spaceBetweenElements={2} wide>
      <TableWithSections
        data={[
          {
            name: "Asset Average Balances",
            fields: [
              "assetAverageBalances.HOME_LOANS",
              "assetAverageBalances.BUSINESS_LOANS",
              "assetAverageBalances.DEPOSITS",
              "assetAverageBalances.CREDIT_CARDS",
              "assetAverageBalances.WEALTH_MANAGEMENT",
              "assetAverageBalances.INSTITUTIONAL_BANKING",
              "assetAverageBalances.OFFSHORE_BANK",
            ],
            values: data,
          },
          {
            name: "Project Impacts - Home Loans",
            fields: [
              "projectImpacts.HOME_LOANS.project-offshore",
              "projectImpacts.HOME_LOANS.project-right-size",
              "projectImpacts.HOME_LOANS.project-harvest",
              "projectImpacts.HOME_LOANS.project-mcfinsey",
              "projectImpacts.HOME_LOANS.project-switch",
              "projectImpacts.HOME_LOANS.project-lightspeed",
              "projectImpacts.HOME_LOANS.project-digital",
              "projectImpacts.HOME_LOANS.project-genius",
              "projectImpacts.HOME_LOANS.project-nitro",
              "projectImpacts.HOME_LOANS.project-big-data",
              "projectImpacts.HOME_LOANS.project-care-bear",
              "projectImpacts.HOME_LOANS.project-tighten",
              "projectImpacts.HOME_LOANS.project-fix-it",
              "projectImpacts.HOME_LOANS.project-roadshow",
              "projectImpacts.HOME_LOANS.project-sustain",
              "projectImpacts.HOME_LOANS.project-shield",
              "projectImpacts.HOME_LOANS.project-simple",
              "projectImpacts.HOME_LOANS.project-fusion",
              "projectImpacts.HOME_LOANS.executiveDecisions",
              "projectImpacts.HOME_LOANS.total",
            ],
            values: data,
            formatter: (val) => percentage(val),
          },
          {
            name: "Project Impacts - Business Loans",
            fields: [
              "projectImpacts.BUSINESS_LOANS.project-offshore",
              "projectImpacts.BUSINESS_LOANS.project-right-size",
              "projectImpacts.BUSINESS_LOANS.project-harvest",
              "projectImpacts.BUSINESS_LOANS.project-mcfinsey",
              "projectImpacts.BUSINESS_LOANS.project-switch",
              "projectImpacts.BUSINESS_LOANS.project-lightspeed",
              "projectImpacts.BUSINESS_LOANS.project-digital",
              "projectImpacts.BUSINESS_LOANS.project-genius",
              "projectImpacts.BUSINESS_LOANS.project-nitro",
              "projectImpacts.BUSINESS_LOANS.project-big-data",
              "projectImpacts.BUSINESS_LOANS.project-care-bear",
              "projectImpacts.BUSINESS_LOANS.project-tighten",
              "projectImpacts.BUSINESS_LOANS.project-fix-it",
              "projectImpacts.BUSINESS_LOANS.project-roadshow",
              "projectImpacts.BUSINESS_LOANS.project-sustain",
              "projectImpacts.BUSINESS_LOANS.project-shield",
              "projectImpacts.BUSINESS_LOANS.project-simple",
              "projectImpacts.BUSINESS_LOANS.project-fusion",
              "projectImpacts.BUSINESS_LOANS.executiveDecisions",
              "projectImpacts.BUSINESS_LOANS.total",
            ],
            values: data,
            formatter: (val) => percentage(val),
          },
          {
            name: "Project Impacts - Credit Cards",
            fields: [
              "projectImpacts.CREDIT_CARDS.project-offshore",
              "projectImpacts.CREDIT_CARDS.project-right-size",
              "projectImpacts.CREDIT_CARDS.project-harvest",
              "projectImpacts.CREDIT_CARDS.project-mcfinsey",
              "projectImpacts.CREDIT_CARDS.project-switch",
              "projectImpacts.CREDIT_CARDS.project-lightspeed",
              "projectImpacts.CREDIT_CARDS.project-digital",
              "projectImpacts.CREDIT_CARDS.project-genius",
              "projectImpacts.CREDIT_CARDS.project-nitro",
              "projectImpacts.CREDIT_CARDS.project-big-data",
              "projectImpacts.CREDIT_CARDS.project-care-bear",
              "projectImpacts.CREDIT_CARDS.project-tighten",
              "projectImpacts.CREDIT_CARDS.project-fix-it",
              "projectImpacts.CREDIT_CARDS.project-roadshow",
              "projectImpacts.CREDIT_CARDS.project-sustain",
              "projectImpacts.CREDIT_CARDS.project-shield",
              "projectImpacts.CREDIT_CARDS.project-simple",
              "projectImpacts.CREDIT_CARDS.project-fusion",
              "projectImpacts.CREDIT_CARDS.executiveDecisions",
              "projectImpacts.CREDIT_CARDS.total",
            ],
            values: data,
            formatter: (val) => percentage(val),
          },
          {
            name: "Project Impacts - Deposits",
            fields: [
              "projectImpacts.DEPOSITS.project-offshore",
              "projectImpacts.DEPOSITS.project-right-size",
              "projectImpacts.DEPOSITS.project-harvest",
              "projectImpacts.DEPOSITS.project-mcfinsey",
              "projectImpacts.DEPOSITS.project-switch",
              "projectImpacts.DEPOSITS.project-lightspeed",
              "projectImpacts.DEPOSITS.project-digital",
              "projectImpacts.DEPOSITS.project-genius",
              "projectImpacts.DEPOSITS.project-nitro",
              "projectImpacts.DEPOSITS.project-big-data",
              "projectImpacts.DEPOSITS.project-care-bear",
              "projectImpacts.DEPOSITS.project-tighten",
              "projectImpacts.DEPOSITS.project-fix-it",
              "projectImpacts.DEPOSITS.project-roadshow",
              "projectImpacts.DEPOSITS.project-sustain",
              "projectImpacts.DEPOSITS.project-shield",
              "projectImpacts.DEPOSITS.project-simple",
              "projectImpacts.DEPOSITS.project-fusion",
              "projectImpacts.DEPOSITS.executiveDecisions",
              "projectImpacts.DEPOSITS.total",
            ],
            values: data,
            formatter: (val) => percentage(val),
          },
          {
            name: "Project Impacts - Wealth Management",
            fields: [
              "projectImpacts.WEALTH_MANAGEMENT.project-offshore",
              "projectImpacts.WEALTH_MANAGEMENT.project-right-size",
              "projectImpacts.WEALTH_MANAGEMENT.project-harvest",
              "projectImpacts.WEALTH_MANAGEMENT.project-mcfinsey",
              "projectImpacts.WEALTH_MANAGEMENT.project-switch",
              "projectImpacts.WEALTH_MANAGEMENT.project-lightspeed",
              "projectImpacts.WEALTH_MANAGEMENT.project-digital",
              "projectImpacts.WEALTH_MANAGEMENT.project-genius",
              "projectImpacts.WEALTH_MANAGEMENT.project-nitro",
              "projectImpacts.WEALTH_MANAGEMENT.project-big-data",
              "projectImpacts.WEALTH_MANAGEMENT.project-care-bear",
              "projectImpacts.WEALTH_MANAGEMENT.project-tighten",
              "projectImpacts.WEALTH_MANAGEMENT.project-fix-it",
              "projectImpacts.WEALTH_MANAGEMENT.project-roadshow",
              "projectImpacts.WEALTH_MANAGEMENT.project-sustain",
              "projectImpacts.WEALTH_MANAGEMENT.project-shield",
              "projectImpacts.WEALTH_MANAGEMENT.project-simple",
              "projectImpacts.WEALTH_MANAGEMENT.project-fusion",
              "projectImpacts.WEALTH_MANAGEMENT.executiveDecisions",
              "projectImpacts.WEALTH_MANAGEMENT.total",
            ],
            values: data,
            formatter: (val) => percentage(val),
          },
          {
            name: "Project Impacts - Institutional Banking",
            fields: [
              "projectImpacts.INSTITUTIONAL_BANKING.project-offshore",
              "projectImpacts.INSTITUTIONAL_BANKING.project-right-size",
              "projectImpacts.INSTITUTIONAL_BANKING.project-harvest",
              "projectImpacts.INSTITUTIONAL_BANKING.project-mcfinsey",
              "projectImpacts.INSTITUTIONAL_BANKING.project-switch",
              "projectImpacts.INSTITUTIONAL_BANKING.project-lightspeed",
              "projectImpacts.INSTITUTIONAL_BANKING.project-digital",
              "projectImpacts.INSTITUTIONAL_BANKING.project-genius",
              "projectImpacts.INSTITUTIONAL_BANKING.project-nitro",
              "projectImpacts.INSTITUTIONAL_BANKING.project-big-data",
              "projectImpacts.INSTITUTIONAL_BANKING.project-care-bear",
              "projectImpacts.INSTITUTIONAL_BANKING.project-tighten",
              "projectImpacts.INSTITUTIONAL_BANKING.project-fix-it",
              "projectImpacts.INSTITUTIONAL_BANKING.project-roadshow",
              "projectImpacts.INSTITUTIONAL_BANKING.project-sustain",
              "projectImpacts.INSTITUTIONAL_BANKING.project-shield",
              "projectImpacts.INSTITUTIONAL_BANKING.project-simple",
              "projectImpacts.INSTITUTIONAL_BANKING.project-fusion",
              "projectImpacts.INSTITUTIONAL_BANKING.executiveDecisions",
              "projectImpacts.INSTITUTIONAL_BANKING.total",
            ],
            values: data,
            formatter: (val) => percentage(val),
          },
          {
            name: "Project Impacts - Offshore Bank",
            fields: [
              "projectImpacts.OFFSHORE_BANK.project-offshore",
              "projectImpacts.OFFSHORE_BANK.project-right-size",
              "projectImpacts.OFFSHORE_BANK.project-harvest",
              "projectImpacts.OFFSHORE_BANK.project-mcfinsey",
              "projectImpacts.OFFSHORE_BANK.project-switch",
              "projectImpacts.OFFSHORE_BANK.project-lightspeed",
              "projectImpacts.OFFSHORE_BANK.project-digital",
              "projectImpacts.OFFSHORE_BANK.project-genius",
              "projectImpacts.OFFSHORE_BANK.project-nitro",
              "projectImpacts.OFFSHORE_BANK.project-big-data",
              "projectImpacts.OFFSHORE_BANK.project-care-bear",
              "projectImpacts.OFFSHORE_BANK.project-tighten",
              "projectImpacts.OFFSHORE_BANK.project-fix-it",
              "projectImpacts.OFFSHORE_BANK.project-roadshow",
              "projectImpacts.OFFSHORE_BANK.project-sustain",
              "projectImpacts.OFFSHORE_BANK.project-shield",
              "projectImpacts.OFFSHORE_BANK.project-simple",
              "projectImpacts.OFFSHORE_BANK.project-fusion",
              "projectImpacts.OFFSHORE_BANK.executiveDecisions",
              "projectImpacts.OFFSHORE_BANK.total",
            ],
            values: data,
            formatter: (val) => percentage(val),
          },
          {
            name: "Fixed Operating Costs",
            fields: [
              "fixedOperatingCosts.HOME_LOANS",
              "fixedOperatingCosts.BUSINESS_LOANS",
              "fixedOperatingCosts.DEPOSITS",
              "fixedOperatingCosts.CREDIT_CARDS",
              "fixedOperatingCosts.WEALTH_MANAGEMENT",
              "fixedOperatingCosts.INSTITUTIONAL_BANKING",
              "fixedOperatingCosts.OFFSHORE_BANK",
              "fixedOperatingCosts.total",
            ],
            totalFields: ["fixedOperatingCosts.total"],
            values: data,
          },
          {
            name: "Cost To Asset Ratio",
            fields: [
              "costToAssetRatio.HOME_LOANS",
              "costToAssetRatio.BUSINESS_LOANS",
              "costToAssetRatio.DEPOSITS",
              "costToAssetRatio.CREDIT_CARDS",
              "costToAssetRatio.WEALTH_MANAGEMENT.otherOpex",
              "costToAssetRatio.WEALTH_MANAGEMENT.servicing",
              "costToAssetRatio.INSTITUTIONAL_BANKING",
              "costToAssetRatio.OFFSHORE_BANK",
            ],
            values: data,
            formatter: (val: number) => percentage(val),
            fieldFormatter: (field: string) => {
              if (field === "costToAssetRatio.WEALTH_MANAGEMENT.otherOpex") {
                return "Wealth Management (Other Opex)";
              }
              if (field === "costToAssetRatio.WEALTH_MANAGEMENT.servicing") {
                return "Wealth Management (Servicing)";
              }
              return null;
            },
          },
          {
            name: "Variable Operating Expense Impact (Exco)",
            fields: [
              "variableOperatingExpenseImpact.HOME_LOANS",
              "variableOperatingExpenseImpact.BUSINESS_LOANS",
              "variableOperatingExpenseImpact.DEPOSITS",
              "variableOperatingExpenseImpact.CREDIT_CARDS",
              "variableOperatingExpenseImpact.WEALTH_MANAGEMENT",
              "variableOperatingExpenseImpact.INSTITUTIONAL_BANKING",
              "variableOperatingExpenseImpact.OFFSHORE_BANK",
            ],
            values: data,
          },
          {
            name: "Variable Operating Expenses",
            fields: [
              "variableOperatingExpenses.HOME_LOANS",
              "variableOperatingExpenses.BUSINESS_LOANS",
              "variableOperatingExpenses.DEPOSITS",
              "variableOperatingExpenses.CREDIT_CARDS",
              "variableOperatingExpenses.WEALTH_MANAGEMENT.otherOpex",
              "variableOperatingExpenses.WEALTH_MANAGEMENT.servicing",
              "variableOperatingExpenses.INSTITUTIONAL_BANKING.clientSpecialists",
              "variableOperatingExpenses.INSTITUTIONAL_BANKING.other",
              "variableOperatingExpenses.OFFSHORE_BANK",
            ],
            values: data,
            fieldFormatter: (field: string) => {
              if (
                field ===
                "variableOperatingExpenses.WEALTH_MANAGEMENT.otherOpex"
              ) {
                return "Wealth Management (Other Opex)";
              }
              if (
                field ===
                "variableOperatingExpenses.WEALTH_MANAGEMENT.servicing"
              ) {
                return "Wealth Management (Servicing)";
              }
              if (
                field ===
                "variableOperatingExpenses.INSTITUTIONAL_BANKING.clientSpecialists"
              ) {
                return "Institutional Banking (Client Specialists)";
              }
              if (
                field ===
                "variableOperatingExpenses.INSTITUTIONAL_BANKING.other"
              ) {
                return "Institutional Banking (Other)";
              }
              return null;
            },
          },
          {
            name: "Branch Costs",
            fields: [
              "branchCosts.numberOfBranches",
              "branchCosts.changeInBranches",
              "branchCosts.cumulativeChangeInBranches",
              "branchCosts.changeInLeaseCosts",
              "branchCosts.fitoutCosts",
            ],
            values: data,
          },
          {
            name: "Total Operating Expenses",
            fields: [
              "totalOperatingExpenses.HOME_LOANS",
              "totalOperatingExpenses.BUSINESS_LOANS",
              "totalOperatingExpenses.DEPOSITS",
              "totalOperatingExpenses.CREDIT_CARDS",
              "totalOperatingExpenses.WEALTH_MANAGEMENT",
              "totalOperatingExpenses.INSTITUTIONAL_BANKING",
              "totalOperatingExpenses.OFFSHORE_BANK",
              "totalOperatingExpenses.total",
            ],
            totalFields: ["totalOperatingExpenses.total"],
            values: data,
          },
          {
            fields: [
              "totalPeopleCostsPreDecisions",
              "peopleCostChangeDueToNonFinancials",
            ],
            values: data,
            fieldFormatter: (field: string) => {
              if (field === "totalPeopleCostsPreDecisions")
                return "Total People Costs (Pre Decisions)";
              if (field === "peopleCostChangeDueToNonFinancials")
                return "People Cost Change Due To Non-Financials (%)";
              return null;
            },
            formatter: (val: number, field: string) => {
              if (field === "peopleCostChangeDueToNonFinancials")
                return percentage(val);
              return null;
            },
          },
          {
            name: "Total Operating Expenses (Fixed + Variable + Others)",
            fields: [
              "HOME_LOANS",
              "BUSINESS_LOANS",
              "DEPOSITS",
              "CREDIT_CARDS",
              "WEALTH_MANAGEMENT",
              "INSTITUTIONAL_BANKING",
              "OFFSHORE_BANK",
              "total",
            ],
            values: pluckResultsByType(
              data,
              "totalOperatingExpensesFixedVariableOthers",
            ),
            fieldFormatter: (field: string) => {
              if (field === "total")
                return "Total Operating Expenses (Excl Off Shore - Not Consolidated)";
              return null;
            },
          },
          {
            name: "Total Operating Expenses (Fixed + Variable + Others)",
            fields: [
              "increaseInOpexPerAbove",
              "increaseInOpexPerSourceCalculations",
              "variance",
            ],
            values: data,
            totalFields: ["variance"],
          },
        ]}
      />
    </VerticalGroup>
  </VerticalGroup>
);

export default ModelTeamResultsDivisionalCosts;
