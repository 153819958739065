const getTeamName = (
  teamId: number,
  numberOfTeams: number,
  teams: API.GameConnection[],
) => {
  if (teamId > numberOfTeams) {
    return `PC Bank ${teamId % numberOfTeams}`;
  }
  const teamDetails = teams.find((t) => t.teamId === teamId);

  return teamDetails?.name ?? `Bank ${teamId}`;
};

export default getTeamName;
