import React from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import { projects } from "../constants";
import TableWithSections from "../tables/TableWithSections";
import { formatValue, percentage } from "../utils/formatters";
import pluckResultsByType from "../utils/pluckResultsByType";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["nonFinancialMetrics"];
}

const ModelTeamResultsNonFinancialMetrics: React.FC<Props> = ({ data }) => {
  const employeeEngagementData = pluckResultsByType(data, "employeeEngagement");
  const npsData = pluckResultsByType(data, "nps");
  const complaintsData = pluckResultsByType(data, "complaints");
  const riskData = pluckResultsByType(data, "riskIncidents");
  const regulatorActionsData = pluckResultsByType(data, "regulatorActions");
  const communityTrustData = pluckResultsByType(data, "communityTrust");
  const summaryChangeMetricsData = pluckResultsByType(
    data,
    "summaryChangeMetrics",
  );

  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <TableWithSections
          header="Employee Engagement"
          data={[
            {
              name: "Opening Balance",
              fields: [
                "openingBalance",
                ...projects,
                "excoDecisions",
                "totalChangeBeforeNonFinancialImpacts",
              ],
              totalFields: ["totalChangeBeforeNonFinancialImpacts"],
              values: pluckResultsByType(
                employeeEngagementData,
                "openingBalance",
              ),
              formatter: (val: number) => percentage(val),
            },
            {
              name: "Non-Financial Division Impacts",
              fields: [
                "IT_OPERATIONS",
                "FRONT_LINE",
                "RISK_COMPLIANCE",
                "HR_TRAINING",
                "salaryIncreaseImpact",
                "salaryDecreaseImpact",
                "closingBalance",
                "changeInEngagement",
                "engagementGainPercentageOfOpening",
                "engagementLossPercentageOfOpening",
                "outputGain",
                "outputLoss",
                "outputChange",
              ],
              totalFields: ["closingBalance"],
              values: pluckResultsByType(
                employeeEngagementData,
                "nonFinancialDivisionImpacts",
              ),
              formatter: (val: number, field: string) => {
                if (["outputChange"].includes(field)) {
                  return null;
                }
                return percentage(val);
              },
              fieldFormatter: (field: string) => {
                if (field === "IT_OPERATIONS") {
                  return "IT & Operations";
                }
                if (field === "RISK_COMPLIANCE") {
                  return "Risk & Compliance";
                }
                if (field === "HR_TRAINING") {
                  return "HR & Training";
                }
                if (field === "engagementGainPercentageOfOpening") {
                  return "Engagement Gain: % of Opening";
                }
                if (field === "engagementLossPercentageOfOpening") {
                  return "Engagement Loss: % of Opening";
                }
                return null;
              },
            },
          ]}
        />

        <TableWithSections
          header="Customer Satisfaction (NPS)"
          data={[
            {
              name: "Non-Financial Division Impacts",
              fields: [
                "IT_OPERATIONS",
                "FRONT_LINE",
                "RISK_COMPLIANCE",
                "HR_TRAINING",
                "SALARY_GROWTH",
                "totalNPSImpactOfNonFinancialMetrics",
                "otherExpectedNPSChanges",
                "totalNPSChange",
              ],
              values: npsData,
              totalFields: [
                "totalNPSImpactOfNonFinancialMetrics",
                "totalNPSChange",
              ],
              formatter: (val: number) => formatValue(val, 0),
              fieldFormatter: (field: string) => {
                if (field === "IT_OPERATIONS") {
                  return "IT & Operations";
                }
                if (field === "RISK_COMPLIANCE") {
                  return "Risk & Compliance";
                }
                if (field === "HR_TRAINING") {
                  return "HR & Training";
                }
                if (field === "totalNPSImpactOfNonFinancialMetrics") {
                  return "Total NPS Impact of Non-Financial Metrics";
                }
                return null;
              },
            },
          ]}
        />

        <TableWithSections
          header="Customer Complaints"
          data={[
            {
              name: "Customer Complaints Impact of Closing Branches",
              fields: ["branchesClosedThisRound", "newCustomerComplaints"],
              values: pluckResultsByType(
                complaintsData,
                "customerComplaintsImpactOfClosingBranches",
              ),
            },
            {
              name: "Opening Balance",
              fields: [
                "openingBalance",
                "annualNewComplaints",
                ...projects,
                "excoDecisions",
                "closureOfBranches",
                "totalChangeBeforeNonFinancialImpacts",
              ],
              totalFields: ["totalChangeBeforeNonFinancialImpacts"],
              values: pluckResultsByType(complaintsData, "openingBalance"),
            },
            {
              name: "Non-Financial Division Impacts",
              fields: [
                "IT_OPERATIONS",
                "FRONT_LINE",
                "RISK_COMPLIANCE",
                "HR_TRAINING",
                "SALARY_GROWTH",
              ],
              values: pluckResultsByType(complaintsData, "nonFinancialImpacts"),
              fieldFormatter: (field: string) => {
                if (field === "IT_OPERATIONS") {
                  return "IT & Operations";
                }
                if (field === "RISK_COMPLIANCE") {
                  return "Risk & Compliance";
                }
                if (field === "HR_TRAINING") {
                  return "HR & Training";
                }
                return null;
              },
            },
            {
              fields: [
                "closingBalance",
                "changeInCustomerComplaints",
                "cummulativeChangeInComplaints",
                "npsImpactOnEachProduct",
              ],
              values: complaintsData,
              isTotalsSection: true,
              fieldFormatter: (field: string) => {
                if (field === "npsImpactOnEachProduct") {
                  return "NPS Impact (On Each Product)";
                }
                return null;
              },
            },
          ]}
        />

        <TableWithSections
          header="Risk Incidents"
          data={[
            {
              name: "Opening Balance",
              fields: [
                "openingBalance",
                "annualNewIncidents",
                ...projects,
                "excoDecisions",
                "totalChangeBeforeNonFinancialImpacts",
              ],
              totalFields: ["totalChangeBeforeNonFinancialImpacts"],
              values: pluckResultsByType(riskData, "openingBalance"),
            },
            {
              name: "Non-Financial Division Impacts",
              fields: [
                "IT_OPERATIONS",
                "FRONT_LINE",
                "RISK_COMPLIANCE",
                "HR_TRAINING",
                "SALARY_GROWTH",
              ],
              values: pluckResultsByType(riskData, "nonFinancialImpacts"),
              fieldFormatter: (field: string) => {
                if (field === "IT_OPERATIONS") {
                  return "IT & Operations";
                }
                if (field === "RISK_COMPLIANCE") {
                  return "Risk & Compliance";
                }
                if (field === "HR_TRAINING") {
                  return "HR & Training";
                }
                return null;
              },
            },
            {
              fields: ["closingBalance", "changeInRiskIncidents"],
              values: riskData,
              isTotalsSection: true,
            },
          ]}
        />

        <TableWithSections
          header="Regulator Actions"
          data={[
            {
              fields: ["ratioClosingToInitialRiskIncidents", "sanctionsIssued"],
              values: regulatorActionsData,
            },
            {
              name: "Opening Balance",
              fields: [
                "openingBalance",
                ...projects,
                "totalChangeBeforeNonFinancialImpacts",
              ],
              totalFields: ["totalChangeBeforeNonFinancialImpacts"],
              values: pluckResultsByType(
                regulatorActionsData,
                "openingBalance",
              ),
            },
            {
              fields: [
                "newSanctionsCalculated",
                "closingBalance",
                "changeInRegulatorActions",
                "costOfRegulatorActions",
              ],
              values: regulatorActionsData,
              isTotalsSection: true,
            },
          ]}
        />

        <TableWithSections
          header="Community Trust / Reputation"
          data={[
            {
              name: "Non-Financial Division Impacts",
              fields: [
                "cummulativeChangeInRiskIncidents",
                "changeInTrustRaw",
                "cappedDecrease",
                "cappedIncrease",
                "riskIncidentImpact",
              ],
              values: pluckResultsByType(
                communityTrustData,
                "nonFinanancialDivisionImpacts",
              ),
              formatter: (val: number, field: string) => {
                if (field !== "cummulativeChangeInRiskIncidents") {
                  return percentage(val);
                }
                return null;
              },
            },
            {
              name: "Opening Balance",
              fields: [
                "openingBalance",
                ...projects,
                "dealRoomNeobank",
                "excoDecisions",
                "totalChangeBeforeNonFinancialImpacts",
              ],
              totalFields: ["totalChangeBeforeNonFinancialImpacts"],
              values: pluckResultsByType(communityTrustData, "openingBalance"),
              formatter: (val: number) => percentage(val),
            },
            {
              fields: [
                "regulatorSanctionsImpact",
                "riskIncidentImpact",
                "closingBalance",
                "changeInCommunityTrust",
                "cummulativeChangeInCommunityTrust",
              ],
              values: communityTrustData,
              totalFields: [
                "closingBalance",
                "changeInCommunityTrust",
                "cummulativeChangeInCommunityTrust",
              ],
              formatter: (val: number) => percentage(val),
            },
          ]}
        />

        <TableWithSections
          header="Summary Non-Financial Change Metrics"
          data={[
            {
              name: "Change Metrics",
              fields: [
                "NPS",
                "Complaints",
                "EmployeeEngagement",
                "RiskIncidents",
                "Community",
                "RegulatorActions",
                "costIncrease",
                "FTEIncrease",
              ],
              values: pluckResultsByType(
                summaryChangeMetricsData,
                "changeMetrics",
              ),
              formatter: (val: number, field: string) => {
                if (["EmployeeEngagement", "Community"].includes(field)) {
                  return percentage(val);
                }
                return null;
              },
            },
            {
              name: "Final Metrics",
              fields: [
                "NPS",
                "Complaints",
                "EmployeeEngagement",
                "RiskIncidents",
                "Community",
                "RegulatorActions",
              ],
              values: pluckResultsByType(
                summaryChangeMetricsData,
                "finalMetrics",
              ),
              formatter: (val: number, field: string) => {
                if (["EmployeeEngagement", "Community"].includes(field)) {
                  return percentage(val);
                }
                return null;
              },
            },
          ]}
        />
      </VerticalGroup>
    </>
  );
};

export default ModelTeamResultsNonFinancialMetrics;
