import React from "react";
import TeamTimer from "./timer/TeamTimer";

import Button from "../../atoms/button/Button";
import Card from "../../atoms/card/Card";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import Text from "../../atoms/text/Text";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import useIsMobile from "../../../hooks/useIsMobile";

interface Props {
  timers: { team: API.GameConnection; timer: API.GameTeamBonusTimer }[];
  round: number;
  update: () => void;
  setError: (err: string | null) => void;
  handleSkip: () => void;
  event: API.EventResponse;
  handleLoginClick: (teamId: number) => void;
}

function BonusTime({
  timers,
  round,
  update,
  setError,
  handleSkip,
  event,
  handleLoginClick,
}: Props) {
  const isMobile = useIsMobile();
  return (
    <>
      <Button block onClick={handleSkip} className="mt-2" colour="yellow">
        I don&apos;t want to give teams any bonus time.
      </Button>

      <Card wide>
        <InlineGroup block evenWidthChildren verticalCenter>
          <Text bold size="sm">
            Teams
          </Text>
          <Text bold size="sm">
            Adjust Time
          </Text>
          <InlineGroup evenWidthChildren>
            <Text bold size="sm" center>
              Start/Stop
            </Text>
            <Text bold size="sm" center>
              Login
            </Text>
            {!isMobile && (
              <>
                <Text bold size="sm" center>
                  Decisions
                </Text>
              </>
            )}
          </InlineGroup>
        </InlineGroup>
        <VerticalGroup className="mt-6" spaceBetweenElements={6}>
          {timers.map((t) => (
            <TeamTimer
              setError={setError}
              key={t.team.teamId}
              round={round}
              timer={t.timer}
              team={t.team}
              update={update}
              event={event}
              handleLoginClick={handleLoginClick}
            />
          ))}
        </VerticalGroup>
      </Card>
    </>
  );
}

export default BonusTime;
