import React from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";
import { formatValue } from "../utils/formatters";
interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["cashflow"];
}

const ModelTeamResultsCashflow: React.FC<Props> = ({ data }) => {
  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <VerticalGroup spaceBetweenElements={2} wide>
          <h3 className="ml-4">Operating Activities</h3>
          <TableWithSections
            data={[
              {
                name: "INFLOWS",
                fields: [
                  "operatingActivities.inflows.repaymentsFromCustomers",
                  "operatingActivities.inflows.DEPOSITS",
                  "operatingActivities.inflows.wholesaleFundingIssues",
                ],
                values: data,
              },
              {
                name: "Interest Income",
                fields: [
                  "operatingActivities.inflows.interestIncome.HOME_LOANS",
                  "operatingActivities.inflows.interestIncome.BUSINESS_LOANS",
                  "operatingActivities.inflows.interestIncome.CREDIT_CARDS",
                  "operatingActivities.inflows.interestIncome.INSTITUTIONAL_BANKING",
                ],
                values: data,
              },
              {
                name: "Other Income",
                fields: [
                  "operatingActivities.inflows.otherIncome.HOME_LOANS",
                  "operatingActivities.inflows.otherIncome.BUSINESS_LOANS",
                  "operatingActivities.inflows.otherIncome.WEALTH_MANAGEMENT",
                  "operatingActivities.inflows.otherIncome.DEPOSITS",
                  "operatingActivities.inflows.otherIncome.CREDIT_CARDS.creditCardFees",
                  "operatingActivities.inflows.otherIncome.CREDIT_CARDS.creditCardInterchangeIncome",
                  "operatingActivities.inflows.otherIncome.INSTITUTIONAL_BANKING",
                  "operatingActivities.inflows.otherIncome.OFFSHORE_BANK",
                  "operatingActivities.inflows.total",
                ],
                totalFields: ["operatingActivities.inflows.total"],
                values: data,
              },
              {
                name: "OUTFLOWS",
                fields: [
                  "operatingActivities.outflows.lendingToCustomers",
                  "operatingActivities.outflows.brokerCommissions",
                  "operatingActivities.outflows.creditCardRewardCosts",
                  "operatingActivities.outflows.depositOutflow",
                  "operatingActivities.outflows.wholesaleFundingRepayments",
                  "operatingActivities.outflows.interestExpense.DEPOSITS",
                  "operatingActivities.outflows.interestExpense.wholesaleFunding",
                  "operatingActivities.outflows.totalOperatingCosts",
                  "operatingActivities.outflows.remediationCosts",
                  "operatingActivities.outflows.taxationExpense",
                  "operatingActivities.outflows.total",
                ],
                totalFields: ["operatingActivities.outflows.total"],
                values: data,
                fieldFormatter: (field: string) => {
                  if (
                    field ===
                    "operatingActivities.outflows.interestExpense.DEPOSITS"
                  ) {
                    return "Interest Expense - Desposits";
                  }
                  if (
                    field ===
                    "operatingActivities.outflows.interestExpense.wholesaleFunding"
                  ) {
                    return "Interest Expense - Wholesale Funding";
                  }
                  return null;
                },
              },
              {
                isTotalsSection: true,
                fields: ["operatingActivities.netOperatingCashflows"],
                values: data,
              },
            ]}
            formatter={(val: number, section: string | undefined) =>
              section === "OUTFLOWS" ? formatValue(-1 * val) : formatValue(val)
            }
          />
        </VerticalGroup>
        <VerticalGroup spaceBetweenElements={2} wide>
          <h3 className="ml-4">Investing Activities</h3>
          <TableWithSections
            data={[
              {
                name: "INFLOWS",
                fields: [
                  "investingActivities.inflows.decreasesInWealthManagementAssets",
                  "investingActivities.inflows.decreasesInFixedAssets",
                  "investingActivities.inflows.saleProceedsLoanBook",
                  "investingActivities.inflows.dividendsMAndAInvestments",
                  "investingActivities.inflows.capitalReturnedFromOffshoreBank",
                  "investingActivities.inflows.total",
                ],
                totalFields: ["investingActivities.inflows.total"],
                values: data,
                fieldFormatter: (field: string) => {
                  if (
                    field ===
                    "investingActivities.inflows.dividendsMAndAInvestments"
                  ) {
                    return "Dividends - M&A Investments";
                  }
                  return null;
                },
              },
              {
                name: "OUTFLOWS",
                fields: [
                  "investingActivities.outflows.increasesInWealthManagementAssets",
                  "investingActivities.outflows.increasesInFixedAssets",
                  "investingActivities.outflows.projectCosts",
                  "investingActivities.outflows.loanBookAcquired",
                  "investingActivities.outflows.investmentIntoOffshoreBank",
                  "investingActivities.outflows.total",
                ],
                totalFields: ["investingActivities.outflows.total"],
                values: data,
              },
              {
                isTotalsSection: true,
                fields: ["investingActivities.netInvestingCashflows"],
                values: data,
              },
            ]}
            // formatter={(val: number, section: string | undefined) => section === 'OUTFLOWS' ? formatValue(-1 * val) : formatValue(val)}
          />
        </VerticalGroup>
        <VerticalGroup spaceBetweenElements={2} wide>
          <h3 className="ml-4">Financing Activities</h3>
          <TableWithSections
            data={[
              {
                name: "INFLOWS",
                fields: [
                  "financingActivities.inflows.shareholderCapitalIssuedManualAndForced",
                  "financingActivities.inflows.drpShareCapitalIssued",
                  "financingActivities.inflows.interestOnCash",
                  "financingActivities.inflows.total",
                ],
                totalFields: ["financingActivities.inflows.total"],
                values: data,
              },
              {
                name: "OUTFLOWS",
                fields: [
                  "financingActivities.outflows.dividendsPaid",
                  "financingActivities.outflows.specialDividendsPaid",
                  "financingActivities.outflows.shareBuybacks",
                  "financingActivities.outflows.total",
                ],
                totalFields: ["financingActivities.outflows.total"],
                values: data,
              },
              {
                isTotalsSection: true,
                fields: ["financingActivities.netFinancingCashflows"],
                values: data,
              },
            ]}
            formatter={(val: number, section: string | undefined) =>
              section === "OUTFLOWS" ? formatValue(-1 * val) : formatValue(val)
            }
          />
        </VerticalGroup>
        <VerticalGroup spaceBetweenElements={2} wide>
          <h3 className="ml-4">Net Cashflow</h3>
          <TableWithSections
            data={[
              {
                isTotalsSection: true,
                fields: ["netCashflow", "operatingCashflowExcludeTax"],
                values: data,
              },
            ]}
          />
        </VerticalGroup>
      </VerticalGroup>
    </>
  );
};

export default ModelTeamResultsCashflow;
