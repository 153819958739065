export interface Option {
  label: string;
  id: string | number;
}

export const dropDownVariables: Option[] = [
  "#country#",
  "#currencySymbol#",
  "#currencyCode#",
  "#centralBankShortName#",
  "#centralBankLongName#",
  "#regulatorLongName#",
  "#regulatorShortName#",
  "#referenceRate#",
  "#referenceRateDirection#",
  "#referenceRateChange#",
  "#competitionRegulatorLongName#",
  "#competitionRegulatorShortName#",
  "#AMLRegulatorLongName#",
  "#AMLRegulatorShortName#",
].map((variable, index) => ({
  label: variable,
  id: index,
}));
