import React, { useCallback } from "react";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import useForm from "../../../hooks/useForm";
import DisplayField from "../../atoms/form/display-field/DisplayField";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import Datepicker from "../../atoms/datepicker/Datepicker";
import Form from "../../atoms/form/Form";

import "../../atoms/form/input/Input.scss";
import Button from "../../atoms/button/Button";
import { duration } from "../../../lib/date";
import CancelButton from "../../atoms/form/actions/CancelButton";
import SimpleModal from "../../organisms/standard-modal/SimpleModal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  data: API.GameRoundTimer;
  onSave: (props: { startTime?: string; finishTime: string }) => Promise<void>;
  onCancel: () => void;
}

const formatDateForAPI = (value: Date) => {
  value.setSeconds(0);
  value.setMilliseconds(0);
  return value.toISOString();
};

const ScheduledRoundForm: React.FC<Props> = ({
  data,
  onSave,
  onCancel,
  isOpen,
  onClose,
}) => {
  const [{ formData, fieldErrors }, { setDateField, setError }] = useForm({
    startTime: new Date(data.startTime!),
    finishTime: new Date(data.finishTime!),
  });

  const { startTime, finishTime } = formData;

  const validate = useCallback((startTime, finishTime) => {
    const errors = [];

    if (finishTime <= startTime) {
      errors.push({
        field: "finishTime",
        message: "Finish time must be after start time",
      });
    } else if (finishTime < new Date()) {
      errors.push({ field: "finishTime", message: "Can't be in the past" });
    } else {
      errors.push({ field: "finishTime", message: undefined });
    }

    return errors;
  }, []);

  const onSubmit = useCallback(() => {
    const errors = validate(startTime, finishTime);
    for (const err of errors) {
      setError(err.field, err.message);
    }
    if (errors.filter((e) => e.message !== undefined).length) {
      return;
    }

    onSave({
      startTime: formatDateForAPI(startTime),
      finishTime: formatDateForAPI(finishTime),
    });
  }, [onSave, startTime, finishTime, setError, validate]);

  return (
    <SimpleModal size="medium" isOpen={isOpen} onClose={onClose} isScrollable>
      <VerticalGroup wide spaceBetweenElements={4}>
        <h2>Round Scheduling</h2>
        <Form wide>
          <Datepicker
            label="Starting Date and Time"
            date={startTime}
            onChange={setDateField("startTime")}
            withTime
            error={fieldErrors.startTime}
            disabled={startTime < new Date()}
          />
          <Datepicker
            label="Finishing Date and Time"
            date={finishTime}
            onChange={setDateField("finishTime")}
            withTime
            error={fieldErrors.finishTime}
          />
          <DisplayField
            label="Round Duration"
            value={duration(startTime!, finishTime!)}
          />
          <InlineGroup block spread className="mt-4">
            <CancelButton
              confirmModalProps={{
                confirmButtonText: "Save",
                inProgress: formData.inProgress,
                showModal: formData.formUpdated,
                onConfirm: onSubmit,
                onDiscard: onCancel,
              }}
              onClick={onCancel}
            />
            <Button data-test="save" wide inProgress={false} onClick={onSubmit}>
              Save
            </Button>
          </InlineGroup>
        </Form>
      </VerticalGroup>
    </SimpleModal>
  );
};

export default ScheduledRoundForm;
