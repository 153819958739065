import React from "react";

import Card from "../../../../atoms/card/Card";
import DisplayField from "../../../../atoms/form/display-field/DisplayField";
import ClientAvatarList from "../../../../molecules/client-avatar-list/ClientAvatarList";
import Clickable from "../../../../atoms/clickable/Clickable";
import Icon from "../../../../atoms/icon/Icon";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import AvoidPageBreak from "../../../../atoms/printable/components/AvoidPageBreak";

interface Props {
  data?: API.SimulationResponse;
  onClickEdit?: () => void;
}

const Display: React.FC<Props> = ({ data, onClickEdit }) => {
  return data ? (
    <Card wide>
      <AvoidPageBreak>
        <InlineGroup verticalCenter>
          <h4>General Settings</h4>
          {onClickEdit ? (
            <Clickable
              data-test={"edit-simulation-general-step"}
              onClick={onClickEdit}
            >
              <Icon
                type="edit"
                tip={{
                  content: "Edit General",
                  id: "edit-general",
                }}
              />
            </Clickable>
          ) : null}
        </InlineGroup>
        <DisplayField label="Simulation ID" value={data.id} />
        <DisplayField
          stretch
          label="Clients"
          value={<ClientAvatarList clients={data.clients} />}
        />
        <DisplayField
          data-test="name"
          value={data.generalSetup.name}
          label="Simulation Name"
        />
        <DisplayField
          data-test="rounds"
          value={data.generalSetup.rounds}
          label="Rounds"
        />
        <DisplayField
          data-test="simulationType"
          value={data.generalSetup.simulationType}
          label="Simulation Type"
        />
        <DisplayField
          data-test="shouldRotateCeo"
          value={data.generalSetup.shouldRotateCeo ? "TRUE" : "FALSE"}
          label="Rotate CEO"
        />
      </AvoidPageBreak>
    </Card>
  ) : null;
};

export default Display;
