import React, { useEffect, useCallback } from "react";

import API from "../../../../../services/api";
import LoadingSpinner from "../../../../atoms/loadingspinner/LoadingSpinner";
import useAPIRequest from "../../../../../hooks/useAPIRequest";
import Banner from "../../../../atoms/banner/Banner";
import AddHelpManuals from "./AddHelpManuals";

interface Props {
  helpManualId: string;
}

const HelpManualEdit: React.FC<Props> = (props) => {
  const { helpManualId } = props;

  const callback = useCallback(() => {
    return API.getHelpManual(helpManualId);
  }, [helpManualId]);

  const [{ inProgress, data, error }, doAPIRequest] =
    useAPIRequest<API.HelpManualResponse>(callback, null);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  return (
    <>
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {inProgress && <LoadingSpinner />}
      {!!data && !inProgress && (
        <AddHelpManuals helpManual={data} title={"Help Manual Edit"} />
      )}
    </>
  );
};

export default HelpManualEdit;
