import React, { useCallback, useEffect, useMemo } from "react";

import useAPIRequest from "../../../hooks/useAPIRequest";
import API from "../../../services/api";

import Dropdown from "../../atoms/form/input/Dropdown";

interface Props {
  helpManualId?: string;
  onChange: (selected: any) => void;
  error?: string;
  label?: string;
  noBorder?: boolean;
  placeholder?: string;
  colour?: string;
}

function HelpManualDropdown({
  helpManualId,
  onChange,
  error,
  label,
  noBorder,
  placeholder,
  colour,
}: Props) {
  const callback = useCallback(() => {
    return API.getHelpManuals();
  }, []);

  const [{ inProgress, data }, doAPIRequest] = useAPIRequest<
    API.HelpManualResponse[]
  >(callback, null);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  const options = useMemo(() => {
    return data
      ? data.map((helpManual) => ({
          id: helpManual.id,
          value: helpManual.id,
          label: helpManual.name,
        }))
      : [];
  }, [data]);

  const defaultValue = useMemo(() => {
    const value = null;

    if (helpManualId) {
      return options.find((helpManual) => helpManual.value === helpManualId);
    }
    return value;
  }, [helpManualId, options]);

  if (inProgress || !data) {
    return (
      <Dropdown
        key="loading-dropdown"
        data-test="help-manual-dropdown-loading"
        label={label}
        error={error}
        noBorder={noBorder}
        selectProps={{
          isLoading: true,
          options: [],
          defaultValue: null,
          onChange: null,
          placeholder: "Loading help manuals...",
        }}
      />
    );
  }

  return (
    <Dropdown
      data-test="help-manual-dropdown"
      label={label}
      error={error}
      noBorder={noBorder}
      colour={colour}
      selectProps={{
        options: options,
        defaultValue: defaultValue,
        onChange: onChange,
        placeholder: placeholder,
        classNamePrefix: "help-manual-dropdown",
      }}
    />
  );
}

export default HelpManualDropdown;
