import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useMemoRequest } from "../../../hooks/useMemoRequest";
import modelApi from "../../../services/modelApi";
import Banner from "../../atoms/banner/Banner";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import RoundResultsPage from "./RoundResultsPage";

const onlyThesePages: API.ReportingLayoutType[] | null = [];

const RoundResultsCast: React.FC = () => {
  const [castIndex, setCastIndex] = useState(0);
  const { eventId, castKey } = useParams<{
    eventId: string;
    castKey: string;
  }>();

  const getReportingResults = useCallback(() => {
    return modelApi.getReportingResultsForCast(eventId, castKey);
  }, [eventId, castKey]);

  const { inProgress, data, error, refresh } =
    useMemoRequest<ModelAPI.Reporting.ReportingResults>(getReportingResults);

  useEffect(() => {
    if (castIndex !== 0) {
      refresh();
    }
  }, [castIndex, refresh]);

  return (
    <>
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {inProgress && <LoadingSpinner />}
      {data && (
        <div style={{ height: "100vh" }}>
          <RoundResultsPage
            eventId={eventId}
            data={data}
            roundNumber={data.roundId}
            onlyThesePages={onlyThesePages}
            hidePagination
            readSyncState
            ignoreKeyPress
            ignoreFontMultiplier
            noAudio
            noVideo
            handleRoundChange={setCastIndex}
          />
        </div>
      )}
    </>
  );
};

export default RoundResultsCast;
