import React, { useCallback, useEffect } from "react";

import useAPIRequest from "../../../../hooks/useAPIRequest";
import API from "../../../../services/api";
import { useHistory } from "react-router-dom";
import IconButton from "../../../molecules/iconbutton/IconButton";

interface Props {
  user: API.UserResponse;
}

function ActivateUser({ user }: Props) {
  const history = useHistory();

  const callback = useCallback(() => {
    const { id, ...rest } = user;
    return API.editUser(id, {
      email: rest.email,
      firstName: rest.firstName,
      lastName: rest.lastName,
      type: rest.type,
      clientIds: rest.clientIds,
      state: "active",
    });
  }, [user]);

  const [{ completed, error }, doAPIRequest] = useAPIRequest(callback, null);

  const handleConfirm = () => {
    doAPIRequest();
  };

  useEffect(() => {
    if (completed && !error) {
      history.push("/administration/users");
    }
  }, [completed, error, history]);

  return (
    <IconButton
      data-test={`toggle-user-state-${user.id}`}
      icon="tick"
      text="Activate User"
      onClick={handleConfirm}
    />
  );
}

export default ActivateUser;
