import React, { useState, useEffect, useCallback, useMemo } from "react";

import Banner from "../../../../../atoms/banner/Banner";
import LoadingSpinner from "../../../../../atoms/loadingspinner/LoadingSpinner";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import GameProgressBar from "../../../../game/GameProgressBar";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import Card from "../../../../../atoms/card/Card";
import GameDetails from "../../../../game/GameDetails";
import ScheduledRoundTimerScreen from "../../../../game/ScheduledRoundTimerScreen";
import FacilitatedRoundTimerScreen from "../../../../game/FacilitatedRoundTimerScreen";
import CloseRoundScreen from "../../../../game/CloseRoundScreen";
import BonusTimeScreen from "../../../../game/BonusTimeScreen";
import Form from "../../../../../atoms/form/Form";
import "./GameStatus.scss";
import { getParticipantLoginLink } from "../../../../game/utils/getParticpantLoginLink";
import ErrorModal from "../../../../../organisms/standard-modal/ErrorModal";
import openNewTab from "../../../../../../lib/openNewTab";
import SendEmailModal from "../participants/SendEmailModal";
import GameControllerBroadcastMessage from "../../../../game/GameControllerBroadcastMessage";
import GameRoundDetails from "../../../../game/GameRoundDetails";

interface Props {
  event: API.EventResponse;
  game: API.GameStatus;
  fetchGameStatus: (onComplete?: (() => void) | undefined) => void;
}

function GameStatus({ event, game, fetchGameStatus }: Props) {
  const [error, setError] = useState<string | null>(null);
  const [screen, setScreen] = useState<"timer" | "bonus" | "close" | null>(
    null,
  );
  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false);
  const [isBroadcastModalOpen, setBroadcastModalOpen] = useState(false);
  const [shouldCalculateRound, setShouldCalculateRound] = useState(0);

  const [selectedScreen, setSelectedScreen] = useState<
    "timer" | "bonus" | "close" | null
  >(null);
  const gameInProgress =
    game.status === "inprogress" ||
    game.status === "bonustime" ||
    game.status === "wrapup";

  const [isErrorModalOpen, setErrorModalOpen] = useState(false);
  const handleLoginClick = useCallback(
    (teamId: number) => {
      try {
        const participantLoginLink = getParticipantLoginLink(event, teamId);
        openNewTab(participantLoginLink);
      } catch (error) {
        setError(error.message);
        setErrorModalOpen(true);
      }
    },
    [event, setError],
  );

  const handleSendWelcomeEmailsClick = useCallback(() => {
    setSendEmailModalOpen(true);
  }, []);

  const handleBroadcastClick = useCallback(() => {
    setBroadcastModalOpen(true);
  }, []);

  const handleSkipBonusTime = useCallback(() => {
    const round = gameInProgress ? game.currentRound : game.currentRound + 1;
    setShouldCalculateRound(round);
    setSelectedScreen("close");
  }, [game.currentRound, gameInProgress]);

  const isScheduledRound = game.roundType === "scheduled";
  // Initially a game has a locked status and starts at round 0, we would need to increment the round so that the correct round starts
  const round = gameInProgress ? game.currentRound : game.currentRound + 1;

  const timers: { team: API.GameConnection; timer: API.GameTeamBonusTimer }[] =
    useMemo(() => {
      const result = [];
      for (let i = 1; i <= game.teamsSetup.length; i++) {
        result.push({
          team: game.teams[i - 1],
          timer: game.bonusTimers[`${round}:${i}`],
        });
      }
      return result;
    }, [game.bonusTimers, game.teams, game.teamsSetup, round]);

  useEffect(() => {
    if (game.status === "bonustime") {
      if (isScheduledRound) {
        setScreen("close");
      } else {
        if (
          game.bonusTimeHasStarted &&
          timers.every((t) => t.timer.timeRemaining === 0)
        ) {
          setScreen("close");
        } else {
          setScreen("bonus");
        }
      }
    } else {
      setScreen("timer");
    }
  }, [game.bonusTimeHasStarted, game.status, timers, isScheduledRound]);

  // Syncs the timer with backend

  useEffect(() => {
    const heartbeater = setInterval(() => {
      fetchGameStatus();
    }, 3000);
    return () => {
      clearInterval(heartbeater);
    };
  }, [fetchGameStatus]);

  const closeRound = useCallback(async () => {
    setSelectedScreen("timer");
    fetchGameStatus();
  }, [fetchGameStatus]);

  const transitionScreen = useCallback(
    (to: "timer" | "bonus" | "close") => {
      if (!selectedScreen) {
        setScreen(to);
      }
    },
    [selectedScreen],
  );

  useEffect(() => {
    if (screen === selectedScreen) {
      setSelectedScreen(null);
    }
  }, [screen, selectedScreen]);

  if (!screen) {
    return null;
  }

  return (
    <>
      <InlineGroup spread verticalCenter block>
        <GameProgressBar
          hideBonusTime={isScheduledRound}
          screen={selectedScreen || screen}
          setSelectedScreen={setSelectedScreen}
        />
      </InlineGroup>
      <Form wide>
        <Card wide padding={4}>
          {error && (
            <Banner
              type="error"
              active={!!error}
              message={error}
              onClose={() => setError(null)}
            />
          )}
          {!screen && <LoadingSpinner />}
          {!!screen && (
            <VerticalGroup
              spaceBetweenElements={2}
              style={{ maxWidth: 880, width: 880 }}
              center
            >
              <InlineGroup block spaceBetweenElements={2}>
                <GameRoundDetails
                  imageUrl={event.branding?.brandingImage?.imageUrl}
                  round={round}
                  totalRounds={game.totalRounds}
                  gameName={event.name}
                />
                <GameDetails event={event} />
              </InlineGroup>
              {isScheduledRound &&
                (selectedScreen === "timer" ||
                  (!selectedScreen && screen === "timer")) && (
                  <ScheduledRoundTimerScreen
                    game={game}
                    round={round}
                    setError={setError}
                    update={fetchGameStatus}
                    setScreen={transitionScreen}
                    event={event}
                    handleLoginClick={handleLoginClick}
                    handleBroadcastClick={handleBroadcastClick}
                    handleSendWelcomeEmailsClick={handleSendWelcomeEmailsClick}
                  />
                )}

              {!isScheduledRound &&
                (selectedScreen === "timer" ||
                  (!selectedScreen && screen === "timer")) && (
                  <FacilitatedRoundTimerScreen
                    game={game}
                    round={round}
                    event={event}
                    setError={setError}
                    update={fetchGameStatus}
                    setScreen={transitionScreen}
                    handleLoginClick={handleLoginClick}
                    handleBroadcastClick={handleBroadcastClick}
                    handleSendWelcomeEmailsClick={handleSendWelcomeEmailsClick}
                  />
                )}

              {(selectedScreen === "bonus" ||
                (!selectedScreen && screen === "bonus")) && (
                <BonusTimeScreen
                  setError={setError}
                  timers={timers}
                  round={round}
                  update={fetchGameStatus}
                  handleSkip={handleSkipBonusTime}
                  event={event}
                  handleLoginClick={handleLoginClick}
                />
              )}

              {(selectedScreen === "close" ||
                (!selectedScreen && screen === "close")) && (
                <CloseRoundScreen
                  closeRound={closeRound}
                  gameId={game.gameId}
                  totalRounds={game.totalRounds}
                  round={round}
                  shouldCalculateRound={shouldCalculateRound}
                  setShouldCalculateRound={setShouldCalculateRound}
                  handleBroadcastClick={handleBroadcastClick}
                />
              )}
            </VerticalGroup>
          )}
        </Card>
      </Form>
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => setErrorModalOpen(false)}
        title="Error"
        description={error}
      />
      <SendEmailModal
        onComplete={() => setSendEmailModalOpen(false)}
        participantCount={event?.participants?.length || 0}
        isOpen={sendEmailModalOpen}
        onClose={() => setSendEmailModalOpen(false)}
        eventId={event.id}
        participants={event?.participants || []}
      />
      <GameControllerBroadcastMessage
        isOpen={isBroadcastModalOpen}
        onClose={() => setBroadcastModalOpen(false)}
        eventId={event.id}
        broadcastMessage={game.broadcastMessage}
      />
    </>
  );
}

export default GameStatus;
