import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import Icon from "../../../atoms/icon/Icon";
import API from "../../../../services/api";

import Clickable from "../../../atoms/clickable/Clickable";
import ErrorModal from "../../../organisms/standard-modal/ErrorModal";
import IconButton from "../../../molecules/iconbutton/IconButton";
import { TemplateResponse, TemplateType } from "../types";

interface Props {
  type: "icon" | "button";
  templateType: TemplateType;
  template: TemplateResponse;
}
export const CLONE_TEMPLATE_TOOLTIP_ID = "clone-reporting-template-tooltip";

export const CloneTemplate: React.FC<Props> = ({
  type,
  templateType,
  template,
}) => {
  const history = useHistory();
  const [hasError, setError] = useState(false);

  const onClick = useCallback(
    async (event) => {
      event.stopPropagation();
      try {
        const newTemplate = await API.cloneTemplate(templateType, template.id);
        history.push(`/${templateType}-templates/${newTemplate.id}/edit`);
      } catch (e) {
        setError(true);
      }
    },
    [template.id, history, templateType],
  );

  return (
    <>
      {type === "icon" && (
        <Clickable data-test={`clone-${template.id}`} onClick={onClick}>
          <Icon type="copy" tt={{ id: CLONE_TEMPLATE_TOOLTIP_ID }} />
        </Clickable>
      )}

      {type === "button" && (
        <IconButton
          data-test={`clone-template-${template.id}`}
          icon="copy"
          text="Clone Presentation"
          onClick={onClick}
        />
      )}

      {hasError && (
        <ErrorModal
          isOpen={hasError}
          onClose={(e) => {
            e.stopPropagation();
            setError(false);
          }}
          title="Clone Presentation"
          description="Something went wrong when cloning the template, please try again later"
        />
      )}
    </>
  );
};
