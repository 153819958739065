import React from "react";
import Image from "../../../atoms/image/Image";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import ResultsText from "../results-text/ResultsText";
import DynamicResultsText from "../results-text/DynamicResultsText";

interface Props {
  round: number;
  logo?: string;
  gameName?: string;
}

const RoundResultsSummary: React.FC<Props> = ({ round, logo, gameName }) => {
  const heading = round === 0 ? "INTRODUCTION" : `ROUND ${round} SUMMARY`;

  return (
    <VerticalGroup
      spaceBetweenElements={10}
      style={{ paddingBottom: "6%" }}
      full
      verticalCenter
      center
      wide
    >
      <VerticalGroup spaceBetweenElements={2} center>
        <Image
          src={logo}
          alt="default logo"
          dynamicSize={{ min: "50px", preferred: "16vw", max: "400px" }}
          className="logo"
        ></Image>
        <ResultsText size="xl" bold center colour="white">
          {gameName}
        </ResultsText>
      </VerticalGroup>
      <VerticalGroup spaceBetweenElements={4} center>
        <DynamicResultsText size="2xl" bold colour="white">
          {heading}
        </DynamicResultsText>
      </VerticalGroup>
    </VerticalGroup>
  );
};

export default RoundResultsSummary;
