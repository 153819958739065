import React from "react";
import { useCurrentUser } from "../../../context/userContext";

interface Props {
  roles: API.Role[];
}

const RoleRequired: React.FC<Props> = ({ children, roles }) => {
  const user = useCurrentUser();
  if (user && roles.includes(user?.type)) {
    return <>{children}</>;
  }
  return null;
};

export default RoleRequired;
