import React from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../../tables/TableWithSections";
import pluckResultsByType from "../../utils/pluckResultsByType";
import { formatValue, percentage } from "../../utils/formatters";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["wholesaleFundingCosts"];
  fields: Array<{ field: string; name: string }>;
}

const formatter = (
  val: number,
  section: string | undefined,
  field: string,
): string | null => {
  if (field === "referenceRate") {
    return percentage(val);
  }
  return formatValue(val, 0);
};

const EffectiveWSFRates: React.FC<Props> = ({ data }) => (
  <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
    <TableWithSections
      hasRoundZero
      header="Effective Wholesale Funding Rates"
      data={[
        {
          name: "Reference Rate Costs",
          fields: [
            "totalWholesaleFundingInterestCosts",
            "averageWholesaleFundingBalances",
            "effectiveWholesaleFundingCost",
          ],
          totalFields: ["effectiveWholesaleFundingCost"],
          values: pluckResultsByType(data, "effectiveWsfRates"),
          formatter: (value: number, field: string) => {
            if (field === "effectiveWholesaleFundingCost") {
              return percentage(value);
            }
            return null;
          },
        },
        {
          name: "New Funding Costs",
          fields: [
            "spreadCostsOnly",
            "averageBalances",
            "effectiveSpreadCostOnly",
            "referenceRate",
          ],
          values: pluckResultsByType(
            pluckResultsByType(data, "effectiveWsfRates"),
            "newFundingCosts",
          ),
          formatter: (value: number, field: string) => {
            if (["effectiveSpreadCostOnly", "referenceRate"].includes(field)) {
              return percentage(value);
            }
            return null;
          },
        },
        {
          fields: ["effectiveWholesaleFundingCostNewFundingOnly"],
          isTotalsSection: true,
          values: pluckResultsByType(data, "effectiveWsfRates"),
          formatter: (value: number) => {
            return percentage(value);
          },
        },
      ]}
      formatter={formatter}
    />
  </VerticalGroup>
);

export default EffectiveWSFRates;
