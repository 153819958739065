export const getTeamName = (team: API.GameConnection) => {
  return team.name ? `${team.name} Bank` : `Bank ${team.teamId}`;
};

export const formatTeamName =
  (team: API.GameConnection) => (newName: string) => {
    if (newName && newName.length > 0) {
      return `${newName} Bank`;
    }

    return team.name ? `${team.name} Bank` : `Bank ${team.teamId}`;
  };
