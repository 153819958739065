import {
  createContext,
  useState,
  useCallback,
  useContext,
  useMemo,
  useLayoutEffect,
} from "react";
import API from "../services/api";
import modelAPI from "../services/modelApi";
import gameAPI from "../services/gameApi";
import APIError from "../errors/APIError";
import { NotImplemented } from "../constants/functions";

const USER_KEY = "user";

const userContext = createContext<Context.UserContext>({
  user: null,
  logout: NotImplemented,
  setToken: NotImplemented,
});

interface UserState {
  user: Context.UserContext["user"] | null;
  loading: boolean;
}

const setTokenForAPIs = (token: string) => {
  API.setToken(token);
  modelAPI.setToken(token);
  gameAPI.setToken(token);
};

export function useUserContext(): [
  UserState,
  Context.UserContext["setToken"],
  Context.UserContext["logout"],
] {
  const storedUser = localStorage.getItem(USER_KEY);
  const [userState, setUser] = useState<UserState>({
    user: null,
    loading: storedUser && JSON.parse(storedUser).token ? true : false,
  });

  if (storedUser) {
    setTokenForAPIs(JSON.parse(storedUser).token);
  }

  const setToken = useCallback(async (token: string) => {
    try {
      setTokenForAPIs(token);
      setUser((user) => ({
        ...user,
        loading: true,
      }));
      const { id, firstName, lastName, email, type, state } =
        await API.getUser("me");
      const userData = {
        token: token,
        id,
        firstName,
        lastName,
        email,
        type,
        state,
      };
      localStorage.setItem(USER_KEY, JSON.stringify({ token: userData.token }));
      setUser({ user: userData, loading: false });
    } catch (e) {
      if (
        e instanceof APIError &&
        (e.type === "unauthenticated" || e.status === 404)
      ) {
        setUser({ user: null, loading: false });
        localStorage.removeItem(USER_KEY);
      }
    }
  }, []);

  useLayoutEffect(() => {
    if (storedUser) {
      const userInCache = JSON.parse(storedUser);
      setToken(userInCache.token);
    }
  }, [setToken, storedUser]);

  const logout = useCallback(() => {
    setUser({ user: null, loading: false });
    setToken("");
  }, [setToken]);

  return [userState, setToken, logout];
}

export const useCurrentUser = (): Context.UserContext["user"] | null => {
  const { user } = useContext(userContext);

  return user;
};

export const useUserActions = () => {
  const { setToken, logout } = useContext(userContext);

  return useMemo(
    () => ({
      setToken,
      logout,
    }),
    [setToken, logout],
  );
};

export default userContext;
