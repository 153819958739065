import React, { useCallback } from "react";
import ReactToggle from "react-toggle";
import classNames from "classnames";

import "react-toggle/style.css";
import "./toggle.scss";

import InputContainer from "../form/input/InputContainer";

interface Props extends React.ComponentProps<typeof InputContainer> {
  checked: boolean;
  onUpdate: (checked: boolean) => void;
  "data-test"?: string;
}

const Toggle: React.FC<Props> = ({
  checked,
  onUpdate,
  label,
  className,
  block,
  noBorder,
  labelFor,
  error,
  helpDescription,
  helpTitle,
  ...props
}) => {
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onUpdate(e.target.checked);
    },
    [onUpdate],
  );

  return (
    <InputContainer
      className={classNames(className, { block, small: true })}
      noBorder={noBorder}
      label={label}
      labelFor={labelFor}
      error={error}
      helpDescription={helpDescription}
      helpTitle={helpTitle}
      data-test={props["data-test"] || "toggle"}
      reverse={props["reverse"]}
    >
      <ReactToggle className="toggle" checked={checked} onChange={onChange} />
    </InputContainer>
  );
};

export default Toggle;
