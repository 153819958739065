import React from "react";

export interface StepperState {
  activeIndex: number;
  totalSteps: number;
}

/**
 * Action
 */
interface ForwardStep {
  type: "ForwardStep";
}

interface BackStep {
  type: "BackStep";
}

interface JumpToStep {
  type: "JumpToStep";
  payload: number;
}

type Actions = ForwardStep | BackStep | JumpToStep;

/**
 * Reducer
 */

export const reducer: React.Reducer<StepperState, Actions> = (
  state,
  action,
) => {
  switch (action.type) {
    case "ForwardStep":
      if (state.activeIndex + 1 < state.totalSteps) {
        return {
          ...state,
          activeIndex: state.activeIndex + 1,
        };
      }
      return state;
    case "BackStep":
      if (state.activeIndex > 0) {
        return {
          ...state,
          activeIndex: state.activeIndex - 1,
        };
      }
      return state;
    case "JumpToStep":
      if (action.payload >= 0 && action.payload < state.totalSteps) {
        return {
          ...state,
          activeIndex: action.payload,
        };
      }
      return state;
    default:
      return state;
  }
};
