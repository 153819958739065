import { useReducer, useCallback, useMemo, createContext } from "react";
import { reducer } from "./state";
import { Noop } from "../../../constants/functions";

interface Context {
  activeIndex: number;
  forwardStep: () => void;
  backStep: () => void;
  jumpToStep: (index: number) => void;
}

export const StepperContext = createContext<Context>({
  activeIndex: 0,
  forwardStep: Noop,
  backStep: Noop,
  jumpToStep: Noop,
});

export const useStepperContextValue = (
  totalSteps: number,
  initialActiveIndex = 0,
) => {
  const [state, dispatch] = useReducer(reducer, {
    activeIndex: initialActiveIndex,
    totalSteps,
  });

  const forwardStep = useCallback(
    () => dispatch({ type: "ForwardStep" }),
    [dispatch],
  );
  const backStep = useCallback(
    () => dispatch({ type: "BackStep" }),
    [dispatch],
  );
  const jumpToStep = useCallback(
    (index: number) => {
      dispatch({
        type: "JumpToStep",
        payload: index,
      });
    },
    [dispatch],
  );

  const context = useMemo(
    () => ({
      activeIndex: state.activeIndex,
      forwardStep,
      backStep,
      jumpToStep,
    }),
    [backStep, forwardStep, jumpToStep, state.activeIndex],
  );

  return context;
};
