import React from "react";

import "./Avatar.scss";

import Image, { Props as ImageProps } from "../image/Image";
import Text from "../text/Text";
import InlineGroup from "../inlinegroup/InlineGroup";
import Tooltip from "../tooltip/Tooltip";
import classNames from "classnames";
import { ThemeColours } from "../../../types/theme";
export interface Props extends ImageProps {
  id: string;
  className?: string;
  src: string | undefined;
  defaultText: string;
  colour?: ThemeColours;
}

function Avatar({ id, src, colour, defaultText, ...rest }: Props) {
  const sizeClass = rest.size ? `size-${rest.size}` : null;
  const colourClass = colour ? `colour-${colour}` : null;
  const avatar = src ? (
    <Image circle src={src} {...rest} data-tip data-for={id} />
  ) : (
    <InlineGroup
      className={classNames("avatar", sizeClass, colourClass)}
      verticalCenter
      center
      data-tip
      data-for={id}
    >
      <Text size="sm" bold colour="white">
        {defaultText}
      </Text>
    </InlineGroup>
  );
  return (
    <>
      {avatar}
      <Tooltip id={id}>{rest.alt}</Tooltip>
    </>
  );
}

export default Avatar;
