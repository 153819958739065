import React, { useState } from "react";
import { useCallback } from "react";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import ModelAPI from "../../../services/modelApi";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import Banner from "../../atoms/banner/Banner";

import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import IconButton from "../../molecules/iconbutton/IconButton";
import { wait } from "../../../lib/wait";
import openNewTab from "../../../lib/openNewTab";
import RoleRequired from "../../molecules/role-required/RoleRequired";
interface Props {
  eventId: string;
  selectedRound: number;
  data: any;
  refresh: () => void;
  error: Error | null;
  inProgress: boolean;
  children: React.ReactNode;
}

const formatError = (error: any) => {
  if (error.errors && error.errors.length > 0) {
    const firstError = error.errors[0];
    return `Schema validation error: ${firstError.path} (${firstError.value} )${firstError.message}`;
  }
  return error?.message ?? "";
};

const ModelPageContainer: React.FC<Props> = ({
  eventId,
  selectedRound,
  data,
  refresh,
  error,
  inProgress,
  children,
}) => {
  const [recalculateError, setRecalculateError] = useState(null);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [calcInProgress, setCalcInProgress] = useState<
    "calc" | "sculpt" | "reporting" | "upload-results" | null
  >(null);

  const onRecalculateAndSculptAll = useCallback(async () => {
    setRecalculateError(null);
    setCalcInProgress("calc");
    try {
      await ModelAPI.recalculateAndSculptAllResults(eventId, selectedRound);
    } catch (e) {
      setRecalculateError((e as any).message);
    }
    setCalcInProgress(null);
    refresh();
  }, [eventId, selectedRound, refresh]);

  const onRecalculateAndReportingAll = useCallback(async () => {
    setRecalculateError(null);
    setCalcInProgress("calc");
    try {
      await ModelAPI.onRecalculateAndReportingAll(eventId, selectedRound);
    } catch (e) {
      setRecalculateError((e as any).message);
    }
    setCalcInProgress(null);
    refresh();
  }, [eventId, selectedRound, refresh]);

  const onRecalcRound = useCallback(async () => {
    setRecalculateError(null);
    setCalcInProgress("calc");
    try {
      await ModelAPI.recalculateAll(eventId, selectedRound);
    } catch (e) {
      setRecalculateError((e as any).message);
    }
    setCalcInProgress(null);
    refresh();
  }, [eventId, refresh, selectedRound]);

  const onRecalculateAll = useCallback(async () => {
    setRecalculateError(null);
    setCalcInProgress("calc");
    try {
      await ModelAPI.recalculateAllResults(eventId, selectedRound);
    } catch (e) {
      setRecalculateError((e as any).message);
    }
    setCalcInProgress(null);
    refresh();
  }, [eventId, selectedRound, refresh]);

  const onRecalculate = useCallback(async () => {
    setRecalculateError(null);
    setCalcInProgress("calc");
    try {
      await ModelAPI.recalculateResults(eventId, selectedRound);
    } catch (e) {
      setRecalculateError((e as any).message);
    }
    setCalcInProgress(null);
    refresh();
  }, [eventId, selectedRound, refresh]);

  const onSculpt = useCallback(async () => {
    setRecalculateError(null);
    setCalcInProgress("sculpt");
    try {
      await ModelAPI.sculptResults(eventId, selectedRound);
      let iteration = 0;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        iteration++;
        const configuration = await ModelAPI.getModelConfiguration(eventId);
        if (configuration.state === "round-sculpted") {
          break;
        }
        if (iteration > 100) {
          throw new Error("Timed out sculpting");
        }
        await wait(5000);
      }
    } catch (e) {
      setRecalculateError((e as any).message);
    }
    setCalcInProgress(null);
    refresh();
  }, [eventId, selectedRound, refresh]);

  const onRecalculateReporting = useCallback(async () => {
    setRecalculateError(null);
    setCalcInProgress("reporting");
    try {
      await ModelAPI.recalculateReporting(eventId, selectedRound);
    } catch (e) {
      setRecalculateError((e as any).message);
    }
    setCalcInProgress(null);
    refresh();
  }, [eventId, selectedRound, refresh]);

  const onPublishReporting = useCallback(async () => {
    setRecalculateError(null);
    setCalcInProgress("reporting");
    try {
      await ModelAPI.publishReporting(eventId, selectedRound);
    } catch (e) {
      setRecalculateError((e as any).message);
    }
    setCalcInProgress(null);
    refresh();
  }, [eventId, selectedRound, refresh]);

  const onUploadResults = useCallback(async () => {
    setCalcInProgress("upload-results");

    try {
      await ModelAPI.uploadResults(eventId, selectedRound + 1);
    } catch (e) {
      setRecalculateError((e as any).message);
    }
    setCalcInProgress(null);
  }, [eventId, selectedRound]);

  const onRefresh = useCallback(async () => {
    refresh();
  }, [refresh]);

  return (
    <VerticalGroup spaceBetweenElements={2} wide>
      <InlineGroup spaceBetweenElements={2} right block>
        <IconButton
          icon="calculator"
          text="Recalculate"
          onClick={onRecalculate}
          inProgress={calcInProgress === "calc"}
        />
        <IconButton
          icon="sculpting"
          text="Sculpt"
          onClick={onSculpt}
          inProgress={calcInProgress === "sculpt"}
        />
        <IconButton
          icon="report"
          text="Calculate Reporting"
          onClick={onRecalculateReporting}
          inProgress={calcInProgress === "reporting"}
        />
        <IconButton
          icon="publish"
          text="Publish Reporting"
          onClick={onPublishReporting}
          inProgress={calcInProgress === "reporting"}
        />
        <IconButton
          icon="eye"
          onClick={() =>
            openNewTab(
              `${window.location.origin}/results/games/${eventId}/rounds/${selectedRound}`,
            )
          }
          text="View Reporting"
        />
        <IconButton
          icon="upload"
          text="Upload Results"
          onClick={onUploadResults}
          inProgress={calcInProgress === "upload-results"}
        />
        <IconButton
          icon="refresh"
          text="Refresh"
          onClick={onRefresh}
          inProgress={inProgress}
        />
        {process.env.REACT_APP_CALC_ALL_ROUND_ENABLED && (
          <RoleRequired roles={["superadmin"]}>
            <IconButton
              icon="more"
              text="Advanced"
              onClick={() => setShowAdvanced(!showAdvanced)}
            />
          </RoleRequired>
        )}
      </InlineGroup>
      {showAdvanced && (
        <InlineGroup spaceBetweenElements={2} right block>
          {process.env.REACT_APP_CALC_ALL_ROUND_ENABLED && (
            <IconButton
              icon="calculator"
              text="Calculate + Sculpt + Reporting (Current Round)"
              onClick={onRecalcRound}
              inProgress={calcInProgress === "calc"}
            />
          )}
          {process.env.REACT_APP_CALC_ALL_ROUND_ENABLED && (
            <IconButton
              icon="calculator"
              text="Calculate + Sculpt + Reporting (All Rounds)"
              onClick={onRecalculateAndSculptAll}
              inProgress={calcInProgress === "calc"}
            />
          )}
          {process.env.REACT_APP_CALC_ALL_ROUND_ENABLED && (
            <IconButton
              icon="calculator"
              text="Calculate + Reporting (All Rounds)"
              onClick={onRecalculateAndReportingAll}
              inProgress={calcInProgress === "calc"}
            />
          )}
          {process.env.REACT_APP_CALC_ALL_ROUND_ENABLED && (
            <IconButton
              icon="calculator"
              text="Calculate (All Rounds)"
              onClick={onRecalculateAll}
              inProgress={calcInProgress === "calc"}
            />
          )}
        </InlineGroup>
      )}
      {(!!error || !!recalculateError) && (
        <Banner
          type="error"
          active
          message={error ? formatError(error) : recalculateError ?? ""}
        />
      )}
      {inProgress && !data && <LoadingSpinner />}
      {!error && !recalculateError && !!data && children}
    </VerticalGroup>
  );
};

export default ModelPageContainer;
