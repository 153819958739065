import React, { useCallback, useContext, useEffect, useState } from "react";

import API from "../../../../../services/api";
import Banner from "../../../../atoms/banner/Banner";
import Form from "../../../../atoms/form/Form";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import { SimulationContext } from "../../context";
import useForm from "../../../../../hooks/useForm";
import Radio from "../../../../atoms/form/input/Radio";
import Checkbox from "../../../../atoms/form/input/Checkbox";
import Text from "../../../../atoms/text/Text";
import ConfirmModal from "../../../../organisms/confirm-modal/ConfirmModal";

export const getEnabledDivisions = (
  businessSetup: API.SimulationUpdateRequest["businessSetup"],
) => {
  const listOfDivisions: string[] = [];
  if (businessSetup?.homeLoansEnabled) {
    listOfDivisions.push("Home Loans");
  }
  if (businessSetup?.businessLoansEnabled) {
    listOfDivisions.push("Business Loans");
  }
  if (businessSetup?.depositsEnabled) {
    listOfDivisions.push("Deposits");
  }
  if (businessSetup?.creditCardsEnabled) {
    listOfDivisions.push("Credit Cards");
  }
  if (businessSetup?.wealthManagementEnabled) {
    listOfDivisions.push("Wealth Management");
  }
  if (businessSetup?.institutionalLendingEnabled) {
    listOfDivisions.push("Institutional Lending");
  }
  if (businessSetup?.offshoreBankEnabled) {
    listOfDivisions.push("Offshore Bank");
  }
  return listOfDivisions;
};

const Business: React.FC = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationComplete, setConfirmationComplete] = useState<
    null | (() => Promise<void | false>)
  >(null);
  const {
    config,
    updateConfig,
    assignOnSubmitFunction,
    updateFormStatus,
    jumpToStepByKey,
  } = useContext(SimulationContext);
  const [
    {
      formData: {
        homeLoansEnabled,
        businessLoansEnabled,
        depositsEnabled,
        creditCardsEnabled,
        wealthManagementEnabled,
        institutionalLendingEnabled,
        offshoreBankEnabled,
      },
      error,
      inProgress,
      formUpdated,
    },
    { setFieldSimple, setRadioField, handleSubmit },
  ] = useForm(
    config?.businessSetup
      ? {
          ...config.businessSetup,
        }
      : {
          homeLoansEnabled: true,
          businessLoansEnabled: true,
          depositsEnabled: true,
          creditCardsEnabled: true,
          wealthManagementEnabled: false,
          institutionalLendingEnabled: false,
          offshoreBankEnabled: false,
        },
  );

  const onSubmit = useCallback(async () => {
    if (config) {
      const payload: API.SimulationUpdateRequest = {
        businessSetup: {
          homeLoansEnabled,
          businessLoansEnabled,
          depositsEnabled,
          creditCardsEnabled,
          wealthManagementEnabled,
          institutionalLendingEnabled,
          offshoreBankEnabled,
        },
      };

      const response = await API.editSimulation(config.id, payload);

      updateConfig(response);
      return response;
    }
  }, [
    config,
    homeLoansEnabled,
    businessLoansEnabled,
    depositsEnabled,
    creditCardsEnabled,
    wealthManagementEnabled,
    institutionalLendingEnabled,
    offshoreBankEnabled,
    updateConfig,
  ]);

  const handleWithWarning = useCallback(
    (e?: React.FormEvent<HTMLFormElement>): Promise<false | void> => {
      if (
        config &&
        config.businessSetup &&
        (config.businessSetup.creditCardsEnabled !== creditCardsEnabled ||
          config.businessSetup.wealthManagementEnabled !==
            wealthManagementEnabled ||
          config.businessSetup.institutionalLendingEnabled !==
            institutionalLendingEnabled ||
          config.businessSetup.offshoreBankEnabled !== offshoreBankEnabled)
      ) {
        setShowConfirmation(true);
        setConfirmationComplete(() => () => handleSubmit(onSubmit)(e));
        return Promise.resolve(false);
      }
      return handleSubmit(onSubmit)(e);
    },
    [
      config,
      handleSubmit,
      onSubmit,
      creditCardsEnabled,
      wealthManagementEnabled,
      institutionalLendingEnabled,
      offshoreBankEnabled,
    ],
  );

  const handleConfirm = useCallback(() => {
    confirmationComplete?.();
    setShowConfirmation(false);
    jumpToStepByKey("playersSetup");
  }, [confirmationComplete, jumpToStepByKey]);

  const handleCancel = useCallback(() => {
    setShowConfirmation(false);
    setConfirmationComplete(null);
    if (config && config.generalSetup.rounds) {
      setFieldSimple("rounds")(config?.generalSetup.rounds);
    }
  }, [config, setFieldSimple]);

  useEffect(() => {
    assignOnSubmitFunction(handleWithWarning);
  }, [assignOnSubmitFunction, handleWithWarning]);

  useEffect(() => {
    updateFormStatus({
      inProgress: inProgress,
      formUpdated: formUpdated,
    });
  }, [updateFormStatus, inProgress, formUpdated]);

  const onRadioChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setRadioField([
        "creditCardsEnabled",
        "wealthManagementEnabled",
        "institutionalLendingEnabled",
        "offshoreBankEnabled",
      ])(e.target.value);
    },
    [setRadioField],
  );

  return (
    <div className="step-container">
      {showConfirmation && (
        <ConfirmModal
          isOpen
          onClose={() => setShowConfirmation(false)}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          title="Confirm change of 4th Business"
          description={`Changing the 4th Business will invalidate any config that has been setup for the current 4th Business`}
          confirmTitle={"Ok"}
        />
      )}
      <VerticalGroup spread full>
        <VerticalGroup wide>
          <Banner type="error" active={!!error} message={error?.message} />
          <Form id="simulation-add-form">
            <h3>Business Settings</h3>
            <Text size="sm" bold>
              Compulsory Businesses
            </Text>
            <Checkbox
              disabled
              name="home-loans"
              checked={homeLoansEnabled}
              onChange={(e) =>
                setFieldSimple("homeLoansEnabled")(e.target.checked)
              }
              label="Home Loans"
            />
            <Checkbox
              disabled
              name="business-loans"
              checked={businessLoansEnabled}
              onChange={(e) =>
                setFieldSimple("businessLoansEnabled")(e.target.checked)
              }
              label="Business Loans"
            />
            <Checkbox
              disabled
              name="deposits"
              checked={depositsEnabled}
              onChange={(e) =>
                setFieldSimple("depositsEnabled")(e.target.checked)
              }
              label="Deposits"
            />
            <Text size="sm" bold>
              Select the 4th Business
            </Text>
            <Radio
              name="extra-division"
              checked={creditCardsEnabled}
              value="creditCardsEnabled"
              onChange={onRadioChange}
              label="Credit Cards"
            />
            <Radio
              name="extra-division"
              checked={wealthManagementEnabled}
              value="wealthManagementEnabled"
              onChange={onRadioChange}
              label="Wealth Management"
            />
            <Radio
              name="extra-division"
              checked={institutionalLendingEnabled}
              value="institutionalLendingEnabled"
              onChange={onRadioChange}
              label="Institutional Lending"
            />
            <Radio
              name="extra-division"
              checked={offshoreBankEnabled}
              value="offshoreBankEnabled"
              onChange={onRadioChange}
              label="Offshore Bank"
            />
          </Form>
        </VerticalGroup>
      </VerticalGroup>
    </div>
  );
};

export { default as BusinessDisplay } from "./Display";
export default Business;
