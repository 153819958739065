import React, { useRef } from "react";
import Card from "../../../../atoms/card/Card";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import Text from "../../../../atoms/text/Text";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import CircleIcon from "../../../../atoms/icon/CircleIcon";
import EditableText, {
  Handles as EditableTextHandles,
} from "../../../../atoms/text/EditableText";
import Label from "../../../../atoms/form/label/Label";
import Clickable from "../../../../atoms/clickable/Clickable";
import Icon from "../../../../atoms/icon/Icon";
import { startCase } from "../../../../../services/utils";

interface Props {
  onChange: (
    businessId: string,
    field: keyof API.BusinessConfigResponse,
  ) => (value: string) => void;
  b: API.BusinessConfigResponse;
  index: number;
  type?: string;
}

const EditableField = ({
  onChange,
  value,
  field,
  label,
  type,
}: {
  onChange: (value: string) => void;
  value: string;
  field: string;
  label: string;
  type: "text" | "textbox";
}) => {
  const ref = useRef<EditableTextHandles>(null);
  return (
    <InlineGroup verticalCenter className="min-h-8">
      <Label>{label}</Label>
      <InlineGroup spaceBetweenElements={2}>
        <EditableText
          size="sm"
          ref={ref}
          type={type}
          onChange={onChange}
          value={value}
          alignment="horizontal"
        />
        <Clickable onClick={() => ref.current?.setIsEditing(true)}>
          <Icon
            noMargin
            type="edit"
            tip={{ content: `Edit ${startCase(field)}`, id: `edit-${field}` }}
          />
        </Clickable>
      </InlineGroup>
    </InlineGroup>
  );
};

const getIconName = (type: API.Products) => {
  switch (type) {
    case "HOME_LOANS":
      return "homeLoans";
    case "BUSINESS_LOANS":
      return "businessLoans";
    case "DEPOSITS":
      return "deposits";
    case "CREDIT_CARDS":
      return "creditCards";
    case "WEALTH_MANAGEMENT":
      return "wealthManagement";
    case "INSTITUTIONAL_BANKING":
      return "institutionalBanking";
    case "OFFSHORE_BANK":
      return "offshoreBank";
    default:
      return "homeLoans";
  }
};

const getIconColour = (type: API.Products) => {
  switch (type) {
    case "HOME_LOANS":
      return "orange";
    case "BUSINESS_LOANS":
      return "blue";
    case "DEPOSITS":
      return "purple";
    default:
      return "green";
  }
};

export const BusinessLabel: React.FC<Props> = ({ b, onChange }) => {
  return (
    <Card key={b.id} wide sharp>
      <Text bold>{b.type}</Text>

      <InlineGroup spaceBetweenElements={6} className="mt-2">
        <InlineGroup verticalCenter>
          <CircleIcon
            type={getIconName(b.type)}
            circleBorder={1}
            circleSize={24}
            size={10}
            colour={getIconColour(b.type)}
          />
        </InlineGroup>
        <VerticalGroup>
          <EditableField
            onChange={onChange(b.id, "name")}
            value={b.name}
            field="name"
            label="Name"
            type="text"
          />
          <EditableField
            onChange={onChange(b.id, "priceType1")}
            value={b.priceType1}
            field="priceType1"
            label="Primary Lever Label"
            type="text"
          />
          <EditableField
            onChange={onChange(b.id, "priceType1InfoBody")}
            value={b.priceType1InfoBody}
            field="priceType1InfoBody"
            label="Primary Lever Info"
            type="textbox"
          />
          <EditableField
            onChange={onChange(b.id, "priceType2")}
            value={b.priceType2}
            field="priceType2"
            label="Secondary Lever Label"
            type="text"
          />
          <EditableField
            onChange={onChange(b.id, "priceType2InfoBody")}
            value={b.priceType2InfoBody}
            field="priceType2InfoBody"
            label="Secondary Lever Info"
            type="textbox"
          />
        </VerticalGroup>
      </InlineGroup>
    </Card>
  );
};
