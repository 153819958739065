import { FieldErrors } from "../../../../../../hooks/useForm";

export const getFieldError = (
  fieldIndex: number,
  field: string,
  fieldErrors: FieldErrors = {},
) => {
  const key = `field[${fieldIndex}].${field}`;
  return fieldErrors[key];
};
