import React from "react";

import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Icon from "../../../atoms/icon/Icon";
import { startCase } from "../../../../services/utils";
import { percentage } from "../../model/utils/formatters";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import ResultsText from "../results-text/ResultsText";
import DynamicResultsText from "../results-text/DynamicResultsText";
import EditableReportingHeading from "../components/EditableReportingHeading";
import ResultsTable from "../table/ResultsTable";

interface Props {
  data: ModelAPI.Reporting.MarketDebrief[];
}

const getIconName = (type: API.Products) => {
  switch (type) {
    case "HOME_LOANS":
      return "homeLoans";
    case "BUSINESS_LOANS":
      return "businessLoans";
    case "DEPOSITS":
      return "deposits";
    case "CREDIT_CARDS":
      return "creditCards";
    case "WEALTH_MANAGEMENT":
      return "wealthManagement";
    case "INSTITUTIONAL_BANKING":
      return "institutionalBanking";
    case "OFFSHORE_BANK":
      return "offshoreBank";
    default:
      return "homeLoans";
  }
};

const getIconColour = (type: API.Products) => {
  switch (type) {
    case "HOME_LOANS":
      return "orange";
    case "BUSINESS_LOANS":
      return "blue";
    case "DEPOSITS":
      return "purple";
    default:
      return "green";
  }
};

const RoundResultsMarketDebrief: React.FC<Props> = ({ data }) => {
  return (
    <RoundResultsContainer>
      <VerticalGroup spaceBetweenElements={4} full center wide>
        <EditableReportingHeading defaultHeading="Market Debrief" />
        <VerticalGroup full className="width-90-percent">
          <ResultsTable softGridLines>
            <thead>
              <tr>
                <th style={{ width: "65%" }}></th>
                <th>
                  <ResultsText size="xl" bold center>
                    Growth
                  </ResultsText>
                </th>
                <th>
                  <ResultsText size="xl" bold center>
                    Losses
                  </ResultsText>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((d, i) => (
                <tr key={i}>
                  <td style={{ width: "60%", paddingLeft: "2%" }}>
                    <InlineGroup block verticalCenter spaceBetweenElements={4}>
                      <Icon
                        dynamicSize={{
                          min: "40px",
                          preferred: "4vw",
                          max: "500px",
                        }}
                        type={getIconName(d.type)}
                        colour={getIconColour(d.type)}
                      ></Icon>
                      <DynamicResultsText size="xl" className="ml-2">
                        {startCase(d.name)}
                      </DynamicResultsText>
                    </InlineGroup>
                  </td>
                  <td>
                    <DynamicResultsText size="xl" center>
                      {percentage(d.growth, 1, false, true)}
                    </DynamicResultsText>
                  </td>
                  <td>
                    <DynamicResultsText size="xl" center>
                      {d.losses == null
                        ? "N/A"
                        : d.losses
                            .map((l) => percentage(l, 2, false, true))
                            .join(" - ")}
                    </DynamicResultsText>
                  </td>
                </tr>
              ))}
            </tbody>
          </ResultsTable>
        </VerticalGroup>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsMarketDebrief;
