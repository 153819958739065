import React, { useCallback, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import API from "../../../services/api";
import Container from "../../atoms/page/Page";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import SimulationDelete from "./SimulationDelete";
import Banner from "../../atoms/banner/Banner";
import IconButton from "../../molecules/iconbutton/IconButton";
import { useSimulation } from "../../../hooks/useSimulation";
import SimulationPreviewWithData from "../../organisms/simulation-preview/SimulationPreviewWithData";

interface SimulationState {
  deleteModalOpen: boolean;
}

function SimulationDetails() {
  const { simulationId } = useParams<{ simulationId: string }>();
  const history = useHistory();

  const { inProgress, error, data } = useSimulation(simulationId);

  const [{ deleteModalOpen }, setSimulationState] = useState<SimulationState>({
    deleteModalOpen: false,
  });

  const onSimulationDeleteComplete = useCallback(() => {
    setSimulationState((prevState) => ({
      ...prevState,
      deleteModalOpen: false,
    }));
    history.push("/simulations");
  }, [history]);

  return (
    <>
      {deleteModalOpen && !!data && (
        <SimulationDelete
          isOpen={deleteModalOpen}
          onClose={() =>
            setSimulationState((prevState) => ({
              ...prevState,
              deleteModalOpen: false,
            }))
          }
          onComplete={onSimulationDeleteComplete}
          simulation={data}
        />
      )}

      <Container className="simulation-edit">
        {error && (
          <Banner type="error" active={!!error} message={error?.message} />
        )}
        <InlineGroup spread verticalCenter>
          <h2>Simulation Details</h2>
          {!!data && !inProgress && (
            <InlineGroup spread spaceBetweenElements={2}>
              <IconButton
                data-test={`edit-simulation-${simulationId}`}
                icon="edit"
                text="Edit Simulation"
                linkTo={`/simulations/${simulationId}/edit`}
              />
              <IconButton
                data-test={`clone-simulation-${simulationId}`}
                icon="clone"
                text="Clone Simulation"
                onClick={async () => {
                  const newSimulation = await API.cloneSimulation(simulationId);
                  history.push(`/simulations/${newSimulation.id}/edit`);
                }}
              />
              <IconButton
                danger
                data-test={`delete-simulation-${data.id}`}
                icon="trash"
                text="Delete"
                onClick={() =>
                  setSimulationState((prevState) => ({
                    ...prevState,
                    deleteModalOpen: true,
                  }))
                }
              />
            </InlineGroup>
          )}
        </InlineGroup>
        {inProgress && <LoadingSpinner />}
        {!!data && !inProgress && (
          <SimulationPreviewWithData simulation={data} />
        )}
      </Container>
    </>
  );
}

export default SimulationDetails;
