import { DEFAULT_CHOICES as DEFAULT_CHECKBOXES_CHOICES } from "./FormDetails/CheckboxesFormDetails";
import { DEFAULT_CHOICES } from "./FormDetails/MultipleChoiceFormDetails";
import { DEFAULT_IMAGE_FIELD } from "./FormDetails/ImageFormDetails";
import { DEFAULT_RATING_ITEMS } from "./FormDetails/RatingFormDetails";
import { DEFAULT_WRITTEN_RESPONSE } from "./FormDetails/WrittenResponseFormDetails";
import { Setters } from "../../../../../hooks/useForm";
import { DEFAULT_WRITTEN_TEXT } from "./FormDetails/WrittenTextDetails";
import { DEFAULT_SHORT_WRITTEN_RESPONSE } from "./FormDetails/ShortWrittenResponseDetails";

export const transformMultipleChoiceToCheckboxes = (
  fields: API.AssessmentQuestionFieldResponse[],
): API.AssessmentQuestionFieldResponse[] => {
  let foundFirstSelected = false;
  return fields.map((field) => {
    if (
      field.type === "multiple_choices" &&
      field.config.markCorrect &&
      !foundFirstSelected
    ) {
      foundFirstSelected = true;
      return {
        ...field,
        type: "checkboxes",
        config: {
          ...field.config,
          type: "checkboxes",
        },
      };
    } else {
      return field.type === "multiple_choices"
        ? {
            ...field,
            type: "checkboxes",
            config: {
              ...field.config,
              type: "checkboxes",
              markCorrect: false,
              mark: 0,
            },
          }
        : field;
    }
  });
};

export const transformCheckboxesToMultipleChoice = (
  question: API.CheckboxesFieldResponse,
): API.MultipleChoiceFieldResponse => {
  return {
    id: question.id,
    type: "multiple_choices",
    config: {
      ...question.config,
      type: "multiple_choices",
    },
  };
};

export const changeQuestionType = (
  setFieldRaw: Setters["setFieldRaw"],
  type: API.QuestionType,
  newType: API.QuestionType,
  fields: API.AssessmentQuestionResponse["fields"],
) => {
  switch (newType) {
    case "multiple_choices":
      if (type === "checkboxes") {
        setFieldRaw(
          "fields",
          fields.map((field: API.AssessmentQuestionFieldResponse) => {
            if (field.type === "checkboxes")
              return transformCheckboxesToMultipleChoice(field);
            return field;
          }),
        );
      } else {
        setFieldRaw("fields", [...DEFAULT_CHOICES]);
      }
      break;
    case "checkboxes":
      if (type === "multiple_choices") {
        setFieldRaw("fields", transformMultipleChoiceToCheckboxes(fields)); // Pass the entire fields array here
      } else {
        setFieldRaw("fields", [...DEFAULT_CHECKBOXES_CHOICES]);
      }
      break;
    case "written_response":
      setFieldRaw("fields", [DEFAULT_WRITTEN_RESPONSE]);
      break;
    case "short_written_response":
      setFieldRaw("fields", [DEFAULT_SHORT_WRITTEN_RESPONSE]);
      break;
    case "image":
      setFieldRaw("fields", [DEFAULT_IMAGE_FIELD]);
      break;
    case "rating":
      setFieldRaw("fields", DEFAULT_RATING_ITEMS);
      break;
    case "written_text":
      setFieldRaw("fields", [DEFAULT_WRITTEN_TEXT]);
      break;
  }
  setFieldRaw("type", newType);
};
