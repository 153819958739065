import React from "react";
import "./ProgressBar.scss";

interface Props {
  percentage: number;
}

export const ProgressBar: React.FC<Props> = ({ percentage }) => {
  return (
    <div className="progressbar">
      <div
        className="progressbar-progress"
        style={{
          width: `${percentage}%`,
        }}
      ></div>
    </div>
  );
};
