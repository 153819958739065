interface RemoteAction {
  name: string;
  args?: { [key: string]: any };
}

export const sendRemoteAction = (action: RemoteAction) => {
  const message = {
    type: "action",
    payload: action,
  };

  if (window.parent === window) {
    window.postMessage(JSON.stringify(message), "*");
  } else if (window.parent) {
    window.parent.postMessage(JSON.stringify(message), "*");
  }
};
