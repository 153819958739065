import React from "react";
import AuthenticationError from "../../../errors/AuthenticationError";

interface AuthenticationErrorBoundaryProps {
  fallback: "unmount" | React.ComponentType;
}

interface AuthenticationErrorBoundaryState {
  hasError: boolean;
}

class AuthenticationErrorBoundary extends React.Component<
  AuthenticationErrorBoundaryProps,
  AuthenticationErrorBoundaryState
> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error: Error) {
    return { hasError: error instanceof AuthenticationError };
  }

  componentDidCatch(error: Error) {
    if (!(error instanceof AuthenticationError)) {
      throw error;
    }
  }

  componentDidUpdate() {
    // In development mode, react will bubble the error even when the error is handled
    // so react-create-app will show the overlay, this part of the code only runs in development
    // mode so that developers don't need to manually close the overlays
    // the overlay flash only happens in development mode that can be ignored
    if (
      this.state.hasError &&
      (process.env.NODE_ENV === "development" ||
        process.env.NODE_ENV === "test")
    ) {
      // @ts-expect-error its ok
      import("react-error-overlay").then((m) => {
        setTimeout(() => {
          m.dismissRuntimeErrors();
        }, 1000);
      });
    }
  }

  render() {
    const { fallback: Fallback } = this.props;

    if (this.state.hasError) {
      return Fallback === "unmount" ? null : <Fallback />;
    }
    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}

export default AuthenticationErrorBoundary;
