import React, { useRef, useState, useCallback, useLayoutEffect } from "react";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";
import { useResizeDetector } from "react-resize-detector";
import Text from "../../atoms/text/Text";
import "./IframePreview.scss";

const PreviewSkeleton = ({
  width,
  height,
}: {
  width: number;
  height: number;
}) => {
  return (
    <div
      className={classNames("previewSkeletonContainer")}
      style={{ width: width, height: height }}
    >
      <Skeleton height={height} width={width} />
      <div
        className="previewSkeletonText"
        style={{ width: width, height: height }}
      >
        <Text bold colour="primaryDark">
          Loading...
        </Text>
      </div>
    </div>
  );
};

interface IframePreviewProps {
  targetSrc: string;
  maxContainerHeight: number;
  minIframeHeight?: number;
  minIframeWidth?: number;
  isFixedHeight?: boolean;
  enablePointerEvents?: boolean;
  render?: (dimension: { height: number; width: number }) => void;
}

const IframePreview: React.FC<IframePreviewProps> = (props) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const [isReady, setReady] = useState(false);
  const { width, height, ref } = useResizeDetector({
    refreshMode: "debounce",
    refreshRate: 100,
  });
  const {
    minIframeHeight = 800,
    minIframeWidth = 680,
    maxContainerHeight,
  } = props;

  const onLoad = useCallback(() => {
    setReady(true);
  }, [setReady]);

  useLayoutEffect(() => {
    setReady(false);
  }, [props.targetSrc]);

  const expectedHeight = width
    ? (width / minIframeWidth) * minIframeHeight
    : null;
  const iframeWidth =
    expectedHeight && expectedHeight > maxContainerHeight
      ? minIframeWidth * (expectedHeight / maxContainerHeight)
      : minIframeWidth;
  const translate = ((minIframeHeight - maxContainerHeight) / 2) * -1;

  return (
    <div
      ref={ref}
      style={{ height: maxContainerHeight }}
      className={classNames("previewContainer")}
    >
      <iframe
        ref={iframeRef}
        className={classNames("previewIFrame", {
          isReady,
          disablePointerEvents: !props.enablePointerEvents ?? false,
        })}
        src={props.targetSrc}
        title="preview"
        data-test="preview-iframe"
        onLoad={onLoad}
        style={
          width && height
            ? {
                width: iframeWidth,
                height: minIframeHeight,
                transform: `translate(0, ${translate}px) scale(${width / iframeWidth})`,
              }
            : {}
        }
      ></iframe>
      {!isReady && height && width && (
        <PreviewSkeleton
          height={Math.min(maxContainerHeight, height)}
          width={width}
        />
      )}
      {isReady &&
        height &&
        width &&
        !!props.render &&
        props.render({ height: Math.min(maxContainerHeight, height), width })}
    </div>
  );
};

export default IframePreview;
