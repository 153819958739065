import React from "react";
import Icon from "../../../atoms/icon/Icon";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import image from "../../../../assets/results/logo.png";
import Image from "../../../atoms/image/Image";

import ResultsText from "../results-text/ResultsText";
import useIsMobile from "../../../../hooks/useIsMobile";

interface Props {}

const RoundResultsThankYou: React.FC<Props> = () => {
  const isMobile = useIsMobile();
  return (
    <RoundResultsContainer>
      <VerticalGroup
        verticalCenter
        full
        wide
        center
        style={{ position: "relative" }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: isMobile
              ? "translate(-95%, -49%)"
              : "translate(-85%, -49%)",
          }}
        >
          <Icon
            type="thankyou"
            style={{
              width: isMobile ? "180%" : "150%",
              height: isMobile ? "180%" : "150%",
            }}
          />
        </div>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: isMobile
              ? "translate(-50%, -80%)"
              : "translate(-65%, -90%)",
          }}
        >
          <VerticalGroup
            spaceBetweenElements={isMobile ? 0 : 4}
            verticalCenter
            center
          >
            <Image
              style={{
                width: isMobile ? "20%" : "30%",
              }}
              src={image}
              alt="Thank you"
            />
            <VerticalGroup center>
              <ResultsText bold size="3xl" colour="secondaryBlue">
                Thank You{" "}
              </ResultsText>
              <ResultsText
                lineHeight={16}
                bold
                size="3xl"
                colour="secondaryBlue"
              >
                For Playing!
              </ResultsText>
            </VerticalGroup>
          </VerticalGroup>
        </div>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsThankYou;
