import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import ModelAPI from "../../../services/modelApi";
import api from "../../../services/api";
import { useMemoRequest } from "../../../hooks/useMemoRequest";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import Banner from "../../atoms/banner/Banner";
import SimpleModal from "../../organisms/standard-modal/SimpleModal";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import FacilitatorModelAdjustments from "./FacilitatorModelAdjustments";
import useIsMobile from "../../../hooks/useIsMobile";
import ConfirmModal from "../../organisms/confirm-modal/ConfirmModal";

interface GetConfigurationAndStatusResponse {
  configuration: ModelAPI.ConfigurationResponse;
  status: API.GameStatus;
}

interface GameControllerFacilitatorAdjustmentsModalProps {
  onClose: () => void;
  onRecalculate: () => void;
}

const GameControllerFacilitatorAdjustmentsModal: React.FC<
  GameControllerFacilitatorAdjustmentsModalProps
> = ({ onClose, onRecalculate }) => {
  const isMobile = useIsMobile();
  const { eventId } = useParams<{ eventId: string }>();

  const getConfigurationAndStatus =
    useCallback(async (): Promise<GetConfigurationAndStatusResponse> => {
      const configuration = await ModelAPI.getModelConfiguration(eventId);
      const status = await api.getGameStatus(eventId);
      return { configuration, status };
    }, [eventId]);

  const { inProgress, data, error } =
    useMemoRequest<GetConfigurationAndStatusResponse>(
      getConfigurationAndStatus,
    );

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleClose = useCallback(() => {
    if (hasUnsavedChanges) {
      setShowConfirmModal(true);
    } else {
      onClose();
    }
  }, [hasUnsavedChanges, onClose]);

  const handleConfirmClose = useCallback(() => {
    setShowConfirmModal(false);
    setHasUnsavedChanges(false);
    onClose();
    onRecalculate();
  }, [onRecalculate, onClose]);

  const handleSaveClose = useCallback(() => {
    onClose();
    setShowConfirmModal(false);
  }, [onClose]);

  const handleCancelClose = useCallback(() => {
    setShowConfirmModal(false);
  }, []);

  const handleChangesMade = useCallback((changes: boolean) => {
    setHasUnsavedChanges(changes);
  }, []);

  const handleDiscardChages = useCallback(() => {
    setShowConfirmModal(false);
    setHasUnsavedChanges(false);
    onClose();
  }, [onClose]);

  return (
    <>
      <SimpleModal
        showCloseButton
        showMobileCloseButton={isMobile}
        isOpen
        size={isMobile ? "small" : "medium"}
        onClose={handleClose}
      >
        {inProgress && <LoadingSpinner />}
        {error && <Banner type="error" active message={error.message} />}
        {!inProgress && !error && data?.configuration && data.status && (
          <VerticalGroup full spaceBetweenElements={4}>
            <FacilitatorModelAdjustments
              eventId={eventId}
              selectedRound={data.configuration.currentRound}
              configuration={data.configuration}
              onRecalculate={onRecalculate}
              onChangesMade={handleChangesMade}
              handleCancelClose={handleSaveClose}
            />
          </VerticalGroup>
        )}
      </SimpleModal>

      <ConfirmModal
        isOpen={showConfirmModal}
        onClose={handleCancelClose}
        onCancel={handleCancelClose}
        onDiscard={handleDiscardChages}
        onConfirm={handleConfirmClose}
        title="Discard Changes"
        description="Are you sure you want to close without saving your changes?"
        confirmTitle="Save"
        warning
      />
    </>
  );
};

export default GameControllerFacilitatorAdjustmentsModal;
