import React, { useCallback, useMemo } from "react";
import TeamTabletColourDropdown from "../../../../organisms/team-tablet-colour-dropdown/TeamTabletColourDropdown";
import Table from "../../../../atoms/table/Table";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import Text from "../../../../atoms/text/Text";
import Card from "../../../../atoms/card/Card";
import { EventMapping } from "../utils/mapInitialTabletMappingForEvent";
import { useTabletConfigContext } from "../context/context";

interface ConfigurationTabletsProps {
  eventMapping: EventMapping | null;
  defaultCeoColour: API.TabletColour;
  setDefaultCeoColour: (colour: API.TabletColour) => void;
}

const ConfigurationTablets = ({
  eventMapping,
  defaultCeoColour,
  setDefaultCeoColour,
}: ConfigurationTabletsProps) => {
  const { updateTeamColour, selectedEventId, eventData, tabletDataPerEvent } =
    useTabletConfigContext();

  const availableTabletsByColour: Record<
    API.TabletColour,
    { inStock: number; inUse: number; available: number }
  > = useMemo(() => {
    if (!selectedEventId) return;
    const selectedEventData = eventData[selectedEventId];
    if (!selectedEventData) return;
    const tabletData = tabletDataPerEvent[selectedEventId];
    if (!tabletData) return;
    if (!tabletData) return {};
    return tabletData.tablets.reduce((acc: any, tablet: any) => {
      if (!acc[tablet.colour]) {
        acc[tablet.colour] = {
          inStock: 0,
          inUse: 0,
          available: 0,
        };
      }
      if (tablet.available) {
        acc[tablet.colour].inStock += 1;
        if (tablet.participantId) {
          acc[tablet.colour].inUse += 1;
        } else {
          acc[tablet.colour].available += 1;
        }
      }

      return acc;
    }, {});
  }, [eventData, selectedEventId, tabletDataPerEvent]);

  const updateColourForTeam = useCallback(
    (teamId: number) => (value: API.TabletColour) => {
      updateTeamColour(teamId, value);
    },
    [updateTeamColour],
  );

  return (
    <InlineGroup style={{ paddingTop: "20px" }}>
      {!eventMapping ? (
        <Card>
          <Text>Please select an event from Events</Text>
        </Card>
      ) : !eventMapping.teamColours.length ? (
        <Card>
          <Text>No teams in selected event</Text>
        </Card>
      ) : (
        <InlineGroup block>
          <VerticalGroup spaceBetweenElements={5}>
            <Text size="lg" bold>
              Select Default CEO Colour
            </Text>
            <Card>
              <TeamTabletColourDropdown
                label="Default CEO Colour"
                defaultColourId={defaultCeoColour}
                onColourChange={setDefaultCeoColour}
              />
            </Card>
            <Text size="lg" bold>
              Select Team Tablet Colours
            </Text>
            <Card>
              <VerticalGroup spaceBetweenElements={4}>
                <VerticalGroup spaceBetweenElements={3}>
                  {eventMapping.teamColours.map((colour, i) => (
                    <TeamTabletColourDropdown
                      key={i}
                      label={`Team #${i + 1}`}
                      defaultColourId={colour}
                      onColourChange={updateColourForTeam(i + 1)}
                      omitColours={[defaultCeoColour]}
                    />
                  ))}
                </VerticalGroup>
              </VerticalGroup>
            </Card>
          </VerticalGroup>
          <VerticalGroup wide center>
            <Text size="lg" bold>
              Tablets In Stock
            </Text>
            <VerticalGroup>
              <Table>
                <thead>
                  <tr>
                    <th>Colour</th>
                    <th style={{ textAlign: "center" }}>In Stock</th>
                    <th style={{ textAlign: "center" }}>In Use</th>
                    <th style={{ textAlign: "center" }}>Available</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(availableTabletsByColour).map(
                    ([colour, counts]) => (
                      <tr key={colour}>
                        <td>{colour}</td>
                        <td style={{ textAlign: "center" }}>
                          {counts.inStock}
                        </td>
                        <td style={{ textAlign: "center" }}>{counts.inUse}</td>
                        <td style={{ textAlign: "center" }}>
                          {counts.available}
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              </Table>
            </VerticalGroup>
          </VerticalGroup>
        </InlineGroup>
      )}
    </InlineGroup>
  );
};

export default ConfigurationTablets;
