import React from "react";
import RoundResultsContainer from "../components/RoundsResultsContainer";

const IntroAcknowlegementOfCountryOnline = () => {
  return (
    <div
      className="results-container acknowlege-online"
      style={{
        flex: 1,
        justifyContent: "space-between",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <RoundResultsContainer withoutPadding fullHeight />
    </div>
  );
};

export default IntroAcknowlegementOfCountryOnline;
