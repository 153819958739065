import React, { useCallback, useMemo } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import {
  percentage,
  reportingPercentage,
  percentageAsBps,
} from "../../model/utils/formatters";
import ResultsComposedChart from "../charts/ResultsComposedChart";
import ChartCard from "../components/ChartCard";

import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import ResultsTable from "../table/ResultsTable";
import { TeamsWithColours } from "../RoundResultsPage";

import Theme from "../../../../styles/_theme.module.scss";
import { getYAxisDataV2 } from "../charts/yaxis";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import { useResultsContext } from "../context/context";
import DynamicResultsText from "../results-text/DynamicResultsText";

interface Props {
  teams: TeamsWithColours[];
  marketMargins: ModelAPI.Reporting.MarketMargins;
  pastRound1: boolean;
}

const getChangeColour = (change: number): string => {
  return change === 0 ? "black" : change > 0 ? Theme.green : Theme.red;
};

export const maxClicks = 1;

const MarginChangeLabel: React.FC = (props: any) => {
  const { x, y, value } = props;

  const colour = getChangeColour(value);
  if (value === null) return null;
  const valueToUse =
    value === 0 ? "No Change" : `(${percentageAsBps(value, 2)})`;
  return (
    <g>
      <text
        x={x}
        y={y + 15}
        fill={colour}
        textAnchor="middle"
        fontWeight="bold"
        fontSize={16}
      >
        {valueToUse}
      </text>
    </g>
  );
};

const RoundResultsMarketMargins: React.FC<Props> = ({
  teams,
  marketMargins,
  pastRound1,
}) => {
  const context = useResultsContext();
  const humanTeams = useMemo(() => teams.filter((t) => !t.isCpuTeam), [teams]);

  const clicksLeft = useMemo(
    () => (context.noAnimations ? 0 : maxClicks - context.clicks),
    [context.clicks, context.noAnimations],
  );

  const data = useMemo(() => {
    return marketMargins.data.map((d, i) => {
      const margin = Number(percentage(d.margin, 2, true, true));
      const cost = Number(percentage(d.cost, 2, true, true));
      const rate = cost + margin;
      return {
        name: d.name,
        net: [cost, rate],
        rate,
        cost,
        margin,
        marginChange: i === 0 ? null : d.marginChange ?? 0,
        midpoint: cost + margin / 2,
      };
    });
  }, [marketMargins]);

  const { minValue, maxValue, numberOfTicks } = getYAxisDataV2({
    pastRound1,
    isPercentage: true,
    data: data.map((d) => ({
      name: "Anything",
      min: d.net[0],
      max: d.net[1],
    })),
  });

  const onNext = useCallback(() => {
    if (context.noAnimations) {
      context.goNextPage();
      return;
    }
    if (context.clicks === maxClicks) {
      context.goNextPage();
    } else {
      context.addClick();
    }
  }, [context]);

  return (
    <RoundResultsContainer onNext={onNext}>
      <VerticalGroup full center wide>
        <ChartCard heading="Market Margins">
          <ResultsComposedChart
            data={data}
            formatter={(val: number) => reportingPercentage(val)}
            marginChangeLabel={MarginChangeLabel}
            yAxisFormatter={(val: number) => reportingPercentage(val, 1)}
            // figure out how to get these values like with yaxis.ts
            minYAxisvalue={minValue}
            maxYAxisvalue={maxValue}
            numberOfTicks={numberOfTicks}
          />
          <InlineGroup
            block
            center
            verticalCenter
            className="mt-2"
            style={{ height: "30%", width: "90%" }}
          >
            {clicksLeft < maxClicks && (
              <ResultsTable id="999">
                <thead>
                  <tr>
                    {humanTeams.map((t) => (
                      <th key={t.teamId}>
                        <DynamicResultsText size="lg">
                          {t.teamName}
                        </DynamicResultsText>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {humanTeams.map((t) => (
                      <th
                        key={t.teamId}
                        style={{
                          color: getChangeColour(
                            marketMargins.teams[t.teamId - 1].change,
                          ),
                        }}
                      >
                        <DynamicResultsText size="md">
                          {marketMargins.teams[t.teamId - 1].change === 0
                            ? "No Change"
                            : percentageAsBps(
                                marketMargins.teams[t.teamId - 1].change,
                                2,
                              )}
                        </DynamicResultsText>
                      </th>
                    ))}
                  </tr>
                  <tr>
                    {humanTeams.map((t) => (
                      <th key={t.teamId}>
                        <DynamicResultsText size="md">
                          {percentage(
                            marketMargins.teams[t.teamId - 1].margin,
                            2,
                          )}
                        </DynamicResultsText>
                      </th>
                    ))}
                  </tr>
                </tbody>
              </ResultsTable>
            )}
          </InlineGroup>
        </ChartCard>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsMarketMargins;
