import React from "react";
import classNames from "classnames";

import "./StickyBar.scss";
import InlineGroup, {
  Props as InlineGroupProps,
} from "../../atoms/inlinegroup/InlineGroup";
import { ThemeSpacing } from "../../../types/theme";

interface Props extends InlineGroupProps {
  children: React.ReactNode;
  className?: string;
  height?: ThemeSpacing;
}

function StickyBar({ children, className, height, ...rest }: Props) {
  const heightClass = height ? `height-${height}` : null;
  return (
    <InlineGroup
      spaceBetweenElements={2}
      className={classNames("sticky-bar", heightClass, className)}
      {...rest}
    >
      {children}
    </InlineGroup>
  );
}

export default StickyBar;
