import React, { useCallback } from "react";
import ConfirmModal from "../../../organisms/confirm-modal/ConfirmModal";

import useAPIRequest from "../../../../hooks/useAPIRequest";
import API from "../../../../services/api";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  client: API.ClientResponse;
}

function ClientToggle({ isOpen, onComplete, onClose, client }: Props) {
  const callback = useCallback(() => {
    return API.editClient(client.id, {
      state: "disabled",
      name: client.name,
      userIds: client.userIds,
    });
  }, [client]);

  const [{ inProgress, error }, doAPIRequest] = useAPIRequest(callback, null);

  const handleConfirm = () => {
    doAPIRequest(onComplete);
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      title={"Confirm Client Deactivation"}
      description="When a client account is deactivated, all users linked to that client will no longer be able to access this portal. They will regain access if the account is reactivated."
      confirmTitle={"Deactivate"}
      error={error && error.message}
      warning
    />
  );
}

export default ClientToggle;
