import React from "react";
import Avatar from "../../atoms/avatar/Avatar";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";

interface Props {
  clients: API.ClientResponse[];
  numberToShow?: number;
}

const ClientAvatarList: React.FC<Props> = ({
  clients,
  numberToShow = 5,
}: Props) => {
  return (
    <InlineGroup spaceBetweenElements={2}>
      {clients.slice(0, numberToShow).map((client) => (
        <Avatar
          id={client.id}
          data-test={`client-${client.name}`}
          key={client.id}
          size={8}
          src={client.logoUrl}
          alt={client.name}
          defaultText={client.name[0]}
        />
      ))}
      {clients.length > numberToShow ? (
        <Avatar
          id="more"
          size={8}
          defaultText="+"
          src={undefined}
          alt="More clients"
        />
      ) : null}
    </InlineGroup>
  );
};

export default ClientAvatarList;
