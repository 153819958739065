import dateFormat from "dateformat";
import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useCurrentEvent } from "../../../../context/CurrentEventContext";
import { useAssessmentResponses } from "../../../../hooks/useAssessments";
import Clickable from "../../../atoms/clickable/Clickable";
import Icon from "../../../atoms/icon/Icon";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import Ribbon from "../../../atoms/ribbon/Ribbon";
import Table from "../../../atoms/table/Table";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import EmptyList from "../../../organisms/empty-list/EmptyList";
import { EventAssessmentContainer } from "./components/EventAssessmentContainer";
import { MarkAssessmentModal } from "./MarkAssessmentModal";
import { getAnswersForRound } from "./utils";

const ParticipantFirstName = ({
  event,
  participantId,
}: {
  event: API.EventResponse;
  participantId: string;
}) => {
  const { participants } = event;

  const participant = participants.find((p) => p.id === participantId);
  if (!participant) return null;
  return <>{participant.firstName}</>;
};

const ParticipantLastName = ({
  event,
  participantId,
}: {
  event: API.EventResponse;
  participantId: string;
}) => {
  const { participants } = event;

  const participant = participants.find((p) => p.id === participantId);
  if (!participant) return null;
  return <>{participant.lastName}</>;
};

const getMarkPercentage = (
  answers: API.AssessmentAnswerResponse["answers"],
  questions: API.AssessmentQuestionResponse[],
): number => {
  const totalMarks = answers.map((a) => a.mark || 0).reduce((a, b) => a + b, 0);
  const totalPoints = questions
    .map((q) => q.marks || 0)
    .reduce((a, b) => a + b, 0);
  return Math.round((totalMarks / totalPoints) * 100);
};

const isAllMarked = (
  answers: API.AssessmentAnswerResponse["answers"],
  round: number,
): boolean => {
  const answersForRound = answers.filter((a) => a.roundId === round);
  const total = answersForRound.length;
  const marks = answersForRound.filter((a) => a.mark !== null).length;
  return marks === total;
};

const answersForRoundIsNotEmpty = (
  answers: API.AssessmentAnswerResponse["answers"],
  round: number,
): boolean => {
  const answersForRound = getAnswersForRound(answers, round);
  return answersForRound.length > 0;
};

const getDateForRound = (
  answers: API.AssessmentAnswerResponse["answers"],
  round: number,
): string => {
  const answersForRound = getAnswersForRound(answers, round);

  if (!answersForRound.length) {
    return "";
  }

  const maxDate = answersForRound.reduce((max, a) => {
    const current = new Date(a.createdAt);
    return current > max ? current : max;
  }, new Date(answersForRound[0].createdAt));

  return dateFormat(maxDate, "dd-mmm-yy");
};

const switchParticipant = (
  answers: API.AssessmentAnswerResponse[] | null,
  event: API.EventResponse | null,
  current: API.ParticipantResponse | null,
  indexFn: (idx: number) => number,
) => {
  if (!current) return null;
  if (!answers) return null;
  if (!event) return null;

  const { id } = current;

  const currentIndex = answers.findIndex((a) => a.participantId === id);

  if (typeof currentIndex === "undefined" || currentIndex === -1) return null;

  const nextIndex =
    indexFn(currentIndex) < 0
      ? answers.length - 1
      : indexFn(currentIndex) > answers.length - 1
        ? 0
        : indexFn(currentIndex);
  const nextAnswer = answers[nextIndex];

  if (typeof nextAnswer === "undefined") return null;

  const next = event.participants.find(
    (p) => p.id === nextAnswer.participantId,
  );

  if (!next) return null;
  return next;
};
const hasUnmarkableTypesOnly = (
  questions: API.AssessmentQuestionResponse[],
): boolean => {
  return questions.every(
    (q) => q.type === "written_text" || q.type === "image",
  );
};
export const AssesssmentResponses = () => {
  const [lastUsedIndex, setLastUsedIndex] = useState(0);
  const [currentRound, setRound] = useState(0);
  const [currentSelectedParticipant, setParticipant] =
    useState<API.ParticipantResponse | null>(null);
  const { eventId } = useParams<{ eventId: string }>();
  const { event, inProgress: isLoadingEvent } = useCurrentEvent();

  const {
    data: answers,
    refresh,
    inProgress: isLoadingAnswers,
  } = useAssessmentResponses(eventId);

  const answersForCurrentParticipantAndRond = useMemo(() => {
    if (!answers) return [];
    if (!currentSelectedParticipant) return [];
    const answersForCurrentParticipant = answers.filter(
      (a) => a.participantId === currentSelectedParticipant.id,
    );
    return answersForCurrentParticipant
      .flatMap((a) => a.answers)
      .filter((a) => a.roundId === currentRound + 1);
  }, [answers, currentRound, currentSelectedParticipant]);

  const questionsForCurrentRound = useMemo(() => {
    if (!event || !event.assessment) return [];
    const { assessment } = event;
    return (
      assessment.groups.find((g) => g.round === currentRound + 1)?.questions ||
      []
    );
  }, [currentRound, event]);

  const areAllTypesUnmarkable = useMemo(() => {
    return hasUnmarkableTypesOnly(questionsForCurrentRound);
  }, [questionsForCurrentRound]);

  const answersForRound = useMemo(() => {
    if (!answers) return [];
    return answers.filter((a) =>
      answersForRoundIsNotEmpty(a.answers, currentRound + 1),
    );
  }, [answers, currentRound]);

  const onNextParticipant = useCallback(
    ({ recordedAnswerIndex }: { recordedAnswerIndex: number }) => {
      setLastUsedIndex(recordedAnswerIndex);
      setParticipant((current) =>
        switchParticipant(answersForRound, event, current, (idx) => idx + 1),
      );
    },
    [answersForRound, event],
  );

  const onPreviousParticipant = useCallback(
    ({ recordedAnswerIndex }: { recordedAnswerIndex: number }) => {
      setLastUsedIndex(recordedAnswerIndex);
      setParticipant((current) =>
        switchParticipant(answersForRound, event, current, (idx) => idx - 1),
      );
    },
    [answersForRound, event],
  );

  const onCloseModal = useCallback(() => {
    setParticipant(null);
  }, []);

  if ((isLoadingAnswers && (!answers || !answers.length)) || isLoadingEvent) {
    return <LoadingSpinner />;
  }

  if (!event || !answers || !answers.length) {
    return <EmptyList icon="assessments" message="There are no answers yet" />;
  }

  const { assessment } = event;

  if (!assessment) {
    return (
      <EmptyList icon="assessments" message="The assessment does not exist" />
    );
  }

  const { groups } = assessment;
  const maxRound = Math.max(...groups.map((g) => g.round));
  const rounds = [...Array(maxRound).keys()];

  return (
    <EventAssessmentContainer>
      <VerticalGroup>
        <InlineGroup
          className={"h-16 mt-2 assessment-ribbon"}
          spaceBetweenElements={2}
          block
        >
          {rounds.map((round) => {
            const group = groups.find((g) => g.round - 1 === round);
            return (
              <>
                <Ribbon
                  key={round}
                  first={round === 0}
                  last={round === maxRound}
                  active={round === currentRound}
                  title={`Round ${round}`}
                  description={`${group ? group.name : ""}`}
                  onClick={() => setRound(round)}
                />
              </>
            );
          })}
        </InlineGroup>

        {questionsForCurrentRound.length === 0 && (
          <VerticalGroup className="pt-16" full center verticalCenter wide>
            <EmptyList
              icon="assessments"
              message="There are no questions for this round."
            />
          </VerticalGroup>
        )}

        {questionsForCurrentRound.length > 0 && areAllTypesUnmarkable && (
          <VerticalGroup className="pt-16" full center verticalCenter wide>
            <EmptyList
              icon="assessments"
              message="Questions in this round don't require marking."
            />
          </VerticalGroup>
        )}

        {questionsForCurrentRound.length > 0 &&
          answersForRound.length === 0 &&
          !areAllTypesUnmarkable && (
            <VerticalGroup className="pt-16" full center verticalCenter wide>
              <EmptyList
                icon="assessments"
                message="No answers have been submitted for this round."
              />
            </VerticalGroup>
          )}

        {answersForRound.length > 0 && (
          <Table clickable>
            <thead>
              <tr>
                <th>Marked</th>
                <th>Date</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Mark</th>
                <th className="right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {answersForRound.map((answer) => {
                return (
                  <tr
                    key={answer.participantId}
                    onClick={() => {
                      setParticipant(
                        event.participants.find(
                          (p) => p.id === answer.participantId,
                        ) || null,
                      );
                    }}
                  >
                    <td>
                      {isAllMarked(answer.answers, currentRound + 1) && (
                        <Icon type="tick" />
                      )}
                    </td>
                    <td>{getDateForRound(answer.answers, currentRound + 1)}</td>
                    <td>
                      <ParticipantFirstName
                        participantId={answer.participantId}
                        event={event}
                      />
                    </td>
                    <td>
                      <ParticipantLastName
                        participantId={answer.participantId}
                        event={event}
                      />
                    </td>
                    <td>
                      {getMarkPercentage(
                        getAnswersForRound(answer.answers, currentRound + 1),
                        questionsForCurrentRound,
                      )}
                      %
                    </td>
                    <td className="right">
                      <Clickable
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        <Icon
                          type="trash"
                          tip={{
                            content: "Delete answer for participant",
                            id: `${answer.participantId}-delete`,
                          }}
                        />
                      </Clickable>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
        {currentSelectedParticipant && (
          <MarkAssessmentModal
            onClose={onCloseModal}
            participant={currentSelectedParticipant}
            totalParticipantCount={answersForRound.length}
            currentParticipantIndex={
              answersForRound.findIndex(
                (a) => a.participantId === currentSelectedParticipant.id,
              ) + 1
            }
            defaultQuestionIndex={lastUsedIndex}
            currentRound={currentRound}
            answers={answersForCurrentParticipantAndRond}
            questions={questionsForCurrentRound}
            onNextParticipant={onNextParticipant}
            onPreviousParticipant={onPreviousParticipant}
            onAnswerUpdated={refresh}
          />
        )}
      </VerticalGroup>
    </EventAssessmentContainer>
  );
};
