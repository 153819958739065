import React from "react";
import ReactDOM from "react-dom";
import ReactModal from "react-modal";
import "./index.scss";
import App from "./App";

ReactModal.setAppElement("#root");

// @ts-expect-error it's okay to use window
if (window.Cypress) {
  if (
    process.env.NODE_ENV === "development" ||
    process.env.NODE_ENV === "test"
  ) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const overlay = require("react-error-overlay");
    try {
      overlay.stopReportingRuntimeErrors();
    } catch {
      // do not show overlay in cypress, and ignore this error
    }
  }

  // In development mode, react will bubble the error even when the error is handled
  // so react-create-app will show the overlay, this part of the code only runs in development
  // this only mutes the custom error we created
  // @ts-expect-error it's okay to use window
  window.Cypress.on("uncaught:exception", (error) => {
    if (error.isCustomError) {
      return false;
    }

    // https://github.com/facebook/create-react-app/issues/10611
    if (
      error.message &&
      error.message.includes("Expected options to be injected")
    ) {
      return false;
    }

    return true;
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);
