import React from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";

const StepContainer: React.FC = ({ children }) => {
  return (
    <div className="step-container">
      <VerticalGroup spread full wide center>
        <VerticalGroup wide style={{ maxWidth: 960 }}>
          {children}
        </VerticalGroup>
      </VerticalGroup>
    </div>
  );
};

export default StepContainer;
