import React, { useCallback, useState, useEffect } from "react";
import SimpleModal from "../../organisms/standard-modal/SimpleModal";
import NumberInput from "../../atoms/form/input/NumberInput";
import InlineError from "../../atoms/inlineerror/InlineError";
import Text from "../../atoms/text/Text";
import Button from "../../atoms/button/Button";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import { formatISOStringWithDateFormat } from "../../../lib/date";
import Datepicker from "../../atoms/datepicker/Datepicker";
import Container from "../../atoms/page/Page";

import Toggle from "../../atoms/toggle/Toggle";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (totalSeconds: number) => void;
  currentTime: number;
}

const UpdateEndDateModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onUpdate,
  currentTime,
}) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [timeError, setTimeError] = useState<string | undefined>(undefined);
  const [useDatePicker, setUseDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  useEffect(() => {
    if (isOpen) {
      setDays(0);
      setHours(0);
      setMinutes(0);
      setTimeError(undefined);
      setSelectedDate(new Date(new Date().getTime() + currentTime * 1000));
    }
  }, [isOpen, currentTime]);

  const handleDaysChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setDays(Number(e.target.value));
    },
    [],
  );

  const handleHoursChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setHours(Number(e.target.value));
    },
    [],
  );

  const handleMinutesChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setMinutes(Number(e.target.value));
    },
    [],
  );

  const calculateAdjustmentSeconds = useCallback(() => {
    if (useDatePicker && selectedDate) {
      const currentDate = new Date(new Date().getTime() + currentTime * 1000);
      return Math.floor(
        (selectedDate.getTime() - currentDate.getTime()) / 1000,
      );
    }
    return days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60;
  }, [days, hours, minutes, useDatePicker, selectedDate, currentTime]);

  const wouldResultInNegativeTime = useCallback(
    (adjustment: number) => {
      return currentTime + adjustment < 0;
    },
    [currentTime],
  );

  const getAdjustedEndTime = useCallback(() => {
    if (useDatePicker && selectedDate) {
      return formatISOStringWithDateFormat(selectedDate.toISOString());
    }
    const baseEndTime = new Date(new Date().getTime() + currentTime * 1000);
    const adjustmentInMilliseconds = calculateAdjustmentSeconds() * 1000;
    const adjustedDate = new Date(
      baseEndTime.getTime() + adjustmentInMilliseconds,
    );
    return formatISOStringWithDateFormat(adjustedDate.toISOString());
  }, [useDatePicker, selectedDate, currentTime, calculateAdjustmentSeconds]);

  useEffect(() => {
    const adjustment = calculateAdjustmentSeconds();
    if (wouldResultInNegativeTime(adjustment)) {
      setTimeError("Cannot reduce time beyond the current remaining time");
    } else {
      setTimeError(undefined);
    }
  }, [calculateAdjustmentSeconds, wouldResultInNegativeTime]);

  const handleEndTimeUpdate = useCallback(() => {
    onUpdate(calculateAdjustmentSeconds());
  }, [onUpdate, calculateAdjustmentSeconds]);

  return (
    <SimpleModal
      showCloseButton
      size="medium"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Container fit>
        <VerticalGroup wide spaceBetweenElements={4}>
          <InlineGroup block spread>
            <Text bold size="xl">
              Update End Time
            </Text>
            <InlineGroup spaceBetweenElements={2} verticalCenter>
              <Text>Calendar mode</Text>
              <Toggle checked={useDatePicker} onUpdate={setUseDatePicker} />
            </InlineGroup>
          </InlineGroup>
          <InlineError active={!!timeError} message={timeError} />
          {useDatePicker ? (
            <Datepicker
              label="End Date and Time"
              date={selectedDate}
              onChange={setSelectedDate}
              withTime
            />
          ) : (
            <VerticalGroup spaceBetweenElements={4} wide>
              <VerticalGroup
                spread
                spaceBetweenElements={4}
                className="width-50-percent"
              >
                <NumberInput
                  center
                  allowNegative
                  value={days}
                  onChange={handleDaysChange}
                  max={365}
                  min={-365}
                  label="Days"
                  noBorder
                  large
                  block
                />

                <NumberInput
                  noBorder
                  allowNegative
                  value={hours}
                  onChange={handleHoursChange}
                  max={23}
                  min={-23}
                  label="Hours"
                  large
                  block
                />

                <NumberInput
                  noBorder
                  allowNegative
                  value={minutes}
                  onChange={handleMinutesChange}
                  max={59}
                  min={-59}
                  label="Minutes"
                  block
                />
              </VerticalGroup>
              <InlineGroup
                className="p-3"
                block
                center
                border={{ style: "solid", color: "darkgrey", width: "0.5px" }}
              >
                <Text size="lg">End Time: {getAdjustedEndTime()}</Text>
              </InlineGroup>
            </VerticalGroup>
          )}

          <InlineGroup block>
            <Button light wide onClick={onClose}>
              Cancel
            </Button>
            <InlineGroup block right>
              <Button
                wide
                onClick={handleEndTimeUpdate}
                disabled={wouldResultInNegativeTime(
                  calculateAdjustmentSeconds(),
                )}
              >
                Update Timer
              </Button>
            </InlineGroup>
          </InlineGroup>
        </VerticalGroup>
      </Container>
    </SimpleModal>
  );
};

export default UpdateEndDateModal;
