import React, { useCallback } from "react";
import ConfirmModal from "../../../organisms/confirm-modal/ConfirmModal";

import useAPIRequest from "../../../../hooks/useAPIRequest";
import API from "../../../../services/api";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  user: API.UserResponse;
}

function UserToggle({ isOpen, onComplete, onClose, user }: Props) {
  const callback = useCallback(() => {
    const { id, ...rest } = user;
    return API.editUser(id, {
      email: rest.email,
      firstName: rest.firstName,
      lastName: rest.lastName,
      type: rest.type,
      clientIds: rest.clientIds,
      state: "disabled",
    });
  }, [user]);

  const [{ inProgress, error }, doAPIRequest] = useAPIRequest(callback, null);

  const handleConfirm = () => {
    doAPIRequest(onComplete);
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      title={"Confirm User Deactivation"}
      description="When a user account is deactivated, they will no longer be able to access this portal. They will regain access if the account is reactivated."
      confirmTitle={"Deactivate"}
      error={error && error.message}
      warning
    />
  );
}

export default UserToggle;
