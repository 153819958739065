import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import useForm, { FormError } from "../../../../../hooks/useForm";
import Toggle from "../../../../atoms/toggle/Toggle";
import Text from "../../../../atoms/text/Text";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import { SimulationContext } from "../../context";
import SpecialProjectsSelect from "./SpecialProjectsSelect";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import Dropdown from "../../../../atoms/form/input/Dropdown";
import API from "../../../../../services/api";
import ErrorModal from "../../../../organisms/standard-modal/ErrorModal";
import "./projects.scss";
import Button from "../../../../atoms/button/Button";

const SpecialProjects = () => {
  const { config, updateConfig, assignOnSubmitFunction } =
    useContext(SimulationContext);
  const [isErrorOpen, setErrorOpen] = useState(false);
  const [
    {
      formData: {
        enabled,
        operationalRiskEnabled,
        operationalRiskRound,
        crimeSyndicateEnabled,
        crimeSyndicateRound,
        specialProjects,
      },
      fieldErrors,
    },
    { setFieldSimple, setFieldRaw, handleSubmit },
  ] = useForm(
    config?.specialProjectsSetup
      ? {
          ...config.specialProjectsSetup,
        }
      : {
          enabled: false,
          operationalRiskEnabled: false,
          operationalRiskRound: 1,
          crimeSyndicateEnabled: false,
          crimeSyndicateRound: 1,
          specialProjects: [],
        },
  );

  const projects = config?.specialProjectsConfig;
  const rounds = config?.generalSetup.rounds;
  const riskRoundsOptions = useMemo(() => {
    if (!operationalRiskEnabled) {
      return [];
    }
    const options = new Array(rounds).fill(null).map((_ignore, idx) => ({
      label: idx + 1,
      value: idx + 1,
    }));
    return options;
  }, [rounds, operationalRiskEnabled]);

  const crimeSyndicateRoundsOptions = useMemo(() => {
    if (!crimeSyndicateEnabled) {
      return [];
    }
    const options = new Array(rounds).fill(null).map((_ignore, idx) => ({
      label: idx + 1,
      value: idx + 1,
    }));
    return options;
  }, [rounds, crimeSyndicateEnabled]);

  const selectedOperationalRiskRound = useMemo(() => {
    return riskRoundsOptions.find(
      (option) => option.value === operationalRiskRound,
    );
  }, [riskRoundsOptions, operationalRiskRound]);

  const selectedCrimeSyndicateRound = useMemo(() => {
    return crimeSyndicateRoundsOptions.find(
      (option) => option.value === crimeSyndicateRound,
    );
  }, [crimeSyndicateRoundsOptions, crimeSyndicateRound]);

  const hideImpactType = useMemo(() => {
    const types = [];
    if (
      !config?.financialSummaryMetrics?.find(
        (metric) => metric.type === "EmployeeEngagement",
      ) &&
      !config?.summaryMetrics?.find(
        (metric) => metric.type === "EmployeeEngagement",
      )
    ) {
      types.push("employee");
    }

    if (
      !config?.summaryMetrics?.find((metric) => metric.type === "Community")
    ) {
      types.push("community");
    }

    return types;
  }, [config?.financialSummaryMetrics, config?.summaryMetrics]);

  const onOperationRiskChange = useCallback(
    (option) => {
      setFieldRaw("operationalRiskRound", option.value);
    },
    [setFieldRaw],
  );

  const onoperationalRiskEnabled = (e: boolean) => {
    setFieldSimple("operationalRiskEnabled")(e);
    if (e === true) {
      if (!specialProjects.includes("project-fix-it")) {
        setFieldRaw(
          "specialProjects",
          specialProjects.concat("project-fix-it"),
        );
      }
    }
  };

  const onCrimeSyndicateRoundChange = useCallback(
    (option) => {
      setFieldRaw("crimeSyndicateRound", option.value);
    },
    [setFieldRaw],
  );

  const onCrimeSyndicateEnabled = (e: any) => {
    setFieldSimple("crimeSyndicateEnabled")(e);
    if (e === true) {
      if (!specialProjects.includes("project-shield")) {
        setFieldRaw(
          "specialProjects",
          specialProjects.concat("project-shield"),
        );
      }
    }
  };

  const onSubmit = useCallback(async () => {
    if (config) {
      const payload: API.SimulationUpdateRequest = {
        generalSetup: config.generalSetup,
        specialProjectsSetup: {
          enabled,
          ...(enabled
            ? {
                specialProjects,
              }
            : {
                specialProjects: [],
              }),
          operationalRiskEnabled,
          ...(operationalRiskEnabled
            ? {
                operationalRiskRound,
              }
            : {}),
          crimeSyndicateEnabled,
          ...(crimeSyndicateEnabled
            ? {
                crimeSyndicateRound,
              }
            : {}),
        },
      };

      const response = await API.editSimulation(config.id, payload);
      updateConfig(response);
      return response;
    }
  }, [
    config,
    updateConfig,
    enabled,
    specialProjects,
    operationalRiskEnabled,
    operationalRiskRound,
    crimeSyndicateEnabled,
    crimeSyndicateRound,
  ]);

  const validate = useCallback(() => {
    const errors: FormError[] = [];
    if (enabled && specialProjects.length === 0) {
      errors.push({
        field: "specialProject",
        message:
          "Please select at least one Special Project or disable Special Projects.",
      });
    } else {
      errors.push({
        field: "specialProject",
        message: undefined,
      });
    }

    if (
      enabled &&
      operationalRiskEnabled &&
      !specialProjects.includes("project-fix-it")
    ) {
      errors.push({
        field: "specialProjectFixIt",
        message: `Please select ${projects?.find((p) => p.type === "project-fix-it")?.name} when enabling the Royal Commission Event.`,
      });
    } else {
      errors.push({
        field: "specialProjectFixIt",
        message: undefined,
      });
    }

    if (enabled && operationalRiskEnabled && !operationalRiskRound) {
      errors.push({
        field: "specialProjectRiskRound",
        message:
          "Please select a round when enabling the Royal Commission Event.",
      });
    } else {
      errors.push({
        field: "specialProjectRiskRound",
        message: undefined,
      });
    }

    if (
      enabled &&
      crimeSyndicateEnabled &&
      !specialProjects.includes("project-shield")
    ) {
      errors.push({
        field: "specialProjectShield",
        message: `Please select ${projects?.find((p) => p.type === "project-shield")?.name} when enabling the Crime Syndicate Event.`,
      });
    } else {
      errors.push({
        field: "specialProjectShield",
        message: undefined,
      });
    }

    if (enabled && crimeSyndicateEnabled && !crimeSyndicateRound) {
      errors.push({
        field: "specialProjectCrimeSyndicateRound",
        message:
          "Please select a round when enabling the Crime Syndicate Event.",
      });
    } else {
      errors.push({
        field: "specialProjectCrimeSyndicateRound",
        message: undefined,
      });
    }

    if (errors.length > 0) {
      setErrorOpen(true);
    }

    return errors;
  }, [
    enabled,
    specialProjects,
    operationalRiskEnabled,
    operationalRiskRound,
    crimeSyndicateEnabled,
    crimeSyndicateRound,
    projects,
  ]);

  useEffect(() => {
    assignOnSubmitFunction(handleSubmit(onSubmit, validate));
  }, [assignOnSubmitFunction, handleSubmit, onSubmit, validate]);

  const clearAll = useCallback(() => {
    setFieldRaw("specialProjects", []);
  }, [setFieldRaw]);

  const errorProperties = useMemo(() => {
    return {
      isOpen:
        (!!fieldErrors.specialProject ||
          !!fieldErrors.specialProjectFixIt ||
          !!fieldErrors.specialProjectRiskRound ||
          !!fieldErrors.specialProjectCrimeSyndicateRound ||
          !!fieldErrors.specialProjectShield) &&
        isErrorOpen,
      title: fieldErrors.specialProject
        ? "No Projects Selected"
        : !!fieldErrors.specialProjectFixIt ||
            !!fieldErrors.specialProjectRiskRound
          ? "Royal Commission"
          : "Crime Syndicate",
      message:
        fieldErrors.specialProject ??
        fieldErrors.specialProjectFixIt ??
        fieldErrors.specialProjectRiskRound ??
        fieldErrors.specialProjectShield ??
        fieldErrors.specialProjectCrimeSyndicateRound,
    };
  }, [
    fieldErrors.specialProject,
    fieldErrors.specialProjectCrimeSyndicateRound,
    fieldErrors.specialProjectFixIt,
    fieldErrors.specialProjectRiskRound,
    fieldErrors.specialProjectShield,
    isErrorOpen,
  ]);

  const handleSpecialProjectsChange = useCallback(
    async (projectId: string, data: Partial<API.SpecialProject>) => {
      if (config?.specialProjectsConfig) {
        const payload: API.SimulationUpdateRequest = {
          specialProjectsConfig: config.specialProjectsConfig.map(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ({ imageUrl, ...prj }) => {
              if (prj.id.toString() !== projectId.toString()) {
                return prj;
              }
              return {
                ...prj,
                ...data,
              };
            },
          ),
        };

        const response = await API.editSimulation(config.id, payload);

        updateConfig(response);
      }
    },
    [config, updateConfig],
  );

  const handleSpecialProjectsImpactChange = useCallback(
    async (
      projectId: string,
      impactId: string,
      data: Partial<API.SpecialProjectImpact>,
    ) => {
      const payload: API.SimulationUpdateRequest = {};

      if (config?.specialProjectsConfig) {
        payload.specialProjectsConfig = config.specialProjectsConfig.map(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ({ imageUrl, ...prj }) => {
            if (prj.id.toString() !== projectId.toString()) {
              return prj;
            }
            return {
              ...prj,
              impacts: prj.impacts.map((impact) => {
                if (impact.id.toString() !== impactId) {
                  return impact;
                }
                return {
                  ...impact,
                  ...data,
                };
              }),
            };
          },
        );

        if (config?.specialProjectsImpactTypeName && data.name) {
          const specialProject = config.specialProjectsConfig.find(
            (prj) => prj.id.toString() === projectId.toString(),
          );
          const impact = specialProject?.impacts.find(
            (impact) => impact.id.toString() === impactId,
          );
          payload.specialProjectsImpactTypeName =
            config.specialProjectsImpactTypeName.map((impactTypeName) => {
              if (impactTypeName.type === impact?.type) {
                return {
                  ...impactTypeName,
                  name: data.name!,
                };
              }
              return impactTypeName;
            });
        }

        const response = await API.editSimulation(config?.id, payload);

        updateConfig(response);
      }
    },
    [config, updateConfig],
  );

  return (
    <VerticalGroup
      wide
      full
      spaceBetweenElements={6}
      className="special-projects-container"
    >
      <InlineGroup block spread verticalCenter>
        <h3>Special Project Settings</h3>
        {enabled && <Text>{specialProjects.length} selected</Text>}
      </InlineGroup>
      <InlineGroup block spread verticalCenter>
        <Toggle
          label="Enable Special Projects"
          helpTitle="Special Projects"
          helpDescription="Including special projects allows participants to prioritise internal investment opportunities. e.g Increase our customer service capability"
          checked={enabled}
          onUpdate={setFieldSimple("enabled")}
          data-test="enable-special-project"
          block
        />
        {enabled && (
          <Button
            wide
            disabled={specialProjects.length === 0}
            onClick={clearAll}
          >
            Clear All
          </Button>
        )}
      </InlineGroup>

      {enabled && (
        <InlineGroup block verticalCenter spread spaceBetweenElements={6}>
          <Toggle
            label="Crime Syndicate Event"
            checked={crimeSyndicateEnabled}
            onUpdate={onCrimeSyndicateEnabled}
            data-test="enable-crime-syndicate"
            helpTitle="Crime Syndicate Event"
            helpDescription="This is an event where a crime syndicate enters the market. Any banks which have not proactively addressed fraud will incur penalties."
          />

          {crimeSyndicateEnabled && config?.generalSetup.rounds && (
            <InlineGroup
              className="pr-2"
              block
              verticalCenter
              spaceBetweenElements={2}
            >
              <Text>Occurs in round</Text>
              <Dropdown
                tiny
                data-test={"crime-syndicate-round-dropdown"}
                selectProps={{
                  value: selectedCrimeSyndicateRound,
                  options: crimeSyndicateRoundsOptions,
                  onChange: onCrimeSyndicateRoundChange,
                  classNamePrefix: "crime-syndicate-round-dropdown",
                }}
              />
            </InlineGroup>
          )}
        </InlineGroup>
      )}

      {enabled && (
        <SpecialProjectsSelect
          selected={specialProjects}
          projects={projects ?? []}
          onChange={setFieldSimple("specialProjects")}
          onUpdateContent={handleSpecialProjectsChange}
          onUpdateImpact={handleSpecialProjectsImpactChange}
          impactTypeName={config?.specialProjectsImpactTypeName}
          hideImpactType={hideImpactType}
          onoperationalRiskEnabled={onoperationalRiskEnabled}
          operationalRiskEnabled={!!operationalRiskEnabled}
          selectedOperationalRiskRound={selectedOperationalRiskRound}
          riskRoundsOptions={riskRoundsOptions}
          onOperationRiskChange={onOperationRiskChange}
          numberOfRounds={config?.generalSetup.rounds ?? 0}
        />
      )}

      {errorProperties.isOpen && (
        <ErrorModal
          isOpen={errorProperties.isOpen}
          onClose={() => {
            setErrorOpen(false);
          }}
          title={errorProperties.title}
          description={errorProperties.message}
        ></ErrorModal>
      )}
    </VerticalGroup>
  );
};

export { default as SpecialProjectsDisplay } from "./Display";
export default SpecialProjects;
