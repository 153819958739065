import React from "react";
import AssessmentListTable from "./AssessmentListTable";
import Container from "../../atoms/page/Page";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import Banner from "../../atoms/banner/Banner";
import useAssessments from "../../../hooks/useAssessments";

function AssessmentList() {
  const { inProgress, completed, data, error, refresh } = useAssessments();

  return (
    <Container>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {completed && data && (
        <AssessmentListTable assessments={data} refreshAssessments={refresh} />
      )}
    </Container>
  );
}

export default AssessmentList;
