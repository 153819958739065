import React, { useMemo } from "react";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import Card from "../../../atoms/card/Card";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import EditableReportingHeading from "../components/EditableReportingHeading";
import Icon from "../../../atoms/icon/Icon";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import ResultsText from "../results-text/ResultsText";
import { pluralise } from "../../../../lib/pluralise";

interface GameParametersProps {
  data: Array<{
    roundId: number;
    durationInMinutes: number;
  }>;
  teams: Array<{
    teamId: number;
    teamName: string;
    isCpuTeam: boolean;
  }>;
}

const IntroGameParameters: React.FC<GameParametersProps> = ({
  data,
  teams,
}) => {
  const regularTeams = useMemo(
    () => teams.filter((team) => !team.isCpuTeam).length,
    [teams],
  );

  const aIBanks = useMemo(
    () => teams.filter((team) => team.isCpuTeam).length,
    [teams],
  );

  const rounds = useMemo(() => data.length, [data]);

  const { maxTime, minTime } = useMemo(
    () => ({
      maxTime: Math.max(...data.map((round) => round.durationInMinutes)),
      minTime: Math.min(...data.map((round) => round.durationInMinutes)),
    }),
    [data],
  );

  const formatTime = (minutes: number) =>
    minutes >= 100
      ? `${Math.floor(minutes / 60)}${minutes % 60 > 0 ? `hr ${minutes % 60}m` : "hr"}`
      : `${minutes}m`;

  const teamCount = useMemo(
    () =>
      aIBanks === 0
        ? `${regularTeams} ${pluralise("Team", regularTeams)}`
        : `${regularTeams} ${pluralise("Team", regularTeams)} + ${aIBanks} ${pluralise("AI Bank", aIBanks)}`,
    [regularTeams, aIBanks],
  );

  const timeRange = useMemo(() => {
    const formattedMinTime = formatTime(minTime);
    const formattedMaxTime = formatTime(maxTime);

    return minTime === maxTime
      ? `(${formattedMaxTime})`
      : `(${formattedMinTime} - ${formattedMaxTime})`;
  }, [minTime, maxTime]);

  return (
    <RoundResultsContainer>
      <Card wide fullHeight sharp>
        <VerticalGroup verticalCenter full wide center>
          <EditableReportingHeading defaultHeading="What Do I Need To Know" />
          <VerticalGroup
            spaceBetweenElements={8}
            verticalCenter
            center
            full
            wide
          >
            <InlineGroup width="90%">
              <Icon fullSize type="gameParameters" />
            </InlineGroup>
            <InlineGroup block center>
              <InlineGroup block>
                <VerticalGroup width="36%" center>
                  <InlineGroup width="50%">
                    <ResultsText
                      right={aIBanks > 0}
                      center
                      colour="primaryDark"
                      bold
                      size="xl"
                    >
                      {teamCount}
                    </ResultsText>
                  </InlineGroup>
                </VerticalGroup>
                <InlineGroup width="17%">
                  <ResultsText colour="primaryDark" bold size="xl">
                    {rounds} Rounds
                  </ResultsText>
                </InlineGroup>
                <VerticalGroup width="19%" center>
                  <InlineGroup width="85%">
                    <ResultsText center colour="primaryDark" bold size="xl">
                      Time Pressure {timeRange}
                    </ResultsText>
                  </InlineGroup>
                </VerticalGroup>
                <VerticalGroup width="24%" center>
                  <InlineGroup width="60%">
                    <ResultsText center colour="primaryDark" bold size="xl">
                      Technology Interface
                    </ResultsText>
                  </InlineGroup>
                </VerticalGroup>
              </InlineGroup>
            </InlineGroup>
          </VerticalGroup>
        </VerticalGroup>
      </Card>
    </RoundResultsContainer>
  );
};

export default IntroGameParameters;
