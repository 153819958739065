import React, { useMemo } from "react";
import Dropdown from "../../atoms/form/input/Dropdown";
import { formatGameInfo } from "../open-event-sidebar/OpenEventSidebar";

interface EventSelectValue {
  id: string;
  value: string;
  label: string;
  event: API.EventResponseSummary;
}

interface EventDropDownProps {
  selectedEventId?: string | null;
  onEventSelected?: (eventData: API.EventResponseSummary) => void;
  data: API.EventResponseSummary[] | null;
  inProgress: boolean;
  noOptionsMessage: string;
}

const EventDropDown: React.FC<EventDropDownProps> = ({
  selectedEventId,
  onEventSelected,
  data,
  inProgress,
  noOptionsMessage,
}) => {
  const options: EventSelectValue[] = useMemo(() => {
    return data
      ? data.map((event) => ({
          id: event.id,
          value: event.id,
          label: `${event.name} (${formatGameInfo(event)})`,
          event,
        }))
      : [];
  }, [data]);

  const currentSelected = useMemo(() => {
    if (selectedEventId) {
      return options.find((option) => option.id === selectedEventId);
    }
    return null;
  }, [selectedEventId, options]);

  const handleEventSelected = (event: API.EventResponseSummary) => {
    if (onEventSelected) {
      onEventSelected(event);
    }
  };

  return (
    <Dropdown
      isVerticalLayout
      selectProps={{
        isLoading: inProgress,
        onChange: (option: EventSelectValue) =>
          handleEventSelected(option.event),
        value: currentSelected,
        options,
        noOptionsMessage: () => noOptionsMessage,
        placeholder: !data
          ? "Loading Events..."
          : data.length === 0
            ? noOptionsMessage
            : "Select an event",
      }}
    />
  );
};

export default EventDropDown;
