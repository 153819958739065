import React from "react";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import Text from "../../../../../atoms/text/Text";

interface Props {
  field: API.WrittenTextFieldRequest;
}

export const WrittenTextPreview: React.FC<Props> = ({ field }) => {
  return (
    <VerticalGroup spaceBetweenElements={1} wide>
      <Text isHtml>{field.config.text}</Text>
    </VerticalGroup>
  );
};
