import React, { useMemo } from "react";
import { DragAndDropItem, Dropzone } from "../../../../organisms/draganddrop";
import { financialSummaryMetricItems } from "./data";
import { SelectedView } from "../../../../organisms/draganddrop";

interface Props {
  data: API.SimulationResponse["financialSummaryMetrics"];
  config: API.SimulationResponse["financialSummaryMetricConfig"];
}

export const FinancialSummaryMetricsReadOnly: React.FC<Props> = ({
  data,
  config,
}) => {
  const selectedItems: Array<DragAndDropItem | undefined> = useMemo(() => {
    return (data ?? []).map((item) => {
      const matchedItem = financialSummaryMetricItems.find(
        (i) => i.type === item.type,
      );
      const matchedConfig = config?.find((i) => i.type === item.type);

      return matchedItem && matchedConfig
        ? {
            id: item.type,
            view: (
              <SelectedView
                // readOnly
                key={item.type}
                {...matchedItem}
                label={matchedConfig.name}
              />
            ),
          }
        : undefined;
    });
  }, [config, data]);

  return <Dropzone items={selectedItems} size={data?.length ?? 0} readOnly />;
};
