import { useLayoutEffect } from "react";
import { useCurrentUser } from "../context/userContext";
import AuthenticationError from "../errors/AuthenticationError";

const useRequiredRoles = (roles?: API.Role[]) => {
  const user = useCurrentUser();

  useLayoutEffect(() => {
    if (!roles) {
      return;
    }

    if (!roles.length) {
      return;
    }

    if (user && !roles.includes(user.type)) {
      throw new AuthenticationError(roles, user.type);
    }
  }, [user, roles]);
};

export default useRequiredRoles;
