import React from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";
import { formatValue, percentage } from "../utils/formatters";
import pluckResultsByType from "../utils/pluckResultsByType";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["revenue"];
}

const ModelTeamResultsRegulatoryCapital: React.FC<Props> = ({ data }) => {
  const interestOnCashCalculationsValues = pluckResultsByType(
    data,
    "interestOnCashCalculations",
  );

  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <TableWithSections
          header="Interest On Cash Calculations"
          data={[
            {
              name: "Cash Balance",
              fields: [
                "openingBalance",
                "netOperatingCashflowsExcludingTax",
                "netInvestingFlows",
                "lessTaxationProxy",
                "proxyClosingBalance",
                "averageBalance",
                "referenceRate",
                "interestOnCash",
              ],
              totalFields: [
                "proxyClosingBalance",
                "averageBalance",
                "referenceRate",
                "interestOnCash",
              ],
              values: pluckResultsByType(
                interestOnCashCalculationsValues,
                "cashBalance",
              ),
              formatter: (val: number, field: string) => {
                if (field === "lessTaxationProxy") {
                  return formatValue(val * -1);
                }
                if (field === "referenceRate") {
                  return percentage(val);
                }
                return formatValue(val, 2);
              },
            },
            {
              name: "Handle for excess cash",
              fields: [
                "closingDepositBalance",
                "targetCashLevel",
                "surplusProxyCash",
                "averageSurplusProxyCash",
                "assumedSavingAccountsTurnedAway",
                "headlinePricingOnSavingsAccounts",
                "interestSavedOnSavingsAccounts",
                "interestOnCashForgone",
                "netAdjustmentToInterestIncome",
                "extraInterestIncomeOnSurplusCashOnly",
              ],
              totalFields: ["surplusProxyCash", "averageSurplusProxyCash"],
              values: pluckResultsByType(
                data,
                "handleForExcessCashDuringForecastPeriod",
              ),
              formatter: (val: number, field: string) => {
                if (
                  [
                    "assumedSavingAccountsTurnedAway",
                    "interestOnCashForgone",
                  ].includes(field)
                ) {
                  return formatValue(val * -1);
                }
                if (field === "headlinePricingOnSavingsAccounts") {
                  return percentage(val);
                }
                return formatValue(val, 2);
              },
            },
          ]}
        />
      </VerticalGroup>
    </>
  );
};

export default ModelTeamResultsRegulatoryCapital;
