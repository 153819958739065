import React, { useCallback, useContext, useEffect, useState } from "react";

import API from "../../../../../../services/api";
import Banner from "../../../../../atoms/banner/Banner";
import Form from "../../../../../atoms/form/Form";
import { EventContext } from "../../context";
import useForm from "../../../../../../hooks/useForm";
import StepContainer from "../../components/StepContainer";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import EventOptions from "../../components/EventOptions";
import Card from "../../../../../atoms/card/Card";
import SelectAssessment from "./SelectAssessment";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import DisplayField from "../../../../../atoms/form/display-field/DisplayField";
import Toggle from "../../../../../atoms/toggle/Toggle";
import { getEventUpdatePayload } from "../../utils";
import FacilitatorHidden from "../../../../../molecules/facilitator-hidden/FacilitatorHidden";

const Assessment: React.FC = () => {
  const { config, updateConfig, assignOnSubmitFunction, updateFormStatus } =
    useContext(EventContext);
  const [
    {
      formData: { clientId, assessment, assessmentId },
      error,
      inProgress,
      formUpdated,
    },
    { setFieldRaw, handleSubmit },
  ] = useForm({ ...config });

  const [toggleEnabled, setToggleEnabled] = useState(() => {
    return !!config?.assessmentId;
  });

  const onAssessmentChanged = useCallback(
    (assessment: API.AssessmentResponse) => {
      setFieldRaw("assessmentId", assessment ? assessment.id : null);
      setFieldRaw("assessment", assessment);
    },
    [setFieldRaw],
  );

  const onUnselectAssessment = useCallback(() => {
    setFieldRaw("assessmentId", null);
    setFieldRaw("assessment", null);
  }, [setFieldRaw]);

  const onSubmit = useCallback(async () => {
    if (config?.id) {
      const payload = getEventUpdatePayload(config, {
        assessmentId: toggleEnabled ? assessmentId : null,
      });
      const response = await API.editEvent(config.id, payload);
      updateConfig({
        ...config,
        assessmentId: toggleEnabled ? assessmentId : undefined,
        assessment: toggleEnabled ? assessment : undefined,
      });
      return response;
    }
  }, [assessment, assessmentId, config, toggleEnabled, updateConfig]);

  const validate = useCallback(() => {
    return [];
  }, []);

  useEffect(() => {
    assignOnSubmitFunction(handleSubmit(onSubmit, validate));
  }, [assignOnSubmitFunction, handleSubmit, onSubmit, validate]);

  useEffect(() => {
    updateFormStatus({
      inProgress: inProgress,
      formUpdated: formUpdated,
    });
  }, [updateFormStatus, inProgress, formUpdated]);

  return (
    <StepContainer>
      <Banner type="error" active={!!error} message={error?.message} />
      <InlineGroup spread verticalCenter block>
        <h2>Assessment</h2>
        <EventOptions />
      </InlineGroup>
      <Form wide>
        <Card wide padding={16}>
          <FacilitatorHidden>
            <InlineGroup block spread verticalCenter>
              <Toggle
                label="Enable Assessments"
                helpTitle="Assessments"
                helpDescription="Assessments are an optional addition to a simulation event. They can be used to test participant knowledge or to gather feedback."
                checked={toggleEnabled}
                onUpdate={setToggleEnabled}
                block
              />
            </InlineGroup>
          </FacilitatorHidden>
          {toggleEnabled && config && (
            <SelectAssessment
              event={config}
              clientId={clientId}
              assessmentId={assessmentId}
              onAssessmentSelected={onAssessmentChanged}
              onUnselectAssessment={onUnselectAssessment}
            />
          )}
          {toggleEnabled && assessmentId ? (
            <>
              <hr className="mt-12" />
              <InlineGroup spread evenWidthChildren className="pt-4">
                <VerticalGroup center spaceBetweenElements={4}>
                  <DisplayField
                    compact
                    isVerticalLayout
                    label="Assessment Id"
                    value={assessmentId}
                  />
                </VerticalGroup>
                <VerticalGroup center spaceBetweenElements={4}>
                  <DisplayField
                    compact
                    isVerticalLayout
                    label="Assessment Name"
                    value={assessment?.name}
                  />
                </VerticalGroup>
              </InlineGroup>
            </>
          ) : null}
        </Card>
      </Form>
    </StepContainer>
  );
};

export default Assessment;
