import React from "react";
import "./ContextMenu.scss";

import { Option } from "../../pages/administration/configuration/economicScenarioBuilder/NewsPreview/contextMenuVariables";

interface ContextMenuProps {
  show: boolean;
  position: { top: number; left: number };
  options: Option[];
  onSelect: (option: Option) => void;
}

const ContextMenu: React.FC<ContextMenuProps> = ({
  show,
  position,
  options,
  onSelect,
}) => {
  if (!show) return null;

  return (
    <ul
      className="context-menu"
      style={{
        position: "fixed",
        top: position.top,
        left: position.left,
        zIndex: 1000,
      }}
    >
      {options.map((option) => (
        <li key={option.id} onClick={() => onSelect(option)}>
          {option.label}
        </li>
      ))}
    </ul>
  );
};

export default ContextMenu;
