import React, { useCallback } from "react";
import ChallengeModal from "../../organisms/challenge-modal/ChallengeModal";

import useAPIRequest from "../../../hooks/useAPIRequest";
import API from "../../../services/api";
import ConfirmModal from "../../organisms/confirm-modal/ConfirmModal";
import { pluralise } from "../../../lib/pluralise";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  assessment: API.AssessmentResponse;
}

function DeleteAssessment({ isOpen, onComplete, onClose, assessment }: Props) {
  const callback = useCallback(
    () => API.deleteAssessment(assessment.id),
    [assessment],
  );

  const [{ inProgress, error }, doAPIRequest] = useAPIRequest(callback, null);

  const handleConfirm = () => {
    doAPIRequest(onComplete);
  };

  if (assessment.eventsCount > 0) {
    return (
      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onClose}
        title="Delete assessment"
        description={`This assessment cannot be deleted as it is being used by ${assessment.eventsCount} ${pluralise("event", assessment.eventsCount)}. Please make sure it is not in use before deleting.`}
        confirmTitle={"Ok"}
      />
    );
  }

  return (
    <ChallengeModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      challengeQuestion="This action is irreversible. Please type the word DELETE below to proceed."
      challengeAnswer="DELETE"
      challengeAnswerWarning="Please type the word DELETE in CAPS to delete the assessment."
      title={`Delete assessment: ${assessment.name}?`}
      confirmTitle="Delete"
      error={error && error.message}
      warning
    />
  );
}

export default DeleteAssessment;
