import React, { useMemo } from "react";
import { useOpenEvents } from "../../../hooks/useOpenEvents";
import EventDropDown from "./EventDropDown";
import { sortEvents } from "./sortEvents";

interface OpenEventDropDownProps {
  selectedEventId?: string | null;
  onEventSelected?: (eventData: API.EventResponseSummary) => void;
  orderBy?: "asc" | "desc";
}

const OpenEventDropDown: React.FC<OpenEventDropDownProps> = ({
  selectedEventId,
  onEventSelected,
  orderBy = "desc",
}) => {
  const { data, inProgress } = useOpenEvents();

  const sortedData = useMemo(() => sortEvents(data, orderBy), [data, orderBy]);

  return (
    <EventDropDown
      noOptionsMessage="No Open Events"
      data={sortedData}
      inProgress={inProgress}
      onEventSelected={onEventSelected}
      selectedEventId={selectedEventId}
    />
  );
};

export default OpenEventDropDown;
