import {
  $createParagraphNode,
  $createTextNode,
  $isTextNode,
  ElementNode,
  LexicalEditor,
  LexicalNode,
  TextNode,
} from "lexical";
import { $createHeadingNode } from "@lexical/rich-text";
import {
  $createColoredTextNode,
  $isColoredTextNode,
  ColoredTextNode,
} from "../plugins/customNode/CustomColourNode";

function parseHtmlToLexicalNodes(
  editor: LexicalEditor,
  html: string,
): LexicalNode[] {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const elements = doc.body.childNodes;
  const nodes: LexicalNode[] = [];

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];

    if (element.nodeType === Node.ELEMENT_NODE) {
      const tagName = (element as HTMLElement).tagName.toLowerCase();
      const style = (element as HTMLElement).style;

      let node: ElementNode | null = null;

      if (tagName === "p") {
        node = $createParagraphNode();
      } else if (tagName === "h1") {
        node = $createHeadingNode("h1");
      } else if (tagName === "h2") {
        node = $createHeadingNode("h2");
      } else if (tagName === "h3") {
        node = $createHeadingNode("h3");
      } else if (tagName === "h4") {
        node = $createHeadingNode("h4");
      }

      if (node) {
        const textNodes = parseInlineElements(editor, element as HTMLElement);
        node.append(...textNodes);
        node.setFormat("left");

        if (style.textAlign === "center") {
          node.setFormat("center");
        } else if (style.textAlign === "right") {
          node.setFormat("right");
        } else if (style.textAlign === "justify") {
          node.setFormat("justify");
        }

        nodes.push(node);
      }
    }
  }

  return nodes;
}
function parseInlineElements(
  editor: LexicalEditor,
  element: HTMLElement,
): LexicalNode[] {
  const nodes: LexicalNode[] = [];
  for (let i = 0; i < element.childNodes.length; i++) {
    const child = element.childNodes[i];
    if (child.nodeType === Node.TEXT_NODE) {
      const textContent = child.textContent || "";
      const style = element.style;
      let textNode: TextNode;

      if (style.color) {
        textNode = $createColoredTextNode(textContent, style.color);
      } else {
        textNode = $createTextNode(textContent);
      }

      if (style.textAlign) {
        textNode.setStyle(`text-align: ${style.textAlign}`);
      }
      nodes.push(textNode);
    } else if (child.nodeType === Node.ELEMENT_NODE) {
      const tagName = (child as HTMLElement).tagName.toLowerCase();
      if (tagName === "b" || tagName === "strong") {
        const childNodes = parseInlineElements(editor, child as HTMLElement);
        childNodes.forEach((childNode) => {
          if ($isTextNode(childNode)) {
            const textContent = childNode.getTextContent();
            const style = (child as HTMLElement).style;
            let boldNode: TextNode;

            if ($isColoredTextNode(childNode)) {
              boldNode = $createColoredTextNode(
                textContent,
                childNode.getColor(),
              ).setFormat("bold");
            } else {
              boldNode = $createTextNode(textContent).setFormat("bold");
            }
            if (style.color) {
              (boldNode as ColoredTextNode).setColor(style.color);
            }

            nodes.push(boldNode);
          } else {
            nodes.push(childNode);
          }
        });
      } else if (tagName === "u") {
        const childNodes = parseInlineElements(editor, child as HTMLElement);
        childNodes.forEach((childNode) => {
          if ($isTextNode(childNode)) {
            childNode.toggleFormat("underline");
          }
        });
        nodes.push(...childNodes);
      } else if (tagName === "s" || tagName === "del") {
        const childNodes = parseInlineElements(editor, child as HTMLElement);
        childNodes.forEach((childNode) => {
          if ($isTextNode(childNode)) {
            childNode.toggleFormat("strikethrough");
          }
        });
        nodes.push(...childNodes);
      } else if (tagName === "i" || tagName === "ltr") {
        const childNodes = parseInlineElements(editor, child as HTMLElement);
        childNodes.forEach((childNode) => {
          if ($isTextNode(childNode)) {
            childNode.toggleFormat("italic");
          }
        });
        nodes.push(...childNodes);
      } else {
        const childNodes = parseInlineElements(editor, child as HTMLElement);
        nodes.push(...childNodes);
      }
    }
  }

  return nodes;
}

export default parseHtmlToLexicalNodes;
