import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import Container from "../../../atoms/page/Page";
import Banner from "../../../atoms/banner/Banner";
import EditWizard from "./index";
import useQuery from "../../../../hooks/useQuery";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Text from "../../../atoms/text/Text";
import { useEvent } from "../../../../hooks/useEvent";

export const PageHeader = () => {
  const query = useQuery();
  const header = query.get("pageHeader");
  return <span>Edit Event - {header}</span>;
};

export const SecondaryPageHeader = (): JSX.Element => {
  const query = useQuery();
  const eventTitle = query.get("eventTitle");
  const clientName = query.get("clientName");
  if (!clientName || !eventTitle) {
    return <span></span>;
  }
  return (
    <InlineGroup spaceBetweenElements={2}>
      <Text colour="green" size="sm">
        {clientName}
      </Text>
      <Text size="sm">—</Text>
      <Text size="sm">{eventTitle}</Text>
    </InlineGroup>
  );
};

function EditEvent() {
  const { eventId } = useParams<{ eventId: string }>();
  const query = useQuery();

  const { inProgress, error, data, refresh } = useEvent(eventId);

  const initialActiveStepKey = useMemo(() => {
    if (query.get("stepKey")) {
      return query.get("stepKey") ?? undefined;
    }
    if (data?.isGameSetup) {
      return "run-simulation";
    }
    return undefined;
  }, [data?.isGameSetup, query]);

  return data ? (
    <EditWizard
      mode="edit"
      event={data}
      refresh={refresh}
      initialActiveStepKey={initialActiveStepKey}
    />
  ) : (
    <Container>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
    </Container>
  );
}

export default EditEvent;
