import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Page from "../../../atoms/page/Page";
import GroupForm from "./GroupForm";
import API from "../../../../services/api";
import { useAssessment } from "../../../../hooks/useAssessments";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import Banner from "../../../atoms/banner/Banner";

interface Props {}

const AssessmentEditGroup: React.FC<Props> = () => {
  const [group, setGroup] = useState<API.AssessmentGroupResponse | null>(null);
  const { assessmentId, groupId } = useParams<{
    assessmentId: string;
    groupId: string;
  }>();
  const { inProgress, data, error } = useAssessment(assessmentId);
  const history = useHistory();

  useEffect(() => {
    if (data) {
      const group = data.groups.find((g) => g.id === groupId);
      if (group != null) {
        setGroup(group);
      }
    }
  }, [data, groupId]);

  const onSave = useCallback(
    async (
      payload: API.AssessmentGroupRequest,
    ): Promise<API.AssessmentGroupResponse | null> => {
      if (group) {
        return API.editAssessmentGroup(assessmentId, group.id, {
          name: payload.name,
          round: payload.round,
        });
      }

      return null;
    },
    [assessmentId, group],
  );

  const onFinish = useCallback(() => {
    history.push(`/assessments/${assessmentId}/view?groupId=${groupId}`);
  }, [history, assessmentId, groupId]);

  if (inProgress) {
    return null;
  }

  return (
    <Page>
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {inProgress && <LoadingSpinner />}
      {!!data && !inProgress && !!group && (
        <GroupForm
          group={group}
          assessment={data}
          onSave={onSave}
          onComplete={onFinish}
          onCancel={onFinish}
        />
      )}
    </Page>
  );
};

export default AssessmentEditGroup;
