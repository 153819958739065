import React, { useState, useEffect } from "react";
import Dropdown from "../../../../../atoms/form/input/Dropdown";

interface AssignTabletColourDropdownProps {
  availableTabletColors: string[];
  onColorSelect: (color: string) => void;
  editselectedColor: string;
}

const AssignTabletColourDropdown: React.FC<AssignTabletColourDropdownProps> = ({
  availableTabletColors,
  onColorSelect,
  editselectedColor,
}) => {
  const [selectedOption, setSelectedOption] = useState<
    { label: string; id: string } | undefined
  >(undefined);

  useEffect(() => {
    const initialOption = editselectedColor
      ? { label: editselectedColor, id: editselectedColor }
      : undefined;
    setSelectedOption(initialOption);
  }, [editselectedColor]);

  const colorOptions =
    editselectedColor && !availableTabletColors.includes(editselectedColor)
      ? [editselectedColor, ...availableTabletColors]
      : availableTabletColors;

  const options = colorOptions.map((colour) => ({ label: colour, id: colour }));

  const handleColorSelect = (selectedOption: { label: string; id: string }) => {
    setSelectedOption(selectedOption);
    onColorSelect(selectedOption.id);
  };

  return (
    <Dropdown
      block
      label={"Tablet Colour"}
      selectProps={{
        options: options,
        value: selectedOption,
        onChange: (selectedOption: { label: string; id: string }) =>
          handleColorSelect(selectedOption),
        placeholder: "Select colour",
      }}
    />
  );
};

export default AssignTabletColourDropdown;
