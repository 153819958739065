import React from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";

import "./QuestionPreview.scss";
import { QuestionPreviewContainer } from "./QuestionPreviewContainer";
import { Field } from "./Fields/Fields";

interface Props {
  group: API.AssessmentGroupResponse;
  question: API.AssessmentQuestionResponse;
  index: number;
  disableQuestionIndex?: boolean;
  answer?: API.AssessmentAnswerResponse["answers"][0]["answer"];
  isAnswerMode?: boolean;
}

export const GenericQuestionPreview: React.FC<Props> = ({
  group,
  question,
  index,
  disableQuestionIndex,
  answer,
  isAnswerMode = false,
}) => {
  return (
    <QuestionPreviewContainer
      index={index}
      question={question}
      group={group}
      disableQuestionIndex={disableQuestionIndex}
      isAnswerMode={isAnswerMode}
    >
      <VerticalGroup spaceBetweenElements={2} wide>
        {question.fields.map((field) => {
          return (
            <Field
              key={field.id}
              field={field}
              question={question}
              answer={answer}
              isAnswerMode={isAnswerMode}
            />
          );
        })}
      </VerticalGroup>
    </QuestionPreviewContainer>
  );
};
