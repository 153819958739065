import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useImmerReducer } from "use-immer";
import {
  FieldErrors,
  FormError,
  Setters,
  State,
} from "../../../../../../hooks/useForm";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import Text from "../../../../../atoms/text/Text";
import Toggle from "../../../../../atoms/toggle/Toggle";
import {
  OnChangeParam,
  RatingField,
  NARatingField,
  NA_INDEX,
} from "../FormFields/RatingField";
import { WrittenResponseField } from "../FormFields/WrittenResponseField";
import "./RatingFormDetails.scss";
import { DEFAULT_WRITTEN_RESPONSE } from "./WrittenResponseFormDetails";

const createRating = ({
  label,
  rating,
}: {
  label: string;
  rating?: number;
}): API.RatingFieldRequest => ({
  type: "rating",
  config: {
    type: "rating",
    label,
    rating,
  },
});

export const DEFAULT_RATING_ITEMS: API.RatingFieldRequest[] = [
  createRating({
    label: "Not liked at all",
    rating: 1,
  }),
  createRating({
    label: "It is okay",
    rating: 2,
  }),
  createRating({
    label: "I loved it",
    rating: 3,
  }),
];

interface FormRatingField extends API.RatingFieldRequest {
  isNew: boolean;
}

interface RatingFormState {
  items: FormRatingField[];
  hasNA: boolean;
  naItem?: FormRatingField;
  hasWrittenResponse: boolean;
  writtenResponseField?: API.WrittenResponseFieldRequest;
}

type Action =
  | {
      type: "UPDATE_RATING";
      changes: OnChangeParam;
    }
  | {
      type: "APPEND_RATING";
      index: number;
    }
  | {
      type: "REMOVE_RATING";
      index: number;
    }
  | {
      type: "TOGGLE_NA_OPTION";
      hasNA: boolean;
    }
  | {
      type: "TOGGLE_WRITTEN_RESPONSE";
      hasWrittenResponse: boolean;
    }
  | {
      type: "UPDATE_WRITTEN_RESPONSE";
      newData: API.WrittenResponseFieldRequest;
    };

const updateRating = (state: RatingFormState, changes: OnChangeParam) => {
  const { index, label, rating } = changes;

  if (index === NA_INDEX && state.naItem) {
    state.naItem = {
      ...state.naItem,
      config: {
        ...state.naItem.config,
        ...(typeof label !== "undefined" ? { label } : {}),
      },
    };
    return;
  }

  const newConf = {
    ...state.items[index].config,
    ...(typeof rating !== "undefined" ? { rating } : {}),
    ...(typeof label !== "undefined" ? { label } : {}),
  };

  state.items[index].config = newConf;
  state.items[index].isNew = false;
};

const appendNewRating = (state: RatingFormState, index: number) => {
  state.items = state.items.flatMap((item, idx) => {
    if (idx !== index) return item;
    return [
      item,
      {
        ...createRating({ label: "", rating: (item.config.rating ?? 0) + 1 }),
        isNew: true,
      },
    ];
  });
};

const removeRating = (state: RatingFormState, index: number) => {
  state.items = state.items.filter((item, idx) => idx !== index);
};

const toggleNA = (state: RatingFormState, hasNA: boolean) => {
  if (state.hasNA === hasNA) return;

  state.hasNA = hasNA;

  if (state.hasNA) {
    state.naItem = {
      ...createRating({ label: "N/A" }),
      isNew: false,
    };
  } else {
    state.naItem = undefined;
  }
};

const toggleWrittenResponse = (
  state: RatingFormState,
  hasWrittenResponse: boolean,
) => {
  if (state.hasWrittenResponse === hasWrittenResponse) return;

  state.hasWrittenResponse = hasWrittenResponse;

  if (state.hasWrittenResponse) {
    state.writtenResponseField = {
      ...DEFAULT_WRITTEN_RESPONSE,
      config: {
        ...DEFAULT_WRITTEN_RESPONSE.config,
      },
    };
  } else {
    state.writtenResponseField = undefined;
  }
};

const updateWrittenResponse = (
  state: RatingFormState,
  newData: API.WrittenResponseFieldRequest,
) => {
  state.writtenResponseField = {
    ...state.writtenResponseField,
    ...newData,
  };
};

const ratingFormReducer = (
  state: RatingFormState,
  action: Action,
): RatingFormState => {
  switch (action.type) {
    case "UPDATE_RATING":
      updateRating(state, action.changes);
      break;
    case "APPEND_RATING":
      appendNewRating(state, action.index);
      break;
    case "REMOVE_RATING":
      removeRating(state, action.index);
      break;
    case "TOGGLE_NA_OPTION":
      toggleNA(state, action.hasNA);
      break;
    case "TOGGLE_WRITTEN_RESPONSE":
      toggleWrittenResponse(state, action.hasWrittenResponse);
      break;
    case "UPDATE_WRITTEN_RESPONSE":
      updateWrittenResponse(state, action.newData);
      break;
    default:
      return state;
  }
  return state;
};

const toFormRatingField = (item: API.RatingFieldRequest) => {
  return {
    ...item,
    isNew: false,
  };
};

interface Props {
  question: State["formData"];
  setFieldRaw: Setters["setFieldRaw"];
  fieldErrors: FieldErrors;
}

const toQuestionFields = (
  state: RatingFormState,
): API.AssessmentQuestionFieldRequest[] => {
  const fields: API.AssessmentQuestionFieldRequest[] = [];

  return fields
    .concat(
      state.items
        .concat(state.naItem ? [state.naItem] : [])
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map(({ isNew, ...item }): API.RatingFieldRequest => item),
    )
    .concat(state.writtenResponseField ? [state.writtenResponseField] : []);
};

export const createInitState = (
  fields: API.AssessmentQuestionFieldRequest[],
): RatingFormState => {
  const ratingItems: API.RatingFieldRequest[] = fields.filter(
    (field: API.AssessmentQuestionFieldRequest) =>
      field.type === "rating" && typeof field.config.rating !== "undefined",
  ) as API.RatingFieldRequest[];

  const writtenResponseField = fields.find(
    ({ type }) => type === "written_response",
  ) as API.WrittenResponseFieldRequest | undefined;
  const hasWrittenResponse = !!writtenResponseField;
  const naItem = fields.find((field: API.AssessmentQuestionFieldRequest) => {
    return (
      field.config.type === "rating" &&
      typeof field.config.rating === "undefined"
    );
  }) as API.RatingFieldRequest | undefined;
  const hasNA = !!naItem;

  return {
    items: ratingItems.map(toFormRatingField),
    hasNA,
    naItem: naItem && toFormRatingField(naItem),
    hasWrittenResponse,
    writtenResponseField,
  };
};

export const validateRatingForm = (
  question: State["formData"],
  errors: FormError[],
) => {
  const state = createInitState(question.fields);

  // first rating field is required
  if (state.items.length === 0) {
    errors.push({
      field: "fields",
      message: "You must add at least one rating field",
    });
    return;
  }

  // first and last rating fiels's label is required
  if (!state.items[0].config.label) {
    errors.push({
      field: "fields.0",
      message: "The first rating field must have a label",
    });
  }

  if (!state.items[state.items.length - 1].config.label) {
    errors.push({
      field: `fields.${state.items.length - 1}`,
      message: "The last rating field must have a label",
    });
  }
};

const getFieldError = (fieldErrors: FieldErrors, index: number) => {
  const field = `fields.${index}`;
  return fieldErrors[field];
};

export const RatingFormDetails: React.FC<Props> = ({
  question,
  setFieldRaw,
  fieldErrors,
}) => {
  const [state, dispatch] = useImmerReducer<RatingFormState>(
    ratingFormReducer,
    createInitState(question.fields),
  );

  const onRatingChange = useCallback(
    ({ index, rating, label }: OnChangeParam) =>
      dispatch({ type: "UPDATE_RATING", changes: { rating, index, label } }),
    [dispatch],
  );

  const onAppendNewRating = useCallback(
    (index: number) => dispatch({ type: "APPEND_RATING", index }),
    [dispatch],
  );

  const onDeleteRating = useCallback(
    (index: number) => dispatch({ type: "REMOVE_RATING", index }),
    [dispatch],
  );

  const onToggleNA = useCallback(
    (hasNA: boolean) => dispatch({ type: "TOGGLE_NA_OPTION", hasNA }),
    [dispatch],
  );

  const onToggleWrittenResponse = useCallback(
    (hasWrittenResponse: boolean) =>
      dispatch({ type: "TOGGLE_WRITTEN_RESPONSE", hasWrittenResponse }),
    [dispatch],
  );

  const onUpdateWrittenResponse = useCallback(
    (newData: API.WrittenResponseFieldRequest) =>
      dispatch({ type: "UPDATE_WRITTEN_RESPONSE", newData }),
    [dispatch],
  );

  useEffect(() => {
    const fields = toQuestionFields(state);
    setFieldRaw("marks", 0);
    setFieldRaw("fields", fields);
  }, [setFieldRaw, state]);

  return (
    <>
      <InlineGroup block spaceBetweenElements={2}>
        <Text className="rating-description">Description *</Text>
        <Text className="rating">Rating *</Text>
      </InlineGroup>

      {state.items.map((field, index) => {
        return (
          <RatingField
            key={index}
            index={index}
            label={field.config.label}
            rating={field.config.rating}
            isNew={field.isNew}
            error={getFieldError(fieldErrors, index)}
            onChange={onRatingChange}
            onAppendNewRating={onAppendNewRating}
            onDelete={onDeleteRating}
          />
        );
      })}

      {state.hasNA && state.naItem && (
        <NARatingField
          label={state.naItem.config.label}
          onChange={onRatingChange}
        />
      )}

      <hr />

      <Toggle
        reverse
        label="Add N/A"
        checked={state.hasNA}
        onUpdate={onToggleNA}
      />

      <Toggle
        reverse
        label='Add an "other" answer for comments'
        checked={state.hasWrittenResponse}
        onUpdate={onToggleWrittenResponse}
      />

      {state.hasWrittenResponse && state.writtenResponseField && (
        <WrittenResponseField
          index={-1}
          onChange={onUpdateWrittenResponse}
          writtenResponseField={state.writtenResponseField}
        />
      )}
    </>
  );
};
