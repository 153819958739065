import React, { useState } from "react";
import { useElementSize } from "usehooks-ts";
import useReportingResults from "../../../hooks/useReportingResults";
import Banner from "../../atoms/banner/Banner";
import Text from "../../atoms/text/Text";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import ReportingTemplatePreviewHeader from "../reporting-templates/components/ReportingTemplatePreviewHeader";
import ReportingTemplatePreviewModal from "../reporting-templates/components/ReportingTemplatePreviewModal";
import SortableReportingTemplatePreviewContent from "../reporting-templates/components/SortableReportingTemplatePreviewContent";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import RoundDropdown from "../../organisms/round-dropdown/RoundDropdown";
import useIsMobile from "../../../hooks/useIsMobile";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";

interface Props {
  eventId: string;
  isOpen: boolean;
  onClose: () => void;
  round: number;
}

const PreviewReportingModal: React.FC<Props> = ({
  eventId,
  isOpen,
  onClose,
  round,
}) => {
  const [mode, setMode] = useState<"fullscreen" | "thumbnails">("thumbnails");
  const [showSpeakerNotes, setShowSpeakerNotes] = useState(false);
  const [headingRef, { height: headingHeight }] = useElementSize();
  const [roundToPreview, setRoundToPreview] = useState(round);
  const isMobile = useIsMobile();
  const { inProgress, data, error } = useReportingResults(
    eventId,
    roundToPreview,
  );

  return (
    <VerticalGroup>
      <ReportingTemplatePreviewModal
        isOpen={isOpen}
        onClose={onClose}
        headingHeight={headingHeight}
      >
        {(dimensions) => {
          const mobileFallbackHeight = window.innerHeight;
          const mobileFallbackWidth = window.innerWidth;

          const contentHeight = isMobile
            ? mobileFallbackHeight
            : dimensions.availableHeight || dimensions.previewHeight;
          const contentWidth = isMobile
            ? mobileFallbackWidth
            : dimensions.availableWidth || dimensions.previewWidth;

          return (
            <>
              {(!isMobile || mode !== "fullscreen") && (
                <ReportingTemplatePreviewHeader
                  showSpeakerNotes={showSpeakerNotes}
                  setShowSpeakerNotes={setShowSpeakerNotes}
                  showSpeakerNotesToggle={mode !== "fullscreen"}
                  mode={mode}
                  setMode={setMode}
                  ref={headingRef}
                >
                  <InlineGroup spaceBetweenElements={2} verticalCenter>
                    <Text>Round:</Text>
                    <RoundDropdown
                      value={roundToPreview}
                      onChange={(selected) =>
                        setRoundToPreview(parseInt(selected.value))
                      }
                      min={0}
                      max={round}
                      tiny
                    />
                  </InlineGroup>
                </ReportingTemplatePreviewHeader>
              )}
              <div
                style={{
                  height: contentHeight,
                  width: contentWidth,
                  overflow: "auto",
                }}
              >
                {error && (
                  <Banner
                    type="error"
                    active={!!error}
                    message={error?.message}
                  />
                )}
                {inProgress && <LoadingSpinner />}
                {!inProgress && data && (
                  <SortableReportingTemplatePreviewContent
                    round={roundToPreview}
                    data={data}
                    mode={mode}
                    setMode={setMode}
                    previewHeight={contentHeight}
                    previewWidth={contentWidth}
                    availableHeight={contentHeight}
                    availableWidth={contentWidth}
                    eventId={eventId}
                    editable={false}
                    showSpeakerNotes={showSpeakerNotes}
                  />
                )}
              </div>
            </>
          );
        }}
      </ReportingTemplatePreviewModal>
    </VerticalGroup>
  );
};

export default React.memo(PreviewReportingModal);
