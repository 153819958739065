import React, { useCallback, useContext, useEffect } from "react";

import API from "../../../../../services/api";
import Banner from "../../../../atoms/banner/Banner";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import { SimulationContext } from "../../context";
import useForm from "../../../../../hooks/useForm";
import Form from "../../../../atoms/form/Form";
import { GeneralDisplay } from "../general";
import { PlayersDisplay } from "../players";
import { ModuleDisplay } from "../module";
import { EconomicScenarioDisplay } from "../economicScenarios";
import { RegionDisplay } from "../region";
import { BusinessDisplay } from "../business";
import { SpecialProjectsDisplay } from "../special-projects/Index";
import { DealRoomDisplay } from "../dealroom";
import { ExcoDecisionDisplay } from "../excodecision";
import { SummaryDisplay } from "../summary";
import { FinancialScreenDisplay } from "../financial-summary";
import { MetadataDisplay } from "../metadata";
import { HelpManualDisplay } from "../resources";

const Preview: React.FC = () => {
  const {
    config,
    updateConfig,
    assignOnSubmitFunction,
    updateFormStatus,
    jumpToStepByKey,
  } = useContext(SimulationContext);
  const [{ error, inProgress, formUpdated }, { handleSubmit }] = useForm({});

  const onSubmit = useCallback(async () => {
    const payload: API.SimulationUpdateRequest = {
      state: "complete",
    };
    if (config) {
      const response = await API.editSimulation(config?.id, payload);
      updateConfig(response);
      return response;
    }
  }, [config, updateConfig]);

  useEffect(() => {
    assignOnSubmitFunction(handleSubmit(onSubmit));
  }, [assignOnSubmitFunction, handleSubmit, onSubmit]);

  useEffect(() => {
    updateFormStatus({
      inProgress: inProgress,
      formUpdated: formUpdated,
    });
  }, [updateFormStatus, inProgress, formUpdated]);

  return (
    <div className="step-container" style={{ width: "100%" }}>
      <VerticalGroup spread full>
        <VerticalGroup wide>
          <Banner type="error" active={!!error} message={error?.message} />
          <Form id="simulation-preview-form" wide>
            <GeneralDisplay
              data={config}
              onClickEdit={() => jumpToStepByKey("generalSetup")}
            />
            <PlayersDisplay
              data={config?.playersSetup}
              onClickEdit={() => jumpToStepByKey("playersSetup")}
            />
            <EconomicScenarioDisplay
              data={config}
              onClickEdit={() => jumpToStepByKey("moduleSetup")}
            />
            <ModuleDisplay
              data={config}
              onClickEdit={() => jumpToStepByKey("moduleSetup")}
            />
            <RegionDisplay
              data={config?.regionalSetup}
              onClickEdit={() => jumpToStepByKey("regionalSetup")}
            />
            <BusinessDisplay
              data={config?.businessSetup}
              onClickEdit={() => jumpToStepByKey("businessSetup")}
            />
            <DealRoomDisplay
              data={config}
              onClickEdit={() => jumpToStepByKey("dealRoomSetup")}
            />
            <ExcoDecisionDisplay
              data={config}
              onClickEdit={() => jumpToStepByKey("excoDecisionSetup")}
            />
            <SummaryDisplay
              data={config?.summaryMetrics}
              config={config?.summaryMetricConfig}
              onClickEdit={() => jumpToStepByKey("summaryMetrics")}
            />
            <FinancialScreenDisplay
              data={config?.financialSummaryMetrics}
              config={config?.financialSummaryMetricConfig}
              onClickEdit={() => jumpToStepByKey("financialSummaryMetrics")}
            />
            <SpecialProjectsDisplay
              data={config?.specialProjectsSetup}
              onClickEdit={() => jumpToStepByKey("specialProjectsSetup")}
            />
            <HelpManualDisplay data={config?.resources} />
            <MetadataDisplay
              data={config?.metadata}
              onClickEdit={() => jumpToStepByKey("metadata")}
            />
          </Form>
        </VerticalGroup>
      </VerticalGroup>
    </div>
  );
};

export default Preview;
