import {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useMemo,
} from "react";

import { Actions, TabletConfigState } from "./state";
import { NotImplemented } from "../../../../../constants/functions";

interface Context {
  data: TabletConfigState["data"];
  selectedEventId: TabletConfigState["selectedEventId"];
  eventData: TabletConfigState["eventData"];
  tabletDataPerEvent: TabletConfigState["tabletDataPerEvent"];
  updateData: (payload: API.TabletMappingResponse) => void;
  updateSelectedEventId: (payload: string | null) => void;
  updateEventData: (payload: API.EventResponse) => void;
  updateTeamColour: (teamNumber: number, colourId: API.TabletColour) => void;
  updateTabletsForEvent: (payload: API.TabletDetailResponse[]) => void;
  clearEvent: (payload: string) => void;
  reset: () => void;
}

export const TabletConfigContext = createContext<Context>({
  data: null,
  selectedEventId: null,
  eventData: {},
  tabletDataPerEvent: {},
  updateData: NotImplemented,
  updateSelectedEventId: NotImplemented,
  updateEventData: NotImplemented,
  updateTeamColour: NotImplemented,
  updateTabletsForEvent: NotImplemented,
  clearEvent: NotImplemented,
  reset: NotImplemented,
});

export const useTabletConfigContextValue = (
  state: TabletConfigState,
  dispatch: Dispatch<Actions>,
): Context => {
  const updateData = useCallback(
    (payload: API.TabletMappingResponse) => {
      dispatch({
        type: "UpdateData",
        payload,
      });
    },
    [dispatch],
  );

  const updateSelectedEventId = useCallback(
    (payload: string | null) => {
      dispatch({
        type: "UpdateSelectedEventId",
        payload,
      });
    },
    [dispatch],
  );

  const updateEventData = useCallback(
    (payload: API.EventResponse) => {
      dispatch({
        type: "UpdateEventData",
        payload,
      });
    },
    [dispatch],
  );

  const updateTeamColour = useCallback(
    (teamNumber: number, colour: API.TabletColour) => {
      dispatch({
        type: "UpdateTeamColour",
        payload: { teamNumber, colour },
      });
    },
    [dispatch],
  );

  const updateTabletsForEvent = useCallback(
    (payload: API.TabletDetailResponse[]) => {
      dispatch({
        type: "UpdateTabletsForEvent",
        payload,
      });
    },
    [dispatch],
  );

  const reset = useCallback(() => {
    dispatch({
      type: "Reset",
    });
  }, [dispatch]);

  const clearEvent = useCallback(
    (payload: string) => {
      dispatch({
        type: "ClearEvent",
        payload,
      });
    },
    [dispatch],
  );

  const context = useMemo(
    () => ({
      data: state.data,
      updateData,
      selectedEventId: state.selectedEventId,
      updateSelectedEventId,
      eventData: state.eventData,
      updateEventData,
      tabletDataPerEvent: state.tabletDataPerEvent,
      updateTeamColour,
      updateTabletsForEvent,
      reset,
      clearEvent,
    }),
    [
      state.data,
      state.eventData,
      state.selectedEventId,
      state.tabletDataPerEvent,
      updateData,
      updateEventData,
      updateSelectedEventId,
      updateTeamColour,
      updateTabletsForEvent,
      reset,
      clearEvent,
    ],
  );

  return context;
};

export const useTabletConfigContext = () => {
  return useContext(TabletConfigContext);
};
