import React from "react";
import classNames from "classnames";
import Clickable from "../../atoms/clickable/Clickable";
import { useLayer, useHover } from "react-laag";
import ModalBody from "../../molecules/modal-body/ModalBody";

import "./InformationPopup.scss";

interface InformationPopupProps {
  className?: string;
  title: string;
  body: string | React.ReactNode;
  shadow?: boolean;
  large?: boolean;
}

const InformationPopup: React.FC<InformationPopupProps> = ({
  className,
  title,
  body,
  shadow,
  large = false,
}) => {
  const [isOver, hoverProps] = useHover();

  const { triggerProps, layerProps, renderLayer } = useLayer({
    container: "layers",
    isOpen: isOver,
    placement: "right-center",
    auto: true,
    triggerOffset: 10,
  });

  return (
    <>
      <Clickable
        className={classNames("information-button", className)}
        {...triggerProps}
        {...hoverProps}
      >
        ?
      </Clickable>
      {isOver &&
        renderLayer(
          <ModalBody
            className={classNames("information-body", {
              shadow,
              large: large,
            })}
            type="info"
            icon="info"
            title={title}
            description={body}
            {...layerProps}
          />,
        )}
    </>
  );
};

export default InformationPopup;
