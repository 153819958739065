import React, { useCallback, useEffect, useState } from "react";
import { useMemoRequest } from "../../../../hooks/useMemoRequest";
import api from "../../../../services/api";
import Banner from "../../../atoms/banner/Banner";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import { SelectedView, Dropzone } from "../../../organisms/draganddrop";
import { financialSummaryMetricItems } from "../../simulations/steps/financial-summary/data";
import { DragDropContext } from "react-beautiful-dnd";
import { Noop } from "../../../../constants/functions";
import SimulationsThatNeedManualModificationModal from "./components/SimulationsThatNeedManualModificationModal";

function FinancialSummaryMetrics() {
  const [
    simulationsThatNeedManualModification,
    setSimulationsThatNeedManualModification,
  ] = useState<API.SimulationsThatNeedManualModification[] | null>(null);

  const getFinancialSummaryMetricConfig = useCallback(async () => {
    return await api.getFinancialSummaryMetricConfig();
  }, []);

  const {
    data: financialSummaryMetricConfig,
    inProgress,
    error,
  } = useMemoRequest(getFinancialSummaryMetricConfig);

  const [
    financialSummaryMetricConfigData,
    setFinancialSummaryMetricConfigData,
  ] = useState<API.FinancialSummaryMetricConfig[] | null>(null);

  useEffect(() => {
    if (financialSummaryMetricConfig) {
      setFinancialSummaryMetricConfigData(financialSummaryMetricConfig);
    }
  }, [financialSummaryMetricConfig]);

  const handleFinancialSummaryMetricConfigUpdate = useCallback(
    async (
      metricConfigId: string,
      data: Partial<API.FinancialSummaryMetricConfig>,
    ) => {
      const response = await api.updateFinancialSummaryMetricConfig(
        metricConfigId,
        data,
      );
      setFinancialSummaryMetricConfigData((prev) => {
        if (!prev) {
          return prev;
        }
        return prev.map((metricConfig) => {
          if (metricConfig.id === metricConfigId) {
            return { ...metricConfig, ...data };
          }
          return metricConfig;
        });
      });
      if (response.simulationsThatNeedManualModification.length > 0) {
        setSimulationsThatNeedManualModification(
          response.simulationsThatNeedManualModification,
        );
      }
    },
    [],
  );

  const updateField = useCallback(
    (metricConfigId: string) => (field: string) => async (value: string) => {
      await handleFinancialSummaryMetricConfigUpdate(metricConfigId, {
        [field]: value,
      });
    },
    [handleFinancialSummaryMetricConfigUpdate],
  );

  const items = (financialSummaryMetricConfigData ?? []).map((metricConfig) => {
    const item = financialSummaryMetricItems.find(
      (item) => item.type === metricConfig.type,
    );

    return {
      id: metricConfig.type,
      view: (
        <SelectedView
          label={metricConfig.name}
          icon={item?.icon}
          onUpdateField={updateField(metricConfig.id)}
        />
      ),
    };
  });

  return (
    <>
      <h3>Financial Summary Metric Settings</h3>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {financialSummaryMetricConfigData && (
        <DragDropContext onDragEnd={Noop}>
          <Dropzone
            items={items}
            size={items.length}
            itemsPerPage={items.length}
            readOnly
          />
        </DragDropContext>
      )}
      {simulationsThatNeedManualModification &&
        simulationsThatNeedManualModification.length > 0 && (
          <SimulationsThatNeedManualModificationModal
            simulationsThatNeedManualModification={
              simulationsThatNeedManualModification
            }
            onClose={() => setSimulationsThatNeedManualModification(null)}
            stepTitle="Financial Metrics"
          />
        )}
    </>
  );
}

export default FinancialSummaryMetrics;
