import React, { useCallback } from "react";
import useAPIRequest from "../../../../../hooks/useAPIRequest";
import API from "../../../../../services/api";
import ConfirmModal from "../../../../organisms/confirm-modal/ConfirmModal";

interface DeleteTabletConfigProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
}

function DeleteTabletConfig({
  isOpen,
  onClose,
  onConfirm,
}: DeleteTabletConfigProps) {
  const deleteCallback = useCallback(() => {
    return API.deleteTabletMapping();
  }, []);

  const [{ inProgress, error }, doAPIRequest] = useAPIRequest(
    deleteCallback,
    null,
  );

  const handleConfirm = useCallback(() => {
    doAPIRequest(onConfirm);
  }, [doAPIRequest, onConfirm]);

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      title="Delete Tablet Configuration?"
      description={
        <>
          Are you sure you want to delete all tablet configuration.
          <br /> <strong> This action cannot be reverted.</strong>
        </>
      }
      confirmTitle="Delete"
      error={error && error.message}
      warning
    />
  );
}

export default DeleteTabletConfig;
