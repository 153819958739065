import React from "react";
import usePreviewReportingData from "../../../../hooks/usePreviewReportingData";

interface Props {
  reportingTemplateId?: string;
  introTemplateId?: string;
  simulationId: string;
  brandingId?: string;
  eventId?: string;
  round: number;
  children(response: {
    inProgress: boolean;
    error: Error | null;
    data: ModelAPI.Reporting.ReportingResults | null;
  }): React.ReactNode;
}

const ReportingTemplatePreviewContentFetcher: React.FC<Props> = ({
  reportingTemplateId,
  introTemplateId,
  simulationId,
  brandingId,
  eventId,
  round,
  children,
}) => {
  const { inProgress, error, data } = usePreviewReportingData({
    reportingTemplateId,
    introTemplateId,
    simulationId,
    roundId: round,
    brandingId,
    eventId,
  });

  return <>{children({ inProgress, error, data })}</>;
};

export default ReportingTemplatePreviewContentFetcher;
