import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useMemoRequest } from "../../../hooks/useMemoRequest";
import modelApi from "../../../services/modelApi";
import Banner from "../../atoms/banner/Banner";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import RoundResultsPage from "./RoundResultsPage";
import RoundResultsNotGeneratedError from "./components/RoundResultsNotGeneratedError";

const onlyThesePages: API.ReportingLayoutType[] | null = [];

const RoundResultsPresentation: React.FC = () => {
  const { eventId } = useParams<{
    eventId: string;
  }>();

  const getRoundNumber = useCallback(async () => {
    try {
      const preso = await modelApi.getReportingPresentationState(eventId, {
        bypass: false,
      });
      return { round: preso.round, calcVersion: preso.calcVersion };
    } catch (error) {
      return { round: 0, calcVersion: 0 };
    }
  }, [eventId]);

  const { inProgress, data, error, refresh } = useMemoRequest<{
    round: number;
    calcVersion: number;
  }>(getRoundNumber);

  return (
    <>
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {inProgress && <LoadingSpinner />}
      {data != null && (
        <RoundResultsPresentationWithRound
          eventId={eventId}
          roundNumber={data.round}
          calcVersion={data.calcVersion}
          refreshPresentation={refresh}
        />
      )}
    </>
  );
};

const RoundResultsPresentationWithRound: React.FC<{
  eventId: string;
  roundNumber: number;
  calcVersion: number;
  refreshPresentation: () => void;
}> = ({ eventId, roundNumber, refreshPresentation, calcVersion }) => {
  const [round, setRound] = useState(roundNumber);
  const [calcVers, setCalcVers] = useState(calcVersion);
  const getReportingResults = useCallback(() => {
    return modelApi.getReportingResults(eventId, round);
  }, [eventId, round]);

  const { inProgress, data, error, refresh } =
    useMemoRequest<ModelAPI.Reporting.ReportingResults>(getReportingResults);

  const notFoundError = error?.message?.includes("not found");

  const handleRoundChange = useCallback(
    (round: number, calcVersion?: number) => {
      setRound(round);
      if (calcVersion != null) {
        setCalcVers(calcVersion);
      }
    },
    [],
  );

  useEffect(() => {
    if (calcVers !== calcVersion) {
      refresh();
    }
  }, [calcVers, calcVersion, refresh]);

  const handleRefresh = useCallback(
    (round: number) => {
      setRound(round);
      refreshPresentation();
    },
    [refreshPresentation],
  );

  return (
    <>
      {!notFoundError && error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {notFoundError && (
        <RoundResultsNotGeneratedError
          roundNumber={round}
          title="Presentation"
        />
      )}
      {inProgress && <LoadingSpinner />}
      <div style={{ height: "100vh" }}>
        <RoundResultsPage
          key={eventId + roundNumber}
          eventId={eventId}
          data={data}
          roundNumber={roundNumber}
          onlyThesePages={onlyThesePages}
          hidePagination
          readSyncState
          ignoreKeyPress
          refreshPresentation={handleRefresh}
          handleRoundChange={handleRoundChange}
          showFullScreenButton
          isPresentationScreen
        />
      </div>
    </>
  );
};

export default RoundResultsPresentation;
