import openNewTab from "./openNewTab";

interface Opts {
  height?: number;
  width?: number;
  left?: number;
  top?: number;
  windowName?: string;
}

const openNewWindow = (url: string, opts: Opts = {}) => {
  let features = `height=${opts.height ?? window.innerHeight},width=${opts.width ?? window.innerWidth}`;
  if (opts.left) {
    features += `,left=${opts.left}`;
  }
  if (opts.top) {
    features += `,top=${opts.top}`;
  }
  const newWindow = window.open(url, opts.windowName ?? window.name, features);

  if (
    !newWindow ||
    newWindow.closed ||
    typeof newWindow.closed === "undefined"
  ) {
    openNewTab(url);
  }
};
export default openNewWindow;
