import React, { useMemo, useRef } from "react";
import SimpleModal from "../../../../../organisms/standard-modal/SimpleModal";
import Container from "../../../../../atoms/page/Page";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import { dropDownVariables } from "./contextMenuVariables";
import Clickable from "../../../../../atoms/clickable/Clickable";
import Icon from "../../../../../atoms/icon/Icon";
import Text from "../../../../../atoms/text/Text";
import Card from "../../../../../atoms/card/Card";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import Button from "../../../../../atoms/button/Button";
import EditableText, {
  Handles as EditableTextHandles,
} from "../../../../../atoms/text/EditableText";
import { LOGO_TO_ICON_MAP } from "../../../../simulations/steps/economicScenarios/LogoModal";
import InformationPopup from "../../../../../organisms/information-popup/InformationPopup";
import { EditingTextBody } from "./InfoPopUpText";
import LogoContainer from "../../../../../LogoContainer/LogoContainer";
interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedRound: number;
  handleScenarioUpdated: (
    updatedScenario: API.EconomicScenarioUpdateRequest,
  ) => void;
  selectedScenario: API.EconomicScenarioResponse | null;
}

export const EditBullAndBearModal = ({
  isOpen,
  onClose,
  selectedRound,
  handleScenarioUpdated,
  selectedScenario,
}: Props) => {
  const marketView1TextRef = useRef<EditableTextHandles>(null);
  const marketView2TextRef = useRef<EditableTextHandles>(null);
  const marketView1ResultsTextRef = useRef<EditableTextHandles>(null);
  const marketView2ResultsTextRef = useRef<EditableTextHandles>(null);

  // const [scenario1Logo, setScenario1Logo] = useState<API.BreakingNewsConfigLogoType>()
  // const [scenario2Logo, setScenario2Logo] = useState<API.BreakingNewsConfigLogoType>()
  // const [showScenario1LogoModal, setShowScenario1LogoModal] = useState(false)
  // const [showScenario2LogoModal, setShowScenario2LogoModal] = useState(false)

  const roundData = useMemo(() => {
    if (
      !selectedScenario ||
      !selectedScenario.news ||
      !selectedScenario.newsConfig
    ) {
      throw new Error("Invalid scenario");
    }

    const newsItem = selectedScenario.news.find(
      (news) => news.roundId === selectedRound,
    );

    if (!newsItem) {
      throw new Error("Invalid round");
    }
    return {
      newsItem,
      newsConfig: selectedScenario.newsConfig,
    };
  }, [selectedRound, selectedScenario]);

  const handleNewsChange = (fieldName: string, newValue: string) => {
    if (selectedScenario && selectedScenario.news) {
      const updatedNews = selectedScenario.news.map((newsItem) => {
        if (newsItem.roundId === selectedRound) {
          return { ...newsItem, [fieldName]: newValue };
        }
        return newsItem;
      });

      const updatedScenario = {
        ...selectedScenario,
        news: updatedNews,
      };

      handleScenarioUpdated(updatedScenario);
    }
  };

  // const handleLogoUpdate = useCallback((scenarioIndex: number, newLogo: API.BreakingNewsConfigLogoType) => {
  //   if (selectedScenario && selectedScenario.news) {
  //     const updatedNews = selectedScenario.news.map(newsItem => {
  //       if (newsItem.roundId === selectedRound) {
  //         if (scenarioIndex === 1) {
  //           setScenario1Logo(newLogo)
  //           return { ...newsItem, scenario1Logo: newLogo }
  //         } else if (scenarioIndex === 2) {
  //           setScenario2Logo(newLogo)
  //           return { ...newsItem, scenario2Logo: newLogo }
  //         }
  //       }
  //       return newsItem
  //     })

  //     const updatedScenario = {
  //       ...selectedScenario,
  //       news: updatedNews
  //     }

  //     handleScenarioUpdated(updatedScenario)
  //   }
  // }, [handleScenarioUpdated, selectedScenario, selectedRound])

  // useEffect(() => {
  //   if (roundData.scenario1Logo && roundData.scenario2Logo) {
  //     setScenario1Logo(roundData.scenario1Logo as API.BreakingNewsConfigLogoType)
  //     setScenario2Logo(roundData.scenario2Logo as API.BreakingNewsConfigLogoType)
  //   }
  // }, [roundData.scenario1Logo, roundData.scenario2Logo])

  return (
    <SimpleModal isOpen={isOpen} size="large" onClose={onClose}>
      <Container fit className="simulation-edit">
        <VerticalGroup spaceBetweenElements={4}>
          <VerticalGroup spaceBetweenElements={4}>
            <InlineGroup verticalCenter spaceBetweenElements={2}>
              <Text size="xl" bold>
                Edit {roundData.newsConfig.marketViewSectionHeading} for Round{" "}
                {selectedRound}
              </Text>
              <InformationPopup title={"Editing Tip"} body={EditingTextBody} />
            </InlineGroup>
            <VerticalGroup spaceBetweenElements={2}>
              <InlineGroup verticalCenter spaceBetweenElements={2}>
                <LogoContainer colour={"secondaryBlue"} size={2}>
                  <Icon
                    stroke="white"
                    size={10}
                    noMargin
                    type={
                      LOGO_TO_ICON_MAP[roundData.newsConfig.marketView1Logo]
                    }
                  />
                </LogoContainer>
                <Text size="xl" bold>
                  {roundData.newsConfig.marketView1Heading}
                </Text>
              </InlineGroup>
              <Card sharp>
                <VerticalGroup verticalCenter spaceBetweenElements={4}>
                  <VerticalGroup verticalCenter spaceBetweenElements={2}>
                    <VerticalGroup>
                      <Text bold>In App Text</Text>
                      <InlineGroup verticalTop spaceBetweenElements={2}>
                        <EditableText
                          lineHeight={6}
                          ref={marketView1TextRef}
                          dropdownOptions={dropDownVariables}
                          enableRightClickMenu
                          value={roundData.newsItem.marketView1Text || ""}
                          onChange={(newUpdate) =>
                            handleNewsChange("marketView1Text", newUpdate)
                          }
                        />{" "}
                        <Clickable
                          onClick={() =>
                            marketView1TextRef.current?.setIsEditing(true)
                          }
                        >
                          <Icon
                            noMargin
                            type="edit"
                            tip={{
                              content: "marketView1Text",
                              id: "marketView1Text",
                            }}
                          />
                        </Clickable>
                      </InlineGroup>
                    </VerticalGroup>
                  </VerticalGroup>

                  <VerticalGroup>
                    <Text bold>Results Text</Text>
                    <InlineGroup verticalTop spaceBetweenElements={2}>
                      <EditableText
                        lineHeight={6}
                        ref={marketView1ResultsTextRef}
                        value={roundData.newsItem.marketView1ResultsText || ""}
                        onChange={(newUpdate) =>
                          handleNewsChange("marketView1ResultsText", newUpdate)
                        }
                      />{" "}
                      <Clickable
                        onClick={() =>
                          marketView1ResultsTextRef.current?.setIsEditing(true)
                        }
                      >
                        <Icon
                          noMargin
                          type="edit"
                          tip={{
                            content: "marketView1ResultsText",
                            id: "marketView1ResultsText",
                          }}
                        />
                      </Clickable>
                    </InlineGroup>
                  </VerticalGroup>
                </VerticalGroup>
              </Card>
            </VerticalGroup>
            <VerticalGroup spaceBetweenElements={2}>
              <InlineGroup verticalCenter spaceBetweenElements={2}>
                <LogoContainer colour={"yellow"} size={2}>
                  <Icon
                    stroke="white"
                    size={10}
                    noMargin
                    type={
                      LOGO_TO_ICON_MAP[roundData.newsConfig.marketView2Logo]
                    }
                  />
                </LogoContainer>
                <Text size="xl" bold>
                  {roundData.newsConfig.marketView2Heading}
                </Text>
              </InlineGroup>
              <Card sharp>
                <VerticalGroup verticalCenter spaceBetweenElements={4}>
                  <VerticalGroup verticalCenter spaceBetweenElements={2}>
                    <VerticalGroup>
                      <Text bold>In App Text</Text>
                      <InlineGroup verticalTop spaceBetweenElements={2}>
                        <EditableText
                          lineHeight={6}
                          ref={marketView2TextRef}
                          dropdownOptions={dropDownVariables}
                          enableRightClickMenu
                          value={roundData.newsItem.marketView2Text || ""}
                          onChange={(newUpdate) =>
                            handleNewsChange("marketView2Text", newUpdate)
                          }
                        />{" "}
                        <Clickable
                          onClick={() =>
                            marketView2TextRef.current?.setIsEditing(true)
                          }
                        >
                          <Icon
                            noMargin
                            type="edit"
                            tip={{
                              content: "marketView2Text",
                              id: "marketView2Text",
                            }}
                          />
                        </Clickable>
                      </InlineGroup>
                    </VerticalGroup>
                  </VerticalGroup>
                  <VerticalGroup>
                    <Text bold>Results Text</Text>
                    <InlineGroup verticalTop spaceBetweenElements={2}>
                      <EditableText
                        lineHeight={6}
                        ref={marketView2ResultsTextRef}
                        value={roundData.newsItem.marketView2ResultsText || ""}
                        onChange={(newUpdate) =>
                          handleNewsChange("marketView2ResultsText", newUpdate)
                        }
                      />{" "}
                      <Clickable
                        onClick={() =>
                          marketView2ResultsTextRef.current?.setIsEditing(true)
                        }
                      >
                        <Icon
                          noMargin
                          type="edit"
                          tip={{
                            content: "marketView2ResultsText",
                            id: "marketView2ResultsText",
                          }}
                        />
                      </Clickable>
                    </InlineGroup>
                  </VerticalGroup>
                </VerticalGroup>
              </Card>
            </VerticalGroup>
            <InlineGroup block reverse stretch>
              <Button light large wide onClick={onClose}>
                Close
              </Button>
            </InlineGroup>
          </VerticalGroup>
        </VerticalGroup>
      </Container>
    </SimpleModal>
  );
};

export default EditBullAndBearModal;
