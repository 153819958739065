import React, { useCallback } from "react";
import ChallengeModal from "../../../organisms/challenge-modal/ChallengeModal";

import useAPIRequest from "../../../../hooks/useAPIRequest";
import API from "../../../../services/api";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  user: API.UserResponse;
}

function DeleteUser({ isOpen, onComplete, onClose, user }: Props) {
  const callback = useCallback(() => API.deleteUser(user.id), [user]);

  const [{ inProgress, error }, doAPIRequest] = useAPIRequest(callback, null);

  const handleConfirm = () => {
    doAPIRequest(onComplete);
  };

  return (
    <ChallengeModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      challengeQuestion="This action is irreversible. Please type the word DELETE below to delete this user."
      challengeAnswer="DELETE"
      challengeAnswerWarning="Please type the word DELETE in CAPS to delete the user."
      title={`Delete user ${user.email}?`}
      confirmTitle="Delete"
      error={error && error.message}
      warning
    />
  );
}

export default DeleteUser;
