import React, { useCallback } from "react";
import ConfirmModal from "../../../../../organisms/confirm-modal/ConfirmModal";
import API from "../../../../../../services/api";
import useAPIRequest from "../../../../../../hooks/useAPIRequest";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  participantCount: number;
  eventId: string;
  participants: API.ParticipantResponse[];
}

function SendEmailModal({
  isOpen,
  onClose,
  onComplete,
  participantCount,
  eventId,
  participants,
}: Props) {
  const callback = async () => {
    if (participants.length > 0) {
      const participantIds = participants.map((participant) => participant.id);
      await API.sendWelcomeEmail(eventId, participantIds);
    } else {
      await API.sendWelcomeEmail(eventId);
    }
  };

  const [{ inProgress, error }, doAPIRequest] = useAPIRequest(callback, null);

  const handleConfirm = useCallback(() => {
    doAPIRequest(onComplete);
  }, [doAPIRequest, onComplete]);

  let message =
    "Are you sure you want to send a welcome email to all participants?";
  if (participantCount > 1) {
    message = `Are you sure you want to send a welcome email to ${participantCount} participants?`;
  } else if (participantCount === 1) {
    const email = participants[0]?.email;
    message = `Are you sure you want to send a welcome email to ${email}?`;
  }
  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      title={message}
      confirmTitle={"Send"}
      error={error && error.message}
      disabled={inProgress}
    />
  );
}

export default SendEmailModal;
