import React, { useCallback, useEffect, useMemo } from "react";

import useAPIRequest from "../../../hooks/useAPIRequest";
import API from "../../../services/api";

import Dropdown from "../../atoms/form/input/Dropdown";

interface Props {
  clientId?: string;
  clientIds?: string[];
  onChange: (selected: any) => void;
  error?: string;
  label?: string;
  isMulti?: boolean;
  noBorder?: boolean;
  placeholder?: string;
  colour?: string;
  isVerticalLayout?: boolean;
}

function ClientDropdown({
  clientId,
  clientIds,
  onChange,
  error,
  label,
  isMulti,
  noBorder,
  placeholder,
  colour,
  isVerticalLayout,
}: Props) {
  const callback = useCallback(() => {
    return API.getClients();
  }, []);

  const [{ inProgress, data }, doAPIRequest] = useAPIRequest<
    API.ClientResponse[]
  >(callback, null);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  const options = useMemo(() => {
    return data
      ? data.map((client) => ({
          id: client.id,
          value: client.id,
          label: client.name,
        }))
      : [];
  }, [data]);

  const defaultValue = useMemo(() => {
    const value = null;
    if (isMulti) {
      if (clientIds) {
        return options.filter((client) => clientIds.includes(client.value));
      }
    } else {
      if (clientId) {
        return options.find((client) => client.value === clientId);
      }
    }
    return value;
  }, [clientId, clientIds, options, isMulti]);

  if (inProgress || !data) {
    return (
      <Dropdown
        isVerticalLayout={isVerticalLayout}
        key="loading-dropdown"
        isMulti={isMulti}
        data-test="client-dropdown-loading"
        label={label}
        error={error}
        noBorder={noBorder}
        selectProps={{
          isLoading: true,
          options: [],
          defaultValue: null,
          onChange: null,
          placeholder: "Loading clients...",
        }}
      />
    );
  }

  return (
    <Dropdown
      isVerticalLayout={isVerticalLayout}
      data-test="client-dropdown"
      isMulti={isMulti}
      label={label}
      error={error}
      noBorder={noBorder}
      colour={colour}
      selectProps={{
        options: options,
        defaultValue: defaultValue,
        onChange: onChange,
        placeholder: placeholder,
        classNamePrefix: "client-dropdown",
      }}
    />
  );
}

export default ClientDropdown;
