import React, { useCallback } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Text from "../../../atoms/text/Text";
import PresentResultsRndDropdown from "../../game/PresentResultsRndDropdown";

interface Props {
  roundNumber: number;
  handleRoundUpdate?: ((round: number) => void) | null;
  title?: string;
}

const RoundResultsNotGeneratedError: React.FC<Props> = ({
  roundNumber,
  handleRoundUpdate,
  title,
}) => {
  const onRoundNumberChange = useCallback(
    (round: number) => {
      handleRoundUpdate && handleRoundUpdate(round);
    },
    [handleRoundUpdate],
  );

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <div className="results-container results-container-summary">
        <div className="results" style={{ flex: 1 }}>
          <VerticalGroup
            wide
            id="round-results-container"
            center
            verticalCenter
            full
          >
            <div>
              {!!title && (
                <Text colour="white" bold size="6xl" className="mb-8">
                  {title}
                </Text>
              )}
              {roundNumber > 0 && (
                <>
                  <Text colour="white" bold size="3xl">
                    Round {roundNumber} results have not been generated yet.
                  </Text>
                  <p>
                    <Text colour="white">
                      This screen will update once results are generated.{" "}
                      {handleRoundUpdate
                        ? "Or select a different round to present."
                        : ""}
                    </Text>
                  </p>
                </>
              )}
              {roundNumber === 0 && (
                <>
                  <Text colour="white" bold size="3xl">
                    This event does not have an intro presentation.
                  </Text>
                  <p>
                    <Text colour="white">
                      This screen will update with Round 1 results once they are
                      generated.
                    </Text>
                  </p>
                </>
              )}
              {handleRoundUpdate && (
                <PresentResultsRndDropdown
                  currentRound={roundNumber}
                  maxRound={6}
                  onChange={onRoundNumberChange}
                />
              )}
            </div>
          </VerticalGroup>
        </div>
      </div>
    </div>
  );
};

export default RoundResultsNotGeneratedError;
