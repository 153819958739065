import React, { useEffect, useState } from "react";
import { useMemoRequest } from "../../../../hooks/useMemoRequest";
import api from "../../../../services/api";
import Banner from "../../../atoms/banner/Banner";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import Ribbon from "../../../atoms/ribbon/Ribbon";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import LabelsByType from "../../simulations/steps/labels/LabelsByType";
import SimulationsThatNeedManualModificationModal from "./components/SimulationsThatNeedManualModificationModal";

const getLabels = async () => {
  return await api.getLabelsConfig();
};

const LabelsScreen: React.FC = () => {
  const [tab, setTab] = useState<API.LabelTypes>("main_menu");

  const { data: labels, inProgress, error } = useMemoRequest(getLabels);

  const [
    simulationsThatNeedManualModification,
    setSimulationsThatNeedManualModification,
  ] = useState<API.SimulationsThatNeedManualModification[] | null>(null);

  const [labelsData, setLabelsData] = useState<
    API.LabelConfigResponse[] | null
  >(null);

  useEffect(() => {
    if (labels) {
      setLabelsData(labels);
    }
  }, [labels]);

  const handleLabelUpdate = async (
    labelId: string,
    data: Partial<API.LabelConfigResponse>,
  ) => {
    setLabelsData((prev) => {
      if (!prev) {
        return prev;
      }
      return prev.map((label) => {
        if (label.id === labelId) {
          return { ...label, ...data };
        }
        return label;
      });
    });
    const response = await api.updateLabelConfig(labelId, data);
    if (response.simulationsThatNeedManualModification?.length > 0) {
      setSimulationsThatNeedManualModification(
        response.simulationsThatNeedManualModification,
      );
    }
  };

  const sortByArray = [
    "informationLabel",
    "summaryButton",
    "newsButton",
    "peerInsightsButton",
    "ourFinancialsButton",
    "helpManualButton",
    "decisionsLabel",
    "excoButton",
    "specialProjectsButton",
    "costsButton",
    "businessesButton",
    "treasuryButton",
    "dealRoomButton",
    "assessmentButton",
  ];

  return (
    <>
      <h3>Labels</h3>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {labelsData && (
        <VerticalGroup full wide>
          <InlineGroup spaceBetweenElements={2} className="h-16 mt-2" block>
            <Ribbon
              first
              active={tab === "main_menu"}
              title="Main Menu"
              onClick={() => setTab("main_menu")}
              className="h-16"
            />
            <Ribbon
              last
              active={tab === "team"}
              title="Team"
              onClick={() => setTab("team")}
              className="h-16"
            />
          </InlineGroup>
          {tab === "main_menu" && (
            <LabelsByType
              onUpdateContent={handleLabelUpdate}
              type={tab}
              items={labelsData}
              sortBy={sortByArray}
            />
          )}
          {tab === "team" && (
            <LabelsByType
              onUpdateContent={handleLabelUpdate}
              type={tab}
              items={labelsData}
            />
          )}
        </VerticalGroup>
      )}
      {simulationsThatNeedManualModification &&
        simulationsThatNeedManualModification.length > 0 && (
          <SimulationsThatNeedManualModificationModal
            simulationsThatNeedManualModification={
              simulationsThatNeedManualModification
            }
            onClose={() => setSimulationsThatNeedManualModification(null)}
            stepTitle="Labels"
          />
        )}
    </>
  );
};

export default LabelsScreen;
