import React from "react";
import Icon, { IconType } from "../../atoms/icon/Icon";
import Text from "../../atoms/text/Text";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";

interface Props {
  icon: IconType;
  message: string;
  half?: boolean;
}

function EmptyList({ icon, message, half }: Props) {
  return (
    <VerticalGroup center verticalCenter full half={half}>
      <Icon type={icon} size={24} colour="secondaryDark4" />
      <Text colour="secondaryDark3" size="xl">
        {message}
      </Text>
    </VerticalGroup>
  );
}

export default EmptyList;
