import React, { useCallback, useState } from "react";

import API from "../../../../../services/api";
import Banner from "../../../../atoms/banner/Banner";
import Icon from "../../../../atoms/icon/Icon";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import LoadingSpinner from "../../../../atoms/loadingspinner/LoadingSpinner";
import Table, { Td, Th, Tr } from "../../../../atoms/table/Table";
import IconButton from "../../../../molecules/iconbutton/IconButton";
import EmptyList from "../../../../organisms/empty-list/EmptyList";
import Tooltip from "../../../../atoms/tooltip/Tooltip";
import BroadcastMessageModal from "../../../game/BroadcastMessageModal";
import Clickable from "../../../../atoms/clickable/Clickable";
import DeleteBroadcastMessage from "./BroadcastMessageDelete";
import { useBroadcastMessages } from "../../../../../hooks/useBroadcastMessages";

const STICKY_BAR_HEIGHT = 0;
const TOOLTIP_ID = "help-manual-table-tooltip";

interface BroadcastMessagesState {
  addOrEditModalOpen: boolean;
  deleteModalOpen: boolean;
  broadcastMessage: API.BroadcastMessage | null;
}

function BroadcastMessages() {
  const [
    { addOrEditModalOpen, deleteModalOpen, broadcastMessage },
    setBroadcastMessagesState,
  ] = useState<BroadcastMessagesState>({
    addOrEditModalOpen: false,
    deleteModalOpen: false,
    broadcastMessage: null,
  });

  const { inProgress, data, error, refresh } = useBroadcastMessages();

  const handleCloseAddOrEditModal = useCallback(() => {
    setBroadcastMessagesState((prevState) => ({
      ...prevState,
      addOrEditModalOpen: false,
    }));
  }, []);

  const onSaveMessage = useCallback(
    async (data: API.BroadcastMessageRequest) => {
      if (broadcastMessage) {
        await API.updateBroadcastMessage(broadcastMessage.id, data);
      } else {
        await API.createBroadcastMessage(data);
      }
      refresh();
      setBroadcastMessagesState((prevState) => ({
        ...prevState,
        broadcastMessage: null,
        addOrEditModalOpen: false,
      }));
    },
    [broadcastMessage, refresh],
  );

  const onDeleteComplete = useCallback(() => {
    setBroadcastMessagesState((prevState) => ({
      ...prevState,
      deleteModalOpen: false,
      broadcastMessage: null,
    }));
    refresh();
  }, [refresh]);

  const handleDeleteMessage = useCallback(
    (message: API.BroadcastMessage) => (event: any) => {
      event.stopPropagation();
      setBroadcastMessagesState((prevState) => ({
        ...prevState,
        deleteModalOpen: true,
        broadcastMessage: message,
      }));
    },
    [],
  );

  return (
    <>
      <Tooltip id={TOOLTIP_ID} />
      <BroadcastMessageModal
        key={broadcastMessage ? broadcastMessage.id : "new"}
        error={null}
        isOpen={addOrEditModalOpen}
        onClose={handleCloseAddOrEditModal}
        onSave={onSaveMessage}
        broadcastMessage={broadcastMessage}
        modalTitle={
          broadcastMessage ? "Edit Broadcast Message" : "Add Broadcast Message"
        }
        modalMessage={
          "Presaved broadcast message that can be sent to all participants"
        }
      />
      ;
      {deleteModalOpen && broadcastMessage && (
        <DeleteBroadcastMessage
          isOpen={deleteModalOpen}
          onClose={() =>
            setBroadcastMessagesState((prevState) => ({
              ...prevState,
              addOrEditModalOpen: false,
              deleteModalOpen: false,
              broadcastMessage: null,
            }))
          }
          onComplete={onDeleteComplete}
          message={broadcastMessage}
        />
      )}
      <InlineGroup block spread verticalCenter>
        <h2>Broadcast Messages</h2>
        <IconButton
          icon="broadcast"
          text="Add Message"
          className="mr-3"
          onClick={() => {
            setBroadcastMessagesState((prevState) => ({
              ...prevState,
              addOrEditModalOpen: true,
              broadcastMessage: null,
            }));
          }}
        />
      </InlineGroup>
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {inProgress && <LoadingSpinner />}
      {!data?.length && (
        <EmptyList
          icon="broadcast"
          message="There are no broadcast messages yet"
        />
      )}
      {!!data?.length && (
        <Table stickyHeader stickyTop={STICKY_BAR_HEIGHT} clickable>
          <thead>
            <Tr>
              <Th>Title</Th>
              <Th>Message</Th>
              <Th right>Actions</Th>
            </Tr>
          </thead>
          <tbody>
            {data?.map((message: API.BroadcastMessage) => (
              <Tr
                key={message.id}
                className="help-manuals-row"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  setBroadcastMessagesState((prevState) => ({
                    ...prevState,
                    addOrEditModalOpen: true,
                    broadcastMessage: message,
                  }));
                }}
              >
                <Td>{message.title}</Td>
                <Td>
                  {message.message.slice(0, 50)}
                  {message.message.length > 50 ? "..." : ""}
                </Td>
                <Td className="right">
                  <Clickable onClick={handleDeleteMessage(message)}>
                    <Icon
                      type="trash"
                      tt={{ content: "Delete Help Manual", id: TOOLTIP_ID }}
                    />
                  </Clickable>
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}

export default BroadcastMessages;
