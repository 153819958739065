import React, { useCallback, useMemo, useState } from "react";
import Table, { Td, Th, Tr } from "../../../../atoms/table/Table";
import Icon from "../../../../atoms/icon/Icon";
import Text from "../../../../atoms/text/Text";
import Clickable from "../../../../atoms/clickable/Clickable";

import AssignTabletModal from "./assign-tablet-modal/AssignTabletModal";
import { EventMapping } from "../utils/mapInitialTabletMappingForEvent";
import ColourSquare from "../components/colourSquare/ColourSquare";
import { unique } from "../../../../../lib/array";
import { useTabletConfigContext } from "../context/context";
interface ConfigurationParticipantsProps {
  participantDetails: API.ParticipantResponse[];
  tabletData: EventMapping;
}

export interface MergedData {
  teamNumber: number | null;
  ceo: string;
  firstName: string;
  lastName: string;
  email: string;
  tabletColour: API.TabletColour | "";
  teamColour: string;
  tabletId?: number;
  hasTablet: boolean;
  participantId: string;
}

const ConfigurationParticipants = ({
  participantDetails,
  tabletData,
}: ConfigurationParticipantsProps) => {
  const { updateTabletsForEvent } = useTabletConfigContext();
  const availableTabletColors = useMemo(() => {
    return unique(
      tabletData.tablets
        .filter((t) => t.available && t.participantId == null)
        .map((t) => t.colour),
    );
  }, [tabletData.tablets]);

  const [selectedParticipant, setSelectedParticipant] =
    useState<MergedData | null>(null);

  const handleAssignTabletColourModalClick = useCallback(
    (participant: MergedData) => {
      setSelectedParticipant(participant);
    },
    [],
  );

  const onClose = useCallback(() => {
    setSelectedParticipant(null);
  }, []);

  const mergedData: MergedData[] = useMemo(() => {
    return participantDetails.map((participant) => {
      const teamColour = participant.team
        ? tabletData.teamColours[participant.team - 1]
        : "";
      const newtabletData = tabletData.tablets.find(
        (tablet) => tablet.participantId === participant.id,
      );

      return {
        teamNumber: participant.team,
        ceo: participant.isCeo ? "Yes" : "No",
        firstName: participant.firstName,
        lastName: participant.lastName,
        email: participant.email,
        tabletColour: newtabletData?.colour || "",
        teamColour,
        tabletId: newtabletData?.tabletId,
        hasTablet: !!newtabletData,
        participantId: participant.id || "",
      };
    });
  }, [participantDetails, tabletData]);

  const updateTabletColor = useCallback(
    (participantId: string, newColor: API.TabletColour, tabletId: number) => {
      const tabletsWithPreviousAssignmentCleared = tabletData.tablets.map(
        (tablet) => {
          if (tablet.participantId === participantId) {
            return { ...tablet, participantId: null };
          }
          return tablet;
        },
      );

      const updatedTablets = tabletsWithPreviousAssignmentCleared.map(
        (tablet) => {
          if (tablet.tabletId === tabletId) {
            return {
              ...tablet,
              participantId: participantId,
            };
          }
          return tablet;
        },
      );

      updateTabletsForEvent(updatedTablets);
    },
    [tabletData.tablets, updateTabletsForEvent],
  );

  return (
    <>
      {selectedParticipant && (
        <AssignTabletModal
          updateTabletColor={updateTabletColor}
          availableTabletColors={availableTabletColors}
          isOpen
          onClose={onClose}
          currentParticipant={selectedParticipant}
          tabletData={tabletData}
        />
      )}
      {mergedData.length === 0 && (
        <Text className="mt-4" colour="secondaryDark3" size="lg">
          There are no participants in this event
        </Text>
      )}
      {mergedData.length > 0 && (
        <Table stickyHeader>
          <thead>
            <Tr>
              <Th>Team #</Th>
              <Th>CEO</Th>
              <Th>First Name</Th>
              <Th>Last Name</Th>
              <Th>Email</Th>
              <Th>Tablet ID</Th>
              <Th>Tablet Colour</Th>
              <Th>Team Colour</Th>
              <Th right>Actions</Th>
            </Tr>
          </thead>
          <tbody>
            {mergedData.map((data, index) => {
              return (
                <Tr
                  key={index}
                  borderColour={!data.hasTablet ? "danger" : undefined}
                >
                  <Td>{data.teamNumber}</Td>
                  <Td>{data.ceo}</Td>
                  <Td>{data.firstName}</Td>
                  <Td>{data.lastName}</Td>
                  <Td>{data.email}</Td>
                  <Td>{data.tabletId}</Td>
                  <Td>
                    <ColourSquare colour={data.tabletColour} />
                  </Td>
                  <Td>
                    <ColourSquare colour={data.teamColour} />
                  </Td>
                  <Td className="right">
                    <Clickable
                      onClick={(event) => {
                        event.stopPropagation();
                        handleAssignTabletColourModalClick(data);
                      }}
                    >
                      <Icon
                        size={5}
                        type="assignTablet"
                        tip={{
                          content: "Assign Tablet colour",
                          id: "Assign Tablet colour",
                        }}
                      />
                    </Clickable>
                  </Td>
                </Tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default ConfigurationParticipants;
