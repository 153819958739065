import React, { useCallback, useEffect, useState } from "react";
import {
  FieldErrors,
  Setters,
  State,
  FormError,
} from "../../../../../../hooks/useForm";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import Toggle from "../../../../../atoms/toggle/Toggle";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import { createChoice, ChoiceField } from "../FormFields/ChoiceField";
import { getFieldError } from "../FormFields/getFieldError";
import { WrittenResponseField } from "../FormFields/WrittenResponseField";

export const DEFAULT_CHOICES: API.AssessmentQuestionFieldRequest[] = [
  createChoice({
    label: "Yes",
    markCorrect: false,
  }),
  createChoice({
    label: "No",
    markCorrect: false,
  }),
  createChoice(),
];

export const validateMultipleChoiceForm = (
  question: State["formData"],
  errors: FormError[],
) => {
  const fields = question.fields;

  fields.forEach((field: API.AssessmentQuestionFieldRequest, index: number) => {
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    switch (field.type) {
      case "multiple_choices":
        if (!field.config.label) {
          errors.push({
            field: `field[${index}].label`,
            message: "choice text can not be empty",
          });
        }
        break;
      case "written_response":
        if (field.config.hasCharacterLimit) {
          if (typeof field.config.characterLimit === "undefined") {
            errors.push({
              field: `field[${index}].characterLimit`,
              message: "character limit is required",
            });
          }
          if (
            Number(field.config.characterLimit) <= 0 ||
            !field.config.characterLimit
          ) {
            errors.push({
              field: `field[${index}].characterLimit`,
              message: "character limit must greater than 0",
            });
          }
        }
        break;
    }
  });
};

interface Props {
  question: State["formData"];
  setField: Setters["setField"];
  setFieldSimple: Setters["setFieldSimple"];
  setFieldRaw: Setters["setFieldRaw"];
  fieldErrors?: FieldErrors;
}

export const MultipleChoiceFormDetails: React.FC<Props> = ({
  question,
  setFieldSimple,
  setFieldRaw,
  fieldErrors,
}) => {
  const [choices, updateChoices] = useState<API.MultipleChoiceFieldRequest[]>(
    question.fields.filter((field: API.AssessmentQuestionFieldRequest) => {
      return field.type === "multiple_choices";
    }),
  );

  const [writtenResponeField, setWrittenResponseField] =
    useState<API.WrittenResponseFieldRequest | null>(() => {
      const field = question.fields.find(
        (field: API.AssessmentQuestionFieldRequest) => {
          return field.type === "written_response";
        },
      );
      if (field) {
        return field;
      }
      return null;
    });

  const [hasOtherAnswer, setHasOtherAnswer] = useState<boolean>(
    writtenResponeField !== null,
  );

  const onUpdateChoice = useCallback(
    (newChoice: API.MultipleChoiceFieldRequest, index: number) => {
      updateChoices((choices) => {
        return choices.map((choice, idx) => {
          if (idx !== index) return choice;

          return {
            ...newChoice,
          };
        });
      });
    },
    [],
  );

  const onAddNewEmptyChoiceBefore = useCallback((index: number) => {
    updateChoices((choices) => {
      return choices.flatMap((choice, idx) => {
        if (idx !== index) return choice;
        return [createChoice(), choice];
      });
    });
  }, []);

  const onRemoveChoice = useCallback((index: number) => {
    updateChoices((choices) => {
      return choices.filter((_choice, idx) => {
        return idx !== index;
      });
    });
  }, []);

  const onToggleHasAnotherAnswer = useCallback((value: boolean) => {
    setHasOtherAnswer(value);
    if (value) {
      setWrittenResponseField({
        type: "written_response",
        config: {
          type: "written_response",
          hasCharacterLimit: false,
          label: "Other",
        },
      });
    } else {
      setWrittenResponseField(null);
    }
  }, []);

  useEffect(() => {
    const fields: API.AssessmentQuestionFieldRequest[] = [
      ...choices,
      ...(writtenResponeField ? [writtenResponeField] : []),
    ];

    setFieldRaw("marks", 0);
    setFieldRaw("fields", fields);
  }, [choices, setFieldRaw, writtenResponeField]);

  const shouldFocusAtField = choices.reduce(
    (result, field, index) => {
      const { max } = result;
      if (typeof field.newlyCreatedAt === "undefined") return result;

      if (max) {
        if (field.newlyCreatedAt > max) {
          result.max = field.newlyCreatedAt;
          result.index = index;
        }
      } else {
        result.max = field.newlyCreatedAt;
        result.index = index;
      }
      return result;
    },
    { max: -1, index: -1 },
  );

  const handleAssignPointsToggle = useCallback(
    (value: boolean) => {
      setFieldSimple("assignMarks")(value);
      if (!value) {
        updateChoices((choices) => {
          return choices.map((choice) => {
            return {
              ...choice,
              config: {
                ...choice.config,
                mark: 0,
              },
            };
          });
        });
      }
    },
    [setFieldSimple, updateChoices],
  );
  return (
    <VerticalGroup spaceBetweenElements={4}>
      <InlineGroup block>
        <Toggle
          reverse
          label="Assign points"
          checked={question.assignMarks}
          onUpdate={handleAssignPointsToggle}
        />
      </InlineGroup>

      <p>Select the correct answer below</p>

      <VerticalGroup spaceBetweenElements={4}>
        {choices.map((choice, idx) => {
          const fieldError = getFieldError(idx, "label", fieldErrors);
          return (
            <ChoiceField
              key={`choice-${idx}-${choice.newlyCreatedAt}`}
              choice={choice}
              index={idx}
              onChange={onUpdateChoice}
              onNewChoiceBefore={onAddNewEmptyChoiceBefore}
              onRemoveChoice={onRemoveChoice}
              assignMarks={question.assignMarks}
              error={fieldError}
              autoFocus={idx === shouldFocusAtField.index}
            />
          );
        })}
      </VerticalGroup>

      <hr />

      <Toggle
        reverse
        label='Add an "other" answer for comments'
        checked={hasOtherAnswer}
        onUpdate={onToggleHasAnotherAnswer}
      />

      {hasOtherAnswer && writtenResponeField && (
        <WrittenResponseField
          onChange={setWrittenResponseField}
          writtenResponseField={writtenResponeField}
          index={choices.length}
          fieldErrors={fieldErrors}
        />
      )}
    </VerticalGroup>
  );
};
