import React, { useContext, useMemo, useState } from "react";

import Banner from "../../../../../atoms/banner/Banner";
import Text from "../../../../../atoms/text/Text";
import Container from "../../../../../atoms/page/Page";
import { EventContext } from "../../context";
import StepContainer from "../../components/StepContainer";
import GameStatus from "./GameStatus";
import { useEvent } from "../../../../../../hooks/useEvent";
import { useGameStatus } from "../../../../../../hooks/useGameStatus";
import { usePollingModelConfiguration } from "../../../../../../hooks/useModelConfiguration";
import ProgressModal from "../../../../../organisms/standard-modal/ProgressModal";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";

const initStatuses = [
  "Configuring simulation settings",
  "Initialising player decisions",
  "Initialising facilitator adjustments",
  "Doing initial calculation",
  "Doing initial sculpt",
  "Ready for initial results to be uploaded",
  "NIM Sculpt 1",
  "Sculpting round 1 Iteration 1",
  "NIM Sculpt 2",
  "Sculpting round 1 Iteration 2",
  "NIM Sculpt 3",
  "Sculpting round 1 Iteration 3",
  "Saving company values",
  "Saving results",
];

const RunSimulation: React.FC = () => {
  const [hideError, setHideError] = useState(false);
  const { config } = useContext(EventContext);

  const { data: event, error: errorEvent } = useEvent(config!.id);
  const {
    data: gameStatus,
    error: errorGameStatus,
    refresh: refreshGameStatus,
  } = useGameStatus(config!.id);

  const { data: modelConfig } = usePollingModelConfiguration(config!.id, 5000);

  const percentageDone = useMemo(() => {
    if (modelConfig == null) {
      return 1;
    }

    const index = initStatuses.indexOf(modelConfig.progressMessage);

    if (index === -1) {
      return 50;
    }

    return (index / initStatuses.length) * 100;
  }, [modelConfig]);

  let content = null;
  if (
    !event ||
    !gameStatus ||
    modelConfig == null ||
    modelConfig?.state === "initialising"
  ) {
    content = !hideError ? (
      <Container noOverflow>
        <ProgressModal
          percentageDone={percentageDone}
          onClose={() => setHideError(true)}
          message={modelConfig?.progressMessage ?? "Setting up model"}
        />
      </Container>
    ) : (
      <Container noOverflow>
        <VerticalGroup className="h-88" wide center verticalCenter>
          <Text bold size="2xl">
            AWS Model has not been setup
          </Text>
        </VerticalGroup>
      </Container>
    );
  } else {
    content = (
      <GameStatus
        event={event}
        game={gameStatus}
        fetchGameStatus={refreshGameStatus}
      />
    );
  }

  return (
    <StepContainer>
      <Banner
        type="error"
        active={!!errorEvent}
        message={errorEvent?.message}
      />
      <Banner
        type="error"
        active={!!errorGameStatus}
        message={errorGameStatus?.message}
      />
      {content}
    </StepContainer>
  );
};

export default RunSimulation;
