import React, { ReactNode } from "react";
import classNames from "classnames";

import "./ModalBody.scss";
import Icon, { IconType } from "../../atoms/icon/Icon";

interface ModalBodyProps extends React.HTMLProps<HTMLDivElement> {
  type: "standard" | "info" | "error" | "warning" | "confirmation";
  title: string;
  description?: string | ReactNode;
  className?: string;
  icon?: IconType;
  "data-test"?: string;
}

const ModalBody = React.forwardRef<HTMLDivElement, ModalBodyProps>(
  ({ type, className, title, description, children, icon, ...rest }, ref) => {
    ModalBody.displayName = "ModalBody";
    return (
      <div
        ref={ref}
        className={classNames("standard-modal-body-style", className, type)}
        {...rest}
      >
        {icon && (
          <div className="icon-header">
            <Icon type={icon} size={10} colour="white" />
          </div>
        )}
        <div className="content">
          <div className="title">{title}</div>
          <div className="description">{description}</div>
          {children}
        </div>
      </div>
    );
  },
);

export default ModalBody;
