import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useMemoRequest } from "../../../hooks/useMemoRequest";
import modelApi from "../../../services/modelApi";
import Banner from "../../atoms/banner/Banner";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import RoundResultsPage from "./RoundResultsPage";
import { usePollingModelConfiguration } from "../../../hooks/useModelConfiguration";
import ConfirmModal from "../../organisms/confirm-modal/ConfirmModal";
import RoundResultsNotGeneratedError from "./components/RoundResultsNotGeneratedError";

const onlyThesePages: API.ReportingLayoutType[] | null = [];

const RoundResultsPresentation: React.FC = () => {
  const { eventId } = useParams<{
    eventId: string;
  }>();

  const getRoundNumber = useCallback(async () => {
    try {
      const preso = await modelApi.getReportingPresentationState(eventId, {
        bypass: false,
      });
      return { round: preso.round, calcVersion: preso.calcVersion };
    } catch (error) {
      return { round: 0, calcVersion: 0 };
    }
  }, [eventId]);

  const { inProgress, data, error, refresh } = useMemoRequest<{
    round: number;
    calcVersion: number;
  }>(getRoundNumber);

  return (
    <>
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {inProgress && <LoadingSpinner />}
      {data != null && (
        <RoundResultsPresentationWithRound
          eventId={eventId}
          roundNumber={data.round}
          calcVersion={data.calcVersion}
          refreshPresentation={refresh}
        />
      )}
    </>
  );
};

const RoundResultsPresentationWithRound: React.FC<{
  eventId: string;
  roundNumber: number;
  calcVersion: number;
  refreshPresentation: () => void;
}> = ({ eventId, roundNumber, refreshPresentation, calcVersion }) => {
  const [checkedIfRoundDifferent, setCheckedIfRoundDifferent] = useState(false);
  const [round, setRound] = useState(roundNumber);
  const [calcVers, setCalcVers] = useState(calcVersion);
  const getReportingResults = useCallback(() => {
    return modelApi.getReportingResults(eventId, round);
  }, [eventId, round]);

  const { inProgress, data, error, refresh } =
    useMemoRequest<ModelAPI.Reporting.ReportingResults>(getReportingResults);

  const notFoundError = error?.message?.includes("not found");

  const handleRoundChange = useCallback(
    (round: number, calcVersion?: number) => {
      setRound(round);
      if (calcVersion != null) {
        setCalcVers(calcVersion);
      }
    },
    [],
  );

  useEffect(() => {
    if (calcVers !== calcVersion) {
      refresh();
    }
  }, [calcVers, calcVersion, refresh]);

  const handleRefresh = useCallback(
    (round: number) => {
      setRound(round);
      refreshPresentation();
    },
    [refreshPresentation],
  );

  const { data: config } = usePollingModelConfiguration(eventId, 10000);

  const handlePresentDifferentRound = useCallback(async () => {
    if (config?.currentRound) {
      await modelApi.updateReportingPresentationState(eventId, {
        round: config.currentRound,
        clicks: 0,
        index: 0,
        version: new Date().valueOf(),
        cast: false,
        fontMultiplier: 1,
        showDebug: false,
      });
      setCheckedIfRoundDifferent(true);
    }
  }, [config?.currentRound, eventId]);

  const isRoundMismatch = useMemo(() => {
    if (
      round === 0 &&
      config &&
      config.currentRound !== 1 &&
      config.state !== "initial-results-uploaded"
    ) {
      return true;
    } else {
      config?.currentRound !== round;
    }
  }, [config, round]);

  return (
    <>
      {!notFoundError && error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {notFoundError && (
        <RoundResultsNotGeneratedError
          roundNumber={round}
          title="Presentation"
        />
      )}
      {inProgress && <LoadingSpinner />}
      <div style={{ height: "100vh" }}>
        <RoundResultsPage
          key={eventId + roundNumber}
          eventId={eventId}
          data={data}
          roundNumber={roundNumber}
          onlyThesePages={onlyThesePages}
          hidePagination
          allowPresentationMode
          syncState
          readSyncState
          refreshPresentation={handleRefresh}
          handleRoundChange={handleRoundChange}
          showFullScreenButton
          isPresentationScreen
        />
      </div>
      {isRoundMismatch && !checkedIfRoundDifferent && (
        <ConfirmModal
          isOpen
          title="Presentation Round Mismatch"
          description={`You are currently presenting Round ${round}, but the current round is ${config?.currentRound}. Would you like to present round ${config?.currentRound} instead?`}
          onConfirm={handlePresentDifferentRound}
          onCancel={() => setCheckedIfRoundDifferent(true)}
          onDiscard={() => setCheckedIfRoundDifferent(true)}
          discardTitle="No"
          onClose={() => setCheckedIfRoundDifferent(true)}
        />
      )}
    </>
  );
};

export default RoundResultsPresentation;
