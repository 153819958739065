import React, { ReactNode } from "react";
import { ThemeColours, ThemeSpacing } from "../../types/theme";
import classNames from "classnames";
import "./LogoContainer.scss";
import InlineGroup from "../atoms/inlinegroup/InlineGroup";

interface LogoContainerProps {
  colour: ThemeColours;
  size: ThemeSpacing;
  children: ReactNode;
  active?: boolean;
  circle?: boolean;
}

const LogoContainer: React.FC<LogoContainerProps> = ({
  colour,
  size,
  children,
  active,
  circle,
}) => {
  const colorClass = colour ? `color-${colour}` : null;
  const sizeClass = size ? `size-${size}` : null;
  const activeClass = active ? "active-logo" : "";
  const circleClass = circle ? "circle" : "";

  return (
    <InlineGroup
      center
      verticalCenter
      className={classNames(
        "logo-containerss",
        colorClass,
        sizeClass,
        activeClass,
        circleClass,
      )}
    >
      {children}
    </InlineGroup>
  );
};

export default LogoContainer;
