import React, { useMemo } from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../../tables/TableWithSections";
import { formatValue } from "../../utils/formatters";
import convertWSFRoundResult from "../utils/convertWSFRoundResult";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["wholesaleFundingMovements"];
  fields: Array<{ field: string; name: string }>;
}

const SpreadOnly: React.FC<Props> = ({ fields, data, configuration }) => {
  const { numberOfHistoricRounds, numberOfRounds, numberOfPostGameRounds } =
    configuration;

  const values = useMemo(() => {
    const results: Array<{ [field: string]: number }> = [];

    const totalYears =
      numberOfHistoricRounds + numberOfRounds + numberOfPostGameRounds;
    for (let year = 0; year <= totalYears; year++) {
      const result: { [field: string]: number } = {
        ...convertWSFRoundResult({
          totalYears,
          rounds: data[year].spreadOnly.rounds,
        }),
        total: data[year].spreadOnly.total,
        centralBankFacilityCosts:
          data[year].spreadOnly.centralBankFacilityCosts,
      };
      results.push(result);
    }
    return results;
  }, [numberOfHistoricRounds, numberOfPostGameRounds, numberOfRounds, data]);
  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <TableWithSections
          header="Spread Only"
          hasRoundZero
          data={[
            {
              fields: [
                ...fields.map((f) => f.field),
                "total",
                "centralBankFacilityCosts",
              ],
              totalFields: ["total", "centralBankFacilityCosts"],
              values,
            },
          ]}
          fieldFormatter={(field: string) =>
            fields.find((f) => f.field === field)?.name ?? null
          }
          formatter={(value: number) => formatValue(value, 1)}
        />
      </VerticalGroup>
    </>
  );
};

export default SpreadOnly;
