import React from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";
import { formatBps, formatValue, percentage } from "../utils/formatters";
import pluckResultsByType from "../utils/pluckResultsByType";
import { projects } from "../constants";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["specialProjects"];
}

const ModelTeamResultsSpecialProjects: React.FC<Props> = ({ data }) => {
  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <TableWithSections
          header="Project Costs"
          data={[
            {
              name: "Benefit: Cost To Asset Reduction",
              fields: [...projects, "executiveDecisions", "total"],
              values: pluckResultsByType(data, "projectCosts"),
              totalFields: ["total"],
            },
          ]}
        />
        {projects.map((p) => (
          <TableWithSections
            key={p}
            header={p}
            data={[
              {
                fields: [
                  "reductionInDepositsOBIMargin",
                  "reductionInOperationalRisk",
                  "boostToObiBusinessIncome",
                ],
                values: pluckResultsByType(data, p),
                formatter: (val: number, field: string) => {
                  if (field === "reductionInDepositsOBIMargin") {
                    return formatBps(val);
                  }
                  return percentage(val);
                },
              },
              {
                name: "Benefit: Cost To Asset Reduction",
                fields: [
                  "HOME_LOANS",
                  "BUSINESS_LOANS",
                  "DEPOSITS",
                  "CREDIT_CARDS",
                  "WEALTH_MANAGEMENT",
                  "INSTITUTIONAL_BANKING",
                  "OFFSHORE_BANK",
                ],
                values: pluckResultsByType(
                  pluckResultsByType(data, p),
                  "benefitCostToAssetReduction",
                ),
                formatter: (val: number) => percentage(val),
              },
              {
                name: "Benefit: RWA (%) Reduction",
                fields: [
                  "benefitRWAReduction.HOME_LOANS",
                  "newRWAs.HOME_LOANS.propietaryHomeLoans",
                  "newRWAs.HOME_LOANS.brokerHomeLoans",
                  "benefitRWAReduction.BUSINESS_LOANS",
                  "benefitRWAReduction.DEPOSITS",
                  "benefitRWAReduction.CREDIT_CARDS",
                  "benefitRWAReduction.WEALTH_MANAGEMENT",
                  "benefitRWAReduction.INSTITUTIONAL_BANKING",
                  "benefitRWAReduction.OFFSHORE_BANK",
                ],
                values: pluckResultsByType(data, p),
                formatter: (val: number) => percentage(val),
                fieldFormatter: (field: string) => {
                  if (field === "HOME_LOANS.propietaryHomeLoans")
                    return "Home Loans - Prop (Change)";
                  if (field === "HOME_LOANS.brokerHomeLoans")
                    return "Home Loans - Broker (Change)";
                  return null;
                },
              },
              {
                name: "Benefit: LIE Reduction",
                fields: [
                  "benefitLIEReduction.HOME_LOANS",
                  "benefitLIEReduction.BUSINESS_LOANS",
                  "benefitLIEReduction.DEPOSITS",
                  "benefitLIEReduction.CREDIT_CARDS",
                  "benefitLIEReduction.WEALTH_MANAGEMENT",
                  "benefitLIEReduction.INSTITUTIONAL_BANKING",
                  "benefitLIEReduction.OFFSHORE_BANK",
                ],
                values: pluckResultsByType(data, p),
                formatter: (val: number) => percentage(val),
              },
              {
                name: "Benefit: Notional Pricing",
                fields: [
                  "benefitNotionalPricing.HOME_LOANS",
                  "benefitNotionalPricing.BUSINESS_LOANS",
                  "benefitNotionalPricing.DEPOSITS",
                  "benefitNotionalPricing.CREDIT_CARDS",
                  "benefitNotionalPricing.WEALTH_MANAGEMENT",
                  "benefitNotionalPricing.INSTITUTIONAL_BANKING",
                  "benefitNotionalPricing.OFFSHORE_BANK",
                ],
                values: pluckResultsByType(data, p),
              },
              {
                name: "Impact on Loss Multipliers",
                fields: [
                  "impactOnLossMultipliers.HOME_LOANS",
                  "impactOnLossMultipliers.BUSINESS_LOANS",
                  "impactOnLossMultipliers.DEPOSITS",
                  "impactOnLossMultipliers.CREDIT_CARDS",
                  "impactOnLossMultipliers.WEALTH_MANAGEMENT",
                  "impactOnLossMultipliers.INSTITUTIONAL_BANKING",
                  "impactOnLossMultipliers.OFFSHORE_BANK",
                ],
                values: pluckResultsByType(data, p),
                formatter: (val: number) => percentage(val),
              },
              {
                name: "Cumulative Impact on Loss Multipliers",
                fields: [
                  "cumulativeImpactOnLossMultipliers.HOME_LOANS",
                  "cumulativeImpactOnLossMultipliers.BUSINESS_LOANS",
                  "cumulativeImpactOnLossMultipliers.DEPOSITS",
                  "cumulativeImpactOnLossMultipliers.CREDIT_CARDS",
                  "cumulativeImpactOnLossMultipliers.WEALTH_MANAGEMENT",
                  "cumulativeImpactOnLossMultipliers.INSTITUTIONAL_BANKING",
                  "cumulativeImpactOnLossMultipliers.OFFSHORE_BANK",
                ],
                values: pluckResultsByType(data, p),
                formatter: (val: number) => percentage(val),
              },
              {
                name: "Impact on new business mix",
                fields: ["goodQuality", "mediumQuality", "poorQuality"],
                values: pluckResultsByType(
                  pluckResultsByType(data, p),
                  "impactOnNewBusinessMix",
                ),
                formatter: (val: number) => percentage(val),
              },
              {
                name: "Business Lending",
                fields: [
                  "BUSINESS_LOANS.goodQuality",
                  "BUSINESS_LOANS.mediumQuality",
                  "BUSINESS_LOANS.poorQuality",
                ],
                values: pluckResultsByType(
                  pluckResultsByType(data, p),
                  "newRWAs",
                ),
                formatter: (val: number) => percentage(val),
              },
              {
                name: "NPS Impact",
                fields: [
                  "HOME_LOANS",
                  "BUSINESS_LOANS",
                  "DEPOSITS",
                  "CREDIT_CARDS",
                  "WEALTH_MANAGEMENT",
                  "INSTITUTIONAL_BANKING",
                  "OFFSHORE_BANK",
                ],
                values: pluckResultsByType(
                  pluckResultsByType(data, p),
                  "npsImpact",
                ),
                formatter: (val: number) => formatValue(val, 2, 2),
              },
              {
                name: "Non-Financial Impacts",
                fields: [
                  "employeeEngagement",
                  "complaints",
                  "riskIncidents",
                  "communityTrust",
                ],
                values: pluckResultsByType(
                  pluckResultsByType(data, p),
                  "nonFinancialImpacts",
                ),
                formatter: (val: number, field: string) => {
                  if (
                    ["employeeEngagement", "communityTrust"].includes(field)
                  ) {
                    return percentage(val);
                  }
                  return null;
                },
              },
              {
                fields: [
                  "increaseInOperationalRisk",
                  "increaseInCoreSpread",
                  "reductionInTermSpreadMultiplier",
                  "remediationAndFines",
                ],
                values: pluckResultsByType(data, p),
                formatter: (val: number, field: string) => {
                  if (
                    [
                      "increaseInOperationalRisk",
                      "increaseInCoreSpread",
                      "reductionInTermSpreadMultiplier",
                    ].includes(field)
                  ) {
                    return percentage(val);
                  }
                  return null;
                },
              },
              {
                name: "Risk Event 1",
                fields: [
                  "riskEvent1.projectReductionImpact",
                  "riskEvent1.regulatorSanctionsImpact",
                  "riskEvent1.riskEventRemediation",
                ],
                values: pluckResultsByType(data, p),
                formatter: (val: number, field: string) => {
                  if (field === "riskEvent1.projectReductionImpact") {
                    return percentage(val);
                  }
                  return null;
                },
              },
            ]}
          />
        ))}
      </VerticalGroup>
    </>
  );
};

export default ModelTeamResultsSpecialProjects;
