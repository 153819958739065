import React, { useRef } from "react";

import useHover from "../../../hooks/useHover";

import Icon, { IconType, Props as IconProps } from "./Icon";

export interface Props extends IconProps {
  hoverType: IconType;
}

function HoverableIcon(props: Props) {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);
  const { hoverType, ...rest } = props;

  return (
    <span ref={hoverRef}>
      <Icon hidden={isHovered} {...rest} />
      <Icon hidden={!isHovered} {...rest} type={hoverType} />
    </span>
  );
}

export default HoverableIcon;
