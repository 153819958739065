import React, { useMemo } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Theme from "../../../../styles/_theme.module.scss";
import { reportingPercentage } from "../../model/utils/formatters";
import { getYAxisDataV2 } from "../charts/yaxis";
import ChartCard from "../components/ChartCard";
import ResultsLineChart from "../charts/ResultsLineChart";
import { convertRoundDataToPercentages } from "../charts/convertRoundDataToPercentages";
import RoundResultsContainer from "../components/RoundsResultsContainer";

interface Props {
  pastRound1: boolean;
  wholesaleFundingCostsAllRatings: ModelAPI.Reporting.DataAndPositions;
}

const bands = [
  { value: "AAA", colour: Theme.chart0 },
  { value: "AA", colour: Theme.chart1 },
  { value: "A", colour: Theme.chart2 },
  { value: "BBB", colour: Theme.chart3 },
  { value: "BB", colour: Theme.chart4 },
  { value: "B", colour: Theme.chart5 },
];

const RoundResultsWholesaleFundingCostsAllRatings: React.FC<Props> = ({
  pastRound1,
  wholesaleFundingCostsAllRatings,
}) => {
  const data = useMemo(
    () => convertRoundDataToPercentages(wholesaleFundingCostsAllRatings.data),
    [wholesaleFundingCostsAllRatings.data],
  );

  const { minValue, maxValue, numberOfTicks, yAxisDp } = getYAxisDataV2({
    isPercentage: true,
    pastRound1,
    data,
  });

  const lines = bands.map((b) => ({
    name: b.value,
    key: b.value,
    colour: b.colour,
  }));
  return (
    <RoundResultsContainer>
      <VerticalGroup full center wide>
        <ChartCard heading="Wholesale Funding Costs - 3 Year">
          <ResultsLineChart
            data={data}
            formatter={(val: number) => reportingPercentage(val)}
            yAxisFormatter={(val: number) => reportingPercentage(val, yAxisDp)}
            minYAxisvalue={minValue}
            maxYAxisvalue={maxValue}
            numberOfTicks={numberOfTicks}
            lines={lines}
            shouldRenderLines={true}
            linesOneByOne={false}
          />
        </ChartCard>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsWholesaleFundingCostsAllRatings;
