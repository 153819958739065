import { useEffect, useMemo, useCallback } from "react";
import API from "../services/api";
import { useAPIRequestRunner } from "./useAPIRequest";

export const useSimulations = (
  clientIds: string[],
  isClientIdLimited = false,
  state?: string,
) => {
  const {
    state: { inProgress, data, error },
    run,
  } = useAPIRequestRunner<API.SimulationResponse[]>();

  const doApiRequest = useCallback(() => {
    run(API.getSimulations(clientIds, state));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...clientIds, run, state]);

  useEffect(() => {
    if (isClientIdLimited && clientIds.length > 0) {
      doApiRequest();
    } else if (isClientIdLimited === false) {
      doApiRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...clientIds, doApiRequest, isClientIdLimited, run, state]);

  return useMemo(
    () => ({
      inProgress,
      data,
      error,
      refresh: doApiRequest,
    }),
    [inProgress, data, error, doApiRequest],
  );
};
