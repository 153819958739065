import {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useMemo,
} from "react";

import { Actions, SimulationState } from "./state";
import { NotImplemented } from "../../../constants/functions";

interface Context {
  config: SimulationState["config"];
  updateConfig: (payload: API.SimulationResponse) => void;
  updateFormStatus: (payload: SimulationState["formStatus"]) => void;
  assignOnSubmitFunction: (payload: () => Promise<false | void>) => void;
  jumpToStepByKey: (
    key: keyof Omit<API.SimulationResponse, "id" | "state" | "clients">,
  ) => void;
}

export const SimulationContext = createContext<Context>({
  config: undefined,
  updateConfig: NotImplemented,
  assignOnSubmitFunction: NotImplemented,
  updateFormStatus: NotImplemented,
  jumpToStepByKey: NotImplemented,
});

export const useSimulationContextValue = (
  state: SimulationState,
  dispatch: Dispatch<Actions>,
  jumpToStep: (index: number) => void,
): Context => {
  const updateConfig = useCallback(
    (payload: API.SimulationResponse) => {
      dispatch({
        type: "UpdateConfig",
        payload,
      });
    },
    [dispatch],
  );

  const assignOnSubmitFunction = useCallback(
    (payload: () => Promise<false | void>) => {
      dispatch({
        type: "AssignOnSubmitFunction",
        payload,
      });
    },
    [dispatch],
  );

  const updateFormStatus = useCallback(
    (payload: SimulationState["formStatus"]) => {
      dispatch({
        type: "UpdateFormStatus",
        payload,
      });
    },
    [dispatch],
  );

  const jumpToStepByKey = useCallback(
    (key: string) => {
      const index = state.steps.findIndex((step) => step.key === key);
      jumpToStep(index);
    },
    [jumpToStep, state.steps],
  );

  const context = useMemo(
    () => ({
      config: state.config,
      updateConfig,
      assignOnSubmitFunction,
      updateFormStatus,
      jumpToStepByKey,
    }),
    [
      state.config,
      updateConfig,
      assignOnSubmitFunction,
      updateFormStatus,
      jumpToStepByKey,
    ],
  );

  return context;
};

export const useSimulationContext = () => {
  return useContext(SimulationContext);
};
