import React from "react";

import AssessmentPreviewWithData from "./AssessmentPreviewWithData";
import Banner from "../../atoms/banner/Banner";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import { useAssessment } from "../../../hooks/useAssessments";

interface Props {
  assessmentId: string;
}

const AssessmentPreview: React.FC<Props> = ({ assessmentId }) => {
  const { inProgress, error, data } = useAssessment(assessmentId);

  return (
    <>
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {inProgress && <LoadingSpinner />}
      {!!data && !inProgress && <AssessmentPreviewWithData assessment={data} />}
    </>
  );
};

export default AssessmentPreview;
