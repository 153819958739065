import React from "react";

import "../../../../../atoms/form/input/Input.scss";
import IntegerDropdown from "../../../../../organisms/integer-dropdown/IntegerDropdown";
import DisplayField from "../../../../../atoms/form/display-field/DisplayField";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import Datepicker from "../../../../../atoms/datepicker/Datepicker";
import { duration } from "../../../../../../lib/date";
import Text from "../../../../../atoms/text/Text";

export interface RoundSchedule {
  timeAllocated: number;
  startTime: Date | null;
  finishTime: Date | null;
}

interface RoundsScheduleProps {
  label: string;
  facilitatedRoundsEnabled: boolean;
  schedule: RoundSchedule;
  onChange: (value: RoundSchedule) => void;
  fieldErrors: {
    startTime?: string;
    finishTime?: string;
  };
}

const RoundSchedulingRow: React.FC<RoundsScheduleProps> = ({
  label,
  facilitatedRoundsEnabled,
  schedule,
  fieldErrors,
  onChange,
}) => {
  if (facilitatedRoundsEnabled) {
    return (
      <IntegerDropdown
        isCreatable
        value={schedule.timeAllocated}
        onChange={(selectedValue: { value: string; label: string }) =>
          onChange({
            ...schedule,
            timeAllocated: parseInt(selectedValue?.value ?? 5),
          })
        }
        min={5}
        max={90}
        step={5}
        labelFormatter={(val: number) => `${val} mins`}
        label={label}
      />
    );
  }
  return (
    <DisplayField
      stretch
      label={label}
      verticalCenter
      value={
        <InlineGroup spaceBetweenElements={2}>
          <Datepicker
            date={schedule.startTime}
            onChange={(value: Date | null) =>
              onChange({ ...schedule, startTime: value })
            }
            withTime
            error={fieldErrors.startTime}
          />
          <Datepicker
            date={schedule.finishTime}
            onChange={(value: Date | null) =>
              onChange({ ...schedule, finishTime: value })
            }
            withTime
            error={fieldErrors.finishTime}
          />
          <div className="display-field-value">
            <Text size="sm" className="ml-2">
              {duration(schedule.startTime!, schedule.finishTime!)}
            </Text>
          </div>
        </InlineGroup>
      }
    />
  );
};

export default RoundSchedulingRow;
