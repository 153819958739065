import React from "react";
import {
  ThemeColours,
  ThemeFontSize,
  ThemeSpacing,
} from "../../../types/theme";
import classNames from "classnames";
import "./Badge.scss";
import ResultsText from "../../pages/results/results-text/ResultsText";
import Text from "../text/Text";
import { ResponsiveSize } from "../image/Image";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  colour?: ThemeColours;
  size?: ThemeSpacing;
  textSize?: ThemeFontSize;
  value: string | number;
  floating?: boolean;
  dynamicSize?: ResponsiveSize;
}

function Badge({
  size,
  colour,
  value,
  textSize,
  floating,
  dynamicSize,
  ...rest
}: Props) {
  const sizeClass = size ? `size-${size}` : null;
  const colourClass = colour ? `colour-${colour}` : null;
  const responsiveStyle = dynamicSize
    ? {
        width: `clamp(${dynamicSize.min}, ${dynamicSize.preferred}, ${dynamicSize.max})`,
        height: `clamp(${dynamicSize.min}, ${dynamicSize.preferred}, ${dynamicSize.max})`,
        borderRadius: "50%",
      }
    : {};

  const TextComponent = dynamicSize ? ResultsText : Text;

  return (
    <div
      style={responsiveStyle}
      className={classNames("badge", sizeClass, colourClass, { floating })}
      {...rest}
    >
      <TextComponent size={textSize} bold colour="white">
        {value}
      </TextComponent>
    </div>
  );
}

export default Badge;
