import React from "react";
import { useCurrentEvent } from "../../../../../context/CurrentEventContext";

export const EventBreadcrumb: React.FC = () => {
  const { event } = useCurrentEvent();

  if (!event) {
    return <>...</>;
  }

  return <>{event.name}</>;
};
