import React from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import { projects } from "../../constants";
import TableWithSections from "../../tables/TableWithSections";
import { formatBps, formatValue, percentage } from "../../utils/formatters";
import pluckResultsByType from "../../utils/pluckResultsByType";
import useModelQuery from "../../utils/useModelQuery";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["regulatoryCapital"];
}

const investmentsFormatter = (val: number, field: string) => {
  if (
    field === "investments" ||
    field === "WEALTH_MANAGEMENT" ||
    field === "OFFSHORE_BANK"
  ) {
    if (val === -1) {
      return "Deduction";
    }
    return percentage(val);
  }
  return null;
};

const ModelTeamResultsRegulatoryCapital: React.FC<Props> = ({ data }) => {
  const riskWeightsValues = pluckResultsByType(data, "riskWeightings");
  const capitalTreatmentsValues = pluckResultsByType(data, "capitalTreatments");
  const capitalTreatmentsForTerminalValueValues = pluckResultsByType(
    data,
    "capitalTreatmentsNormalisedForTerminalValue",
  );
  const capitalAllocationByProductValues = pluckResultsByType(
    data,
    "capitalAllocationByProduct",
  );
  const cet1Values = pluckResultsByType(data, "cet1Ratio");
  const creditRatingValues = pluckResultsByType(data, "creditRating");
  const mandatedCet1CapitalValues = pluckResultsByType(
    data,
    "mandatedCet1Capital",
  );
  const capitalSculptValues = pluckResultsByType(
    data,
    "capitalSculptCalculations",
  );

  const { openIfTable } = useModelQuery();

  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <TableWithSections
          header="Assets"
          data={[
            {
              name: "Closing Asset Balances",
              fields: [
                "cash",
                "HOME_LOANS.propietaryHomeLoans",
                "HOME_LOANS.brokerHomeLoans",
                "BUSINESS_LOANS",
                "CREDIT_CARDS",
                "WEALTH_MANAGEMENT",
                "INSTITUTIONAL_BANKING",
                "OFFSHORE_BANK",
                "otherFixedAssets",
                "investments",
              ],
              values: pluckResultsByType(data, "assets"),
              // formatter: (val: number, field: string) => {
              //   return investmentsFormatter(val, field);
              // },
            },
          ]}
        />
        <TableWithSections
          header="Risk Weightings"
          data={[
            {
              name: "Cash",
              fields: ["standardRiskWeight"],
              values: pluckResultsByType(riskWeightsValues, "cash"),
            },
            {
              name: "Home Loans - Proprietary",
              fields: [
                "propietaryHomeLoans.standardRiskWeight",
                ...projects.map((p) => `propietaryHomeLoans.${p}`),
                "propietaryHomeLoans.total",
              ],
              totalFields: ["propietaryHomeLoans.total"],
              values: pluckResultsByType(riskWeightsValues, "HOME_LOANS"),
              formatter: (val: number) => percentage(val),
            },
            {
              name: "Home Loans - Broker",
              fields: [
                "brokerHomeLoans.standardRiskWeight",
                ...projects.map((p) => `brokerHomeLoans.${p}`),
                "brokerHomeLoans.total",
              ],
              totalFields: ["brokerHomeLoans.total"],
              values: pluckResultsByType(riskWeightsValues, "HOME_LOANS"),
              formatter: (val: number) => percentage(val),
            },
            {
              isTotalsSection: true,
              fields: ["effectiveRiskWeight"],
              values: pluckResultsByType(riskWeightsValues, "HOME_LOANS"),
              formatter: (val: number) => percentage(val),
            },
            {
              name: "Business Loans",
              fields: [
                "standardMethod.riskWeighting",
                "advancedMethod.goodQualityBalance",
                "advancedMethod.mediumQualityBalance",
                "advancedMethod.poorQualityBalance",
                "advancedMethod.goodQualityRiskWeight",
                "advancedMethod.mediumQualityRiskWeight",
                "advancedMethod.poorQualityRiskWeight",
                "advancedMethod.blendedAdvancedRiskWeight",
                "standardisedFrameworkRiskWeight",
                "advancedFrameworkRiskWeight",
                "selectedFrameworkRiskWeight",
                ...projects,
                "excoDecisions",
                "total",
              ],
              totalFields: ["selectedFrameworkRiskWeight", "total"],
              values: pluckResultsByType(riskWeightsValues, "BUSINESS_LOANS"),
              formatter: (val: number, field: string) => {
                if (
                  [
                    "advancedMethod.goodQualityBalance",
                    "advancedMethod.mediumQualityBalance",
                    "advancedMethod.poorQualityBalance",
                  ].includes(field)
                ) {
                  return null;
                }
                return percentage(val);
              },
            },
            {
              name: "Credit Cards",
              fields: [
                "standardRiskWeight",
                ...projects,
                "excoDecisions",
                "total",
              ],
              totalFields: ["total"],
              values: pluckResultsByType(riskWeightsValues, "CREDIT_CARDS"),
              formatter: (val: number) => percentage(val),
            },
            {
              name: "Institutional Banking",
              fields: ["standardRiskWeight", ...projects, "total"],
              totalFields: ["total"],
              values: pluckResultsByType(
                riskWeightsValues,
                "INSTITUTIONAL_BANKING",
              ),
              formatter: (val: number) => percentage(val),
            },
            {
              name: "Offshore Bank",
              fields: ["standardRiskWeight", ...projects, "total"],
              totalFields: ["total"],
              values: pluckResultsByType(riskWeightsValues, "OFFSHORE_BANK"),
              formatter: (val: number) => percentage(val),
            },
            {
              name: "Wealth Management",
              fields: ["WEALTH_MANAGEMENT"],
              totalFields: ["WEALTH_MANAGEMENT"],
              values: riskWeightsValues,
              formatter: investmentsFormatter,
            },
            {
              name: "Other Fixed Assets",
              fields: ["standardRiskWeight", ...projects, "total"],
              totalFields: ["total"],
              values: pluckResultsByType(riskWeightsValues, "otherFixedAssets"),
              formatter: (val: number) => percentage(val),
            },
            {
              name: "Investments",
              fields: ["investments"],
              totalFields: ["investments"],
              values: riskWeightsValues,
              formatter: investmentsFormatter,
            },
          ]}
        />
        <TableWithSections
          header="Capital Treatments"
          data={[
            {
              name: "Operational Risk",
              fields: ["riskBase", ...projects, "riskBeforeRegulatoryBuffers"],
              totalFields: ["riskBeforeRegulatoryBuffers"],
              values: pluckResultsByType(
                capitalTreatmentsValues,
                "operationalRisk",
              ),
              formatter: (val: number) => percentage(val),
            },
            {
              fields: [
                "cash",
                "HOME_LOANS.propietaryHomeLoans",
                "HOME_LOANS.brokerHomeLoans",
                "BUSINESS_LOANS",
                "CREDIT_CARDS",
                "WEALTH_MANAGEMENT",
                "INSTITUTIONAL_BANKING",
                "OFFSHORE_BANK",
                "otherFixedAssets",
                "investments",
              ],
              values: capitalTreatmentsValues,
              formatter: (val: number, field: string) =>
                investmentsFormatter(val, field) || percentage(val),
            },
            {
              isTotalsSection: true,
              fields: [
                "creditRiskWeightedAssets",
                "operationalRiskWeightedAssets",
                "totalRiskWeightedAssets",
                "totalCapitalDeductions",
              ],
              values: capitalTreatmentsValues,
            },
          ]}
        />
        <TableWithSections
          header="Capital Treatments - Normalised For Terminal Value"
          data={[
            {
              name: "Operational Risk",
              fields: ["riskBase"],
              values: pluckResultsByType(
                capitalTreatmentsForTerminalValueValues,
                "operationalRisk",
              ),
              formatter: (val: number) => percentage(val),
            },
            {
              fields: [
                "cash",
                "HOME_LOANS.propietaryHomeLoans",
                "HOME_LOANS.brokerHomeLoans",
                "BUSINESS_LOANS",
                "CREDIT_CARDS",
                "WEALTH_MANAGEMENT",
                "INSTITUTIONAL_BANKING",
                "OFFSHORE_BANK",
                "otherFixedAssets",
                "investments",
              ],
              values: capitalTreatmentsForTerminalValueValues,
              formatter: (val: number, field: string) =>
                investmentsFormatter(val, field) || percentage(val),
            },
            {
              isTotalsSection: true,
              fields: [
                "creditRiskWeightedAssets",
                "operationalRiskWeightedAssets",
                "riskWeightedAssetCapital",
                "totalCapitalDeductions",
                "normalisedTotalCapitalLevel",
              ],
              values: capitalTreatmentsForTerminalValueValues,
            },
          ]}
        />
        <TableWithSections
          header="Capital Allocation By Product"
          openOnInit={openIfTable("capitalAllocationByProduct")}
          data={[
            {
              fields: ["actualCet1CapitalRatio"],
              values: capitalAllocationByProductValues,
              formatter: (val: number) => percentage(val, 2),
            },
            {
              name: "Capital Allocation By Asset Type - Closing Balance",
              fields: [
                "cash",
                "HOME_LOANS.propietaryHomeLoans",
                "HOME_LOANS.brokerHomeLoans",
                "BUSINESS_LOANS",
                "CREDIT_CARDS",
                "WEALTH_MANAGEMENT",
                "INSTITUTIONAL_BANKING",
                "OFFSHORE_BANK",
                "otherFixedAssets",
                "investments",
                "totalAllocatedCapitalClosingBalances",
                "totalAllocatedCapitalRiskWeightedAssetsOnly",
              ],
              totalFields: [
                "totalAllocatedCapitalClosingBalances",
                "totalAllocatedCapitalRiskWeightedAssetsOnly",
              ],
              values: pluckResultsByType(
                capitalAllocationByProductValues,
                "capitalAllocationByAssetTypeClosingBalance",
              ),
            },
            {
              name: "Capital Allocation By Asset Type - Average Balance",
              fields: [
                "cash",
                "HOME_LOANS.propietaryHomeLoans",
                "HOME_LOANS.brokerHomeLoans",
                "BUSINESS_LOANS",
                "CREDIT_CARDS",
                "WEALTH_MANAGEMENT",
                "INSTITUTIONAL_BANKING",
                "OFFSHORE_BANK",
                "otherFixedAssets",
                "investments",
                "totalAllocatedCapitalAverageBalances",
                "balanceSheetAverageShareholdersFunds",
                "variance",
              ],
              totalFields: ["totalAllocatedCapitalAverageBalances"],
              values: pluckResultsByType(
                capitalAllocationByProductValues,
                "capitalAllocationByAssetTypeAverageBalance",
              ),
            },
          ]}
        />
        <TableWithSections
          header="CET1 Ratio"
          data={[
            {
              name: "Sources Of Capital",
              fields: [
                "retainedEarnings",
                "shareholdersFunds",
                "totalCapitalBeforeDeductions",
              ],
              totalFields: ["totalCapitalBeforeDeductions"],
              values: pluckResultsByType(cet1Values, "sourcesOfCapital"),
            },
            {
              fields: [
                "lessDeductions",
                "totalEligibleCapital",
                "totalRiskWeightedAssets",
                "capitalRatio",
              ],
              totalFields: [
                "totalEligibleCapital",
                "totalRiskWeightedAssets",
                "capitalRatio",
              ],
              values: cet1Values,
              formatter: (val: number, field: string) =>
                field === "capitalRatio" ? percentage(val) : null,
            },
          ]}
        />
        <TableWithSections
          header="Credit Rating"
          data={[
            {
              fields: [
                "capitalRatio",
                "creditRatingAtCloseOfPeriod",
                "creditRatingForWSFSpreads",
              ],
              values: creditRatingValues,
              formatter: (val: number, field: string) =>
                field === "capitalRatio" ? percentage(val) : String(val),
            },
          ]}
        />
        <TableWithSections
          fakeRoundZero
          header="Mandated Cet1 Capital"
          openOnInit={openIfTable("mandatedCet1Capital")}
          data={[
            {
              name: "Total Eligible Capital",
              fields: [
                "totalEligibleCapital",
                "existingRegulatorMandatedCapitalRaisings",
                "totalEligibleCapitalBeforeForcedRaisings",
              ],
              totalFields: ["totalEligibleCapitalBeforeForcedRaisings"],
              values: pluckResultsByType(
                mandatedCet1CapitalValues,
                "totalEligibleCapital",
              ),
              formatter: (val, field) => {
                if (field === "existingRegulatorMandatedCapitalRaisings") {
                  return formatValue(-1 * val);
                }
                return null;
              },
            },
            {
              fields: [
                "minimumCapitalRatio",
                "totalRiskWeightedAssets",
                "minimumCapitalRequired",
                "shortfall",
              ],
              totalFields: [
                "totalEligibleCapital",
                "totalRiskWeightedAssets",
                "capitalRatio",
              ],
              values: mandatedCet1CapitalValues,
              formatter: (val, field) => {
                if (field === "minimumCapitalRatio") {
                  return percentage(val);
                }
                return null;
              },
            },
            {
              name: "Regulatory Mandated CET1 Capital Raising",
              fields: [
                "targetCapitalRatio",
                "buffer",
                "totalTargetEligibleCapital",
                "currentEligibleCapitalBeforeMandatory",
                "totalRegulatorMandatedCapitalRaising",
                "existingRegulatorMandatedCapitalRaisings",
                "additionalRegulatorMandatedCapitalRaising",
              ],
              totalFields: [
                "totalTargetEligibleCapital",
                "totalRegulatorMandatedCapitalRaising",
                "additionalRegulatorMandatedCapitalRaising",
              ],
              values: pluckResultsByType(
                mandatedCet1CapitalValues,
                "regulatorMandatedCet1CapitalRaising",
              ),
              highlightFields: ["additionalRegulatorMandatedCapitalRaising"],
              formatter: (val, field) => {
                if (
                  [
                    "existingRegulatorMandatedCapitalRaisings",
                    "currentEligibleCapitalBeforeMandatory",
                  ].includes(field)
                ) {
                  return formatValue(-1 * val);
                }
                return null;
              },
            },
          ]}
        />
        <TableWithSections
          fakeRoundZero
          header="Capital Sculpt Calculations"
          openOnInit={openIfTable("capitalSculptCalculations")}
          data={[
            {
              name: "Current Capital Measures",
              fields: ["totalEligibleCapital", "totalRiskWeightedAssets"],
              values: pluckResultsByType(
                capitalSculptValues,
                "currentCapitalMeasures",
              ),
            },
            {
              name: "Capital Required - Optimal",
              fields: [],
              values: [],
            },
            {
              name: "Option 1 - Default Target Ratio",
              fields: [
                "targetCapitalRatio",
                "buffer",
                "totalTargetEligibleCapital",
              ],
              totalFields: ["totalTargetEligibleCapital"],
              values: pluckResultsByType(
                pluckResultsByType(
                  capitalSculptValues,
                  "capitalRequiredOptimal",
                ),
                "option1",
              ),
            },
            {
              name: "Option 2 - Player Selected Rating",
              fields: [
                "selectedRating",
                "targetCapitalRatio",
                "targetCapital",
                "capitalBuffer",
                "totalTargetEligibleCapital",
              ],
              totalFields: ["totalTargetEligibleCapital"],
              values: pluckResultsByType(
                pluckResultsByType(
                  capitalSculptValues,
                  "capitalRequiredOptimal",
                ),
                "option2",
              ),
              formatter: (val, field) => {
                if (field === "selectedRating") {
                  return val ? `${val}` : "-";
                }
                if (field === "targetCapitalRatio") {
                  return percentage(val, 2);
                }
                return null;
              },
            },
            {
              name: "Active Option",
              fields: [
                "totalTargetEligibleCapital",
                "currentEligibleCapital",
                "optimalAdditionalCapital",
              ],
              totalFields: ["optimalAdditionalCapital"],
              values: pluckResultsByType(
                pluckResultsByType(
                  capitalSculptValues,
                  "capitalRequiredOptimal",
                ),
                "activeOption",
              ),
              formatter: (val, field) => {
                if (field === "currentEligibleCapital") {
                  return formatValue(val * -1);
                }
                return null;
              },
            },
            {
              name: "DRP - Optimal (PC and Forecast Only)",
              fields: [
                "existingShareIssuesAndBuybacks",
                "maximumDRPRaise",
                "existingDRPAmount",
                "drpAdjustment",
                "additionalDRPRaise",
                "currentYearDividend",
                "additionalDRPPlanDividendDivReduction",
                "shareholderRaiseCapRemaining",
                "shareholderBuybackCapRemaining",
                "optimalAdditionalCapital",
                "playerType",
                "cet1MethodFlag",
                "liveRoundFlag",
                "targetCET1Ratio",
                "additionalMandatedCapitalRaise",
                "targetCET1RatioInclMandatedBuffer",
                "actualCET1Ratio",
                "variance",
                "mixVarianceDRP",
                "additionalRegulatorMandatedCapitalRaising",
                "varianceFromOptimalRatioIssuesAndBuybacks",
                "varianceCheck",
              ],
              totalFields: ["variance", "varianceCheck"],
              highlightFields: [
                "additionalDRPPlanDividendDivReduction",
                "optimalAdditionalCapital",
              ],
              values: pluckResultsByType(capitalSculptValues, "drp"),
              fieldFormatter: (field: string) => {
                if (field === "additionalDRPPlanDividendDivReduction") {
                  return "Additional DRP Plan Adjustment";
                }
                if (field === "cet1MethodFlag") {
                  return "Manual Method Flag";
                }
                return null;
              },
              formatter: (val, field) => {
                if (field === "playerType") {
                  if (val) return `${val}`;
                  return "-";
                }
                if (["cet1MethodFlag", "liveRoundFlag"].includes(field)) {
                  const boolVal = val as unknown as boolean;
                  if (boolVal === true) return "True";
                  if (boolVal === false) return "False";
                  return "-";
                }
                if (["existingDRPAmount"].includes(field)) {
                  return formatValue(val * -1);
                }
                if (["additionalDRPPlanDividendDivReduction"].includes(field)) {
                  return percentage(val);
                }
                if (
                  [
                    "targetCET1Ratio",
                    "actualCET1Ratio",
                    "variance",
                    "mixVarianceDRP",
                    "additionalRegulatorMandatedCapitalRaising",
                    "varianceFromOptimalRatioIssuesAndBuybacks",
                    "varianceCheck",
                    "additionalMandatedCapitalRaise",
                    "targetCET1RatioInclMandatedBuffer",
                  ].includes(field)
                ) {
                  return formatBps(val, 0);
                }
                return null;
              },
            },
          ]}
        />
      </VerticalGroup>
    </>
  );
};

export default ModelTeamResultsRegulatoryCapital;
