import React from "react";
import classNames from "classnames";

import "./Video.scss";

import placeholder from "../../../assets/common/placeholder.png";
import { ThemeSpacing } from "../../../types/theme";

export interface Props extends React.VideoHTMLAttributes<HTMLVideoElement> {
  className?: string;
  size?: ThemeSpacing;
  src: string | undefined;
  widthSize?: ThemeSpacing;
  heightSize?: ThemeSpacing;
  setAutoPlay?: boolean;
  contain?: boolean;
  cover?: boolean;
  muted?: boolean;
}

function Video({
  size,
  src,
  className,
  widthSize,
  heightSize,
  setAutoPlay,
  contain,
  muted,
  ...rest
}: Props) {
  const sizeClass = size ? `size-${size}` : null;
  const widthClass = widthSize ? `width-${widthSize}` : null;
  const heightClass = heightSize ? `height-${heightSize}` : null;
  return (
    <video
      controls
      autoPlay={setAutoPlay}
      className={classNames(
        "video",
        { "video-contain": contain },
        sizeClass,
        widthClass,
        heightClass,
        className,
      )}
      src={src ?? placeholder}
      muted={muted}
      {...rest}
    />
  );
}

export default Video;
