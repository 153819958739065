import React, { ReactNode, useMemo } from "react";
import Select from "react-select";
import Creatable from "react-select/creatable";
import InlineGroup from "../../inlinegroup/InlineGroup";

import "./Input.scss";
import InlineError from "../../inlineerror/InlineError";
import Theme from "../../../../styles/_theme.module.scss";
import InputContainer from "./InputContainer";
import Icon, { IconType } from "../../icon/Icon";
import { ThemeColours } from "../../../../types/theme";
import Text from "../../text/Text";
import VerticalGroup from "../../verticalgroup/VerticalGroup";
import classNames from "classnames";

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label?: string;
  labelFor?: string;
  selectProps: any;
  error?: string;
  isMulti?: boolean;
  noBorder?: boolean;
  colour?: string;
  block?: boolean;
  small?: boolean;
  tiny?: boolean;
  smallish?: boolean;
  isCreatable?: boolean;
  isVerticalLayout?: boolean;
  midsize?: boolean;
  menuPlacement?: "top" | "bottom";
  alwaysOpen?: boolean;
  helpTitle?: string;
  helpDescription?: string;
}
interface Option {
  label: string | ReactNode;
  id: string | number;
  formattedLabel?: string | ReactNode;
  iconType?: IconType;
  optionColour?: ThemeColours;
  iconColour?: ThemeColours;
}

function Dropdown({
  className,
  selectProps,
  alwaysOpen,
  error,
  isMulti,
  noBorder,
  colour,
  block,
  small,
  tiny,
  smallish,
  midsize,
  isCreatable,
  isVerticalLayout,
  label,
  menuPlacement = "bottom",
  helpTitle,
  helpDescription,

  ...rest
}: Props) {
  const props = useMemo(
    () => ({
      menuIsOpen: alwaysOpen,
      classNamePrefix: "dropdown",
      formatOptionLabel: (
        option: Option,
        { context }: { context: "menu" | "value" },
      ) => {
        if (context === "menu") {
          if (option.formattedLabel) {
            return option.formattedLabel;
          }
          return (
            <InlineGroup verticalCenter>
              {option.iconType && (
                <Icon
                  size={5}
                  type={option.iconType}
                  colour={option.iconColour}
                />
              )}
              <Text colour={option.optionColour}>{option.label}</Text>
            </InlineGroup>
          );
        } else {
          return option.label;
        }
      },

      styles: {
        control: (provided: any, state: any) => ({
          ...provided,
          paddingTop: isMulti ? "0.5rem" : 0,
          paddingBottom: isMulti ? "0.5rem" : 0,
          minHeight: "2rem",
          boxShadow: "none",
          border: noBorder ? "none" : provided.border,
          backgroundColor: noBorder ? "inherit" : provided.backgroundColor,
          borderColor: error
            ? Theme.danger
            : state.isFocused
              ? Theme.secondaryBlue1
              : Theme.secondaryDark3,
          "&:hover": {
            borderColor: state.isFocused
              ? Theme.secondaryBlue1
              : Theme.secondaryDark3,
          },
          width: block ? "100%" : provided.width,
        }),
        option: (provided: any, state: any) => ({
          ...provided,
          backgroundColor: state.isFocused ? "#51b6f5" : "white",
          color: state.isFocused ? "white" : "black",
          fontSize: "0.75rem",
          fontFamily: "Montserrat",
        }),
        valueContainer: (provided: any) => ({
          ...provided,
          fontSize: "0.75rem",
          fontFamily: "Montserrat",
          padding: "0.2rem 0.75rem",
        }),
        loadingIndicator: (provided: any) => ({
          ...provided,
          color: Theme.secondaryBlue1,
        }),
        menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
      },
      className: "dropdown",
      theme: (theme: any) => ({
        ...theme,
        spacing: {
          ...theme.spacing,
          baseUnit: 2,
        },
        colors: {
          ...theme.colors,
          neutral20: colour || theme.colors.neutral20,
          neutral50: colour || theme.colors.neutral50,
          neutral60: colour || theme.colors.neutral60,
          neutral80: colour || theme.colors.neutral80,
        },
      }),
      isMulti,
      menuPortalTarget: document.body,
      menuPlacement: menuPlacement,
      ...selectProps,
    }),
    [
      block,
      colour,
      error,
      isMulti,
      noBorder,
      selectProps,
      menuPlacement,
      alwaysOpen,
    ],
  );

  return (
    <InputContainer
      block={block}
      containerBlock
      small={small}
      tiny={tiny}
      midsize={midsize}
      smallish={smallish}
      isVerticalLayout={isVerticalLayout}
      className={className}
      label={label}
      helpTitle={helpTitle}
      helpDescription={helpDescription}
      {...rest}
    >
      <VerticalGroup wide={block}>
        <InlineGroup verticalCenter block={block}>
          {/* {!!label && <Label className='label' htmlFor={labelFor} >{label}</Label>} */}
          <div
            data-test={label}
            className={classNames("dropdown-container", {
              "width-100-percent": block,
            })}
          >
            {isCreatable ? <Creatable {...props} /> : <Select {...props} />}
          </div>
        </InlineGroup>
        {!!error && (
          <div className="input-error">
            <InlineError active={!!error} message={error} />
          </div>
        )}
      </VerticalGroup>
    </InputContainer>
  );
}

export default Dropdown;
