import { HOUR_MILLISECONDS } from "../../../../../../lib/date";

export const DEFAULT_MINS_ALLOCATED = 45;

export const getDefaultDate = (): Date => {
  const now = new Date();
  now.setSeconds(0);
  now.setMilliseconds(0);
  const nowMins = now.getMinutes();
  if (nowMins === 0 || nowMins === 30) {
    return now;
  }
  if (nowMins < 30) {
    now.setMinutes(30);
    return now;
  }

  // add an hour
  now.setMinutes(0);
  now.setTime(now.getTime() + HOUR_MILLISECONDS);
  return now;
};

export const setStateFromSchedule = (
  schedule: API.EventSchedule,
  simulation: API.SimulationResponse,
) => ({
  facilitatedRoundsEnabled: schedule.roundType === "facilitated",
  scheduledRoundsEnabled: schedule.roundType === "scheduled",
  rounds: simulation.generalSetup.rounds,
  daysBetweenRounds: 7,
  schedules: schedule.schedules.map((roundSchedule: API.RoundSchedule) => ({
    timeAllocated: roundSchedule.timeAllocated || DEFAULT_MINS_ALLOCATED,
    startTime: roundSchedule.startTime
      ? new Date(roundSchedule.startTime)
      : getDefaultDate(),
    finishTime: roundSchedule.finishTime
      ? new Date(roundSchedule.finishTime)
      : getDefaultDate(),
  })),
});

export const formatDateForAPI = (value: Date | null) => {
  value = value ?? getDefaultDate();
  value.setSeconds(0);
  value.setMilliseconds(0);
  return value.toISOString();
};
