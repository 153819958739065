import React, { useCallback } from "react";
import { Redirect } from "react-router-dom";
import Button from "../../../atoms/button/Button";
import Input from "../../../atoms/form/input/Input";
import useForm from "../../../../hooks/useForm";
import API from "../../../../services/api";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import DisplayField from "../../../atoms/form/display-field/DisplayField";
import Form, { FormRequiredText } from "../../../atoms/form/Form";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Banner from "../../../atoms/banner/Banner";
import UploadFile from "../../../organisms/upload-file/upload-file";
import Image from "../../../atoms/image/Image";
import useObjectURL from "use-object-url";
import UserDropdown from "../../../organisms/user-dropdown/UserDropdown";
interface Props {
  client?: API.ClientResponse;
  onSave: (data: API.ClientRequest, logo?: File) => Promise<API.ClientResponse>;
  onCancel: () => void;
}

function ClientForm({ client, onSave, onCancel }: Props) {
  const [
    {
      formData: { name, state, logo, userIds },
      inProgress,
      fieldErrors,
      error,
      data,
      completed,
    },
    { setField, setFieldRaw, setMultiSelectField, handleSubmit },
  ] = useForm(
    client
      ? {
          ...client,
        }
      : {
          name: "",
          state: "active",
          logo: null,
          userIds: [],
        },
  );

  const callback = useCallback(() => {
    const payload = {
      name: name.trim(),
      state: state,
      userIds,
    };
    return onSave(payload, logo);
  }, [onSave, name, state, logo, userIds]);

  const validate = useCallback(() => {
    const errors = [];
    if (!name) {
      errors.push({ field: "name", message: "Enter a client name" });
    }

    if (!logo && !client?.logoUrl) {
      errors.push({ field: "logo", message: "Please upload a logo" });
    }

    return errors;
  }, [name, logo, client]);

  const handleLogo = useCallback(
    async (file) => {
      setFieldRaw("logo", file);
    },
    [setFieldRaw],
  );

  const objectURL = useObjectURL(logo);

  return (
    <VerticalGroup full spread>
      <VerticalGroup wide>
        <Banner type="error" active={!!error} message={error?.message} />
        {!!completed && !error && (
          <Redirect to={`/administration/clients/${data.id}/view`} />
        )}
        <Form id="client-add-form" onSubmit={handleSubmit(callback, validate)}>
          <FormRequiredText />
          <Input
            autoFocus
            data-test="name"
            type="text"
            label="Client Name *"
            value={name}
            onChange={setField("name")}
            error={fieldErrors.name}
          />
          <DisplayField
            stretch
            label="Logo"
            value={
              <Image
                className="br-2"
                shadow
                heightSize={20}
                src={objectURL || client?.logoUrl}
                alt="Client logo"
              />
            }
          />
          <UploadFile
            buttonText="Upload logo"
            label="Upload new logo"
            handleFileUpload={handleLogo}
            title="Select client logo"
            description="Should be at least 400x400 px"
            allowedType="image"
            errorMsg={fieldErrors.logo}
          />
          <UserDropdown
            label="Select Users *"
            isMulti
            userIds={userIds}
            onChange={setMultiSelectField("userIds")}
            error={fieldErrors.userIds}
            types={["admin", "standard"]}
          />
          {!!client && <DisplayField label="Client ID" value={client.id} />}
        </Form>
      </VerticalGroup>
      <InlineGroup block spread>
        <Button data-test="cancel" light wide onClick={onCancel}>
          Cancel
        </Button>
        <Button
          data-test="save"
          type="submit"
          form="client-add-form"
          wide
          inProgress={inProgress}
        >
          Save
        </Button>
      </InlineGroup>
    </VerticalGroup>
  );
}

export default ClientForm;
