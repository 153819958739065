export const getAnswersForRound = (
  answersForAParticipant: API.AssessmentAnswerResponse["answers"] = [],
  round: number,
): API.AssessmentAnswerResponse["answers"] => {
  return answersForAParticipant.filter((a) => a.roundId === round);
};

const allAnswersForRound = (
  answers: API.AssessmentAnswerResponse[],
  round: number,
): API.AssessmentAnswerResponse[] => {
  return answers.map((answerItem) => ({
    participantId: answerItem.participantId,
    answers: answerItem.answers.filter((a) => a.roundId === round),
  }));
};

export const amountOfParticipantStarted = (
  answers: API.AssessmentAnswerResponse[],
  round: number,
) => {
  const answersForRound = allAnswersForRound(answers, round);
  return answersForRound.filter((answerItem) => answerItem.answers.length > 0)
    .length;
};

export const getQuestionsForRound = (
  groups: API.AssessmentResponse["groups"],
  round: number,
) => {
  const group = groups.find((group) => group.round === round);

  if (group) {
    return group.questions || [];
  }

  return [];
};

export const completedParticipantsForRound = (
  assessment: API.AssessmentResponse,
  answers: API.AssessmentAnswerResponse[],
  round: number,
): API.AssessmentAnswerResponse[] => {
  const answersForRound = allAnswersForRound(answers, round);
  const questionsForRound = getQuestionsForRound(assessment.groups, round);

  return answersForRound.filter(
    (answerItem) => answerItem.answers.length === questionsForRound.length,
  );
};

export const completedParticipantsForRoundCount = (
  assessment: API.AssessmentResponse,
  answers: API.AssessmentAnswerResponse[],
  round: number,
): number => {
  return completedParticipantsForRound(assessment, answers, round).length;
};

export const allScoresForRound = (
  assessment: API.AssessmentResponse,
  answers: API.AssessmentAnswerResponse[],
  round: number,
): number[] => {
  const questions = getQuestionsForRound(assessment.groups, round);
  const totalPoints = questions
    .map((q) => q.marks || 0)
    .reduce((a, b) => a + b, 0);

  return completedParticipantsForRound(assessment, answers, round).map(
    (answerItem) => {
      const totalMarks = answerItem.answers
        .map((a) => a.mark || 0)
        .reduce((a, b) => a + b, 0);
      return Math.round((totalMarks / totalPoints) * 100);
    },
  );
};

const scoreStats =
  (statsFn: (nums: number[]) => number) =>
  (
    assessment: API.AssessmentResponse,
    answers: API.AssessmentAnswerResponse[],
    round: number,
  ): number => {
    const scores = allScoresForRound(assessment, answers, round);

    if (scores.length === 0) return 0;

    return statsFn(scores);
  };

const sum = (nums: number[]) => nums.reduce((a, b) => a + b, 0);
const avg = (nums: number[]) => sum(nums) / nums.length;
const median = (nums: number[]) => {
  const sorted = Array.from(nums).sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2;
  }

  return sorted[middle];
};

export const calculateAvg = scoreStats(avg);

export const calculateMedian = scoreStats(median);

export const calculateMax = scoreStats((nums: number[]) => Math.max(...nums));

export const calculateMin = scoreStats((nums: number[]) => Math.min(...nums));

export const answersRemainingToBeMarked = (
  assessment: API.AssessmentResponse,
  answers: API.AssessmentAnswerResponse[],
  round: number,
): API.AssessmentAnswerResponse[] => {
  const completed = completedParticipantsForRound(assessment, answers, round);

  return completed.filter((answerItem) => {
    const total = answerItem.answers.length;
    const marks = answerItem.answers.filter((a) => a.mark !== null).length;
    return total !== marks;
  });
};
