import React, { useCallback, useContext, useEffect, useState } from "react";

import API from "../../../../../services/api";
import ClientDropdown from "../../../../organisms/client-dropdown/ClientDropdown";
import Banner from "../../../../atoms/banner/Banner";
import DisplayField from "../../../../atoms/form/display-field/DisplayField";
import Input from "../../../../atoms/form/input/Input";
import Form, { FormRequiredText } from "../../../../atoms/form/Form";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import { SimulationContext } from "../../context";
import useForm from "../../../../../hooks/useForm";
import RoundDropdown from "../../../../organisms/round-dropdown/RoundDropdown";
import Toggle from "../../../../atoms/toggle/Toggle";
import ConfirmModal from "../../../../organisms/confirm-modal/ConfirmModal";
import SimulationTypeDropdown from "../../../../organisms/simulation-type-dropdown/SimulationTypeDropdown";

const General: React.FC = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationComplete, setConfirmationComplete] = useState<
    null | (() => Promise<void | false>)
  >(null);
  const {
    config,
    updateConfig,
    assignOnSubmitFunction,
    updateFormStatus,
    jumpToStepByKey,
  } = useContext(SimulationContext);
  const [
    {
      formData: { name, clientIds, rounds, shouldRotateCeo },
      fieldErrors,
      error,
      inProgress,
      formUpdated,
    },
    {
      setField,
      setFieldSimple,
      setDropdownField,
      setMultiSelectField,
      handleSubmit,
    },
  ] = useForm(
    config?.generalSetup
      ? {
          ...config.generalSetup,
        }
      : {
          name: "",
          clientIds: [],
          rounds: 3,

          simulationType: "online",
          shouldRotateCeo: true,
        },
  );
  const [simulationType, setSimulationType] = useState<
    "online" | "tablet" | "hybrid"
  >(config?.generalSetup.simulationType || "online");

  const onSubmit = useCallback(async () => {
    const payload: API.SimulationCreateRequest = {
      name: name.trim(),
      clientIds,
      rounds,
      simulationType,

      shouldRotateCeo,
    };
    const response = config?.id
      ? await API.editSimulation(config.id, { generalSetup: payload })
      : await API.createSimulation(payload);

    updateConfig(response);
    return response;
  }, [
    name,
    clientIds,
    rounds,
    simulationType,
    shouldRotateCeo,
    config?.id,
    updateConfig,
  ]);

  const validate = useCallback(() => {
    const errors = [];
    if (!name) {
      errors.push({ field: "name", message: "Enter a name" });
    }

    if (!clientIds.length) {
      errors.push({
        field: "clientIds",
        message: "Please select at least one client",
      });
    }

    return errors;
  }, [name, clientIds]);

  const handleWithWarning = useCallback(
    (e?: React.FormEvent<HTMLFormElement>): Promise<false | void> => {
      if (
        config &&
        config.generalSetup.rounds !== rounds &&
        config.generalSetup.rounds > rounds
      ) {
        setShowConfirmation(true);
        setConfirmationComplete(
          () => () => handleSubmit(onSubmit, validate)(e),
        );
        return Promise.resolve(false);
      }
      return handleSubmit(onSubmit, validate)(e);
    },
    [config, handleSubmit, onSubmit, rounds, validate],
  );

  const handleConfirm = useCallback(() => {
    confirmationComplete?.();
    setShowConfirmation(false);
    jumpToStepByKey("playersSetup");
  }, [confirmationComplete, jumpToStepByKey]);

  const handleCancel = useCallback(() => {
    setShowConfirmation(false);
    setConfirmationComplete(null);
    if (config && config.generalSetup.rounds) {
      setFieldSimple("rounds")(config?.generalSetup.rounds);
    }
  }, [config, setFieldSimple]);

  useEffect(() => {
    assignOnSubmitFunction(handleWithWarning);
  }, [assignOnSubmitFunction, handleWithWarning]);

  useEffect(() => {
    updateFormStatus({
      inProgress: inProgress,
      formUpdated: formUpdated,
    });
  }, [updateFormStatus, inProgress, formUpdated]);

  return (
    <div className="step-container">
      {showConfirmation && (
        <ConfirmModal
          isOpen
          onClose={() => setShowConfirmation(false)}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          title="Confirm change of rounds"
          description={`Changing rounds will invalidate any config that has been setup for rounds greater than ${rounds}`}
          confirmTitle={"Ok"}
        />
      )}
      <VerticalGroup spread full>
        <VerticalGroup wide>
          <Banner type="error" active={!!error} message={error?.message} />
          <Form id="simulation-add-form">
            <h3>General Settings</h3>
            <FormRequiredText />
            <Input
              autoFocus
              data-test="name"
              type="text"
              label="Simulation name *"
              value={name}
              onChange={setField("name")}
              error={fieldErrors.name}
            />
            <ClientDropdown
              label="Select Clients *"
              isMulti
              clientIds={clientIds}
              onChange={setMultiSelectField("clientIds")}
              error={fieldErrors.clientIds}
            />
            <RoundDropdown
              label="Number of Rounds"
              value={rounds}
              onChange={setDropdownField("rounds")}
            />

            <SimulationTypeDropdown
              simulationType={simulationType}
              setSimulationType={setSimulationType}
            />

            <Toggle
              label="Rotate CEO"
              helpTitle="Rotate CEO"
              helpDescription="Toggles whether the simulation is setup to rotate CEO's between rounds"
              checked={shouldRotateCeo}
              onUpdate={setFieldSimple("shouldRotateCeo")}
              block
            />
            {!!config && (
              <DisplayField label="Simulation ID" value={config.id} />
            )}
          </Form>
        </VerticalGroup>
      </VerticalGroup>
    </div>
  );
};

export { default as GeneralDisplay } from "./Display";
export default General;
