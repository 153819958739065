// step 9
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import { SummaryMetricItem, summaryMetricItems } from "./data";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import InformationPopup from "../../../../organisms/information-popup/InformationPopup";
import Text from "../../../../atoms/text/Text";
import Dropdown from "../../../../atoms/form/input/Dropdown";
import {
  DraggableItemsPool,
  DragAndDropItem,
  Dropzone,
  DRAGGABLE_ITEMS_POOL_ID,
  DROPPABLE_AREA_PREFIX,
} from "../../../../organisms/draganddrop";
import SummaryStandard from "./SummarySelectedView";
import DraggableView from "../../../../organisms/draganddrop/DraggableView";
import Icon from "../../../../atoms/icon/Icon";

interface Props {
  defaultSelected?: API.SimulationUpdateRequest["summaryMetrics"];
  onSummaryChange?: (
    items: API.SimulationUpdateRequest["summaryMetrics"],
    totalPercentage: number,
  ) => void;
  numberOfMetrics: number;
  onNumberOfMetricsChange: (value: number) => void;
  onMetricFieldUpdate: (
    metricConfigId: string,
  ) => (field: string) => (value: string) => void;
  summaryMetricConfig: API.SimulationUpdateRequest["summaryMetricConfig"];
}

const MIN_NUM_METRICS = 2;
const MAX_NUM_METRICS = 11;

const NUMBER_OPTIONS = Array(MAX_NUM_METRICS - MIN_NUM_METRICS + 1)
  .fill(0)
  .map((_, idx) => ({
    label: `${MIN_NUM_METRICS + idx}${MIN_NUM_METRICS + idx === 6 ? " (Recommended)" : ""}`,
    value: MIN_NUM_METRICS + idx,
  }));

const SummaryForm: React.FC<Props> = ({
  defaultSelected,
  onSummaryChange,
  numberOfMetrics,
  onNumberOfMetricsChange,
  onMetricFieldUpdate,
  summaryMetricConfig,
}) => {
  const [selected, setSelected] = useState<Array<SummaryMetricItem | null>>(
    () => {
      const initItems = new Array(numberOfMetrics).fill(null);

      if (defaultSelected) {
        defaultSelected.forEach((item, idx) => {
          initItems[idx] = {
            ...summaryMetricItems.find((i) => i.type === item.type),
            percentage: item.percentage,
          };
        });
      }

      return initItems;
    },
  );
  const [items] = useState<SummaryMetricItem[]>(summaryMetricItems);

  const totalPercentage = useMemo(() => {
    return selected.reduce((total, s) => {
      return total + (s?.percentage ?? 0);
    }, 0);
  }, [selected]);

  const onSelectedItemChange = useCallback(
    (selected: Array<SummaryMetricItem | null>) => {
      const finalSelected: SummaryMetricItem[] = selected.filter(
        (item) => item !== null,
      ) as SummaryMetricItem[];
      onSummaryChange &&
        onSummaryChange(
          finalSelected.map(({ type, percentage }) => ({ type, percentage })),
          totalPercentage,
        );
    },
    [onSummaryChange, totalPercentage],
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (result.destination) {
        const { draggableId: id } = result;
        const { droppableId: to } = result.destination;
        const { droppableId: from } = result.source;
        const item = items.find((item) => item.type === id);

        if (!item) return;

        if (
          from === DRAGGABLE_ITEMS_POOL_ID &&
          to.startsWith(DROPPABLE_AREA_PREFIX)
        ) {
          const toIndex = Number(to.split("-").pop());
          setSelected((selectedItems) => {
            const updatedSelectedItems = [...selectedItems];
            updatedSelectedItems[toIndex] = item;
            onSelectedItemChange(updatedSelectedItems);
            return updatedSelectedItems;
          });
        } else if (
          from.startsWith(DROPPABLE_AREA_PREFIX) &&
          to.startsWith(DROPPABLE_AREA_PREFIX)
        ) {
          const fromIndex = Number(from.split("-").pop());
          const toIndex = Number(to.split("-").pop());
          setSelected((selectedItems) => {
            const updatedSelectedItems = [...selectedItems];
            const fromItem = updatedSelectedItems[fromIndex];
            updatedSelectedItems[fromIndex] = updatedSelectedItems[toIndex];
            updatedSelectedItems[toIndex] = fromItem;
            onSelectedItemChange(updatedSelectedItems);
            return updatedSelectedItems;
          });
        } else if (
          from.startsWith(DROPPABLE_AREA_PREFIX) &&
          to === DRAGGABLE_ITEMS_POOL_ID
        ) {
          const fromIndex = Number(from.split("-").pop());
          setSelected((selectedItems) => {
            const updatedSelectedItems = [...selectedItems];

            updatedSelectedItems[fromIndex] = null;
            onSelectedItemChange(updatedSelectedItems);
            return updatedSelectedItems;
          });
        }
      }
    },
    [items, onSelectedItemChange],
  );

  const onPercentageChange = useCallback(
    (item: SummaryMetricItem, percent: number) => {
      setSelected((selected) => {
        const updatedSelectedItems = selected.map((s) => {
          if (s?.type === item.type) {
            return {
              ...s,
              percentage: percent,
            };
          }
          return s;
        });
        onSelectedItemChange(updatedSelectedItems);
        return updatedSelectedItems;
      });
    },
    [onSelectedItemChange],
  );

  const draggableItems: DragAndDropItem[] = useMemo(() => {
    return items
      .filter((item) => {
        return !selected.find((selectedItem) => {
          return selectedItem && item.type === selectedItem.type;
        });
      })
      .map((item) => {
        const metricConfig = summaryMetricConfig?.find(
          (metricConfig) => metricConfig.type === item.type,
        );
        const props = {
          ...item,
          ...(metricConfig ? { label: metricConfig.name } : {}),
        };
        return {
          id: item.type,
          view: <DraggableView key={item.type} {...props} />,
        };
      });
  }, [items, selected, summaryMetricConfig]);

  const onNumOfMetricsChange = useCallback(
    (option) => {
      onNumberOfMetricsChange(option.value);
    },
    [onNumberOfMetricsChange],
  );

  const selectedNumofMetricsOption = useMemo(() => {
    return NUMBER_OPTIONS.find((op) => op.value === numberOfMetrics);
  }, [numberOfMetrics]);

  useEffect(() => {
    setSelected((selected) => {
      if (selected.length < numberOfMetrics) {
        const remaining = numberOfMetrics - selected.length;
        return selected.concat(new Array(remaining).fill(null));
      } else {
        return selected.slice(0, numberOfMetrics);
      }
    });
  }, [numberOfMetrics, setSelected]);

  const selectedItems: Array<DragAndDropItem | undefined> = useMemo(() => {
    return selected.map((item) => {
      if (!item) return undefined;

      const metricConfig = summaryMetricConfig?.find(
        (metricConfig) => metricConfig.type === item.type,
      );

      const props = {
        ...item,
        ...(metricConfig ? { label: metricConfig.name } : {}),
      };

      return {
        id: item.type,
        view: (
          <SummaryStandard
            key={item.type}
            onPercentageChange={onPercentageChange}
            item={{ ...props }}
            onUpdateField={onMetricFieldUpdate(metricConfig!.id)}
          />
        ),
      };
    });
  }, [onMetricFieldUpdate, onPercentageChange, selected, summaryMetricConfig]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <VerticalGroup full wide spaceBetweenElements={4}>
        <h3>Summary Screen Settings</h3>
        <InlineGroup verticalCenter spaceBetweenElements={2}>
          <Text size="sm" bold>
            Select summary metrics to be shown
          </Text>
          <InformationPopup
            title="Summary Screen Metrics"
            body="Select which metrics will appear during the simulation on each participant’s main summary screen. Set which metrics will also be used to determine the performance of the teams (ie determine the winning/losing teams). You must assign weightings adding to 100% to at least one metric to determine performance (e.g 40% customer satisfaction + 60% shareholder returns)."
          />
        </InlineGroup>
        <InlineGroup verticalCenter>
          <Text size="sm">Winning team weighting allocated:</Text>
          <Text
            className="ml-2"
            bold
            size="sm"
            colour={totalPercentage !== 100 ? "danger" : undefined}
          >
            {totalPercentage}%
          </Text>
          {100 - totalPercentage === 0 && (
            <Icon type="tick" size={4} colour="green" />
          )}
          <Text bold>{100 - totalPercentage === 0 ? "" : ","}</Text>
          <Text className="ml-2" bold>
            {100 - totalPercentage === 0
              ? ""
              : `${Math.abs(100 - totalPercentage)}% ${totalPercentage > 100 ? "over" : "remaining"}`}
          </Text>
        </InlineGroup>
        <InlineGroup verticalCenter spaceBetweenElements={4}>
          <Text size="sm" bold>
            Select number of summary screen metrics{" "}
          </Text>
          <Dropdown
            data-test={"summary-metric-dropdown-num-of-metrics"}
            selectProps={{
              value: selectedNumofMetricsOption,
              options: NUMBER_OPTIONS,
              onChange: onNumOfMetricsChange,
              classNamePrefix: "summary-metric-dropdown-num-of-metrics",
            }}
          />
        </InlineGroup>
        <Dropzone items={selectedItems} size={numberOfMetrics} />

        <h3>Metrics to Choose From</h3>
        <DraggableItemsPool items={draggableItems} />
      </VerticalGroup>
    </DragDropContext>
  );
};

export default SummaryForm;
