import React, { useCallback, useEffect, useState } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import Table, { Th } from "../../../../atoms/table/Table";
import { QuestionRow } from "./QuestionRow";

interface Props {
  assessmentId: string;
  groupId: string;
  questions: API.AssessmentQuestionResponse[];
  onChange: (questions: API.AssessmentQuestionResponse[]) => void;
}

export const QuestionTable: React.FC<Props> = ({
  questions,
  assessmentId,
  groupId,
  onChange,
}) => {
  const [questionsToShow, updateQuestions] = useState(questions);

  const onDeleteQuestion = useCallback(
    (question: API.AssessmentQuestionResponse) => {
      updateQuestions((questions) => {
        return questions.filter((q) => q.id !== question.id);
      });
    },
    [],
  );

  const onDragEnd = useCallback((result: DropResult) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;

    updateQuestions((list) => {
      const result = Array.from(list);
      const [removed] = result.splice(sourceIndex, 1);
      result.splice(destIndex, 0, removed);
      return result;
    });
  }, []);

  useEffect(() => {
    onChange(questionsToShow);
  }, [onChange, questionsToShow]);

  return (
    <Table>
      <thead>
        <Th>Question/Items</Th>
        <Th center width={64}>
          Question Type
        </Th>
        <Th center width={32}>
          Required
        </Th>
        <Th center width={32}>
          Points
        </Th>
        <Th center width={16}>
          Actions
        </Th>
      </thead>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={`${groupId}`}>
          {(provided) => (
            <tbody {...provided.droppableProps} ref={provided.innerRef}>
              {questionsToShow.map((question, index) => {
                return (
                  <QuestionRow
                    key={question.id}
                    index={index}
                    assessmentId={assessmentId}
                    groupId={groupId}
                    question={question}
                    onDelete={onDeleteQuestion}
                  />
                );
              })}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>
    </Table>
  );
};
