import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import IconButton from "../../../molecules/iconbutton/IconButton";
import EmptyList from "../../../organisms/empty-list/EmptyList";
import { QuestionTable } from "../Question/QuestionTable/Table";
import { DeleteGroup } from "./DeleteGroup";
import API from "../../../../services/api";

interface Props {
  group: API.AssessmentGroupResponse;
  assessmentId: string;
  onQuestionsChange: (questions: API.AssessmentQuestionResponse[]) => void;
}

interface AssessmentGroupState {
  deleteModalOpen: boolean;
}

function AssessmentGroup({ group, assessmentId, onQuestionsChange }: Props) {
  const [{ deleteModalOpen }, setAssessmentState] =
    useState<AssessmentGroupState>({
      deleteModalOpen: false,
    });
  const history = useHistory();

  const onAssessmentGroupDeleteComplete = useCallback(() => {
    setAssessmentState((prevState) => ({
      ...prevState,
      deleteModalOpen: false,
    }));
    history.push("/assessments");
  }, [history]);

  const handleQuestionsChange = useCallback(
    async (questions: API.AssessmentQuestionResponse[]) => {
      await API.editAssessmentGroup(assessmentId, group.id, {
        name: group.name,
        round: group.round,
        questions: questions.map((q) => q.id),
      });

      onQuestionsChange(questions);
    },
    [assessmentId, group.id, group.name, group.round, onQuestionsChange],
  );

  return (
    <>
      {deleteModalOpen && !!group && !!assessmentId && (
        <DeleteGroup
          assessmentId={assessmentId}
          group={group}
          isOpen={deleteModalOpen}
          onClose={() =>
            setAssessmentState((prevState) => ({
              ...prevState,
              deleteModalOpen: false,
            }))
          }
          onComplete={onAssessmentGroupDeleteComplete}
        />
      )}
      <InlineGroup className={"mt-2"}>
        <InlineGroup
          className={"h-16 mt-2"}
          spaceBetweenElements={2}
          block
          right
        >
          <IconButton
            link
            icon="assessments"
            className="main-edit-action-button"
            linkTo={`/assessments/${assessmentId}/groups/${group.id}/add-question`}
            text="Add Question"
          />
          <IconButton
            link
            icon="edit"
            className="main-edit-action-button"
            linkTo={`/assessments/${assessmentId}/edit/${group.id}/edit`}
            text="Edit Group"
          />
          <IconButton
            danger
            icon="trash"
            text="Delete"
            onClick={() =>
              setAssessmentState((prevState) => ({
                ...prevState,
                deleteModalOpen: true,
              }))
            }
          />
        </InlineGroup>
      </InlineGroup>
      {group.questions && group.questions.length === 0 && (
        <EmptyList message="No questions in this group" icon="risk" half />
      )}
      {group.questions && group.questions.length > 0 && (
        <QuestionTable
          key={group.id}
          onChange={handleQuestionsChange}
          questions={group.questions}
          assessmentId={assessmentId}
          groupId={group.id}
        />
      )}
    </>
  );
}

export default AssessmentGroup;
