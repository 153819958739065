import React, { useCallback, useEffect, useState } from "react";
import API from "../../../../services/api";
import useAPIRequest from "../../../../hooks/useAPIRequest";
import AuditLogsTable from "./AuditLogsTable";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import Banner from "../../../atoms/banner/Banner";
import Page from "../../../atoms/page/Page";
import StickyBar from "../../../organisms/sticky-bar/StickyBar";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Icon from "../../../atoms/icon/Icon";
import UserDropdown from "../../../organisms/user-dropdown/UserDropdown";
import Theme from "../../../../styles/_theme.module.scss";

export const STICKY_BAR_HEIGHT = 0;

function AuditLogs() {
  const [userId, setUserId] = useState<string>();

  const callback = useCallback(() => {
    return API.getAuditLogs(userId);
  }, [userId]);

  const [{ inProgress, data, completed, error }, doAPIRequest] = useAPIRequest(
    callback,
    { data: [] },
  );

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  return (
    <Page>
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {inProgress && <LoadingSpinner />}
      <StickyBar height={STICKY_BAR_HEIGHT} spaceBetweenElements={2}>
        <InlineGroup verticalCenter spaceBetweenElements={2}>
          <Icon height={16} width={16} type="filter" colour="blue" />
          <UserDropdown
            noBorder
            placeholder="All Users"
            colour={Theme.blue}
            onChange={(userId) => setUserId(userId.value)}
          />
        </InlineGroup>
      </StickyBar>
      {completed && data && <AuditLogsTable auditlogs={data} />}
    </Page>
  );
}

export default AuditLogs;
