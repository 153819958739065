import React from "react";
import BpsDropdown from "../../../../organisms/bps-dropdown/BpsDropdown";
import IntegerDropdown from "../../../../organisms/integer-dropdown/IntegerDropdown";
import MultiplierDropdown from "../../../../organisms/multiplier-dropdown/MultiplierDropdown";
import PercentageDropdown from "../../../../organisms/percentage-dropdown/PercentageDropdown";
import AmountDropdown from "./AmountDropdown";

type InputType = "bps" | "percentage" | "integer" | "multiplier" | "amount";

interface Common {
  label: string;
  type: InputType;
  min?: number;
  max?: number;
  step?: number;
  multiplier?: string;
}

export interface DataProps<DataType, K extends keyof DataType> extends Common {
  field: keyof DataType[K];
}

interface InputProps {
  label: string;
  type: InputType;
  onChange: (value: number) => void;
  value: number;
  min?: number;
  max?: number;
  step?: number;
  multiplier?: string;
}

const GloInput = ({
  label,
  type,
  value,
  onChange,
  min,
  max,
  step,
  multiplier,
}: InputProps) => {
  if (type === "percentage")
    return (
      <PercentageDropdown
        label={label}
        value={value}
        onChange={onChange}
        min={min ?? 0}
        max={max ?? 100}
        step={step ?? 1}
        midsize
      />
    );
  if (type === "bps")
    return (
      <BpsDropdown
        label={label}
        value={value}
        onChange={onChange}
        min={min ?? 0}
        max={max ?? 100}
        step={step ?? 1}
        midsize
      />
    );
  if (type === "integer")
    return (
      <IntegerDropdown
        label={label}
        value={value}
        onChange={(selected: { value: number }) => onChange(selected.value)}
        min={min ?? 0}
        max={max ?? 1000}
        step={step ?? 10}
        midsize
        isCreatable
      />
    );

  if (type === "multiplier")
    return (
      <MultiplierDropdown
        label={label}
        value={value}
        onChange={onChange}
        min={min ?? 0}
        max={max ?? 100}
        step={step ?? 1}
        midsize
        multiplier={multiplier}
      />
    );

  if (type === "amount")
    return (
      <AmountDropdown
        label={label}
        value={value}
        onChange={(selected: { value: number }) => onChange(selected.value)}
        min={min}
        max={max}
        step={step}
      />
    );

  return null;
};

export default GloInput;
