import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import Icon from "../../../atoms/icon/Icon";
import Text from "../../../atoms/text/Text";
import Table from "../../../atoms/table/Table";

import EmptyList from "../../../organisms/empty-list/EmptyList";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import { formatPersonName } from "../../../../lib/formatters";
import { formatUserType } from "./lib/formatters";
import DeleteUser from "./UserDelete";
import Clickable from "../../../atoms/clickable/Clickable";
import { STICKY_BAR_HEIGHT } from "./UserList";
import ClientAvatarList from "../../../molecules/client-avatar-list/ClientAvatarList";
import Tooltip from "../../../atoms/tooltip/Tooltip";

interface Props {
  users: API.UserResponse[];
  refreshUsers: () => void;
}

interface UserState {
  deleteModalOpen: boolean;
  userSelected: API.UserResponse | null;
}

const TOOLTIP_ID = "users-table-tooltip";

const getUserStatus = (user: API.UserResponse) => {
  switch (user.state) {
    case "active":
      return user.verified ? "Active" : "Invite Sent";
    case "disabled":
      return "Deactivated";
  }
};

function UserListTable({ users, refreshUsers }: Props) {
  const history = useHistory();
  const [{ deleteModalOpen, userSelected }, setUserState] = useState<UserState>(
    { deleteModalOpen: false, userSelected: null },
  );

  const onUserDeleteComplete = useCallback(() => {
    setUserState((prevState) => ({
      ...prevState,
      deleteModalOpen: false,
      userSelected: null,
    }));
    refreshUsers();
  }, [refreshUsers]);

  const handleEventClick = useCallback(
    (user: API.UserResponse) => (event: any) => {
      event.stopPropagation();
      event.preventDefault();
      history.push(`/administration/users/${user.id}/view`);
    },
    [history],
  );

  const handleDeleteClick = useCallback(
    (user: API.UserResponse) => (event: any) => {
      event.stopPropagation();
      setUserState({
        deleteModalOpen: true,
        userSelected: user,
      });
    },
    [],
  );

  return (
    <>
      <Tooltip id={TOOLTIP_ID} />

      {deleteModalOpen && !!userSelected && (
        <DeleteUser
          isOpen={deleteModalOpen}
          onClose={() =>
            setUserState((prevState) => ({
              ...prevState,
              brandingModalOpen: false,
              userSelected: null,
            }))
          }
          onComplete={onUserDeleteComplete}
          user={userSelected}
        />
      )}
      {!users.length && (
        <EmptyList icon="users" message="There are no users yet" />
      )}
      {!!users.length && (
        <Table stickyHeader stickyTop={STICKY_BAR_HEIGHT} clickable>
          <thead>
            <tr>
              <th>User</th>
              <th>Account Type</th>
              <th>Status</th>
              <th>Clients</th>
              <th className="right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr
                className="usersTable-row"
                key={user.id}
                data-test={user.id}
                onClick={handleEventClick(user)}
              >
                <td className="pt-4 pb-4">
                  <VerticalGroup>
                    <Text bold>{formatPersonName(user)}</Text>
                    <Text className="mt-1">{user.email}</Text>
                  </VerticalGroup>
                </td>
                <td>{formatUserType(user.type)}</td>
                <td>{getUserStatus(user)}</td>
                <td>
                  <ClientAvatarList clients={user.clients} />
                </td>
                <td className="right">
                  <Icon
                    type="search"
                    tt={{ content: "View User", id: TOOLTIP_ID }}
                  />
                  <Clickable
                    data-test={`delete-${user.id}`}
                    onClick={handleDeleteClick(user)}
                  >
                    <Icon
                      type="trash"
                      tt={{ content: "Delete User", id: TOOLTIP_ID }}
                    />
                  </Clickable>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}

export default UserListTable;
