import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { ThemeColours, ThemeSpacing } from "../../../types/theme";
import Button, { Props as ButtonProps } from "../../atoms/button/Button";
import Icon, { IconType } from "../../atoms/icon/Icon";

interface Props extends Partial<ButtonProps> {
  icon: IconType;
  text?: ReactNode;
  linkTo?: string;
  color?: ThemeColours;
  noTab?: boolean;
  iconSize?: ThemeSpacing;
  disabledColour?: ThemeColours;
  completed?: boolean;
  rounded?: boolean;
}

function IconButton({
  icon,
  text,
  linkTo,
  color,
  noTab = false,
  disabledColour,
  iconSize,
  completed,
  wide,
  ...rest
}: Props) {
  if (rest.disabled && disabledColour) {
    rest.colour = disabledColour;
  }
  const button = (
    <Button
      wide={wide}
      light={!rest.danger}
      bold
      {...rest}
      {...(noTab ? { tabIndex: -1 } : {})}
    >
      <Icon type={icon} colour={color} size={iconSize} />
      {text && <div className="mr-2">{text}</div>}
      {completed != null && (
        <Icon
          noMargin
          type="status"
          className="mr-2"
          colour={completed ? "lightgreen" : "grey1"}
        />
      )}
    </Button>
  );

  if (linkTo) {
    return <Link to={linkTo}>{button}</Link>;
  }

  return button;
}

export default IconButton;
