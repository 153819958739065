import React from "react";
import { LabelProps } from "recharts";

export interface CustomLabelProps extends Omit<LabelProps, "value"> {
  value: { value: number; topValueToUse: number };
}

export interface BarChartData {
  [key: string]: string | number | null | React.FC<CustomLabelProps>;
  key: string;
  topLabel: React.FC<CustomLabelProps>;
  fill: string | null;
  name: string;
  raw?: any;
}

export const createBarChartTopLabel = (
  labelFormatter: (val: number) => string,
) => {
  const BarChartTopLabel: React.FC<CustomLabelProps> = ({
    x,
    y,
    width,
    height,
    value,
  }) => {
    if (value == null) {
      return null;
    }
    const valueToUse = value.topValueToUse ?? value.value;
    const xToUse = Number(x) ?? 0;
    const yToUse = Number(y) ?? 0;
    const widthToUse = Number(width) ?? 0;
    const heightToUse = Number(height) ?? 0;
    return (
      <g>
        <text
          x={xToUse + widthToUse / 2}
          y={
            (value.value < 0 ? yToUse + heightToUse : yToUse) +
            (value.value < 0 ? -10 : value.value < 200 ? -15 : -5)
          }
          fill="#000"
          textAnchor="middle"
          fontWeight="bold"
          fontSize="clamp(0.25rem, calc(0.25rem + 1.2vw), 4rem)"
        >
          {labelFormatter(valueToUse)}
        </text>
      </g>
    );
  };
  return BarChartTopLabel;
};
