// hooks.ts

import { useMemo } from "react";
import { getYAxisDataV2 } from "../../charts/yaxis";
import { ChartDataItem } from "./ChartSection";

interface DataItem {
  name: string;
  value: number;
  fill: string;
  logo: string;
  displayValue: string;
  border?: boolean;
  topLabel?: string;
  additionalLogo?: string;
}

interface YAxisData {
  minValue: number;
  maxValue: number;
  numberOfTicks: number;
}

export const useChartData = (data: DataItem[]): ChartDataItem[] => {
  return useMemo(
    () =>
      data.map((item) => ({
        ...item,
        formatter: () => item.displayValue,
        topLabel: item.topLabel,
      })),
    [data],
  );
};

export const useYAxisData = (data: DataItem[]): YAxisData => {
  return useMemo(
    () =>
      getYAxisDataV2({
        pastRound1: true,
        min: 0,
        data: [{ name: "fake0", value: 0 }].concat(
          data.map((item) => ({ name: item.name, value: item.value })),
        ),
      }),
    [data],
  );
};
