import React, { useCallback } from "react";
import API from "../../../../../services/api";
import useAPIRequest from "../../../../../hooks/useAPIRequest";
import ChallengeModal from "../../../../organisms/challenge-modal/ChallengeModal";
import ConfirmModal from "../../../../organisms/confirm-modal/ConfirmModal";
import { pluralise } from "../../../../../lib/pluralise";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  manual: API.HelpManualResponse;
}

function DeleteHelpManuals({ isOpen, onComplete, onClose, manual }: Props) {
  const callback = useCallback(() => API.deleteHelpManual(manual.id), [manual]);

  const [{ inProgress, error }, doAPIRequest] = useAPIRequest(callback, null);

  const handleConfirm = () => {
    doAPIRequest(onComplete);
  };

  if (manual.simulationCount > 0) {
    return (
      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onClose}
        title="Delete events"
        description={`This help manual cannot be deleted as it is being used by ${manual.simulationCount} ${pluralise("simulation", manual.simulationCount)}. Please make sure it is not in use before deleting.`}
        confirmTitle={"Ok"}
      />
    );
  }

  return (
    <ChallengeModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      challengeQuestion="This action is irreversible. Please type the word DELETE below to delete this user."
      challengeAnswer="DELETE"
      challengeAnswerWarning="Please type the word DELETE in CAPS to delete the user."
      title={`Delete help manual: ${manual.name}?`}
      confirmTitle="Delete"
      error={error && error.message}
      warning
    />
  );
}

export default DeleteHelpManuals;
