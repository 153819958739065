import React from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import Text from "../../../../atoms/text/Text";

import "./QuestionPreview.scss";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import { useParams } from "react-router-dom";
import IconButton from "../../../../molecules/iconbutton/IconButton";
import { useCurrentAssessment } from "../../../../../context/AssessmentContext";

interface Props {
  group: API.AssessmentGroupResponse;
  question: API.AssessmentQuestionResponse;
  index: number;
  disableQuestionIndex?: boolean;
  answer?: any;
  isAnswerMode?: boolean;
}

export const QuestionPreviewContainer: React.FC<Props> = ({
  children,
  group,
  index,
  question,
  disableQuestionIndex,
  isAnswerMode = false,
}) => {
  const { assessmentId: maybeId } = useParams<{ assessmentId: string }>();
  const maybeAssessment = useCurrentAssessment();

  const assessmentId = maybeId || (maybeAssessment && maybeAssessment.id);

  return (
    <VerticalGroup className="question-container" wide spaceBetweenElements={2}>
      <InlineGroup block spread verticalCenter>
        {!disableQuestionIndex && (
          <>
            {question.type === "image" && <Text></Text>}
            {question.type === "written_text" && <Text></Text>}
            {!["image", "written_text"].includes(question.type) && (
              <Text>Question {index + 1}</Text>
            )}

            <InlineGroup right verticalCenter>
              <Text size="sm">Points: {question.marks}</Text>
              {assessmentId && (
                <IconButton
                  noUi
                  icon="edit"
                  wide={false}
                  light={false}
                  linkTo={`/assessments/${assessmentId}/groups/${group.id}/questions/${question.id}/edit`}
                />
              )}
            </InlineGroup>
          </>
        )}
      </InlineGroup>
      {!isAnswerMode && <Text isHtml>{question.question}</Text>}
      {children}
    </VerticalGroup>
  );
};
