import React from "react";
import Card from "../../atoms/card/Card";
import Text from "../../atoms/text/Text";
import Image from "../../atoms/image/Image";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import classNames from "classnames";

interface Props {
  mobile?: boolean;
  imageUrl?: string;
  round: number;
  totalRounds: number;
  gameName: string;
}

const GameRoundDetails: React.FC<Props> = ({
  mobile,
  imageUrl,
  round,
  totalRounds,
  gameName,
}) => {
  return (
    <Card
      wide={mobile}
      className={classNames({
        "width-40-percent": !mobile,
      })}
    >
      <InlineGroup
        verticalCenter
        spaceBetweenElements={4}
        fullHeight
        // className={classNames({ 'p-4': !mobile })}
        center={mobile}
      >
        {!!imageUrl && <Image src={imageUrl} alt="Client logo" size={20} />}
        <VerticalGroup>
          <Text size="3xl" bold>
            {round > totalRounds ? "Wrap Up" : `Round ${round}`}
          </Text>
          <Text size="md">{gameName}</Text>
        </VerticalGroup>
      </InlineGroup>
    </Card>
  );
};

export default GameRoundDetails;
