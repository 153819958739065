import React, { useCallback } from "react";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import Text from "../../../../atoms/text/Text";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import Image from "../../../../atoms/image/Image";

import "./deals.scss";
import IntegerDropdown from "../../../../organisms/integer-dropdown/IntegerDropdown";
import { FieldErrors } from "../../../../../hooks/useForm";
import Card from "../../../../atoms/card/Card";
import EditableText, {
  Handles as EditableTextHandles,
} from "../../../../atoms/text/EditableText";
import { useRef } from "react";
import Clickable from "../../../../atoms/clickable/Clickable";
import Icon from "../../../../atoms/icon/Icon";
import Toggle from "../../../../atoms/toggle/Toggle";
interface DealItemProps {
  deal: API.DealConfigResponse;
  amlEventEnabled: boolean;
  selectedRound?: number;
  onChange: (deal: API.DealConfigResponse, round: number) => void;
  numberOfRounds?: number;
  error?: string;
  onUpdateContent?: (
    dealId: string,
    data: Partial<API.DealConfigResponse>,
  ) => Promise<void>;
  onAMLEventEnabled?: (enabled: boolean) => void;
  amlEventRound?: number;
  onAMLEventRoundChange?: (selected: any) => void;
}

const DealItem: React.FC<DealItemProps> = ({
  deal,
  selectedRound,
  onChange,
  numberOfRounds,
  error,
  onUpdateContent,
  amlEventEnabled,
  onAMLEventEnabled,
  amlEventRound,
  onAMLEventRoundChange,
}) => {
  const onDealItemChange = useCallback(
    ({ value }) => {
      onChange(deal, value);
    },
    [onChange, deal],
  );

  const updateField = useCallback(
    (field: keyof API.DealConfigResponse) => (value: string) => {
      if (onUpdateContent) {
        onUpdateContent(deal.id, {
          [field]: value,
        });
      }
    },
    [deal.id, onUpdateContent],
  );

  const nameRef = useRef<EditableTextHandles>(null);
  const descriptionRef = useRef<EditableTextHandles>(null);
  const outcomeRef = useRef<EditableTextHandles>(null);
  const additionalInfoRef = useRef<EditableTextHandles>(null);

  return (
    <Card className="mt-4 p-6" sharp error={!!error}>
      <VerticalGroup spaceBetweenElements={6}>
        <InlineGroup spread block>
          <InlineGroup spaceBetweenElements={2} verticalCenter>
            <EditableText
              type="text"
              ref={nameRef}
              bold
              size="sm"
              value={deal.name}
              onChange={updateField("name")}
            />
            <Clickable onClick={() => nameRef.current?.setIsEditing(true)}>
              <Icon
                noMargin
                type="edit"
                tip={{ content: "Edit Name", id: "edit-name" }}
              />
            </Clickable>
          </InlineGroup>
          {numberOfRounds && (
            <IntegerDropdown
              name={`deal-room-dropdown-${deal.type}`}
              error={error}
              small
              placeholder="Select Round"
              min={0}
              max={numberOfRounds}
              onChange={onDealItemChange}
              value={selectedRound}
              labelFormatter={(round) =>
                round === 0 ? "Not Selected" : `Round ${round}`
              }
            />
          )}
        </InlineGroup>
        {deal.type === "neobank" &&
          !!selectedRound &&
          onAMLEventEnabled != null && (
            <InlineGroup block verticalCenter spaceBetweenElements={6} right>
              <Toggle
                label="AML Event"
                checked={amlEventEnabled}
                onUpdate={onAMLEventEnabled}
                data-test="enable-aml-event"
                helpTitle="AML Event"
                helpDescription={`This is an event where the bank who won the ${deal.name} deal will be fined`}
              />

              {amlEventEnabled &&
                numberOfRounds &&
                onAMLEventRoundChange != null && (
                  <InlineGroup
                    className="pr-2"
                    verticalCenter
                    spaceBetweenElements={2}
                  >
                    <Text>Occurs in round</Text>
                    <IntegerDropdown
                      tiny
                      data-test={"aml-event-round-dropdown"}
                      min={selectedRound}
                      max={numberOfRounds}
                      value={amlEventRound}
                      onChange={onAMLEventRoundChange}
                    />
                  </InlineGroup>
                )}
            </InlineGroup>
          )}
        <InlineGroup block spaceBetweenElements={6}>
          <Image size={56} cover src={deal.imageUrl} alt={deal.name} />
          <VerticalGroup spaceBetweenElements={2} wide>
            <InlineGroup spaceBetweenElements={2} verticalCenter>
              <Text size="sm" bold>
                Description
              </Text>
              <Clickable
                onClick={() => descriptionRef.current?.setIsEditing(true)}
              >
                <Icon
                  noMargin
                  type="edit"
                  tip={{ content: "Edit Description", id: "edit-description" }}
                />
              </Clickable>
            </InlineGroup>
            <EditableText
              ref={descriptionRef}
              size="sm"
              value={deal.description}
              onChange={updateField("description")}
            />
            <InlineGroup spaceBetweenElements={2} verticalCenter>
              <Text size="sm" bold>
                Outcome
              </Text>
              <Clickable onClick={() => outcomeRef.current?.setIsEditing(true)}>
                <Icon
                  noMargin
                  type="edit"
                  tip={{ content: "Edit Outcome", id: "edit-outcome" }}
                />
              </Clickable>
            </InlineGroup>
            <EditableText
              ref={outcomeRef}
              size="sm"
              value={deal.outcome ?? "No outcome"}
              onChange={updateField("outcome")}
            />
            <InlineGroup spaceBetweenElements={2} verticalCenter>
              <Text size="sm" bold>
                Internal Information
              </Text>
              <Clickable
                onClick={() => additionalInfoRef.current?.setIsEditing(true)}
              >
                <Icon
                  noMargin
                  type="edit"
                  tip={{
                    content: "Edit Internal Information",
                    id: "edit-internal",
                  }}
                />
              </Clickable>
            </InlineGroup>
            <EditableText
              ref={additionalInfoRef}
              size="sm"
              value={deal.additionalInformation ?? "No internal information"}
              onChange={updateField("additionalInformation")}
            />
          </VerticalGroup>
        </InlineGroup>
      </VerticalGroup>
    </Card>
  );
};

interface DealRoomSelectProps {
  selected?: { [key in API.DealRoom]: number };
  onChange?: (newSelected: { [key in API.DealRoom]: number }) => void;
  numberOfRounds?: number;
  fieldErrors?: FieldErrors;
  onUpdateContent?: (
    dealId: string,
    data: Partial<API.DealConfigResponse>,
  ) => Promise<void>;
  deals: API.DealConfigResponse[];
  amlEventEnabled?: boolean;
  onAMLEventEnabled?: (enabled: boolean) => void;
  amlEventRound?: number;
  onAMLEventRoundChange?: (selected: any) => void;
}

const DealRoomSelect: React.FC<DealRoomSelectProps> = ({
  selected,
  onChange,
  numberOfRounds,
  fieldErrors,
  onUpdateContent,
  deals,
  amlEventEnabled,
  onAMLEventEnabled,
  amlEventRound,
  onAMLEventRoundChange,
}) => {
  const onDealSelectChange = useCallback(
    (deal: API.DealConfigResponse, round: number) => {
      if (!selected || !onChange) {
        return;
      }
      const items = { ...selected };
      if (round > 0) {
        items[deal.type] = round;
      } else {
        delete items[deal.type];
      }

      onChange(items);
    },
    [onChange, selected],
  );

  return (
    <VerticalGroup
      className="deals-select"
      wide
      spaceBetweenElements={6}
      flexAuto
    >
      {deals &&
        deals.length &&
        deals.map((deal) => {
          const selectedRound = selected?.[deal.type];
          return (
            <DealItem
              key={deal.id}
              deal={deal}
              selectedRound={selectedRound}
              onChange={onDealSelectChange}
              numberOfRounds={numberOfRounds}
              onUpdateContent={onUpdateContent}
              error={
                selectedRound
                  ? fieldErrors?.[`dealRooms-${deal.type}`]
                  : undefined
              }
              amlEventEnabled={!!amlEventEnabled}
              onAMLEventEnabled={onAMLEventEnabled}
              amlEventRound={amlEventRound}
              onAMLEventRoundChange={onAMLEventRoundChange}
            />
          );
        })}
    </VerticalGroup>
  );
};

export default DealRoomSelect;
