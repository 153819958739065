import React, { useCallback, useMemo, useState } from "react";
import Card from "../../../../../atoms/card/Card";
import Text from "../../../../../atoms/text/Text";
import EconomicScenarioLineChart from "../EconomicScenarioCharts/EconomicScenarioLineChart ";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import IntegerDropdown from "../../../../../organisms/integer-dropdown/IntegerDropdown";
import FastEditingDropDown from "../../../../../organisms/FastEditingDropDown/FastEditingDropDown";
import {
  WsfCosts,
  calculateTotalSpreads,
  calculateWsf,
} from "../calculations/wsfCosts";
import { formatToRating, wsfConvertDivide } from "../calculations/utils";
import PercentageDropdown from "../../../../../organisms/percentage-dropdown/PercentageDropdown";
import EconomicScenarioRoundDropdown from "../../../../../organisms/economicScenarioRoundDropdown/EconomicScenarioRoundDropdown";
import InformationPopup from "../../../../../organisms/information-popup/InformationPopup";
import EnumDropdown from "../../../../../organisms/enum-dropdown/EnumDropdown";
import EconomySettingDropDown from "../components/EconomySettingDropDown";

interface Props {
  round: number;
  selectedScenario: API.EconomicScenarioResponse;
  handleScenarioUpdated: (
    updatedScenario: API.EconomicScenarioUpdateRequest,
  ) => void;
  setRound: (round: number) => void;
  handleRoundChange: (newRound: number) => void;
  handleUpdate: (scenario: {
    value: string;
    label: string;
    description?: string;
  }) => void;
  scenarios: API.EconomicScenarioResponse[];
}

type ChartType = keyof API.EconomicScenarioWSFCosts;

const bondOptions = [
  { value: 1, label: "1 Year Bonds" },
  { value: 2, label: "2 Year Bonds" },
  { value: 3, label: "3 Year Bonds" },
  { value: 4, label: "4 Year Bonds" },
  { value: 5, label: "5 Year Bonds" },
];

const WFSCosts = ({
  round,
  selectedScenario,
  handleScenarioUpdated,
  setRound,
  handleRoundChange,
  handleUpdate,
  scenarios,
}: Props) => {
  const [term, setTerm] = useState(5);
  const handleAndRecalc = useCallback(
    (updatedWsfCosts: API.EconomicScenarioWSFCosts) => {
      handleScenarioUpdated({
        wsfCosts: {
          coreCreditSpreads: {
            startingSpread: updatedWsfCosts.coreCreditSpreads.startingSpread,
            spreadIncrement: updatedWsfCosts.coreCreditSpreads.spreadIncrement,
            spreadFactor: updatedWsfCosts.coreCreditSpreads.spreadFactor,
          },
          termPremiums: {
            startingSpread: updatedWsfCosts.termPremiums.startingSpread,
            spreadIncrement: updatedWsfCosts.termPremiums.spreadIncrement,
            spreadFactor: updatedWsfCosts.termPremiums.spreadFactor,
          },
          ratingImpactSpreads: {
            startingSpread: updatedWsfCosts.ratingImpactSpreads.startingSpread,
            spreadIncrement:
              updatedWsfCosts.ratingImpactSpreads.spreadIncrement,
            spreadFactor: updatedWsfCosts.ratingImpactSpreads.spreadFactor,
          },
          referenceRate: updatedWsfCosts.referenceRate,
        },
      });
    },
    [handleScenarioUpdated],
  );

  const calculatedWsfCosts = useMemo(() => {
    return calculateWsf({
      coreCreditSpread: {
        startingSpread:
          selectedScenario.wsfCosts.coreCreditSpreads.startingSpread,
        spreadIncrement:
          selectedScenario.wsfCosts.coreCreditSpreads.spreadIncrement,
        spreadFactor: selectedScenario.wsfCosts.coreCreditSpreads.spreadFactor,
      },
      termPremiums: {
        startingSpread: selectedScenario.wsfCosts.termPremiums.startingSpread,
        spreadIncrement: selectedScenario.wsfCosts.termPremiums.spreadIncrement,
        spreadFactor: selectedScenario.wsfCosts.termPremiums.spreadFactor,
      },
      ratingImpactSpreads: {
        startingSpread:
          selectedScenario.wsfCosts.ratingImpactSpreads.startingSpread,
        spreadIncrement:
          selectedScenario.wsfCosts.ratingImpactSpreads.spreadIncrement,
        spreadFactor:
          selectedScenario.wsfCosts.ratingImpactSpreads.spreadFactor,
      },
      referenceRate: selectedScenario.wsfCosts.referenceRate,
    });
  }, [
    selectedScenario.wsfCosts.coreCreditSpreads.spreadFactor,
    selectedScenario.wsfCosts.coreCreditSpreads.spreadIncrement,
    selectedScenario.wsfCosts.coreCreditSpreads.startingSpread,
    selectedScenario.wsfCosts.ratingImpactSpreads.spreadFactor,
    selectedScenario.wsfCosts.ratingImpactSpreads.spreadIncrement,
    selectedScenario.wsfCosts.ratingImpactSpreads.startingSpread,
    selectedScenario.wsfCosts.referenceRate,
    selectedScenario.wsfCosts.termPremiums.spreadFactor,
    selectedScenario.wsfCosts.termPremiums.spreadIncrement,
    selectedScenario.wsfCosts.termPremiums.startingSpread,
  ]);

  const totalSpreads = useMemo(() => {
    return calculatedWsfCosts
      ? calculateTotalSpreads(calculatedWsfCosts, term)
      : {};
  }, [calculatedWsfCosts, term]);

  const handleChange = useCallback(
    (
      wsfCostType: "coreCreditSpreads" | "termPremiums" | "ratingImpactSpreads",
      valueType: "startingSpread" | "spreadFactor" | "spreadIncrement",
    ) =>
      (option: { value: number }) => {
        const numericValue = option.value;
        const newValueTypeArray = [
          ...selectedScenario.wsfCosts[wsfCostType][valueType],
        ];
        newValueTypeArray[round - 1] = numericValue;

        handleAndRecalc({
          ...selectedScenario.wsfCosts,
          [wsfCostType]: {
            ...selectedScenario.wsfCosts[wsfCostType],
            [valueType]: newValueTypeArray,
          },
        });
      },
    [handleAndRecalc, round, selectedScenario.wsfCosts],
  );

  const handleReferenceRateChange = useCallback(
    (newValue: number) => {
      const newReferenceRate = [...selectedScenario.wsfCosts.referenceRate];
      newReferenceRate[round - 1] = newValue;

      handleAndRecalc({
        ...selectedScenario.wsfCosts,
        referenceRate: newReferenceRate,
      });
    },
    [handleAndRecalc, round, selectedScenario.wsfCosts],
  );

  const transformDataAndGetCurrentRoundSpreads = (
    wsfCosts: WsfCosts,
    round: number,
  ) => {
    const currentRoundIndex = round - 1;
    let currentRoundSpreads = {};

    const chartData = wsfCosts.coreCreditSpreads.AAA.map((_, index) => {
      const dataPoint = {
        name: `${index + 1}`,
        AAA: wsfCosts.coreCreditSpreads.AAA[index],
        AA: wsfCosts.coreCreditSpreads.AA[index],
        A: wsfCosts.coreCreditSpreads.A[index],
        BBB: wsfCosts.coreCreditSpreads.BBB[index],
        BB: wsfCosts.coreCreditSpreads.BB[index],
        B: wsfCosts.coreCreditSpreads.B[index],
      };
      if (index === currentRoundIndex) {
        currentRoundSpreads = {
          AAA: dataPoint.AAA,
          AA: dataPoint.AA,
          A: dataPoint.A,
          BBB: dataPoint.BBB,
          BB: dataPoint.BB,
          B: dataPoint.B,
        };
      }

      return dataPoint;
    });

    return { chartData, currentRoundSpreads };
  };

  const { chartData, currentRoundSpreads } = calculatedWsfCosts
    ? transformDataAndGetCurrentRoundSpreads(calculatedWsfCosts, round)
    : { chartData: [], currentRoundSpreads: {} };

  const transformTermPremiumsData = (wsfCosts: WsfCosts, round: number) => {
    const currentRoundIndex = round - 1;
    let currentRoundSpreads = {};
    const termPremiumsChartData = wsfCosts.termPremiums.oneYr.map(
      (_, index) => {
        const termDataPoint = {
          name: `${index + 1}`,
          oneYr: wsfCosts.termPremiums.oneYr[index],
          twoYr: wsfCosts.termPremiums.twoYr[index],
          threeYr: wsfCosts.termPremiums.threeYr[index],
          fourYr: wsfCosts.termPremiums.fourYr[index],
          fiveYr: wsfCosts.termPremiums.fiveYr[index],
        };
        if (index === currentRoundIndex) {
          currentRoundSpreads = {
            "1 year": termDataPoint["oneYr"],
            "2 year": termDataPoint["twoYr"],
            "3 year": termDataPoint["threeYr"],
            "4 year": termDataPoint["fourYr"],
            "5 year": termDataPoint["fiveYr"],
          };
        }
        return termDataPoint;
      },
    );
    return { termPremiumsChartData, currentRoundSpreads };
  };
  const {
    termPremiumsChartData,
    currentRoundSpreads: termPremiumsCurrentRoundSpreads,
  } = calculatedWsfCosts
    ? transformTermPremiumsData(calculatedWsfCosts, round)
    : { termPremiumsChartData: [], currentRoundSpreads: {} };

  const transformTotalSpreadsData = (totalSpreads: {
    [key: string]: number[];
  }) => {
    const totalSpreadsChartData = totalSpreads.AAA.map((_, index) => {
      const dataPoint = {
        name: `${index + 1}`,
        AAA: totalSpreads.AAA[index],
        AA: totalSpreads.AA[index],
        A: totalSpreads.A[index],
        BBB: totalSpreads.BBB[index],
        BB: totalSpreads.BB[index],
        B: totalSpreads.B[index],
        referenceRate: selectedScenario.wsfCosts.referenceRate[index] / 100,
      };

      return dataPoint;
    });

    return { totalSpreadsChartData };
  };

  const { totalSpreadsChartData } = calculatedWsfCosts
    ? transformTotalSpreadsData(totalSpreads)
    : { totalSpreadsChartData: [] };

  const transformRatingImpactAndGetCurrentRoundSpreads = (
    wsfCosts: WsfCosts,
    round: number,
  ) => {
    const currentRoundIndex = round - 1;
    let currentRoundSpreads = {};

    const chartData = wsfCosts.ratingImpactSpreads.AAA.map((_, index) => {
      const dataPoint = {
        name: `${index + 1}`,
        AAA: wsfCosts.ratingImpactSpreads.AAA[index],
        AA: wsfCosts.ratingImpactSpreads.AA[index],
        A: wsfCosts.ratingImpactSpreads.A[index],
        BBB: wsfCosts.ratingImpactSpreads.BBB[index],
        BB: wsfCosts.ratingImpactSpreads.BB[index],
        B: wsfCosts.ratingImpactSpreads.B[index],
      };
      if (index === currentRoundIndex) {
        currentRoundSpreads = {
          AAA: dataPoint.AAA,
          AA: dataPoint.AA,
          A: dataPoint.A,
          BBB: dataPoint.BBB,
          BB: dataPoint.BB,
          B: dataPoint.B,
        };
      }

      return dataPoint;
    });

    return { chartData, currentRoundSpreads };
  };

  const {
    chartData: ratingImpactChartData,
    currentRoundSpreads: ratingImpactCurrentRoundSpread,
  } = calculatedWsfCosts
    ? transformRatingImpactAndGetCurrentRoundSpreads(calculatedWsfCosts, round)
    : { chartData: [], currentRoundSpreads: {} };

  const getCurrentRoundTotalSpreads = (
    totalSpreads: { [key: string]: number[] },
    round: number,
  ): { [key: string]: number } => {
    const currentRoundIndex = round - 1;
    const currentRoundTotalSpreads: { [key: string]: number } = {};

    Object.keys(totalSpreads).forEach((key) => {
      if (totalSpreads[key] && totalSpreads[key].length > currentRoundIndex) {
        currentRoundTotalSpreads[key] = totalSpreads[key][currentRoundIndex];
      }
    });

    return currentRoundTotalSpreads;
  };

  const currentRoundTotalSpreads = useMemo(() => {
    return getCurrentRoundTotalSpreads(totalSpreads, round);
  }, [totalSpreads, round]);

  const handleFastEditChange = useCallback(
    (chartType: ChartType) =>
      (selectedValue: { value: string; label: string; round?: string }) => {
        const dataToCopy: API.EconomicScenarioWSFCosts = {
          coreCreditSpreads: {
            startingSpread: [
              ...selectedScenario.wsfCosts.coreCreditSpreads.startingSpread,
            ],
            spreadIncrement: [
              ...selectedScenario.wsfCosts.coreCreditSpreads.spreadIncrement,
            ],
            spreadFactor: [
              ...selectedScenario.wsfCosts.coreCreditSpreads.spreadFactor,
            ],
          },
          termPremiums: {
            startingSpread: [
              ...selectedScenario.wsfCosts.termPremiums.startingSpread,
            ],
            spreadIncrement: [
              ...selectedScenario.wsfCosts.termPremiums.spreadIncrement,
            ],
            spreadFactor: [
              ...selectedScenario.wsfCosts.termPremiums.spreadFactor,
            ],
          },
          ratingImpactSpreads: {
            startingSpread: [
              ...selectedScenario.wsfCosts.ratingImpactSpreads.startingSpread,
            ],
            spreadIncrement: [
              ...selectedScenario.wsfCosts.ratingImpactSpreads.spreadIncrement,
            ],
            spreadFactor: [
              ...selectedScenario.wsfCosts.ratingImpactSpreads.spreadFactor,
            ],
          },
          referenceRate: [...selectedScenario.wsfCosts.referenceRate],
        };
        if (selectedValue.value === "copy_from" && selectedValue.round) {
          const roundToCopyFrom = parseInt(
            selectedValue.round.replace("round_", ""),
          );
          const costType = dataToCopy[chartType];
          if (Array.isArray(costType)) {
            for (let i = round; i < costType.length; i++) {
              costType[round - 1] = costType[roundToCopyFrom];
            }
          } else {
            Object.keys(costType).forEach((subKey) => {
              const valueArray = costType[subKey as keyof typeof costType];
              if (Array.isArray(valueArray)) {
                valueArray[round - 1] = valueArray[roundToCopyFrom];
              }
            });
          }
        } else if (selectedValue.value === "paste_forward") {
          const costType = dataToCopy[chartType];
          if (Array.isArray(costType)) {
            for (let i = round; i < costType.length; i++) {
              costType[i] = costType[round - 1];
            }
          } else {
            Object.keys(costType).forEach((subKey) => {
              const valueArray = costType[
                subKey as keyof typeof costType
              ] as number[];
              if (Array.isArray(valueArray)) {
                for (let i = round; i < valueArray.length; i++) {
                  valueArray[i] = valueArray[round - 1];
                }
              }
            });
          }
        }
        handleAndRecalc(dataToCopy);
      },
    [selectedScenario, handleAndRecalc, round],
  );

  return (
    <VerticalGroup spaceBetweenElements={3}>
      <InlineGroup stretch block evenWidthChildren>
        <InlineGroup stretch block>
          <EconomySettingDropDown
            scenariosData={scenarios}
            onChange={handleUpdate}
            value={selectedScenario?.id}
            small
          />
        </InlineGroup>
        <InlineGroup stretch block reverse>
          <InlineGroup spaceBetweenElements={3}>
            <EconomicScenarioRoundDropdown
              onChange={setRound}
              currentRound={round}
              small
            />
          </InlineGroup>
        </InlineGroup>
      </InlineGroup>
      <Card wide>
        <VerticalGroup wide spaceBetweenElements={4}>
          <Text bold>Core Spreads (%)</Text>
          <EconomicScenarioLineChart
            onRoundChange={handleRoundChange}
            currentRound={round}
            currentRoundSpreads={currentRoundSpreads}
            legendDesign="costs"
            legendOrientation="vertical"
            height={235}
            data={chartData}
            lineConfigurations={[
              { dataKey: "B", strokeColor: "#FA6A50" },
              { dataKey: "BB", strokeColor: "#F9AA3C" },
              { dataKey: "BBB", strokeColor: "#9549D6" },
              { dataKey: "A", strokeColor: "#27A4F2" },
              { dataKey: "AA", strokeColor: "#67D0B6" },
              { dataKey: "AAA", strokeColor: "#06A882", activeDot: true },
            ]}
            formatter={wsfConvertDivide}
          />
          <InlineGroup block spaceBetweenElements={4}>
            <VerticalGroup spaceBetweenElements={2}>
              <InlineGroup verticalCenter spaceBetweenElements={2}>
                <Text>AAA Spread (Bps)</Text>
                <InformationPopup
                  shadow
                  title={"AAA Spread"}
                  body={"This sets the spread (in Bps) for the AAA rating"}
                />
              </InlineGroup>
              <IntegerDropdown
                value={
                  selectedScenario.wsfCosts.coreCreditSpreads.startingSpread[
                    round - 1
                  ]
                }
                onChange={handleChange("coreCreditSpreads", "startingSpread")}
                min={0}
                max={99}
                step={1}
                midsize
              />
            </VerticalGroup>
            <VerticalGroup spaceBetweenElements={2}>
              <InlineGroup verticalCenter spaceBetweenElements={2}>
                <Text>Increment (Bps)</Text>
                <InformationPopup
                  shadow
                  title={"Increment"}
                  body={"The default spread between each rating (in Bps)"}
                />
              </InlineGroup>
              <IntegerDropdown
                value={
                  selectedScenario.wsfCosts.coreCreditSpreads.spreadIncrement[
                    round - 1
                  ]
                }
                onChange={handleChange("coreCreditSpreads", "spreadIncrement")}
                min={0}
                max={99}
                step={1}
                midsize
              />
            </VerticalGroup>
            <VerticalGroup spaceBetweenElements={2}>
              <InlineGroup verticalCenter spaceBetweenElements={2}>
                <Text>Expand Factor </Text>
                <InformationPopup
                  shadow
                  title={"Expand Factor"}
                  body={
                    "A higher expand factor will increase the spread between each subsequent rating."
                  }
                />
              </InlineGroup>
              <IntegerDropdown
                value={
                  selectedScenario.wsfCosts.coreCreditSpreads.spreadFactor[
                    round - 1
                  ]
                }
                onChange={handleChange("coreCreditSpreads", "spreadFactor")}
                min={0}
                max={99}
                step={1}
                midsize
              />
            </VerticalGroup>
            <VerticalGroup spaceBetweenElements={2}>
              <Text>Fast Editing</Text>
              <FastEditingDropDown
                currentRound={round}
                midsize
                onChange={handleFastEditChange("coreCreditSpreads")}
              />
            </VerticalGroup>
          </InlineGroup>
        </VerticalGroup>
      </Card>
      <Card wide>
        <VerticalGroup wide spaceBetweenElements={4}>
          <Text bold>Term Premiums (%)</Text>

          <EconomicScenarioLineChart
            onRoundChange={handleRoundChange}
            currentRound={round}
            currentRoundSpreads={termPremiumsCurrentRoundSpreads}
            legendDesign="costs"
            legendOrientation="vertical"
            height={200}
            data={termPremiumsChartData}
            lineConfigurations={[
              { dataKey: "fiveYr", name: "5 year", strokeColor: "#F9AA3C" },
              { dataKey: "fourYr", name: "4 year", strokeColor: "#9549D6" },
              { dataKey: "threeYr", name: "3 year", strokeColor: "#27A4F2" },
              { dataKey: "twoYr", name: "2 year", strokeColor: "#67D0B6" },
              {
                dataKey: "oneYr",
                name: "1 year",
                strokeColor: "#06A882",
                activeDot: true,
              },
            ]}
            formatter={wsfConvertDivide}
          />
          <InlineGroup block spaceBetweenElements={4}>
            <VerticalGroup spaceBetweenElements={2}>
              <InlineGroup verticalCenter spaceBetweenElements={2}>
                <Text>Year 1 Spread (Bps)</Text>
                <InformationPopup
                  shadow
                  title={"Year 1 Spread"}
                  body={
                    "This sets the spread (in Bps) for the 1 Year funding duration"
                  }
                />
              </InlineGroup>
              <IntegerDropdown
                value={
                  selectedScenario.wsfCosts.termPremiums.startingSpread[
                    round - 1
                  ]
                }
                onChange={handleChange("termPremiums", "startingSpread")}
                min={0}
                max={99}
                step={1}
                midsize
              />
            </VerticalGroup>
            <VerticalGroup spaceBetweenElements={2}>
              <InlineGroup verticalCenter spaceBetweenElements={2}>
                <Text>Increment (Bps)</Text>
                <InformationPopup
                  shadow
                  title={"Increment"}
                  body={
                    "The default spread between each funding duration (in Bps)"
                  }
                />
              </InlineGroup>
              <IntegerDropdown
                value={
                  selectedScenario.wsfCosts.termPremiums.spreadIncrement[
                    round - 1
                  ]
                }
                onChange={handleChange("termPremiums", "spreadIncrement")}
                min={0}
                max={99}
                step={1}
                midsize
              />
            </VerticalGroup>
            <VerticalGroup spaceBetweenElements={2}>
              <InlineGroup verticalCenter spaceBetweenElements={2}>
                <Text>Expand Factor</Text>
                <InformationPopup
                  shadow
                  title={"Expand Factor"}
                  body={
                    "A higher expand factor will increase the spread between each subsequent duration."
                  }
                />
              </InlineGroup>
              <IntegerDropdown
                value={
                  selectedScenario.wsfCosts.termPremiums.spreadFactor[round - 1]
                }
                onChange={handleChange("termPremiums", "spreadFactor")}
                min={0}
                max={99}
                step={1}
                midsize
              />
            </VerticalGroup>
            <VerticalGroup spaceBetweenElements={2}>
              <Text>Fast Editing</Text>
              <FastEditingDropDown
                currentRound={round}
                midsize
                onChange={handleFastEditChange("termPremiums")}
              />
            </VerticalGroup>
          </InlineGroup>
        </VerticalGroup>
      </Card>
      <Card wide>
        <VerticalGroup wide spaceBetweenElements={4}>
          <Text bold>Rating Impact On Term Premiums (Multiple)</Text>
          <EconomicScenarioLineChart
            onRoundChange={handleRoundChange}
            currentRound={round}
            currentRoundSpreads={ratingImpactCurrentRoundSpread}
            legendDesign="costs"
            legendOrientation="vertical"
            height={235}
            data={ratingImpactChartData}
            lineConfigurations={[
              { dataKey: "B", strokeColor: "#FA6A50" },
              { dataKey: "BB", strokeColor: "#F9AA3C" },
              { dataKey: "BBB", strokeColor: "#9549D6" },
              { dataKey: "A", strokeColor: "#27A4F2" },
              { dataKey: "AA", strokeColor: "#67D0B6" },
              { dataKey: "AAA", strokeColor: "#06A882", activeDot: true },
            ]}
            formatter={wsfConvertDivide}
          />
          <InlineGroup block spaceBetweenElements={4}>
            <VerticalGroup spaceBetweenElements={2}>
              <InlineGroup verticalCenter spaceBetweenElements={2}>
                <Text>AAA (Multiple)</Text>
                <InformationPopup
                  shadow
                  title={"AAA Multiple"}
                  body={"This sets the spread multiple for the AAA rating"}
                />
              </InlineGroup>
              <IntegerDropdown
                value={
                  selectedScenario.wsfCosts.ratingImpactSpreads.startingSpread[
                    round - 1
                  ]
                }
                midsize
                onChange={handleChange("ratingImpactSpreads", "startingSpread")}
                min={0}
                max={250}
                step={1}
                menuPlacement="top"
                labelFormatter={formatToRating}
              />
            </VerticalGroup>
            <VerticalGroup spaceBetweenElements={2}>
              <InlineGroup verticalCenter spaceBetweenElements={2}>
                <Text>Increment (Multiple)</Text>
                <InformationPopup
                  shadow
                  title={"Increment"}
                  body={"The default multiple between each rating"}
                />
              </InlineGroup>
              <IntegerDropdown
                value={
                  selectedScenario.wsfCosts.ratingImpactSpreads.spreadIncrement[
                    round - 1
                  ]
                }
                midsize
                onChange={handleChange(
                  "ratingImpactSpreads",
                  "spreadIncrement",
                )}
                min={0}
                max={100}
                step={1}
                menuPlacement="top"
                labelFormatter={formatToRating}
              />
            </VerticalGroup>
            <VerticalGroup spaceBetweenElements={2}>
              <InlineGroup verticalCenter spaceBetweenElements={2}>
                <Text>Expand Factor</Text>
                <InformationPopup
                  shadow
                  title={"Expand Factor"}
                  body={
                    "A higher expand factor will increase the multiple between each subsequent rating."
                  }
                />
              </InlineGroup>
              <IntegerDropdown
                value={
                  selectedScenario.wsfCosts.ratingImpactSpreads.spreadFactor[
                    round - 1
                  ]
                }
                midsize
                onChange={handleChange("ratingImpactSpreads", "spreadFactor")}
                min={0}
                max={99}
                step={1}
                menuPlacement="top"
              />
            </VerticalGroup>
            <VerticalGroup spaceBetweenElements={2}>
              <Text>Fast Editing</Text>
              <FastEditingDropDown
                currentRound={round}
                midsize
                onChange={handleFastEditChange("ratingImpactSpreads")}
              />
            </VerticalGroup>
          </InlineGroup>
        </VerticalGroup>
      </Card>
      <Card wide>
        <VerticalGroup wide spaceBetweenElements={4}>
          <InlineGroup spread block verticalCenter>
            <Text bold>Total Spreads - {term} Year Bonds (%)</Text>
            <EnumDropdown
              value={term}
              onChange={(option) => setTerm(Number(option.value))}
              options={bondOptions}
              small
            />
          </InlineGroup>
          <EconomicScenarioLineChart
            onRoundChange={handleRoundChange}
            currentRound={round}
            selectedReferenceRate={
              selectedScenario.wsfCosts.referenceRate[round - 1] / 10000
            }
            currentRoundSpreads={currentRoundTotalSpreads}
            legendDesign="costs"
            legendOrientation="vertical"
            height={270}
            data={totalSpreadsChartData}
            lineConfigurations={[
              { dataKey: "B", strokeColor: "#FA6A50" },
              { dataKey: "BB", strokeColor: "#F9AA3C" },
              { dataKey: "BBB", strokeColor: "#9549D6" },
              { dataKey: "A", strokeColor: "#27A4F2" },
              { dataKey: "AA", strokeColor: "#67D0B6" },
              { dataKey: "AAA", strokeColor: "#06A882", activeDot: true },
              {
                dataKey: "referenceRate",
                name: "Reference Rate",
                strokeColor: "#A0A4A8",
              },
            ]}
            formatter={wsfConvertDivide}
          />
          <InlineGroup block spaceBetweenElements={4}>
            <VerticalGroup spaceBetweenElements={2}>
              <Text>Reference Rate</Text>
              <PercentageDropdown
                value={selectedScenario.wsfCosts.referenceRate[round - 1]}
                onChange={handleReferenceRateChange}
                min={0}
                max={99}
                step={1}
                midsize
                menuPlacement="top"
              />
            </VerticalGroup>
            <VerticalGroup spaceBetweenElements={2}>
              <Text>Fast Editing</Text>
              <FastEditingDropDown
                currentRound={round}
                midsize
                onChange={handleFastEditChange("referenceRate")}
              />
            </VerticalGroup>
          </InlineGroup>
        </VerticalGroup>
      </Card>
    </VerticalGroup>
  );
};

export default WFSCosts;
