import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import API from "../../../../../services/api";
import Banner from "../../../../atoms/banner/Banner";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import { SimulationContext } from "../../context";
import useForm from "../../../../../hooks/useForm";
import Toggle from "../../../../atoms/toggle/Toggle";
import ExcoDecisionSelect from "./ExcoDecisionSelect";
import { findDuplicate, resizeArray } from "../../lib/array";
import ErrorModal from "../../../../organisms/standard-modal/ErrorModal";

import "./exco.scss";

import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import Button from "../../../../atoms/button/Button";
import api from "../../../../../services/api";

const convertExcoToArray = (
  rounds: number,
  excoDecisions: { [key in API.ExcoDecisionType]: number },
) => {
  const decisionsArray = resizeArray<API.ExcoDecisionType>([], rounds, "none");
  for (const [exco, round] of Object.entries(excoDecisions)) {
    decisionsArray[round - 1] = exco as API.ExcoDecisionType;
  }
  return decisionsArray;
};

const ExcoDecision: React.FC = () => {
  const [isErrorOpen, setErrorOpen] = useState(false);
  const { config, updateConfig, assignOnSubmitFunction, updateFormStatus } =
    useContext(SimulationContext);
  const [
    {
      formData: { enabled, excoDecisions },
      error,
      inProgress,
      formUpdated,
      fieldErrors,
    },
    { setFieldSimple, handleSubmit, setFieldRaw, setError },
  ] = useForm(
    config?.excoDecisionSetup
      ? {
          ...config.excoDecisionSetup,
          excoDecisions: config.excoDecisionSetup.excoDecisions.reduce(
            (acc: { [type: string]: number }, exco, index) => {
              if (exco !== "none") {
                acc[exco] = index + 1;
              }
              return acc;
            },
            {},
          ),
        }
      : {
          enabled: false,
          excoDecisions: {},
        },
  );

  const excludedExcoDecisions = useMemo(() => {
    if (config?.businessSetup) {
      if (!config.businessSetup.creditCardsEnabled) {
        return [
          "credit-cards",
          "credit-cards-&-bushfires",
          "credit-cards-washed-away",
          "buy-now-cry-later",
        ] as API.ExcoDecisionType[];
      }
    }
    return [] as API.ExcoDecisionType[];
  }, [config?.businessSetup]);

  const suitableExcoDecisions = useMemo(() => {
    return (config?.excoDecisionsConfig ?? []).filter(
      (ed) => !excludedExcoDecisions.includes(ed.type),
    );
  }, [config?.excoDecisionsConfig, excludedExcoDecisions]);

  const onSubmit = useCallback(async () => {
    if (config) {
      const payload: API.SimulationUpdateRequest = {
        excoDecisionSetup: {
          enabled,
          excoDecisions: convertExcoToArray(
            config.generalSetup.rounds,
            excoDecisions,
          ),
        },
      };

      const response = await API.editSimulation(config.id, payload);

      updateConfig(response);
      return response;
    }
  }, [config, enabled, excoDecisions, updateConfig]);

  const validate = useCallback(
    (newExcoDecisions?: { [key in API.ExcoDecisionType]: number }) => {
      const errors = [];
      const decisionsToUse = (newExcoDecisions ?? excoDecisions) as {
        [key in API.ExcoDecisionType]: number;
      };
      const roundsArray: number[] = Object.entries(decisionsToUse)
        .filter((entry) => entry[0] !== "none")
        .map((entry) => entry[1]);
      const duplicatedValueSet = findDuplicate<number>(roundsArray);

      for (const decisionEntry of Object.entries(decisionsToUse)) {
        const [excoType, round] = decisionEntry;
        if (excoType === "none") {
          errors.push({ field: "excoDecisions-none", message: undefined });
          continue;
        }

        if (duplicatedValueSet.has(round)) {
          errors.push({
            field: `excoDecisions-${excoType}`,
            message: "Only select 1 decision per round",
          });
        } else {
          errors.push({
            field: `excoDecisions-${excoType}`,
            message: undefined,
          });
        }
      }

      if (
        enabled &&
        !Object.keys(decisionsToUse).some(
          (d: string) => d != null && d !== "none",
        )
      ) {
        errors.push({
          field: "excoDecisions",
          message:
            "Please select at least one Executive Decision or disable Executive Decisions.",
        });
        setErrorOpen(true);
      }

      return errors;
    },
    [excoDecisions, setErrorOpen, enabled],
  );

  useEffect(() => {
    assignOnSubmitFunction(handleSubmit(onSubmit, validate, true));
  }, [assignOnSubmitFunction, handleSubmit, onSubmit, validate]);

  useEffect(() => {
    updateFormStatus({
      inProgress: inProgress,
      formUpdated: formUpdated,
    });
  }, [updateFormStatus, inProgress, formUpdated]);

  const updateExcoDecision = useCallback(
    (newValue: { [key in API.ExcoDecisionType]: number }) => {
      setFieldRaw("excoDecisions", newValue);

      const errors = validate(newValue);
      for (const err of errors) {
        setError(err.field, err.message);
      }
    },
    [setError, setFieldRaw, validate],
  );

  const clearAll = useCallback(() => {
    setFieldRaw("excoDecisions", {});
  }, [setFieldRaw]);

  const handleExcoDecisionsContentUpdate = useCallback(
    async (excoId: string, data: Partial<API.ExcoConfigResponse>) => {
      if (config?.excoDecisionsConfig) {
        const payload: API.SimulationUpdateRequest = {
          excoDecisionsConfig: config?.excoDecisionsConfig.map(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ({ imageUrl, ...edc }) => {
              if (edc.id !== excoId) {
                return edc;
              }
              return {
                ...edc,
                ...data,
              };
            },
          ),
        };

        const response = await api.editSimulation(config.id, payload);

        updateConfig(response);
      }
    },
    [config, updateConfig],
  );

  const handleExcoDecisionsOptionUpdate = useCallback(
    async (
      excoId: string,
      optionId: string,
      data: Partial<API.ExcoOptionConfig>,
    ) => {
      if (config?.excoDecisionsConfig) {
        const payload: API.SimulationUpdateRequest = {
          excoDecisionsConfig: config?.excoDecisionsConfig.map(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ({ imageUrl, ...edc }) => {
              if (edc.id !== excoId) {
                return edc;
              }
              return {
                ...edc,
                options: edc.options.map((option) => {
                  if (option.id !== optionId) {
                    return option;
                  }
                  return {
                    ...option,
                    ...data,
                  };
                }),
              };
            },
          ),
        };

        const response = await api.editSimulation(config.id, payload);

        updateConfig(response);
      }
    },
    [config, updateConfig],
  );

  return (
    <VerticalGroup
      wide
      full
      spaceBetweenElements={6}
      className="exco-container"
    >
      <InlineGroup block spread verticalCenter>
        <Banner type="error" active={!!error} message={error?.message} />
        <h3>Executive Team Decision Settings</h3>
      </InlineGroup>
      <InlineGroup block spread verticalCenter>
        <Toggle
          label="Enable Executive Decisions"
          helpTitle="Executive Decisions"
          helpDescription="This allows participants to experience a number of bespoke management decisions. They are provided with often imperfect information and must select from a number of uncertain outcomes."
          checked={enabled}
          onUpdate={setFieldSimple("enabled")}
          block
        />
        {enabled && (
          <Button
            wide
            disabled={
              Object.keys(excoDecisions).filter((k) => k !== "none").length ===
              0
            }
            onClick={clearAll}
          >
            Clear All
          </Button>
        )}
      </InlineGroup>
      {enabled && (
        <ExcoDecisionSelect
          onUpdateContent={handleExcoDecisionsContentUpdate}
          onUpdateOption={handleExcoDecisionsOptionUpdate}
          excoDecisions={suitableExcoDecisions}
          fieldErrors={fieldErrors}
          selected={excoDecisions}
          onChange={updateExcoDecision}
          numberOfRounds={config?.generalSetup.rounds ?? 0}
        />
      )}
      {!!fieldErrors.excoDecisions && (
        <ErrorModal
          isOpen={!!fieldErrors.excoDecisions && isErrorOpen}
          onClose={() => {
            setErrorOpen(false);
          }}
          title="No decisions selected"
          description={fieldErrors.excoDecisions}
        ></ErrorModal>
      )}
    </VerticalGroup>
  );
};

export { default as ExcoDecisionDisplay } from "./Display";
export default ExcoDecision;
