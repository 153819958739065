import React, { useCallback, useEffect, useMemo } from "react";
import Button from "../../atoms/button/Button";
import Input from "../../atoms/form/input/Input";
import useForm from "../../../hooks/useForm";
import API from "../../../services/api";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import DisplayField from "../../atoms/form/display-field/DisplayField";
import Form, { FormRequiredText } from "../../atoms/form/Form";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import Banner from "../../atoms/banner/Banner";
import ClientDropdown from "../../organisms/client-dropdown/ClientDropdown";
import CancelButton from "../../atoms/form/actions/CancelButton";

interface Props {
  assessment?: API.AssessmentResponse;
  onSave: (data: API.AssessmentRequest) => Promise<API.AssessmentResponse>;
  onCancel: () => void;
  onComplete: (data: API.AssessmentResponse) => void;
  mode: "edit" | "create";
}

function AssessmentForm({ assessment, onSave, onCancel, onComplete }: Props) {
  const [
    { formData, inProgress, fieldErrors, error, data, completed },
    { setField, setMultiSelectField, handleSubmit },
  ] = useForm(
    assessment
      ? {
          ...assessment,
        }
      : {
          clientIds: [],
          name: "",
          isModalOpen: false,
        },
  );

  const { groups, clientIds, name } = formData;

  const callback = useCallback(() => {
    const payload = {
      clientIds,
      name: name.trim(),
      ...(groups
        ? {
            groups: groups.map((group: API.AssessmentGroupResponse) => ({
              id: group.id,
              questions: group.questions
                ? group.questions.map((question) => question.id)
                : [],
            })),
          }
        : {}),
    };
    return onSave(payload);
  }, [clientIds, name, groups, onSave]);

  const validate = useCallback(() => {
    const errors = [];
    if (!clientIds.length) {
      errors.push({ field: "clientIds", message: "Select a client" });
    }
    if (!name) {
      errors.push({ field: "name", message: "Enter a name" });
    }
    return errors;
  }, [clientIds, name]);

  const onSubmit = useMemo(() => {
    return handleSubmit(callback, validate);
  }, [callback, handleSubmit, validate]);

  useEffect(() => {
    if (!!completed && !error) {
      onComplete(data);
    }
  }, [completed, error, onComplete, data]);

  return (
    <VerticalGroup full spread>
      <VerticalGroup wide>
        <Banner type="error" active={!!error} message={error?.message} />
        <InlineGroup evenWidthChildren block spaceBetweenElements={10}>
          <Form id="assessment-add-form" onSubmit={onSubmit}>
            <FormRequiredText />
            <ClientDropdown
              label="Select Clients *"
              isMulti
              clientIds={clientIds}
              onChange={setMultiSelectField("clientIds")}
              error={fieldErrors.clientIds}
            />
            <Input
              data-test="name"
              type="text"
              label="Assessment Name *"
              value={name}
              onChange={setField("name")}
              error={fieldErrors.name}
            />
            {!!assessment && (
              <DisplayField label="Assessment ID" value={assessment.id} />
            )}
          </Form>
        </InlineGroup>
      </VerticalGroup>
      <InlineGroup block spread className="mt-4">
        <CancelButton
          confirmModalProps={{
            confirmButtonText: "Save",
            inProgress: formData.inProgress,
            showModal: formData.formUpdated,
            onConfirm: onSubmit,
            onDiscard: onCancel,
          }}
          onClick={onCancel}
        />
        <Button
          data-test="save"
          type="submit"
          form="assessment-add-form"
          wide
          inProgress={inProgress}
        >
          Save
        </Button>
      </InlineGroup>
    </VerticalGroup>
  );
}

export default AssessmentForm;
