import { useCallback, useEffect, useMemo } from "react";
import API from "../services/api";
import useAPIRequest from "./useAPIRequest";

export const useOpenEvents = (opts?: { isLoadTest: boolean }) => {
  const callback = useCallback(() => {
    if (opts?.isLoadTest) {
      return API.getLoadTestEvents();
    }
    return API.getOpenEvents();
  }, [opts?.isLoadTest]);

  const [{ inProgress, data, error }, doAPIRequest] = useAPIRequest<
    API.EventResponseSummary[]
  >(callback, null);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  return useMemo(
    () => ({
      inProgress,
      data,
      error,
      refresh: doAPIRequest,
    }),
    [inProgress, data, error, doAPIRequest],
  );
};
