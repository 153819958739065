import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import Icon from "../../../atoms/icon/Icon";

import Clickable from "../../../atoms/clickable/Clickable";

interface Props {
  assessmentId: string;
  groupId: string;
  question: API.AssessmentQuestionResponse;
}

export const AssessmentCloneQuestion: React.FC<Props> = ({
  assessmentId,
  groupId,
  question,
}) => {
  const history = useHistory();

  const onClick = useCallback(
    (event) => {
      event.stopPropagation();
      history.push(
        `/assessments/${assessmentId}/groups/${groupId}/questions/${question.id}/clone`,
      );
    },
    [assessmentId, groupId, history, question],
  );

  return (
    <>
      <Clickable data-test={`clone-${question.id}`} onClick={onClick}>
        <Icon
          type="copy"
          tip={{
            content: (
              <>
                <b>Clone Question</b>
                <p>
                  Create a new question using the details <br /> of this
                  question as a starting point.
                </p>
              </>
            ),
            id: `${question.id}-copy`,
          }}
        />
      </Clickable>
    </>
  );
};
