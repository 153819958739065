import React from "react";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import Input from "../../../../../atoms/form/input/Input";
import Text from "../../../../../atoms/text/Text";

interface Props {
  question: API.AssessmentQuestionResponse;
  field: API.WrittenResponseFieldResponse;
  answer?: API.AssessmentAnswerResponse["answers"][0]["answer"];
  isAnswerMode: boolean;
}

export const WrittenResponseFieldPreview: React.FC<Props> = ({
  field,
  answer,
  question,
  isAnswerMode = false,
}) => {
  const hasAnswer = typeof answer !== "undefined";
  const value = typeof answer !== "undefined" ? answer[0].value : "";
  return (
    <VerticalGroup spaceBetweenElements={1} wide>
      <Input
        block
        containerBlock
        {...(hasAnswer ? { value } : {})}
        {...(isAnswerMode ? { disabled: true } : {})}
      />
      {field.config.hasCharacterLimit && (
        <Text size="xs" colour="secondaryDark4">
          Character limit {field.config.characterLimit}
        </Text>
      )}
      {question.assignMarks === true && (
        <Text size="xs" colour="secondaryDark4">
          {question.marks} points
        </Text>
      )}
    </VerticalGroup>
  );
};
