import React from "react";
import classNames from "classnames";

import "./Banner.scss";
import InlineGroup from "../inlinegroup/InlineGroup";
import Text from "../../atoms/text/Text";
import Clickable from "../clickable/Clickable";
import Icon from "../icon/Icon";

interface Props {
  active: boolean;
  message?: string;
  className?: string;
  type: "error" | "success";
  onClose?: () => void;
}

function Banner({ active, type, message, className, onClose }: Props) {
  return (
    <div
      className={classNames(
        "banner-wrapper",
        { hidden: !active },
        type,
        className,
      )}
    >
      <InlineGroup
        block
        verticalCenter
        spread
        className={classNames("banner-message", type)}
      >
        <Text data-test={`banner-${type}`}>{message}</Text>
        {onClose && (
          <Clickable onClick={onClose}>
            <Icon noMargin type="close" />
          </Clickable>
        )}
      </InlineGroup>
    </div>
  );
}

export default Banner;
