import React from "react";
import Card from "../../../atoms/card/Card";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Image from "../../../atoms/image/Image";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import image from "../../../../assets/results/excoforum.png";
import ArrowHeader from "../components/ArrowHeader";
import "../RoundResultsTablePage.scss";
import ResultsText from "../results-text/ResultsText";
import { DotAndText } from "../components/DotAndText";

interface Props {
  config: API.ReportingTemplateLayoutConfig;
}

const RoundResultsExcoInformation: React.FC<Props> = ({ config }) => {
  return (
    <RoundResultsContainer>
      <VerticalGroup full center wide spaceBetweenElements={4}>
        <InlineGroup fullHeight block>
          <Card wide sharp padding={0}>
            <InlineGroup fullHeight block>
              <VerticalGroup
                className="p-4"
                full
                style={{ width: "65%" }}
                spaceBetweenElements={4}
              >
                <VerticalGroup
                  wide
                  style={{ height: "50%", position: "relative" }}
                >
                  <Image
                    style={{ maxWidth: "100%" }}
                    src={image}
                    alt="Executive Forum"
                    height="100%"
                    width="100%"
                    cover
                  />
                  <InlineGroup
                    block
                    fullHeight
                    bgColour="blue"
                    style={{ opacity: 0.3, position: "absolute" }}
                  />
                </VerticalGroup>
                <VerticalGroup
                  wide
                  style={{ height: "50%" }}
                  spaceBetweenElements={4}
                >
                  <ArrowHeader
                    text="Instructions"
                    colour="primaryDark"
                    style={{ width: "45%", zIndex: 2 }}
                  />
                  <VerticalGroup spread full>
                    <DotAndText
                      num={1}
                      text="Read and discuss as a team, not individually"
                      dotColour="blue"
                    />
                    <DotAndText
                      num={2}
                      text="Ask for more information if you need it"
                      dotColour="yellow"
                    />
                    <DotAndText
                      num={3}
                      text="Thoroughly consider each of the options available"
                      dotColour="green"
                    />
                    <DotAndText
                      num={4}
                      text="Select one of the options (the first option is default selected)"
                      dotColour="lightgreen"
                    />
                  </VerticalGroup>
                </VerticalGroup>
              </VerticalGroup>
              <VerticalGroup
                className="p-8"
                full
                bgColour="primaryDark"
                style={{ width: "35%" }}
                spread
                center
              >
                <div />
                <ResultsText center colour="white" size="2xl" bold>
                  {config.heading ?? "Executive Forum"}
                </ResultsText>
                <ResultsText center colour="white" size="md">
                  (more details in the simulation interface)
                </ResultsText>
              </VerticalGroup>
            </InlineGroup>
          </Card>
        </InlineGroup>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsExcoInformation;
