import React, { useMemo } from "react";

import Dropdown from "../../atoms/form/input/Dropdown";
import { useReportingTemplateList } from "../../../hooks/useReportingTemplate";

interface SelectValue {
  id: string;
  value: string;
  label: string;
  template: API.ReportingTemplateResponse;
}

interface ReportingTemplateDropdownProps {
  reportingTemplateId?: string;
  clientId?: string;
  onTemplateSelected?: (template: API.ReportingTemplateResponse) => void;
  error?: string;
}

const ReportingTemplateDropdown: React.FC<ReportingTemplateDropdownProps> = ({
  reportingTemplateId,
  clientId,
  onTemplateSelected,
  error,
}) => {
  const { data, inProgress } = useReportingTemplateList(
    clientId ? [clientId] : [],
    clientId ? true : false,
  );

  const options: SelectValue[] = useMemo(() => {
    return data
      ? data.map((template) => ({
          id: template.id,
          value: template.id,
          label: template.name,
          template,
        }))
      : [];
  }, [data]);

  const currentSelected = useMemo(() => {
    if (reportingTemplateId) {
      return options.find((option) => option.id === reportingTemplateId);
    }
    return null;
  }, [reportingTemplateId, options]);

  return (
    <Dropdown
      isVerticalLayout
      error={error}
      selectProps={{
        isLoading: inProgress,
        onChange: (option: SelectValue) =>
          onTemplateSelected && onTemplateSelected(option.template),
        value: currentSelected,
        options,
        noOptionsMessage: () => "No Templates",
        placeholder: !data
          ? "Loading Templates..."
          : data.length === 0
            ? "No Templates"
            : "Select a template",
      }}
    />
  );
};

export default ReportingTemplateDropdown;
