import React, { useRef, useState } from "react";
import Card from "../../../../../atoms/card/Card";
import Text from "../../../../../atoms/text/Text";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import Button from "../../../../../atoms/button/Button";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import ConfirmModal from "../../../../../organisms/confirm-modal/ConfirmModal";
import Clickable from "../../../../../atoms/clickable/Clickable";
import Icon from "../../../../../atoms/icon/Icon";
import EditableText, {
  Handles as EditableTextHandles,
} from "../../../../../atoms/text/EditableText";
import AddScenarioModal from "./AddScenarioModal";
import EconomySettingDropDown from "../components/EconomySettingDropDown";
import API from "../../../../../../services/api";
import { dropDownVariables } from "../NewsPreview/contextMenuVariables";

interface Props {
  selectedScenario?: API.EconomicScenarioResponse | null;
  scenarios: API.EconomicScenarioResponse[];
  handleScenarioUpdated: (
    updatedScenario: API.EconomicScenarioUpdateRequest,
  ) => void;
  handleSelectScenario: (id: string | null) => void;
  handleDeleteScenario: (id: string) => void;
  handleScenarioAdded: (id: API.EconomicScenarioResponse) => void;
  handleUpdate: (scenario: {
    value: string;
    label: string;
    description?: string;
  }) => void;
}

const ScenarioSettings = ({
  selectedScenario,
  scenarios,
  handleScenarioUpdated,
  handleScenarioAdded,
  handleDeleteScenario,
  handleUpdate,
}: Props) => {
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [isAddScenarioModalOpen, setAddScenarioModalOpen] =
    useState<boolean>(false);
  const deleteButtonRef = useRef<HTMLButtonElement | null>(null);
  const addButtonRef = useRef<HTMLButtonElement | null>(null);
  const handleOpenAddScenarioModal = () => {
    if (addButtonRef.current) {
      addButtonRef.current!.blur();
    }
    setAddScenarioModalOpen(true);
  };
  const handleCloseAddScenarioModal = () => {
    setAddScenarioModalOpen(false);
  };

  const handleDeleteClick = () => {
    setConfirmModalOpen(true);
    if (deleteButtonRef.current) {
      deleteButtonRef.current!.blur();
    }
    setConfirmModalOpen(true);
  };

  const handleCloseModal = () => {
    setConfirmModalOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (!selectedScenario?.id) return;

    try {
      await API.deleteEconomicScenario(selectedScenario.id);
      handleDeleteScenario(selectedScenario.id);
    } catch (error) {
      console.error("Error deleting scenario:", error);
    }

    setConfirmModalOpen(false);
  };

  const handleDescriptionChange = async (newDescription: string) => {
    if (selectedScenario) {
      handleScenarioUpdated({
        name: selectedScenario.name,
        description: newDescription,
      });
    }
  };
  const ref = useRef<EditableTextHandles>(null);

  return (
    <VerticalGroup wide full spaceBetweenElements={2}>
      <Card wide>
        <VerticalGroup spread full>
          <VerticalGroup half spread spaceBetweenElements={2}>
            <Text size="lg" bold>
              Scenario Settings
            </Text>
            <VerticalGroup full verticalCenter spread>
              <VerticalGroup spaceBetweenElements={2}>
                <Text>Scenario</Text>
                <InlineGroup spaceBetweenElements={2}>
                  <EconomySettingDropDown
                    scenariosData={scenarios}
                    onChange={handleUpdate}
                    value={selectedScenario?.id}
                  />
                  <Button
                    ref={addButtonRef}
                    onClick={handleOpenAddScenarioModal}
                  >
                    Add
                  </Button>
                  <Button
                    ref={deleteButtonRef}
                    warning
                    onClick={handleDeleteClick}
                  >
                    Delete
                  </Button>
                </InlineGroup>
              </VerticalGroup>
            </VerticalGroup>
            <VerticalGroup spaceBetweenElements={2}>
              <Text>Description</Text>
              <InlineGroup verticalCenter spaceBetweenElements={2}>
                <EditableText
                  dropdownOptions={dropDownVariables}
                  ref={ref}
                  value={selectedScenario?.description ?? ""}
                  onChange={handleDescriptionChange}
                />{" "}
                <Clickable onClick={() => ref.current?.setIsEditing(true)}>
                  <Icon
                    noMargin
                    type="edit"
                    tip={{
                      content: "Edit Description",
                      id: "edit-description",
                    }}
                  />
                </Clickable>
              </InlineGroup>
            </VerticalGroup>
          </VerticalGroup>
        </VerticalGroup>
      </Card>

      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
        onCancel={handleCloseModal}
        title="Confirm Delete"
        description={`Are you sure you want to delete this scenario ${selectedScenario?.name}?`}
        confirmTitle="Delete"
        cancelTitle="Cancel"
        warning
      />
      <AddScenarioModal
        onScenarioCreated={handleScenarioAdded}
        isOpen={isAddScenarioModalOpen}
        onClose={handleCloseAddScenarioModal}
        onUpdateScenario={handleUpdate}
        scenariosData={scenarios}
      />
    </VerticalGroup>
  );
};

export default ScenarioSettings;
