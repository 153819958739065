import React from "react";
import Card from "../../../atoms/card/Card";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import ResultsText from "../results-text/ResultsText";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import ResultsTable from "../table/ResultsTable";
import { Td, Th, Tr } from "../../../atoms/table/Table";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Icon from "../../../atoms/icon/Icon";
import "./IntroWinningMetric.scss";
import EditableReportingHeading from "../components/EditableReportingHeading";
import ReportingSubHeading from "../components/ReportingSubHeading";

interface Props {
  data: ModelAPI.Reporting.WinningMetrics[];
}

const IntroWinningMetrics: React.FC<Props> = ({ data }) => {
  const filteredMetrics = data.filter((metric) => metric.percentage > 0);
  const isMoreThanSixMetrics = filteredMetrics.length > 6;

  return (
    <RoundResultsContainer>
      <Card fullHeight wide sharp>
        <VerticalGroup className="winning-metrics-slide" wide center full>
          <EditableReportingHeading defaultHeading="How Do I Win?" />
          <ReportingSubHeading subHeading="A leader board will be presented each round" />
          <VerticalGroup verticalCenter wide center full>
            <InlineGroup
              className=" winning-metrics-container"
              spread
              width="90%"
              minHeight={isMoreThanSixMetrics ? "98%" : "70%"}
            >
              <InlineGroup verticalCenter width="63%" block>
                <ResultsTable normalFontWeight alternateColourRows noborder>
                  <thead>
                    <Tr>
                      <Th className="metric-header-text">
                        <ResultsText
                          size={isMoreThanSixMetrics ? "md" : "lg"}
                          left
                        >
                          Measure
                        </ResultsText>
                      </Th>
                      <Th className="weighting-column">
                        <ResultsText size={isMoreThanSixMetrics ? "md" : "lg"}>
                          Weighting
                        </ResultsText>
                      </Th>
                    </Tr>
                  </thead>
                  <tbody>
                    {filteredMetrics.map((metric) => (
                      <Tr key={metric.type}>
                        <Td className="metric-body-text">
                          <ResultsText
                            size={isMoreThanSixMetrics ? "md" : "lg"}
                            className="p-1"
                            left
                          >
                            {metric.name}
                          </ResultsText>
                        </Td>
                        <Td className="weighting-column">
                          <ResultsText
                            size={isMoreThanSixMetrics ? "md" : "lg"}
                          >
                            {metric.percentage}%
                          </ResultsText>
                        </Td>
                      </Tr>
                    ))}
                  </tbody>
                </ResultsTable>
              </InlineGroup>
              <InlineGroup
                verticalCenter
                block
                className="target-icon-container"
              >
                <Icon fullSize type="target" />
              </InlineGroup>
            </InlineGroup>
          </VerticalGroup>
        </VerticalGroup>
      </Card>
    </RoundResultsContainer>
  );
};

export default IntroWinningMetrics;
