import React, { useCallback, useEffect, useState } from "react";
// import isEqual from 'lodash.isequal'
import Banner from "../../../atoms/banner/Banner";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import Ribbon from "../../../atoms/ribbon/Ribbon";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import NewsPreview from "./economicScenarioBuilder/NewsPreview/NewsPreview";
import GrowthLosses from "./economicScenarioBuilder/GrowthLosses/GrowthLosses";
import WFSCosts from "./economicScenarioBuilder/WFSCosts/WFSCosts";
import ScenarioSettings from "./economicScenarioBuilder/ScenarioSettings/ScenarioSettings";
import AdminAPI from "../../../../services/api";
import useScenarios from "../../../../hooks/useScenarios";
import { PreviewSettings } from "./economicScenarioBuilder/types";
import EditEconomyModal from "./economicScenarioBuilder/NewsPreview/EditEconomyModal";
import EditBullAndBearModal from "./economicScenarioBuilder/NewsPreview/EditBullAndBearModal";
import EditNewsConfigModal from "./economicScenarioBuilder/NewsPreview/EditNewsConfigModal";
import EditSettingsModal from "./economicScenarioBuilder/NewsPreview/EditSettingsModal";

const EconomicScenarioBuilder: React.FC = () => {
  const [tab, setTab] = useState<API.LabelTypes>("news_preview");
  const [round, setRound] = useState(1);
  const [newsPreviewRound, setNewsPreviewRound] = useState(4);
  const {
    data: scenarios,
    inProgress,
    completed,
    error,
    refresh,
  } = useScenarios();
  const [selectedScenario, setSelectedScenario] =
    useState<API.EconomicScenarioResponse | null>();
  const [editModal, setEditModal] = useState<boolean>(false);
  const [editNewsConfigModal, setEditNewsConfigModal] =
    useState<boolean>(false);
  const [editBullAndBearModal, setEditBullAndBearModal] =
    useState<boolean>(false);
  const [editSettingsModal, setEditSettingsModal] = useState<boolean>(false);
  const [previewMode, setPreviewMode] = useState("news");

  const [previewSettings, setPreviewSettings] = useState<PreviewSettings>({
    country: "australia",
    referenceRate: "BBSW",
    centralBank: "rba",
    regulator: "APRA",
    currency: "AUD",
    product: "CREDIT_CARDS",
  });

  useEffect(() => {
    if (scenarios && scenarios.length > 0 && !inProgress) {
      if (selectedScenario == null) {
        setSelectedScenario(scenarios[0]);
      } else {
        const scenario = scenarios.find(
          (scenario) => scenario.id === selectedScenario.id,
        );
        if (!scenario) {
          setSelectedScenario(scenarios[0]);
          return;
        }
        // if (!isEqual(scenario, selectedScenario)) {
        //   setSelectedScenario(scenario)
        // }
      }
    }
  }, [inProgress, scenarios, selectedScenario]);

  const handleDeleteScenario = useCallback(() => {
    setSelectedScenario(null);
    refresh();
  }, [refresh]);

  const handleScenarioUpdated = useCallback(
    async (updatedScenario: API.EconomicScenarioUpdateRequest) => {
      if (selectedScenario) {
        const payload = {
          name: updatedScenario.name,
          description: updatedScenario.description,
          wsfCosts: updatedScenario.wsfCosts,
          news: updatedScenario.news,
          growthAndLosses: updatedScenario.growthAndLosses,
          newsConfig: updatedScenario.newsConfig,
        };

        const response = await AdminAPI.updateEconomicScenario(
          selectedScenario.id,
          payload,
        );

        setSelectedScenario(response);
      }
    },
    [selectedScenario],
  );

  useEffect(() => {
    if (selectedScenario != null) {
      const growthAndLosses = selectedScenario.growthAndLosses.find(
        (g) => g.country === previewSettings.country,
      );
      if (!growthAndLosses) {
        handleScenarioUpdated({
          growthAndLosses: selectedScenario.growthAndLosses.concat({
            country: previewSettings.country,
            netRates: {
              HOME_LOANS: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              BUSINESS_LOANS: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              DEPOSITS: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              CREDIT_CARDS: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              WEALTH_MANAGEMENT: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              INSTITUTIONAL_BANKING: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              OFFSHORE_BANK: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            lossMultipliers: {
              HOME_LOANS: {
                proprietaryLoans: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                brokerLoans: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              },
              BUSINESS_LOANS: {
                goodQuality: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                mediumQuality: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                poorQuality: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              },
              CREDIT_CARDS: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              OFFSHORE_BANK: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              INSTITUTIONAL_BANKING: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
          }),
        });
      }
    }
  }, [handleScenarioUpdated, previewSettings.country, selectedScenario]);

  const handleSelectScenario = useCallback(
    (scenarioId: string | null) => {
      if (!scenarioId) {
        setSelectedScenario(null);
        return;
      }
      const scenario = scenarios?.find(
        (scenario) => scenario.id === scenarioId,
      );
      if (scenario) {
        setSelectedScenario(scenario);
      }
    },
    [scenarios],
  );

  const handleScenarioAdded = useCallback(
    (scenario: API.EconomicScenarioResponse) => {
      refresh();
      setSelectedScenario(scenario);
    },
    [refresh],
  );

  const handlePreviewSettingsChange = (
    field: keyof PreviewSettings,
    value: any,
  ) => {
    setPreviewSettings((prevSettings) => {
      const newSettings = { ...prevSettings, [field]: value };
      return newSettings;
    });
  };
  const handleEditEconomyClick = () => {
    setEditModal(true);
  };
  const handleEditBullAndBearClick = () => {
    setEditBullAndBearModal(true);
  };
  const handleEditNewsConfigClick = () => {
    setEditNewsConfigModal(true);
  };
  const handleSaveNews = async () => {
    setEditNewsConfigModal(false);
    setEditBullAndBearModal(false);
    setEditModal(false);
    refresh();
  };

  const handleEditSettingsClick = useCallback(() => {
    setEditSettingsModal(true);
  }, []);

  const handleDropdownChange =
    (field: keyof PreviewSettings) => (newValue: any) => {
      const valueToSet = newValue && newValue.value ? newValue.value : newValue;
      handlePreviewSettingsChange(field, valueToSet);
    };

  const growthAndLosses = selectedScenario
    ? selectedScenario.growthAndLosses.find(
        (g) => g.country === previewSettings.country,
      )
    : null;
  const handleRoundChange = (newRound: number) => {
    setRound(newRound);
  };
  const handleUpdate = (scenario: {
    value: string;
    label: string;
    description?: string;
  }) => {
    handleSelectScenario(scenario.value);
  };
  return (
    <VerticalGroup full className="py-2">
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {completed && (
        <VerticalGroup
          full
          spaceBetweenElements={1}
          wide
          style={{ overflowY: "auto" }}
        >
          <InlineGroup spaceBetweenElements={2} className="h-16 mb-2" block>
            <Ribbon
              first
              active={tab === "news_preview"}
              title="News Preview"
              onClick={() => setTab("news_preview")}
              className="h-16"
            />
            <Ribbon
              active={tab === "growth_&_losses"}
              title="Growth & Losses"
              onClick={() => setTab("growth_&_losses")}
              className="h-16"
            />
            <Ribbon
              active={tab === "wsf_costs"}
              title="WSF Costs"
              onClick={() => setTab("wsf_costs")}
              className="h-16"
            />
            <Ribbon
              last
              active={tab === "settings"}
              title="Settings"
              onClick={() => setTab("settings")}
              className="h-16"
            />
          </InlineGroup>
          <InlineGroup fullHeight block>
            {tab === "news_preview" && selectedScenario && (
              <NewsPreview
                setPreviewMode={setPreviewMode}
                previewMode={previewMode}
                handleEditBullAndBearClick={handleEditBullAndBearClick}
                handleEditNewsConfigClick={handleEditNewsConfigClick}
                newsPreviewRound={newsPreviewRound}
                scenarioId={selectedScenario?.id}
                roundId={newsPreviewRound - 3}
                previewSettings={previewSettings}
                setNewsPreviewRound={setNewsPreviewRound}
                handleEditEconomyClick={handleEditEconomyClick}
                handleEditSettingsClick={handleEditSettingsClick}
                selectedScenario={selectedScenario}
                handleUpdate={handleUpdate}
                scenarios={scenarios ?? []}
              />
            )}

            {tab === "growth_&_losses" &&
              growthAndLosses &&
              selectedScenario && (
                <GrowthLosses
                  handleDropdownChange={handleDropdownChange}
                  previewSettings={previewSettings}
                  selectedScenario={selectedScenario}
                  round={round}
                  growthAndLosses={growthAndLosses}
                  handleScenarioUpdated={handleScenarioUpdated}
                  handlePreviewSettingsChange={handlePreviewSettingsChange}
                  setRound={setRound}
                  handleRoundChange={handleRoundChange}
                  handleUpdate={handleUpdate}
                  scenarios={scenarios ?? []}
                />
              )}
            {tab === "wsf_costs" && selectedScenario && (
              <WFSCosts
                setRound={setRound}
                round={round}
                selectedScenario={selectedScenario}
                handleScenarioUpdated={handleScenarioUpdated}
                handleRoundChange={handleRoundChange}
                handleUpdate={handleUpdate}
                scenarios={scenarios ?? []}
              />
            )}

            {tab === "settings" && (
              <ScenarioSettings
                handleScenarioAdded={handleScenarioAdded}
                scenarios={scenarios ?? []}
                handleScenarioUpdated={handleScenarioUpdated}
                handleSelectScenario={handleSelectScenario}
                selectedScenario={selectedScenario}
                handleDeleteScenario={handleDeleteScenario}
                handleUpdate={handleUpdate}
              />
            )}
          </InlineGroup>
        </VerticalGroup>
      )}
      {editModal && selectedScenario !== undefined && (
        <EditEconomyModal
          isOpen={editModal}
          onClose={handleSaveNews}
          selectedRound={newsPreviewRound - 3}
          handleScenarioUpdated={handleScenarioUpdated}
          selectedScenario={selectedScenario}
        />
      )}
      {editBullAndBearModal && selectedScenario !== undefined && (
        <EditBullAndBearModal
          isOpen={editBullAndBearModal}
          onClose={handleSaveNews}
          selectedRound={newsPreviewRound - 3}
          handleScenarioUpdated={handleScenarioUpdated}
          selectedScenario={selectedScenario}
        />
      )}
      {editSettingsModal && selectedScenario != null && (
        <EditSettingsModal
          isOpen={editSettingsModal}
          onClose={() => setEditSettingsModal(false)}
          data={previewSettings}
          handleSettingsChange={setPreviewSettings}
        />
      )}
      {editNewsConfigModal && selectedScenario !== undefined && (
        <EditNewsConfigModal
          isOpen={editNewsConfigModal}
          onClose={handleSaveNews}
          selectedRound={newsPreviewRound - 3}
          handleScenarioUpdated={handleScenarioUpdated}
          selectedScenario={selectedScenario}
        />
      )}
    </VerticalGroup>
  );
};
export default EconomicScenarioBuilder;
