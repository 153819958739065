import React, { ReactNode, MouseEvent } from "react";
import Modal from "../../atoms/modal/Modal";

import "./StandardModal.scss";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import Button from "../../atoms/button/Button";
import ModalBody from "../../molecules/modal-body/ModalBody";

interface Props {
  isOpen: boolean;
  onClose: (event: MouseEvent<HTMLButtonElement>) => void;
  title?: string;
  description?: string | ReactNode;
  className?: string;
  "data-test"?: string;
}

function ErrorModal({
  isOpen,
  onClose,
  title,
  description,
  className,
  ...props
}: Props) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalBody
        type="error"
        title={title ?? "Oh no, something went wrong"}
        description={description}
        icon="ban"
        className={className}
        data-test={props["data-test"]}
      >
        <InlineGroup className="mt-4" spread reverse>
          <Button
            danger
            data-test="modal-error-confirm"
            onClick={onClose}
            large
          >
            Close
          </Button>
        </InlineGroup>
      </ModalBody>
    </Modal>
  );
}

export default ErrorModal;
