import React, { useEffect, useState, useRef, useCallback } from "react";
import Text from "../../../atoms/text/Text";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import EditableText, {
  Handles as EditableTextHandles,
} from "../../../atoms/text/EditableText";
import Clickable from "../../../atoms/clickable/Clickable";
import Icon from "../../../atoms/icon/Icon";
import { formatDuration, parseDuration } from "../utils/durationUtils";
import { LayoutResponse, LayoutUpdateRequest } from "../types";

interface Props {
  field: "durationEstimate" | "durationEstimateFirstRound";
  layout: LayoutResponse;
  onLayoutUpdate: (layoutId: string, data: LayoutUpdateRequest) => void;
}

export const DurationEstimateEdit: React.FC<Props> = ({
  field,
  layout,
  onLayoutUpdate,
}) => {
  const duration = Number(layout[field as keyof LayoutResponse] || 60);
  const [formattedDuration, setFormattedDuration] = useState(
    formatDuration(duration),
  );
  const [editableTextKey, setEditableTextKey] = useState(layout.id);
  const editableTextRef = useRef<EditableTextHandles>(null);

  useEffect(() => {
    const newDuration = duration;
    setFormattedDuration(formatDuration(newDuration));
  }, [duration]);

  const handleDurationChange = useCallback(
    (formattedDuration: string) => {
      const newDuration = parseDuration(formattedDuration);
      if (isNaN(newDuration) || newDuration < 0) {
        editableTextRef.current?.reset();
        return;
      }
      const formattedNewDuration = formatDuration(newDuration);
      setFormattedDuration(formattedNewDuration);
      setEditableTextKey(editableTextKey + 1);
      onLayoutUpdate(layout.id, {
        [field]: newDuration,
      });
    },
    [editableTextKey, field, layout.id, onLayoutUpdate],
  );

  return (
    <InlineGroup verticalCenter spaceBetweenElements={2} spread block>
      <InlineGroup>
        <Text>
          Duration {field === "durationEstimateFirstRound" ? "(1st Round)" : ""}
          :
        </Text>
      </InlineGroup>
      <InlineGroup spaceBetweenElements={2}>
        <EditableText
          key={editableTextKey}
          size="md"
          ref={editableTextRef}
          type="text"
          onChange={handleDurationChange}
          value={formattedDuration}
        />
        <Clickable onClick={() => editableTextRef.current?.setIsEditing(true)}>
          <Icon
            noMargin
            type="edit"
            tip={{ content: "Edit Duration", id: "edit-duration" }}
          />
        </Clickable>
      </InlineGroup>
    </InlineGroup>
  );
};
