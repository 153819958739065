import { IconType } from "../../../../atoms/icon/Icon";
export interface FinancialSummaryMecticItem
  extends API.FinancialSummaryMetrics {
  label: string;
  icon: IconType;
  type: API.FinancialSummaryType;
}

export const financialSummaryMetricItems: FinancialSummaryMecticItem[] = [
  {
    type: "NPAT_GROWTH",
    label: "NPAT Growth",
    icon: "npatGrowth",
  },
  {
    type: "NIM",
    label: "NIM",
    icon: "nim",
  },
  {
    type: "COF",
    label: "Cost of Funds",
    icon: "costOfFunds",
  },
  {
    type: "CTIR",
    label: "Cost To Income Ratio",
    icon: "costToIncome",
  },
  {
    type: "LIE",
    label: "Loan Impairment",
    icon: "loanImpairment",
  },
  {
    type: "CET1",
    label: "Capital Ratio",
    icon: "capitalRatio",
  },
  {
    type: "TSR",
    label: "Total Shareholder Return",
    icon: "shareHolderReturn",
  },
  {
    type: "ROE",
    label: "Return On Equity",
    icon: "returnOnEquity",
  },
  {
    type: "Complaints",
    label: "Complaints",
    icon: "customerComplaints",
  },
  {
    type: "EmployeeEngagement",
    label: "Employee Engagement",
    icon: "employeeEngagement",
  },
  {
    type: "RemediationAndFines",
    label: "Remediation And Fines",
    icon: "remediationAndFines",
  },
];
