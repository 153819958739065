import { useCallback, useMemo } from "react";
import API from "../services/api";
import useAPIRequest from "./useAPIRequest";

interface UseAuthToken {
  inProgress: boolean;
  data: API.TabletOtp | null;
  error: Error | null;
  refresh: () => void;
}

export const useAuthToken = (): UseAuthToken => {
  const callback = useCallback(() => {
    return API.getTabletOtp();
  }, []);

  const [{ inProgress, data, error }, doAPIRequest] =
    useAPIRequest<API.TabletOtp>(callback, null);

  return useMemo(
    () => ({
      inProgress,
      data,
      error,
      refresh: doAPIRequest,
    }),
    [inProgress, data, error, doAPIRequest],
  );
};
