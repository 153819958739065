import React from "react";
import SimpleModal from "../../../organisms/standard-modal/SimpleModal";
import Container from "../../../atoms/page/Page";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Text from "../../../atoms/text/Text";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import SquareIconButton from "../../../molecules/square-icon-button/SquareIconButton";

interface AddSectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onInsertTextClick: () => void;
  onInsertImageClick: () => void;
  onInsertVideoClick: () => void;
  onInsertRowClick: () => void;
}

const AddSectionModal: React.FC<AddSectionModalProps> = ({
  isOpen,
  onClose,
  onInsertTextClick,
  onInsertImageClick,
  onInsertVideoClick,
  onInsertRowClick,
}) => {
  return (
    <SimpleModal
      className="add-section-container"
      showCloseButton
      isOpen={isOpen}
      onClose={onClose}
      size="small"
    >
      <Container fit>
        <Text bold>Select Section Type</Text>
        <VerticalGroup
          spaceBetweenElements={6}
          className="pt-4 pb-4"
          wide
          spread
        >
          <InlineGroup spaceBetweenElements={6} evenWidthChildren block>
            <SquareIconButton
              icon="font"
              label="Text"
              onClick={onInsertTextClick}
            />
            <SquareIconButton
              icon="image"
              label="Image"
              onClick={onInsertImageClick}
            />
          </InlineGroup>
          <InlineGroup spaceBetweenElements={6} evenWidthChildren block>
            <SquareIconButton
              icon="videoCamera"
              label="Video"
              onClick={onInsertVideoClick}
            />

            <SquareIconButton
              icon="row"
              label="Row"
              onClick={onInsertRowClick}
            />
          </InlineGroup>
        </VerticalGroup>
      </Container>
    </SimpleModal>
  );
};

export default AddSectionModal;
