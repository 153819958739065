import { IconType } from "../../../../atoms/icon/Icon";

export interface SummaryMetric {
  type: API.SummaryType;
  percentage: number;
  icon: IconType;
}

export interface SummaryMetricItem extends SummaryMetric {
  label: string;
}

export const summaryMetricItems: SummaryMetricItem[] = [
  {
    type: "TSR",
    label: "Shareholder Return",
    percentage: 0,
    icon: "shareHolderReturn",
  },
  {
    type: "NPS",
    label: "Customer Satisfaction",
    percentage: 0,
    icon: "customerSatisfaction",
  },
  {
    type: "ROE",
    label: "Return On Equity",
    percentage: 0,
    icon: "returnOnEquity",
  },
  {
    type: "Customers",
    label: "Customers",
    percentage: 0,
    icon: "employeeEngagement",
  },
  {
    type: "LIE",
    label: "Loan Impairment",
    percentage: 0,
    icon: "loanImpairment",
  },
  {
    type: "Rating",
    label: "Credit Rating",
    percentage: 0,
    icon: "creditRating",
  },
  {
    type: "EmployeeEngagement",
    label: "Employee Engagement",
    percentage: 0,
    icon: "employeeEngagement",
  },
  {
    type: "Community",
    label: "Community Trust",
    percentage: 0,
    icon: "communityTrust",
  },
  {
    type: "RiskIncidents",
    label: "Risk Incidents",
    percentage: 0,
    icon: "risk",
  },
  {
    type: "Complaints",
    label: "Customer Complaints",
    percentage: 0,
    icon: "customerComplaints",
  },
  {
    type: "RegulatorActions",
    label: "Regulator Sanctions",
    percentage: 0,
    icon: "regulatorSanctions",
  },
];
