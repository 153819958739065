import React from "react";
import Image, { Props as ImageProps } from "../../atoms/image/Image";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import SimpleModal from "../standard-modal/SimpleModal";
import "./image-preview-modal.scss";

interface Props extends ImageProps {
  isOpen: boolean;
  onClose: () => void;
  src: string | undefined;
  alt: string;
  showCloseButton?: boolean;
  className?: string;
  "data-test"?: string;
}

export const ImageModal: React.FC<Props> = ({
  src,
  alt,
  isOpen,
  onClose,
  ...props
}) => {
  return (
    <SimpleModal
      isOpen={isOpen}
      isMinimal
      isScrollable
      size="large"
      onClose={onClose}
      showCloseButton
    >
      <InlineGroup spread center block>
        <Image className="br-2 .image" shadow src={src} alt={alt} {...props} />
      </InlineGroup>
    </SimpleModal>
  );
};
