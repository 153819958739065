import React from "react";
import TeamPositions from "../positions/TeamPositions";
import { TeamsWithColours } from "../RoundResultsPage";
import ResultsLineChart, { DataPoint } from "./ResultsLineChart";
import { useResultsContext } from "../context/context";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import { createLineChartDot } from "./LineChartDot";
import useChartStyles from "../../../../hooks/useChartStyles";

interface TeamWithColoursAndDot extends TeamsWithColours {
  dot?: any;
}

interface Props {
  data: DataPoint[];
  teams: TeamWithColoursAndDot[];
  formatter?: (val: number) => string | number;
  toolTipFormatter?: (val: number) => string | number;
  yAxisFormatter?: (val: number) => string | number;
  maxYAxisvalue: number;
  minYAxisvalue: number;
  numberOfTicks: number;
  teamPositions?: ModelAPI.Reporting.TeamPosition[];
  tooltipOrdering?: "highest" | "lowest";
  shouldRenderLines: boolean;
}

const ResultsTeamLineChart: React.FC<Props> = ({
  data,
  teams,
  formatter,
  yAxisFormatter,
  maxYAxisvalue,
  minYAxisvalue,
  numberOfTicks,
  teamPositions,
  tooltipOrdering,
  toolTipFormatter,
  shouldRenderLines,
}) => {
  const chartStyles = useChartStyles();
  const context = useResultsContext();

  const lines = teams.map((t) => ({
    name: t.teamName,
    key: `team${t.teamId}`,
    colour: t.colour,
    dot: t.dot
      ? t.dot
      : createLineChartDot(
          chartStyles.circleRadius,
          chartStyles.strokeWidth,
          teams,
        ),
  }));
  return (
    <VerticalGroup wide full center style={{ marginRight: "30px" }}>
      <ResultsLineChart
        data={data}
        formatter={formatter}
        yAxisFormatter={yAxisFormatter}
        maxYAxisvalue={maxYAxisvalue}
        minYAxisvalue={minYAxisvalue}
        numberOfTicks={numberOfTicks}
        lines={lines}
        tooltipOrdering={tooltipOrdering}
        toolTipFormatter={toolTipFormatter}
        shouldRenderLines={shouldRenderLines}
      />
      {!context.hideTeamPositions &&
        teamPositions &&
        teamPositions.length > 0 && (
          <InlineGroup>
            <TeamPositions
              teams={teams}
              teamPositions={teamPositions}
              shouldRender={shouldRenderLines}
            />
          </InlineGroup>
        )}
    </VerticalGroup>
  );
};

export default ResultsTeamLineChart;
