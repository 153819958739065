import React, { useMemo } from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../../tables/TableWithSections";
import convertWSFRoundResult from "../utils/convertWSFRoundResult";
interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["wholesaleFundingMovements"];
  fields: Array<{ field: string; name: string }>;
}

const NewWSFIssued: React.FC<Props> = ({ fields, data, configuration }) => {
  const { numberOfHistoricRounds, numberOfRounds, numberOfPostGameRounds } =
    configuration;

  const values = useMemo(() => {
    const results: Array<{ [field: string]: number }> = [];
    const totalYears =
      numberOfHistoricRounds + numberOfRounds + numberOfPostGameRounds;
    for (let year = 0; year <= totalYears; year++) {
      const result: { [field: string]: number } = {
        ...convertWSFRoundResult({
          totalYears,
          rounds: data[year].newWholesaleFundingIssued.rounds,
        }),
        total: data[year].newWholesaleFundingIssued.total,
      };
      results.push(result);
    }
    return results;
  }, [numberOfHistoricRounds, numberOfPostGameRounds, numberOfRounds, data]);

  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <TableWithSections
          header="New Wholesale Funding Issued"
          hasRoundZero
          data={[
            {
              fields: [...fields.map((f) => f.field), "total"],
              totalFields: ["total"],
              values,
            },
          ]}
          fieldFormatter={(field: string) =>
            fields.find((f) => f.field === field)?.name ?? null
          }
        />
      </VerticalGroup>
    </>
  );
};

export default NewWSFIssued;
