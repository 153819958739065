import React, { useState } from "react";
import SimpleModal from "../../../../../organisms/standard-modal/SimpleModal";
import Text from "../../../../../atoms/text/Text";
import Button from "../../../../../atoms/button/Button";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import "./AddScenarioModal.scss";
import Icon from "../../../../../atoms/icon/Icon";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import Input from "../../../../../atoms/form/input/Input";
import API from "../../../../../../services/api";
import InlineError from "../../../../../atoms/inlineerror/InlineError";
import Container from "../../../../../atoms/page/Page";
import ClickableBox from "../../../../../molecules/clickable-box/ClickableBox";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onUpdateScenario: (scenario: {
    value: string;
    label: string;
    description?: string;
  }) => void;

  scenariosData: API.EconomicScenarioResponse[] | null;
  onScenarioCreated: (id: API.EconomicScenarioResponse) => void;
}

const AddScenarioModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onUpdateScenario,
  onScenarioCreated,
  scenariosData,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [isNewBlank, setIsNewBlank] = useState(false);
  const [newScenarioName, setNewScenarioName] = useState<string>("");
  const [newScenarioDescription, setNewScenarioDescription] =
    useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleCreateScenario = async () => {
    try {
      const newScenarioRequest = {
        name: newScenarioName,
        description: newScenarioDescription,
      };

      const newScenario = await API.createEconomicScenario(newScenarioRequest);

      setNewScenarioName("");
      setNewScenarioDescription("");
      onScenarioCreated(newScenario);
      handleClose();
      return newScenario;
    } catch (error) {
      setIsError(true);
      setErrorMessage("" + error);
    }
  };
  const handleNewBlankClick = () => {
    setIsNewBlank(true);
  };
  const handleClose = () => {
    setIsNewBlank(false);
    onClose();
    setNewScenarioName("");
    setNewScenarioDescription("");

    setIsError(false);
    setErrorMessage("");
    setSelectedOption(null);
  };
  const handleBackClick = () => {
    setIsNewBlank(false);
    setSelectedOption(null);
    setNewScenarioName("");
    setNewScenarioDescription("");
  };

  const handleCreateDuplicate = async () => {
    setIsError(false);
    setErrorMessage("");

    if (selectedOption) {
      const selectedScenario = scenariosData?.find(
        (scenario) => scenario.id === selectedOption,
      );

      if (selectedScenario) {
        const duplicateScenarioData = {
          name: newScenarioName,
          description: newScenarioDescription,
        };

        try {
          const duplicatedScenario = await API.duplicateEconomicScenario(
            selectedScenario.id,
            duplicateScenarioData,
          );

          const updatedScenario = {
            value: duplicatedScenario.id,
            label: duplicatedScenario.name,
            description: duplicatedScenario.description,
          };
          onUpdateScenario(updatedScenario);
          onScenarioCreated(duplicatedScenario);
          handleClose();
        } catch (error) {
          setIsError(true);
          setErrorMessage("" + error);
        }
      }
    }
  };
  return (
    <SimpleModal
      isOpen={isOpen}
      size="large"
      onClose={handleClose}
      showCloseButton={true}
    >
      <Container fit className="simulation-edit">
        <VerticalGroup spaceBetweenElements={4}>
          {isNewBlank ? (
            <VerticalGroup full wide spaceBetweenElements={5}>
              <InlineGroup verticalCenter>
                <Icon size={6} type="leftChevron" onClick={handleBackClick} />
                <Text size="lg" bold>
                  Add Scenario
                </Text>{" "}
              </InlineGroup>
              <VerticalGroup full wide spaceBetweenElements={1}>
                <InlineError active={isError} message={errorMessage} />

                <Input
                  label="Name "
                  autoFocus
                  type="text"
                  value={newScenarioName}
                  onChange={(e) => setNewScenarioName(e.target.value)}
                />
              </VerticalGroup>
              <VerticalGroup full wide spaceBetweenElements={1}>
                <Input
                  label="Description"
                  type="textbox"
                  large
                  block
                  value={newScenarioDescription}
                  onChange={(e) => setNewScenarioDescription(e.target.value)}
                />
              </VerticalGroup>
              <InlineGroup block spread>
                <Button onClick={handleClose} outline light large wide>
                  Cancel
                </Button>
                <Button onClick={handleCreateScenario} large wide>
                  Create
                </Button>
              </InlineGroup>
            </VerticalGroup>
          ) : selectedOption ? (
            <VerticalGroup full wide spaceBetweenElements={5}>
              <InlineGroup verticalCenter>
                <Icon size={6} type="leftChevron" onClick={handleBackClick} />
                <Text size="lg" bold>
                  Duplicate Scenario
                </Text>
              </InlineGroup>
              <VerticalGroup full wide spaceBetweenElements={1}>
                <InlineError active={isError} message={errorMessage} />

                <Input
                  label="Name"
                  type="text"
                  autoFocus
                  value={newScenarioName}
                  onChange={(e) => setNewScenarioName(e.target.value)}
                />
              </VerticalGroup>
              <VerticalGroup full wide spaceBetweenElements={1}>
                <Input
                  label="Description"
                  type="textbox"
                  large
                  block
                  value={newScenarioDescription}
                  onChange={(e) => setNewScenarioDescription(e.target.value)}
                />
              </VerticalGroup>
              <InlineGroup block spread>
                <Button onClick={handleClose} outline light large wide>
                  Cancel
                </Button>
                <Button onClick={handleCreateDuplicate} large wide>
                  Create
                </Button>
              </InlineGroup>
            </VerticalGroup>
          ) : (
            <>
              <Text size="lg" bold>
                Add Scenario
              </Text>
              <Button outline block wide large onClick={handleNewBlankClick}>
                + New Blank{" "}
              </Button>
              <VerticalGroup spaceBetweenElements={2}>
                <Text bold>Based on existing</Text>
                <div className="scenarioGrid">
                  {scenariosData ? (
                    scenariosData.map((scenario, index) => (
                      <ClickableBox
                        key={index}
                        onClick={() => {
                          setSelectedOption(scenario.id);
                          setNewScenarioName(`${scenario.name}-copy`);
                          setNewScenarioDescription(scenario.description || "");
                        }}
                        active={selectedOption === scenario.id}
                      >
                        <Text bold>{scenario.name}</Text>
                        <Text>{scenario.description}</Text>
                      </ClickableBox>
                    ))
                  ) : (
                    <div>No scenarios available</div>
                  )}
                </div>
              </VerticalGroup>
            </>
          )}
        </VerticalGroup>
      </Container>
    </SimpleModal>
  );
};

export default AddScenarioModal;
