import React, { useState, useEffect } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import CustomizedLegend from "./CustomLegend";
import { getYAxisDataV2 } from "../../../../results/charts/yaxis";
import CustomTick from "./CustomTick";
import CustomTooltip from "./CustomToolTip";
interface CurrentRoundSpreads {
  [key: string]: string;
}
interface LoanChartProps {
  data: any[];
  lineConfigurations: LineConfiguration[];
  legendDesign?: "growthLosses" | "costs";
  legendOrientation?: "horizontal" | "vertical";
  height?: number;
  currentRoundSpreads?: { [rating: string]: number };
  selectedReferenceRate?: number;
  formatter?: (val: number) => string | number;
  legendFormatter?: (val: number) => string | number;
  toolTipFormatter?: (val: number) => string | number;
  currentRound?: number;
  onRoundChange?: (newRound: number) => void;
}

interface LineConfiguration {
  name?: string;
  dataKey: string;
  strokeColor: string;
  activeDot?: boolean;
}

const DESKTOP_BREAKPOINT = 1600;

const EconomicScenarioLineChart: React.FC<LoanChartProps> = ({
  data,
  lineConfigurations,
  legendDesign = "growthLosses",
  legendOrientation = "horizontal",
  currentRoundSpreads = {},
  height = 300,
  selectedReferenceRate = 0,
  formatter,
  toolTipFormatter,
  legendFormatter,
  currentRound = 1,
  onRoundChange,
}) => {
  const [chartWidth, setChartWidth] = useState(window.innerWidth * 0.4);
  const chartMargin =
    legendOrientation === "horizontal"
      ? { left: -10, top: 8, right: 15 }
      : { left: -10, top: 8, right: 0 };

  const chartConfiguration = getYAxisDataV2({
    pastRound1: true,
    data: data,
  });

  useEffect(() => {
    const handleResize = () => {
      const isDesktop = window.innerWidth >= DESKTOP_BREAKPOINT;

      if (legendOrientation === "vertical") {
        setChartWidth(window.innerWidth * (isDesktop ? 0.7 : 0.6));
      } else {
        setChartWidth(window.innerWidth * (isDesktop ? 0.5 : 0.4));
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [legendOrientation]);
  let legendProps: any = {};
  if (legendOrientation === "horizontal") {
    legendProps = {
      layout: "horizontal",
      align: "center",
    };
  } else {
    legendProps = {
      layout: "vertical",
      align: "right",
    };
  }

  const formattedCurrentRoundSpreads = Object.keys(currentRoundSpreads).reduce(
    (acc: CurrentRoundSpreads, key) => {
      const value = currentRoundSpreads[key];
      if (typeof value === "number") {
        acc[key] = legendFormatter
          ? legendFormatter(value).toString()
          : formatter
            ? formatter(value).toString()
            : value.toString();
      }
      return acc;
    },
    {} as CurrentRoundSpreads,
  );

  const handleTickClick = (value: string) => {
    if (onRoundChange) {
      const newRound = parseInt(value, 10);
      if (!isNaN(newRound)) {
        onRoundChange(newRound);
      }
    }
  };
  return (
    <LineChart
      width={chartWidth}
      height={height}
      data={data}
      margin={chartMargin}
    >
      <CartesianGrid strokeDasharray="1 0" vertical={false} />
      <XAxis
        tick={(tickProps) => (
          <CustomTick {...tickProps} onClick={handleTickClick} />
        )}
        dataKey="name"
      />
      <YAxis
        tickFormatter={formatter ? (tick) => formatter(tick) : (tick) => tick}
        domain={[chartConfiguration.minValue, chartConfiguration.maxValue]}
        tickCount={chartConfiguration.numberOfTicks + 1}
      />
      <Tooltip
        content={
          <CustomTooltip
            toolTipFormatter={toolTipFormatter}
            formatter={formatter}
          />
        }
      />
      <Legend
        content={
          <CustomizedLegend
            currentRoundSpreads={formattedCurrentRoundSpreads}
            payload={[]}
            style={legendDesign}
            selectedReferenceRate={selectedReferenceRate}
          />
        }
        {...legendProps}
      />

      {lineConfigurations.map((line) => (
        <Line
          key={line.dataKey}
          name={line.name}
          type="monotone"
          dataKey={line.dataKey}
          stroke={line.strokeColor}
          dot={(dotProps) => {
            const displayStyle =
              dotProps.payload.name.trim() === currentRound.toString()
                ? {}
                : { display: "none" };
            return (
              <circle
                cx={dotProps.cx}
                cy={dotProps.cy}
                r={5}
                fill={line.strokeColor}
                style={displayStyle}
              />
            );
          }}
          strokeWidth={1.5}
          activeDot={{ r: 6 }}
        />
      ))}
    </LineChart>
  );
};

export default EconomicScenarioLineChart;
