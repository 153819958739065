import React, { useCallback } from "react";
import { useParams } from "react-router";
import { FieldErrors, Setters, State } from "../../../../../../hooks/useForm";
import Input from "../../../../../atoms/form/input/Input";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import { FileDropZone } from "../../../../../organisms/file-dropzone/FileDropZone";
import api from "../../../../../../services/api";
import { useState } from "react";
import { useEffect } from "react";
import DisplayField from "../../../../../atoms/form/display-field/DisplayField";
import { ImageWithPreviewModal } from "../../../../../organisms/image-preview-modal/ImageWithPreviewModal";
import IconButton from "../../../../../molecules/iconbutton/IconButton";
import InlineError from "../../../../../atoms/inlineerror/InlineError";
import { toWebpBlobs } from "../../../../../../lib/images";

interface Props {
  question: State["formData"];
  setField: Setters["setField"];
  setFieldSimple: Setters["setFieldSimple"];
  setFieldRaw: Setters["setFieldRaw"];
  fieldErrors?: FieldErrors;
}

export const DEFAULT_IMAGE_FIELD = {
  type: "image",
  config: {
    type: "image",
    nickName: "",
  },
};

export const ImageFormDetails: React.FC<Props> = ({
  question,
  setFieldRaw,
}) => {
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [isUploading, setUploading] = useState(false);
  const { assessmentId } = useParams<{ assessmentId: string }>();
  const [field, setFieldValue] = useState<API.ImageFieldRequest>(() => {
    return (
      question.fields[0] || {
        ...DEFAULT_IMAGE_FIELD,
      }
    );
  });

  useEffect(() => {
    setFieldRaw("fields", [field]);
    setFieldRaw("question", field.config.nickName);
  }, [setFieldRaw, field]);

  const changeNickname = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue((field) => ({
        ...field,
        config: {
          ...field.config,
          nickName: e.target.value,
        },
      }));
    },
    [],
  );

  const onDrop = useCallback(
    (files: File[]) => {
      (async () => {
        setUploading(true);
        setUploadError(null);

        const newFile = await toWebpBlobs(files[0]);
        if (newFile && newFile.size > 6000000) {
          setUploadError(
            "File size must <= 10M, please try again with a smaller image",
          );
          setUploading(false);
          return;
        }

        if (newFile) {
          try {
            const image = await api.uploadAssessmentImage(
              assessmentId,
              newFile,
            );
            setFieldValue((field) => {
              return {
                ...field,
                config: {
                  ...field.config,
                  imageId: image.id,
                },
              };
            });
            setUploading(false);
            setUploadError(null);
          } catch (e) {
            setUploading(false);
            setUploadError("something went wrong when uploading file");
          }
        }
      })();
    },
    [assessmentId],
  );

  return (
    <VerticalGroup>
      <Input
        type="text"
        label="Nickname (optional)"
        helpDescription="A nickname is used to identify an image or provide a short description of it."
        helpTitle="Nickname"
        value={field.config.nickName}
        onChange={changeNickname}
      />
      {field.config.imageId && (
        <InlineGroup className="mt-4">
          <DisplayField
            stretch
            label="Uploaded File Preview"
            value={
              <InlineGroup spaceBetweenElements={4}>
                <ImageWithPreviewModal
                  className="br-2"
                  shadow
                  heightSize={20}
                  src={api.getAssessmentImageUrl(
                    assessmentId,
                    field.config.imageId,
                  )}
                  alt={field.config.nickName}
                />

                <IconButton
                  danger
                  icon="trash"
                  wide={false}
                  light={false}
                  onClick={() => {
                    setFieldValue((field) => {
                      return {
                        ...field,
                        config: {
                          ...field.config,
                          imageId: undefined,
                        },
                      };
                    });
                  }}
                />
              </InlineGroup>
            }
          />
        </InlineGroup>
      )}
      <InlineGroup block>
        <FileDropZone
          required={false}
          className="width-100-percent"
          onDrop={onDrop}
          accept="image/*"
          dropzonePlaceholderMain="Drag your file here"
          dropzonePlaceholderSecondary="or click to upload from your computer"
          isLoading={isUploading}
        />
      </InlineGroup>
      {uploadError && <InlineError active message={uploadError} />}
    </VerticalGroup>
  );
};
