import { useCallback, useEffect, useMemo } from "react";
import API from "../services/api";
import useAPIRequest from "./useAPIRequest";

export const useSpecialProjects = () => {
  const callback = useCallback(() => {
    return API.getSpecialProjects();
  }, []);

  const [{ inProgress, data, error }, doAPIRequest] =
    useAPIRequest<API.SpecialProjectsResponse>(callback, null);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  return useMemo(
    () => ({
      inProgress,
      data,
      error,
      refresh: doAPIRequest,
    }),
    [inProgress, data, error, doAPIRequest],
  );
};
