import React, { useCallback, useContext, useEffect } from "react";

import API from "../../../../../../services/api";
import Banner from "../../../../../atoms/banner/Banner";
import Form from "../../../../../atoms/form/Form";
import { EventContext } from "../../context";
import useForm from "../../../../../../hooks/useForm";
import StepContainer from "../../components/StepContainer";
import EventOptions from "../../components/EventOptions";
import Card from "../../../../../atoms/card/Card";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import SelectSimulation from "./SelectSimulation";
import SelectReportingTemplate from "./SelectReportingTemplate";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import DisplayField from "../../../../../atoms/form/display-field/DisplayField";
import { getEventUpdatePayload } from "../../utils";
import SelectIntroTemplate from "./SelectIntroTemplate";

const Simulation: React.FC = () => {
  const { config, updateConfig, assignOnSubmitFunction, updateFormStatus } =
    useContext(EventContext);
  const [
    {
      formData: {
        id,
        brandingId,
        simulationId,
        clientId,
        simulation,
        reportingTemplateId,
        introTemplateId,
        reportingTemplate,
        introTemplate,
      },
      error,
      fieldErrors,
      inProgress,
      formUpdated,
    },
    { handleSubmit, setFieldRaw },
  ] = useForm({ ...config });

  const onSimulationChanged = useCallback(
    (simulation: API.SimulationResponse) => {
      setFieldRaw("simulationId", simulation ? simulation.id : null);
      setFieldRaw("simulation", simulation);
    },
    [setFieldRaw],
  );

  const onUnselectSimulation = useCallback(() => {
    setFieldRaw("simulationId", null);
    setFieldRaw("simulation", null);
  }, [setFieldRaw]);

  const onTemplateChanged = useCallback(
    (reportingTemplate: API.ReportingTemplateResponse) => {
      setFieldRaw(
        "reportingTemplateId",
        reportingTemplate ? reportingTemplate.id : null,
      );
      setFieldRaw("reportingTemplate", reportingTemplate);
    },
    [setFieldRaw],
  );

  const onUnselectTemplate = useCallback(() => {
    setFieldRaw("reportingTemplateId", null);
    setFieldRaw("reportingTemplate", null);
  }, [setFieldRaw]);

  const onIntroTemplateChanged = useCallback(
    (template: API.IntroTemplateResponse) => {
      setFieldRaw("introTemplateId", template ? template.id : null);
      setFieldRaw("introTemplate", template);
    },
    [setFieldRaw],
  );

  const onUnselectIntroTemplate = useCallback(() => {
    setFieldRaw("introTemplateId", null);
    setFieldRaw("introTemplate", null);
  }, [setFieldRaw]);

  const onSubmit = useCallback(async () => {
    if (config?.id) {
      const payload = getEventUpdatePayload(config, {
        simulationId,
        reportingTemplateId,
        introTemplateId,
      });
      const response = await API.editEvent(config.id, payload);
      updateConfig({
        ...config,
        simulationId: response.simulationId,
        simulation,
        reportingTemplateId: response.reportingTemplateId,
        reportingTemplate,
        introTemplateId: response.introTemplateId,
        introTemplate,
        schedule: response.schedule,
      });
      return response;
    }
  }, [
    config,
    simulation,
    simulationId,
    reportingTemplateId,
    reportingTemplate,
    introTemplateId,
    introTemplate,
    updateConfig,
  ]);

  const validate = useCallback(() => {
    const errors = [];
    if (!simulationId) {
      errors.push({ field: "simulationId", message: "Select a simulation" });
    }
    if (!reportingTemplateId) {
      errors.push({
        field: "reportingTemplateId",
        message: "Select a reporting template",
      });
    }
    if (!introTemplateId) {
      errors.push({
        field: "introTemplateId",
        message: "Select an intro template",
      });
    }
    return errors;
  }, [simulationId, reportingTemplateId, introTemplateId]);

  useEffect(() => {
    assignOnSubmitFunction(handleSubmit(onSubmit, validate));
  }, [assignOnSubmitFunction, handleSubmit, onSubmit, validate]);

  useEffect(() => {
    updateFormStatus({
      inProgress: inProgress,
      formUpdated: formUpdated,
    });
  }, [updateFormStatus, inProgress, formUpdated]);

  return (
    <StepContainer>
      <Banner type="error" active={!!error} message={error?.message} />
      <InlineGroup spread verticalCenter block>
        <h2>Simulation Settings</h2>
        <EventOptions />
      </InlineGroup>
      <Form wide>
        <Card wide padding={16}>
          <VerticalGroup spaceBetweenElements={4}>
            <SelectSimulation
              clientId={clientId}
              simulationId={simulationId}
              onSimulationSelected={onSimulationChanged}
              onUnselectSimulation={onUnselectSimulation}
              error={fieldErrors.simulationId}
            />
            <SelectReportingTemplate
              clientId={clientId}
              simulationId={simulationId}
              brandingId={brandingId}
              eventId={id}
              maxRounds={simulation?.generalSetup?.rounds ?? 6}
              reportingTemplate={reportingTemplate}
              onTemplateSelected={onTemplateChanged}
              onUnselectTemplate={onUnselectTemplate}
              error={fieldErrors.reportingTemplateId}
            />
            <SelectIntroTemplate
              clientId={clientId}
              simulationId={simulationId}
              brandingId={brandingId}
              eventId={id}
              introTemplate={introTemplate}
              onTemplateSelected={onIntroTemplateChanged}
              onUnselectTemplate={onUnselectIntroTemplate}
              error={fieldErrors.introTemplateId}
            />
          </VerticalGroup>
          {simulationId ? (
            <>
              <hr className="mt-12" />
              <InlineGroup spread evenWidthChildren className="pt-4">
                <VerticalGroup center spaceBetweenElements={4}>
                  <DisplayField
                    compact
                    isVerticalLayout
                    label="Simulation Id"
                    value={id}
                  />
                </VerticalGroup>
                <VerticalGroup center spaceBetweenElements={4}>
                  <DisplayField
                    compact
                    isVerticalLayout
                    label="Simulation Name"
                    value={simulation?.generalSetup?.name}
                  />
                </VerticalGroup>
              </InlineGroup>
            </>
          ) : null}
        </Card>
      </Form>
    </StepContainer>
  );
};

export default Simulation;
