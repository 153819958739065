import React, { useCallback, useMemo, useState, useEffect } from "react";
import Button from "../../../atoms/button/Button";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Container from "../../../atoms/page/Page";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import { useResultsContext } from "../context/context";
import SimpleModal from "../../../organisms/standard-modal/SimpleModal";
import Text from "../../../atoms/text/Text";
import Input from "../../../atoms/form/input/Input";

import "./DebugInfoModal.scss";
import api from "../../../../services/api";
import InlineError from "../../../atoms/inlineerror/InlineError";

interface InfoSectionProps {
  title: string;
  data: Record<string, any>;
}

const InfoSection: React.FC<InfoSectionProps> = ({ title, data }) => (
  <VerticalGroup className="debug-info-section">
    <Text className="debug-info-section-title" bold size="lg">
      {title}:
    </Text>
    {Object.entries(data).map(([key, value]) => (
      <div key={key} className="debug-info-item">
        <span className="debug-info-item-key">{key}:</span> {value}
      </div>
    ))}
  </VerticalGroup>
);

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

const DebugInfoModal: React.FC<Props> = ({ onClose, isOpen }) => {
  const context = useResultsContext();
  const [description, setDescription] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!isOpen) {
      setDescription("");
      setIsSubmitted(false);
      setIsSubmitting(false);
      setError(null);
    }
  }, [isOpen]);

  const collectDebugInfo = (userInput: string): API.DebugInfo => {
    const now = new Date();
    const pageLoadTime =
      window.performance.timing.loadEventEnd -
      window.performance.timing.navigationStart;

    return {
      userInput,
      screenSize: {
        width: window.screen.width,
        height: window.screen.height,
        pixelRatio: window.devicePixelRatio,
      },
      windowSize: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      deviceInfo: {
        platform: navigator.platform,
        userAgent: navigator.userAgent,
        deviceType:
          window.innerWidth < 768
            ? "mobile"
            : window.innerWidth < 1024
              ? "tablet"
              : "desktop",
        language: navigator.language,
      },
      browserInfo: {
        name: navigator.userAgent.split(" ")[0],
        version: navigator.userAgent.split(" ")[1],
        zoom: Math.round((window.outerWidth / window.innerWidth) * 100),
      },
      pageInfo: {
        currentUrl: window.location.href,
        referrer: document.referrer,
        loadTime: pageLoadTime,
      },
      sessionInfo: {
        timestamp: now.toISOString(),
        timeOnPage: Math.floor(performance.now() / 1000),
      },
    };
  };

  const handleSendDebugInfo = useCallback(async () => {
    if (!context.eventId) {
      setError("Missing event ID. Please try again later.");
      return;
    }

    try {
      setIsSubmitting(true);
      setError(null);
      const debugInfo = collectDebugInfo(description);
      await api.sendDebugInfo(context.eventId, debugInfo);
      setIsSubmitted(true);
    } catch (error) {
      setError("Failed to send debug info. Please try again.");
      setIsSubmitted(false);
    } finally {
      setIsSubmitting(false);
    }
  }, [context.eventId, description]);

  const getDisplayInfo = useCallback(() => {
    return {
      screen: {
        width: window.screen.width,
        height: window.screen.height,
        availWidth: window.screen.availWidth,
        availHeight: window.screen.availHeight,
        colorDepth: window.screen.colorDepth,
        pixelDepth: window.screen.pixelDepth,
        orientation: window.screen.orientation
          ? window.screen.orientation.type
          : "N/A",
      },
      window: {
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
        outerWidth: window.outerWidth,
        outerHeight: window.outerHeight,
      },
      other: {
        devicePixelRatio: window.devicePixelRatio,
        zoomLevel: Math.round((window.outerWidth / window.innerWidth) * 100),
        userAgent: navigator.userAgent,
        cast:
          context.cast !== undefined
            ? context.cast.toString()
            : "Not available",
      },
    };
  }, [context.cast]);

  const displayInfo = useMemo(() => getDisplayInfo(), [getDisplayInfo]);
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <>
      <SimpleModal
        preventPropagation
        isOpen={isOpen}
        onClose={handleClose}
        size="medium"
      >
        <VerticalGroup spaceBetweenElements={4}>
          <Text bold size="xl">
            Debug Info
          </Text>
          <Container fit>
            <div className="debug-info-modal">
              <InlineGroup>
                <div style={{ flex: 1 }}>
                  <InfoSection title="Screen" data={displayInfo.screen} />
                </div>
                <div className="debug-vertical-divider" />
                <InlineGroup style={{ flex: 1 }}>
                  <InfoSection title="Window" data={displayInfo.window} />
                </InlineGroup>
              </InlineGroup>
              <div className="debug-horizontal-divider" />
              <InfoSection title="Other" data={displayInfo.other} />
            </div>
            <div className="debug-horizontal-divider" />
            <div className="report-info-section">
              <VerticalGroup wide spaceBetweenElements={3}>
                <Text bold size="lg">
                  Report Issue:
                </Text>
                <Input
                  maxHeight={56}
                  block
                  type="textbox"
                  label="Please describe the issue you're experiencing in detail. Include what you were trying to do when the problem occurred and any error messages you saw."
                  isVerticalLayout
                  placeholder="Enter issue here"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  disabled={isSubmitted}
                />
                <InlineError active={!!error} message={error || ""} block />
                {isSubmitted && (
                  <InlineGroup
                    border={{
                      style: "solid",
                      width: "1px",
                      color: "white",
                      rounded: "8px",
                    }}
                    className="p-2"
                    bgColour="darkGreen"
                    block
                    center
                  >
                    <Text bold colour="white">
                      Issue reported successful
                    </Text>
                  </InlineGroup>
                )}
              </VerticalGroup>
            </div>
          </Container>
          <InlineGroup block spread>
            <Button onClick={handleClose}>Close</Button>
            <Button
              onClick={handleSendDebugInfo}
              disabled={isSubmitted || isSubmitting}
            >
              {isSubmitting
                ? "Sending..."
                : isSubmitted
                  ? "Report Sent"
                  : "Report Issue"}
            </Button>
          </InlineGroup>
        </VerticalGroup>
      </SimpleModal>
    </>
  );
};

export default DebugInfoModal;
