import React from "react";
import {
  EventMapping,
  mapInitialTabletMappingForEvent,
} from "../utils/mapInitialTabletMappingForEvent";
import { sortParticipants } from "../../../../../lib/participantSort";

export interface TabletConfigState {
  data: API.TabletMappingResponse | null;
  selectedEventId: string | null;
  eventData: Record<string, API.EventResponse>;
  tabletDataPerEvent: Record<string, EventMapping>;
}

/**
 * Action
 */
interface UpdateData {
  type: "UpdateData";
  payload: API.TabletMappingResponse;
}

interface UpdateSelectedEventId {
  type: "UpdateSelectedEventId";
  payload: string | null;
}

interface UpdateEventData {
  type: "UpdateEventData";
  payload: API.EventResponse;
}

interface UpdateTeamColour {
  type: "UpdateTeamColour";
  payload: { teamNumber: number; colour: API.TabletColour };
}

interface UpdateTabletsForEvent {
  type: "UpdateTabletsForEvent";
  payload: API.TabletDetailResponse[];
}

interface Reset {
  type: "Reset";
}

interface ClearEvent {
  type: "ClearEvent";
  payload: string;
}

export type Actions =
  | UpdateData
  | UpdateSelectedEventId
  | UpdateEventData
  | UpdateTeamColour
  | UpdateTabletsForEvent
  | Reset
  | ClearEvent;

/**
 * Reducer
 */

export const reducer: React.Reducer<TabletConfigState, Actions> = (
  state,
  action,
) => {
  switch (action.type) {
    case "UpdateData":
      return {
        ...state,
        data: action.payload,
      };
    case "UpdateSelectedEventId":
      return {
        ...state,
        selectedEventId: action.payload,
      };
    case "UpdateEventData":
      if (!state.data) {
        return state;
      }
      return {
        ...state,
        eventData: {
          ...state.eventData,
          [action.payload.id]: {
            ...action.payload,
            teamsCount:
              action.payload.simulation?.playersSetup?.teams ??
              action.payload.teamsCount,
            participants: sortParticipants(action.payload.participants ?? []),
          },
        },
        tabletDataPerEvent: {
          ...state.tabletDataPerEvent,
          [action.payload.id]: mapInitialTabletMappingForEvent(
            action.payload,
            state.data,
          ),
        },
      };
    case "UpdateTeamColour": {
      if (!state.selectedEventId) {
        return state;
      }
      if (!state.tabletDataPerEvent[state.selectedEventId]) {
        return state;
      }
      return {
        ...state,
        tabletDataPerEvent: {
          ...state.tabletDataPerEvent,
          [state.selectedEventId]: {
            ...state.tabletDataPerEvent[state.selectedEventId],
            teamColours: state.tabletDataPerEvent[
              state.selectedEventId
            ].teamColours.map((colour, index) =>
              index === action.payload.teamNumber - 1
                ? action.payload.colour
                : colour,
            ),
          },
        },
      };
    }
    case "UpdateTabletsForEvent": {
      if (!state.selectedEventId) {
        return state;
      }
      if (!state.tabletDataPerEvent[state.selectedEventId]) {
        return state;
      }
      return {
        ...state,
        tabletDataPerEvent: {
          ...state.tabletDataPerEvent,
          [state.selectedEventId]: {
            ...state.tabletDataPerEvent[state.selectedEventId],
            tablets: action.payload,
          },
        },
      };
    }
    case "Reset": {
      return {
        data: null,
        selectedEventId: null,
        eventData: {},
        tabletDataPerEvent: {},
      };
    }
    case "ClearEvent": {
      const eventData = { ...state.eventData };
      delete eventData[action.payload];
      const tabletDataPerEvent = { ...state.tabletDataPerEvent };
      delete tabletDataPerEvent[action.payload];
      return {
        data: {
          teams: (state.data?.teams ?? []).filter(
            (t) => t.eventId !== action.payload,
          ),
          tablets: (state.data?.tablets ?? []).map((t) =>
            t.eventId === action.payload
              ? { ...t, participantId: null, eventId: null, email: null }
              : t,
          ),
        },
        selectedEventId:
          state.selectedEventId === action.payload
            ? null
            : state.selectedEventId,
        eventData,
        tabletDataPerEvent,
      };
    }
    default:
      return state;
  }
};
