import React, { useMemo, useCallback } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import ResultsTable from "../table/ResultsTable";
import { TeamsWithColours } from "../RoundResultsPage";
import { percentage, thousandSeparator } from "../../model/utils/formatters";

import "../RoundResultsTablePage.scss";
import classNames from "classnames";
import { useResultsContext } from "../context/context";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import ResultsText from "../results-text/ResultsText";
import DynamicResultsText from "../results-text/DynamicResultsText";
import EditableReportingHeading from "../components/EditableReportingHeading";

interface Props {
  teams: TeamsWithColours[];
  dealRoom: ModelAPI.Reporting.DealRoom;
}

const getSortedBidsFromHighestToLowest = (
  data: ModelAPI.Reporting.DealRoom,
) => {
  return data.bids.filter((b) => b.bid > 0).sort((a, b) => b.bid - a.bid);
};

export const getMaxClicks = (data: ModelAPI.Reporting.DealRoom) => {
  return getSortedBidsFromHighestToLowest(data).length + 1;
};

const RoundResultsDealRoomTable: React.FC<Props> = ({ teams, dealRoom }) => {
  const context = useResultsContext();

  const { nonBids, sortedBidsFromHighestToLowest } = useMemo(() => {
    const nonBids = dealRoom.bids.filter((b) => b.bid === 0);
    const sortedBidsFromHighestToLowest =
      getSortedBidsFromHighestToLowest(dealRoom);
    return { nonBids, sortedBidsFromHighestToLowest };
  }, [dealRoom]);

  const hasReturns = useMemo(() => {
    return dealRoom.bids.some((b) => b.return != null);
  }, [dealRoom.bids]);

  const maxClicks = getMaxClicks(dealRoom);
  const noBids = sortedBidsFromHighestToLowest.length === 0;

  const { showInfoAndOutcome, showRow } = useMemo(() => {
    const showInfoAndOutcome = context.noAnimations || context.clicks >= 1;
    const showRow = context.noAnimations
      ? 0
      : Math.max(0, sortedBidsFromHighestToLowest.length - context.clicks + 1);
    return { showInfoAndOutcome, showRow };
  }, [
    context.clicks,
    context.noAnimations,
    sortedBidsFromHighestToLowest.length,
  ]);

  const onNext = useCallback(() => {
    if (context.clicks < maxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context, maxClicks]);

  return (
    <RoundResultsContainer onNext={onNext}>
      <VerticalGroup spaceBetweenElements={4} full center wide>
        <EditableReportingHeading
          defaultHeading="Deal Room"
          suffix=" Results"
        />
        <InlineGroup
          spread
          fullHeight
          block
          className={classNames("executive-decisions-table", {
            animate: !context.noAnimations,
          })}
        >
          {noBids ? (
            <InlineGroup fullHeight block center verticalCenter>
              <DynamicResultsText size="2xl" bold>
                NO BIDS!
              </DynamicResultsText>
            </InlineGroup>
          ) : (
            <>
              <VerticalGroup
                style={{ width: "50%" }}
                className={classNames({
                  "show-options": showInfoAndOutcome,
                  "pr-4": showInfoAndOutcome,
                  "no-show-options": !showInfoAndOutcome,
                })}
              >
                <ResultsText
                  size="xl"
                  bold
                  className="executive-decisions-options-title pb-4"
                >
                  {dealRoom.name}
                </ResultsText>
                <VerticalGroup className="pb-4">
                  <DynamicResultsText size="lg" isHtml>
                    {dealRoom.outcome}
                  </DynamicResultsText>
                </VerticalGroup>
              </VerticalGroup>
              <VerticalGroup style={{ width: "52%", height: "100%" }}>
                <ResultsTable id={dealRoom.id}>
                  <thead>
                    <tr>
                      <th>BANK</th>
                      <th>BID</th>
                      {hasReturns && <th>RETURN</th>}
                    </tr>
                  </thead>
                  <tbody
                    onClick={() => context.addClick()}
                    className="clickable"
                  >
                    {sortedBidsFromHighestToLowest.map((bid, i) => {
                      const teamForTable = teams.find(
                        (t) => t.teamId === bid.teamId,
                      );

                      return (
                        <tr
                          key={i}
                          className={
                            context.noAnimations || showRow <= i
                              ? "show-bids"
                              : "no-show-options"
                          }
                        >
                          <td style={{ color: `${teamForTable?.colour}` }}>
                            <ResultsText size="xl">
                              {" "}
                              {teamForTable?.teamName}
                            </ResultsText>
                          </td>
                          <td>
                            <ResultsText size="xl">
                              {thousandSeparator(bid.bid)}
                            </ResultsText>
                          </td>
                          {hasReturns && (
                            <td>
                              <ResultsText size="xl">
                                {percentage(bid.return ?? 0, 1)}
                              </ResultsText>
                            </td>
                          )}
                        </tr>
                      );
                    })}

                    {nonBids.map((bid, i) => {
                      const teamForTable = teams.find(
                        (t) => t.teamId === bid.teamId,
                      );

                      return (
                        <tr key={i}>
                          <td>
                            <ResultsText
                              size="xl"
                              bold
                              color={`${teamForTable?.colour}`}
                            >
                              {teamForTable?.teamName}
                            </ResultsText>
                          </td>
                          <td>
                            <ResultsText size="xl">No Bid</ResultsText>
                          </td>
                          {hasReturns && <td>-</td>}
                        </tr>
                      );
                    })}
                  </tbody>
                </ResultsTable>
                {!context.hidePagination && (
                  <InlineGroup
                    block
                    center
                    className="executive-decisions-card"
                  >
                    <ResultsText
                      size="md"
                      center
                      colour="primaryDark"
                      className="mt-2"
                    >
                      Click to reveal more information.
                    </ResultsText>
                  </InlineGroup>
                )}
              </VerticalGroup>
            </>
          )}
        </InlineGroup>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsDealRoomTable;
