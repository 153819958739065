import React, { useCallback, useMemo } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import { TeamsWithColours } from "../RoundResultsPage";
import ResultsTeamLineChart from "../charts/ResultsTeamLineChart";
import { getYAxisDataV2 } from "../charts/yaxis";
import { thousandSeparator } from "../../model/utils/formatters";
import ChartCard from "../components/ChartCard";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import { useResultsContext } from "../context/context";

interface Props {
  pastRound1: boolean;
  currencyDenomination: string;
  teams: TeamsWithColours[];
  incrementalFTECosts: ModelAPI.Reporting.DataAndPositions;
  parentKey: number;
}

const RoundResultsIncrementalFTECosts: React.FC<Props> = ({
  pastRound1,
  currencyDenomination,
  teams,
  incrementalFTECosts,
  parentKey,
}) => {
  const { minValue, maxValue, numberOfTicks } = getYAxisDataV2({
    pastRound1,
    data: incrementalFTECosts.data,
  });
  const context = useResultsContext();
  const maxClicks = useMemo(
    () => (context.allowPresentationMode || context.readSyncState ? 1 : 0),
    [context.allowPresentationMode, context.readSyncState],
  );
  const onNext = useCallback(() => {
    if (context.clicks < maxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context, maxClicks]);

  return (
    <RoundResultsContainer onNext={onNext}>
      <VerticalGroup full center wide>
        <ChartCard
          heading="Incremental FTE Costs"
          currencyDenomination={currencyDenomination}
        >
          <ResultsTeamLineChart
            data={incrementalFTECosts.data}
            teams={teams.filter((t) => !t.isCpuTeam)}
            minYAxisvalue={minValue}
            maxYAxisvalue={maxValue}
            numberOfTicks={numberOfTicks}
            formatter={thousandSeparator}
            teamPositions={incrementalFTECosts.teamPositions}
            key={parentKey}
            shouldRenderLines={context.clicks === maxClicks}
          />
        </ChartCard>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsIncrementalFTECosts;
