import React, { useCallback } from "react";
import API from "../../../../../services/api";
import useAPIRequest from "../../../../../hooks/useAPIRequest";
import ChallengeModal from "../../../../organisms/challenge-modal/ChallengeModal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  message: API.BroadcastMessage;
}

function DeleteBroadcastMessage({
  isOpen,
  onComplete,
  onClose,
  message,
}: Props) {
  const callback = useCallback(
    () => API.deleteBroadcastMessage(message.id),
    [message],
  );

  const [{ inProgress, error }, doAPIRequest] = useAPIRequest(callback, null);

  const handleConfirm = () => {
    doAPIRequest(onComplete);
  };

  return (
    <ChallengeModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      challengeQuestion="This action is irreversible. Please type the word DELETE below to delete this user."
      challengeAnswer="DELETE"
      challengeAnswerWarning="Please type the word DELETE in CAPS to delete the user."
      title={`Delete broadcast message`}
      confirmTitle="Delete"
      error={error && error.message}
      warning
    />
  );
}

export default DeleteBroadcastMessage;
