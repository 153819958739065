import React from "react";

import classNames from "classnames";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";

import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Text from "../../../atoms/text/Text";

import "./Sidebar.scss";
import Clickable from "../../../atoms/clickable/Clickable";
import { Module } from "./Configuration";
interface Props {
  selected: string;
  onChange: (moduleId: string) => void;
  simulationSettings: Module[];
  slideSettings: Module[];
  interfaceSettings: Module[];
  tools: Module[];
}

interface MenuItemProps {
  onChange: (moduleId: string) => void;
  selected: string;
  item: Module;
}

const MenuItem: React.FC<MenuItemProps> = ({ onChange, selected, item }) => (
  <Clickable
    onClick={() => onChange(item.id)}
    className={classNames("configurationsidebarmodule", {
      active: item.id === selected,
    })}
    key={item.id}
  >
    <VerticalGroup spread wide spaceBetweenElements={2}>
      <InlineGroup verticalCenter center>
        <Text size="sm" medium>
          {item.name}
        </Text>
      </InlineGroup>
    </VerticalGroup>
  </Clickable>
);

function ConfigurationSidebar({
  selected,
  onChange,
  simulationSettings,
  slideSettings,
  interfaceSettings,
  tools,
}: Props) {
  return (
    <div className="configurationsidebar">
      <VerticalGroup wide>
        <InlineGroup className="pl-2 pr-4 pt-4 pb-2">
          <Text colour="green" size="lg" center>
            SIMULATION SETTINGS
          </Text>
        </InlineGroup>
        <VerticalGroup wide>
          {simulationSettings.map((m) => (
            <MenuItem
              key={m.id}
              onChange={onChange}
              selected={selected}
              item={m}
            />
          ))}
        </VerticalGroup>
        <InlineGroup className="pl-2 pr-4 pt-4 pb-2">
          <Text colour="green" size="lg" center>
            SLIDE SETTINGS
          </Text>
        </InlineGroup>
        <VerticalGroup wide>
          {slideSettings.map((m) => (
            <MenuItem
              key={m.id}
              onChange={onChange}
              selected={selected}
              item={m}
            />
          ))}
        </VerticalGroup>
        <InlineGroup className="pl-2 pr-4 pt-4 pb-2">
          <Text colour="green" size="lg" center>
            INTERFACE SETTINGS
          </Text>
        </InlineGroup>
        <VerticalGroup wide>
          {interfaceSettings.map((m) => (
            <MenuItem
              key={m.id}
              onChange={onChange}
              selected={selected}
              item={m}
            />
          ))}
        </VerticalGroup>
        <InlineGroup className="pl-2 pr-4 pt-4 pb-2">
          <Text colour="green" size="lg" center>
            TOOLS
          </Text>
        </InlineGroup>
        <VerticalGroup wide>
          {tools.map((m) => (
            <MenuItem
              key={m.id}
              onChange={onChange}
              selected={selected}
              item={m}
            />
          ))}
        </VerticalGroup>
      </VerticalGroup>
    </div>
  );
}

export default ConfigurationSidebar;
