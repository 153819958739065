import React from "react";
import classNames from "classnames";

import "./Label.scss";
import { ThemeColours } from "../../../../types/theme";
import DOMPurify from "dompurify";

interface Props extends React.LabelHTMLAttributes<HTMLLabelElement> {
  className?: string;
  label?: string;
  labelFor?: string;
  colour?: ThemeColours;
  bold?: boolean;
  isHtml?: boolean;
  noShrink?: boolean;
}

function Label({
  children,
  className,
  labelFor,
  colour,
  bold,
  noShrink,
  isHtml = false,
}: Props) {
  const colourClass = colour ? `colour-${colour}` : null;
  if (isHtml) {
    const sanitizedHtml = DOMPurify.sanitize(String(children));
    return (
      <label
        className={classNames("label", colourClass, className, {
          bold,
          noShrink,
        })}
        htmlFor={labelFor}
        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
      />
    );
  }
  return (
    <label
      className={classNames("label", colourClass, className, {
        bold,
        noShrink,
      })}
      htmlFor={labelFor}
    >
      {children}
    </label>
  );
}

export default Label;
