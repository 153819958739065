import React from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../../tables/TableWithSections";
import pluckResultsByType from "../../utils/pluckResultsByType";
import { formatValue, percentage } from "../../utils/formatters";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["wholesaleFundingCosts"];
  fields: Array<{ field: string; name: string }>;
}

const fieldMap: { [key: string]: string } = {
  centralBankFacility: "CBF",
  wsf1: "1 Year",
  wsf2: "2 Year",
  wsf3: "3 Year",
  wsf4: "4 Year",
  wsf5: "5 Year",
};

const formatter = (
  val: number,
  section: string | undefined,
  field: string,
): string | null => {
  if (field === "referenceRate") {
    return percentage(val);
  }
  return formatValue(val, 0);
};

const TotalWholesaleFundingCosts: React.FC<Props> = ({ data }) => {
  const values = pluckResultsByType(data, "totals");

  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <TableWithSections
          hasRoundZero
          header="Total Wholesale Funding Costs"
          data={[
            {
              name: "Reference Rate Costs",
              fields: [
                "averageBalance",
                "referenceRate",
                "wholesaleFundingCostsReferenceRateOnly",
              ],
              totalFields: ["wholesaleFundingCostsReferenceRateOnly"],
              values: pluckResultsByType(values, "referenceRateCosts"),
              fieldFormatter: (field: string) => fieldMap[field],
            },
            {
              name: "Spread Costs",
              fields: [
                "centralBankFacilityCosts",
                "marketSpreadCosts",
                "totalSpreadCosts",
              ],
              totalFields: ["totalSpreadCosts"],
              values: pluckResultsByType(values, "spreadCosts"),
              fieldFormatter: (field: string) => fieldMap[field],
            },
            {
              isTotalsSection: true,
              fields: ["totalWholesaleFundingInterestCosts"],
              values,
            },
          ]}
          formatter={formatter}
        />
      </VerticalGroup>
    </>
  );
};

export default TotalWholesaleFundingCosts;
