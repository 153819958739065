import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Login from "./components/pages/login/Login";
import Verify from "./components/pages/login/Verify";
import ForgotPassword from "./components/pages/login/ForgotPassword";
import ResetPassword from "./components/pages/login/ResetPassword";
import ErrorBoundary from "./components/pages/error/Error";
import RoundResultsPublished from "./components/pages/results/RoundResultsPublished";
import RoundResultsCast from "./components/pages/results/RoundResultsCast";
import { useUserActions } from "./context/userContext";

function Routes() {
  const { logout } = useUserActions();
  return (
    <BrowserRouter basename={process.env.REACT_APP_PATH_PREFIX}>
      <Switch>
        <Route
          path="/results/games/:eventId/:publishedResultsId"
          exact
          component={RoundResultsPublished}
        />
        <Route
          path="/results/games/:eventId/cast/:castKey"
          exact
          component={RoundResultsCast}
        />
        <Route
          render={() => (
            <ErrorBoundary logout={logout}>
              <Switch>
                <Route path="/" exact component={Login} />
                <Route path="/verify" exact component={Verify} />
                <Route
                  path="/forgot-password"
                  exact
                  component={ForgotPassword}
                />
                <Route path="/reset-password" exact component={ResetPassword} />
                <Redirect to="/" />
              </Switch>
            </ErrorBoundary>
          )}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
