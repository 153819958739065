import React from "react";

import "./CurlyBrace.scss";

interface Props {
  width: number;
}

const CurlyBrace: React.FC<Props> = ({ width }) => {
  return (
    <div className="curly-brace" style={{ width }}>
      <div className="brace brace-left"></div>
      <div className="brace brace-right"></div>
    </div>
  );
};

export default CurlyBrace;
