import React from "react";
import { useBranding } from "../../../hooks/useBrandings";
import IframePreview from "../iframe-preview/IframePreview";
import Text from "../../atoms/text/Text";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";

const HOST = process.env.REACT_APP_GAME_URL || "";
const PATHNAME = "/branding-preview";

interface BrandingPreviewWrappedProps {
  buttonBackgroundColour: string;
  buttonTextColour: string;
  clientName: string;
  gameName: string;
  headingBackgroundColour: string;
  headingTextColour: string;
  mainBackgroundColour: string;
  mainTextColour: string;
  logoUrl?: string;
  maxContainerHeight: number;
  minIframeHeight?: number;
  minIframeWidth?: number;
  isFixedHeight?: boolean;
}

const buildUrl = ({
  buttonBackgroundColour,
  buttonTextColour,
  clientName,
  gameName,
  headingBackgroundColour,
  headingTextColour,
  mainBackgroundColour,
  mainTextColour,
  logoUrl,
}: BrandingPreviewWrappedProps) => {
  const url = new URL(HOST);

  url.pathname = PATHNAME;

  url.searchParams.set("buttonBackgroundColour", buttonBackgroundColour);
  url.searchParams.set("buttonTextColour", buttonTextColour);
  url.searchParams.set("clientName", clientName);
  url.searchParams.set("gameName", gameName);
  url.searchParams.set("headingBackgroundColour", headingBackgroundColour);
  url.searchParams.set("headingTextColour", headingTextColour);
  url.searchParams.set("mainBackgroundColour", mainBackgroundColour);
  url.searchParams.set("mainTextColour", mainTextColour);
  url.searchParams.set("logo", logoUrl ?? "");

  return url.href;
};

export const BrandingPreviewWrapped: React.FC<BrandingPreviewWrappedProps> = (
  props,
) => {
  const targetSrc = buildUrl({ ...props });

  return (
    <VerticalGroup center verticalCenter spaceBetweenElements={2} wide flexAuto>
      <Text size="sm">{props.gameName}</Text>
      <IframePreview {...{ ...props, targetSrc }} />
    </VerticalGroup>
  );
};

interface BrandingPreviewProps {
  branding: API.BrandingResponse;
  maxContainerHeight: number;
  minIframeHeight?: number;
}

const BrandingPreview: React.FC<BrandingPreviewProps> = ({
  maxContainerHeight,
  branding,
  minIframeHeight,
}) => (
  <BrandingPreviewWrapped
    buttonBackgroundColour={branding.buttonBackgroundColour}
    buttonTextColour={branding.buttonTextColour}
    clientName={branding.clientName}
    gameName={branding.gameName}
    headingBackgroundColour={branding.headingBackgroundColour}
    headingTextColour={branding.headingTextColour}
    mainBackgroundColour={branding.mainBackgroundColour}
    mainTextColour={branding.mainTextColour}
    maxContainerHeight={maxContainerHeight}
    logoUrl={branding.brandingImage?.imageUrl}
    minIframeHeight={minIframeHeight}
  />
);

interface AsyncBrandingPreviewProps {
  brandingId: string;
  maxContainerHeight: number;
}
export const AsyncBrandingPreview: React.FC<AsyncBrandingPreviewProps> = ({
  brandingId,
  maxContainerHeight,
}) => {
  const { data: branding } = useBranding(brandingId);

  return (
    branding && (
      <BrandingPreview
        branding={branding}
        maxContainerHeight={maxContainerHeight}
      />
    )
  );
};

export default BrandingPreview;
