import React, { useState, useEffect, useCallback, useMemo } from "react";
import Banner from "../../atoms/banner/Banner";
import BonusTimeScreen from "./BonusTimeScreen";
import CloseRoundScreen from "./CloseRoundScreen";
import FacilitatedRoundTimerScreen from "./FacilitatedRoundTimerScreen";
import ScheduledRoundTimerScreen from "./ScheduledRoundTimerScreen";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import GameProgressBar from "./GameProgressBar";
import GameDetails from "./GameDetails";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import Text from "../../atoms/text/Text";
import Icon from "../../atoms/icon/Icon";
import "./GameStatus.scss";
import MobileGameDetails from "./MobileGameDetails";
import { getParticipantLoginLink } from "./utils/getParticpantLoginLink";
import ErrorModal from "../../organisms/standard-modal/ErrorModal";
import openNewTab from "../../../lib/openNewTab";
import IconButton from "../../molecules/iconbutton/IconButton";
import SendEmailModal from "../events/event/steps/participants/SendEmailModal";
import GameControllerBroadcastMessage from "./GameControllerBroadcastMessage";
import Clickable from "../../atoms/clickable/Clickable";
import ParticipantsCheckList from "./ParticipantsCheckList";
import GameRoundDetails from "./GameRoundDetails";
import useIsMobileOrTablet from "../../../hooks/useIsMobileOrTablet";

interface Props {
  event: API.EventResponse;
  game: API.GameStatus;
  fetchGameStatus: (onComplete?: (() => void) | undefined) => void;
  handleEventChange?: () => void;
  fetchEvent: () => void;
}

function GameStatus({
  event,
  game,
  fetchGameStatus,
  handleEventChange,
  fetchEvent,
}: Props) {
  const isMobileOrTablet = useIsMobileOrTablet();
  const [showParticipantsList, setShowParticipantsList] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [LoginError, setLoginError] = useState<string | null>(null);
  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false);
  const [screen, setScreen] = useState<"timer" | "bonus" | "close" | null>(
    null,
  );
  const [selectedScreen, setSelectedScreen] = useState<
    "timer" | "bonus" | "close" | null
  >(null);
  const [isErrorModalOpen, setErrorModalOpen] = useState(false);
  const [isBroadcastModalOpen, setBroadcastModalOpen] = useState(false);
  const [shouldCalculateRound, setShouldCalculateRound] = useState(0);
  const handleLoginClick = useCallback(
    (teamId: number) => {
      try {
        const participantLoginLink = getParticipantLoginLink(event, teamId);
        openNewTab(participantLoginLink);
      } catch (error) {
        setLoginError(error.message);
        setErrorModalOpen(true);
      }
    },
    [event],
  );

  const handleSendWelcomeEmailsClick = useCallback(() => {
    setSendEmailModalOpen(true);
  }, []);

  const handleBroadcastClick = useCallback(() => {
    setBroadcastModalOpen(true);
  }, []);

  const gameInProgress =
    game.status === "inprogress" ||
    game.status === "bonustime" ||
    game.status === "wrapup";

  const handleSkipBonusTime = useCallback(() => {
    const round = gameInProgress ? game.currentRound : game.currentRound + 1;
    setShouldCalculateRound(round);
    setSelectedScreen("close");
  }, [game.currentRound, gameInProgress]);

  const isScheduledRound = game.roundType === "scheduled";
  // Initially a game has a locked status and starts at round 0, we would need to increment the round so that the correct round starts
  const round = gameInProgress ? game.currentRound : game.currentRound + 1;

  const timers: { team: API.GameConnection; timer: API.GameTeamBonusTimer }[] =
    useMemo(() => {
      const result = [];
      for (let i = 1; i <= game.teamsSetup.length; i++) {
        result.push({
          team: game.teams[i - 1],
          timer: game.bonusTimers[`${round}:${i}`],
        });
      }
      return result;
    }, [game.bonusTimers, game.teams, game.teamsSetup, round]);

  useEffect(() => {
    if (game.status === "bonustime") {
      if (isScheduledRound) {
        setScreen("close");
      } else {
        if (
          game.bonusTimeHasStarted &&
          timers.every((t) => t.timer.timeRemaining === 0)
        ) {
          setScreen("close");
        } else {
          setScreen("bonus");
        }
      }
    } else {
      setScreen("timer");
    }
  }, [game.bonusTimeHasStarted, game.status, timers, isScheduledRound]);

  // Syncs the timer with backend

  useEffect(() => {
    const heartbeater = setInterval(() => {
      fetchGameStatus();
    }, 3000);
    return () => {
      clearInterval(heartbeater);
    };
  }, [fetchGameStatus]);

  const closeRound = useCallback(async () => {
    setSelectedScreen("timer");
    fetchGameStatus();
  }, [fetchGameStatus]);

  const transitionScreen = useCallback(
    (to: "timer" | "bonus" | "close") => {
      if (!selectedScreen) {
        setScreen(to);
      }
    },
    [selectedScreen],
  );

  useEffect(() => {
    if (screen === selectedScreen) {
      setSelectedScreen(null);
      if (showParticipantsList) {
        setShowParticipantsList(false);
      }
    }
  }, [screen, selectedScreen, showParticipantsList]);

  if (!screen) {
    return null;
  }

  return (
    <>
      {error && (
        <Banner
          type="error"
          active={!!error}
          message={error}
          onClose={() => setError(null)}
        />
      )}
      {!screen && <LoadingSpinner />}
      {!!screen && (
        <VerticalGroup
          spaceBetweenElements={2}
          style={
            isMobileOrTablet
              ? {
                  width: "100%",
                  overflow: "auto",
                  paddingBottom: "5rem",
                  gap: "0.2rem",
                }
              : { maxWidth: 880, width: 880 }
          }
          center
        >
          {!showParticipantsList && (
            <GameProgressBar
              hideBonusTime={isScheduledRound}
              screen={selectedScreen || screen}
              setSelectedScreen={(selectedScreen) => {
                setSelectedScreen(selectedScreen);
                setShowParticipantsList(false);
              }}
            />
          )}
          {isMobileOrTablet ? (
            <>
              <VerticalGroup
                wide
                spaceBetweenElements={4}
                className="game-status__content"
              >
                {!showParticipantsList && (
                  <GameRoundDetails
                    imageUrl={event.branding?.brandingImage?.imageUrl}
                    round={round}
                    totalRounds={game.totalRounds}
                    gameName={event.name}
                    mobile
                  />
                )}
                {showParticipantsList && (
                  <ParticipantsCheckList
                    event={event}
                    fetchEvent={fetchEvent}
                  />
                )}
              </VerticalGroup>
              <InlineGroup block center className="change-event">
                <InlineGroup className="width-50-percent" center>
                  <Clickable onClick={handleEventChange}>
                    <VerticalGroup center>
                      <Icon colour="white" type="events" size={8} noMargin />
                      <Text size="md" color="white" bold>
                        Change Event
                      </Text>
                    </VerticalGroup>
                  </Clickable>
                </InlineGroup>
                <InlineGroup className="width-50-percent" center>
                  {!showParticipantsList ? (
                    <Clickable onClick={() => setShowParticipantsList(true)}>
                      <VerticalGroup center>
                        <Icon colour="white" type="users" size={8} noMargin />
                        <Text size="md" color="white" bold>
                          Participants
                        </Text>
                      </VerticalGroup>
                    </Clickable>
                  ) : (
                    <Clickable onClick={() => setShowParticipantsList(false)}>
                      <VerticalGroup center>
                        <Icon
                          colour="white"
                          type="gameController"
                          size={8}
                          noMargin
                        />
                        <Text size="md" color="white" bold>
                          Game Controller
                        </Text>
                      </VerticalGroup>
                    </Clickable>
                  )}
                </InlineGroup>
              </InlineGroup>
            </>
          ) : (
            <InlineGroup block spaceBetweenElements={2}>
              <GameRoundDetails
                imageUrl={event.branding?.brandingImage?.imageUrl}
                round={round}
                totalRounds={game.totalRounds}
                gameName={event.name}
              />
              <GameDetails event={event} />
            </InlineGroup>
          )}
          <VerticalGroup spaceBetweenElements={isMobileOrTablet ? 3 : 0} wide>
            {isScheduledRound &&
              (selectedScreen === "timer" ||
                (!selectedScreen && screen === "timer")) &&
              !showParticipantsList && (
                <ScheduledRoundTimerScreen
                  game={game}
                  event={event}
                  round={round}
                  setError={setError}
                  update={fetchGameStatus}
                  setScreen={transitionScreen}
                  handleLoginClick={handleLoginClick}
                  handleBroadcastClick={handleBroadcastClick}
                  handleSendWelcomeEmailsClick={handleSendWelcomeEmailsClick}
                />
              )}

            {!isScheduledRound &&
              (selectedScreen === "timer" ||
                (!selectedScreen && screen === "timer")) &&
              !showParticipantsList &&
              !showParticipantsList && (
                <FacilitatedRoundTimerScreen
                  game={game}
                  event={event}
                  round={round}
                  setError={setError}
                  update={fetchGameStatus}
                  setScreen={transitionScreen}
                  handleLoginClick={handleLoginClick}
                  handleBroadcastClick={handleBroadcastClick}
                  handleSendWelcomeEmailsClick={handleSendWelcomeEmailsClick}
                />
              )}

            {(selectedScreen === "bonus" ||
              (!selectedScreen && screen === "bonus")) &&
              !showParticipantsList && (
                <BonusTimeScreen
                  setError={setError}
                  timers={timers}
                  round={round}
                  update={fetchGameStatus}
                  handleSkip={handleSkipBonusTime}
                  event={event}
                  handleLoginClick={handleLoginClick}
                />
              )}

            {(selectedScreen === "close" ||
              (!selectedScreen && screen === "close")) &&
              !showParticipantsList && (
                <CloseRoundScreen
                  closeRound={closeRound}
                  gameId={game.gameId}
                  totalRounds={game.totalRounds}
                  round={round}
                  shouldCalculateRound={shouldCalculateRound}
                  setShouldCalculateRound={setShouldCalculateRound}
                  handleBroadcastClick={handleBroadcastClick}
                />
              )}
            {isMobileOrTablet && !showParticipantsList && (
              <MobileGameDetails event={event} />
            )}
            {isMobileOrTablet &&
              (selectedScreen === "timer" ||
                (!selectedScreen && screen === "timer")) &&
              round <= 1 &&
              !showParticipantsList && (
                <div
                  className={
                    isMobileOrTablet ? "width-100-percent" : "width-40-percent"
                  }
                >
                  <IconButton
                    block
                    wide={false}
                    data-test="send-emails"
                    icon="email"
                    text="Send Welcome Emails"
                    onClick={() => setSendEmailModalOpen(true)}
                  />
                </div>
              )}
            {isMobileOrTablet &&
              (selectedScreen === "timer" ||
                (!selectedScreen && screen === "timer")) &&
              !showParticipantsList && (
                <IconButton
                  block
                  icon="broadcast"
                  onClick={handleBroadcastClick}
                  text="Broadcast"
                />
              )}
          </VerticalGroup>
        </VerticalGroup>
      )}
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => setErrorModalOpen(false)}
        title="Error"
        description={LoginError}
      />
      <SendEmailModal
        onComplete={() => setSendEmailModalOpen(false)}
        participantCount={event?.participants?.length || 0}
        isOpen={sendEmailModalOpen}
        onClose={() => setSendEmailModalOpen(false)}
        eventId={event.id}
        participants={event?.participants || []}
      />

      <GameControllerBroadcastMessage
        isOpen={isBroadcastModalOpen}
        onClose={() => setBroadcastModalOpen(false)}
        eventId={event.id}
        broadcastMessage={game.broadcastMessage}
      />
    </>
  );
}

export default GameStatus;
