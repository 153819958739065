import React, { useCallback } from "react";
import BrandingForm from "./BrandingForm";
import API from "../../../services/api";
import Container from "../../atoms/page/Page";
import { useHistory } from "react-router-dom";

function CreateBranding() {
  const onSave = useCallback((data: API.BrandingRequest) => {
    return API.createBranding(data);
  }, []);

  const history = useHistory();

  const onCancel = useCallback(() => {
    history.push("/brandings");
  }, [history]);

  return (
    <Container>
      <BrandingForm onSave={onSave} onCancel={onCancel} />
    </Container>
  );
}

export default CreateBranding;
