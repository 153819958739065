import { useEventListener } from "usehooks-ts";

interface Props {
  keymap: Record<string, () => void>;
}
export const useKeypress = ({ keymap }: Props) => {
  const handleKeyPress = (event: any) => {
    if (keymap[event.code]) {
      keymap[event.code]();
    } else if (keymap[event.key]) {
      keymap[event.key]();
    }
  };
  useEventListener("keydown", handleKeyPress);
};
