import React, { useCallback } from "react";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import CeoTransferCircle from "../components/CeoTransferCircle";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import { useResultsContext } from "../context/context";
import ResultsText from "../results-text/ResultsText";
import EditableReportingHeading from "../components/EditableReportingHeading";
import ReportingSubHeading from "../components/ReportingSubHeading";

interface Props {}

export const maxClicks = 2;

const RoundResultsCeoTransferTablet: React.FC<Props> = () => {
  const context = useResultsContext();

  const onNext = useCallback(() => {
    if (context.clicks === maxClicks) {
      context.goNextPage();
    } else {
      context.addClick();
    }
  }, [context]);

  return (
    <RoundResultsContainer onNext={onNext}>
      <VerticalGroup full center wide spaceBetweenElements={4}>
        <VerticalGroup spaceBetweenElements={2} center wide>
          <EditableReportingHeading defaultHeading="Changing CEO's" />
          <ReportingSubHeading subHeading="How to change CEO" />
        </VerticalGroup>
        <InlineGroup
          block
          spaceBetweenElements={2}
          fullHeight
          evenWidthChildren
        >
          <CeoTransferCircle
            type="tablet"
            showPlayer1={context.clicks === 1}
            showPlayer2={context.clicks > 1}
          />
          <VerticalGroup spaceBetweenElements={4} wide full className="pt-24">
            {context.clicks >= 1 && (
              <VerticalGroup spaceBetweenElements={4}>
                <ResultsText size="lg" bold>
                  If you were last round&apos;s CEO.....
                </ResultsText>
                <ul>
                  <VerticalGroup spaceBetweenElements={2}>
                    <li>
                      <ResultsText size="md">Appoint a new CEO</ResultsText>
                    </li>
                    <li>
                      <ResultsText size="md">
                        Pass them the red tablet
                      </ResultsText>
                    </li>
                  </VerticalGroup>
                </ul>
              </VerticalGroup>
            )}
          </VerticalGroup>
        </InlineGroup>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsCeoTransferTablet;
