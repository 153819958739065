import React from "react";
import IntegerDropdown from "../integer-dropdown/IntegerDropdown";

interface Props {
  label?: string;
  value: number;
  onChange: (selected: { value: string; label: string }) => void;
  error?: string;
  min?: number;
  max?: number;
  tiny?: boolean;
  useRoundsLabel?: boolean;
  small?: boolean;
}

const RoundDropdown: React.FC<Props> = ({
  label,
  onChange,
  value,
  min,
  max,
  tiny,
  useRoundsLabel,
  small,
}) => {
  return (
    <IntegerDropdown
      label={label}
      value={value}
      onChange={onChange}
      min={min ?? 3}
      max={max ?? 6}
      tiny={tiny}
      useRoundsLabel={useRoundsLabel}
      small={small}
    />
  );
};

export default RoundDropdown;
