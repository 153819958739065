import React, { useMemo } from "react";
import classNames from "classnames";
import ReactModal from "react-modal";
import Modal from "../../atoms/modal/Modal";
import "./StandardModal.scss";
import Icon from "../../atoms/icon/Icon";
import Theme from "../../../styles/_theme.module.scss";
import useIsMobileOrTablet from "../../../hooks/useIsMobileOrTablet";

interface Props {
  isOpen: boolean;
  isMinimal?: boolean;
  isScrollable?: boolean;
  size: "small" | "medium" | "large" | "scale";
  onClose: () => void;
  showCloseButton?: boolean;
  showMobileCloseButton?: boolean;
  className?: string;
  "data-test"?: string;
  noPadding?: boolean;
  preventPropagation?: boolean;
}

const simpleModalOverride: ReactModal.Styles = {
  content: {
    overflow: "visible",
  },
};

const SimpleModal: React.FC<Props> = ({
  isOpen,
  size,
  isMinimal = false,
  showCloseButton = false,
  isScrollable = false,
  onClose,
  className,
  children,
  showMobileCloseButton = false,
  noPadding = false,
  preventPropagation = false,
  ...props
}) => {
  const isMobileOrTablet = useIsMobileOrTablet();

  const preventEventPropagation = (e: React.KeyboardEvent) => {
    if (preventPropagation) {
      e.stopPropagation();
    }
  };

  const overrides = useMemo(() => {
    if (isMinimal) {
      return {
        content: {
          ...simpleModalOverride.content,
          background: "none",
        },
      };
    }
    if (size === "scale") {
      return {
        content: {
          ...simpleModalOverride.content,
          width: isMobileOrTablet ? "100%" : "95%",
          height: isMobileOrTablet ? "100%" : "",
        },
      };
    }
    return simpleModalOverride;
  }, [isMinimal, size, isMobileOrTablet]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} overrideStyles={overrides}>
      <div
        data-test={props["data-test"]}
        onKeyDown={preventEventPropagation}
        onKeyUp={preventEventPropagation}
      >
        <div
          className={classNames(
            "standard-modal-body",
            `size-${size}`,
            className,
            {
              showCloseButton,
              minimal: isMinimal,
              scrollable: isScrollable,
              "no-padding": noPadding,
            },
          )}
        >
          {children}
        </div>
        {(showCloseButton || (showMobileCloseButton && isMobileOrTablet)) && (
          <button
            className={classNames("close-button", {
              "mobile-close-button": showMobileCloseButton && isMobileOrTablet,
            })}
            onClick={onClose}
            aria-label="Close"
          >
            <Icon
              size={showMobileCloseButton ? 8 : 0}
              type="close"
              fill={showMobileCloseButton ? Theme.black : Theme.white}
              className="m-0"
            />
          </button>
        )}
      </div>
    </Modal>
  );
};

export default SimpleModal;
