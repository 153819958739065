import React, { useCallback } from "react";
import classNames from "classnames";

import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import Button from "../../../../../atoms/button/Button";
import BrandingPreview from "../../../../../organisms/brandingpreview/BrandingPreview";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";

interface BrandingSelectOptionProps {
  branding: API.BrandingResponse;
  onBrandingSelected: (branding: API.BrandingResponse) => void;
  "data-test"?: string;
}
const BrandingSelectOption: React.FC<BrandingSelectOptionProps> = ({
  branding,
  onBrandingSelected,
  ...props
}) => {
  const onClick = useCallback(() => {
    onBrandingSelected(branding);
  }, [onBrandingSelected, branding]);

  return (
    <InlineGroup
      block
      spaceBetweenElements={4}
      className={classNames("branding-select-option")}
    >
      <BrandingPreview branding={branding} maxContainerHeight={195} />
      <VerticalGroup center verticalCenter flexAuto>
        <Button data-test={props["data-test"]} onClick={onClick}>
          Select
        </Button>
      </VerticalGroup>
    </InlineGroup>
  );
};

export default BrandingSelectOption;
