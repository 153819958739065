import React, { useReducer, useCallback } from "react";
import objectHash from "object-hash";

import APIError from "../errors/APIError";

interface FieldnameMapper {
  [field: string]: string;
}

export interface FieldErrors {
  [field: string]: string | undefined;
}

export interface State {
  inProgress: boolean;
  completed: boolean;
  fieldErrors: FieldErrors;
  initialHash: string;
  formUpdated: boolean;
  error: Error | null;
  errors: { [field: string]: Error };
  formData: { [field: string]: any };
  // This is to store the response data
  data: any;
}

interface ValidationError {
  path: string;
  value: string;
  message: string;
}

export interface FormError {
  field: string;
  message: string | undefined;
}

type Action =
  | { type: "UPDATE_FIELD"; data: { field: string; value: any } }
  | { type: "UPDATE_ERRORS"; data: ValidationError[] }
  | {
      type: "UPDATE_ERROR";
      data: { field: string; message: string | undefined };
    }
  | { type: "API_REQUEST_IN_PROGRESS" }
  | { type: "API_REQUEST_SUCCESS"; data: any }
  | { type: "API_REQUEST_FAILURE"; data: Error }
  | { type: "CLEAR_FORM_UPDATED" }
  | { type: "CLEAR_FORM_ERROR" }
  | { type: "RESET_FORM_DATA"; data: State["formData"] };

const createReducer =
  (mapper?: FieldnameMapper) =>
  (state: State, action: Action): State => {
    switch (action.type) {
      case "UPDATE_FIELD": {
        const updatedFormData = {
          ...state.formData,
          [action.data.field]: action.data.value,
        };
        return {
          ...state,
          formData: {
            ...state.formData,
            [action.data.field]: action.data.value,
          },
          fieldErrors: { ...state.fieldErrors, [action.data.field]: "" },
          formUpdated: objectHash(updatedFormData) !== state.initialHash,
        };
      }
      case "UPDATE_ERRORS": {
        return {
          ...state,
          inProgress: false,
          fieldErrors: action.data.reduce(
            (acc: { [field: string]: string }, error) => {
              const fieldName =
                mapper && mapper[error.path]
                  ? mapper[error.path]
                  : error.path.charAt(0).toUpperCase() + error.path.slice(1);
              acc[error.path] = `${fieldName} ${error.message}`;
              return acc;
            },
            {},
          ),
        };
      }
      case "UPDATE_ERROR": {
        return {
          ...state,
          fieldErrors: {
            ...state.fieldErrors,
            [action.data.field]: action.data.message,
          },
        };
      }
      case "CLEAR_FORM_ERROR": {
        return {
          ...state,
          fieldErrors: {},
        };
      }
      case "API_REQUEST_IN_PROGRESS": {
        return {
          ...state,
          inProgress: true,
          completed: false,
          error: null,
          fieldErrors: {},
        };
      }
      case "API_REQUEST_SUCCESS": {
        return {
          ...state,
          inProgress: false,
          completed: true,
          data: action.data,
        };
      }
      case "API_REQUEST_FAILURE": {
        return {
          ...state,
          inProgress: false,
          completed: true,
          error: action.data,
        };
      }
      case "CLEAR_FORM_UPDATED": {
        return { ...state, formUpdated: false };
      }
      case "RESET_FORM_DATA": {
        return {
          errors: {},
          data: null,
          error: null,
          fieldErrors: {},
          inProgress: false,
          completed: false,
          formUpdated: false,
          initialHash: objectHash(action.data),
          formData: action.data,
        };
      }
    }
  };

export interface Setters {
  setField: (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  setNumberField: (
    field: string,
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  setCheckboxField: (
    field: string,
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  setFieldRaw: (field: string, value: any) => void;
  setFieldSimple: (field: string) => (value: any) => void;
  setFieldError: (field: string) => (message: string | undefined) => void;
  setError: (field: string, message: string | undefined) => void;
  setDateField: (field: string) => (value: Date | null) => void;
  setDropdownField: (
    field: string,
  ) => (selectedValue: { value: string; label: string }) => void;
  setMultiSelectField: (
    field: string,
  ) => (selectedValue: { value: string; label: string }) => void;
  setRadioField: (fields: string[]) => (selectedValue: string) => void;
  clearFormUpdated: () => void;
  clearFormError: () => void;
  resetFormData: (data: State["formData"]) => void;
  handleSubmit: <T>(
    func: () => void | Promise<T>,
    validate?: () => FormError[],
    doNotMerge?: boolean,
  ) => (e?: React.FormEvent<HTMLFormElement>) => Promise<false | void>;
}

interface Params {
  fieldNameMapper?: FieldnameMapper;
  errorPathPrefix?: string;
}
const useForm = (
  initialData: State["formData"] = {},
  params: Params = {},
): [State, Setters] => {
  const { fieldNameMapper, errorPathPrefix } = params;
  const reducer = createReducer(fieldNameMapper);
  const [state, dispatch] = useReducer(reducer, {
    errors: {},
    data: null,
    error: null,
    fieldErrors: {},
    inProgress: false,
    completed: false,
    formUpdated: false,
    initialHash: objectHash(initialData),
    formData: initialData,
  });

  const setField = useCallback(
    (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: "UPDATE_FIELD",
        data: { field, value: e.target.value },
      });
    },
    [dispatch],
  );

  const setNumberField = useCallback(
    (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: "UPDATE_FIELD",
        data: { field, value: Number(e.target.value) },
      });
    },
    [dispatch],
  );

  const setCheckboxField = useCallback(
    (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: "UPDATE_FIELD",
        data: { field, value: e.target.checked },
      });
    },
    [dispatch],
  );

  const setFieldRaw = useCallback(
    (field: string, value: any) => {
      dispatch({ type: "UPDATE_FIELD", data: { field, value } });
    },
    [dispatch],
  );

  const setFieldSimple = useCallback(
    (field: string) => (value: any) => {
      dispatch({ type: "UPDATE_FIELD", data: { field, value: value } });
    },
    [],
  );

  const setFieldError = useCallback(
    (field: string) => (message: string | undefined) => {
      dispatch({ type: "UPDATE_ERROR", data: { field, message } });
    },
    [dispatch],
  );

  const setError = useCallback(
    (field: string, message: string | undefined) => {
      dispatch({ type: "UPDATE_ERROR", data: { field, message } });
    },
    [dispatch],
  );

  const clearFormError = useCallback(() => {
    dispatch({ type: "CLEAR_FORM_ERROR" });
  }, [dispatch]);

  const setDateField = useCallback(
    (field: string) => (value: Date | null) => {
      dispatch({ type: "UPDATE_FIELD", data: { field, value } });
    },
    [dispatch],
  );

  const setDropdownField = useCallback(
    (field: string) => (selectedValue: { value: string; label: string }) => {
      dispatch({
        type: "UPDATE_FIELD",
        data: { field, value: selectedValue.value },
      });
    },
    [dispatch],
  );

  const setMultiSelectField = useCallback(
    (field: string) => (selectedValue: { value: string; label: string }) => {
      if (Array.isArray(selectedValue)) {
        dispatch({
          type: "UPDATE_FIELD",
          data: { field, value: selectedValue.map((v) => v.value) },
        });
      } else {
        dispatch({ type: "UPDATE_FIELD", data: { field, value: [] } });
      }
    },
    [dispatch],
  );

  const setRadioField = useCallback(
    (fields: string[]) => (selectedValue: string) => {
      fields.forEach((field) => {
        if (field === selectedValue) {
          dispatch({ type: "UPDATE_FIELD", data: { field, value: true } });
        } else {
          dispatch({ type: "UPDATE_FIELD", data: { field, value: false } });
        }
      });
    },
    [dispatch],
  );

  const clearFormUpdated = useCallback(() => {
    dispatch({ type: "CLEAR_FORM_UPDATED" });
  }, [dispatch]);

  const resetFormData = useCallback((data: State["formData"]) => {
    dispatch({ type: "RESET_FORM_DATA", data });
  }, []);

  const handleSubmit = useCallback(
    <T>(
      func: () => void | Promise<T>,
      validate?: () => FormError[],
      doNotMerge?: boolean,
    ) =>
      async (e?: React.FormEvent<HTMLFormElement>) => {
        e?.preventDefault();

        if (validate) {
          const errors = doNotMerge
            ? validate()
            : mergeError(
                Object.keys(state.fieldErrors)
                  .map((field) => ({
                    field,
                    message: state.fieldErrors[field],
                  }))
                  .filter((error) => error.message),
                validate(),
              );
          if (errors.length > 0) {
            for (const formError of errors) {
              dispatch({ type: "UPDATE_ERROR", data: formError });
            }
            /**
             * We only return false when there a valid error message
             * When message === undefined, it's unsetting the error
             */
            if (errors.filter((err) => err.message !== undefined).length > 0)
              return false;
          }
        }
        const doSubmitFunction = async () => {
          dispatch({ type: "API_REQUEST_IN_PROGRESS" });
          try {
            const response = await func();
            dispatch({ type: "API_REQUEST_SUCCESS", data: response });
          } catch (e) {
            if (e instanceof APIError && e.type === "invalid_payload") {
              const errors = e.errors as ValidationError[];
              dispatch({
                type: "UPDATE_ERRORS",
                data: errors.map((e) => {
                  const path =
                    !!errorPathPrefix && e.path.startsWith(errorPathPrefix)
                      ? e.path.replace(errorPathPrefix, "")
                      : e.path;
                  return {
                    ...e,
                    path,
                  };
                }),
              });
            } else {
              dispatch({ type: "API_REQUEST_FAILURE", data: e });
            }
            return false;
          }
        };
        return doSubmitFunction();
      },
    [errorPathPrefix, state.fieldErrors],
  );

  return [
    state,
    {
      setField,
      setFieldRaw,
      setFieldSimple,
      setDateField,
      setDropdownField,
      setMultiSelectField,
      setRadioField,
      handleSubmit,
      setFieldError,
      setError,
      clearFormUpdated,
      setCheckboxField,
      setNumberField,
      clearFormError,
      resetFormData,
    },
  ];
};

const mergeError = (errors: FormError[], overrides: FormError[]) => {
  const errorMap = errors.reduce(
    (prev, curr) => {
      return {
        ...prev,
        [curr.field]: curr,
      };
    },
    {} as Record<string, FormError>,
  );

  const overrideMap = overrides.reduce(
    (prev, curr) => {
      return {
        ...prev,
        [curr.field]: curr,
      };
    },
    {} as Record<string, FormError>,
  );

  const mergedMap = {
    ...errorMap,
    ...overrideMap,
  };

  return Object.values(mergedMap);
};

export default useForm;
