import React from "react";

import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Icon from "../../../atoms/icon/Icon";
import "./YAxisIndicator.scss";
import ResultsText from "../results-text/ResultsText";

interface Props {
  name: string;
  topIndicator: string;
  bottomIndicator: string;
}

const YAxisIndicator: React.FC<Props> = ({
  name,
  topIndicator,
  bottomIndicator,
}) => {
  return (
    <InlineGroup
      center
      style={{ marginLeft: "20px" }}
      className="vertical-text"
      spread
      verticalCenter
    >
      <Icon
        dynamicSize={{ min: "15px", preferred: "1vw", max: "40px" }}
        type="arrow"
      ></Icon>
      <InlineGroup fullHeight block spread className="vertical pt-2 pb-2">
        <InlineGroup center>
          <ResultsText size="sm" vertical>
            {topIndicator}
          </ResultsText>
        </InlineGroup>
        <InlineGroup center>
          <ResultsText size="sm" vertical>{`(${name})`}</ResultsText>
        </InlineGroup>
        <InlineGroup center>
          <ResultsText size="sm" vertical>
            {bottomIndicator}
          </ResultsText>
        </InlineGroup>
      </InlineGroup>
      <Icon
        dynamicSize={{ min: "15px", preferred: "1vw", max: "40px" }}
        type="arrow"
        className="rotate"
      ></Icon>
    </InlineGroup>
  );
};

export default YAxisIndicator;
