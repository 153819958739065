import React, { useCallback, useMemo } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import { LabelByType } from "./Label";

interface Props {
  isDNDEnabled?: boolean;
  type: API.LabelTypes;
  items: API.LabelConfigResponse[];
  sortBy?: Array<string>;
  onUpdateContent: (
    labelId: string,
    data: Partial<API.LabelConfigResponse>,
  ) => Promise<void>;
  onItemsOrderChanged?: (items: API.LabelConfigResponse[]) => void;
}

function LabelsByType({
  isDNDEnabled = false,
  type,
  items,
  sortBy,
  onUpdateContent,
  onItemsOrderChanged,
}: Props) {
  const onChange = useCallback(
    (labelId: string, field: keyof API.LabelConfigResponse) =>
      (value: string) => {
        onUpdateContent(labelId, { [field]: value });
      },
    [onUpdateContent],
  );

  const sortedItems = useMemo(() => {
    const itemsForType = items.filter((item) => item.type === type);
    if (sortBy?.length) {
      const clone = Array.from(itemsForType);
      clone.sort((a, b) => {
        return sortBy.indexOf(a.name) - sortBy.indexOf(b.name);
      });
      return clone;
    }

    return itemsForType;
  }, [items, sortBy, type]);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!isDNDEnabled) return;
      if (!result.destination) return;

      const sourceIndex = result.source.index;
      const destIndex = result.destination.index;

      const newItems = Array.from(sortedItems);
      const [removed] = newItems.splice(sourceIndex, 1);
      newItems.splice(destIndex, 0, removed);

      onItemsOrderChanged && onItemsOrderChanged(newItems);
    },
    [isDNDEnabled, sortedItems, onItemsOrderChanged],
  );

  if (isDNDEnabled) {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={`${type}-droppable`}>
          {(provided) => (
            <VerticalGroup
              className="mt-2"
              spaceBetweenElements={2}
              wide
              {...provided.droppableProps}
              style={{ overflow: "auto" }}
              ref={provided.innerRef}
            >
              {sortedItems.map((item, i) => (
                <Draggable draggableId={item.id} index={i} key={item.id}>
                  {(provided) => (
                    <LabelByType
                      key={item.id}
                      ref={provided.innerRef}
                      item={item}
                      onChange={onChange(item.id, "value")}
                      isDraggable
                      dragHandleProps={provided.dragHandleProps || undefined}
                      draggableProps={provided.draggableProps}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </VerticalGroup>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  return (
    <VerticalGroup
      wide
      spaceBetweenElements={2}
      className="mt-2"
      style={{ overflowY: "auto" }}
    >
      {sortedItems.map((item) => (
        <LabelByType
          key={item.id}
          item={item}
          onChange={onChange(item.id, "value")}
        />
      ))}
    </VerticalGroup>
  );
}

export default LabelsByType;
