import React from "react";

import Card from "../../../../atoms/card/Card";
import DisplayField from "../../../../atoms/form/display-field/DisplayField";
import Clickable from "../../../../atoms/clickable/Clickable";
import Icon from "../../../../atoms/icon/Icon";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";

interface Props {
  data?: API.SimulationResponse["metadata"];
  onClickEdit?: () => void;
}

const Display: React.FC<Props> = ({ data, onClickEdit }) => {
  return data ? (
    <Card wide>
      <InlineGroup verticalCenter>
        <h4>Custom Settings</h4>
        {onClickEdit ? (
          <Clickable
            data-test={"edit-simulation-metadata"}
            onClick={onClickEdit}
          >
            <Icon
              type="edit"
              tip={{
                content: "Edit Metadata",
                id: "edit-metadata",
              }}
            />
          </Clickable>
        ) : null}
      </InlineGroup>
      {Object.entries(data).map(([key, value], i) => {
        return (
          <DisplayField
            key={i}
            value={String(value)}
            label={key}
            className="mt-2 mb-2"
            nowrap={false}
            stretch
          />
        );
      })}
    </Card>
  ) : null;
};

export default Display;
