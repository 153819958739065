import React, { useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import BrandingForm from "./BrandingForm";
import API from "../../../services/api";
import useAPIRequest from "../../../hooks/useAPIRequest";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import Container from "../../atoms/page/Page";
import Banner from "../../atoms/banner/Banner";

function CloneBranding() {
  const { brandingId } = useParams<{ brandingId: string }>();

  const callback = useCallback(() => {
    return API.getBranding(brandingId);
  }, [brandingId]);

  const [{ inProgress, data, error }, doApiRequest] = useAPIRequest(
    callback,
    null,
  );

  useEffect(() => {
    doApiRequest();
  }, [doApiRequest]);

  const onSave = useCallback((data: API.BrandingRequest) => {
    return API.createBranding(data);
  }, []);

  const history = useHistory();

  const onCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Container>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {data && (
        <BrandingForm
          branding={{
            ...data,
            id: "",
          }}
          onSave={onSave}
          onCancel={onCancel}
        />
      )}
    </Container>
  );
}

export default CloneBranding;
