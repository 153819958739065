import React, { useCallback } from "react";
import Card from "../../../atoms/card/Card";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import EditableReportingHeading from "../components/EditableReportingHeading";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Image from "../../../atoms/image/Image";
import ResultsText from "../results-text/ResultsText";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import chinaImg from "../../../../assets/results/china.jpg";
import englandImg from "../../../../assets/results/england.jpg";
import europeImg from "../../../../assets/results/europe.jpg";
import indonesiaImg from "../../../../assets/results/indonesia.png";
import japanImg from "../../../../assets/results/japan.png";
import nzImg from "../../../../assets/results/nz.png";
import singaporeImg from "../../../../assets/results/singapore.jpg";
import ausImg from "../../../../assets/results/australia.jpg";
import usaImg from "../../../../assets/results/usa.jpg";
import { useResultsContext } from "../context/context";
import classNames from "classnames";
import "./IntroTreasury.scss";

export const introTreasuryMaxClicks = 1;
interface Props {
  centralBank: string;
}

const TREASURY_IMAGES: Record<string, string> = {
  pbc: chinaImg,
  boe: englandImg,
  ecb: europeImg,
  bi: indonesiaImg,
  boj: japanImg,
  rbnz: nzImg,
  rba: ausImg,
  fed: usaImg,
  mas: singaporeImg,
};

const IntroTreasury: React.FC<Props> = ({ centralBank }) => {
  const context = useResultsContext();
  const treasuryImage = TREASURY_IMAGES[centralBank.toLowerCase()] || ausImg;

  const onNext = useCallback(() => {
    if (context.clicks < introTreasuryMaxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context]);

  const showSubHeading = context.noAnimations || context.clicks >= 1;
  return (
    <RoundResultsContainer onNext={onNext}>
      <Card className="intro-treasury-slide" wide fullHeight>
        <VerticalGroup spaceBetweenElements={1} wide center full>
          <EditableReportingHeading defaultHeading="Treasury" />
          <InlineGroup
            className={classNames("intro-treasury-subheading", {
              show: showSubHeading,
            })}
            center
            spaceBetweenElements={2}
            block
          >
            <ResultsText colour="primaryDark" size="xl">
              One Year {centralBank.toUpperCase()}
            </ResultsText>
            <ResultsText size="xl" bold colour="danger">
              BAIL OUT
            </ResultsText>
            <ResultsText colour="primaryDark" size="xl">
              loans available at
            </ResultsText>
            <ResultsText size="xl" bold colour="danger">
              EXPENSIVE
            </ResultsText>
            <ResultsText colour="primaryDark" size="xl">
              rates!
            </ResultsText>
          </InlineGroup>
          <InlineGroup fullHeight block center verticalCenter>
            <Image
              className={classNames("intro-treasury-image", {
                show: true,
              })}
              alt="Treasury"
              src={treasuryImage}
              dynamicSize={{
                min: "600px",
                preferred: "65%",
                max: "2500px",
              }}
              contain
            />
          </InlineGroup>
        </VerticalGroup>
      </Card>
    </RoundResultsContainer>
  );
};

export default IntroTreasury;
