import React, { useCallback } from "react";
import useAPIRequest from "../../../../hooks/useAPIRequest";
import ChallengeModal from "../../../organisms/challenge-modal/ChallengeModal";

import API from "../../../../services/api";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  assessmentId: string;
  group: API.AssessmentGroupResponse;
}

export const DeleteGroup: React.FC<Props> = ({
  assessmentId,
  isOpen,
  onComplete,
  onClose,
  group,
}) => {
  const callback = useCallback(
    () => API.deleteAssessmentGroup(assessmentId, group),
    [assessmentId, group],
  );

  const [{ inProgress, error }, doAPIRequest] = useAPIRequest(callback, null);

  const handleConfirm = useCallback(() => {
    doAPIRequest(onComplete);
  }, [doAPIRequest, onComplete]);

  return (
    <ChallengeModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      challengeQuestion="This action is irreversible. Please type the word DELETE below to proceed."
      challengeAnswer="DELETE"
      challengeAnswerWarning="Please type the word DELETE in CAPS to delete the group."
      title={`Delete group: ${group.name}?`}
      confirmTitle="Delete"
      error={error && error.message}
      warning
    />
  );
};
