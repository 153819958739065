import React from "react";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import Image from "../../../../atoms/image/Image";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import { FileDropZone } from "../../../../organisms/file-dropzone/FileDropZone";
import InlineError from "../../../../atoms/inlineerror/InlineError";
import { useResultsContext } from "../../context/context";

interface ImageSectionProps {
  item: API.ReportingLayoutCustomSlideImageOption;
  index: number;
  onDrop: (files: File[], index: number, itemIndex: number) => void;
  isUploading: boolean;
  uploadError: string | null;
  getAlignment: (
    item: API.ReportingLayoutCustomSlideImageOption,
  ) => "left" | "center" | "right";
}

const ImageSection: React.FC<ImageSectionProps> = ({
  item,
  index,
  onDrop,
  isUploading,
  uploadError,
  getAlignment,
}) => {
  const context = useResultsContext();

  return (
    <InlineGroup fullHeight block>
      {item.imageUrl && (
        <InlineGroup
          block
          center={getAlignment(item) === "center"}
          right={getAlignment(item) === "right"}
        >
          <Image
            width={`${item.width}${item.unit || "px"}`}
            height={`${item.height}${item.unit || "px"}`}
            src={item.imageUrl}
            alt="Custom Image"
          />
        </InlineGroup>
      )}
      {!item.imageId && !item.imageUrl && context.allowCustomTemplateEdits && (
        <VerticalGroup wide full>
          <FileDropZone
            required={false}
            className="width-100-percent"
            onDrop={(files: any) => onDrop(files, index, -1)}
            accept="image/*"
            dropzonePlaceholderMain="Drag your file here"
            dropzonePlaceholderSecondary="or click to upload from your computer"
            isLoading={isUploading}
          />
          {uploadError && <InlineError active message={uploadError} />}
        </VerticalGroup>
      )}
      {!item.imageId && !item.imageUrl && !context.allowCustomTemplateEdits && (
        <Image
          src="https://via.placeholder.com/728x500.png?text=Add+Your+Custom+Image"
          alt="Custom Image Placeholder"
          contain
          dynamicSize={{
            min: "400px",
            preferred: "85vw",
            max: "400px",
          }}
        />
      )}
    </InlineGroup>
  );
};

export default ImageSection;
