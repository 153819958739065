import React from "react";
import { useCallback } from "react";
import { useMemoRequest } from "../../../../hooks/useMemoRequest";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import ModelAPI from "../../../../services/modelApi";
import { Accordion } from "../../../organisms/accordion/accordion";
import ProfitAndLoss from "./ProfitAndLoss";
import Cashflow from "./Cashflow";
import BalanceSheet from "./BalanceSheet";
import CompanyValuation from "./CompanyValuation";
import TotalShareholderReturn from "./TotalShareholderReturn";
import ModelPageContainer from "../ModelPageContainer";
import useModelQuery from "../utils/useModelQuery";

interface Props {
  eventId: string;
  configuration: ModelAPI.ConfigurationResponse;
  selectedTeam: number;
  selectedRound: number;
}

const ModelKeyResults: React.FC<Props> = ({
  eventId,
  configuration,
  selectedTeam,
  selectedRound,
}) => {
  const getTeamResults = useCallback(() => {
    return ModelAPI.getModelTeamResults(eventId, selectedRound, selectedTeam);
  }, [eventId, selectedRound, selectedTeam]);

  const { inProgress, data, error, refresh } =
    useMemoRequest<ModelAPI.TeamResultsResponse>(getTeamResults);

  const { expandIfSection } = useModelQuery();

  return (
    <ModelPageContainer
      eventId={eventId}
      selectedRound={selectedRound}
      data={data}
      refresh={refresh}
      error={error}
      inProgress={inProgress}
    >
      {data && (
        <VerticalGroup spaceBetweenElements={2} wide>
          <Accordion title="Profit And Loss" defaultState="collapse" wide>
            <ProfitAndLoss
              configuration={configuration}
              data={data.profitAndLoss}
            />
          </Accordion>
          <Accordion title="Cashflow" defaultState="collapse" wide>
            <Cashflow configuration={configuration} data={data.cashflow} />
          </Accordion>
          <Accordion
            title="Balance Sheet"
            defaultState={expandIfSection("balanceSheet")}
            wide
          >
            <BalanceSheet
              configuration={configuration}
              data={data.balanceSheet}
            />
          </Accordion>
          <Accordion title="Company Valuation" defaultState="collapse" wide>
            <CompanyValuation
              configuration={configuration}
              data={data.companyValuation}
            />
          </Accordion>
          <Accordion
            title="Total Shareholder Return"
            defaultState="collapse"
            wide
          >
            <TotalShareholderReturn
              configuration={configuration}
              data={data.totalShareholderReturn}
            />
          </Accordion>
        </VerticalGroup>
      )}
    </ModelPageContainer>
  );
};

export default ModelKeyResults;
