import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import Banner from "../../atoms/banner/Banner";
import Text from "../../atoms/text/Text";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import Container from "../../atoms/page/Page";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import ModelConfiguration from "./ModelConfiguration/ModelConfiguration";
import ModelDecisions from "./ModelDecisions/ModelDecisions";
import ModelMarketShare from "./ModelMarketShare/ModelMarketShare";
import ModelTreasuryCalculations from "./ModelTreasuryCalculations/ModelTreasuryCalculations";
import ModelVarianceChecks from "./ModelVarianceChecks/ModelVarianceChecks";
import ModelSetupChecks from "./ModelSetupChecks/ModelSetupChecks";
import ModelTeamResults from "./ModelTeamResults/ModelTeamResults";
import ModelKeyResults from "./ModelKeyResults/ModelKeyResults";
import ModelAPI from "../../../services/modelApi";
import { useMemoRequest } from "../../../hooks/useMemoRequest";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import Ribbon from "../../atoms/ribbon/Ribbon";
import api from "../../../services/api";
import IntegerDropdown from "../../organisms/integer-dropdown/IntegerDropdown";
import getTeamName from "./utils/getTeamName";
import { ModelContext, useModelContextValue } from "./context";
import { reducer } from "./state";
import useQuery from "../../../hooks/useQuery";
import ModelAdjustments from "./ModelAdjustments/ModelAdjustments";
import ModelLogs from "./ModelLogs/ModelLogs";

type Tab =
  | "configuration"
  | "decisions"
  | "wsf"
  | "marketshare"
  | "results"
  | "key_results"
  | "variancecheck"
  | "setupcheck"
  | "logs"
  | "facilitator-adjustments";

interface GetConfigurationAndStatusAndEventResponse {
  configuration: ModelAPI.ConfigurationResponse;
  status: API.GameStatus;
  event: API.EventResponse;
}

export const SecondaryPageHeader = (): JSX.Element => {
  const query = useQuery();
  const eventTitle = query.get("eventTitle");
  const clientName = query.get("clientName");
  if (!clientName || !eventTitle) {
    return <span></span>;
  }
  return (
    <InlineGroup spaceBetweenElements={2}>
      <Text colour="green" size="sm">
        {clientName}
      </Text>
      <Text size="sm">—</Text>
      <Text size="sm">{eventTitle}</Text>
    </InlineGroup>
  );
};

const Model: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const query = useQuery();
  let initialTeam = 1;
  const queryTeam = query.get("team");
  if (queryTeam != null) {
    initialTeam = parseInt(queryTeam);
  }
  let initialTab: Tab = "configuration";
  const queryTab = query.get("tab");
  if (queryTab != null) {
    initialTab = queryTab as Tab;
  }

  const [tab, setTab] = useState<Tab>(initialTab);
  const history = useHistory();
  const prevEventRef = useRef<API.EventResponse | undefined>();
  const prevQueryRef = useRef<URLSearchParams | null>(null);

  const setTeam = useCallback((team: number) => {
    dispatch({
      type: "UpdateSelectedTeam",
      payload: team,
    });
  }, []);

  useEffect(() => {
    if (queryTab != null) {
      setTab(queryTab as Tab);
    }

    if (queryTeam != null) {
      const parsedQueryTeam = parseInt(queryTeam);
      setTeam(parsedQueryTeam);
    }
  }, [queryTab, queryTeam, setTeam]);

  const [state, dispatch] = useReducer(reducer, {
    config: undefined,
    selectedRound: 0,
    selectedTeam: initialTeam,
  });

  const getConfigurationAndStatusAndEvent = useCallback(async () => {
    const configuration = await ModelAPI.getModelConfiguration(eventId);
    const status = await api.getGameStatus(eventId);
    const event = await api.getEvent(eventId);
    dispatch({
      type: "UpdateConfig",
      payload: configuration,
    });
    dispatch({
      type: "UpdateSelectedRound",
      payload: configuration.currentRound,
    });
    dispatch({
      type: "UpdateGameStatus",
      payload: status,
    });
    return { configuration, status, event };
  }, [eventId]);

  const { inProgress, data, error, refresh } =
    useMemoRequest<GetConfigurationAndStatusAndEventResponse>(
      getConfigurationAndStatusAndEvent,
    );

  useEffect(() => {
    if (
      data?.event !== prevEventRef.current ||
      query !== prevQueryRef.current
    ) {
      const eventTitle = data?.event
        ? `${data.event.name} ${data.event.streamName ? `(${data.event.streamName})` : ""}`
        : "";
      const clientName = data?.event?.client ?? "";

      if (
        query.get("eventTitle") !== eventTitle ||
        query.get("clientName") !== clientName
      ) {
        const newQuery = new URLSearchParams(query);
        newQuery.set("eventTitle", eventTitle);
        newQuery.set("clientName", clientName);
        history.push({ search: newQuery.toString() });
      }

      prevEventRef.current = data?.event;
      prevQueryRef.current = query;
    }
  }, [query, data?.event, history]);

  const { numberOfTeams, totalNumberOfTeams, numberOfRounds } = useMemo(() => {
    if (!data) {
      return { numberOfTeams: 0, totalNumberOfTeams: 0, numberOfRounds: 0 };
    }
    const { numberOfTeams, numberOfCpuTeams, numberOfRounds } =
      data.configuration;
    const totalNumberOfTeams = numberOfTeams + numberOfCpuTeams;
    return { numberOfTeams, totalNumberOfTeams, numberOfRounds };
  }, [data]);

  const heading = useMemo(() => {
    switch (tab) {
      case "configuration":
        return "Configuration";
      case "decisions":
        return "Player Decisions";
      case "marketshare":
        return "Market Calculations";
      case "wsf":
        return "Treasury Calculations";
      case "results":
        return "Core Calculations";
      case "key_results":
        return "Key Results";
      case "variancecheck":
        return "Checks";
      case "setupcheck":
        return `Checks - Data Displayed To Users During Round ${state.selectedRound}`;
      case "facilitator-adjustments":
        return "Adjustments";
      case "logs":
        return "Logs";
    }
  }, [tab, state.selectedRound]);

  const modelContext = useModelContextValue(state, dispatch);

  return (
    <ModelContext.Provider value={modelContext}>
      <Container>
        <InlineGroup spaceBetweenElements={2} className="h-16">
          <Ribbon
            first
            active={tab === "configuration"}
            title="Configuration"
            icon="settings"
            onClick={() => setTab("configuration")}
          />
          <Ribbon
            active={tab === "decisions"}
            title="Decisions"
            icon="tick"
            onClick={() => setTab("decisions")}
          />
          <Ribbon
            active={tab === "marketshare"}
            title="Market"
            icon="shareHolderReturn"
            onClick={() => setTab("marketshare")}
          />
          <Ribbon
            active={tab === "wsf"}
            title="Treasury Calculations"
            icon="treasure"
            onClick={() => setTab("wsf")}
          />
          <Ribbon
            active={tab === "results"}
            title="Core Calculations"
            icon="calculator"
            onClick={() => setTab("results")}
          />
          <Ribbon
            active={tab === "key_results"}
            title="Key Results"
            icon="key"
            onClick={() => setTab("key_results")}
          />
          <Ribbon
            active={tab === "facilitator-adjustments"}
            title="Adjustments"
            icon="slider"
            onClick={() => setTab("facilitator-adjustments")}
          />
          <Ribbon
            active={tab === "variancecheck"}
            title="Checks"
            icon="clipboard"
            onClick={() => setTab("variancecheck")}
          />
          <Ribbon
            active={tab === "setupcheck"}
            title="Setup Check"
            icon="compare"
            onClick={() => setTab("setupcheck")}
          />
          <Ribbon
            last
            active={tab === "logs"}
            title="Logs"
            icon="audit"
            onClick={() => setTab("logs")}
          />
        </InlineGroup>
        <InlineGroup verticalCenter spread block>
          <h2>{heading}</h2>
          <InlineGroup spread spaceBetweenElements={2}>
            {numberOfTeams !== 0 &&
              data != null &&
              data.status &&
              tab !== "logs" && (
                <IntegerDropdown
                  small
                  labelFormatter={(team: number) =>
                    getTeamName(team, numberOfTeams, data!.status.teams)
                  }
                  min={1}
                  max={totalNumberOfTeams}
                  onChange={(selected) => setTeam(selected.value)}
                  value={state.selectedTeam}
                />
              )}
            {numberOfRounds !== 0 && data != null && tab !== "logs" && (
              <IntegerDropdown
                small
                labelFormatter={(round: number) => `Round ${round}`}
                min={0}
                max={numberOfRounds}
                onChange={(selected) => {
                  dispatch({
                    type: "UpdateSelectedRound",
                    payload: selected.value,
                  });
                }}
                value={state.selectedRound}
              />
            )}
          </InlineGroup>
        </InlineGroup>
        <Banner type="error" active={!!error} message={error?.message ?? ""} />
        {inProgress && <LoadingSpinner />}
        <VerticalGroup full>
          {tab === "configuration" && (
            <ModelConfiguration
              configuration={data?.configuration}
              eventId={eventId}
              refreshConfiguration={refresh}
            />
          )}
          {tab === "decisions" && !!data && (
            <ModelDecisions
              eventId={eventId}
              selectedRound={state.selectedRound}
              configuration={data.configuration}
              teams={data.status.teams}
            />
          )}
          {tab === "marketshare" && !!data && (
            <ModelMarketShare
              eventId={eventId}
              selectedRound={state.selectedRound}
              configuration={data.configuration}
              teams={data.status.teams}
            />
          )}
          {tab === "wsf" && !!data && (
            <ModelTreasuryCalculations
              eventId={eventId}
              selectedRound={state.selectedRound}
              configuration={data.configuration}
              selectedTeam={state.selectedTeam}
            />
          )}
          {tab === "results" && !!data && (
            <ModelTeamResults
              eventId={eventId}
              selectedRound={state.selectedRound}
              configuration={data.configuration}
              selectedTeam={state.selectedTeam}
            />
          )}
          {tab === "key_results" && !!data && (
            <ModelKeyResults
              eventId={eventId}
              selectedRound={state.selectedRound}
              configuration={data.configuration}
              selectedTeam={state.selectedTeam}
            />
          )}
          {tab === "variancecheck" && !!data && (
            <ModelVarianceChecks
              eventId={eventId}
              selectedRound={state.selectedRound}
              configuration={data.configuration}
              selectedTeam={state.selectedTeam}
            />
          )}
          {tab === "setupcheck" && !!data && (
            <ModelSetupChecks
              eventId={eventId}
              selectedRound={state.selectedRound}
              configuration={data.configuration}
              selectedTeam={state.selectedTeam}
            />
          )}
          {tab === "facilitator-adjustments" && !!data && (
            <ModelAdjustments
              eventId={eventId}
              selectedRound={state.selectedRound}
              configuration={data.configuration}
              selectedTeam={state.selectedTeam}
              teams={data.status.teams}
            />
          )}
          {tab === "logs" && !!data && <ModelLogs eventId={eventId} />}
        </VerticalGroup>
      </Container>
    </ModelContext.Provider>
  );
};

export default Model;
