import React, { useCallback, useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";

import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import Container from "../../atoms/page/Page";
import Button from "../../atoms/button/Button";
import CancelButton from "../../atoms/form/actions/CancelButton";
import Stepper, {
  useStepperContextValue,
  StepperContext,
} from "../../organisms/stepper/Stepper";
import Step from "../../organisms/stepper/Step";
import useQuery from "../../../hooks/useQuery";
import { reducer, SimulationState } from "./state";
import { SimulationContext, useSimulationContextValue } from "./context";
import General from "./steps/general";
import Players from "./steps/players";
import Module from "./steps/module";
import Region from "./steps/region";
import Business from "./steps/business";
import DealRoom from "./steps/dealroom";
import ExcoDecision from "./steps/excodecision";
import Summary from "./steps/summary";
import SpecialProjects from "./steps/special-projects/Index";
import FinancialSummary from "./steps/financial-summary";
import Preview from "./steps/preview";
import Metadata from "./steps/metadata";
import Labels from "./steps/labels";
import Help from "./steps/help";
import { useCurrentUser } from "../../../context/userContext";
import API from "../../../services/api";
import ConfirmModal from "../../organisms/confirm-modal/ConfirmModal";
import Resources from "./steps/resources";
import { Noop } from "../../../constants/functions";

export const Breadcrumb = () => {
  const query = useQuery();
  const stepTitle = query.get("stepTitle");
  return <span>Create New Simulation - {stepTitle}</span>;
};

export const PageHeader = () => {
  const query = useQuery();
  const header = query.get("pageHeader");
  return <span>Create New Simulation - {header}</span>;
};

const stateInit: SimulationState = {
  steps: [
    {
      title: "General",
      content: <General />,
      key: "generalSetup",
    },
    {
      title: "Players",
      content: <Players />,
      key: "playersSetup",
    },
    {
      title: "Economy And Other",
      content: <Module />,
      key: "moduleSetup",
    },
    {
      title: "Market",
      content: <Region />,
      key: "regionalSetup",
    },
    {
      title: "Businesses",
      content: <Business />,
      key: "businessSetup",
    },
    {
      title: "Deal Room",
      content: <DealRoom />,
      key: "dealRoomSetup",
    },
    {
      title: "Executive Decisions",
      content: <ExcoDecision />,
      key: "excoDecisionSetup",
    },
    {
      title: "Summary Screen",
      content: <Summary />,
      key: "summaryMetrics",
    },
    {
      title: "Financial Metrics",
      content: <FinancialSummary />,
      key: "financialSummaryMetrics",
    },
    {
      title: "Special Projects",
      content: <SpecialProjects />,
      key: "specialProjectsSetup",
    },
    {
      title: "Resources",
      content: <Resources />,
      key: "resources",
    },
    {
      title: "Labels",
      content: <Labels />,
      key: "labelConfig",
      roleRequired: ["superadmin"],
    },
    {
      title: "Help",
      content: <Help />,
      key: "helpConfig",
      roleRequired: ["superadmin"],
    },
    {
      title: "Custom Settings",
      content: <Metadata />,
      key: "metadata",
      roleRequired: ["superadmin"],
    },
    {
      title: "Preview",
      content: <Preview />,
      key: "preview",
    },
  ],
  formStatus: {
    inProgress: false,
    formUpdated: false,
  },
  onSubmit: async () => console.warn("No onSubmit action assigned"),
  isJumpStepModalOpen: false,
};

interface Props {
  simulation?: API.SimulationResponse;
}

const CreateSimulation: React.FC<Props> = ({ simulation }) => {
  const history = useHistory();
  const query = useQuery();
  const user = useCurrentUser();
  const [state, dispatch] = useReducer(
    reducer,
    {
      ...stateInit,
      config: simulation,
    },
    (state) => {
      return {
        ...state,
        steps: state.steps.filter((step) => {
          if (!step.roleRequired || !user?.type) {
            return true;
          }
          if (step.roleRequired.includes(user.type)) {
            return true;
          }
          return false;
        }),
      };
    },
  );
  const stepTitle = query.get("stepTitle");
  const initialStepIndex = stepTitle
    ? state.steps.findIndex((s) => s.title === stepTitle)
    : 0;

  const stepperContext = useStepperContextValue(
    state.steps.length,
    initialStepIndex > 0 ? initialStepIndex : 0,
  );
  const { activeIndex, jumpToStep, backStep, forwardStep } = stepperContext;

  const steps = state.steps;
  const activeStep = steps[activeIndex];

  useEffect(() => {
    let completeSteps = 0;
    if (state.config) {
      const stepKeys = steps.map((step) => step.key);
      completeSteps = Object.keys(state.config).filter((key) =>
        stepKeys.includes(key),
      ).length;
    }
    const completePercentage = `${((completeSteps / (steps.length - 1)) * 100).toFixed(0)}%`;

    const currentTitle = activeStep.title;
    const currentHeader = `${activeStep.title} ${completePercentage} complete`;
    console.log({ currentTitle, stepTitle: query.get("stepTitle") });
    if (
      query.get("stepTitle") !== currentTitle ||
      query.get("pageHeader") !== currentHeader
    ) {
      query.set("stepTitle", currentTitle);
      query.set("pageHeader", currentHeader);
      history.push({ search: query.toString() });
    }
  }, [history, query, activeStep, activeIndex, state.config, steps]);

  const onCancelNavigation = useCallback(() => {
    if (state.config?.id) {
      return history.push(`/simulations/${state.config.id}/view`);
    }
    return history.push("/simulations");
  }, [history, state.config?.id]);

  const isFirstStep = activeIndex === 0;
  const isLastStep = activeIndex + 1 === steps.length;

  const onNextStep = useCallback(async () => {
    if ((await state.onSubmit()) === false) return;
    if (isLastStep) {
      history.push(`/simulations/${state.config?.id}/view`);
    } else {
      forwardStep();
    }
  }, [forwardStep, history, isLastStep, state]);

  const onPrevStep = useCallback(async () => {
    if ((await state.onSubmit()) === false) return;
    backStep();
  }, [backStep, state]);

  const publish = useCallback(async () => {
    if ((await state.onSubmit()) === false) return;
    const payload: API.SimulationUpdateRequest = {
      state: "complete",
    };
    if (state.config) {
      await API.editSimulation(state.config?.id, payload);
      history.push(`/simulations/${state.config?.id}/view`);
    }
  }, [history, state]);

  const onJumpStepModalClose = useCallback(() => {
    dispatch({
      type: "SetJumpStepModalStatus",
      payload: {
        isJumpStepModalOpen: false,
        jumpStepModalConfirmAction: undefined,
      },
    });
  }, []);

  const onJumpStep = useCallback(
    (index: number) => {
      if (!state.config?.id) return;

      if (state.formStatus.formUpdated) {
        dispatch({
          type: "SetJumpStepModalStatus",
          payload: {
            isJumpStepModalOpen: true,
            jumpStepModalConfirmAction: () => {
              jumpToStep(index);
              onJumpStepModalClose();
            },
          },
        });
      } else {
        jumpToStep(index);
      }
    },
    [
      jumpToStep,
      onJumpStepModalClose,
      state.config?.id,
      state.formStatus.formUpdated,
    ],
  );

  const simulationContext = useSimulationContextValue(
    state,
    dispatch,
    jumpToStep,
  );

  const showPublishButton =
    !isLastStep &&
    state.config &&
    (state.formStatus.formUpdated || state.config.state !== "complete");

  return (
    <StepperContext.Provider value={stepperContext}>
      <SimulationContext.Provider value={simulationContext}>
        <div style={{ display: "flex", height: "100%" }}>
          <Stepper direction="vertical">
            {steps.map((step) => {
              return (
                <Step
                  key={step.title}
                  title={step.title}
                  onClick={onJumpStep}
                  disabledMessage="This step is disabled until Step 1 is complete"
                  disabled={state.config?.id == null}
                  complete={
                    (step.title === "Preview" &&
                      state.config?.state === "complete") ||
                    Object.keys(state.config || {}).some(
                      (key) => key === step.key,
                    )
                  }
                />
              );
            })}
          </Stepper>
          <div style={{ flex: 1 }}>
            <Container>
              <VerticalGroup wide full spread>
                {activeStep.content}
                <InlineGroup block spread className="mt-4">
                  <CancelButton
                    confirmModalProps={{
                      inProgress: state.formStatus.inProgress,
                      showModal: state.formStatus.formUpdated,
                      title: "Save Changes",
                      description:
                        "Do you want to save your changes to this step?",
                      confirmButtonText: "Save",
                      onConfirm: onNextStep,
                      onDiscard: onCancelNavigation,
                    }}
                    onClick={onCancelNavigation}
                  />
                  <InlineGroup block right spaceBetweenElements={2}>
                    {showPublishButton ? (
                      <Button
                        data-test="publish"
                        wide
                        inProgress={state.formStatus.inProgress}
                        onClick={publish}
                      >
                        Publish
                      </Button>
                    ) : null}
                    {isFirstStep ? null : (
                      <Button
                        data-test="prevStep"
                        light
                        wide
                        onClick={onPrevStep}
                        inProgress={state.formStatus.inProgress}
                      >
                        Save & Back
                      </Button>
                    )}
                    <Button
                      data-test="nextStep"
                      wide
                      inProgress={state.formStatus.inProgress}
                      onClick={onNextStep}
                    >
                      {isLastStep ? "Publish" : "Save & Next"}
                    </Button>
                  </InlineGroup>
                </InlineGroup>
              </VerticalGroup>
            </Container>
          </div>
        </div>
        <ConfirmModal
          isOpen={state.isJumpStepModalOpen}
          onClose={onJumpStepModalClose}
          onCancel={onJumpStepModalClose}
          onConfirm={state.jumpStepModalConfirmAction || Noop}
          title={"Save Changes"}
          description={
            "You haven't saved the changes you made. Are you sure to continue?"
          }
          confirmTitle={"Continue"}
          warning
        />
      </SimulationContext.Provider>
    </StepperContext.Provider>
  );
};

export default CreateSimulation;
