import React from "react";
import ParticipantsTable, {
  ReportingParticipant,
} from "../components/ParticipantTable";

interface Props {
  brandingLogo: string;
  participants: ReportingParticipant[];
}

const IntroParticipantSummary: React.FC<Props> = ({
  participants,
  brandingLogo,
}) => {
  return (
    <ParticipantsTable
      defaultTitle="Welcome To The Excelerate Banking Simulation"
      participants={participants}
      brandingLogo={brandingLogo}
    />
  );
};

export default IntroParticipantSummary;
