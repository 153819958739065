import React from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../../tables/TableWithSections";
import useModelQuery from "../../utils/useModelQuery";
interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["wholesaleFundingMovements"];
  fields: Array<{ field: string; name: string }>;
}

const fieldMap: { [key: string]: string } = {
  "outstandingWholesaleFundingByTenor.centralBankFacility": "CBF",
  "outstandingWholesaleFundingByTenor.wsf1": "1 Year",
  "outstandingWholesaleFundingByTenor.wsf2": "2 Year",
  "outstandingWholesaleFundingByTenor.wsf3": "3 Year",
  "outstandingWholesaleFundingByTenor.wsf4": "4 Year",
  "outstandingWholesaleFundingByTenor.wsf5": "5 Year",
};

const OutstandingWholesaleFundingByTenor: React.FC<Props> = ({ data }) => {
  const { openIfTable } = useModelQuery();
  return (
    <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
      <TableWithSections
        header="Outstanding Wholesale Funding - Balance By Tenor"
        hasRoundZero
        openOnInit={openIfTable("outstandingWholesaleFundingByTenor")}
        data={[
          {
            fields: [
              "outstandingWholesaleFundingByTenor.centralBankFacility",
              "outstandingWholesaleFundingByTenor.wsf1",
              "outstandingWholesaleFundingByTenor.wsf2",
              "outstandingWholesaleFundingByTenor.wsf3",
              "outstandingWholesaleFundingByTenor.wsf4",
              "outstandingWholesaleFundingByTenor.wsf5",
              "outstandingWholesaleFundingByTenor.total",
              "outstandingWholesaleFundingByTenor.variance",
            ],
            totalFields: [
              "outstandingWholesaleFundingByTenor.total",
              "outstandingWholesaleFundingByTenor.variance",
            ],
            values: data,
          },
        ]}
        fieldFormatter={(field: string) => fieldMap[field] ?? null}
      />
    </VerticalGroup>
  );
};

export default OutstandingWholesaleFundingByTenor;
