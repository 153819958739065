import React, {
  Fragment,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";
import { startCase } from "../../../../services/utils";
import Card from "../../../atoms/card/Card";
import Text from "../../../atoms/text/Text";
import Table, { Td, Th, Tr } from "../../../atoms/table/Table";
import { formatValue } from "../utils/formatters";
import classNames from "classnames";
import { pick } from "dot-object";
import { last } from "../../../../lib/array";
import { useModelContext } from "../context";
import InformationPopup from "../../../organisms/information-popup/InformationPopup";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import useIsMobile from "../../../../hooks/useIsMobile";

export interface CustomCell {
  clickable?: boolean;
  component: React.ReactNode;
}

export interface SectionData {
  name?: string;
  isTotalsSection?: boolean;
  fields: string[];
  values: { [field: string]: number | CustomCell }[];
  totalFields?: string[];
  highlightFields?: string[];
  formatter?: (val: number, field: string, index: number) => string | null;
  fieldFormatter?: (
    field: string,
    section: string | undefined,
  ) => string | null;
}

export interface Props {
  id?: string;
  header?: string;
  data: SectionData[];
  formatter?: (
    val: number,
    section: string | undefined,
    field: string,
    index: number,
  ) => string | null;
  fieldFormatter?: (
    field: string,
    section: string | undefined,
  ) => string | null;
  hasRoundZero?: boolean;
  fakeRoundZero?: boolean;
  collapseHeaders?: string[];
  highlightIfNotZero?: boolean;
  openOnInit?: boolean;
  onClick?: (field: string, year: number) => void;
  helpTitle?: string;
  helpDescription?: string;
}

interface TableHeader {
  key: number | string;
  label: string;
}

interface Section {
  name?: string;
  isTotalsSection?: boolean;
  data: {
    field: string;
    values: Array<number | CustomCell>;
    totalFields?: string[];
    highlightFields?: string[];
  }[];
  formatter?: (val: number, field: string, index: number) => string | null;
  fieldFormatter?: (
    field: string,
    section: string | undefined,
  ) => string | null;
}

const getLastBitOfFieldName = (val: string) => last(val.split("."));

type CollapseState = "collapse" | "expand";

const TableWithSections = ({
  id,
  header,
  data,
  formatter,
  fieldFormatter,
  hasRoundZero,
  fakeRoundZero,
  collapseHeaders,
  highlightIfNotZero,
  openOnInit,
  onClick,
  helpTitle,
  helpDescription,
}: PropsWithChildren<Props>) => {
  const isMobile = useIsMobile();
  const { config, selectedRound, selectedTeam } = useModelContext();
  if (!config) {
    throw new Error("Config not loaded yet");
  }
  const { numberOfHistoricRounds, numberOfRounds, numberOfPostGameRounds } =
    config;
  const totalRounds =
    numberOfRounds + numberOfHistoricRounds + numberOfPostGameRounds;
  const [collapseState, setCollapseState] = useState<CollapseState>(
    openOnInit ? "expand" : "collapse",
  );

  const onToggle = useCallback(() => {
    setCollapseState((state) => (state === "collapse" ? "expand" : "collapse"));
  }, [setCollapseState]);

  const indexOfCurrentRound = numberOfHistoricRounds + selectedRound;

  const tableHeaders: TableHeader[] = useMemo(() => {
    const roundZeroArray: TableHeader[] = [];
    if (hasRoundZero || fakeRoundZero) {
      roundZeroArray.push({ key: "round0", label: "0" });
    } else {
      roundZeroArray.push({ key: "round0", label: "" });
    }
    return roundZeroArray.concat(
      new Array(totalRounds).fill(0).map((val, i) => ({
        key: i,
        label: `${i + 1}`,
      })),
    );
  }, [totalRounds, hasRoundZero, fakeRoundZero]);

  const tableData: Section[] = useMemo(() => {
    const sections: Section[] = [];
    data.forEach((sectionData) => {
      const {
        fields,
        values,
        name,
        isTotalsSection,
        totalFields = [],
        highlightFields = [],
        fieldFormatter,
        formatter,
      } = sectionData;
      const sectionRows: Section["data"][0][] = [];
      for (const field of fields) {
        const section: Section["data"][0] = {
          field,
          values: [],
          totalFields,
          highlightFields,
        };
        if (fakeRoundZero) {
          section.values.push(0);
        } else if (!hasRoundZero) {
          section.values.push(0);
        }
        for (const year of values) {
          section.values.push(pick(field, year));
        }
        sectionRows.push(section);
      }
      sections.push({
        name,
        isTotalsSection,
        data: sectionRows,
        fieldFormatter,
        formatter,
      });
    });
    return sections;
  }, [data, fakeRoundZero, hasRoundZero]);

  if (collapseHeaders && collapseHeaders.length === totalRounds) {
    collapseHeaders = ["-"].concat(collapseHeaders);
  }

  const hasBorderLeft = (i: number) =>
    i === numberOfHistoricRounds + 1 ||
    i === numberOfHistoricRounds + numberOfRounds + 1;

  return (
    <Card wide sharp padding={isMobile ? 0 : 2}>
      <div style={{ width: "100%", overflowX: "auto" }}>
        <Table fixed id={id}>
          <thead>
            <tr onClick={onToggle} style={{ cursor: "pointer" }}>
              <Th width={isMobile ? 16 : 48}>
                <InlineGroup verticalCenter>
                  <Text size="sm">{header}</Text>
                  {helpTitle && helpDescription && (
                    <div>
                      <InformationPopup
                        className="ml-2"
                        title={helpTitle}
                        body={helpDescription}
                      />
                    </div>
                  )}
                </InlineGroup>
              </Th>
              {collapseState === "expand" &&
                tableHeaders.map((header, i) => (
                  <Th
                    width={isMobile ? 16 : 0}
                    noBold
                    borderLeft={hasBorderLeft(i)}
                    highlight={i === indexOfCurrentRound}
                    key={header.key}
                    right
                  >
                    {header.label}
                  </Th>
                ))}
              {collapseState === "collapse" &&
                !!collapseHeaders &&
                collapseHeaders?.length > 0 &&
                collapseHeaders.map((header, i) => (
                  <Th
                    softHighlight={
                      highlightIfNotZero && collapseHeaders?.[i] !== "-"
                    }
                    noBold
                    borderLeft={hasBorderLeft(i)}
                    highlight={i === indexOfCurrentRound}
                    key={`collapse-header-${i}`}
                    right
                  >
                    {collapseHeaders?.[i] ?? ""}
                  </Th>
                ))}
            </tr>
          </thead>
          <tbody
            style={
              collapseState === "collapse"
                ? { height: 0, overflow: "hidden" }
                : {}
            }
          >
            {collapseState === "expand" &&
              tableData.map((section, sectionIndex) => {
                const isTotalSection = section.isTotalsSection;
                return (
                  <Fragment key={sectionIndex}>
                    {!isTotalSection && section.name && (
                      <Tr>
                        <Td width={48} colSpan={4}>
                          <Text bold>{section.name}</Text>
                        </Td>
                        {tableHeaders.slice(3).map((header, i) => (
                          <Td
                            key={i}
                            borderLeft={hasBorderLeft(i + 3)}
                            right
                          ></Td>
                        ))}
                      </Tr>
                    )}
                    {section.data.map((sectionDataRow, sectionRowIndex) => {
                      const rowIsTotal = sectionDataRow.totalFields?.includes(
                        sectionDataRow.field,
                      );
                      const rowIsHighlighted =
                        sectionDataRow.highlightFields?.includes(
                          sectionDataRow.field,
                        ) || selectedTeam.toString() === sectionDataRow.field;

                      const isTotal = rowIsTotal || isTotalSection;
                      const field = getLastBitOfFieldName(sectionDataRow.field);
                      const formattedField =
                        (section.fieldFormatter
                          ? section.fieldFormatter(
                              sectionDataRow.field,
                              section.name,
                            )
                          : fieldFormatter
                            ? fieldFormatter(sectionDataRow.field, section.name)
                            : startCase(field)) ?? startCase(field);
                      return (
                        <Tr
                          key={sectionDataRow.field}
                          borderTop={
                            (rowIsTotal &&
                              sectionDataRow.totalFields &&
                              sectionDataRow.totalFields[0] ===
                                sectionDataRow.field) ||
                            (isTotalSection && sectionRowIndex === 0)
                          }
                        >
                          <Td
                            className={classNames({
                              "pl-8": !isTotalSection && section.name,
                            })}
                            softHighlight={!isMobile && rowIsHighlighted}
                            width={48}
                            style={
                              isMobile
                                ? {
                                    position: "sticky",
                                    left: 0,
                                    zIndex: 2,
                                    backgroundColor: "#e8e8e8",
                                  }
                                : {}
                            }
                          >
                            <Text bold={isTotal}>{formattedField}</Text>
                          </Td>
                          {sectionDataRow.values.map((value, i) => {
                            // @ts-expect-error componennt does exist
                            const valueComponent = value?.component;
                            // @ts-expect-error clickable does exist
                            const valueClickable = value?.clickable;

                            const formattedValue = valueComponent
                              ? valueComponent
                              : i === 0 && !hasRoundZero && !fakeRoundZero
                                ? ""
                                : (section.formatter
                                    ? section.formatter(
                                        value as number,
                                        sectionDataRow.field,
                                        i,
                                      )
                                    : formatter
                                      ? formatter(
                                          value as number,
                                          section.name,
                                          sectionDataRow.field,
                                          i,
                                        )
                                      : formatValue((value as number) ?? 0)) ??
                                  formatValue((value as number) ?? 0);
                            const canClick =
                              (valueComponent && valueClickable) ||
                              (highlightIfNotZero &&
                                formattedValue !== "-" &&
                                !!onClick);
                            return (
                              <Td
                                onClick={() =>
                                  onClick && onClick(sectionDataRow.field, i)
                                }
                                clickable={canClick}
                                borderLeft={hasBorderLeft(i)}
                                softHighlight={
                                  rowIsHighlighted ||
                                  (highlightIfNotZero && formattedValue !== "-")
                                }
                                highlight={i === indexOfCurrentRound}
                                key={i}
                                right
                              >
                                <Text bold={isTotal}>{formattedValue}</Text>
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    })}
                  </Fragment>
                );
              })}
          </tbody>
        </Table>
      </div>
    </Card>
  );
};

export default TableWithSections;
