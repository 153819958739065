import React from "react";
import Dropdown from "../../atoms/form/input/Dropdown";
import { rounds } from "../../pages/model/constants";

interface EconomicScenarioRoundDropdownProps {
  onChange: (round: number) => void;
  label?: string;
  error?: string;
  placeholder?: string;
  colour?: string;
  small?: boolean;
  midsize?: boolean;
  currentRound: number;
  showPlayableOnly?: boolean;
  smallish?: boolean;
}
const numberOfPlayableRounds = 6;

const EconomicScenarioRoundDropdown: React.FC<
  EconomicScenarioRoundDropdownProps
> = ({
  onChange,
  label,
  error,
  placeholder,
  colour,
  small,
  midsize,
  currentRound,
  showPlayableOnly = false,
  smallish,
}) => {
  const getLabel = (index: number): string => {
    let label = "";
    if (index < 3) {
      label = `Historical ${index + 1}`;
    } else if (index >= 3 && index < 9) {
      label = `Round ${index - 2}`;
    } else if (index >= 9) {
      label = `Reversion ${index - 8}`;
    }
    return label;
  };
  const isPlayableRound = (index: number): boolean => {
    return index >= 3 && index < 3 + numberOfPlayableRounds;
  };
  const roundOptions = Array.from({ length: rounds }, (_, index) => {
    if (showPlayableOnly && !isPlayableRound(index)) {
      return null;
    }
    const optionLabel = getLabel(index);
    return {
      value: `round_${index}`,
      label: optionLabel,
      formattedLabel:
        index === currentRound - 1 ? `${optionLabel} (current)` : optionLabel,
      isDisabled: index === currentRound - 1,
    };
  }).filter(Boolean);

  const currentRoundOption = roundOptions.find(
    (option) => option?.value === `round_${currentRound - 1}`,
  );

  const handleSelect = (option: { value: string; label: string }) => {
    const prefix = option.value.split("_")[0];
    const number = parseInt(option.value.split("_")[1], 10);
    let roundNumber;

    switch (prefix) {
      case "historical":
        roundNumber = number;
        break;
      case "round":
        roundNumber = number + 1;
        break;
      case "reversion":
        roundNumber = number + 8;
        break;
      default:
        roundNumber = currentRound;
    }

    onChange(roundNumber);
  };

  return (
    <Dropdown
      label={label}
      error={error}
      colour={colour}
      small={small}
      smallish={smallish}
      midsize={midsize}
      selectProps={{
        options: roundOptions,
        onChange: handleSelect,
        placeholder: placeholder || "Select a round",
        classNamePrefix: "round-dropdown",
        value: currentRoundOption,
      }}
    />
  );
};

export default EconomicScenarioRoundDropdown;
