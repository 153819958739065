import React from "react";
interface GradientProps {
  startColor: string;
  endColor: string;
  idCSS: string;
  rotation: number;
}
const Gradient: React.FC<GradientProps> = ({
  startColor,
  endColor,
  idCSS,
  rotation,
}) => {
  const gradientTransform = `rotate(${rotation})`;

  return (
    <svg style={{ height: 0, position: "absolute" }}>
      <defs>
        <linearGradient id={idCSS} gradientTransform={gradientTransform}>
          <stop offset="0%" stopColor={startColor} />
          <stop offset="100%" stopColor={endColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default Gradient;
