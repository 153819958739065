import React, { useCallback, useState } from "react";
import Text from "../../../../atoms/text/Text";
import Button from "../../../../atoms/button/Button";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import OpenEventDropDown from "../../../../organisms/event-dropdown/OpenEventDropDown";
import SimpleModal from "../../../../organisms/standard-modal/SimpleModal";
import { useTabletConfigContext } from "../context/context";
import API from "../../../../../services/api";
import useAPIRequest from "../../../../../hooks/useAPIRequest";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ConfigAddModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const [event, setEvent] = useState<API.EventResponseSummary | null>(null);
  const { eventData, updateSelectedEventId, updateEventData } =
    useTabletConfigContext();

  const callback = useCallback(async () => {
    if (!event) return;
    updateSelectedEventId(event.id);
    if (!eventData[event.id]) {
      const fullEventData = await API.getEvent(event.id);
      if (fullEventData) {
        updateEventData(fullEventData);
      }
    }
  }, [event, eventData, updateEventData, updateSelectedEventId]);

  const [{ inProgress }, doAPIRequest] = useAPIRequest(callback, null);

  const handleConfirm = useCallback(() => {
    doAPIRequest(onClose);
  }, [doAPIRequest, onClose]);

  return (
    <SimpleModal
      isOpen={isOpen}
      size="small"
      onClose={onClose}
      showCloseButton={true}
    >
      <VerticalGroup spaceBetweenElements={4}>
        <Text bold size="lg">
          Selected Event
        </Text>
        <OpenEventDropDown
          selectedEventId={event?.id}
          onEventSelected={(e) => setEvent(e)}
          orderBy="asc"
        />
        <InlineGroup block spread>
          <Button
            onClick={onClose}
            outline
            light
            large
            wide
            disabled={inProgress}
          >
            Cancel
          </Button>
          <Button onClick={handleConfirm} large wide inProgress={inProgress}>
            Add
          </Button>
        </InlineGroup>
      </VerticalGroup>
    </SimpleModal>
  );
};

export default ConfigAddModal;
