import React from "react";
import { FieldErrors } from "../../../../../../hooks/useForm";
import Input from "../../../../../atoms/form/input/Input";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import Toggle from "../../../../../atoms/toggle/Toggle";
import { getFieldError } from "./getFieldError";

interface Props {
  onChange: (writtenResponseField: API.WrittenResponseFieldRequest) => void;
  writtenResponseField: API.WrittenResponseFieldRequest;
  index: number;
  fieldErrors?: FieldErrors;
}

const DEFAULT_CHARACTER_LIMIT = 300;

export const WrittenResponseField: React.FC<Props> = ({
  writtenResponseField,
  onChange,
  fieldErrors,
  index,
}) => {
  return (
    <InlineGroup>
      <Toggle
        onUpdate={(isOn: boolean) => {
          onChange({
            type: "written_response",
            config: {
              ...writtenResponseField.config,
              type: "written_response",
              hasCharacterLimit: isOn,
              ...(isOn ? { characterLimit: DEFAULT_CHARACTER_LIMIT } : {}),
            },
          });
        }}
        checked={writtenResponseField.config.hasCharacterLimit}
        label="Character limit"
        reverse
        small
      />
      {writtenResponseField.config.hasCharacterLimit && (
        <Input
          className="ml-2"
          type="text"
          small
          reverse
          label="Characters"
          value={writtenResponseField.config.characterLimit}
          onChange={(e) => {
            onChange({
              type: "written_response",
              config: {
                ...writtenResponseField.config,
                characterLimit: Number(e.target.value),
              },
            });
          }}
          error={getFieldError(index, "characterLimit", fieldErrors)}
        />
      )}
    </InlineGroup>
  );
};
