import React from "react";
import { fullDateAndTime } from "../../../../lib/date";
import { formatPersonName } from "../../../../lib/formatters";
import { capitalize } from "../../../../services/utils";
import Table from "../../../atoms/table/Table";
import EmptyList from "../../../organisms/empty-list/EmptyList";
import { STICKY_BAR_HEIGHT } from "./AuditLogs";

interface Props {
  auditlogs: API.AuditResponse[];
}

function AuditLogsTable({ auditlogs }: Props) {
  return (
    <>
      {!auditlogs?.length && (
        <EmptyList icon="audit" message="There are no audit logs yet" />
      )}
      {!!auditlogs?.length && (
        <Table stickyHeader stickyTop={STICKY_BAR_HEIGHT}>
          <thead>
            <tr>
              <th>User</th>
              <th>Date</th>
              <th>Category</th>
              <th>Action</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {auditlogs.map((al) => (
              <tr key={al.id}>
                <td>{al.user ? formatPersonName(al.user) : "Unknown User"}</td>
                <td>{fullDateAndTime(al.createdAt)}</td>
                <td>{capitalize(al.category)}</td>
                <td>{al.action}</td>
                <td>{al.description}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}

export default AuditLogsTable;
