import React, { useCallback, useEffect } from "react";

import useAPIRequest from "../../../../hooks/useAPIRequest";
import API from "../../../../services/api";
import { useHistory } from "react-router-dom";
import IconButton from "../../../molecules/iconbutton/IconButton";

interface Props {
  client: API.ClientResponse;
}

function ActivateClient({ client }: Props) {
  const history = useHistory();

  const callback = useCallback(() => {
    return API.editClient(client.id, {
      state: "active",
      name: client.name,
      userIds: client.userIds,
    });
  }, [client]);

  const [{ completed, error }, doAPIRequest] = useAPIRequest(callback, null);

  const handleConfirm = () => {
    doAPIRequest();
  };

  useEffect(() => {
    if (completed && !error) {
      history.push("/administration/clients");
    }
  }, [completed, error, history]);

  return (
    <IconButton
      data-test={`toggle-client-state-${client.id}`}
      icon="tick"
      text="Activate Client"
      onClick={handleConfirm}
    />
  );
}

export default ActivateClient;
