import {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useMemo,
} from "react";

import { Actions, EventState } from "./state";
import { NotImplemented } from "../../../../constants/functions";

interface Context {
  config: EventState["config"];
  updateConfig: (payload: API.EventResponse) => void;
  refreshConfig: () => Promise<API.EventResponse | undefined>;
  updateFormStatus: (payload: EventState["formStatus"]) => void;
  assignOnSubmitFunction: (payload: () => Promise<false | void>) => void;
  assignOnSubmitNextFunction: (payload: () => Promise<false | void>) => void;
  assignOnSubmitPreviousFunction: (
    payload: () => Promise<false | void>,
  ) => void;
  jumpToStepByKey: (
    key:
      | keyof Omit<API.EventResponse, "id" | "state" | "clients">
      | "run-simulation",
  ) => void;
  formMode: EventState["formStatus"]["mode"];
}

export const EventContext = createContext<Context>({
  config: undefined,
  updateConfig: NotImplemented,
  assignOnSubmitFunction: NotImplemented,
  assignOnSubmitNextFunction: NotImplemented,
  assignOnSubmitPreviousFunction: NotImplemented,
  updateFormStatus: NotImplemented,
  jumpToStepByKey: NotImplemented,
  refreshConfig: NotImplemented as any,
  formMode: undefined,
});

export const useEventContextValue = (
  state: EventState,
  dispatch: Dispatch<Actions>,
  jumpToStep: (index: number) => void,
  refresh?: () => Promise<API.EventResponse | undefined>,
): Context => {
  const updateConfig = useCallback(
    (payload: API.EventResponse) => {
      dispatch({
        type: "UpdateConfig",
        payload,
      });
    },
    [dispatch],
  );

  const assignOnSubmitFunction = useCallback(
    (payload: () => Promise<false | void>) => {
      dispatch({
        type: "AssignOnSubmitFunction",
        payload,
      });
    },
    [dispatch],
  );

  const assignOnSubmitNextFunction = useCallback(
    (payload: () => Promise<false | void>) => {
      dispatch({
        type: "AssignOnSubmitNextFunction",
        payload,
      });
    },
    [dispatch],
  );

  const assignOnSubmitPreviousFunction = useCallback(
    (payload: () => Promise<false | void>) => {
      dispatch({
        type: "AssignOnSubmitPreviousFunction",
        payload,
      });
    },
    [dispatch],
  );

  const updateFormStatus = useCallback(
    (payload: EventState["formStatus"]) => {
      dispatch({
        type: "UpdateFormStatus",
        payload,
      });
    },
    [dispatch],
  );

  const jumpToStepByKey = useCallback(
    (key: string) => {
      const index = state.steps.findIndex((step) => step.key === key);
      jumpToStep(index);
    },
    [jumpToStep, state.steps],
  );

  const refreshConfig = useCallback(async () => {
    const response = refresh ? await refresh() : undefined;
    if (response) {
      updateConfig(response);
    }
    return response;
  }, [refresh, updateConfig]);

  const context = useMemo(
    () => ({
      config: state.config,
      updateConfig,
      assignOnSubmitFunction,
      assignOnSubmitNextFunction,
      assignOnSubmitPreviousFunction,
      updateFormStatus,
      jumpToStepByKey,
      refreshConfig: refreshConfig,
      formMode: state.formStatus.mode,
    }),
    [
      state.config,
      state.formStatus.mode,
      updateConfig,
      assignOnSubmitFunction,
      assignOnSubmitNextFunction,
      assignOnSubmitPreviousFunction,
      updateFormStatus,
      jumpToStepByKey,
      refreshConfig,
    ],
  );

  return context;
};

export const useEventContext = () => {
  return useContext(EventContext);
};
