import React from "react";
import IframePreview from "../iframe-preview/IframePreview";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import AdminAPI from "../../../services/api";

const HOST = process.env.REACT_APP_GAME_URL || "";
const PATHNAME = "/assessment-preview";

interface AssessmentPreviewWrappedProps {
  assessmentId: string;
  groupId: string;
  maxContainerHeight: number;
  minIframeHeight?: number;
  minIframeWidth?: number;
  isFixedHeight?: boolean;
}

export const buildUrl = ({
  assessmentId,
  groupId,
}: AssessmentPreviewWrappedProps) => {
  const url = new URL(HOST);

  url.pathname = PATHNAME;

  url.searchParams.set("assessmentId", assessmentId);
  url.searchParams.set("groupId", groupId);
  url.searchParams.set("token", AdminAPI.token);

  return url.href;
};

export const AssessmentPreviewWrapped: React.FC<
  AssessmentPreviewWrappedProps
> = (props) => {
  const targetSrc = buildUrl({ ...props });

  return (
    <VerticalGroup center verticalCenter spaceBetweenElements={2} wide flexAuto>
      <IframePreview enablePointerEvents {...{ ...props, targetSrc }} />
    </VerticalGroup>
  );
};

interface AssessmentPreviewProps {
  assessmentId: string;
  groupId: string;
  maxContainerHeight: number;
  minIframeHeight?: number;
}

const AssessmentPreviewIframe: React.FC<AssessmentPreviewProps> = ({
  maxContainerHeight,
  assessmentId,
  groupId,
  minIframeHeight,
}) => (
  <AssessmentPreviewWrapped
    assessmentId={assessmentId}
    groupId={groupId}
    maxContainerHeight={maxContainerHeight}
    minIframeHeight={minIframeHeight}
  />
);

export default AssessmentPreviewIframe;
