import React, { useMemo } from "react";
import { startCase } from "../../../../services/utils";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";
import { percentage } from "../utils/formatters";
import getTeamIdsAsFields from "../utils/getTeamIdsAsFields";
import getTeamName from "../utils/getTeamName";
import pluckResultsByType from "../utils/pluckResultsByType";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.DecisionsResponse["operationalCosts"];
  teams: API.GameConnection[];
}

const DIVISIONS = [
  "IT_OPERATIONS",
  "FRONT_LINE",
  "RISK_COMPLIANCE",
  "HR_TRAINING",
  "SALARY_GROWTH",
];

const OperationalCosts: React.FC<Props> = ({ configuration, data, teams }) => {
  const { numberOfTeams, numberOfCpuTeams } = configuration;
  const totalNumberOfTeams = numberOfTeams + numberOfCpuTeams;
  const fields = useMemo(
    () => getTeamIdsAsFields(totalNumberOfTeams),
    [totalNumberOfTeams],
  );

  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        {DIVISIONS.map((division) => {
          const values = pluckResultsByType(data, division);
          return (
            <TableWithSections
              key={division}
              header={startCase(division)}
              data={[
                {
                  fields,
                  values,
                },
              ]}
              fieldFormatter={(field: string) =>
                getTeamName(parseInt(field), numberOfTeams, teams)
              }
              formatter={(val: number) => percentage(val)}
            />
          );
        })}
      </VerticalGroup>
    </>
  );
};

export default OperationalCosts;
