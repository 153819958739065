import React from "react";
import { useParams } from "react-router-dom";
import Text from "../../atoms/text/Text";
import { useAssessment } from "../../../hooks/useAssessments";
import Page from "../../atoms/page/Page";
import { Link } from "react-router-dom";
import AssessmentPreviewWithData from "../../organisms/assessment-preview/AssessmentPreviewWithData";

const AssessmentSurveyPreview: React.FC = () => {
  const { assessmentId } = useParams<{ assessmentId: string }>();
  const { inProgress, data } = useAssessment(assessmentId);

  if (inProgress || !data) return null;

  return (
    <Page>
      <Link to={`/assessments/${assessmentId}/view`}>
        <Text className="mb-2" size="sm">
          &lt; back to editing
        </Text>
      </Link>
      <AssessmentPreviewWithData assessment={data} />
    </Page>
  );
};

export default AssessmentSurveyPreview;
