import React from "react";
import { useCurrentUser } from "../../../context/userContext";

interface Props {}

const FacilitatorOnly: React.FC<Props> = ({ children }) => {
  const user = useCurrentUser();
  if (user && user.type === "facilitator") {
    return <>{children}</>;
  }
  return null;
};

export default FacilitatorOnly;
