import { percentage } from "../../model/utils/formatters";

export const convertRoundDataToPercentages = (
  data: ModelAPI.Reporting.RoundData[],
) => {
  return data.map((d) => {
    return Object.entries(d).reduce<ModelAPI.Reporting.RoundData>(
      (acc, [key, value]) => {
        if (key !== "name" && typeof value === "number") {
          acc[key] = Number(percentage(value as number, 2, true, true, true));
        } else {
          acc[key] = value;
        }
        return acc;
      },
      { name: d.name },
    );
  });
};
