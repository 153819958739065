import React, { useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import BrandingForm from "./BrandingForm";
import API from "../../../services/api";
import useAPIRequest from "../../../hooks/useAPIRequest";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import Container from "../../atoms/page/Page";
import Banner from "../../atoms/banner/Banner";

function EditBranding() {
  const { brandingId } = useParams<{ brandingId: string }>();

  const callback = useCallback(() => {
    return API.getBranding(brandingId);
  }, [brandingId]);

  const [{ inProgress, data, error }, doApiRequest] = useAPIRequest(
    callback,
    null,
  );

  useEffect(() => {
    doApiRequest();
  }, [doApiRequest]);

  const onSave = useCallback(
    (data: API.BrandingRequest) => {
      return API.editBranding(brandingId, data);
    },
    [brandingId],
  );

  const history = useHistory();

  const onCancel = useCallback(() => {
    history.push(`/brandings/${brandingId}/view`);
  }, [history, brandingId]);

  return (
    <Container>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {data && (
        <BrandingForm branding={data} onSave={onSave} onCancel={onCancel} />
      )}
    </Container>
  );
}

export default EditBranding;
