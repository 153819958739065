import React from "react";

import Logo from "../../../assets/login/logo.png";
import "./PublicPageContainer.scss";

function PublicPageContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="public-page-container">
      <img src={Logo} alt="Excelerate Consulting" />
      {children}
    </div>
  );
}

export default PublicPageContainer;
