import React, { useCallback, useMemo } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import { TeamsWithColours } from "../RoundResultsPage";
import ResultsTeamLineChart from "../charts/ResultsTeamLineChart";
import { getYAxisDataV2 } from "../charts/yaxis";
import YAxisIndicator from "../yAxisIndicator/YAxisIndicator";
import ChartCard from "../components/ChartCard";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import { useResultsContext } from "../context/context";

interface Props {
  pastRound1: boolean;
  teams: TeamsWithColours[];
  overallWinningMetric: ModelAPI.Reporting.DataAndPositions;
}

const RoundResultsOverallWinningMetric: React.FC<Props> = ({
  pastRound1,
  teams,
  overallWinningMetric,
}) => {
  const teamsWithoutCpu = useMemo(
    () => teams.filter((t) => !t.isCpuTeam),
    [teams],
  );
  const { minValue, maxValue, numberOfTicks } = getYAxisDataV2({
    max: -1,
    min: -1 * teams.length,
    pastRound1,
    data: overallWinningMetric.data,
  });
  const context = useResultsContext();
  const maxClicks = useMemo(
    () => (context.allowPresentationMode || context.readSyncState ? 1 : 0),
    [context.allowPresentationMode, context.readSyncState],
  );
  const onNext = useCallback(() => {
    if (context.clicks < maxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context, maxClicks]);

  return (
    <RoundResultsContainer onNext={onNext}>
      <VerticalGroup full center wide>
        <ChartCard heading="Winning Bank">
          <YAxisIndicator
            name="Weighted Ranking"
            topIndicator="First"
            bottomIndicator="Last"
          />
          <ResultsTeamLineChart
            data={overallWinningMetric.data}
            teams={teamsWithoutCpu}
            minYAxisvalue={minValue}
            maxYAxisvalue={maxValue}
            numberOfTicks={numberOfTicks}
            toolTipFormatter={(val) => (-1 * val).toFixed(2)}
            formatter={(val) => -1 * val}
            teamPositions={overallWinningMetric.teamPositions}
            shouldRenderLines={context.clicks === maxClicks}
          />
        </ChartCard>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsOverallWinningMetric;
