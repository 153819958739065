import React from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import Group from "./Group";
import { FieldsOf } from "./types";
import GloInput, { DataProps } from "./GloInput";

type DataType = API.GlobalModelVariablesResponse["costsAndLosses"];

interface Props {
  data: DataType;
  onChange: <T extends keyof DataType>(
    key: T,
    field?: undefined | FieldsOf<DataType, T>,
  ) => (selected: any) => void;
  onChangeNested: <T extends keyof DataType>(
    key: T,
    subKey: FieldsOf<DataType, T>,
    field: FieldsOf<DataType[T], FieldsOf<DataType, T>>,
  ) => (selected: any) => void;
}

const loanImpairmentHomeLoans: Array<
  DataProps<DataType["loanImpairment"], "homeLoans">
> = [
  {
    label: "Proprietary Loans",
    field: "proprietaryLoans",
    type: "percentage",
    step: 1,
  },
  { label: "Broker Loans", field: "brokerLoans", type: "percentage", step: 1 },
];

const loanImpairmentBusinessLoans: Array<
  DataProps<DataType["loanImpairment"], "businessLoans">
> = [
  { label: "Good Quality", field: "goodQuality", type: "percentage", step: 1 },
  {
    label: "Medium Quality",
    field: "mediumQuality",
    type: "percentage",
    step: 1,
  },
  { label: "Poor Quality", field: "poorQuality", type: "percentage", step: 1 },
];

const loanImpairment: Array<
  DataProps<Omit<DataType, "homeLoans" | "businessLoans">, "loanImpairment">
> = [
  { label: "Credit Cards", field: "creditCards", type: "percentage", step: 1 },
  {
    label: "Institutional Lending",
    field: "institutionalLending",
    type: "percentage",
    step: 1,
  },
  {
    label: "Offshore Bank",
    field: "offshoreBank",
    type: "percentage",
    step: 1,
  },
];

const operatingCostsOpeningCostToAssetRatios: Array<
  DataProps<DataType["operatingCosts"], "openingCostToAssetRatio">
> = [
  { label: "Home Loans", field: "homeLoans", type: "bps" },
  { label: "Business Lending", field: "businessLoans", type: "bps" },
  { label: "Deposits", field: "deposits", type: "bps" },
  { label: "Wealth Management", field: "wealthManagement", type: "bps" },
  { label: "Credit Cards", field: "creditCards", type: "bps" },
  {
    label: "Institutional Lending",
    field: "institutionalLending",
    type: "bps",
  },
  { label: "Offshore Bank", field: "offshoreBank", type: "bps" },
];

const operatingCostsCostToAssetRatios: Array<
  DataProps<DataType["operatingCosts"], "costToAssetRatio">
> = [
  { label: "Home Loans", field: "homeLoans", type: "bps" },
  { label: "Business Lending", field: "businessLoans", type: "bps" },
  { label: "Deposits", field: "deposits", type: "bps" },
  { label: "Wealth Management", field: "wealthManagement", type: "bps" },
  { label: "Credit Cards", field: "creditCards", type: "bps" },
  {
    label: "Institutional Lending",
    field: "institutionalLending",
    type: "bps",
  },
  { label: "Offshore Bank", field: "offshoreBank", type: "bps" },
];

const CostsAndLosses: React.FC<Props> = ({
  data,
  onChange,
  onChangeNested,
}) => {
  return (
    <VerticalGroup wide spaceBetweenElements={3} className="p-2">
      <Group title="Loan Impairment">
        <h4>Home Loans</h4>
        {loanImpairmentHomeLoans.map(({ field, ...rest }) => (
          <GloInput
            key={field}
            value={data.loanImpairment.homeLoans[field]}
            onChange={onChangeNested("loanImpairment", "homeLoans", field)}
            {...rest}
          />
        ))}
        <h4>Business Lending</h4>
        {loanImpairmentBusinessLoans.map(({ field, ...rest }) => (
          <GloInput
            key={field}
            value={data.loanImpairment.businessLoans[field]}
            onChange={onChangeNested("loanImpairment", "businessLoans", field)}
            {...rest}
          />
        ))}
        <h4>Other</h4>
        {loanImpairment.map(({ field, ...rest }) => (
          <GloInput
            key={field}
            // @ts-expect-error fuck hey typescript
            value={data.loanImpairment[field]}
            onChange={onChange("loanImpairment", field)}
            {...rest}
          />
        ))}
      </Group>
      <Group title="Operating Costs">
        <h4>Opening Cost to Asset Ratios</h4>
        {operatingCostsOpeningCostToAssetRatios.map(({ field, ...rest }) => (
          <GloInput
            key={field}
            value={data.operatingCosts.openingCostToAssetRatio[field]}
            onChange={onChangeNested(
              "operatingCosts",
              "openingCostToAssetRatio",
              field,
            )}
            {...rest}
          />
        ))}
        <h4>Variable Cost to Asset Ratios</h4>
        {operatingCostsCostToAssetRatios.map(({ field, ...rest }) => (
          <GloInput
            key={field}
            value={data.operatingCosts.costToAssetRatio[field]}
            onChange={onChangeNested(
              "operatingCosts",
              "costToAssetRatio",
              field,
            )}
            {...rest}
          />
        ))}
        <h4>People Costs</h4>
        <GloInput
          label="People Costs (% of Total)"
          value={data.operatingCosts.peopleCosts}
          onChange={onChange("operatingCosts", "peopleCosts")}
          type="percentage"
        />
      </Group>
    </VerticalGroup>
  );
};
export default CostsAndLosses;
