import React, { ReactNode, useCallback } from "react";
import StandardModal from "../standard-modal/StandardModal";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import Button from "../../atoms/button/Button";
import Input from "../../atoms/form/input/Input";

import useForm from "../../../hooks/useForm";
import ErrorModal from "../standard-modal/ErrorModal";
import Text from "../../atoms/text/Text";

interface Props {
  isOpen: boolean;
  confirmTitle?: string;
  confirmInProgress?: boolean;
  onConfirm: () => void;
  onClose: () => void;
  onCancel: () => void;
  title: string;
  error: string | null;
  confirmingMessage?: string | null;
  challengeQuestion: string | ReactNode;
  challengeAnswer: string;
  challengeAnswerWarning?: string;
  disabled?: boolean;
  warning?: boolean;
}

function ChallengeModal({
  isOpen,
  onClose,
  title,
  confirmTitle,
  onConfirm,
  onCancel,
  confirmInProgress,
  confirmingMessage,
  error,
  challengeQuestion,
  challengeAnswer,
  challengeAnswerWarning,
  disabled,
  warning,
}: Props) {
  const [
    {
      formData: { challengeAnswerState },
      fieldErrors,
    },
    { setField, handleSubmit },
  ] = useForm({
    challengeAnswerState: "",
  });

  const callback = useCallback(() => {
    onConfirm();
    return;
  }, [onConfirm]);

  const validate = useCallback(() => {
    const errors = [];

    if (challengeAnswer !== challengeAnswerState) {
      errors.push({
        field: "challengeAnswerState",
        message: challengeAnswerWarning || "Answer is not valid!",
      });
    }

    return errors;
  }, [challengeAnswerState, challengeAnswer, challengeAnswerWarning]);

  if (error) {
    return <ErrorModal isOpen onClose={onClose} description={error} />;
  }

  return (
    <StandardModal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      description={challengeQuestion}
    >
      <>
        <form
          onSubmit={handleSubmit(callback, validate)}
          id="challenge-modal-form"
          className="mt-2"
        >
          <InlineGroup className="mt-4">
            {confirmingMessage && (
              <Text colour="green">{confirmingMessage}</Text>
            )}
          </InlineGroup>
          <Input
            type="text"
            value={challengeAnswerState}
            onChange={setField("challengeAnswerState")}
            error={fieldErrors.challengeAnswerState}
            data-test="modal-challenge-answer"
            block
            autoFocus
          />
        </form>
        <InlineGroup className="mt-4" spread>
          {!!onCancel && (
            <Button
              data-test="modal-cancel"
              light
              onClick={onCancel}
              disabled={confirmInProgress}
              large
            >
              Cancel
            </Button>
          )}
          <Button
            data-test="modal-confirm-question"
            type="submit"
            form="challenge-modal-form"
            inProgress={confirmInProgress}
            disabled={confirmInProgress || disabled}
            warning={warning}
            large
          >
            {confirmTitle || "Yes"}
          </Button>
        </InlineGroup>
      </>
    </StandardModal>
  );
}

export default ChallengeModal;
