import React, { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import ReportingTemplateForm from "../ReportingTemplateForm";
import API from "../../../../services/api";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import Container from "../../../atoms/page/Page";
import Banner from "../../../atoms/banner/Banner";
import useReportingTemplate from "../../../../hooks/useReportingTemplate";

function ReportingTemplateEdit() {
  const { reportingTemplateId } = useParams<{ reportingTemplateId: string }>();
  const { inProgress, data, error } = useReportingTemplate(reportingTemplateId);

  const onSave = useCallback(
    (data: API.ReportingTemplateRequest) => {
      return API.editTemplate("reporting", reportingTemplateId, data);
    },
    [reportingTemplateId],
  );

  const history = useHistory();

  const onCancel = useCallback(() => {
    history.push(`/reporting-templates/${reportingTemplateId}/view`);
  }, [history, reportingTemplateId]);

  const onComplete = useCallback(
    (data) => {
      history.push(`/reporting-templates/${data.id}/view`);
    },
    [history],
  );

  return (
    <Container>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {data && (
        <ReportingTemplateForm
          mode="edit"
          template={data}
          onSave={onSave}
          onCancel={onCancel}
          onComplete={onComplete}
        />
      )}
    </Container>
  );
}

export default ReportingTemplateEdit;
