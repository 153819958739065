import { useState, useEffect } from "react";

const useChartStyles = () => {
  const [chartStyles, setChartStyles] = useState({
    strokeWidth: 3,
    circleRadius: 5,
  });

  useEffect(() => {
    const updateChartStyles = () => {
      let strokeWidth = 8;
      let circleRadius = 10;

      if (smallScreen.matches) {
        strokeWidth = 1.5;
        circleRadius = 3;
      } else if (mediumScreen.matches) {
        strokeWidth = 2.5;
        circleRadius = 6;
      }

      setChartStyles({ strokeWidth, circleRadius });
    };

    const largeScreen = window.matchMedia("(max-width: 3000px)");
    const mediumScreen = window.matchMedia("(max-width: 2000px)");
    const smallScreen = window.matchMedia("(max-width: 820px)");

    largeScreen.addEventListener("change", updateChartStyles);
    mediumScreen.addEventListener("change", updateChartStyles);
    smallScreen.addEventListener("change", updateChartStyles);

    updateChartStyles();

    return () => {
      largeScreen.removeEventListener("change", updateChartStyles);
      mediumScreen.removeEventListener("change", updateChartStyles);
      smallScreen.removeEventListener("change", updateChartStyles);
    };
  }, []);

  return chartStyles;
};

export default useChartStyles;
