import React, { useCallback, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Icon, { IconType } from "../../../atoms/icon/Icon";
import Text from "../../../atoms/text/Text";
import HoverableIcon from "../../../atoms/icon/HoverableIcon";
import { shortDate } from "../../../../lib/date";
import Clickable from "../../../atoms/clickable/Clickable";
import ToggleEvent from "../event/components/ToggleEvent";
import DeleteEvent from "../event/components/DeleteEvent";
import StickyBar from "../../../organisms/sticky-bar/StickyBar";
import EmptyList from "../../../organisms/empty-list/EmptyList";
import IconButton from "../../../molecules/iconbutton/IconButton";
import RoleRequired from "../../../molecules/role-required/RoleRequired";
import Tooltip from "../../../atoms/tooltip/Tooltip";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import { ThemeColours } from "../../../../types/theme";
import { useCurrentUser } from "../../../../context/userContext";
import FacilitatorHidden from "../../../molecules/facilitator-hidden/FacilitatorHidden";
import { CellProps, Column } from "react-table";
import SortableTable from "../../../organisms/sortable-table/Sortable-table";

const TOOLTIP_ID = "event-table-tooltip";

export interface EventsWithStatus extends API.EventResponseSummary {
  status?: GameAPI.EventStatus["status"];
  statusText?: string;
  statusIcon?: IconType;
  statusColour?: ThemeColours;
}

interface Props {
  events: EventsWithStatus[];
  refreshEvents: () => void;
  showStatus?: boolean;
}

const getStreamName = (event: API.EventResponseSummary) => {
  let result = "-";
  if (event.streamName) {
    result = event.streamName;
  }
  return result;
};

interface EventState {
  eventModalOpen: boolean;
  deleteModalOpen: boolean;
  eventSelected: API.EventResponseSummary | null;
}

const STICKY_BAR_HEIGHT = 8;

function EventsTable({ events, refreshEvents, showStatus }: Props) {
  const user = useCurrentUser();
  const showStickybar = useMemo(() => {
    return user?.type !== "facilitator";
  }, [user?.type]);

  const [{ eventModalOpen, deleteModalOpen, eventSelected }, setEventState] =
    useState<EventState>({
      eventModalOpen: false,
      deleteModalOpen: false,
      eventSelected: null,
    });
  const history = useHistory();

  const onEventToggleComplete = useCallback(() => {
    setEventState((prevState) => ({
      ...prevState,
      eventModalOpen: false,
      eventSelected: null,
    }));
    refreshEvents();
  }, [refreshEvents]);

  const onEventDeleteComplete = useCallback(() => {
    setEventState((prevState) => ({
      ...prevState,
      deleteModalOpen: false,
      eventSelected: null,
    }));
    refreshEvents();
  }, [refreshEvents]);

  const handleDeleteClick = useCallback(
    (e: API.EventResponseSummary) => (event: React.MouseEvent) => {
      event.stopPropagation();
      setEventState({
        eventModalOpen: false,
        deleteModalOpen: true,
        eventSelected: e,
      });
    },
    [],
  );

  const handleCloseClick = useCallback(
    (e: API.EventResponseSummary) => (event: React.MouseEvent) => {
      event.stopPropagation();
      setEventState({
        eventModalOpen: true,
        deleteModalOpen: false,
        eventSelected: e,
      });
    },
    [],
  );

  const handleEventClick = useCallback(
    (event: API.EventResponseSummary) => {
      history.push(`/events/${event.id}/view`);
    },
    [history],
  );

  const handleCloneClick = useCallback(
    (e: API.EventResponseSummary) => (event: React.MouseEvent) => {
      event.stopPropagation();
      history.push(`/events/${e.id}/clone`);
    },
    [history],
  );

  const onRowClick = useCallback(
    (
      event: { stopPropagation: () => void; preventDefault: () => void },
      row: { original: API.EventResponseSummary },
    ) => {
      event.stopPropagation();
      event.preventDefault();
      handleEventClick(row.original);
    },
    [handleEventClick],
  );

  const columns = useMemo<Column<EventsWithStatus>[]>(() => {
    const baseColumns: Column<EventsWithStatus>[] = [
      {
        Header: "Event Date",
        accessor: "date",
        Cell: ({ value }: CellProps<EventsWithStatus, string>) => (
          <>{shortDate(value)}</>
        ),
      },
      {
        Header: "Client",
        accessor: "client",
      },
      {
        Header: "Event Name",
        accessor: "name",
      },
      {
        Header: "Stream",
        accessor: (row: EventsWithStatus) => getStreamName(row),
      },
      {
        Header: "Pax #",
        accessor: "participantsCount",
      },
    ];

    if (showStatus) {
      baseColumns.push({
        Header: "Status",
        accessor: (row: EventsWithStatus) => row.statusText,
        Cell: ({ row }: CellProps<EventsWithStatus>) => {
          const original = row.original;
          return (
            <InlineGroup verticalCenter>
              {original.statusIcon && (
                <Icon
                  noMargin
                  type={original.statusIcon}
                  colour={original.statusColour}
                />
              )}
              <Text style={{ marginLeft: 4 }} colour={original.statusColour}>
                {original.statusText}
              </Text>
            </InlineGroup>
          );
        },
      });
    }

    baseColumns.push({
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: ({ row }: CellProps<EventsWithStatus>) => {
        const e = row.original;
        return (
          <InlineGroup
            onClick={(event: React.MouseEvent) => event.stopPropagation()}
          >
            <Icon
              type="search"
              tt={{ content: "View Event", id: TOOLTIP_ID }}
              onClick={(event: React.MouseEvent) => {
                event.stopPropagation();
                handleEventClick(e);
              }}
            />
            <FacilitatorHidden>
              <Clickable
                data-test={`clone-${e.id}`}
                onClick={(event: React.MouseEvent) =>
                  handleCloneClick(e)(event)
                }
              >
                <Icon
                  type="copy"
                  tt={{
                    id: "clone-event",
                  }}
                />
              </Clickable>
            </FacilitatorHidden>
            <Clickable
              data-test={`toggle-${e.id}`}
              onClick={(event: React.MouseEvent) => handleCloseClick(e)(event)}
            >
              <HoverableIcon
                hoverType={e.closed ? "unlock" : "lock"}
                type={e.closed ? "lock" : "unlock"}
                tt={{
                  id: e.closed ? "closed-event" : "open-event",
                }}
              />
            </Clickable>
            <FacilitatorHidden>
              {e.closed && (
                <Clickable
                  data-test={`delete-${e.id}`}
                  onClick={(event: React.MouseEvent) =>
                    handleDeleteClick(e)(event)
                  }
                >
                  <Icon
                    type="trash"
                    tt={{
                      content: "Delete event",
                      id: TOOLTIP_ID,
                    }}
                  />
                </Clickable>
              )}
            </FacilitatorHidden>
          </InlineGroup>
        );
      },
    });

    return baseColumns;
  }, [
    handleCloneClick,
    handleCloseClick,
    handleDeleteClick,
    handleEventClick,
    showStatus,
  ]);

  return (
    <>
      <Tooltip id={TOOLTIP_ID} />
      <Tooltip id="clone-event">
        <>
          <b>Clone Event</b>
          <p>
            Create a new event using the details <br /> of this event as a
            starting point.
          </p>
        </>
      </Tooltip>
      <Tooltip id="closed-event">
        <>
          <b>Open Event</b>
          <p>
            This will re-open the event and it <br />
            will be accessible to participants.
          </p>
        </>
      </Tooltip>
      <Tooltip id="open-event">
        <>
          <b>Close Event</b>
          <p>
            This will close the event. Participants <br />
            will no longer be able to access the event.
          </p>
        </>
      </Tooltip>
      {eventModalOpen && !!eventSelected && (
        <ToggleEvent
          isOpen={eventModalOpen}
          onClose={() =>
            setEventState((prevState) => ({
              ...prevState,
              eventModalOpen: false,
              eventSelected: null,
            }))
          }
          onComplete={onEventToggleComplete}
          event={eventSelected}
        />
      )}
      {deleteModalOpen && !!eventSelected && (
        <DeleteEvent
          isOpen={deleteModalOpen}
          onClose={() =>
            setEventState((prevState) => ({
              ...prevState,
              deleteModalOpen: false,
              eventSelected: null,
            }))
          }
          onComplete={onEventDeleteComplete}
          event={eventSelected}
        />
      )}
      {showStickybar && (
        <StickyBar height={STICKY_BAR_HEIGHT}>
          <FacilitatorHidden>
            <Link data-test="add-event" to="/events/add">
              <IconButton icon="addEvent" text="Add Event" />
            </Link>
          </FacilitatorHidden>
          {process.env.REACT_APP_ALLOW_IMPORT_EVENTS === "true" && (
            <RoleRequired roles={["superadmin"]}>
              <Link to="/events/import">
                <IconButton text="Import Event" icon="import" />
              </Link>
            </RoleRequired>
          )}
        </StickyBar>
      )}
      {!events.length && (
        <EmptyList icon="events" message="There are no events yet" />
      )}
      {!!events.length && (
        <SortableTable
          columns={columns}
          data={events}
          stickyHeader={showStickybar}
          stickyTop={STICKY_BAR_HEIGHT}
          clickable
          onRowClick={onRowClick}
          showCheckboxes={false}
          defaultSortBy={[{ id: "date", desc: false }]}
        />
      )}
    </>
  );
}

export default EventsTable;
