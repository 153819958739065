import React, { useCallback, useMemo, useState } from "react";
import Text from "../../../../atoms/text/Text";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import { useTabletConfigContext } from "../context/context";
import Grid from "../../../../atoms/grid/Grid";
import ClickableBox from "../../../../molecules/clickable-box/ClickableBox";
import { fullDate } from "../../../../../lib/date";
import ConfigAddModal from "./ConfigAddModal";
import ClearConfigForEvent from "../components/ClearConfigForEvent";
import Clickable from "../../../../atoms/clickable/Clickable";
import Icon from "../../../../atoms/icon/Icon";

interface Props {
  refresh: () => void;
}

const ConfigurationEvents: React.FC<Props> = ({ refresh }) => {
  const [showModal, setShowModal] = useState(false);
  const [eventIdForDelete, setEventIdForDelete] = useState<string | null>();
  const {
    eventData,
    selectedEventId,
    updateSelectedEventId,
    tabletDataPerEvent,
  } = useTabletConfigContext();

  const existingEventConfigurations = useMemo(() => {
    return tabletDataPerEvent ? Object.keys(tabletDataPerEvent) : [];
  }, [tabletDataPerEvent]);

  const onDeleteClick = useCallback(
    (eventId: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      setEventIdForDelete(eventId);
    },
    [],
  );

  return (
    <VerticalGroup wide spaceBetweenElements={5}>
      {eventIdForDelete && (
        <ClearConfigForEvent
          eventId={eventIdForDelete}
          isOpen
          onClose={() => setEventIdForDelete(null)}
          onConfirm={refresh}
        />
      )}
      <Text bold size="lg">
        Select Event Configuration
      </Text>
      <Grid>
        {existingEventConfigurations.map((eventId) => {
          const ed = eventData[eventId];
          if (!ed) return null;
          return (
            <ClickableBox
              key={eventId}
              onClick={() => updateSelectedEventId(eventId)}
              active={selectedEventId === eventId}
              style={{ position: "relative" }}
            >
              <Clickable
                onClick={onDeleteClick(eventId)}
                style={{
                  position: "absolute",
                  top: "0%",
                  right: "0%",
                  padding: 2,
                }}
              >
                <Icon size={4} colour="secondaryBlue" type="trash" />
              </Clickable>
              <Text>{ed.client}</Text>
              <Text>{ed.name}</Text>
              <Text>{fullDate(ed.date)}</Text>
            </ClickableBox>
          );
        })}
        <ClickableBox onClick={() => setShowModal(true)} active={false}>
          <VerticalGroup wide center verticalCenter full>
            <Text>+ Add Configuration</Text>
          </VerticalGroup>
        </ClickableBox>
      </Grid>
      <ConfigAddModal isOpen={showModal} onClose={() => setShowModal(false)} />
    </VerticalGroup>
  );
};

export default ConfigurationEvents;
