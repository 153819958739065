import React from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";
import { formatValue, percentage } from "../utils/formatters";
import pluckResultsByType from "../utils/pluckResultsByType";
import useModelQuery from "../utils/useModelQuery";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["shareCapital"];
}

const transferPricingAllocationsFieldMap: { [key: string]: string } = {
  year1: "1 Year",
  year2: "2 Years",
  year3: "3 Years",
  year4: "4 Years",
  year5: "5 Years",
  year6: "6 Years",
  year7: "7 Years",
  year8: "8 Years",
  year9: "9 Years",
  year10: "10 Years",
};

const ModelTeamResultsTransferPricing: React.FC<Props> = ({ data }) => {
  const fundingAllocationsData = pluckResultsByType(data, "fundingAllocations");
  const transferPricingAllocationsData = pluckResultsByType(
    data,
    "transferPricingAllocations",
  );
  const assetBehavioursData = pluckResultsByType(data, "assetBehaviours");
  const { openIfTable } = useModelQuery();
  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <TableWithSections
          header="Funding Allocations"
          data={[
            {
              name: "Asset Average Balances",
              fields: [
                "cash",
                "HOME_LOANS",
                "BUSINESS_LOANS",
                "CREDIT_CARDS",
                "WEALTH_MANAGEMENT",
                "INSTITUTIONAL_BANKING",
                "OFFSHORE_BANK",
                "otherFixedAssets",
                "investments",
                "total",
              ],
              totalFields: ["total"],
              values: pluckResultsByType(
                fundingAllocationsData,
                "assetAverageBalances",
              ),
            },
            {
              name: "Liability Average Balances",
              fields: ["DEPOSITS", "wholesaleFunding", "total"],
              totalFields: ["total"],
              values: pluckResultsByType(
                fundingAllocationsData,
                "liabilityAverageBalances",
              ),
            },
            {
              name: "",
              fields: ["fundingGap"],
              values: fundingAllocationsData,
            },
            {
              name: "Asset Average Capital Balances",
              fields: [
                "cash",
                "HOME_LOANS",
                "BUSINESS_LOANS",
                "CREDIT_CARDS",
                "WEALTH_MANAGEMENT",
                "INSTITUTIONAL_BANKING",
                "OFFSHORE_BANK",
                "otherFixedAssets",
                "investments",
                "total",
              ],
              totalFields: ["total"],
              values: pluckResultsByType(
                fundingAllocationsData,
                "assetAverageCapitalBalances",
              ),
            },
            {
              name: "Asset Average Balances To Be Funded",
              fields: [
                "cash",
                "HOME_LOANS",
                "BUSINESS_LOANS",
                "CREDIT_CARDS",
                "WEALTH_MANAGEMENT",
                "INSTITUTIONAL_BANKING",
                "OFFSHORE_BANK",
                "otherFixedAssets",
                "investments",
                "total",
                "averageLiabilities",
                "variance",
              ],
              totalFields: ["total"],
              values: pluckResultsByType(
                fundingAllocationsData,
                "assetAverageBalancesToBeFunded",
              ),
            },
          ]}
        />
        <TableWithSections
          header="Transfer Pricing Allocations"
          openOnInit={openIfTable("transferPricingAllocations")}
          data={[
            {
              name: "",
              fields: ["differenceBetween1YearAnd10YearTransferPricing"],
              values: transferPricingAllocationsData,
              formatter: (value: number, field: string, index: number) => {
                if (index < 10) {
                  return percentage(value, 2);
                }
                return "-";
              },
            },
            {
              name: "Average Asset Totals By",
              fields: [
                "year1",
                "year2",
                "year3",
                "year4",
                "year5",
                "year6",
                "year7",
                "year8",
                "year9",
                "year10",
                "totalAssetAverageBalancesToBeFunded",
                "weightedAverageWsfRateAdjustment",
                "wsfRate",
              ],
              totalFields: ["totalAssetAverageBalancesToBeFunded"],
              values: pluckResultsByType(
                transferPricingAllocationsData,
                "averageAssetTotalsBy",
              ),
              fieldFormatter: (field: string) =>
                transferPricingAllocationsFieldMap[field],
              formatter: (value: number, field: string) => {
                if (
                  ["weightedAverageWsfRateAdjustment", "wsfRate"].includes(
                    field,
                  )
                ) {
                  return percentage(value, 2);
                }
                return null;
              },
            },
            {
              name: "Transfer Pricing Curve",
              fields: [
                "year1",
                "year2",
                "year3",
                "year4",
                "year5",
                "year6",
                "year7",
                "year8",
                "year9",
                "year10",
                "weightedAverageTransferPricingRate",
                "variance",
              ],
              values: pluckResultsByType(
                transferPricingAllocationsData,
                "transferPricingCurve",
              ),
              fieldFormatter: (field: string) =>
                transferPricingAllocationsFieldMap[field],
              formatter: (value: number) => percentage(value, 2),
            },
            {
              name: "Treasury Division /Transfer Pricing Income",
              fields: [
                "HOME_LOANS",
                "BUSINESS_LOANS",
                "CREDIT_CARDS",
                "WEALTH_MANAGEMENT",
                "INSTITUTIONAL_BANKING",
                "OFFSHORE_BANK",
                "otherFixedAssets",
                "interestOnCash",
                "total",
              ],
              totalFields: ["total"],
              values: pluckResultsByType(
                transferPricingAllocationsData,
                "treasuryDivisionTransferPricingIncome",
              ),
            },
            {
              name: "Treasury Division /Transfer Pricing Expenses",
              fields: ["DEPOSITS", "wholesaleFunding", "total"],
              totalFields: ["total"],
              values: pluckResultsByType(
                transferPricingAllocationsData,
                "treasuryDivisionTransferPricingExpenses",
              ),
              formatter: (val) => formatValue(-1 * val, 2),
            },
            {
              fields: ["treasuryNetTransferPricingIncome"],
              isTotalsSection: true,
              values: transferPricingAllocationsData,
            },
          ]}
        />
        <TableWithSections
          header="Asset Behaviours"
          openOnInit={openIfTable("assetBehaviours")}
          data={[
            {
              name: "",
              fields: ["cashLiquidAssets"],
              values: assetBehavioursData,
              fieldFormatter: () => "Cash / Liquid Assets",
            },
            {
              name: "Home Loans",
              fields: [
                "openingBalance",
                "year1",
                "year2",
                "year3",
                "year4",
                "year5",
              ],
              values: pluckResultsByType(assetBehavioursData, "HOME_LOANS"),
            },
            {
              name: "Business Lending",
              fields: [
                "openingBalance",
                "year1",
                "year2",
                "year3",
                "year4",
                "year5",
              ],
              values: pluckResultsByType(assetBehavioursData, "BUSINESS_LOANS"),
            },
            {
              name: "Credit Cards",
              fields: [
                "openingBalance",
                "year1",
                "year2",
                "year3",
                "year4",
                "year5",
              ],
              values: pluckResultsByType(assetBehavioursData, "CREDIT_CARDS"),
            },
            {
              name: "Wealth Management",
              fields: [
                "openingBalance",
                "year1",
                "year2",
                "year3",
                "year4",
                "year5",
              ],
              values: pluckResultsByType(
                assetBehavioursData,
                "WEALTH_MANAGEMENT",
              ),
            },
            {
              name: "Institutional Banking",
              fields: [
                "openingBalance",
                "year1",
                "year2",
                "year3",
                "year4",
                "year5",
              ],
              values: pluckResultsByType(
                assetBehavioursData,
                "INSTITUTIONAL_BANKING",
              ),
            },
            {
              name: "Offshore Bank",
              fields: [
                "openingBalance",
                "year1",
                "year2",
                "year3",
                "year4",
                "year5",
              ],
              values: pluckResultsByType(assetBehavioursData, "OFFSHORE_BANK"),
            },
            {
              name: "Other Fixed Assets",
              fields: [
                "openingBalance",
                "year1",
                "year2",
                "year3",
                "year4",
                "year5",
              ],
              values: pluckResultsByType(
                assetBehavioursData,
                "otherFixedAssets",
              ),
            },
            {
              name: "Total",
              fields: [
                "openingBalance",
                "year1",
                "year2",
                "year3",
                "year4",
                "year5",
                "total",
                "totalAssetsPerBalanceSheet",
                "investments",
                "totalAssetsNetOfEquityInvestments",
                "variance",
              ],
              totalFields: ["total", "totalAssetsNetOfEquityInvestments"],
              highlightFields: ["variance"],
              values: pluckResultsByType(assetBehavioursData, "total"),
            },
            {
              name: "Transaction Accounts",
              fields: [
                "transaction.openingBalance",
                "transaction.year1",
                "transaction.year2",
                "transaction.year3",
                "transaction.year4",
                "transaction.year5",
              ],
              values: pluckResultsByType(assetBehavioursData, "DEPOSITS"),
            },
            {
              name: "Savings Accounts",
              fields: [
                "savings.openingBalance",
                "savings.year1",
                "savings.year2",
                "savings.year3",
                "savings.year4",
                "savings.year5",
              ],
              values: pluckResultsByType(assetBehavioursData, "DEPOSITS"),
            },
            {
              name: "Total Accounts",
              fields: [
                "total.year1",
                "total.year2",
                "total.year3",
                "total.year4",
                "total.year5",
              ],
              values: pluckResultsByType(assetBehavioursData, "DEPOSITS"),
            },
          ]}
        />
      </VerticalGroup>
    </>
  );
};

export default ModelTeamResultsTransferPricing;
