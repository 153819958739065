import React, { forwardRef, useRef } from "react";
import { startCase } from "../../../../../services/utils";
import Card from "../../../../atoms/card/Card";
import Clickable from "../../../../atoms/clickable/Clickable";
import Label from "../../../../atoms/form/label/Label";
import Icon from "../../../../atoms/icon/Icon";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import EditableText, {
  Handles as EditableTextHandles,
} from "../../../../atoms/text/EditableText";
import Text from "../../../../atoms/text/Text";

const isMultiLine = (type: string, name: string) =>
  type === "team" && ["placeholderStrategy", "defaultStrategy"].includes(name);

const overrides: { [key: string]: string } = {
  defaultStrategy: "presetStrategy",
  placeholderStrategy: "exampleStrategy",
};

const EditableField = ({
  onChange,
  value,
  field,
  label,
  fieldType,
}: {
  onChange: (value: string) => void;
  value: string;
  field: string;
  label: string;
  fieldType: "text" | "textbox";
}) => {
  const ref = useRef<EditableTextHandles>(null);
  return (
    <InlineGroup verticalCenter>
      <InlineGroup>
        <Label>
          <Text bold size="sm">
            {overrides[label] ?? startCase(label)}
          </Text>
        </Label>
      </InlineGroup>
      <InlineGroup spaceBetweenElements={2}>
        <EditableText
          size="sm"
          ref={ref}
          type={fieldType}
          onChange={onChange}
          value={value}
        />
        <Clickable onClick={() => ref.current?.setIsEditing(true)}>
          <Icon
            noMargin
            type="edit"
            tip={{ content: `Edit ${startCase(field)}`, id: `edit-${field}` }}
          />
        </Clickable>
      </InlineGroup>
    </InlineGroup>
  );
};

interface Props {
  item: API.LabelConfigResponse;
  onChange: (value: string) => void;
  isDraggable?: boolean;
  draggableProps?: Record<string, any>;
  dragHandleProps?: Record<string, any>;
}

export const LabelByType = forwardRef<HTMLDivElement, Props>((props, ref) => {
  LabelByType.displayName = "LabelByType";
  const { item, onChange, isDraggable = false } = props;

  return (
    <Card wide sharp ref={ref} {...props.draggableProps}>
      <InlineGroup block spread>
        <EditableField
          fieldType={
            isMultiLine(props.item.type, props.item.name) ? "textbox" : "text"
          }
          onChange={onChange}
          value={item.value}
          field="value"
          label={item.name}
        />
        {isDraggable && (
          <Clickable {...props.dragHandleProps}>
            <Icon noMargin type="draggable" />
          </Clickable>
        )}
      </InlineGroup>
    </Card>
  );
});
