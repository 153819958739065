import React, { useCallback, useContext, useEffect } from "react";

import API from "../../../../../services/api";
import Banner from "../../../../atoms/banner/Banner";
import Form from "../../../../atoms/form/Form";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import { SimulationContext } from "../../context";
import useForm from "../../../../../hooks/useForm";
import CountryDropdown from "./CountryDropdown";
import CurrencyDropdown from "./CurrencyDropdown";
import DenominationDropdown from "./DenominationDropdown";
import RegulatorDropdown from "./RegulatorDropdown";
import CentralBankDropdown from "./CentralBankDropdown";
import ReferenceRateDropdown from "./ReferenceRateDropdown";
import Text from "../../../../atoms/text/Text";

interface Preset {
  currency: API.Currency;
  denomination: API.Denomination;
  regulator: API.Regulator;
  centralBank: API.CentralBank;
  referenceRate: API.ReferenceRate;
  startingFinancials: API.Country;
}

export const presets: Record<API.Country, Preset> = {
  australia: {
    currency: "AUD",
    denomination: "Millions",
    regulator: "APRA",
    centralBank: "rba",
    referenceRate: "BBSW",
    startingFinancials: "australia",
  },
  new_zealand: {
    currency: "NZD",
    denomination: "Millions",
    regulator: "RBNZ",
    centralBank: "rbnz",
    referenceRate: "NZD_BBSW",
    startingFinancials: "new_zealand",
  },
  usa: {
    currency: "USD",
    denomination: "Millions",
    regulator: "Fed",
    centralBank: "fed",
    referenceRate: "Fed_Funds_Rate",
    startingFinancials: "usa",
  },
  united_kingdom: {
    currency: "GBP",
    denomination: "Millions",
    regulator: "PRA",
    centralBank: "boe",
    referenceRate: "LIBOR",
    startingFinancials: "united_kingdom",
  },
  europe: {
    currency: "EUR",
    denomination: "Millions",
    regulator: "EBA",
    centralBank: "ecb",
    referenceRate: "EUIRBOR",
    startingFinancials: "europe",
  },
  indonesia: {
    currency: "IDR",
    denomination: "Billions",
    regulator: "OJK",
    centralBank: "bi",
    referenceRate: "JIBOR",
    startingFinancials: "indonesia",
  },
  japan: {
    currency: "JPY",
    denomination: "Billions",
    regulator: "FSA",
    centralBank: "boj",
    referenceRate: "JIBOR",
    startingFinancials: "japan",
  },
  china: {
    currency: "RMB",
    denomination: "Millions",
    regulator: "CBIRC",
    centralBank: "pbc",
    referenceRate: "SHIBOR",
    startingFinancials: "china",
  },
  singapore: {
    currency: "SGD",
    denomination: "Millions",
    regulator: "MAS",
    centralBank: "mas",
    referenceRate: "SIBOR",
    startingFinancials: "singapore",
  },
  small_market: {
    currency: "AUD",
    denomination: "Millions",
    regulator: "APRA",
    centralBank: "rba",
    referenceRate: "BBSW",
    startingFinancials: "small_market",
  },
  medium_market: {
    currency: "AUD",
    denomination: "Millions",
    regulator: "APRA",
    centralBank: "rba",
    referenceRate: "BBSW",
    startingFinancials: "medium_market",
  },
  big_market: {
    currency: "AUD",
    denomination: "Millions",
    regulator: "APRA",
    centralBank: "rba",
    referenceRate: "BBSW",
    startingFinancials: "big_market",
  },
};

const Region: React.FC = () => {
  const { config, updateConfig, assignOnSubmitFunction, updateFormStatus } =
    useContext(SimulationContext);
  const [
    {
      formData: {
        country,
        currency,
        denomination,
        regulator,
        centralBank,
        referenceRate,
        startingFinancials,
      },
      error,
      fieldErrors,
      inProgress,
      formUpdated,
    },
    { setDropdownField, setFieldSimple, handleSubmit },
  ] = useForm(
    config?.regionalSetup
      ? {
          ...config.regionalSetup,
        }
      : {
          country: "australia",
          currency: "AUD",
          denomination: "Millions",
          regulator: "APRA",
          centralBank: "rba",
          referenceRate: "BBSW",
          startingFinancials: "australia",
        },
  );

  const onSubmit = useCallback(async () => {
    if (config) {
      const payload: API.SimulationUpdateRequest = {
        regionalSetup: {
          country,
          currency,
          denomination,
          regulator,
          centralBank,
          referenceRate,
          startingFinancials,
        },
      };

      const response = await API.editSimulation(config.id, payload);

      updateConfig(response);
      return response;
    }
  }, [
    config,
    country,
    currency,
    denomination,
    referenceRate,
    regulator,
    centralBank,
    startingFinancials,
    updateConfig,
  ]);

  useEffect(() => {
    assignOnSubmitFunction(handleSubmit(onSubmit));
  }, [assignOnSubmitFunction, handleSubmit, onSubmit]);

  useEffect(() => {
    updateFormStatus({
      inProgress: inProgress,
      formUpdated: formUpdated,
    });
  }, [updateFormStatus, inProgress, formUpdated]);

  const updateCountry = useCallback(
    (selectedValue: { value: API.Country; label: string }) => {
      setFieldSimple("country")(selectedValue.value);
      const preset = presets[selectedValue.value];

      for (const [key, value] of Object.entries(preset)) {
        setFieldSimple(key)(value);
      }
    },
    [setFieldSimple],
  );

  return (
    <div className="step-container">
      <VerticalGroup spread full>
        <VerticalGroup wide>
          <Banner type="error" active={!!error} message={error?.message} />
          <Form id="simulation-add-form">
            <h3>Market Settings</h3>
            <CountryDropdown
              label="Country"
              onChange={updateCountry}
              value={country}
              error={fieldErrors["regionalSetup.country"]}
            />
            <Text size="sm" bold>
              Cosmetic Market Settings
            </Text>
            <CurrencyDropdown
              label="Currency"
              onChange={setDropdownField("currency")}
              value={currency}
              error={fieldErrors["regionalSetup.currency"]}
            />
            <DenominationDropdown
              label="Denomination"
              onChange={setDropdownField("denomination")}
              value={denomination}
              error={fieldErrors["regionalSetup.denomination"]}
            />
            <CentralBankDropdown
              label="Central Bank"
              onChange={setDropdownField("centralBank")}
              value={centralBank}
              error={fieldErrors["regionalSetup.centralBank"]}
            />
            <RegulatorDropdown
              label="Regulator"
              onChange={setDropdownField("regulator")}
              value={regulator}
              error={fieldErrors["regionalSetup.regulator"]}
            />
            <ReferenceRateDropdown
              label="Reference Rate"
              onChange={setDropdownField("referenceRate")}
              value={referenceRate}
              error={fieldErrors["regionalSetup.referenceRate"]}
            />
            <Text size="sm" bold>
              Financials And Metrics
            </Text>
            <CountryDropdown
              label="Starting Financials"
              onChange={setDropdownField("startingFinancials")}
              value={startingFinancials}
              name="starting-financials-dropdown"
              error={fieldErrors["regionalSetup.startingFinancials"]}
            />
          </Form>
        </VerticalGroup>
      </VerticalGroup>
    </div>
  );
};

export { default as RegionDisplay } from "./Display";
export default Region;
