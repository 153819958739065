import React from "react";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../../../atoms/loadingspinner/LoadingSpinner";
import Container from "../../../../atoms/page/Page";
import Banner from "../../../../atoms/banner/Banner";
import { useEvent } from "../../../../../hooks/useEvent";
import EventForm from "..";

function CloneEvent() {
  const { eventId } = useParams<{ eventId: string }>();

  const { inProgress, error, data } = useEvent(eventId);

  if (data) {
    return <EventForm event={data} mode="clone" />;
  }

  return (
    <Container>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
    </Container>
  );
}

export default CloneEvent;
