import React, { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import Page from "../../../atoms/page/Page";
import GroupForm from "./GroupForm";
import API from "../../../../services/api";
import { useAssessment } from "../../../../hooks/useAssessments";
import Banner from "../../../atoms/banner/Banner";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";

const AssessmentAddGroup: React.FC = () => {
  const { assessmentId } = useParams<{ assessmentId: string }>();
  const { inProgress, data, error } = useAssessment(assessmentId);
  const history = useHistory();

  const onSave = useCallback(
    async (
      payload: API.AssessmentGroupRequest,
    ): Promise<API.AssessmentGroupResponse> => {
      return API.createAssessmentGroup(assessmentId, payload);
    },
    [assessmentId],
  );

  const onFinish = useCallback(
    (data?: API.AssessmentGroupResponse) => {
      history.push(
        `/assessments/${assessmentId}/view${data != null ? `?groupId=${data.id}` : ""}`,
      );
    },
    [history, assessmentId],
  );

  return (
    <Page>
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {inProgress && <LoadingSpinner />}
      {!!data && !inProgress && (
        <GroupForm
          assessment={data}
          onSave={onSave}
          onComplete={onFinish}
          onCancel={onFinish}
        />
      )}
    </Page>
  );
};

export default AssessmentAddGroup;
