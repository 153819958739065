import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
} from "react";
import ReactTooltip from "react-tooltip";
import API from "../../../../../../services/api";
import DeleteParticipant from "./DeleteParticipant";
import ParticipantImport from "./ParticipantImport";
import StickyBar from "../../../../../organisms/sticky-bar/StickyBar";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import IconButton from "../../../../../molecules/iconbutton/IconButton";
import Text from "../../../../../atoms/text/Text";
import AddParticipantModal from "./AddParticipantModal";
import EditParticipantModal from "./EditParticipantModal";
import SendEmailModal from "./SendEmailModal";
import ErrorModal from "../../../../../organisms/standard-modal/ErrorModal";
import Dropdown from "../../../../../atoms/form/input/Dropdown";
import PrintParticipantsPage from "./PrintParticipantsPage";
import PrintParticipantsAttendancePage from "./PrintParticipantsAttendancePage";
import ParticipantsTable from "./ParticipantsTable";
import { CellProps, Column } from "react-table";
import Clickable from "../../../../../atoms/clickable/Clickable";
import Icon from "../../../../../atoms/icon/Icon";
import PrintOnly from "../../../../../atoms/printable/components/PrintOnly";

interface Props {
  participants: API.ParticipantResponse[];
  refreshEvent: () => void;
  data: any;
  event: API.EventResponse;
}

export interface ParticipantState {
  importModalOpen?: boolean;
  deleteParticipantModalOpen: boolean;
  addParticipantModalOpen: boolean;
  editParticipantModalOpen: boolean;
  participantToDelete: API.ParticipantResponse | null;
  selectedParticipant: API.ParticipantResponse | null;
  sendEmailModalOpen?: boolean;
}

const STICKY_BAR_HEIGHT = 20;

function ParticipantsSection({
  participants,
  refreshEvent,
  data,
  event,
}: Props) {
  const [
    {
      importModalOpen,
      deleteParticipantModalOpen,
      participantToDelete,
      addParticipantModalOpen,
      editParticipantModalOpen,
      selectedParticipant,
      sendEmailModalOpen,
    },
    setParticipantModalState,
  ] = useState<ParticipantState>({
    importModalOpen: false,
    deleteParticipantModalOpen: false,
    participantToDelete: null,
    addParticipantModalOpen: false,
    editParticipantModalOpen: false,
    selectedParticipant: null,
    sendEmailModalOpen: false,
  });

  const [selected, setSelected] = useState<API.ParticipantResponse[]>([]);
  const { id: eventId } = event;

  const handleExport = () => {
    if (data) API.exportParticipants(data);
  };

  const handleExportAttendance = () => {
    if (data) API.exportAttendanceList(data);
  };
  const handleExportParticipantsList = () => {
    if (data) API.exportParticipantsList(data);
  };

  const onParticipantDeleteComplete = useCallback(() => {
    setParticipantModalState((prevState) => ({
      ...prevState,
      deleteParticipantModalOpen: false,
      participantToDelete: null,
    }));
    refreshEvent();
  }, [refreshEvent]);

  const onParticipantImportComplete = useCallback(() => {
    setParticipantModalState((prevState) => ({
      ...prevState,
      importModalOpen: false,
    }));
    refreshEvent();
  }, [refreshEvent]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const deleteParticipant = useCallback(
    (participant: API.ParticipantResponse) =>
      setParticipantModalState((prevState) => ({
        ...prevState,
        deleteParticipantModalOpen: true,
        participantToDelete: participant,
      })),
    [],
  );

  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const navigateParticipant = useCallback(
    (step: number) => {
      if (!selectedParticipant) return;

      const currentIndex = event.participants.findIndex(
        (p: API.ParticipantResponse) => p.id === selectedParticipant.id,
      );
      let nextIndex = currentIndex + step;
      if (nextIndex < 0) {
        nextIndex = event.participantsCount - 1;
      } else if (nextIndex >= event.participantsCount) {
        nextIndex = 0;
      }
      setParticipantModalState((prevState) => ({
        ...prevState,
        editParticipantModalOpen: true,
        selectedParticipant: event.participants[nextIndex],
      }));
    },
    [event.participants, event.participantsCount, selectedParticipant],
  );

  const participantActions = [
    { label: "Add Participant", id: "add", iconType: "addUser" },
    { label: "Import Participants", id: "import", iconType: "import" },
    { label: "Export Participants", id: "export", iconType: "export" },

    {
      label: "Print Participant List",
      id: "print-participants-list",
      iconType: "printParticipants",
    },
    {
      label: "Download Participant List",
      id: "download-participants-list",
      iconType: "excelFile",
    },

    {
      label: "Print Attendance List",
      id: "print-attendance-list",
      iconType: "printAttendance",
    },
    {
      label: "Download Attendance List",
      id: "download-attendance-list",
      iconType: "excelFile",
    },
    {
      label: "Delete Selected",
      id: "delete",
      iconType: "trash",
      optionColour: "danger",
      iconColour: "danger",
    },
  ];
  const printListRef = useRef<any>(null);
  const printAttendanceRef = useRef<any>(null);

  const handleActionSelect = (selectedOption: any) => {
    switch (selectedOption.id) {
      case "add":
        setParticipantModalState((prevState) => ({
          ...prevState,
          addParticipantModalOpen: true,
        }));
        break;
      case "import":
        setParticipantModalState((prevState) => ({
          ...prevState,
          importModalOpen: true,
        }));
        break;
      case "export":
        handleExport();
        break;
      case "delete":
        setParticipantModalState((prevState) => ({
          ...prevState,
          deleteParticipantModalOpen: true,
        }));
        break;
      case "print-participants-list":
        if (printListRef.current) {
          printListRef.current.handlePrint();
        }
        break;
      case "download-participants-list":
        if (printListRef.current) {
          handleExportParticipantsList();
        }
        break;
      case "print-attendance-list":
        if (printAttendanceRef.current) {
          printAttendanceRef.current.handlePrint();
        }
        break;
      case "download-attendance-list":
        if (printAttendanceRef.current) {
          handleExportAttendance();
        }
        break;
      default:
        console.log("No action");
    }
  };
  const handleEditParticipant = useCallback(
    (participant: API.ParticipantResponse) => {
      setParticipantModalState((prevState) => ({
        ...prevState,
        editParticipantModalOpen: true,
        selectedParticipant: participant,
      }));
    },
    [],
  );

  const handleDeleteParticipant = useCallback(
    (participant: API.ParticipantResponse) => {
      setParticipantModalState((prevState) => ({
        ...prevState,
        deleteParticipantModalOpen: true,
        participantToDelete: participant,
      }));
    },
    [],
  );

  const handleSelectedRowsChange = useCallback(
    (selectedRows: API.ParticipantResponse[]) => {
      setSelected(selectedRows);
    },
    [],
  );

  const handleShowError = useCallback((errorMsg: string) => {
    setErrorMsg(errorMsg);
    setShowError(true);
  }, []);
  const columns: Column<API.ParticipantResponse>[] = useMemo(
    () => [
      {
        Header: "Team #",
        accessor: "team",
      },
      {
        Header: "CEO",
        accessor: (row) => (row.isCeo ? "Yes" : "No"),
      },
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Email Status",
        id: "welcomeEmailStatus",
        accessor: (row) => {
          if (row.welcomeEmailSent) {
            return "Sent";
          } else if (!row.welcomeEmailSent && row.welcomeEmailError) {
            return (
              <Clickable
                onClick={(event) => {
                  event.stopPropagation();
                  handleShowError(row.welcomeEmailError ?? "");
                }}
              >
                <InlineGroup verticalCenter>
                  <div>Error</div> <Icon type="error" />{" "}
                </InlineGroup>
              </Clickable>
            );
          } else {
            return "Not Sent";
          }
        },
        Cell: ({ value }: CellProps<API.ParticipantResponse, any>) => {
          return <>{value}</>; // Ensure it returns JSX (ReactElement)
        },
      },
      {
        Header: "Actions",
        id: "actions",
        Cell: ({ row }: { row: { original: API.ParticipantResponse } }) => (
          <>
            <Clickable
              data-test={`view-${row.original.id}`}
              onClick={(event) => {
                event.stopPropagation();
                handleEditParticipant(row.original);
              }}
            >
              <Icon type="search" tip={{ content: "View participant" }} />
            </Clickable>
            <Clickable
              data-test={`delete-${row.original.id}`}
              onClick={(event) => {
                event.stopPropagation();
                handleDeleteParticipant(row.original);
              }}
            >
              <Icon type="trash" tip={{ content: "Delete participant" }} />
            </Clickable>
          </>
        ),
      },
    ],
    [handleEditParticipant, handleDeleteParticipant, handleShowError],
  );
  return (
    <>
      <PrintOnly>
        <PrintParticipantsPage
          participants={participants}
          event={event}
          ref={printListRef}
        />
        <PrintParticipantsAttendancePage
          participants={participants}
          event={event}
          ref={printAttendanceRef}
        />
      </PrintOnly>
      <div>
        <ErrorModal
          isOpen={showError}
          onClose={() => setShowError(false)}
          title="Email Error"
          description={errorMsg}
        />

        <DeleteParticipant
          isOpen={deleteParticipantModalOpen}
          participants={participantToDelete ? [participantToDelete] : selected}
          eventId={eventId}
          allParticipants={participants}
          onClose={() => {
            setParticipantModalState((prevState) => ({
              ...prevState,
              deleteParticipantModalOpen: false,
              participantToDelete: null,
            }));
          }}
          onComplete={onParticipantDeleteComplete}
        />
        {importModalOpen && (
          <ParticipantImport
            isOpen={importModalOpen}
            onClose={() =>
              setParticipantModalState((prevState) => ({
                ...prevState,
                importModalOpen: false,
              }))
            }
            onComplete={onParticipantImportComplete}
            eventId={eventId}
          />
        )}
        {addParticipantModalOpen && (
          <AddParticipantModal
            isOpen={addParticipantModalOpen}
            onClose={() =>
              setParticipantModalState((prevState) => ({
                ...prevState,
                addParticipantModalOpen: false,
              }))
            }
            onComplete={() => {
              setParticipantModalState((prevState) => ({
                ...prevState,
                addParticipantModalOpen: false,
              }));
              refreshEvent();
            }}
            event={event}
          />
        )}
        {selectedParticipant && (
          <EditParticipantModal
            isOpen={editParticipantModalOpen}
            onClose={() =>
              setParticipantModalState((prevState) => ({
                ...prevState,
                editParticipantModalOpen: false,
                selectedParticipant: null,
              }))
            }
            onComplete={(dismissPopup: boolean) => {
              setParticipantModalState((prevState) => ({
                ...prevState,
                editParticipantModalOpen: !dismissPopup,
                selectedParticipant: null,
              }));
              refreshEvent();
            }}
            event={event}
            participant={selectedParticipant}
            onNextParticipant={() => navigateParticipant(1)}
            onPrevParticipant={() => navigateParticipant(-1)}
            deleteParticipant={deleteParticipant}
          />
        )}
        {sendEmailModalOpen && (
          <SendEmailModal
            onComplete={() =>
              setParticipantModalState((prevState) => ({
                ...prevState,
                sendEmailModalOpen: false,
              }))
            }
            participantCount={selected.length}
            isOpen={sendEmailModalOpen}
            onClose={() =>
              setParticipantModalState((prevState) => ({
                ...prevState,
                sendEmailModalOpen: false,
              }))
            }
            eventId={eventId}
            participants={selected}
          />
        )}

        <StickyBar
          spread
          verticalCenter
          height={STICKY_BAR_HEIGHT}
          style={{ backgroundColor: "white" }}
        >
          <InlineGroup block spread reverse>
            <Dropdown
              selectProps={{
                options: participantActions,
                onChange: handleActionSelect,
                placeholder: "Participant Actions",
                isSearchable: false,
              }}
            />
            <IconButton
              data-test="send-emails"
              icon="email"
              text="Send Welcome Email's"
              onClick={() =>
                setParticipantModalState((prevState) => ({
                  ...prevState,
                  sendEmailModalOpen: true,
                }))
              }
            />
          </InlineGroup>
        </StickyBar>
        {!data.participants.length && (
          <Text colour="secondaryDark3" size="lg">
            There are no participants yet
          </Text>
        )}
        {!!data.participants.length && (
          <ParticipantsTable
            participants={participants}
            event={event}
            onEditParticipant={handleEditParticipant}
            onDeleteParticipant={handleDeleteParticipant}
            onSelectedRowsChange={handleSelectedRowsChange}
            onShowError={handleShowError}
            stickyTop={STICKY_BAR_HEIGHT}
            columns={columns}
            showCheckboxes
          />
        )}
      </div>
    </>
  );
}

export default ParticipantsSection;
