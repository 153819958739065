import React from "react";
import classNames from "classnames";
import { ThemeColours } from "../../../../types/theme";
import ResultsText from "../results-text/ResultsText";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";

export const DotAndText: React.FC<{
  text: string;
  dotColour: ThemeColours;
  num?: number;
  dotSize?: string;
  isLast?: boolean;
  isFirst?: boolean;
  marginTop?: string;
  textSize?: string;
  hasTopSeparator?: boolean;
  noLines?: boolean;
}> = ({
  text,
  num,
  dotColour,
  dotSize,
  isLast = false,
  isFirst = false,
  textSize = "lg",
  noLines = false,
}) => {
  const dotColourClass = `bg-colour-${dotColour}`;
  const sizeClass = dotSize ? `size-${dotSize}` : null;
  return (
    <div className="dot-and-text-container">
      <InlineGroup center style={{ position: "relative" }}>
        {!isFirst && !noLines && (
          <div className={classNames("line", "top-line")} />
        )}
        <div
          className={classNames("options-dot", dotColourClass, sizeClass)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: dotSize,
            height: dotSize,
            zIndex: 2,
          }}
        >
          <ResultsText size="sm" bold colour="white">
            {num}
          </ResultsText>
        </div>
        {!isLast && !noLines && (
          <div className={classNames("line", "bottom-line")} />
        )}
      </InlineGroup>
      <div>
        <ResultsText size={textSize}>{text}</ResultsText>
      </div>
    </div>
  );
};
