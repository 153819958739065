import React from "react";
import { MultipleChoiceFieldPreview } from "./MultipleChoiceFieldPreview";
import { WrittenResponseFieldPreview } from "./WrittenResponseFieldPreview";
import { CheckboxesFieldPreview } from "./CheckboxesFieldPreview";
import { ImageFieldPreview } from "./ImageFieldPreview";
import { WrittenTextPreview } from "./WrittenTextPreview";

interface FieldProps {
  question: API.AssessmentQuestionResponse;
  field: API.AssessmentQuestionFieldResponse;
  answer?: API.AssessmentAnswerResponse["answers"][0]["answer"];
  isAnswerMode?: boolean;
}
export const Field: React.FC<FieldProps> = ({
  field,
  question,
  answer,
  isAnswerMode = false,
}) => {
  switch (field.type) {
    case "multiple_choices":
      return (
        <MultipleChoiceFieldPreview
          field={field}
          question={question}
          answer={answer}
          isAnswerMode={isAnswerMode}
        />
      );
    case "written_response":
      return (
        <WrittenResponseFieldPreview
          field={field}
          question={question}
          answer={answer}
          isAnswerMode={isAnswerMode}
        />
      );
    case "checkboxes":
      return (
        <CheckboxesFieldPreview
          field={field}
          question={question}
          answer={answer}
          isAnswerMode={isAnswerMode}
        />
      );
    case "image":
      return <ImageFieldPreview field={field} />;
    case "written_text":
      return <WrittenTextPreview field={field} />;
    default:
      return <div>unknown question type</div>;
  }
};
