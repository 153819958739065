import React from "react";

import Container from "../../../../../atoms/page/Page";
import SimpleModal from "../../../../../organisms/standard-modal/SimpleModal";
import AssessmentPreview from "../../../../../organisms/assessment-preview";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  assessmentId: string;
}

const AssessmentPreviewModal: React.FC<Props> = ({
  assessmentId,
  isOpen,
  onClose,
}) => {
  return (
    <SimpleModal size="large" isOpen={isOpen} showCloseButton onClose={onClose}>
      <Container className="simulation-edit">
        <h2>Assessment Preview</h2>
        <AssessmentPreview assessmentId={assessmentId} />
      </Container>
    </SimpleModal>
  );
};

export default AssessmentPreviewModal;
