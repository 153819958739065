import React, { useCallback, useEffect, useReducer, useMemo } from "react";
import { useHistory } from "react-router-dom";

import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Container from "../../../atoms/page/Page";
import Button from "../../../atoms/button/Button";
import CancelButton from "../../../atoms/form/actions/CancelButton";
import Stepper, {
  useStepperContextValue,
  StepperContext,
} from "../../../organisms/stepper/Stepper";
import Step from "../../../organisms/stepper/Step";
import useQuery from "../../../../hooks/useQuery";
import { reducer, EventState } from "./state";
import { EventContext, useEventContextValue } from "./context";
import Details from "./steps/details";
import Branding from "./steps/branding";

import ConfirmModal from "../../../organisms/confirm-modal/ConfirmModal";
import Assessment from "./steps/assessment";
import Participants from "./steps/participants";
import Schedule from "./steps/schedule";
import Settings from "./steps/run";
import Simulation from "./steps/simulation";
import Scrollable from "../../../atoms/scrollable/Scrollable";
import { Noop } from "../../../../constants/functions";
import { useCurrentUser } from "../../../../context/userContext";

export const Breadcrumb = () => {
  const query = useQuery();
  const stepTitle = query.get("stepTitle");
  return <span>Add New Event - {stepTitle}</span>;
};

export const PageHeader = () => {
  const query = useQuery();
  const header = query.get("pageHeader");
  return <span>Add New Event - {header}</span>;
};

const stateInit: EventState = {
  steps: [
    {
      title: "Event Details",
      content: <Details />,
      key: "name",
    },
    {
      title: "Branding",
      content: <Branding />,
      key: "branding",
    },
    {
      title: "Simulation Settings",
      content: <Simulation />,
      key: "simulation",
    },
    {
      title: "Round Schedule",
      content: <Schedule />,
      key: "schedule",
    },
    {
      title: "Assessment",
      content: <Assessment />,
      key: "assessment",
    },
    {
      title: "Participants",
      content: <Participants />,
      key: "participantsCount",
      submitButtonText: "Build Simulation",
    },
    {
      title: "Run The Simulation",
      content: <Settings />,
      key: "run-simulation",
      disabledMessage: "The game is not ready yet.",
      hideNavigationButtons: true,
    },
  ],
  formStatus: {
    inProgress: false,
    formUpdated: false,
    mode: "create",
  },
  onSubmitPrevious: async () => console.warn("No onSubmit action assigned"),
  onSubmitNext: async () => console.warn("No onSubmit action assigned"),
  isJumpStepModalOpen: false,
};

interface Props {
  event?: API.EventResponse;
  refresh?: () => Promise<API.EventResponse | undefined>;
  initialActiveStepKey?: string;
  mode?: EventState["formStatus"]["mode"];
}

const CreateEvent: React.FC<Props> = ({
  event,
  refresh,
  initialActiveStepKey,
  mode = "edit",
}) => {
  const history = useHistory();
  const query = useQuery();
  const [state, dispatch] = useReducer(
    reducer,
    {
      ...stateInit,
      config: event,
    },
    (state) => {
      return {
        ...state,
        steps: state.steps,
        formStatus: {
          ...state.formStatus,
          mode,
        },
      };
    },
  );

  const currentUser = useCurrentUser();

  const filteredSteps = useMemo(() => {
    if (currentUser?.type === "facilitator") {
      return state.steps.filter(
        (step) => step.key !== "assessment" || event?.assessment != null,
      );
    }
    return state.steps;
  }, [currentUser?.type, event?.assessment, state.steps]);

  useEffect(() => {
    if (event?.isGameSetup === true) {
      dispatch({
        type: "UpdateStepMeta",
        payload: {
          key: "run-simulation",
          data: {
            disabled: false,
          },
        },
      });
    } else {
      dispatch({
        type: "UpdateStepMeta",
        payload: {
          key: "run-simulation",
          data: {
            disabled: true,
          },
        },
      });
    }
  }, [event?.isGameSetup]);

  const initialActiveStepIndex = useMemo(() => {
    const index = filteredSteps.findIndex(
      (step) => step.key === initialActiveStepKey,
    );
    return index !== -1 ? index : 0;
  }, [initialActiveStepKey, filteredSteps]);
  const stepperContext = useStepperContextValue(
    state.steps.length,
    initialActiveStepIndex,
  );
  const { activeIndex, jumpToStep, backStep, forwardStep } = stepperContext;

  const steps = filteredSteps;
  const activeStep = steps[activeIndex];

  useEffect(() => {
    let completeSteps = 0;
    let numberOfSteps = steps.length;
    if (state.config) {
      const stepKeys = steps
        .map((step) => step.key)
        .filter((key) => key !== "assessment");
      numberOfSteps = stepKeys.length;
      completeSteps = Object.entries(state.config).filter(
        ([key, val]) =>
          stepKeys.includes(key) &&
          val !== null &&
          (typeof val === "number" ? val > 0 : true),
      ).length;
    }
    const completePercentage = `${(
      (completeSteps / (numberOfSteps - 1)) *
      100
    ).toFixed(0)}%`;

    const currentTitle = activeStep.title;
    const currentHeader = `${activeStep.title} ${completePercentage} complete`;
    const eventTitle = state.config
      ? `${state.config.name} ${
          state.config.streamName ? `(${state.config.streamName})` : ""
        }`
      : "";
    const clientName = state.config?.client ?? "";
    if (
      query.get("stepTitle") !== currentTitle ||
      query.get("pageHeader") !== currentHeader ||
      query.get("eventTitle") !== eventTitle ||
      query.get("clientName") !== clientName
    ) {
      query.set("stepTitle", currentTitle);
      query.set("pageHeader", currentHeader);
      query.set("eventTitle", eventTitle);
      query.set("clientName", clientName);
      query.delete("stepKey");
      history.push({ search: query.toString() });
    }
  }, [history, query, activeStep, activeIndex, state.config, steps]);

  const onCancelNavigation = useCallback(() => {
    return history.push("/events");
  }, [history]);

  const isFirstStep = activeIndex === 0;
  const isLastStep = activeIndex + 1 === steps.length;
  const submitButtonText =
    activeStep.submitButtonText || (isLastStep ? "Publish" : "Save & Next");

  const onNextStep = useCallback(async () => {
    if ((await state.onSubmitNext()) === false) return;
    if (isLastStep) {
      history.push(`/events/${state.config?.id}/view`);
    } else {
      forwardStep();
    }
  }, [forwardStep, history, isLastStep, state]);

  const onPrevStep = useCallback(async () => {
    if ((await state.onSubmitPrevious()) === false) return;
    backStep();
  }, [backStep, state]);

  const onJumpStepModalClose = useCallback(() => {
    dispatch({
      type: "SetJumpStepModalStatus",
      payload: {
        isJumpStepModalOpen: false,
        jumpStepModalConfirmAction: undefined,
      },
    });
  }, []);

  const onJumpStep = useCallback(
    (index: number) => {
      if (!state.config?.id) return;

      if (state.formStatus.formUpdated) {
        dispatch({
          type: "SetJumpStepModalStatus",
          payload: {
            isJumpStepModalOpen: true,
            jumpStepModalConfirmAction: () => {
              jumpToStep(index);
              onJumpStepModalClose();
            },
          },
        });
      } else {
        jumpToStep(index);
      }
    },
    [
      jumpToStep,
      onJumpStepModalClose,
      state.config?.id,
      state.formStatus.formUpdated,
    ],
  );

  const eventContext = useEventContextValue(
    state,
    dispatch,
    jumpToStep,
    refresh,
  );

  return (
    <StepperContext.Provider value={stepperContext}>
      <EventContext.Provider value={eventContext}>
        <Container column noOverflow>
          <Stepper direction="horizontal">
            {steps.map((step) => {
              return (
                <Step
                  key={step.title}
                  title={step.title}
                  onClick={onJumpStep}
                  disabledMessage={
                    step.disabledMessage ??
                    "This step is disabled until Step 1 is complete"
                  }
                  disabled={
                    state.config?.id == null ||
                    step.disabled ||
                    state.formStatus.mode === "clone"
                  }
                  complete={Object.keys(state.config || {}).some(
                    (key) => key === step.key,
                  )}
                  data-test={step.key}
                />
              );
            })}
          </Stepper>
          <VerticalGroup wide full spread style={{ height: "92%" }}>
            <Scrollable wide>{activeStep.content}</Scrollable>
            {!activeStep.hideNavigationButtons ? (
              <InlineGroup block spread className="mt-4">
                <CancelButton
                  confirmModalProps={{
                    inProgress: state.formStatus.inProgress,
                    showModal: state.formStatus.formUpdated,
                    title: "Save Changes",
                    description:
                      "Do you want to save your changes to this step?",
                    confirmButtonText: "Save",
                    onConfirm: onNextStep,
                    onDiscard: onCancelNavigation,
                  }}
                  onClick={onCancelNavigation}
                />
                <InlineGroup block right spaceBetweenElements={2}>
                  {isFirstStep ? null : (
                    <Button
                      data-test="prevStep"
                      light
                      wide
                      onClick={onPrevStep}
                      inProgress={state.formStatus.inProgress}
                    >
                      Save & Back
                    </Button>
                  )}
                  <Button
                    data-test="nextStep"
                    wide
                    inProgress={state.formStatus.inProgress}
                    onClick={onNextStep}
                  >
                    {submitButtonText}
                  </Button>
                </InlineGroup>
              </InlineGroup>
            ) : null}
          </VerticalGroup>
        </Container>
        <ConfirmModal
          isOpen={state.isJumpStepModalOpen}
          onClose={onJumpStepModalClose}
          onCancel={onJumpStepModalClose}
          onConfirm={state.jumpStepModalConfirmAction || Noop}
          title={"Save Changes"}
          description={
            "You haven't saved the changes you made. Are you sure to continue?"
          }
          confirmTitle={"Continue"}
          warning
        />
      </EventContext.Provider>
    </StepperContext.Provider>
  );
};

export default CreateEvent;
