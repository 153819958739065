import React, { useCallback } from "react";
import ConfirmModal from "../../../../organisms/confirm-modal/ConfirmModal";

import useAPIRequest from "../../../../../hooks/useAPIRequest";
import API from "../../../../../services/api";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  event: API.EventResponseSummary;
}

function ToggleEvent({ isOpen, onClose, onComplete, event }: Props) {
  const callback = useCallback(() => {
    if (event.closed) {
      return API.openEvent(event.id);
    }
    return API.closeEvent(event.id);
  }, [event]);

  const [{ inProgress, error }, doAPIRequest] = useAPIRequest(callback, null);

  const handleConfirm = useCallback(() => {
    doAPIRequest(onComplete);
  }, [doAPIRequest, onComplete]);

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      title={`Confirm Event ${event.closed ? "Opening" : "Closure"}`}
      description={
        event.closed
          ? "Opening an event will make it accessible to participants"
          : "Closed events are no longer accessible to participants"
      }
      confirmTitle="Confirm"
      error={error && error.message}
    />
  );
}

export default ToggleEvent;
