import { useCallback, useEffect, useMemo } from "react";
import API from "../services/api";
import useAPIRequest from "./useAPIRequest";

const MAX_AGE = 500; // ms
const CACHE = new Map<string, Promise<API.AssessmentQuestionResponse>>();

export const useQuestion = (
  assessmentId: string,
  groupId: string,
  questionId: string,
) => {
  const callback = useCallback(() => {
    let cachedPromise = CACHE.get(assessmentId);
    if (cachedPromise) {
      return cachedPromise;
    }

    cachedPromise = API.getAssessmentQuesiton(
      assessmentId,
      groupId,
      questionId,
    );

    CACHE.set(groupId, cachedPromise);
    setTimeout(() => {
      CACHE.delete(groupId);
    }, MAX_AGE);

    return cachedPromise;
  }, [groupId, assessmentId, questionId]);

  const [{ inProgress, data, error }, doAPIRequest] =
    useAPIRequest<API.AssessmentQuestionResponse>(callback, null);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  return useMemo(
    () => ({
      inProgress,
      data,
      error,
      refresh: doAPIRequest,
    }),
    [inProgress, data, error, doAPIRequest],
  );
};
