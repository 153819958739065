import React from "react";

const CustomTick = (props: any) => {
  const { x, y, payload, onClick } = props;

  const labelsMap: { [key: string]: string } = {
    "1": "Hist1",
    "2": "Hist2",
    "3": "Hist3",
    "4": "Rnd1",
    "5": "Rnd2",
    "6": "Rnd3",
    "7": "Rnd4",
    "8": "Rnd5",
    "9": "Rnd6",
    "10": "Rev1",
    "11": "Rev2",
    "12": "Rev3",
    "13": "Rev4",
    "14": "Rev5",
  };

  const formattedValue = labelsMap[payload.value] || payload.value;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={19}
        fill="#666"
        textAnchor="middle"
        onClick={() => onClick(payload.value)}
        style={{ cursor: "pointer" }}
      >
        {formattedValue}
      </text>
    </g>
  );
};

export default CustomTick;
