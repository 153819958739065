import React from "react";

import Card from "../../../../atoms/card/Card";
import DisplayField from "../../../../atoms/form/display-field/DisplayField";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import Clickable from "../../../../atoms/clickable/Clickable";
import Icon from "../../../../atoms/icon/Icon";
import { getLabelFromValue as getCountryLabel } from "./CountryDropdown";
import { getLabelFromValue as getCurrencyLabel } from "./CurrencyDropdown";
import { getLabelFromValue as getDenominationLabel } from "./DenominationDropdown";
import { getLabelFromValue as getRegulatorLabel } from "./RegulatorDropdown";
import { getLabelFromValue as getCentralBankLabel } from "./CentralBankDropdown";
import { getLabelFromValue as getReferenceRateLabel } from "./ReferenceRateDropdown";
import AvoidPageBreak from "../../../../atoms/printable/components/AvoidPageBreak";

interface Props {
  data: API.SimulationResponse["regionalSetup"];
  onClickEdit?: () => void;
}

const Display: React.FC<Props> = ({ data, onClickEdit }) => {
  return data ? (
    <Card wide>
      <AvoidPageBreak>
        <InlineGroup verticalCenter>
          <h4>Market Settings</h4>
          {onClickEdit ? (
            <Clickable
              data-test={"edit-simulation-region-step"}
              onClick={onClickEdit}
            >
              <Icon
                type="edit"
                tip={{
                  content: "Edit Region",
                  id: "edit-region",
                }}
              />
            </Clickable>
          ) : null}
        </InlineGroup>
        <DisplayField label="Country" value={getCountryLabel(data.country)} />
        <DisplayField
          label="Currency"
          value={getCurrencyLabel(data.currency)}
        />
        <DisplayField
          label="Denomination"
          value={getDenominationLabel(data.denomination)}
        />
        <DisplayField
          label="Central Bank"
          value={getCentralBankLabel(data.centralBank)}
        />
        <DisplayField
          label="Regulator"
          value={getRegulatorLabel(data.regulator)}
        />
        <DisplayField
          label="Reference Rate"
          value={getReferenceRateLabel(data.referenceRate)}
        />
        <DisplayField
          label="Starting Financials"
          value={getCountryLabel(data.startingFinancials)}
        />
      </AvoidPageBreak>
    </Card>
  ) : null;
};

export default Display;
