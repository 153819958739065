import React from "react";
import classNames from "classnames";

import "./Table.scss";
import { ThemeColours, ThemeSpacing } from "../../../types/theme";

interface TdProps extends React.HTMLAttributes<HTMLTableCellElement> {
  className?: string;
  left?: boolean;
  center?: boolean;
  right?: boolean;
  highlight?: boolean;
  width?: ThemeSpacing;
  noBold?: boolean;
  borderLeft?: boolean;
  softHighlight?: boolean;
  dangerHighlight?: boolean;
  clickable?: boolean;
  colSpan?: number;
  rowSpan?: number;
}
const widthClass = (val?: number) => (val ? `width-${val}` : undefined);
export const Td: React.FC<TdProps> = ({
  children,
  className,
  left,
  center,
  right,
  highlight,
  width,
  borderLeft,
  softHighlight,
  dangerHighlight,
  clickable,
  colSpan,
  ...rest
}) => (
  <td
    colSpan={colSpan}
    className={classNames(
      className,
      {
        left,
        center,
        right,
        highlight,
        borderLeft,
        softHighlight,
        dangerHighlight,
        clickable,
      },
      widthClass(width),
    )}
    {...rest}
  >
    {children}
  </td>
);
export const Th: React.FC<TdProps> = ({
  children,
  className,
  left,
  center,
  right,
  highlight,
  width,
  noBold,
  borderLeft,
  softHighlight,
  ...rest
}) => (
  <th
    className={classNames(
      className,
      { left, center, right, highlight, noBold, borderLeft, softHighlight },
      widthClass(width),
    )}
    {...rest}
  >
    {children}
  </th>
);

interface TRProps extends React.HTMLAttributes<HTMLTableRowElement> {
  className?: string;
  colour?: ThemeColours;
  borderTop?: boolean;
  borderColour?: ThemeColours;
  bold?: boolean;
}
const colourClass = (val?: ThemeColours) => (val ? `colour-${val}` : undefined);
const borderColourClass = (val?: ThemeColours) =>
  val ? `border-colour-${val}` : undefined;
export const Tr: React.FC<TRProps> = ({
  children,
  className,
  colour,
  borderTop,
  borderColour,
  bold,
  ...rest
}) => (
  <tr
    className={classNames(
      className,
      { borderTop, border: !!borderColour, bold },
      colourClass(colour),
      borderColourClass(borderColour),
    )}
    {...rest}
  >
    {children}
  </tr>
);
export interface Props {
  id?: string;
  className?: string;
  children?: React.ReactNode;
  stickyHeader?: boolean;
  stickyTop?: ThemeSpacing;
  fixed?: boolean;
  clickable?: boolean;
}

function Table({
  id,
  className,
  children,
  stickyHeader,
  stickyTop,
  fixed,
  clickable,
}: Props) {
  const stickyTopClass = stickyTop ? `sticky-top-${stickyTop}` : null;
  return (
    <table
      id={id}
      className={classNames(
        "table",
        { "sticky-header": stickyHeader, fixed, clickable },
        stickyTopClass,
        className,
      )}
    >
      {children}
    </table>
  );
}

export default Table;
