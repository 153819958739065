import { useMemo } from "react";
import useElementSize from "./useElementSize";

interface Dimensions {
  previewHeight: number;
  previewWidth: number;
  availableHeight: number;
  availableWidth: number;
}

interface RenderedDimensions {
  width: number;
  height: number;
}

const useDimensions = (
  headingHeight: number,
  containerPadding: number,
  innerContainerPadding: number,
  RENDERED_DIMENSIONS: RenderedDimensions,
): [Dimensions, (node: HTMLElement | null) => void] => {
  const [setRef, containerSize] = useElementSize<HTMLElement>();

  const dimensions = useMemo(() => {
    if (containerSize.width && containerSize.height && headingHeight) {
      const heightAvailable =
        containerSize.height -
        headingHeight -
        containerPadding -
        innerContainerPadding;
      const widthAvailable =
        containerSize.width - containerPadding - innerContainerPadding;

      const scale = Math.min(
        1,
        Math.min(
          widthAvailable / RENDERED_DIMENSIONS.width,
          heightAvailable / RENDERED_DIMENSIONS.height,
        ),
      );
      return {
        previewHeight: scale * RENDERED_DIMENSIONS.height,
        previewWidth: scale * RENDERED_DIMENSIONS.width,
        availableHeight: heightAvailable,
        availableWidth: widthAvailable,
      };
    }
    return {
      previewHeight: 0,
      previewWidth: 0,
      availableHeight: 0,
      availableWidth: 0,
    };
  }, [
    containerSize,
    headingHeight,
    containerPadding,
    innerContainerPadding,
    RENDERED_DIMENSIONS,
  ]);

  return [dimensions, setRef];
};

export default useDimensions;
