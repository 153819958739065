import React from "react";

import WarningModal from "../../organisms/standard-modal/WarningModal";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import useIsMobileOrTablet from "../../../hooks/useIsMobileOrTablet";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  warnings: string[];
}

function WarningsModal({ isOpen, onClose, warnings }: Props) {
  const isMobileOrTablet = useIsMobileOrTablet();
  return (
    <WarningModal
      isOpen={isOpen}
      onClose={onClose}
      title="Warnings"
      width={!isMobileOrTablet ? "500px" : undefined}
      description={
        <VerticalGroup spaceBetweenElements={4}>
          The following issues have been detected:
          <br />
          <VerticalGroup>
            {warnings.map((warning, index) => (
              <div key={index}>{warning}</div>
            ))}
          </VerticalGroup>
        </VerticalGroup>
      }
    />
  );
}

export default WarningsModal;
