import React, { useState, useMemo, useCallback, useEffect } from "react";
import AssignTabletColourDropdown from "../assign-tablet-colour-dropdown/AssignTabletColourDropdown";
import Button from "../../../../../atoms/button/Button";
import SimpleModal from "../../../../../organisms/standard-modal/SimpleModal";
import Container from "../../../../../atoms/page/Page";
import Text from "../../../../../atoms/text/Text";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import Dropdown from "../../../../../atoms/form/input/Dropdown";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import { EventMapping } from "../../utils/mapInitialTabletMappingForEvent";
import { MergedData } from "../ConfigurationParticipants";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  availableTabletColors: string[];
  currentParticipant: MergedData;
  updateTabletColor: (
    participantId: string,
    colour: API.TabletColour,
    tabletId: number,
  ) => void;
  tabletData: EventMapping;
}

function AssignTabletModal({
  isOpen,
  onClose,
  availableTabletColors,
  currentParticipant,
  updateTabletColor,
  tabletData,
}: Props) {
  const [selectedColor, setSelectedColor] = useState<string>(
    currentParticipant.tabletColour || "",
  );
  const [selectedTabletId, setSelectedTabletId] = useState<number | undefined>(
    currentParticipant.tabletId,
  );
  useEffect(() => {
    setSelectedTabletId(undefined);
  }, [selectedColor]);
  const tabletsOfSelectedColour = useMemo(() => {
    let tablets = tabletData.tablets
      .filter(
        (tablet) =>
          tablet.colour === selectedColor &&
          tablet.available &&
          tablet.participantId == null,
      )
      .map((tablet) => ({
        id: tablet.tabletId,
        label: ` ${tablet.tabletId}`,
      }));

    if (currentParticipant.tabletId) {
      const assignedTablet = tabletData.tablets.find(
        (tablet) => tablet.tabletId === currentParticipant.tabletId,
      );
      if (
        assignedTablet &&
        !tablets.find((t) => t.id === assignedTablet.tabletId) &&
        selectedColor === currentParticipant.tabletColour
      ) {
        tablets = [
          {
            id: assignedTablet.tabletId,
            label: `${assignedTablet.tabletId}`,
          },
          ...tablets,
        ];
      }
    }
    return tablets;
  }, [selectedColor, tabletData.tablets, currentParticipant]);
  useEffect(() => {
    if (tabletsOfSelectedColour.length > 0) {
      setSelectedTabletId(tabletsOfSelectedColour[0].id);
    } else {
      setSelectedTabletId(undefined);
    }
  }, [tabletsOfSelectedColour]);

  const onSave = useCallback(() => {
    if (currentParticipant && selectedTabletId) {
      updateTabletColor(
        currentParticipant.participantId,
        selectedColor as API.TabletColour,
        selectedTabletId,
      );
    }
    onClose();
  }, [
    currentParticipant,
    selectedColor,
    selectedTabletId,
    updateTabletColor,
    onClose,
  ]);

  return (
    <SimpleModal size="medium" isOpen={isOpen} onClose={onClose}>
      <VerticalGroup spaceBetweenElements={2}>
        <Text size="lg" bold>
          Assign Tablet: {currentParticipant?.firstName}{" "}
        </Text>
        <Container fit>
          <VerticalGroup spaceBetweenElements={2}>
            <AssignTabletColourDropdown
              availableTabletColors={availableTabletColors}
              onColorSelect={(color) => setSelectedColor(color)}
              editselectedColor={selectedColor}
            />
            {selectedColor && (
              <Dropdown
                block
                label="Tablet ID"
                selectProps={{
                  options: tabletsOfSelectedColour,
                  placeholder: "Select Tablet ID",
                  onChange: (option: { id: number }) =>
                    setSelectedTabletId(option.id),
                  value: tabletsOfSelectedColour.find(
                    (option) => option.id === selectedTabletId,
                  ),
                }}
              />
            )}
          </VerticalGroup>
        </Container>
        <InlineGroup block spread>
          <Button light outline large onClick={onClose}>
            Close
          </Button>
          <Button large onClick={onSave}>
            Save
          </Button>
        </InlineGroup>
      </VerticalGroup>
    </SimpleModal>
  );
}

export default AssignTabletModal;
