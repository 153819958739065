import React, { useCallback, useContext, useEffect } from "react";

import API from "../../../../../services/api";
import Banner from "../../../../atoms/banner/Banner";
import Form from "../../../../atoms/form/Form";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import { SimulationContext } from "../../context";
import useForm from "../../../../../hooks/useForm";
import IntegerDropdown from "../../../../organisms/integer-dropdown/IntegerDropdown";
import CpuStrategyDropdown from "./CpuStrategyDropdown";
import Text from "../../../../atoms/text/Text";

const Players: React.FC = () => {
  const { config, updateConfig, assignOnSubmitFunction, updateFormStatus } =
    useContext(SimulationContext);
  const [
    {
      formData: { teams, cpuTeams, cpuStrategies },
      inProgress,
      error,
      formUpdated,
    },
    { setDropdownField, setFieldRaw, handleSubmit },
  ] = useForm(
    config?.playersSetup
      ? {
          teams: config.playersSetup?.teams,
          cpuTeams: config.playersSetup?.cpuStrategies.length,
          cpuStrategies: config.playersSetup?.cpuStrategies,
        }
      : {
          teams: 4,
          cpuTeams: 0,
          cpuStrategies: [],
        },
  );

  const onSubmit = useCallback(async () => {
    if (config) {
      const payload: API.SimulationUpdateRequest = {
        playersSetup: {
          teams,
          cpuStrategies,
        },
      };

      const response = await API.editSimulation(config.id, payload);

      updateConfig(response);
      return response;
    }
  }, [config, teams, cpuStrategies, updateConfig]);

  const validate = useCallback(() => {
    const errors = [];
    if (teams < 4 || teams > 20) {
      errors.push({
        field: "teams",
        message: "Number of teams can only be between 4 and 20",
      });
    }

    if (cpuTeams < 0 || cpuTeams > 20) {
      errors.push({
        field: "cpuTeams",
        message: "Number of computer teams can only be between 0 and 20",
      });
    }

    return errors;
  }, [cpuTeams, teams]);

  useEffect(() => {
    assignOnSubmitFunction(handleSubmit(onSubmit, validate));
  }, [assignOnSubmitFunction, handleSubmit, onSubmit, validate]);

  useEffect(() => {
    updateFormStatus({
      inProgress: inProgress,
      formUpdated: formUpdated,
    });
  }, [updateFormStatus, inProgress, formUpdated]);

  const updateStrategy = useCallback(
    (index: number) => {
      return (option: { value: string; label: string }) => {
        cpuStrategies[index] = option.value;
        setFieldRaw("cpuStrategies", [...cpuStrategies]);
      };
    },
    [cpuStrategies, setFieldRaw],
  );

  const updateCpuTeams = useCallback(
    (option: { value: number }) => {
      const updatedCpuStrategies = Array(option.value);
      for (let i = 0; i < option.value; i++) {
        // Fill empty slot with default
        if (cpuStrategies[i] === undefined) {
          updatedCpuStrategies[i] = "default";
        } else {
          updatedCpuStrategies[i] = cpuStrategies[i];
        }
      }
      setFieldRaw("cpuTeams", option.value);
      setFieldRaw("cpuStrategies", [...updatedCpuStrategies]);
    },
    [cpuStrategies, setFieldRaw],
  );

  return (
    <div className="step-container">
      <VerticalGroup spread full>
        <VerticalGroup wide>
          <Banner type="error" active={!!error} message={error?.message} />
          <Form id="simulation-add-form">
            <h3>Player Settings</h3>
            <IntegerDropdown
              label="Number of Human Teams"
              onChange={setDropdownField("teams")}
              value={teams}
              name="teams-dropdown"
              min={4}
              max={20}
            />
            <IntegerDropdown
              label="Number of Computer Teams"
              onChange={updateCpuTeams}
              value={cpuTeams}
              name="cpu-teams-dropdown"
              min={0}
              max={20}
            />
            {cpuTeams > 0 ? (
              <Text size="sm" bold>
                Choose the Automated/Computer Bank Strategies
              </Text>
            ) : null}
            {cpuStrategies.map((strategy: string, i: number) => (
              <CpuStrategyDropdown
                key={i}
                label={`Automated Bank #${i + 1}`}
                value={strategy}
                onChange={updateStrategy(i)}
                name={`cpu-strategy-dropdown-${i + 1}`}
              />
            ))}
          </Form>
        </VerticalGroup>
      </VerticalGroup>
    </div>
  );
};

export { default as PlayersDisplay } from "./Display";
export default Players;
