// step 9
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import {
  FinancialSummaryMecticItem,
  financialSummaryMetricItems,
} from "./data";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import InformationPopup from "../../../../organisms/information-popup/InformationPopup";
import Text from "../../../../atoms/text/Text";
import Dropdown from "../../../../atoms/form/input/Dropdown";
import {
  DraggableItemsPool,
  DragAndDropItem,
  Dropzone,
  DRAGGABLE_ITEMS_POOL_ID,
  DROPPABLE_AREA_PREFIX,
} from "../../../../organisms/draganddrop";
import { DraggableView, SelectedView } from "../../../../organisms/draganddrop";

interface Props {
  defaultSelected?: API.SimulationUpdateRequest["financialSummaryMetrics"];
  onSummaryChange?: (
    items: API.SimulationUpdateRequest["financialSummaryMetrics"],
  ) => void;
  numberOfMetrics: number;
  onNumberOfMetricsChange: (value: number) => void;
  financialSummaryMetricConfig?: API.SimulationUpdateRequest["financialSummaryMetricConfig"];
  onMetricFieldUpdate: (
    metricConfigId: string,
  ) => (field: string) => (value: string) => void;
}

const MIN_NUM_METRICS = 2;
const MAX_NUM_METRICS = 11;

const NUMBER_OPTIONS = Array(MAX_NUM_METRICS - MIN_NUM_METRICS + 1)
  .fill(0)
  .map((_, idx) => ({
    label: `${MIN_NUM_METRICS + idx}${MIN_NUM_METRICS + idx === 6 ? " (Recommended)" : ""}`,
    value: MIN_NUM_METRICS + idx,
  }));

const SummaryForm: React.FC<Props> = ({
  defaultSelected,
  onSummaryChange,
  numberOfMetrics,
  onNumberOfMetricsChange,
  financialSummaryMetricConfig,
  onMetricFieldUpdate,
}) => {
  const [selected, setSelected] = useState<
    Array<FinancialSummaryMecticItem | null>
  >(() => {
    const initItems = new Array(numberOfMetrics).fill(null);

    if (defaultSelected) {
      defaultSelected.forEach((item, idx) => {
        initItems[idx] = {
          ...financialSummaryMetricItems.find((i) => i.type === item.type),
        };
      });
    }

    return initItems;
  });
  const [items] = useState<FinancialSummaryMecticItem[]>(
    financialSummaryMetricItems,
  );

  const onSelectedItemChange = useCallback(
    (selected: Array<FinancialSummaryMecticItem | null>) => {
      const finalSelected: FinancialSummaryMecticItem[] = selected.filter(
        (item) => item !== null,
      ) as FinancialSummaryMecticItem[];
      onSummaryChange &&
        onSummaryChange(finalSelected.map(({ type }) => ({ type })));
    },
    [onSummaryChange],
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (result.destination) {
        const { draggableId: id } = result;
        const { droppableId: to } = result.destination;
        const { droppableId: from } = result.source;
        const item = items.find((item) => item.type === id);

        if (!item) return;

        if (
          from === DRAGGABLE_ITEMS_POOL_ID &&
          to.startsWith(DROPPABLE_AREA_PREFIX)
        ) {
          const toIndex = Number(to.split("-").pop());
          setSelected((selectedItems) => {
            selectedItems[toIndex] = item;
            const updatedSelectedItems = [...selectedItems];
            onSelectedItemChange(updatedSelectedItems);
            return updatedSelectedItems;
          });
        } else if (
          from.startsWith(DROPPABLE_AREA_PREFIX) &&
          to.startsWith(DROPPABLE_AREA_PREFIX)
        ) {
          const fromIndex = Number(from.split("-").pop());
          const toIndex = Number(to.split("-").pop());

          setSelected((selectedItems) => {
            const fromItem = selectedItems[fromIndex];
            selectedItems[fromIndex] = selectedItems[toIndex];
            selectedItems[toIndex] = fromItem;
            const updatedSelectedItems = [...selectedItems];
            onSelectedItemChange(updatedSelectedItems);
            return updatedSelectedItems;
          });
        } else if (
          from.startsWith(DROPPABLE_AREA_PREFIX) &&
          to === DRAGGABLE_ITEMS_POOL_ID
        ) {
          const fromIndex = Number(from.split("-").pop());
          setSelected((selectedItems) => {
            selectedItems[fromIndex] = null;
            const updatedSelectedItems = [...selectedItems];
            onSelectedItemChange(updatedSelectedItems);
            return updatedSelectedItems;
          });
        }
      }
    },
    [items, onSelectedItemChange],
  );

  const draggableItems: DragAndDropItem[] = useMemo(() => {
    return items
      .filter((item) => {
        return !selected.find((selectedItem) => {
          return selectedItem && item.type === selectedItem.type;
        });
      })
      .map((item) => {
        const metricConfig = financialSummaryMetricConfig?.find(
          (metricConfig) => metricConfig.type === item.type,
        );
        const props = {
          ...item,
          ...(metricConfig ? { label: metricConfig.name } : {}),
        };
        return {
          id: item.type,
          view: <DraggableView key={item.type} {...props} />,
        };
      });
  }, [financialSummaryMetricConfig, items, selected]);

  const onNumOfMetricsChange = useCallback(
    (option) => {
      onNumberOfMetricsChange(option.value);
    },
    [onNumberOfMetricsChange],
  );

  const selectedNumofMetricsOption = useMemo(() => {
    return NUMBER_OPTIONS.find((op) => op.value === numberOfMetrics);
  }, [numberOfMetrics]);

  useEffect(() => {
    setSelected((selected) => {
      if (selected.length < numberOfMetrics) {
        const remaining = numberOfMetrics - selected.length;
        return selected.concat(new Array(remaining).fill(null));
      } else {
        return selected.slice(0, numberOfMetrics);
      }
    });
  }, [numberOfMetrics, setSelected]);

  const selectedItems: Array<DragAndDropItem | undefined> = useMemo(() => {
    return selected.map((item) => {
      if (!item) return undefined;

      const metricConfig = financialSummaryMetricConfig?.find(
        (metricConfig) => metricConfig.type === item.type,
      );

      const props = {
        ...item,
        ...(metricConfig
          ? {
              label: metricConfig.name,
              onUpdateField: onMetricFieldUpdate(metricConfig.id),
            }
          : {}),
      };

      return {
        id: item.type,
        view: <SelectedView key={item.type} {...props} />,
      };
    });
  }, [financialSummaryMetricConfig, onMetricFieldUpdate, selected]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <VerticalGroup full wide spaceBetweenElements={4}>
        <h3>Financial Summary Screen Settings</h3>
        <InlineGroup verticalCenter spaceBetweenElements={2}>
          <Text size="sm" bold>
            Select financial summary metrics to be shown
          </Text>
          <InformationPopup
            title="Financial Summary Screen Metrics"
            body="Select which metrics will appear during the simulation on each participant’s financial summary screen."
          />
        </InlineGroup>
        <InlineGroup className="mb-4" verticalCenter spaceBetweenElements={4}>
          <Text size="sm" bold>
            Select number of financial summary screen metrics{" "}
          </Text>
          <Dropdown
            data-test={"financial-summary-metric-dropdown-num-of-metrics"}
            selectProps={{
              value: selectedNumofMetricsOption,
              options: NUMBER_OPTIONS,
              onChange: onNumOfMetricsChange,
              classNamePrefix:
                "financial-summary-metric-dropdown-num-of-metrics",
            }}
          />
        </InlineGroup>
        <Dropzone items={selectedItems} size={numberOfMetrics} />

        <h3>Financial Summary Screen Metrics to Choose From</h3>
        <DraggableItemsPool items={draggableItems} />
      </VerticalGroup>
    </DragDropContext>
  );
};

export default SummaryForm;
