import React, { useMemo, useCallback } from "react";

import Dropdown from "../../../../atoms/form/input/Dropdown";

interface Props {
  onChange: (value: number) => void;
  value: number;
  id: string;
}

interface PercentageSelectOption {
  id: number;
  label: string;
  value: number;
}

const PercentageSelect: React.FC<Props> = ({ onChange, value, id }) => {
  const options = useMemo(() => {
    const basicOptions: PercentageSelectOption[] = new Array(21)
      .fill(0)
      .map((_, idx) => ({
        id: idx,
        label: `${idx * 5}%`,
        value: idx * 5,
      }));

    const current = basicOptions.find((item) => item.value === value);

    if (!current) {
      basicOptions.push({
        id: basicOptions.length,
        label: value ? `${value}%` : "",
        value: value,
      });
    }

    return basicOptions;
  }, [value]);

  const currentSelected = useMemo(
    () => options.find((item: PercentageSelectOption) => item.value === value),
    [options, value],
  );

  const onValueChange = useCallback(
    (newValue) => {
      onChange(newValue?.value ?? "");
    },
    [onChange],
  );

  return (
    <Dropdown
      data-test={`summary-metric-dropdown-${id}`}
      isCreatable
      selectProps={{
        isClearable: true,
        value: currentSelected,
        options: options,
        onChange: onValueChange,
        formatCreateLabel: (val: string) => `${val.replace(/[^\d]/g, "")}%`,
        getNewOptionData: (val: string) => ({
          label: `${val.replace(/[^\d]/g, "")}%`,
          value: Number(val.replace(/[^\d]/g, "")),
        }),
        classNamePrefix: `summary-metric-dropdown-${id}`,
      }}
    />
  );
};

export default PercentageSelect;
