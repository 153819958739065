import React, { createContext, useContext } from "react";

interface CurrentAssessmentData {
  assessment: null | API.AssessmentResponse;
}

const defaultData: CurrentAssessmentData = {
  assessment: null,
};

const currentAssessmentContext =
  createContext<CurrentAssessmentData>(defaultData);

interface Props {
  assessment: API.AssessmentResponse;
}

export const SetCurrentAssessment: React.FC<Props> = ({
  children,
  ...props
}) => {
  return (
    <currentAssessmentContext.Provider value={props}>
      {children}
    </currentAssessmentContext.Provider>
  );
};

export const useCurrentAssessment = () => {
  return useContext(currentAssessmentContext).assessment;
};
