import React from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";
import pluckResultsByType from "../utils/pluckResultsByType";
import { deals } from "../constants";
import { percentage } from "../utils/formatters";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["dealCalculations"];
}

const ModelTeamResultsDealCalculations: React.FC<Props> = ({ data }) => {
  const dealsData = pluckResultsByType(data, "deals");
  const totalData = pluckResultsByType(data, "total");
  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        {deals.map((d) => {
          const dataForDecision = pluckResultsByType(dealsData, d);
          const sections = [
            {
              fields: [
                "winningBid",
                "dividends",
                "terminalValue",
                "communityTrustImpact",
                "remediationAndFines",
                "transactionAmount",
                "loanBookPremium",
              ],
              values: dataForDecision,
              formatter: (val: number, field: string) => {
                if (field === "communityTrustImpact") {
                  return percentage(val);
                }
                return null;
              },
            },
          ];
          return <TableWithSections key={d} header={d} data={sections} />;
        })}
        <TableWithSections
          header="Total Impacts"
          data={[
            {
              name: "Balance Sheet Impacts",
              fields: [
                "openingBalance",
                "impacts.offshore-bank",
                "impacts.regional-bank",
                "impacts.home-loan-broker",
                "impacts.payments-partner",
                "impacts.neobank",
                "impacts.home-loan-book",
                "impacts.buy-now-cry-later",
                "closingBalance",
                "averageBalance",
                "netMovement",
              ],
              totalFields: ["closingBalance", "averageBalance", "netMovement"],
              values: pluckResultsByType(totalData, "balanceSheetImpacts"),
            },
            {
              name: "Terminal Value Impacts",
              fields: [
                "openingBalance",
                "impacts.offshore-bank",
                "impacts.regional-bank",
                "impacts.home-loan-broker",
                "impacts.payments-partner",
                "impacts.neobank",
                "impacts.home-loan-book",
                "impacts.buy-now-cry-later",
                "closingBalance",
              ],
              totalFields: ["closingBalance"],
              values: pluckResultsByType(totalData, "terminalValueImpacts"),
            },
            {
              name: "Dividends",
              fields: [
                "offshore-bank",
                "regional-bank",
                "home-loan-broker",
                "payments-partner",
                "neobank",
                "home-loan-book",
                "buy-now-cry-later",
                "total",
              ],
              totalFields: ["total"],
              values: pluckResultsByType(totalData, "dividends"),
            },
          ]}
        />
      </VerticalGroup>
    </>
  );
};

export default ModelTeamResultsDealCalculations;
