import {
  TextNode,
  NodeKey,
  EditorConfig,
  LexicalNode,
  SerializedTextNode,
} from "lexical";

interface SerializedColoredTextNode extends SerializedTextNode {
  type: "colored-text";
  color: string;
  version: number;
}

export class ColoredTextNode extends TextNode {
  updateDOM(prevNode: ColoredTextNode, dom: HTMLElement): boolean {
    const domText = dom.textContent;
    if (this.__text !== domText) {
      dom.textContent = this.__text;
    }
    if (prevNode.__color !== this.__color) {
      dom.style.color = this.__color;
    }
    dom.style.fontWeight = this.hasFormat("bold") ? "bold" : "normal";
    dom.style.fontStyle = this.hasFormat("italic") ? "italic" : "normal";
    dom.style.textDecoration = this.hasFormat("underline")
      ? "underline"
      : "none";
    return false;
  }
  __color: string;

  static getType(): string {
    return "colored-text";
  }

  getColor(): string {
    return this.__color;
  }

  static clone(node: ColoredTextNode): ColoredTextNode {
    return new ColoredTextNode(node.__text, node.__color, node.__key);
  }

  constructor(text: string, color: string, key?: NodeKey) {
    super(text, key);
    this.__color = color;
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = super.createDOM(config);
    element.style.color = this.__color;
    return element;
  }

  exportJSON(): SerializedColoredTextNode {
    return {
      ...super.exportJSON(),
      type: "colored-text",
      color: this.__color,
      version: 1,
    };
  }

  static importJSON(
    serializedNode: SerializedColoredTextNode,
  ): ColoredTextNode {
    const node = $createColoredTextNode(
      serializedNode.text,
      serializedNode.color,
    );
    node.setMode(serializedNode.mode);
    node.setDetail(serializedNode.detail);
    node.setFormat(serializedNode.format);
    node.setStyle(serializedNode.style);
    return node;
  }

  setColor(color: string): void {
    const writable = this.getWritable();
    writable.__color = color;

    this.getLatest().markDirty();
  }
}

export function $createColoredTextNode(
  text: string,
  color: string,
): ColoredTextNode {
  return new ColoredTextNode(text, color);
}

export function $isColoredTextNode(
  node: LexicalNode | null | undefined,
): node is ColoredTextNode {
  return node instanceof ColoredTextNode;
}
