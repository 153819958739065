import React, { useCallback, useContext, useEffect, useState } from "react";

import API from "../../../../../services/api";
import Banner from "../../../../atoms/banner/Banner";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import { SimulationContext } from "../../context";
import useForm from "../../../../../hooks/useForm";
import PropertyViewer from "./PropertyViewer";
import JsonViewer from "./JsonViewer";
import StickyBar from "../../../../organisms/sticky-bar/StickyBar";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import IconButton from "../../../../molecules/iconbutton/IconButton";

const Metadata: React.FC = () => {
  const { config, updateConfig, assignOnSubmitFunction, updateFormStatus } =
    useContext(SimulationContext);
  const [
    {
      formData: { metadata },
      error,
      inProgress,
      formUpdated,
      fieldErrors,
    },
    { handleSubmit, setFieldRaw, setError },
  ] = useForm(
    config?.metadata
      ? {
          metadata: config.metadata,
        }
      : {},
  );

  const [viewer, setViewer] = useState<"property" | "json">("property");

  const onSubmit = useCallback(async () => {
    if (config) {
      const payload: API.SimulationUpdateRequest = {
        metadata,
      };
      const response = await API.editSimulation(config.id, payload);
      updateConfig(response);
      return response;
    }
  }, [config, metadata, updateConfig]);

  useEffect(() => {
    assignOnSubmitFunction(handleSubmit(onSubmit));
  }, [assignOnSubmitFunction, handleSubmit, onSubmit]);

  useEffect(() => {
    updateFormStatus({
      inProgress: inProgress,
      formUpdated: formUpdated,
    });
  }, [updateFormStatus, inProgress, formUpdated]);

  return (
    <div className="step-container">
      <VerticalGroup spread full>
        <VerticalGroup wide>
          <Banner
            type="error"
            active={!!error || !!fieldErrors["metadata"]}
            message={error?.message || fieldErrors["metadata"]}
          />
          <StickyBar height={8} spaceBetweenElements={2}>
            <InlineGroup spaceBetweenElements={2}>
              {viewer === "property" ? (
                <IconButton
                  data-test="to-json-view"
                  icon="simulations"
                  text="JSON Viewer"
                  onClick={() => setViewer("json")}
                />
              ) : null}
              {viewer === "json" ? (
                <IconButton
                  data-test="to-property-view"
                  icon="simulations"
                  text="Property Viewer"
                  onClick={() => setViewer("property")}
                />
              ) : null}
            </InlineGroup>
          </StickyBar>
          <h3>Metadata Settings</h3>
          {viewer === "property" ? (
            <PropertyViewer metadata={metadata} setFieldRaw={setFieldRaw} />
          ) : null}
          {viewer === "json" ? (
            <JsonViewer
              metadata={metadata}
              setFieldRaw={setFieldRaw}
              setError={setError}
            />
          ) : null}
        </VerticalGroup>
      </VerticalGroup>
    </div>
  );
};

export { default as MetadataDisplay } from "./Display";

export default Metadata;
