import React, { useCallback } from "react";
import useAPIRequest from "../../../../../hooks/useAPIRequest";
import ConfirmModal from "../../../../organisms/confirm-modal/ConfirmModal";

import API from "../../../../../services/api";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  assessmentId: string;
  groupId: string;
  question: API.AssessmentQuestionResponse;
}

export const DeleteQuestion: React.FC<Props> = ({
  assessmentId,
  groupId,
  question,
  isOpen,
  onComplete,
  onClose,
}) => {
  const callback = useCallback(
    () => API.deleteAssessmentQuestion(assessmentId, groupId, question.id),
    [assessmentId, groupId, question],
  );

  const [{ inProgress, error }, doAPIRequest] = useAPIRequest(callback, null);

  const handleConfirm = useCallback(() => {
    doAPIRequest(onComplete);
  }, [doAPIRequest, onComplete]);

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      title={`Delete '${question.question}'`}
      description={"Are you sure you want to delete this question?"}
      confirmTitle="Delete"
      error={error && error.message}
      warning
    />
  );
};
