import React from "react";

import EnumDropdown, {
  getValueFunc,
} from "../../../../organisms/enum-dropdown/EnumDropdown";

interface Props {
  onChange: (selected: { value: string; label: string }) => void;
  value?: string;
  error?: string;
  label?: string;
  name?: string;
}

export const options = [
  {
    value: "rba",
    label: "RBA",
  },
  {
    value: "rbnz",
    label: "RBNZ",
  },
  {
    value: "fed",
    label: "Fed",
  },
  {
    value: "boe",
    label: "BoE",
  },
  {
    value: "ecb",
    label: "ECB",
  },
  {
    value: "bi",
    label: "BI",
  },
  {
    value: "boj",
    label: "BoJ",
  },
  {
    value: "pbc",
    label: "PBC",
  },
  {
    value: "mas",
    label: "Monetary Authority Of Singapore",
  },
];

export const getLabelFromValue = getValueFunc(options);

function CentralBankDropdown({ onChange, value, error, label, name }: Props) {
  return (
    <EnumDropdown
      onChange={onChange}
      options={options}
      value={value}
      error={error}
      label={label}
      name={name ?? "centralbank-dropdown"}
    />
  );
}

export default CentralBankDropdown;
