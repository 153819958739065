import React from "react";
import { DotProps } from "recharts";
import { TeamsWithColours } from "../RoundResultsPage";

export interface CustomDotProps extends Omit<DotProps, "value"> {
  value: { value: number; topValueToUse: number };
  index: number;
  dataKey: string;
}

const TEAMID_REGEX = /team([0123456789]+)/;

export const createLineChartDot = (
  circleRadius: number,
  strokeWidth: number,
  teams?: TeamsWithColours[],
  colourFormatter?: (index: number, team: number) => string | undefined,
) => {
  const CustomDot: React.FC<CustomDotProps> = (props) => {
    const { cx, cy, value, index, dataKey, stroke } = props;
    if (value == null) {
      return null;
    }
    const xToUse = Number(cx) ?? 0;
    const yToUse = Number(cy) ?? 0;
    const teamIdMatch = dataKey?.match(TEAMID_REGEX);
    const teamId = teamIdMatch ? parseInt(teamIdMatch[1]) : undefined;
    const team = teams?.find((t) => t.teamId === teamId);
    const fill =
      teamIdMatch && colourFormatter
        ? colourFormatter(index, parseInt(teamIdMatch[1]))
        : undefined;

    if (team?.isCpuTeam) {
      return (
        <rect
          x={xToUse - circleRadius}
          y={yToUse - circleRadius}
          width={circleRadius * 2}
          height={circleRadius * 2}
          fill={"white"}
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
      );
    }

    return (
      <circle
        cx={xToUse}
        cy={yToUse}
        r={circleRadius}
        fill={fill ?? "white"}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
    );
  };

  return CustomDot;
};
