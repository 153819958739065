import { useEffect, useMemo } from "react";
import useAPIRequest from "./useAPIRequest";

export const useMemoRequest = <T>(func: () => Promise<T>) => {
  const [{ inProgress, data, error }, doAPIRequest] = useAPIRequest<T>(
    func,
    null,
  );

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest, func]);

  return useMemo(
    () => ({
      inProgress,
      data,
      error,
      refresh: doAPIRequest,
    }),
    [inProgress, data, error, doAPIRequest],
  );
};
