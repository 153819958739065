import React from "react";
import ResultsText from "../results-text/ResultsText";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Icon, { IconType } from "../../../atoms/icon/Icon";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import { ThemeColours } from "../../../../types/theme";
import "./CharcteristicItem.scss";
import useIsMobile from "../../../../hooks/useIsMobile";

interface CharacteristicItemProps {
  color: ThemeColours;
  heading: string;
  bulletPoints: string[];
  icon: IconType;
  visible?: boolean;
  animationClass?: string;
}

export const CharacteristicItem: React.FC<CharacteristicItemProps> = ({
  color,
  heading,
  bulletPoints,
  visible = true,
  animationClass = "",
  icon,
}) => {
  const isMobile = useIsMobile();
  return (
    <InlineGroup
      className={`characteristic-item-container characteristic-item ${animationClass}`}
      block
      fullHeight
      style={{ visibility: visible ? "visible" : "hidden" }}
    >
      <InlineGroup verticalCenter block style={{ width: "8%" }} fullHeight>
        <InlineGroup bgColour={color} verticalCenter block fullHeight center>
          <Icon
            noMargin
            dynamicSize={{ min: "20px", preferred: "3.8vw", max: "200px" }}
            type={icon}
          />
        </InlineGroup>
      </InlineGroup>
      <InlineGroup
        border={{ style: "solid", color: color, width: "3px" }}
        verticalCenter
        block
      >
        <VerticalGroup
          spaceBetweenElements={2}
          full
          style={{ padding: isMobile ? "4px" : "5px 10px 8px 20px" }}
          wide
        >
          <ResultsText bold size="md" colour={color}>
            {heading}
          </ResultsText>
          <ResultsText size="sm">
            <ul className="info-list">
              {bulletPoints.map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
          </ResultsText>
        </VerticalGroup>
      </InlineGroup>
    </InlineGroup>
  );
};
