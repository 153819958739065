import React, { useEffect, useMemo } from "react";
import ReactDiffViewer from "react-diff-viewer-continued";
import Banner from "../../../atoms/banner/Banner";

import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";

import { diff, flattenChangeset } from "json-diff-ts";

interface Props {
  inProgress: boolean;
  data: { model: any; game: any } | null;
  error: Error | null;
  setIssues: (val: number) => void;
}

const Differ: React.FC<Props> = ({ inProgress, data, error, setIssues }) => {
  const diffs = useMemo(() => diff(data?.model, data?.game), [data]);
  useEffect(() => {
    if (diffs.length) {
      setIssues(flattenChangeset(diffs).length);
    }
  }, [diffs, diffs.length, setIssues]);

  if (error) {
    return <Banner type="error" message={error.message} active />;
  }
  if (inProgress || !data) {
    return <LoadingSpinner />;
  }
  return (
    <ReactDiffViewer
      leftTitle="AWS Model"
      rightTitle="Excel Model"
      useDarkTheme={false}
      oldValue={JSON.stringify(data.model, null, 2)}
      newValue={JSON.stringify(data.game, null, 2)}
      splitView={true}
      showDiffOnly={false}
    />
  );
};

export default Differ;
