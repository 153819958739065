import React, { ChangeEvent, useRef, useState, useMemo } from "react";
import classNames from "classnames";

import Button from "../../atoms/button/Button";
import Label from "../../atoms/form/label/Label";
import Text from "../../atoms/text/Text";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import InlineError from "../../atoms/inlineerror/InlineError";
import Card from "../../atoms/card/Card";

interface Props {
  handleFileUpload: (file: any) => void;
  title: string;
  description: string;
  label: string;
  buttonText: string;
  allowedType: "image" | "zip" | "any";
  errorMsg?: string;
  disabled?: boolean;
}

function UploadFile({
  handleFileUpload,
  title,
  description,
  label,
  buttonText,
  allowedType,
  disabled,
  errorMsg,
}: Props) {
  const fileInput = useRef<HTMLInputElement>(null);
  const [error, setError] = useState("");
  const handleClick = () => {
    fileInput.current?.click();
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      fileValidation(file);
    }
  };

  const fileValidation = (file: any) => {
    const isImage = () => file["type"].includes("image");
    const isZip = () => file["type"].includes("zip");
    switch (allowedType) {
      case "image":
        if (isImage()) {
          handleFileUpload(file);
          setError("");
        } else setError("File type not allowed!");
        break;
      case "zip":
        if (isZip()) {
          handleFileUpload(file);
          setError("");
        } else setError("File type not allowed!");
        break;
      case "any":
        handleFileUpload(file);
        break;
      default:
        setError("File type not allowed!");
        break;
    }
  };

  const hasError = !!error || !!errorMsg;

  const accept = useMemo(() => {
    switch (allowedType) {
      case "image":
        return "image/*";
      case "zip":
        return ".zip";
      default:
        return "*/*";
    }
  }, [allowedType]);

  return (
    <InlineGroup>
      {!!label && <Label>{label}</Label>}
      <VerticalGroup>
        <Card error={hasError} className={classNames("input-width")}>
          <VerticalGroup>
            <Text size="sm"> {title} </Text>
            <Text size="xs"> {description} </Text>
            <Button
              onClick={handleClick}
              disabled={disabled}
              style={{ marginTop: "5px" }}
            >
              {buttonText}
            </Button>
            <input
              type="file"
              accept={accept}
              ref={fileInput}
              onChange={handleOnChange}
              style={{ display: "none" }}
              data-test="file-upload"
            />
          </VerticalGroup>
        </Card>
        <div className="input-error">
          <InlineError active={hasError} message={errorMsg ?? error} />
        </div>
      </VerticalGroup>
    </InlineGroup>
  );
}

export default UploadFile;
