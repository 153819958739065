import React, { useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Container from "../../atoms/page/Page";
import Input from "../../atoms/form/input/Input";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import AssessmentDelete from "./AssessmentDelete";
import DisplayField from "../../atoms/form/display-field/DisplayField";
import Form from "../../atoms/form/Form";
import Banner from "../../atoms/banner/Banner";
import IconButton from "../../molecules/iconbutton/IconButton";
import ClientAvatarList from "../../molecules/client-avatar-list/ClientAvatarList";
import { useAssessment } from "../../../hooks/useAssessments";
import { CloneAssessment } from "./actions/CloneAssessment";
import AssessmentRibbon from "./AssessmentRibbon";
import useQuery from "../../../hooks/useQuery";

interface AssessmentState {
  deleteModalOpen: boolean;
}

function AssessmentDetails() {
  const { assessmentId } = useParams<{ assessmentId: string }>();
  const history = useHistory();
  const query = useQuery();

  const { inProgress, data, error } = useAssessment(assessmentId);

  const [{ deleteModalOpen }, setAssessmentState] = useState<AssessmentState>({
    deleteModalOpen: false,
  });

  const [index, setIndex] = useState(0);

  const groupId = query.get("groupId");

  useEffect(() => {
    if (groupId) {
      const selectedGroupIndex = data?.groups.findIndex(
        (g) => g.id === groupId,
      );
      if (selectedGroupIndex != null && selectedGroupIndex >= 0) {
        setIndex(selectedGroupIndex);
        query.delete("groupId");
        history.replace({
          search: query.toString(),
        });
      }
    }
  }, [data, groupId, history, query]);

  const onAssessmentDeleteComplete = useCallback(() => {
    setAssessmentState((prevState) => ({
      ...prevState,
      deleteModalOpen: false,
    }));
    history.push("/assessments");
  }, [history]);

  return (
    <>
      {deleteModalOpen && !!data && (
        <AssessmentDelete
          isOpen={deleteModalOpen}
          onClose={() =>
            setAssessmentState((prevState) => ({
              ...prevState,
              deleteModalOpen: false,
            }))
          }
          onComplete={onAssessmentDeleteComplete}
          assessment={data}
        />
      )}

      <Container className="assessment-edit">
        {error && (
          <Banner type="error" active={!!error} message={error?.message} />
        )}
        <InlineGroup spread verticalCenter>
          <h2>Assessment Details</h2>
          {!!data && !inProgress && (
            <InlineGroup spread spaceBetweenElements={2}>
              <IconButton
                link
                icon="group"
                className="main-edit-action-button"
                linkTo={`/assessments/${assessmentId}/edit/add-group`}
                text="Add Group"
              />
              <CloneAssessment assessment={data} type="button" />
              <IconButton
                data-test={`edit-assessment-${assessmentId}`}
                icon="edit"
                text="Edit Assessment"
                linkTo={`/assessments/${assessmentId}/edit`}
              />
              <IconButton
                link
                icon="preview"
                className="main-edit-action-button"
                linkTo={`/assessments/${assessmentId}/edit/preview`}
                text="Preview"
              />
              <IconButton
                danger
                data-test={`delete-assessment-${data.id}`}
                icon="trash"
                text="Delete"
                onClick={() =>
                  setAssessmentState((prevState) => ({
                    ...prevState,
                    deleteModalOpen: true,
                  }))
                }
              />
            </InlineGroup>
          )}
        </InlineGroup>
        {inProgress && <LoadingSpinner />}
        {!!data && !inProgress && (
          <>
            <Form>
              <DisplayField
                stretch
                label="Clients With Access"
                value={<ClientAvatarList clients={data.clients} />}
              />
              <Input
                data-test="name"
                type="text"
                value={data.name}
                label="Assessment Name"
                readOnly
              />
            </Form>
            {data.groups?.length > 0 && (
              <>
                <hr />
                <h2>Rounds</h2>
                <AssessmentRibbon
                  selectedIndex={index}
                  setSelectedIndex={setIndex}
                  groups={data.groups}
                  assessmentId={data.id}
                />
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
}

export default AssessmentDetails;
