import React, { useCallback, useEffect, useState } from "react";
import ExcoDecisionSelect from "../../simulations/steps/excodecision/ExcoDecisionSelect";
import { useExcoConfig } from "../../../../hooks/useExcoConfig";
import api from "../../../../services/api";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import Banner from "../../../atoms/banner/Banner";
import SimulationsThatNeedManualModificationModal from "./components/SimulationsThatNeedManualModificationModal";

function ExcoDecisions() {
  const [
    simulationsThatNeedManualModification,
    setSimulationsThatNeedManualModification,
  ] = useState<API.SimulationsThatNeedManualModification[] | null>(null);
  const { data: excoDecisions, inProgress, error } = useExcoConfig();

  const [excoDecisionsData, setExcoDecisionsData] = useState<
    API.ExcoConfigResponse[] | null
  >(null);

  useEffect(() => {
    if (excoDecisions) {
      setExcoDecisionsData(excoDecisions);
    }
  }, [excoDecisions]);

  const handleExcoDecisionsUpdate = useCallback(
    async (excoId: string, data: Partial<API.ExcoConfigResponse>) => {
      const response = await api.updateExcoConfig(excoId, data);
      setExcoDecisionsData((prev) => {
        if (!prev) {
          return prev;
        }
        return prev.map((exco) => {
          if (exco.id === excoId) {
            return { ...exco, ...data };
          }
          return exco;
        });
      });
      if (response.simulationsThatNeedManualModification.length > 0) {
        setSimulationsThatNeedManualModification(
          response.simulationsThatNeedManualModification,
        );
      }
    },
    [],
  );

  const handleExcoDecisionsOptionUpdate = useCallback(
    async (
      excoId: string,
      optionId: string,
      data: Partial<API.ExcoOptionConfig>,
    ) => {
      const response = await api.updateExcoOptionConfig(excoId, optionId, data);
      setExcoDecisionsData((prev) => {
        if (!prev) {
          return prev;
        }
        return prev.map((exco) => {
          if (exco.id === excoId) {
            return {
              ...exco,
              options: exco.options.map((option) => {
                if (option.id === optionId) {
                  return { ...option, ...data };
                }
                return option;
              }),
            };
          }
          return exco;
        });
      });
      if (response.simulationsThatNeedManualModification.length > 0) {
        setSimulationsThatNeedManualModification(
          response.simulationsThatNeedManualModification,
        );
      }
    },
    [],
  );

  return (
    <>
      <h3>Executive Team Decision Settings</h3>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {excoDecisionsData && (
        <ExcoDecisionSelect
          onUpdateContent={handleExcoDecisionsUpdate}
          onUpdateOption={handleExcoDecisionsOptionUpdate}
          excoDecisions={excoDecisionsData}
        />
      )}
      {simulationsThatNeedManualModification &&
        simulationsThatNeedManualModification.length > 0 && (
          <SimulationsThatNeedManualModificationModal
            simulationsThatNeedManualModification={
              simulationsThatNeedManualModification
            }
            onClose={() => setSimulationsThatNeedManualModification(null)}
            stepTitle="Executive Decisions"
          />
        )}
    </>
  );
}

export default ExcoDecisions;
