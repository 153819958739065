import React, { useMemo } from "react";

import { useSimulations } from "../../../hooks/useSimulations";
import Dropdown from "../../atoms/form/input/Dropdown";

interface SelectValue {
  id: string;
  value: string;
  label: string;
  simulation: API.SimulationResponse;
}

interface SimulationDropdownProps {
  simulationId?: string;
  clientId?: string;
  state?: string;
  onSimulationSelected?: (simulation: API.SimulationResponse) => void;
}

const SimulationDropdown: React.FC<SimulationDropdownProps> = ({
  simulationId,
  clientId,
  state,
  onSimulationSelected,
}) => {
  const { data } = useSimulations(
    clientId ? [clientId] : [],
    clientId ? true : false,
    state,
  );

  const options: SelectValue[] = useMemo(() => {
    return data
      ? data.map((simulation) => ({
          id: simulation.id,
          value: simulation.id,
          label: simulation.generalSetup.name,
          simulation,
        }))
      : [];
  }, [data]);

  const publishedOptions = useMemo(() => {
    return options
      ? options.filter(
          (simulation) => simulation.simulation.state === "complete",
        )
      : [];
  }, [options]);

  const currentSelected = useMemo(() => {
    if (simulationId) {
      return options.find((option) => option.id === simulationId);
    }
    return null;
  }, [simulationId, options]);

  return (
    <Dropdown
      selectProps={{
        onChange: (option: SelectValue) =>
          onSimulationSelected && onSimulationSelected(option.simulation),
        value: currentSelected,
        options: publishedOptions,
        noOptionsMessage: () => "No Published Simulations",
      }}
    />
  );
};

export default SimulationDropdown;
