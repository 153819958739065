import { useCallback, useEffect, useMemo } from "react";
import API from "../services/api";
import useAPIRequest from "./useAPIRequest";

const useScenarios = () => {
  const callback = useCallback(() => {
    return API.listEconomicScenarios();
  }, []);

  const [{ inProgress, completed, data, error }, doAPIRequest] = useAPIRequest<
    API.EconomicScenarioResponse[]
  >(callback, { data: [] });

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  const refresh = useCallback(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  return useMemo(
    () => ({
      inProgress,
      completed,
      data,
      error,
      refresh,
    }),
    [inProgress, completed, data, error, refresh],
  );
};

export default useScenarios;
