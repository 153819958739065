import React, { useCallback, useContext } from "react";
import classNames from "classnames";

import "./Step.scss";
import { StepperContext } from "./context";
import Text from "../../atoms/text/Text";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import Tooltip from "../../atoms/tooltip/Tooltip";
import Icon from "../../atoms/icon/Icon";
import Ribbon from "../../atoms/ribbon/Ribbon";

interface PublicProps {
  /**
   * A display title for the step
   */
  title: string;
  onClick?: (index: number) => void;
  disabled?: boolean;
  disabledMessage?: string;
  complete?: boolean;
}

export interface PrivateProps extends PublicProps {
  index: number;
  lastIndex: number;
  direction: "horizontal" | "vertical";
}

const Step: React.FC<PrivateProps> = ({
  index,
  title,
  direction,
  onClick,
  disabled,
  disabledMessage,
  complete,
  lastIndex,
}) => {
  const { activeIndex } = useContext(StepperContext);
  const isActive = activeIndex === index;
  const stepClass = classNames(
    "step",
    { active: isActive },
    { vertical: direction === "vertical" },
    { horizontal: direction === "horizontal" },
    { complete },
  );

  const onClickCallback = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      !disabled && onClick && onClick(index);
    },
    [onClick, index, disabled],
  );

  const stepContent =
    direction === "horizontal" ? (
      <Ribbon
        first={index === 0}
        last={index === lastIndex}
        active={isActive}
        title={title}
        onClick={onClickCallback}
        disabled={disabled}
      />
    ) : (
      <div
        className={stepClass}
        onClick={onClickCallback}
        data-tip
        data-for={`step-${index}`}
      >
        <InlineGroup verticalCenter>
          {complete ? (
            <Icon noMargin type="tick" size={4} colour="green" />
          ) : (
            <span className="step-number">
              <Text
                size="xs"
                medium
                colour={isActive ? "secondaryBlue" : undefined}
                center
              >
                {index + 1}
              </Text>
            </span>
          )}
          <Text
            size="sm"
            className="ml-2"
            medium
            colour={complete ? "green" : isActive ? "secondaryBlue" : undefined}
          >
            {title.toUpperCase()}
          </Text>
        </InlineGroup>
      </div>
    );

  return (
    <>
      {stepContent}
      {!!disabled && (
        <Tooltip id={`step-${index}`}>
          <>
            <b>Step Disabled</b>
            <p>{disabledMessage ?? "This step is disabled"}</p>
          </>
        </Tooltip>
      )}
    </>
  );
};

export default Step as React.FC<PublicProps>;
