import { useEffect, useMemo, useCallback } from "react";
import API from "../services/api";
import { useAPIRequestRunner } from "./useAPIRequest";

export const useSimulation = (simulationId: string) => {
  const {
    state: { inProgress, data, error },
    run,
  } = useAPIRequestRunner<API.SimulationResponse>();

  const doApiRequest = useCallback(() => {
    run(API.getSimulation(simulationId));
  }, [run, simulationId]);

  useEffect(() => {
    doApiRequest();
  }, [doApiRequest]);

  return useMemo(
    () => ({
      inProgress,
      data,
      error,
      refresh: doApiRequest,
    }),
    [inProgress, data, error, doApiRequest],
  );
};
