import React, { useCallback, useEffect } from "react";
import API from "../../../../services/modelApi";
import useAPIRequest from "../../../../hooks/useAPIRequest";
import Container from "../../../atoms/page/Page";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import Banner from "../../../atoms/banner/Banner";
import EmptyList from "../../../organisms/empty-list/EmptyList";
import Table, { Td, Th, Tr } from "../../../atoms/table/Table";

function ModelLogs({ eventId }: { eventId: string }) {
  const callback = useCallback(() => {
    return API.getModelLogs(eventId);
  }, [eventId]);

  const [{ inProgress, data, completed, error }, doAPIRequest] =
    useAPIRequest<ModelAPI.EventLogResponse>(callback, { data: { logs: [] } });

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  return (
    <Container>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {completed && data && !data.logs.length && (
        <EmptyList icon="audit" message="There are no logs yet" />
      )}
      {completed && data && data.logs.length && (
        <Table>
          <thead>
            <Tr>
              <Th width={24}>Date</Th>
              <Th width={24}>Time</Th>
              <Th>Message</Th>
            </Tr>
          </thead>
          <tbody>
            {data.logs.map((log, i) => {
              const isError =
                log.level === "ERROR" || /error/i.test(log.message);
              return (
                <Tr key={i}>
                  <Td dangerHighlight={isError} width={24}>
                    {log.date}
                  </Td>
                  <Td dangerHighlight={isError} width={24}>
                    {log.time}
                  </Td>
                  <Td dangerHighlight={isError}>{log.message}</Td>
                </Tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </Container>
  );
}

export default ModelLogs;
