import React from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";
import { formatValue } from "../utils/formatters";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["taxation"];
}

const ModelTeamResultsTaxation: React.FC<Props> = ({ data }) => {
  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <TableWithSections
          data={[
            {
              fields: [
                "netProfitBeforeTax",
                "remediationCosts",
                // 'investmentDividends',
                "offshore-bank",
                "regional-bank",
                "home-loan-broker",
                "payments-partner",
                "neobank",
                "totalTaxableAmount",
                "taxationExpenseProfitAndLoss",
                "offshoreBankTaxationExpense",
                "taxationExpenseCashExludingOffshoreBank",
              ],
              values: data,
              totalFields: ["totalTaxableAmount"],
              formatter: (val: number, field: string) => {
                if (field === "offshoreBankTaxationExpense") {
                  return formatValue(val * -1);
                }
                return null;
              },
              fieldFormatter: (field: string) => {
                if (field === "netProfitBeforeTax") {
                  return "NET PROFIT BEFORE TAX (NPBT)";
                }
                if (field === "remediationCosts") {
                  return "add back: Remediation Costs";
                }
                if (field === "investmentDividends") {
                  return "less: Investment Dividends (Exempt / Fully Franked)";
                }
                if (field === "taxationExpenseProfitAndLoss") {
                  return "Taxation Expense - P&L";
                }
                if (field === "taxationExpenseCashExludingOffshoreBank") {
                  return "Taxation Expense - Cash (Excl Offshore Bank)";
                }
                return null;
              },
            },
          ]}
        />
      </VerticalGroup>
    </>
  );
};

export default ModelTeamResultsTaxation;
