import React, { useMemo, useState } from "react";
import Card from "../../../../atoms/card/Card";
import DisplayField from "../../../../atoms/form/display-field/DisplayField";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import Clickable from "../../../../atoms/clickable/Clickable";
import Icon from "../../../../atoms/icon/Icon";
import AvoidPageBreak from "../../../../atoms/printable/components/AvoidPageBreak";
import NewsPreviewIframe from "../../../administration/configuration/economicScenarioBuilder/NewsPreview/NewsPreviewIframe";
import { PreviewSettings } from "../../../administration/configuration/economicScenarioBuilder/types";
import ResultsPreview from "../../../administration/configuration/economicScenarioBuilder/NewsPreview/ResultsPreview";
import RoundDropdown from "../../../../organisms/round-dropdown/RoundDropdown";
import ScreenOnly from "../../../../atoms/printable/components/ScreenOnly";

interface Props {
  data?: API.SimulationResponse;
  onClickEdit?: () => void;
}

const Display: React.FC<Props> = ({ data, onClickEdit }) => {
  const moduleSetup = data?.moduleSetup;
  const [round, setRound] = useState(1);

  const previewSettings: PreviewSettings = useMemo(() => {
    const marketSettings = data?.regionalSetup;
    const businessSettings = data?.businessSetup;
    let product: API.Products = "CREDIT_CARDS";
    if (businessSettings?.institutionalLendingEnabled) {
      product = "INSTITUTIONAL_BANKING";
    } else if (businessSettings?.wealthManagementEnabled) {
      product = "WEALTH_MANAGEMENT";
    } else if (businessSettings?.offshoreBankEnabled) {
      product = "OFFSHORE_BANK";
    }
    return {
      country: marketSettings?.country ?? "australia",
      referenceRate: marketSettings?.referenceRate ?? "BBSW",
      centralBank: marketSettings?.centralBank ?? "rba",
      regulator: marketSettings?.regulator ?? "APRA",
      currency: marketSettings?.currency ?? "AUD",
      product,
    };
  }, [data?.businessSetup, data?.regionalSetup]);

  return moduleSetup ? (
    <Card wide>
      <AvoidPageBreak>
        <InlineGroup verticalCenter>
          <h4>Economic Scenario</h4>
          {onClickEdit ? (
            <Clickable
              data-test={"edit-simulation-module-step"}
              onClick={onClickEdit}
            >
              <Icon
                type="edit"
                tip={{
                  content: "Edit Economic Scenario",
                  id: "edit-economic-scenario",
                }}
              />
            </Clickable>
          ) : null}
        </InlineGroup>
        <InlineGroup spread>
          <DisplayField label="Economy" value={moduleSetup.economy} />
          <ScreenOnly>
            <RoundDropdown
              value={round}
              min={1}
              max={data?.generalSetup?.rounds}
              onChange={(selected) => setRound(Number(selected.value))}
              small
              useRoundsLabel
            />
          </ScreenOnly>
        </InlineGroup>
        <ScreenOnly>
          <InlineGroup verticalCenter spaceBetweenElements={8} className="mt-4">
            <div style={{ height: 400, width: "50%" }}>
              <NewsPreviewIframe
                scenarioId={moduleSetup.fkScenarioId}
                roundId={round}
                previewSettings={previewSettings}
                maxContainerHeight={400}
              />
            </div>
            <div style={{ height: 400, width: "50%" }}>
              <ResultsPreview
                scenarioId={moduleSetup.fkScenarioId}
                roundId={round}
                previewSettings={previewSettings}
              />
            </div>
          </InlineGroup>
        </ScreenOnly>
      </AvoidPageBreak>
    </Card>
  ) : null;
};

export default Display;
