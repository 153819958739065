import React, { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import AssessmentForm from "./AssessmentForm";
import API from "../../../services/api";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import Container from "../../atoms/page/Page";
import Banner from "../../atoms/banner/Banner";
import { useAssessment } from "../../../hooks/useAssessments";

function EditAssessment() {
  const { assessmentId } = useParams<{ assessmentId: string }>();
  const { inProgress, data, error } = useAssessment(assessmentId);

  const onSave = useCallback(
    (data: API.AssessmentRequest) => {
      return API.editAssessment(assessmentId, data);
    },
    [assessmentId],
  );

  const history = useHistory();

  const onCancel = useCallback(() => {
    history.push(`/assessments/${assessmentId}/view`);
  }, [history, assessmentId]);

  const onComplete = useCallback(
    (data) => {
      history.push(`/assessments/${data.id}/view`);
    },
    [history],
  );

  return (
    <Container>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {data && (
        <AssessmentForm
          mode="edit"
          assessment={data}
          onSave={onSave}
          onCancel={onCancel}
          onComplete={onComplete}
        />
      )}
    </Container>
  );
}

export default EditAssessment;
