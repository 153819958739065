import React, { useMemo } from "react";
import classNames from "classnames";

import Table, { Td, Th, Tr } from "../../../atoms/table/Table";
import ResultsText from "../results-text/ResultsText";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Image from "../../../atoms/image/Image";
import EditableReportingHeading from "../components/EditableReportingHeading";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import excelerateLogo from "../../../../assets/logo.png";
import { useResultsContext } from "../context/context";
import RoundResultsContainer from "./RoundsResultsContainer";
import Card from "../../../atoms/card/Card";
import "./ParticipantTable.scss";
import useIsMobileOrTablet from "../../../../hooks/useIsMobileOrTablet";

const MAX_PARTICIPANTS_PER_PAGE = 60;

export interface ReportingParticipant {
  team: number;
  firstName: string;
  lastName: string;
}

export const getParticipantSummaryMaxClicks = (
  participants: ReportingParticipant[],
) => {
  const filtered = participants.filter((participant) => {
    const hasPlayerInFirstName = participant.firstName
      .toLowerCase()
      .includes("player");
    const hasTabletInLastName = participant.lastName
      .toLowerCase()
      .includes("tablet");
    return !hasPlayerInFirstName && !hasTabletInLastName;
  });
  return Math.ceil(filtered.length / MAX_PARTICIPANTS_PER_PAGE) - 1;
};

interface ParticipantsTableProps {
  participants: ReportingParticipant[];
  brandingLogo: string;
  defaultTitle?: string;
}

interface BrandingHeaderProps {
  brandingLogo: string;
  defaultTitle?: string;
}

const BrandingHeader: React.FC<BrandingHeaderProps> = ({
  brandingLogo,
  defaultTitle,
}) => (
  <InlineGroup verticalCenter block spread>
    <Image
      className="header-logo"
      contain
      src={brandingLogo}
      alt="branding image"
      dynamicSize={{
        min: "100px",
        preferred: "15%",
        max: "300px",
      }}
    />
    <InlineGroup width="70%" center>
      <EditableReportingHeading center defaultHeading={defaultTitle} />
    </InlineGroup>
    <Image
      className="header-logo"
      contain
      src={excelerateLogo}
      alt="branding image"
      dynamicSize={{
        min: "100px",
        preferred: "15%",
        max: "300px",
      }}
    />
  </InlineGroup>
);
const EmptyRow: React.FC = React.memo(() => (
  <Tr>
    <Td className="participant-summary-row">
      <ResultsText>&nbsp;</ResultsText>
    </Td>
    <Td className="participant-summary-row">
      <ResultsText>&nbsp;</ResultsText>
    </Td>
    <Td className="participant-summary-row">
      <ResultsText>&nbsp;</ResultsText>
    </Td>
  </Tr>
));

EmptyRow.displayName = "EmptyRow";

const ParticipantsTableContent: React.FC<{
  participants: ReportingParticipant[];
  maxRows: number;
  className?: string;
}> = ({ participants, maxRows, className }) => {
  const emptyRows = useMemo(() => {
    const emptyRowsCount = maxRows - participants.length;
    return [...Array(emptyRowsCount)].map((_, index) => (
      <EmptyRow key={`empty-${index}`} />
    ));
  }, [maxRows, participants.length]);

  return (
    <VerticalGroup full wide>
      <hr className="participant-summary-divider" />
      <InlineGroup block center>
        <ResultsText bold center colour="danger">
          Participants (Sorted by First Name)
        </ResultsText>
      </InlineGroup>
      <Table className={className}>
        <thead>
          <Tr className="intro-participant-summary-table-header">
            <Th>
              <ResultsText colour="white">Table #</ResultsText>
            </Th>
            <Th>
              <ResultsText colour="white">First Name</ResultsText>
            </Th>
            <Th>
              <ResultsText colour="white">Last Name</ResultsText>
            </Th>
          </Tr>
        </thead>
        <tbody>
          {participants.map((participant, index) => (
            <Tr
              key={`${participant.firstName}-${participant.lastName}-${index}`}
            >
              <Td className="participant-summary-row">
                <ResultsText>{participant.team}</ResultsText>
              </Td>
              <Td className="participant-summary-row">
                <ResultsText>{participant.firstName}</ResultsText>
              </Td>
              <Td className="participant-summary-row">
                <ResultsText>{participant.lastName}</ResultsText>
              </Td>
            </Tr>
          ))}
          {emptyRows}
        </tbody>
      </Table>
    </VerticalGroup>
  );
};

const ParticipantsTable: React.FC<ParticipantsTableProps> = ({
  participants,
  brandingLogo,
  defaultTitle,
}) => {
  const context = useResultsContext();
  const isMobileOrTablet = useIsMobileOrTablet();

  const filteredParticipants = useMemo(() => {
    return participants.filter((participant) => {
      const hasPlayerInFirstName = participant.firstName
        .toLowerCase()
        .includes("player");
      const hasTabletInLastName = participant.lastName
        .toLowerCase()
        .includes("tablet");
      return !hasPlayerInFirstName && !hasTabletInLastName;
    });
  }, [participants]);

  const filteredAndSortedParticipants = useMemo(() => {
    return [...filteredParticipants].sort((a, b) =>
      a.firstName.localeCompare(b.firstName),
    );
  }, [filteredParticipants]);

  const totalPages = Math.ceil(
    filteredParticipants.length / MAX_PARTICIPANTS_PER_PAGE,
  );
  const currentPage = Math.min(context.clicks, totalPages - 1);

  const currentPageTables = useMemo(() => {
    const startIndex = currentPage * MAX_PARTICIPANTS_PER_PAGE;
    const pageParticipants = filteredAndSortedParticipants.slice(
      startIndex,
      Math.min(
        startIndex + MAX_PARTICIPANTS_PER_PAGE,
        filteredAndSortedParticipants.length,
      ),
    );
    const participantCount = pageParticipants.length;
    const tablesToUse = Math.max(
      2,
      Math.min(3, Math.ceil(participantCount / 20)),
    );
    const maxRowsPerTable = Math.ceil(participantCount / tablesToUse);
    const tables: ReportingParticipant[][] = Array(tablesToUse)
      .fill(null)
      .map(() => []);

    pageParticipants.forEach((participant, index) => {
      const tableIndex = Math.floor(index / maxRowsPerTable);
      if (tableIndex < tablesToUse) {
        tables[tableIndex].push(participant);
      }
    });

    return { tables, maxRowsPerTable };
  }, [filteredAndSortedParticipants, currentPage]);

  const onNext = React.useCallback(() => {
    if (currentPage < totalPages - 1) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context, currentPage, totalPages]);

  return (
    <div
      className="results-container"
      style={{
        flex: 1,
        justifyContent: "space-between",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <RoundResultsContainer withoutPadding fullHeight onNext={onNext}>
        <Card className="intro-participant-summary-slide" wide>
          <VerticalGroup wide center full>
            <BrandingHeader
              defaultTitle={defaultTitle}
              brandingLogo={brandingLogo}
            />

            {filteredParticipants.length === 0 ? (
              <VerticalGroup className="pb-8" verticalCenter center full>
                <ResultsText size="lg" color="secondary">
                  No Participants to Display
                </ResultsText>
              </VerticalGroup>
            ) : (
              <InlineGroup
                fullHeight
                spaceBetweenElements={isMobileOrTablet ? 3 : 8}
                block
                spread
              >
                {currentPageTables.tables.map((tableParticipants, index) => (
                  <React.Fragment key={index}>
                    {tableParticipants.length > 0 && (
                      <ParticipantsTableContent
                        participants={tableParticipants}
                        maxRows={currentPageTables.maxRowsPerTable}
                        className={classNames(
                          "intro-participant-summary-table",
                          {
                            current: true,
                          },
                        )}
                      />
                    )}
                  </React.Fragment>
                ))}
              </InlineGroup>
            )}

            <ResultsText size="sm" center></ResultsText>
          </VerticalGroup>
        </Card>
      </RoundResultsContainer>
    </div>
  );
};

export default ParticipantsTable;
