import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
  ReferenceLine,
} from "recharts";
import { useResultsContext } from "../context/context";
import useIsHighResolution from "../../../../hooks/useIsHightResolution";
import useIsMobile from "../../../../hooks/useIsMobile";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";

interface Props {
  data: any[];
  formatter?: (val: number) => string | number;
  maxYAxisvalue?: number;
  minYAxisvalue?: number;
  numberOfTicks?: number;
  withoutCpuTeams?: boolean;
  shouldRenderLines?: boolean;
  hideAxes?: boolean;
  showInnerLabels?: boolean;
  barSize?: number;
  showBottomLabels?: boolean;
  showTopLabels?: boolean;
  logo?: React.ReactNode;
  additionalLogo?: React.ReactNode;
}

const ResultsBarChart: React.FC<Props> = ({
  data,
  formatter,
  maxYAxisvalue,
  minYAxisvalue,
  numberOfTicks,
  shouldRenderLines,
  hideAxes = false,
  showInnerLabels = false,
  barSize = 500,
  showBottomLabels = true,
  showTopLabels = true,
}) => {
  const isHighResolution = useIsHighResolution();
  const context = useResultsContext();
  const isMobile = useIsMobile();

  const CustomBar = (props: any) => {
    const {
      x,
      y,
      width,
      height,
      fill,
      logo,
      additionalLogo,
      border,
      value,
      showInnerLabels,
      formatter,
      index,
    } = props;
    const logoHeight = 100;
    const additionalLogoHeight = 50;
    const textPadding = 40;
    const lastIndex = data.length - 1;
    const textColor = index === 0 || index === lastIndex ? "black" : "white";

    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill={fill}
          stroke={border ? "#000000" : fill}
          strokeWidth={border ? 2 : 0}
        />
        {showInnerLabels && (
          <text
            x={x + width / 2}
            y={y + textPadding}
            fill={textColor}
            textAnchor="middle"
            fontSize="clamp(0.70rem, calc(0.70rem + 1.2vw), 2.9rem)"
            fontWeight={600}
          >
            {formatter ? formatter(value) : value}
          </text>
        )}
        <foreignObject
          x={x}
          y={y + height - logoHeight - additionalLogoHeight}
          width={width}
          height={logoHeight + additionalLogoHeight}
        >
          <VerticalGroup wide bottom center full>
            {additionalLogo && (
              <div
                style={{
                  height: additionalLogoHeight,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {additionalLogo}
              </div>
            )}
            <div
              style={{
                height: logoHeight,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {logo}
            </div>
          </VerticalGroup>
        </foreignObject>
      </g>
    );
  };
  return (
    <ResponsiveContainer width="100%" height="92%" debounce={1}>
      <BarChart
        data={data}
        margin={{
          top: 30,
          right: 50,
          left: isHighResolution ? 60 : 20,
          bottom: 60,
        }}
      >
        {!hideAxes && <CartesianGrid vertical={false} />}
        <ReferenceLine y={0} stroke="#000" />
        {!hideAxes && (
          <XAxis
            dataKey="name"
            tickLine={false}
            padding={{ left: isMobile ? 20 : 75, right: isMobile ? 20 : 75 }}
            hide={true}
          />
        )}
        {!hideAxes && (
          <YAxis
            axisLine={false}
            tickLine={false}
            tickCount={numberOfTicks ? numberOfTicks + 1 : undefined}
            tickFormatter={
              formatter ? (tick) => formatter(tick) : (tick) => tick
            }
            domain={[minYAxisvalue ?? "auto", maxYAxisvalue ?? "auto"]}
            tick={{ fontSize: "clamp(0.15rem, calc(0.15rem + 0.80vw), 2rem)" }}
          />
        )}
        {shouldRenderLines && (
          <Bar
            type="natural"
            dataKey={"value"}
            animationDuration={context.noAnimations ? 0 : 1000}
            animationBegin={0}
            barSize={barSize}
            shape={
              <CustomBar
                x={0}
                y={0}
                width={0}
                height={0}
                fill=""
                stroke=""
                strokeWidth={0}
                showInnerLabels={showInnerLabels}
                formatter={formatter}
              />
            }
          >
            {showTopLabels && (
              <LabelList
                dataKey="topLabel"
                position="top"
                content={(props: any) => {
                  const { x, y, width, value } = props;
                  return value ? (
                    <text
                      x={x + width / 2}
                      y={y - 10}
                      fill="#000000"
                      fontWeight={600}
                      textAnchor="middle"
                      fontSize="clamp(0.8rem, calc(0.8rem + 0.5vw), 1.3rem)"
                    >
                      {value}
                    </text>
                  ) : null;
                }}
              />
            )}
            {!showInnerLabels && (
              <LabelList
                position="top"
                content={data[0].topLabel}
                valueAccessor={(data: any) => data.payload}
              />
            )}

            {data[0].hasMiddleLabel && (
              <LabelList
                position="middle"
                content={data[0].content}
                valueAccessor={(data: any) => data.payload}
              ></LabelList>
            )}
            {showBottomLabels && (
              <LabelList
                position="bottom"
                content={data[0].legend}
                valueAccessor={(data: any) => data.payload}
              />
            )}
          </Bar>
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ResultsBarChart;
