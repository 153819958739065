import React, { ReactNode, useCallback, useEffect } from "react";
import StandardModal from "../standard-modal/StandardModal";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import Button from "../../atoms/button/Button";
import ErrorModal from "../standard-modal/ErrorModal";
import SuccessModal from "../standard-modal/SuccessModal";
interface Props {
  isOpen: boolean;
  confirmTitle?: string;
  discardTitle?: string;
  cancelTitle?: string;
  confirmInProgress?: boolean;
  onConfirm: () => void;
  onClose: () => void;
  onDiscard?: () => void;
  onCancel?: () => void;
  title: string;
  description?: string | ReactNode;
  error?: string | null;
  disabled?: boolean;
  warning?: boolean;
  confirmationMessage?: string | ReactNode;
}

function ConfirmModal({
  isOpen,
  onClose,
  title,
  confirmTitle,
  discardTitle,
  cancelTitle,
  onConfirm,
  onDiscard,
  onCancel,
  confirmInProgress,
  error,
  description,
  disabled,
  warning,
  confirmationMessage,
}: Props) {
  const handleUserKeyPress = useCallback(
    (event: KeyboardEvent) => {
      const { key } = event;
      if (key === "Enter") {
        onConfirm();
      }
    },
    [onConfirm],
  );

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("keydown", handleUserKeyPress);
    }
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress, isOpen]);

  if (isOpen && error) {
    return <ErrorModal isOpen onClose={onClose} description={error} />;
  }
  if (confirmationMessage) {
    return (
      <SuccessModal
        isOpen
        onClose={onClose}
        description={confirmationMessage}
      />
    );
  }
  return (
    <StandardModal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      description={description}
    >
      <>
        <InlineGroup className="mt-4" spread spaceBetweenElements={2}>
          {onCancel && (
            <Button data-test="modal-cancel" light onClick={onCancel} large>
              {cancelTitle || "Cancel"}
            </Button>
          )}
          <InlineGroup right spaceBetweenElements={2}>
            {onDiscard && (
              <Button
                data-test="modal-discard"
                onClick={onDiscard}
                inProgress={confirmInProgress}
                disabled={confirmInProgress || disabled}
                large
              >
                {discardTitle || "Discard"}
              </Button>
            )}
            <Button
              data-test="modal-confirm"
              onClick={onConfirm}
              inProgress={confirmInProgress}
              disabled={confirmInProgress || disabled}
              warning={warning}
              large
            >
              {confirmTitle || "Yes"}
            </Button>
          </InlineGroup>
        </InlineGroup>
      </>
    </StandardModal>
  );
}

export default ConfirmModal;
