import React from "react";
import Card from "../../../atoms/card/Card";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import "../RoundResults.scss";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import ResultsText from "../results-text/ResultsText";
import EditableReportingHeading from "./EditableReportingHeading";

interface Props {
  currencyDenomination?: string;
  heading: string;
}

const ChartCard: React.FC<Props> = ({
  currencyDenomination,
  heading,
  children,
}) => {
  return (
    <Card style={{ paddingTop: "0px" }} flex wide sharp>
      <VerticalGroup full center wide>
        <VerticalGroup full center wide>
          <EditableReportingHeading defaultHeading={heading} />
          {children}
        </VerticalGroup>
        {currencyDenomination && (
          <VerticalGroup wide>
            <InlineGroup
              right
              block
              className="pr-12"
              style={{ alignSelf: "flex-end" }}
            >
              <ResultsText size="lg" bold>
                {currencyDenomination}&nbsp;
              </ResultsText>
            </InlineGroup>
          </VerticalGroup>
        )}
      </VerticalGroup>
    </Card>
  );
};

export default ChartCard;
