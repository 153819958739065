import { useCallback, useEffect, useMemo } from "react";
import ModelAPI from "../services/modelApi";
import useAPIRequest from "./useAPIRequest";

export const useReportingResults = (eventId: string, roundId: number) => {
  const callback = useCallback(() => {
    return ModelAPI.getReportingResults(eventId, roundId);
  }, [eventId, roundId]);

  const [{ inProgress, data, error }, doAPIRequest] =
    useAPIRequest<ModelAPI.Reporting.ReportingResults>(callback, null);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  return useMemo(
    () => ({
      inProgress,
      data,
      error,
      refresh: doAPIRequest,
    }),
    [inProgress, data, error, doAPIRequest],
  );
};

export default useReportingResults;
