import React from "react";
import { Link, matchPath, useHistory } from "react-router-dom";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";

import { routeConfig } from "../../../Router";
import logo from "../../../assets/header/logo.png";
import home from "../../../assets/header/home.svg";

import "./Header.scss";
import { useCurrentUser } from "../../../context/userContext";
import { formatPersonName } from "../../../lib/formatters";
import Text from "../../atoms/text/Text";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";

const flattenRoutes = (routes: typeof routeConfig): typeof routeConfig => {
  return routes
    .map((route) => {
      return [...(route.routes ? flattenRoutes(route.routes) : []), route];
    })
    .flat();
};

function Header() {
  const breadcrumbs = useReactRouterBreadcrumbs(routeConfig, {
    disableDefaults: true,
  });
  const history = useHistory();
  const user = useCurrentUser();

  const possibleRoutes: string[] = [];
  const routeToHeader: any = {};
  const routeToSecondaryHeader: any = {};
  routeToHeader["err"] = "Not Found";

  flattenRoutes(routeConfig).forEach((route) => {
    possibleRoutes.push(route.path);
    if (route.header) {
      routeToHeader[route.path] = route.header;
    }
    if (route.secondaryHeader) {
      routeToSecondaryHeader[route.path] = route.secondaryHeader;
    }
  });

  const { path } = matchPath(history.location.pathname, {
    path: possibleRoutes,
    exact: true,
    strict: true,
  }) || { path: "err" };

  return (
    <div className="header">
      <div className="top">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
        <div className="title" data-test="header-title">
          {routeToHeader[path]}
        </div>
        <div className="user">
          {user && <div className="name">{formatPersonName(user)}</div>}
          <div className="date">
            {new Date().toLocaleString("en-us", {
              weekday: "long",
              month: "long",
              day: "numeric",
            })}
          </div>
        </div>
      </div>
      <div className="bottom">
        <InlineGroup spaceBetweenElements={2} className="bottom-sidebar">
          <Link to="/">
            <Text size="md">
              <img src={home} alt="home" />
            </Text>
          </Link>
          <InlineGroup spaceBetweenElements={1}>
            {breadcrumbs.map(({ match, breadcrumb }, i) => {
              if (i === breadcrumbs.length - 1) {
                return (
                  <Text
                    key={match.url}
                    data-test={`breadcrumb-${i}`}
                    colour="white"
                    size="md"
                    allowOverflow
                  >
                    {breadcrumb}
                  </Text>
                );
              }
              return (
                <Link to={match.url} key={match.url}>
                  <InlineGroup
                    key={match.url}
                    data-test={`breadcrumb-${i}`}
                    spaceBetweenElements={1}
                  >
                    <Text colour="green" size="md" allowOverflow>
                      {breadcrumb}
                    </Text>
                    <Text colour="green" size="md" allowOverflow>
                      /
                    </Text>
                  </InlineGroup>
                </Link>
              );
            })}
          </InlineGroup>
        </InlineGroup>
        <InlineGroup className="pl-4">
          <Text size="md">{routeToSecondaryHeader[path]}</Text>
        </InlineGroup>
      </div>
    </div>
  );
}

export default Header;
