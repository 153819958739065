import React, { useEffect, useRef, useState } from "react";

interface AutoSizerProps extends React.HTMLAttributes<HTMLDivElement> {
  interval?: number;
  render: (width: number, height: number) => React.ReactNode;
}

const AutoSizer = React.memo(
  ({ interval, render, ...other }: AutoSizerProps) => {
    AutoSizer.displayName = "AutoSizer";
    const reference = useRef<HTMLDivElement>(null);
    const [size, setSize] = useState<{
      width: number;
      height: number;
    } | null>();
    useEffect(() => {
      let storedWidth = size?.width;
      let storedHeight = size?.height;
      const id = setInterval(() => {
        const element = reference.current;
        if (element) {
          const width = element.offsetWidth;
          const height = element.offsetHeight;
          if (width !== storedWidth || height !== storedHeight) {
            storedWidth = width;
            storedHeight = height;
            setSize({ width, height });
          }
        }
      }, interval ?? 100);
      return () => {
        clearInterval(id);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interval]);

    return (
      <div ref={reference} {...other}>
        {size && render && render(size.width, size.height)}
      </div>
    );
  },
);

interface ContentStretcherProps extends React.HTMLAttributes<HTMLDivElement> {
  sizerInterval?: number;
  contentWidth: number;
  contentHeight: number;
  children: React.ReactNode;
}

const ContentStretcher = ({
  sizerInterval,
  contentWidth,
  contentHeight,
  children,
  ...other
}: ContentStretcherProps) => (
  <AutoSizer
    {...other}
    style={{
      ...other.style,
      position: "relative",
      display: "flex",
    }}
    interval={sizerInterval}
    render={(containerWidth, containerHeight) => {
      const contentScale =
        containerHeight * contentWidth < containerWidth * contentHeight
          ? containerHeight / contentHeight
          : containerWidth / contentWidth;
      return (
        <div
          style={{
            position: "absolute",
            // left: '50%',
            // top: '50%',
            // transform: `translate(-50%, -50%) scale(${contentScale})`,
            transform: `scale(${contentScale})`,
            // transformOrigin: '50% 50%',
          }}
        >
          {children}
        </div>
      );
    }}
  />
);

export default ContentStretcher;
