import React, { forwardRef } from "react";
import classNames from "classnames";
import InputContainer from "./InputContainer";
import "./Input.scss";
import { Textbox, Option } from "./Textbox";
import { ThemeColours, ThemeSpacing } from "../../../../types/theme";
import RichTextEditor from "./richTextEditor/RichTextEditor";

export type InputStatus = "normal" | "correct" | "wrong";

interface Props
  extends React.InputHTMLAttributes<HTMLInputElement>,
    React.ComponentProps<typeof InputContainer> {
  allowLastPass?: boolean;
  inputClassName?: string;
  onFieldInvalid?: (errorMessage: string | undefined) => void;
  colour?: ThemeColours;
  status?: InputStatus;
  isLabelHtml?: boolean;
  enableRightClickMenu?: boolean;
  dropdownOptions?: Option[];
  lineHeight?: ThemeSpacing;
  richTextInputHeight?: string;
  maxHeight?: ThemeSpacing;
}

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      inputClassName,
      noBorder,
      label,
      labelFor,
      error,
      block,
      containerBlock,
      verticalCenter,
      autoFocus,
      large,
      small,
      reverse,
      right,
      helpTitle,
      helpDescription,
      allowLastPass = false,
      isVerticalLayout = false,
      type,
      status = "normal",
      colour,
      isLabelHtml = false,
      enableRightClickMenu,
      dropdownOptions = [],
      lineHeight,
      richTextInputHeight,
      readOnly,
      maxHeight,
      ...rest
    },
    ref,
  ) => {
    Input.displayName = "Input";
    const lineHeightClass = lineHeight ? `lineheight-${lineHeight}` : null;
    const maxHeightClass = maxHeight ? `maxHeight-${maxHeight}` : null;
    return (
      <InputContainer
        className={classNames(className, { block })}
        noBorder={noBorder}
        isLabelHtml={isLabelHtml}
        label={label}
        labelFor={labelFor}
        helpDescription={helpDescription}
        helpTitle={helpTitle}
        error={error}
        reverse={reverse}
        right={right}
        containerBlock={containerBlock}
        verticalCenter={verticalCenter}
        isVerticalLayout={isVerticalLayout}
        colour={colour}
      >
        {type !== "textbox" && type !== "richText" && (
          <input
            ref={ref}
            autoFocus={autoFocus}
            data-lpignore={allowLastPass ? "false" : "true"}
            className={classNames("input", inputClassName, lineHeightClass, {
              invalid: error,
              block,
              large,
              small,
              correct: status === "correct",
              wrong: status === "wrong",
            })}
            type={type}
            {...rest}
          />
        )}
        {type === "textbox" && (
          <Textbox
            dropdownOptions={dropdownOptions}
            enableRightClickMenu={enableRightClickMenu}
            ref={ref as any}
            role="textbox"
            className={classNames(
              "input",
              inputClassName,
              lineHeightClass,
              maxHeightClass,
              {
                invalid: error,
                block,
                large,
                small,
              },
            )}
            readOnly={readOnly}
            {...rest}
          />
        )}
        {type === "richText" && (
          <RichTextEditor
            richTextInputHeight={richTextInputHeight}
            value={rest.value as string}
            onChange={(newValue: { text: string; html: string }) => {
              const event = {
                target: { value: newValue.html },
              } as React.ChangeEvent<HTMLInputElement>;
              rest.onChange?.(event);
            }}
          />
        )}
      </InputContainer>
    );
  },
);

export default Input;
