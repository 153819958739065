import React from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import Text from "../../../../atoms/text/Text";

const Group = ({
  title,
  subtext,
  children,
}: {
  title: string;
  subtext?: string;
  children: React.ReactNode;
}) => {
  return (
    <>
      <h3>{title}</h3>
      {subtext && (
        <Text italic bold>
          {subtext}
        </Text>
      )}
      <VerticalGroup wide spaceBetweenElements={2} className="pt-2 pb-2">
        {children}
      </VerticalGroup>
    </>
  );
};

export default Group;
