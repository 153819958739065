export const pluralise = (
  singular: string,
  amount: number,
  plural?: string,
): string => {
  plural = plural ?? singular + "s";
  if (amount === 1) {
    return singular;
  }
  return plural;
};
