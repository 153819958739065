import React from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";
import pluckResultsByType from "../utils/pluckResultsByType";
import { excoDecisions } from "../constants";
import startCase from "lodash.startcase";
import { percentage } from "../utils/formatters";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["excoDecisions"];
}

const onlyHas2Options = ["employee-costs-10%", "bonus-season"];
const totalSections = [
  "communityTrustImpactReputation",
  "employeeEngagementImpact",
  "customerSatisfactionCardBusinessOnly",
  "customerSatisfactionAllProducts",
  "customerComplaints",
  "changeInUnresolvedRiskIncidents",
  "changeInRiskWeightedAssetsCards",
  "changeInRiskWeightedAssetsBusinessLoans",
  "changeInCreditCardInterestIncomePercentageChange",
  "changeInCreditCardReveueImpact",
  "changeInCreditCardCustomerAnnualSpendPerCustomer",
  "changeInTotalCreditCardCustomerAnnualSpendWholeBank",
  "additionalAttritionInBusinessCustomers",
  "remediationAndFines",
  "investmentCosts",
  "changeToCostToAssetRatioAllBusinesses",
  "coalMinerInterest",
  "coalMiningLoans",
  "coalMiningLosses",
  "costImpactsByProduct.HOME_LOANS",
  "costImpactsByProduct.BUSINESS_LOANS",
  "costImpactsByProduct.DEPOSITS",
  "costImpactsByProduct.CREDIT_CARDS",
  "costImpactsByProduct.WEALTH_MANAGEMENT",
  "costImpactsByProduct.INSTITUTIONAL_BANKING",
  "costImpactsByProduct.OFFSHORE_BANK",
];

const excoFields = [
  "reductionInCostAssetRatios",
  "reputationImpact",
  "increaseInNewRiskIncidents",
  "reductionInEmployeeEngagement",
  "interestIncomeImpact",
  "npsImpactOfCardsBusiness",
  "customerComplaintsImpact",
  "costOfDevelopment",
  "npsImpact",
  "riskWeightReductionCards",
  "changeInCreditCardCustomerAnnualSpendPerCustomer",
  "remediationAndFines",
  "additionalAttritionInBusinessCustomers",
  "changeInCreditCardReveueImpact",
  "changeInRiskWeightedAssetsBusinessLoans",
  "changeInTotalCreditCardCustomerAnnualSpendWholeBank",
  "coalMiningLoans",
  "investment",
  "investmentValue",
  "terminalValueImpact",
  "coalMinerInterest",
  "coalMiningLosses",
  "costImpactsByProduct.HOME_LOANS",
  "costImpactsByProduct.BUSINESS_LOANS",
  "costImpactsByProduct.DEPOSITS",
  "costImpactsByProduct.CREDIT_CARDS",
  "costImpactsByProduct.WEALTH_MANAGEMENT",
  "costImpactsByProduct.INSTITUTIONAL_BANKING",
  "costImpactsByProduct.OFFSHORE_BANK",
];

const impactFormatter = (val: number, field: string) => {
  if (
    [
      "interestIncomeImpact",
      "reductionInCostAssetRatios",
      "reputationImpact",
      "reductionInEmployeeEngagement",
      "riskWeightReductionCards",
      "additionalAttritionInBusinessCustomers",
      "changeInRiskWeightedAssetsBusinessLoans",
      "changeInTotalCreditCardCustomerAnnualSpendWholeBank",
      "coalMinerInterest",
    ].includes(field)
  ) {
    return percentage(val);
  }
  return null;
};

const fieldFormatter = (field: string) => {
  if (field === "costImpactsByProduct.HOME_LOANS")
    return "Cost Impacts (Home Loans)";
  if (field === "costImpactsByProduct.BUSINESS_LOANS")
    return "Cost Impacts (Business Loans)";
  if (field === "costImpactsByProduct.DEPOSITS")
    return "Cost Impacts (Deposits";
  if (field === "costImpactsByProduct.CREDIT_CARDS")
    return "Cost Impacts (Credit Cards)";
  if (field === "costImpactsByProduct.WEALTH_MANAGEMENT")
    return "Cost Impacts (Wealth Management)";
  if (field === "costImpactsByProduct.INSTITUTIONAL_BANKING")
    return "Cost Impacts (Institutional Banking)";
  if (field === "costImpactsByProduct.OFFSHORE_BANK")
    return "Cost Impacts (Offshore Bank)";

  return null;
};

const ModelTeamResultsExcoDecisions: React.FC<Props> = ({ data }) => {
  const excoData = pluckResultsByType(data, "excoDecisions");
  const totalData = pluckResultsByType(data, "total");
  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        {excoDecisions.map((e) => {
          const dataForDecision = pluckResultsByType(excoData, e);
          const sections = [
            {
              name: "Option A - Impacts",
              fields: excoFields,
              values: pluckResultsByType(dataForDecision, "optionA"),
              formatter: impactFormatter,
              fieldFormatter,
            },
            {
              name: "Option B - Impacts",
              fields: excoFields,
              values: pluckResultsByType(dataForDecision, "optionB"),
              formatter: impactFormatter,
              fieldFormatter,
            },
          ];
          if (!onlyHas2Options.includes(e)) {
            sections.push({
              name: "Option C - Impacts",
              fields: excoFields,
              values: pluckResultsByType(dataForDecision, "optionC"),
              formatter: impactFormatter,
              fieldFormatter,
            });
          }
          return <TableWithSections key={e} header={e} data={sections} />;
        })}
        <TableWithSections
          header="Total Executive Decision Impacts - All Decisions"
          data={totalSections.map((ts) => ({
            key: ts,
            name: startCase(ts),
            fields: [...excoDecisions, "total"],
            totalFields: ["total"],
            values: pluckResultsByType(totalData, ts),
            formatter: (value: number) => {
              if (
                [
                  "communityTrustImpactReputation",
                  "employeeEngagementImpact",
                  "changeInRiskWeightedAssetsCards",
                  "changeInRiskWeightedAssetsBusinessLoans",
                  "changeInCreditCardInterestIncomePercentageChange",
                  "changeInTotalCreditCardCustomerAnnualSpendWholeBank",
                  "additionalAttritionInBusinessCustomers",
                  "changeToCostToAssetRatioAllBusinesses",
                  "coalMinerInterest",
                ].includes(ts)
              ) {
                return percentage(value);
              }
              return null;
            },
          }))}
        />
      </VerticalGroup>
    </>
  );
};

export default ModelTeamResultsExcoDecisions;
