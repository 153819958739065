import React from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Image from "../../../atoms/image/Image";
import Card from "../../../atoms/card/Card";
import ArrowHeader from "../components/ArrowHeader";

import "../RoundResultsTablePage.scss";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import ResultsText from "../results-text/ResultsText";
import DynamicResultsText from "../results-text/DynamicResultsText";
import { DotAndText } from "../components/DotAndText";
import { ThemeColours } from "../../../../types/theme";

interface Props {
  data: ModelAPI.Reporting.NextDealRoom;
  config: API.ReportingTemplateLayoutConfig;
  dotColors?: ThemeColours[];
}

const RoundResultsDealRoomInformation: React.FC<Props> = ({
  data,
  config,
  dotColors,
}) => {
  const defaultColors: ThemeColours[] = [
    "blue",
    "yellow",
    "green",
    "lightgreen",
  ];
  const colors = dotColors || defaultColors;
  return (
    <RoundResultsContainer>
      <InlineGroup fullHeight block>
        <Card wide fullHeight sharp padding={0}>
          <InlineGroup fullHeight block>
            <VerticalGroup
              className="p-4 width-60-percent"
              spaceBetweenElements={2}
              full
            >
              <VerticalGroup style={{ height: "45%" }} wide>
                <InlineGroup
                  className="decisions-table-image-wrapper  "
                  fullHeight
                >
                  <Image
                    src={data.imageUrl}
                    alt="Deal Room Image"
                    style={{ width: "100%", height: "100%" }}
                    cover
                  />
                  <ResultsText
                    size="xl"
                    className="decisions-table-image-overlay"
                  >
                    {data.name}
                  </ResultsText>
                </InlineGroup>
              </VerticalGroup>
              <VerticalGroup
                spaceBetweenElements={2}
                style={{ paddingLeft: "1.5%" }}
                full
                wide
              >
                <ResultsText size="lg" bold>
                  {"Deal Overview"}
                </ResultsText>
                <VerticalGroup wide full>
                  <VerticalGroup wide spaceBetweenElements={4} full>
                    {data.overviewQuestion && (
                      <InlineGroup>
                        <VerticalGroup full>
                          {data.overviewQuestion.split("?").map((q) => {
                            if (q) {
                              return (
                                <ResultsText key={q} size="md">
                                  {q}?
                                </ResultsText>
                              );
                            }
                            return null;
                          })}
                        </VerticalGroup>
                      </InlineGroup>
                    )}

                    <div>
                      {data.overviewDescription.split("\n").map((d) => (
                        <DynamicResultsText key={d} size="md">
                          {d}
                        </DynamicResultsText>
                      ))}
                    </div>
                  </VerticalGroup>

                  {!!data.decide && data.decide.decisions.length > 0 && (
                    <VerticalGroup full spaceBetweenElements={4} wide>
                      <InlineGroup
                        style={{ zIndex: 2 }}
                        className="width-70-percent"
                      >
                        <ArrowHeader
                          text=" You'll need to discuss:"
                          colour="primaryDark"
                        />
                      </InlineGroup>
                      {data.decide.decisions.map((d: any, i) => (
                        <VerticalGroup key={i} full spaceBetweenElements={4}>
                          <DotAndText
                            textSize="md"
                            num={i + 1}
                            text={d}
                            dotColour={colors[i % colors.length]}
                            dotSize={
                              "clamp(0.10rem, calc(0.15rem + 1.5vw), 2rem)"
                            }
                          />
                        </VerticalGroup>
                      ))}
                    </VerticalGroup>
                  )}
                </VerticalGroup>
              </VerticalGroup>
            </VerticalGroup>
            <VerticalGroup
              className="p-8"
              full
              bgColour="primaryDark"
              style={{ width: "41%" }}
              spread
              center
            >
              <div />
              <ResultsText center colour="white" size="2xl" bold>
                {config.heading ?? "Deal Room"}
              </ResultsText>
              <ResultsText center colour="white" size="md">
                (more details in the simulation interface)
              </ResultsText>
            </VerticalGroup>
          </InlineGroup>
        </Card>
      </InlineGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsDealRoomInformation;
