import React from "react";
import InlineGroup from "../../inlinegroup/InlineGroup";
import Input, { InputStatus } from "./Input";
import Text from "../../text/Text";
import { ThemeColours } from "../../../../types/theme";

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  checked?: boolean;
  name: string;
  inputClassName?: string;
  colour?: ThemeColours;
  status?: InputStatus;
  isLabelHtml?: boolean;
}

const Checkbox: React.FC<Props> = ({
  name,
  label,
  checked,
  onChange,
  colour,
  status,
  isLabelHtml = false,
  ...rest
}) => {
  return (
    <InlineGroup verticalCenter spaceBetweenElements={2}>
      <Input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        reverse
        containerBlock
        right
        status={status}
        {...rest}
      />
      <Text size="sm" isHtml={isLabelHtml} colour={colour}>
        {label}
      </Text>
    </InlineGroup>
  );
};

export default Checkbox;
