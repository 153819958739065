import React, { useMemo } from "react";

import Dropdown from "../../atoms/form/input/Dropdown";

interface Props {
  isCreatable?: boolean;
  onChange: (selected: any) => void;
  value?: number;
  error?: string;
  label?: string;
  name?: string;
  min: number;
  max: number;
  step?: number;
  labelFormatter?: (val: number) => string;
  formatCreateLabel?: (val: string) => string;
  placeholder?: string;
  small?: boolean;
  blocklist?: number[];
  tiny?: boolean;
  smallish?: boolean;
  useRoundsLabel?: boolean;
  decimalPlaces?: number;
  midsize?: boolean;
  menuPlacement?: "bottom" | "top";
  direction?: "desc" | "asc";
  helpTitle?: string;
  helpDescription?: string;
}

function IntegerDropdown({
  onChange,
  value,
  error,
  label,
  name,
  min,
  max,
  step = 1,
  isCreatable = false,
  useRoundsLabel = false,
  decimalPlaces = 0,
  labelFormatter = (val) =>
    useRoundsLabel ? `Round ${val}` : `${val.toFixed(decimalPlaces)}`,
  formatCreateLabel = (val) => `${val}`,
  placeholder,
  small,
  blocklist = [],
  tiny,
  smallish,
  midsize,
  menuPlacement = "bottom",
  direction,
  helpTitle,
  helpDescription,
}: Props) {
  const options = useMemo(() => {
    const totalOptions = (max - min) / step + 1;
    const basicOptions = Array(totalOptions)
      .fill(0)
      .map((_, idx) => {
        const value = Number((min + idx * step).toFixed(decimalPlaces));
        return {
          id: value,
          value: value,
          label: labelFormatter(value),
        };
      });

    if (value) {
      const current = basicOptions.find((item) => item.value === value);

      if (!current) {
        basicOptions.push({
          id: value,
          label: labelFormatter(value),
          value: value,
        });
      }
    }

    return basicOptions
      .sort((a, b) => {
        if (direction === "desc") return b.id - a.id;
        return a.id - b.id;
      })
      .filter(
        (option) => option.value === value || !blocklist.includes(option.value),
      );
  }, [
    max,
    min,
    step,
    value,
    decimalPlaces,
    labelFormatter,
    direction,
    blocklist,
  ]);

  const selectedValue = useMemo(() => {
    const selectedOption = options.find((option) => option.value === value);

    return selectedOption || options[0];
  }, [options, value]);
  const parseValue = (val: string) => {
    return Number(
      parseFloat(val.replace(/[^\d.]/g, "")).toFixed(decimalPlaces),
    );
  };
  return (
    <Dropdown
      menuPlacement={menuPlacement}
      isCreatable={isCreatable}
      data-test={name ?? "integer-dropdown"}
      label={label}
      error={error}
      small={small}
      tiny={tiny}
      smallish={smallish}
      midsize={midsize}
      helpDescription={helpDescription}
      helpTitle={helpTitle}
      selectProps={{
        placeholder: placeholder,
        isClearable: isCreatable,
        options: options,
        value: selectedValue,
        onChange,
        formatCreateLabel,
        getNewOptionData: (val: string) => {
          const numberValue = parseValue(val);
          return {
            id: numberValue,
            label: labelFormatter(numberValue),
            value: numberValue,
          };
        },
        classNamePrefix: name ?? "integer-dropdown",
      }}
    />
  );
}

export default IntegerDropdown;
