import React from "react";
import { PluginHook, useRowSelect, Hooks } from "react-table";

import Input from "../../atoms/form/input/Input";

export const createRowSelectPlugin = (
  showCheckboxes: boolean,
): PluginHook<any>[] => [
  useRowSelect,
  (hooks: Hooks) => {
    hooks.visibleColumns.push((columns) => {
      if (!showCheckboxes) return columns;
      return [
        {
          id: "selection",
          // eslint-disable-next-line react/prop-types
          Header: ({ getToggleAllRowsSelectedProps }) => {
            const checkboxProps = {
              ...getToggleAllRowsSelectedProps(),
              indeterminate: undefined,
            };
            return (
              <div style={{ marginTop: "20px" }}>
                <Input
                  {...checkboxProps}
                  className="checkbox"
                  type="checkbox"
                  data-test={"select-all"}
                />
              </div>
            );
          },
          // eslint-disable-next-line react/prop-types
          Cell: ({ row }) => {
            const checkboxProps = {
              // eslint-disable-next-line react/prop-types
              ...row.getToggleRowSelectedProps(),
              indeterminate: undefined,
            };
            return (
              <div>
                <Input
                  {...checkboxProps}
                  className="checkbox"
                  type="checkbox"
                  // eslint-disable-next-line react/prop-types
                  data-test={`select-${(row.original as any).id}`}
                />
              </div>
            );
          },
        },
        ...columns,
      ];
    });
  },
];
