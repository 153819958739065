import React from "react";
import RoundResultsCeoTransferTablet, {
  maxClicks as maxClicksTablet,
} from "./RoundResultsCeoTransferTablet";
import RoundResultsCeoTransferVirtual, {
  maxClicks as maxClicksVirtual,
} from "./RoundResultsCeoTransferVirtual";

type SimType = "online" | "tablet" | "hybrid";
interface Props {
  simulationType: SimType;
}

export const getMaxClicks = (simulationType: SimType) => {
  if (simulationType === "tablet") {
    return maxClicksTablet;
  }
  return maxClicksVirtual;
};

const RoundResultsCeoTransfer: React.FC<Props> = ({ simulationType }) => {
  if (simulationType === "tablet") {
    return <RoundResultsCeoTransferTablet />;
  } else {
    return <RoundResultsCeoTransferVirtual />;
  }
};

export default RoundResultsCeoTransfer;
