import React from "react";
import Header from "./components/organisms/header/Header";
import Sidebar from "./components/organisms/sidebar/Sidebar";
import useIsMobileOrTablet from "./hooks/useIsMobileOrTablet";

const Layout: React.FC = (props) => {
  const isMobileOrTablet = useIsMobileOrTablet();

  return (
    <div>
      {!isMobileOrTablet && <Header />}
      <div style={{ display: "flex" }}>
        {!isMobileOrTablet && <Sidebar />}
        <div style={{ flex: 1 }}>{props.children}</div>
      </div>
    </div>
  );
};

export default Layout;
