import React from "react";

import VirtualMeetingIntro from "../components/VirtualMeetingIntro";
import webexStart from "../../../../assets/results/webexstart.png";
import webexOne from "../../../../assets/results/webex.png";
import webexFour from "../../../../assets/results/webexFour.png";

const IntroWebex = () => {
  const steps = [
    {
      text: "Please minimize background noise by using mute.",
      imageOverride: webexOne,
    },
    { text: "Feel free to use the chat function for questions / comments." },
    { text: "Use the chat if you need our assistance." },
    {
      text: "We'd love to see you on camera, however, turn off cameras during videos.",
      imageOverride: webexFour,
    },
  ];

  return (
    <VirtualMeetingIntro
      defaultHeading="Webex Logistics"
      image={webexStart}
      steps={steps}
    />
  );
};

export default IntroWebex;
