import { unique } from "../../../../../lib/array";
// import { teamTableColourOptions } from '../../../../organisms/team-tablet-colour-dropdown/TeamTableColourDropdown'

export interface EventMapping {
  teamColours: API.TabletColour[];
  tablets: API.TabletDetailResponse[];
}

const sortByTeam = (a: API.TabletTeamResponse, b: API.TabletTeamResponse) =>
  a.teamId! - b.teamId!;

export const mapInitialTabletMappingForEvent = (
  event: API.EventResponse,
  tabletConfig: API.TabletMappingResponse,
): EventMapping => {
  const alreadyMapped = tabletConfig.teams.some(
    (tablet) => tablet.eventId === event.id,
  );
  if (alreadyMapped) {
    return {
      teamColours: tabletConfig.teams
        .filter((team) => team.eventId === event.id)
        .sort(sortByTeam)
        .map((t) => t.colour),
      tablets: tabletConfig.tablets,
    };
  }
  const teamsCount = event.simulation?.playersSetup?.teams ?? 0;

  const availableTabletsPerColour = tabletConfig.tablets.reduce(
    (acc: any, tablet: any) => {
      if (tablet.available) {
        acc[tablet.colour] = (acc[tablet.colour] || 0) + 1;
      }
      return acc;
    },
    {},
  );

  const availableColours = unique(
    tabletConfig.tablets
      .filter(
        (t) => t.available && t.participantId == null && t.colour !== "red",
      )
      .map((t) => t.colour),
  ).sort((a, b) => availableTabletsPerColour[b] - availableTabletsPerColour[a]);

  return {
    teamColours: Array.from(
      { length: teamsCount },
      (_, i) => availableColours[i % availableColours.length],
    ),
    tablets: tabletConfig.tablets,
  };
};

export const getUniqueTabletColors = (
  tablets: API.TabletDetailResponse[],
): API.TabletColour[] => {
  const uniqueColors = new Set<API.TabletColour>();
  tablets.forEach((tablet) => {
    uniqueColors.add(tablet.colour as API.TabletColour);
  });
  return Array.from(uniqueColors);
};
