import React, { useCallback } from "react";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Image from "../../../atoms/image/Image";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import { useResultsContext } from "../context/context";
import "../RoundResults.scss";
import image1 from "../../../../assets/results/packup1.png";
import image2 from "../../../../assets/results/tabletWithOutline.svg";
import classNames from "classnames";
import ResultsText from "../results-text/ResultsText";
import EditableReportingHeading from "../components/EditableReportingHeading";
import useIsMobile from "../../../../hooks/useIsMobile";
import ReportingSubHeading from "../components/ReportingSubHeading";

interface Props {}

const STARTING_FRAME = 0;
const TURN_OFF_YOUR_TABLETS = 1;
const STACK_TABLETS = 2;
const BRING_THEM = 3;

const TABLET_HEIGHT_DESKTOP = 400;
const TABLET_HEIGHT_MOBILE = 300;
const TabletWithBlackScreen: React.FC<{ animate?: boolean }> = ({
  animate,
}) => {
  const isMobile = useIsMobile();
  return (
    <>
      <Image
        src={image1}
        alt="Tablet"
        height={isMobile ? TABLET_HEIGHT_MOBILE : TABLET_HEIGHT_DESKTOP}
        style={{ alignSelf: "center", justifySelf: "center" }}
      />
      <div
        className={classNames({ "fade-in-opacity": animate })}
        style={{
          animationDelay: animate ? "1s" : undefined,
          backgroundColor: "black",
          position: "absolute",
          height: isMobile ? 230 : 305,
          width: isMobile ? 190 : 252,
          marginBottom: isMobile ? 8 : 12,
        }}
      />
    </>
  );
};

const RoundResultsPackUp: React.FC<Props> = () => {
  const context = useResultsContext();
  const isMobile = useIsMobile();
  const onNext = useCallback(() => {
    if (context.clicks >= BRING_THEM) {
      context.goNextPage();
    } else {
      context.addClick();
    }
  }, [context]);

  return (
    <RoundResultsContainer onNext={onNext}>
      <VerticalGroup full center wide spaceBetweenElements={4}>
        <VerticalGroup spaceBetweenElements={2} center wide>
          <EditableReportingHeading defaultHeading="Simulation Complete" />
          <ReportingSubHeading subHeading="Please Turn Off Your Tablets" />
        </VerticalGroup>
        {context.clicks === STARTING_FRAME && (
          <InlineGroup
            block
            fullHeight
            verticalCenter
            center
            style={{ alignSelf: "center", justifySelf: "center" }}
          >
            <Image
              src={image1}
              alt="Tablet"
              dynamicSize={{ min: "250px", preferred: "20vw", max: "800px" }}
            />
          </InlineGroup>
        )}
        {context.clicks > STARTING_FRAME && (
          <InlineGroup
            block
            fullHeight
            verticalCenter
            center
            spaceBetweenElements={24}
          >
            <InlineGroup
              className="slide-left"
              verticalCenter
              center
              style={{
                position: "relative",
                height: isMobile ? TABLET_HEIGHT_MOBILE : TABLET_HEIGHT_DESKTOP,
              }}
            >
              <TabletWithBlackScreen animate />
              {context.clicks > TURN_OFF_YOUR_TABLETS && (
                <InlineGroup
                  className="slide-right"
                  fullHeight
                  verticalCenter
                  center
                  style={{
                    position: "absolute",
                    height: isMobile
                      ? TABLET_HEIGHT_MOBILE
                      : TABLET_HEIGHT_DESKTOP,
                    top: isMobile ? 15 : 20,
                    right: isMobile ? 15 : 20,
                  }}
                >
                  <Image
                    src={image2}
                    alt="Tablet"
                    height={
                      isMobile ? TABLET_HEIGHT_MOBILE : TABLET_HEIGHT_DESKTOP
                    }
                    style={{ alignSelf: "center", justifySelf: "center" }}
                  />
                </InlineGroup>
              )}
              {context.clicks > TURN_OFF_YOUR_TABLETS && (
                <InlineGroup
                  className="slide-right"
                  fullHeight
                  verticalCenter
                  center
                  style={{
                    animationDelay: "1s",
                    position: "absolute",
                    height: isMobile
                      ? TABLET_HEIGHT_MOBILE
                      : TABLET_HEIGHT_DESKTOP,
                    top: isMobile ? 30 : 40,
                    right: isMobile ? 30 : 40,
                  }}
                >
                  <Image
                    src={image2}
                    alt="Tablet"
                    height={
                      isMobile ? TABLET_HEIGHT_MOBILE : TABLET_HEIGHT_DESKTOP
                    }
                    style={{ alignSelf: "center", justifySelf: "center" }}
                  />
                </InlineGroup>
              )}
              {context.clicks > TURN_OFF_YOUR_TABLETS && (
                <InlineGroup
                  className="slide-right"
                  fullHeight
                  verticalCenter
                  center
                  style={{
                    animationDelay: "2s",
                    position: "absolute",
                    height: isMobile
                      ? TABLET_HEIGHT_MOBILE
                      : TABLET_HEIGHT_DESKTOP,
                    top: isMobile ? 45 : 60,
                    right: isMobile ? 45 : 60,
                  }}
                >
                  <Image
                    src={image2}
                    alt="Tablet"
                    height={
                      isMobile ? TABLET_HEIGHT_MOBILE : TABLET_HEIGHT_DESKTOP
                    }
                    style={{ alignSelf: "center", justifySelf: "center" }}
                  />
                </InlineGroup>
              )}
            </InlineGroup>
            <VerticalGroup spaceBetweenElements={8}>
              <ResultsText size="lg">
                1. Please turn off your tablets
              </ResultsText>
              <ResultsText
                size="lg"
                style={{
                  visibility:
                    context.clicks < STACK_TABLETS ? "hidden" : undefined,
                }}
              >
                2. Stack them, all facing the same way
              </ResultsText>
              <ResultsText
                size="lg"
                style={{
                  visibility:
                    context.clicks < BRING_THEM ? "hidden" : undefined,
                }}
              >
                3. Bring them to us for packing
              </ResultsText>
            </VerticalGroup>
          </InlineGroup>
        )}
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsPackUp;
