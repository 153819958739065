import React, { useEffect } from "react";
import { useCallback } from "react";
import Differ from "./Differ";
import { useMemoRequest } from "../../../../hooks/useMemoRequest";
import ModelAPI from "../../../../services/modelApi";
import GameAPI from "../../../../services/gameApi";

interface Props {
  refreshId: number;
  eventId: string;
  selectedRound: number;
  setIssues: (val: number) => void;
}

const Opex: React.FC<Props> = ({
  refreshId,
  eventId,
  selectedRound,
  setIssues,
}) => {
  const getSetupValues = useCallback(async () => {
    const model = await ModelAPI.getModelOpexSetup(eventId, selectedRound);
    const game = await GameAPI.getOpexSetup(eventId, selectedRound);
    return { model, game };
  }, [eventId, selectedRound]);

  const { inProgress, data, error, refresh } = useMemoRequest<{
    model: any;
    game: any;
  }>(getSetupValues);

  useEffect(() => {
    if (refreshId > 0) {
      refresh();
    }
  }, [refreshId, refresh]);

  return (
    <Differ
      inProgress={inProgress}
      data={data}
      error={error}
      setIssues={setIssues}
    />
  );
};

export default Opex;
