import { useCallback, useEffect, useMemo, useState } from "react";
import API from "../services/api";
import useAPIRequest, { useAPIRequestRunner } from "./useAPIRequest";

const MAX_AGE = 500; // ms
const BRANDING_CACHE = new Map<string, Promise<API.AssessmentResponse>>();

export const useAssessmentResponses = (eventId: string) => {
  const callback = useCallback(() => {
    return API.getAssessmentResponses(eventId);
  }, [eventId]);

  const [{ inProgress, completed, data, error }, doAPIRequest] = useAPIRequest<
    API.AssessmentAnswerResponse[]
  >(callback, { data: [] });

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  const refresh = useCallback(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  return useMemo(
    () => ({
      inProgress,
      completed,
      data,
      error,
      refresh,
    }),
    [inProgress, completed, data, error, refresh],
  );
};

export const useMarkAnswer = () => {
  const [apiState, setAPIState] = useState<{
    inProgress: boolean;
    completed: boolean;
    error: Error | null;
  }>({
    inProgress: false,
    completed: false,
    error: null,
  });

  const markAnswer = useCallback(
    async (answerId: string, mark: number, comment: string) => {
      try {
        setAPIState({
          inProgress: true,
          completed: false,
          error: null,
        });
        await API.markAnswer(answerId, mark, comment);
        setAPIState({
          inProgress: false,
          completed: true,
          error: null,
        });
      } catch (e) {
        setAPIState({
          inProgress: false,
          completed: true,
          error: e as Error,
        });
      }
    },
    [],
  );

  return {
    apiState,
    markAnswer,
  };
};

export const useAssessment = (assessmentId: string) => {
  const callback = useCallback(() => {
    let cachedPromise = BRANDING_CACHE.get(assessmentId);
    if (cachedPromise) {
      return cachedPromise;
    }

    cachedPromise = API.getAssessment(assessmentId);

    BRANDING_CACHE.set(assessmentId, cachedPromise);
    setTimeout(() => {
      BRANDING_CACHE.delete(assessmentId);
    }, MAX_AGE);

    return cachedPromise;
  }, [assessmentId]);

  const [{ inProgress, data, error }, doAPIRequest] =
    useAPIRequest<API.AssessmentResponse>(callback, null);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  return useMemo(
    () => ({
      inProgress,
      data,
      error,
      refresh: doAPIRequest,
    }),
    [inProgress, data, error, doAPIRequest],
  );
};

const useAssessments = (clientIds: string[] = []) => {
  const {
    state: { inProgress, data, error, completed },
    run,
  } = useAPIRequestRunner<API.AssessmentResponse[]>();

  const doApiRequest = useCallback(() => {
    run(API.getAssessments(clientIds));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...clientIds, run]);

  useEffect(() => {
    doApiRequest();
  }, [doApiRequest]);

  return useMemo(
    () => ({
      inProgress,
      completed,
      data,
      error,
      refresh: doApiRequest,
    }),
    [inProgress, completed, data, error, doApiRequest],
  );
};

export default useAssessments;
