import React, { useMemo } from "react";
import { startCase } from "../../../../services/utils";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";
import { formatValue, formatBps } from "../utils/formatters";
import getTeamIdsAsFields from "../utils/getTeamIdsAsFields";
import getTeamName from "../utils/getTeamName";
import pluckResultsByType from "../utils/pluckResultsByType";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.DecisionsResponse["productPricing"];
  teams: API.GameConnection[];
}

const ProductPricing: React.FC<Props> = ({ configuration, data, teams }) => {
  const { numberOfTeams, numberOfCpuTeams, enabledProducts } = configuration;
  const totalNumberOfTeams = numberOfTeams + numberOfCpuTeams;
  const fields = useMemo(
    () => getTeamIdsAsFields(totalNumberOfTeams),
    [totalNumberOfTeams],
  );

  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        {enabledProducts.map((product) => {
          const type = product.type;
          const values = pluckResultsByType(
            pluckResultsByType(data, "primary"),
            type,
          );
          return (
            <TableWithSections
              key={type}
              header={startCase(product.name)}
              data={[
                {
                  fields,
                  values,
                },
              ]}
              fieldFormatter={(field: string) =>
                getTeamName(parseInt(field), numberOfTeams, teams)
              }
              formatter={(val: number) =>
                type === "CREDIT_CARDS" ? formatValue(val) : formatBps(val)
              }
            />
          );
        })}
      </VerticalGroup>
    </>
  );
};

export default ProductPricing;
