import React, { useMemo } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Theme from "../../../../styles/_theme.module.scss";
import { reportingPercentage } from "../../model/utils/formatters";
import { getYAxisDataV2 } from "../charts/yaxis";
import ChartCard from "../components/ChartCard";
import ResultsLineChart from "../charts/ResultsLineChart";
import { convertRoundDataToPercentages } from "../charts/convertRoundDataToPercentages";
import RoundResultsContainer from "../components/RoundsResultsContainer";

interface Props {
  pastRound1: boolean;
  wholesaleFundingCostsARatings: ModelAPI.Reporting.DataAndPositions;
}

const years = [
  { value: "wsf1", colour: Theme.chart0 },
  { value: "wsf2", colour: Theme.chart1 },
  { value: "wsf3", colour: Theme.chart2 },
  { value: "wsf4", colour: Theme.chart3 },
  { value: "wsf5", colour: Theme.chart4 },
];

const RoundResultsWholesaleFundingCostsARatings: React.FC<Props> = ({
  pastRound1,
  wholesaleFundingCostsARatings,
}) => {
  const data = useMemo(
    () => convertRoundDataToPercentages(wholesaleFundingCostsARatings.data),
    [wholesaleFundingCostsARatings.data],
  );

  const { minValue, maxValue, numberOfTicks, yAxisDp } = getYAxisDataV2({
    isPercentage: true,
    pastRound1,
    data,
  });

  const lines = years.map((y, i) => ({
    name: `${i + 1} Year`,
    key: y.value,
    colour: y.colour,
  }));

  return (
    <RoundResultsContainer>
      <VerticalGroup full center wide>
        <ChartCard heading="Wholesale Funding Costs - A Rating">
          <ResultsLineChart
            data={data}
            formatter={(val: number) => reportingPercentage(val)}
            yAxisFormatter={(val: number) => reportingPercentage(val, yAxisDp)}
            minYAxisvalue={minValue}
            maxYAxisvalue={maxValue}
            numberOfTicks={numberOfTicks}
            lines={lines}
            shouldRenderLines={true}
            linesOneByOne={false}
          />
        </ChartCard>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsWholesaleFundingCostsARatings;
