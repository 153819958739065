import React from "react";
import VirtualMeetingIntro from "../components/VirtualMeetingIntro";
import zoomStart from "../../../../assets/results/zoomstart.png";
import zoomMute from "../../../../assets/results/zoomOne.png";
import zoomChat from "../../../../assets/results/zoomTwo.png";
import zoomVideo from "../../../../assets/results/zoomThree.png";

const IntroZoom = () => {
  const steps = [
    {
      text: "Please minimize background noise by using mute.",
      imageOverride: zoomMute,
    },
    {
      text: "Feel free to use the chat function for questions / comments.",
      imageOverride: zoomChat,
    },
    {
      text: "Use the chat if you need our assistance.",
      imageOverride: zoomChat,
    },
    {
      text: "We'd love to see you on camera, however, turn off cameras during videos.",
      imageOverride: zoomVideo,
    },
  ];

  return (
    <VirtualMeetingIntro
      defaultHeading="Zoom Logistics"
      image={zoomStart}
      steps={steps}
    />
  );
};

export default IntroZoom;
