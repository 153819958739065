import React, { useCallback } from "react";
import Card from "../../../atoms/card/Card";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import EditableReportingHeading from "../components/EditableReportingHeading";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Icon from "../../../atoms/icon/Icon";
import ResultsText from "../results-text/ResultsText";
import "./IntroBreakoutRooms.scss";
import { useResultsContext } from "../context/context";
import classNames from "classnames";

export const BreakoutRoomsMaxClicks = 3;

const IntroBreakoutRooms = () => {
  const context = useResultsContext();
  const onNext = useCallback(() => {
    if (context.clicks < BreakoutRoomsMaxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context]);

  const showMainRoom = context.noAnimations || context.clicks >= 1;
  const showBreakoutRooms = context.noAnimations || context.clicks >= 2;
  const showTimerInfo = context.noAnimations || context.clicks >= 3;
  return (
    <RoundResultsContainer onNext={onNext}>
      <Card className="breakout-slide" fullHeight>
        <VerticalGroup wide center full>
          <EditableReportingHeading defaultHeading="Working Across Main & Breakout Rooms" />
          <InlineGroup width="75%">
            <VerticalGroup
              className={classNames("main-room-column", {
                show: showMainRoom,
              })}
            >
              <InlineGroup>
                <Icon type="mainRoom" fullSize />
              </InlineGroup>
              <VerticalGroup
                className="main-room-info"
                spaceBetweenElements={2}
                center
                full
              >
                <ResultsText colour="primaryDark" bold>
                  Main Room
                </ResultsText>
                <ResultsText center>
                  Everyone is currently in the main room.
                </ResultsText>
                <ResultsText center>
                  We will present to all participants from this room.
                </ResultsText>
              </VerticalGroup>
            </VerticalGroup>

            <VerticalGroup
              className={classNames("breakout-column", {
                show: showBreakoutRooms,
              })}
              center
              full
            >
              <InlineGroup>
                <Icon type="breakout" fullSize />
              </InlineGroup>
              <VerticalGroup
                className="breakout-rooms-info"
                spaceBetweenElements={2}
                center
                full
              >
                <ResultsText colour="blue" bold>
                  Breakout Rooms
                </ResultsText>
                <ResultsText center>
                  You will shortly go to your breakout rooms.
                </ResultsText>
                <ResultsText center>
                  There is a separate invite for these rooms.
                </ResultsText>
                <ResultsText center>
                  This is a private room for your team alone to work through the
                  simulation.
                </ResultsText>
              </VerticalGroup>
            </VerticalGroup>

            <VerticalGroup
              className={classNames("breakout-timer-column", {
                show: showTimerInfo,
              })}
              center
              full
            >
              <InlineGroup>
                <Icon type="roomsTimer" fullSize />
              </InlineGroup>
              <VerticalGroup className="breakout-timer-info" full center>
                <ResultsText center>
                  When the simulation time runs out, we will bring you back to
                  the main room
                </ResultsText>
              </VerticalGroup>
            </VerticalGroup>
          </InlineGroup>
        </VerticalGroup>
      </Card>
    </RoundResultsContainer>
  );
};

export default IntroBreakoutRooms;
