import React from "react";
import Clickable from "../../../../atoms/clickable/Clickable";
import Icon, { IconType } from "../../../../atoms/icon/Icon";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import SimpleModal from "../../../../organisms/standard-modal/SimpleModal";
import LogoContainer from "../../../../LogoContainer/LogoContainer";
interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedLogo: API.BreakingNewsConfigLogoType;
  onLogoUpdate: (logo: API.BreakingNewsConfigLogoType) => void;
}

export const LOGO_TO_ICON_MAP: Record<
  API.BreakingNewsConfigLogoType,
  IconType
> = {
  bear: "newsBear",
  bull: "newsBull",
};

const LOGOS: API.BreakingNewsConfigLogoType[] = ["bear", "bull"];

const Logo: React.FC<{
  active: boolean;
  logo: API.BreakingNewsConfigLogoType;
  onLogoUpdate: (logo: API.BreakingNewsConfigLogoType) => void;
}> = ({ active, logo, onLogoUpdate }) => (
  <Clickable onClick={() => onLogoUpdate(logo)}>
    <LogoContainer active={active} colour="secondaryBlue" size={1}>
      <Icon stroke="white" key={logo} type={LOGO_TO_ICON_MAP[logo]} size={20} />
    </LogoContainer>
  </Clickable>
);

const LogoModal: React.FC<Props> = ({
  isOpen,
  onClose,
  selectedLogo,
  onLogoUpdate,
}) => {
  return (
    <SimpleModal size="small" isOpen={isOpen} showCloseButton onClose={onClose}>
      <VerticalGroup spaceBetweenElements={2}>
        <InlineGroup spaceBetweenElements={2} evenWidthChildren block>
          {LOGOS.map((logo) => {
            const active = logo === selectedLogo;
            return (
              <InlineGroup key={logo} center className="width-50-percent">
                <Logo logo={logo} onLogoUpdate={onLogoUpdate} active={active} />
              </InlineGroup>
            );
          })}
        </InlineGroup>
      </VerticalGroup>
    </SimpleModal>
  );
};

export default LogoModal;
