import React, { useCallback } from "react";
import { useMemoRequest } from "../../../../hooks/useMemoRequest";
import ModelAPI from "../../../../services/modelApi";
import ModelPageContainer from "../ModelPageContainer";
import ModelAdjustmentsContent from "./ModelAdjustmentsContent";

interface Props {
  eventId: string;
  configuration: ModelAPI.ConfigurationResponse;
  selectedTeam: number;
  selectedRound: number;
  teams: API.GameConnection[];
}

const ModelAdjustments: React.FC<Props> = ({
  eventId,
  selectedRound,
  configuration,
  teams,
}) => {
  const getAdjustments = useCallback(() => {
    return ModelAPI.getAdjustments(eventId);
  }, [eventId]);

  const {
    inProgress,
    data: adjustmentData,
    error,
    refresh,
  } = useMemoRequest<ModelAPI.FacilitatorAdjustmentsResponse>(getAdjustments);

  const getResults = useCallback(() => {
    return ModelAPI.getModelTeamResultsAll(eventId, selectedRound);
  }, [eventId, selectedRound]);

  const {
    inProgress: resultInProgress,
    data: resultData,
    error: resultsError,
    refresh: resultsRefresh,
  } = useMemoRequest<ModelAPI.RoundResultsResponse[]>(getResults);

  const handleUpdate = useCallback(
    async (
      type: keyof ModelAPI.FacilitatorAdjustmentsResponse,
      teamId: number,
      year: number,
      value: number,
    ) => {
      try {
        await ModelAPI.updateAdjustments(
          eventId,
          year - configuration.numberOfHistoricRounds + 1,
          {
            teamId,
            type,
            value,
          },
        );
        refresh();
        resultsRefresh();
      } catch (e) {
        console.error("Error updating adjustments:", e);
      }
    },
    [configuration.numberOfHistoricRounds, eventId, refresh, resultsRefresh],
  );

  return (
    <ModelPageContainer
      eventId={eventId}
      selectedRound={selectedRound}
      data={adjustmentData}
      refresh={() => {
        refresh();
        resultsRefresh();
      }}
      error={error ?? resultsError}
      inProgress={inProgress || resultInProgress}
    >
      {adjustmentData && resultData && (
        <ModelAdjustmentsContent
          eventId={eventId}
          selectedRound={selectedRound}
          configuration={configuration}
          teams={teams}
          adjustmentData={adjustmentData}
          resultData={resultData}
          onUpdate={handleUpdate}
        />
      )}
    </ModelPageContainer>
  );
};

export default ModelAdjustments;
