import React, { useCallback, useEffect, useState } from "react";
import {
  CheckboxesFormDetails,
  validateCheckboxForm,
} from "./FormDetails/CheckboxesFormDetails";
import {
  DEFAULT_CHOICES,
  MultipleChoiceFormDetails,
  validateMultipleChoiceForm,
} from "./FormDetails/MultipleChoiceFormDetails";
import { ImageFormDetails } from "./FormDetails/ImageFormDetails";
import { GroupSelect } from "./Dropdowns/GroupSelect";
import { QUESTION_TYPE_NAME_MAPPING } from "../QuestionTable/QuestionRow";
import { QuestionTypeSelect } from "./Dropdowns/QuestionTypeSelect";
import { WrittenResponseFormDetails } from "./FormDetails/WrittenResponseFormDetails";
import { ShortWrittenResponseDetails } from "./FormDetails/ShortWrittenResponseDetails";
import {
  RatingFormDetails,
  validateRatingForm,
} from "./FormDetails/RatingFormDetails";
import { WrittenTextDetails } from "./FormDetails/WrittenTextDetails";
import Button from "../../../../atoms/button/Button";
import CancelButton from "../../../../atoms/form/actions/CancelButton";
import ErrorModal from "../../../../organisms/standard-modal/ErrorModal";
import Form, { FormRequiredText } from "../../../../atoms/form/Form";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import Input from "../../../../atoms/form/input/Input";
import Toggle from "../../../../atoms/toggle/Toggle";
import useForm from "../../../../../hooks/useForm";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import { changeQuestionType } from "./question-type-transform";

interface CommonProps {
  assessmentId: string;
  groupId: string;
  onComplete: (groupId: string) => void;
  onCancel: () => void;
  onNext?: () => void;
}

interface CreateProps extends CommonProps {
  mode: "create";
  onSave: (
    groupId: string,
    payload: API.AssessmentQuestionRequest,
  ) => Promise<API.AssessmentQuestionResponse>;
}

interface EditProps extends CommonProps {
  mode: "edit" | "clone";
  data: API.AssessmentQuestionResponse;
  onSave: (
    groupId: string,
    payload: API.AssessmentQuestionRequest,
  ) => Promise<API.AssessmentQuestionResponse>;
}

type Props = CreateProps | EditProps;

const fromPropData = (props: EditProps) => {
  return {
    ...props.data,
  };
};

const fromClonePropData = (props: EditProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...rest } = props.data;
  return rest;
};

const toFields = ({
  id,
  config,
  type,
}: API.AssessmentQuestionFieldRequest) => ({
  id,
  type,
  config,
});

export const QuestionForm = (props: Props) => {
  const { mode, assessmentId, groupId, onSave, onComplete, onCancel, onNext } =
    props;

  const [showErrorModal, setShowErrorModel] = useState(false);

  const [
    { formData, inProgress, fieldErrors, error, completed, formUpdated },
    {
      setField,
      setFieldRaw,
      setFieldSimple,
      setFieldError,
      setNumberField,
      handleSubmit,
      clearFormError,
    },
  ] = useForm(
    mode === "edit"
      ? fromPropData(props as EditProps)
      : mode === "clone"
        ? fromClonePropData(props as EditProps)
        : {
            type: "multiple_choices",
            question: "",
            mandatory: true,
            assignMarks: false,
            fields: [...DEFAULT_CHOICES],
            marks: 0,
          },
  );

  const { question, mandatory, assignMarks, marks, fields } = formData;

  const type = formData.type as API.QuestionType;
  const [selectedGroup, setSelectedGroup] = useState<string | null>(groupId);
  const [shouldGoToNext, setShouldGoToNext] = useState(false);

  const callback = useCallback(() => {
    const payload = {
      type,
      question,
      mandatory,
      assignMarks,
      marks,
      fields: fields.map(toFields),
    };

    return onSave(selectedGroup ?? groupId, payload);
  }, [
    assignMarks,
    fields,
    groupId,
    mandatory,
    marks,
    onSave,
    question,
    type,
    selectedGroup,
  ]);

  const onChangeQuestionTYpe = useCallback(
    (value) => {
      changeQuestionType(setFieldRaw, type, value.value, fields);
    },
    [setFieldRaw, type, fields],
  );

  const validate = useCallback(() => {
    const errors = [];
    if (!groupId) {
      errors.push({ field: "groupId", message: "Select a group" });
    }

    if (
      type !== "image" &&
      (!question || (question && question.trim().length === 0))
    ) {
      errors.push({ field: "question", message: "Question can not be empty" });
    }

    if (type === "rating") {
      validateRatingForm(formData, errors);
    } else if (type === "checkboxes") {
      validateCheckboxForm(formData, errors);
    } else if (type === "multiple_choices") {
      validateMultipleChoiceForm(formData, errors);
    }

    return errors;
  }, [groupId, type, question, formData]);

  const onSubmit = useCallback(async () => {
    clearFormError();
    const success = await handleSubmit(callback, validate, true)();
    return success;
  }, [callback, clearFormError, handleSubmit, validate]);

  const onSubmitAndNext = useCallback(async () => {
    setShouldGoToNext(true);
    await onSubmit();
    onNext?.();
  }, [onSubmit, onNext]);

  useEffect(() => {
    if (!!completed && !error) {
      if (onNext && shouldGoToNext) {
        onNext();
      } else {
        onComplete(selectedGroup ?? groupId);
      }
    }

    if (error) {
      setShowErrorModel(true);
    }
  }, [
    completed,
    error,
    onComplete,
    selectedGroup,
    groupId,
    onNext,
    shouldGoToNext,
  ]);

  return (
    <VerticalGroup full spread>
      <Form id="assessment-group-form">
        {!!error && (
          <ErrorModal
            isOpen={showErrorModal}
            title={
              error.message === "should at least have one correct field."
                ? "Select a correct response"
                : "An error has occurred"
            }
            description={
              error.message === "should at least have one correct field."
                ? `${QUESTION_TYPE_NAME_MAPPING[type]} questions require at least 1 correct response, please select one.`
                : error.message
            }
            onClose={() => setShowErrorModel(false)}
          />
        )}
        <FormRequiredText />
        {(mode === "edit" || mode === "clone") && (
          <GroupSelect
            groupId={groupId}
            assessmentId={assessmentId}
            onChange={(selectedGroup: string) =>
              setSelectedGroup(selectedGroup)
            } // Use the group ID directly
          />
        )}
        <QuestionTypeSelect onChange={onChangeQuestionTYpe} value={type} />
        {type !== "image" && (
          <>
            <Input
              data-test="question"
              type="textbox"
              label={type === "written_text" ? "Heading *" : "Question *"}
              value={question}
              onChange={setField("question")}
              onFieldInvalid={setFieldError("question")}
              error={fieldErrors.question}
            />
            {type !== "written_text" && (
              <Toggle
                reverse
                label="Required"
                checked={mandatory}
                onUpdate={setFieldSimple("mandatory")}
              />
            )}
          </>
        )}
        {type === "multiple_choices" && (
          <MultipleChoiceFormDetails
            question={formData}
            setField={setField}
            setFieldSimple={setFieldSimple}
            setFieldRaw={setFieldRaw}
            fieldErrors={fieldErrors}
          />
        )}
        {type === "checkboxes" && (
          <CheckboxesFormDetails
            question={formData}
            setField={setField}
            setFieldSimple={setFieldSimple}
            setFieldRaw={setFieldRaw}
            fieldErrors={fieldErrors}
          />
        )}
        {type === "written_response" && (
          <WrittenResponseFormDetails
            question={formData}
            setFieldSimple={setFieldSimple}
            setNumberField={setNumberField}
            setFieldRaw={setFieldRaw}
            fieldErrors={fieldErrors}
          />
        )}
        {type === "short_written_response" && (
          <ShortWrittenResponseDetails
            question={formData}
            setFieldSimple={setFieldSimple}
            setNumberField={setNumberField}
            setFieldRaw={setFieldRaw}
            fieldErrors={fieldErrors}
          />
        )}
        {type === "image" && (
          <ImageFormDetails
            question={formData}
            setField={setField}
            setFieldRaw={setFieldRaw}
            setFieldSimple={setFieldSimple}
            fieldErrors={fieldErrors}
          />
        )}
        {type === "rating" && (
          <RatingFormDetails
            question={formData}
            setFieldRaw={setFieldRaw}
            fieldErrors={fieldErrors}
          />
        )}
        {type === "written_text" && (
          <WrittenTextDetails
            question={formData}
            setFieldRaw={setFieldRaw}
            fieldErrors={fieldErrors}
            setFieldError={setFieldError}
          />
        )}
      </Form>
      <InlineGroup block spread className="mt-4">
        <CancelButton
          confirmModalProps={{
            confirmButtonText: "Save",
            inProgress: inProgress,
            showModal: formUpdated,
            onConfirm: onSubmit,
            onDiscard: onCancel,
          }}
          onClick={onCancel}
        />
        <InlineGroup spaceBetweenElements={2}>
          {!!onNext && (
            <Button
              data-test="save"
              onClick={onSubmitAndNext}
              form="assessment-group-form"
              wide
              inProgress={inProgress}
            >
              Save And Next
            </Button>
          )}
          <Button
            data-test="save"
            onClick={onSubmit}
            form="assessment-group-form"
            wide
            inProgress={inProgress}
          >
            Save
          </Button>
        </InlineGroup>
      </InlineGroup>
    </VerticalGroup>
  );
};
