import React, { useCallback, useEffect, useState } from "react";
import useForm, { FormError } from "../../../../../hooks/useForm";
import { useSimulationContext } from "../../context";
import SummaryForm from "./SummaryForm";
import API from "../../../../../services/api";
import ErrorModal from "../../../../organisms/standard-modal/ErrorModal";
import Text from "../../../../atoms/text/Text";
import { pluralise } from "../../../../../lib/pluralise";

const Summary = () => {
  const { config, updateConfig, assignOnSubmitFunction, updateFormStatus } =
    useSimulationContext();
  const [validationError, setValidationError] = useState<{
    title: string;
    message: string;
  } | null>(null);
  const [numberOfMetrics, setNumberOfMetrics] = useState(
    config?.summaryMetrics?.length || 6,
  );

  const [
    {
      formData: { summaryMetrics },
      inProgress,
      formUpdated,
    },
    { handleSubmit, setFieldRaw },
  ] = useForm({
    summaryMetrics: config?.summaryMetrics ?? [],
  });

  const onSubmit = useCallback(async () => {
    if (config) {
      const payload: API.SimulationUpdateRequest = {
        summaryMetrics,
      };
      const response = await API.editSimulation(config.id, payload);
      updateConfig(response);
      return response;
    }
  }, [config, updateConfig, summaryMetrics]);

  const onMetricFieldUpdate = useCallback(
    (metricConfigId: string) => (field: string) => async (value: string) => {
      if (config) {
        const payload: API.SimulationUpdateRequest = {
          summaryMetricConfig: (config.summaryMetricConfig ?? []).map(
            (metricConfig) => {
              if (metricConfig.id === metricConfigId) {
                return {
                  ...metricConfig,
                  [field]: value,
                };
              }
              return metricConfig;
            },
          ),
        };
        const response = await API.editSimulation(config.id, payload);
        updateConfig(response);
        return response;
      }
    },
    [config, updateConfig],
  );

  const validate = useCallback(() => {
    const errors: FormError[] = [];
    const totalPercentage = (
      (summaryMetrics as API.SimulationSummaryItem[]) ?? []
    ).reduce((total, item) => total + item.percentage, 0);
    if (totalPercentage !== 100) {
      const percentageDifference = 100 - totalPercentage;
      const percentageDifferenceMessage =
        percentageDifference < 0
          ? `${Math.abs(percentageDifference)}% over allocation`
          : `There is ${percentageDifference}% left to allocate`;
      const errorMessage = `Please allocate 100% across the selected summary screen metrics. ${percentageDifferenceMessage}`;
      errors.push({
        field: "summaryMetrics",
        message: errorMessage,
      });
      setValidationError({
        title: "Winning Team Weighting Not Fully Allocated",
        message: errorMessage,
      });
    }
    if (summaryMetrics.length !== numberOfMetrics) {
      const metricsRemaining = numberOfMetrics - summaryMetrics.length;
      const errorMessage = `${metricsRemaining} ${pluralise("metric", metricsRemaining)} still to be selected. Please select all ${numberOfMetrics} metrics`;
      errors.push({
        field: "summaryMetrics",
        message: errorMessage,
      });
      setValidationError({ title: "Missing Metrics", message: errorMessage });
    }

    return errors;
  }, [summaryMetrics, numberOfMetrics]);

  const onSummaryChange = useCallback(
    (
      metrics: API.SimulationUpdateRequest["summaryMetrics"],
      percentage: number,
    ) => {
      setFieldRaw("summaryMetrics", metrics);
      setFieldRaw("totalPercentage", percentage);
    },
    [setFieldRaw],
  );

  useEffect(() => {
    assignOnSubmitFunction(handleSubmit(onSubmit, validate));
  }, [assignOnSubmitFunction, handleSubmit, onSubmit, validate]);

  useEffect(() => {
    updateFormStatus({
      inProgress: inProgress,
      formUpdated: formUpdated,
    });
  }, [updateFormStatus, inProgress, formUpdated]);

  return (
    <div className="step-container">
      <SummaryForm
        defaultSelected={config?.summaryMetrics}
        onSummaryChange={onSummaryChange}
        numberOfMetrics={numberOfMetrics}
        onNumberOfMetricsChange={setNumberOfMetrics}
        onMetricFieldUpdate={onMetricFieldUpdate}
        summaryMetricConfig={config?.summaryMetricConfig}
      />
      {validationError && (
        <ErrorModal
          isOpen={validationError != null}
          onClose={() => {
            setValidationError(null);
          }}
          title={validationError?.title}
          description={<Text>{validationError?.message}</Text>}
        ></ErrorModal>
      )}
    </div>
  );
};

export { default as SummaryDisplay } from "./Display";
export default Summary;
