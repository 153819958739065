import React from "react";
import Clickable from "../clickable/Clickable";
import Text, { Props as TextProps } from "./Text";

interface Props extends TextProps {
  "data-test"?: string;
  onClick: () => void;
}

const ClickableText: React.FC<Props> = ({ children, onClick, ...rest }) => {
  return (
    <Clickable onClick={onClick}>
      <Text size="sm" colour="secondaryBlue" {...rest}>
        {children}
      </Text>
    </Clickable>
  );
};

export default ClickableText;
