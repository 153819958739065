import React, { useCallback, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import Page from "../../../atoms/page/Page";
import { QuestionForm } from "./QuestionForm";
import API from "../../../../services/api";
import { useGroup } from "../../../../hooks/useGroup";

const AssessmentEditQuestion: React.FC = () => {
  const { assessmentId, questionId, groupId } = useParams<{
    assessmentId: string;
    groupId: string;
    questionId: string;
  }>();

  const { inProgress, data: groupData } = useGroup(assessmentId, groupId);

  const history = useHistory();

  const onSave = useCallback(
    async (
      groupId: string,
      payload: API.AssessmentQuestionRequest,
    ): Promise<API.AssessmentQuestionResponse> => {
      const { fields, ...rest } = payload;

      const newFields = fields
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map(({ id, ...restField }) => restField)
        .map((field) => {
          if (field.type === "written_response") {
            return {
              ...field,
              config: {
                ...field.config,
                hasCharacterLimit: Boolean(field.config.hasCharacterLimit),
              },
            };
          }
          return field;
        });

      const newPayload = {
        ...rest,
        fields: newFields,
      };

      return API.updateAssessmentQuestion(
        assessmentId,
        groupId,
        questionId,
        newPayload,
      );
    },
    [assessmentId, questionId],
  );

  const onComplete = useCallback(
    (newGroupId: string) => {
      history.push(`/assessments/${assessmentId}/view?groupId=${newGroupId}`);
    },
    [assessmentId, history],
  );

  const onCancel = useCallback(() => {
    history.push(`/assessments/${assessmentId}/view?groupId=${groupId}`);
  }, [assessmentId, groupId, history]);

  const questionData = useMemo(() => {
    return groupData?.questions?.find((question) => question.id === questionId);
  }, [groupData?.questions, questionId]);

  const nextQuestion = useMemo(() => {
    if (!groupData || !questionData || !groupData.questions) {
      return null;
    }
    const currentIndex = groupData.questions?.findIndex(
      (question) => question.id === questionId,
    );
    if (currentIndex >= 0 && currentIndex < groupData.questions.length - 1) {
      return groupData.questions[currentIndex + 1];
    }
  }, [groupData, questionData, questionId]);

  const onNext = useCallback(() => {
    if (!nextQuestion) {
      return null;
    }
    if (nextQuestion) {
      history.push(
        `/assessments/${assessmentId}/groups/${groupId}/questions/${nextQuestion.id}/edit`,
      );
    }
  }, [assessmentId, groupId, history, nextQuestion]);

  if (inProgress || !questionData) {
    return null;
  }

  return (
    <Page>
      <QuestionForm
        key={questionId}
        mode="edit"
        assessmentId={assessmentId}
        onSave={onSave}
        onCancel={onCancel}
        onComplete={onComplete}
        onNext={nextQuestion ? onNext : undefined}
        data={questionData}
        groupId={groupId}
      />
    </Page>
  );
};

export default AssessmentEditQuestion;
