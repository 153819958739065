import React from "react";
import Icon, { IconType, mapTypeToIcon } from "../../atoms/icon/Icon";
import Text from "../../atoms/text/Text";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import Grid from "../../atoms/grid/Grid";

const ListIcons = () => {
  const icons = Object.keys(mapTypeToIcon);
  return (
    <div>
      <h1>List of Icons</h1>
      <Grid columns={4}>
        {icons.map((icon: string) => (
          <VerticalGroup key={icon} center>
            <Icon type={icon as IconType} />
            <Text>{icon}</Text>
          </VerticalGroup>
        ))}
      </Grid>
    </div>
  );
};

export default ListIcons;
