import React from "react";
import { summaryMetricItems } from "./data";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import Text from "../../../../atoms/text/Text";
import AvoidPageBreak from "../../../../atoms/printable/components/AvoidPageBreak";

interface PrintSummaryMetricsProps {
  data: API.SimulationResponse["summaryMetrics"];
  config: API.SimulationResponse["summaryMetricConfig"];
}

const PrintSummaryMetrics: React.FC<PrintSummaryMetricsProps> = ({
  data,
  config,
}) => {
  return (
    <AvoidPageBreak>
      <VerticalGroup spaceBetweenElements={4}>
        <h4>Summary Metrics</h4>
        <VerticalGroup spaceBetweenElements={4}>
          {data &&
            data.map((item) => {
              const matchedConfig = config?.find((i) => i.type === item.type);
              const matchedItem = summaryMetricItems.find(
                (i) => i.type === item.type,
              );

              if (matchedConfig && matchedItem) {
                return (
                  <Text key={item.type}>
                    {matchedConfig.name}: {item.percentage}%
                  </Text>
                );
              }
              return null;
            })}
        </VerticalGroup>
      </VerticalGroup>
    </AvoidPageBreak>
  );
};

export default PrintSummaryMetrics;
