type ErrorType = "unauthenticated" | "invalid_payload" | "unknown";

interface ValidationError {
  path: string;
  value?: string;
  message: string;
}
interface APIErrorProps {
  status: number;
  message: string;
  errors?: ValidationError[] | string[];
  type?: ErrorType;
}

const deriveTypeFromMessage = (message: string): ErrorType => {
  switch (message) {
    case "Invalid payload":
      return "invalid_payload";
    default:
      return "unknown";
  }
};

export default class APIError extends Error {
  status: number;
  message: string;
  errors?: ValidationError[] | string[];
  type: ErrorType;

  constructor({ status, message, type, errors }: APIErrorProps) {
    super(message);
    this.status = status;
    this.message = message;
    this.errors = errors || [];
    this.type = type || deriveTypeFromMessage(message);
  }
}
