export const sortEvents = (
  data: API.EventResponseSummary[] | null,
  orderBy?: "asc" | "desc",
) => {
  if (!data) {
    return null;
  }
  if (orderBy === "desc") {
    return data;
  }
  return data.sort((a, b) => {
    if (a.date < b.date) {
      return -1;
    }
    if (a.date > b.date) {
      return 1;
    }
    return 0;
  });
};
