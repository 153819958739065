import React, { useContext, useCallback, useEffect } from "react";

import { SimulationContext } from "../../context";
import useForm, { FormError } from "../../../../../hooks/useForm";
// import InlineGroup from '../../../../atoms/inlinegroup/InlineGroup'
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import HelpManualDropdown from "../../../../organisms/help-manual-dropdown/HelpMenuDropdown";
import api from "../../../../../services/api";
import HelpManualPreview from "./HelpManualPreview";

const Resources: React.FC = () => {
  const { config, updateConfig, assignOnSubmitFunction, updateFormStatus } =
    useContext(SimulationContext);
  const [
    {
      formData: { helpManualId },
      fieldErrors,
      inProgress,
      formUpdated,
    },
    { handleSubmit, setDropdownField },
  ] = useForm(
    config
      ? {
          helpManualId: config.resources?.helpManualId,
        }
      : {},
  );

  const onSubmit = useCallback(async () => {
    if (config) {
      const payload: API.SimulationUpdateRequest = {
        resources: { helpManualId },
      };
      const response = await api.editSimulation(config.id, payload);
      updateConfig(response);
      return response;
    }
  }, [config, helpManualId, updateConfig]);

  const validate = useCallback(() => {
    const errors: FormError[] = [];
    if (!helpManualId) {
      errors.push({
        field: "helpManualId",
        message: "Please select a help manual",
      });
    }

    return errors;
  }, [helpManualId]);

  useEffect(() => {
    assignOnSubmitFunction(handleSubmit(onSubmit, validate));
  }, [assignOnSubmitFunction, handleSubmit, onSubmit, validate]);

  useEffect(() => {
    updateFormStatus({
      inProgress: inProgress,
      formUpdated: formUpdated,
    });
  }, [updateFormStatus, inProgress, formUpdated]);

  return (
    <VerticalGroup
      wide
      full
      spaceBetweenElements={6}
      className="special-projects-container"
    >
      <h3>Resources</h3>
      <HelpManualDropdown
        label="Select Help Manual *"
        helpManualId={helpManualId}
        onChange={setDropdownField("helpManualId")}
        error={fieldErrors.clientIds}
      />
      {helpManualId ? <HelpManualPreview helpManualId={helpManualId} /> : null}
    </VerticalGroup>
  );
};

export { default as HelpManualDisplay } from "./Display";
export default Resources;
