import React from "react";

import AdminAPI from "../../../../../../services/api";
import { PreviewSettings } from "../types";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import IframePreview from "../../../../../organisms/iframe-preview/IframePreview";

const HOST = process.env.REACT_APP_GAME_URL || "";
const PATHNAME = "/news-preview";

interface NewsPreviewWrappedProps {
  scenarioId: string;
  roundId: number;
  previewSettings: PreviewSettings;
  maxContainerHeight: number;
  minIframeHeight?: number;
  minIframeWidth?: number;
  isFixedHeight?: boolean;
}

export const buildUrl = ({
  scenarioId,
  roundId,
  previewSettings,
}: NewsPreviewWrappedProps) => {
  const url = new URL(HOST);

  url.pathname = PATHNAME;

  url.searchParams.set("scenarioId", scenarioId);
  url.searchParams.set("roundId", roundId.toString());
  url.searchParams.set("country", previewSettings.country);
  url.searchParams.set("referenceRate", previewSettings.referenceRate);
  url.searchParams.set("centralBank", previewSettings.centralBank);
  url.searchParams.set("regulator", previewSettings.regulator);
  url.searchParams.set("currency", previewSettings.currency);
  url.searchParams.set("product", previewSettings.product);
  url.searchParams.set("token", AdminAPI.token);

  return url.href;
};

export const NewsPreviewWrapped: React.FC<NewsPreviewWrappedProps> = (
  props,
) => {
  const targetSrc = buildUrl({ ...props });

  return (
    <VerticalGroup center verticalCenter spaceBetweenElements={2} wide flexAuto>
      <IframePreview
        key={targetSrc}
        enablePointerEvents
        {...{ ...props, targetSrc }}
      />
    </VerticalGroup>
  );
};

interface NewsPreviewProps {
  scenarioId: string;
  roundId: number;
  previewSettings: PreviewSettings;
  maxContainerHeight: number;
  minIframeHeight?: number;
}

const NewsPreviewIframe: React.FC<NewsPreviewProps> = ({
  maxContainerHeight,
  scenarioId,
  roundId,
  previewSettings,
  minIframeHeight,
}) => (
  <NewsPreviewWrapped
    scenarioId={scenarioId}
    roundId={roundId}
    previewSettings={previewSettings}
    maxContainerHeight={maxContainerHeight}
    minIframeHeight={minIframeHeight}
  />
);

export default NewsPreviewIframe;
