import classNames from "classnames";
import dateFormat from "dateformat";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useMarkAnswer } from "../../../../hooks/useAssessments";
import useForm from "../../../../hooks/useForm";
import { useNoPropagation } from "../../../../hooks/useNoPropagation";
import Button from "../../../atoms/button/Button";
import Input from "../../../atoms/form/input/Input";
import NumberInput from "../../../atoms/form/input/NumberInput";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Text from "../../../atoms/text/Text";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import SimpleModal from "../../../organisms/standard-modal/SimpleModal";
import { QuestionPreview } from "../../assessments/Question/QuestionPreview/QuestionPreview";
import "./MarkAssessmentModal.scss";

interface Props {
  onClose: () => void;
  participant: API.ParticipantResponse;
  currentRound: number;
  answers: API.AssessmentAnswerResponse["answers"];
  questions: API.AssessmentQuestionResponse[];
  totalParticipantCount: number;
  currentParticipantIndex: number;
  defaultQuestionIndex: number;
  onNextParticipant: ({
    recordedAnswerIndex,
  }: {
    recordedAnswerIndex: number;
  }) => void;
  onPreviousParticipant: ({
    recordedAnswerIndex,
  }: {
    recordedAnswerIndex: number;
  }) => void;
  onAnswerUpdated: () => void;
}

const ParticipantField = ({
  name,
  value,
}: {
  name: string;
  value: string | number;
}) => {
  return (
    <InlineGroup>
      <Text className="participant-field-name">{name}</Text>
      <Text bold>{value}</Text>
    </InlineGroup>
  );
};

const autoMark = (
  question: API.AssessmentQuestionResponse,
  answer: API.AssessmentAnswerResponse["answers"][number]["answer"],
) => {
  if (question.type === "multiple_choices") {
    const correctFields = (
      question.fields as API.CheckboxesFieldResponse[]
    ).filter((field) => field.config.markCorrect);

    if (
      answer.length <= correctFields.length &&
      answer.every((a) => correctFields.some((field) => field.id === a.fieldId))
    ) {
      const theMark = answer
        .map((a) => correctFields.find((field) => field.id === a.fieldId))
        .reduce((result, field) => {
          if (field) {
            result += field.config.mark ?? 0;
          }
          return result;
        }, 0);
      return theMark;
    }

    return 0;
  } else if (question.type === "checkboxes") {
    const correctField = (
      question.fields as API.CheckboxesFieldResponse[]
    ).find((field) => field.config.markCorrect);

    if (answer.some((a) => a.fieldId === correctField?.id)) {
      return correctField?.config.mark;
    }
  }

  return 0;
};

const formatCompleted = (dateStr: string) => {
  if (!dateStr) {
    return "?";
  }

  const date = new Date(dateStr);
  return dateFormat(date, "h:MMtt dddd dd mmmm yyyy");
};

export const MarkAssessmentModal: React.FC<Props> = ({
  onClose,
  participant,
  currentRound,
  answers,
  questions,
  totalParticipantCount,
  currentParticipantIndex,
  defaultQuestionIndex,
  onNextParticipant,
  onPreviousParticipant,
  onAnswerUpdated,
}) => {
  const [currentQuestionIndex, setQuestionIndex] = useState(0);
  const [shouldShow, setShow] = useState(true);

  const close = useCallback(() => {
    setShow(false);
    onClose();
  }, [onClose]);
  const currentQuestion = useMemo(
    () => questions[currentQuestionIndex],
    [currentQuestionIndex, questions],
  );
  const answerForQuestion = useMemo(
    () => answers.find((a) => a.questionId === currentQuestion.id),
    [answers, currentQuestion],
  );
  const nextParticipant = useNoPropagation(
    useCallback(() => {
      onNextParticipant({ recordedAnswerIndex: currentQuestionIndex });
    }, [currentQuestionIndex, onNextParticipant]),
  );
  const prevParticipant = useNoPropagation(
    useCallback(() => {
      onPreviousParticipant({ recordedAnswerIndex: currentQuestionIndex });
    }, [currentQuestionIndex, onPreviousParticipant]),
  );

  const [
    {
      formData: { mark, comment },
    },
    { setField, setFieldRaw, setNumberField },
  ] = useForm({
    mark: undefined,
    comment: "",
  });
  const { apiState, markAnswer } = useMarkAnswer();

  useEffect(() => {
    setQuestionIndex(defaultQuestionIndex);
  }, [currentParticipantIndex, defaultQuestionIndex]);

  useEffect(() => {
    setFieldRaw(
      "mark",
      answerForQuestion?.mark ??
        autoMark(currentQuestion, answerForQuestion?.answer || []),
    );
    setFieldRaw("comment", answerForQuestion?.markComment ?? "");
  }, [
    currentQuestionIndex,
    setFieldRaw,
    currentParticipantIndex,
    answerForQuestion,
    currentQuestion,
  ]);

  const prevQuestion = useCallback(async () => {
    if (answerForQuestion) {
      await markAnswer(answerForQuestion.answerId, mark, comment);
    }

    setQuestionIndex((index) => {
      let next = index;
      if (--next >= 0) {
        return next;
      }
      return index;
    });
  }, [answerForQuestion, comment, mark, markAnswer]);

  const hasPrevious = useMemo(() => {
    let next = currentQuestionIndex;
    return --next >= 0;
  }, [currentQuestionIndex]);

  const nextQuestion = useCallback(async () => {
    if (answerForQuestion) {
      await markAnswer(answerForQuestion.answerId, mark, comment);
    }

    setQuestionIndex((index) => {
      let next = index;

      if (++next < questions.length) {
        return next;
      }

      if (next === questions.length) {
        close();
      }

      return index;
    });

    onAnswerUpdated();
  }, [
    answerForQuestion,
    close,
    comment,
    mark,
    markAnswer,
    onAnswerUpdated,
    questions,
  ]);

  const hasNext = useMemo(() => {
    let next = currentQuestionIndex;
    return ++next < questions.length;
  }, [currentQuestionIndex, questions]);

  const maxPoints = currentQuestion.marks || 0;

  return (
    <>
      <SimpleModal
        isOpen={shouldShow}
        onClose={onClose}
        size="large"
        showCloseButton
        className="mark-assessment-modal"
      >
        <VerticalGroup spaceBetweenElements={4} wide>
          <Text className="mark-assessment-modal-section" size="2xl">
            Mark Response
          </Text>

          {/* participant stataus */}
          <VerticalGroup
            spaceBetweenElements={2}
            wide
            className="mark-assessment-modal-section"
          >
            <InlineGroup block spread>
              <Button link onClick={prevParticipant}>
                <Text bold size="md">
                  {"<< Prev Participant"}
                </Text>
              </Button>
              <InlineGroup spaceBetweenElements={2}>
                <Text size="md">Participant</Text>
                <Text bold size="md">
                  {currentParticipantIndex}/{totalParticipantCount}
                </Text>
              </InlineGroup>
              <Button link onClick={nextParticipant}>
                <Text bold size="md">
                  {"Next Participant >>"}
                </Text>
              </Button>
            </InlineGroup>
          </VerticalGroup>

          <InlineGroup
            block
            spread
            spaceBetweenElements={4}
            className="mark-assessment-modal-section"
          >
            {new Array(totalParticipantCount).fill(0).map((_, idx) => {
              return (
                <div
                  key={idx}
                  className={classNames(
                    {
                      selected: idx < currentParticipantIndex,
                    },
                    "participant-progress",
                  )}
                ></div>
              );
            })}
          </InlineGroup>

          {/* participant info */}
          <VerticalGroup
            spaceBetweenElements={2}
            wide
            className="participant-info-panel mark-assessment-modal-section"
          >
            <InlineGroup block stretch evenWidthChildren>
              <VerticalGroup spaceBetweenElements={2}>
                <ParticipantField
                  name="First Name:"
                  value={participant.firstName}
                />
                <ParticipantField
                  name="Last Name:"
                  value={participant.lastName}
                />
                <ParticipantField
                  name="Email adddress:"
                  value={participant.email}
                />
              </VerticalGroup>
              <VerticalGroup spaceBetweenElements={2}>
                <ParticipantField name="Round #:" value={currentRound + 1} />
                <ParticipantField
                  name="Question #:"
                  value={currentQuestionIndex + 1}
                />
                <ParticipantField
                  name="Completed #:"
                  value={formatCompleted(answerForQuestion?.createdAt || "")}
                />
                <ParticipantField name="Max Points #:" value={maxPoints} />
              </VerticalGroup>
            </InlineGroup>
          </VerticalGroup>

          {!(
            currentQuestion.type === "image" ||
            currentQuestion.type === "written_text"
          ) && (
            <VerticalGroup
              spaceBetweenElements={2}
              className="mark-assessment-modal-section"
            >
              <Text bold>Question</Text>
              <Text isHtml>{currentQuestion.question}</Text>
            </VerticalGroup>
          )}

          <VerticalGroup
            spaceBetweenElements={2}
            className="mark-assessment-modal-section"
          >
            <Text bold>
              {!(
                currentQuestion.type === "image" ||
                currentQuestion.type === "written_text"
              )
                ? "Student Response"
                : "Question"}
            </Text>
            <QuestionPreview
              group={answerForQuestion?.groupId}
              question={currentQuestion}
              index={currentQuestionIndex}
              answer={answerForQuestion?.answer}
              isAnswerMode
              disableQuestionIndex
            />
          </VerticalGroup>

          {!(
            currentQuestion.type === "image" ||
            currentQuestion.type === "written_text"
          ) && (
            <VerticalGroup
              spaceBetweenElements={2}
              wide
              className="mark-assessment-modal-section"
            >
              <Text bold>Marker Comment</Text>
              <Input
                type="textbox"
                placeholder="Marker Comment"
                block
                value={comment}
                onChange={setField("comment")}
                key={comment} // This is to tell the component to remount when prop.value changes
              />
            </VerticalGroup>
          )}

          <InlineGroup
            spread
            block
            verticalBottom
            className="mark-assessment-modal-section"
          >
            {!(
              currentQuestion.type === "image" ||
              currentQuestion.type === "written_text"
            ) ? (
              <VerticalGroup spaceBetweenElements={2}>
                <Text bold>Points Allocated</Text>
                <NumberInput
                  type="text"
                  small
                  defaultValue={autoMark(
                    currentQuestion,
                    answerForQuestion?.answer || [],
                  )}
                  value={mark}
                  onChange={setNumberField("mark")}
                />
              </VerticalGroup>
            ) : (
              <div />
            )}

            <InlineGroup spaceBetweenElements={2}>
              <Button onClick={close}>Cancel</Button>
              <Button onClick={prevQuestion} disabled={!hasPrevious}>
                Save & Previous
              </Button>
              {!hasNext && (
                <Button onClick={nextQuestion} disabled={apiState.inProgress}>
                  Save
                </Button>
              )}
              {hasNext && <Button onClick={nextQuestion}>Save & Next</Button>}
            </InlineGroup>
          </InlineGroup>
        </VerticalGroup>
      </SimpleModal>
    </>
  );
};
