import React, { useCallback } from "react";

import { SummaryMetricItem } from "./data";
import PercentageSelect from "./PercentageSelect";
import { SelectedView } from "../../../../organisms/draganddrop";
import Text from "../../../../atoms/text/Text";

interface Props {
  item: SummaryMetricItem;
  onPercentageChange?: (item: SummaryMetricItem, value: number) => void;
  readOnly?: boolean;
  onUpdateField?: (field: string) => (value: string) => void;
}

const SummarySelectedView: React.FC<Props> = ({
  onPercentageChange,
  item,
  readOnly,
  onUpdateField,
}) => {
  const onChange = useCallback(
    (item: SummaryMetricItem) => {
      return (value: number) => {
        onPercentageChange && onPercentageChange(item, value);
      };
    },
    [onPercentageChange],
  );

  return (
    <SelectedView
      label={item.label}
      icon={item.icon}
      onUpdateField={onUpdateField}
      footerView={
        readOnly ? (
          <Text bold>{item.percentage}%</Text>
        ) : (
          <PercentageSelect
            onChange={onChange(item)}
            value={item.percentage}
            id={item.type}
          />
        )
      }
    />
  );
};

export default SummarySelectedView;
