import React, { useMemo, useCallback } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Icon from "../../../atoms/icon/Icon";
import Image from "../../../atoms/image/Image";
import ArcherMan from "../../../../assets/results/ArcherMan.png";
import ResultsTable from "../table/ResultsTable";
import { TeamsWithColours } from "../RoundResultsPage";
import { round, thousandSeparator } from "../../model/utils/formatters";

import "../RoundResultsTablePage.scss";
import { useResultsContext } from "../context/context";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import ResultsText from "../results-text/ResultsText";
import EditableReportingHeading from "../components/EditableReportingHeading";
import ReportingSubHeading from "../components/ReportingSubHeading";

interface Props {
  teams: TeamsWithColours[];
  data: ModelAPI.Reporting.TeamAndAmount[];
}

const getSortedBailoutsFromHighestToLowest = (
  data: ModelAPI.Reporting.TeamAndAmount[],
) => {
  return data.sort((a, b) => b.amount - a.amount);
};

export const getMaxClicks = (data: ModelAPI.Reporting.TeamAndAmount[]) => {
  return getSortedBailoutsFromHighestToLowest(data).length;
};

const RoundResultsBankBailOuts: React.FC<Props> = ({ data, teams }) => {
  const context = useResultsContext();

  const { sortedBailoutsFromHighestToLowest } = useMemo(() => {
    const sortedBailoutsFromHighestToLowest =
      getSortedBailoutsFromHighestToLowest(data);
    return { sortedBailoutsFromHighestToLowest };
  }, [data]);

  const maxClicks = getMaxClicks(data);

  const showRow = useMemo(() => {
    return context.noAnimations ? 0 : maxClicks - context.clicks;
  }, [context.clicks, context.noAnimations, maxClicks]);

  const onNext = useCallback(() => {
    if (!context.hidePagination) {
      context.goNextPage();
      return;
    }
    if (showRow > 0) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context, showRow]);

  return (
    <RoundResultsContainer onNext={onNext}>
      {sortedBailoutsFromHighestToLowest.length === 0 ? (
        <VerticalGroup full center wide spaceBetweenElements={2}>
          <EditableReportingHeading
            prefix="No "
            defaultHeading="Bank Bail Outs"
            suffix="!"
          />
          <InlineGroup fullHeight center verticalCenter>
            <Image
              src={ArcherMan}
              alt="Archor Man"
              dynamicSize={{ min: "200px", preferred: "25vw", max: "900px" }}
            ></Image>
          </InlineGroup>
        </VerticalGroup>
      ) : (
        <VerticalGroup full center wide spaceBetweenElements={2}>
          <EditableReportingHeading defaultHeading="Bank Bail Outs" />
          <ReportingSubHeading subHeading="Rescued Banks" />
          <InlineGroup spread fullHeight verticalCenter>
            <VerticalGroup verticalCenter full style={{ width: "40%" }} center>
              <Icon
                dynamicSize={{ min: "200px", preferred: "25vw", max: "900px" }}
                type="bank"
              ></Icon>
            </VerticalGroup>
            <VerticalGroup style={{ width: "100%", height: "100%" }}>
              <ResultsTable id="999">
                <thead>
                  <tr>
                    <th>BANK</th>
                    <th>AMOUNT ($bn)</th>
                  </tr>
                </thead>
                <tbody onClick={() => context.addClick()} className="clickable">
                  {sortedBailoutsFromHighestToLowest.map((bail, i) => {
                    const teamForTable = teams.find(
                      (t) => t.teamId === bail.teamId,
                    );

                    return (
                      <tr key={i}>
                        <td
                          style={{ color: `${teamForTable?.colour}` }}
                          className={
                            showRow <= i ? "show-bids" : "no-show-options"
                          }
                        >
                          <ResultsText size="xl" center>
                            {teamForTable?.teamName}
                          </ResultsText>
                        </td>
                        <td>
                          <ResultsText size="xl" center>
                            {thousandSeparator(round(bail.amount / 1000, 0))}
                          </ResultsText>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </ResultsTable>
              {/* Added so you know to click on table body to display data */}
              {!context.hidePagination && (
                <InlineGroup block center className="executive-decisions-card">
                  <ResultsText center colour="primaryDark" className="mt-2">
                    Click on table row to display data.
                  </ResultsText>
                </InlineGroup>
              )}
            </VerticalGroup>
            <VerticalGroup full verticalCenter style={{ width: "40%" }} center>
              {/* still need svg type for this icon */}
              <Icon
                type="emptyPockets"
                colour="yellow"
                dynamicSize={{ min: "150px", preferred: "25vw", max: "900px" }}
              ></Icon>
            </VerticalGroup>
          </InlineGroup>
        </VerticalGroup>
      )}
    </RoundResultsContainer>
  );
};

export default RoundResultsBankBailOuts;
