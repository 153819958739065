import React, { useCallback, useRef, useState } from "react";
import { startCase } from "../../../../../services/utils";
import Card from "../../../../atoms/card/Card";
import Clickable from "../../../../atoms/clickable/Clickable";
import Label from "../../../../atoms/form/label/Label";
import Icon from "../../../../atoms/icon/Icon";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import Ribbon from "../../../../atoms/ribbon/Ribbon";
import EditableText, {
  Handles as EditableTextHandles,
} from "../../../../atoms/text/EditableText";
import Text from "../../../../atoms/text/Text";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";

const EditableField = ({
  onChange,
  value,
  field,
  label,
  fieldType,
}: {
  onChange: (value: string) => void;
  value: string;
  field: string;
  label: string;
  fieldType: "text" | "textbox";
}) => {
  const ref = useRef<EditableTextHandles>(null);
  return (
    <InlineGroup verticalCenter>
      <InlineGroup>
        <Label>{label}</Label>
      </InlineGroup>
      <InlineGroup spaceBetweenElements={2}>
        <EditableText
          size="sm"
          ref={ref}
          type={fieldType}
          onChange={onChange}
          value={value}
        />
        <Clickable onClick={() => ref.current?.setIsEditing(true)}>
          <Icon
            noMargin
            type="edit"
            tip={{ content: `Edit ${startCase(field)}`, id: `edit-${field}` }}
          />
        </Clickable>
      </InlineGroup>
    </InlineGroup>
  );
};

function InfoItemsByType({
  type,
  items,
  onUpdateContent,
}: {
  type: API.HelpTypes;
  items: API.HelpConfigResponse[];
  onUpdateContent: (
    helpId: string,
    data: Partial<API.HelpConfigResponse>,
  ) => Promise<void>;
}) {
  const onChange = useCallback(
    (helpId: string, field: keyof API.HelpConfigResponse) =>
      (value: string) => {
        onUpdateContent(helpId, { [field]: value });
      },
    [onUpdateContent],
  );

  return (
    <VerticalGroup wide spaceBetweenElements={2} className="mt-2">
      {items
        .filter((i) => i.type === type)
        .map((item, i) => (
          <Card wide sharp key={i}>
            <Text size="sm" bold>
              {item.name}
            </Text>
            <VerticalGroup spaceBetweenElements={2} className="mt-2">
              <EditableField
                fieldType="text"
                onChange={onChange(item.id, "heading")}
                value={item.heading}
                field="heading"
                label="Heading"
              />
              <EditableField
                fieldType="textbox"
                onChange={onChange(item.id, "body")}
                value={item.body}
                field="body"
                label="Body"
              />
              <EditableField
                fieldType="text"
                onChange={onChange(item.id, "moreInfoLink")}
                value={item.moreInfoLink ?? "-"}
                field="moreInfoLink"
                label="More Info Link"
              />
            </VerticalGroup>
          </Card>
        ))}
    </VerticalGroup>
  );
}

interface InfoPopupsProps {
  onUpdateContent: (
    helpId: string,
    data: Partial<API.HelpConfigResponse>,
  ) => Promise<void>;
  helpItems: API.HelpConfigResponse[];
}

const InfoPopups: React.FC<InfoPopupsProps> = ({
  onUpdateContent,
  helpItems,
}) => {
  const [tab, setTab] = useState<API.HelpTypes>("summary");

  return (
    <VerticalGroup wide style={{ overflowY: "auto" }}>
      <InlineGroup spaceBetweenElements={2} className="h-16 mt-2" block>
        <Ribbon
          first
          active={tab === "summary"}
          title="Summary"
          onClick={() => setTab("summary")}
          className="h-16"
        />
        <Ribbon
          active={tab === "financial_summary"}
          title="Financial Summary"
          onClick={() => setTab("financial_summary")}
          className="h-16"
        />
        <Ribbon
          last
          active={tab === "treasury"}
          title="Treasury"
          onClick={() => setTab("treasury")}
          className="h-16"
        />
      </InlineGroup>
      {tab === "summary" && (
        <InfoItemsByType
          onUpdateContent={onUpdateContent}
          type={tab}
          items={helpItems}
        />
      )}
      {tab === "financial_summary" && (
        <InfoItemsByType
          onUpdateContent={onUpdateContent}
          type={tab}
          items={helpItems}
        />
      )}
      {tab === "treasury" && (
        <InfoItemsByType
          onUpdateContent={onUpdateContent}
          type={tab}
          items={helpItems}
        />
      )}
    </VerticalGroup>
  );
};

export default InfoPopups;
