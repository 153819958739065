import React from "react";
import classNames from "classnames";
import DOMPurify from "dompurify";
import "./Text.scss";
import { ThemeColours, ThemeFontSize } from "../../../types/theme";
import { ThemeSpacing } from "../../../types/theme";

export interface Props {
  children?: React.ReactNode;
  className?: string;
  colour?: ThemeColours;
  color?: string;
  size?: ThemeFontSize;
  center?: boolean;
  bold?: boolean;
  "data-test"?: string;
  mono?: boolean;
  medium?: boolean;
  vertical?: boolean;
  italic?: boolean;
  right?: boolean;
  allowOverflow?: boolean;
  respectNewLine?: boolean;
  isHtml?: boolean;
  style?: React.CSSProperties;
  lineHeight?: ThemeSpacing;
}

function Text({
  children,
  color,
  colour,
  size,
  bold,
  center,
  className,
  mono,
  medium,
  vertical,
  italic,
  right,
  allowOverflow,
  respectNewLine,
  isHtml = false,
  lineHeight,
  ...rest
}: Props) {
  const colourClass = colour ? `colour-${colour}` : null;
  const sizeClass = size ? `size-${size}` : null;
  const lineHeightClass = lineHeight ? `lineheight-${lineHeight}` : null;
  if (isHtml) {
    const sanitizedHtml = DOMPurify.sanitize(String(children));
    return (
      <div
        style={{ color }}
        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
        className={classNames(
          "text",
          lineHeightClass,
          {
            center,
            bold,
            mono,
            medium,
            vertical,
            italic,
            right,
            allowOverflow,
          },
          colourClass,
          sizeClass,
          className,
        )}
        {...rest}
      />
    );
  }
  return (
    <div
      style={{ color }}
      className={classNames(
        "text",
        lineHeightClass,
        {
          center,
          bold,
          mono,
          medium,
          vertical,
          italic,
          right,
          allowOverflow,
          respectNewLine,
        },
        colourClass,
        sizeClass,
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
}

export default Text;
