import React, { useCallback, useMemo } from "react";
import Card from "../../../atoms/card/Card";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import EditableReportingHeading from "../components/EditableReportingHeading";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Image from "../../../atoms/image/Image";
import ResultsText from "../results-text/ResultsText";
import "./IntroFacilitatorDetails.scss";
import { useResultsContext } from "../context/context";
import { ThemeColours } from "../../../../types/theme";

const BACKGROUND_COLORS: ThemeColours[] = ["blue", "yellow", "green"];

const calculateFacilitatorDistribution = (
  totalFacilitators: number,
): {
  maxClicks: number;
  slideCounts: number[];
} => {
  if (totalFacilitators <= 0) {
    return { maxClicks: 0, slideCounts: [0] };
  }

  if (totalFacilitators <= 3) {
    return { maxClicks: 0, slideCounts: [totalFacilitators] };
  }

  const slideCounts: number[] = [];
  let remaining = totalFacilitators;

  while (remaining > 4) {
    slideCounts.push(3);
    remaining -= 3;
  }

  switch (remaining) {
    case 4:
      slideCounts.push(2, 2);
      break;
    case 3:
      slideCounts.push(3);
      break;
    case 2:
      slideCounts.push(2);
      break;
    case 1:
      if (slideCounts.length > 0) {
        slideCounts.pop();
        slideCounts.push(2, 2);
      } else {
        slideCounts.push(1);
      }
      break;
  }
  return {
    maxClicks: slideCounts.length - 1,
    slideCounts,
  };
};

interface FacilitatorDetails {
  name: string;
  imageUrl: string | null;
  bio: string;
  dotPoints: string[];
}

interface FacilitatorCardProps {
  facilitator: FacilitatorDetails;
  absoluteIndex: number;
}

const FacilitatorCard: React.FC<FacilitatorCardProps> = ({
  facilitator,
  absoluteIndex,
}) => {
  const backgroundColor =
    BACKGROUND_COLORS[absoluteIndex % BACKGROUND_COLORS.length];

  return (
    <VerticalGroup center className="IntroFacilitatorDetails">
      <InlineGroup center>
        <InlineGroup
          bgColour={backgroundColor}
          className="facilitator-image-wrapper"
        >
          <InlineGroup
            center
            verticalCenter
            bgColour="white"
            className="facilitator-image-white-space"
          >
            <div className="facilitator-image-container">
              <Image
                cover
                src={facilitator.imageUrl || undefined}
                alt={`${facilitator.name}`}
                className="facilitator-image"
              />
            </div>
          </InlineGroup>
        </InlineGroup>
      </InlineGroup>

      <InlineGroup
        bgColour={backgroundColor}
        block
        center
        className="facilitator-name-container"
      >
        <ResultsText center size="lg" color="white" bold>
          {facilitator.name.toUpperCase()}
        </ResultsText>
      </InlineGroup>

      <InlineGroup className="facilitator-bio-wrapper">
        <InlineGroup center className="facilitator-bio-container">
          <ResultsText
            isHtml
            bold
            center
            italic
            className="facilitator-bio-text"
          >
            {facilitator.bio || "No bio provided"}
          </ResultsText>
        </InlineGroup>
      </InlineGroup>

      <hr className="facilitator-divider" />

      <VerticalGroup
        spaceBetweenElements={2}
        wide
        className="facilitator-dot-points"
      >
        {(facilitator.dotPoints?.length
          ? facilitator.dotPoints
          : ["No career highlights provided"]
        ).map((point: string, index: number) => (
          <ResultsText
            isHtml
            className="facilitator-results-text"
            key={index}
            left
          >
            {point}
          </ResultsText>
        ))}
      </VerticalGroup>
    </VerticalGroup>
  );
};

interface IntroFacilitatorDetailsProps {
  data: ModelAPI.Reporting.ReportingResults;
}

const IntroFacilitatorDetails: React.FC<IntroFacilitatorDetailsProps> = ({
  data,
}) => {
  const context = useResultsContext();
  const facilitatorDistribution = useMemo(() => {
    if (!data?.facilitators?.length) return { maxClicks: 0, slideCounts: [0] };
    return calculateFacilitatorDistribution(data.facilitators.length);
  }, [data?.facilitators]);

  const visibleFacilitators = useMemo(() => {
    if (!data?.facilitators?.length) return [];

    const { slideCounts } = facilitatorDistribution;
    const startIndex = slideCounts
      .slice(0, context.clicks)
      .reduce((sum, count) => sum + count, 0);

    const currentSlideCount = slideCounts[context.clicks];

    return data.facilitators.slice(startIndex, startIndex + currentSlideCount);
  }, [data?.facilitators, context.clicks, facilitatorDistribution]);

  const onNext = useCallback(() => {
    if (context.clicks < facilitatorDistribution.maxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context, facilitatorDistribution.maxClicks]);

  const calculateAbsoluteIndex = useCallback(
    (index: number) => {
      return (
        facilitatorDistribution.slideCounts
          .slice(0, context.clicks)
          .reduce((sum, count) => sum + count, 0) + index
      );
    },
    [context.clicks, facilitatorDistribution.slideCounts],
  );

  return (
    <RoundResultsContainer onNext={onNext}>
      <Card wide>
        <VerticalGroup full wide center verticalCenter>
          <EditableReportingHeading defaultHeading="Today's Coaches" />
          <InlineGroup
            spaceBetweenElements={20}
            center
            block
            fullHeight
            verticalCenter
          >
            {visibleFacilitators.map((facilitator, index) => (
              <FacilitatorCard
                key={`${facilitator.name}-${index}`}
                facilitator={facilitator}
                absoluteIndex={calculateAbsoluteIndex(index)}
              />
            ))}
          </InlineGroup>
        </VerticalGroup>
      </Card>
    </RoundResultsContainer>
  );
};

export default IntroFacilitatorDetails;
