import React from "react";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import "./ColourSquare.scss";

interface Props {
  colour?: string;
}

const colourSquareStyle = (colours: string[]) => ({
  backgroundColor: colours.length === 1 ? colours[0] : undefined,
  backgroundImage:
    colours.length === 2
      ? `linear-gradient(${colours[0]} 49%, ${colours[1]} 51%)`
      : undefined,
  width: colours.length === 2 ? "38px" : undefined,
});

const renderColourText = (colours: string[]) => {
  return colours.length === 2 ? `${colours[0]}  ${colours[1]}` : colours[0];
};

const ColourSquare: React.FC<Props> = ({ colour }) => {
  const colours = colour ? colour.split("+") : [];
  if (colours.length === 0) {
    return null;
  }

  return (
    <VerticalGroup verticalCenter spread style={{ width: "70px" }}>
      <InlineGroup
        spread
        style={{ display: "flex", alignItems: "center", width: "70px" }}
      >
        <span>{renderColourText(colours)}</span>
        <div className="color-square" style={colourSquareStyle(colours)}></div>
      </InlineGroup>
    </VerticalGroup>
  );
};

export default ColourSquare;
