export interface WsfCosts {
  coreCreditSpreads: {
    startingSpread: number[];
    spreadIncrement: number[];
    spreadFactor: number[];
    AAA: number[];
    AA: number[];
    A: number[];
    BBB: number[];
    BB: number[];
    B: number[];
  };
  termPremiums: {
    startingSpread: number[];
    spreadIncrement: number[];
    spreadFactor: number[];
    oneYr: number[];
    twoYr: number[];
    threeYr: number[];
    fourYr: number[];
    fiveYr: number[];
  };
  ratingImpactSpreads: {
    startingSpread: number[];
    spreadIncrement: number[];
    spreadFactor: number[];
    AAA: number[];
    AA: number[];
    A: number[];
    BBB: number[];
    BB: number[];
    B: number[];
  };
  referenceRate: number[];
}

export interface Params {
  coreCreditSpread: {
    startingSpread: number[];
    spreadIncrement: number[];
    spreadFactor: number[];
  };
  termPremiums: {
    startingSpread: number[];
    spreadIncrement: number[];
    spreadFactor: number[];
  };
  ratingImpactSpreads: {
    startingSpread: number[];
    spreadIncrement: number[];
    spreadFactor: number[];
  };
  referenceRate: number[];
}

export const calculateWsf = (params: Params): WsfCosts => {
  const coreCreditInput = params.coreCreditSpread;
  const coreCreditSpreadsAAA = coreCreditInput.startingSpread;
  const coreCreditSpreadsAA = coreCreditSpreadsAAA.map(
    (spread, index) =>
      spread +
      coreCreditInput.spreadIncrement[index] *
        Math.pow(1 + coreCreditInput.spreadFactor[index] / 100, 2),
  );
  const coreCreditSpreadsA = coreCreditSpreadsAA.map(
    (spread, index) =>
      spread +
      coreCreditInput.spreadIncrement[index] *
        Math.pow(1 + coreCreditInput.spreadFactor[index] / 100, 3),
  );
  const coreCreditSpreadsBBB = coreCreditSpreadsA.map(
    (spread, index) =>
      spread +
      coreCreditInput.spreadIncrement[index] *
        Math.pow(1 + coreCreditInput.spreadFactor[index] / 100, 4),
  );
  const coreCreditSpreadsBB = coreCreditSpreadsBBB.map(
    (spread, index) =>
      spread +
      coreCreditInput.spreadIncrement[index] *
        Math.pow(1 + coreCreditInput.spreadFactor[index] / 100, 5),
  );
  const coreCreditSpreadsB = coreCreditSpreadsBB.map(
    (spread, index) =>
      spread +
      coreCreditInput.spreadIncrement[index] *
        Math.pow(1 + coreCreditInput.spreadFactor[index] / 100, 6),
  );

  const termPremiumsInput = params.termPremiums;
  const termPremiumsSpreads1Yr = termPremiumsInput.startingSpread;
  const termPremiumsSpreads2Yr = termPremiumsSpreads1Yr.map(
    (spread, index) =>
      spread +
      termPremiumsInput.spreadIncrement[index] *
        Math.pow(1 + termPremiumsInput.spreadFactor[index] / 100, 2),
  );
  const termPremiumsSpreads3Yr = termPremiumsSpreads2Yr.map(
    (spread, index) =>
      spread +
      termPremiumsInput.spreadIncrement[index] *
        Math.pow(1 + termPremiumsInput.spreadFactor[index] / 100, 3),
  );
  const termPremiumsSpreads4Yr = termPremiumsSpreads3Yr.map(
    (spread, index) =>
      spread +
      termPremiumsInput.spreadIncrement[index] *
        Math.pow(1 + termPremiumsInput.spreadFactor[index] / 100, 4),
  );
  const termPremiumsSpreads5Yr = termPremiumsSpreads4Yr.map(
    (spread, index) =>
      spread +
      termPremiumsInput.spreadIncrement[index] *
        Math.pow(1 + termPremiumsInput.spreadFactor[index] / 100, 5),
  );

  const ratingImpactInput = params.ratingImpactSpreads;
  const ratingImpactOnTermPremiumsAAA = ratingImpactInput.startingSpread;
  const ratingImpactOnTermPremiumsAA = ratingImpactOnTermPremiumsAAA.map(
    (spread, index) =>
      spread +
      ratingImpactInput.spreadIncrement[index] *
        Math.pow(1 + ratingImpactInput.spreadFactor[index] / 100, 2),
  );
  const ratingImpactOnTermPremiumsA = ratingImpactOnTermPremiumsAA.map(
    (spread, index) =>
      spread +
      ratingImpactInput.spreadIncrement[index] *
        Math.pow(1 + ratingImpactInput.spreadFactor[index] / 100, 3),
  );
  const ratingImpactOnTermPremiumsBBB = ratingImpactOnTermPremiumsA.map(
    (spread, index) =>
      spread +
      ratingImpactInput.spreadIncrement[index] *
        Math.pow(1 + ratingImpactInput.spreadFactor[index] / 100, 4),
  );
  const ratingImpactOnTermPremiumsBB = ratingImpactOnTermPremiumsBBB.map(
    (spread, index) =>
      spread +
      ratingImpactInput.spreadIncrement[index] *
        Math.pow(1 + ratingImpactInput.spreadFactor[index] / 100, 5),
  );
  const ratingImpactOnTermPremiumsB = ratingImpactOnTermPremiumsBB.map(
    (spread, index) =>
      spread +
      ratingImpactInput.spreadIncrement[index] *
        Math.pow(1 + ratingImpactInput.spreadFactor[index] / 100, 6),
  );

  const ratingImpactSpreads = {
    ...params.ratingImpactSpreads,
    AAA: ratingImpactOnTermPremiumsAAA,
    AA: ratingImpactOnTermPremiumsAA,
    A: ratingImpactOnTermPremiumsA,
    BBB: ratingImpactOnTermPremiumsBBB,
    BB: ratingImpactOnTermPremiumsBB,
    B: ratingImpactOnTermPremiumsB,
  };

  return {
    coreCreditSpreads: {
      ...params.coreCreditSpread,
      AAA: coreCreditSpreadsAAA,
      AA: coreCreditSpreadsAA,
      A: coreCreditSpreadsA,
      BBB: coreCreditSpreadsBBB,
      BB: coreCreditSpreadsBB,
      B: coreCreditSpreadsB,
    },
    termPremiums: {
      ...params.termPremiums,
      oneYr: termPremiumsSpreads1Yr,
      twoYr: termPremiumsSpreads2Yr,
      threeYr: termPremiumsSpreads3Yr,
      fourYr: termPremiumsSpreads4Yr,
      fiveYr: termPremiumsSpreads5Yr,
    },
    ratingImpactSpreads,
    referenceRate: params.referenceRate,
  };
};
export const calculateTotalSpreads = (
  wsfCosts: WsfCosts,
  term: number,
): { [key: string]: number[] } => {
  const { coreCreditSpreads, termPremiums, ratingImpactSpreads } = wsfCosts;
  const termPremiumsKey: keyof typeof termPremiums = (
    ["oneYr", "twoYr", "threeYr", "fourYr", "fiveYr"] as Array<
      keyof typeof termPremiums
    >
  )[term - 1];
  const totalSpreads = {
    AAA: coreCreditSpreads.AAA.map(
      (spread, index) =>
        spread +
        termPremiums[termPremiumsKey][index] *
          (ratingImpactSpreads.AAA[index] / 100),
    ),
    AA: coreCreditSpreads.AA.map(
      (spread, index) =>
        spread +
        termPremiums[termPremiumsKey][index] *
          (ratingImpactSpreads.AA[index] / 100),
    ),
    A: coreCreditSpreads.A.map(
      (spread, index) =>
        spread +
        termPremiums[termPremiumsKey][index] *
          (ratingImpactSpreads.A[index] / 100),
    ),
    BBB: coreCreditSpreads.BBB.map(
      (spread, index) =>
        spread +
        termPremiums[termPremiumsKey][index] *
          (ratingImpactSpreads.BBB[index] / 100),
    ),
    BB: coreCreditSpreads.BB.map(
      (spread, index) =>
        spread +
        termPremiums[termPremiumsKey][index] *
          (ratingImpactSpreads.BB[index] / 100),
    ),
    B: coreCreditSpreads.B.map(
      (spread, index) =>
        spread +
        termPremiums[termPremiumsKey][index] *
          (ratingImpactSpreads.B[index] / 100),
    ),
  };

  return totalSpreads;
};
