import React from "react";
import Card from "../../../../../atoms/card/Card";
import "./CustomToolTip.scss";
interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  formatter?: (value: any) => string | number;
  toolTipFormatter?: (value: any) => string | number;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  formatter,
  toolTipFormatter,
}) => {
  if (active && payload && payload.length) {
    return (
      <Card sharp className="custom-tooltip">
        {payload.map((entry, index) => {
          const formattedValue = toolTipFormatter
            ? toolTipFormatter(entry.value)
            : formatter
              ? formatter(entry.value)
              : entry.value;
          return (
            <p key={index} style={{ color: entry.stroke }}>
              {`${entry.name}: ${formattedValue}`}
            </p>
          );
        })}
      </Card>
    );
  }

  return null;
};

export default CustomTooltip;
