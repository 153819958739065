import React from "react";

import EnumDropdown, {
  getValueFunc,
} from "../../../../organisms/enum-dropdown/EnumDropdown";

interface Props {
  onChange: (selected: { value: API.Country; label: string }) => void;
  value?: API.Country;
  error?: string;
  label?: string;
  name?: string;
  small?: boolean;
}

export const options: Array<{ value: API.Country; label: string }> = [
  {
    value: "australia",
    label: "Australia",
  },
  {
    value: "new_zealand",
    label: "New Zealand",
  },
  {
    value: "usa",
    label: "USA",
  },
  {
    value: "united_kingdom",
    label: "United Kingdom",
  },
  {
    value: "europe",
    label: "Europe",
  },
  {
    value: "indonesia",
    label: "Indonesia",
  },
  {
    value: "japan",
    label: "Japan",
  },
  {
    value: "china",
    label: "China",
  },
  {
    value: "singapore",
    label: "Singapore",
  },
  {
    value: "small_market",
    label: "Small Market",
  },
  {
    value: "medium_market",
    label: "Medium Market",
  },
  {
    value: "big_market",
    label: "Big Market",
  },
];

export const getLabelFromValue = getValueFunc(options);

function CountryDropdown({
  onChange,
  value,
  error,
  label,
  name,
  small,
}: Props) {
  return (
    <EnumDropdown<API.Country>
      small={small}
      onChange={onChange}
      options={options}
      value={value}
      error={error}
      label={label}
      name={name ?? "country-dropdown"}
    />
  );
}

export default CountryDropdown;
