import React from "react";

import Card from "../../../../atoms/card/Card";
import Text from "../../../../atoms/text/Text";
import DisplayField from "../../../../atoms/form/display-field/DisplayField";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import Clickable from "../../../../atoms/clickable/Clickable";
import Icon from "../../../../atoms/icon/Icon";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import { useSpecialProjects } from "../../../../../hooks/useSpecialProjects";
import AvoidPageBreak from "../../../../atoms/printable/components/AvoidPageBreak";

interface Props {
  data: API.SimulationResponse["specialProjectsSetup"];
  onClickEdit?: () => void;
}

const Display: React.FC<Props> = ({ data, onClickEdit }) => {
  const { data: projects } = useSpecialProjects();
  return data && projects ? (
    <Card wide>
      <AvoidPageBreak>
        <InlineGroup verticalCenter>
          <h4>Special Project Settings</h4>
          {onClickEdit ? (
            <Clickable
              data-test={"edit-simulation-special-projects-step"}
              onClick={onClickEdit}
            >
              <Icon
                type="edit"
                tip={{
                  content: "Edit Special Projects",
                  id: "edit-special-projects",
                }}
              />
            </Clickable>
          ) : null}
        </InlineGroup>
        {!data.enabled && <Text size="sm">Disabled</Text>}
        {data.enabled && data.operationalRiskEnabled && (
          <DisplayField
            label="Operational Risk Event"
            value={`Round #${data.operationalRiskRound}`}
          />
        )}
        {data.enabled && data.crimeSyndicateEnabled && (
          <DisplayField
            label="Crime Syndicate Event"
            value={`Round #${data.crimeSyndicateRound}`}
          />
        )}
        {data.enabled && data.specialProjects.length > 0 && (
          <DisplayField
            className="mt-2"
            stretch
            verticalCenter={false}
            label="List of projects"
            value={
              <VerticalGroup spaceBetweenElements={3}>
                {data.specialProjects.map((project) => (
                  <Text size="sm" key={project}>
                    {projects.find((p) => p.type === project)?.name ??
                      `Project ${project}`}
                  </Text>
                ))}
              </VerticalGroup>
            }
          />
        )}
      </AvoidPageBreak>
    </Card>
  ) : null;
};

export default Display;
