import React from "react";
import classNames from "classnames";
import Theme from "../../../styles/_theme.module.scss";
import Icon, { IconType } from "../../atoms/icon/Icon";
import { Link } from "react-router-dom";
import { useCurrentUser } from "../../../context/userContext";

export interface ISidebarItem {
  name: string;
  icon: IconType;
  path: string;
  isNested: boolean;
  parentIndex?: number;
  isParent: boolean;
  requiredRoles?: API.Role[];
}

interface Props {
  item: ISidebarItem;
  active: boolean;
  indent: boolean;
}

const SidebarItem: React.FC<Props> = ({ item, active, indent }) => {
  const user = useCurrentUser();
  if (
    user &&
    item.requiredRoles &&
    item.requiredRoles?.length > 0 &&
    !item.requiredRoles?.includes(user?.type)
  ) {
    return null;
  }

  const sidebarClass = classNames(
    "item",
    { active: active },
    { indent: indent },
  );
  return (
    <Link data-test={item.name} to={item.path} key={item.name}>
      <div className={sidebarClass}>
        <Icon type={item.icon} active={active} fill={Theme.white} />
        <p>{item.name}</p>
      </div>
    </Link>
  );
};

export default SidebarItem;
