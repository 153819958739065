import React, { useCallback, useEffect, useState } from "react";
import Banner from "../../../../atoms/banner/Banner";
import LoadingSpinner from "../../../../atoms/loadingspinner/LoadingSpinner";
import ReportingTemplateDetailsLayout from "../../../reporting-templates/ReportingTemplateDetailsLayout";
import { DropResult } from "react-beautiful-dnd";
import {
  LayoutResponse,
  LayoutUpdateRequest,
} from "../../../reporting-templates/types";
import ReportingTemplatesThatNeedManualModification from "../components/ReportingTemplatesThatNeedManualModification";

interface Props {
  templateType: "intro" | "reporting";
  inProgress: boolean;
  error: Error | null;
  apiLayouts: LayoutResponse[] | null;
  data: ModelAPI.Reporting.ReportingResults | null;
  refresh: () => void;
  updateConfig: (
    layoutId: string,
    data: LayoutUpdateRequest,
  ) => Promise<{
    templatesThatNeedManualModification: API.ReportingTemplatesThatNeedManualModification[];
  }>;
  patchLayouts: (data: LayoutResponse[]) => void;
}

function Layouts({
  templateType,
  inProgress,
  error,
  apiLayouts,
  refresh,
  updateConfig,
  patchLayouts,
  data: previewData,
}: Props) {
  const [selectedLayout, setSelectedLayout] = useState<LayoutResponse | null>(
    null,
  );
  const [apiError, setApiError] = useState<string | null>(null);
  const [apiUpdating, setApiUpdating] = useState(false);
  const [layouts, setLayouts] = useState<LayoutResponse[]>([]);

  const [
    reportingTemplatesThatNeedManualModification,
    setReportingTemplatesThatNeedManualModification,
  ] = useState<API.ReportingTemplatesThatNeedManualModification[] | null>(null);

  useEffect(() => {
    if (apiLayouts) {
      setLayouts(apiLayouts);
      if (
        !selectedLayout ||
        !apiLayouts.some((layout) => layout.id === selectedLayout.id)
      ) {
        setSelectedLayout(apiLayouts[0]);
      }
    }
  }, [apiLayouts, selectedLayout]);

  const onLayoutUpdate = useCallback(
    async (layoutId: string, data: LayoutUpdateRequest) => {
      try {
        setApiUpdating(true);
        if (selectedLayout?.id === layoutId) {
          setSelectedLayout((sl) => ({
            ...sl!,
            ...data,
          }));
        }
        setLayouts((list) => {
          const result = Array.from(list);
          const index = result.findIndex((r) => r.id === layoutId);
          result[index] = { ...result[index], ...data };
          return result;
        });
        const response = await updateConfig(layoutId, data);
        if (
          response.templatesThatNeedManualModification &&
          response.templatesThatNeedManualModification.length > 0
        ) {
          setReportingTemplatesThatNeedManualModification(
            response.templatesThatNeedManualModification,
          );
        }
        refresh();
        setApiUpdating(false);
      } catch (e) {
        setApiUpdating(false);
        setApiError(e.message);
      }
    },
    [selectedLayout?.id, updateConfig, refresh],
  );

  const onDragEnd = useCallback(
    async (result: DropResult) => {
      if (!result.destination) return;

      const sourceIndex = result.source.index;
      const destIndex = result.destination.index;

      let layoutsResult: LayoutResponse[] = [];

      setLayouts((list) => {
        const result = Array.from(list);
        const [removed] = result.splice(sourceIndex, 1);
        result.splice(destIndex, 0, removed);
        layoutsResult = result;
        return result;
      });

      for (let i = 0; i < layoutsResult.length; i++) {
        const val = layoutsResult[i];
        if (val.config && val.config.type === "custom_image") {
          delete val.config.imageUrl;
        }
        if (val.config && val.config.type === "custom_text_and_image_left") {
          delete val.config.imageUrl;
        }
        if (val.config && val.config.type === "custom_text_and_image_right") {
          delete val.config.imageUrl;
        }
        if (val.config && val.config.type === "custom_video") {
          delete val.config.videoUrl;
        }
      }

      try {
        setApiUpdating(true);
        await patchLayouts(layoutsResult);
        refresh();
        setApiUpdating(false);
      } catch (e) {
        setApiUpdating(false);
        setApiError(e.message);
      }
    },
    [patchLayouts, refresh],
  );

  const onCustomTemplateEdit = useCallback(
    (data: API.ReportingTemplateLayoutUpdateRequest) => {
      onLayoutUpdate(selectedLayout!.id, data);
    },
    [onLayoutUpdate, selectedLayout],
  );

  return (
    <>
      {inProgress && !apiLayouts && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {apiError != null && (
        <Banner type="error" active={!!apiError} message={apiError} />
      )}
      {!!apiLayouts && !!previewData && !!selectedLayout && layouts?.length && (
        <ReportingTemplateDetailsLayout
          disabled={apiUpdating}
          droppableId={"masterConfig"}
          onDragEnd={onDragEnd}
          layouts={layouts}
          selectedLayout={selectedLayout}
          setSelectedLayout={setSelectedLayout}
          previewData={previewData}
          onLayoutUpdate={onLayoutUpdate}
          onCustomTemplateEdit={onCustomTemplateEdit}
          hideDurationEstimateFirstRound={
            layouts[0].durationEstimateFirstRound === undefined
          }
        />
      )}
      {reportingTemplatesThatNeedManualModification &&
        reportingTemplatesThatNeedManualModification.length > 0 && (
          <ReportingTemplatesThatNeedManualModification
            templateType={templateType}
            templatesThatNeedManualModification={
              reportingTemplatesThatNeedManualModification
            }
            onClose={() =>
              setReportingTemplatesThatNeedManualModification(null)
            }
          />
        )}
    </>
  );
}

export default Layouts;
