import React, { useMemo } from "react";
import RoundResultsPage from "../../results/RoundResultsPage";

import Clickable from "../../../atoms/clickable/Clickable";
import HoverableIcon from "../../../atoms/icon/HoverableIcon";
import Icon from "../../../atoms/icon/Icon";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import useIsMobile from "../../../../hooks/useIsMobile";
import classNames from "classnames";
import "./ReportingPagePreviewTile.scss";
import { LayoutResponse, LayoutUpdateRequest } from "../types";
import useIsMonitor from "../../../../hooks/useIsMonitor";

export const THUMBNAIL_COLUMN_WIDTH = 400;
export const THUMBNAIL_WIDTH = THUMBNAIL_COLUMN_WIDTH - 20;
export const RENDERED_DIMENSIONS = { width: 1945, height: 1000 };
export const RENDERED_DIMENSIONS_SMALL_SCREEN = { width: 1366, height: 768 };

export interface Props extends React.ButtonHTMLAttributes<HTMLDivElement> {
  disabled?: boolean;
  layout: LayoutResponse;
  onLayoutUpdate?: (layoutId: string, data: LayoutUpdateRequest) => void;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  data: ModelAPI.Reporting.ReportingResults;
  showHeader?: boolean;
  showTeamPositions?: boolean;
  overlay?: React.ReactNode;
  roundNumber?: number;
  useOverrideDisabled?: boolean;
  dndDragProps?: any;
  thumbnailWidth?: number;
  thumbnailHeight?: number;
}

function ReportingPagePreviewTile({
  disabled,
  layout,
  onLayoutUpdate,
  dragHandleProps,
  data,
  showHeader,
  showTeamPositions,
  overlay,
  roundNumber,
  style,
  useOverrideDisabled,
  dndDragProps,
  thumbnailWidth = 380,
  thumbnailHeight = 200,
}: Props) {
  const isMobile = useIsMobile();
  const isMonitor = useIsMonitor();

  const containerClasses = useMemo(() => {
    return classNames("ReportingPagePreviewTile", {
      "container-mobile": isMobile,
    });
  }, [isMobile]);

  const contentClasses = useMemo(() => {
    return classNames({
      "content-mobile": isMobile,
    });
  }, [isMobile]);

  const getContentScale = () => {
    if (isMobile) return 1;

    const contentWidth = isMonitor
      ? RENDERED_DIMENSIONS.width
      : RENDERED_DIMENSIONS_SMALL_SCREEN.width;
    const contentHeight = isMonitor
      ? RENDERED_DIMENSIONS.height
      : RENDERED_DIMENSIONS_SMALL_SCREEN.height;

    // Use the same scaling logic as ContentStretcher
    return thumbnailHeight * contentWidth < thumbnailWidth * contentHeight
      ? thumbnailHeight / contentHeight
      : thumbnailWidth / contentWidth;
  };

  const renderContent = () => (
    <div
      className="content-wrapper"
      style={{
        position: "relative",

        width: thumbnailWidth,
        height: thumbnailHeight,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: isMobile
            ? "100%"
            : isMonitor
              ? RENDERED_DIMENSIONS.width
              : RENDERED_DIMENSIONS_SMALL_SCREEN.width,
          height: isMobile
            ? "100%"
            : isMonitor
              ? RENDERED_DIMENSIONS.height
              : RENDERED_DIMENSIONS_SMALL_SCREEN.height,
          transform: !isMobile ? `scale(${getContentScale()})` : undefined,
          transformOrigin: "center center",
          position: "absolute",
        }}
      >
        <RoundResultsPage
          data={data}
          roundNumber={roundNumber ?? data.roundId ?? 3}
          onlyThesePages={[layout.type]}
          hidePagination
          hideHeader={!showHeader}
          hideTeamPositions={!showTeamPositions}
          noAnimations
          noAudio
          ignoreKeyPress
          allowHiddenLayouts
        />
        {(!!onLayoutUpdate ||
          !!dragHandleProps ||
          !!overlay ||
          !!dndDragProps) && (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              backgroundColor:
                !!onLayoutUpdate || !!dragHandleProps
                  ? layout.enabled
                    ? "rgba(0, 0, 0, 0.3)"
                    : "rgba(0, 0, 0, 0.6)"
                  : undefined,
            }}
          >
            {!!onLayoutUpdate && (
              <Clickable
                disabled={disabled}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  if (onLayoutUpdate) {
                    if (useOverrideDisabled) {
                      onLayoutUpdate(layout.id, {
                        overrideDisabled: layout.overrideDisabled
                          ? false
                          : true,
                      });
                    } else {
                      onLayoutUpdate(layout.id, { enabled: !layout.enabled });
                    }
                  }
                }}
              >
                <HoverableIcon
                  size={32}
                  fill={
                    layout.enabled && !layout.overrideDisabled ? "white" : "red"
                  }
                  hoverType={
                    layout.enabled && !layout.overrideDisabled
                      ? "hidden"
                      : "eye"
                  }
                  type={
                    layout.enabled && !layout.overrideDisabled
                      ? "eye"
                      : "hidden"
                  }
                />
              </Clickable>
            )}
            {!isMobile && !!dndDragProps && (
              <Clickable
                disabled={disabled}
                style={{
                  position: "absolute",
                  top: "0%",
                  right: "0%",
                  padding: 30,
                }}
                id={dndDragProps.id}
                ref={dndDragProps.ref}
                {...dndDragProps.attributes}
                {...dndDragProps.listeners}
              >
                <Icon size={16} fill="white" type="draggable" />
              </Clickable>
            )}
            {!isMobile && !!dragHandleProps && (
              <Clickable
                disabled={disabled}
                style={{
                  position: "absolute",
                  top: "0%",
                  right: "0%",
                  padding: 30,
                }}
                {...dragHandleProps}
              >
                <Icon size={16} fill="white" type="draggable" />
              </Clickable>
            )}
            {!!overlay && overlay}
          </div>
        )}
      </div>
    </div>
  );

  if (isMobile) {
    return (
      <div className={containerClasses}>
        <div className={contentClasses}>{renderContent()}</div>
      </div>
    );
  }

  return (
    <div
      className="ReportingPagePreviewTile"
      style={{
        background: "#e1e1e1",
        width: thumbnailWidth,
        height: thumbnailHeight,
        position: "relative",

        ...style,
      }}
    >
      {renderContent()}
    </div>
  );
}

export default React.memo(ReportingPagePreviewTile);
