/**
 * AuthenticationError
 *
 * user logged in, but have no access to some resources
 */

class AuthenticationError extends Error {
  isCustomError = true;
  name = "AuthenticationError";

  constructor(expected: API.Role[], current: API.Role) {
    super(
      `Expected roles ${expected.join(",")}, but current user is a ${current}`,
    );
  }
}

export default AuthenticationError;
