import React, { ReactNode } from "react";

interface PrintOnlyProps {
  children: ReactNode;
}

const PrintOnly: React.FC<PrintOnlyProps> = ({ children }) => {
  return <div className="print-only">{children}</div>;
};

export default PrintOnly;
