import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from "react";
import { useParams } from "react-router-dom";
import { useEvent } from "../hooks/useEvent";

interface CurrentEventContxtData {
  inProgress: boolean;
  event: null | API.EventResponse;
  error: null | Error;
  refresh: () => void;
}

const defaultData: CurrentEventContxtData = {
  inProgress: true,
  event: null,
  error: null,
  refresh: () => undefined,
};

const currentEventContxt = createContext<CurrentEventContxtData>(defaultData);

const Provider = currentEventContxt.Provider;

const EventProvider: React.FC<{ id: string }> = ({ id, children }) => {
  const { data, inProgress, error, refresh } = useEvent(id);

  const contextData = useMemo(
    () => ({
      inProgress,
      event: data,
      error,
      refresh,
    }),
    [data, error, inProgress, refresh],
  );

  return <Provider value={contextData}>{children}</Provider>;
};

export const CurrentEventProvider: React.FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const params = useParams<{ eventId: string | undefined }>();

  if (typeof params.eventId === "undefined") {
    return <Provider value={defaultData}>{children}</Provider>;
  }

  return <EventProvider id={params.eventId}>{children}</EventProvider>;
};

export const useCurrentEvent = () => {
  return useContext(currentEventContxt);
};

export const useAssessmentInEvent = () => {
  const { inProgress, event, error, refresh } = useContext(currentEventContxt);

  return useMemo(() => {
    return {
      inProgress,
      assessment: event ? event.assessment : null,
      error,
      refresh,
    };
  }, [error, event, inProgress, refresh]);
};
