import React from "react";

import Card from "../../../../atoms/card/Card";
import DisplayField from "../../../../atoms/form/display-field/DisplayField";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import Clickable from "../../../../atoms/clickable/Clickable";
import Icon from "../../../../atoms/icon/Icon";
import Text from "../../../../atoms/text/Text";
import AvoidPageBreak from "../../../../atoms/printable/components/AvoidPageBreak";

interface Props {
  data?: API.SimulationResponse;
  onClickEdit?: () => void;
}

const formatDisplayField = (
  dealRoom: string,
  dealRoomConfig: API.DealConfigResponse[],
  dealRoomSetup: API.SimulationResponse["dealRoomSetup"],
) => {
  let response =
    dealRoomConfig?.find((d) => d.type === dealRoom)?.name ?? "None";
  if (dealRoomSetup?.amlEventEnabled && dealRoom === "neobank") {
    response += ` (AML Event Enabled Round ${dealRoomSetup.amlEventRound})`;
  }
  return response;
};

const Display: React.FC<Props> = ({ data, onClickEdit }) => {
  return data?.dealRoomSetup && data?.dealRoomConfig ? (
    <Card wide>
      <AvoidPageBreak>
        <InlineGroup verticalCenter>
          <h4>Deal Room Settings</h4>
          {onClickEdit ? (
            <Clickable
              data-test={"edit-simulation-deal-room-step"}
              onClick={onClickEdit}
            >
              <Icon
                type="edit"
                tip={{
                  content: "Edit Deal Room",
                  id: "edit-deal-room",
                }}
              />
            </Clickable>
          ) : null}
        </InlineGroup>
        {!data.dealRoomSetup.enabled && <Text size="sm">Disabled</Text>}
        {data.dealRoomSetup.enabled &&
          data.dealRoomSetup.dealRooms.map((dealRoom: string, i: number) => {
            if (i >= data.generalSetup.rounds) return null;
            return (
              <DisplayField
                key={i}
                label={`Round #${i + 1}`}
                value={formatDisplayField(
                  dealRoom,
                  data.dealRoomConfig!,
                  data.dealRoomSetup,
                )}
              />
            );
          })}
      </AvoidPageBreak>
    </Card>
  ) : null;
};

export default Display;
