import React, { forwardRef } from "react";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import "./PrintParticipantsPage.scss";
import Table, { Th, Tr } from "../../../../../atoms/table/Table";
import Text from "../../../../../atoms/text/Text";
import { sortParticipants } from "../../../../../../lib/participantSort";
import Image from "../../../../../atoms/image/Image";
import { ParticipantRow } from "./PrintParticipantsAttendancePage";
import Printable from "../../../../../atoms/printable/Printable";
import PrintPreviewGameDetails from "../../../../game/PrintPreviewGameDetails";

interface Props {
  event: API.EventResponse;
  participants: API.ParticipantResponse[];
}

const PrintParticipantsPage = forwardRef<HTMLDivElement, Props>(
  ({ participants, event }, ref) => {
    const filterParticipants = (participants: API.ParticipantResponse[]) => {
      return participants.filter(
        (p) =>
          !(
            p.lastName.toLowerCase().includes("tablet") &&
            p.firstName.toLowerCase().includes("player")
          ),
      );
    };
    const sortParticipantsByLastName = (participants: any) => {
      return [...participants].sort((a, b) =>
        a.lastName.localeCompare(b.lastName),
      );
    };

    const sortParticipantsByFirstName = (participants: any) => {
      return [...participants].sort((a, b) =>
        a.firstName.localeCompare(b.firstName),
      );
    };

    const filteredParticipants = filterParticipants(participants);

    const sortedParticipantsByTeam = sortParticipants(filteredParticipants);
    const sortedParticipantsByLastName =
      sortParticipantsByLastName(filteredParticipants);
    const sortedParticipantsByFirstName =
      sortParticipantsByFirstName(filteredParticipants);

    let currentTeam: number | null = null;
    let isWhiteRow = false;

    const renderTableHeader = () => (
      <thead className="participant-header">
        <tr>
          <th colSpan={4}>
            <VerticalGroup center>
              <Text center bold size="lg">
                Participants List
              </Text>
              <InlineGroup block stretch verticalCenter spread>
                <VerticalGroup className="game-details">
                  <PrintPreviewGameDetails
                    participantsCount={filteredParticipants.length}
                    event={event}
                  />
                </VerticalGroup>
                <Image
                  height={36}
                  src={event.branding?.brandingImage?.imageUrl}
                  alt="branding image"
                />
              </InlineGroup>
            </VerticalGroup>
          </th>
        </tr>
      </thead>
    );

    return (
      <Printable orientation="landscape" ref={ref}>
        <VerticalGroup center className="participants-page" wide>
          <Table>
            {renderTableHeader()}

            <InlineGroup className="participants-tables" block spread>
              <VerticalGroup style={{ width: "25%" }} wide>
                <Table className={"participant-table"}>
                  <thead>
                    <Tr>
                      <Th center colSpan={4}>
                        Participants Sorted By Team
                      </Th>
                    </Tr>
                    <Tr>
                      <Th width={12}>Team</Th>
                      <Th>First Name</Th>
                      <Th>Last Name</Th>
                    </Tr>
                  </thead>
                  <tbody>
                    {sortedParticipantsByTeam.map((participant) => {
                      if (participant.team !== currentTeam) {
                        isWhiteRow = !isWhiteRow;
                        currentTeam = participant.team;
                      }
                      const rowColour = isWhiteRow ? "white" : "primaryGrey";

                      return (
                        <ParticipantRow
                          key={participant.id}
                          participant={participant}
                          bold={participant.isCeo}
                          colour={rowColour}
                        />
                      );
                    })}
                  </tbody>
                </Table>
              </VerticalGroup>
              <VerticalGroup style={{ width: "25%" }} wide>
                <Table className={"participant-table"}>
                  <thead>
                    <Tr>
                      <Th center colSpan={4}>
                        Participants Sorted By Last Name
                      </Th>
                    </Tr>
                    <Tr>
                      <Th width={12}>Team</Th>

                      <Th>First Name</Th>
                      <Th>Last Name</Th>
                    </Tr>
                  </thead>
                  <tbody>
                    {sortedParticipantsByLastName.map((participant) => (
                      <ParticipantRow
                        key={participant.id}
                        participant={participant}
                        bold={participant.isCeo}
                      />
                    ))}
                  </tbody>
                </Table>
              </VerticalGroup>
              <VerticalGroup style={{ width: "25%" }} wide>
                <Table className={"participant-table"}>
                  <thead>
                    <Tr>
                      <Th colSpan={4} center>
                        Participants Sorted By First Name
                      </Th>
                    </Tr>
                    <Tr>
                      <Th width={12}>Team</Th>

                      <Th>First Name</Th>
                      <Th>Last Name</Th>
                    </Tr>
                  </thead>
                  <tbody>
                    {sortedParticipantsByFirstName.map((participant) => (
                      <ParticipantRow
                        key={participant.id}
                        participant={participant}
                        bold={participant.isCeo}
                      />
                    ))}
                  </tbody>
                </Table>
              </VerticalGroup>
            </InlineGroup>
          </Table>
        </VerticalGroup>
      </Printable>
    );
  },
);

PrintParticipantsPage.displayName = "PrintParticipantsPage";
export default PrintParticipantsPage;
