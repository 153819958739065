import React, { useMemo } from "react";
import Radio from "../../../../atoms/form/input/Radio";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import Text from "../../../../atoms/text/Text";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import { createInitState } from "../QuestionForm/FormDetails/RatingFormDetails";
import { OtherOptionField } from "./Fields/OtherOptionFIeld";

import "./QuestionPreview.scss";
import { QuestionPreviewContainer } from "./QuestionPreviewContainer";
import { Noop } from "../../../../../constants/functions";

interface Props {
  group: API.AssessmentGroupResponse;
  question: API.AssessmentQuestionResponse;
  index: number;
  disableQuestionIndex?: boolean;
  answer?: API.AssessmentAnswerResponse["answers"][0]["answer"];
  isAnswerMode?: boolean;
}

export const RatingQuestionPreview: React.FC<Props> = ({
  index,
  group,
  question,
  disableQuestionIndex,
  answer,
  isAnswerMode = false,
}) => {
  const state = useMemo(() => createInitState(question.fields), [question]);
  const ratingItems = useMemo(() => state.items, [state]);
  const hasAnswer = typeof answer !== "undefined";
  const answerItem = typeof answer !== "undefined" ? answer[0] : undefined;
  const matchedField = useMemo(() => {
    if (typeof answerItem !== "undefined") {
      return ratingItems.find((field) => field.id === answerItem.fieldId);
    }
    return undefined;
  }, [answerItem, ratingItems]);

  return (
    <QuestionPreviewContainer
      index={index}
      question={question}
      group={group}
      disableQuestionIndex={disableQuestionIndex}
      isAnswerMode={isAnswerMode}
    >
      <VerticalGroup spaceBetweenElements={2} wide>
        <InlineGroup
          className="pl-8 pr-8 pb-20 pt-4"
          block
          spread
          verticalCenter
        >
          {ratingItems.map((item: API.RatingFieldRequest, index: number) => {
            return (
              <>
                <VerticalGroup
                  className="rating-container"
                  center
                  key={index}
                  verticalCenter
                >
                  <Radio
                    inline
                    onChange={Noop}
                    name={question.id}
                    label=""
                    large
                    {...(hasAnswer
                      ? { checked: matchedField?.id === item.id }
                      : {})}
                    {...(isAnswerMode ? { disabled: true } : {})}
                  />
                  <VerticalGroup className="rating-label mt-2" center>
                    <Text center>{item.config.rating}</Text>
                    <Text center>{item.config.label}</Text>
                  </VerticalGroup>
                </VerticalGroup>
                {index !== ratingItems.length - 1 && (
                  <span className="separator" />
                )}
              </>
            );
          })}
        </InlineGroup>

        {state.naItem && (
          <Radio
            onChange={Noop}
            name={question.id}
            label={state.naItem.config.label}
            {...(answerItem
              ? {
                  checked: answerItem.fieldId === state.naItem.id,
                  disabled: true,
                }
              : {})}
          />
        )}

        {state.writtenResponseField && (
          <OtherOptionField
            question={question}
            type="checkboxes"
            field={state.writtenResponseField}
            answer={answer}
            isAnswerMode={isAnswerMode}
          />
        )}
      </VerticalGroup>
    </QuestionPreviewContainer>
  );
};
