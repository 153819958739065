import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import Icon from "../../../atoms/icon/Icon";
import Image from "../../../atoms/image/Image";
import Table from "../../../atoms/table/Table";

import { capitalize } from "../../../../services/utils";
import StickyBar from "../../../organisms/sticky-bar/StickyBar";
import EmptyList from "../../../organisms/empty-list/EmptyList";
import IconButton from "../../../molecules/iconbutton/IconButton";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import DeleteClient from "./ClientDelete";
import Clickable from "../../../atoms/clickable/Clickable";
import Tooltip from "../../../atoms/tooltip/Tooltip";

interface Props {
  clients: API.ClientResponse[];
  handleExport: () => void;
  refreshClients: () => void;
}

interface ClientState {
  deleteModalOpen: boolean;
  clientSelected: API.ClientResponse | null;
}

const STICKY_BAR_HEIGHT = 8;
const TOOLTIP_ID = "clients-table-tooltip";

function ClientListTable({ clients, handleExport, refreshClients }: Props) {
  const history = useHistory();
  const [{ deleteModalOpen, clientSelected }, setClientState] =
    useState<ClientState>({ deleteModalOpen: false, clientSelected: null });

  const onClientDeleteComplete = useCallback(() => {
    setClientState((prevState) => ({
      ...prevState,
      deleteModalOpen: false,
      clientSelected: null,
    }));
    refreshClients();
  }, [refreshClients]);

  const handleDeleteClick = useCallback(
    (client: API.ClientResponse) => (event: any) => {
      event.stopPropagation();
      setClientState({
        deleteModalOpen: true,
        clientSelected: client,
      });
    },
    [],
  );
  const handleClientClick = useCallback(
    (client: API.ClientResponse) => (event: any) => {
      event.stopPropagation();
      event.preventDefault();
      history.push(`/administration/clients/${client.id}/view`);
    },
    [history],
  );

  return (
    <>
      <Tooltip id={TOOLTIP_ID} />
      {deleteModalOpen && !!clientSelected && (
        <DeleteClient
          isOpen={deleteModalOpen}
          onClose={() =>
            setClientState((prevState) => ({
              ...prevState,
              brandingModalOpen: false,
              clientSelected: null,
            }))
          }
          onComplete={onClientDeleteComplete}
          client={clientSelected}
        />
      )}
      <StickyBar height={STICKY_BAR_HEIGHT} spaceBetweenElements={2}>
        <IconButton
          data-test="add-client"
          linkTo="/administration/clients/add"
          icon="addUser"
          text="Add Client"
        />
        <IconButton onClick={handleExport} icon="export" text="Export List" />
      </StickyBar>
      {!clients.length && (
        <EmptyList icon="clients" message="There are no clients yet" />
      )}
      {!!clients.length && (
        <Table stickyHeader stickyTop={STICKY_BAR_HEIGHT} clickable>
          <thead>
            <tr>
              <th>Client</th>
              <th>Status</th>
              <th>Open Events #</th>
              <th className="right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {clients.map((client) => (
              <tr
                className="clientsTable-row"
                key={client.id}
                data-test={client.id}
                onClick={handleClientClick(client)}
              >
                <td>
                  <InlineGroup verticalCenter className="pt-4 pb-4">
                    <Image
                      src={client.logoUrl}
                      alt={client.name}
                      heightSize={10}
                    />
                    <div className="ml-4">{client.name}</div>
                  </InlineGroup>
                </td>
                <td>
                  {client.state === "disabled"
                    ? "Deactivated"
                    : capitalize(client.state)}
                </td>
                <td>{client.openEventsCount}</td>
                <td className="right">
                  <Icon
                    type="search"
                    tt={{ content: "View Client", id: TOOLTIP_ID }}
                  />
                  <Clickable
                    data-test={`delete-${client.id}`}
                    onClick={handleDeleteClick(client)}
                  >
                    <Icon
                      type="trash"
                      tt={{ content: "Delete Client", id: TOOLTIP_ID }}
                    />
                  </Clickable>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}

export default ClientListTable;
