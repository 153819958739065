import React, { useCallback, useMemo } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import { TeamsWithColours } from "../RoundResultsPage";
import ResultsTeamLineChart from "../charts/ResultsTeamLineChart";
import { getYAxisDataV2 } from "../charts/yaxis";
import ChartCard from "../components/ChartCard";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import { useResultsContext } from "../context/context";

interface Props {
  pastRound1: boolean;
  teams: TeamsWithColours[];
  customerComplaints: ModelAPI.Reporting.DataAndPositions;
}

const RoundResultsCustomerComplaints: React.FC<Props> = ({
  pastRound1,
  teams,
  customerComplaints,
}) => {
  const { minValue, maxValue, numberOfTicks } = getYAxisDataV2({
    pastRound1,
    data: customerComplaints.data,
    // tickMultiplier: 100000
  });
  const context = useResultsContext();
  const maxClicks = useMemo(
    () => (context.allowPresentationMode || context.readSyncState ? 1 : 0),
    [context.allowPresentationMode, context.readSyncState],
  );
  const onNext = useCallback(() => {
    if (context.clicks < maxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context, maxClicks]);

  return (
    <RoundResultsContainer onNext={onNext}>
      <VerticalGroup full center wide>
        <ChartCard heading="Customer Complaints">
          <ResultsTeamLineChart
            shouldRenderLines={context.clicks === maxClicks}
            data={customerComplaints.data}
            teams={teams}
            minYAxisvalue={minValue}
            maxYAxisvalue={maxValue}
            numberOfTicks={numberOfTicks}
            teamPositions={customerComplaints.teamPositions}
          />
        </ChartCard>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsCustomerComplaints;
