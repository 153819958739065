import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SimpleModal from "../../../../../organisms/standard-modal/SimpleModal";
import Container from "../../../../../atoms/page/Page";
import Text from "../../../../../atoms/text/Text";
import Card from "../../../../../atoms/card/Card";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import EditableText, {
  Handles as EditableTextHandles,
} from "../../../../../atoms/text/EditableText";
import Clickable from "../../../../../atoms/clickable/Clickable";
import Icon from "../../../../../atoms/icon/Icon";
import Button from "../../../../../atoms/button/Button";
import LogoModal, {
  LOGO_TO_ICON_MAP,
} from "../../../../simulations/steps/economicScenarios/LogoModal";
import LogoContainer from "../../../../../LogoContainer/LogoContainer";
interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedRound: number;
  handleScenarioUpdated: (
    updatedScenario: API.EconomicScenarioUpdateRequest,
  ) => void;
  selectedScenario: API.EconomicScenarioResponse | null;
}

export const EditNewsConfigModal = ({
  isOpen,
  onClose,
  selectedScenario,
  selectedRound,
  handleScenarioUpdated,
}: Props) => {
  const mainSectionHeadingRef = useRef<EditableTextHandles>(null);
  const mainSectionTitleRef = useRef<EditableTextHandles>(null);
  const mainSectionSecondaryTitleRef = useRef<EditableTextHandles>(null);
  const marketViewSectionHeadingRef = useRef<EditableTextHandles>(null);
  const marketView1HeadingRef = useRef<EditableTextHandles>(null);
  const marketView2HeadingRef = useRef<EditableTextHandles>(null);
  const [scenario1Logo, setScenario1Logo] =
    useState<API.BreakingNewsConfigLogoType>();
  const [scenario2Logo, setScenario2Logo] =
    useState<API.BreakingNewsConfigLogoType>();
  const [showScenario1LogoModal, setShowScenario1LogoModal] = useState(false);
  const [showScenario2LogoModal, setShowScenario2LogoModal] = useState(false);

  const roundData = useMemo(() => {
    if (
      !selectedScenario ||
      !selectedScenario.news ||
      !selectedScenario.newsConfig
    ) {
      throw new Error("Invalid scenario");
    }

    const newsItem = selectedScenario.news.find(
      (news) => news.roundId === selectedRound,
    );

    if (!newsItem) {
      throw new Error("Invalid round");
    }
    return {
      newsItem,
      newsConfig: selectedScenario.newsConfig,
    };
  }, [selectedRound, selectedScenario]);

  const handleNewsChange = (fieldName: string, newValue: string) => {
    if (selectedScenario && selectedScenario.newsConfig) {
      const updatedNewsConfig = {
        ...selectedScenario.newsConfig,
        [fieldName]: newValue,
      };

      const updatedScenario = {
        ...selectedScenario,
        newsConfig: updatedNewsConfig,
      };

      handleScenarioUpdated(updatedScenario);
    }
  };

  const handleLogoUpdate = useCallback(
    (scenarioIndex: number, newLogo: API.BreakingNewsConfigLogoType) => {
      if (selectedScenario && selectedScenario.newsConfig) {
        const updatedNewsConfig = { ...selectedScenario.newsConfig };

        if (scenarioIndex === 1) {
          setScenario1Logo(newLogo);
          updatedNewsConfig.marketView1Logo = newLogo;
        } else if (scenarioIndex === 2) {
          setScenario2Logo(newLogo);
          updatedNewsConfig.marketView2Logo = newLogo;
        }

        const updatedScenario = {
          ...selectedScenario,
          newsConfig: updatedNewsConfig,
        };

        handleScenarioUpdated(updatedScenario);
      }
    },
    [handleScenarioUpdated, selectedScenario],
  );

  useEffect(() => {
    if (
      roundData.newsConfig.marketView1Logo &&
      roundData.newsConfig.marketView2Logo
    ) {
      setScenario1Logo(
        roundData.newsConfig.marketView1Logo as API.BreakingNewsConfigLogoType,
      );
      setScenario2Logo(
        roundData.newsConfig.marketView2Logo as API.BreakingNewsConfigLogoType,
      );
    }
  }, [
    roundData.newsConfig.marketView1Logo,
    roundData.newsConfig.marketView2Logo,
  ]);
  return (
    <SimpleModal isOpen={isOpen} size="medium" onClose={onClose}>
      <Container fit>
        <VerticalGroup wide spaceBetweenElements={5}>
          <Text size="xl" bold>
            Edit News Config
          </Text>
          <VerticalGroup wide spaceBetweenElements={5}>
            <VerticalGroup spaceBetweenElements={2} wide>
              <Text center size="lg" bold>
                Main Section
              </Text>
              <Card wide sharp>
                <VerticalGroup spaceBetweenElements={4}>
                  <VerticalGroup verticalCenter>
                    <Text bold>Main Heading</Text>
                    <InlineGroup verticalCenter spaceBetweenElements={2}>
                      <EditableText
                        type="text"
                        ref={mainSectionHeadingRef}
                        enableRightClickMenu
                        value={roundData.newsConfig.mainSectionHeading || ""}
                        onChange={(newUpdate) =>
                          handleNewsChange("mainSectionHeading", newUpdate)
                        }
                      />{" "}
                      <Clickable
                        onClick={() =>
                          mainSectionHeadingRef.current?.setIsEditing(true)
                        }
                      >
                        <Icon
                          noMargin
                          type="edit"
                          tip={{
                            content: "Main Section Text",
                            id: "mainSectionHeading",
                          }}
                        />
                      </Clickable>
                    </InlineGroup>
                  </VerticalGroup>
                  <VerticalGroup verticalCenter>
                    <Text bold>Title</Text>
                    <InlineGroup verticalCenter spaceBetweenElements={2}>
                      <EditableText
                        type="text"
                        ref={mainSectionTitleRef}
                        enableRightClickMenu
                        value={roundData.newsConfig.mainSectionTitle || ""}
                        onChange={(newUpdate) =>
                          handleNewsChange("mainSectionTitle", newUpdate)
                        }
                      />{" "}
                      <Clickable
                        onClick={() =>
                          mainSectionTitleRef.current?.setIsEditing(true)
                        }
                      >
                        <Icon
                          noMargin
                          type="edit"
                          tip={{
                            content: "Main Section Text",
                            id: "mainSectionTitle",
                          }}
                        />
                      </Clickable>
                    </InlineGroup>
                  </VerticalGroup>
                  <VerticalGroup verticalCenter>
                    <Text bold>Secondary Title</Text>
                    <InlineGroup verticalCenter spaceBetweenElements={2}>
                      <EditableText
                        type="text"
                        ref={mainSectionSecondaryTitleRef}
                        enableRightClickMenu
                        value={
                          roundData.newsConfig.mainSectionSecondaryTitle || ""
                        }
                        onChange={(newUpdate) =>
                          handleNewsChange(
                            "mainSectionSecondaryTitle",
                            newUpdate,
                          )
                        }
                      />{" "}
                      <Clickable
                        onClick={() =>
                          mainSectionSecondaryTitleRef.current?.setIsEditing(
                            true,
                          )
                        }
                      >
                        <Icon
                          noMargin
                          type="edit"
                          tip={{
                            content: "Main Section Text",
                            id: "mainSectionSecondaryTitle",
                          }}
                        />
                      </Clickable>
                    </InlineGroup>
                  </VerticalGroup>
                </VerticalGroup>
              </Card>
            </VerticalGroup>
            <VerticalGroup spaceBetweenElements={2} wide>
              <Text center size="lg" bold>
                Market View Section
              </Text>
              <Card wide sharp>
                <VerticalGroup spaceBetweenElements={4}>
                  <VerticalGroup verticalCenter>
                    <Text bold>Main Heading</Text>
                    <InlineGroup verticalCenter spaceBetweenElements={2}>
                      <EditableText
                        type="text"
                        ref={marketViewSectionHeadingRef}
                        enableRightClickMenu
                        value={
                          roundData.newsConfig.marketViewSectionHeading || ""
                        }
                        onChange={(newUpdate) =>
                          handleNewsChange(
                            "marketViewSectionHeading",
                            newUpdate,
                          )
                        }
                      />{" "}
                      <Clickable
                        onClick={() =>
                          marketViewSectionHeadingRef.current?.setIsEditing(
                            true,
                          )
                        }
                      >
                        <Icon
                          noMargin
                          type="edit"
                          tip={{
                            content: "Main Section Text",
                            id: "marketViewSectionHeading",
                          }}
                        />
                      </Clickable>
                    </InlineGroup>
                  </VerticalGroup>
                  <InlineGroup verticalCenter spaceBetweenElements={3}>
                    <LogoContainer colour={"secondaryBlue"} size={3}>
                      <Clickable
                        onClick={() => {
                          setShowScenario1LogoModal(true);
                        }}
                      >
                        <Icon
                          stroke="white"
                          size={12}
                          noMargin
                          type={
                            LOGO_TO_ICON_MAP[
                              roundData.newsConfig.marketView1Logo
                            ]
                          }
                        />
                      </Clickable>
                    </LogoContainer>
                    <VerticalGroup verticalCenter spaceBetweenElements={2}>
                      <VerticalGroup spaceBetweenElements={1}>
                        <Text bold>Market View Heading 1 </Text>
                        <InlineGroup verticalTop spaceBetweenElements={2}>
                          <EditableText
                            type="text"
                            ref={marketView1HeadingRef}
                            enableRightClickMenu
                            value={
                              roundData.newsConfig.marketView1Heading || ""
                            }
                            onChange={(newUpdate) =>
                              handleNewsChange("marketView1Heading", newUpdate)
                            }
                          />{" "}
                          <Clickable
                            onClick={() =>
                              marketView1HeadingRef.current?.setIsEditing(true)
                            }
                          >
                            <Icon
                              noMargin
                              type="edit"
                              tip={{
                                content: "marketView1Heading",
                                id: "marketView1Heading",
                              }}
                            />
                          </Clickable>
                        </InlineGroup>
                      </VerticalGroup>
                    </VerticalGroup>
                  </InlineGroup>
                  <InlineGroup verticalCenter spaceBetweenElements={3}>
                    <LogoContainer colour={"yellow"} size={3}>
                      <Clickable
                        onClick={() => {
                          setShowScenario2LogoModal(true);
                        }}
                      >
                        <Icon
                          stroke="white"
                          size={12}
                          noMargin
                          type={
                            LOGO_TO_ICON_MAP[
                              roundData.newsConfig.marketView2Logo
                            ]
                          }
                        />
                      </Clickable>
                    </LogoContainer>
                    <VerticalGroup verticalCenter spaceBetweenElements={2}>
                      <VerticalGroup spaceBetweenElements={1}>
                        <Text bold>Market View Heading 2 </Text>
                        <InlineGroup verticalTop spaceBetweenElements={2}>
                          <EditableText
                            type="text"
                            ref={marketView2HeadingRef}
                            enableRightClickMenu
                            value={
                              roundData.newsConfig.marketView2Heading || ""
                            }
                            onChange={(newUpdate) =>
                              handleNewsChange("marketView2Heading", newUpdate)
                            }
                          />{" "}
                          <Clickable
                            onClick={() =>
                              marketView2HeadingRef.current?.setIsEditing(true)
                            }
                          >
                            <Icon
                              noMargin
                              type="edit"
                              tip={{
                                content: "marketView2Heading",
                                id: "marketView2Heading",
                              }}
                            />
                          </Clickable>
                        </InlineGroup>
                      </VerticalGroup>
                    </VerticalGroup>
                  </InlineGroup>
                </VerticalGroup>
              </Card>
            </VerticalGroup>
            <InlineGroup block reverse stretch>
              <Button light large wide onClick={onClose}>
                Close
              </Button>
            </InlineGroup>
          </VerticalGroup>
        </VerticalGroup>
        {showScenario1LogoModal && (
          <LogoModal
            isOpen={showScenario1LogoModal}
            onClose={() => setShowScenario1LogoModal(false)}
            selectedLogo={scenario1Logo || "bull"}
            onLogoUpdate={(newLogo) => handleLogoUpdate(1, newLogo)}
          />
        )}
        {showScenario2LogoModal && (
          <LogoModal
            isOpen={showScenario2LogoModal}
            onClose={() => setShowScenario2LogoModal(false)}
            selectedLogo={scenario2Logo || "bear"}
            onLogoUpdate={(newLogo) => handleLogoUpdate(2, newLogo)}
          />
        )}
      </Container>
    </SimpleModal>
  );
};

export default EditNewsConfigModal;
