import React, { ReactNode } from "react";

interface ScreenOnlyProps {
  children: ReactNode;
}

const ScreenOnly: React.FC<ScreenOnlyProps> = ({ children }) => {
  return <div className="screen-only">{children}</div>;
};

export default ScreenOnly;
