import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { routeConfig } from "../../../Router";
import { useUserActions } from "../../../context/userContext";
import SidebarItem, { ISidebarItem } from "./SidebarItem";
import "./Sidebar.scss";
import Button from "../../atoms/button/Button";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";

function SideBar() {
  const location = useLocation();
  const lastActiveIndexString = localStorage.getItem("lastActiveIndex");
  const lastActiveIndex = Number(lastActiveIndexString);
  const [activeIndex, setActiveIndex] = useState(
    lastActiveIndex !== null ? lastActiveIndex : 1,
  );
  const { logout } = useUserActions();

  function changeActiveIndex(newIndex: number) {
    localStorage.setItem("lastActiveIndex", `${newIndex}`);
    setActiveIndex(newIndex);
  }

  function getPath(path: string) {
    if (path.charAt(0) !== "/") {
      return "/" + path;
    }
    return path;
  }

  const items: ISidebarItem[] = useMemo(() => {
    const result = [];

    for (const route of routeConfig) {
      let possibleParentIndex = -1;
      if (route.sidebar) {
        const isParent = !!route.routes?.length;
        let path = route.path;
        if (isParent) {
          const firstChildSidebarRoute = route.routes!.find((r) => !!r.sidebar);
          if (firstChildSidebarRoute) {
            path = firstChildSidebarRoute.path;
          }
        }
        result.push({
          path,
          ...route.sidebar,
          isNested: false,
          isParent,
          requiredRoles: route.requiredRoles,
        });
        possibleParentIndex = result.length - 1;
      }
      if (route.routes) {
        for (const nestedRoute of route.routes) {
          if (nestedRoute.sidebar) {
            result.push({
              path: nestedRoute.path,
              ...nestedRoute.sidebar,
              isNested: possibleParentIndex !== -1,
              isParent: false,
              parentIndex: possibleParentIndex,
              requiredRoles: nestedRoute.requiredRoles,
            });
          }
        }
      }
    }

    return result;
  }, []);

  useEffect(() => {
    const activeItem = items.findIndex(
      (item) =>
        !item.isParent && getPath(item.path) === getPath(location.pathname),
    );
    if (activeItem !== -1) changeActiveIndex(activeItem);
    else if (location.pathname === "/") changeActiveIndex(-1);
  }, [location, items]);

  const activeItem = items[activeIndex];

  return (
    <div className="sidebar">
      <div className="container">
        <div>
          {items.map(
            (item, index) =>
              (!item.isNested ||
                item.parentIndex === activeItem?.parentIndex) && (
                <SidebarItem
                  key={index}
                  active={
                    index === activeIndex || index === activeItem?.parentIndex
                  }
                  indent={item.isNested}
                  item={item}
                />
              ),
          )}
        </div>
        <InlineGroup>
          <Button noUi className="logout ml-6" onClick={logout}>
            Log Out
          </Button>
        </InlineGroup>
      </div>
      <div className="skeleton" />
    </div>
  );
}

export default SideBar;
