import React, { useCallback, useEffect, useState } from "react";
import { useDealConfig } from "../../../../hooks/useDealConfig";
import api from "../../../../services/api";
import Banner from "../../../atoms/banner/Banner";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import DealRoomSelect from "../../simulations/steps/dealroom/DealRoomSelect";
import SimulationsThatNeedManualModificationModal from "./components/SimulationsThatNeedManualModificationModal";

function DealRoom() {
  const { data: deals, inProgress, error } = useDealConfig();

  const [dealsData, setDealsData] = useState<API.DealConfigResponse[] | null>(
    null,
  );

  useEffect(() => {
    if (deals) {
      setDealsData(deals);
    }
  }, [deals]);

  const [
    simulationsThatNeedManualModification,
    setSimulationsThatNeedManualModification,
  ] = useState<API.SimulationsThatNeedManualModification[] | null>(null);

  const handleDealRoomUpdate = useCallback(
    async (dealId: string, data: Partial<API.DealConfigResponse>) => {
      const response = await api.updateDealConfig(dealId, data);
      setDealsData((prev) => {
        if (!prev) {
          return prev;
        }
        return prev.map((deal) => {
          if (deal.id === dealId) {
            return { ...deal, ...data };
          }
          return deal;
        });
      });
      if (response.simulationsThatNeedManualModification?.length > 0) {
        setSimulationsThatNeedManualModification(
          response.simulationsThatNeedManualModification,
        );
      }
    },
    [],
  );

  return (
    <>
      <h3>Deal Room Settings</h3>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {dealsData && (
        <DealRoomSelect
          onUpdateContent={handleDealRoomUpdate}
          deals={dealsData}
        />
      )}
      {simulationsThatNeedManualModification &&
        simulationsThatNeedManualModification.length > 0 && (
          <SimulationsThatNeedManualModificationModal
            simulationsThatNeedManualModification={
              simulationsThatNeedManualModification
            }
            onClose={() => setSimulationsThatNeedManualModification(null)}
            stepTitle="Deal Room"
          />
        )}
    </>
  );
}

export default DealRoom;
