import React from "react";
import IntegerDropdown from "../../../organisms/integer-dropdown/IntegerDropdown";
import { useResultsContext } from "../context/context";
import { FontSizeMultiplier } from "../results-text/ResultsText";

interface Props {
  label?: string;
  menuPlacement?: "top" | "bottom";
}

const ResultsFontMutiplierDropdown: React.FC<Props> = ({
  label,
  menuPlacement,
}) => {
  const context = useResultsContext();

  const onChange = (option: { value: FontSizeMultiplier }) => {
    context.updateFontMultiplier(option.value);
  };

  return (
    <IntegerDropdown
      decimalPlaces={1}
      label={label}
      value={context.fontMultiplier}
      onChange={onChange}
      min={1}
      max={5}
      step={0.5}
      menuPlacement={menuPlacement}
      tiny
      labelFormatter={(val) => `${val}x`}
    />
  );
};

export default ResultsFontMutiplierDropdown;
