import React from "react";
import classNames from "classnames";
import Icon from "../../atoms/icon/Icon";
import { useDropzone } from "react-dropzone";

import "./FileDropZone.scss";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";

interface Props {
  className?: string;
  onDrop: (acceptedFiles: Array<File>) => void;
  accept?: string;
  dropzoneText?: string;
  dropzonePlaceholderMain?: string;
  dropzonePlaceholderSecondary?: string;
  required?: boolean;
  isLoading?: boolean;
  fullHeight?: boolean;
}

export const FileDropZone: React.FC<Props> = ({
  className,
  onDrop,
  accept,
  dropzoneText,
  dropzonePlaceholderMain,
  dropzonePlaceholderSecondary,
  required = true,
  isLoading = false,
  fullHeight = false,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: accept,
  });

  if (isLoading) {
    return (
      <div
        className={classNames("dropzone", className, {
          errorActive: "red-border-error",
        })}
      >
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <InlineGroup
      fullHeight={fullHeight}
      center
      verticalCenter
      {...getRootProps({
        className: classNames("dropzone", className, {
          errorActive: "red-border-error",
        }),
      })}
    >
      <input {...getInputProps()} data-test="dropzone" required={required} />
      <VerticalGroup wide center verticalCenter>
        <InlineGroup verticalCenter block center className="upload-text-item">
          <Icon type="upload" className="upload-icon" />
          {!dropzoneText && (
            <div>{dropzonePlaceholderMain || "Upload files here"}</div>
          )}
        </InlineGroup>
        {!dropzoneText && (
          <div className="upload-text-secondary">
            {dropzonePlaceholderSecondary || ""}
          </div>
        )}
      </VerticalGroup>
      {!!dropzoneText && <div className="uploaded-file">{dropzoneText}</div>}
    </InlineGroup>
  );
};
