import React, { useCallback, useEffect, useState } from "react";
import { useMemoRequest } from "../../../../hooks/useMemoRequest";
import api from "../../../../services/api";
import Banner from "../../../atoms/banner/Banner";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import BusinessLabels from "../../simulations/steps/labels/BusinessLabels";
import SimulationsThatNeedManualModificationModal from "./components/SimulationsThatNeedManualModificationModal";

function Business() {
  const [
    simulationsThatNeedManualModification,
    setSimulationsThatNeedManualModification,
  ] = useState<API.SimulationsThatNeedManualModification[] | null>(null);

  const getBusinesses = useCallback(async () => {
    return await api.getBusinessConfig();
  }, []);

  const { data: businesses, inProgress, error } = useMemoRequest(getBusinesses);

  const [businessesData, setBusinessesData] = useState<
    API.BusinessConfigResponse[] | null
  >(null);
  useEffect(() => {
    if (businesses) {
      setBusinessesData(businesses);
    }
  }, [businesses]);

  const handleBusinessUpdate = useCallback(
    async (businessId: string, data: Partial<API.BusinessConfigResponse>) => {
      const response = await api.updateBusinessConfig(businessId, data);
      setBusinessesData((prev) => {
        if (!prev) {
          return prev;
        }
        return prev.map((business) => {
          if (business.id === businessId) {
            return { ...business, ...data };
          }
          return business;
        });
      });
      if (response.simulationsThatNeedManualModification.length > 0) {
        setSimulationsThatNeedManualModification(
          response.simulationsThatNeedManualModification,
        );
      }
    },
    [],
  );

  return (
    <>
      <h3>Business Settings</h3>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {businessesData && (
        <BusinessLabels
          businesses={businessesData}
          onUpdateContent={handleBusinessUpdate}
        />
      )}
      {simulationsThatNeedManualModification &&
        simulationsThatNeedManualModification.length > 0 && (
          <SimulationsThatNeedManualModificationModal
            simulationsThatNeedManualModification={
              simulationsThatNeedManualModification
            }
            onClose={() => setSimulationsThatNeedManualModification(null)}
            stepTitle="Labels"
          />
        )}
    </>
  );
}

export default Business;
