import React, { useCallback, useMemo } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import { TeamsWithColours } from "../RoundResultsPage";
import ResultsTeamLineChart from "../charts/ResultsTeamLineChart";
import { getYAxisDataV2 } from "../charts/yaxis";
import ChartCard from "../components/ChartCard";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import { createLineChartDot } from "../charts/LineChartDot";
import useChartStyles from "../../../../hooks/useChartStyles";
import { thousandSeparator } from "../../model/utils/formatters";
import { getDotColour } from "../utils/getDotColour";
import { useResultsContext } from "../context/context";

interface Props {
  pastRound1: boolean;
  teams: TeamsWithColours[];
  businessLoanLosses: ModelAPI.Reporting.DataAndPositions;
  creditSettings: ModelAPI.Reporting.RoundData[];
  currencyDenomination: string;
}
// const MaxClicks=1
const RoundResultsBusinessLoanLosses: React.FC<Props> = ({
  pastRound1,
  teams,
  businessLoanLosses,
  creditSettings,
  currencyDenomination,
}) => {
  const { minValue, maxValue, numberOfTicks } = getYAxisDataV2({
    pastRound1,
    data: businessLoanLosses.data,
    // padding: 1,
    // min: 0
  });

  const chartStyles = useChartStyles();

  const context = useResultsContext();
  // const [showLines, setShowLines] = useState(!context.allowPresentationMode);
  const teamsWithDot = teams
    .filter((t) => !t.isCpuTeam)
    .map((t) => ({
      ...t,
      dot: createLineChartDot(
        chartStyles.circleRadius,
        chartStyles.strokeWidth,
        teams,
        getDotColour(creditSettings ?? []),
      ),
    }));

  const maxClicks = useMemo(
    () => (context.allowPresentationMode || context.readSyncState ? 1 : 0),
    [context.allowPresentationMode, context.readSyncState],
  );
  const onNext = useCallback(() => {
    if (context.clicks < maxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context, maxClicks]);

  return (
    <RoundResultsContainer onNext={onNext}>
      <VerticalGroup full center wide>
        <ChartCard
          currencyDenomination={currencyDenomination}
          heading="Business Loan Losses"
        >
          <ResultsTeamLineChart
            data={businessLoanLosses.data}
            teams={teamsWithDot}
            formatter={(val: number) => `${thousandSeparator(val)}m`}
            minYAxisvalue={minValue}
            maxYAxisvalue={maxValue}
            numberOfTicks={numberOfTicks}
            teamPositions={businessLoanLosses.teamPositions}
            tooltipOrdering="highest"
            shouldRenderLines={context.clicks === maxClicks}
          />
        </ChartCard>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsBusinessLoanLosses;
