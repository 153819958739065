export const getParticipantLoginLink = (
  event: API.EventResponse,
  teamId: number,
) => {
  const teamParticipants = event.participants.filter(
    (p: any) => p.team === teamId,
  );

  if (teamParticipants.length === 0) {
    throw new Error("There are no players in this team.");
  }

  const ceoParticipant = teamParticipants.find((p: any) => p.isCeo);

  if (ceoParticipant) {
    return `${process.env.REACT_APP_GAME_URL}?eventId=${encodeURIComponent(
      event.id,
    )}&participantId=${encodeURIComponent(
      ceoParticipant.id,
    )}&email=${encodeURIComponent(ceoParticipant.email)}`;
  }

  const firstParticipant = teamParticipants[0];

  return `${process.env.REACT_APP_GAME_URL}?eventId=${encodeURIComponent(
    event.id,
  )}&participantId=${encodeURIComponent(
    firstParticipant.id,
  )}&email=${encodeURIComponent(firstParticipant.email)}`;
};
