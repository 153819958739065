import React from "react";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import Clickable from "../../../../atoms/clickable/Clickable";
import Icon from "../../../../atoms/icon/Icon";
import EditableText from "../../../../atoms/text/EditableText";
import ParseHtml from "../../components/ParseHtmlIntoResultsTest";
import Image from "../../../../atoms/image/Image";
import Video from "../../../../atoms/video/Video";
import { FileDropZone } from "../../../../organisms/file-dropzone/FileDropZone";
import Text from "../../../../atoms/text/Text";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import { useResultsContext } from "../../context/context";

interface RowSectionProps {
  rowIndex: number;
  items: API.ReportingLayoutCustomSlideRowItem[];
  onAddItem: (rowIndex: number, itemIndex: number) => void;
  onEditItem: (
    rowIndex: number,
    itemIndex: number,
    newItem: API.ReportingLayoutCustomSlideRowItem,
  ) => void;
  onDeleteItem: (rowIndex: number, itemIndex: number) => void;
  onEditMedia: (
    index: number,
    type: "image" | "video",
    rowItemIndex?: number,
  ) => void;
  onDrop: (files: File[], index: number, itemIndex: number) => void;
  handleVideoUpload: (files: File[], index: number, itemIndex: number) => void;
}

const RowSection: React.FC<RowSectionProps> = ({
  rowIndex,
  items,
  onAddItem,
  onEditItem,
  onDeleteItem,
  onEditMedia,
  onDrop,
  handleVideoUpload,
}) => {
  const context = useResultsContext();

  const getAlignment = (
    item:
      | API.ReportingLayoutCustomSlideImageOption
      | API.ReportingLayoutCustomSlideVideoOption,
  ) => {
    return item.alignment || "left";
  };

  return (
    <InlineGroup className="section-content">
      {items.map((item, index) => (
        <div
          key={index}
          className="row-item"
          style={{
            flex: `1 1 ${100 / items.length}%`,
          }}
        >
          <div className="item-content">
            {context.allowCustomTemplateEdits && (
              <div className="delete-tab">
                <VerticalGroup>
                  <Clickable
                    onClick={() => onDeleteItem(rowIndex, index)}
                    className="action-button"
                  >
                    <Icon type="trash" size={8} />
                  </Clickable>
                  {item.type === "image" && (
                    <Clickable
                      onClick={() => onEditMedia(rowIndex, "image", index)}
                      className="action-button"
                    >
                      <Icon type="editImage" size={8} />
                    </Clickable>
                  )}
                  {item.type === "video" && (
                    <Clickable
                      onClick={() => onEditMedia(rowIndex, "video", index)}
                      className="action-button"
                    >
                      <Icon type="editVideo" size={8} />
                    </Clickable>
                  )}
                </VerticalGroup>
              </div>
            )}

            {item.type === "text" && item.value === "" ? (
              <Clickable
                style={{ minHeight: "40px" }}
                onClick={() => onAddItem(rowIndex, index)}
                className="action-button"
              >
                {context.allowCustomTemplateEdits && (
                  <div>
                    <Text>Add Content</Text>
                    <Icon type="plus" size={8} />
                  </div>
                )}
              </Clickable>
            ) : item.type === "text" ? (
              context.allowCustomTemplateEdits ? (
                <div className="image-container" style={{ width: "100%" }}>
                  <EditableText
                    key={`editable-text-${index}`}
                    clickableStyle={{ width: "100%" }}
                    value={item.value}
                    type="richText"
                    onChange={(val: string) =>
                      onEditItem(rowIndex, index, { ...item, value: val })
                    }
                  />
                </div>
              ) : (
                <ParseHtml html={item.value} />
              )
            ) : item.type === "image" ? (
              <InlineGroup fullHeight block>
                {item.imageUrl ? (
                  <InlineGroup
                    block
                    center={getAlignment(item) === "center"}
                    right={getAlignment(item) === "right"}
                  >
                    <Image
                      width={`${item.width}${item.unit || "px"}`}
                      height={`${item.height}${item.unit || "px"}`}
                      src={item.imageUrl}
                      alt="Custom Image"
                    />
                  </InlineGroup>
                ) : (
                  context.allowCustomTemplateEdits && (
                    <FileDropZone
                      required={false}
                      className="width-100-percent"
                      onDrop={(files: any) => onDrop(files, rowIndex, index)}
                      accept="image/*"
                      dropzonePlaceholderMain="Drag your file here"
                      dropzonePlaceholderSecondary="or click to upload from your computer"
                    />
                  )
                )}
              </InlineGroup>
            ) : item.type === "video" ? (
              <InlineGroup fullHeight block>
                {item.videoUrl ? (
                  <InlineGroup
                    fullHeight
                    block
                    center={getAlignment(item) === "center"}
                    right={getAlignment(item) === "right"}
                  >
                    <Video
                      width={item.width || 600}
                      height={item.height || 500}
                      controls
                      src={item.videoUrl}
                    />
                  </InlineGroup>
                ) : (
                  context.allowCustomTemplateEdits && (
                    <FileDropZone
                      required={false}
                      className="width-100-percent"
                      onDrop={(files) =>
                        handleVideoUpload(files, rowIndex, index)
                      }
                      accept="video/*"
                      dropzonePlaceholderMain="Drag your video here"
                      dropzonePlaceholderSecondary="or click to upload from your computer"
                    />
                  )
                )}
              </InlineGroup>
            ) : null}
          </div>
        </div>
      ))}
    </InlineGroup>
  );
};

export default RowSection;
