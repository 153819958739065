import React, { useCallback } from "react";
import ChallengeModal from "../../../organisms/challenge-modal/ChallengeModal";

import useAPIRequest from "../../../../hooks/useAPIRequest";
import API from "../../../../services/api";
import ConfirmModal from "../../../organisms/confirm-modal/ConfirmModal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  client: API.ClientResponse;
}

function DeleteClient({ isOpen, onComplete, onClose, client }: Props) {
  const callback = useCallback(() => API.deleteClient(client.id), [client]);

  const [{ inProgress, error }, doAPIRequest] = useAPIRequest(callback, null);

  const handleConfirm = () => {
    doAPIRequest(onComplete);
  };

  if (client.openEventsCount > 0) {
    return (
      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onClose}
        title="Close client events"
        description="This client cannot be deleted until all open events are either closed or deleted."
        confirmTitle={"Ok"}
      />
    );
  }

  return (
    <ChallengeModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      challengeQuestion="This action is irreversible and will delete all events and data associated with this client. Please type the word DELETE below to proceed."
      challengeAnswer="DELETE"
      challengeAnswerWarning="Please type the word DELETE in CAPS to delete the client."
      title={`Delete client: ${client.name}?`}
      confirmTitle="Delete"
      error={error && error.message}
      warning
    />
  );
}

export default DeleteClient;
