import React, { useCallback } from "react";
import ReportingTemplateForm from "../ReportingTemplateForm";
import API from "../../../../services/api";
import Container from "../../../atoms/page/Page";
import { useHistory } from "react-router-dom";

function ReportingTemplateCreate() {
  const onSave = useCallback((data: API.ReportingTemplateRequest) => {
    return API.createTemplate("reporting", data);
  }, []);

  const history = useHistory();

  const onCancel = useCallback(() => {
    history.push("/reporting-template");
  }, [history]);

  const onComplete = useCallback(
    (data) => {
      history.push(`/reporting-templates/${data.id}/view`);
    },
    [history],
  );

  return (
    <Container>
      <ReportingTemplateForm
        onSave={onSave}
        onCancel={onCancel}
        onComplete={onComplete}
        mode="create"
      />
    </Container>
  );
}

export default ReportingTemplateCreate;
