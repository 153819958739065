import React, { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import classNames from "classnames";

import SimpleModal from "../../../../organisms/standard-modal/SimpleModal";
import Image from "../../../../atoms/image/Image";
import api from "../../../../../services/api";
import LoadingSpinner from "../../../../atoms/loadingspinner/LoadingSpinner";
import Text from "../../../../atoms/text/Text";

interface Props {
  helpManualId: string;
}

const HelpManualPreview: React.FC<Props> = (props) => {
  const { helpManualId } = props;
  const [isModalOpen, setModalStatus] = useState<boolean>(false);
  const [helpManual, setHelpManual] = useState<API.HelpManualResponse | null>(
    null,
  );

  useEffect(() => {
    const getHelpManual = async () => {
      const helpManualResponse = await api.getHelpManual(helpManualId);
      setHelpManual(helpManualResponse);
    };
    getHelpManual();
  }, [helpManualId]);

  const images = helpManual?.images.map((image) => ({
    original: image.imageUrl,
    thumbnail: image.imageUrl,
  }));
  const firstImage = images && images[0];

  return (
    <>
      {isModalOpen && (
        <SimpleModal
          size="medium"
          isMinimal
          showCloseButton
          isOpen={isModalOpen}
          onClose={() => setModalStatus(false)}
        >
          <ImageGallery showPlayButton={false} items={images ?? []} />
        </SimpleModal>
      )}
      {helpManual ? (
        <>
          <Text>{helpManual.name}</Text>
          <Image
            maxWidth="998px"
            contain
            alt={helpManual.name}
            src={firstImage?.thumbnail}
            className={classNames("help-manual-preview", "image")}
            onClick={() => setModalStatus(true)}
          />
        </>
      ) : (
        <LoadingSpinner size="1.3rem" />
      )}
    </>
  );
};

export default HelpManualPreview;
