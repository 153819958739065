import React from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import ClickableText from "../../../../atoms/text/ClickableText";
import openNewTab from "../../../../../lib/openNewTab";
import ConfirmModal from "../../../../organisms/confirm-modal/ConfirmModal";

interface Props {
  templateType: "intro" | "reporting";
  templatesThatNeedManualModification: API.ReportingTemplatesThatNeedManualModification[];
  onClose: () => void;
}

const ReportingTemplatesThatNeedManualModificationModal: React.FC<Props> = ({
  templateType,
  templatesThatNeedManualModification,
  onClose,
}) => {
  return (
    <ConfirmModal
      isOpen
      onClose={onClose}
      onConfirm={onClose}
      onCancel={onClose}
      confirmTitle="OK"
      title={"Templates That Need Manual Modification"}
      description={
        <VerticalGroup spaceBetweenElements={4}>
          The following templates could not be updated as the text has diverged
          from the base config.
          <br />
          Please manually update the text in the following templates
          <VerticalGroup className="mt-4">
            {templatesThatNeedManualModification.map((template, index) => (
              <ClickableText
                key={index}
                onClick={() => {
                  openNewTab(`/${templateType}-templates/${template.id}/view`);
                }}
              >
                {template.name}
              </ClickableText>
            ))}
          </VerticalGroup>
        </VerticalGroup>
      }
    />
  );
};

export default ReportingTemplatesThatNeedManualModificationModal;
