import React from "react";

import Form from "../../atoms/form/Form";
import Text from "../../atoms/text/Text";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import { Accordion } from "../accordion/accordion";
import EmptyList from "../empty-list/EmptyList";
import { SetCurrentAssessment } from "../../../context/AssessmentContext";
import AssessmentPreviewIframe from "../assessment-preview-iframe/AssessmentPreviewIframe";
import { buildUrl } from "../assessment-preview-iframe/AssessmentPreviewIframe";
import Button from "../../atoms/button/Button";

interface Props {
  assessment: API.AssessmentResponse;
}

const maxContainerHeight = 470;
const AssessmentPreviewWithData: React.FC<Props> = ({ assessment: data }) => {
  return (
    <SetCurrentAssessment assessment={data}>
      <Form>
        <Text size="lg" className="mb-4 bt-4">
          {data.name}
        </Text>
        <VerticalGroup full wide spaceBetweenElements={4}>
          {data.groups.map((group) => {
            const viewInTabUrl = buildUrl({
              assessmentId: data.id,
              groupId: group.id,
              maxContainerHeight,
            });
            const onViewInTabClick = () => window.open(viewInTabUrl, "_blank");

            return (
              <Accordion
                actions={
                  <Button onClick={onViewInTabClick}>Open In New Tab</Button>
                }
                defaultState="expand"
                wide
                title={group.name}
                key={group.id}
              >
                {group.questions && group.questions.length === 0 && (
                  <EmptyList message="No questions in this group" icon="risk" />
                )}
                {group.questions && group.questions?.length > 0 ? (
                  <AssessmentPreviewIframe
                    assessmentId={data.id}
                    groupId={group.id}
                    maxContainerHeight={maxContainerHeight}
                  />
                ) : null}
              </Accordion>
            );
          })}
        </VerticalGroup>
      </Form>
    </SetCurrentAssessment>
  );
};

export default AssessmentPreviewWithData;
