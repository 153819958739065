import React from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../../tables/TableWithSections";
import { formatLossMultiplier, percentage } from "../../utils/formatters";
import pluckResultsByType from "../../utils/pluckResultsByType";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["wholesaleFundingCosts"];
  fields: Array<{ field: string; name: string }>;
}

const fieldMap: { [key: string]: string } = {
  centralBankFacility: "CBF",
  wsf1: "1 Year",
  wsf2: "2 Year",
  wsf3: "3 Year",
  wsf4: "4 Year",
  wsf5: "5 Year",
};

const formatter = (
  val: number,
  section: string | undefined,
  field: string,
): string | null => {
  if (field === "creditRating") {
    return val.toString();
  }
  if (field === "creditRatingImpact") {
    return formatLossMultiplier(val);
  }
  return percentage(val);
};

const InterestAndSpread: React.FC<Props> = ({ data }) => {
  const values = pluckResultsByType(data, "interestAndSpread");

  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <TableWithSections
          hasRoundZero
          header="Interest Rate / Spread Calcs"
          data={[
            {
              fields: [
                "creditRating",
                "wholesaleFundingReferenceRate",
                "coreCreditSpread",
                "creditRatingImpact",
              ],
              values,
              formatter: (val: number, field: string) => {
                if (field === "creditRatingImpact") {
                  if (val === 0) {
                    // this is to handle round 0
                    return "-";
                  }
                }
                return formatter(val, "", field);
              },
            },
            {
              name: "Term Premiums",
              fields: ["wsf1", "wsf2", "wsf3", "wsf4", "wsf5"],
              values: pluckResultsByType(values, "termPremiums"),
              fieldFormatter: (field: string) => fieldMap[field],
            },
            {
              name: "Term Spread By Tenor",
              fields: [
                "centralBankFacility",
                "wsf1",
                "wsf2",
                "wsf3",
                "wsf4",
                "wsf5",
              ],
              values: pluckResultsByType(values, "termSpreadByTenor"),
              fieldFormatter: (field: string) => fieldMap[field],
            },
            {
              fields: ["largeIssuePremium"],
              values,
            },
            {
              name: "Term Spread Incl Large Debt",
              fields: [
                "centralBankFacility",
                "wsf1",
                "wsf2",
                "wsf3",
                "wsf4",
                "wsf5",
              ],
              values: pluckResultsByType(
                values,
                "termSpreadsIncludingLargeDebt",
              ),
              fieldFormatter: (field: string) => fieldMap[field],
            },
          ]}
          formatter={formatter}
        />
      </VerticalGroup>
    </>
  );
};

export default InterestAndSpread;
