import React, { useMemo } from "react";
import { useModelContext } from "../../context";
import TableWithSections, {
  Props as TableWithSectionsProps,
} from "../../tables/TableWithSections";
import { formatValue } from "../../utils/formatters";
import getTeamIdsAsFields from "../../utils/getTeamIdsAsFields";
import getTeamName from "../../utils/getTeamName";

interface Props extends TableWithSectionsProps {
  data: any[];
  header: string;
  onClick?: (field: string) => void;
}

const hasZeroRound = ({
  data,
  configuration,
}: {
  data: any[];
  configuration: ModelAPI.ConfigurationResponse;
}) => {
  return (
    data.length + 1 ===
    configuration?.numberOfRounds +
      configuration?.numberOfHistoricRounds +
      configuration?.numberOfPostGameRounds
  );
};

interface ModelVarianceTableProps extends Props {
  fields: string[];
}

export const ModelVarianceTable: React.FC<ModelVarianceTableProps> = ({
  data,
  header,
  fields,
  onClick,
  helpTitle,
  helpDescription,
}) => {
  const { config } = useModelContext();
  if (!config) {
    throw new Error("Config not loaded yet");
  }
  const isZeroRound = hasZeroRound({ data, configuration: config });
  return (
    <TableWithSections
      header={header}
      hasRoundZero={isZeroRound}
      fakeRoundZero={!isZeroRound}
      helpTitle={helpTitle}
      helpDescription={helpDescription}
      highlightIfNotZero
      onClick={onClick}
      collapseHeaders={data.map((msv: any) => formatValue(msv.biggest))}
      data={[
        {
          fields,
          values: data,
        },
      ]}
    />
  );
};

export const ModelVarianceTeamsTable: React.FC<Props> = ({
  data,
  header,
  onClick,
  helpTitle,
  helpDescription,
}) => {
  const { config, gameStatus } = useModelContext();
  if (!config || !gameStatus) {
    throw new Error("Config not loaded yet");
  }
  const { numberOfTeams, numberOfCpuTeams } = config;

  const totalNumberOfTeams = numberOfTeams + numberOfCpuTeams;
  const teamFields = useMemo(
    () => getTeamIdsAsFields(totalNumberOfTeams),
    [totalNumberOfTeams],
  );

  return (
    <TableWithSections
      header={header}
      hasRoundZero
      highlightIfNotZero
      onClick={onClick}
      helpTitle={helpTitle ?? header}
      helpDescription={helpDescription}
      collapseHeaders={data.map((msv: any) => formatValue(msv.biggest))}
      data={[
        {
          fields: teamFields,
          fieldFormatter: (field: string) =>
            teamFields.includes(field)
              ? getTeamName(
                  parseInt(field),
                  config.numberOfTeams,
                  gameStatus.teams,
                )
              : null,
          values: data,
        },
      ]}
    />
  );
};
