import React, { useCallback, useEffect, useMemo } from "react";
import API from "../../../../services/api";
import GameAPI from "../../../../services/gameApi";
import EventsTable, { EventsWithStatus } from "./EventsTable";
import useAPIRequest from "../../../../hooks/useAPIRequest";
import Container from "../../../atoms/page/Page";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import Banner from "../../../atoms/banner/Banner";
import { useMemoRequest } from "../../../../hooks/useMemoRequest";
import { mapEventsWithStatus } from "./mapEventsWithStatus";

function LoadTestEvents() {
  const getGameApiStatus = useCallback(() => {
    return GameAPI.getStatus();
  }, []);

  const callback = useCallback(() => {
    return API.getLoadTestEvents();
  }, []);

  const [{ inProgress, data, completed, error }, doAPIRequest] = useAPIRequest<
    API.EventResponseSummary[]
  >(callback, { data: [] });

  const { data: gameApiStatus } = useMemoRequest(getGameApiStatus);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  const handleRefreshEvent = useCallback(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  const events: EventsWithStatus[] | null = useMemo(() => {
    return mapEventsWithStatus(data, gameApiStatus);
  }, [data, gameApiStatus]);

  return (
    <Container>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {completed && events && (
        <EventsTable
          events={events}
          refreshEvents={handleRefreshEvent}
          showStatus
        />
      )}
    </Container>
  );
}

export default LoadTestEvents;
