import React, { useMemo, useRef } from "react";
import SimpleModal from "../../../../../organisms/standard-modal/SimpleModal";
import Container from "../../../../../atoms/page/Page";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import { dropDownVariables } from "./contextMenuVariables";
import Clickable from "../../../../../atoms/clickable/Clickable";
import Icon from "../../../../../atoms/icon/Icon";
import Text from "../../../../../atoms/text/Text";
import Card from "../../../../../atoms/card/Card";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import Button from "../../../../../atoms/button/Button";
import EditableText, {
  Handles as EditableTextHandles,
} from "../../../../../atoms/text/EditableText";
import InformationPopup from "../../../../../organisms/information-popup/InformationPopup";
import { EditingTextBody } from "./InfoPopUpText";
interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedRound: number;
  handleScenarioUpdated: (
    updatedScenario: API.EconomicScenarioUpdateRequest,
  ) => void;
  selectedScenario: API.EconomicScenarioResponse | null;
}

export const EditEconomyModal = ({
  isOpen,
  onClose,
  selectedRound,
  handleScenarioUpdated,
  selectedScenario,
}: Props) => {
  const mainSectionTextRef = useRef<EditableTextHandles>(null);
  const mainSectionSecondaryTextRef = useRef<EditableTextHandles>(null);
  const mainSectionSecondaryTextAuthorRef = useRef<EditableTextHandles>(null);
  const resultsMainHeadingRef = useRef<EditableTextHandles>(null);
  const resultsMainTextRef = useRef<EditableTextHandles>(null);
  const roundData = useMemo(() => {
    if (
      !selectedScenario ||
      !selectedScenario.news ||
      !selectedScenario.newsConfig
    ) {
      throw new Error("Invalid scenario");
    }

    const newsItem = selectedScenario.news.find(
      (news) => news.roundId === selectedRound,
    );

    if (!newsItem) {
      throw new Error("Invalid round");
    }
    return {
      newsItem,
      newsConfig: selectedScenario.newsConfig,
    };
  }, [selectedRound, selectedScenario]);

  const handleNewsChange = (fieldName: string, newValue: string) => {
    if (selectedScenario && selectedScenario.news) {
      const updatedNews = selectedScenario.news.map((newsItem) => {
        if (newsItem.roundId === selectedRound) {
          return { ...newsItem, [fieldName]: newValue };
        }
        return newsItem;
      });

      const updatedScenario = {
        ...selectedScenario,
        news: updatedNews,
      };

      handleScenarioUpdated(updatedScenario);
    }
  };
  return (
    <SimpleModal isOpen={isOpen} size="large" onClose={onClose}>
      <Container fit className="simulation-edit">
        <VerticalGroup spaceBetweenElements={5}>
          <InlineGroup verticalCenter spaceBetweenElements={2}>
            <Text size="xl" bold>
              Edit {roundData.newsConfig.mainSectionHeading} for Round{" "}
              {selectedRound}
            </Text>
            <InformationPopup title={"Editing Tip"} body={EditingTextBody} />
          </InlineGroup>
          <VerticalGroup spaceBetweenElements={3}>
            <Text size="xl" bold>
              In App Text
            </Text>
            <Card sharp>
              <VerticalGroup spaceBetweenElements={5}>
                <VerticalGroup spaceBetweenElements={2}>
                  <Text bold>{roundData.newsConfig.mainSectionTitle}</Text>
                  <InlineGroup verticalCenter spaceBetweenElements={2}>
                    <EditableText
                      lineHeight={6}
                      ref={mainSectionTextRef}
                      dropdownOptions={dropDownVariables}
                      enableRightClickMenu
                      value={roundData.newsItem.mainSectionText || ""}
                      onChange={(newUpdate) =>
                        handleNewsChange("mainSectionText", newUpdate)
                      }
                    />{" "}
                    <Clickable
                      onClick={() =>
                        mainSectionTextRef.current?.setIsEditing(true)
                      }
                    >
                      <Icon
                        noMargin
                        type="edit"
                        tip={{
                          content: "Main Section Text",
                          id: "mainSectionText",
                        }}
                      />
                    </Clickable>
                  </InlineGroup>
                </VerticalGroup>
                <VerticalGroup spaceBetweenElements={2}>
                  <Text bold>
                    {roundData.newsConfig.mainSectionSecondaryTitle}
                  </Text>
                  <InlineGroup verticalCenter spaceBetweenElements={2}>
                    <EditableText
                      ref={mainSectionSecondaryTextRef}
                      dropdownOptions={dropDownVariables}
                      enableRightClickMenu
                      value={roundData.newsItem.mainSectionSecondaryText || ""}
                      onChange={(newUpdate) =>
                        handleNewsChange("mainSectionSecondaryText", newUpdate)
                      }
                    />{" "}
                    <Clickable
                      onClick={() =>
                        mainSectionSecondaryTextRef.current?.setIsEditing(true)
                      }
                    >
                      <Icon
                        noMargin
                        type="edit"
                        tip={{
                          content: "Main Section Secondary Text",
                          id: "mainSectionSecondaryText",
                        }}
                      />
                    </Clickable>
                  </InlineGroup>
                </VerticalGroup>
                <VerticalGroup spaceBetweenElements={2}>
                  <Text bold>
                    {roundData.newsConfig.mainSectionSecondaryTitle} Author
                  </Text>
                  <InlineGroup verticalCenter spaceBetweenElements={2}>
                    <EditableText
                      type="text"
                      ref={mainSectionSecondaryTextAuthorRef}
                      dropdownOptions={dropDownVariables}
                      enableRightClickMenu
                      value={
                        roundData.newsItem.mainSectionSecondaryTextAuthor || ""
                      }
                      onChange={(newUpdate) =>
                        handleNewsChange(
                          "mainSectionSecondaryTextAuthor",
                          newUpdate,
                        )
                      }
                    />{" "}
                    <Clickable
                      onClick={() =>
                        mainSectionSecondaryTextAuthorRef.current?.setIsEditing(
                          true,
                        )
                      }
                    >
                      <Icon
                        noMargin
                        type="edit"
                        tip={{
                          content: "Main Section Secondary Text Author",
                          id: "mainSectionSecondaryTextAuthor",
                        }}
                      />
                    </Clickable>
                  </InlineGroup>
                </VerticalGroup>
              </VerticalGroup>
            </Card>
            <Text size="xl" bold>
              Results Text
            </Text>
            <Card wide sharp>
              <VerticalGroup spaceBetweenElements={2}>
                <VerticalGroup verticalCenter spaceBetweenElements={3}>
                  <VerticalGroup verticalCenter>
                    <Text bold>Results Heading</Text>
                    <InlineGroup verticalCenter spaceBetweenElements={2}>
                      <EditableText
                        type="text"
                        lineHeight={6}
                        ref={resultsMainHeadingRef}
                        dropdownOptions={dropDownVariables}
                        enableRightClickMenu
                        value={roundData.newsItem.resultsMainHeading || ""}
                        onChange={(newUpdate) =>
                          handleNewsChange("resultsMainHeading", newUpdate)
                        }
                      />{" "}
                      <Clickable
                        onClick={() =>
                          resultsMainHeadingRef.current?.setIsEditing(true)
                        }
                      >
                        <Icon
                          noMargin
                          type="edit"
                          tip={{
                            content: "resultsMainHeading",
                            id: "resultsMainHeading",
                          }}
                        />
                      </Clickable>
                    </InlineGroup>
                  </VerticalGroup>
                  <VerticalGroup verticalCenter>
                    <Text bold>Main Text</Text>
                    <InlineGroup verticalCenter spaceBetweenElements={2}>
                      <EditableText
                        lineHeight={6}
                        ref={resultsMainTextRef}
                        dropdownOptions={dropDownVariables}
                        enableRightClickMenu
                        value={roundData.newsItem.resultsMainText || ""}
                        onChange={(newUpdate) =>
                          handleNewsChange("resultsMainText", newUpdate)
                        }
                      />{" "}
                      <Clickable
                        onClick={() =>
                          resultsMainTextRef.current?.setIsEditing(true)
                        }
                      >
                        <Icon
                          noMargin
                          type="edit"
                          tip={{
                            content: "resultsMainText",
                            id: "resultsMainText",
                          }}
                        />
                      </Clickable>
                    </InlineGroup>
                  </VerticalGroup>
                </VerticalGroup>
              </VerticalGroup>
            </Card>
          </VerticalGroup>
          <InlineGroup block reverse stretch>
            <Button light large wide onClick={onClose}>
              Close
            </Button>
          </InlineGroup>
        </VerticalGroup>
      </Container>
    </SimpleModal>
  );
};

export default EditEconomyModal;
