import React, { useCallback, useEffect, useState } from "react";
import useForm from "../../../hooks/useForm";
import ErrorModal from "../../organisms/standard-modal/ErrorModal";
import StandardModal from "../../organisms/standard-modal/StandardModal";
import Input from "../../atoms/form/input/Input";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import Button from "../../atoms/button/Button";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import useIsMobile from "../../../hooks/useIsMobile";
import Dropdown from "../../atoms/form/input/Dropdown";

interface Props {
  modalTitle: string;
  modalMessage: string;
  isOpen: boolean;
  error?: string | null;
  onClose: () => void;
  onSave: (message: API.BroadcastMessageRequest | API.BroadcastMessage) => void;
  onDelete?: () => void;
  broadcastMessage: API.GameStatus["broadcastMessage"] | null;
  preSavedMessages?: API.BroadcastMessage[] | null;
  confirmButtonText?: string;
}

export default function BroadcastMessageModal(props: Props) {
  const isMobile = useIsMobile();
  const [selectedPresavedMessage, setSelectedPresavedMessage] =
    useState<API.BroadcastMessage | null>(null);
  const {
    modalTitle,
    modalMessage,
    isOpen,
    onClose,
    onSave,
    onDelete,
    broadcastMessage,
    preSavedMessages,
    confirmButtonText = "Submit",
  } = props;
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    if (props.error && !error) {
      setError(props.error);
    }
  }, [error, props.error]);
  const [
    {
      formData: { title, message, link, linkCTA },
      fieldErrors,
    },
    { setField, handleSubmit, setFieldRaw },
  ] = useForm(
    broadcastMessage
      ? broadcastMessage
      : {
          title: "",
          message: "",
          link: null,
          linkCTA: null,
        },
  );

  const callback = useCallback(async () => {
    onSave({ title, message, link, linkCTA });
    setFieldRaw("title", "");
    setFieldRaw("message", "");
    setFieldRaw("link", null);
    setFieldRaw("linkCTA", null);
    onClose();
  }, [onSave, title, message, link, linkCTA, setFieldRaw, onClose]);

  const removeBroadcastMessage = useCallback(async () => {
    if (onDelete) {
      onDelete();
      setFieldRaw("title", "");
      setFieldRaw("message", "");
      setFieldRaw("link", null);
      setFieldRaw("linkCTA", null);
      onClose();
    }
  }, [onClose, onDelete, setFieldRaw]);

  const validate = useCallback(() => {
    const errors = [];

    if (!title) {
      errors.push({
        field: "title",
        message: "Title is required",
      });
    }

    if (!message) {
      errors.push({
        field: "message",
        message: "Message is required",
      });
    }

    return errors;
  }, [message, title]);

  if (error) {
    return (
      <ErrorModal isOpen onClose={() => setError(null)} description={error} />
    );
  }

  return (
    <StandardModal
      isOpen={isOpen}
      onClose={onClose}
      title={modalTitle}
      width={isMobile ? "90%" : "30%"}
      description={modalMessage}
    >
      <>
        <form
          onSubmit={handleSubmit(callback, validate)}
          id="broadcast-modal-form"
          className="mt-2"
        >
          <VerticalGroup spaceBetweenElements={2} wide>
            {preSavedMessages && preSavedMessages.length > 0 && (
              <Dropdown
                block
                isVerticalLayout
                label="Select a pre-saved message"
                selectProps={{
                  options: preSavedMessages.map((message) => ({
                    value: message.id,
                    label: message.title,
                  })),
                  defaultValue: selectedPresavedMessage?.id,
                  onChange: (value: any) => {
                    const selectedMessage = preSavedMessages.find(
                      (message) => message.id === value.value,
                    );
                    if (selectedMessage) {
                      setSelectedPresavedMessage(selectedMessage);
                      setFieldRaw("title", selectedMessage.title);
                      setFieldRaw("message", selectedMessage.message);
                      setFieldRaw("link", selectedMessage.link);
                      setFieldRaw("linkCTA", selectedMessage.linkCTA);
                    }
                  },
                  classNamePrefix: "presaved-messages-dropdown",
                }}
              />
            )}
            <Input
              type="text"
              value={title}
              onChange={setField("title")}
              error={fieldErrors.title}
              block
              autoFocus
              label="Title"
              isVerticalLayout
            />
            <Input
              type="textbox"
              value={message}
              onChange={setField("message")}
              error={fieldErrors.message}
              block
              autoFocus
              label="Body"
              isVerticalLayout
            />
            <Input
              type="text"
              value={link}
              onChange={setField("link")}
              error={fieldErrors.link}
              block
              autoFocus
              label="Link"
              isVerticalLayout
            />
            <Input
              type="text"
              value={linkCTA}
              onChange={setField("linkCTA")}
              error={fieldErrors.linkCTA}
              block
              autoFocus
              label="Link Label"
              isVerticalLayout
            />
          </VerticalGroup>
        </form>
        <InlineGroup className="mt-4" spread>
          {!!onClose && (
            <Button data-test="modal-cancel" light onClick={onClose} large>
              Cancel
            </Button>
          )}
          <InlineGroup spaceBetweenElements={2}>
            {broadcastMessage && onDelete && (
              <Button
                form="broadcast-modal-form"
                large
                onClick={removeBroadcastMessage}
                danger
              >
                {"Remove"}
              </Button>
            )}
            <Button type="submit" form="broadcast-modal-form" large>
              {confirmButtonText}
            </Button>
          </InlineGroup>
        </InlineGroup>
      </>
    </StandardModal>
  );
}
