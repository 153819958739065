import React from "react";
import classNames from "classnames";

import { HexColorInput as OriginalColorInput } from "react-colorful";
import InputContainer from "./InputContainer";

import "./Input.scss";

interface HexInputProps
  extends React.ComponentProps<typeof OriginalColorInput>,
    React.ComponentProps<typeof InputContainer> {}

const HexInput: React.FC<HexInputProps> = ({
  autoFocus,
  block,
  className,
  error,
  label,
  labelFor,
  large,
  small,
  noBorder,
  helpTitle,
  helpDescription,
  ...rest
}) => {
  return (
    <InputContainer
      className={classNames(className, { block, hex: true })}
      noBorder={noBorder}
      label={label}
      labelFor={labelFor}
      error={error}
      helpDescription={helpDescription}
      helpTitle={helpTitle}
    >
      <OriginalColorInput
        autoFocus={autoFocus}
        className={classNames("input", { invalid: error, block, large, small })}
        {...rest}
      />
    </InputContainer>
  );
};

export default HexInput;
