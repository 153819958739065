import React from "react";
import classNames from "classnames";

import "./ListView.scss";
import VerticalGroup, {
  Props as VerticalGroupProps,
} from "../../atoms/verticalgroup/VerticalGroup";
import Scrollable from "../../atoms/scrollable/Scrollable";
import { ThemeSpacing } from "../../../types/theme";

interface Props extends VerticalGroupProps {
  children: React.ReactNode;
  className?: string;
  height?: ThemeSpacing;
}

function ListView({ children, className, height, ...rest }: Props) {
  const heightClass = height ? `height-${height}` : null;
  return (
    <VerticalGroup
      className={classNames(
        "list-view",
        "bg-white",
        "br-2",
        heightClass,
        className,
      )}
      {...rest}
    >
      <Scrollable>{children}</Scrollable>
    </VerticalGroup>
  );
}

export default ListView;
