import React, { useCallback } from "react";
import Checkbox from "../../../../../atoms/form/input/Checkbox";
import Input from "../../../../../atoms/form/input/Input";
import NumberInput from "../../../../../atoms/form/input/NumberInput";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import IconButton from "../../../../../molecules/iconbutton/IconButton";
import "./field.scss";

export const createChoice = ({
  label = "",
  markCorrect = false,
  mark = 0,
}: Partial<
  API.MultipleChoiceFieldRequest["config"]
> = {}): API.MultipleChoiceFieldRequest => ({
  type: "multiple_choices",
  config: {
    type: "multiple_choices",
    label,
    markCorrect,
    mark,
  },
  newlyCreatedAt: Date.now(),
});

export const createCheckboxesChoice = ({
  label = "",
  markCorrect = false,
  mark = 0,
}: Partial<
  API.CheckboxesFieldRequest["config"]
> = {}): API.CheckboxesFieldRequest => ({
  type: "checkboxes",
  config: {
    type: "checkboxes",
    label,
    markCorrect,
    mark,
  },
  newlyCreatedAt: Date.now(),
});

interface ChoiceFieldProps<Choice> {
  choice: Choice;
  index: number;
  onChange: (choice: Choice, idx: number) => void;
  onNewChoiceBefore: (index: number) => void;
  onRemoveChoice: (index: number) => void;
  assignMarks: boolean;
  error?: string;
  autoFocus?: boolean;
}

interface ConfigLike {
  id?: string;
  config: {
    label: string;
    markCorrect: boolean;
    mark?: number;
  };
}

export const ChoiceField = <T extends ConfigLike>({
  choice,
  onChange,
  index,
  onNewChoiceBefore,
  onRemoveChoice,
  assignMarks,
  error,
  autoFocus,
}: ChoiceFieldProps<T>) => {
  const onChangeLabel = useCallback(
    (e) => {
      const newValue = e.target.value.trim();
      onChange(
        {
          ...choice,
          config: {
            ...choice.config,
            label: newValue,
          },
        },
        index,
      );
    },
    [choice, index, onChange],
  );

  const onChangeCorrect = useCallback(
    (e) => {
      const newValue = e.target.checked;
      onChange(
        {
          ...choice,
          config: {
            ...choice.config,
            markCorrect: newValue,
            mark: newValue ? 1 : 0,
          },
        },
        index,
      );
    },
    [choice, index, onChange],
  );

  const onChangeMark = useCallback(
    (e) => {
      const newValue = e.target.value;
      onChange(
        {
          ...choice,
          config: {
            ...choice.config,
            mark: Number(newValue),
            markCorrect: Number(newValue) > 0,
          },
        },
        index,
      );
    },
    [choice, index, onChange],
  );

  const onPlusClick = useCallback(() => {
    onNewChoiceBefore(index);
  }, [index, onNewChoiceBefore]);

  const onMinusClick = useCallback(() => {
    onRemoveChoice(index);
  }, [index, onRemoveChoice]);

  return (
    <InlineGroup verticalCenter>
      <Checkbox
        name="is-correct"
        label=""
        tabIndex={-1}
        checked={choice.config.markCorrect}
        onChange={onChangeCorrect}
        inputClassName="survey-monkey-checkbox"
      />
      <Input
        type="textbox"
        inputClassName="low-init-height"
        placeholder="type choice"
        value={choice.config.label}
        onChange={onChangeLabel}
        error={error}
        autoFocus={autoFocus}
      />
      <IconButton
        mini
        noTab
        noUi
        light={false}
        wide={false}
        icon="roundPlus"
        onClick={onPlusClick}
      />
      <IconButton
        mini
        noTab
        noUi
        light={false}
        wide={false}
        icon="roundMinus"
        onClick={onMinusClick}
      />
      {assignMarks && (
        <NumberInput
          type="text"
          reverse
          label="points"
          small
          defaultValue={choice.config.markCorrect ? 1 : 0}
          value={choice.config.mark}
          onChange={onChangeMark}
          className={"ml-4"}
        />
      )}
    </InlineGroup>
  );
};
