import React from "react";
import { useCallback } from "react";
import { useMemoRequest } from "../../../../hooks/useMemoRequest";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import ModelAPI from "../../../../services/modelApi";

import ModelPageContainer from "../ModelPageContainer";
import Text from "../../../atoms/text/Text";
import { navigateTo, withBiggestVariance } from "./utils/utils";
import { pick } from "dot-object";
import { ModelVarianceTable, ModelVarianceTeamsTable } from "./utils/tables";

interface Props {
  eventId: string;
  configuration: ModelAPI.ConfigurationResponse;
  selectedTeam: number;
  selectedRound: number;
}

const transformTeamValuesArray = (
  teamValues: { id: number; value: number }[],
  formatter?: (val: number) => any,
) => {
  return teamValues.reduce<{ [team: string]: number }>((acc, teamValue) => {
    acc[teamValue.id] = formatter
      ? formatter(teamValue.value)
      : teamValue.value;
    return acc;
  }, {});
};

const ModelVarianceChecks: React.FC<Props> = ({ eventId, selectedRound }) => {
  const getVarianceChecks = useCallback(() => {
    return ModelAPI.getModelVarianceChecks(eventId, selectedRound);
  }, [eventId, selectedRound]);

  const { inProgress, data, error, refresh } =
    useMemoRequest<any>(getVarianceChecks);

  return (
    <ModelPageContainer
      eventId={eventId}
      selectedRound={selectedRound}
      data={data}
      refresh={refresh}
      error={error}
      inProgress={inProgress}
    >
      {!!data && (
        <VerticalGroup spaceBetweenElements={4} wide>
          <Text size="3xl" bold>
            Critical
          </Text>
          <ModelVarianceTable
            header="Market Share"
            helpTitle="Market Share"
            helpDescription="This is variance between the total amount of product available to the market and the sum of all the product won by the individual teams."
            onClick={(field: string) => {
              navigateTo({
                eventId,
                tab: "marketshare",
                section: field,
                table: field,
              });
            }}
            data={data.marketShare.map((d: any) =>
              withBiggestVariance({
                propietaryHomeLoans: pick("HOME_LOANS.propietaryHomeLoans", d)
                  .variance,
                brokerHomeLoans: pick("HOME_LOANS.brokerHomeLoans", d).variance,
                businessLoans: pick("BUSINESS_LOANS", d).variance,
                deposits: pick("DEPOSITS", d).variance,
                creditCards: pick("CREDIT_CARDS", d).variance,
                wealthManagement: pick("WEALTH_MANAGEMENT", d).variance,
                institutionalBanking: pick("INSTITUTIONAL_BANKING", d).variance,
              }),
            )}
            fields={[
              "propietaryHomeLoans",
              "brokerHomeLoans",
              "businessLoans",
              "deposits",
              "creditCards",
              "wealthManagement",
              "institutionalBanking",
            ]}
          />

          <ModelVarianceTeamsTable
            header="WSF Closing Balance: Detail = Balance Sheet"
            onClick={(field: string) => {
              navigateTo({
                eventId,
                team: field,
                tab: "wsf",
                section: "wsfMovements",
                table: "outstandingWholesaleFunding",
              });
            }}
            data={data.treasury.map((d: any) =>
              withBiggestVariance({
                ...transformTeamValuesArray(
                  pick("outstandingWholesaleFundingEndOfYear", d),
                ),
              }),
            )}
            helpDescription="This is the variance between the total of all the individual tranches of wholesale funding being tracked vs the total balance per the balance sheet."
          />
          <ModelVarianceTeamsTable
            header="WSF Closing Balance: Detail = Weighted Average Life"
            onClick={(field: string) => {
              navigateTo({
                eventId,
                team: field,
                tab: "wsf",
                section: "wsfMovements",
                table: "outstandingWholesaleFundingByTenor",
              });
            }}
            data={data.treasury.map((d: any) =>
              withBiggestVariance({
                ...transformTeamValuesArray(
                  pick("outstandingWholesaleFundingBalanceByTenor", d),
                ),
              }),
            )}
            helpDescription="This is the variance between the total of all the individual tranches of wholesale funding being tracked vs the total of the 5 maturity buckets."
          />
          <ModelVarianceTeamsTable
            header="WSF Average Balance: Detail = Balance Sheet"
            onClick={(field: string) => {
              navigateTo({
                eventId,
                team: field,
                tab: "wsf",
                section: "wsfMovements",
                table: "averageWholesaleFundingBalance",
              });
            }}
            data={data.treasury.map((d: any) =>
              withBiggestVariance({
                ...transformTeamValuesArray(
                  pick("averageWholesaleFundingBalance", d),
                ),
              }),
            )}
            helpDescription="This is the variance between the total of all the individual tranches of average wholesale funding being tracked vs a simple average based on balance sheet amounts."
          />
          <ModelVarianceTeamsTable
            header="WSF Large Debt Premiums"
            onClick={(field: string) => {
              navigateTo({
                eventId,
                team: field,
                tab: "wsf",
                section: "wsfCosts",
                table: "largeDebtIssuePremiums",
              });
            }}
            data={data.treasury.map((d: any) =>
              withBiggestVariance({
                ...transformTeamValuesArray(pick("largeDebtIssuePremiums", d)),
              }),
            )}
            helpDescription="This is the variance between the total amount of wholesale funding raised by the player and the breakdown of this amount into the “Large Issue” buckets."
          />

          <ModelVarianceTeamsTable
            header="WSF: Sculpt To Each Term Still Required"
            onClick={(field: string) => {
              navigateTo({
                eventId,
                team: field,
                tab: "wsf",
                section: "wsfCalculations",
                table: "sculptEachTerm",
              });
            }}
            data={data.treasury.map((d: any) =>
              withBiggestVariance({
                ...transformTeamValuesArray(pick("sculptEachTerm", d)),
              }),
            )}
            helpDescription="This is the variance between the total of the remaining adjustments to each funding tenor required and the total active amount of  new wholesale funding still required. "
          />

          <ModelVarianceTeamsTable
            header="CET1: Mandated Capital Still Required"
            onClick={(field: string) => {
              navigateTo({
                eventId,
                team: field,
                tab: "wsf",
                section: "regulatoryCapital",
                table: "mandatedCet1Capital",
              });
            }}
            data={data.treasury.map((d: any) =>
              withBiggestVariance({
                ...transformTeamValuesArray(
                  pick("additionalRegulatorMandatedCapitalRaising", d),
                ),
              }),
            )}
            helpDescription="This is the variance between current amount of forced regulator CET1 capital issued and the expected amount."
          />

          <ModelVarianceTeamsTable
            header="Balance Sheet Balances"
            onClick={(field: string) => {
              navigateTo({
                eventId,
                team: field,
                tab: "key_results",
                section: "balanceSheet",
                table: "balanceSheetEquity",
              });
            }}
            data={data.balanceSheet.map((d: any) =>
              withBiggestVariance({
                ...transformTeamValuesArray(pick("equity", d)),
              }),
            )}
            helpDescription="This check is the variance between the net assets in the balance sheet and the total equity."
          />
          <ModelVarianceTeamsTable
            header="Average Capital Balances By Product"
            onClick={(field: string) => {
              navigateTo({
                eventId,
                team: field,
                tab: "wsf",
                section: "regulatoryCapital",
                table: "capitalAllocationByProduct",
              });
            }}
            data={data.regulatoryCapital.map((d: any) =>
              withBiggestVariance({
                ...transformTeamValuesArray(
                  pick("capitalAllocationByProduct", d),
                ),
              }),
            )}
            helpDescription="This is the variance between the total of all equity allocated to all of the products vs. the total Balance Sheet Shareholder Funds."
          />
          <ModelVarianceTeamsTable
            header="Transfer Pricing: Net Income"
            onClick={(field: string) => {
              navigateTo({
                eventId,
                team: field,
                tab: "results",
                section: "transferPricing",
                table: "transferPricingAllocations",
              });
            }}
            data={data.transferPricing.map((d: any) =>
              withBiggestVariance({
                ...transformTeamValuesArray(
                  pick("transferPricingAllocations", d),
                ),
              }),
            )}
            helpDescription="This is the Net Transfer Pricing income which should ideally be zero. When zero it means all transfer pricing of interest is 100% allocated to the 4 products."
          />

          <Text size="3xl" bold>
            Informational
          </Text>
          <ModelVarianceTeamsTable
            header="CET1: Optimal Amount Still Required"
            onClick={(field: string) => {
              navigateTo({
                eventId,
                team: field,
                tab: "wsf",
                section: "regulatoryCapital",
                table: "capitalSculptCalculations",
              });
            }}
            data={data.treasury.map((d: any) =>
              withBiggestVariance({
                ...transformTeamValuesArray(
                  pick("optimalAdditionalCapital", d),
                ),
              }),
            )}
            helpDescription="This is the variance between the capital required to achieve the target credit rating and the current capital levels. Ideally this variance is zero, but sometimes the capital raise cap prevents sufficient capital being raised to achieve the desired credit rating."
          />
          <ModelVarianceTeamsTable
            header="Target CET1 Ratio Variance (Bps)"
            onClick={(field: string) => {
              navigateTo({
                eventId,
                team: field,
                tab: "wsf",
                section: "regulatoryCapital",
                table: "capitalSculptCalculations",
              });
            }}
            data={data.treasury.map((d: any) =>
              withBiggestVariance({
                ...transformTeamValuesArray(pick("drpOptimal", d)),
              }),
            )}
            helpDescription="This is the variance (in Bps) between the target CET1 capital ratio and the actual CET1 capital ratio. Ideally this variance is zero, but sometimes the capital raise cap prevents sufficient capital being raised to achieve the desired credit rating."
          />
          <ModelVarianceTeamsTable
            header="Target CET1 Ratio Variance (Bps) 2"
            onClick={(field: string) => {
              navigateTo({
                eventId,
                team: field,
                tab: "wsf",
                section: "regulatoryCapital",
                table: "capitalSculptCalculations",
              });
            }}
            data={data.treasury.map((d: any) =>
              withBiggestVariance({
                ...transformTeamValuesArray(pick("drpOptimalVarianceCheck", d)),
              }),
            )}
            helpDescription="The max of a number of CET1 capital variances. "
          />
          <ModelVarianceTeamsTable
            header="Central Bank Facility: Cash Amount Still Needed"
            onClick={(field: string) => {
              navigateTo({
                eventId,
                team: field,
                tab: "wsf",
                section: "wsfCalculations",
                table: "centralBankFacility",
              });
            }}
            data={data.treasury.map((d: any) =>
              withBiggestVariance({
                ...transformTeamValuesArray(pick("cashAmountToRaise", d)),
              }),
            )}
            helpDescription="This is the variance between the Target Cash Level and the current Closing Cash Balance. This amount is only used when the cash balance without CBF is negative."
          />
          <ModelVarianceTeamsTable
            header="WSF: Optimal WSF Amount Still Needed"
            onClick={(field: string) => {
              navigateTo({
                eventId,
                team: field,
                tab: "wsf",
                section: "wsfCalculations",
                table: "optimalWholesaleFundingRaise",
              });
            }}
            data={data.treasury.map((d: any) =>
              withBiggestVariance({
                ...transformTeamValuesArray(pick("activeSculptAmount", d)),
              }),
            )}
            helpDescription="This is the variance between the current wholesale funding raised and the ideal amount of wholesale funding to raise. This amount is only used when the WSF issues are being managed automatically."
          />
        </VerticalGroup>
      )}
    </ModelPageContainer>
  );
};

export default ModelVarianceChecks;
