import React, { useCallback, useMemo } from "react";
import AdminAPI from "../../../services/api";
import EventDropDown from "./EventDropDown";
import { useMemoRequest } from "../../../hooks/useMemoRequest";
import { sortEvents } from "./sortEvents";

interface ImportEventsListDropdownProps {
  selectedEventId?: string;
  onEventSelected?: (eventData: API.EventResponseSummary) => void;
}

const ImportEventsListDropdown: React.FC<ImportEventsListDropdownProps> = ({
  selectedEventId,
  onEventSelected,
}) => {
  const callback = useCallback(() => {
    return AdminAPI.getImportEventsList();
  }, []);

  const { data, inProgress } = useMemoRequest(callback);

  const sortedData = useMemo(() => sortEvents(data, "asc"), [data]);

  return (
    <EventDropDown
      noOptionsMessage="No Events to Import"
      data={sortedData}
      inProgress={inProgress}
      onEventSelected={onEventSelected}
      selectedEventId={selectedEventId}
    />
  );
};

export default ImportEventsListDropdown;
