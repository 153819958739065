import React from "react";
import EnumDropdown from "../../../../../organisms/enum-dropdown/EnumDropdown";

export const QUESTION_TYPES = [
  "checkboxes",
  "image",
  "multiple_choices",
  "rating",
  "written_response",
  "short_written_response",
  "written_text",
];

const OPTIONS = [
  { value: "multiple_choices", label: "Multiple Choice" },
  { value: "checkboxes", label: "Checkboxes" },
  { value: "written_response", label: "Written Response" },
  { value: "short_written_response", label: "Short Written Response" },
  { value: "rating", label: "Rating" },
  { value: "written_text", label: "Written Text" },
  { value: "image", label: "Image" },
];

interface Props {
  onChange: (selected: { value: string; label: string }) => void;
  value: string;
}

export const QuestionTypeSelect: React.FC<Props> = ({ onChange, value }) => {
  return (
    <EnumDropdown
      label="Question Type *"
      options={OPTIONS}
      onChange={onChange}
      value={value}
    />
  );
};
