import React, { useCallback, useMemo } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import { TeamsWithColours } from "../RoundResultsPage";
import ResultsTeamLineChart from "../charts/ResultsTeamLineChart";
import { getYAxisDataV2 } from "../charts/yaxis";
import ChartCard from "../components/ChartCard";
import { reportingPercentage } from "../../model/utils/formatters";
import { convertRoundDataToPercentages } from "../charts/convertRoundDataToPercentages";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import { getDotColour } from "../utils/getDotColour";
import { createLineChartDot } from "../charts/LineChartDot";
import useChartStyles from "../../../../hooks/useChartStyles";
import { useResultsContext } from "../context/context";
interface Props {
  pastRound1: boolean;
  teams: TeamsWithColours[];
  loanImpairmentExpense: ModelAPI.Reporting.DataAndPositions;
  creditSettings: ModelAPI.Reporting.RoundData[];
}

const RoundResultsLoanImpairmentExpense: React.FC<Props> = ({
  pastRound1,
  teams,
  loanImpairmentExpense,
  creditSettings,
}) => {
  const data = useMemo(
    () => convertRoundDataToPercentages(loanImpairmentExpense.data),
    [loanImpairmentExpense.data],
  );
  const { minValue, maxValue, numberOfTicks, yAxisDp } = getYAxisDataV2({
    isPercentage: true,
    padding: 0.9,
    pastRound1,
    data,
  });

  const chartStyles = useChartStyles();

  const teamsWithDot = teams.map((t) => ({
    ...t,
    dot: createLineChartDot(
      chartStyles.circleRadius,
      chartStyles.strokeWidth,
      teams,
      getDotColour(creditSettings ?? []),
    ),
  }));
  const context = useResultsContext();
  const maxClicks = useMemo(
    () => (context.allowPresentationMode || context.readSyncState ? 1 : 0),
    [context.allowPresentationMode, context.readSyncState],
  );
  const onNext = useCallback(() => {
    if (context.clicks < maxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context, maxClicks]);
  return (
    <RoundResultsContainer onNext={onNext}>
      <VerticalGroup full center wide>
        <ChartCard heading="Loan Impairment Expense">
          <ResultsTeamLineChart
            data={data}
            teams={teamsWithDot}
            formatter={(val: number) => reportingPercentage(val)}
            yAxisFormatter={(val: number) => reportingPercentage(val, yAxisDp)}
            minYAxisvalue={minValue}
            maxYAxisvalue={maxValue}
            numberOfTicks={numberOfTicks}
            teamPositions={loanImpairmentExpense.teamPositions}
            shouldRenderLines={context.clicks === maxClicks}
            tooltipOrdering="lowest"
          />
        </ChartCard>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsLoanImpairmentExpense;
