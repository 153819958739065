import React, { useState, useEffect, useCallback } from "react";
import ImageGallery from "react-image-gallery";
import classNames from "classnames";

import SimpleModal from "../../../../organisms/standard-modal/SimpleModal";
import Image from "../../../../atoms/image/Image";
import API from "../../../../../services/api";
import LoadingSpinner from "../../../../atoms/loadingspinner/LoadingSpinner";
import DisplayField from "../../../../atoms/form/display-field/DisplayField";
import ClientAvatarList from "../../../../molecules/client-avatar-list/ClientAvatarList";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import useAPIRequest from "../../../../../hooks/useAPIRequest";
import Banner from "../../../../atoms/banner/Banner";
import IconButton from "../../../../molecules/iconbutton/IconButton";

interface Props {
  helpManualId: string;
}

const HelpManualDetails: React.FC<Props> = (props) => {
  const { helpManualId } = props;
  const [isModalOpen, setModalStatus] = useState<boolean>(false);

  const callback = useCallback(() => {
    return API.getHelpManual(helpManualId);
  }, [helpManualId]);

  const [{ inProgress, data, error }, doAPIRequest] =
    useAPIRequest<API.HelpManualResponse>(callback, null);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  const images = data?.images.map((image) => ({
    original: image.imageUrl,
    thumbnail: image.imageUrl,
  }));
  const firstImage = images && images[0];

  if (!data) return null;

  return (
    <>
      {isModalOpen && (
        <SimpleModal
          size="medium"
          isMinimal
          showCloseButton
          isOpen={isModalOpen}
          onClose={() => setModalStatus(false)}
        >
          <ImageGallery showPlayButton={false} items={images ?? []} />
        </SimpleModal>
      )}

      <div className={"ml-2"}>
        {error && (
          <Banner type="error" active={!!error} message={error?.message} />
        )}
        <InlineGroup spread verticalCenter>
          <h2>Help Manual Details</h2>
          {!!data && !inProgress && (
            <InlineGroup spread spaceBetweenElements={2}>
              <IconButton
                data-test={`edit-help-manual-${helpManualId}`}
                icon="edit"
                text="Edit Help Manual"
                linkTo={`/administration/configuration/helpManuals/${helpManualId}/edit`}
              />
            </InlineGroup>
          )}
        </InlineGroup>
        {inProgress && <LoadingSpinner />}
        <DisplayField label="File Name" value={data.name} />
        {data.availableToAllClients ? (
          <DisplayField label="Available to all clients" value={"true"} />
        ) : (
          <DisplayField
            stretch
            label="Clients"
            value={<ClientAvatarList clients={data.clients} />}
          />
        )}
        <DisplayField label="Preview" value={""} />
        {firstImage ? (
          <Image
            alt={data.name}
            src={firstImage?.thumbnail}
            className={classNames(
              "help-manual-preview",
              "image",
              "input-width",
            )}
            onClick={() => setModalStatus(true)}
          />
        ) : (
          <LoadingSpinner size="1.3rem" />
        )}
      </div>
    </>
  );
};

export default HelpManualDetails;
