import React from "react";
import { useCallback } from "react";
import { useMemoRequest } from "../../../../hooks/useMemoRequest";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import ModelAPI from "../../../../services/modelApi";
import { Accordion } from "../../../organisms/accordion/accordion";
import MarketCalculations from "./MarketCalculations";
import ModelPageContainer from "../ModelPageContainer";
import ProjectImpacts from "./ProjectImpacts";
import useModelQuery from "../utils/useModelQuery";

interface Props {
  eventId: string;
  configuration: ModelAPI.ConfigurationResponse;
  teams: API.GameConnection[];
  selectedRound: number;
}

const ModelMarketShare: React.FC<Props> = ({
  eventId,
  configuration,
  teams,
  selectedRound,
}) => {
  const getMarketShare = useCallback(() => {
    return ModelAPI.getMarketShare(eventId, selectedRound);
  }, [eventId, selectedRound]);

  const { inProgress, data, error, refresh } =
    useMemoRequest<ModelAPI.MarketShareResponse>(getMarketShare);

  const { expandIfSection } = useModelQuery();

  return (
    <ModelPageContainer
      eventId={eventId}
      selectedRound={selectedRound}
      data={data}
      refresh={refresh}
      error={error}
      inProgress={inProgress}
    >
      {!!data && (
        <VerticalGroup spaceBetweenElements={2} wide>
          <Accordion
            title="Project Impacts"
            defaultState={expandIfSection("projectImpacts")}
            wide
          >
            <ProjectImpacts
              configuration={configuration}
              data={data.marketShare}
              teams={teams}
            />
          </Accordion>
          <Accordion
            title="Home Loans - Propietary Loans"
            defaultState={expandIfSection("propietaryHomeLoans")}
            wide
          >
            <MarketCalculations
              configuration={configuration}
              data={data.marketShare}
              teams={teams}
              prefix="HOME_LOANS.propietaryHomeLoans"
              type="propietaryHomeLoans"
              secondaryLeversHeading="Exco Impact"
            />
          </Accordion>
          <Accordion
            title="Home Loans - Broker Loans"
            defaultState={expandIfSection("brokerHomeLoans")}
            wide
          >
            <MarketCalculations
              configuration={configuration}
              data={data.marketShare}
              teams={teams}
              prefix="HOME_LOANS.brokerHomeLoans"
              hideNPS
              type="brokerHomeLoans"
              secondaryLeversHeading="Exco Impact"
            />
          </Accordion>
          <Accordion
            title="Business Loans"
            defaultState={expandIfSection("businessLoans")}
            wide
          >
            <MarketCalculations
              configuration={configuration}
              data={data.marketShare}
              teams={teams}
              prefix="BUSINESS_LOANS"
              secondaryLeversHeading="Lending Criteria Notional Pricing Impact"
              type="businessLoans"
            />
          </Accordion>
          <Accordion
            title="Deposits"
            defaultState={expandIfSection("deposits")}
            wide
          >
            <MarketCalculations
              configuration={configuration}
              data={data.marketShare}
              teams={teams}
              prefix="DEPOSITS"
              primaryAttrition="Player Attrition - Transaction Accounts"
              secondaryAttrition="Player Attrition - Savings Accounts"
              secondaryLeversHeading="Impact of Branch #s"
              type="deposits"
            />
          </Accordion>
          <Accordion
            title="Credit Cards"
            defaultState={expandIfSection("creditCards")}
            wide
          >
            <MarketCalculations
              configuration={configuration}
              data={data.marketShare}
              teams={teams}
              prefix="CREDIT_CARDS"
              totalLoansInTheMarketName="Total Market Credit Card Spend"
              headlinePricingType="dollars"
              secondaryLeversHeading="Impact of Reward Points"
              type="creditCards"
            />
          </Accordion>
          <Accordion
            title="Wealth Management"
            defaultState={expandIfSection("wealthManagement")}
            wide
          >
            <MarketCalculations
              configuration={configuration}
              data={data.marketShare}
              teams={teams}
              prefix="WEALTH_MANAGEMENT"
              secondaryLeversHeading="Service Levels Notional Impact"
              type="wealthManagement"
            />
          </Accordion>
          <Accordion
            title="Institutional Banking"
            defaultState={expandIfSection("institutionalBanking")}
            wide
          >
            <MarketCalculations
              configuration={configuration}
              data={data.marketShare}
              teams={teams}
              prefix="INSTITUTIONAL_BANKING"
              secondaryLeversHeading="Client Specialists"
              type="institutionalBanking"
              secondaryPricingType="value"
            />
          </Accordion>
          <Accordion
            title="Offshore Bank"
            defaultState={expandIfSection("offshoreBank")}
            wide
          >
            <MarketCalculations
              configuration={configuration}
              data={data.marketShare}
              teams={teams}
              prefix="OFFSHORE_BANK"
              secondaryLeversHeading="Cummulative Investment Impact - Notional Pricing Impact"
              type="offshoreBank"
              secondaryPricingType="bps"
            />
          </Accordion>
        </VerticalGroup>
      )}
    </ModelPageContainer>
  );
};

export default ModelMarketShare;
