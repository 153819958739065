import React, { useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import API from "../../../services/api";
import useAPIRequest from "../../../hooks/useAPIRequest";
import Container from "../../atoms/page/Page";
import Input from "../../atoms/form/input/Input";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import BrandingDelete from "./BrandingDelete";
import DisplayField from "../../atoms/form/display-field/DisplayField";
import Form from "../../atoms/form/Form";
import Banner from "../../atoms/banner/Banner";
import IconButton from "../../molecules/iconbutton/IconButton";
import Image from "../../atoms/image/Image";
import {
  ColorSection,
  ColourPopupContainer,
  ColourRow,
} from "./BrandingColorSection";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import BrandingPreview from "../../organisms/brandingpreview/BrandingPreview";
import ClientAvatarList from "../../molecules/client-avatar-list/ClientAvatarList";
import RoleRequired from "../../molecules/role-required/RoleRequired";

interface BrandingState {
  deleteModalOpen: boolean;
}

function BrandingDetails() {
  const { brandingId } = useParams<{ brandingId: string }>();
  const history = useHistory();

  const callback = useCallback(() => {
    return API.getBranding(brandingId);
  }, [brandingId]);

  const [{ inProgress, data, error }, doAPIRequest] =
    useAPIRequest<API.BrandingResponse>(callback, null);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  const [{ deleteModalOpen }, setBrandingState] = useState<BrandingState>({
    deleteModalOpen: false,
  });

  const onBrandingDeleteComplete = useCallback(() => {
    setBrandingState((prevState) => ({ ...prevState, deleteModalOpen: false }));
    history.push("/brandings");
  }, [history]);

  return (
    <>
      {deleteModalOpen && !!data && (
        <BrandingDelete
          isOpen={deleteModalOpen}
          onClose={() =>
            setBrandingState((prevState) => ({
              ...prevState,
              deleteModalOpen: false,
            }))
          }
          onComplete={onBrandingDeleteComplete}
          branding={data}
        />
      )}

      <Container className="branding-edit">
        {error && (
          <Banner type="error" active={!!error} message={error?.message} />
        )}
        <InlineGroup right>
          {!!data && !inProgress && (
            <InlineGroup spread spaceBetweenElements={2}>
              <IconButton
                data-test={`edit-branding-${brandingId}`}
                icon="edit"
                text="Edit Branding"
                linkTo={`/brandings/${brandingId}/edit`}
              />
              <RoleRequired roles={["superadmin", "standard", "admin"]}>
                <IconButton
                  data-test={`clone-branding-${brandingId}`}
                  icon="clone"
                  text="Clone Branding"
                  linkTo={`/brandings/${brandingId}/clone`}
                />
                <IconButton
                  danger
                  data-test={`delete-branding-${data.id}`}
                  icon="trash"
                  text="Delete"
                  onClick={() =>
                    setBrandingState((prevState) => ({
                      ...prevState,
                      deleteModalOpen: true,
                    }))
                  }
                />
              </RoleRequired>
            </InlineGroup>
          )}
        </InlineGroup>
        {inProgress && <LoadingSpinner />}
        {!!data && !inProgress && (
          <>
            <InlineGroup evenWidthChildren block spaceBetweenElements={10}>
              <VerticalGroup>
                <h2>Branding Details</h2>
                <Form>
                  <DisplayField
                    stretch
                    label="Clients With Access"
                    value={<ClientAvatarList clients={data.clients} />}
                  />
                  <Input
                    data-test="clientName"
                    type="text"
                    value={data.clientName}
                    label="Client Name"
                    readOnly
                  />
                  <Input
                    data-test="gameName"
                    type="text"
                    value={data.gameName}
                    label="Game Name"
                    readOnly
                  />
                  <Input
                    data-test="inApptext"
                    type="text"
                    value={data.insideAppText}
                    label="In App Text"
                    readOnly
                  />
                  <DisplayField
                    stretch
                    label="Logo"
                    value={
                      <Image
                        className="br-2"
                        shadow
                        heightSize={20}
                        src={data.brandingImage?.imageUrl}
                        alt="Branding logo"
                      />
                    }
                  />
                  <DisplayField label="Branding ID" value={data.id} />
                </Form>
              </VerticalGroup>
              <VerticalGroup>
                <h2>Preview: Participant Login Page</h2>
                <BrandingPreview branding={data} maxContainerHeight={460} />
              </VerticalGroup>
            </InlineGroup>
            <VerticalGroup wide>
              <h2>Design Details</h2>
              <InlineGroup block spread spaceBetweenElements={10}>
                <ColourPopupContainer>
                  <ColorSection title="Fill Colours">
                    <ColourRow
                      id="fill-heading-bg"
                      title="Heading & footer background"
                      color={data.headingBackgroundColour}
                      readOnly
                    />
                    <ColourRow
                      id="fill-main-bg"
                      title="Main background"
                      color={data.mainBackgroundColour}
                      readOnly
                    />
                    <ColourRow
                      id="fill-login-btn"
                      title="Login button"
                      color={data.buttonBackgroundColour}
                      readOnly
                    />
                  </ColorSection>
                  <ColorSection title="Text Colours">
                    <ColourRow
                      id="text-heading"
                      title="Heading & footer text"
                      color={data.headingTextColour}
                      readOnly
                    />
                    <ColourRow
                      id="text-main"
                      title="Main text"
                      color={data.mainTextColour}
                      readOnly
                    />
                    <ColourRow
                      id="text-login-btn"
                      title="Login button"
                      color={data.buttonTextColour}
                      readOnly
                    />
                  </ColorSection>
                </ColourPopupContainer>
              </InlineGroup>
            </VerticalGroup>
          </>
        )}
      </Container>
    </>
  );
}

export default BrandingDetails;
