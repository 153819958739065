import React from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../../tables/TableWithSections";
import { formatLossMultiplier, percentage } from "../../utils/formatters";
import pluckResultsByType from "../../utils/pluckResultsByType";
import useModelQuery from "../../utils/useModelQuery";
import { fieldMap } from "../utils/fieldMap";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.TeamResultsResponse["wholesaleFundingCalculations"];
  fields: Array<{ field: string; name: string }>;
}

const WholesaleFundingCalculations: React.FC<Props> = ({ data }) => {
  const centralBankFacilityData = pluckResultsByType(
    data,
    "centralBankFacility",
  );
  const largeDebtIssuePremiumData = pluckResultsByType(
    data,
    "largeDebtIssuePremium",
  );
  const optimalWholesaleFundingRaiseData = pluckResultsByType(
    data,
    "optimalWholesaleFundingRaise",
  );
  const sculptEachTermData = pluckResultsByType(data, "sculptEachTerm");
  const { openIfTable } = useModelQuery();
  return (
    <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
      <VerticalGroup spaceBetweenElements={2} wide>
        <TableWithSections
          hasRoundZero
          header="Links In"
          data={[
            {
              fields: ["creditRating"],
              values: data,
              formatter: (val) => String(val),
            },
            {
              name: "Wholesale Funding Rates",
              fields: [
                "referenceRate",
                "coreCreditSpread",
                "coreCreditSpreadAdjustments",
                "coreCreditSpreadEffective",
                "creditRatingImpactOnTermPremiums",
                "creditRatingImpactOnTermPremiumsAdjustment",
                "creditRatingImpactOnTermPremiumsEffective",
              ],
              totalFields: [
                "coreCreditSpreadEffective",
                "creditRatingImpactOnTermPremiumsEffective",
              ],
              values: pluckResultsByType(data, "wholesaleFundingRates"),
              formatter: (val: number, field: string) => {
                if (
                  [
                    "referenceRate",
                    "coreCreditSpread",
                    "coreCreditSpreadAdjustments",
                    "coreCreditSpreadEffective",
                  ].includes(field)
                ) {
                  return percentage(val);
                }
                return formatLossMultiplier(val);
              },
            },
            {
              name: "Term Premiums",
              fields: ["wsf1", "wsf2", "wsf3", "wsf4", "wsf5"],
              values: pluckResultsByType(data, "termPremiums"),
              fieldFormatter: (field: string) => fieldMap[field],
              formatter: (val) => percentage(val),
            },
            {
              name: "Total Spread (By Tenor)",
              fields: [
                "centralBankFacility",
                "wsf1",
                "wsf2",
                "wsf3",
                "wsf4",
                "wsf5",
              ],
              values: pluckResultsByType(data, "totalSpreadByTenor"),
              fieldFormatter: (field: string) => fieldMap[field],
              formatter: (val) => percentage(val),
            },
            {
              fields: ["efRateTooLow"],
              values: data,
              formatter: (val) => String(val),
            },
            {
              name: "Total Spread By Tenor (Incl Large Issue Premium)",
              fields: [
                "centralBankFacility",
                "wsf1",
                "wsf2",
                "wsf3",
                "wsf4",
                "wsf5",
              ],
              values: pluckResultsByType(
                data,
                "totalSpreadByTenorIncludingLargeIssuePremium",
              ),
              fieldFormatter: (field: string) => fieldMap[field],
              formatter: (val) => percentage(val),
            },
            {
              name: "Wholesale Funding Movements",
              fields: [
                "centralBankFacility",
                "wsf1",
                "wsf2",
                "wsf3",
                "wsf4",
                "wsf5",
              ],
              values: pluckResultsByType(data, "wholesaleFundingMovements"),
              fieldFormatter: (field: string) => fieldMap[field],
            },
          ]}
        />
      </VerticalGroup>
      <VerticalGroup spaceBetweenElements={2} wide>
        <TableWithSections
          hasRoundZero
          header="Central Bank Facility"
          openOnInit={openIfTable("centralBankFacility")}
          data={[
            {
              name: "Central Bank Borrowing Required",
              fields: [
                "closingCashBalanceBeforeCBF",
                "closingCashBalanceAfterCBF",
                "totalDeposits",
                "targetCashLevel",
                "cashAmountToRaise",
              ],
              highlightFields: ["cashAmountToRaise"],
              values: pluckResultsByType(
                centralBankFacilityData,
                "centralBankBorrowingRequired",
              ),
              fieldFormatter: (field: string) => {
                if (field === "closingCashBalanceBeforeCBF") {
                  return "Closing Cash Balance - Before CBF";
                }
                if (field === "closingCashBalanceAfterCBF") {
                  return "Closing Cash Balance - After CBF";
                }
                return null;
              },
            },
          ]}
        />
      </VerticalGroup>
      <VerticalGroup spaceBetweenElements={2} wide>
        <TableWithSections
          hasRoundZero
          header="Optimal Wholesale Funding Raise"
          openOnInit={openIfTable("optimalWholesaleFundingRaise")}
          data={[
            {
              name: "Full Sculpt",
              fields: [
                "targetCashLevel",
                "regulatorMandatedCapitalRaised",
                "totalTargetCashLevel",
                "closingCashBalance",
                "idealAdjustmentToExistingAssumptions",
                "currentWholesaleFundingRaised",
                "changeInwholesaleFundingRequired",
              ],
              values: pluckResultsByType(
                optimalWholesaleFundingRaiseData,
                "fullSculpt",
              ),
            },
            {
              name: "Amount Sculpt",
              fields: [
                "playerDecisionAmountToRaise",
                "currentWholesaleFundingRaised",
                "changeInwholesaleFundingRequired",
              ],
              values: pluckResultsByType(
                optimalWholesaleFundingRaiseData,
                "amountSculpt",
              ),
            },
            {
              name: "Active Sculpting Method",
              fields: [
                "playerType",
                "amountOnlyFlag",
                "liveRoundFlag",
                "cbfNotRequiredFlag",
                "activeChangeAmount",
              ],
              values: pluckResultsByType(
                optimalWholesaleFundingRaiseData,
                "activeSculpt",
              ),
              formatter: (val, field) => {
                if (field === "playerType") {
                  return `${val}`;
                }
                if (
                  [
                    "amountOnlyFlag",
                    "liveRoundFlag",
                    "cbfNotRequiredFlag",
                  ].includes(field)
                ) {
                  return (val as unknown as boolean) === true
                    ? "True"
                    : "False";
                }
                return null;
              },
            },
          ]}
        />
      </VerticalGroup>
      <VerticalGroup spaceBetweenElements={2} wide>
        <TableWithSections
          hasRoundZero
          header="Sculpt Each Term"
          openOnInit={openIfTable("sculptEachTerm")}
          data={[
            {
              fields: ["currentClosingWholesaleFundingBalance"],
              values: sculptEachTermData,
            },
            {
              name: "Current Wholesale Funding Decisions",
              fields: ["wsf1", "wsf2", "wsf3", "wsf4", "wsf5", "total"],
              totalFields: ["total"],
              fieldFormatter: (field: string) => fieldMap[field],
              values: pluckResultsByType(
                sculptEachTermData,
                "currentWholesaleFundingDecisions",
              ),
            },
            {
              name: "Ideal Wholesale Funding Profile",
              fields: ["wsf1", "wsf2", "wsf3", "wsf4", "wsf5", "total"],
              totalFields: ["total"],
              fieldFormatter: (field: string) => fieldMap[field],
              values: pluckResultsByType(
                sculptEachTermData,
                "idealWholesaleFundingProfile",
              ),
            },
            {
              name: "Total Gap Between Existing Profile And Ideal Profile",
              fields: ["wsf1", "wsf2", "wsf3", "wsf4", "wsf5", "total"],
              totalFields: ["total"],
              fieldFormatter: (field: string) => fieldMap[field],
              values: pluckResultsByType(
                sculptEachTermData,
                "totalGapBetweenExistingProfileAndIdealProfile",
              ),
            },
            {
              name: "Ideal Placeholder Assumptions",
              fields: ["wsf1", "wsf2", "wsf3", "wsf4", "wsf5"],
              fieldFormatter: (field: string) => fieldMap[field],
              values: pluckResultsByType(
                sculptEachTermData,
                "idealPlaceholderAssumptions",
              ),
            },
            {
              name: "Adjustment To Placeholder Decisions",
              fields: ["wsf1", "wsf2", "wsf3", "wsf4", "wsf5", "total"],
              highlightFields: [
                "wsf1",
                "wsf2",
                "wsf3",
                "wsf4",
                "wsf5",
                "total",
              ],
              totalFields: ["total"],
              fieldFormatter: (field: string) => fieldMap[field],
              values: pluckResultsByType(
                sculptEachTermData,
                "adjustmentToPlaceholderDecisions",
              ),
            },
            {
              fields: [
                "expectedAdjustments",
                "variance",
                "maxVarianceInSculptTerms",
              ],
              values: sculptEachTermData,
            },
          ]}
        />
      </VerticalGroup>
      <VerticalGroup spaceBetweenElements={2} wide>
        <TableWithSections
          hasRoundZero
          header="Large Debt Issue Premium"
          data={[
            {
              fields: ["weightedAveragePremium"],
              values: largeDebtIssuePremiumData,
              formatter: (val) => percentage(val),
            },
          ]}
        />
      </VerticalGroup>
    </VerticalGroup>
  );
};

export default WholesaleFundingCalculations;
