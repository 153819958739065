import React from "react";
import { Redirect } from "react-router-dom";
import { useCurrentEvent } from "../../../../context/CurrentEventContext";

export const AssessmentResponsesIndex = () => {
  const { event, inProgress, error } = useCurrentEvent();

  if (inProgress) return null;
  if (!event && !error && !inProgress) return null;

  if (!event || error) return <Redirect to={"/events"} />;

  return event.assessment?.id ? (
    <Redirect
      to={`/events/${event.id}/view/assessments/${event.assessment?.id}/responses`}
    />
  ) : (
    <Redirect to={`/events/${event.id}/view`} />
  );
};
