import React, { useCallback } from "react";
import Card from "../../../atoms/card/Card";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import EditableReportingHeading from "../components/EditableReportingHeading";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Icon from "../../../atoms/icon/Icon";
import ReportingSubHeading from "../components/ReportingSubHeading";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import { useResultsContext } from "../context/context";
import classNames from "classnames";
import "./IntroRegulatoryCapitalFull.scss";
import ResultsTable from "../table/ResultsTable";
import { Td, Th, Tr } from "../../../atoms/table/Table";
import ResultsText from "../results-text/ResultsText";

export const regulatoryCapitalFullMaxClicks = 4;

const RATINGS_DATA = {
  headers: ["Rating", "AAA", "AA", "A", "BBB", "BB", "B", "Default"],
  rows: [
    {
      label: "CET1 Ratio Must be Above",
      values: [
        "12.50%",
        "11.50%",
        "10.50%",
        "9.50%",
        "8.50%",
        "8.00%",
        "<8.00%",
      ],
    },
  ],
};

const IntroRegulatoryCapitalFull = () => {
  const context = useResultsContext();

  const onNext = useCallback(() => {
    if (context.clicks < regulatoryCapitalFullMaxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context]);

  const showSubHeading = context.noAnimations || context.clicks >= 1;
  const showAllContent = context.noAnimations || context.clicks >= 2;
  const animateScale = context.noAnimations || context.clicks >= 3;
  const showTable = context.noAnimations || context.clicks >= 4;

  return (
    <RoundResultsContainer onNext={onNext}>
      <Card wide fullHeight className="capital-rating-slide-full">
        <VerticalGroup wide center full spread>
          <EditableReportingHeading defaultHeading="Capital is a regulatory requirement" />
          <InlineGroup
            center
            className={classNames("capital-subheading-full", {
              show: showSubHeading,
            })}
          >
            <ReportingSubHeading subHeading="Banks are required to hold minimum capital levels to protect those who trust the bank with their savings (Depositors)" />
          </InlineGroup>

          <InlineGroup
            className={classNames("capital-rating-container-full", {
              show: showAllContent,
            })}
            verticalTop
            center
          >
            <VerticalGroup wide center>
              <div
                className={classNames("scale-animation-full", {
                  animate: animateScale,
                })}
              >
                <Icon type="strengthScales" fullSize />
              </div>
              <div className="bottom-icon-full">
                <Icon type="strengthScalesBottom" fullSize />
              </div>
            </VerticalGroup>
          </InlineGroup>

          <ResultsTable
            className={classNames("cet1-ratio-table-full", {
              show: showTable,
            })}
          >
            <thead>
              <Tr>
                {RATINGS_DATA.headers.map((header, index) => (
                  <Th
                    key={index}
                    className={classNames({
                      "first-column-full": index === 0,
                      "highlighted-column-full": index === 3,
                    })}
                  >
                    <ResultsText bold>{header}</ResultsText>
                  </Th>
                ))}
              </Tr>
            </thead>
            <tbody>
              {RATINGS_DATA.rows.map((row, rowIndex) => (
                <Tr key={rowIndex}>
                  <Td className="first-column-full">
                    <ResultsText>{row.label}</ResultsText>
                  </Td>
                  {row.values.map((value, cellIndex) => (
                    <Td
                      key={cellIndex}
                      className={classNames({
                        "highlighted-column-full": cellIndex === 2,
                      })}
                    >
                      <ResultsText>{value}</ResultsText>
                    </Td>
                  ))}
                </Tr>
              ))}
            </tbody>
          </ResultsTable>
        </VerticalGroup>
      </Card>
    </RoundResultsContainer>
  );
};

export default IntroRegulatoryCapitalFull;
