import React, { useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import Page from "../../../atoms/page/Page";
import { QuestionForm } from "./QuestionForm";
import API from "../../../../services/api";
import { useQuestion } from "../../../../hooks/useQuestion";

const AssessmentCloneQuestion: React.FC = () => {
  const { assessmentId, questionId, groupId } = useParams<{
    assessmentId: string;
    groupId: string;
    questionId: string;
  }>();

  const { inProgress, data: questionData } = useQuestion(
    assessmentId,
    groupId,
    questionId,
  );

  const history = useHistory();

  const onComplete = useCallback(
    (newGroupId: string) => {
      history.push(`/assessments/${assessmentId}/view?groupId=${newGroupId}`);
    },
    [assessmentId, history],
  );

  const onSave = useCallback(
    async (
      groupId: string,
      payload: API.AssessmentQuestionRequest,
    ): Promise<API.AssessmentQuestionResponse> => {
      const { fields, ...rest } = payload;

      const newFields = fields
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map(({ id, ...restField }) => restField)
        .map((field) => {
          if (field.type === "written_response") {
            return {
              ...field,
              config: {
                ...field.config,
                hasCharacterLimit: Boolean(field.config.hasCharacterLimit),
              },
            };
          }
          return field;
        });

      const newPayload = {
        ...rest,
        fields: newFields,
      };

      const response = await API.createAssessmentQuesiton(
        assessmentId,
        groupId,
        newPayload,
      );
      return response;
    },
    [assessmentId],
  );
  const onCancel = useCallback(() => {
    history.push(`/assessments/${assessmentId}/view?groupId=${groupId}`);
  }, [assessmentId, groupId, history]);

  if (inProgress || !questionData) {
    return null;
  }

  return (
    <Page>
      <QuestionForm
        mode="clone"
        assessmentId={assessmentId}
        onSave={onSave}
        onCancel={onCancel}
        onComplete={onComplete}
        data={questionData}
        groupId={groupId}
      />
    </Page>
  );
};

export default AssessmentCloneQuestion;
