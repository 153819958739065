import React from "react";

import Card from "../../../../atoms/card/Card";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import Clickable from "../../../../atoms/clickable/Clickable";
import Icon from "../../../../atoms/icon/Icon";
import { SummaryMetricsReadOnly } from "./SummaryMetricsReadOnly";
import ScreenOnly from "../../../../atoms/printable/components/ScreenOnly";

interface Props {
  data?: API.SimulationResponse["summaryMetrics"];
  config?: API.SimulationResponse["summaryMetricConfig"];
  onClickEdit?: () => void;
}

const Display: React.FC<Props> = ({ data, config, onClickEdit }) => {
  if (!data) return null;

  return (
    <Card wide>
      <ScreenOnly>
        <InlineGroup verticalCenter>
          <h4>Summary Screen Settings</h4>
          {onClickEdit ? (
            <Clickable
              data-test={"edit-simulation-summary-step"}
              onClick={onClickEdit}
            >
              <Icon
                type="edit"
                tip={{
                  content: "Edit Summary Screen Metrics",
                  id: "edit-summary",
                }}
              />
            </Clickable>
          ) : null}
        </InlineGroup>
        <h4>Metrics List</h4>
        <SummaryMetricsReadOnly data={data} config={config} />
      </ScreenOnly>
    </Card>
  );
};

export default Display;
