export const toWebpBlobs = async (file: File): Promise<File | null> => {
  if (!file) {
    return null;
  }

  return new Promise((resolve) => {
    const rawImage = new Image();
    rawImage.addEventListener("load", function () {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = rawImage.width;
      canvas.height = rawImage.height;
      ctx && ctx.drawImage(rawImage, 0, 0);

      canvas.toBlob(
        (blob) => {
          if (blob) {
            const ext = file.name.split(".").pop();
            const newFileName = file.name.replace(
              new RegExp(`${ext}$`),
              "webp",
            );
            resolve(new File([blob], newFileName));
          } else {
            resolve(null);
          }
        },
        "image/webp",
        0.5,
      );
    });

    rawImage.src = URL.createObjectURL(file);
  });
};
