import { round } from "../../model/utils/formatters";

// Math.ceil and Math.floor work differently with negative numbers so making these helper functions to align with excel
// const roundDown = (val: number) => val < 0 ? Math.ceil(val) : Math.floor(val)

const roundUp = (val: number) => (val < 0 ? Math.floor(val) : Math.ceil(val));
const roundDown = (val: number) => (val < 0 ? Math.ceil(val) : Math.floor(val));

interface ParamsV2 {
  max?: number;
  min?: number;
  isPercentage?: boolean;
  pastRound1: boolean;
  targetTicks?: number;
  padding?: number;
  expectedDecimals?: number;
  data: ModelAPI.Reporting.RoundData[];
  tickMultiplier?: number;
}

export const getYAxisDataV2 = ({
  max,
  min,
  isPercentage,
  padding = 0.25,
  pastRound1,
  expectedDecimals = isPercentage ? 4 : 0,
  data,
  targetTicks = 10,
  tickMultiplier = 1,
}: ParamsV2): {
  minValue: number;
  maxValue: number;
  numberOfTicks: number;
  yAxisDp: number;
} => {
  expectedDecimals = isPercentage ? expectedDecimals - 2 : expectedDecimals;
  const tickSizes = isPercentage
    ? [0.01, 0.025, 0.05, 0.1, 0.25, 0.5, 1, 2.5, 5, 10, 20, 25]
    : [1, 2, 5, 10, 25, 50, 100, 250, 500, 1000, 2000, 2500, 5000];
  const yAxisDp = [2, 3, 2, 1, 2, 1, 0, 0, 0, 0];
  const minDataValue = data.reduce<number | null>(
    (minSoFar: number | null, d: ModelAPI.Reporting.RoundData) => {
      Object.values(d).forEach((value: number | string | null) => {
        if (typeof value === "number") {
          if (minSoFar == null || value < minSoFar) {
            minSoFar = value;
          }
        }
      });
      return minSoFar;
    },
    null,
  );

  const maxDataValue = data.reduce<number | null>(
    (maxSoFar: number | null, d: ModelAPI.Reporting.RoundData) => {
      Object.values(d).forEach((value: number | string | null) => {
        if (typeof value === "number") {
          if (maxSoFar == null || value > maxSoFar) {
            maxSoFar = value;
          }
        }
      });
      return maxSoFar;
    },
    null,
  );

  if (minDataValue == null || maxDataValue == null) {
    throw new Error("No valid data");
  }

  const tickResults = [];
  for (let tickIndex = 0; tickIndex < tickSizes.length; tickIndex++) {
    const tickSize = tickSizes[tickIndex] * tickMultiplier;
    const bias = 0 - tickIndex * 0.001;
    const maxRoundUp = roundUp(maxDataValue / tickSize) * tickSize;
    const minRoundDown = roundDown(minDataValue / tickSize) * tickSize;

    const maxExtra =
      pastRound1 &&
      Math.abs(round(maxDataValue - maxRoundUp, expectedDecimals)) <
        tickSize * padding
        ? tickSize
        : 0;
    const minExtra =
      pastRound1 &&
      Math.abs(round(minDataValue - minRoundDown, expectedDecimals)) <
        tickSize * padding
        ? tickSize
        : 0;
    let maxPlusExtra = round(maxRoundUp + maxExtra, expectedDecimals);
    if (max) {
      if (max < 0) {
        maxPlusExtra = Math.min(maxPlusExtra, max);
      } else {
        maxPlusExtra = Math.max(maxPlusExtra, max);
      }
    }
    let minMinusExtra = round(minRoundDown - minExtra, expectedDecimals);
    if (min != null) {
      if (min === 0) {
        if (minRoundDown === 0) {
          minMinusExtra = 0;
        } else {
          minMinusExtra = Math.min(min, minMinusExtra);
        }
      } else {
        minMinusExtra = Math.min(min, minMinusExtra);
      }
    }
    const ticks = round((maxPlusExtra - minMinusExtra) / tickSize, 0);
    const variance = round(Math.abs(ticks - targetTicks) + bias, 0);
    tickResults.push({
      tickSize,
      minDataValue,
      maxDataValue,
      maxRoundUp,
      minRoundDown,
      maxPlusExtra,
      minMinusExtra,
      ticks,
      variance,
      yAxisDp: yAxisDp[tickIndex] ?? 0,
    });
  }
  const smallestVariance = Math.min(...tickResults.map((tr) => tr.variance));
  const firstResultWithSmallestVariance = tickResults.find(
    (tr) => tr.variance === smallestVariance,
  );
  return {
    minValue: firstResultWithSmallestVariance?.minMinusExtra ?? 0,
    maxValue: firstResultWithSmallestVariance?.maxPlusExtra ?? 0,
    numberOfTicks: firstResultWithSmallestVariance?.ticks ?? targetTicks,
    yAxisDp: firstResultWithSmallestVariance?.yAxisDp ?? 0,
  };
};
