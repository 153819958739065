import { useCallback, useEffect, useRef, useState } from "react";
import { DataPoint, LineData } from "./ResultsLineChart";

interface Props {
  chartRef: React.MutableRefObject<any>;
  shouldRenderLines?: boolean;
  data: DataPoint[];
  lines: LineData[];
}

function useLineChartRendering({
  data,
  chartRef,
  shouldRenderLines,
  lines,
}: Props): [
  null | number,
  boolean,
  (val: boolean) => void,
  React.MutableRefObject<number>,
] {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [renderStartTime, setRenderStartTime] = useState<null | number>(null);
  const animationCountRef = useRef<number>(0);
  const [animationsCompleted, setAnimationsCompleted] = useState(false);
  useEffect(() => {
    if (shouldRenderLines) {
      setRenderStartTime(Date.now());
      setAnimationsCompleted(false);
      animationCountRef.current = 0;
    }
  }, [shouldRenderLines]);

  const showTooltipForCurrentRound = useCallback(() => {
    if (chartRef.current && data.length > 0) {
      const lastActiveRoundIndex = data.reduce((lastIndex, point, index) => {
        if (lines.some((line) => point[line.key] !== null)) {
          return index;
        }
        return lastIndex;
      }, -1);
      if (lastActiveRoundIndex === -1) return;
      const currentRoundData = data[lastActiveRoundIndex] as {
        [key: string]: any;
      };
      const xScale = chartRef.current.state.xAxisMap[0].scale;
      const yScale = chartRef.current.state.yAxisMap[0].scale;
      const xPosition = xScale(currentRoundData.name);
      const yPosition = yScale(
        Math.max(
          ...lines
            .map((line) => currentRoundData[line.key])
            .filter((value) => value !== null),
        ),
      );
      chartRef.current.setState(
        {
          activeTooltipIndex: lastActiveRoundIndex,
          isTooltipActive: true,
          activeCoordinate: { x: xPosition, y: yPosition },
          activeLabel: currentRoundData.name,
          activePayload: [
            ...lines
              .map((line) => ({
                name: line.name,
                value: currentRoundData[line.key],
                color: line.colour,
              }))
              .filter((item) => item.value !== null),
          ],
        },
        () => {
          setTooltipVisible(true);
        },
      );
    }
  }, [chartRef, data, lines]);

  useEffect(() => {
    if (animationsCompleted) {
      showTooltipForCurrentRound();
    }
  }, [animationsCompleted, showTooltipForCurrentRound]);

  useEffect(() => {
    if (animationsCompleted) {
      showTooltipForCurrentRound();
    }
  }, [animationsCompleted, showTooltipForCurrentRound]);

  return [
    renderStartTime,
    tooltipVisible,
    setAnimationsCompleted,
    animationCountRef,
  ];
}

export default useLineChartRendering;
