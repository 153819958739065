import React from "react";

import Container from "../../../../../atoms/page/Page";
import SimpleModal from "../../../../../organisms/standard-modal/SimpleModal";
import ParticipantForm from "./ParticipantForm";
import useIsMobileOrTablet from "../../../../../../hooks/useIsMobileOrTablet";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  event: API.EventResponse;
}

const AddParticipantModal: React.FC<Props> = ({
  event,
  isOpen,
  onClose,
  onComplete,
}) => {
  const isMobileOrTablet = useIsMobileOrTablet();
  return (
    <SimpleModal
      size={isMobileOrTablet ? "scale" : "large"}
      isOpen={isOpen}
      showCloseButton
      showMobileCloseButton={isMobileOrTablet}
      onClose={onClose}
      data-test="add-participant-modal"
    >
      <Container fit className="simulation-edit">
        <h2>Add participant</h2>
        <ParticipantForm
          event={event}
          onAfterSubmit={onComplete}
          onCancel={onClose}
        />
      </Container>
    </SimpleModal>
  );
};

export default AddParticipantModal;
