import React, { useCallback, useEffect } from "react";
import API from "../../../services/api";
import useAPIRequest from "../../../hooks/useAPIRequest";
import SimulationListTable from "./SimulationListTable";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import Banner from "../../atoms/banner/Banner";
import Container from "../../atoms/page/Page";
import StickyBar from "../../organisms/sticky-bar/StickyBar";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import IconButton from "../../molecules/iconbutton/IconButton";

export const STICKY_BAR_HEIGHT = 8;

function SimulationList() {
  const callback = useCallback(() => {
    return API.getSimulations();
  }, []);

  const [{ inProgress, data, completed, error }, doAPIRequest] = useAPIRequest(
    callback,
    { data: [] },
  );

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  const handleRefreshSimulations = useCallback(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  return (
    <Container>
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      <StickyBar height={STICKY_BAR_HEIGHT} spaceBetweenElements={2}>
        <InlineGroup spaceBetweenElements={2}>
          <IconButton
            data-test="add-simulation"
            linkTo="/simulations/add"
            icon="simulations"
            text="Add Simulation"
          />
        </InlineGroup>
      </StickyBar>
      {inProgress && <LoadingSpinner />}
      {completed && data && (
        <SimulationListTable
          simulations={data}
          refreshSimulations={handleRefreshSimulations}
        />
      )}
    </Container>
  );
}

export default SimulationList;
