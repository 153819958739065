import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import Icon from "../../atoms/icon/Icon";
import Image from "../../atoms/image/Image";
import Table from "../../atoms/table/Table";
import StickyBar from "../../organisms/sticky-bar/StickyBar";
import EmptyList from "../../organisms/empty-list/EmptyList";
import IconButton from "../../molecules/iconbutton/IconButton";
import Clickable from "../../atoms/clickable/Clickable";
import DeleteBranding from "./BrandingDelete";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import ClientAvatarList from "../../molecules/client-avatar-list/ClientAvatarList";
import Tooltip from "../../atoms/tooltip/Tooltip";
import RoleRequired from "../../molecules/role-required/RoleRequired";

interface Props {
  brandings: API.BrandingResponse[];
  refreshBrandings: () => void;
}

interface BrandingState {
  deleteModalOpen: boolean;
  brandingSelected: API.BrandingResponse | null;
}

const STICKY_BAR_HEIGHT = 8;
const TOOLTIP_ID = "branding-table-tooltip";

function BrandingListTable({ brandings, refreshBrandings }: Props) {
  const history = useHistory();
  const [{ deleteModalOpen, brandingSelected }, setBrandingState] =
    useState<BrandingState>({ deleteModalOpen: false, brandingSelected: null });

  const onBrandingDeleteComplete = useCallback(() => {
    setBrandingState((prevState) => ({
      ...prevState,
      deleteModalOpen: false,
      brandingSelected: null,
    }));
    refreshBrandings();
  }, [refreshBrandings]);

  const handleBrandingClick = useCallback(
    (branding: API.BrandingResponse) => (event: any) => {
      event.stopPropagation();
      event.preventDefault();
      history.push(`/brandings/${branding.id}/view`);
    },
    [history],
  );

  const handleCloneClick = useCallback(
    (branding: API.BrandingResponse) => (event: any) => {
      event.stopPropagation();
      history.push(`/brandings/${branding.id}/clone`);
    },
    [history],
  );

  const handleDeleteClick = useCallback(
    (branding: API.BrandingResponse) => (event: any) => {
      event.stopPropagation();
      setBrandingState({
        deleteModalOpen: true,
        brandingSelected: branding,
      });
    },
    [],
  );

  return (
    <>
      <Tooltip id={TOOLTIP_ID} />
      <Tooltip id="clone-Branding">
        <>
          <b>Clone Branding</b>
          <p>
            Create a new branding using the details <br /> of this branding as a
            starting point.
          </p>
        </>
      </Tooltip>
      {deleteModalOpen && !!brandingSelected && (
        <DeleteBranding
          isOpen={deleteModalOpen}
          onClose={() =>
            setBrandingState((prevState) => ({
              ...prevState,
              brandingModalOpen: false,
              brandingSelected: null,
            }))
          }
          onComplete={onBrandingDeleteComplete}
          branding={brandingSelected}
        />
      )}
      <StickyBar height={STICKY_BAR_HEIGHT}>
        <RoleRequired roles={["superadmin", "standard", "admin"]}>
          <IconButton
            data-test="add-branding"
            linkTo="/brandings/add"
            icon="branding"
            text="Create Theme"
          />
        </RoleRequired>
      </StickyBar>
      {!brandings.length && (
        <EmptyList icon="branding" message="There are no brandings yet" />
      )}
      {!!brandings.length && (
        <Table stickyHeader stickyTop={STICKY_BAR_HEIGHT} clickable>
          <thead>
            <tr>
              <th>Client Icon</th>
              <th>Client Name</th>
              <th>Game Name</th>
              <th>Clients</th>
              <th># Events Using</th>
              <th className="right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {brandings.map((branding) => (
              <tr
                className="brandingsTable-row"
                key={branding.id}
                data-test={branding.id}
                onClick={handleBrandingClick(branding)}
              >
                <td>
                  <InlineGroup className="pt-4 pb-4">
                    <Image
                      src={branding.brandingImage?.imageUrl}
                      alt="Branding image"
                      heightSize={10}
                    />
                  </InlineGroup>
                </td>
                <td>{branding.clientName}</td>
                <td>{branding.gameName}</td>
                <td>
                  <ClientAvatarList clients={branding.clients} />
                </td>
                <td>{branding.eventsCount}</td>
                <td className="right">
                  <Icon
                    type="search"
                    tt={{ content: "View Branding", id: TOOLTIP_ID }}
                  />
                  <RoleRequired roles={["superadmin", "standard", "admin"]}>
                    <Clickable
                      data-test={`clone-${branding.id}`}
                      onClick={handleCloneClick(branding)}
                    >
                      <Icon
                        type="copy"
                        tt={{ content: "Clone Branding", id: "clone-Branding" }}
                      />
                    </Clickable>
                  </RoleRequired>
                  {branding && (
                    <RoleRequired roles={["superadmin", "standard", "admin"]}>
                      <Clickable
                        data-test={`delete-${branding.id}`}
                        onClick={handleDeleteClick(branding)}
                      >
                        <Icon
                          type="trash"
                          tt={{ content: "Delete Branding", id: TOOLTIP_ID }}
                        />
                      </Clickable>
                    </RoleRequired>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}

export default BrandingListTable;
