import React, { useCallback } from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import ChartCard from "../../components/ChartCard";
import RoundResultsContainer from "../../components/RoundsResultsContainer";
import ResultsText from "../../results-text/ResultsText";
import { useResultsContext } from "../../context/context";
import { useMemo } from "react";
import { mockData, mockDataPageTwo, mockDataPageTwoChange } from "./mockData";
import ChartSection from "./ChartSection";
import QuoteSection from "./QuoteSection";
import { useChartData, useYAxisData } from "./hooks";
import "./RoundResultsPoorRisk.scss";
export const poorRiskMaxClicks = 8;

type ContentMap = {
  [key: number]: React.ReactNode;
};

const RoundResultsPoorRisk = () => {
  const context = useResultsContext();
  const data = useChartData(mockData);
  const dataPageTwo = useChartData(mockDataPageTwo);
  const yAxisData = useYAxisData(mockData);
  const yAxisDataPageTwo = useYAxisData(mockDataPageTwo);
  const yAxisDataPageTwoChange = useYAxisData(mockDataPageTwoChange);
  const dataPageTwoChange = useChartData(mockDataPageTwoChange);
  const contentMap: ContentMap = useMemo(
    () => ({
      0: null,
      1: (
        <ResultsText size="xl" bold colour="blue">
          Costs To remediate prior wrongdoings are significant
        </ResultsText>
      ),
      2: (
        <>
          <ResultsText size="xl" bold colour="blue">
            Costs to remediate prior wrongdoings are significant
          </ResultsText>
          <ChartSection
            data={data}
            yAxisData={yAxisData}
            title="Total remediation costs 2017+"
          />
        </>
      ),
      3: (
        <>
          <ResultsText size="xl" bold colour="blue">
            Costs to remediate prior wrongdoings are significant
          </ResultsText>
          <ChartSection
            data={data}
            yAxisData={yAxisData}
            title="Total remediation costs 2017+"
          />
          <QuoteSection
            quote="We need to fix our issues so we can move forward, and it's my #1 priority. We're addressing our risk management capability, improving culture, accelerating customer remediation and reducing IT complexity"
            author="Westpac CEO Peter King"
          />
        </>
      ),
      4: null,
      5: (
        <ResultsText center size="xl" bold colour="blue">
          APRA required major banks to hold extra capital until operational risk
          is reduced.
        </ResultsText>
      ),
      6: (
        <>
          <ResultsText center size="xl" bold colour="blue">
            APRA required major banks to hold extra capital until operational
            risk is reduced.
          </ResultsText>
          <ChartSection
            data={dataPageTwo}
            yAxisData={yAxisDataPageTwo}
            title="$3.25 Bn of additional capital!"
          />
        </>
      ),
      7: (
        <>
          <ResultsText center size="xl" bold colour="blue">
            APRA required major banks to hold extra capital until operational
            risk is reduced.
          </ResultsText>
          <ChartSection
            data={dataPageTwo}
            yAxisData={yAxisDataPageTwo}
            title="$3.25 Bn of additional capital!"
          />
          <QuoteSection quote="The capital add-ons will apply until the banks have completed their planned remediation to strengthen risk management, and closed gaps identified in their self-assessments." />
        </>
      ),
      8: (
        <>
          <ResultsText center size="xl" bold colour="blue">
            APRA required major banks to hold extra capital until operational
            risk is reduced.
          </ResultsText>
          <ChartSection
            data={dataPageTwoChange}
            yAxisData={yAxisDataPageTwoChange}
            title="$1.75 Bn of additional capital!"
          />
          <QuoteSection quote="The capital add-ons will apply until the banks have completed their planned remediation to strengthen risk management, and closed gaps identified in their self-assessments." />
        </>
      ),
    }),
    [
      data,
      dataPageTwo,
      dataPageTwoChange,
      yAxisData,
      yAxisDataPageTwo,
      yAxisDataPageTwoChange,
    ],
  );

  const content = useMemo(() => {
    if (context.noAnimations) {
      return contentMap[3];
    }
    return contentMap[context.clicks] || null;
  }, [context.clicks, context.noAnimations, contentMap]);

  const onNext = useCallback(() => {
    if (context.clicks < poorRiskMaxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context]);

  return (
    <RoundResultsContainer onNext={onNext}>
      <VerticalGroup full center wide>
        <ChartCard
          heading={
            context.clicks < 4
              ? "Poor Risk Management is Costly"
              : "Cost of Inadequate Risk Management"
          }
        >
          <VerticalGroup center wide full spaceBetweenElements={2}>
            {content}
          </VerticalGroup>
        </ChartCard>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsPoorRisk;
