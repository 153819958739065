import React, { useEffect, useState } from "react";
import API from "../../../../services/api";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import FancyCheckbox from "../../../atoms/fancy-checkbox/FancyCheckbox";
import Text from "../../../atoms/text/Text";
import { LayoutResponse } from "../types";

interface Props {
  layout: LayoutResponse;
  onLayoutUpdate: (
    layoutId: string,
    data: API.ReportingTemplateLayoutUpdateRequest,
  ) => void;
}

export const EditRoundRestrictions: React.FC<Props> = ({
  layout,
  onLayoutUpdate,
}) => {
  const [inProgress, setInProgress] = useState(0);
  const [roundRestrictions, setRoundRestrictions] = useState(
    layout.roundRestrictions ?? [],
  );

  useEffect(() => {
    setRoundRestrictions(layout.roundRestrictions ?? []);
  }, [layout]);

  const addRoundToRestrictions = async (round: number) => {
    const set = new Set(roundRestrictions);
    if (set.has(round)) {
      set.delete(round);
    } else {
      set.add(round);
    }
    const rr = Array.from(set).sort((a, b) => {
      if (a === -1) {
        return 1;
      }
      if (b === -1) {
        return -1;
      }
      return a - b;
    });
    setInProgress(round);
    onLayoutUpdate(layout.id, {
      roundRestrictions: rr,
    });
    setRoundRestrictions(rr);
    setInProgress(0);
  };

  return (
    <>
      <VerticalGroup spaceBetweenElements={2}>
        <Text bold>Choose Rounds</Text>
        <VerticalGroup>
          {[1, 2, 3, 4, 5, 6].map((round) => (
            <FancyCheckbox
              key={round}
              name={`Round ${round}`}
              isLabelHtml
              label={`Rnd ${round}`}
              inProgress={inProgress === round}
              onChange={() => addRoundToRestrictions(round)}
              checked={roundRestrictions.includes(round)}
            />
          ))}
          <FancyCheckbox
            name="Final Rnd"
            isLabelHtml
            label="Final Rnd"
            inProgress={inProgress === -1}
            onChange={() => addRoundToRestrictions(-1)}
            checked={roundRestrictions.includes(-1)}
          />
        </VerticalGroup>
      </VerticalGroup>
    </>
  );
};
