import React, { useMemo } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import TableWithSections from "../tables/TableWithSections";
import getTeamIdsAsFields from "../utils/getTeamIdsAsFields";
import getTeamName from "../utils/getTeamName";
import pluckResultsByType from "../utils/pluckResultsByType";

interface Props {
  configuration: ModelAPI.ConfigurationResponse;
  data: ModelAPI.DecisionsResponse;
  teams: API.GameConnection[];
}

const fields = ["centralBankFacility", "wsf1", "wsf2", "wsf3", "wsf4", "wsf5"];
const fieldMap: { [key: string]: string } = {
  wsf1: "1 Year",
  wsf2: "2 Year",
  wsf3: "3 Year",
  wsf4: "4 Year",
  wsf5: "5 Year",
};

const WholesaleFundingMovements: React.FC<Props> = ({
  configuration,
  data,
  teams,
}) => {
  const { numberOfTeams, numberOfCpuTeams } = configuration;
  const totalNumberOfTeams = numberOfTeams + numberOfCpuTeams;
  const wholesaleFundingMovementsData = data.wholesaleFundingMovements;
  const wholesaleFundingMovementsAmountMethodData =
    data.wholesaleFundingMovementsAmountMethod;
  const teamsArray = useMemo(
    () => new Array(totalNumberOfTeams).fill(0),
    [totalNumberOfTeams],
  );
  const teamFields = useMemo(
    () => getTeamIdsAsFields(totalNumberOfTeams),
    [totalNumberOfTeams],
  );
  return (
    <>
      <VerticalGroup className="mt-2" spaceBetweenElements={2} wide>
        <TableWithSections
          header="Amount Method"
          data={[
            {
              fields: teamFields,
              values: wholesaleFundingMovementsAmountMethodData,
            },
          ]}
          fieldFormatter={(field: string) =>
            getTeamName(parseInt(field), numberOfTeams, teams)
          }
        />
        {teamsArray.map((_, index) => {
          const values = pluckResultsByType(
            wholesaleFundingMovementsData,
            (index + 1).toString(),
          );
          return (
            <TableWithSections
              key={index}
              header={getTeamName(index + 1, numberOfTeams, teams)}
              data={[
                {
                  fields,
                  values,
                },
              ]}
              fieldFormatter={(field: string) => fieldMap[field] ?? null}
            />
          );
        })}
      </VerticalGroup>
    </>
  );
};

export default WholesaleFundingMovements;
