import React from "react";
import Container from "../atoms/page/Page";

function LogOut() {
  return (
    <Container>
      <h1>Log Out</h1>
    </Container>
  );
}

export default LogOut;
