import React from "react";
import "./CustomLegend.scss";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import Text from "../../../../../atoms/text/Text";
import { formatPercentage } from "../calculations/utils";
import InformationPopup from "../../../../../organisms/information-popup/InformationPopup";
interface LegendPayload {
  value: string;
  id: string;
  type: string;
  color: string;
}

interface CustomizedLegendProps {
  payload: LegendPayload[];
  style?: "growthLosses" | "costs";
  currentRoundSpreads: { [rating: string]: string };
  selectedReferenceRate?: number;
}

const CustomizedLegend: React.FC<CustomizedLegendProps> = ({
  payload,
  currentRoundSpreads,
  selectedReferenceRate,
  style = "growthLosses",
}) => {
  if (style === "costs") {
    return (
      <VerticalGroup spaceBetweenElements={2}>
        <VerticalGroup spaceBetweenElements={1} className="cost-container">
          {payload.map((entry, index) => (
            <InlineGroup
              className="cost-legend"
              verticalCenter
              key={`item-${index}`}
              style={{
                backgroundColor: entry.color,
              }}
            >
              <InlineGroup spaceBetweenElements={8}>
                <InlineGroup className="cost-text-container">
                  <Text bold color="white">
                    {entry.value === "Reference Rate" &&
                    selectedReferenceRate != null ? (
                      <Text bold color="black">
                        Ref Rate
                      </Text>
                    ) : (
                      entry.value
                    )}
                  </Text>
                </InlineGroup>
                <Text bold color="white">
                  {entry.value === "Reference Rate" &&
                  selectedReferenceRate != null ? (
                    <Text bold color="black">
                      {formatPercentage(selectedReferenceRate)}
                    </Text>
                  ) : (
                    currentRoundSpreads[entry.value] || "0.00"
                  )}
                </Text>
              </InlineGroup>
            </InlineGroup>
          ))}
        </VerticalGroup>
        <div style={{ paddingLeft: "20px" }}>
          <InformationPopup
            shadow
            title={"Select Year"}
            body={
              "Select and edit a particular year by selecting it from the dropdown, or clicking on the year in the chart X-Axis."
            }
          />
        </div>
      </VerticalGroup>
    );
  }

  return (
    <div className="flex-container">
      <div className="information-container">
        <InformationPopup
          shadow
          title={"Select Year"}
          body={
            "Select and edit a particular year by selecting it from the dropdown, or clicking on the year in the chart X-Axis."
          }
        />
      </div>
      <div className="legend-container">
        {payload.map((entry, index) => (
          <InlineGroup
            verticalCenter
            key={`item-${index}`}
            className="legend-item"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 32 32"
              className="legend-svg"
            >
              <rect x="8" y="8" width="16" height="16" fill={entry.color} />
            </svg>
            <span>{entry.value}</span>
          </InlineGroup>
        ))}
      </div>
    </div>
  );
};

export default CustomizedLegend;
