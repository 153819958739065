export const sortParticipants = (
  participants: API.ParticipantResponse[],
): API.ParticipantResponse[] => {
  return participants.sort((a, b) => {
    if (a.team < b.team) {
      return -1;
    } else if (a.team > b.team) {
      return 1;
    } else {
      if (a.isCeo) {
        return -1;
      } else if (b.isCeo) {
        return 1;
      } else {
        return a.firstName.localeCompare(b.firstName);
      }
    }
  });
};
