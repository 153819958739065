import React, { useCallback } from "react";
import useForm from "../../../../../hooks/useForm";
import API from "../../../../../services/api";
import Banner from "../../../../atoms/banner/Banner";
import Button from "../../../../atoms/button/Button";
import Form, { FormRequiredText } from "../../../../atoms/form/Form";
import Input from "../../../../atoms/form/input/Input";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import ClientDropdown from "../../../../organisms/client-dropdown/ClientDropdown";
import Toggle from "../../../../atoms/toggle/Toggle";
import { Redirect, useHistory } from "react-router-dom";
import UploadFile from "../../../../organisms/upload-file/upload-file";

interface Props {
  helpManual?: API.HelpManualResponse;
  title?: string;
}

const AddHelpManuals: React.FC<Props> = (props) => {
  const [
    {
      formData: { name, zipFile, clientIds, availableToAllClients },
      inProgress,
      fieldErrors,
      error,
      completed,
    },
    {
      setField,
      setFieldSimple,
      setFieldRaw,
      setMultiSelectField,
      handleSubmit,
    },
  ] = useForm(
    props.helpManual
      ? {
          name: props.helpManual.name,
          zipFile: null,
          clientIds: props.helpManual.clientIds,
          availableToAllClients: props.helpManual.availableToAllClients,
        }
      : {
          name: "",
          zipFile: null,
          clientIds: [],
          availableToAllClients: true,
        },
  );

  const history = useHistory();

  const callback = useCallback(async () => {
    const payload = {
      name: name.trim(),
      clientIds: availableToAllClients ? [] : clientIds,
      availableToAllClients,
    };
    const response = props.helpManual?.id
      ? await API.updateHelpManual(props.helpManual.id, payload)
      : await API.createHelpManual(payload);
    if (zipFile) {
      await API.uploadHelpManual(response.id, zipFile);
    }
  }, [name, availableToAllClients, clientIds, props.helpManual, zipFile]);

  const validate = useCallback(() => {
    const errors = [];
    if (!name) {
      errors.push({ field: "name", message: "Enter a help manual name" });
    }

    if (!props.helpManual && !zipFile) {
      errors.push({
        field: "zipFile",
        message: "Please select an zip file to upload",
      });
    }

    if (!clientIds && !availableToAllClients) {
      errors.push({ field: "clientIds", message: "Select a client" });
    }

    return errors;
  }, [name, props.helpManual, zipFile, clientIds, availableToAllClients]);

  const onCancel = useCallback(() => {
    history.push("/administration/configuration/helpManuals");
  }, [history]);

  const handleUpload = useCallback(
    async (file) => {
      setFieldRaw("zipFile", file);
    },
    [setFieldRaw],
  );

  return (
    <>
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {!!completed && !error && (
        <Redirect to={"/administration/configuration/helpManuals"} />
      )}
      <VerticalGroup full spread className={"ml-2"}>
        <VerticalGroup wide>
          <h2>{props.title || "Add Help Manual"}</h2>
          <Form
            id="helpManuals-add-form"
            onSubmit={handleSubmit(callback, validate)}
          >
            <FormRequiredText />
            <Input
              type="text"
              label="File Name *"
              value={name}
              onChange={setField("name")}
              error={fieldErrors.name}
            />
            <UploadFile
              buttonText={inProgress ? "Uploading..." : "Select zip file"}
              label="Select help manual"
              handleFileUpload={handleUpload}
              title="Select a zip file containing the help manual"
              description={zipFile ? `${zipFile.name} selected` : ""}
              allowedType="zip"
              errorMsg={fieldErrors.zipFile}
              disabled={inProgress}
            />
            <InlineGroup verticalCenter>
              <Toggle
                label="Available to all clients"
                helpTitle="Available to all clients"
                helpDescription=""
                checked={!!availableToAllClients}
                onUpdate={setFieldSimple("availableToAllClients")}
                block
              />
            </InlineGroup>
            {!availableToAllClients && (
              <ClientDropdown
                label="Select clients *"
                isMulti
                clientIds={clientIds}
                error={fieldErrors.clientIds}
                onChange={setMultiSelectField("clientIds")}
              />
            )}
          </Form>
        </VerticalGroup>
        <InlineGroup block spread>
          <Button data-test="cancel" light wide onClick={onCancel}>
            Cancel
          </Button>
          <Button
            data-test="save"
            type="submit"
            form="helpManuals-add-form"
            wide
            inProgress={inProgress}
          >
            Save
          </Button>
        </InlineGroup>
      </VerticalGroup>
    </>
  );
};

export default AddHelpManuals;
