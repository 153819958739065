import React from "react";
import { useCallback } from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import { BusinessLabel } from "./BusinessLabel";
interface BusinessLabelsProps {
  onUpdateContent: (
    businessId: string,
    data: Partial<API.BusinessConfigResponse>,
  ) => Promise<void>;
  onBussinessOrderChanged?: (
    businesses: API.BusinessConfigResponse[],
  ) => Promise<void>;
  businesses: API.BusinessConfigResponse[];
}

const BusinessLabels: React.FC<BusinessLabelsProps> = ({
  businesses,
  onUpdateContent,
}) => {
  const onChange = useCallback(
    (businessId: string, field: keyof API.BusinessConfigResponse) =>
      (value: string) => {
        onUpdateContent(businessId, { [field]: value });
      },
    [onUpdateContent],
  );

  return (
    <VerticalGroup
      className="mt-2"
      spaceBetweenElements={2}
      wide
      style={{ overflowY: "auto" }}
    >
      {businesses.map((b, index) => (
        <BusinessLabel onChange={onChange} b={b} key={b.id} index={index} />
      ))}
    </VerticalGroup>
  );
};

export default BusinessLabels;
