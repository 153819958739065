import React, { useCallback, useRef } from "react";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import Text from "../../../../atoms/text/Text";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import Image from "../../../../atoms/image/Image";

import "./exco.scss";
import IntegerDropdown from "../../../../organisms/integer-dropdown/IntegerDropdown";
import { FieldErrors } from "../../../../../hooks/useForm";
import Card from "../../../../atoms/card/Card";
import EditableText, {
  Handles as EditableTextHandles,
} from "../../../../atoms/text/EditableText";
import Clickable from "../../../../atoms/clickable/Clickable";
import Icon from "../../../../atoms/icon/Icon";

const Option: React.FC<{
  excoId: string;
  option: API.ExcoOptionConfig;
  onUpdateField?: (
    excoId: string,
    optionId: string,
    data: Partial<API.ExcoOptionConfig>,
  ) => Promise<void>;
}> = ({ excoId, option, onUpdateField }) => {
  const updateField = useCallback(
    (field: keyof API.ExcoOptionConfig) => (value: string) => {
      if (onUpdateField) {
        onUpdateField(excoId, option.id, { [field]: value });
      }
    },
    [onUpdateField, excoId, option.id],
  );

  const nameRef = useRef<EditableTextHandles>(null);
  const descriptionRef = useRef<EditableTextHandles>(null);
  const outcomeRef = useRef<EditableTextHandles>(null);

  const { name, description, outcome } = option;

  return (
    <Card transparent wide sharp>
      <VerticalGroup spaceBetweenElements={1}>
        <InlineGroup spaceBetweenElements={2} verticalCenter>
          <EditableText
            ref={nameRef}
            type="text"
            size="sm"
            bold
            value={name}
            onChange={updateField("name")}
          />
          <Clickable onClick={() => nameRef.current?.setIsEditing(true)}>
            <Icon noMargin type="edit" tip={{ content: "Edit Name" }} />
          </Clickable>
        </InlineGroup>
        <Text bold>Description</Text>
        <InlineGroup spaceBetweenElements={2} verticalCenter>
          <EditableText
            ref={descriptionRef}
            size="sm"
            value={description?.length ? description : "No description given"}
            onChange={updateField("description")}
          />
          <Clickable onClick={() => descriptionRef.current?.setIsEditing(true)}>
            <Icon noMargin type="edit" tip={{ content: "Edit Description" }} />
          </Clickable>
        </InlineGroup>
        <Text bold>Outcome</Text>
        <InlineGroup spaceBetweenElements={2} verticalCenter>
          <EditableText
            ref={outcomeRef}
            size="sm"
            value={outcome?.length ? outcome : "No outcome given"}
            onChange={updateField("outcome")}
          />
          <Clickable onClick={() => outcomeRef.current?.setIsEditing(true)}>
            <Icon noMargin type="edit" tip={{ content: "Edit Outcome" }} />
          </Clickable>
        </InlineGroup>
      </VerticalGroup>
    </Card>
  );
};

interface ExcoItemProps {
  exco: API.ExcoConfigResponse;
  selectedRound?: number;
  onChange: (exco: API.ExcoConfigResponse, round: number) => void;
  numberOfRounds?: number;
  error?: string;
  onUpdateContent?: (
    excoId: string,
    data: Partial<API.ExcoConfigResponse>,
  ) => Promise<void>;
  onUpdateOption?: (
    excoId: string,
    optionId: string,
    data: Partial<API.ExcoOptionConfig>,
  ) => Promise<void>;
}

const ExcoItem: React.FC<ExcoItemProps> = ({
  exco,
  selectedRound,
  onChange,
  numberOfRounds,
  error,
  onUpdateContent,
  onUpdateOption,
}) => {
  const onExcoItemChange = useCallback(
    ({ value }) => {
      onChange(exco, value);
    },
    [onChange, exco],
  );

  const updateField = useCallback(
    (field: keyof API.ExcoConfigResponse) => (value: string) => {
      if (onUpdateContent) {
        onUpdateContent(exco.id, {
          [field]: value,
        });
      }
    },
    [exco.id, onUpdateContent],
  );

  const nameRef = useRef<EditableTextHandles>(null);
  const descriptionRef = useRef<EditableTextHandles>(null);

  return (
    <Card className="mt-4 p-6" sharp error={!!error} wide>
      <VerticalGroup spaceBetweenElements={6}>
        <InlineGroup spread block>
          <InlineGroup spaceBetweenElements={2} verticalCenter>
            <EditableText
              ref={nameRef}
              bold
              type="text"
              size="sm"
              value={exco.name}
              onChange={updateField("name")}
            />
            <Clickable onClick={() => nameRef.current?.setIsEditing(true)}>
              <Icon noMargin type="edit" tip={{ content: "Edit Name" }} />
            </Clickable>
          </InlineGroup>
          {numberOfRounds && (
            <IntegerDropdown
              name={`exco-decisions-dropdown-${exco.type}`}
              error={error}
              small
              placeholder="Select Round"
              min={0}
              max={numberOfRounds}
              onChange={onExcoItemChange}
              value={selectedRound}
              labelFormatter={(round) =>
                round === 0 ? "Not Selected" : `Round ${round}`
              }
            />
          )}
        </InlineGroup>
        <InlineGroup block spaceBetweenElements={6}>
          <Image size={56} cover src={exco.imageUrl} alt={exco.name} />
          <InlineGroup spaceBetweenElements={6} evenWidthChildren block>
            <VerticalGroup spaceBetweenElements={2}>
              <InlineGroup spaceBetweenElements={2} verticalCenter>
                <Text size="sm" bold>
                  Description
                </Text>
                <Clickable
                  onClick={() => descriptionRef.current?.setIsEditing(true)}
                >
                  <Icon
                    noMargin
                    type="edit"
                    tip={{ content: "Edit Description" }}
                  />
                </Clickable>
              </InlineGroup>
              <EditableText
                ref={descriptionRef}
                size="sm"
                value={
                  exco.description.length
                    ? exco.description
                    : "No description given"
                }
                onChange={updateField("description")}
              />
            </VerticalGroup>
            <VerticalGroup spaceBetweenElements={2}>
              <Text size="sm" bold>
                Options
              </Text>
              {exco.options.map((option) => (
                <Option
                  excoId={exco.id}
                  option={option}
                  key={option.id}
                  onUpdateField={onUpdateOption}
                />
              ))}
            </VerticalGroup>
          </InlineGroup>
        </InlineGroup>
      </VerticalGroup>
    </Card>
  );
};

interface ExcoDecisionSelectProps {
  selected?: { [key in API.ExcoDecisionType]: number };
  onChange?: (newSelected: { [key in API.ExcoDecisionType]: number }) => void;
  numberOfRounds?: number;
  fieldErrors?: FieldErrors;
  onUpdateContent?: (
    excoId: string,
    data: Partial<API.ExcoConfigResponse>,
  ) => Promise<void>;
  onUpdateOption?: (
    excoId: string,
    optionId: string,
    data: Partial<API.ExcoOptionConfig>,
  ) => Promise<void>;
  excoDecisions: API.ExcoConfigResponse[];
}

const ExcoDecisionSelect: React.FC<ExcoDecisionSelectProps> = ({
  selected,
  onChange,
  numberOfRounds,
  fieldErrors,
  onUpdateContent,
  onUpdateOption,
  excoDecisions,
}) => {
  const onExcoSelectChange = useCallback(
    (exco: API.ExcoConfigResponse, round: number) => {
      if (!selected || !onChange) {
        return;
      }
      const items = { ...selected };
      if (round > 0) {
        items[exco.type] = round;
      } else {
        delete items[exco.type];
      }

      onChange(items);
    },
    [onChange, selected],
  );

  return (
    <VerticalGroup
      className="exco-select"
      wide
      spaceBetweenElements={6}
      flexAuto
    >
      {excoDecisions &&
        excoDecisions.length &&
        excoDecisions.map((exco) => {
          const selectedRound = selected?.[exco.type];
          return (
            <ExcoItem
              key={exco.id}
              exco={exco}
              selectedRound={selectedRound}
              onChange={onExcoSelectChange}
              numberOfRounds={numberOfRounds}
              error={
                selectedRound
                  ? fieldErrors?.[`excoDecisions-${exco.type}`]
                  : undefined
              }
              onUpdateContent={onUpdateContent}
              onUpdateOption={onUpdateOption}
            />
          );
        })}
    </VerticalGroup>
  );
};

export default ExcoDecisionSelect;
