export const withBiggestVariance = (variances: Record<string, number>) => {
  const biggest = Object.values(variances).reduce<number>(
    (acc: number, v: number) => {
      const variance = Math.abs(v);
      if (variance > acc) {
        return variance;
      }
      return acc;
    },
    0,
  );
  return {
    ...variances,
    biggest,
  };
};

interface NavigateToProps {
  eventId: string;
  team?: string;
  tab: string;
  section: string;
  table: string;
}

export const navigateTo = ({
  eventId,
  team,
  tab,
  section,
  table,
}: NavigateToProps) => {
  window.location.replace(
    `/events/${eventId}/debug?${team ? `team=${team}&` : ""}tab=${tab}&section=${section}&table=${table}`,
  );
};
