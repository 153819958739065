import React, { useCallback } from "react";
import ChallengeModal from "../../organisms/challenge-modal/ChallengeModal";

import useAPIRequest from "../../../hooks/useAPIRequest";
import API from "../../../services/api";
import ConfirmModal from "../../organisms/confirm-modal/ConfirmModal";
import { pluralise } from "../../../lib/pluralise";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  branding: API.BrandingResponse;
}

function DeleteBranding({ isOpen, onComplete, onClose, branding }: Props) {
  const callback = useCallback(
    () => API.deleteBranding(branding.id),
    [branding],
  );

  const [{ inProgress, error }, doAPIRequest] = useAPIRequest(callback, null);

  const handleConfirm = () => {
    doAPIRequest(onComplete);
  };

  if (branding.eventsCount > 0) {
    return (
      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onClose}
        title="Delete events"
        description={`This branding cannot be deleted as it is being used by ${branding.eventsCount} ${pluralise("event", branding.eventsCount)}. Please make sure it is not in use before deleting.`}
        confirmTitle={"Ok"}
      />
    );
  }

  return (
    <ChallengeModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      challengeQuestion="This action is irreversible. Please type the word DELETE below to proceed."
      challengeAnswer="DELETE"
      challengeAnswerWarning="Please type the word DELETE in CAPS to delete the branding."
      title={`Delete branding: ${branding.clientName} | ${branding.gameName}?`}
      confirmTitle="Delete"
      error={error && error.message}
      warning
    />
  );
}

export default DeleteBranding;
